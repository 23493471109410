/* eslint-disable  */
import React from 'react';
import { Fab, makeStyles, Tooltip, Zoom, withStyles } from '@material-ui/core';
import TooltipIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  tooltipFabLeft: {
    width: 16,
    height: 16,
    minHeight: 16,
    display: 'flex',
    backgroundColor: 'transparent',
    color: theme.palette.text.third,
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    boxShadow: 'none',
    marginLeft: '4px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tooltipFabIcon: {
    fontSize: 16,
  },
  tooltipFab: {
    width: 16,
    height: 16,
    minHeight: 16,
    display: 'flex',
    backgroundColor: 'transparent',
    color: theme.palette.text.third,
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    boxShadow: 'none',
    marginLeft: 6,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
      color: "#c0c0c0"
    },
    '&.relative': {
      position: 'relative',
      top: 'auto',
      transform: 'none',
      left: 'auto',
    },
  },
  selfAligned: {
    width: 16,
    height: 16,
    minHeight: 16,
    display: 'flex',
    backgroundColor: 'transparent',
    color: theme.palette.text.third,
    boxShadow: 'none',
    marginLeft: 6,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
const CustomTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    maxWidth: 220,
    fontSize: 12,
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

export default function CustomToolTip(props) {
  const classes = useStyles();
  return (
    <>
      {props.isFabIcon ? (
        <CustomTip
          TransitionComponent={Zoom}
          title={props.title}
          interactive
          disableHoverListener={props.disableHover || false}
          placement={props.placement}
          arrow
          disabled={props.disabled}
          onOpen={props.onOpen}>
          <Fab
            size="small"
            className={`${props.left ? classes.tooltipFabLeft : classes.tooltipFab} ${props.className || ''
              }`}>
            <TooltipIcon className={`${classes.tooltipFabIcon}`} />
          </Fab>
        </CustomTip>
      ) : props.isSelfAligned ? (
        <CustomTip
          TransitionComponent={Zoom}
          title={props.title}
          interactive

          disableHoverListener={props.disableHover || false}
          placement={props.placement}
          arrow
          onOpen={props.onOpen}>
          <Fab size="small" className={`${classes.selfAligned}`}>
            <TooltipIcon className={`${classes.tooltipFabIcon}`} />
          </Fab>
        </CustomTip>
      ) : (
        <CustomTip
          TransitionComponent={Zoom}
          title={props.title}
          interactive
          disableHoverListener={props.disableHover || false}
          placement={props.placement}
          arrow
          onOpen={props.onOpen}>
          {props.children}
        </CustomTip>
      )}
    </>
  );
}
