import React from 'react';
import moment from 'moment-timezone';
import { Fade, Paper, Box, Typography, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';
import SvgFreetrialRestrictions from '../../../icons/svgFreeTrialRestrictions';
import { useStyles } from './style';
import { planTypeDisplay } from './planTypeDisplay';
import { colors } from 'constants/colors';

export const PlanPaymentDueMenu = ({ isPlanMenuOpen }) => {
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const { planDropdown } = useStyles();
  const planExpiryDate = new Date(Number(UserInfo.subscription_end_date_milli));
  const planExpiry = moment(planExpiryDate).format('D MMM, YYYY');

  return (
    <Fade in={isPlanMenuOpen}>
      <Box
        position="absolute"
        className={`${planDropdown} hasTip`}
        width={280}
        left="-50%"
        zIndex={5}
        mt={3}
        mb={1.2}>
        <Paper elevation={2}>
          <Box className="dropDownBody">
            <Box className="choosePlan" display="flex" alignItems="center">
              <Box width="100%">
                <Box className="currentPlan" display="flex" mb={1} p={2} alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%">
                    <Box>
                      <Typography variant="h6" component="h6">
                        {planTypeDisplay(UserInfo)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button size="small" className="expired-btn" disabled>
                        {' '}
                        Payment Due{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box className="expiredPlan" mb={1}>
                  <Box mb={2}>
                    <Typography variant="h6" component="h6" display="block">
                      Payment due on : {planExpiry}
                    </Typography>
                  </Box>

                  <SvgFreetrialRestrictions />
                </Box>
                <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                  <Box mb={1}>
                    <Typography variant="body2" component="label" color={colors.textSecondary}>
                      Your payment of ${UserInfo.is_payment_due_or_auto_collection_off.amount_due}{' '}
                      is due on{' '}
                      {moment(
                        new Date(UserInfo.is_payment_due_or_auto_collection_off.due_date).getTime()
                      ).format('D MMM, YYYY')}
                      .
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};
