import { useSelector, shallowEqual } from 'react-redux';
import { eventDataSelector } from 'redux/custom-selector';

export const useCommunityVersion = (versionViseData) => {
  const { EventData } = useSelector(eventDataSelector, shallowEqual);

  if (EventData.community_version > 0) {
    if (Array.isArray(versionViseData)) {
      return versionViseData[EventData.community_version - 1];
    }
  } else {
    return null;
  }
};

export const useCommunityVersionNumber = () => {
  const { EventData } = useSelector(eventDataSelector, shallowEqual);

  return EventData.community_version;
};
