import { actionTypes } from 'redux/actions/types';

const {
  EVENT_INT_APP_LOADING,
  EVENT_INT_APP_SUCCESS,
  AVAILABLE_INT_APP_LIST_LOADING,
  AVAILABLE_INT_APP_LIST_SUCCESS,
  TOGGLE_ADD_UPDATE_APP_SIDE_PANEL,
  ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING,
  ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS,
  EVENT_ADD_APP_LOADING,
  VERIFY_ENABLED_EMBED_APP,
  CHECK_EMBED_APP_BACKGROUND_MODE,
} = actionTypes;

const iState = {
  isTableLoading: false,
  tableData: { list: [], isEmbedAppEnabled: false },
  isAddAppListLoading: false,
  intAddAppList: [],
  sidePanel: {
    isShow: false,
    data: {},
  },
  isIconListLoading: false,
  iconList: [],
  isAddAppLoading: false,
  isBackGroundMode: false,
};

const eventEmbedAppsReducer = (state = iState, action) => {
  switch (action.type) {
    case EVENT_INT_APP_LOADING:
      return { ...state, isTableLoading: action.payload };
    case EVENT_INT_APP_SUCCESS:
      return { ...state, tableData: action.payload };
    case AVAILABLE_INT_APP_LIST_LOADING:
      return { ...state, isAddAppListLoading: action.payload };
    case AVAILABLE_INT_APP_LIST_SUCCESS:
      return { ...state, intAddAppList: action.payload };
    case TOGGLE_ADD_UPDATE_APP_SIDE_PANEL:
      return { ...state, sidePanel: action.payload };
    case ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING:
      return { ...state, isIconListLoading: action.payload };
    case EVENT_ADD_APP_LOADING:
      return { ...state, isAddAppLoading: action.payload };
    case ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS:
      return { ...state, iconList: action.payload };
    case CHECK_EMBED_APP_BACKGROUND_MODE:
      return { ...state, isBackGroundMode: action.payload };

    case VERIFY_ENABLED_EMBED_APP:
      return { ...state, tableData: { list: state.tableData, isEmbedAppEnabled: action.payload } };
    default:
      return { ...state };
  }
};

export default eventEmbedAppsReducer;
