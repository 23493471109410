/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react';
import { Box, makeStyles, Container, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SelectDropDown from 'custom-components/form-fields/selectDropdown';
import BtnWithLoader from 'custom-components/btnWithLoader';
import {
  API,
  POSTAPIWITHCUSTOMDOMAIN,
  GETAPIWITHCUSTOMDOMAIN,
  PUTAPIWITHCUSTOMDOMAIN,
} from 'api-setup/api-helper';
import {
  ORG_ONBOARDING,
  ONBOARDING_COUNTRIES_INDUSTRIES,
  USER_INFO1,
} from '../../api-setup/api-endpoints';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { commonSelector, userInfoSelector } from 'redux/custom-selector';
import ProjectSetting from '../../settings';
import { useSource } from 'lib/custom-common-hooks';
import { setNewMessage } from 'redux/actions/setting-actions';
import { OrgQusAnsSchema } from '../../models/schema';
import validateFunctions from '../../models/validation';
import {
  setOnboardingDataCollectionDone,
  getOnboardingDataCollectionDone,
  getUserCredential,
} from 'lib/cookies';
import amplitude from 'lib/amplitude';
import { setUserInfo } from 'redux/actions/user-action';
import { setUserCredential } from 'lib/cookies.js';
import { PricingPlan } from 'constants/pricingPlan';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    padding: `${theme.spacing(7)}px ${theme.spacing(0)}px`,
    marginBottom: theme.spacing(12),
    backgroundColor: theme.palette.bg.separator,
  },
  Title: {
    color: `${theme.palette.text.primary}`,
    fontWeight: 'Bold',
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  boldText: {
    fontWeight: 500,
  },
  trialFeatureBlock: {
    height: '100%',
    '& .blockBody': {
      padding: theme.spacing(5),
    },
  },
  featureList: {
    '& .listItem': {
      display: 'flex',
      marginTop: 20,
      '& .check': {
        marginRight: 15,
      },
      '& .MuiTypography-caption': {
        display: 'block',
      },
    },
  },
  textData: {
    textTransform: 'none',
  },
}));

const eventInYearOpt = ['1 - 5', '5 - 15', '15 - 30', '30 - 50', '50 - 100', '100 +'];

const eventInAttOpt = ['1 - 500', '500 - 1500', '1500 - 5000', '5000 - 25000', '25000 +'];

const initFromField = {
  eventInYear: '',
  eventAttende: '',
  industry: '',
  country: '',
};

export default function Onboarding() {
  const history = useHistory();
  const classes = useStyles();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const dispatch = useDispatch();
  const source = useSource();
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const CUSTOM_URL = ProjectSetting.customBaseURL;
  const [loading, setLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [formField, setFormField] = useState({ ...initFromField });
  const [countriesList, setCountriesList] = useState();
  const [industriesList, setIndustriesList] = useState();
  const APICALL = React.useCallback(
    (ACTION, PropReduxData, methodType, URL, headerData, bodyData, isCutomUrl, APICB) => {
      const [header, body] = API.generateHeader(PropReduxData, headerData, bodyData);
      let APIPromise;
      let APIBASEURL = '';
      if (!isCutomUrl) {
        APIBASEURL = ProjectSetting.baseURL;
      } else {
        APIBASEURL = ProjectSetting.customBaseURL;
      }
      if (methodType === 'GET') {
        APIPromise = GETAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      } else if (methodType === 'POST') {
        APIPromise = POSTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      } else if (methodType === 'PUT') {
        APIPromise = PUTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(setNewMessage({ message: resp.data.message, type: 'success', show: true }));
          if (APICB) APICB(ACTION, 'SUCCESS', resp.data);
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            if (APICB) APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
    },
    []
  );

  React.useEffect(() => {
    const orgId = reduxData.userId;
    source.AS = new Map();
    const [headers, body] = API.generateHeader(reduxData, { organiserId: orgId }, null);

    GETAPIWITHCUSTOMDOMAIN(
      false,
      ONBOARDING_COUNTRIES_INDUSTRIES,
      source.AS,
      headers,
      body,
      CUSTOM_URL
    )
      .then((resp) => {
        setCountriesList(resp.data.countries);
        setIndustriesList(resp.data.industries);
      })
      .catch(API.catchHandler);

    return () => API.removeAllApi(source.AS);
  }, [userInfo.UserInfo.id]);

  React.useEffect(() => {
    const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
      'isOnboardingDataCollectionDone'
    );
    if (isOnboardingDataCollectionDone.toString() === '0') {
      history.push('/onboarding');
    } else {
      history.push('/events');
    }
  }, []);

  function handleValidations(formFields) {
    const { eventInYear, eventAttende, industry, country } = formFields;
    const data = { eventInYear, eventAttende, industry, country };
    const errorObject = validateFunctions.validate(OrgQusAnsSchema, data);
    if (errorObject) {
      return errorObject;
    } else {
      return false;
    }
  }

  const handelClickOnSubmit = () => {
    const errorObject = handleValidations(formField);

    if (!errorObject) {
      setErrorObj({});
      const reqObj = {
        data: {
          annualEvents: formField.eventInYear,
          industry: formField.industry,
          avgAttendeeCount: formField.eventAttende,
          countryId: formField.country.id,
          countryName: formField.country.name,
        },
      };
      setLoading(true);
      APICALL(
        'ORG_ONBOARDING',
        reduxData,
        'POST',
        ORG_ONBOARDING,
        null,
        reqObj,
        true,
        (ACTION, type, data) => {
          if (type === 'SUCCESS') {
            amplitude.Track.moreAboutYourself(
              formField.eventInYear,
              formField.eventAttende,
              formField.industry,
              formField.country.name
            );
            fetchUserAuth();
          } else {
            dispatch(setNewMessage({ message: data.message, type: 'error', show: true }));
          }
        }
      );
    } else {
      setErrorObj(errorObject);
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    }
  };

  const fetchUserAuth = () => {
    const cookiesData = getUserCredential();
    const orgId = reduxData.userId;
    source.AS = new Map();
    const [headers, body] = API.generateHeader(
      reduxData,
      { teamMemberId: cookiesData.teamMemberId ? cookiesData.teamMemberId : 0, organiserId: orgId },
      null
    );

    GETAPIWITHCUSTOMDOMAIN(false, USER_INFO1, source.AS, headers, body, CUSTOM_URL)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          setOnboardingDataCollectionDone(
            resp.data.data.isOnboardingDataCollectionDone,
            'isOnboardingDataCollectionDone'
          );
          setUserCredential(
            {
              authToken: cookiesData.authToken,
              teamMemberId: cookiesData.teamMemberId,
              userPermission: resp.data.data.userPermission,
              organiser_id: orgId,
            },
            'userData'
          );
          // dispatch(setUserInfo(resp.data.data));
          if (
            (resp?.data?.data?.planName === PricingPlan.ENTERPRISE ||
              resp?.data?.data?.planName === PricingPlan.PRO)
          )
            dispatch(setUserInfo({ ...resp.data.data, isCustomEnabled: 1 }));
          else dispatch(setUserInfo(resp.data.data));
          setLoading(false);
          history.push('/events');
        } else {
          setLoading(false);
          history.push('/events');
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    return () => API.removeAllApi(source.AS);
  };

  return (
    <Box className={classes.pageWrapper}>
      <Box display="flex">
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={6}>
              <Box p={7}>
                <Box mb={1}>
                  <Typography variant="h5" className={classes.Title}>
                    Let&apos;s start with the basics
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="subtitle" className={classes.subTitle}>
                    Tell us about the events you host
                  </Typography>
                </Box>
                <Box mt={4}>
                  <SelectDropDown
                    name="country"
                    required
                    placeholder="Select the number closest to your choice"
                    label={
                      <Typography variant="p" className={`${classes.boldtext} ${classes.textData}`}>
                        How many events do you host in a year?
                      </Typography>
                    }
                    inputClassName="bgWhite"
                    size="large"
                    value={formField.eventInYear}
                    error={errorObj.eventInYear}
                    onChange={(val) => {
                      setFormField({
                        ...formField,
                        eventInYear: val,
                      });
                      setErrorObj({ ...errorObj, eventInYear: '' });
                    }}
                    options={eventInYearOpt}
                  />
                </Box>
                <Box mt={4}>
                  <SelectDropDown
                    required
                    name="numberofevent"
                    label={
                      <Typography variant="p" className={`${classes.boldtext} ${classes.textData}`}>
                        How many attendees visit your events?
                      </Typography>
                    }
                    inputClassName="bgWhite"
                    size="large"
                    placeholder="Select the average number of attendees"
                    value={formField.eventAttende}
                    error={errorObj.eventAttende}
                    options={eventInAttOpt}
                    onChange={(val) => {
                      setFormField({
                        ...formField,
                        eventAttende: val,
                      });
                      setErrorObj({ ...errorObj, eventAttende: '' });
                    }}
                  />
                </Box>

                <Box mt={4}>
                  <SelectDropDown
                    name="industries"
                    required
                    placeholder="Pick an option that describes your business"
                    inputClassName="bgWhite"
                    size="large"
                    hasSearch
                    label={
                      <Typography variant="p" className={`${classes.boldtext} ${classes.textData}`}>
                        Select the type of your Organisation?
                      </Typography>
                    }
                    value={formField.industry}
                    error={errorObj.industry}
                    options={industriesList}
                    onChange={(val) => {
                      setFormField({
                        ...formField,
                        industry: val,
                      });
                      setErrorObj({ ...errorObj, industry: '' });
                    }}
                  />
                </Box>
                <Box mt={4}>
                  <SelectDropDown
                    name="countries"
                    required
                    placeholder="Pick the host country of your events"
                    label={
                      <Typography variant="p" className={`${classes.boldtext} ${classes.textData}`}>
                        Country
                      </Typography>
                    }
                    inputClassName="bgWhite"
                    size="large"
                    value={formField.country}
                    error={errorObj.country}
                    options={countriesList}
                    labelKey="name"
                    valueKey="id"
                    isArrayOfObject
                    hasSearch
                    onChange={(val) => {
                      setFormField({
                        ...formField,
                        country: val,
                      });
                      setErrorObj({ ...errorObj, country: '' });
                    }}
                  />
                </Box>

                <Box mt={4}>
                  {' '}
                  <BtnWithLoader
                    className="uppercase"
                    onClick={handelClickOnSubmit}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    type="submit"
                    isLoading={loading}
                    loadingLabel="Submitting"
                    label="Start Free Trial"
                    fullWidth
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.trialFeatureBlock}>
                <CardContent className="blockBody">
                  <Box component="span" color="primary.main">
                    <Typography variant="h5" className={classes.boldtext}>
                      What is in the free trial?
                    </Typography>
                  </Box>

                  <Box mt={2}>
                    <Typography variant="p" className={classes.boldtext}>
                      {' '}
                      Gain access to our core features for 7 Days.
                    </Typography>{' '}
                  </Box>

                  <Box mt={2} pl={2} className={classes.featureList}>
                    <Box className="listItem">
                      <Box>
                        <Typography variant="p" className={classes.boldtext}>
                          {' '}
                          Go Live with Sessions
                        </Typography>
                        <Typography variant="caption" component="span">
                          {' '}
                          Broadcast from Hubilo studio or add YouTube/Vimeo
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="listItem">
                      <Box>
                        <Typography variant="p" className={classes.boldtext}>
                          Lounges/Rooms
                        </Typography>
                        <Typography variant="caption" component="span">
                          Two smart ways to meet & discuss with participants
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="listItem">
                      <Box>
                        <Typography variant="p" className={classes.boldtext}>
                          Virtual Booths
                        </Typography>
                        <Typography variant="caption" component="span">
                          A virtual showcase for your brand or sponsor
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="listItem">
                      <Box>
                        <Typography variant="p" className={classes.boldtext}>
                          More Attendees
                        </Typography>
                        <Typography variant="caption" component="span">
                          {' '}
                          Host events with up to 25 attendees
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="listItem">
                      <Box>
                        <Typography variant="p" className={classes.boldtext}>
                          Advanced Features
                        </Typography>
                        <Typography variant="caption" component="span">
                          {' '}
                          Features like in-depth analytics, leaderboard and much more
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
