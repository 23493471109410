import { RESEND_EMAIL } from 'api-setup/api-endpoints';
import { GETAPI, API } from 'api-setup/api-helper';

// resend email api call

export const resendEmailApiCall = (token, history, setIsLoading, dispatch) => {
  setIsLoading(true);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  GETAPI(false, RESEND_EMAIL, null, headers, null)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        //
      } else {
        API.errStatusHandler(res, history, dispatch);
        setIsLoading(false);
      }
    })
    .catch((err) => {
      API.catchHandler(err);
      setIsLoading(false);
    });
};
