import { actionTypes } from 'redux/actions/types';

const {
  EVENT_ICON_LOADING,
  EVENT_ICON_SUCCESS,
  MEETING_DETAILS_LOADING,
  MEETING_DETAILS_SUCCESS,
  MEETING_DETAILS_UPDATE_LOADING,
} = actionTypes;

const iState = {
  isIconLoading: false,
  iconList: [],
  isMeetingDetailsLoading: false,
  meetingDetails: {},
  updateMeetingLoading: false,
};

const meetingReducer = (state = iState, action) => {
  switch (action.type) {
    case EVENT_ICON_LOADING:
      return { ...state, isIconLoading: action.payload };
    case EVENT_ICON_SUCCESS:
      return { ...state, iconList: action.payload };
    case MEETING_DETAILS_LOADING:
      return { ...state, isMeetingDetailsLoading: action.payload };
    case MEETING_DETAILS_SUCCESS:
      return { ...state, meetingDetails: action.payload };
    case MEETING_DETAILS_UPDATE_LOADING:
      return { ...state, updateMeetingLoading: action.payload };
    default:
      return state;
  }
};

export default meetingReducer;
