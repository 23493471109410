/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import Recaptcha from 'react-invisible-recaptcha';
import { Box, Button, Typography, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InputField from '../../custom-components/form-fields/inputField';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import { VERIFY_USER, RESET_PASSWORD } from '../../api-setup/api-endpoints';
import Validation from '../../models/validation';
import { ResetPasswordSchema } from '../../models/schema';
import SvgBrokenLink from '../../icons/svgBrokenLink';
import { resendEmailApiCall } from 'lib/common-api-functions';
import projectSetting from '../../settings.js';
import { removeAllFromQuery } from 'lib/url-utilities';
import { API, POSTAPI, GETAPI } from 'api-setup/api-helper';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';
import { Lock } from 'react-feather';
import CustomToolTip from 'custom-components/customToolTip';

const useStyles = makeStyles((theme) => ({
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  emailVeriFailedWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
  },
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

// handles validations for form fields
const handleValidations = (formFields) => {
  let newErrObj = '';
  newErrObj = Validation.validate(ResetPasswordSchema, formFields);
  if (newErrObj) return newErrObj;
  return false;
};

// api call for posting organisation and password
const updateApiCall = (
  captchaToken,
  recaptchaRef,
  token,
  formFields,
  history,
  setIsLoading,
  dispatch,
  setIsResetDone
) => {
  const { password, confirmPassword } = formFields;
  const body = {
    data: {
      password,
      confirm_password: confirmPassword,
      captchaToken,
    },
  };
  const headers = { Authorization: `Bearer ${token}` };

  setIsLoading(true);
  POSTAPI(false, RESET_PASSWORD, null, headers, body)
    .then((res) => {
      setIsLoading(false);
      if (res.data.status === API.apiSuccessStatus) {
        setIsResetDone(true);
        dispatch(
          setNewMessage({
            message: 'Success! Your Password has been changed Successfully',
            type: 'success',
            show: true,
          })
        );
        // history.replace('/login');
      } else {
        API.errStatusHandler(res, history, dispatch, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'stg' ||
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            if (recaptchaRef && recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
          }
        });
      }
    })
    .catch((err) => {
      setIsLoading(false);
      API.catchHandler(err, () => {
        if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
          if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    });
};

const loginRedirectionScreen = (history) => {
  return (
    <Box width={350} p={3} m="auto">
      <Box display="flex" justifyContent="center">
        <HubiloLogo height="70px" width="140px" />
      </Box>
      <Box mt={12} color="text.grayColor" textAlign="center">
        <Typography variant="caption" component="p">
          You can now use your new password to log in to your account
        </Typography>
      </Box>
      <Box mt={3.75}>
        <Button
          className="uppercase"
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => history.replace('/login')}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default function VerifyToken(props) {
  const { history } = props;
  const { subTitleWithSeparator, emailVeriFailedWrap, iconColor } = useStyles();
  const [isEmailVerificationFailed, setEmailVerificationFailed] = useState(false);
  const [formFields, setFormFields] = useState({
    password: '',
    confirmPassword: '',
  });
  const [captchaToken, setCaptchaToken] = useState('');
  const recaptchaRef = useRef(null);
  const [errObj, setErrObj] = useState('');
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetDone, setIsResetDone] = useState(false);
  const dispatch = useDispatch();

  const formSubmit = (event) => {
    event.preventDefault();
    const err = handleValidations(formFields);
    if (!err) {
      setErrObj({});
      if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.execute();
        }
      } else {
        makeApiCall('');
      }
    } else {
      setErrObj(err);
    }
  };
  useEffect(() => {
    let request;
    let _token;
    const queryParams = props.location.search.split('=');
    const query = queryParams.pop();
    if (query === 'new') {
      request = query;
      const [tempQueryParams] = queryParams.slice(queryParams.length - 1);
      const [tempToken] = tempQueryParams.split('&request');
      _token = tempToken;
      //   _token = queryParams.slice(queryParams.length - 1)[0].split('&request')[0];
    } else {
      _token = query;
    }
    if (!_token) {
      props.history.replace('/login');
    } else {
      setToken(_token);
      removeAllFromQuery(props.history, props.location);
      let headers;
      if (!request) {
        headers = { type: 'forgot', Authorization: `Bearer ${_token}` };
      } else {
        headers = { type: 'forgot', Authorization: `Bearer ${_token}`, is_renew: 1 };
      }

      GETAPI(false, VERIFY_USER, null, headers, null)
        .then((resp) => {
          if (resp.data.status !== API.apiSuccessStatus) {
            setEmailVerificationFailed(true);
          }
        })
        .catch(API.catchHandler);
    }
  }, []);

  useEffect(() => {
    if (captchaToken) {
      makeApiCall(captchaToken);
    }
  }, [captchaToken]);

  const makeApiCall = (propcCaptchaToken = '') => {
    setIsLoading(true);
    if (isEmailVerificationFailed) resendEmailApiCall(token, props.history, setIsLoading, dispatch);
    else
      updateApiCall(
        propcCaptchaToken,
        recaptchaRef,
        token,
        formFields,
        props.history,
        setIsLoading,
        dispatch,
        setIsResetDone
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errObj[name]) setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      {isResetDone ? (
        loginRedirectionScreen(history)
      ) : isEmailVerificationFailed ? (
        <Box className={`${emailVeriFailedWrap}`} display="flex" alignItems="start">
          <Box width={350} p={3} m="auto">
            <Box mb={2} display="flex" justifyContent="center">
              <HubiloLogo height="50px" width="120px" />
            </Box>
            <Box mb={8} className="text-center">
              <Typography variant="subtitle2" component="h3">
                The reset URL is invalid
              </Typography>
            </Box>
            <Box mt={8} className="text-center" color="text.grayColor">
              <Box component="span">
                <SvgBrokenLink />
              </Box>
              <Box mt={5} ml="auto" mr="auto">
                <Typography variant="caption" component="p">
                  It&apos;s a also possible that you have already reset your password using this
                  link, or it&apos;s been more than 24 hours since we sent the link to you.
                </Typography>
              </Box>

              <Box mt={4}>
                <Typography color="textPrimary" variant="caption">
                  You can request another password.{' '}
                  <Link to="/forgot-password" className="text-uppercase">
                    <Box component="span" color="primary.main" fontWeight={600}>
                      Reset Now
                    </Box>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box mb={10} display="flex" justifyContent="center">
            <HubiloLogo height="50px" width="120px" />
          </Box>
          <form onSubmit={(event) => formSubmit(event)}>
            <Box mt={3.2}>
              <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
                <Typography variant="caption" component="span">
                  Please create your password
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" position="relative" width="93%" height="30px">
                    <CustomToolTip
                      isFabIcon
                      title="Password should be minimum seven characters with a combination of Upper, lowercase, digits and special characters."
                      placement="top"
                    />
                  </Box>
                  <InputField
                    disabled={isLoading}
                    error={errObj.password}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    value={formFields.password}
                    required
                    placeholder="Password"
                    type="password"
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Lock className={iconColor} />}
                    showFieldVisibilityBtn
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    disabled={isLoading}
                    error={errObj.confirmPassword}
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formFields.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder="Confirm Password"
                    type="password"
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Lock className={iconColor} />}
                    showFieldVisibilityBtn
                  />
                </Grid>
              </Grid>
              <Box mt={5}>
                <BtnWithLoader
                  className="uppercase"
                  onClick={formSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                  isLoading={isLoading}
                  loadingLabel="Finishing Up"
                  label="Reset Password"
                  fullWidth
                />
              </Box>
              <Box mt={5} textAlign="center" color="text.grayColor">
                <Typography variant="caption" component="p">
                  Remembered your password?{' '}
                  <Link to="/" className="text-uppercase">
                    <Box component="span" color="primary.main" fontWeight={600}>
                      Try Logging in
                    </Box>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        </>
      )}
      {process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod' ? (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={projectSetting.recaptchaSiteKey}
          onResolved={(PropToken) => setCaptchaToken(PropToken)}
        />
      ) : (
        ''
      )}
    </>
  );
}
