import { planTypeDisplay } from "lib/common-function";
import { useSelector, shallowEqual } from "react-redux";
import { streamTypeByNum } from 'pages/session/streamSessionDetails/constants';
import {
  userInfoSelector,
  eventDataSelector,
} from 'redux/custom-selector';

import {
    getSessionData,
    getSessionAdvanceData
} from 'redux/selectors/conversion-prompt.js';
import { useEffect, useState } from "react";

const useAmplitudeData = () => {
    const [eventPropertySet, setEventPropertySet] = useState({});
    const [sessionPropertySet, setSessionPropertySet] = useState({});

    const eventData = useSelector(eventDataSelector, shallowEqual);
    const userInfo = useSelector(userInfoSelector, shallowEqual);
    const sessionData = useSelector(getSessionData, shallowEqual);
    const sessionAdvanceData = useSelector(getSessionAdvanceData, shallowEqual);
    
    useEffect(()=>{
        if(eventData){
            setEventPropertySet({
                event_id: eventData.id,
                event_type: eventData.EventData.eventMetaTypeValue,
                event_name: eventData.EventData.name,
                event_format: eventData.EventData.type,
                organiser_id: eventData.EventData.organiser_id,
                organisation: userInfo.UserInfo.organisation,
                plan_type: planTypeDisplay(userInfo.UserInfo.planName),
                event_start_date: eventData.EventData.start_time,
                event_end_date: eventData.EventData.end_time,
            });
        }
    },[eventData]);

    useEffect(()=>{
         if(sessionData && sessionAdvanceData){
            let streamTypeName;
            try {
                streamTypeName = sessionAdvanceData.streamTypeId ? streamTypeByNum[sessionAdvanceData.streamTypeId] : '';
            } catch {
                streamTypeName = '';
            }
            setSessionPropertySet({
                session_id: sessionData.details.id,
                session_name: sessionData.details.name,
                is_featured: Boolean(sessionData.details.is_featured),
                is_onsite: Boolean(sessionData.details.location),
                is_enhanced: Boolean(sessionAdvanceData.showHubiverseLayout),
                is_streaming: Boolean(sessionAdvanceData.streamTypeId),
                stream_type: streamTypeName
            });
        }
    },[sessionData, sessionAdvanceData]);

    return {
        eventPropertySet,
        sessionPropertySet
    }
}

export default useAmplitudeData;
