import React, { useState, useEffect } from 'react';

import CreateNewEventRadioBtn from 'custom-components/form-fields/CreateNewEventRadioBtn';
import WebinarCreateNewEvent from './WebinarCreateNewEvent';
import { Box, Typography } from '@material-ui/core';
import SVGErrorIconOutline from 'icons/svgErrorIconOutline';
import SvgEventLockIcon from 'icons/svgEventLockIcon';
import SvgVirtualEventIcon from 'icons/svgVirtualEventIcon';
import SvgSalesKickOff from 'icons/svgSalesKickoff';
import SvgThoughtLeadershipIcon from 'icons/svgThoughtLeadershipIcon';
import SvgBrandBuildingIcon from 'icons/svgBrandBuildingIcon';
import SvgDemandGenerationWebinarIcon from 'icons/svgDemandGenerationWebinarIcon';
import SvgProductLaunchIcon from 'icons/svgProductLaunchIcon';
import SvgHybridEventIcon from 'icons/svgHybridEventIcon';
import SvgPersonEventIcon from 'icons/svgPersonEventIcon';
import SvgWebinarIcon from 'icons/svgCreateEventWebinarIcon';
import SvgUpgradePlan from 'icons/svgUpgradePlan';
import { useStyles } from 'pages/CreateNewEvent/style';
import MsgNotificationComponent from 'pages/CreateNewEvent/MsgNotificationComponent';
import SvgInfoIcon from 'icons/svgInfoIcon';
import SwitchField from 'custom-components/form-fields/switch';
import { EActionType, EEventFormat } from './common/constants';
import CustomToolTip from 'custom-components/customToolTip';
import CreateEventFooter from './CreateNewEventLayout/CreateEventFooter';
import { shallowEqual, useSelector } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';
import { PricingPlan } from 'constants/pricingPlan';
import { useHistory, useParams } from 'react-router';
import { useCustomCreateEventContext } from './context';
import EventFormatLoader from 'pages/CreateNewEvent/Loaders/EventFormatScreenLoader';
import { getActionFromPath } from './common/utils';
import { createEventTrackAmplitute } from 'lib/amplitude/amplitude-tracking';
import ProjectSetting from 'settings';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import SvgWarningIcon from '../../icons/svgWarningIcon';

const EventFormat = ({ isLoading }) => {
  const classes = useStyles();

  // history - contains route history
  const history = useHistory();
  const { state, dispatch } = useCustomCreateEventContext();

  // isCheckIn is to manage check-in toggle ON/OFF for hybrid and in-person events
  const [isCheckIn, setCheckIn] = useState(state.isCheckIn === 1);

  // showUpgrade - to show the upgrade hover box for advanced plan organizers
  const [showUpgrade, setShowUpgrade] = useState('');

  const [showWebinarHover, setWebinarHover] = useState(false);
  // const reduxData = useSelector(commonSelector, shallowEqual);

  // userInfo - the copy of logged-in organiser data
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  // isDisabled is to check admin toggle and disabled the hybrid and in-person options
  const isDisabled = userInfo?.UserInfo?.can_hyb_inp_event === 0;

  // planName - represents the logged-in user subscription plan name (ex: enterprise, planless, advanced, pro)
  const planName = userInfo.UserInfo.subscription_meta_id ? userInfo.UserInfo.planName : '';

  // format - local state to store the selected format
  const [format, setFormat] = useState(state.format);

  // params - contains the route params such as eventId, organiserId, screenId
  const params = useParams();

  // screen - represents the current screen
  const screen = Number(params.screenId);

  // actionType - contains the organiser action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  // isEdit - contains if the current screen action type is edit or not
  const isEdit = actionType === EActionType.EDIT;

  // apiData is the copy of event api data for edit event
  const apiData = state?.editDetails;

  // resticting creating webinar events to client
  const isWebinarEnabled = process.env.REACT_APP_ENABLE_WEBINAR_EVENTS?.toLowerCase() === 'true';

  const { is_virtual_addon_enabled: isVEAddOnEnabled = 0, ve_addon_qty: veAddOnQty = 0 } =
    userInfo.UserInfo;

  // handleChange - will trigger when organiser changes the format
  const handleChange = (event) => {
    setFormat(event.target.value);
    dispatch({ type: 'details', payload: {} });
    dispatch({ type: 'type', payload: {} });
    dispatch({ type: 'setAddOns', payload: {} });
  };

  // handleMouseEvents - triggers when organiser hover on the hybrid and in-person formats
  const handleMouseEvents = (value) => {
    // if the admin toggle is ON, do nothing
    if (!isDisabled) return;

    // if the admin toggle is OFF and the organizer's plan name is advanced, it'll allow to the next step
    if (planName === PricingPlan.ADVANCED) {
      // if the action type is edit and api data format is same as hovered value - do nothing
      if (isEdit && apiData.type === value) return;
      // sets the value to the state and show/hide the upgrade helper text in correct position
      setShowUpgrade(value);
    }
    if (planName === PricingPlan.WEBINAR || planName === PricingPlan.DIGITAL_EVENTS) {
      // if the action type is edit and api data format is same as hovered value - do nothing
      if (isEdit && apiData.type === value) return;
      // sets the value to the state and show/hide the upgrade helper text in correct position
      setShowUpgrade(value);
    }
  };

  /*
   * this effect is to scroll the page to the check-in toggle when organiser changes the format
   * it'll trigger only when organiser selects hybrid/in-person format
   */
  useEffect(() => {
    if (format === EEventFormat.HYBRID || format === EEventFormat.IN_PERSON) {
      const checkInDiv = document.getElementById('CHECK_IN_TOGGLE');
      if (checkInDiv) {
        checkInDiv.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [format]);

  /*
   * this effect is to update the check-in toggle value based on the state
   * it'll trigger when user click on previous button in event type screen
   */
  useEffect(() => {
    setCheckIn(state.isCheckIn === 1);
  }, [state.isCheckIn]);

  /*
   * this effect is to initialize/update the format based on admin toggle
   * it'll trigger when user click on previous button in event type screen and at the start
   */
  useEffect(() => {
    if (state.format === '') {
      if (isDisabled) {
        if (
          planName === PricingPlan.WEBINAR ||
          planName === PricingPlan.WEBINAR_LITE ||
          planName === ''
        ) {
          setFormat(EEventFormat.WEBINAR);
        } else {
          setFormat(EEventFormat.VIRTUAL);
        }
      }
    } else setFormat(state.format);
  }, [planName, state.format, userInfo, isDisabled]);

  const isWebinarDisabled =
    isEdit && apiData.type !== EEventFormat.WEBINAR && format !== EEventFormat.WEBINAR;

  const getEventFormatOptions = () => {
    const webinarEventOption = [
      {
        label: 'Webinar',
        value: EEventFormat.WEBINAR,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgWebinarIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : (
          ''
        ),
        onMouseEnter: () => {
          isWebinarDisabled && setWebinarHover(true);
        },
        onMouseLeave: () => setWebinarHover(false),
        isActive: format === EEventFormat.WEBINAR,
        key: EEventFormat.WEBINAR,
        disabled: isWebinarDisabled,
      },
    ];
    const allEventTypes = [
      {
        label: 'Virtual Event',
        value: EEventFormat.VIRTUAL,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgVirtualEventIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : (
          ''
        ),
        isActive: format === EEventFormat.VIRTUAL,
        onMouseEnter: () => {
          if (planName === PricingPlan.WEBINAR) handleMouseEvents(EEventFormat.HYBRID);
        },
        onMouseLeave: () => setShowUpgrade(''),
        key: EEventFormat.VIRTUAL,
        disabled:
          planName === PricingPlan.WEBINAR ||
          (isEdit && apiData.type !== EEventFormat.VIRTUAL && format !== EEventFormat.VIRTUAL),
      },
      {
        label: 'Hybrid Event',
        value: EEventFormat.HYBRID,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgHybridEventIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : (
          ''
        ),
        isActive: format === EEventFormat.HYBRID,
        onMouseEnter: () => handleMouseEvents(EEventFormat.HYBRID),
        onMouseLeave: () => setShowUpgrade(''),
        disabled:
          (isDisabled && format !== EEventFormat.HYBRID) ||
          (isEdit && apiData.type !== EEventFormat.VIRTUAL && format !== EEventFormat.HYBRID),
        key: EEventFormat.HYBRID,
      },
      {
        label: 'In-Person Event',
        value: EEventFormat.IN_PERSON,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgPersonEventIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : (
          ''
        ),
        isActive: format === EEventFormat.IN_PERSON,
        onMouseEnter: () => handleMouseEvents(EEventFormat.IN_PERSON),
        onMouseLeave: () => setShowUpgrade(''),
        key: EEventFormat.IN_PERSON,
        disabled:
          (isDisabled && format !== EEventFormat.IN_PERSON) ||
          (isEdit && apiData.type !== EEventFormat.VIRTUAL && format !== EEventFormat.IN_PERSON),
      },
    ];
    if (isWebinarEnabled) {
      return webinarEventOption.concat(allEventTypes);
    }
    return allEventTypes;
  };

  const getEventWebinarFormatOptions = () => {
    const allEventTypes = [
      {
        label: 'Webinar',
        secondaryText: 'Create a webinar for up to 3 hours',
        value: EEventFormat.WEBINAR,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgWebinarIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : null,
        onMouseEnter: () => {
          isWebinarDisabled && setWebinarHover(true);
        },
        onMouseLeave: () => setWebinarHover(false),
        isActive: format === EEventFormat.WEBINAR,
        key: EEventFormat.WEBINAR,
        disabled: isWebinarDisabled,
        features: [
          { icon: <SvgDemandGenerationWebinarIcon />, text: 'Demand Generation Webinar' },
          { icon: <SvgBrandBuildingIcon />, text: 'Brand Building' },
          { icon: <SvgProductLaunchIcon />, text: 'Product Launch' },
        ],
      },
      {
        label: 'Virtual Event',
        secondaryText: 'Create an event for up to 3 days',
        value: EEventFormat.VIRTUAL,
        isSvgIcon: (
          <span className="radioBtnIcon">
            <SvgVirtualEventIcon />
          </span>
        ),
        isDisabledIcon: isDisabled ? (
          <span className="disabledLockIcon">
            <SvgEventLockIcon color="primary" />
          </span>
        ) : (
          ''
        ),
        isActive: format === EEventFormat.VIRTUAL,
        onMouseEnter: () => {
          if (planName === PricingPlan.WEBINAR) handleMouseEvents(EEventFormat.HYBRID);
        },
        onMouseLeave: () => setShowUpgrade(''),
        key: EEventFormat.VIRTUAL,
        disabled:
          (planName === '' && !isVEAddOnEnabled) ||
          ((planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
            veAddOnQty < 1) ||
          (isEdit && apiData.type !== EEventFormat.VIRTUAL && format !== EEventFormat.VIRTUAL),
        features: [
          { icon: <SvgPersonEventIcon />, text: 'User Conference' },
          { icon: <SvgThoughtLeadershipIcon />, text: 'Thought Leadership' },
          { icon: <SvgSalesKickOff />, text: 'Lead Generation activity' },
        ],
      },
    ];

    return allEventTypes;
  };

  // store format screen data in redux
  const onNext = () => {
    if (actionType === EActionType.EDIT) {
      createEventTrackAmplitute('EditEventFormatNextButtonClicked', {
        event_id: params?.eventId,
        checkin_facility_required_edited: `${isCheckIn ? 'YES' : 'NO'}`,
        event_format_selected_edited:
          apiData.type !== format ? `"[${apiData.type}, ${format}]"` : null,
        parent_screen: 'EDIT_EVENT_FORMAT',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
      createEventTrackAmplitute('EditEventTypeScreenViewed', {
        event_id: params?.eventId,
        referrer_screen: 'EDIT_EVENT_FORMAT',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
    } else {
      createEventTrackAmplitute('EventFormatNextButtonClicked', {
        checkin_facility_required: `${isCheckIn ? 'YES' : 'NO'}`,
        event_format_selected: format,
        parent_screen: 'EVENT_FORMAT',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
      createEventTrackAmplitute('EventTypeScreenViewed', {
        referrer_screen: 'EVENT_FORMAT',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
    }
    dispatch({ type: 'format', payload: { format, isCheckIn: isCheckIn ? 1 : 0 } });

    // redirects the page to the event type screen
    if (actionType === EActionType.CREATE) history.push(`/events/create/${screen + 1}`);
    else history.push(`/events/${params?.eventId}/${params?.organiserId}/edit/${screen + 1}`);
  };

  // path - stores the upgrade screen route path
  const path = `${window.location.origin}/#/plans`;

  // If webinar event is trying to edit, the event type screen won't be shown instead next screen will be showed.
  if (actionType === EActionType.EDIT && apiData && apiData.type === EEventFormat.WEBINAR) {
    history.push(`/events/${params?.eventId}/${params?.organiserId}/edit/2`);

    return null;
  }

  return (
    <>
      {isLoading ? (
        <EventFormatLoader />
      ) : (
        <>
          <Box className={classes.eventFormatScreen}>
            {isEdit &&
            (apiData.type === EEventFormat.IN_PERSON || apiData.type === EEventFormat.HYBRID) ? (
              <MsgNotificationComponent
                msgText="The event format selected by you cannot be changed now."
                classType="error"
                icon={<SVGErrorIconOutline color="primary" />}
                mt={1.5}
              />
            ) : null}

            {isEdit &&
            (!isDisabled || planName === PricingPlan.ADVANCED) &&
            state.format !== EEventFormat.VIRTUAL ? null : !isDisabled ? (
              <MsgNotificationComponent
                msgText="If you select hybrid, in-person or webinar as the event format, it cannot be changed later."
                classType="error"
                icon={<SVGErrorIconOutline color="primary" />}
                mt={1.5}
                className={`${isWebinarEnabled ? 'msgNotifi' : ''}`}
              />
            ) : planName === PricingPlan.ADVANCED ? (
              <MsgNotificationComponent
                msgText="To create hybrid or in-person events, subscribe to the Pro or Enterprise plan."
                classType="error"
                icon={<SvgEventLockIcon color="primary" />}
                linkText="Upgrade Plan"
                mt={1.5}
                clicked={() => {
                  window.open(path, '_blank');
                  createEventTrackAmplitute('UpgradePlanButtonClicked', {
                    entry_point: `${
                      format === EEventFormat.HYBRID
                        ? 'HYBRID_EVENT_HOVER'
                        : format === EEventFormat.IN_PERSON
                        ? 'IN_PERSON_EVENT_HOVER'
                        : 'PLAIN_TEXT'
                    }`,
                    parent_screen: `${
                      actionType === EActionType.EDIT
                    } ? 'EDIT_EVENT_FORMAT' : 'EVENT_FORMAT'`,
                    funnel_entry_section: `${
                      actionType !== EActionType.EDIT
                    } ? 'CREATE_EVENT_FUNNEL' : 'EDIT_EVENT_FUNNEL'`,
                    app_version: ProjectSetting.buildversion,
                    user_id: userInfo.UserInfo.id,
                    organisation: userInfo.UserInfo.organisation,
                    group:
                      userInfo.UserInfo.is_team_member_loggedin === true
                        ? 'TEAM MEMBER'
                        : 'ORGANISER',
                    plan_type: planName,
                  });
                }}
              />
            ) : // : planName === PricingPlan.WEBINAR ? (
            //   <MsgNotificationComponent
            //     msgText="To create virtual, hybrid or in-person events, upgrade to the Digital Events or All-in plan."
            //     classType="error"
            //     icon={<SvgEventLockIcon color="primary" />}
            //     linkText="See plans"
            //     mt={1.5}
            //     clicked={() => {
            //       createEventTrackAmplitute('seePlansClicked', {
            //         organisation: userInfo.UserInfo.organisation,
            //        group: userInfo?.UserInfo?.is_team_member_loggedin
            //          ? 'TEAM_MEMBER'
            //          : 'ORGANISER',
            //        organiser_id: userInfo?.UserInfo?.organiser_id,
            //       user_id: userInfo?.UserInfo?.id,
            //        plan_type: userInfo?.UserInfo?.planName,
            //      });
            //      window.open('https://www.hubilo.com/plans-packages', '_blank');
            //    }}
            //    />
            //   )
            planName === PricingPlan.DIGITAL_EVENTS ? (
              <MsgNotificationComponent
                msgText="To create hybrid or in-person events, upgrade to the All-in plan."
                classType="error"
                icon={<SvgEventLockIcon color="primary" />}
                linkText="See plans"
                mt={1.5}
                clicked={() => {
                  createEventTrackAmplitute('seePlansClicked', {
                    organisation: userInfo.UserInfo.organisation,
                    group: userInfo?.UserInfo?.is_team_member_loggedin
                      ? 'TEAM_MEMBER'
                      : 'ORGANISER',
                    organiser_id: userInfo?.UserInfo?.organiser_id,
                    user_id: userInfo?.UserInfo?.id,
                    plan_type: userInfo?.UserInfo?.planName,
                  });
                  window.open('https://www.hubilo.com/plans-packages', '_blank');
                }}
              />
            ) : !isEdit &&
              ((planName === '' && isVEAddOnEnabled) ||
                ((planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
                  veAddOnQty > 0)) ? (
              <MsgNotificationComponent
                msgText={`Event format can't be changed later. Make sure to select the right one.`}
                classType="error"
                icon={<SvgWarningIcon />}
                mt={1.5}
              />
            ) : null}
            <Box
              className={` ${classes.eventFormat} ${isWebinarEnabled ? 'eventFormatWebinar' : ''}`}
              mt={3}>
              {planName === PricingPlan.WEBINAR ||
              planName === PricingPlan.WEBINAR_LITE ? (
                <WebinarCreateNewEvent
                  handleChange={(value) => {
                    setFormat(value);
                    dispatch({ type: 'details', payload: {} });
                    dispatch({ type: 'type', payload: {} });
                    dispatch({ type: 'setAddOns', payload: {} });
                  }}
                  name="eventFormat"
                  value={format}
                  defaultValue={format}
                  formControlClassName="newCreateEventOption"
                  options={getEventWebinarFormatOptions()}
                />
              ) : (
                <CreateNewEventRadioBtn
                  handleChange={handleChange}
                  name="eventFormat"
                  value={format}
                  defaultValue={format}
                  formControlClassName={
                    isWebinarEnabled
                      ? 'createEventOption createEventOptionWithWebinar'
                      : 'createEventOption'
                  }
                  options={getEventFormatOptions()}
                />
              )}
              {showUpgrade !== '' && (
                <Box
                  className={`upgradePlanCardHoverWrapper ${
                    showUpgrade === EEventFormat.IN_PERSON ? 'inPersonPosition' : 'hybridPosition'
                  }
                                    `}
                  onMouseEnter={() => setShowUpgrade(showUpgrade)}
                  onMouseLeave={() => setShowUpgrade('')}>
                  <Box className="upgradePlanCardHover">
                    <SvgUpgradePlan />
                    <Box className="upgradePlanCardHoverInner">
                      <Typography variant="body2" component="span">
                        {planName === PricingPlan.DIGITAL_EVENTS
                          ? 'To create hybrid or in-person events, upgrade to the All-in plan.'
                          : 'To create hybrid or in-person events, subscribe to the Pro or Enterprise plan.'}
                      </Typography>
                      <Box
                        className="upgradePlanBtn"
                        mt={1}
                        onClick={() => {
                          if (
                            planName === PricingPlan.WEBINAR ||
                            planName === PricingPlan.DIGITAL_EVENTS
                          ) {
                            createEventTrackAmplitute('seePlansClicked', {
                              organisation: userInfo.UserInfo.organisation,
                              group: userInfo?.UserInfo?.is_team_member_loggedin
                                ? 'TEAM_MEMBER'
                                : 'ORGANISER',
                              organiser_id: userInfo?.UserInfo?.organiser_id,
                              user_id: userInfo?.UserInfo?.id,
                              plan_type: userInfo?.UserInfo?.planName,
                            });
                            window.open('https://www.hubilo.com/plans-packages', '_blank');
                          } else {
                            window.open(path, '_blank');
                          }
                        }}>
                        <Typography variant="body2" component="span">
                          {planName === PricingPlan.WEBINAR ||
                          planName === PricingPlan.DIGITAL_EVENTS
                            ? 'See plans'
                            : ' Upgrade Plan'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              {showWebinarHover && (
                <Box
                  className="upgradePlanCardHoverWrapper webinarPosition"
                  onMouseEnter={() => setWebinarHover(showWebinarHover)}
                  onMouseLeave={() => setWebinarHover('')}>
                  <Box className="upgradePlanCardHover">
                    <Box className="upgradePlanCardHoverInner">
                      <Typography variant="caption" component="span">
                        Changing to a webinar event format is not possible.
                      </Typography>
                    </Box>
                    <span className="arrow">&nbsp;</span>
                  </Box>
                </Box>
              )}
            </Box>
            {(format === EEventFormat.HYBRID || format === EEventFormat.IN_PERSON) && (
              <Box className="checkInFacilityLocation" mt={3}>
                <Typography variant="body2" component="span">
                  Check-in facility required at the event location
                  <CustomToolTip
                    placement="top"
                    title={
                      <Typography variant="caption" component="span">
                        Enable this toggle if attendees need to scan their QR codes while checking
                        into the event or if you are printing badges for the attendees.
                      </Typography>
                    }>
                    <span>
                      <SvgInfoIcon />
                    </span>
                  </CustomToolTip>
                </Typography>
                <Box ml={2} display="inline-flex" id="CHECK_IN_TOGGLE">
                  <SwitchField
                    name="is_landing_page"
                    checked={isCheckIn}
                    onChange={(e) => {
                      setCheckIn(e.target.checked);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <CreateEventFooter onNext={onNext} disabledNext={format === ''} />
        </>
      )}
    </>
  );
};

export default EventFormat;
