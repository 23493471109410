import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography, Breadcrumbs } from '@material-ui/core';
import { Info } from 'react-feather';
import { useStyles } from './style';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import ChooseLandingModal from './chooseLandingModal';
import { DEF_TEMPLATE_PREVIEW } from './constants';
import ChooseTemplateCard from './chooseTemplateCard';
import LoaderForBuilder from './LoaderForBuilder';
import PreviewTemplate from './previewTemplate';
import { useCustomLPApi } from './service';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';
import { useSelector, shallowEqual } from 'react-redux';
import { eventLanguagesSelector, baseEventLanguagesSelector, basicInfoSelector } from 'redux/custom-selector';
// import SkeletonLoader from 'custom-components/skeletonLoader';

// const Loader = () => {
//   return (
//     <Box>
//       <SkeletonLoader height="32px" width="180px" variant="text" />
//       <SkeletonLoader height="18px" width="100%" variant="text" />
//       <Box mt={3}>
//         <Grid container spacing={3}>
//           {[1, 2].map((f) => (
//             <Grid item xs={12} lg={6} key={`${f}-d`}>
//               <SkeletonLoader height="300px" width="100%" variant="rect" />
//               <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
//                 <Box>
//                   <SkeletonLoader height="32px" width="120px" variant="text" />
//                   <SkeletonLoader height="20px" width="180px" variant="text" />
//                 </Box>
//                 <Box display="flex">
//                   <SkeletonLoader variant="round" height="30px" width="30px" />
//                   <SkeletonLoader variant="round" height="30px" width="30px" ml={1} />
//                   <SkeletonLoader variant="round" height="30px" width="30px" ml={1} />
//                 </Box>
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

export default function ChooseTemplate() {
  const classes = useStyles();
  const history = useHistory();
  const [previewData, setPreview] = useState({
    preview: false,
    previewTemplateData: {},
    index: null,
  });
  const basicEventInfo = useSelector(basicInfoSelector, shallowEqual);
  const availableLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const baseEventLanguage = useSelector(baseEventLanguagesSelector, shallowEqual);
  const isControlBuild = Boolean(
    availableLanguages &&
    Array.isArray(availableLanguages) &&
    availableLanguages.length &&
    availableLanguages.length === 1 &&
    (baseEventLanguage?.short_code === 'EN' || basicEventInfo?.eventType === 'WEBINAR')
  );
  const [buildData, setBuild] = useState({ build: false, buildTemplateData: {} });
  const [customLPData, Action] = useCustomLPApi();
  const [templateName, setTemplateName] = useState('');
  const match = useRouteMatch();
  let templatesData = [];
  templatesData = customLPData?.templatesData;
  const [getSelectedView, setSelectedView] = useState({
    id: templatesData.length > 0 ? templatesData[0].id : null,
    preview: DEF_TEMPLATE_PREVIEW,
  });

  const handlePreview = (templateData, previewType, index) => {
    commonTrackEvent('preview template', {
      Template_id: templateData.id,
      'Template name ': templateData.name,
      'rank template': index,
      'Preview type': previewType,
    });

    setPreview({
      ...previewData,
      preview: !previewData.preview,
      previewTemplateData: templateData,
      index,
    });
  };

  const handleBuild = (data) => {
    commonTrackEvent('click start building lp', {
      Template_id: data.id,
      'Template name ': data.name,
      'rank template': data.id,
      source: 'choose your own template',
    });

    setBuild({ ...buildData, build: !buildData.build, buildTemplateData: data });
  };
  const onBuildHandler = (data, isContinue) => {
    const eventName = isContinue ? 'continue build your site' : 'skip name build your site';

    commonTrackEvent(eventName, {
      Template_id: buildData.buildTemplateData.templateId,
      'Template name ': buildData.buildTemplateData.name,
      name: data.name,
    });
    Action.createLandingPage(data, isContinue).then((res) => {
      if (res) {
        commonTrackEvent('success creating design', {
          Template_id: res.templateId,
          'Template name': buildData.buildTemplateData.name,
          'design name': data.name,
          'design-id': res.id,
        });
      }
    });
  };

  useEffect(() => {
    if (!isControlBuild) {
      history.push(
        `/event/${match.params.eventId}/${match.params.organiserId}/registration/landing-page`
      );
    } else Action.getAllTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customLPData.createLandingPageData.id) {
      Action.editLandingPage(customLPData.createLandingPageData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customLPData.createLandingPageData]);

  useEffect(() => {
    if (customLPData.editLPData.length > 0) {
      window.location.href = customLPData.editLPData;
      setBuild({ ...buildData, build: !buildData.build });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customLPData.editLPData]);
  return (
    <Container maxWidth="lg">
      {/* <Loader /> */}
      {!previewData.preview ? (
        <>
          <Box component="header" mb={3}>
            <Breadcrumbs
              className={classes.breadCrumbsBlock}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb">
              <Link
                to={`/event/${match.params.eventId}/${match.params.organiserId}/registration/landing-page`}
                color="inherit">
                Event Landing page
              </Link>
              <Typography color="textSecondary" variant="caption">
                Choose Template
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box mb={3}>
            <Typography variant="h4" component="h4" className={classes.fontNormal}>
              Choose your template
            </Typography>
          </Box>

          <Box mb={3} display="flex" alignItems="center" className={classes.warnBlock}>
            <Info size={15} />
            <Typography variant="body2" component="p">
              After creating, click on the ‘Home’ icon to return to the dashboard. You can publish
              the landing page from the dashboard only.
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {templatesData &&
              templatesData.length > 0 &&
              templatesData.map((templateData, id) => {
                return (
                  <Grid key={id} item xs={6}>
                    <ChooseTemplateCard
                      index={id + 1}
                      templateData={templateData}
                      handlePreview={handlePreview}
                      handleBuild={handleBuild}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </>
      ) : (
        <PreviewTemplate
          setPreview={setPreview}
          previewData={previewData}
          isLanding
          buildData={buildData}
          setBuild={(data) => {
            commonTrackEvent('click start building lp', {
              Template_id: previewData.previewTemplateData.id,
              'Template name ': previewData.previewTemplateData.name,
              'rank template': previewData.index,
              source: 'preview screen',
            });
            setBuild(data);
          }}
        />
      )}
      <ChooseLandingModal
        isOpen={buildData.build}
        buildTemplateData={buildData.buildTemplateData}
        getSelectedView={getSelectedView}
        setSelectedView={setSelectedView}
        onClose={() => {
          Action.setErrorMsg('');
          setTemplateName('');
          setBuild({ ...buildData, build: !buildData.build });
        }}
        setTemplateName={setTemplateName}
        templateName={templateName}
        onBuildHandler={onBuildHandler}
        errorMsg={customLPData.errorMsg}
      />
      {customLPData.loadingState.createLoader && <LoaderForBuilder />}
    </Container>
  );
}
