import React, { useEffect, useState } from 'react';
import { Grid, Container, Divider, Box, Link, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import SvgHubiloNewIcon from 'icons/svgHubiloNewIcon';

const CookiePolicyComp = () => {
  const classes = useStyles();

  const [selectedLink, setSelectedLink] = React.useState('01');
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const handleScroll = (id) => {
    const ele = document.getElementById(id);
    setSelectedLink(id);
    if (ele)
      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
  };

  // console.log('here');
  return (
    <Box pt={0} pb={4} className={classes.whiteBg}>
      <Box className={classes.cookieHeader}>
        {' '}
        <SvgHubiloNewIcon className="logo-icon cursor-pointer" height="40px" width="160px" />
      </Box>
      <Container maxWidth="lg">
        <Box component="header" mb={1} pt={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            // mb={3.75}
            component="header">
            <Typography color="textPrimary" variant="h5" component="h5">
              Dashboard - Cookie Policy
            </Typography>
          </Box>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs component="aside">
            <Box className={` ${scroll ? `${classes.hasFixed}` : ''} ${classes.jumpMenuLinks}`}>
              <Link
                onClick={() => handleScroll('01')}
                className={selectedLink === '01' ? 'active' : ''}>
                01. Introduction
              </Link>
              <Link
                onClick={() => handleScroll('02')}
                className={selectedLink === '02' ? 'active' : ''}>
                02. What cookies do we use and why?
              </Link>
              <Link
                onClick={() => handleScroll('03')}
                className={selectedLink === '03' ? 'active' : ''}>
                03. The website deploys the following cookies:
              </Link>
              <Link
                onClick={() => handleScroll('04')}
                className={selectedLink === '04' ? 'active' : ''}>
                04. Cookies served through our websites
              </Link>
              <Link
                onClick={() => handleScroll('05')}
                className={selectedLink === '05' ? 'active' : ''}>
                05. How long will cookies remain on my device?
              </Link>
              <Link
                onClick={() => handleScroll('06')}
                className={selectedLink === '06' ? 'active' : ''}>
                06. Changes to this Cookie Notice
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <Box id="01" className={selectedLink === '01' ? `${classes.topViewStart}` : ''}>
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="h2" required>
                  Introduction
                </Typography>
              </Box>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  This website uses cookies to improve the user experience and ensure that it is
                  functioning effectively.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  This Cookie Policy is part of our Privacy Policy. For more information about us,
                  and how we protect visitor information, please see our
                  <Box
                    component="a"
                    variant="body2"
                    href="https://hubilo.com/privacy-policy/"
                    target="_blank"
                    ml={0.3}
                    className="text-underline  cursor-pointer">
                    Privacy Policy.
                  </Box>
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  In order to provide you with a more personalized and responsive service we need to
                  remember and store information about how you use this website. This is done using
                  small text files called cookies. Cookies contain small amounts of information and
                  are downloaded to your computer or other device by a server. Your web browser then
                  sends these cookies back to this website on each subsequent visit so that it can
                  recognize you and remember things like your user preferences.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  Whenever you use this website, information may be collected through the use of
                  cookies and other technologies. By using this website you agree to our use of
                  cookies as described in this Cookie Policy.
                </Typography>
              </Box>
            </Box>
            <Box mt={0} mb={0} pt={1}>
              <Divider />
            </Box>
            <Box id="02" className={selectedLink === '02' ? `${classes.topViewStart}` : ''}>
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="h2" required>
                  What cookies do we use and why?
                </Typography>
              </Box>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  Some of the cookies we use are necessary to enable you to move around the website
                  and use its features such as accessing secure areas that may contain content for
                  registered users.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  We or our service providers also use analytic services to help us understand how
                  effective our content is, what interests our users have, and to improve how this
                  website works. In addition, we use few services to understand more about the
                  visitors to this website, and to count visitor numbers and performance cookies to
                  track how many individual users access this website and how often. This
                  information is used for statistical purposes and to improve how this website works
                  and it is not our intention to use such information to personally identify any
                  user. However, if at any time you register and sign into this website we may
                  combine such data with information from our web analytic services and cookies to
                  analyze how you use this website in more detail.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  In addition, we also use cookies on certain pages of the website to communicate
                  with third party data suppliers in order to infer your digital behaviour. This
                  helps us to understand and target more relevant advertising in the future. The
                  information we receive is all aggregate and anonymous, but will include statistics
                  such as demographics, online behaviour, product interests and lifestyle.
                </Typography>
              </Box>
            </Box>
            <Box mt={0} mb={0} pt={1}>
              <Divider />
            </Box>
            <Box id="03" className={selectedLink === '03' ? `${classes.topViewStart}` : ''}>
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="h2" required>
                  The website deploys the following cookies:
                </Typography>
              </Box>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  • “Essential” cookies are necessary for the Website to function and cannot be
                  switched off in our systems. They are usually only set in response to actions made
                  by you which amount to a request for services, such as setting your privacy
                  preferences, logging in or filling in forms.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  • “Analytics” cookies allow us to track analytics by counting visits and traffic
                  sources so we can measure and improve the performance of our Website. They may be
                  set by us or by third party providers (e.g., Amplitude, Google Analytics) to help
                  us to know which pages or videos are the most and least popular and see how
                  visitors move around the Website.
                </Typography>
              </Box>
            </Box>
            <Box mt={0} mb={0} pt={1}>
              <Divider />
            </Box>
            <Box id="04" className={selectedLink === '04' ? `${classes.topViewStart}` : ''}>
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="p" required>
                  Cookies served through our websites
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  Below you will find more specific details on the different types of first and
                  approved third party cookies served through our websites along with why we use
                  these cookies.
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className={classes.blockCookieView}>
                    <Box mb={0.5}>
                      <Typography variant="body1" component="p" color="textPrimary">
                        Category of Cookies
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="body2" component="p" color="textSecondary">
                        <strong>Essential :</strong> We use essential cookies that are necessary for
                        the site to work as intended. This is enabled by default to ensure basic
                        website functionality. You can set your browser to block or alert you about
                        these cookies, but some parts of the site will not then work.
                      </Typography>
                    </Box>

                    <Box mb={0.5}>
                      <Typography variant="body1" component="p" color="textPrimary">
                        Applicable Cookies
                      </Typography>
                    </Box>
                    <Typography variant="body2" component="p" color="textSecondary">
                      Hubilo <br />
                      SameSite <br />
                      Beamer
                      <br /> Intercom
                      <br /> Hubspot
                    </Typography>
                  </Box>
                </Grid>{' '}
                <Grid item xs={12} md={6} lg={6}>
                  <Box className={classes.blockCookieView}>
                    <Box mb={0.5}>
                      <Typography variant="body1" component="h2" color="textPrimary">
                        Cookies served through our websites
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="body2" component="p" color="textSecondary">
                        <strong>Analytics :</strong>Analytics cookies are used to help the website
                        operator know how the site performs, how visitors interact with the site,
                        and whether there may be technical issues. This storage type usually doesn’t
                        collect information that identifies a visitor.
                      </Typography>
                    </Box>

                    <Box mb={0.5}>
                      <Typography variant="body1" component="p" color="textPrimary">
                        Applicable Cookies
                      </Typography>
                    </Box>
                    <Typography variant="body2" component="p" color="textSecondary">
                      Amplitude
                      <br />
                      Google Analytics
                    </Typography>
                  </Box>
                </Grid>{' '}
              </Grid>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  Cookie data captured by Hubilo is solely for the purpose to improve efficiency and
                  performance of Hubilo’s website. No cookie related data is shared or sold with any
                  third party by Hubilo.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  You have the right to choose whether or not to accept cookies and we explain how
                  you can exercise this right below. However, please note that if you choose to
                  refuse cookies you may not be able to use the full functionality of the website.
                  You may change the cookie settings for the website at any time by clicking on the
                  ‘Cookie Icon’.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  As an alternative, you can block all cookies by activating the setting on your
                  browser that allows you to refuse the setting of all or some cookies. However, if
                  you use your browser settings to block all cookies (including essential cookies)
                  you may not be able to access all or parts of our site. If you accept some or all
                  cookies on the website you still have the option of setting your browser to notify
                  you when you receive a cookie, so that you may determine whether to accept it or
                  not.
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  If we detect that you have enabled the Do Not Track setting in your browser, we
                  will automatically disable Advertising/Targeting cookies.
                </Typography>
              </Box>
            </Box>
            <Box mt={0} mb={0} pt={1}>
              <Divider />
            </Box>
            <Box id="05">
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="h2" required>
                  How long will cookies remain on my device?
                </Typography>
              </Box>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  The period of time cookies will stay on your device will depend on whether it is a
                  “persistent” or “session” cookie. A persistent cookie will be stored by a web
                  browser and will remain valid until its set expiration date, unless deleted by you
                  before the expiration date. A session cookie, on the other hand, will expire at
                  the end of your web session, when the web browser is closed.
                </Typography>
              </Box>
            </Box>
            <Box mt={0} mb={0} pt={1}>
              <Divider />
            </Box>
            <Box id="06">
              <Box className={classes.blankBlock}>&nbsp;</Box>
              <Box mb={1.5}>
                <Typography variant="body1" component="h2" required>
                  ‍Changes to this Cookie Notice
                </Typography>
              </Box>

              <Box mb={1.5}>
                <Typography variant="body2" component="p" color="textSecondary">
                  We may modify or amend this Cookie Notice from time to time at our discretion.
                  When we make changes to this notice, we will amend the revision date at the top of
                  this page, and such modified or amended Cookie Notice shall be effective as to you
                  and your information as of that revision date.
                </Typography>
                <Box mb={1.5}>
                  <Typography variant="body2" component="p" color="textSecondary">
                    We encourage you to periodically review this Cookie Notice to be informed about
                    how we are using cookies.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CookiePolicyComp;
