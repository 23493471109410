import { commonActionTypes } from 'redux/actions/common';

const { GET_BASIC_INFO, GET_BASIC_INFO_LOADING, SET_COPY_EVENT_VE_CREDITS_MODAL_OPEN } =
  commonActionTypes;

const iBasicInfoState = {
  isBasicInfoLoading: false,
  basicInfo: {},
  isVeAddOnCreditsNotAvl: false,
};

const commonReducer = (state = { ...iBasicInfoState }, action) => {
  switch (action.type) {
    case GET_BASIC_INFO_LOADING:
      return { ...state, isBasicInfoLoading: action.payload };
    case GET_BASIC_INFO:
      return { ...state, basicInfo: action.payload };
    case SET_COPY_EVENT_VE_CREDITS_MODAL_OPEN:
      return { ...state, isVeAddOnCreditsNotAvl: action.payload.isVeAddOnCreditsNotAvl };
    default:
      return { ...state };
  }
};

export default commonReducer;
