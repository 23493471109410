import { SET_BASE_EVENT_LANGUAGE } from "redux/actions/base-language-action";


const baseEventLanguageReducer = (state = [], action) => {
    switch (action.type) {
        case SET_BASE_EVENT_LANGUAGE:
            return action.payload
        default:
            return state;
    }
}

export default baseEventLanguageReducer;