import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Badge,
} from '@material-ui/core';
import InputField from 'custom-components/form-fields/inputField';
import { Filter, Search, X as CancelIcon } from 'react-feather';
import { useStyles } from './attendee-lead-score-table-style';
import CustomToolTip from 'custom-components/customToolTip';
import ExportBtn from 'custom-components/exportBtn';
import leadScoreLearnMoreUrls from '../../constants/learnMoreUrls';
import EnhancedTable from 'custom-components/enhancedTable';
import { leadScoreAnalyticsHeadRows } from './tableData';
import useTable from 'lib/hooks/table-hooks';
import leadScoreApiUrls from '../../constants/apiUrls';
import { createLeadScoreAnalyticsTableData } from './handlers';
import AnalyticsFilter, { idExtractor } from './analytics-filter';
import { shallowEqual, useSelector } from 'react-redux';
import { leadScoreSelector } from '../../lead-score-redux-flow/lead-score-selector';
import { getLeadLevelDetails } from '../../lead-level/handler';
import ExportToMail from './export-to-mail';
import {
  downloadLeadScoreReportClicked,
  filterIconClicked,
} from '../../utility/lead-score-amplitude';

const AttendeeLeadScoreTable = ({ eventInfo }) => {
  const { chartBlock, badgeColor, chipLabel } = useStyles();
  const [StateTable, ActionTable, restOptions] = useTable(
    leadScoreApiUrls.leadLevelAnalyticsDetails,
    {
      createData: createLeadScoreAnalyticsTableData,
      isCustomDomainV2: true,
    },
    {},
    { rowsPerPage: 10 }
  );
  const inputRef = React.useRef(null);
  const setFocus = React.useRef(false);
  const { overview } = useSelector(leadScoreSelector, shallowEqual);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [levels, setLevels] = useState([]);
  const [filterPayload, setFilterPayload] = useState({ liveLevelIds: [], onDemandLevelIds: [] });
  const [isExport, setIsExport] = useState(false);

  const tableHasData = Boolean(StateTable.tableList.length);
  const isFilterApplied =
    filterPayload.liveLevelIds.length || filterPayload.onDemandLevelIds.length;

  useEffect(() => {
    if (inputRef && inputRef.current && !tableHasData && setFocus.current) {
      inputRef.current.focus();
    }
  }, [tableHasData]);

  useEffect(() => {
    if (overview.levels) {
      setLevels(overview.levels);
    }
  }, [overview]);

  const toggleExport = () => {
    setIsExport(!isExport);
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} className={`${chartBlock}`}>
          <Card>
            <CardHeader
              // className={classes.cardHeader2}
              title={
                <>
                  <Box display="table-cell" position="relative">
                    <Typography variant="subtitle2" component="h3">
                      ATTENDEE LEAD SCORE
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Box width={250}>
                      <InputField
                        type="text"
                        size="small"
                        startAdornment
                        startIconAdornment
                        startAdornmentIcon={<Search size={18} />}
                        endIconBtnAdornment
                        value={StateTable.searchText}
                        endAdornment={Boolean(StateTable.searchText.length)}
                        endIcon
                        onChange={(event) => {
                          ActionTable.setSearchText(event.target.value);
                          if (setFocus && !setFocus.current) {
                            setFocus.current = true;
                          }
                        }}
                        endBtnIcon={<CancelIcon size={18} />}
                        autofocus={false}
                        onBtnClick={() => {
                          ActionTable.setSearchText('');
                          if (setFocus && setFocus.current) {
                            setFocus.current = false;
                          }
                        }}
                        onEnter={() => {
                          ActionTable.reducer('FIRST-PAGE');
                        }}
                        disabled={
                          StateTable.loadingState.isInitialLoading ||
                          StateTable.loadingState.isPaginateLoading
                        }
                        placeholder="Search"
                        inputRef={inputRef}
                      />
                    </Box>
                    <Box ml={1.5}>
                      <CustomToolTip title="Filter" placement="top">
                        <Badge
                          badgeContent=""
                          className={isFilterApplied ? badgeColor : ''}
                          component="div">
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              filterIconClicked(eventInfo);
                              setFilterOpen(!isFilterOpen);
                            }}
                            className="square">
                            <Filter size={20} />
                          </Button>
                        </Badge>
                      </CustomToolTip>
                    </Box>
                    <Box ml={1.5}>
                      <CustomToolTip title="Export" placement="top">
                        <ExportBtn
                          onClick={() => {
                            downloadLeadScoreReportClicked(eventInfo);
                            toggleExport();
                          }}
                        />
                      </CustomToolTip>
                    </Box>
                  </Box>
                </>
              }
              subheader={
                <>
                  <Box>
                    <Typography component="span" variant="body2">
                      Get insights about attendee lead scores and levels during your event.
                      <Box
                        component="a"
                        onClick={() =>
                          window.open(leadScoreLearnMoreUrls.leadLevelAnalyticsUrl, '_blank')
                        }
                        className="cursor-pointer text-underline">
                        {` Learn More`}
                      </Box>
                    </Typography>
                  </Box>
                </>
              }
            />

            <CardContent>
              <Box display="flex" justifyContent="center" pb={2}>
                <EnhancedTable
                  isInitLoading={StateTable.loadingState.isInitialLoading}
                  isPaginateLoading={StateTable.loadingState.isPaginateLoading}
                  headRows={leadScoreAnalyticsHeadRows}
                  rows={StateTable.tableList}
                  rowsUi={{
                    name: ({ name, email }) => {
                      return (
                        <Box
                          component="p"
                          mt={0}
                          mb={0}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxWidth="240px"
                          whiteSpace="nowrap">
                          <Typography color="textPrimary" variant="body2">
                            {name}
                          </Typography>
                          <Typography color="textSecondary" variant="caption">
                            {email}
                          </Typography>
                        </Box>
                      );
                    },
                    liveScore: ({ liveScore }) => {
                      return (
                        <Box justifyContent="center" display="flex" pr={3}>
                          {liveScore}
                        </Box>
                      );
                    },
                    liveLeadLevelId: ({ liveLeadLevelId }) => {
                      const details = getLeadLevelDetails(liveLeadLevelId, levels); // color in details
                      return (
                        <Box justifyContent="center" display="flex">
                          <Chip
                            label={details.label || ''}
                            size="small"
                            className={`text-${'primary'} ${chipLabel}`}
                            style={{ background: `${details.color}`, color: 'white' }}
                          />
                        </Box>
                      );
                    },
                    onDemandScore: ({ onDemandScore }) => {
                      return (
                        <Box justifyContent="center" display="flex" pr={3}>
                          {onDemandScore}
                        </Box>
                      );
                    },
                    onDemandLevelId: ({ onDemandLevelId }) => {
                      const details = getLeadLevelDetails(onDemandLevelId, levels); //  color in details
                      return (
                        <Box justifyContent="center" display="flex">
                          <Chip
                            label={details.label || ''}
                            size="small"
                            className={`text-${'primary'} ${chipLabel}`}
                            style={{ background: `${details.color}`, color: 'white' }}
                          />
                        </Box>
                      );
                    },
                  }}
                  {...restOptions}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {isFilterOpen && (
        <AnalyticsFilter
          isOpen={isFilterOpen}
          onClose={() => {
            setFilterOpen(false);
          }}
          filterPayload={filterPayload}
          levels={levels}
          onApply={(payload = {}) => {
            setFilterPayload(payload);

            const payloadOfIds = {
              onDemandLevelIds: idExtractor(payload.onDemandLevelIds),
              liveLevelIds: idExtractor(payload.liveLevelIds),
            };
            ActionTable.setFilterData({ ...StateTable.filterData, ...payloadOfIds });
          }}
          eventInfo={eventInfo}
          isFilterApplied={isFilterApplied}
        />
      )}
      {isExport && <ExportToMail isExport={isExport} toggleExport={toggleExport} />}
    </>
  );
};

export default AttendeeLeadScoreTable;
