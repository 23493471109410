export const SET_EVENT_LIST_DATA = 'SET_EVENT_LIST__DATA';
export const UPDATE_EVENT_LIST_DATA = 'UPDATE_EVENT_LIST__DATA';

export const setEventListData = (eventListData) => {
  if (eventListData.langUpdate) {
    return {
      type: UPDATE_EVENT_LIST_DATA,
      data: eventListData,
    };
  }
  return {
    type: SET_EVENT_LIST_DATA,
    data: eventListData,
  };
};
