/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgUserConferenceIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}>
            <path d="M19.79 7.13C19.6958 7.01082 19.5758 6.91466 19.4389 6.84882C19.302 6.78298 19.1519 6.74918 19 6.75H8.00001V6.25C8.00126 5.81468 8.1445 5.39165 8.408 5.04514C8.6715 4.69862 9.04086 4.44753 9.46001 4.33C9.72929 4.58983 10.086 4.73963 10.46 4.75H11.46C11.8578 4.75 12.2394 4.59196 12.5207 4.31066C12.802 4.02936 12.96 3.64782 12.96 3.25C12.96 2.85218 12.802 2.47064 12.5207 2.18934C12.2394 1.90804 11.8578 1.75 11.46 1.75H10.46C10.2346 1.75031 10.0122 1.80177 9.80949 1.9005C9.60683 1.99924 9.42921 2.14267 9.29001 2.32C8.36828 2.48625 7.53411 2.97067 6.93289 3.68884C6.33167 4.40702 6.00153 5.31339 6.00001 6.25V6.75H5.00001C4.84812 6.74918 4.69804 6.78298 4.56115 6.84882C4.42427 6.91466 4.30419 7.01082 4.21001 7.13C4.10984 7.24874 4.0387 7.38918 4.00225 7.5402C3.9658 7.69121 3.96503 7.84864 4.00001 8L4.62001 10.49C4.76805 11.0635 5.08256 11.5804 5.5239 11.9755C5.96524 12.3705 6.51365 12.6261 7.10001 12.71L7.88001 19.71H7.00001C6.7348 19.71 6.48044 19.8154 6.29291 20.0029C6.10537 20.1904 6.00001 20.4448 6.00001 20.71C6.00001 20.9752 6.10537 21.2296 6.29291 21.4171C6.48044 21.6046 6.7348 21.71 7.00001 21.71H17C17.2652 21.71 17.5196 21.6046 17.7071 21.4171C17.8947 21.2296 18 20.9752 18 20.71C18 20.4448 17.8947 20.1904 17.7071 20.0029C17.5196 19.8154 17.2652 19.71 17 19.71H16.12L16.9 12.71C17.4822 12.6199 18.0251 12.3604 18.4607 11.9639C18.8964 11.5673 19.2057 11.0512 19.35 10.48L20 8C20.035 7.84864 20.0342 7.69121 19.9978 7.5402C19.9613 7.38918 19.8902 7.24874 19.79 7.13V7.13ZM14.1 19.75H9.90001L9.12001 12.75H14.88L14.1 19.75ZM17.41 10C17.3553 10.2219 17.2262 10.4183 17.0442 10.5566C16.8623 10.6949 16.6384 10.7667 16.41 10.76H7.56001C7.33158 10.7667 7.10776 10.6949 6.92581 10.5566C6.74386 10.4183 6.61476 10.2219 6.56001 10L6.28001 8.75H17.72L17.41 10Z" fill="currentcolor" />
        </svg>
    );
}
