// libs
import React, { useState } from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useSource } from 'lib/custom-common-hooks';

// Custom Components
import CustomModal from 'custom-components/customModal';
import BtnWithLoader from 'custom-components/btnWithLoader';

// API helpers
import { TOGGLE_CAMPAIGN_STATUS } from 'api-setup/api-endpoints';
import { API, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';

// redux Actions
import { deactivateEmailAction } from 'redux/actions/email-deactivate-action';
import { setNewMessage } from 'redux/actions/setting-actions';

// redux Selectors
import {
  CampaignStatusSelector,
  CampaignActionSelector,
  CampaignIdSelector,
  commonSelector,
  CampaignCBSelector,
} from 'redux/custom-selector';

// helpers
import { isTrueObject } from 'pages/emailBuilder/create-email/helpers';

// constants
import projectSetting from 'settings';
import {
  LABEL_DEACTIVATE_TITLE,
  LABEL_DEACTIVATE_DESCRIPTION,
  LABEL_ACTIVATE_BTN,
  LABEL_DEACTIVATE_BTN,
  schemaCampaignStatus,
} from './create-email/constants';

// Style CSS
import { useStyles } from './style';
import SvgDeactivateEmail from 'icons/svgDeactivateEmail';
import { Box, Button, Grid, Typography } from '@material-ui/core';

// Component Constants
const CUSTOM_URL = projectSetting.customBaseURL5;
const initState = {
  isLoading: false,
};

// Component
const EmailDisable = () => {
  const classes = useStyles();

  // config
  const history = useHistory();
  const dispatch = useDispatch();
  const source = useSource();

  // Redux State
  const reduxData = useSelector(commonSelector, shallowEqual);
  const isModalOpen = useSelector(CampaignStatusSelector, shallowEqual);
  const campaignStatus = useSelector(CampaignActionSelector, shallowEqual);
  const campaignId = useSelector(CampaignIdSelector, shallowEqual);
  const CampaignCB = useSelector(CampaignCBSelector, shallowEqual);

  // Component States
  const [state, setState] = useState({ ...initState });
  const updateState = (currState = {}) => {
    const hasKeys = isTrueObject(currState);
    if (hasKeys) {
      setState((prevState) => {
        return {
          ...prevState,
          ...currState,
        };
      });
    }
  };

  // API Calls
  const toggleCampaignStatus = (payload = {}) => {
    const [header] = API.generateHeader(reduxData, null, null);
    return POSTAPIWITHCUSTOMDOMAIN(
      false,
      TOGGLE_CAMPAIGN_STATUS,
      source.AS,
      header,
      payload,
      CUSTOM_URL
    )
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          const sMsg = get(resp?.data || {}, 'message', '');
          if (sMsg) dispatch(setNewMessage({ message: sMsg, type: 'success', show: true }));
          if (CampaignCB) CampaignCB();
          dispatch(deactivateEmailAction());
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            updateState({ isLoading: false });
            dispatch(deactivateEmailAction());
          });
        }
      })
      .catch(API.catchHandler);
  };

  // Manipulation Functions
  const handleAction = () => {
    if (campaignId) {
      updateState({ isLoading: true });
      toggleCampaignStatus({
        status: campaignStatus.toUpperCase(),
        campaignId,
      });
    }
  };

  const handleClose = () => {
    dispatch(deactivateEmailAction());
  };

  // render values
  const actionBtnLabel =
    campaignStatus === schemaCampaignStatus.active ? LABEL_ACTIVATE_BTN : LABEL_DEACTIVATE_BTN;
  const actionLoading = get(state, 'isLoading', false);

  return (
    <Box>
      <CustomModal isOpen={isModalOpen} onClose={handleClose} paddingZero>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Box className={classes.emailDisableTextBlock}>
                <Typography color="textPrimary" variant="h6" component="h3">
                  {LABEL_DEACTIVATE_TITLE}
                </Typography>
                <Box className="emailDisableDesc">
                  <Typography color="textSecondary" variant="body1" component="h6">
                    {LABEL_DEACTIVATE_DESCRIPTION}
                  </Typography>
                </Box>
                <BtnWithLoader
                  // className="uppercase"
                  onClick={handleAction}
                  disabled={actionLoading}
                  variant="contained"
                  size="small"
                  color="primary"
                  isLoading={actionLoading}
                  loadingLabel={actionBtnLabel}
                  label={actionBtnLabel}
                />
                <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.emailDisableImgBlock}
                display="flex"
                alignItems="center"
                justifyContent="center">
                <SvgDeactivateEmail />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default EmailDisable;
