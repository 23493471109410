import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from './style';
import CardWrapper from 'custom-components/cardWrapper';
import InputField from '../../../../custom-components/form-fields/inputField';
import RadioBtn from 'custom-components/form-fields/radioBtn';
import Checkbox2 from 'custom-components/form-fields/checkbox2';
import { numberRegex } from 'models/regex';
import OptionList from 'custom-components/form-fields/optionsList';
import Validator from 'models/validation';
import { quizQuestionsSchema } from 'models/schema';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useDispatch } from 'react-redux';
import QuestionsSkeletonLoader from './quizQuestionsSkeletonLoader';
import { useRouteMatch } from 'react-router';
import { useCustomQuizContext } from '../context';

const initialState = {
  name: '',
  isMandatory: false,
  fieldType: 'Checkbox',
  points: 10,
};

const fieldTypeValues = {
  Checkbox: 1,
  Radio: 2,
};

const AddEditQuestion = ({
  handleAddQuestion,
  isEdit = false,
  handleEditQuestion,
  getQuizQuestions,
  questionData,
  handleEditCancle,
  quizLoading = false,
  addQuestion = false,
  apiLoading = false,
  questionsLength = 0,
}) => {
  const { state } = useCustomQuizContext();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState(isEdit ? { ...questionData } : { ...initialState });
  const [errObj, setErrObj] = useState('');
  const [loaders, setLoaders] = useState({
    addLoading: false,
    moreLoading: false,
  });
  const match = useRouteMatch();

  const [checkBoxOptions, setCheckBoxOptions] = useState(
    isEdit && formFields?.fieldType === 1
      ? questionData.options
      : [
          { value: '', id: 1, isSelected: false, error: '' },
          { value: '', id: 2, isSelected: false, error: '' },
        ]
  );
  const [radioOptions, setRadioOptions] = useState(
    isEdit && formFields?.fieldType !== 1
      ? questionData.options
      : [
          { value: '', id: 1, isSelected: false, error: '' },
          { value: '', id: 2, isSelected: false, error: '' },
        ]
  );

  useEffect(() => {
    if (!apiLoading) {
      setLoaders({
        addLoading: false,
        moreLoading: false,
      });
    }
  }, [apiLoading]);

  useEffect(() => {
    if (addQuestion && !apiLoading) {
      const page = document.getElementById('questionsPage');
      if (page) {
        page.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'end',
        });
      }

      const ele = document.getElementById('questionWrapperScroll');
      if (ele) {
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
    }
  }, [addQuestion, apiLoading]);

  useEffect(() => {
    if (typeof formFields?.fieldType === 'number') {
      setFormFields({
        ...formFields,
        fieldType: formFields?.fieldType === 1 ? 'Checkbox' : 'Radio',
      });
    }
  }, [formFields?.fieldType]);

  const handleNewQuestionChanges = (name, value) => {
    if (errObj[name]) setErrObj({ ...errObj, [name]: '' });
    if (name === 'points' && !numberRegex.test(value)) {
      setErrObj({ ...errObj, points: true });
    }
    setFormFields({ ...formFields, [name]: value });
  };

  const resetForm = () => {
    setFormFields({ ...initialState });
    setCheckBoxOptions([
      { value: '', id: 1, isSelected: false, error: '' },
      { value: '', id: 2, isSelected: false, error: '' },
    ]);
    setRadioOptions([
      { value: '', id: 1, isSelected: false, error: '' },
      { value: '', id: 2, isSelected: false, error: '' },
    ]);
  };
  const formSubmit = (addMore) => {
    const { name, fieldType, points } = formFields;
    const errObject = Validator.validate(quizQuestionsSchema, {
      name,
      fieldType,
      points,
    });
    let optionsError = false;
    let ansIndex = -1;

    const optionsArray = formFields.fieldType === 'Checkbox' ? checkBoxOptions : radioOptions;
    const validateOptions = [...optionsArray];
    optionsArray.map((item, index) => {
      if (item.value === '') {
        const optionVData = optionsArray[index];
        optionVData.error = 'Please enter option';
        optionsError = true;
      }
      if (item.isSelected === true) {
        ansIndex = index;
      }
    });
    formFields.fieldType === 'Checkbox'
      ? setCheckBoxOptions(validateOptions)
      : setRadioOptions(validateOptions);
    setErrObj(errObject);
    if (errObject || optionsError) {
      return dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    } else if (ansIndex === -1) {
      return dispatch(
        setNewMessage({
          message: 'Please select the right answer',
          type: 'error',
          show: true,
        })
      );
    }
    if (!errObject && !optionsError) {
      if (addMore === 'addMore')
        setLoaders({
          ...loaders,
          moreLoading: true,
        });
      else
        setLoaders({
          ...loaders,
          addLoading: true,
        });
      const ans = [];
      optionsArray.map((item) => {
        if (item?.isSelected) ans.push(item?.id);
      });
      const questionPayload = {
        name,
        points: Number(points),
        options: optionsArray,
        fieldType:
          formFields.fieldType === 'Checkbox' ? fieldTypeValues.Checkbox : fieldTypeValues.Radio,
        ans,
        isMandatory: formFields?.isMandatory,
        position: isEdit ? questionData.position : questionsLength,
      };
      if (isEdit) {
        const quizId = match?.params?.quizId;
        const editData = {
          data: {
            quizId,
            questionId: questionData?.id,
            question: questionPayload,
          },
        };
        handleEditQuestion(editData);
      } else {
        handleAddQuestion([questionPayload], addMore === 'addMore');
      }

      resetForm();
    }
  };

  const onAddOption = React.useCallback(
    (value) => {
      const addNewOptions = { value: '', isSelected: false };
      switch (value) {
        case 'checkbox':
          addNewOptions.id = checkBoxOptions.length + 1;
          setCheckBoxOptions([...checkBoxOptions, addNewOptions]);
          break;
        case 'radio':
          addNewOptions.id = radioOptions.length + 1;
          setRadioOptions([...radioOptions, addNewOptions]);
          break;
        default:
          break;
      }
    },
    [checkBoxOptions, formFields, radioOptions]
  );

  const renderOptions = () => {
    switch (formFields.fieldType) {
      case 'Checkbox': {
        return (
          <Grid item xs={12}>
            <OptionList
              label="Options"
              showBothIcon
              required
              isMultiple
              defaultOptionsLength={2}
              addOptionListAction
              error={errObj?.options}
              isMultiSelect
              onChange={(val, i) => {
                setErrObj({ ...errObj, blank_text: '' });
                const data = checkBoxOptions[i];
                data.value = val;
                data.error = '';
                setCheckBoxOptions([...checkBoxOptions]);
              }}
              addOptionBtnLabel="ADD OPTION"
              options={checkBoxOptions}
              placeholder="Enter option value"
              handleCheckBox={(i, selected) => {
                const dataValue = checkBoxOptions[i];
                dataValue.isSelected = !selected;
                setCheckBoxOptions([...checkBoxOptions]);
              }}
              deleteBtn={(i) => {
                const data = checkBoxOptions.filter((option, index) => index !== i);
                setCheckBoxOptions([...data]);
              }}
              addOption={() => onAddOption('checkbox')}
              clearBtn={() => {
                checkBoxOptions.map((item) => {
                  item.isSelected = false;
                  return 0;
                });
                setCheckBoxOptions([...checkBoxOptions]);
              }}
            />
          </Grid>
        );
      }
      case 'Radio': {
        return (
          <Grid item xs={12}>
            <OptionList
              label="Options"
              showBothIcon
              error={errObj?.options}
              addOptionListAction
              isMultiple
              required
              onChange={(valueData, i) => {
                setErrObj({ ...errObj, blank_text: '' });
                const data = radioOptions[i];
                data.value = valueData;
                data.error = '';
                setRadioOptions([...radioOptions]);
              }}
              addOptionBtnLabel="ADD OPTION"
              options={radioOptions}
              placeholder="Enter option value"
              checkBtn={(i, selected) => {
                const copyRadioOptions = radioOptions;
                copyRadioOptions.map((item, index) => {
                  if (index === i) {
                    item.isSelected = !item.isSelected;
                  } else {
                    item.isSelected = false;
                  }
                });
                setRadioOptions([...copyRadioOptions]);
              }}
              deleteBtn={(i) => {
                const data = radioOptions.filter((option, index) => index !== i);
                setRadioOptions([...data]);
              }}
              addOption={() => onAddOption('radio')}
              clearBtn={() => {
                radioOptions.map((item) => {
                  item.isSelected = false;
                  return 0;
                });
                setRadioOptions([...radioOptions]);
              }}
            />
          </Grid>
        );
      }
      default:
        break;
    }
  };

  return (
    <Box>
      <CardWrapper
        title={isEdit ? 'Edit Question' : 'Add Question'}
        isScrollable
        actionProps={{
          label: isEdit ? 'Save' : 'Save and Add more',
          loadingLabel: 'Saving',
          isLoading: loaders.moreLoading,
          onClick: () => formSubmit('addMore'),
          disabled: loaders.addLoading || loaders.moreLoading,
        }}
        addActionProps={
          isEdit
            ? null
            : {
                label: 'Add',
                loadingLabel: 'Adding',
                onClick: () => formSubmit('add'),
                isLoading: loaders.addLoading,
                disabled: loaders.addLoading || loaders.moreLoading,
              }
        }
        cancelProps={{
          label: 'Cancel',
          disabled: loaders.addLoading || loaders.moreLoading,
          onClick: () => {
            resetForm();
            handleEditCancle();
          },
        }}>
        {quizLoading ? (
          <QuestionsSkeletonLoader />
        ) : (
          <Grid container spacing={4} id="questionWrapperScroll">
            <Grid item xs={12}>
              <InputField
                label="Question"
                placeholder="Enter Question"
                type="text"
                name="name"
                maxLength={181}
                required
                value={formFields?.name}
                error={errObj?.name}
                onChange={(e) => handleNewQuestionChanges(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.radio}>
                <RadioBtn
                  required
                  label="Question Type"
                  options={[
                    { label: 'Checkbox', value: 'Checkbox' },
                    { label: 'Radio', value: 'Radio' },
                  ]}
                  name="fieldType"
                  value={formFields?.fieldType}
                  error={errObj?.fieldType}
                  handleChange={(e) => handleNewQuestionChanges(e.target.name, e.target.value)}
                />
              </Box>

              <Box mt={1}>
                <Checkbox2
                  name="isMandatory"
                  color="primary"
                  checked={formFields?.isMandatory}
                  label="Mandatory"
                  value={formFields?.isMandatory}
                  error={errObj?.isMandatory}
                  onChange={(e) => {
                    handleNewQuestionChanges(e.target.name, e.target.checked);
                  }}
                />
              </Box>
            </Grid>
            {renderOptions()}

            <Grid item xs={12}>
              <Box width="46%">
                <InputField
                  label="Points for correct answer"
                  placeholder="10"
                  type="number"
                  name="points"
                  minValue={0}
                  required
                  maxValue={99999}
                  value={formFields?.points}
                  error={errObj?.points}
                  onChange={(e) => {
                    if (Number(e.target.value) <= 99999)
                      handleNewQuestionChanges('points', e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </CardWrapper>
    </Box>
  );
};

export default AddEditQuestion;
