import React from 'react';

export default function SvgRussianFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#F0F0F0"
        />
        <path
          d="M19.378 13.4782C19.78 12.3949 20 11.2232 20 9.99999C20 8.77678 19.78 7.60506 19.378 6.52174H0.621992C0.220039 7.60506 0 8.77678 0 9.99999C0 11.2232 0.220039 12.3949 0.621992 13.4782L10 14.3478L19.378 13.4782Z"
          fill="#0052B4"
        />
        <path
          d="M10.0001 20C14.2997 20 17.9652 17.2863 19.3781 13.4783H0.62207C2.035 17.2863 5.70043 20 10.0001 20Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
