const REGISTRATION_FLOW_CONSTANTS_NON_WEBINAR = {
  SETUP_MODAL_TITLE: `Set up your event`,
  SETUP_MODAL_DESC_1:
    'Start setting up using our easy 5-step workflow, with videos and articles to support you along the way.',
  SETUP_MODAL_SUB_DESC_1: `Mark your steps complete as you make progress.`,
  SETUP_MODAL_DO_NOT_SHOW: `Don’t show this pop-up again`,
  TITLE_CONTINUE_TO_SETUP: 'Continue to set up your event',
  CONTINUE_TO_SETUP_DESC_1:
    'You have made good progress but you need to fill-in a few more details to set up your event.',
  OTHER_LP_DESC: `Build your landing page, and return to continue with your setup.`,
};

const REGISTRATION_FLOW_CONSTANTS_WEBINAR = {
  SETUP_MODAL_TITLE: `Set up your webinar`,
  SETUP_MODAL_DESC_1: 'Start setting up using our easy 5-step workflow, with videos and articles to support you along the way.',
  SETUP_MODAL_DESC_2: `5-step workflow, `,
  SETUP_MODAL_DESC_3: `with videos and articles to support you along the way.`,
  SETUP_MODAL_SUB_DESC_1: `Mark your steps complete as you make progress.`,
  SETUP_MODAL_SUB_DESC_2: ``,
  SETUP_MODAL_DO_NOT_SHOW: 'Don’t show this pop-up again',
  TITLE_CONTINUE_TO_SETUP: 'Continue to set up your webinar',
  CONTINUE_TO_SETUP_DESC_1:
    'You have made good progress but you need to fill-in a few more details to set up your webinar.',
  OTHER_LP_DESC: `Build your landing page, and return to continue with your setup.`,
};

const COMMON_CONSTANTS = {
  GET_STARTED: 'Get started',
  KEEP_GOING: 'Keep going',
  UPDATE: 'Update',
  INITIAL_SETUP_IS_DONE: 'Your initial setup is done',
  INITIAL_SETUP_DESC:
    'To update or make changes in your initial setup, you can always come back here.',
  LANDING_PAGE_HEADING: 'Build a landing page that makes your audience take notice',
  LANDING_PAGE_SUB_HEADING: `Select a landing page builder. It's the most powerful tool to increase registrations.`,
  LANDING_PAGE_STATUS_TEXT: `Use highly customizable templates that take no coding skills with Hubilo landing page.`,
  PERSONALIZE_LP: 'Personalize your landing page with Hubilo',
  PERSONALIZE_LP_DESC:
    'Our landing page brings a variety of user-friendly templates tuned to your every need.',
  NEED_MORE_HELP: 'Need more help?',
  GO_THROUGH_VIDEO_ARTICLES: 'Go through our support articles and video tutorials',
  PERSONALIZE_LP_ADD_TRACKING_PIXEL: 'Add tracking pixel',
  PERSONALIZE_LP_TRACKING_PIXEL_DESC:
    'Add tracking pixel to track visitor behaviour on your landing page and measure its performance.',
  PERSONALIZE_LP_ADD_UTM_CODE: 'Add UTM code',
  PERSONALIZE_LP_ADD_UTM_CODE_DESC:
    'Add UTM code to your landing page to track campaign performance and registration sources.',
};

const REGISTRATION_PAGE_CONSTANTS = {
  TITLE: {
    WEBINAR: 'Easy-to-complete registration forms increase sign-ups',
    NON_WEBINAR: 'Easy-to-complete ticketing and registration process increases sign-ups',
  },
  SUB_TITLE: {
    WEBINAR:
      "Choose a platform for your registration form. It's the first step of your attendee's journey, make it simple.",
    NON_WEBINAR:
      "Choose a platform for your registrations and ticketing. It's the first step of your attendee's journey, make it simple.",
  },
  BANNER: {
    WEBINAR: {
      HUBILO: 'Hubilo registration forms are easy-to-build with configurable fields and settings.',
      OTHER:
        'Hubilo registration forms are easy-to-build with configurable fields and settings. To use it, select Hubilo landing page in the first step.',
    },
    NON_WEBINAR: {
      HUBILO:
        'Hubilo ticketing supports multiple payment options. Our widget easily plugs in with your landing page.',
      OTHER:
        'Hubilo ticketing supports multiple payment options. Our widget easily plugs in with your landing page.',
    },
  },
  BUTTON_TEXT: {
    WEBINAR: 'Build Hubilo form',
    NON_WEBINAR: 'Go to ticketing',
  },
  STEP_TITLE: {
    WEBINAR: 'Build your registration form with Hubilo',
    NON_WEBINAR: 'Set up Hubilo registration and tickets',
  },
  OTHER_TEXT: {
    WEBINAR: 'You can use any third-party form that can be embedded using HTML code, like Typeform.',
    NON_WEBINAR:
      'Complete your registration and ticketing process, and return to continue with your setup.',
  },
  STEP_ONE_TITLE: {
    HUBSPOT: {
      WEBINAR: 'Create your custom form with HubSpot to capture registrations.',
      NON_WEBINAR: 'Create your custom form with HubSpot to capture registrations',
    },
    MARKETO: {
      WEBINAR:
        'To capture registrations, we recommend using your Marketo program form and Marketo landing page together.',
      NON_WEBINAR: 'Create your custom form with Marketo to capture registrations',
    },
    ELOQUA: {
      WEBINAR: 'Create your custom form with Eloqua to capture registrations.',
      NON_WEBINAR: 'Create your custom form with Eloqua to capture registrations',
    },
  },
  LEARN_MORE_TITLE: {
    WEBINAR: "You can use external forms only with Hubilo's custom landing page, and not the default landing page.",
    NON_WEBINAR:
      "You can use external forms only with Hubilo's custom landing page, and not the default landing page.",
  },
  // LEARN_MORE_URL: {
  //   WEBINAR:
  //     'https://support.hubilo.com/hc/en-us/articles/11370776067597-Setup-registration-form-for-the-webinar',
  //   NON_WEBINAR:
  //     'https://support.hubilo.com/hc/en-us/articles/9855273388301-How-to-create-new-tickets-',
  // },
};

const TOTAL_REGISTRATION_STEP = 5;

const INTEGRATIONS_CONSTANTS = {
  HUBSPOT: 1,
  MARKETO: 2,
  ELOQUA: 3,
  MSDYNAMICS: 4,
  SALESFORCE: 5,
  MAILCHIMP: 6,
};

const INTEGRATION_APP_IDS = {
  SALESFORCE: 1,
  HUBSPOT: 2,
  MARKETO: 5,
  MAILCHIMP: 6,
  MSDYNAMICS: 7,
  ELOQUA: 10,
};

const FLOW_STEP_CONST = {
  landingPage: 1,
  registration: 2,
  emailManager: 3,
  loginOptions: 4,
  syncData: 5,
};

const ACTIVE_TABS = {
  CRM: 0,
  MARKTETING_AUTOMATION: 1,
};

const STEP_STATUS_INDICATOR = {
  ACTION_NEEDED: 'Action Needed',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
};

const REG_FLOW_STEPS = [
  {
    label: 'Landing page',
    step: FLOW_STEP_CONST.landingPage,
    isVisited: true,
    isCompleted: false,
  },
  {
    label: 'Registration',
    isVisited: false,
    isCompleted: false,
    step: FLOW_STEP_CONST.registration,
  },
  {
    label: 'Email manager',
    isVisited: false,
    isComplete: false,
    step: FLOW_STEP_CONST.emailManager,
  },
  {
    label: 'Login options',
    isVisited: false,
    isComplete: false,
    step: FLOW_STEP_CONST.loginOptions,
  },
  {
    label: 'Sync data',
    isVisited: false,
    isComplete: false,
    step: FLOW_STEP_CONST.syncData,
  },
];

const SUPPORT_ARTICLES = {
  LANDING_PAGE: {
    PERSONALIZE: {
      WEBINAR: {
        VIDEO: {
          title: 'Set up your Hubilo Landing Page',
          time: '2 mins',
          thumbnail: 'video-thumbnails/landing-page-webinar.png',
          url: 'https://www.loom.com/embed/cd2b419f8d3c46afba2c589754afaebd?sid=aed1fe05-e330-4a9e-b4eb-90f07af8fb4f',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Overview - Event Landing Page',
            url: 'https://support.hubilo.com/hc/en-us/articles/9713263024141-Event-Landing-Page-Overview-',
          },
          {
            title: "Get started with Hubilo's default event landing page",
            url: 'https://support.hubilo.com/hc/en-us/articles/9857232895501-Landing-Page-Builder-Create-Your-Own-Event-',
          },
          {
            title: 'Create your own custom landing page using Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9857130139661-Event-Landing-Page-Default-Setup',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Set up your Hubilo landing page',
          time: '2 mins',
          thumbnail: 'video-thumbnails/landing-page-non-webinar.png',
          url: 'https://www.loom.com/embed/cd2b419f8d3c46afba2c589754afaebd?sid=aed1fe05-e330-4a9e-b4eb-90f07af8fb4f',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Overview - Event Landing Page',
            url: 'https://support.hubilo.com/hc/en-us/articles/9713263024141-Event-Landing-Page-Overview-',
          },
          {
            title: "Get started with Hubilo's default event landing page",
            url: 'https://support.hubilo.com/hc/en-us/articles/9857232895501-Landing-Page-Builder-Create-Your-Own-Event-',
          },
          {
            title: 'Create your own custom landing page using Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9857130139661-Event-Landing-Page-Default-Setup',
          },
        ],
      },
    },
    TRACKING_PIXEL: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to set up tracking pixel',
          urls: [
            'tracking-pixel-webinar/1.png',
            'tracking-pixel-webinar/2.png',
            'tracking-pixel-webinar/3.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Add tracking pixel to your Hubilo landing page',
            url: 'https://support.hubilo.com/hc/en-us/articles/9854531257229-Add-tracking-pixel-to-event-landing-page-ticketing-page-',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to set up tracking pixel',
          urls: ['tracking-pixel-non-webinar/1.png', 'tracking-pixel-non-webinar/2.png'],
        },
        ARTICLES: [
          {
            title: 'Add tracking pixel to your Hubilo landing page',
            url: 'https://support.hubilo.com/hc/en-us/articles/9854531257229-Add-tracking-pixel-to-event-landing-page-ticketing-page-',
          },
        ],
      },
    },
    UTM_CODE: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to set up UTM',
          urls: ['utm-webinar/1.png', 'utm-webinar/2.png', 'utm-webinar/3.png'],
        },
        ARTICLES: [
          {
            title: 'Add UTM code to your Hubilo landing page',
            url: 'https://support.hubilo.com/hc/en-us/articles/16150911072397-UTM-Tracking',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to set up UTM',
          urls: ['utm/1.png', 'utm/2.png', 'utm/3.png'],
        },
        ARTICLES: [
          {
            title: 'Add UTM code to your Hubilo landing page',
            url: 'https://support.hubilo.com/hc/en-us/articles/16150911072397-UTM-Tracking',
          },
        ],
      },
    },
  },
  REGISTRATION: {
    HUBILO_REGISTRATION_FORM: {
      VIDEO: null,
      IMAGE: {
        title: 'Steps to set up registration form',
        urls: [
          'hubilo-registration-setup/1.png',
          'hubilo-registration-setup/2.png',
          'hubilo-registration-setup/3.png',
        ],
      },
      ARTICLES: [
        {
          title: 'Setup registration form for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/11370776067597-Setup-registration-form-for-the-webinar',
        },
      ],
    },
    HUBILO_NON_WEBINAR: {
      FIRST_STEP: {
        VIDEO: {
          title: 'Set up tickets for your event',
          time: '3 mins',
          thumbnail: 'video-thumbnails/registration-hubilo-step1-non-webinar.png',
          url: 'https://www.loom.com/embed/2fac2d048a9f45dcb60ef397a5ff8819?sid=61d486ca-c52e-4d26-ac2e-b6d558b7735c',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Create first ticket for your event',
            url: 'https://support.hubilo.com/hc/en-us/articles/9855273388301-How-to-create-new-tickets-',
          },
          {
            title: 'Customize your event ticket(add/edit ticket details)',
            url: 'https://support.hubilo.com/hc/en-us/articles/9855374732813-What-are-Basic-Details-in-Add-Edit-Ticket-in-Hubilo-Event-',
          },
          {
            title: 'Explore various payment methods to setup for your event tickets',
            url: 'https://support.hubilo.com/hc/en-us/articles/9854742962189-Various-payment-methods-available-for-buying-tickets-for-events',
          },
          {
            title: 'Control the access of your events for different ticket holders',
            url: 'https://support.hubilo.com/hc/en-us/articles/9854886993549-How-to-associate-tickets-to-people-groups-',
          },
          {
            title: 'Connect your stripe account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9855170458893-Connect-your-Stripe-account-on-Hubilo-to-sell-paid-tickets-',
          },
        ],
      },
      SECOND_STEP: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to add custom groups in your event',
          urls: [
            'adding-more-groups/1.png',
            'adding-more-groups/2.png',
            'adding-more-groups/3.png',
            'adding-more-groups/4.png',
          ],
        },
        ARTICLES: [
          {
            title: 'How to add custom group and assign permissions to a group',
            url: 'https://support.hubilo.com/hc/en-us/articles/9933013691917-How-to-add-a-group-How-to-set-chat-and-meeting-permissions-for-a-group-',
          },
          {
            title: 'Hubilo default group and adding users to the default group',
            url: 'https://support.hubilo.com/hc/en-us/articles/9878579127821-What-are-the-default-groups-How-to-assign-people-to-these-groups-',
          },
        ],
      },
    },
    HUBSPOT_BUILD: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Hubspot form',
          urls: [
            'hubspot-build-form/1.png',
            'hubspot-build-form/2.png',
            'hubspot-build-form/3.png',
            'hubspot-build-form/4.png',
            'hubspot-build-form/5.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create forms in Hubspot',
            url: 'https://knowledge.hubspot.com/forms/create-forms',
          },
          {
            title: 'Customize and edit your Hubspot form fields',
            url: 'https://knowledge.hubspot.com/forms/edit-your-form-fields',
          },
          {
            title: 'Customize the layout of your Hubspot form fields',
            url: 'https://knowledge.hubspot.com/forms/customize-the-layout-of-your-form-fields',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Hubspot form',
          urls: [
            'hubspot-build-form/1.png',
            'hubspot-build-form/2.png',
            'hubspot-build-form/3.png',
            'hubspot-build-form/4.png',
            'hubspot-build-form/5.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create forms in Hubspot',
            url: 'https://knowledge.hubspot.com/forms/create-forms',
          },
          {
            title: 'Customize and edit your Hubspot form fields',
            url: 'https://knowledge.hubspot.com/forms/edit-your-form-fields',
          },
          {
            title: 'Customize the layout of your Hubspot form fields',
            url: 'https://knowledge.hubspot.com/forms/customize-the-layout-of-your-form-fields',
          },
        ],
      },
    },
    HUBSPOT_IMPORT: {
      WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Hubspot to Hubilo',
          time: '4 mins',
          thumbnail: 'video-thumbnails/registration-hubspot-webinar.png',
          url: 'https://www.loom.com/embed/ba0795cf0baa403cbbfcc60f16d26ae1?sid=56ec20d4-a94e-4355-a78c-b1a913d772e6',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Import your registrations from Hubspot to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917350661261-5-Import-data-from-HubSpot-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Hubspot to Hubilo',
          time: '4 mins',
          thumbnail: 'video-thumbnails/registration-hubspot-non-webinar.png',
          url: 'https://www.loom.com/embed/ba0795cf0baa403cbbfcc60f16d26ae1?sid=2f85f5c2-8d25-402c-a3df-6286876a6057',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Import your registrations from Hubspot to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917350661261-5-Import-data-from-HubSpot-to-Hubilo',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
        ],
      },
    },
    HUBSPOT_CREATE: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create workflow in Hubspot',
          urls: [
            'hubspot-create-workflow-webinar/1.png',
            'hubspot-create-workflow-webinar/2.png',
            'hubspot-create-workflow-webinar/3.png',
            'hubspot-create-workflow-webinar/4.png',
            'hubspot-create-workflow-webinar/5.png',
            'hubspot-create-workflow-webinar/6.png',
            'hubspot-create-workflow-webinar/7.png',
            'hubspot-create-workflow-webinar/8.png',
            'hubspot-create-workflow-webinar/9.png',
            'hubspot-create-workflow-webinar/10.png',
            'hubspot-create-workflow-webinar/11.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create Automation Workflows',
            url: 'https://knowledge.hubspot.com/workflows/create-workflows',
          },
          {
            title: 'What are triggers in workflow?',
            url: 'https://knowledge.hubspot.com/workflows/set-your-workflow-enrollment-triggers#contacts',
          },
          {
            title: 'What are actions(connected apps) in workflow?',
            url: 'https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#connected-apps',
          },
          {
            title: 'Importing data from Hubspot to Hubilo using workflow',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917350661261-5-Import-data-from-HubSpot-to-Hubilo',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create workflow in Hubspot',
          urls: [
            'hubspot-create-workflow-non-webinar/1.png',
            'hubspot-create-workflow-non-webinar/2.png',
            'hubspot-create-workflow-non-webinar/3.png',
            'hubspot-create-workflow-non-webinar/4.png',
            'hubspot-create-workflow-non-webinar/5.png',
            'hubspot-create-workflow-non-webinar/6.png',
            'hubspot-create-workflow-non-webinar/7.png',
            'hubspot-create-workflow-non-webinar/8.png',
            'hubspot-create-workflow-non-webinar/9.png',
            'hubspot-create-workflow-non-webinar/10.png',
            'hubspot-create-workflow-non-webinar/11.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create Automation Workflows',
            url: 'https://knowledge.hubspot.com/workflows/create-workflows',
          },
          {
            title: 'What are triggers in workflow?',
            url: 'https://knowledge.hubspot.com/workflows/set-your-workflow-enrollment-triggers#contacts',
          },
          {
            title: 'What are actions(connected apps) in workflow?',
            url: 'https://knowledge.hubspot.com/workflows/choose-your-workflow-actions#connected-apps',
          },
          {
            title: 'Importing data from Hubspot to Hubilo using workflow',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917350661261-5-Import-data-from-HubSpot-to-Hubilo',
          },
        ],
      },
    },
    HUBSPOT_EMBED: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to embed Hubspot form on Hubilo Custom Landing Page',
          urls: [
            'hubspot-embed-webinar/1.png',
            'hubspot-embed-webinar/2.png',
            'hubspot-embed-webinar/3.png',
            'hubspot-embed-webinar/4.png',
            'hubspot-embed-webinar/5.png',
            'hubspot-embed-webinar/6.png',
            'hubspot-embed-webinar/7.png',
            'hubspot-embed-webinar/8.png',
            'hubspot-embed-webinar/9.png',
            'hubspot-embed-webinar/10.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Getting embed code for your Hubspot form',
            url: 'https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17779401305357-Adding-an-external-form-Hubspot-Typeform-etc-to-the-LP',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to embed Hubspot form on Hubilo Custom Landing Page',
          urls: [
            'hubspot-embed-non-webinar/1.png',
            'hubspot-embed-non-webinar/2.png',
            'hubspot-embed-non-webinar/3.png',
            'hubspot-embed-non-webinar/4.png',
            'hubspot-embed-non-webinar/5.png',
            'hubspot-embed-non-webinar/6.png',
            'hubspot-embed-non-webinar/7.png',
            'hubspot-embed-non-webinar/8.png',
            'hubspot-embed-non-webinar/9.png',
            'hubspot-embed-non-webinar/10.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Getting embed code for your Hubspot form',
            url: 'https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17779401305357-Adding-an-external-form-Hubspot-Typeform-etc-to-the-LP',
          },
        ],
      },
    },
    MARKETO_BUILD: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Marketo form',
          urls: [
            'marketo-build-form/1.png',
            'marketo-build-form/2.png',
            'marketo-build-form/3.png',
            'marketo-build-form/4.png',
            'marketo-build-form/5.png',
            'marketo-build-form/6.png',
            'marketo-build-form/7.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create a Marketo Program form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/create-a-form.html?lang=en',
          },
          {
            title: 'Add fields to your Marketo form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/add-a-field-to-a-form.html?lang=en',
          },
          {
            title: 'Approve your Marketo form to use',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/approve-a-form.html?lang=en',
          },
          {
            title: 'Customize and edit your form fields on Marketo',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/edit-a-field-label-in-a-form.html?lang=en',
          },
          {
            title: 'Preview your Marketo Form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/preview-a-form.html?lang=en',
          },
          {
            title: 'Customize the theme of your Marketo Form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/select-a-form-theme.html?lang=en',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Marketo form',
          urls: [
            'marketo-build-form/1.png',
            'marketo-build-form/2.png',
            'marketo-build-form/3.png',
            'marketo-build-form/4.png',
            'marketo-build-form/5.png',
            'marketo-build-form/6.png',
            'marketo-build-form/7.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create a Marketo Program form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/create-a-form.html?lang=en',
          },
          {
            title: 'Add fields to your Marketo form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/add-a-field-to-a-form.html?lang=en',
          },
          {
            title: 'Approve your Marketo form to use',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/approve-a-form.html?lang=en',
          },
          {
            title: 'Customize and edit your form fields on Marketo',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/edit-a-field-label-in-a-form.html?lang=en',
          },
          {
            title: 'Preview your Marketo Form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/preview-a-form.html?lang=en',
          },
          {
            title: 'Customize the theme of your Marketo Form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/creating-a-form/select-a-form-theme.html?lang=en',
          },
        ],
      },
    },
    MARKETO_IMPORT: {
      WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Marketo Program to Hubilo',
          time: '7 mins',
          thumbnail: 'video-thumbnails/registration-marketo-import-webinar.png',
          url: 'https://www.loom.com/embed/061c9f8532ef4d9e92445ed9534e18e8?sid=a75826dc-edbe-4799-bb40-d3c6d7605691',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Marketo Program to Hubilo',
          time: '7 mins',
          thumbnail: 'video-thumbnails/registration-marketo-import-non-webinar.png',
          url: 'https://www.loom.com/embed/061c9f8532ef4d9e92445ed9534e18e8?sid=a75826dc-edbe-4799-bb40-d3c6d7605691',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
        ],
      },
    },
    MARKETO_EMBED: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to embed Eloqua form on Hubilo Custom Landing Page',
          urls: [
            'eloqua-embed-webinar/1.png',
            'eloqua-embed-webinar/2.png',
            'eloqua-embed-webinar/3.png',
            'eloqua-embed-webinar/4.png',
            'eloqua-embed-webinar/5.png',
            'eloqua-embed-webinar/6.png',
            'eloqua-embed-webinar/7.png',
            'eloqua-embed-webinar/8.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Getting embed code for your Marketo form',
            url: 'https://experienceleague.adobe.com/docs/marketo/using/product-docs/demand-generation/forms/form-actions/embed-a-form-on-your-website.html?lang=en',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17779401305357-Adding-an-external-form-Hubspot-Typeform-etc-to-the-LP',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to embed Eloqua form on Hubilo Custom Landing Page',
          urls: [
            'eloqua-embed-non-webinar/1.png',
            'eloqua-embed-non-webinar/2.png',
            'eloqua-embed-non-webinar/3.png',
            'eloqua-embed-non-webinar/4.png',
            'eloqua-embed-non-webinar/5.png',
            'eloqua-embed-non-webinar/6.png',
            'eloqua-embed-non-webinar/7.png',
            'eloqua-embed-non-webinar/8.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Getting embed code for your Marketo form',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
        ],
      },
    },
    ELOQUA_BUILD: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Eloqua form',
          urls: [
            'eloqua-build-form/1.png',
            'eloqua-build-form/2.png',
            'eloqua-build-form/3.png',
            'eloqua-build-form/4.png',
            'eloqua-build-form/5.png',
            'eloqua-build-form/6.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create a form on Eloqua',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/CreatingForms.htm',
          },
          {
            title: 'Customize your form style and configuration',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/StyleForm.htm',
          },
          {
            title: 'Customize contact field, form elements and field groups on Eloqua',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/FieldsElementsGroups.htm',
          },
          {
            title: 'View and Export your form submission data',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/ViewingAndExportingFormSubmissionData.htm',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: {
          title: 'Steps to create Eloqua form',
          urls: [
            'eloqua-build-form/1.png',
            'eloqua-build-form/2.png',
            'eloqua-build-form/3.png',
            'eloqua-build-form/4.png',
            'eloqua-build-form/5.png',
            'eloqua-build-form/6.png',
          ],
        },
        ARTICLES: [
          {
            title: 'Create a form on Eloqua',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/CreatingForms.htm',
          },
          {
            title: 'Customize your form style and configuration',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/StyleForm.htm',
          },
          {
            title: 'Customize contact field, form elements and field groups on Eloqua',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/FieldsElementsGroups.htm',
          },
          {
            title: 'View and Export your form submission data',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/ViewingAndExportingFormSubmissionData.htm',
          },
        ],
      },
    },
    ELOQUA_IMPORT: {
      WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Eloqua form to Hubilo',
          time: '5 mins',
          thumbnail: 'video-thumbnails/registration-eloqua-webinar.png',
          url: 'https://www.loom.com/embed/0245902599984e1aa353be007c260db4?sid=e8252ee1-15fb-4e35-b217-5fe244f42b05',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Import registrations from Eloqua form to Hubilo',
          time: '5 mins',
          thumbnail: 'video-thumbnails/registration-eloqua-non-webinar.png',
          url: 'https://www.loom.com/embed/0245902599984e1aa353be007c260db4?sid=e8252ee1-15fb-4e35-b217-5fe244f42b05',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
    },
    ELOQUA_EMBED: {
      WEBINAR: {
        VIDEO: null,
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Getting embed code for your Eloqua form',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/EmbeddingFormInWebPage.htm',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17779401305357-Adding-an-external-form-Hubspot-Typeform-etc-to-the-LP',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: null,
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Getting embed code for your Eloqua form',
            url: 'https://docs.oracle.com/en/cloud/saas/marketing/eloqua-user/Help/Forms/Tasks/EmbeddingFormInWebPage.htm',
          },
          {
            title: "Adding external form on Hubilo's Custom Landing Page",
            url: 'https://support.hubilo.com/hc/en-us/articles/17779401305357-Adding-an-external-form-Hubspot-Typeform-etc-to-the-LP',
          },
        ],
      },
    },
  },
  EMAIL_MANAGER: {
    HUBILO: {
      WEBINAR: {
        VIDEO: {
          title: 'Setup your emails using Hubilo email manager',
          time: '3 mins',
          thumbnail: 'video-thumbnails/email-hubilo-webinar.png',
          url: 'https://www.loom.com/embed/bd70569a319b497385c4c94d6f0e5ba8?sid=85a80118-5324-4fd6-81c1-d5b7723fefc7',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Create and manage all your mails at one place for your event',
            url: 'https://support.hubilo.com/hc/en-us/articles/9879735828493-Email-Marketing-Engine-Create-and-manage-all-your-event-mails-at-one-place',
          },
          {
            title: 'Create automated emails from Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9879734290189-Email-Manager-Automated-Mail',
          },
          {
            title: 'Create custom emails for your events',
            url: 'https://support.hubilo.com/hc/en-us/articles/9718852307725-Email-Manager-Custom-Mail',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Setup your emails using Hubilo email manager',
          time: '3 mins',
          thumbnail: 'video-thumbnails/email-hubilo-non-webinar.png',
          url: 'https://www.loom.com/embed/b95f691b6f224a60a455ca626d506951?sid=835ab35c-8a9d-4f61-bf5f-e52e83bc9b18',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Create and manage all your mails at one place for your event',
            url: 'https://support.hubilo.com/hc/en-us/articles/9879735828493-Email-Marketing-Engine-Create-and-manage-all-your-event-mails-at-one-place',
          },
          {
            title: 'Create automated emails from Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9879734290189-Email-Manager-Automated-Mail',
          },
          {
            title: 'Create custom emails for your events',
            url: 'https://support.hubilo.com/hc/en-us/articles/9718852307725-Email-Manager-Custom-Mail',
          },
        ],
      },
    },
    MARKETO: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your Attendee data from Hubilo to Marketo',
          time: '8 mins',
          thumbnail: 'video-thumbnails/email-marketo-webinar.png',
          url: 'https://www.loom.com/embed/5d8f733b5d1b4f39ba91497e630e40ce?sid=02f4c9b3-8f5f-4927-99b7-f7d2108c6da6',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
          {
            title:
              "Automatically update the program member status of your leads with Hubilo's attendance status",
            url: 'https://support.hubilo.com/hc/en-us/articles/17370420464781-4-Configure-Status-updates-and-Tokens',
          },
          {
            title: 'Export attendee intent activity to Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370587965581-5-Configure-Attendee-s-Activity-as-Custom-Activities',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your Attendee data from Hubilo to Marketo',
          time: '8 mins',
          thumbnail: 'video-thumbnails/email-marketo-non-webinar.png',
          url: 'https://www.loom.com/embed/5d8f733b5d1b4f39ba91497e630e40ce?sid=02f4c9b3-8f5f-4927-99b7-f7d2108c6da6',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
          {
            title:
              "Automatically update the program member status of your leads with Hubilo's attendance status",
            url: 'https://support.hubilo.com/hc/en-us/articles/17370420464781-4-Configure-Status-updates-and-Tokens',
          },
          {
            title: 'Export attendee intent activity to Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370587965581-5-Configure-Attendee-s-Activity-as-Custom-Activities',
          },
        ],
      },
    },
    HUBSPOT: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and more) to Hubspot',
          time: '4 mins',
          thumbnail: 'video-thumbnails/email-hubspot-webinar.png',
          url: 'https://www.loom.com/embed/35c3f2c6fbcc41c398348e8d1bd78a48?sid=c5c550a5-a16e-41fe-a5dd-7a925d74bf3b',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
          {
            title: "Export your attendee's intent and behaviour activity from Hubilo to Hubspot",
            url: 'https://support.hubilo.com/hc/en-us/articles/15916879946253-4-Export-User-Activities-from-Hubilo-to-HubSpot',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and more) to Hubspot',
          time: '4 mins',
          thumbnail: 'video-thumbnails/email-hubspot-non-webinar.png',
          url: 'https://www.loom.com/embed/35c3f2c6fbcc41c398348e8d1bd78a48?sid=c5c550a5-a16e-41fe-a5dd-7a925d74bf3b',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
          {
            title: "Export your attendee's intent and behaviour activity from Hubilo to Hubspot",
            url: 'https://support.hubilo.com/hc/en-us/articles/15916879946253-4-Export-User-Activities-from-Hubilo-to-HubSpot',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
        ],
      },
    },
    ELOQUA: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and other analytics) to Eloqua',
          time: '4 mins',
          thumbnail: 'video-thumbnails/email-eloqua-webinar.png',
          url: 'https://www.loom.com/embed/fec28da7c2c24a67ab90e8fdb4e37137?sid=5a5b1638-2947-4c7e-a36b-55c3a32c37a0',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and other analytics) to Eloqua',
          time: '4 mins',
          thumbnail: 'video-thumbnails/email-eloqua-webinar.png',
          url: 'https://www.loom.com/embed/fec28da7c2c24a67ab90e8fdb4e37137?sid=5a5b1638-2947-4c7e-a36b-55c3a32c37a0',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
    },
    MAILCHIMP: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to Mailchimp',
          time: '3 mins',
          thumbnail: 'video-thumbnails/email-mailchimp-webinar.png',
          url: 'https://www.loom.com/embed/f4239c19fb7043a48cc01f62afe769b7?sid=75192dae-f8eb-423a-b974-82b0cdacf138',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect Mailchimp with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932538144141-Integrate-Mailchimp-with-Hubilo',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to Mailchimp',
          time: '3 mins',
          thumbnail: 'video-thumbnails/email-mailchimp-non-webinar.png',
          url: 'https://www.loom.com/embed/35c3f2c6fbcc41c398348e8d1bd78a48?sid=fbf78372-ba05-471f-b517-172540dc5934',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect Mailchimp with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932538144141-Integrate-Mailchimp-with-Hubilo',
          },
        ],
      },
    },
  },
  LOGIN_OPTIONS: {
    WEBINAR: {
      VIDEO: null,
      IMAGE: {
        title: 'Steps to set up Login options',
        urls: ['login-options-webinar/1.png', 'login-options-webinar/2.png'],
      },
      ARTICLES: [
        {
          title: 'Explore Hubilo login options',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932943095437-Understand-your-event-app-s-Login-Settings',
        },
        {
          title: 'Setup login methods for restricted webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932873471757-How-to-set-up-log-in-process-for-a-restricted-event-',
        },
        {
          title: 'Setup Custom login code for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932874251021-How-to-set-up-a-custom-login-code-',
        },
        {
          title: 'Setup social media login for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932941594253-How-to-enable-social-media-login-',
        },
        {
          title: 'Setup SSO(Single-sign-on) for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932613719181-How-to-set-up-SSO-',
        },
        {
          title: 'Setup deafult login code for your attendees',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932950545677-What-is-the-default-login-code-',
        },
        {
          title: 'Add user consent for registrants signing up for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9933164390413-How-to-set-the-user-consent-for-first-time-users-while-Signup-Login-',
        },
      ],
    },
    NON_WEBINAR: {
      VIDEO: null,
      IMAGE: {
        title: 'Steps to set up Login options',
        urls: ['login-options-step/1.png', 'login-options-step/2.png', 'login-options-step/3.png'],
      },
      ARTICLES: [
        {
          title: 'Explore Hubilo login options',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932943095437-Understand-your-event-app-s-Login-Settings',
        },
        {
          title: 'Setup login methods for restricted webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932873471757-How-to-set-up-log-in-process-for-a-restricted-event-',
        },
        {
          title: 'Setup Custom login code for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932874251021-How-to-set-up-a-custom-login-code-',
        },
        {
          title: 'Setup social media login for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932941594253-How-to-enable-social-media-login-',
        },
        {
          title: 'Setup SSO(Single-sign-on) for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932613719181-How-to-set-up-SSO-',
        },
        {
          title: 'Setup deafult login code for your attendees',
          url: 'https://support.hubilo.com/hc/en-us/articles/9932950545677-What-is-the-default-login-code-',
        },
        {
          title: 'Add user consent for registrants signing up for your webinar',
          url: 'https://support.hubilo.com/hc/en-us/articles/9933164390413-How-to-set-the-user-consent-for-first-time-users-while-Signup-Login-',
        },
      ],
    },
  },
  SYNC: {
    MARKETO: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your Attendee data from Hubilo to Marketo',
          time: '8 mins',
          thumbnail: 'video-thumbnails/sync-marketo-webinar.png',
          url: 'https://www.loom.com/embed/5d8f733b5d1b4f39ba91497e630e40ce?sid=02f4c9b3-8f5f-4927-99b7-f7d2108c6da6',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
          {
            title:
              "Automatically update the program member status of your leads with Hubilo's attendance status",
            url: 'https://support.hubilo.com/hc/en-us/articles/17370420464781-4-Configure-Status-updates-and-Tokens',
          },
          {
            title: 'Export attendee intent activity to Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370587965581-5-Configure-Attendee-s-Activity-as-Custom-Activities',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your Attendee data from Hubilo to Marketo',
          time: '8 mins',
          thumbnail: 'video-thumbnails/sync-marketo-non-webinar.png',
          url: 'https://www.loom.com/embed/5d8f733b5d1b4f39ba91497e630e40ce?sid=02f4c9b3-8f5f-4927-99b7-f7d2108c6da6',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Setting up Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
          },
          {
            title: 'Connecting Marketo Program with Hubilo event',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
          },
          {
            title: 'Import your registrations from Marketo to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
          },
          {
            title:
              "Automatically update the program member status of your leads with Hubilo's attendance status",
            url: 'https://support.hubilo.com/hc/en-us/articles/17370420464781-4-Configure-Status-updates-and-Tokens',
          },
          {
            title: 'Export attendee intent activity to Marketo',
            url: 'https://support.hubilo.com/hc/en-us/articles/17370587965581-5-Configure-Attendee-s-Activity-as-Custom-Activities',
          },
        ],
      },
    },
    HUBSPOT: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data and activity to Hubspot',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-hubspot-webinar.png',
          url: 'https://www.loom.com/embed/35c3f2c6fbcc41c398348e8d1bd78a48?sid=c5c550a5-a16e-41fe-a5dd-7a925d74bf3b',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
          {
            title: "Export your attendee's intent and behaviour activity from Hubilo to Hubspot",
            url: 'https://support.hubilo.com/hc/en-us/articles/15916879946253-4-Export-User-Activities-from-Hubilo-to-HubSpot',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data and activity to Hubspot',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-hubspot-non-webinar.png',
          url: 'https://www.loom.com/embed/35c3f2c6fbcc41c398348e8d1bd78a48?sid=1ea28933-5656-4ac8-94ff-4f4087b8c9d2',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Hubspot account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
          },
          {
            title: 'Export your attendee data from Hubilo to Hubspot',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
          },
          {
            title: "Export your attendee's intent and behaviour activity from Hubilo to Hubspot",
            url: 'https://support.hubilo.com/hc/en-us/articles/15916879946253-4-Export-User-Activities-from-Hubilo-to-HubSpot',
          },
          {
            title: 'Create a new property in your Hubspot Contact',
            url: 'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
          },
        ],
      },
    },
    ELOQUA: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and other analytics) to Eloqua',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-eloqua-webinar.png',
          url: 'https://www.loom.com/embed/fec28da7c2c24a67ab90e8fdb4e37137?sid=5a5b1638-2947-4c7e-a36b-55c3a32c37a0',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data(magic link and other analytics) to Eloqua',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-eloqua-non-webinar.png',
          url: 'https://www.loom.com/embed/fec28da7c2c24a67ab90e8fdb4e37137?sid=5a5b1638-2947-4c7e-a36b-55c3a32c37a0',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Import your registrations from Eloqua form to Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932543194509-Import-data-from-Eloqua-Form-to-Hubilo',
          },
          {
            title: 'Export your attendee data from Hubilo to Eloqua',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932544053389-Export-data-from-Hubilo-to-Eloqua-',
          },
        ],
      },
    },
    SALESFORCE: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to Salesforce',
          time: '8 mins',
          thumbnail: 'video-thumbnails/sync-salesforce-webinar.png',
          url: 'https://www.loom.com/embed/79427df57d70475c94fb7c5a9a547935?sid=c9f7686e-a52a-494f-9f51-2fe2d79d7717',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Salesforce account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131694553741-How-to-connect-Salesforce-with-Hubilo',
          },
          {
            title: 'Setup and enable Salesforce Integration for your webinar',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131783973773-How-to-set-up-and-enable-the-Salesforce-Integration-for-your-Hubilo-event-',
          },
          {
            title: 'Connect Salesforce campaign with your Hubilo webinar',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131845336717-How-to-connect-Salesforce-Campaign-with-your-Hubilo-event',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to Salesforce ',
          time: '8 mins',
          thumbnail: 'video-thumbnails/sync-salesforce-non-webinar.png',
          url: 'https://www.loom.com/embed/79427df57d70475c94fb7c5a9a547935?sid=c9f7686e-a52a-494f-9f51-2fe2d79d7717',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Connect your Salesforce account with Hubilo',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131694553741-How-to-connect-Salesforce-with-Hubilo',
          },
          {
            title: 'Setup and enable Salesforce Integration for your webinar',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131783973773-How-to-set-up-and-enable-the-Salesforce-Integration-for-your-Hubilo-event-',
          },
          {
            title: 'Connect Salesforce campaign with your Hubilo webinar',
            url: 'https://support.hubilo.com/hc/en-us/articles/16131845336717-How-to-connect-Salesforce-Campaign-with-your-Hubilo-event',
          },
        ],
      },
    },
    MSDYNAMICS: {
      WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to MS Dynamics',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-ms-dynamics-webinar.png',
          url: 'https://www.loom.com/embed/64dbc2476a9e4d33910cc4bade650411?sid=8cdbca27-c416-46b5-9efb-cc12317f9699',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'Export your registrations data from Hubilo to MS Dynamics',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932568954765-Export-data-from-Hubilo-to-Microsoft-Dynamics-365',
          },
        ],
      },
      NON_WEBINAR: {
        VIDEO: {
          title: 'Export your attendee data to MS Dynamics 365',
          time: '4 mins',
          thumbnail: 'video-thumbnails/sync-ms-dynamics-non-webinar.png',
          url: 'https://www.loom.com/embed/64dbc2476a9e4d33910cc4bade650411?sid=8cdbca27-c416-46b5-9efb-cc12317f9699',
        },
        IMAGE: null,
        ARTICLES: [
          {
            title: 'How to connect your MS dynamics with Hubilo?',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932568954765-Export-data-from-Hubilo-to-Microsoft-Dynamics-365',
          },
          {
            title: 'How to link your MS Dynamics Events with Hubilo webinar/events?',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932568954765-Export-data-from-Hubilo-to-Microsoft-Dynamics-365',
          },
          {
            title: 'Configure your Dynamics Leads and Contacts fieldHow to export Attendees to MS Dynamics?',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932568954765-Export-data-from-Hubilo-to-Microsoft-Dynamics-365',
          },
          {
            title: 'How to export Attendees to MS Dynamics?',
            url: 'https://support.hubilo.com/hc/en-us/articles/9932568954765-Export-data-from-Hubilo-to-Microsoft-Dynamics-365',
          },
        ],
      },
    },
  },
};

const CONFIGURATION_STEPS = {
  REGISTRATION: {
    HUBILO: {
      HUBILO_LP: [
        `Create a paid or free ticket`,
        `Set up the buyer and attendee form`,
        `You can also choose to set up a simplified registration process for your attendees`,
        `Copy the code to embed the Ticketing/Registration widget & paste the code on your landing page to start collecting registrations`,
      ],
      OTHER_LP: [
        `Create a paid or free ticket`,
        `Set up the buyer and attendee form`,
        `You can also choose to set up a simplified registration process for your attendees`,
        `Copy the code to embed the Ticketing/Registration widget`,
        `Paste the code on your landing page to start collecting registrations`,
      ],
    },
    HUBSPOT: {
      STEP_ONE: [
        `Create your custom form with HubSpot to capture registrations`,
        `Get the HTML embed code for your form`,
      ],
      EMBED: [
        `Go to your Event > Landing Page`,
        `Create or edit your custom landing page`,
        `Click the widget icon (+) on the left menu panel and drag and drop the HTML widget where you want to embed your form.`,
        `Paste the HTML embed code of your registration form`,
        `Preview and see the final look of your form on the Hubilo landing Page`
      ],
      LOGGED_IN: [
        `Configure the HubSpot fields to import`,
        `Export the magic link back to your HubSpot`,
        `Automate your registration data to Hubilo`,
      ],
      NOT_LOGGED_IN: [
        `Connect your HubSpot account`,
        `Configure the HubSpot fields to import`,
        `Export the magic link back to your HubSpot`,
        'Automate your registration data to Hubilo',
      ],
    },
    MARKETO: {
      STEP_ONE: [
        `Create your custom form with Marketo to capture registrations`,
        `Get the HTML embed code for your form`,
      ],
      EMBED: [
        `Go to your Event > Landing Page`,
        `Create or edit your custom landing page`,
        `Click the widget icon (+) on the left menu panel and drag and drop the HTML widget where you want to embed your form.`,
        `Paste the HTML embed code of your registration form`,
        `Preview and see the final look of your form on the Hubilo landing Page`
      ],
      LOGGED_IN: [
        `Configure the Marketo fields to import`,
        `Export the magic link back to your Marketo`,
        `Automate your registration data to Hubilo`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Marketo account`,
        `Configure the Marketo fields to import`,
        `Export the magic link back to your Marketo`,
        'Automate your registration data to Hubilo',
      ],
    },
    ELOQUA: {
      STEP_ONE: [
        `Create your custom form with Eloqua to capture registrations`,
        `Get the HTML embed code for your form`,
      ],
      EMBED: [
        `Go to your Event > Landing Page`,
        `Create or edit your custom landing page`,
        `Click the widget icon (+) on the left menu panel and drag and drop the HTML widget where you want to embed your form.`,
        `Paste the HTML embed code of your registration form`,
        `Preview and see the final look of your form on the Hubilo landing Page`
      ],
      LOGGED_IN: [
        `Sync your Hubilo and Eloqua fields by mapping them on your Eloqua form`,
        `Automate your registration data to Hubilo through Eloqua using the webhook URL`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Eloqua account`,
        `Sync your Hubilo and Eloqua fields by mapping them on your Eloqua form`,
        `Automate your registration data to Hubilo through Eloqua using the webhook URL`,
      ],
    },
  },
  EMAIL_MANAGER: {
    HUBSPOT: {
      LOGGED_IN: [
        `Confirm your magic link field selection for HubSpot export is set up`,
        `Enable data sync`,
        `Use the Hubilo magic link to create tailored emails for your attendees`,
      ],
      NOT_LOGGED_IN: [
        `Connect your HubSpot account`,
        `Confirm your magic link field selection for HubSpot export is set up`,
        `Enable data sync`,
        'Use the Hubilo magic link to create tailored emails for your attendees',
      ],
    },
    MARKETO: {
      LOGGED_IN: [
        `Hubilo will sync the magic link for each attendee in the 'Webinar URL' field of the Program Member`,
        `Configure your tokens to help you customize email templates`,
        `Enable data sync`,
        `Use the Hubilo magic link and tokens to create tailored emails for your attendees`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Marketo account`,
        `Hubilo will sync the magic link for each attendee in the 'Webinar URL' field of the Program Member`,
        `Configure your tokens to help you customize email templates`,
        'Enable data sync',
        `Use the Hubilo magic link and tokens to create tailored emails for your attendees`,
      ],
    },
    ELOQUA: {
      LOGGED_IN: [
        `Confirm your magic link field selection for Eloqua export is set up`,
        `Use the Hubilo magic link to create tailored emails for your attendees`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Eloqua account`,
        `Confirm your magic link field selection for Eloqua export is set up`,
        `Use the Hubilo magic link to create tailored emails for your attendees`,
      ],
    },
    MAILCHIMP: {
      LOGGED_IN: [
        `Choose the audience list to which you want to export your registrations`,
        `Set up your Mailchimp field mapping with Hubilo fields`,
        `Create tailored emails to your attendees through Mailchimp`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Mailchimp account`,
        `Choose the audience list to which you want to export your registrations`,
        `Set up your Mailchimp field mapping with Hubilo fields`,
        `Create tailored emails to your attendees through Mailchimp`,
      ],
    },
  },
  SYNC: {
    HUBSPOT: {
      LOGGED_IN: [
        `Configure your Hubilo fields for export to HubSpot`,
        `Set up attendee activities, such as logins, session views, and poll submissions, to export to your HubSpot activity`,
        `Enable data sync and Hubilo will automatically start enriching your HubSpot data`,
      ],
      NOT_LOGGED_IN: [
        `Connect your HubSpot account`,
        `Configure your Hubilo fields for export to HubSpot`,
        `Set up attendee activities, such as logins, session views, and poll submissions, to export to your HubSpot activity`,
        'Enable data sync and Hubilo will automatically start enriching your HubSpot data',
      ],
    },
    MARKETO: {
      LOGGED_IN: [
        `Connect to your Hubilo event by configuring a Marketo Program`,
        `Configure the Hubilo fields to import registrations from Marketo program`,
        `Set up attendee activities, such as logins, session views, and poll submissions, to export as your Marketo custom activity`,
        `Enable data sync and Hubilo will automatically start enriching your attendee activity data in Marketo`,
      ],
      NOT_LOGGED_IN: [
        `Connect to your Hubilo event by configuring a Marketo Program`,
        `Configure the Hubilo fields to import registrations from Marketo program`,
        'Set up attendee activities, such as logins, session views, and poll submissions, to export as your Marketo custom activity',
        `Enable data sync and Hubilo will automatically start enriching your attendee activity data in Marketo`,
      ],
    },
    ELOQUA: {
      LOGGED_IN: [
        `Configure your Hubilo fields for export to Eloqua`,
        `Send your session attendance analytics to a custom object`,
        `Hubilo will automatically sync your data to Eloqua`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Eloqua account`,
        `Configure your Hubilo fields for export to Eloqua`,
        `Send your session attendance analytics to a custom object`,
        `Hubilo will automatically sync your data to Eloqua`,
      ],
    },
    SALESFORCE: {
      LOGGED_IN: [
        `Configure your Leads and Contacts fields`,
        `Link your Salesforce campaign with your Hubilo event`,
        `Set up attendee activities, such as logins, session views, and poll submissions, to export to your Salesforce tasks`,
        `Enable data sync and Hubilo will automatically start creating and enriching your Salesforce Leads/Contacts`,
      ],
      NOT_LOGGED_IN: [
        `Connect your Salesforce account and configure your Leads and Contacts fields`,
        `Link your Salesforce campaign with your Hubilo event`,
        'Set up attendee activities, such as logins, session views, and poll submissions, to export to your Salesforce tasks',
        `Enable data sync and Hubilo will automatically start creating and enriching your Salesforce Leads/Contacts`,
      ],
    },
    MSDYNAMICS: {
      LOGGED_IN: [
        `Connect your MS Dynamics (Marketing or Sales) account.`,
        `Link you MS Dynamics event with your webinar/event`,
        `Configure your Dynamics Leads and Contacts field`,
        `Select attendee activities, such as logins, session views, and poll submissions, to export to your MS Dynamics contact activities`,
        `Enable data sync to automatically sync your registrations with MS dynamics`,
      ],
      NOT_LOGGED_IN: [
        `Connect your MS Dynamics account`,
        `Select the entity where you want to sync your data - Leads, Contacts, or a custom entity`,
        `Configure your Hubilo fields for export to MS Dynamics`,
        'Hubilo will automatically sync your data to MS Dynamics',
      ],
    },
  },
};

const WORKFLOW_REDIRECTION_URLS = {
  REGISTRATION: {
    HUBILO: {
      SETUP_FORM: 'registration/setup-form/form-fields',
      TICKETING: 'registration/ticket',
    },
    MARKETO_BUILD: {
      LOGGED_IN: '$https://login.marketo.com/',
      NOT_LOGGED_IN: `$https://login.marketo.com/`,
    },
    MARKETO_EMBED: {
      LOGGED_IN: 'registration/landing-page',
      NOT_LOGGED_IN: 'registration/landing-page',
    },
    MARKETO_IMPORT: {
      LOGGED_IN: 'integrations/connected-apps/Marketo/5?type=connectProgram',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MARKETO}`,
    },
    ELOQUA_BUILD: {
      LOGGED_IN: '$https://login.eloqua.com',
      NOT_LOGGED_IN: '$https://login.eloqua.com',
    },
    ELOQUA_EMBED: {
      LOGGED_IN: 'registration/landing-page',
      NOT_LOGGED_IN: 'registration/landing-page',
    },
    ELOQUA_IMPORT: {
      LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
    },
    HUBSPOT_BUILD: {
      LOGGED_IN: '$https://app.hubspot.com/login',
      NOT_LOGGED_IN: '$https://app.hubspot.com/login',
    },
    HUBSPOT_EMBED: {
      LOGGED_IN: 'registration/landing-page',
      NOT_LOGGED_IN: 'registration/landing-page',
    },
    HUBSPOT_IMPORT: {
      LOGGED_IN: 'integrations/connected-apps/HubSpot/2?type=Import',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.HUBSPOT}`,
    },
    HUBSPOT_CREATE: {
      LOGGED_IN: '$https://app.hubspot.com/login',
      NOT_LOGGED_IN: '$https://app.hubspot.com/login',
    },
  },
  EMAIL_MANAGER: {
    HUBILO: 'email-builder',
    MARKETO: {
      LOGGED_IN: 'integrations/connected-apps/Marketo/5?type=connectProgram',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MARKETO}`,
    },
    ELOQUA: {
      LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
    },
    HUBSPOT: {
      LOGGED_IN: 'integrations/connected-apps/HubSpot/2?type=Export',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.HUBSPOT}`,
    },
    MAILCHIMP: {
      LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MAILCHIMP}`,
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MAILCHIMP}`,
    },
  },
  LOGIN_OPTIONS: {
    SETUP_FORM: 'settings/login-options',
  },
  SYNC: {
    MARKETO: {
      LOGGED_IN: 'integrations/connected-apps/Marketo/5?type=connectProgram',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MARKETO}`,
    },
    ELOQUA: {
      LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.ELOQUA}`,
    },
    HUBSPOT: {
      LOGGED_IN: 'integrations/connected-apps/HubSpot/2?type=Export',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.HUBSPOT}`,
    },
    SALESFORCE: {
      LOGGED_IN: 'integrations/connected-apps/Salesforce/1?type=Export',
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.SALESFORCE}`,
    },
    MSDYNAMICS: {
      LOGGED_IN: `integrations/connected-apps/Microsoft%20Dynamics%20365/7?type=0`,
      NOT_LOGGED_IN: `@/integrations?appId=${INTEGRATION_APP_IDS.MSDYNAMICS}`,
    },
  },
};

const COMMON_REGISTRATION_OPTIONS = [
  { name: 'HubSpot', value: 'HubSpot', label: 'HubSpot' },
  { name: 'Marketo', value: 'Marketo', label: 'Marketo' },
  { name: 'Eloqua', value: 'Eloqua', label: 'Eloqua' },
  { name: 'Other', value: 'Other', label: 'Other' },
];

const WEBINAR_REGISTRATION_OPTIONS_HUBILO = [
  { name: 'Hubilo Registration Widget', value: 'Hubilo', label: 'Hubilo registration form', tag: 'Recommended' },
  ...COMMON_REGISTRATION_OPTIONS,
];
const WEBINAR_REGISTRATION_OPTIONS_OTHER = [
  {
    name: 'Hubilo Registration Widget',
    value: 'Hubilo',
    label: 'Hubilo Registration Widget',
    disabled: true,
    tag: 'Coming soon',
  },
  ...COMMON_REGISTRATION_OPTIONS,
];
const NON_WEBINAR_REGISTRATION_OPTIONS_HUBILO = [
  {
    name: 'Hubilo Registration Widget',
    value: 'Hubilo',
    label: 'Hubilo ticketing & registrations',
  },
  ...COMMON_REGISTRATION_OPTIONS,
];
const NON_WEBINAR_REGISTRATION_OPTIONS_OTHER = [
  {
    name: 'Hubilo Registration Widget',
    value: 'Hubilo',
    label: 'Hubilo ticketing & registrations',
  },
  ...COMMON_REGISTRATION_OPTIONS,
];

const COMMON_EMAIL_MANAGER_OPTIONS = [
  { name: 'HubSpot', value: 'HubSpot', label: 'HubSpot' },
  { name: 'Marketo', value: 'Marketo', label: 'Marketo' },
  { name: 'Eloqua', value: 'Eloqua', label: 'Eloqua' },
  { name: 'Mailchimp', value: 'Mailchimp', label: 'Mailchimp' },
  { name: 'Other', value: 'Other', label: 'Other' },
];

const EMAIL_MANAGER_OPTIONS_HUBILO = [
  {
    name: 'Hubilo Email Manager',
    value: 'Hubilo',
    label: 'Hubilo email manager',
    tag: 'Recommended',
  },
  ...COMMON_EMAIL_MANAGER_OPTIONS,
];

const EMAIL_MANAGER_OPTIONS_OTHER = [
  { name: 'Hubilo Email Manager', value: 'Hubilo', label: 'Hubilo email manager' },
  ...COMMON_EMAIL_MANAGER_OPTIONS,
];

const SYNC_DATA_CRM = [
  { name: 'Salesforce', value: 'Salesforce', label: 'Salesforce' },
  { name: 'HubSpot', value: 'HubSpot', label: 'HubSpot' },
  { name: 'MS Dynamics', value: 'MSDynamics', label: 'MS Dynamics' },
  { name: 'Other', value: 'Other', label: 'Other' },
];

const SYNC_DATA_MARKETING_AUTOMATION = [
  { name: 'Marketo', value: 'Marketo', label: 'Marketo' },
  { name: 'HubSpot', value: 'HubSpot', label: 'HubSpot' },
  { name: 'Eloqua', value: 'Eloqua', label: 'Eloqua' },
  { name: 'Other', value: 'Other', label: 'Other' },
];

const STATIC_ICONS = {
  infoMsg: 'static-images/infoMsg.png',
  partyIcon: 'static-images/party-icon.png',
  webinarHybrid: 'static-images/webinar-hybrid.png',
  webinarInperson: 'static-images/webinar-inperson.png',
  webinarRegular: 'static-images/webinar-regular.png',
  webinarVirtual: 'static-images/webinar-virtual.png',
};

export {
  REGISTRATION_FLOW_CONSTANTS_NON_WEBINAR,
  REGISTRATION_FLOW_CONSTANTS_WEBINAR,
  TOTAL_REGISTRATION_STEP,
  COMMON_CONSTANTS,
  REG_FLOW_STEPS,
  FLOW_STEP_CONST,
  INTEGRATIONS_CONSTANTS,
  STEP_STATUS_INDICATOR,
  SUPPORT_ARTICLES,
  WEBINAR_REGISTRATION_OPTIONS_HUBILO,
  WEBINAR_REGISTRATION_OPTIONS_OTHER,
  NON_WEBINAR_REGISTRATION_OPTIONS_HUBILO,
  NON_WEBINAR_REGISTRATION_OPTIONS_OTHER,
  CONFIGURATION_STEPS,
  WORKFLOW_REDIRECTION_URLS,
  EMAIL_MANAGER_OPTIONS_HUBILO,
  EMAIL_MANAGER_OPTIONS_OTHER,
  SYNC_DATA_CRM,
  SYNC_DATA_MARKETING_AUTOMATION,
  ACTIVE_TABS,
  INTEGRATION_APP_IDS,
  REGISTRATION_PAGE_CONSTANTS,
  STATIC_ICONS,
};
