import {
    EMAIL_ACTIVATE,
    EMAIL_DEACTIVATE,
} from 'redux/actions/email-deactivate-action';

const initState = {
    isOpen: false,
    status: '',
    id: '',
    callback: null,
};


const CampaignStatus = (state = { ...initState }, action) => {
    switch (action.type) {
        case EMAIL_ACTIVATE:
            return { ...state, ...action.data, isOpen: true };
        case EMAIL_DEACTIVATE:
            return { ...state, ...initState };
        default:
            break;
    }
    return state;
};

export default CampaignStatus;