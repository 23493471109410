import React from 'react';

const style = {
  a: {
    fill: '#c1c1c1',
    stroke: '#c1c1c1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  },
};

function SvgNoRecordingsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="370" height="308" viewBox="0 0 368 304">
      <g clipPath="url(#clip0_135_34451)">
        <path
          d="M322.477 116.653C319.917 83.2512 297.459 55.1296 269.542 38.2976C242.313 21.9856 210.499 15.0186 178.944 18.4576C135.648 23.3216 77.9585 51.0592 80.9601 102.157C81.5041 111.398 84.1089 120.634 82.7521 129.792C80.3073 146.304 66.0481 158.042 56.5441 171.77C46.4469 186.431 41.5055 204.031 42.4961 221.805C43.6609 241.53 53.9009 262.074 72.2433 269.44C90.5857 276.806 111.386 269.491 131.194 270.221C163.29 271.411 190.976 293.638 222.611 299.168C246.237 303.174 270.489 297.69 290.093 283.91C299.117 277.51 307.091 269.19 311.264 258.918C321.767 233.107 305.991 203.731 310.144 176.173C313.005 157.222 323.162 140.672 322.694 121.056C322.656 119.58 322.584 118.112 322.477 116.653Z"
          fill="#E0E0E0"
        />
        <path
          opacity="0.7"
          d="M322.477 116.653C319.917 83.2512 297.459 55.1296 269.542 38.2976C242.313 21.9856 210.499 15.0186 178.944 18.4576C135.648 23.3216 77.9585 51.0592 80.9601 102.157C81.5041 111.398 84.1089 120.634 82.7521 129.792C80.3073 146.304 66.0481 158.042 56.5441 171.77C46.4469 186.431 41.5055 204.031 42.4961 221.805C43.6609 241.53 53.9009 262.074 72.2433 269.44C90.5857 276.806 111.386 269.491 131.194 270.221C163.29 271.411 190.976 293.638 222.611 299.168C246.237 303.174 270.489 297.69 290.093 283.91C299.117 277.51 307.091 269.19 311.264 258.918C321.767 233.107 305.991 203.731 310.144 176.173C313.005 157.222 323.162 140.672 322.694 121.056C322.656 119.58 322.584 118.112 322.477 116.653Z"
          fill="white"
        />
        <path
          d="M321.229 297.6H329.28"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.8398 297.6H316.665"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.5459 184.768L60.4419 270.048"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M59.0081 253.728L54.9761 249.466"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M56.1986 250.739C56.1903 250.088 56.0426 249.446 55.7656 248.856C55.4885 248.267 55.0884 247.744 54.5922 247.322C53.5618 246.394 48.109 244.122 47.5906 246.042C47.0402 248.109 52.6082 252.211 56.1986 250.739Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M56.1986 250.739L51.0146 247.507"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M60.0193 263.424L55.9873 259.162"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M57.2096 260.442C57.2017 259.789 57.0543 259.146 56.7772 258.556C56.5001 257.965 56.0998 257.441 55.6032 257.018C54.5728 256.096 49.12 253.818 48.6016 255.738C48.0384 257.805 53.6192 261.907 57.2096 260.442Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M57.2099 260.442L52.0259 257.21"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M56.5379 230.061L52.5059 225.799"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M53.7281 227.079C53.7203 226.426 53.5729 225.783 53.2958 225.193C53.0187 224.602 52.6184 224.078 52.1217 223.655C51.0913 222.727 45.6385 220.455 45.1201 222.375C44.5569 224.442 50.1377 228.544 53.7281 227.079Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M53.7284 227.078L48.5444 223.84"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M57.5491 239.757L53.5171 235.501"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M54.7205 236.8C54.7143 236.148 54.5682 235.505 54.2922 234.914C54.0162 234.324 53.6166 233.799 53.1205 233.376C52.0837 232.454 46.6309 230.176 46.0805 232.096C45.5749 234.144 51.1493 238.24 54.7205 236.8Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M54.7397 236.774L49.5557 233.542"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M54.8928 214.304L50.8608 210.048"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M52.0835 211.322C52.0757 210.67 51.9289 210.027 51.653 209.437C51.3771 208.847 50.9784 208.322 50.4835 207.898C49.4467 206.976 43.9939 204.698 43.4435 206.618C42.9123 208.691 48.4931 212.787 52.0835 211.322Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M52.0834 211.322L46.8994 208.09"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M53.242 198.483L49.21 194.221"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M50.4322 195.501C50.4248 194.849 50.2781 194.207 50.0022 193.616C49.7263 193.026 49.3274 192.501 48.8322 192.077C47.7954 191.155 42.3426 188.877 41.8242 190.797C41.2802 192.864 46.8418 196.967 50.4322 195.501Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M50.432 195.501L45.248 192.269"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M58.3296 247.987L61.3952 242.989"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M60.48 244.48C60.354 243.841 60.3659 243.182 60.5149 242.547C60.6639 241.912 60.9465 241.317 61.344 240.8C62.1696 239.68 67.0272 236.32 67.9296 238.087C68.8896 240 64.2816 245.184 60.48 244.48Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M60.4609 244.486L65.1329 240.026"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M55.6675 222.47L58.7331 217.466"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M57.7984 218.97C57.6723 218.33 57.6842 217.671 57.8332 217.037C57.9822 216.402 58.2649 215.806 58.6624 215.29C59.488 214.17 64.3456 210.81 65.248 212.57C66.24 214.496 61.6192 219.661 57.7984 218.97Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M57.7983 218.97L62.4703 214.502"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M54.4961 211.251L57.5617 206.247"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M56.6402 207.751C56.5141 207.111 56.526 206.452 56.675 205.817C56.824 205.183 57.1067 204.587 57.5041 204.07C58.3297 202.95 63.1873 199.59 64.0897 201.357C65.0561 203.283 60.4801 208.448 56.6402 207.751Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M56.6274 207.75L61.2994 203.283"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M52.7808 194.81L55.8464 189.805"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M54.9121 191.309C54.7861 190.669 54.798 190.01 54.947 189.376C55.096 188.741 55.3787 188.146 55.7761 187.629C56.6017 186.509 61.4593 183.149 62.3617 184.915C63.3409 186.842 58.7329 192 54.9121 191.309Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M54.9121 191.309L59.5841 186.842"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M51.4688 184.32C51.3427 183.681 51.3546 183.022 51.5036 182.387C51.6526 181.752 51.9353 181.157 52.3328 180.64C53.1584 179.52 58.016 176.16 58.9184 177.92C59.8976 179.84 55.2896 184.992 51.4688 184.32Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M51.4692 184.301L56.1412 179.834"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M51.738 184.493C51.0891 184.551 50.4353 184.47 49.8204 184.255C49.2055 184.04 48.6438 183.695 48.1732 183.245C47.1428 182.31 44.3012 177.126 46.1572 176.41C48.1796 175.635 52.8324 180.768 51.738 184.493Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M51.7379 184.493L47.7891 179.373"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M75.0147 196.512L66.1699 281.792"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M68.1218 265.53L65.0498 260.531"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M65.9906 262.029C66.1161 261.389 66.1034 260.729 65.9533 260.095C65.8032 259.46 65.5191 258.865 65.1202 258.349C64.301 257.235 59.437 253.869 58.5346 255.642C57.5554 257.568 62.1698 262.726 65.9906 262.029Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M65.9907 262.029L61.5811 257.799"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M70.5793 241.863L67.5073 236.864"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M68.4486 238.362C68.5741 237.722 68.5614 237.062 68.4113 236.428C68.2612 235.793 67.9771 235.198 67.5782 234.682C66.759 233.568 61.895 230.202 60.9926 231.975C60.0134 233.901 64.6278 239.059 68.4486 238.362Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M68.4482 238.362L64.0322 234.131"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M69.5744 251.558L66.5024 246.56"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.4373 248.064C67.5654 247.424 67.5546 246.765 67.4055 246.13C67.2564 245.495 66.9726 244.9 66.5733 244.384C65.7477 243.264 60.8901 239.904 59.9877 241.67C59.0085 243.597 63.6229 248.762 67.4373 248.064Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.4369 248.064L63.0273 243.834"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M72.2116 226.106L69.146 221.107"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M70.0805 222.611C70.2065 221.972 70.1946 221.313 70.0456 220.678C69.8966 220.044 69.614 219.448 69.2165 218.931C68.3909 217.811 63.5269 214.451 62.6309 216.218C61.6453 218.144 66.2405 223.303 70.0805 222.611Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M70.0805 222.611L65.6709 218.375"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M73.8562 210.285L70.7842 205.287"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M71.7249 206.784C71.8505 206.144 71.8378 205.485 71.6877 204.85C71.5375 204.215 71.2535 203.62 70.8545 203.104C70.0353 201.99 65.1713 198.624 64.2689 200.397C63.2897 202.323 67.9041 207.482 71.7249 206.784Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M71.7251 206.784L67.3091 202.554"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M68.6401 259.776L72.6721 255.514"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M71.4497 256.787C71.4576 256.136 71.6044 255.493 71.8803 254.903C72.1562 254.312 72.5549 253.787 73.0497 253.363C74.0865 252.442 79.5329 250.163 80.0513 252.083C80.6209 254.15 75.0401 258.253 71.4497 256.787Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M71.4497 256.787L76.9409 253.382"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M71.2896 234.253L75.3216 229.99"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M74.0996 231.264C74.1058 230.612 74.2519 229.969 74.5279 229.379C74.8039 228.788 75.2035 228.263 75.6996 227.84C76.7364 226.919 82.1828 224.64 82.7012 226.56C83.2644 228.627 77.69 232.73 74.0996 231.264Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M74.0996 231.264L79.5908 227.859"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M72.4546 223.034L76.4866 218.771"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M75.2578 220.051C75.2656 219.399 75.4131 218.756 75.6902 218.165C75.9672 217.575 76.3676 217.05 76.8642 216.627C77.901 215.699 83.3474 213.427 83.8658 215.347C84.429 217.414 78.8546 221.517 75.2578 220.051Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M75.2578 220.051L80.749 216.64"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M74.1572 206.592L78.1892 202.33"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M76.9668 203.61C76.9746 202.957 77.122 202.314 77.3991 201.724C77.6762 201.133 78.0765 200.609 78.5732 200.186C79.6036 199.258 85.0564 196.986 85.5748 198.906C86.138 200.96 80.5636 205.075 76.9668 203.61Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M76.9668 203.61L82.458 200.198"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M75.04 196.039C75.0515 195.391 75.2007 194.752 75.4776 194.167C75.7546 193.581 76.153 193.06 76.6464 192.64C77.6768 191.712 83.1296 189.44 83.648 191.36C84.2112 193.395 78.6304 197.504 75.04 196.039Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M75.04 196.039L80.5312 192.627"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M75.264 196.282C74.6228 196.201 74.006 195.986 73.4544 195.649C72.9027 195.313 72.4288 194.863 72.064 194.33C71.2512 193.203 69.504 187.539 71.5008 187.226C73.6064 186.88 77.12 192.858 75.264 196.282Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M75.2642 196.282L72.4546 190.464"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path d="M64.877 161.792V269.632" stroke="#9E9E9E" strokeWidth="2" strokeMiterlimit="10" />
        <path
          d="M64.9604 253.415L61.3892 248.762"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 250.157C62.5377 249.507 62.4583 248.853 62.2441 248.236C62.0299 247.62 61.6859 247.057 61.2354 246.586C60.301 245.555 55.117 242.714 54.4066 244.57C53.6322 246.586 58.7522 251.245 62.477 250.157Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 250.157L57.6514 246.4"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9604 233.152L61.3892 228.493"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 229.888C62.5377 229.239 62.4583 228.584 62.2441 227.968C62.0299 227.352 61.6859 226.789 61.2354 226.317C60.301 225.293 55.117 222.445 54.4066 224.301C53.6322 226.317 58.7522 230.976 62.477 229.888Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 229.888L57.6514 226.138"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9604 209.357L61.3892 204.698"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 206.08C62.5381 205.432 62.4588 204.778 62.2445 204.163C62.0303 203.547 61.6861 202.986 61.2354 202.515C60.301 201.485 55.117 198.637 54.4066 200.493C53.6322 202.528 58.7522 207.181 62.477 206.08Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 206.093L57.6514 202.343"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9604 193.517L61.3892 188.858"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 190.253C62.5381 189.604 62.4588 188.95 62.2445 188.335C62.0303 187.72 61.6861 187.158 61.2354 186.688C60.301 185.658 55.117 182.81 54.4066 184.666C53.6322 186.688 58.7522 191.36 62.477 190.253Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 190.253L57.6514 186.502"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9604 177.606L61.3892 172.947"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 174.349C62.5377 173.7 62.4583 173.045 62.2441 172.429C62.0299 171.813 61.6859 171.25 61.2354 170.778C60.301 169.747 55.117 166.938 54.4066 168.762C53.6322 170.778 58.7522 175.437 62.477 174.349Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M62.477 174.349L57.6514 170.592"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8833 259.603L68.4545 254.944"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3663 256.346C67.3093 255.7 67.3906 255.05 67.6047 254.439C67.8188 253.828 68.1608 253.269 68.6079 252.8C69.5423 251.77 74.7263 248.96 75.4367 250.784C76.2111 252.8 71.0911 257.434 67.3663 256.346Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3667 256.346L72.4739 252.39"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8833 227.373L68.4545 222.714"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3666 224.109C67.3056 223.46 67.3849 222.806 67.5992 222.191C67.8134 221.576 68.1576 221.014 68.6082 220.544C69.5426 219.514 74.7266 216.666 75.437 218.522C76.2114 220.544 71.0914 225.197 67.3666 224.109Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3667 224.109L72.4739 220.154"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8833 211.462L68.4545 206.803"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3669 208.205C67.3062 207.555 67.3857 206.9 67.5999 206.284C67.8141 205.668 68.158 205.105 68.6085 204.634C69.5429 203.603 74.7269 200.762 75.4373 202.618C76.2117 204.634 71.0917 209.28 67.3669 208.205Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3667 208.205L72.4739 204.25"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8833 190.438L68.4545 185.779"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3669 187.175C67.3062 186.525 67.3857 185.87 67.5999 185.254C67.8141 184.638 68.158 184.075 68.6085 183.603C69.5429 182.579 74.7269 179.732 75.4373 181.588C76.2117 183.604 71.0917 188.263 67.3669 187.175Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3667 187.174L72.4739 183.219"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.8833 173.907L68.4545 169.248"
          stroke="#9E9E9E"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3669 170.643C67.3062 169.994 67.3857 169.339 67.5999 168.723C67.8141 168.107 68.158 167.544 68.6085 167.072C69.5429 166.048 74.7269 163.2 75.4373 165.056C76.2117 167.072 71.0917 171.731 67.3669 170.643Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M67.3667 170.643L72.4739 166.688"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.6656 163.315C64.6069 162.666 64.6874 162.012 64.9015 161.396C65.1155 160.78 65.4584 160.217 65.9072 159.744C66.8416 158.714 72.0256 155.904 72.7424 157.722C73.5168 159.744 68.3968 164.397 64.6656 163.315Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M64.666 163.315L69.7796 159.354"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9156 163.533C64.2638 163.524 63.6215 163.375 63.032 163.097C62.4425 162.819 61.9194 162.417 61.498 161.92C60.5764 160.883 58.298 155.43 60.218 154.912C62.298 154.362 66.3876 159.942 64.9156 163.533Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M64.9155 163.533L61.5171 158.035"
          stroke="#9E9E9E"
          strokeWidth="0.5"
          strokeMiterlimit="10"
        />
        <path
          d="M69.3121 297.6H57.8881L56.4609 268.755H70.7393L69.3121 297.6Z"
          fill="#CCCCCC"
          stroke="#9E9E9E"
          strokeMiterlimit="10"
        />
        <path
          d="M101.654 65.0202L80.0957 73.2571L88.3097 94.7557L109.868 86.5189L101.654 65.0202Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.195 78.809L88.3291 76.75L92.9775 88.9162L101.195 78.809Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.654 65.0202L80.0957 73.2571L81.601 77.1969L103.159 68.9601L101.654 65.0202Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.6069 77.1947L84.6977 76.0138L85.1056 71.343L82.0147 72.5239L81.6069 77.1947Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.314 66.7481L101.654 65.0203L99.4003 65.8814L98.9985 70.5499L102.089 69.369L102.314 66.7481Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.8989 69.1295L87.808 70.3104L87.4062 74.9789L90.4971 73.7979L90.8989 69.1295Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.6983 66.9138L93.6075 68.0947L93.1996 72.7655L96.2905 71.5846L96.6983 66.9138Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.864 166.587L270.056 170.109L273.568 192.854L296.376 189.332L292.864 166.587Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M289.516 179.972L277.371 175.255L279.359 188.126L289.516 179.972Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.864 166.587L270.056 170.109L270.699 174.278L293.507 170.756L292.864 166.587Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M270.706 174.277L273.976 173.772L275.356 169.291L272.086 169.796L270.706 174.277Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M293.146 168.415L292.864 166.587L290.479 166.956L289.105 171.435L292.375 170.93L293.146 168.415Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M281.485 168.344L278.215 168.849L276.841 173.329L280.111 172.824L281.485 168.344Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M287.62 167.397L284.35 167.902L282.97 172.383L286.24 171.878L287.62 167.397Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.824 17.5264L173.673 11.0493L167.214 33.1387L189.365 39.6159L195.824 17.5264Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.12 28.2303L178.125 18.812L174.469 31.3126L187.12 28.2303Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.824 17.5264L173.673 11.0493L172.489 15.0974L194.64 21.5745L195.824 17.5264Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M172.495 15.0991L175.671 16.0277L178.82 12.5544L175.644 11.6258L172.495 15.0991Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.305 19.3015L195.824 17.5263L193.508 16.8491L190.365 20.3242L193.54 21.2528L195.305 19.3015Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.773 14.2951L181.597 13.3664L178.454 16.8415L181.63 17.7702L184.773 14.2951Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.731 16.0374L187.556 15.1087L184.406 18.582L187.582 19.5107L190.731 16.0374Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M136.007 74.3691H250.637C252.504 74.3691 254.295 75.1109 255.615 76.4311C256.935 77.7514 257.677 79.542 257.677 81.4091V252.289H128.979V81.4091C128.979 79.542 129.721 77.7514 131.041 76.4311C132.362 75.1109 134.152 74.3691 136.019 74.3691H136.007Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M250.637 74.3691H136.007C134.14 74.3691 132.349 75.1109 131.029 76.4311C129.709 77.7514 128.967 79.542 128.967 81.4091V86.5291H257.664V81.4091C257.664 79.5442 256.924 77.7555 255.607 76.4356C254.289 75.1157 252.502 74.3725 250.637 74.3691Z"
          fill="#ED7767"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.372 82.5548C241.432 82.5548 242.292 81.6952 242.292 80.6348C242.292 79.5745 241.432 78.7148 240.372 78.7148C239.311 78.7148 238.452 79.5745 238.452 80.6348C238.452 81.6952 239.311 82.5548 240.372 82.5548Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M246.343 82.5548C247.403 82.5548 248.263 81.6952 248.263 80.6348C248.263 79.5745 247.403 78.7148 246.343 78.7148C245.282 78.7148 244.423 79.5745 244.423 80.6348C244.423 81.6952 245.282 82.5548 246.343 82.5548Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M252.314 82.5548C253.374 82.5548 254.234 81.6952 254.234 80.6348C254.234 79.5745 253.374 78.7148 252.314 78.7148C251.254 78.7148 250.394 79.5745 250.394 80.6348C250.394 81.6952 251.254 82.5548 252.314 82.5548Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.452 110.683H141.62V147.483H198.452V110.683Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.452 142.984H141.62V147.489H198.452V142.984Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.911 145.832H144.128C143.959 145.832 143.796 145.764 143.676 145.644C143.556 145.524 143.488 145.361 143.488 145.192C143.488 145.022 143.556 144.859 143.676 144.739C143.796 144.619 143.959 144.552 144.128 144.552H195.968C196.138 144.552 196.301 144.619 196.421 144.739C196.541 144.859 196.608 145.022 196.608 145.192C196.608 145.361 196.541 145.524 196.421 145.644C196.301 145.764 196.138 145.832 195.968 145.832H195.911Z"
          fill="#ED7767"
        />
        <path
          d="M198.452 156.98H141.62V193.78H198.452V156.98Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.452 189.281H141.62V193.787H198.452V189.281Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.911 192.123H144.128C143.959 192.123 143.796 192.055 143.676 191.935C143.556 191.815 143.488 191.653 143.488 191.483C143.488 191.313 143.556 191.15 143.676 191.03C143.796 190.91 143.959 190.843 144.128 190.843H195.968C196.138 190.843 196.301 190.91 196.421 191.03C196.541 191.15 196.608 191.313 196.608 191.483C196.608 191.653 196.541 191.815 196.421 191.935C196.301 192.055 196.138 192.123 195.968 192.123H195.911Z"
          fill="#ED7767"
        />
        <path
          d="M198.452 203.271H141.62V240.071H198.452V203.271Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.452 235.572H141.62V240.078H198.452V235.572Z"
          fill="white"
          stroke="#9E9E9E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.911 238.42H144.128C143.959 238.42 143.796 238.353 143.676 238.233C143.556 238.113 143.488 237.95 143.488 237.78C143.488 237.611 143.556 237.448 143.676 237.328C143.796 237.208 143.959 237.14 144.128 237.14H195.968C196.138 237.14 196.301 237.208 196.421 237.328C196.541 237.448 196.608 237.611 196.608 237.78C196.608 237.95 196.541 238.113 196.421 238.233C196.301 238.353 196.138 238.42 195.968 238.42H195.911Z"
          fill="#ED7767"
        />
        <path d="M236.928 114.26H201.408V118.42H236.928V114.26Z" fill="#CCCCCC" />
        <path d="M249.728 123.22H201.408V127.38H249.728V123.22Z" fill="#CCCCCC" />
        <path d="M249.728 132.18H201.408V136.34H249.728V132.18Z" fill="#CCCCCC" />
        <path d="M236.928 161.94H201.408V166.1H236.928V161.94Z" fill="#CCCCCC" />
        <path d="M249.728 170.9H201.408V175.06H249.728V170.9Z" fill="#CCCCCC" />
        <path d="M249.728 179.86H201.408V184.02H249.728V179.86Z" fill="#CCCCCC" />
        <path d="M236.928 209.62H201.408V213.78H236.928V209.62Z" fill="#CCCCCC" />
        <path d="M249.728 218.58H201.408V222.74H249.728V218.58Z" fill="#CCCCCC" />
        <path d="M249.728 227.54H201.408V231.7H249.728V227.54Z" fill="#CCCCCC" />
        <path
          d="M274.739 94.3246L274.739 94.3242L281.184 73.8499L281.184 73.8497C281.243 73.6598 281.339 73.4834 281.467 73.3307C281.595 73.178 281.751 73.052 281.927 72.9598C282.104 72.8677 282.297 72.8112 282.495 72.7937C282.693 72.7761 282.893 72.7979 283.083 72.8576L292.669 75.8752C292.956 75.966 293.209 76.1405 293.395 76.3763C293.582 76.6119 293.693 76.898 293.716 77.1977C293.716 77.1978 293.716 77.198 293.716 77.1981L293.888 79.7233L293.893 79.7914L293.958 79.8119L306.629 83.8003C306.819 83.86 306.995 83.9566 307.147 84.0846C307.3 84.2125 307.425 84.3692 307.517 84.5458C307.609 84.7223 307.665 84.9153 307.682 85.1135C307.699 85.3118 307.677 85.5115 307.617 85.7011L307.617 85.7013L302.183 102.966L302.182 102.966C302.123 103.156 302.027 103.333 301.899 103.485C301.772 103.638 301.615 103.764 301.439 103.856C301.262 103.948 301.07 104.005 300.871 104.022C300.673 104.04 300.473 104.018 300.284 103.959L275.726 96.2285C275.536 96.1687 275.36 96.0719 275.207 95.9437C275.055 95.8156 274.929 95.6585 274.837 95.4816L274.748 95.5277L274.837 95.4816C274.745 95.3047 274.689 95.1115 274.673 94.9129C274.656 94.7144 274.678 94.5144 274.739 94.3246ZM274.644 94.2942L281.088 73.8199L274.644 94.2942Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M281.911 76.0164L306.797 83.8496C307.564 84.0911 307.99 84.9086 307.748 85.6757L302.277 103.056C302.036 103.823 301.218 104.249 300.451 104.008L275.566 96.1747C274.799 95.9332 274.373 95.1157 274.614 94.3486L280.085 76.9681C280.327 76.2011 281.144 75.775 281.911 76.0164Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M290.105 93.5337L290.105 93.5336C289.324 93.2565 288.643 92.7541 288.147 92.0897C287.651 91.4254 287.364 90.6291 287.321 89.8014C287.278 88.9737 287.481 88.1518 287.905 87.4397C288.329 86.7276 288.955 86.1572 289.703 85.8007C290.451 85.4441 291.289 85.3174 292.109 85.4365C292.929 85.5557 293.695 85.9153 294.311 86.47C294.927 87.0247 295.364 87.7496 295.568 88.5529C295.772 89.3562 295.733 90.2018 295.457 90.983C295.155 91.8276 294.591 92.5536 293.847 93.0553C293.104 93.557 292.219 93.8082 291.323 93.7723C290.907 93.7543 290.497 93.6739 290.105 93.5337ZM292.849 85.7888C292.512 85.6732 292.161 85.6025 291.806 85.5783L295.202 90.8925L295.296 90.9259C295.296 90.9259 295.296 90.9259 295.296 90.9259C295.652 89.9204 295.595 88.8144 295.136 87.8511C294.678 86.8879 293.855 86.1462 292.85 85.7892L292.85 85.7892L292.849 85.7888Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M292.294 88.9822L293.381 90.2557L291.732 90.5625L290.084 90.8692L290.642 89.2882L290.642 89.288L291.205 87.7046L292.294 88.9822L292.294 88.9822Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M204.495 287.075L204.495 287.074L199.655 267.8L199.655 267.799C199.61 267.622 199.6 267.437 199.627 267.256C199.653 267.074 199.715 266.9 199.809 266.743C199.903 266.585 200.027 266.448 200.174 266.339C200.321 266.229 200.489 266.15 200.666 266.106L209.691 263.839C209.96 263.772 210.242 263.788 210.503 263.883C210.763 263.978 210.988 264.148 211.15 264.372C211.15 264.372 211.151 264.372 211.151 264.373L212.511 266.281L212.55 266.337L212.616 266.32L224.545 263.325C224.722 263.28 224.907 263.271 225.089 263.298C225.27 263.325 225.444 263.387 225.601 263.481C225.758 263.576 225.895 263.7 226.004 263.847C226.113 263.995 226.192 264.162 226.237 264.34L226.237 264.34L230.318 280.593L230.318 280.594C230.363 280.771 230.372 280.956 230.346 281.137C230.319 281.319 230.257 281.493 230.163 281.65C230.07 281.808 229.945 281.945 229.798 282.054C229.651 282.164 229.484 282.243 229.306 282.288L206.188 288.093C206.01 288.137 205.824 288.147 205.643 288.12C205.461 288.093 205.287 288.03 205.13 287.935C204.973 287.841 204.836 287.716 204.727 287.568C204.618 287.421 204.539 287.253 204.495 287.075ZM204.398 287.099L199.558 267.824L204.398 287.099Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M201.274 269.165L224.701 263.282C225.419 263.102 226.148 263.538 226.328 264.256L230.436 280.618C230.617 281.336 230.181 282.065 229.463 282.245L206.035 288.128C205.317 288.308 204.589 287.872 204.409 287.154L200.3 270.792C200.12 270.074 200.556 269.345 201.274 269.165Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M216.225 278.997L216.224 278.997C215.475 279.157 214.696 279.091 213.984 278.808C213.273 278.525 212.661 278.037 212.227 277.406C211.793 276.775 211.555 276.029 211.544 275.264C211.534 274.498 211.751 273.746 212.167 273.103C212.584 272.461 213.181 271.956 213.885 271.653C214.588 271.35 215.366 271.262 216.119 271.401C216.872 271.54 217.567 271.899 218.116 272.433C218.665 272.967 219.044 273.651 219.204 274.4C219.375 275.211 219.282 276.056 218.939 276.811C218.597 277.565 218.022 278.191 217.299 278.596C216.963 278.783 216.601 278.918 216.225 278.997ZM214.635 271.558C214.316 271.63 214.007 271.743 213.717 271.894L218.966 274.451L219.064 274.43C219.064 274.43 219.064 274.43 219.064 274.43C218.857 273.462 218.275 272.616 217.445 272.077C216.614 271.539 215.604 271.352 214.636 271.558L214.636 271.558L214.635 271.558Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M215.744 274.36L217.207 274.834L216.064 275.867L214.922 276.899L214.599 275.394L214.599 275.394L214.278 273.885L215.744 274.36L215.744 274.36Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M91.7889 178.533L91.789 178.533L97.7559 159.576L97.7559 159.576C97.8106 159.401 97.8992 159.239 98.0167 159.098C98.1342 158.958 98.2783 158.842 98.4407 158.757C98.6032 158.672 98.7808 158.62 98.9633 158.604C99.1459 158.587 99.3298 158.607 99.5046 158.663L108.38 161.456C108.644 161.54 108.877 161.701 109.049 161.918C109.221 162.135 109.324 162.398 109.345 162.674C109.345 162.674 109.345 162.675 109.345 162.675L109.504 165.013L109.509 165.081L109.574 165.101L121.305 168.794C121.48 168.849 121.642 168.938 121.783 169.056C121.923 169.173 122.039 169.318 122.123 169.48C122.208 169.643 122.259 169.821 122.275 170.003C122.291 170.186 122.271 170.37 122.215 170.544L122.215 170.545L117.184 186.529L117.183 186.53C117.129 186.705 117.04 186.867 116.923 187.008C116.805 187.148 116.661 187.264 116.499 187.349C116.336 187.434 116.159 187.486 115.976 187.502C115.794 187.518 115.61 187.498 115.435 187.443L92.698 180.286C92.523 180.231 92.3605 180.142 92.22 180.024C92.0795 179.906 91.9637 179.761 91.8792 179.599C91.7948 179.436 91.7433 179.258 91.7278 179.075C91.7123 178.892 91.733 178.708 91.7889 178.533ZM91.6936 178.503L97.6605 159.546L91.6936 178.503Z"
          fill="#E0E0E0"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M98.4199 161.587L121.46 168.839C122.166 169.062 122.559 169.814 122.336 170.521L117.271 186.613C117.049 187.319 116.296 187.711 115.59 187.489L92.5495 180.236C91.8433 180.014 91.451 179.261 91.6733 178.555L96.7385 162.463C96.9608 161.757 97.7136 161.365 98.4199 161.587Z"
          fill="#F2F2F2"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M106.011 177.791L106.011 177.791C105.289 177.535 104.659 177.071 104.201 176.457C103.743 175.843 103.478 175.107 103.438 174.342C103.398 173.578 103.586 172.818 103.978 172.16C104.37 171.502 104.948 170.975 105.64 170.645C106.331 170.316 107.104 170.199 107.862 170.309C108.62 170.419 109.329 170.751 109.898 171.264C110.467 171.777 110.871 172.446 111.059 173.189C111.248 173.931 111.212 174.712 110.956 175.434C110.677 176.215 110.156 176.886 109.469 177.349C108.782 177.813 107.965 178.045 107.136 178.012C106.752 177.995 106.373 177.921 106.011 177.791ZM108.551 170.621C108.242 170.515 107.92 170.449 107.593 170.426L110.727 175.353L110.821 175.386C110.821 175.386 110.821 175.386 110.821 175.386C111.152 174.454 111.099 173.428 110.673 172.534C110.248 171.64 109.485 170.952 108.552 170.621L108.552 170.621L108.551 170.621Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
        <path
          d="M108.03 173.589L109.028 174.759L107.514 175.04L106 175.322L106.513 173.87L106.513 173.87L107.029 172.416L108.03 173.589L108.03 173.589Z"
          fill="white"
          stroke="#9E9E9E"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_135_34451">
          <rect width="320" height="320" fill="white" transform="translate(24)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNoRecordingsIcon;
