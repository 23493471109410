import React from 'react';

const ContentHubIcon = ({ height = '22', width = '22', className = '' }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			className={className}
			viewBox="0 0 25 25"
			fill="none"
		>
			<rect
				x="2.74023"
				y="2.21143"
				width="20"
				height="20"
				rx="2"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinejoin="round"
			/>
			<path
				d="M2.73926 8.21143H22.7439"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7402 8.21143L12.7402 22.2114"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.7305 14.4032C20.4101 14.7624 20.4101 15.6605 19.7305 16.0197L16.7693 17.585C16.0897 17.9442 15.2402 17.4952 15.2402 16.7767L15.2402 13.6461C15.2402 12.9277 16.0897 12.4786 16.7693 12.8379L19.7305 14.4032Z"
				fill="currentColor"
			/>
			<path
				d="M5.74023 13.2686H9.74023"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.74023 17.2114H7.74023"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ContentHubIcon;
