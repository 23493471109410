/* eslint-disable no-param-reassign */
import { SET_USER_INFO,SET_USER_PLAN_INFO, REMOVE_USER_INFO } from 'redux/actions/user-action';


const userInfo = (state = {}, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            state = { ...state, ...action.data };
            break;
        
            case SET_USER_PLAN_INFO:
                state = { ...state, ...action.data };
                break;
        case REMOVE_USER_INFO:
            state = {};
            break;
        default:
            break;
    }
    return state;
}

export default userInfo;