import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/root-reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { env } from 'settings';

export default function configureStore(preloadedState) {
  const middleWares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middleWares);

  const enhancers = middlewareEnhancer;

  const composedEnhancers = env === 'prod' ? enhancers : composeWithDevTools(enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  return store;
}
