import _ from 'lodash';

export const dataMapperForSaveCookieConsentPref = (data = []) => {
  let finalData = {};
  let app = {};
  if (data.length) {
    const cat = data.map((item) => item.classification);
    cat.forEach((item) => {
      let appArr = [];
      if (data.length) {
        data.forEach((val) => {
          if (val.classification === item) {
            appArr = val.application;
          }
        });
      }
      if (appArr.length) {
        appArr.forEach((apps) => {
          app = { ...apps };
        });
        finalData = { ...finalData, [item.toLowerCase()]: app };
      }
    });
  }
  return finalData;
};

export const featurePrefExtractorMapper = (data = {}) => {
  let x = {};
  const y = Object.values(data);
  if (y.length) {
    y.forEach((item) => {
      x = { ...x, ...item };
    });
  }
  return x;
};

export const getBannerPositioning = (data) => {
  let positionX = 'start';
  let positionY = 'bottom';
  if (data === 'Floating top-left') {
    positionX = 'start';
    positionY = 'left';
  }
  if (data === 'Floating top-right') {
    positionX = 'end';
    positionY = 'right';
  }
  if (data === 'Floating bottom-left') {
    positionX = 'bottom';
    positionY = 'start';
  }
  if (data === 'Floating bottom-right') {
    positionX = 'bottom';
    positionY = 'end';
  }
  if (data === 'Floating center') {
    positionX = 'center';
    positionY = 'center';
  }
  if (data === 'Banner top') {
    positionX = 'start';
    positionY = 'top';
  }
  if (data === 'Banner bottom') {
    positionX = 'start';
    positionY = 'bottom';
  }
  return { positionX, positionY };
};

export const convertToInitials = (init = [], data = {}) => {
  const finalData = [];
  if (!_.isEmpty(data)) {
    if (init.length) {
      init.forEach((item) => {
        if (item.application.length) {
          item.application.forEach((app = {}) => {
            const appList = _.isEmpty(app) ? [] : Object.keys(app);
            const selectedAppList = Object.keys(data);
            if (appList.length && selectedAppList.length) {
              selectedAppList.forEach((apl) => {
                if (appList.includes(apl)) {
                  finalData.push(item);
                }
              });
            }
          });
        }
      });
    }
  }
  return finalData.length ? _.uniqBy(finalData, 'id') : [];
};
