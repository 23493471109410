/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SpekaerDeviceLargeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
            <path d="M17.9912 0.00195329C17.8599 0.00187836 17.7298 0.0276929 17.6084 0.0779211C17.4871 0.128149 17.3768 0.201806 17.2839 0.294679C17.1911 0.387553 17.1174 0.497822 17.0672 0.619181C17.017 0.740541 16.9911 0.87061 16.9912 1.00195V1.63867C16.1478 2.68438 15.0819 3.52887 13.871 4.11067C12.66 4.69248 11.3346 4.99696 9.99121 5.00195H3.99121C3.19583 5.00282 2.43327 5.31917 1.87085 5.88159C1.30843 6.44401 0.992079 7.20657 0.991211 8.00195V10.002C0.992079 10.7973 1.30843 11.5599 1.87085 12.1223C2.43327 12.6847 3.19583 13.0011 3.99121 13.002H4.475L2.07227 18.6084C2.00698 18.7604 1.98047 18.9263 1.99512 19.0911C2.00978 19.256 2.06514 19.4146 2.15624 19.5528C2.24734 19.6909 2.37133 19.8043 2.51706 19.8827C2.6628 19.9611 2.82572 20.0021 2.99121 20.002H6.99121C7.18696 20.0021 7.37843 19.9446 7.54182 19.8368C7.7052 19.729 7.83329 19.5755 7.91016 19.3955L10.6339 13.04C11.8646 13.1303 13.0636 13.472 14.157 14.0439C15.2505 14.6158 16.215 15.4058 16.9912 16.365V17.002C16.9912 17.2672 17.0966 17.5215 17.2841 17.7091C17.4716 17.8966 17.726 18.002 17.9912 18.002C18.2564 18.002 18.5108 17.8966 18.6983 17.7091C18.8859 17.5215 18.9912 17.2672 18.9912 17.002V1.00195C18.9913 0.87061 18.9655 0.74054 18.9152 0.61918C18.865 0.49782 18.7914 0.38755 18.6985 0.294676C18.6056 0.201803 18.4953 0.128146 18.374 0.0779185C18.2526 0.0276908 18.1226 0.00187705 17.9912 0.00195329ZM3.99121 11.002C3.72605 11.0018 3.4718 10.8964 3.2843 10.7089C3.0968 10.5214 2.99139 10.2671 2.99121 10.002V8.00195C2.99139 7.73679 3.09681 7.48254 3.2843 7.29505C3.4718 7.10755 3.72605 7.00213 3.99121 7.00195H4.99121V11.002H3.99121ZM6.33203 18.002H4.50781L6.65039 13.002H8.47461L6.33203 18.002ZM16.9912 13.5238C15.0195 11.8994 12.5459 11.0082 9.99121 11.0019H6.99121V7.0019H9.99121C12.5459 6.99537 15.0195 6.10406 16.9912 4.47956V13.5238Z" fill="#444444" />
        </svg>
    );
}
