/* eslint-disable  */
/* eslint-disable no-unused-vars */

import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import BtnWithLoader from 'custom-components/btnWithLoader';

export default function DefaultScreen({
  takaATourPath = '',
  takeTourAction,
  title,
  disabled,
  btnSize = 'small',
  subTitle,
  svgIcon,
  btnLabel,
  btnAction,
  children,
  marginAuto = '0',
  widthOfBox = '100%',
  hasButtonLoader = '',
  subTitleVariant,
  isLoading = false,
  maxWidth,
  loadingLabel = '',
  classNameForBtn,
  spaceIconHeader = 1,
  spaceHeaderSubHeader = 1,
  spaceSubHeaderChildren = 1,
  spaceChildrenButton = 2,
  svgBottomMargin = 0,
  svgTopMargin = 0,
  subtitleMt,
  defaultScreenSvgClass,
  ...props
}) {
  return (
    <Box textAlign="center" width={widthOfBox} m={marginAuto} {...props}>
      {!svgIcon ? (
        false
      ) : (
        <Box className={defaultScreenSvgClass} mt={svgTopMargin} mb={svgBottomMargin}>
          {svgIcon}
        </Box>
      )}
      {!title ? (
        false
      ) : (
        <Box mt={1.75} mb={1} className="defaultScreentitle">
            <Typography color="textPrimary" variant={"body1"} component="h5">
            {title}
          </Typography>
        </Box>
      )}
      {!subTitle ? (
        false
      ) : (
          <Box mt={subtitleMt || 2} color="text.grayColor" px={3} maxWidth={maxWidth || 470} mx="auto">
          <Typography variant={`${subTitleVariant} || subtitle1`} component="p">
            {subTitle}
          </Typography>
        </Box>
      )}

      {!children ? false : <Box mt={3}>{children}</Box>}
      {!btnLabel ? (
        false
      ) : (
        <Box mt={3} className="defaultScreenAction">
          {hasButtonLoader ? (
            <BtnWithLoader
              className="uppercase"
              onClick={btnAction}
              disabled={isLoading || disabled}
              variant="contained"
              size="small"
              color="primary"
              isLoading={isLoading}
              loadingLabel={loadingLabel}
              label={btnLabel}
            />
          ) : (
            <Box display="flex" justifyContent="center">
              <Box>
                <Button
                  className={`${classNameForBtn ? classNameForBtn : 'uppercase'}`}
                  disabled={disabled}
                  onClick={btnAction}
                  size={btnSize || 'small'}
                  variant="contained"
                  color="primary">
                  {btnLabel}
                </Button>
              </Box>
              {takaATourPath ? (
                <Box ml={2}>
                  <Button
                    className="uppercase"
                    disabled={disabled}
                    onClick={takeTourAction}
                    size={btnSize || 'small'}
                    variant="outlined"
                    color="primary">
                    Take A Tour
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
