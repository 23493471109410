/* eslint-disable react/self-closing-comp */
import React from 'react';

export default function SvgEditIcon(props) {
  return (
    <svg x="0px" y="0px" fill="currentColor" viewBox="0 0 15.3 15.4" {...props}>
      <g>
        <path d="M5.4,13.6C5.4,13.6,5.4,13.6,5.4,13.6c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l9.2-9.2c0.3-0.3,0.5-0.7,0.5-1.1s-0.2-0.8-0.5-1.1l-1.7-1.7c-0.6-0.6-1.6-0.6-2.2,0L1.8,9.6c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l-1.1,4.4l-0.4,0.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1l0.5-0.4L5.4,13.6z M2.4,10.8L4.4,13l-2.8,0.8L2.4,10.8z M11.6,1.1c0.2-0.2,0.7-0.2,0.9,0l1.7,1.7c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.3-0.2,0.5l-1.8,1.8L9.8,2.8L11.6,1.1z M9.2,3.5l2.6,2.6l-6.5,6.5L2.8,9.9L9.2,3.5z"></path>
      </g>
    </svg>
  );
}
