/* eslint-disable no-param-reassign */
import {
  SET_COPY_EVENT_DATA,
  REMOVE_COPY_EVENT_DATA,
} from 'redux/actions/create-copy-event-action';

const createCopyEventReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_COPY_EVENT_DATA:
      state = { ...state, ...action.data };
      break;
    case REMOVE_COPY_EVENT_DATA:
      state = {};
      break;
    default:
      break;
  }
  return state;
};

export default createCopyEventReducer;
