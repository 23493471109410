import { COOKIE_RECORD_LIST, COOKIE_RECORD } from 'api-setup/api-endpoints';
import { genericAPIErrorHandler, POSTAPIWITHCUSTOMDOMAIN, API } from 'api-setup/api-helper';
import { setNewMessage } from 'redux/actions/setting-actions';
import { browserSignature } from 'utility/browserSignature';
import {
  featurePrefExtractorMapper,
  dataMapperForSaveCookieConsentPref,
  convertToInitials,
} from 'utility/dataMapper/cookieConsent';
import { saveDataToLocalStorage, getDataFromLocalStorage } from 'utility/localStorageHandler';
import {
  LOCAL_STORAGE_KEY,
  COOKIE_EXPIRE_TIME,
  dashboardCookies,
  mandatoryPref,
  IS_ENABLE_SAVE_API_COOKIE_CONSENT,
} from 'constants/cookieConsent';
import ProjectSetting from 'settings';
import { initializeAmplitude, validateAmplitudeStatus } from 'lib/amplitude/init';
import isEmpty from 'lodash/isEmpty';
import {
  cookieConsentToggle,
  cookieConsentLoading,
  storagePrefToggle,
} from 'redux/actions/cookie-consent-action';

import { getBrowserAndItsVersion } from 'utility/BrowserDetection';
import { detectIncognito } from 'utility/PrivateBrowserDetection';

const { browser } = getBrowserAndItsVersion();

export const getCookieRecordAsync = (browserSignatureVal) => async (dispatch) => {
  detectIncognito(browser, (isPrivate) => {
    const browserDetails = `browser:${browser}isPrivate:${isPrivate}`;
    const clientStorageId =
      browserSignatureVal !== ''
        ? browserSignatureVal + browserDetails
        : browserSignature() + browserDetails;
    saveDataToLocalStorage(LOCAL_STORAGE_KEY.GET_BROWSER_SIGNATURE, clientStorageId);

    const cookieData = {
      payload: {
        data: {
          clientStorageId,
        },
      },
    };
    try {
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        COOKIE_RECORD_LIST,
        null,
        null,
        cookieData,
        ProjectSetting.customBaseURL2
      )
        .then((res) => {
          if (res.data.status === API.apiSuccessStatus) {
            res.data.success.data.clientStorageId = cookieData.payload.data.clientStorageId;
            dispatch({ type: 'COOKIE_RECORD_LIST', payload: res.data.success.data });
            const initialCookie = dataMapperForSaveCookieConsentPref([mandatoryPref]);
            const savedSignature =
              getDataFromLocalStorage(LOCAL_STORAGE_KEY.SAVE_BROWSER_SIGNATURE) || '';
            const getSignature =
              getDataFromLocalStorage(LOCAL_STORAGE_KEY.GET_BROWSER_SIGNATURE) || '';
            const isSameSignature =
              savedSignature && getSignature ? savedSignature === getSignature : false;
            let consentData;
            if (IS_ENABLE_SAVE_API_COOKIE_CONSENT) {
              consentData = featurePrefExtractorMapper(
                res.data.success.data.details.consentData || {}
              ); // for new user consent data will be null
            } else {
              consentData = getDataFromLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA) || {};
            }
            if (isEmpty(consentData) || !isSameSignature) {
              // only initial cookie will load
              dispatch(cookieConsentToggle({ isShow: true }));
              dispatch(storagePrefToggle({ isShowStoragePref: false }));

              saveDataToLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA, {
                ...featurePrefExtractorMapper(initialCookie),
                storedAt: Date.now(),
              });
            } else {
              const getLocalSaveState =
                getDataFromLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA) || {};
              if (isEmpty(getLocalSaveState)) {
                dispatch(cookieConsentToggle({ isShow: true }));
              } else {
                dispatch(cookieConsentToggle({ isShow: false }));
                dispatch(storagePrefToggle({ isShowStoragePref: false }));
              }
              saveDataToLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA, {
                ...consentData,
                storedAt: Date.now() + COOKIE_EXPIRE_TIME,
              });
            }
            if (!IS_ENABLE_SAVE_API_COOKIE_CONSENT) {
              const convertedDataInitials = convertToInitials(dashboardCookies, consentData) || [];
              dispatch({
                type: 'COOKIE_CONSENT_UPDATE',
                payload: convertedDataInitials.length
                  ? dataMapperForSaveCookieConsentPref(convertedDataInitials)
                  : [],
              });
            }
            dispatch(cookieConsentLoading({ isListLoading: true }));
          }
        })
        .catch(() => {});
    } catch (err) {
      dispatch(genericAPIErrorHandler(true, err, dispatch));
    } finally {
      // dispatch(loadingAction(false));
    }
  });
};

export const saveCookieRecordAsync = (dataVal) => async (dispatch) => {
  if (IS_ENABLE_SAVE_API_COOKIE_CONSENT) {
    const cookieData = {
      payload: {
        data: {
          clientStorageId: dataVal.clientStorageId,
          cookieData: {
            ...dataVal.cookieData,
          },
        },
      },
    };
    try {
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        COOKIE_RECORD,
        null,
        null,
        cookieData,
        ProjectSetting.customBaseURL2
      ).then((res) => {
        if (res.data.status === API.apiSuccessStatus) {
          const consentData = featurePrefExtractorMapper(dataVal.cookieData);
          dispatch({ type: 'COOKIE_CONSENT_UPDATE', payload: dataVal.cookieData });
          saveDataToLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA, {
            ...consentData,
            storedAt: Date.now() + COOKIE_EXPIRE_TIME,
          }); // TODO : Storing Data so we can verify
          // CONTROL FEATURE
          // if (consentData.isAmplitude) {
          initializeAmplitude();
          validateAmplitudeStatus();
          // }
          // window.location.reload(); // TODO : window will reload for initialization
          if (res.data.message) {
            dispatch(setNewMessage({ message: res.data.message, type: 'success', show: true }));
          }
        }
      });
    } catch (err) {
      dispatch(genericAPIErrorHandler(true, err, dispatch));
    } finally {
      // dispatch(loadingAction(false));
    }
  } else {
    const consentData = featurePrefExtractorMapper(dataVal.cookieData);
    dispatch({ type: 'COOKIE_CONSENT_UPDATE', payload: dataVal.cookieData });
    saveDataToLocalStorage(LOCAL_STORAGE_KEY.SAVE_BROWSER_SIGNATURE, dataVal.clientStorageId); // Browser Signature update at the time of save
    saveDataToLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA, {
      ...consentData,
      storedAt: Date.now() + COOKIE_EXPIRE_TIME,
    }); // TODO : Storing Data so we can verify
    // CONTROL FEATURE
    // if (consentData.isAmplitude) {
    initializeAmplitude();
    validateAmplitudeStatus();
    dispatch(setNewMessage({ message: 'Changes Saved Successfully', type: 'success', show: true })); // msg added
    // }
    // window.location.reload(); // TODO : window will reload for initialization
  }
};
