import { actionTypes } from './types';

const loadingAction = (type, payload) => {
  return {
    type,
    payload,
  };
};

const toggleEmbedAppSidePanel = (payload) => {
  return { type: actionTypes.TOGGLE_ADD_UPDATE_APP_SIDE_PANEL, payload };
};

const checkEmbedAPPBackgroundMode = (payload) => {
  return { type: actionTypes.CHECK_EMBED_APP_BACKGROUND_MODE, payload };
};

export { loadingAction, toggleEmbedAppSidePanel, checkEmbedAPPBackgroundMode };
