import React from 'react';
import moment from 'moment-timezone';
import { Fade, Paper, Box, Typography, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userInfoSelector } from 'redux/custom-selector';
import SvgFreetrialRestrictions from '../../../icons/svgFreeTrialRestrictions';
import { useStyles } from './style';
import { planTypeDisplay } from './planTypeDisplay';
import { colors } from 'constants/colors';
import { PricingPlan } from 'constants/pricingPlan';

export const PlanCancelledMenu = ({ isPlanMenuOpen, setPlanMenuOption }) => {
  const history = useHistory();
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const { planDropdown } = useStyles();
  const planExpiryDate = new Date(Number(UserInfo.subscription_end_date_milli));
  const planExpiry = moment(planExpiryDate).format('D MMM, YYYY');

  return (
    <Fade in={isPlanMenuOpen}>
      <Box
        position="absolute"
        className={`${planDropdown} hasTip`}
        width={280}
        left="-50%"
        zIndex={5}
        mt={3}
        mb={1.2}>
        <Paper elevation={2}>
          <Box className="dropDownBody">
            <Box className="choosePlan" display="flex" alignItems="center">
              <Box width="100%">
                <Box className="currentPlan" display="flex" mb={1} p={2} alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%">
                    <Box>
                      <Typography variant="h6" component="h6">
                        {UserInfo && planTypeDisplay(UserInfo)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button size="small" className="expired-btn" disabled>
                        {' '}
                        Cancelled{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className="expiredPlan" mb={1}>
                  <Box mb={2}>
                    <Typography variant="h6" component="h6" display="block">
                      Your {UserInfo && planTypeDisplay(UserInfo)} has been cancelled on{' '}
                      {planExpiry}
                    </Typography>
                  </Box>
                  <Box className="expiredSVG">
                    <SvgFreetrialRestrictions />
                  </Box>
                </Box>
                <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                  <Box mb={1}>
                    <Typography variant="body2" component="label" color={colors.textSecondary}>
                      {!(
                        UserInfo.planName === PricingPlan.ALL_IN ||
                        UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
                        UserInfo.planName === PricingPlan.WEBINAR
                      )
                        ? `You have cancelled your subscription. You can continue using the dashboard
                      till ${planExpiry} with limited functionalities. Reactivate to use the
                      dashboard.`
                        : `You have cancelled your subscription. You can continue using the dashboard
                      till ${planExpiry} with limited functionalities. Talk to our sales team to reactivate your subscription.`}
                    </Typography>
                  </Box>
                  {!(
                    UserInfo.planName === PricingPlan.ALL_IN ||
                    UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
                    UserInfo.planName === PricingPlan.WEBINAR
                  ) ? (
                    <Button
                      onClick={() => {
                        history.push('/billing');
                        setPlanMenuOption(false);
                      }}
                      className="uppercase"
                      variant="contained"
                      color={colors.primary}
                      size="small">
                      GO TO BILLING
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};
