import React from 'react';
import SkeletonLoader from 'custom-components/skeletonLoader';
import { Box } from '@material-ui/core';

const QuestionsSkeletonLoader = () => {
  return (
    <>
      <SkeletonLoader width="70px" variant="text" height="22px" />
      <Box mt={1}>
        <SkeletonLoader width="100%" variant="rect" height="44px" />
      </Box>
      <SkeletonLoader width="85px" variant="text" height="18px" />

      <Box mt={4}>
        <SkeletonLoader width="110px" variant="text" height="22px" />
        <Box mt={1} display="flex" alignItems="center" justifyContent="start">
          <SkeletonLoader
            width="22px"
            variant="round"
            height="22px"
            skeletonProps={{ borderRadius: '100% !important', marginRight: '10px' }}
          />
          <SkeletonLoader
            width="60px"
            variant="text"
            height="22px"
            skeletonProps={{ marginRight: '50px' }}
          />
          <SkeletonLoader
            width="22px"
            variant="round"
            height="22px"
            skeletonProps={{ borderRadius: '100% !important', marginRight: '10px' }}
          />
          <SkeletonLoader width="50px" variant="text" height="22px" />
        </Box>
      </Box>

      <Box mt={4}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <SkeletonLoader
            width="22px"
            variant="square"
            height="22px"
            skeletonProps={{ borderRadius: '10% !important', marginRight: '10px' }}
          />
          <SkeletonLoader
            width="80px"
            variant="text"
            height="22px"
            skeletonProps={{ marginRight: '50px' }}
          />
        </Box>
      </Box>

      <Box mt={4}>
        <SkeletonLoader width="70px" variant="text" height="22px" />
        <Box mt={1}>
          <SkeletonLoader width="100%" variant="rect" height="44px" />
        </Box>
        <SkeletonLoader width="85px" variant="text" height="18px" />

        <Box mt={2}>
          <Box mt={1}>
            <SkeletonLoader width="100%" variant="rect" height="44px" />
          </Box>
          <SkeletonLoader width="85px" variant="text" height="18px" />
        </Box>
      </Box>

      <Box mt={2}>
        <SkeletonLoader
          width="150px"
          variant="square"
          height="36px"
          skeletonProps={{ borderRadius: '5px !important' }}
        />
      </Box>

      <Box mt={4}>
        <SkeletonLoader width="170px" variant="text" height="22px" />
        <Box mt={1}>
          <SkeletonLoader width="60%" variant="rect" height="44px" />
        </Box>
      </Box>
    </>
  );
};

export default QuestionsSkeletonLoader;
