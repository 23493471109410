import {
  FETCH_DUPLICATE_SUCCESS,
  NEW_COPIED_EVENT_IDS,
  SET_BEACON_STATUS,
  SET_COPYING_API_CALL,
} from 'redux/actions/copy-status-action';

const copyStatusReducer = (
  state = {
    status: [],
    newIds: [],
    beaconModules: [],
    beacon: false,
    copyCheckApiCall: false,
    copyCheckApiCallCount: 0,
    copyBtnDisable: false,
    copyEventTz: '',
    intervalTimer: '',
    newCopyEventId: '',
    newCopyEventName: '',
    mShownFor: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_DUPLICATE_SUCCESS:
      return { ...state, status: action.data };
    case NEW_COPIED_EVENT_IDS:
      return { ...state, newIds: action.data };
    case SET_BEACON_STATUS:
      return { ...state, ...action.data };
    case SET_COPYING_API_CALL:
      return { ...state, ...action.data };
    default:
      break;
  }
  return state;
};

export default copyStatusReducer;
