import { combineReducers } from 'redux';
import OTHER from 'redux/reducers/setting-reducer';
import UserInfo from 'redux/reducers/user-reducer';
import EventData from 'redux/reducers/event-reducer';
import EventListData from 'redux/reducers/eventList-reducer';
import Timezones from 'redux/reducers/timezones-reducer';
import Language from 'redux/reducers/language-reducer';
import ContestData from 'redux/reducers/contest-reducer';
import SessionData from 'redux/reducers/session-reducer';
import ConversionPromptReducer from 'redux/reducers/conversion-prompt-reducer';
import MultiEventData from 'redux/reducers/multi-analytics-event-reducer';
import StripeDetails from 'redux/reducers/stripe-details-reducer';
import EventLanguages from 'redux/reducers/event-languages-reducer';
import CurrentEventLanguage from 'redux/reducers/current-event-language-reducer';
import BaseEventLanguage from 'redux/reducers/base-event-language-reducer';
import eventEmbedAppsReducer from './event-embed-apps';
import sessionEngagementReducer from './session-engagement-reducer';
import meetingReducer from './meeting';
import cookieConsentInfo from './cookie-consent-reducer';
import CopyEvent from './create-copy-event-reducer';
import CopyStatus from './copy-status-reducer';
import roiReducer from './roi-reducer';
import roomReactionReducerEnable from './room-reaction-reducer';
import loungeReactionEnableReducer from './lounge-reaction-reducer';
import templateModalInfo from './template-details-reducer';
import templatePreviewInfo from './template-preview-reducer';
import ticketDataInfo from './ticket-table-reducer';
import CampaignStatus from './email-deactivate-reducer';
import createEditParentReducer from './eventCreateEditReducer';
import ticketReducer from './ticket-reducer';
import Analytics from './analytics-reducer';
import inviteEmailCampaignData from './invite-email-campaign-reducer';
import { downTimeReducer } from 'pages/DownTime/downTimeReducer';
import leadScoreReducer from 'pages/engagement/lead-score/lead-score-redux-flow';
import commonReducer from './common';
import SurveyDetailsReducer from './survey-reducer';
import registrationFlowReducer from 'pages/NewRegistrationFlow/data-management';
import ZoomReducer from './zoom-reducer';
import alertData from './alert-reducer';

const RootReducer = combineReducers({
  OTHER,
  UserInfo,
  EventData,
  EventListData,
  Timezones,
  ContestData,
  SessionData,
  MultiEventData,
  StripeDetails,
  Language,
  EventLanguages,
  CurrentEventLanguage,
  BaseEventLanguage,
  eventEmbedAppsReducer,
  sessionEngagementReducer,
  meetingReducer,
  cookieConsentInfo,
  CopyEvent,
  CopyStatus,
  templateModalInfo,
  templatePreviewInfo,
  CampaignStatus,
  roomReactionReducerEnable,
  loungeReactionEnableReducer,
  ticketDataInfo,
  createEditParentReducer,
  ticketReducer,
  Analytics,
  inviteEmailCampaignData,
  downTimeReducer,
  leadScoreReducer,
  commonReducer,
  ConversionPromptReducer,
  SurveyDetail: SurveyDetailsReducer,
  registrationFlowReducer,
  ZoomData: ZoomReducer,
  alertData,
  roiReducer,
});

export default RootReducer;
