/* eslint-disable no-useless-escape */
/* eslint-disable no-new-object */
/* eslint-disable prefer-template */
import PS from 'settings';
import amplitude from 'amplitude-js';
import { LOCAL_STORAGE_KEY } from 'constants/cookieConsent';
import { getDataFromLocalStorage } from 'utility/localStorageHandler';
import isEmpty from 'lodash/isEmpty';

export const validateAmplitudeStatus = () => {
  let isAllow = false;
  const getData = getDataFromLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA);
  if (getData && Object.keys(getData).length) {
    if (getData.isAmplitude) {
      isAllow = getData.isAmplitude;
    }
  }
  return isAllow;
};

export const shouldApplied = validateAmplitudeStatus();

export const eventProperties = new Object();

export const initializeAmplitude = (data = {}) => {
  if (isEmpty(data)) {
    amplitude.getInstance().init(PS.amplitudeApiKey, null, data);
  } else {
    amplitude.getInstance().init(PS.amplitudeApiKey);
  }
};

if (shouldApplied && !window.isAmplitudeApplied) {
  window.isAmplitudeApplied = true;
  // This condition is added to grab deviceId which is coming from hubilo.com for non login user
  const deviceIdAmplitude = decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          encodeURIComponent('deviceId').replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
  if (deviceIdAmplitude) {
    initializeAmplitude({
      deviceId: deviceIdAmplitude,
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
    });
  } else {
    initializeAmplitude();
  }
}
