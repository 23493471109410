/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  CardHeader,
  Card,
  Box,
  Divider,
  CardContent,
  Fade,
  Typography,
  Grid,
} from '@material-ui/core';
import BtnWithLoader from './btnWithLoader';
import MultiLanguageDropDown from 'custom-components/form-fields/multiLanguageDropDown';
import MultiLangOptions from 'utility/MultiLangOptions';
import MultiLanguageStatus from 'custom-components/form-fields/multiLanguageStatus';
import ReactionEnabled from 'custom-components/reaction-enabled/reactionEnabled';
import RaiseHandEnabled from 'custom-components/raise-hand/raiseHandEnabled';
// redux
import { shallowEqual, useSelector } from 'react-redux';
import { eventLanguagesSelector, commonSelector, basicInfoSelector } from 'redux/custom-selector';
import { sessionEngagementSelector } from 'redux/selectors';
import { EventTypes } from 'pages/events/constants';
import useScrollCheck from 'utility/customHooks/ScrollCheck';
import { GETAPI, API } from 'api-setup/api-helper';
import { POST_STREAM_OPTIONS } from 'api-setup/api-endpoints';
import { useSource } from 'lib/custom-common-hooks';
import { useParams } from 'react-router-dom';

const HBS = 4; // stream type id for hbs
const HBS_3 = 4; // stream subtype  id for hbs 3

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: `${theme.spacing(3)}px !important`,
    '@media screen and (max-width: 960px)': {
      padding: `${theme.spacing(2.5)}px !important`,
    },

    '& .MuiCardHeader-content': {
      paddingRight: theme.spacing(1),
      '& .MuiCardHeader-title': {
        marginBottom: '5px',
      },
      '& .MuiCardHeader-subheader': {
        color: theme.palette.text.grayColor,
        fontSize: theme.fontSize.size8,
      },
    },
    '& .MuiCardHeader-action': {
      margin: 0,
      alignSelf: 'center',
    },
  },

  cardContent: {
    padding: `${theme.spacing(3)}px !important`,
    '@media screen and (max-width: 960px)': {
      padding: `${theme.spacing(2.5)}px !important`,
    },
    '&.toScroll': {
      marginBottom: `${theme.spacing(2.5)}px !important`,
      overflowY: `auto`,
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
        10.75
      )}px ${theme.spacing(3)}px !important`,
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 50px rgba(0,0,0,0.35) !important',
      },
    },
    '&.with-subtitle': {
      maxHeight: `calc(100vh - 320px)`,
    },
    '&.no-subtitle': {
      maxHeight: `calc(100vh - 290px)`,
    },
  },

  divider: {
    padding: `0 ${theme.spacing(3)}px`,
    '@media screen and (max-width: 960px)': {
      padding: `0 ${theme.spacing(2.5)}px`,
    },

    '& .MuiDivider-root': {
      backgroundColor: theme.palette.bg.whiteOff2,
    },
  },
  card: {
    borderRadius: 10,
    overflow: 'visible',
    position: 'relative',
  },
  cardAction: {
    padding: theme.spacing(3),
    '& .MuiButton-root + .MuiButton-root': {
      marginLeft: theme.spacing(3),
    },
    '&.stickyFooter': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      backgroundColor: 'white',
      width: 'calc(100% - 48px)',
      transition: `opacity 0.3s`,
      borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
      zIndex: 2,
      '&.hide': {
        opacity: 0,
      },
      '&.show': {
        opacity: 1,
      },
    },
  },
  cardHeaderActions: {
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2.5),
    },
  },
  tagMentionCardContent: {
    padding: `${theme.spacing(0, 2.5, 0, 2.5)} !important`,
    '@media screen and (max-width: 960px)': {
      padding: `${theme.spacing(2.5)}px !important`,
    },
    '&.toScroll': {
      marginBottom: `${theme.spacing(2.5)}px !important`,
      overflowY: `auto`,
      padding: `${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(
        10.75
      )}px ${theme.spacing(3)}px !important`,
      '&::-webkit-scrollbar-thumb': {
        boxShadow: 'inset 0 0 50px rgba(0,0,0,0.35) !important',
      },
    },
    '&.with-subtitle': {
      maxHeight: `calc(100vh - 320px)`,
    },
    '&.no-subtitle': {
      maxHeight: `calc(100vh - 290px)`,
    },
  },
}));

const CardWrapper = ({
  title,
  subheader,
  headerActions,
  children,
  actionProps,
  cancelProps,
  isLanguageSupport = false,
  onLanguageChange,
  isDisable = false,
  selectedLanguage,
  isEdit = false,
  isLegalPage = false,
  isReactionsEnabled = false,
  tagMentions,
  isScrollable,
  addActionProps,
  isBasicDetail = false,
}) => {
  const classes = useStyles();
  const { isScrolling, handleScroll } = useScrollCheck();
  const source = useSource();
  const params = useParams();
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const [multiLanguageOptions, setMultilanguageOptions] = useState([]);
  const [langNotification, setLangNotification] = useState(false);
  const commonSelectorData = useSelector(commonSelector, shallowEqual);
  const eventBasicInfo = useSelector(basicInfoSelector, shallowEqual);
  const ENABLE_REACTIONS_FEATURE = true;
  const { type: isEngagementEnabled = false, isHbsInteractiveMode } = useSelector(
    sessionEngagementSelector,
    shallowEqual
  );
  const agendaId = params?.sessionId;
  const [headers] = API.generateHeader(commonSelectorData, { agendaId }, null);
  const [isHbs3Selected, setHbs3Selected] = useState(false);

  React.useEffect(() => {
    source.AS = new Map();
    return () => {
      API.removeAllApi(source.AS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if(isBasicDetail) return;
    GETAPI(false, POST_STREAM_OPTIONS, source.AS, headers)
      .then((res) => {
        if (res.data.status === API.apiSuccessStatus) {
          const { stream_type_id, stream_subtype_id } = res.data.data;
          setHbs3Selected(stream_type_id === HBS && stream_subtype_id === HBS_3);
        } else {
          API.errStatusHandler(res, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBasicDetail]);

  useEffect(() => {
    if (eventLanguages) {
      const languages = [];
      for (const language of eventLanguages) {
        const langIndex = MultiLangOptions.findIndex((i) => i.langName === language.name);
        if (langIndex !== -1) {
          if (language.is_base === 1 && !selectedLanguage) {
            if (onLanguageChange)
              onLanguageChange({
                ...MultiLangOptions[langIndex],
                id: language.id,
              });
          }
          languages.push({
            ...MultiLangOptions[langIndex],
            id: language.id,
          });
        }
      }
      setMultilanguageOptions(languages);
    }
  }, [eventLanguages]);

  const onGotItClick = () => {
    setLangNotification(false);
    if (commonSelectorData && commonSelectorData?.eventId && commonSelectorData?.userId)
      localStorage.setItem(
        `${commonSelectorData?.eventId}-${commonSelectorData?.userId}-langNotificationSeen`,
        'true'
      );
  };

  useEffect(() => {
    if (typeof isLanguageSupport === 'boolean') {
      setLangNotification(isLanguageSupport);
    }
  }, [isLanguageSupport]);

  const getScrollClass = () => {
    let customClass = '';
    if (isScrollable) {
      customClass = 'toScroll';
      if (subheader) {
        customClass = `${customClass} with-subtitle`;
      } else {
        customClass = `${customClass} no-subtitle`;
      }
    }
    return customClass;
  };
  const getScrollClassFooter = () => {
    let fixFooter = '';
    if (isScrollable) {
      fixFooter = 'stickyFooter';
      if (isScrolling) {
        fixFooter = `${fixFooter} hide`;
      } else {
        fixFooter = `${fixFooter} show`;
      }
    }
    return fixFooter;
  };
  return (
    <Fade in timeout={{ enter: 500, exit: 300 }}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={title}
          subheader={subheader}
          subheaderTypographyProps={{
            variant: 'caption',
          }}
          titleTypographyProps={{
            component: 'h2',
          }}
          action={
            <>
              <Box className={classes.cardHeaderActions}>{headerActions || false}</Box>
              {isLanguageSupport ? (
                <Box mr={1}>
                  <MultiLanguageDropDown
                    onMenuSelect={onLanguageChange}
                    options={multiLanguageOptions}
                    isDisable={isDisable}
                    selectedLanguage={selectedLanguage}
                    isEdit={isEdit}
                  />
                </Box>
              ) : (
                false
              )}
            </>
          }
        />
        {langNotification &&
          (isLegalPage &&
          !localStorage.getItem(
            `${commonSelectorData?.eventId}-${commonSelectorData?.userId}-langNotificationSeen`
          ) ? (
            <Box className={classes.divider}>
              <MultiLanguageStatus onGotItClick={onGotItClick} />
            </Box>
          ) : !localStorage.getItem(
              `${commonSelectorData?.eventId}-${commonSelectorData?.userId}-langNotificationSeen`
            ) ? (
            <MultiLanguageStatus onGotItClick={onGotItClick} />
          ) : (
            false
          ))}
        {/* </Box>	   */}
        {!tagMentions && (
          <Box className={classes.divider}>
            <Divider />
          </Box>
        )}
        <CardContent
          className={`${
            !tagMentions ? classes.cardContent : classes.tagMentionCardContent
          } ${getScrollClass()}`}
          onScroll={() => handleScroll()}>
          {children}
        </CardContent>
        {!isEngagementEnabled ? (
          <>
            {ENABLE_REACTIONS_FEATURE &&
              isReactionsEnabled &&
              eventBasicInfo.eventType !== EventTypes.IN_PERSON && (
                <Box mr={3.75} ml={3.75}>
                  <ReactionEnabled
                    context="session"
                    className="reactionEnabledEngagement"
                    desc="Enabling this will allow attendees to react using emojis during a session."
                  />
                  {isHbsInteractiveMode && isHbs3Selected ? (
                    <RaiseHandEnabled
                      context="session"
                      className="reactionEnabledEngagement"
                      desc="Enabling this will allow attendees to raise hand and join the session as a guest speaker."
                    />
                  ) : null}
                </Box>
              )}
          </>
        ) : (
          <></>
        )}
        {actionProps && actionProps.label && (
          <>
            {!isScrolling && !isScrollable && !tagMentions && (
              <Box className={classes.divider}>
                <Divider />
              </Box>
            )}
            <Box
              className={`${classes.cardAction} ${getScrollClassFooter()} ${
                isScrolling && isScrollable ? 'none' : 'flex'
              }`}
              display="flex"
              justifyContent="flex-end">
              {cancelProps ? (
                <BtnWithLoader
                  className="uppercase"
                  color="primary"
                  size="small"
                  variant="outlined"
                  {...cancelProps}
                />
              ) : (
                false
              )}
              <BtnWithLoader
                disabled="true"
                className="uppercase"
                color="primary"
                size="small"
                variant="contained"
                {...actionProps}
              />
              {/* For another action button beside previous one*/}
              {addActionProps ? (
                <BtnWithLoader
                  className="uppercase"
                  color="primary"
                  size="small"
                  variant="contained"
                  {...addActionProps}
                />
              ) : (
                false
              )}
              {/* For another action button beside previous one*/}
            </Box>
          </>
        )}
      </Card>
    </Fade>
  );
};

export default CardWrapper;
