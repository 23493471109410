/* eslint-disable no-param-reassign */
import { SET_EVENT_DATA, REMOVE_EVENT_DATA, SET_CREDITS_TAB_VISIBILITY } from 'redux/actions/event-action';

const eventData = (state = {}, action) => {
  switch (action.type) {
    case SET_EVENT_DATA:
      state = { ...state, ...action.data };
      break;
    case REMOVE_EVENT_DATA:
      state = {};
      break;
    case SET_CREDITS_TAB_VISIBILITY:
      state = { ...state, isCreditsTabVisible: action.payload}
      break;
    default:
      break;
  }
  return state;
};

export default eventData;
