import React from 'react';
import InfoIcon from 'icons/svgInfoIconCustom';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorComponent: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFF7ED',
    borderRadius: '8px',
    padding: '10px 12px',
    '&.darkColor': {
      background: '#FFBF3F',
    },
    '& svg': {
      marginRight: theme.spacing(1.5),
      color: '#444444',
    },
    '& .ctaBtn': {
      '&:hover': {
        background: 'transparent',
      },
      '& .MuiButton-startIcon ': {
        '&>svg': {
          marginRight: 0,
          color: theme.palette.primary.main,
        },
      },
    },
  },
}));

const InfoMsgComponent = ({
  preTitle = '',
  title,
  darkColor,
  marginBottom = 2,
  infoIcon = false,
  subtitle,
  CTAIcon,
  CTAText,
  learnMore,
  learnMoreLink,
  className,
  fullWidth,
  onCtaClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <Box
      mb={marginBottom}
      className={`${classes.errorComponent} ${darkColor ? 'darkColor' : ''} ${className}`}
      display="flex"
      width={fullWidth || 'auto'}
      alignItems="center"
      justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {infoIcon || <InfoIcon />}
        <Box>
          <Box display="flex" alignItems="center">
            {preTitle && (
              <Typography
                variant="subtitle2"
                color="textPrimary"
                component="p"
                style={{ marginRight: 5 }}>
                {`${preTitle}`}
              </Typography>
            )}
            {title && (
              <Typography variant="body2" color="textPrimary" component="p">
                {title}
              </Typography>
            )}
            {learnMore && (
              <Box
                onClick={() => {
                  window.open(learnMoreLink, '_blank');
                }}
                component="a"
                variant="body2"
                ml={0.3}
                className="text-underline  cursor-pointer">
                Learn More
              </Box>
            )}
          </Box>
          {subtitle && (
            <Typography variant="body2" component="p" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      {CTAText && (
        <Button
          startIcon={CTAIcon}
          className="ctaBtn"
          variant="text"
          color="primary"
          onClick={onCtaClick}>
          {CTAText}
        </Button>
      )}
    </Box>
  );
};

export default InfoMsgComponent;
