/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
/* eslint-disable operator-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  List,
  Button,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  ListItem,
  InputLabel,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import SwitchField from 'custom-components/form-fields/switch';
import InputField from 'custom-components/form-fields/inputField';
import { Trash2, Eye, EyeOff, Plus, Navigation } from 'react-feather';
import Sidebar from 'custom-components/sidebar';
import CardWrapper from 'custom-components/cardWrapper';
import BtnGroup from 'custom-components/form-fields/btnGroup';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useSource } from 'lib/custom-common-hooks';
import { useHistory } from 'react-router-dom';
import { setNewMessage } from 'redux/actions/setting-actions';
import {
  PUTAPI,
  API,
  POSTAPI,
  GETAPI,
  GETAPIWITHCUSTOMDOMAIN,
  POSTAPIWITHCUSTOMDOMAIN,
} from 'api-setup/api-helper';
import {
  basicInfoSelector,
  commonSelector,
  currentEventLanguagesSelector,
  eventLanguagesSelector,
  userInfoSelector,
} from 'redux/custom-selector';
import {
  EVENT_SECTION_MAIN_SEC_LIST,
  EVENT_SECTION_ICON_LIB,
  EVENT_SECTION_SUB_SEC_LIST,
  EVENT_SECTION_VISIBILTY,
  EVENT_SECTION_MAIN_SEC_DETAIL,
  EVENT_SECTION_SUB_SEC_DELETE,
  EVENT_SECTION_SUB_SEC_DETAIL,
  EVENT_SECTION_MAIN_ORDER,
  EVENT_SECTION_SUB_ADD,
  EVENT_SECTION_EDIT_DETAILS,
  EVENT_SECTION_SUB_SEC_ORDER,
  GET_SIGNED_URL,
} from '../../api-setup/api-endpoints';
import { EventSectionV1Schema, EventSectionV1SchemaForBooth } from 'models/schema';
import Validator from 'models/validation';
import ConfirmationPopUp from 'custom-components/popUp';
import { Container, Draggable } from 'react-smooth-dnd';
import { applyDrag, getBrandEventImage } from 'lib/common-function';
import BtnWithLoader from 'custom-components/btnWithLoader';
import ProjectSetting from 'settings';
import LearnMoreLink from 'learnMoreLinks';
import CustomToolTip from 'custom-components/customToolTip';
import SvgLightBulb from 'icons/SvgLightBulb';
import { useStyles } from './style';
import {
  IconSidebarLoader,
  SubSectionLoad,
  EventSectionRight,
  EventSectionLoader,
} from './skeleton';
import { LanguageBox } from 'custom-components/languageBox';
import { restrictingCharacerInput } from 'settings';
import {
  getCalculatedCharLegth,
  getFileUploadDetails,
  uploadS3Promise,
} from 'lib/common-file-function';
import TextAreaField from 'custom-components/form-fields/textAreaField';
import { addUpdateEmbedAppIconListAsync } from 'redux/api-service/embed-apps';
import { eventEmbedAppSelector } from 'redux/selectors';
import { iFrameRegex } from 'models/regex';
import SvgUnionDotsIcon from 'icons/svgUnionDots';
import sanitiseIframe from '../../lib/sanitise-iframe';
import { isSingleFrameCount } from 'utility/validator';
import MobileAppEventSetting from './mobileAppEventSetting';
import { EmbedTypes } from 'pages/embed-apps/side-bar';
import RadioBtn from 'custom-components/form-fields/radioBtn';
import { FileUploadUI } from 'custom-components/form-fields/fileUploadField';
import { EventTypes } from 'pages/events/constants';
import { HEAVY_SESSION_COMMUNITY } from 'pages/session/sessions/constants';

const notificationTime = 8000;

const iconInitData = {
  currentPage: 1,
  totalCount: 0,
  totalPage: 0,
  data: [],
};

const iconInit = {
  id: 7,
  name: 'Reception',
  svg_image:
    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><defs><style>.gg{fill:none;}.ggg{fill:#a0a9bc;}</style></defs><rect class="gg" width="30" height="30"/><g transform="translate(5.212 4)"><path class="ggg" d="M19.5,22H1.5A1.538,1.538,0,0,1,0,20.5V1.5A1.538,1.538,0,0,1,1.5,0h18A1.538,1.538,0,0,1,21,1.5v19A1.538,1.538,0,0,1,19.5,22ZM1.5,1a.472.472,0,0,0-.5.5v19a.472.472,0,0,0,.5.5h18a.472.472,0,0,0,.5-.5V1.5a.472.472,0,0,0-.5-.5Z"/><g transform="translate(2 12)"><path class="ggg" d="M17.5,20H3.5A1.538,1.538,0,0,1,2,18.5v-6a.472.472,0,0,1,.5-.5.472.472,0,0,1,.5.5v6a.472.472,0,0,0,.5.5h14a.472.472,0,0,0,.5-.5v-6a.5.5,0,0,1,1,0v6A1.538,1.538,0,0,1,17.5,20Z" transform="translate(-2 -12)"/></g><g transform="translate(0 5)"><path class="ggg" d="M3,10A2.946,2.946,0,0,1,0,7V5.5A.472.472,0,0,1,.5,5a.472.472,0,0,1,.5.5V7A2,2,0,0,0,5,7V5.5A.472.472,0,0,1,5.5,5a.472.472,0,0,1,.5.5V7A2.946,2.946,0,0,1,3,10Z" transform="translate(0 -5)"/></g><g transform="translate(5 5)"><path class="ggg" d="M8,10A2.946,2.946,0,0,1,5,7V5.5A.472.472,0,0,1,5.5,5a.472.472,0,0,1,.5.5V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,8,10Z" transform="translate(-5 -5)"/></g><g transform="translate(10 5)"><path class="ggg" d="M13,10a2.946,2.946,0,0,1-3-3V5.5a.5.5,0,0,1,1,0V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,13,10Z" transform="translate(-10 -5)"/></g><g transform="translate(15 5)"><path class="ggg" d="M18,10a2.946,2.946,0,0,1-3-3V5.5a.5.5,0,0,1,1,0V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,18,10Z" transform="translate(-15 -5)"/></g></g></svg>',
};
const CUSTON_BASE_URL = ProjectSetting.customBaseURL2;

const defaultSubSec = {
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  options: [],
  apiDone: true,
};

const defultIcon = { ...iconInit };

const onSiteHSList = [
  { name: 'Speakers', apiName: 'show_speaker' },
  { name: 'Location', apiName: 'show_location' },
  { name: 'Sponsors', apiName: 'show_sponsor' },
  { name: 'Engage now CTA', apiName: 'show_engage_cta' },
  { name: 'Banner', apiName: 'show_banner' },
];

const virtualHSList = [
  { name: 'Speakers', apiName: 'show_speaker' },
  { name: 'Banner', apiName: 'show_banner' },
  { name: 'Join now CTA', apiName: 'show_join_now_cta' },
  { name: 'Sponsors', apiName: 'show_sponsor' },
];

const agendaHSTabs = {
  ONSITE: 'ONSITE',
  VIRTUAL: 'VIRTUAL',
};

const defaultAgendaComponentsVisibility = {
  [agendaHSTabs.VIRTUAL]: {
    show_speaker: 1,
    show_banner: 1,
    show_join_now_cta: 1,
    show_sponsor: 1,
  },
  [agendaHSTabs.ONSITE]: {
    show_speaker: 1,
    show_banner: 1,
    show_engage_cta: 1,
    show_sponsor: 1,
    show_location: 1,
  },
};

const isEnableSetToDefault = (existingVal) => {
  let isChanged = false;
  if (existingVal && Object.keys(existingVal)?.length) {
    if (Object.keys(existingVal)?.length !== Object.keys(defaultAgendaComponentsVisibility)?.length)
      isChanged = true;
    const virtualComp = existingVal[agendaHSTabs.VIRTUAL];
    if (virtualComp && Object.keys(virtualComp)) {
      if (
        virtualComp.show_speaker !==
        defaultAgendaComponentsVisibility[agendaHSTabs.VIRTUAL]?.show_speaker
      )
        isChanged = true;
      if (
        virtualComp.show_banner !==
        defaultAgendaComponentsVisibility[agendaHSTabs.VIRTUAL]?.show_banner
      )
        isChanged = true;
      if (
        virtualComp.show_join_now_cta !==
        defaultAgendaComponentsVisibility[agendaHSTabs.VIRTUAL]?.show_join_now_cta
      )
        isChanged = true;
      if (
        virtualComp.show_sponsor !==
        defaultAgendaComponentsVisibility[agendaHSTabs.VIRTUAL]?.show_sponsor
      )
        isChanged = true;
    }
    const onsiteComp = existingVal[agendaHSTabs.ONSITE];
    if (onsiteComp && Object.keys(onsiteComp)) {
      if (
        onsiteComp.show_speaker !==
        defaultAgendaComponentsVisibility[agendaHSTabs.ONSITE]?.show_speaker
      )
        isChanged = true;
      if (
        onsiteComp.show_banner !==
        defaultAgendaComponentsVisibility[agendaHSTabs.ONSITE]?.show_banner
      )
        isChanged = true;
      if (
        onsiteComp.show_engage_cta !==
        defaultAgendaComponentsVisibility[agendaHSTabs.ONSITE]?.show_engage_cta
      )
        isChanged = true;
      if (
        onsiteComp.show_sponsor !==
        defaultAgendaComponentsVisibility[agendaHSTabs.ONSITE]?.show_sponsor
      )
        isChanged = true;
      if (
        onsiteComp.show_location !==
        defaultAgendaComponentsVisibility[agendaHSTabs.ONSITE]?.show_location
      )
        isChanged = true;
    }
  }
  return isChanged;
};

const isDisabled = (activeTab) => {
  let isDisable = false;
  if (activeTab === 'mobile' || activeTab === 'mobile-app') {
    isDisable = true;
  }
  return isDisable;
};

const visibilityKey = (tab) => {
  const key = tab === 'mobile' ? 'is_visible' : 'mobile_app_visibility';
  return key;
};

function EventSectionsNewUI() {
  const classes = useStyles();
  const {
    eventList,
    dividerBlock,
    headingTitle,
    switchBtnStyle,
    eventBlockSetting,
    eventNameBlock,
    eventNameBlockCheck,
    peopleBlock,
    appchangeIconBtn,
    eventSettingBlock,
    peopleSubTitle,
    appSectionHeading,
    selectColumnField,
    selectColumns,
    switchBtnDrgStyle,
    groupDragable,
    disabledBlock,
    appRightBlockContent,
    changeIconBtn,
    peopleWrapper,
    peopleHeading,
    footerActionBtn,
    dragFieldLine,
  } = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const source = useSource();
  const history = useHistory();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const [loadingState, setLoadState] = React.useState({
    initLoad: false,
    iconLibLoad: false,
    eventMainSecLoad: false,
    subSectionLoad: false,
    subSectionLoadMore: false,
    eventSubStatusLoader: false,
    eventSecDetailLoad: false,
    eventSubSecDetailLoad: false,
    createOrEditEventSectionLoad: false,
    saveEventLoader: false,
    deleteEventLoader: false,
    eventStatusLoader: false,
    eventStatuCall: true,
    isUpload: false,
  });
  const [iconData, setIconData] = useState({ ...iconInitData });
  const [editErrObj, setEditErrObj] = useState({});
  const [mainEventSectionList, setMainEventSectionList] = useState([]);
  const [selSectionId, setSelSectionId] = useState('');
  const [isConfirmModalOpen, toggleDeleteModal] = useState(false);
  const [selStatusSectionId, setStatusSelSectionId] = useState('');
  const [selTempEventSectionData, setTempSelEventSectionData] = useState({});
  const [activeTab, setActiveTab] = useState('desktop');
  const [subSelSectionId, setSubSelSectionId] = useState('');
  const [selSubStatusSectionId, setSubStatusSelSectionId] = useState('');
  const [selSectionObj, setSelSectionObj] = useState({});
  const [secAction, setSecAction] = useState('editMainSec');
  const [deleteMainSectionId, setSdeleteMainSectionId] = useState(null);
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);
  const [selectedLanguage, setSelectedLanguage] = useState(currentEventLanguage);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [dragStart, setDragStart] = React.useState('');
  const [toastItems, setToastItems] = React.useState([]);
  const [isNewEventMobileSection, setIsNewEventMobileSEction] = React.useState(false);
  const { id: baseLangId } = useSelector((state) => state.BaseEventLanguage); // TODO : BaseLang Extract from redux
  const [embedType, setEmbedType] = useState(EmbedTypes.EMBED_CODE);
  const [fileData, setFileData] = useState({
    realFileName: '',
    fileName: '',
    fileBlob: null,
  });
  const [fileUploadData, setFileUploadData] = useState({
    uploadStatus: 'IDEAL',
    uploadingPer: 0,
  });
  // const [loadingState, setLoadState] = useState({
  //   isUpload: false,
  // });
  // const source = useSource();
  const [isCancelUpload, setCancelUpload] = useState(false);
  const [s3FileName, setS3FileName] = useState('');
  const basicEventInfo = useSelector(basicInfoSelector, shallowEqual);

  const [tabs, setTabs] = React.useState([]);
  const [activeTabSidePanel, setActiveTabSidePanel] = useState(
    basicEventInfo.eventType === EventTypes.VIRTUAL ? agendaHSTabs.VIRTUAL : agendaHSTabs.ONSITE
  );

  useEffect(() => {
    if (currentEventLanguage) {
      setSelectedLanguage(currentEventLanguage);
    }
  }, [currentEventLanguage]);
  const [peoplePrimary, setPeoplePrimary] = useState(0);
  const [activeAppIsEmbed, setActiveAppIsEmbed] = useState(false);
  const { iconList = [], isAddAppLoading = false } = useSelector(
    eventEmbedAppSelector,
    shallowEqual
  );
  const textAreas = document.getElementsByTagName('textarea');

  Array.prototype.forEach.call(textAreas, function (elem) {
    elem.placeholder = elem.placeholder.replace(/\\n/g, '\n');
  });
  useEffect(() => {
    dispatch(addUpdateEmbedAppIconListAsync({ name: '' }));
  }, [dispatch]);

  const APICALL = React.useCallback(
    (
      ACTION,
      reduxDataVal,
      methodType,
      URL,
      headerData,
      bodyData,
      withData = false,
      APICB,
      isShowMessage = true
    ) => {
      const [header, body] = API.generateHeader(reduxDataVal, headerData, bodyData);
      let APIPromise;
      if (methodType === 'GET') {
        APIPromise = GETAPI(false, URL, source.AS, header);
      } else if (methodType === 'POST') {
        APIPromise = POSTAPI(false, URL, source.AS, header, body);
      } else if (methodType === 'PUT') {
        APIPromise = PUTAPI(false, URL, source.AS, header, body);
      } else if (methodType === 'POSTAPIWITHCUSTOMDOMAIN') {
        APIPromise = POSTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, CUSTON_BASE_URL);
      } else if (methodType === 'GETAPIWITHCUSTOMDOMAIN') {
        APIPromise = GETAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, CUSTON_BASE_URL);
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message && isShowMessage)
            dispatch(setNewMessage({ message: resp.data.message, type: 'success', show: true }));
          if (withData) {
            APICB && APICB(ACTION, 'SUCCESS', resp.data);
          } else {
            APICB && APICB(ACTION, 'SUCCESS', resp.data.data);
          }
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            APICB && APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  /**
   * Get icon liberary
   */
  const getIconLib = () => {
    setLoadState({ ...loadingState, iconLibLoad: true });
    APICALL(
      'GET-EVENT-SECTION-LIST',
      reduxData,
      'POSTAPIWITHCUSTOMDOMAIN',
      EVENT_SECTION_ICON_LIB,
      { language: selectedLanguage?.id },
      null,
      true,
      (ACTION, type, data) => {
        setLoadState({ ...loadingState, iconLibLoad: false });
        if (type === 'SUCCESS') {
          if (data && data.icons) {
            if (data.icons.length > 0) {
              setIconData(data.icons);
            }
          }
        }
      }
    );
  };

  /**
   *
   */
  const handelClickOnSection = React.useCallback(
    (sectionId, item) => {
      setActiveAppIsEmbed(!!item.integration_meta_name);
      const isClickedOnEmbedApp = !!item.integration_meta_name;
      let apiCall = true;
      if (selSectionId && item.is_parent === 0) {
        if (selSectionId !== sectionId) {
          apiCall = true;
        } else if (eventLanguages && eventLanguages.length > 1) {
          apiCall = true;
        } else {
          apiCall = false;
        }
      } else {
        apiCall = true;
      }
      if (apiCall) {
        setSelSectionId(sectionId);
        setSubSelSectionId('');
        setLoadState({
          createOrEditEventSectionLoad: false,
          deleteEventLoader: false,
          eventMainSecLoad: false,
          eventSecDetailLoad: true,
          eventStatuCall: true,
          eventStatusLoader: false,
          eventSubSecDetailLoad: false,
          eventSubStatusLoader: false,
          iconLibLoad: false,
          initLoad: false,
          saveEventLoader: false,
          subSectionLoad: false,
          subSectionLoadMore: false,
        });
        const reqData = {
          data: {
            eventSection: sectionId,
          },
        };
        APICALL(
          'GET-EVENT-SECTION-DETAILS',
          reduxData,
          'POSTAPIWITHCUSTOMDOMAIN',
          EVENT_SECTION_MAIN_SEC_DETAIL,
          { language: isClickedOnEmbedApp ? baseLangId : selectedLanguage?.id }, // TODO : Keep base lang in case of EMBED APP
          reqData,
          true,
          (ACTION, type, data) => {
            setLoadState({
              createOrEditEventSectionLoad: false,
              deleteEventLoader: false,
              eventMainSecLoad: false,
              eventSecDetailLoad: false,
              eventStatuCall: true,
              eventStatusLoader: false,
              eventSubSecDetailLoad: false,
              eventSubStatusLoader: false,
              iconLibLoad: false,
              initLoad: false,
              saveEventLoader: false,
              subSectionLoad: false,
              subSectionLoadMore: false,
            });
            setSecAction('editMainSec');
            setEditErrObj({});
            if (type === 'SUCCESS') {
              if (data) {
                const editItem = data;
                editItem.is_parent = item.is_parent;
                setSelSectionObj(editItem);
                // for embedApp file upload feature
                if (editItem.file_name) {
                  setFileData({
                    realFileName: editItem.file_name,
                    fileName: editItem.file_name,
                    fileBlob: null,
                  });
                  setS3FileName(editItem.file_name);
                  setEmbedType(EmbedTypes.FILE_UPLOAD);
                } else if (editItem.embed_code) {
                  setEmbedType(EmbedTypes.EMBED_CODE);
                  setS3FileName('');
                  setFileData({
                    realFileName: '',
                    fileName: '',
                    fileBlob: null,
                  });
                }
                setTempSelEventSectionData(editItem);
                if (editItem.eventSectionMetaName === 'PEOPLE') {
                  setPeoplePrimary(editItem.is_primary);
                }
              }
            }
          }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selSectionId, selectedLanguage]
  );

  /**
   * Get main event section list
   * @param {*} typeData
   */
  const getListOfEventSection = (typeData) => {
    const bodyData = {
      data: {},
    };
    if (typeData === 'mobile-app') {
      bodyData.data.getForMobileApp = true;
    } else {
      bodyData.data.getMobileView = typeData === 'mobile';
    }
    setLoadState({ ...loadingState, eventMainSecLoad: true });
    APICALL(
      'GET-EVENT-SECTION-LIST',
      reduxData,
      'POSTAPIWITHCUSTOMDOMAIN',
      EVENT_SECTION_MAIN_SEC_LIST,
      { language: selectedLanguage?.id },
      bodyData,
      true,
      (ACTION, type, data) => {
        setLoadState({ ...loadingState, eventMainSecLoad: false });
        if (type === 'SUCCESS') {
          setToastItems([]);
          let tabList = [];
          if (data.isNewEventMobileSection) {
            setIsNewEventMobileSEction(data.isNewEventMobileSection);
            tabList.push(
              { label: 'Desktop', type: 'desktop', isChecked: typeData === 'desktop' },
              { label: 'Mobile Web', type: 'mobile', isChecked: typeData === 'mobile' },
              {
                label: 'Mobile App',
                type: 'mobile-app',
                isChecked: typeData === 'mobile-app',
              }
            );
            setTabs([...tabList]);
          } else {
            tabList.push(
              { label: 'Desktop', type: 'desktop', isChecked: typeData === 'desktop' },
              { label: 'Mobile', type: 'mobile', isChecked: typeData === 'mobile' }
            );
            setTabs([...tabList]);
          }
          if (data) {
            if (data.eventSection && data.eventSection.length > 0) {
              const listData = data.eventSection;
              let filterData = listData.filter((x) => x.is_visible === 1 && x.is_primary === 1)[0]
                .event_section_id;
              let index = listData.findIndex((x) => x.event_section_id === filterData);
              let findIndex = index > -1 ? index : selectedSectionIndex;
              setSelectedSectionIndex(findIndex);
              if (
                (listData.filter((x) => x[visibilityKey(typeData)] === 1).length > 0 &&
                  activeTab === 'desktop') ||
                activeTab === 'mobile-app'
              ) {
                const obj = listData.filter(
                  (x) => x[visibilityKey(typeData)] === 1 && x.is_primary === 1
                );
                if (
                  listData.length > 0 &&
                  listData[findIndex] &&
                  listData[findIndex].event_section_id
                ) {
                  handelClickOnSection(listData[findIndex].event_section_id, listData[findIndex]);
                } else {
                  const nobj = listData.filter(
                    (x) => x[visibilityKey(typeData)] === 1 && x.is_primary === 0
                  );
                  if (nobj.length > 0) {
                    if (!nobj[findIndex] && obj.length > 0) {
                      handelClickOnSection(
                        nobj[findIndex - listData.length].event_section_id,
                        nobj[findIndex - listData.length]
                      );
                    } else {
                      handelClickOnSection(nobj[findIndex].event_section_id, nobj[findIndex]);
                    }
                  }
                }
              } else {
                handelClickOnSection(listData[findIndex].event_section_id, listData[findIndex]);
              }
              listData.map((item) => {
                item.subSectionList = JSON.parse(JSON.stringify(defaultSubSec));
                if (item.is_parent && item.event_subsection_count > 0) {
                  if (item.event_subsection_list) {
                    item.subSectionList.options = JSON.parse(
                      JSON.stringify(JSON.parse(item.event_subsection_list))
                    );
                    item.subSectionList.totalcount = item.event_subsection_count;
                    item.subSectionList.totalPage =
                      item.event_subsection_count > 4
                        ? Math.ceil(item.event_subsection_count / 4)
                        : 1;
                  }
                }
                return 0;
              });
              setMainEventSectionList(listData);
            }
            if (data.eventSectionMeta) {
              // setEventSecMetaArr(data.eventSectionMeta)
            }
          }
        }
      }
    );
  };

  // useEffect(() => {
  //   getIconLib();
  //   getListOfEventSection(activeTab);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    getIconLib();
    getListOfEventSection(activeTab);
  }, [selectedLanguage]);

  const handelClickOnIcon = React.useCallback(
    (item) => {
      if (activeAppIsEmbed) {
        setSelSectionObj({
          ...selSectionObj,
          svg_image: item.svg_image,
          event_section_icon_id: null,
          integrated_app_icon_id: item.id,
        });
      } else {
        setSelSectionObj({
          ...selSectionObj,
          svg_image: item.svg_image,
          event_section_icon_id: item.id,
        });
      }

      setIsOpen(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selSectionObj]
  );

  const onDropEditSectionData = React.useCallback(
    (dropResult, type) => {
      if (type === 'display_category') {
        const oldData = JSON.parse(JSON.stringify(selSectionObj));
        const data = applyDrag(oldData.eventSectionDisplayCategory, dropResult);
        data.map((item, ind) => {
          item.order_index = ind + 1;
          return 0;
        });
        oldData.eventSectionDisplayCategory = data;
        setSelSectionObj(oldData);
      } else if (type === 'exhibitor_category') {
        const oldData = JSON.parse(JSON.stringify(selSectionObj));
        const data = applyDrag(oldData.eventSectionDisplayCategory, dropResult);
        data.map((item, ind) => {
          item.order_index = ind + 1;
          return 0;
        });
        oldData.eventSectionDisplayCategory = data;
        setSelSectionObj(oldData);
      } else if (type === 'display_profile_groups') {
        const oldData = JSON.parse(JSON.stringify(selSectionObj));
        const data = applyDrag(oldData.eventSectionProfile, dropResult);
        data.map((item, ind) => {
          item.order_index = ind + 1;
          return 0;
        });
        oldData.eventSectionProfile = data;
        setSelSectionObj(oldData);
      }
    },
    [selSectionObj]
  );

  /**
   * Get sub section
   * @param {*} mainSecId
   * @param {*} isFirstPage
   */
  const getListOfEventSubSection = (mainSecId, isFirstPage) => {
    let currPage = 1;
    const mainEventSectionListObj = JSON.parse(JSON.stringify(mainEventSectionList));
    const fIndex = mainEventSectionList.findIndex((x) => x.event_section_id === mainSecId);
    let mianSecDet = null;
    if (fIndex !== -1) {
      mianSecDet = JSON.parse(JSON.stringify(mainEventSectionList[fIndex]));
    }
    if (!isFirstPage) {
      if (mianSecDet) {
        currPage = mianSecDet.subSectionList.currentPage + 1;
      }
    }
    const header = {
      page: isFirstPage ? 1 : currPage,
      limit: 4,
      language: selectedLanguage?.id,
    };
    const reqBody = {
      data: {
        eventSectionId: mainSecId,
      },
    };
    let isCall = true;
    if (isFirstPage) {
      isCall = true;
    } else {
      if (mianSecDet.subSectionList.currentPage < mianSecDet.subSectionList.totalPage) {
        isCall = true;
      } else {
        isCall = false;
      }
    }
    // isCall = false;
    if (mianSecDet.subSectionList.apiDone && isCall) {
      if (isFirstPage) {
        mianSecDet.subSectionList.apiDone = false;
        mianSecDet.subSectionList.initLoad = true;
        mainEventSectionListObj[fIndex] = mianSecDet;
        // setMainEventSectionList(mainEventSectionListObj);
        setLoadState({ ...loadingState, subSectionLoad: true });
      } else {
        mianSecDet.subSectionList.apiDone = false;
        mianSecDet.subSectionList.loading = true;
        mainEventSectionListObj[fIndex] = mianSecDet;
        // setMainEventSectionList(mainEventSectionListObj);
        setLoadState({ ...loadingState, subSectionLoadMore: true });
      }

      APICALL(
        'GET-SUB-EVENT-SECTION-LIST',
        reduxData,
        'POSTAPIWITHCUSTOMDOMAIN',
        EVENT_SECTION_SUB_SEC_LIST,
        header,
        reqBody,
        true,
        (ACTION, type, data) => {
          if (type === 'SUCCESS') {
            if (data) {
              const respData = {
                currentPage: data.current_page,
                totalcount: data.total_count,
                totalPage: data.total_page,
                initLoad: false,
                loading: false,
                options: [],
                apiDone: true,
              };
              if (isFirstPage) {
                respData.initLoad = false;
                respData.options = data.eventSection;
              } else {
                respData.currentPage = currPage;
                respData.totalcount = mianSecDet.subSectionList.totalcount;
                respData.totalPage = mianSecDet.subSectionList.totalPage;
                respData.options = [...mianSecDet.subSectionList.options, ...data.eventSection];
                respData.loading = false;
              }
              mianSecDet.subSectionList = respData;

              mainEventSectionListObj[fIndex] = mianSecDet;
              setMainEventSectionList(mainEventSectionListObj);
            } else {
              mianSecDet.subSectionList.apiDone = true;
              mianSecDet.subSectionList.loading = false;
              mianSecDet.subSectionList.initLoad = false;
              mainEventSectionListObj[fIndex] = mianSecDet;
              setMainEventSectionList(mainEventSectionListObj);
            }
          } else {
            mianSecDet.subSectionList.apiDone = true;
            mianSecDet.subSectionList.loading = false;
            mianSecDet.subSectionList.initLoad = false;
            mainEventSectionListObj[fIndex] = mianSecDet;
            setMainEventSectionList(mainEventSectionListObj);
          }
          setLoadState({ ...loadingState, subSectionLoad: false, subSectionLoadMore: false });
        }
      );
    }
  };

  /**
   * Rearrnge event section
   */
  const onDropPrim = React.useCallback(
    async (dropResult) => {
      const headerData = {
        language: selectedLanguage?.id,
        ...reduxData,
      };
      const [headers] = API.generateHeader(reduxData, headerData);
      if (activeTab === 'desktop') {
        let showTopNavMes = false;
        let topMovedMessage = '';
        if (dropResult.payload.is_primary === 0) {
          showTopNavMes = true;
          topMovedMessage = `Success! ${dropResult.payload.label} moved to the top navigation.`;
        }
        dropResult.payload.is_primary = 1;
        dropResult.removedIndex = mainEventSectionList
          .filter((x) => x.is_primary === 1)
          .findIndex((x) => x.event_section_id === dropResult.payload.event_section_id);
        const data = applyDrag(
          mainEventSectionList.filter((x) => x.is_primary === 1),
          dropResult
        );
        const mainEventSectionListData = JSON.parse(JSON.stringify(mainEventSectionList));
        const index = mainEventSectionListData.findIndex(
          (x) => x.event_section_id === dropResult.payload.event_section_id
        );
        mainEventSectionListData[index].is_primary = 1;
        const topNavigationItem = mainEventSectionListData.filter(
          (x) => x.is_primary === 1 && x.event_section_id !== dropResult.payload.event_section_id
        );
        const repData = [];
        const stData = [];
        let indexOfItem = 0;
        data.map((item) => {
          if (item.is_primary === 1) {
            item.order_index = indexOfItem;
            repData.push({
              eventSectionId: item.event_section_id,
              orderIndex: item.order_index,
              is_primary: 1,
            });
            indexOfItem++;
            stData.push(item);
          }
          return 0;
        });
        const subObjArr = JSON.parse(
          JSON.stringify(mainEventSectionListData.filter((x) => x.is_primary === 0))
        );
        subObjArr.map((item) => {
          stData.push(item);
          return 0;
        });

        const reqDataValue = [];
        let indexOfItemVal = 0;
        stData.map((itemV) => {
          itemV.order_index = indexOfItemVal;
          reqDataValue.push({
            eventSectionId: itemV.event_section_id,
            orderIndex: itemV.order_index,
            is_primary: itemV.is_primary,
          });
          indexOfItemVal++;
          return 0;
        });

        let isSidePanelToTopNav = false;
        let messageVal = '';
        const findTopItem = topNavigationItem[topNavigationItem.length - 1];
        if (topNavigationItem.length >= 7) {
          isSidePanelToTopNav = true;
          showTopNavMes = false;
          stData.map((item) => {
            if (item.event_section_id === findTopItem.event_section_id) {
              item.is_primary = 0;
            }
            return 0;
          });

          reqDataValue.map((itemV) => {
            if (itemV.eventSectionId === findTopItem.event_section_id) {
              itemV.is_primary = 0;
            }
            return 0;
          });

          messageVal = `Success! ${dropResult.payload.label} move to the top navigation.For the best user
           experience.${findTopItem.label} has been moved to the side panel`;
        }
        const visibility = stData.filter((x) => x.is_primary === 0 && x.is_visible === 0);
        let isVisible = '';
        if (stData.filter((x) => x.is_primary === 0).length === visibility.length) {
          isVisible = true;
        }
        if (isVisible) {
          const updateData = stData.filter((x) => x.is_primary === 0)[0];
          updateData.is_visible = 1;
          if (updateData.event_subsection_list !== null) {
            if (updateData.is_parent) {
              const findIndex = stData.findIndex(
                (x) => x.event_section_id === updateData.event_section_id
              );
              if (updateData.is_visible) {
                if (
                  stData[findIndex].subSectionList &&
                  stData[findIndex].subSectionList.options &&
                  stData[findIndex].subSectionList.options.length > 0
                ) {
                  stData[findIndex].subSectionList.options.map((item) => {
                    item.is_visible = 1;
                    return 0;
                  });
                }
              }
            }
          }
          await updateVisibilityAPICall(updateData);
        }

        setMainEventSectionList([...stData]);
        const bodyData = {
          data: {
            isDesktopView: activeTab === 'desktop',
            eventSectionOrder: reqDataValue,
          },
        };
        if (activeTab === 'mobile-app') {
          bodyData.data.isMobileAppView = true;
        }

        // if (dropResult.addedIndex !== dropResult.removedIndex) {
        POSTAPIWITHCUSTOMDOMAIN(
          false,
          EVENT_SECTION_MAIN_ORDER,
          source.AS,
          headers,
          bodyData,
          CUSTON_BASE_URL
        )
          .then((resp) => {
            const { status, message } = resp.data;
            if (status === 200) {
              let findTopItem;
              const itemsMoved = [];
              if (topNavigationItem.length >= 7) {
                findTopItem = topNavigationItem[topNavigationItem.length - 1];
                itemsMoved.push(findTopItem.event_section_id);
              }
              itemsMoved.push(dropResult.payload.event_section_id);
              setToastItems(itemsMoved);
              setTimeout(() => {
                setToastItems([]);
              }, notificationTime);
              if (isSidePanelToTopNav) {
                dispatch(
                  setNewMessage({
                    message: messageVal,
                    type: 'success',
                    show: true,
                    sec: notificationTime,
                  })
                );
              } else if (showTopNavMes === true) {
                dispatch(
                  setNewMessage({
                    message: topMovedMessage,
                    type: 'success',
                    show: true,
                    sec: notificationTime,
                  })
                );
              } else {
                dispatch(
                  setNewMessage({ message, type: 'success', show: true, sec: notificationTime })
                );
              }
              setMainEventSectionList([...stData]);
              setDragStart('');
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
        // }
      } else {
        const data = applyDrag(mainEventSectionList, dropResult);
        const repData = [];
        const stData = [];
        data.map((item, ind) => {
          item.order_index = ind;
          repData.push({
            eventSectionId: item.event_section_id,
            orderIndex: item.order_index,
          });
          stData.push(item);
          return 0;
        });
        setMainEventSectionList([...stData]);
        const bodyData = {
          data: {
            eventSectionOrder: repData,
          },
        };
        if (activeTab === 'mobile-app') {
          bodyData.data.isMobileAppView = true;
        } else {
          bodyData.data.isDesktopView = activeTab === 'desktop';
        }
        // if (dropResult.addedIndex !== dropResult.removedIndex) {
        const [headers, body] = API.generateHeader(reduxData, headerData, bodyData);
        // if (dropResult.addedIndex !== dropResult.removedIndex) {
        POSTAPIWITHCUSTOMDOMAIN(
          false,
          EVENT_SECTION_MAIN_ORDER,
          source.AS,
          headers,
          bodyData,
          CUSTON_BASE_URL
        ).then((resp) => {
          const { status, message } = resp.data;
          if (status === 200) {
            // console.log(dropResult.payload.event_section_id);
            setToastItems([dropResult.payload.event_section_id]);
            setTimeout(() => {
              setToastItems([]);
            }, 7000);
            dispatch(
              setNewMessage({
                message,
                type: 'success',
                show: true,
                sec: notificationTime,
              })
            );
            setMainEventSectionList([...stData]);
            setDragStart('');
          } else {
            API.errStatusHandler(resp, { dispatch, history });
          }
        });
      }
    },
    [mainEventSectionList, APICALL, reduxData, activeTab]
  );

  const updateVisibilityAPICall = (dataV) => {
    dataV.isSubsection = false;
    setStatusSelSectionId(dataV.event_section_id);
    dataV.is_visible = 1;
    const reqData = {
      data: {
        event_section_id: dataV.event_section_id,
        is_visible: dataV.is_visible,
        isSubsection: dataV.isSubsection,
        is_parent: dataV.is_parent,
      },
    };
    if (activeTab === 'mobile-app') {
      reqData.data.isMobileAppView = true;
    }

    setLoadState({ ...loadingState, eventStatusLoader: true, eventStatuCall: false });
    APICALL(
      'UPDATE-EVENT-SECTION-STATUS',
      reduxData,
      'POSTAPIWITHCUSTOMDOMAIN',
      EVENT_SECTION_VISIBILTY,
      { language: selectedLanguage?.id },
      reqData,
      false,
      (ACTION, type, data) => {
        setLoadState({
          ...loadingState,
          eventStatusLoader: false,
          eventStatuCall: true,
        });
        setStatusSelSectionId('');
      }
    );
  };

  const onDropSec = React.useCallback(
    async (dropResult) => {
      let showTopNavMes = false;
      let topMovedMessage = '';
      if (dropResult.payload.is_primary === 1) {
        showTopNavMes = true;
        topMovedMessage = `Success! ${dropResult.payload.label} moved to the side panel.`;
      }
      dropResult.payload.is_primary = 0;
      dropResult.removedIndex = mainEventSectionList
        .filter((x) => x.is_primary === 0)
        .findIndex((x) => x.event_section_id === dropResult.payload.event_section_id);
      dropResult.removeIndex = mainEventSectionList.findIndex(
        (x) => x.event_section_id === dropResult.payload.event_section_id
      );
      const data = applyDrag(
        mainEventSectionList.filter((x) => x.is_primary === 0),
        dropResult
      );
      const mainEventSectionListData = JSON.parse(JSON.stringify(mainEventSectionList));
      const index = mainEventSectionListData.findIndex(
        (x) => x.event_section_id === dropResult.payload.event_section_id
      );
      mainEventSectionListData[index].is_primary = 0;
      const repData = [];
      const stData = [];
      let indexOfItem = 0;
      data.map((item, ind) => {
        if (item.is_primary === 0) {
          item.order_index = ind;
          item.order_index = indexOfItem;
          repData.push({
            eventSectionId: item.event_section_id,
            orderIndex: item.order_index,
            is_primary: item.is_primary,
          });
          indexOfItem++;
          stData.push(item);
        }
        return 0;
      });
      const subObjArr = JSON.parse(
        JSON.stringify(mainEventSectionListData.filter((x) => x.is_primary === 1))
      );
      subObjArr.map((item) => {
        stData.push(item);
        return 0;
      });

      const visibility = stData.filter((x) => x.is_primary === 1 && x.is_visible === 0);
      let isVisible = '';
      if (stData.filter((x) => x.is_primary === 1).length === visibility.length) {
        isVisible = true;
      }
      if (isVisible) {
        const updateData = stData.filter((x) => x.is_primary === 1)[0];
        updateData.is_visible = 1;
        if (updateData.event_subsection_list !== null) {
          if (updateData.is_parent) {
            const findIndex = stData.findIndex(
              (x) => x.event_section_id === updateData.event_section_id
            );
            if (updateData.is_visible) {
              if (
                stData[findIndex].subSectionList &&
                stData[findIndex].subSectionList.options &&
                stData[findIndex].subSectionList.options.length > 0
              ) {
                stData[findIndex].subSectionList.options.map((item) => {
                  item.is_visible = 1;
                  return 0;
                });
              }
            }
          }
        }
        await updateVisibilityAPICall(updateData);
      }

      setMainEventSectionList(stData);
      const reqDataValue = [];
      let indexOfItemVal = 0;
      stData.map((itemV) => {
        itemV.order_index = indexOfItemVal;
        reqDataValue.push({
          eventSectionId: itemV.event_section_id,
          orderIndex: itemV.order_index,
          is_primary: itemV.is_primary,
        });
        indexOfItemVal++;
        return 0;
      });
      const bodyData = {
        data: {
          isDesktopView: activeTab === 'desktop',
          eventSectionOrder: reqDataValue,
        },
      };
      const headerData = {
        language: selectedLanguage?.id,
        ...reduxData,
      };
      const [headers, body] = API.generateHeader(reduxData, headerData, bodyData);
      // if (dropResult.addedIndex !== dropResult.removeIndex) {
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        EVENT_SECTION_MAIN_ORDER,
        source.AS,
        headers,
        bodyData,
        CUSTON_BASE_URL
      )
        .then((resp) => {
          const { status, message } = resp.data;
          if (status === 200) {
            setToastItems([dropResult.payload.event_section_id]);
            setTimeout(() => {
              setToastItems([]);
            }, 7000);
            if (showTopNavMes) {
              dispatch(
                setNewMessage({
                  message: topMovedMessage,
                  type: 'success',
                  show: true,
                  sec: notificationTime,
                })
              );
            } else {
              dispatch(
                setNewMessage({ message, type: 'success', show: true, sec: notificationTime })
              );
            }
            setDragStart('');
          } else API.errStatusHandler(resp, { dispatch, history });
        })
        .catch(API.catchHandler);
      // }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [mainEventSectionList, APICALL, reduxData]
  );

  const onDropSub = React.useCallback(
    async (dropResult, col, eventItem) => {
      if (!(dropResult.payload.is_parent && dropResult.payload.is_parent === 1)) {
        if (eventItem.event_section_id !== dropResult.payload.event_section_id) {
          return dispatch(
            setNewMessage({
              message: 'Subsection order index update failed!',
              type: 'error',
              show: true,
              sec: notificationTime,
            })
          );
        }

        let subSecdata = applyDrag(removeDuplicates(eventItem.subSectionList.options), dropResult);
        const repData = [];
        const stData = [];
        subSecdata.map((item, ind) => {
          item.order_index = ind;
          repData.push({
            subSectionId: item.event_subsection_id,
            orderIndex: item.order_index,
          });
          stData.push(item);
          return 0;
        });
        let idx = mainEventSectionList
          .map((obj) => obj.event_section_id)
          .indexOf(eventItem.event_section_id);
        let tempMainSectionList = mainEventSectionList;
        tempMainSectionList[idx].subSectionList.options = stData;
        tempMainSectionList[idx].event_subsection_list = JSON.stringify(stData);
        setMainEventSectionList(tempMainSectionList);
        const bodyData = {
          data: {
            isDesktopView: activeTab === 'desktop',
            subSectionOrder: repData,
          },
        };
        if (activeTab === 'mobile-app') {
          bodyData.data.isMobileAppView = true;
        }
        const header = {
          language: selectedLanguage?.id,
          eventSectionId: eventItem.event_section_id,
        };
        setDragStart('');
        if (dropResult.addedIndex !== dropResult.removedIndex) {
          APICALL(
            'REARRANGE-SUB-SECTION-LIST',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_SUB_SEC_ORDER,
            header,
            bodyData,
            false,
            () => {
              setDragStart('');
            }
          );
        }
      }
    },
    [mainEventSectionList, APICALL, reduxData, activeTab]
  );

  const handelClickOnUpdateEventStatusMobileApp = React.useCallback(
    (sectionDet, val) => {
      const lengthOfEvt =
        mainEventSectionList.filter((x) => x[visibilityKey(activeTab)] === 1).length || 0;
      let isAPICall = true;
      if (lengthOfEvt === 1 && val === 0) {
        isAPICall = false;
      }
      if (isAPICall) {
        if (loadingState.eventStatuCall) {
          sectionDet.isSubsection = false;
          setStatusSelSectionId(sectionDet.event_section_id);
          sectionDet.is_visible = val;
          if (activeTab === 'mobile-app') {
            sectionDet[visibilityKey(activeTab)] = val;
          }

          const reqData = {
            data: {
              event_section_id: sectionDet.event_section_id,
              is_visible: sectionDet.is_visible,
              isSubsection: sectionDet.isSubsection,
              is_parent: sectionDet.is_parent,
            },
          };
          if (activeTab === 'mobile-app') {
            reqData.data.isMobileAppView = true;
          }

          setLoadState({ ...loadingState, eventStatusLoader: true, eventStatuCall: false });
          APICALL(
            'UPDATE-EVENT-SECTION-STATUS',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_VISIBILTY,
            { language: selectedLanguage?.id },
            reqData,
            false,
            (ACTION, type, data) => {
              setLoadState({
                ...loadingState,
                eventStatusLoader: false,
                eventStatuCall: true,
              });
              setStatusSelSectionId('');
              if (type === 'SUCCESS') {
                const oldData = JSON.parse(JSON.stringify(mainEventSectionList));
                const findIndex = oldData.findIndex(
                  (x) => x.event_section_id === sectionDet.event_section_id
                );
                if (findIndex !== -1) {
                  oldData[findIndex][visibilityKey(activeTab)] = val;
                  if (oldData[findIndex].is_parent) {
                    if (
                      oldData[findIndex].subSectionList &&
                      oldData[findIndex].subSectionList.options &&
                      oldData[findIndex].subSectionList.options.length > 0
                    ) {
                      if (val === 0) {
                        oldData[findIndex].subSectionList.options.map((item) => {
                          item[[visibilityKey(activeTab)]] = 0;
                          return 0;
                        });
                      } else {
                        oldData[findIndex].subSectionList.options.map((item) => {
                          item[[visibilityKey(activeTab)]] = 1;
                          return 0;
                        });
                      }
                    }
                  }
                  setMainEventSectionList(oldData);
                }
                if (val === 0) {
                  if (findIndex === 0) {
                    if (oldData.length > 0) {
                      const selData = oldData.find(
                        (x) =>
                          x[visibilityKey(activeTab)] === 1 &&
                          x.is_primary === selSectionObj.is_primary
                      );
                      const oData = oldData[findIndex];
                      if (
                        oData &&
                        selSectionObj &&
                        oData.event_section_id === selSectionObj.event_section_id
                      ) {
                        handelClickOnSection(selData.event_section_id, selData);
                      }
                    }
                  } else {
                    if (oldData.length > 0) {
                      const selData = oldData.find(
                        (x) =>
                          x[visibilityKey(activeTab)] === 1 &&
                          x.is_primary === selSectionObj.is_primary
                      );
                      const oData = oldData[findIndex];
                      if (
                        oData &&
                        selSectionObj &&
                        oData.event_section_id === selSectionObj.event_section_id
                      ) {
                        handelClickOnSection(selData.event_section_id, selData);
                      }
                    }
                  }
                }
              } else {
                dispatch(
                  setNewMessage({
                    message: data.message,
                    type: 'error',
                    show: true,
                    sec: notificationTime,
                  })
                );
              }
            }
          );
        } else {
          dispatch(
            setNewMessage({
              message:
                'Section visibility changes are being processed. Please try in a few seconds.',
              type: 'error',
              show: true,
              sec: notificationTime,
            })
          );
        }
      } else {
        dispatch(
          setNewMessage({
            message: 'Not allowed. At least one section has to be enabled',
            type: 'error',
            show: true,
            sec: notificationTime,
          })
        );
      }
      //	eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selStatusSectionId, mainEventSectionList, loadingState, selSectionObj]
  );
  /**
   * Update visbilty of main and sub section
   */
  const handelClickOnUpdateEventStatus = React.useCallback(
    (sectionDet, val) => {
      let lengthOfEvt = 0;
      if (sectionDet.is_primary === 1) {
        lengthOfEvt = mainEventSectionList.filter(
          (x) => x.is_visible === 1 && x.is_primary === 1
        ).length;
      } else {
        lengthOfEvt = mainEventSectionList.filter(
          (x) => x.is_visible === 1 && x.is_primary === 0
        ).length;
      }
      let isAPICall = true;
      if (lengthOfEvt === 1 && val === 0) {
        isAPICall = false;
      }
      if (isAPICall) {
        if (loadingState.eventStatuCall) {
          sectionDet.isSubsection = false;
          setStatusSelSectionId(sectionDet.event_section_id);
          sectionDet.is_visible = val;
          const reqData = {
            data: {
              event_section_id: sectionDet.event_section_id,
              is_visible: sectionDet.is_visible,
              isSubsection: sectionDet.isSubsection,
              is_parent: sectionDet.is_parent,
            },
          };
          if (activeTab === 'mobile-app') {
            reqData.data.isMobileAppView = true;
          }

          setLoadState({ ...loadingState, eventStatusLoader: true, eventStatuCall: false });
          APICALL(
            'UPDATE-EVENT-SECTION-STATUS',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_VISIBILTY,
            { language: selectedLanguage?.id },
            reqData,
            false,
            (ACTION, type, data) => {
              setLoadState({
                ...loadingState,
                eventStatusLoader: false,
                eventStatuCall: true,
              });
              setStatusSelSectionId('');
              if (type === 'SUCCESS') {
                const oldData = JSON.parse(JSON.stringify(mainEventSectionList));
                const findIndex = oldData.findIndex(
                  (x) => x.event_section_id === sectionDet.event_section_id
                );
                if (findIndex !== -1) {
                  oldData[findIndex].is_visible = val;
                  if (oldData[findIndex].is_parent) {
                    if (
                      oldData[findIndex].subSectionList &&
                      oldData[findIndex].subSectionList.options &&
                      oldData[findIndex].subSectionList.options.length > 0
                    ) {
                      if (val === 0) {
                        oldData[findIndex].subSectionList.options.map((item) => {
                          item.is_visible = 0;
                          return 0;
                        });
                        if (selSectionObj.is_primary === undefined) {
                          let filterValue = oldData.find(
                            (x) => x.event_section_meta_id === selSectionObj.event_section_meta_id
                          );
                          if (filterValue === undefined) filterValue = oldData[findIndex];
                          if (filterValue !== undefined) {
                            const isPrimary = filterValue.is_primary;

                            const selectedData = oldData.find(
                              (x) => x.is_visible === 1 && x.is_primary === isPrimary
                            );
                            const nextIndex = (findIndex + 1) % oldData.length;

                            handelClickOnSection(selectedData.event_section_id, selectedData);
                          }
                        }
                      } else {
                        oldData[findIndex].subSectionList.options.map((item) => {
                          item.is_visible = 1;
                          return 0;
                        });
                      }
                    }
                  }
                  setMainEventSectionList(oldData);
                }
                if (val === 0) {
                  if (findIndex === 0) {
                    if (oldData.length > 0) {
                      const selData = oldData.find(
                        (x) => x.is_visible === 1 && x.is_primary === selSectionObj.is_primary
                      );
                      const oData = oldData[findIndex];
                      if (
                        oData &&
                        selSectionObj &&
                        oData.event_section_id === selSectionObj.event_section_id
                      ) {
                        handelClickOnSection(selData.event_section_id, selData);
                      }
                    }
                  } else {
                    if (oldData.length > 0) {
                      const selData = oldData.find(
                        (x) => x.is_visible === 1 && x.is_primary === selSectionObj.is_primary
                      );
                      const oData = oldData[findIndex];
                      if (selSectionObj.is_primary === undefined) {
                        const filterValue = oldData.find(
                          (x) => x.event_section_meta_id === selSectionObj.event_section_meta_id
                        );
                        const isPrimary = filterValue.is_primary;

                        const selectedData = oldData.find(
                          (x) => x.is_visible === 1 && x.is_primary === isPrimary
                        );
                        // const nextIndex = (findIndex + 1) % oldData.length;

                        handelClickOnSection(selectedData.event_section_id, selectedData);
                      }
                      if (
                        oData &&
                        selSectionObj &&
                        oData.event_section_id === selSectionObj.event_section_id
                      ) {
                        handelClickOnSection(selData.event_section_id, selData);
                      }
                    }
                  }
                }
              } else {
                dispatch(
                  setNewMessage({
                    message: data.message,
                    type: 'error',
                    show: true,
                    sec: notificationTime,
                  })
                );
              }
            }
          );
        } else {
          dispatch(
            setNewMessage({
              message:
                'Section visibility changes are being processed. Please try in a few seconds.',
              type: 'error',
              show: true,
              sec: notificationTime,
            })
          );
        }
      } else {
        dispatch(
          setNewMessage({
            message: 'Not allowed. At least one section has to be enabled',
            type: 'error',
            show: true,
            sec: notificationTime,
          })
        );
      }
      //	eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selStatusSectionId, mainEventSectionList, loadingState, selSectionObj]
  );

  /**
   *
   */
  const handelClickOnSubSection = React.useCallback(
    (sectionId, subSectionId, item) => {
      let apiCall = true;
      if (subSelSectionId) {
        if (subSelSectionId !== subSectionId) {
          apiCall = true;
        } else {
          apiCall = false;
        }
      } else {
        apiCall = true;
      }
      if (apiCall) {
        setSubSelSectionId(subSectionId);
        setSelSectionId(sectionId);
        setLoadState({
          createOrEditEventSectionLoad: false,
          deleteEventLoader: false,
          eventMainSecLoad: false,
          eventSecDetailLoad: false,
          eventStatuCall: true,
          eventStatusLoader: false,
          eventSubSecDetailLoad: true,
          eventSubStatusLoader: false,
          iconLibLoad: false,
          initLoad: false,
          saveEventLoader: false,
          subSectionLoad: false,
          subSectionLoadMore: false,
        });
        const reqData = {
          data: {
            eventSubsection: subSectionId,
            // "eventSectionMeta": metaObj
            eventSectionMetaId: item.event_section_meta_id,
          },
        };
        APICALL(
          'GET-EVENT-SUB-SECTION-DETAILS',
          reduxData,
          'POSTAPIWITHCUSTOMDOMAIN',
          EVENT_SECTION_SUB_SEC_DETAIL,
          { language: selectedLanguage?.id },
          reqData,
          true,
          (ACTION, type, data) => {
            setLoadState({
              createOrEditEventSectionLoad: false,
              deleteEventLoader: false,
              eventMainSecLoad: false,
              eventSecDetailLoad: false,
              eventStatuCall: true,
              eventStatusLoader: false,
              eventSubSecDetailLoad: false,
              eventSubStatusLoader: false,
              iconLibLoad: false,
              initLoad: false,
              saveEventLoader: false,
              subSectionLoad: false,
              subSectionLoadMore: false,
            });
            setSecAction('editSubSec');
            setEditErrObj({});
            if (type === 'SUCCESS') {
              if (data) {
                const editItem = data;
                editItem.is_parent = 0;
                setSelSectionObj(editItem);
                setTempSelEventSectionData(editItem);
              }
            }
          }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [subSelSectionId]
  );

  const handelClickOnUpdateSubSecEventStatusMobileAPP = React.useCallback(
    (sectionDet, val, mainSecId) => {
      const oldData = JSON.parse(JSON.stringify(mainEventSectionList));
      const findIndex = oldData.findIndex((x) => x.event_section_id === mainSecId);
      let callApi = true;
      if (findIndex !== -1 && val === 0) {
        if (
          oldData[findIndex].subSectionList.options.filter((x) => x[visibilityKey(activeTab)] === 1)
            .length > 1
        ) {
          callApi = true;
        } else {
          if (
            oldData[findIndex].is_primary === 1 &&
            oldData.filter((x) => x[visibilityKey(activeTab)] === 1 && x.is_primary === 1).length >
              1
          ) {
            callApi = true;
          } else if (
            oldData[findIndex].is_primary === 0 &&
            oldData.filter((x) => x[visibilityKey(activeTab)] === 1 && x.is_primary === 0).length >
              1
          ) {
            callApi = true;
          } else {
            callApi = false;
          }
        }
      }
      if (callApi) {
        if (loadingState.eventStatuCall) {
          sectionDet.isSubsection = true;
          setSubStatusSelSectionId(sectionDet.event_subsection_id);
          sectionDet[visibilityKey(activeTab)] = val;
          const reqData = {
            data: {
              event_section_id: sectionDet.event_subsection_id,
              is_visible: sectionDet[visibilityKey(activeTab)],
              isSubsection: sectionDet.isSubsection,
              is_parent: null,
            },
          };
          if (activeTab === 'mobile-app') {
            reqData.data.isMobileAppView = true;
          }

          setLoadState({ ...loadingState, eventSubStatusLoader: true, eventStatuCall: false });
          APICALL(
            'UPDATE-EVENT-SECTION-STATUS',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_VISIBILTY,
            { language: selectedLanguage?.id },
            reqData,
            true,
            (ACTION, type, data) => {
              setLoadState({
                ...loadingState,
                eventSubStatusLoader: false,
                eventStatuCall: true,
              });
              setSubStatusSelSectionId('');
              if (type === 'SUCCESS') {
                let visible_subsection_count = -1;
                if (typeof data.visible_subsection_count !== 'undefined') {
                  visible_subsection_count = JSON.parse(
                    JSON.stringify(data.visible_subsection_count)
                  );
                }
                if (findIndex !== -1) {
                  const indexOfSubsec = oldData[findIndex].subSectionList.options.findIndex(
                    (x) => x.event_subsection_id === sectionDet.event_subsection_id
                  );
                  if (indexOfSubsec !== -1) {
                    oldData[findIndex].subSectionList.options[indexOfSubsec][
                      visibilityKey(activeTab)
                    ] = val;
                    if (
                      val === 0 &&
                      visible_subsection_count !== -1 &&
                      visible_subsection_count === 0
                    ) {
                      oldData[findIndex][visibilityKey(activeTab)] = 0;
                    } else {
                      oldData[findIndex][visibilityKey(activeTab)] = 1;
                    }
                    setMainEventSectionList(oldData);
                    if (
                      val === 0 &&
                      selSectionObj.event_section_id === sectionDet.event_subsection_id
                    ) {
                      const vIndex = oldData[findIndex].subSectionList.options.findIndex(
                        (x) =>
                          x[visibilityKey(activeTab)] &&
                          x[visibilityKey(activeTab)].toString() === '1'
                      );
                      if (vIndex !== -1) {
                        handelClickOnSubSection(
                          oldData[findIndex].event_section_id,
                          oldData[findIndex].subSectionList.options[vIndex].event_subsection_id,
                          oldData[findIndex].subSectionList.options[vIndex]
                        );
                      } else {
                        const pVindex = oldData.findIndex(
                          (x) => x.is_visible && x.is_visible.toString() === '1'
                        );
                        if (pVindex !== -1) {
                          handelClickOnSection(oldData[pVindex].event_section_id, oldData[pVindex]);
                        }
                      }
                    }
                  }
                }
              } else {
                dispatch(
                  setNewMessage({
                    message: data.message,
                    type: 'error',
                    show: true,
                    sec: notificationTime,
                  })
                );
              }
            }
          );
        } else {
          dispatch(
            setNewMessage({
              message:
                'Section visibility changes are being processed. Please try in a few seconds.',
              type: 'error',
              show: true,
              sec: notificationTime,
            })
          );
        }
      } else {
        dispatch(
          setNewMessage({
            message: 'Not allowed. At least one section has to be enabled',
            type: 'error',
            show: true,
            sec: notificationTime,
          })
        );
      }
      //	eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selSubStatusSectionId, mainEventSectionList, loadingState, selSectionObj]
  );

  /**
   * Update visbilty of main and sub section
   */
  const handelClickOnUpdateSubSecEventStatus = React.useCallback(
    (sectionDet, val, mainSecId) => {
      const oldData = JSON.parse(JSON.stringify(mainEventSectionList));
      const findIndex = oldData.findIndex((x) => x.event_section_id === mainSecId);
      let callApi = true;
      if (findIndex !== -1 && val === 0) {
        if (
          oldData[findIndex].subSectionList.options.filter((x) => x.is_visible === 1).length > 1
        ) {
          callApi = true;
        } else {
          if (
            oldData[findIndex].is_primary === 1 &&
            oldData.filter((x) => x.is_visible === 1 && x.is_primary === 1).length > 1
          ) {
            callApi = true;
          } else if (
            oldData[findIndex].is_primary === 0 &&
            oldData.filter((x) => x.is_visible === 1 && x.is_primary === 0).length > 1
          ) {
            callApi = true;
          } else {
            callApi = false;
          }
        }
      }
      if (callApi) {
        if (loadingState.eventStatuCall) {
          sectionDet.isSubsection = true;
          setSubStatusSelSectionId(sectionDet.event_subsection_id);
          sectionDet.is_visible = val;
          const reqData = {
            data: {
              event_section_id: sectionDet.event_subsection_id,
              is_visible: sectionDet.is_visible,
              isSubsection: sectionDet.isSubsection,
              is_parent: null,
            },
          };
          if (activeTab === 'mobile-app') {
            reqData.data.isMobileAppView = true;
          }

          setLoadState({ ...loadingState, eventSubStatusLoader: true, eventStatuCall: false });
          APICALL(
            'UPDATE-EVENT-SECTION-STATUS',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_VISIBILTY,
            { language: selectedLanguage?.id },
            reqData,
            true,
            (ACTION, type, data) => {
              setLoadState({
                ...loadingState,
                eventSubStatusLoader: false,
                eventStatuCall: true,
              });
              setSubStatusSelSectionId('');
              if (type === 'SUCCESS') {
                let visible_subsection_count = -1;
                if (typeof data.visible_subsection_count !== 'undefined') {
                  visible_subsection_count = JSON.parse(
                    JSON.stringify(data.visible_subsection_count)
                  );
                }
                if (findIndex !== -1) {
                  const indexOfSubsec = oldData[findIndex].subSectionList.options.findIndex(
                    (x) => x.event_subsection_id === sectionDet.event_subsection_id
                  );
                  if (indexOfSubsec !== -1) {
                    oldData[findIndex].subSectionList.options[indexOfSubsec].is_visible = val;
                    if (
                      val === 0 &&
                      visible_subsection_count !== -1 &&
                      visible_subsection_count === 0
                    ) {
                      oldData[findIndex].is_visible = 0;
                    } else {
                      oldData[findIndex].is_visible = 1;
                    }
                    setMainEventSectionList(oldData);
                    if (
                      val === 0 &&
                      selSectionObj.event_section_id === sectionDet.event_subsection_id
                    ) {
                      const vIndex = oldData[findIndex].subSectionList.options.findIndex(
                        (x) => x.is_visible && x.is_visible.toString() === '1'
                      );
                      if (vIndex !== -1) {
                        handelClickOnSubSection(
                          oldData[findIndex].event_section_id,
                          oldData[findIndex].subSectionList.options[vIndex].event_subsection_id,
                          oldData[findIndex].subSectionList.options[vIndex]
                        );
                      } else {
                        const pVindex = oldData.findIndex(
                          (x) => x.is_visible && x.is_visible.toString() === '1'
                        );
                        if (pVindex !== -1) {
                          handelClickOnSection(oldData[pVindex].event_section_id, oldData[pVindex]);
                        }
                      }
                    }
                  }
                }
              } else {
                dispatch(
                  setNewMessage({
                    message: data.message,
                    type: 'error',
                    show: true,
                    sec: notificationTime,
                  })
                );
              }
            }
          );
        } else {
          dispatch(
            setNewMessage({
              message:
                'Section visibility changes are being processed. Please try in a few seconds.',
              type: 'error',
              show: true,
              sec: notificationTime,
            })
          );
        }
      } else {
        dispatch(
          setNewMessage({
            message: 'Not allowed. At least one section has to be enabled',
            type: 'error',
            show: true,
            sec: notificationTime,
          })
        );
      }
      //	eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selSubStatusSectionId, mainEventSectionList, loadingState, selSectionObj]
  );

  /**
   *
   * @param {*} e
   * @param {*} type
   * @param {*} id
   */
  const handelClickOnSwitch = (e, type, id) => {
    if (type === 'make_section') {
      const oldData = JSON.parse(JSON.stringify(selSectionObj));
      const index = oldData.eventSectionVisibility.findIndex((x) => x.id === id);
      if (e.target.checked) {
        if (index !== -1) {
          //	oldData.section_visible_for_groups.push({id:indexData.id,name:indexData.name,is_enable:true});
          oldData.eventSectionVisibility[index].is_visible = 1;
        }
      } else {
        if (index !== -1) {
          // oldData.section_visible_for_groups.splice(index, 1);
          oldData.eventSectionVisibility[index].is_visible = 0;
        }
      }
      setSelSectionObj(oldData);
    } else if (type === 'display_category') {
      const oldData = JSON.parse(JSON.stringify(selSectionObj));
      const index = oldData.eventSectionDisplayCategory.findIndex((x) => x.id === id);
      if (e.target.checked) {
        if (index !== -1) {
          oldData.eventSectionDisplayCategory[index].is_visible = 1;
        }
      } else {
        if (index !== -1) {
          oldData.eventSectionDisplayCategory[index].is_visible = 0;
        }
      }
      setSelSectionObj(oldData);
    } else if (type === 'exhibitor_category') {
      const oldData = JSON.parse(JSON.stringify(selSectionObj));
      const index = oldData.eventSectionDisplayCategory.findIndex((x) => x.id === id);
      if (e.target.checked) {
        if (index !== -1) {
          oldData.eventSectionDisplayCategory[index].is_visible = 1;
          // oldData.category_string.push(id);
        }
      } else {
        if (index !== -1) {
          oldData.eventSectionDisplayCategory[index].is_visible = 0;
          // oldData.category_string.splice(index, 1);
        }
      }
      setSelSectionObj(oldData);
    } else if (type === 'display_profile_groups') {
      const oldData = JSON.parse(JSON.stringify(selSectionObj));
      const index = oldData.eventSectionProfile.findIndex((x) => x.id === id);
      if (e.target.checked) {
        if (index !== -1) {
          oldData.eventSectionProfile[index].is_visible = 1;
        }
      } else {
        if (index !== -1) {
          oldData.eventSectionProfile[index].is_visible = 0;
        }
      }
      setSelSectionObj(oldData);
    } else if (type === 'hs-section') {
      const oldData = JSON.parse(JSON.stringify(selSectionObj));
      const hsSection = oldData?.agendaComponentsVisibility[activeTabSidePanel];
      if (hsSection) {
        oldData.agendaComponentsVisibility[activeTabSidePanel][id?.apiName] = e.target.checked
          ? 1
          : 0;
      }
      setSelSectionObj(oldData);
    }
  };

  const handleValidations = (formFields) => {
    let errObj = '';
    const { label, event_section_meta_name } = formFields;
    if (
      event_section_meta_name === 'VIRTUAL_BOOTH' ||
      formFields?.eventSectionMetaName1 === 'VIRTUAL_BOOTH'
    )
      errObj = Validator.validate(EventSectionV1SchemaForBooth, { label });
    else errObj = Validator.validate(EventSectionV1Schema, { label });
    if (!errObj) errObj = {};
    let valid = true;
    if (activeAppIsEmbed) {
      // TODO : only if embed code is active
      if (
        selSectionObj.embed_code.trim() &&
        selSectionObj.embed_code.trim() !== '' &&
        embedType === EmbedTypes.EMBED_CODE
      ) {
        const val = selSectionObj.embed_code.trim();
        const isValidIframe = Boolean(val.match(iFrameRegex));
        if (!isValidIframe) {
          valid = false;
          errObj.embed_code = 'Please enter valid embed code';
        } else {
          // TODO : Multiple Iframe Check
          if (!isSingleFrameCount(selSectionObj.embed_code)) {
            valid = false;
            errObj.embed_code = 'Please enter a valid single embed code';
          }
        }
        // else {
        // const count = (selSectionObj.embed_code.match(/iframe/g) || []).length;
        // if (count > 2) {
        //   errObj.embed_code = 'Please enter a valid single iframe code';
        // }
        //  }
      } else if (
        (selSectionObj.file_name || fileData.fileName) &&
        embedType === EmbedTypes.FILE_UPLOAD
      ) {
        valid = true;
      } else {
        errObj.embed_code = 'Embed code is required';
      }
    }
    Object.values(errObj).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return !valid ? errObj : false;
  };

  const saveEventSubAndMainSection = () => {
    let err = handleValidations(selSectionObj);
    let isError = false;
    if (err) {
      if (selSectionObj.is_parent === 1) {
        if (Object.keys(err).length === 0) {
          err = {};
          isError = false;
        } else {
          isError = true;
        }
      } else {
        isError = true;
      }
    }
    if (isError) {
      // setErrObj(err);
      setEditErrObj(err);
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
          sec: notificationTime,
        })
      );
    } else {
      const reqObj = {};
      const fIndex = mainEventSectionList.findIndex((x) => x.event_section_id === selSectionId);
      let mianSecDet = null;
      if (fIndex !== -1) {
        mianSecDet = JSON.parse(JSON.stringify(mainEventSectionList[fIndex]));
      }
      if (secAction === 'createSubSec') {
        reqObj.label = selSectionObj.label;
        reqObj.event_section_id = selSectionId;
        reqObj.event_section_meta_id = null;
        reqObj.is_visible = 1;
        reqObj.show_loggedin_profile_only = null;
        reqObj.show_online_profile = null;
        reqObj.segregate_profile_into_tabs = null;
        reqObj.default_sort_order = null;
        if (mianSecDet) {
          reqObj.event_section_meta_id = mianSecDet.event_section_meta_id;
        }
        const reqBody = {
          data: {
            eventSubsection: reqObj,
          },
        };
        setLoadState({
          createOrEditEventSectionLoad: true,
          deleteEventLoader: false,
          eventMainSecLoad: false,
          eventSecDetailLoad: false,
          eventStatuCall: true,
          eventStatusLoader: false,
          eventSubSecDetailLoad: false,
          eventSubStatusLoader: false,
          iconLibLoad: false,
          initLoad: false,
          saveEventLoader: false,
          subSectionLoad: false,
          subSectionLoadMore: false,
        });

        APICALL(
          'ADD-EVENT-SUB-SECTION-DETAILS',
          reduxData,
          'POSTAPIWITHCUSTOMDOMAIN',
          EVENT_SECTION_SUB_ADD,
          { language: selectedLanguage?.id },
          reqBody,
          true,
          (ACTION, type, data) => {
            setLoadState({
              createOrEditEventSectionLoad: false,
              deleteEventLoader: false,
              eventMainSecLoad: false,
              eventSecDetailLoad: false,
              eventStatuCall: true,
              eventStatusLoader: false,
              eventSubSecDetailLoad: false,
              eventSubStatusLoader: false,
              iconLibLoad: false,
              initLoad: false,
              saveEventLoader: false,
              subSectionLoad: false,
              subSectionLoadMore: false,
              isUpload: false,
            });
            if (type === 'SUCCESS') {
              setSecAction('editSubSec');
              if (data) {
                const oldSecData = JSON.parse(JSON.stringify(mainEventSectionList));
                const editItem = JSON.parse(JSON.stringify(data));
                const editItemForEdit = JSON.parse(JSON.stringify(data));
                editItem.is_parent = 0;
                editItem.event_subsection_id = JSON.parse(JSON.stringify(data.event_section_id));
                editItem.event_section_id = JSON.parse(
                  JSON.stringify(oldSecData[fIndex].event_section_id)
                );
                editItem.is_visible = JSON.parse(JSON.stringify(data.is_visible));
                editItemForEdit.is_parent = 0;
                // editItem.event_subsection_id = JSON.parse(JSON.stringify(data.event_section_id));
                editItemForEdit.event_section_id = JSON.parse(
                  JSON.stringify(data.event_section_id)
                );
                editItemForEdit.is_visible = JSON.parse(
                  JSON.stringify(data.show_section_visibility)
                );
                setSubSelSectionId(data.event_subsection_id);
                if (fIndex !== -1) {
                  oldSecData[fIndex].subSectionList.options.push(editItem);
                  oldSecData[fIndex].event_subsection_count =
                    oldSecData[fIndex].event_subsection_count + 1;
                  oldSecData[fIndex].totalcount = oldSecData[fIndex].event_subsection_count;
                  oldSecData[fIndex].subSectionList.totalPage =
                    oldSecData[fIndex].event_subsection_count > 4
                      ? Math.ceil(oldSecData[fIndex].event_subsection_count / 4)
                      : 1;
                }
                setMainEventSectionList(oldSecData);
                setSelSectionObj(editItemForEdit);
                setTempSelEventSectionData(editItemForEdit);
              }
            }
          }
        );
      }
      if (secAction === 'editSubSec' || secAction === 'editMainSec') {
        if (selSectionObj.is_parent === 1) {
          reqObj.eventSectionDetails = {
            label: selSectionObj.label,
            event_section_id: selSectionObj.event_section_id,
            event_section_icon_id: selSectionObj.event_section_icon_id,
            is_visible: selSectionObj.is_visible,
            show_loggedin_profile_only: null,
            isSubsection: false,
            show_online_profile: null,
            segregate_profile_into_tabs: null,
            default_sort_order: null,
            is_primary: peoplePrimary,
          };
          reqObj.displayCategory = null;
          reqObj.profile = null;
          reqObj.visibility = null;
        } else {
          reqObj.eventSectionDetails = {
            label: selSectionObj.label,
            event_section_id: selSectionObj.event_section_id,
            event_section_icon_id: selSectionObj.event_section_icon_id,
            is_visible: selSectionObj.is_visible,
            isSubsection: secAction === 'editSubSec',
          };
          if (selSectionObj.event_section_meta_name === 'ATTENDEES') {
            reqObj.eventSectionDetails.show_loggedin_profile_only =
              selSectionObj.show_loggedin_profile_only;
            reqObj.eventSectionDetails.show_online_profile = selSectionObj.show_online_profile;
            reqObj.eventSectionDetails.segregate_profile_into_tabs =
              selSectionObj.segregate_profile_into_tabs;
            reqObj.eventSectionDetails.default_sort_order = selSectionObj.default_sort_order;
            reqObj.profile =
              selSectionObj.eventSectionProfile && selSectionObj.eventSectionProfile.length > 0
                ? selSectionObj.eventSectionProfile
                : null;
            reqObj.displayCategory = null;
          } else if (selSectionObj.event_section_meta_name === 'SPEAKERS') {
            reqObj.eventSectionDetails.show_loggedin_profile_only = null;
            reqObj.eventSectionDetails.show_online_profile = null;
            reqObj.eventSectionDetails.segregate_profile_into_tabs = null;
            reqObj.eventSectionDetails.default_sort_order = selSectionObj.default_sort_order;
            reqObj.profile = null;
            reqObj.displayCategory =
              selSectionObj.eventSectionDisplayCategory &&
              selSectionObj.eventSectionDisplayCategory.length > 0
                ? selSectionObj.eventSectionDisplayCategory
                : null;
          } else if (selSectionObj.event_section_meta_name === 'VIRTUAL_BOOTH') {
            reqObj.eventSectionDetails.show_loggedin_profile_only = null;
            reqObj.eventSectionDetails.show_online_profile = null;
            reqObj.eventSectionDetails.segregate_profile_into_tabs = null;
            reqObj.eventSectionDetails.default_sort_order = null;
            reqObj.displayCategory =
              selSectionObj.eventSectionDisplayCategory &&
              selSectionObj.eventSectionDisplayCategory.length > 0
                ? selSectionObj.eventSectionDisplayCategory
                : null;
          } else {
            reqObj.eventSectionDetails.show_loggedin_profile_only = null;
            reqObj.eventSectionDetails.show_online_profile = null;
            reqObj.eventSectionDetails.segregate_profile_into_tabs = null;
            reqObj.eventSectionDetails.default_sort_order = null;
            reqObj.profile = null;
            reqObj.displayCategory = null;
          }
          reqObj.visibility =
            selSectionObj.eventSectionVisibility && selSectionObj.eventSectionVisibility.length > 0
              ? selSectionObj.eventSectionVisibility
              : null;
          reqObj.agendaComponentsVisibility = selSectionObj.agendaComponentsVisibility
            ? selSectionObj.agendaComponentsVisibility
            : null;
        }
        if (activeAppIsEmbed) {
          reqObj.eventSectionDetails = {
            ...reqObj.eventSectionDetails,
            app_svg_image: selSectionObj.app_svg_image,
            integrated_app_icon_id: selSectionObj.integrated_app_icon_id,
            integrated_app_id: selSectionObj.integrated_app_id,
            embed_code:
              embedType === EmbedTypes.EMBED_CODE && selSectionObj.embed_code
                ? selSectionObj.embed_code
                : '',
            file_name:
              embedType === EmbedTypes.FILE_UPLOAD && selSectionObj.file_name
                ? selSectionObj.file_name
                : '',
            label: selSectionObj.label,
            event_section_icon_id: null,
          };
        }

        const reqBody = {
          data: reqObj,
        };
        setLoadState({
          createOrEditEventSectionLoad: true,
          deleteEventLoader: false,
          eventMainSecLoad: false,
          eventSecDetailLoad: false,
          eventStatuCall: true,
          eventStatusLoader: false,
          eventSubSecDetailLoad: false,
          eventSubStatusLoader: false,
          iconLibLoad: false,
          initLoad: false,
          saveEventLoader: false,
          subSectionLoad: false,
          subSectionLoadMore: false,
          isUpload: false,
        });
        APICALL(
          'EDIT-EVENT-SECTION-DETAILS',
          reduxData,
          'POSTAPIWITHCUSTOMDOMAIN',
          EVENT_SECTION_EDIT_DETAILS,
          { language: activeAppIsEmbed ? baseLangId : selectedLanguage?.id }, // TODO : baseLngId for embedappp only
          reqBody,
          true,
          (ACTION, type) => {
            setLoadState({
              createOrEditEventSectionLoad: false,
              deleteEventLoader: false,
              eventMainSecLoad: false,
              eventSecDetailLoad: false,
              eventStatuCall: true,
              eventStatusLoader: false,
              eventSubSecDetailLoad: false,
              eventSubStatusLoader: false,
              iconLibLoad: false,
              initLoad: false,
              saveEventLoader: false,
              subSectionLoad: false,
              subSectionLoadMore: false,
              isUpload: false,
            });
            if (type === 'SUCCESS') {
              const mainEventSecObj = JSON.parse(JSON.stringify(mainEventSectionList));
              const fIndexData = mainEventSectionList.findIndex(
                (x) => x.event_section_id === selSectionId
              );
              if (secAction === 'editMainSec') {
                if (fIndexData !== -1) {
                  mainEventSecObj[fIndexData].event_section_icon_id =
                    selSectionObj.event_section_icon_id;
                  mainEventSecObj[fIndexData].svg_image = selSectionObj.svg_image;
                  mainEventSecObj[fIndexData].label = selSectionObj.label;
                }
                if (selSectionObj.eventSectionMetaName === 'PEOPLE') {
                  const peopleMainSection = mainEventSecObj.find(
                    (x) => x.event_section_meta_name === 'PEOPLE'
                  );
                  mainEventSecObj[
                    mainEventSecObj.findIndex((x) => x.event_section_meta_name === 'PEOPLE')
                  ] = { ...peopleMainSection, is_primary: peoplePrimary };
                }
              } else {
                if (fIndexData !== -1) {
                  const subIndex = mainEventSecObj[fIndexData].subSectionList.options.findIndex(
                    (x) => x.event_subsection_id === selSectionObj.event_section_id
                  );
                  if (subIndex !== -1) {
                    mainEventSecObj[fIndexData].subSectionList.options[subIndex].label =
                      selSectionObj.label;
                  }
                }
              }
              setTempSelEventSectionData(selSectionObj);
              setMainEventSectionList(mainEventSecObj);
            }
          }
        );
      }
    }
  };

  /**
   *
   */
  const handelConfirmClickOnDeleteSection = React.useCallback(() => {
    if (deleteMainSectionId) {
      const oldData = JSON.parse(JSON.stringify(mainEventSectionList));
      const findIndex = oldData.findIndex((x) => x.event_section_id === deleteMainSectionId);
      if (findIndex !== -1 && oldData[findIndex].event_subsection_count > 1) {
        if (subSelSectionId) {
          // setLoadState({ ...loadingState, deleteEventLoader: true });
          setLoadState({
            createOrEditEventSectionLoad: false,
            deleteEventLoader: true,
            eventMainSecLoad: false,
            eventSecDetailLoad: false,
            eventStatuCall: true,
            eventStatusLoader: false,
            eventSubSecDetailLoad: false,
            eventSubStatusLoader: false,
            iconLibLoad: false,
            initLoad: false,
            saveEventLoader: false,
            subSectionLoad: false,
            subSectionLoadMore: false,
          });
          const reqBody = {
            data: {
              eventSubsectionId: subSelSectionId,
            },
          };
          APICALL(
            'EDIT-EVENT-SECTION-DETAILS',
            reduxData,
            'POSTAPIWITHCUSTOMDOMAIN',
            EVENT_SECTION_SUB_SEC_DELETE,
            { language: selectedLanguage?.id },
            reqBody,
            true,
            (ACTION, type, data) => {
              setLoadState({
                createOrEditEventSectionLoad: false,
                deleteEventLoader: false,
                eventMainSecLoad: false,
                eventSecDetailLoad: false,
                eventStatuCall: true,
                eventStatusLoader: false,
                eventSubSecDetailLoad: false,
                eventSubStatusLoader: false,
                iconLibLoad: false,
                initLoad: false,
                saveEventLoader: false,
                subSectionLoad: false,
                subSectionLoadMore: false,
              });
              if (type === 'SUCCESS') {
                toggleDeleteModal(false);
                let visible_subsection_count = -1;
                if (typeof data.visible_subsection_count !== 'undefined') {
                  visible_subsection_count = JSON.parse(
                    JSON.stringify(data.visible_subsection_count)
                  );
                }
                if (mainEventSectionList && mainEventSectionList.length > 0) {
                  const subIndex = oldData[findIndex].subSectionList.options.findIndex(
                    (x) => x.event_subsection_id === subSelSectionId
                  );
                  if (subIndex !== -1) {
                    oldData[findIndex].subSectionList.options.splice(subIndex, 1);
                    oldData[findIndex].event_subsection_count =
                      oldData[findIndex].event_subsection_count - 1;
                    oldData[findIndex].totalcount = oldData[findIndex].event_subsection_count;
                    oldData[findIndex].subSectionList.totalPage =
                      oldData[findIndex].event_subsection_count > 4
                        ? Math.ceil(oldData[findIndex].event_subsection_count / 4)
                        : 1;
                  }
                  if (visible_subsection_count !== -1 && visible_subsection_count === 0) {
                    oldData[findIndex].is_visible = 0;
                    oldData[findIndex].subSectionList.options.map((item) => {
                      item.is_visible = 0;
                      return 0;
                    });
                  }
                  setMainEventSectionList(oldData);
                }
                setSdeleteMainSectionId(null);
                if (
                  oldData[findIndex].subSectionList.options.length === 0 &&
                  oldData[findIndex].event_subsection_count > 0
                ) {
                  dispatch(
                    setNewMessage({
                      message: 'Please reload page to see more virtual booths',
                      type: 'success',
                      show: true,
                      sec: notificationTime,
                    })
                  );
                }
              }
            }
          );
        }
      } else {
        dispatch(
          setNewMessage({
            message: "You can't delete all sub section",
            type: 'error',
            show: true,
            sec: notificationTime,
          })
        );
      }
    }
    //	eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState, selSectionId, subSelSectionId, deleteMainSectionId]);

  const activeAppHandler = (val) => {
    if (val.integration_meta_name) {
      setActiveAppIsEmbed(true);
    } else {
      setActiveAppIsEmbed(false);
    }
  };

  const removeDuplicates = (arrayData) => {
    return arrayData.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.event_subsection_id === thing.event_subsection_id)
    );
  };

  // below function for file upload in embed app section
  const uploadFile = (blob, realFileName) => {
    const valueFile = blob;
    const fileDetails = getFileUploadDetails(valueFile);

    const payload = {
      payload: {
        data: {
          ...fileDetails,
          uploadType: 'EMBED_APP',
        },
      },
    };

    const [header] = API.generateHeader(reduxData);
    const customURLV2 = ProjectSetting.customBaseURL2;
    setLoadState({ ...loadingState, isUpload: true });
    // setFileUploadData({ uploadStatus: 'IDEAL', uploadingPer: 0 });
    setFileUploadData({ uploadStatus: 'UPLOADING', uploadingPer: 0 });
    POSTAPIWITHCUSTOMDOMAIN(false, GET_SIGNED_URL, source.AS, header, payload, customURLV2)
      .then((fetchSignature) => {
        clearInterval(source.interVal);

        if (fetchSignature.data?.urlLists.length && fetchSignature.data?.urlLists[0].uploadURL) {
          source.uploadingPer = 0;

          const uploadOption = {
            fileConfig: fetchSignature.data?.urlLists[0],
            contentType: fileDetails.contentType,
            progressCB: (val) => {
              source.uploadingPer = val;
            },
          };

          // if (resp.data.message)
          //   dispatch(
          //     setNewMessage({
          //       message: resp.data.message,
          //       type: 'success',
          //       show: true,
          //     })
          //   );

          // source.uploadingPer = 0;
          source.interVal = setInterval(
            () => {
              if (source.uploadingPer >= 99) {
                clearInterval(source.interVal);
              } else {
                setFileUploadData({
                  uploadStatus: 'UPLOADING',
                  uploadingPer: source.uploadingPer + 1,
                });

                source.uploadingPer += 1;
              }
            },
            source.uploadingPer < 25
              ? 200
              : source.uploadingPer < 45
              ? 600
              : source.uploadingPer < 60
              ? 800
              : source.uploadingPer < 90
              ? 1200
              : 1500
          );
          uploadS3Promise(valueFile, uploadOption)
            .then(() => {
              if (!isCancelUpload) {
                setFileData({
                  realFileName,
                  fileName: fetchSignature.data?.urlLists[0].fileName,
                  fileBlob: valueFile,
                });
                setFileUploadData({ uploadStatus: 'UPLOADED', uploadingPer: 100 });
                setLoadState({ ...loadingState, isUpload: false });
                setS3FileName(fetchSignature.data?.urlLists[0].fileName);
                if (activeAppIsEmbed) {
                  setSelSectionObj({
                    ...selSectionObj,
                    file_name: fetchSignature.data?.urlLists[0].fileName,
                  });
                }
              } else {
                setCancelUpload(false);
              }
            })
            .catch((e) => {
              dispatch(
                setNewMessage({
                  message: e?.message,
                  type: 'error',
                  show: true,
                })
              );
              setFileUploadData({ uploadStatus: 'FAILED', uploadingPer: 0 });
              source.uploadingPer = 0;
              setLoadState({ ...loadingState, isUpload: false });
            });
        } else {
          setLoadState({ ...loadingState, isUpload: false });
          // API.errStatusHandler(resp, history, dispatch, () => {
          //   if (resp.data.status === 400) {
          //     setFileUploadData({ uploadStatus: 'FAILED', uploadingPer: 0 });
          //     source.uploadingPer = 0;
          //   }
          // });
        }
      })
      .catch(API.catchHandler);

    const bodyFormData = new FormData();
    // bodyFormData.append('location', 'user_profile');
    bodyFormData.append('type', 'file');
    bodyFormData.append('data', blob);
  };

  // below function for file upload in embed app section
  const onRemoveFile = () => {
    setFileData({
      realFileName: '',
      fileName: '',
      fileBlob: null,
    });
    setFileUploadData({
      uploadStatus: 'IDEAL',
      uploadingPer: 0,
    });
    if (activeAppIsEmbed) {
      setSelSectionObj({ ...selSectionObj, file_name: '' });
    }
    setS3FileName('');
  };

  const handleSetToDefault = () => {
    const oldData = JSON.parse(JSON.stringify(selSectionObj));
    oldData.agendaComponentsVisibility = defaultAgendaComponentsVisibility;
    setSelSectionObj(oldData);
  };

  return (
    <>
      <CardWrapper
        title="Event Sections"
        subheader={
          <Box>
            {' '}
            Add, delete and update your event sections. Configure sections by choosing the icon,
            renaming the label, displaying the section under certain groups, and much more.{' '}
            <Box
              component="a"
              className="cursor-pointer text-underline"
              onClick={() => window.open(LearnMoreLink.setting_flx_event_section_1, '_blank')}>
              Learn More
            </Box>
          </Box>
        }>
        {!loadingState.eventMainSecLoad ? (
          <>
            <Box display="flex" justifyContent="space-between" mb={2.5}>
              <Box width="46%">
                <BtnGroup
                  isFluidBtn
                  options={tabs}
                  onChange={(val) => {
                    const tabList = tabs;
                    tabList.map((item) => {
                      item.isChecked = item.type === val.type;
                      return 0;
                    });
                    setTabs(tabList);
                    setActiveTab(val.type);
                    getListOfEventSection(val.type);
                  }}
                />

                {activeTab === 'desktop' ? (
                  <>
                    <Box className={classes.navCountLimitBox} mt={2} mb={2}>
                      <Typography variant="h6" component="h6">
                        Top Navigation & Side Panel
                      </Typography>
                      <Typography variant="body2" component="p" color="textSecondary">
                        Top navigation can have up to 7 items. Items listed below can be moved
                        across Top Navigation & Side panel.{' '}
                        <Box
                          component="a"
                          className="cursor-pointer text-underline learnMoreLink"
                          onClick={() =>
                            window.open(LearnMoreLink.setting_flx_event_section_2, '_blank')
                          }>
                          Learn More
                        </Box>
                      </Typography>
                    </Box>

                    <Box
                      width="92%"
                      display="flex"
                      position="relative"
                      justifyContent="space-between"
                      mb={0}
                      mt={3}>
                      <Box>
                        <Typography
                          className={classes.columnText}
                          variant="subtitle1"
                          component="label"
                          color="textPrimary">
                          Top Navigation
                        </Typography>
                        {/* <Typography color="textSecondary" component="p" variant="body2">
                          Items selected will appear on the top navigation.
                        </Typography> */}
                      </Box>
                      <CustomToolTip
                        isFabIcon
                        title="A minimum of 1 event section is mandatory in the Top Navigation. The visibility will be toggled on by default for the section."
                        placement="top"
                      />
                    </Box>
                  </>
                ) : (
                  ''
                )}

                {activeTab === 'mobile' || activeTab === 'mobile-app' ? (
                  <Box
                    p={1.2}
                    mr={1}
                    position="relative"
                    mb={2}
                    mt={2}
                    display="flex"
                    alignItems="center"
                    className={classes.warnMsg}>
                    <SvgLightBulb height="25px" width="25px" />
                    <Box width="80%" ml={1} display="table-cell" position="relative">
                      <Typography component="p" variant="caption">
                        {activeTab === 'mobile' && isNewEventMobileSection
                          ? 'The top 4 will come as navigation on the mobile web. The rest will come as a secondary item.'
                          : activeTab === 'mobile'
                          ? 'The top 4 will come as a navigation on mobile. Rest will come as a secondary item.'
                          : 'When there are more than 5 sections, only the top 4 will be displayed in tabs and other items will be available in the secondary menu.'}
                      </Typography>
                      {/* <CustomToolTip isFabIcon title="hi" placement="top" /> */}
                    </Box>
                  </Box>
                ) : (
                  ''
                )}
                {activeTab === 'mobile' || activeTab === 'mobile-app' ? (
                  <Box className={eventBlockSetting}>
                    <Grid container>
                      <Grid item xs={12}>
                        <List className={eventList}>
                          <Container
                            lockAxis="y"
                            orientation="vertical"
                            groupName="col"
                            dragClass="card-ghost"
                            dropClass="card-ghost-drop"
                            dropPlaceholder={{
                              className: 'dragPlaceHolder',
                            }}
                            getChildPayload={(i) => mainEventSectionList[i]}
                            animationDuration={300}
                            onDragStart={({ isSource, payload, willAcceptDrop }) => {
                              if (isSource) {
                                setDragStart('TopNavigation');
                              }
                            }}
                            onDragEnter={() => {
                              setDragStart('TopNavigation');
                            }}
                            onDrop={(dropResult) => {
                              if (
                                activeTab === 'mobile' ||
                                activeTab === 'mobile-app' ||
                                mainEventSectionList
                                  .map((obj) => obj.label)
                                  .includes(dropResult?.payload?.label)
                              ) {
                                if (dropResult.addedIndex !== null) {
                                  if (dropResult.addedIndex !== dropResult.removedIndex) {
                                    if (dragStart === 'TopNavigation') {
                                      onDropPrim(dropResult, 'column');
                                    } else if (dragStart === 'SidePanel') {
                                      onDropSec(dropResult, 'column');
                                    }
                                  }
                                }
                              }
                            }}>
                            {activeTab === 'mobile' || activeTab === 'mobile-app' ? (
                              <MobileAppEventSetting
                                activeAppHandler={activeAppHandler}
                                handelClickOnSection={handelClickOnSection}
                                defultIcon={defultIcon}
                                loadingState={loadingState}
                                activeTab={activeTab}
                                mainEventSectionList={mainEventSectionList}
                                selStatusSectionId={selStatusSectionId}
                                toastItems={toastItems}
                                selSectionId={selSectionId}
                                setDragStart={setDragStart}
                                dragStart={dragStart}
                                handelClickOnUpdateSubSecEventStatus={
                                  handelClickOnUpdateSubSecEventStatusMobileAPP
                                }
                                onDropSub={onDropSub}
                                removeDuplicates={removeDuplicates}
                                handelClickOnSubSection={handelClickOnSubSection}
                                subSelSectionId={subSelSectionId}
                                getListOfEventSubSection={getListOfEventSubSection}
                                setSelectedSectionIndex={setSelectedSectionIndex}
                                selSubStatusSectionId={selSubStatusSectionId}
                                handelClickOnUpdateEventStatus={
                                  handelClickOnUpdateEventStatusMobileApp
                                }
                              />
                            ) : (
                              false
                            )}
                          </Container>
                        </List>
                      </Grid>
                    </Grid>
                  </Box>
                ) : null}
                <Box className={eventBlockSetting}>
                  <Grid container>
                    <Grid item xs={12}>
                      <List className={eventList}>
                        <Container
                          lockAxis="y"
                          orientation="vertical"
                          groupName="col"
                          dragClass="card-ghost"
                          dropClass="card-ghost-drop"
                          dropPlaceholder={{
                            className: 'dragPlaceHolder',
                          }}
                          getChildPayload={(i) =>
                            mainEventSectionList.filter((x) => x.is_primary === 1)[i]
                          }
                          animationDuration={300}
                          onDragStart={({ isSource, payload, willAcceptDrop }) => {
                            if (isSource) {
                              setDragStart('TopNavigation');
                            }
                          }}
                          onDragEnter={() => {
                            setDragStart('TopNavigation');
                          }}
                          onDrop={(dropResult) => {
                            if (
                              activeTab === 'mobile' ||
                              mainEventSectionList
                                .map((obj) => obj.label)
                                .includes(dropResult.payload.label)
                            ) {
                              if (dropResult.addedIndex !== null) {
                                if (dropResult.addedIndex !== dropResult.removedIndex) {
                                  if (dragStart === 'TopNavigation') {
                                    onDropPrim(dropResult, 'column');
                                  } else if (dragStart === 'SidePanel') {
                                    onDropSec(dropResult, 'column');
                                  }
                                }
                              }
                            }
                          }}>
                          {activeTab === 'desktop' &&
                          mainEventSectionList &&
                          mainEventSectionList.length > 0 ? (
                            <>
                              {mainEventSectionList
                                .filter((x) => x.is_primary === 1)
                                .map((eventItem, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <Draggable
                                        className={`${
                                          mainEventSectionList.filter((x) => x.is_primary === 1)
                                            .length === 1
                                            ? 'disabledItem'
                                            : ''
                                        }`}>
                                        <Box
                                          bgcolor={`${eventItem.is_parent ? '#f6f6f6' : false}`}
                                          borderRadius={`${eventItem.is_parent ? '8px' : false}`}
                                          pl={`${eventItem.is_parent ? '5px' : false}`}
                                          pr={`${eventItem.is_parent ? '5px' : false}`}>
                                          <Box
                                            position="relative"
                                            className={`${
                                              eventItem.is_parent &&
                                              eventItem.event_subsection_count === 0
                                                ? classes.eventCustomBlock
                                                : ''
                                            }`}>
                                            {eventItem.is_parent &&
                                            eventItem.event_subsection_count === 0 ? (
                                              <Box position="absolute" top="30%" left="5px">
                                                <CustomToolTip placement="top" title="Add Booths">
                                                  <IconButton
                                                    className={classes.iconButton}
                                                    onClick={() => {
                                                      setSecAction('createSubSec');
                                                      setSelSectionId(eventItem.event_section_id);
                                                      setSelSectionObj({
                                                        label: '',
                                                        is_parent: 0,
                                                      });
                                                      setTempSelEventSectionData({
                                                        label: '',
                                                        is_parent: 0,
                                                      });
                                                    }}>
                                                    <Plus size={20} color="#FB7A75" />
                                                  </IconButton>
                                                </CustomToolTip>
                                              </Box>
                                            ) : (
                                              ''
                                            )}
                                            <ListItem
                                              disabled={eventItem.is_visible === 0}
                                              selected={eventItem.event_section_id === selSectionId}
                                              button
                                              disableGutters
                                              className={`${eventNameBlock} ${
                                                eventItem.integration_meta_name === null
                                                  ? ''
                                                  : 'badgeText'
                                              } ${
                                                toastItems.length &&
                                                toastItems.indexOf(eventItem.event_section_id) > -1
                                                  ? `toastBackground`
                                                  : ``
                                              }`}
                                              onClick={() => {
                                                activeAppHandler(eventItem);
                                                handelClickOnSection(
                                                  eventItem.event_section_id,
                                                  eventItem
                                                );
                                                setSelectedSectionIndex(key);
                                                // if (eventItem.is_parent && eventItem.subSectionList &&
                                                // 	eventItem.subSectionList.options.length === 0) {
                                                // 	getListOfEventSubSection(eventItem.event_section_id, 1);
                                                // }
                                              }}>
                                              <ListItemIcon>
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    padding="7.5px 4px"
                                                    display="flex"
                                                    flexDirection="column">
                                                    <SvgUnionDotsIcon
                                                      color="#a5a5a5"
                                                      height="18px"
                                                      width="11px"
                                                    />
                                                  </Box>
                                                </Box>
                                                <Box
                                                  className={classes.eventsIcon}
                                                  height={30}
                                                  width={30}
                                                  mr={1}>
                                                  {eventItem.svg_image ||
                                                  eventItem.app_svg_image ? (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          eventItem.svg_image ||
                                                          eventItem.app_svg_image,
                                                      }}
                                                    />
                                                  ) : (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: defultIcon.svg,
                                                      }}
                                                    />
                                                  )}
                                                </Box>
                                              </ListItemIcon>
                                              <ListItemText
                                                primaryTypographyProps={{
                                                  variant: 'subtitle2',
                                                  component: 'label',
                                                }}
                                                primary={eventItem.label}
                                                secondaryTypographyProps={{
                                                  variant: 'body2',
                                                  component: 'caption',
                                                }}
                                                secondary={
                                                  eventItem.event_section_meta_name
                                                    ? eventItem.event_section_meta_name.replace(
                                                        /_/g,
                                                        ' '
                                                      )
                                                    : eventItem.integration_meta_name
                                                    ? eventItem.integration_meta_name
                                                    : ''
                                                }
                                              />
                                              <ListItemSecondaryAction
                                                className={eventNameBlockCheck}>
                                                <IconButton>
                                                  {loadingState.eventStatusLoader &&
                                                  selStatusSectionId ===
                                                    eventItem.event_section_id ? (
                                                    <CircularProgress color="primary" size={19} />
                                                  ) : (
                                                    <>
                                                      {eventItem.is_visible === 1 ? (
                                                        <Eye
                                                          size={20}
                                                          className="circleCheckIconColor"
                                                          onClick={() =>
                                                            handelClickOnUpdateEventStatus(
                                                              eventItem,
                                                              0
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <EyeOff
                                                          size={20}
                                                          className="circleCheckIconColor"
                                                          onClick={() =>
                                                            handelClickOnUpdateEventStatus(
                                                              eventItem,
                                                              1
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </IconButton>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          </Box>

                                          {eventItem.is_parent === 1 ? (
                                            <Box
                                              position="relative"
                                              className="childWrapper"
                                              onScroll={(event) => {
                                                const ele = event.target;
                                                if (
                                                  ele.scrollHeight - ele.scrollTop <=
                                                  ele.clientHeight + 5
                                                ) {
                                                  getListOfEventSubSection(
                                                    eventItem.event_section_id,
                                                    false
                                                  );
                                                }
                                              }}>
                                              <Container
                                                lockAxis="y"
                                                orientation="vertical"
                                                groupName="col"
                                                dragClass="card-ghost"
                                                dropClass="card-ghost-drop"
                                                dropPlaceholder={{
                                                  className: 'dragPlaceHolder',
                                                }}
                                                getChildPayload={(i) =>
                                                  eventItem.subSectionList.options[i]
                                                }
                                                animationDuration={200}
                                                onDragStart={({
                                                  isSource,
                                                  payload,
                                                  willAcceptDrop,
                                                }) => {
                                                  if (isSource) {
                                                    setDragStart('ExhibitorSubSec');
                                                  }
                                                }}
                                                onDragEnter={() => {
                                                  setDragStart('ExhibitorSubSec');
                                                }}
                                                onDrop={(dropResult) => {
                                                  if (dropResult.addedIndex !== null) {
                                                    if (
                                                      dropResult.addedIndex !==
                                                      dropResult.removedIndex
                                                    ) {
                                                      if (dragStart === 'ExhibitorSubSec') {
                                                        onDropSub(dropResult, 'column', eventItem);
                                                      }
                                                    }
                                                  }
                                                }}>
                                                {loadingState.subSectionLoad ? (
                                                  <SubSectionLoad />
                                                ) : eventItem.subSectionList &&
                                                  eventItem.subSectionList.options.length > 0 ? (
                                                  // && eventItem.event_section_id === selSectionId
                                                  <>
                                                    {removeDuplicates(
                                                      eventItem.subSectionList.options
                                                    ).map((subItem, subIndex) => {
                                                      return (
                                                        <Draggable
                                                          position="relative"
                                                          key={`${key}${subIndex}`}>
                                                          <Box position="relative">
                                                            {eventItem.event_section_meta_name !==
                                                              'PEOPLE' &&
                                                            removeDuplicates(
                                                              eventItem.subSectionList.options
                                                            ).length ===
                                                              subIndex + 1 ? (
                                                              <Box
                                                                position="absolute"
                                                                top="27px"
                                                                left="4px">
                                                                {eventItem.event_section_meta_name !==
                                                                'PEOPLE' ? (
                                                                  <CustomToolTip
                                                                    placement="top"
                                                                    title="Add Booths">
                                                                    <IconButton
                                                                      className={classes.iconButton}
                                                                      onClick={() => {
                                                                        activeAppHandler(eventItem);
                                                                        setSecAction(
                                                                          'createSubSec'
                                                                        );
                                                                        setSelSectionId(
                                                                          eventItem.event_section_id
                                                                        );
                                                                        setSelSectionObj({
                                                                          label: '',
                                                                          is_parent: 0,
                                                                          eventSectionMetaName1:
                                                                            eventItem.event_section_meta_name,
                                                                        });
                                                                        setTempSelEventSectionData({
                                                                          label: '',
                                                                          is_parent: 0,
                                                                        });
                                                                      }}>
                                                                      <Plus
                                                                        size={20}
                                                                        color="#FB7A75"
                                                                      />
                                                                    </IconButton>
                                                                  </CustomToolTip>
                                                                ) : (
                                                                  ''
                                                                )}
                                                              </Box>
                                                            ) : (
                                                              ''
                                                            )}
                                                            <Box
                                                              position="relative"
                                                              style={{
                                                                float: 'right',
                                                                width: '85%',
                                                              }}>
                                                              <ListItem
                                                                disabled={subItem.is_visible === 0}
                                                                selected={
                                                                  subItem.event_subsection_id ===
                                                                  subSelSectionId
                                                                }
                                                                button
                                                                disableGutters
                                                                className={`${eventNameBlock} ${
                                                                  eventItem.integration_meta_name ===
                                                                  null
                                                                    ? ''
                                                                    : 'badgeText'
                                                                }${
                                                                  toastItems.length &&
                                                                  toastItems.indexOf(
                                                                    eventItem.event_section_id
                                                                  ) > -1
                                                                    ? `toastBackground`
                                                                    : ``
                                                                }`}
                                                                onClick={() => {
                                                                  activeAppHandler(eventItem);
                                                                  handelClickOnSubSection(
                                                                    eventItem.event_section_id,
                                                                    subItem.event_subsection_id,
                                                                    subItem
                                                                  );
                                                                  const finalIndex =
                                                                    mainEventSectionList
                                                                      .filter(
                                                                        (f) => f.is_primary === 1
                                                                      )
                                                                      .findIndex(
                                                                        (item) =>
                                                                          item.event_section_meta_name ===
                                                                          eventItem.event_section_meta_name
                                                                      );
                                                                  setSelectedSectionIndex(
                                                                    finalIndex
                                                                  );
                                                                }}>
                                                                <ListItemIcon>
                                                                  <Box
                                                                    component="span"
                                                                    padding="7.5px 4px"
                                                                    display="flex"
                                                                    flexDirection="column">
                                                                    <SvgUnionDotsIcon
                                                                      color="#a5a5a5"
                                                                      height="18px"
                                                                      width="11px"
                                                                    />
                                                                  </Box>
                                                                  <Box
                                                                    className={classes.eventsIcon}
                                                                    height={30}
                                                                    width={30}
                                                                    mr={1}>
                                                                    {eventItem.svg_image ? (
                                                                      <div
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            eventItem.svg_image,
                                                                        }}
                                                                      />
                                                                    ) : (
                                                                      <div
                                                                        dangerouslySetInnerHTML={{
                                                                          __html: defultIcon.svg,
                                                                        }}
                                                                      />
                                                                    )}
                                                                  </Box>
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                  primaryTypographyProps={{
                                                                    variant: 'subtitle2',
                                                                    component: 'label',
                                                                    className:
                                                                      'eventSectiontitleTitle',
                                                                  }}
                                                                  primary={`${subItem.label}`}
                                                                  secondaryTypographyProps={{
                                                                    variant: 'body2',
                                                                    component: 'caption',
                                                                  }}
                                                                  secondary={
                                                                    eventItem.event_section_meta_name
                                                                      ? eventItem.event_section_meta_name.replace(
                                                                          /_/g,
                                                                          ' '
                                                                        )
                                                                      : ''
                                                                  }
                                                                />
                                                                <ListItemSecondaryAction
                                                                  className={eventNameBlockCheck}>
                                                                  {eventItem.event_section_meta_name ===
                                                                  'PEOPLE' ? null : (
                                                                    <IconButton
                                                                      disabled={
                                                                        subItem.is_visible === 0
                                                                      }
                                                                      className="circleTrashIconColor"
                                                                      onClick={() => {
                                                                        setSdeleteMainSectionId(
                                                                          eventItem.event_section_id
                                                                        );
                                                                        toggleDeleteModal(true);
                                                                        setSubSelSectionId(
                                                                          subItem.event_subsection_id
                                                                        );
                                                                      }}>
                                                                      <Trash2 size={20} />
                                                                    </IconButton>
                                                                  )}
                                                                  <IconButton>
                                                                    {loadingState.eventSubStatusLoader &&
                                                                    selSubStatusSectionId ===
                                                                      subItem.event_subsection_id ? (
                                                                      <CircularProgress
                                                                        color="primary"
                                                                        size={19}
                                                                      />
                                                                    ) : (
                                                                      <>
                                                                        {subItem.is_visible ===
                                                                        1 ? (
                                                                          <Eye
                                                                            size={20}
                                                                            className="circleCheckIconColor"
                                                                            onClick={() =>
                                                                              handelClickOnUpdateSubSecEventStatus(
                                                                                subItem,
                                                                                0,
                                                                                eventItem.event_section_id
                                                                              )
                                                                            }
                                                                          />
                                                                        ) : (
                                                                          <EyeOff
                                                                            size={20}
                                                                            className="circleCheckIconColor"
                                                                            onClick={() =>
                                                                              handelClickOnUpdateSubSecEventStatus(
                                                                                subItem,
                                                                                1,
                                                                                eventItem.event_section_id
                                                                              )
                                                                            }
                                                                          />
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </IconButton>
                                                                </ListItemSecondaryAction>
                                                              </ListItem>
                                                            </Box>
                                                          </Box>
                                                        </Draggable>
                                                      );
                                                    })}
                                                    {loadingState.subSectionLoadMore ? (
                                                      <SubSectionLoad />
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </Container>
                                            </Box>
                                          ) : (
                                            ''
                                          )}
                                        </Box>
                                      </Draggable>
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            ''
                          )}
                        </Container>
                      </List>
                    </Grid>
                  </Grid>
                </Box>
                {activeTab === 'desktop' ? (
                  <Box
                    display="flex"
                    width="90%"
                    position="relative"
                    justifyContent="space-between"
                    mb={0}
                    mt={3}>
                    <Box>
                      <Typography
                        className={classes.columnText}
                        variant="subtitle1"
                        component="label"
                        color="textPrimary">
                        Side panel
                      </Typography>
                      {/* <Typography color="textSecondary" component="p" variant="caption">
                        Items selected will appear on the side panel.
                      </Typography> */}
                    </Box>
                    <CustomToolTip
                      isFabIcon
                      title="A minimum of 1 event section is mandatory in the Side Panel. The visibility will be toggled on by default for the section."
                      placement="top"
                    />
                  </Box>
                ) : (
                  ''
                )}
                <Box className={eventBlockSetting}>
                  <Grid container>
                    <Grid item xs={12}>
                      <List className={eventList}>
                        <Container
                          lockAxis="y"
                          orientation="vertical"
                          groupName="col"
                          dragClass="card-ghost"
                          dropClass="card-ghost-drop"
                          dropPlaceholder={{
                            showOnTop: true,
                            className: 'dragPlaceHolder',
                          }}
                          getChildPayload={(i) =>
                            mainEventSectionList.filter((x) => x.is_primary === 0)[i]
                          }
                          animationDuration={200}
                          onDragStart={({ isSource, payload, willAcceptDrop }) => {
                            if (isSource) {
                              setDragStart('SidePanel');
                            }
                          }}
                          onDragEnter={() => {
                            setDragStart('SidePanel');
                          }}
                          onDrop={(dropResult) => {
                            if (
                              activeTab === 'mobile' ||
                              mainEventSectionList
                                .map((obj) => obj.label)
                                .includes(dropResult.payload.label)
                            ) {
                              if (dropResult.addedIndex !== null) {
                                if (dropResult.addedIndex !== dropResult.removedIndex) {
                                  if (dragStart === 'TopNavigation') {
                                    onDropPrim(dropResult, 'column');
                                  } else if (dragStart === 'SidePanel') {
                                    onDropSec(dropResult, 'column');
                                  }
                                }
                              }
                            }
                          }}>
                          {activeTab === 'desktop' &&
                          mainEventSectionList &&
                          mainEventSectionList.length > 0 ? (
                            <>
                              {mainEventSectionList
                                .filter((x) => x.is_primary === 0)
                                .map((eventItem, key) => {
                                  return (
                                    <React.Fragment key={key}>
                                      <Draggable className="">
                                        <Box
                                          bgcolor={`${eventItem.is_parent ? '#f6f6f6' : false}`}
                                          borderRadius={`${eventItem.is_parent ? '8px' : false}`}
                                          pl={`${eventItem.is_parent ? '5px' : false}`}
                                          pr={`${eventItem.is_parent ? '5px' : false}`}>
                                          <Box>
                                            <ListItem
                                              disabled={eventItem.is_visible === 0}
                                              selected={eventItem.event_section_id === selSectionId}
                                              button
                                              disableGutters
                                              className={`${eventNameBlock}  ${
                                                eventItem.integration_meta_name === null
                                                  ? ''
                                                  : 'badgeText'
                                              }${
                                                toastItems.length &&
                                                toastItems.indexOf(eventItem.event_section_id) > -1
                                                  ? `toastBackground`
                                                  : ``
                                              }`}
                                              onClick={() => {
                                                activeAppHandler(eventItem);
                                                handelClickOnSection(
                                                  eventItem.event_section_id,
                                                  eventItem
                                                );

                                                const finalIndex =
                                                  key +
                                                  mainEventSectionList.filter(
                                                    (x) => x.is_primary === 1
                                                  ).length;
                                                setSelectedSectionIndex(finalIndex);
                                              }}>
                                              <ListItemIcon>
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    padding="7.5px 4px"
                                                    display="flex"
                                                    flexDirection="column">
                                                    <SvgUnionDotsIcon
                                                      color="#a5a5a5"
                                                      height="18px"
                                                      width="11px"
                                                    />
                                                  </Box>
                                                </Box>
                                                <Box
                                                  className={classes.eventsIcon}
                                                  height={30}
                                                  width={30}
                                                  mr={1}>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        eventItem.svg_image ||
                                                        eventItem.app_svg_image ||
                                                        defultIcon.svg,
                                                    }}
                                                  />
                                                </Box>
                                              </ListItemIcon>
                                              <ListItemText
                                                primaryTypographyProps={{
                                                  variant: 'subtitle2',
                                                  component: 'label',
                                                }}
                                                primary={eventItem.label}
                                                secondaryTypographyProps={{
                                                  variant: 'body2',
                                                  component: 'caption',
                                                }}
                                                secondary={
                                                  eventItem.event_section_meta_name
                                                    ? eventItem.event_section_meta_name.replace(
                                                        /_/g,
                                                        ' '
                                                      )
                                                    : eventItem.integration_meta_name
                                                }
                                              />
                                              <ListItemSecondaryAction
                                                className={eventNameBlockCheck}>
                                                <IconButton>
                                                  {loadingState.eventStatusLoader &&
                                                  selStatusSectionId ===
                                                    eventItem.event_section_id ? (
                                                    <CircularProgress color="primary" size={19} />
                                                  ) : (
                                                    <>
                                                      {eventItem.is_visible === 1 ? (
                                                        <Eye
                                                          size={20}
                                                          className="circleCheckIconColor"
                                                          onClick={() =>
                                                            handelClickOnUpdateEventStatus(
                                                              eventItem,
                                                              0
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <EyeOff
                                                          size={20}
                                                          className="circleCheckIconColor"
                                                          onClick={() =>
                                                            handelClickOnUpdateEventStatus(
                                                              eventItem,
                                                              1
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </IconButton>
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                          </Box>
                                          {eventItem.is_parent === 1 ? (
                                            <Box
                                              position="relative"
                                              className="childWrapper"
                                              onScroll={(event) => {
                                                const ele = event.target;
                                                if (
                                                  ele.scrollHeight - ele.scrollTop <=
                                                  ele.clientHeight + 5
                                                ) {
                                                  getListOfEventSubSection(
                                                    eventItem.event_section_id,
                                                    false
                                                  );
                                                }
                                              }}>
                                              <Container
                                                lockAxis="y"
                                                orientation="vertical"
                                                groupName="col"
                                                dragClass="card-ghost"
                                                dropClass="card-ghost-drop"
                                                dropPlaceholder={{
                                                  className: 'dragPlaceHolder',
                                                }}
                                                getChildPayload={(i) =>
                                                  eventItem.subSectionList.options[i]
                                                }
                                                animationDuration={200}
                                                onDragStart={({
                                                  isSource,
                                                  payload,
                                                  willAcceptDrop,
                                                }) => {
                                                  if (isSource) {
                                                    setDragStart('ExhibitorSubSec');
                                                  }
                                                }}
                                                onDragEnter={() => {
                                                  setDragStart('ExhibitorSubSec');
                                                }}
                                                onDrop={(dropResult) => {
                                                  if (dropResult.addedIndex !== null) {
                                                    if (
                                                      dropResult.addedIndex !==
                                                      dropResult.removedIndex
                                                    ) {
                                                      if (dragStart === 'ExhibitorSubSec') {
                                                        onDropSub(dropResult, 'column', eventItem);
                                                      }
                                                    }
                                                  }
                                                }}>
                                                {loadingState.subSectionLoad ? (
                                                  <SubSectionLoad />
                                                ) : eventItem.subSectionList.options.length > 0 ? (
                                                  <>
                                                    {eventItem.subSectionList.options.map(
                                                      (subItem, subIndex) => {
                                                        return (
                                                          <Draggable
                                                            position="relative"
                                                            key={`${key}${subIndex}`}>
                                                            <Box
                                                              position="relative"
                                                              key={`${key}${subIndex}`}>
                                                              {eventItem.subSectionList.options
                                                                .length ===
                                                              subIndex + 1 ? (
                                                                <Box
                                                                  position="absolute"
                                                                  top="27px"
                                                                  left="4px">
                                                                  {eventItem.event_section_meta_name !==
                                                                  'PEOPLE' ? (
                                                                    <CustomToolTip
                                                                      placement="top"
                                                                      title="Add Booths">
                                                                      <IconButton
                                                                        className={
                                                                          classes.iconButton
                                                                        }
                                                                        onClick={() => {
                                                                          activeAppHandler(
                                                                            eventItem
                                                                          );
                                                                          setSecAction(
                                                                            'createSubSec'
                                                                          );
                                                                          setSelSectionId(
                                                                            eventItem.event_section_id
                                                                          );
                                                                          setSelSectionObj({
                                                                            label: '',
                                                                            is_parent: 0,
                                                                            eventSectionMetaName1:
                                                                              eventItem.event_section_meta_name,
                                                                          });
                                                                          setTempSelEventSectionData(
                                                                            {
                                                                              label: '',
                                                                              is_parent: 0,
                                                                            }
                                                                          );
                                                                        }}>
                                                                        <Plus
                                                                          size={20}
                                                                          color="#FB7A75"
                                                                        />
                                                                      </IconButton>
                                                                    </CustomToolTip>
                                                                  ) : (
                                                                    ''
                                                                  )}
                                                                </Box>
                                                              ) : (
                                                                ''
                                                              )}
                                                              <Box
                                                                position="relative"
                                                                style={{
                                                                  float: 'right',
                                                                  width: '85%',
                                                                }}>
                                                                <ListItem
                                                                  disabled={
                                                                    subItem.is_visible === 0
                                                                  }
                                                                  selected={
                                                                    subItem.event_subsection_id ===
                                                                    subSelSectionId
                                                                  }
                                                                  button
                                                                  disableGutters
                                                                  className={`${eventNameBlock} ${
                                                                    eventItem.integration_meta_name ===
                                                                    null
                                                                      ? ''
                                                                      : 'badgeText'
                                                                  }${
                                                                    toastItems.length &&
                                                                    toastItems.indexOf(
                                                                      eventItem.event_section_id
                                                                    ) > -1
                                                                      ? `toastBackground`
                                                                      : ``
                                                                  }`}
                                                                  onClick={() => {
                                                                    activeAppHandler(eventItem);
                                                                    handelClickOnSubSection(
                                                                      eventItem.event_section_id,
                                                                      subItem.event_subsection_id,
                                                                      subItem
                                                                    );
                                                                  }}>
                                                                  <ListItemIcon>
                                                                    <Box>
                                                                      <Box
                                                                        component="span"
                                                                        padding="7.5px 4px"
                                                                        display="flex"
                                                                        flexDirection="column">
                                                                        <SvgUnionDotsIcon
                                                                          color="#a5a5a5"
                                                                          height="18px"
                                                                          width="11px"
                                                                        />
                                                                      </Box>
                                                                    </Box>
                                                                    <Box
                                                                      className={classes.eventsIcon}
                                                                      height={30}
                                                                      width={30}
                                                                      mr={1}>
                                                                      {eventItem.svg_image ? (
                                                                        <div
                                                                          dangerouslySetInnerHTML={{
                                                                            __html:
                                                                              eventItem.svg_image,
                                                                          }}
                                                                        />
                                                                      ) : (
                                                                        <div
                                                                          dangerouslySetInnerHTML={{
                                                                            __html: defultIcon.svg,
                                                                          }}
                                                                        />
                                                                      )}
                                                                    </Box>
                                                                  </ListItemIcon>
                                                                  <ListItemText
                                                                    primaryTypographyProps={{
                                                                      variant: 'subtitle2',
                                                                      component: 'label',
                                                                      className:
                                                                        'eventSectiontitleTitle',
                                                                    }}
                                                                    primary={`${subItem.label}`}
                                                                    secondaryTypographyProps={{
                                                                      variant: 'body2',
                                                                      component: 'caption',
                                                                    }}
                                                                    secondary={
                                                                      subItem.event_section_meta_name
                                                                        ? subItem.event_section_meta_name.replace(
                                                                            /_/g,
                                                                            ' '
                                                                          )
                                                                        : ''
                                                                    }
                                                                  />
                                                                  <ListItemSecondaryAction
                                                                    className={eventNameBlockCheck}>
                                                                    {eventItem.event_section_meta_name ===
                                                                    'PEOPLE' ? null : (
                                                                      <IconButton
                                                                        disabled={
                                                                          subItem.is_visible === 0
                                                                        }
                                                                        className="circleTrashIconColor"
                                                                        onClick={() => {
                                                                          setSdeleteMainSectionId(
                                                                            eventItem.event_section_id
                                                                          );
                                                                          toggleDeleteModal(true);
                                                                          setSubSelSectionId(
                                                                            subItem.event_subsection_id
                                                                          );
                                                                        }}>
                                                                        <Trash2 size={20} />
                                                                      </IconButton>
                                                                    )}
                                                                    <IconButton>
                                                                      {loadingState.eventSubStatusLoader &&
                                                                      selSubStatusSectionId ===
                                                                        subItem.event_subsection_id ? (
                                                                        <CircularProgress
                                                                          color="primary"
                                                                          size={19}
                                                                        />
                                                                      ) : (
                                                                        <>
                                                                          {subItem.is_visible ===
                                                                          1 ? (
                                                                            <Eye
                                                                              size={20}
                                                                              className="circleCheckIconColor"
                                                                              onClick={() =>
                                                                                handelClickOnUpdateSubSecEventStatus(
                                                                                  subItem,
                                                                                  0,
                                                                                  eventItem.event_section_id
                                                                                )
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            <EyeOff
                                                                              size={20}
                                                                              className="circleCheckIconColor"
                                                                              onClick={() =>
                                                                                handelClickOnUpdateSubSecEventStatus(
                                                                                  subItem,
                                                                                  1,
                                                                                  eventItem.event_section_id
                                                                                )
                                                                              }
                                                                            />
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </IconButton>
                                                                  </ListItemSecondaryAction>
                                                                </ListItem>
                                                              </Box>
                                                            </Box>
                                                          </Draggable>
                                                        );
                                                      }
                                                    )}
                                                    {loadingState.subSectionLoadMore ? (
                                                      <SubSectionLoad />
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                ) : (
                                                  ''
                                                )}
                                              </Container>
                                            </Box>
                                          ) : (
                                            ''
                                          )}
                                        </Box>
                                      </Draggable>
                                    </React.Fragment>
                                  );
                                })}
                            </>
                          ) : (
                            ''
                          )}
                        </Container>
                      </List>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {!loadingState.eventMainSecLoad &&
              (loadingState.eventSecDetailLoad || loadingState.eventSubSecDetailLoad) ? (
                <EventSectionRight />
              ) : (
                <Box width="50%" position="relative">
                  <Box className={peopleBlock} p={2}>
                    {isDisabled(activeTab) ? (
                      <CustomToolTip
                        title="Configuration of sections can happen only from desktop, same will be reflected on mobile."
                        placement="top">
                        <Box
                          className={disabledBlock}
                          position="absolute"
                          zIndex={1}
                          top="0px"
                          left="0px"
                          height="100%"
                          width="100%"
                        />
                      </CustomToolTip>
                    ) : (
                      ''
                    )}
                    <Box className={peopleWrapper}>
                      {!activeAppIsEmbed && eventLanguages && eventLanguages.length > 1 ? (
                        <>
                          <LanguageBox
                            mb={1}
                            className={peopleHeading}
                            languages={eventLanguages}
                            selectedLanguage={selectedLanguage}
                            onLanguageChange={setSelectedLanguage}>
                            &nbsp;
                          </LanguageBox>
                          <Box pb={2} className={appSectionHeading}>
                            <Typography variant="body1" className={headingTitle} component="h4">
                              {selSectionObj.label}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <Box pb={2} className={appSectionHeading}>
                          <Typography variant="body1" className={headingTitle} component="h4">
                            {selSectionObj.label}
                          </Typography>
                        </Box>
                      )}

                      <Box py={2.5} className={appRightBlockContent}>
                        <Grid container spacing={2}>
                          {secAction === 'editMainSec' ? (
                            <>
                              <Grid item xs={12}>
                                <InputLabel>App Icon</InputLabel>
                                <Box mt={1.2}>
                                  <Button
                                    disabled={isDisabled(activeTab)}
                                    className={appchangeIconBtn}
                                    onClick={() => setIsOpen(!isOpen)}>
                                    <Box
                                      component="span"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      // className={classes.eventsIcon}
                                      height={75}
                                      width={75}>
                                      {selSectionObj.svg_image || selSectionObj.app_svg_image ? (
                                        <Box
                                          component="span"
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              selSectionObj.svg_image ||
                                              selSectionObj.app_svg_image,
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </Box>
                                  </Button>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <InputField
                              isLanguageSupport={
                                !activeAppIsEmbed && eventLanguages && eventLanguages.length > 1
                              }
                              label="Label"
                              value={selSectionObj.label}
                              required
                              disabled={isDisabled(activeTab)}
                              onChange={(e) => {
                                setSelSectionObj({ ...selSectionObj, label: e.target.value });
                                setEditErrObj({ ...editErrObj, label: '' });
                              }}
                              type="text"
                              onKeyPress={(e) => {
                                const regex = new RegExp('^[0-9a-zA-Z_. ]+$');
                                const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                                if (regex.test(str)) {
                                  return true;
                                }
                                e.preventDefault();
                                return false;
                              }}
                              placeholder="Enter Section Name"
                              error={editErrObj.label}
                              maxLength={
                                selSectionObj.event_section_meta_name === 'VIRTUAL_BOOTH' ||
                                selSectionObj.eventSectionMetaName1 === 'VIRTUAL_BOOTH'
                                  ? restrictingCharacerInput.settings.eventSectionV2.boothLabel
                                  : restrictingCharacerInput.settings.eventSectionV2.label
                              }
                              descText={getCalculatedCharLegth(
                                selSectionObj.event_section_meta_name === 'VIRTUAL_BOOTH' ||
                                  selSectionObj.eventSectionMetaName1 === 'VIRTUAL_BOOTH'
                                  ? restrictingCharacerInput.settings.eventSectionV2.boothLabel
                                  : restrictingCharacerInput.settings.eventSectionV2.label,
                                selSectionObj.label
                              )}
                            />
                          </Grid>
                          {activeAppIsEmbed && (
                            <Grid item xs={12} style={{ padding: '0 10px' }}>
                              <RadioBtn
                                label="Embed App"
                                id="embed-code"
                                handleChange={(evt) => {
                                  setEmbedType(evt.target.value);
                                }}
                                defaultValue={embedType}
                                value={embedType}
                                options={[
                                  { label: 'Embed Code', value: EmbedTypes.EMBED_CODE },
                                  { label: 'File Upload', value: EmbedTypes.FILE_UPLOAD },
                                ]}
                              />

                              {embedType === EmbedTypes.EMBED_CODE && (
                                <Box mt={3.5}>
                                  <TextAreaField
                                    rowMin={3}
                                    label="Embed Code"
                                    name="codeScript"
                                    descText="Paste your ‘iframe’ code below to embed your app"
                                    descType="TOOLTIP"
                                    required
                                    error={editErrObj.embed_code}
                                    isShowCustomDescText
                                    ToolTipCharCount
                                    placeholder={`Place your code here... \n<iframe src="https://example.com/"></iframe>`}
                                    value={selSectionObj.embed_code}
                                    maxLength={restrictingCharacerInput.embedApps.embedCode}
                                    onChange={(e) => {
                                      let code = '';
                                      if (e.target.name === 'codeScript') {
                                        code = sanitiseIframe(e.target.value);
                                      }
                                      setSelSectionObj({
                                        ...selSectionObj,
                                        embed_code: code,
                                      });
                                      setEditErrObj({ ...editErrObj, embed_code: '' });
                                    }}
                                  />
                                </Box>
                              )}

                              {embedType === EmbedTypes.FILE_UPLOAD && (
                                <Box mt={3.5}>
                                  <FileUploadUI
                                    label="File Upload"
                                    id="embed-file-upload"
                                    descText="Users will be able to zoom in, Zoom out and drag across the uploaded pdf/image file."
                                    descType="TOOLTIP"
                                    required
                                    maxSize={10}
                                    fileTypes={['PDF', 'IMAGE']}
                                    fileData={fileData}
                                    uploadStatus={fileUploadData.uploadStatus}
                                    uploadingProgress={fileUploadData.uploadingPer}
                                    btnLoader={loadingState.isUpload}
                                    setFileData={setFileData}
                                    extraUploadInfo="2000x2000px (Maximum) | 10MB (Maximum)"
                                    onUploadFile={uploadFile}
                                    onErrorMessage={(message) => {
                                      dispatch(
                                        setNewMessage({ message, type: 'info', show: true })
                                      );
                                    }}
                                    setFileUploadData={setFileUploadData}
                                    onCancelUpload={() => {
                                      onRemoveFile();
                                      setCancelUpload(true);
                                    }}
                                    onDeleteFile={() => {
                                      onRemoveFile();
                                    }}
                                    editFieldProps={{
                                      placeholder: 'Enter File Name',
                                      value: fileData.fileName,
                                      onChange: (event) =>
                                        setFileData({ ...fileData, fileName: event.target.value }),
                                      isAllowFileNameEdit: false,
                                    }}
                                    comingFrom="EmbedApp"
                                    fileSize={
                                      fileData.fileBlob && typeof fileData.fileBlob === 'object'
                                        ? (fileData?.fileBlob?.size / (1024 * 1024)).toFixed(2)
                                        : ''
                                    }
                                    maxImageHeight={2000}
                                    maxImageWidth={2000}
                                    downloadUrl={
                                      fileData.fileName
                                        ? getBrandEventImage(
                                            'embed_app',
                                            fileData.fileName,
                                            reduxData.organiserId,
                                            reduxData.eventId
                                          )
                                        : ''
                                    }
                                  />
                                </Box>
                              )}
                            </Grid>
                          )}
                        </Grid>
                        {(secAction === 'editSubSec' || secAction === 'editMainSec') &&
                        selSectionObj.is_parent === 0 ? (
                          <Box mt={3}>
                            {selSectionObj.event_section_meta_name === 'ATTENDEES' ? (
                              <Box className={dividerBlock}>
                                <Divider />
                              </Box>
                            ) : (
                              false
                            )}
                            <List className={eventSettingBlock}>
                              {selSectionObj.event_section_meta_name === 'ATTENDEES' ? (
                                <>
                                  <ListItem disableGutters>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        variant: 'body2',
                                        component: 'label',
                                      }}
                                      primary="Show logged in profiles only"
                                    />
                                    <ListItemSecondaryAction>
                                      <Box className={switchBtnStyle}>
                                        <SwitchField
                                          disabled={activeTab === 'mobile'}
                                          name="isShowLoggedInOnlyInAttendeeTab"
                                          checked={selSectionObj.show_loggedin_profile_only === 1}
                                          onChange={(e) => {
                                            setSelSectionObj({
                                              ...selSectionObj,
                                              show_loggedin_profile_only: e.target.checked ? 1 : 0,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <ListItem disableGutters>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        variant: 'body2',
                                        component: 'label',
                                      }}
                                      primary="Show online profile toggle"
                                    />
                                    <ListItemSecondaryAction>
                                      <Box className={switchBtnStyle}>
                                        <SwitchField
                                          disabled={activeTab === 'mobile'}
                                          name="isShowOnlineAttendeeTab"
                                          checked={selSectionObj.show_online_profile === 1}
                                          onChange={(e) => {
                                            setSelSectionObj({
                                              ...selSectionObj,
                                              show_online_profile: e.target.checked ? 1 : 0,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <ListItem disableGutters>
                                    <ListItemText
                                      primary={
                                        <>
                                          <Box
                                            className="d-flex"
                                            display="inline-flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            position="relative">
                                            <Typography variant="body2" component="label">
                                              Segregate profiles into tabs{' '}
                                              <CustomToolTip
                                                isFabIcon
                                                title="The people profiles will be displayed under tabs of people groups."
                                                placement="top"
                                              />
                                            </Typography>
                                          </Box>
                                        </>
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      <Box className={switchBtnStyle}>
                                        <SwitchField
                                          name="isSegregateIntoGroups"
                                          disabled={activeTab === 'mobile'}
                                          checked={selSectionObj.segregate_profile_into_tabs === 1}
                                          onChange={(e) => {
                                            setSelSectionObj({
                                              ...selSectionObj,
                                              segregate_profile_into_tabs: e.target.checked ? 1 : 0,
                                            });
                                          }}
                                        />
                                      </Box>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                  <ListItem dense disableGutters divider />
                                  <ListItem disableGutters className={peopleSubTitle}>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="body2"
                                          className={headingTitle}
                                          component="label">
                                          Default Sort Order
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                  <ListItem disableGutters>
                                    <BtnGroup
                                      isFluidBtn
                                      disabled={activeTab === 'mobile'}
                                      options={[
                                        {
                                          label: 'Sort By Name',
                                          isChecked:
                                            selSectionObj.default_sort_order === 'name' ? 1 : 0,
                                          type: 'name',
                                        },
                                        {
                                          label: 'Sort by Date Added',
                                          isChecked:
                                            selSectionObj.default_sort_order === 'date' ? 1 : 0,
                                          type: 'date',
                                        },
                                      ]}
                                      onChange={(val) => {
                                        setSelSectionObj({
                                          ...selSectionObj,
                                          default_sort_order: val.type,
                                        });
                                      }}
                                    />
                                  </ListItem>
                                </>
                              ) : (
                                ''
                              )}
                              {selSectionObj.event_section_meta_name === 'SPEAKERS' ? (
                                <>
                                  <ListItem dense disableGutters divider />
                                  <ListItem disableGutters className={peopleSubTitle}>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="body2"
                                          className={headingTitle}
                                          component="label">
                                          Default Sort Order
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                  <ListItem disableGutters>
                                    <BtnGroup
                                      isFluidBtn
                                      disabled={activeTab === 'mobile'}
                                      options={[
                                        {
                                          label: 'Sort By Name',
                                          isChecked:
                                            selSectionObj.default_sort_order === 'name' ? 1 : 0,
                                          type: 'name',
                                        },
                                        {
                                          label: 'Sort by Category',
                                          isChecked:
                                            selSectionObj.default_sort_order === 'category' ? 1 : 0,
                                          type: 'category',
                                        },
                                      ]}
                                      onChange={(val) => {
                                        setSelSectionObj({
                                          ...selSectionObj,
                                          default_sort_order: val.type,
                                        });
                                      }}
                                    />
                                  </ListItem>
                                </>
                              ) : (
                                ''
                              )}
                              {selSectionObj.event_section_meta_name === 'ATTENDEES' ? (
                                <>
                                  <ListItem dense disableGutters divider />
                                  <ListItem disableGutters className={peopleSubTitle}>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        variant: 'body2',
                                        component: 'label',
                                      }}
                                      primary={
                                        <Box
                                          component="span"
                                          display="inline-flex"
                                          alignItems="center"
                                          position="relative">
                                          <Box component="span">
                                            <Typography
                                              variant="body2"
                                              className={headingTitle}
                                              component="label">
                                              Display Profiles Of{' '}
                                              <CustomToolTip
                                                isFabIcon
                                                title="Only the People Groups you choose below will be visible in this section."
                                                placement="top"
                                              />
                                            </Typography>
                                          </Box>
                                        </Box>
                                      }
                                    />
                                  </ListItem>
                                  {selSectionObj.eventSectionProfile &&
                                  selSectionObj.eventSectionProfile.length > 0 ? (
                                    <Container
                                      lockAxis="y"
                                      orientation="vertical"
                                      onDrop={(dropResult) => {
                                        onDropEditSectionData(dropResult, 'display_profile_groups');
                                      }}>
                                      {selSectionObj.eventSectionProfile.map((item, key) => {
                                        return (
                                          <Draggable key={key} className="">
                                            <ListItem disableGutters className={groupDragable}>
                                              <Box
                                                className={`${selectColumns} cursor-pointer`}
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Box>
                                                  <Box>
                                                    <Box
                                                      component="span"
                                                      padding="7.5px 4px"
                                                      display="flex"
                                                      flexDirection="column">
                                                      <SvgUnionDotsIcon
                                                        color="#a5a5a5"
                                                        height="18px"
                                                        width="11px"
                                                      />
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <Typography
                                                  variant="body2"
                                                  noWrap
                                                  component="span"
                                                  className={selectColumnField}>
                                                  {item.name}
                                                </Typography>
                                                <Box className={switchBtnDrgStyle}>
                                                  <SwitchField
                                                    disabled={activeTab === 'mobile'}
                                                    name="display_profile_groups"
                                                    checked={item.is_visible === 1}
                                                    onChange={(e) =>
                                                      handelClickOnSwitch(
                                                        e,
                                                        'display_profile_groups',
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            </ListItem>
                                          </Draggable>
                                        );
                                      })}
                                    </Container>
                                  ) : (
                                    false
                                  )}
                                </>
                              ) : (
                                ''
                              )}

                              {selSectionObj.event_section_meta_name === 'SPEAKERS' ? (
                                <>
                                  <ListItem dense disableGutters divider />
                                  <ListItem disableGutters className={peopleSubTitle}>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        variant: 'body2',
                                        component: 'label',
                                      }}
                                      primary={
                                        <Box
                                          component="span"
                                          display="inline-flex"
                                          alignItems="center"
                                          position="relative">
                                          <Box component="span">
                                            <Typography
                                              variant="body2"
                                              className={headingTitle}
                                              component="label">
                                              Display Categories{' '}
                                              <CustomToolTip
                                                isFabIcon
                                                title="Only the Speaker Categories you choose below will be visible in this section."
                                                placement="top"
                                              />
                                            </Typography>
                                          </Box>
                                        </Box>
                                      }
                                    />
                                  </ListItem>
                                  <Container
                                    lockAxis="y"
                                    orientation="vertical"
                                    onDrop={(dropResult) => {
                                      onDropEditSectionData(dropResult, 'display_category');
                                    }}>
                                    {selSectionObj.eventSectionDisplayCategory &&
                                    selSectionObj.eventSectionDisplayCategory.length > 0 ? (
                                      <>
                                        {selSectionObj.eventSectionDisplayCategory.map(
                                          (item, key) => {
                                            return (
                                              <React.Fragment key={key}>
                                                {' '}
                                                <Draggable className="">
                                                  <ListItem
                                                    disableGutters
                                                    className={`speakerDragItem ${groupDragable}`}>
                                                    <Box
                                                      className={`${selectColumns} cursor-pointer`}
                                                      display="flex"
                                                      justifyContent="space-between"
                                                      alignItems="center">
                                                      <Box>
                                                        <IconButton size="small">
                                                          <Box
                                                            component="span"
                                                            padding="7.5px 4px"
                                                            display="flex"
                                                            flexDirection="column">
                                                            <SvgUnionDotsIcon
                                                              color="#a5a5a5"
                                                              height="18px"
                                                              width="11px"
                                                            />
                                                          </Box>
                                                        </IconButton>
                                                      </Box>
                                                      <Typography
                                                        variant="body2"
                                                        noWrap
                                                        component="span"
                                                        className={selectColumnField}>
                                                        {item.name}{' '}
                                                      </Typography>
                                                      <Box className={switchBtnDrgStyle}>
                                                        <SwitchField
                                                          name="display_category"
                                                          disabled={activeTab === 'mobile'}
                                                          checked={item.is_visible === 1}
                                                          onChange={(e) =>
                                                            handelClickOnSwitch(
                                                              e,
                                                              'display_category',
                                                              item.id
                                                            )
                                                          }
                                                        />
                                                      </Box>
                                                    </Box>
                                                  </ListItem>
                                                </Draggable>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      'Categories added to Speakers will appear here. Add categories while adding Speaker'
                                    )}
                                  </Container>
                                </>
                              ) : (
                                ''
                              )}
                              {selSectionObj.event_section_meta_name === 'VIRTUAL_BOOTH' ? (
                                <>
                                  <ListItem dense disableGutters divider />
                                  <ListItem disableGutters className={peopleSubTitle}>
                                    <ListItemText
                                      primaryTypographyProps={{
                                        variant: 'body2',
                                        component: 'label',
                                      }}
                                      primary={
                                        <Box
                                          component="span"
                                          display="inline-flex"
                                          alignItems="center"
                                          position="relative">
                                          <Box component="span">
                                            <Typography
                                              variant="body2"
                                              className={headingTitle}
                                              component="label">
                                              Display Categories{' '}
                                              <CustomToolTip
                                                isFabIcon
                                                title="Only the Virtual Booth Categories you choose below will be visible in this section."
                                                placement="top"
                                              />{' '}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      }
                                    />
                                  </ListItem>
                                  {selSectionObj.eventSectionDisplayCategory &&
                                  selSectionObj.eventSectionDisplayCategory.length > 0 ? (
                                    <>
                                      {selSectionObj.eventSectionDisplayCategory.map(
                                        (item, key) => {
                                          return (
                                            <ListItem
                                              key={key}
                                              disableGutters
                                              className={groupDragable}>
                                              <Box
                                                className={`${selectColumns} cursor-pointer`}
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Typography
                                                  variant="body2"
                                                  noWrap
                                                  component="span"
                                                  className={selectColumnField}>
                                                  {item.exhibitor_category_name}{' '}
                                                </Typography>
                                                <Box className={switchBtnDrgStyle}>
                                                  <SwitchField
                                                    disabled={activeTab === 'mobile'}
                                                    name="exhibitor_category"
                                                    checked={item.is_visible === 1}
                                                    onChange={(e) =>
                                                      handelClickOnSwitch(
                                                        e,
                                                        'exhibitor_category',
                                                        item.id
                                                      )
                                                    }
                                                  />
                                                </Box>
                                              </Box>
                                            </ListItem>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    'Categories added to Virtual Booths will appear here. Add categories while adding Virtual Booths'
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                              <ListItem dense disableGutters divider />
                              <ListItem disableGutters className={peopleSubTitle}>
                                <ListItemText
                                  primary={
                                    <Box
                                      component="span"
                                      display="inline-flex"
                                      alignItems="center"
                                      position="relative">
                                      <Box component="span">
                                        <Typography
                                          variant="body2"
                                          className={headingTitle}
                                          component="label">
                                          Make This Section Visible To{' '}
                                          <CustomToolTip
                                            isFabIcon
                                            title="Show or Hide this section to the particular group only."
                                            placement="top"
                                          />{' '}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              {selSectionObj.eventSectionVisibility &&
                              selSectionObj.eventSectionVisibility.length > 0 ? (
                                <>
                                  {selSectionObj.eventSectionVisibility.map((item, key) => {
                                    return (
                                      <React.Fragment key={key}>
                                        <ListItem disableGutters>
                                          <ListItemText
                                            primaryTypographyProps={{
                                              variant: 'body2',
                                              component: 'label',
                                            }}
                                            primary={item.name}
                                          />
                                          <ListItemSecondaryAction>
                                            <Box className={switchBtnStyle}>
                                              <SwitchField
                                                disabled={activeTab === 'mobile'}
                                                name="section_Visible"
                                                checked={item.is_visible === 1}
                                                onChange={(e) =>
                                                  handelClickOnSwitch(e, 'make_section', item.id)
                                                }
                                              />
                                            </Box>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      </React.Fragment>
                                    );
                                  })}
                                </>
                              ) : (
                                false
                              )}
                            </List>
                            {HEAVY_SESSION_COMMUNITY &&
                              selSectionObj.eventSectionMetaName === 'AGENDA' &&
                              selSectionObj?.agendaComponentsVisibility && (
                                <>
                                  <ListItem dense disableGutters divider />
                                  <Box mt={3} className={classes.holisticAgendaTab}>
                                    {basicEventInfo.eventType === EventTypes.HYBRID && (
                                      <BtnGroup
                                        isFluidBtn
                                        options={[
                                          {
                                            label: 'On-site',
                                            type: agendaHSTabs.ONSITE,
                                            isChecked: activeTabSidePanel === agendaHSTabs.ONSITE,
                                          },
                                          {
                                            label: 'Virtual',
                                            type: agendaHSTabs.VIRTUAL,
                                            isChecked: activeTabSidePanel === agendaHSTabs.VIRTUAL,
                                          },
                                        ]}
                                        onChange={(val) => {
                                          setActiveTabSidePanel(val.type);
                                        }}
                                      />
                                    )}
                                    {activeTabSidePanel === agendaHSTabs.ONSITE ? (
                                      <List>
                                        <ListItem disableGutters className={peopleSubTitle}>
                                          <ListItemText
                                            primary={
                                              <Box
                                                component="span"
                                                display="inline-flex"
                                                alignItems="center"
                                                position="relative">
                                                <Box component="span">
                                                  <Typography
                                                    variant="body2"
                                                    className={headingTitle}
                                                    component="label">
                                                    Agenda section will show
                                                    <CustomToolTip
                                                      isFabIcon
                                                      title="Show or hide the components you want to show on the session card"
                                                      placement="top"
                                                    />{' '}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            }
                                          />
                                        </ListItem>
                                        {selSectionObj.eventSectionVisibility &&
                                        selSectionObj.eventSectionVisibility.length > 0 ? (
                                          <>
                                            {onSiteHSList.map((item, key) => {
                                              const isChecked =
                                                selSectionObj?.agendaComponentsVisibility[
                                                  activeTabSidePanel
                                                ] &&
                                                Object.keys(
                                                  selSectionObj?.agendaComponentsVisibility[
                                                    activeTabSidePanel
                                                  ]
                                                ).length &&
                                                selSectionObj?.agendaComponentsVisibility[
                                                  activeTabSidePanel
                                                ][item.apiName] === 1;
                                              return (
                                                <React.Fragment key={key}>
                                                  <ListItem disableGutters>
                                                    <ListItemText
                                                      primaryTypographyProps={{
                                                        variant: 'body2',
                                                        component: 'label',
                                                      }}
                                                      primary={item.name}
                                                    />
                                                    <ListItemSecondaryAction>
                                                      <Box className={switchBtnStyle}>
                                                        <SwitchField
                                                          disabled={activeTab === 'mobile'}
                                                          name="section_Visible"
                                                          checked={isChecked}
                                                          onChange={(e) =>
                                                            handelClickOnSwitch(
                                                              e,
                                                              'hs-section',
                                                              item
                                                            )
                                                          }
                                                        />
                                                      </Box>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                </React.Fragment>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          false
                                        )}
                                      </List>
                                    ) : (
                                      <List>
                                        <ListItem disableGutters className={peopleSubTitle}>
                                          <ListItemText
                                            primary={
                                              <Box
                                                component="span"
                                                display="inline-flex"
                                                alignItems="center"
                                                position="relative">
                                                <Box component="span">
                                                  <Typography
                                                    variant="body2"
                                                    className={headingTitle}
                                                    component="label">
                                                    Agenda section will show
                                                    <CustomToolTip
                                                      isFabIcon
                                                      title="Show or hide the components you want to show on the session card"
                                                      placement="top"
                                                    />{' '}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            }
                                          />
                                        </ListItem>
                                        {selSectionObj.eventSectionVisibility &&
                                        selSectionObj.eventSectionVisibility.length > 0 ? (
                                          <>
                                            {virtualHSList.map((item, key) => {
                                              const isChecked =
                                                selSectionObj?.agendaComponentsVisibility[
                                                  activeTabSidePanel
                                                ] &&
                                                Object.keys(
                                                  selSectionObj?.agendaComponentsVisibility[
                                                    activeTabSidePanel
                                                  ]
                                                ).length &&
                                                selSectionObj?.agendaComponentsVisibility[
                                                  activeTabSidePanel
                                                ][item.apiName] === 1;
                                              return (
                                                <React.Fragment key={key}>
                                                  <ListItem disableGutters>
                                                    <ListItemText
                                                      primaryTypographyProps={{
                                                        variant: 'body2',
                                                        component: 'label',
                                                      }}
                                                      primary={item.name}
                                                    />
                                                    <ListItemSecondaryAction>
                                                      <Box className={switchBtnStyle}>
                                                        <SwitchField
                                                          disabled={activeTab === 'mobile'}
                                                          name="section_Visible"
                                                          checked={isChecked}
                                                          onChange={(e) =>
                                                            handelClickOnSwitch(
                                                              e,
                                                              'hs-section',
                                                              item
                                                            )
                                                          }
                                                        />
                                                      </Box>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>
                                                </React.Fragment>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          false
                                        )}
                                      </List>
                                    )}
                                  </Box>
                                  <Button
                                    onClick={handleSetToDefault}
                                    color="inherit"
                                    variant="text"
                                    disabled={
                                      !isEnableSetToDefault(
                                        selSectionObj?.agendaComponentsVisibility
                                      )
                                    }
                                    size="small"
                                    className="setDefaultBtn">
                                    <Typography variant="body2" component="p" color="textSecondary">
                                      Set to default
                                    </Typography>
                                  </Button>
                                </>
                              )}
                          </Box>
                        ) : (
                          ''
                        )}
                        {/* {selSectionObj.eventSectionMetaName === 'PEOPLE' ? (
                          <List>
                            <ListItem disableGutters>
                              <ListItemText
                                primaryTypographyProps={{
                                  variant: 'body2',
                                  component: 'label',
                                }}
                                primary="People section as Primary Item"
                              />
                              <ListItemSecondaryAction>
                                <Box className={switchBtnStyle}>
                                  <SwitchField
                                    disabled={activeTab === 'mobile'}
                                    name="section_Visible"
                                    checked={peoplePrimary === 1}
                                    onChange={() => setPeoplePrimary(peoplePrimary === 1 ? 0 : 1)}
                                  />
                                </Box>
                              </ListItemSecondaryAction>
                            </ListItem>
                          </List>
                        ) : (
                          ''
                        )} */}
                      </Box>
                    </Box>
                    <Box display="flex" pt={2} mt={2} className={footerActionBtn}>
                      <BtnWithLoader
                        onClick={() => {
                          setSelSectionObj(selTempEventSectionData);
                          setEditErrObj({});
                        }}
                        disabled={
                          activeTab === 'mobile' ||
                          loadingState.createOrEditEventSectionLoad ||
                          userInfo.UserInfo.isPlanExpired ||
                          userInfo.UserInfo.subscriptionStatus === 'CANCELLED'
                        }
                        label="Cancel"
                        variant="outlined"
                        className="uppercase"
                        size="small"
                        color="primary"
                      />
                      <Box ml={1.2}>
                        <BtnWithLoader
                          onClick={saveEventSubAndMainSection}
                          disabled={
                            activeTab === 'mobile' ||
                            loadingState.createOrEditEventSectionLoad ||
                            userInfo.UserInfo.isPlanExpired ||
                            userInfo.UserInfo.subscriptionStatus === 'CANCELLED'
                          }
                          isLoading={loadingState.createOrEditEventSectionLoad}
                          loadingLabel="Saving"
                          label="Save"
                          variant="contained"
                          className="uppercase"
                          size="small"
                          color="primary"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <EventSectionLoader />
        )}
      </CardWrapper>
      <IconSideBar
        classes={classes}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        dataList={activeAppIsEmbed ? iconList : iconData}
        loadingState={activeAppIsEmbed ? isAddAppLoading : loadingState}
        handelClickOnIcon={handelClickOnIcon}
        action={secAction}
        activeTab={activeTab}
        selEventSectionData={selSectionObj}
        isEmbedAppActive={activeAppIsEmbed}
      />
      <ConfirmationPopUp
        isOpen={isConfirmModalOpen}
        onClose={() => toggleDeleteModal(false)}
        data={{
          title: 'You are about to delete event section',
          primaryDesc: 'Are you sure you want to continue?',
          Icon: Trash2,
          actionBtn: [
            {
              name: 'CANCEL',
              onClick: () => {
                toggleDeleteModal(false);
                setSelSectionId('');
              },
            },
            {
              name: 'Delete',
              type: 'primary',
              onClick: handelConfirmClickOnDeleteSection,
              isLoading: loadingState.deleteEventLoader,
              loadingLabel: 'Deleting',
            },
          ],
        }}
      />
    </>
  );
}

const IconSideBar = (props) => {
  const [iconEvt, setIconEvt] = React.useState('');
  const [selIconId, setSelIconId] = React.useState(-1);
  const [tempSelIconId, setTempSelIconId] = React.useState(-1);
  const {
    isOpen,
    action,
    selEventSectionData,
    activeTab,
    dataList,
    loadingState,
    isEmbedAppActive = false,
  } = props;

  useEffect(() => {
    if (isEmbedAppActive) {
      if (props.selEventSectionData.integrated_app_icon_id) {
        setSelIconId(props.selEventSectionData.integrated_app_icon_id);
        setTempSelIconId(props.selEventSectionData.integrated_app_icon_id);
        setIconEvt(
          props.dataList.filter((iconsList) => {
            return iconsList.id === props.selEventSectionData.integrated_app_icon_id;
          })[0]
        );
      }
    } else {
      if (props.selEventSectionData.event_section_icon_id) {
        setSelIconId(props.selEventSectionData.event_section_icon_id);
        setTempSelIconId(props.selEventSectionData.event_section_icon_id);
        setIconEvt(
          props.dataList.filter((iconsList) => {
            return iconsList.id === props.selEventSectionData.event_section_icon_id;
          })[0]
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, selEventSectionData]);

  return (
    <Sidebar
      open={isOpen}
      title="Choose Icon"
      onClose={() => {
        props.onClose();
        setSelIconId(tempSelIconId);
      }}
      asideWidth={650}
      footerBtnLabel="Choose"
      disabled={activeTab === 'mobile'}
      footerBtnAction={() => {
        props.handelClickOnIcon(iconEvt);
        setTempSelIconId(iconEvt.id);
      }}>
      <Grid container spacing={2}>
        {dataList && dataList.length > 0 ? (
          <>
            {dataList.map((item, key) => {
              return (
                <Grid item xs={2} key={key}>
                  {selIconId === item.id ? (
                    <Button
                      disabled={props.activeTab === 'mobile'}
                      className={`${props.classes.iconButtonSidebar} ${props.classes.activeBorderColor}`}
                      data-item={JSON.stringify(item)}
                      onClick={() => {
                        setIconEvt(item);
                        setSelIconId(item.id);
                      }}>
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={props.classes.eventsIcon}
                        height={60}
                        width={60}>
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          dangerouslySetInnerHTML={{ __html: item.svg_image }}
                        />
                      </Box>
                    </Button>
                  ) : (
                    <Button
                      disabled={props.activeTab === 'mobile'}
                      className={props.classes.iconButtonSidebar}
                      data-item={JSON.stringify(item)}
                      onClick={() => {
                        setIconEvt(item);
                        setSelIconId(item.id);
                      }}>
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={props.classes.eventsIcon}
                        height={60}
                        width={60}>
                        <Box
                          component="span"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          dangerouslySetInnerHTML={{ __html: item.svg_image || item.app_svg_image }}
                        />
                      </Box>
                    </Button>
                  )}
                </Grid>
              );
            })}
          </>
        ) : (
          false
        )}
      </Grid>
      {loadingState.iconLibLoad ? <IconSidebarLoader /> : false}
    </Sidebar>
  );
};

export default React.memo(EventSectionsNewUI);
