export const getBrowserAndItsVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  let browser = '';
  let version = '';
  if (ua.indexOf('edge') !== -1) {
    browser = 'edge';
    version = ua.substring(ua.indexOf('edge') + 5);
  } else if (ua.indexOf('edg') !== -1) {
    browser = 'edge';
    version = ua.substring(ua.indexOf('edg') + 4);
  } else if (ua.indexOf('opr') !== -1) {
    browser = 'opera';
    version = ua.substring(ua.indexOf('opr') + 4);
  } else if (ua.indexOf('chrome') !== -1) {
    browser = 'chrome';
    version = ua.substring(ua.indexOf('chrome') + 7);
  } else if (ua.indexOf('firefox') !== -1) {
    browser = 'firefox';
    version = ua.substring(ua.indexOf('firefox') + 8);
  } else if (ua.indexOf('safari') !== -1) {
    browser = 'safari';
    version = ua.substring(ua.indexOf('safari') + 7);
  }
  if (version.indexOf(' ') !== -1) version = version.substring(0, version.indexOf(' '));
  return { browser, version: parseInt(version, 10) };
};