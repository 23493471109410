import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Smartphone, Tablet, Monitor } from 'react-feather';
import { useStyles } from './style';
import { TEMPLATE_PREVIEW, DEF_TEMPLATE_PREVIEW } from './constants';
import SvgTabMockUp from 'icons/svgTabMockUp';
import SvgMobMockup from 'icons/svgMobMockup';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';

const ChooseTemplateCard = ({ handlePreview, handleBuild, templateData, index }) => {
  const classes = useStyles();
  const {
    desktopThumbnailUrl = '',
    tabletThumbnailUrl = '',
    mobileThumbnailUrl = '',
  } = templateData;
  let thumbnailUrl = '';
  const [getSelectedView, setSelectedView] = useState({
    id: templateData.id,
    preview: DEF_TEMPLATE_PREVIEW,
  });

  if (getSelectedView.id === templateData.id && getSelectedView.preview === 'DESKTOP') {
    thumbnailUrl = desktopThumbnailUrl;
  } else if (getSelectedView.id === templateData.id && getSelectedView.preview === 'TABLET') {
    thumbnailUrl = tabletThumbnailUrl;
  } else if (getSelectedView.id === templateData.id && getSelectedView.preview === 'MOBILE') {
    thumbnailUrl = mobileThumbnailUrl;
  }
  const previewHandler = (e, id, previewType) => {
    e.preventDefault();
    setSelectedView({ id, preview: previewType });
    commonTrackEvent('preview template', {
      Template_id: templateData.id,
      'Template name ': templateData.name,
      'rank template': index,
      'Preview type': previewType,
    });
  };
  return (
    <>
      <Box className={classes.chooseCard} position="relative">
        <Box
          p={1.5}
          width={getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP ? '100%' : 'auto'}
          className="cardActionBlock">
          {getSelectedView.preview === TEMPLATE_PREVIEW.MOBILE ? (
            <Box position="relative">
              <SvgMobMockup height="15rem" />
              <Box className="previewMob">
                {thumbnailUrl.includes('png') || thumbnailUrl.includes('jpg') ? (
                  <img src={thumbnailUrl} alt="template-img" />
                ) : (
                  <iframe
                    title="Embed Iframe"
                    src={thumbnailUrl}
                    name="customTemplatePreview"
                    width="100%"
                    className="iframeSet"
                    height="100%"
                  />
                )}
              </Box>
            </Box>
          ) : (
            false
          )}
          {getSelectedView.preview === TEMPLATE_PREVIEW.TABLET ? (
            <Box position="relative">
              <SvgTabMockUp height="15rem" />
              <Box className="previewTab">
                {thumbnailUrl.includes('png') || thumbnailUrl.includes('jpg') ? (
                  <img src={thumbnailUrl} alt="template-img" />
                ) : (
                  <iframe
                    title="Embed Iframe"
                    src={thumbnailUrl}
                    name="customTemplatePreview"
                    width="100%"
                    className="iframeSet"
                    height="100%"
                  />
                )}{' '}
              </Box>
            </Box>
          ) : (
            false
          )}
          {getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP ? (
            <Box className="deskPreview">
              {thumbnailUrl.includes('png') || thumbnailUrl.includes('jpg') ? (
                <img src={thumbnailUrl} alt="template-img" />
              ) : (
                <iframe
                  title="Embed Iframe"
                  src={thumbnailUrl}
                  name="customTemplatePreview"
                  width="100%"
                  className="iframeSet"
                  height={270}
                />
              )}{' '}
            </Box>
          ) : (
            false
          )}
          <Box
            className="cardAction"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => handleBuild(templateData)}>
              Start Building
            </Button>
            <Button onClick={() => handlePreview(templateData, getSelectedView.preview, index)}>
              Preview
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        mt={1}
        className={classes.chooseCardContent}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Box>
          <Typography variant="h5" component="h5">
            {templateData.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" className="actionButtonIcon">
          <IconButton
            className={
              getSelectedView.id === templateData.id &&
              getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP
                ? 'active'
                : ''
            }
            onClick={(e) => previewHandler(e, templateData.id, TEMPLATE_PREVIEW.DESKTOP)}>
            <Monitor size={20} />
          </IconButton>
          <IconButton
            className={
              getSelectedView.id === templateData.id &&
              getSelectedView.preview === TEMPLATE_PREVIEW.TABLET
                ? 'active'
                : ''
            }
            onClick={(e) => previewHandler(e, templateData.id, TEMPLATE_PREVIEW.TABLET)}>
            <Tablet size={20} />
          </IconButton>
          <IconButton
            className={
              getSelectedView.id === templateData.id &&
              getSelectedView.preview === TEMPLATE_PREVIEW.MOBILE
                ? 'active'
                : ''
            }
            onClick={(e) => previewHandler(e, templateData.id, TEMPLATE_PREVIEW.MOBILE)}>
            <Smartphone size={15} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ChooseTemplateCard;
