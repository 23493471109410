import React from 'react';

import { Box, Typography, Container } from '@material-ui/core';
import Breadcrumb from './Breadcrumb';
import Steppers from './Steppers';
import { EActionType, EEventScreen } from 'pages/CreateNewEvent/common/constants';
import { useStyles } from '../style';
import { useParams } from 'react-router';
import { userInfoSelector } from 'redux/custom-selector';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useCustomCreateEventContext } from '../context';
import { PricingPlan } from 'constants/pricingPlan';

const CreateNewEventHeader = ({ actionType }) => {
  const classes = useStyles();
  // params - contains the route param such as screen ID, eventID, organiser id
  const params = useParams();
  // userInfo - contains logged in user data
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  // screenId - represents the current screen (ex: format, type, details, language)
  const { state } = useCustomCreateEventContext();
  const { screenId } = params;

  // renderHeading - based on screenId, the respective compoenent header will be rendered.
  // ex: If screenId is 1 then Select the format of your event will be rendered
  const renderHeading = () => {
    switch (Number(screenId)) {
      case EEventScreen.EVENT_TYPE:
        return `What’s your goal for this ${state.format === 'WEBINAR' ? 'webinar' : 'event'}?`; // TODO
      case EEventScreen.EVENT_DETAILS:
        return `What’s your ${
          state.format === 'WEBINAR' ? 'webinar' : 'event'
        } about? Add some details.`;
      case EEventScreen.EVENT_LANGUAGES:
        return `${state.format === 'WEBINAR' ? "Choose the language that your webinar will support" : "Choose language support for your event"}`;
      default:
        return `What's the format of your event?`;
    }
  };

  return (
    <Box className={classes.headerFixed}>
      <Container maxWidth="lg">
        <Breadcrumb />
        {(userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
          userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE ||
          !userInfo?.UserInfo?.planName) &&
          actionType === EActionType.CREATE && (
            <Box mt={1.5}>
              <Typography
                component="h4"
                variant="h4"
                className={classes.fontStrong}
                color="textPrimary">{`Hi ${userInfo.UserInfo.first_name}, let’s create your event`}</Typography>
            </Box>
          )}
        <Box mt={3}>
          <Steppers />{' '}
        </Box>
        <Box mt={3}>
          <Typography component="h6" variant="h6" color="textPrimary">
            {renderHeading()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

CreateNewEventHeader.propTypes = {
  actionType: PropTypes.oneOf([EActionType.EDIT, EActionType.CREATE]).isRequired,
};

export default CreateNewEventHeader;
