import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, LabelList } from 'recharts';
import { Grid, Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import CustomToolTip from 'custom-components/customToolTip';
import { useStyles } from './lead-level-distribution-style';
import BtnGroup from 'custom-components/form-fields/btnGroup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { leadScoreSelector } from '../../lead-score-redux-flow/lead-score-selector';
import { getLeadLevelOverviewThunk } from '../../lead-score-redux-flow/thunks/lead-level-analytics-thunk';
import { dataMapperForGraph } from './handler';
import { getLeadLevelDetails } from '../../lead-level/handler';
import ChartsTooltipComponent from './ChartsTooltipCommon';
import { PieChartLoader } from './PieChartLoader';
import SvgPieChartIcon from 'icons/svgPieChartIcon';

const NoDataYetSvg = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height={170}>
      <SvgPieChartIcon width="180" height="180" />
    </Box>
  );
};

const CustomizedLabel = (props) => {
  const { x, fill } = props;
  return (
    <text x={x} fill={fill} textAnchor="start">
      {x} %
    </text>
  );
};

const LeadLevelDistribution = () => {
  const { chartBlock } = useStyles();
  const [status, setStatus] = useState('live'); // live | onDemand
  const dispatch = useDispatch();
  const { isGetOverviewLoading, overview } = useSelector(leadScoreSelector, shallowEqual);
  const [graphData, setGraphData] = useState({
    distribution: [],
    totalAttendees: 0,
    avgAttendeeScore: 0,
  }); // based on live & onDemand it will change
  useEffect(() => {
    dispatch(getLeadLevelOverviewThunk());
  }, [dispatch]);

  useEffect(() => {
    if (overview && overview[status] && overview.levels) {
      const data = dataMapperForGraph({ levels: overview.levels, distribution: overview[status] });
      setGraphData(data);
    }
  }, [status, overview]);

  const isNoData = Boolean(graphData.totalAttendees === 0);

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={12} className={`${chartBlock}`}>
          {isGetOverviewLoading ? (
            <PieChartLoader />
          ) : (
            <Card>
              <CardHeader
                // className={classes.cardHeader2}
                title={
                  <>
                    <Box display="table-cell" position="relative">
                      <Typography variant="subtitle2" component="h3" className="heading">
                        LEVEL DISTRIBUTION
                      </Typography>
                      <CustomToolTip
                        isFabIcon
                        title="Distribution of attendee’s into levels, as determined by you."
                        placement="top"
                      />
                    </Box>
                    <Box className="legendBlock">
                      <BtnGroup
                        isFluidBtn
                        options={[
                          {
                            label: 'Live',
                            type: 'live',
                            isChecked: status === 'live',
                          },
                          {
                            label: 'on-Demand',
                            type: 'onDemand',
                            isChecked: status === 'onDemand',
                          },
                        ]}
                        onChange={(e) => {
                          setStatus(e.type);
                        }}
                      />
                    </Box>
                  </>
                }
                subheader={
                  <>
                    <Box mt={3} display="flex" alignItems="flex-start">
                      <Box display="table-cell">
                        <Box display="flex" alignItems="center" position="relative">
                          <Typography variant="body2" component="h3">
                            Total Attendees
                          </Typography>
                          <CustomToolTip
                            isSelfAligned
                            title="The total number of people who attended the event."
                            placement="top"
                          />
                        </Box>
                        <Typography color="primary" variant="h5" component="h3">
                          {graphData.totalAttendees}
                        </Typography>
                      </Box>
                      <Box display="table-cell" pl={10}>
                        <Box display="flex" alignItems="center" position="relative">
                          <Typography variant="body2" component="h3">
                            Avg. Engagement Score
                          </Typography>
                          <CustomToolTip
                            isSelfAligned
                            title="The average lead score across attendees."
                            placement="top"
                          />
                        </Box>
                        <Typography color="primary" variant="h5" component="h3">
                          {graphData.avgAttendeeScore}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                }
              />
              <CardContent>
                {isNoData ? (
                  <NoDataYetSvg />
                ) : (
                  <BarChart
                    layout="vertical"
                    width={1000}
                    height={170}
                    data={graphData.distribution || []}
                    // barCategoryGap={10}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 15,
                    }}>
                    <XAxis
                      type="number"
                      dataKey="total"
                      // domain={getChartRange(graphData.totalAttendees).domain}
                      // ticks={getChartRange(graphData.totalAttendees).ticks}
                    />
                    <YAxis type="category" dataKey="name" />
                    <Tooltip content={<ChartsTooltipComponent />} cursor={{ fill: '#f6f6f6' }} />
                    <Bar
                      dataKey="total"
                      stackId="a"
                      barSize={18}
                      label={<CustomizedLabel x="100" />}>
                      <LabelList dataKey="total" position="right" />
                      {graphData.distribution.map((item, index) => {
                        const { color } = getLeadLevelDetails(item.id);
                        return <Cell key={`cell-${index}`} fill={color} />;
                      })}
                    </Bar>
                  </BarChart>
                )}
                <Box display="flex" justifyContent="center" pb={2}>
                  <Typography variant="body2" component="p" color="textSecondary">
                    {isNoData ? 'No data yet' : 'Number of attendees'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadLevelDistribution;
