/* eslint-disable no-useless-escape */
import moment from 'moment-timezone';
import { EMPTY_STR } from 'constants/common';
import { convertLocalToTimezoneMilli, getMilliSecondsByDateTime } from 'lib/common-function';

export const REGEX = {
  NUMBER: `/^[0-9]+$/g`,
  LINK: `/(((http|https)://)?)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)/gi`,
  EMAIL: `/^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,4}$/`,
};

export const BULK_EMAIL_DTLS = {
  TEMPLATE_URL: 'https://cdn.hubilo.com/sample/TicketAccessSample.xlsx',
  ACCEPTED_FILE_TYPES: ['EXCEL'],
};

export const TICKET_CONST = {
  UNARCHIVE: 'UNARCHIVE',
  ARCHIVE: 'ARCHIVE',
  OPEN: 'OPEN',
  SOLD_OUT: 'SOLD_OUT',
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
  FREE: 'FREE',
  PAID: 'PAID',
  ABSORB: 'ABSORB',
  PASS_ON: 'PASS_ON',
  CUMULATIVE: 'CUMULATIVE',
  INDIVIDUAL: 'INDIVIDUAL',
  ADD: 'ADD',
  EDIT: 'EDIT',
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const TICKET_LABELS = {
  NEXT_STEP: 'Next Step',
  CREATE_TICKET: 'Create ticket',
  EDIT_TICKET: 'Edit ticket',
  CREATE_NEW_TICKET: `Let's create a new ticket`,
  NUMBER: 'Number',
  CHECKBOX: 'Checkbox',
  LINK: 'Link',
  DATE: 'Date',
  RADIO: 'Radio',
  GROUP: 'Group',
  DROPDOWN: 'Dropdown',
  FILE: 'File',
  TEXT: 'Text',
  TEXTAREA: 'Text Area',
  EMAIL: 'Email',
  SAVE: 'Save',
};

export const TICKET_PREV_DATE_FORMAT = 'DD MMM, YY';

export const STATUS_TYPES = {
  SOLD_OUT: 'SOLD OUT',
  CLOSE: 'SOLD OUT',
};

export const OPTIONS = {
  TICKET_TYPE: [
    {
      label: 'Free',
      value: TICKET_CONST.FREE,
    },
    {
      label: 'Paid',
      value: TICKET_CONST.PAID,
    },
  ],
  TICKET_STATUS: [
    {
      label: 'Open',
      value: TICKET_CONST.OPEN,
    },
    {
      label: 'Sold Out',
      value: TICKET_CONST.SOLD_OUT,
    },
  ],
  TICKET_VISIBILITY: [
    {
      label: 'Visible',
      value: TICKET_CONST.VISIBLE,
    },
    {
      label: 'Hidden',
      value: TICKET_CONST.HIDDEN,
    },
  ],
  FEE_SETTINGS: [
    {
      label: 'Absorb',
      value: TICKET_CONST.ABSORB,
    },
    {
      label: 'Pass On',
      value: TICKET_CONST.PASS_ON,
    },
  ],
  DISCOUNT_TYPE: [
    {
      label: 'Apply discount on cumulative purchase',
      value: TICKET_CONST.CUMULATIVE,
      secondaryText:
        'Buyer purchases 5 ticket at $20 each and gets a discount of $5 on overall. He pays $95 entirely.',
    },
    {
      label: 'Apply discount on individual ticket',
      value: TICKET_CONST.INDIVIDUAL,
      secondaryText:
        'Buyer purchases 5 ticket at $20 each and gets a discount of $5 on each ticket. He pays $75 entirely.',
    },
  ],
};

const nowUtcTime = moment.utc().valueOf();
const hasEmailBuilder = (data = {}) => {
  if (data && data.is_email_builder) return true;
  return false;
};
export const TICKET_INIT_VALUES = {
  TICKET_FIELDS: (eventData) => ({
    name: EMPTY_STR,
    description: EMPTY_STR,
    quantity: EMPTY_STR,
    is_free: 0,
    price: EMPTY_STR,
    currency_id: EMPTY_STR,
    is_status_open: 1,
    is_visible: 1,
    ticket_fee_type_id: 1,
    attendee_pays: EMPTY_STR,
    organiser_get: EMPTY_STR,
    group: EMPTY_STR,
    start_date_time: convertLocalToTimezoneMilli(eventData.EventData.timezone.name),
    end_date_time: moment.unix(+eventData.EventData.start_time_milli).isBefore(nowUtcTime)
      ? EMPTY_STR
      : getMilliSecondsByDateTime(eventData.EventData.end_time),
    is_limit_access_on: 0,
    buy_limit_per_user: EMPTY_STR,
    is_invite_email_on: 1,
    emails: [],
    discount_id: [],
    discount_on_cumulative_or_individual: 0,
    email_subject: 'Start interacting with your fellow attendees at the <|event_name|>',
    email_title: 'Start interacting with your fellow attendees at the <|event_name|>',
    email_body:
      'Hello <|first_name|> <br/><br/> Congratulations! You are now officially a part of <|event_name|>.<br/> You may now start setting up meetings and interaction with your fellow attendees who will be attending the event.',
    cta_label: 'Join the community now',
    sendInviteEmailAt: null,
    shouldSendRegistrationEmail: true,
    registrationEmailSubject: 'You have been registered for Event <|event_name|>',
    registrationEmailBody:
      'Hi <|attendee_firstname|>,<br/><br/>You have been registered for the event <|event_name|>. The event is scheduled from <|event_start|> to <|event_end|>. Thanks for Registering and see you soon!',
    registrationHasAddToCalendarButtons: true,
    is_tax_on: 0,
    is_invoice_on: 0,
    country_id: EMPTY_STR,
    ticketType: TICKET_CONST.FREE,
    tax_name: EMPTY_STR,
    tax_rate: EMPTY_STR,
    make_default_tax: 0,
    invoice_name: EMPTY_STR,
    address: EMPTY_STR,
    city: EMPTY_STR,
    state: EMPTY_STR,
    country: EMPTY_STR,
    pincode: EMPTY_STR,
    tax_id: EMPTY_STR,
    invoice_no: EMPTY_STR,
    enable_gst_invoice: 0,
    make_default_invoice: 0,
    minTicketStartDate: convertLocalToTimezoneMilli(eventData.EventData.timezone.name),
  }),
  VOICE_PREVIEW: {
    ticketId: EMPTY_STR,
    ticketName: EMPTY_STR,
    ticketValidFrom: EMPTY_STR,
    ticketValidTill: EMPTY_STR,
    currencyId: EMPTY_STR,
    buyerDetail: {
      name: EMPTY_STR,
      address: EMPTY_STR,
    },
    referenceNumber: EMPTY_STR,
    invoiceDate: EMPTY_STR,
    ticketType: [],
    organisationDetails: EMPTY_STR,
    gstRegNumber: EMPTY_STR,
    igst: {
      percentage: EMPTY_STR,
      amount: EMPTY_STR,
    },
    subTotal: EMPTY_STR,
    grandTotal: EMPTY_STR,
  },
};

export const TICKETING_DESC = {
  ticketSettings: {
    buyerForm: {
      title: 'Buyer Form',
      desc: 'This form will be shown to the buyer to collect their information during the ticket purchase.',
    },
    taxSettings: {
      title: 'Tax Setting',
      desc: 'Know more in taxing and invoice.',
    },
    transactionalEmail: {
      title: 'Transactional Email',
      desc: 'Email a buyer receives upon transaction.',
    },
    incTransactionalEmail: {
      title: 'Incomplete transactional Email',
      desc: 'Email received by Buyer in case of incomplete transaction.',
    },
    landingPageSettings: {
      title: 'Landing Page Settings',
      desc: `Set the date and text for the "Go to Event" CTA on the landing page to
              redirect the attendee to the community.`,
    },
    gdprCompliance: {
      title: 'GDPR Compliance',
      desc: 'Take the consent of the people when they register for the event.',
    },
    simplerRegistration: {
      title: 'Enable Simple Registration',
      desc: 'This allows the attendee to register for the event with free tickets. Attendee details will be added under the people section after registration.'
    },
    buyerCanChooseOnlyOneTicketType: {
      title: 'Buyer can choose only 1 ticket type',
    },
  },
};

export const TICKET_SETTINGS_CONST = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  DROPDOWN: 'DROPDOWN',
  NUMBER: 'NUMBER',
  GROUP: 'GROUP',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  FILE: 'FILE',
  DATE: 'DATE',
  LINK: 'LINK',
  TEXT_EMAIL: 'TEXT_EMAIL',
};

export const TICKET_SETTINGS_OPTIONS = {
  BUYER_TRANSACTION: [
    {
      label: 'Single',
      value: TICKET_CONST.SINGLE,
    },
    {
      label: 'Multiple',
      value: TICKET_CONST.MULTIPLE,
    },
  ],
  ATTENDEE_TRANSACTION: [
    {
      label: 'Single',
      value: TICKET_CONST.SINGLE,
    },
    {
      label: 'Multiple',
      value: TICKET_CONST.MULTIPLE,
    },
  ],
};

// Ticket Setting - Buyer form fields - Number type
export const TS_DEF_NUMBER_FIELD_TYPE = {
  name: TICKET_LABELS.NUMBER,
  type: TICKET_SETTINGS_CONST.NUMBER,
  defaultValues: {
    isMandatory: false,
    minLength: 1,
    maxLength: 50,
    regex: REGEX.NUMBER,
  },
};

// Ticket Settings - Currency Init Details
export const TS_CURRENCY_INIT_DETAILS = {
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: EMPTY_STR,
  tempSearch: EMPTY_STR,
  defaultCurrencyId: EMPTY_STR,
  attendeePays: EMPTY_STR,
  organiserGet: EMPTY_STR,
  options: [],
  apiDone: true,
  absorb_stripe: EMPTY_STR,
  conversionRate: EMPTY_STR,
  pass_stripe: EMPTY_STR,
  stripe_per_ticket: EMPTY_STR,
};

export const TS_OTHER_PAYMENT_METHODS = {
  FT: 'FT',
  DW: 'DW',
  CHQ: 'CHQ',
  PAV: 'PAV',
  STP: 'STP',
  RZP: 'RZP',
  PP: 'PP',
};

// Ticket Settings - Payment Method Options
export const TS_PAYMENT_METHOD_DTLS = {
  FT: {
    title: 'Funds Transfer',
    placeHolder: `Account Holder's Name - 
Account No -
Bank Code (IFSC, BIC, ABA, RTN etc) - `,
    tooltipText:
      'You can provide all the details required for the buyer to make the payment through funds transfer. For example, mention the account holder’s name, account number, and bank code like IFSC, BIC, ABA RTN, etc.',
  },

  DW: {
    title: 'Digital Wallet',
    placeHolder: `Digital Wallet Name - 
Wallet ID / Username - `,
    tooltipText:
      'You can provide all the details required for the buyer to make the payment using a digital wallet. Mention the digital wallet name and the username or wallet ID, for example, Venmo username, PayPal ID, UPI ID, etc.',
  },

  CHQ: {
    title: 'Cheque',
    placeHolder: `Payee Name - 
Account Number - `,
    tooltipText:
      'You can provide all the details required for the buyer to make the payment through a cheque. For example, mention the payee name, account number, etc.',
  },

  PAV: {
    title: 'Pay at Venue',
    placeHolder: '',
    tooltipText: '',
  },
};

export const ORDERS_PENDING_ORDERS_REASONS = [
  { name: 'Payment not received', value: 'NOT_RCVD' },
  { name: 'Payment details do not match', value: 'DTL_MSMCH' },
  { name: 'Tickets are running out / Tickets have run out', value: 'TKTS_NA' },
];

export const COPY_EVENT_INIT_VALUES = {
  EVENT_FIELDS: (eventData) => ({
    name: eventData.name || EMPTY_STR,
    description: eventData.description || EMPTY_STR,
    timezone: EMPTY_STR,
    timezoneId: EMPTY_STR,
    start_date_time: eventData.start_time_milli || EMPTY_STR,
    event_languages: eventData.event_languages || [],
    base_language: eventData.base_language || EMPTY_STR,
    start_time_milli: eventData.start_time_milli || EMPTY_STR,
  }),
};

export const COPY_EVENT_LABELS = {
  NEXT: 'Next',
  COPY: 'Copy',
  FINISH: 'Finish',
};

export const copyModulesForWebinar = ['sessions', 'speakers', 'branding', 'settings', 'campaigns', 'custom_landing_pages'];
