import React from 'react';
import SvgZoom from 'icons/svgZoom';
import SvgVimeo from 'icons/svgVimeo';
import SvgVideo2 from 'icons/svgVideo2';
import SvgYouTube from 'icons/svgYoutube';
import SvgHubiloIcon from 'icons/svgSmallNewHubiloIcon';
import SvgExternalEncoIcon from 'icons/svgExternalEncoIcon';
import SvgMonitorIcon from 'icons/svgMonitorIcon';
import SvgFacebookIcon from 'icons/svgFacebookIcon';
import SvgTwitchIcon from 'icons/svgTwitchIcon';
import SvgCrowdcastIcon from 'icons/svgCrowdcastIcon';
import SvgIBMWatsonIcon from 'icons/svgIBMWatsonIcon';

export const options = {
  YOUTUBE: 1,
  VIMEO: 2,
  OTHER: 3,
  CUSTOM: 4,
  ZOOM: 5,
  PRERECORDED: 6,
  IBM: 7,
  FB: 8,
  NO_STREAM: 9,
};

export const streamTypeByNum = {
  1: 'YOUTUBE',
  2: 'VIMEO',
  3: 'OTHER',
  4: 'CUSTOM',
  5: 'ZOOM',
  6: 'PRERECORDED',
  7: 'IBM',
  8: 'FB',
};

export const PLAYER_TYPE_ID = {
  OTHER: 1,
  IBM: 4,
  FB: 5,
};

export const allowedType = ['.mp4'];

export const eventHostInit = {
  loading: false,
  page: 0,
  totalPage: 0,
  options: [],
  search: '',
};

export const studioHostInit = {
  loading: false,
  page: 0,
  totalPage: 0,
  options: [],
  search: '',
};

export const customIntStreamDataInit = {
  rtmp_url: '',
  stream_key: '',
  is_mux: 0,
  start_link: '',
  join_link: '',
  m3u8_link: '',
  mp4_link: '',
  recording_duration: '',
  session_start_time_milli: '',
  studio_link: '',
};

export const subSourceOptions = [
  { value: '1', label: 'Zoom Meeting', icon: <SvgZoom width="22px" height="22px" /> },
  {
    value: '2',
    label: 'External Encoder',
    icon: <SvgExternalEncoIcon width="18px" height="18px" />,
  },
];

// subSourceWebinarOptions - webinar event sub source options
export const subSourceWebinarOptions = [
  {
    value: '2',
    label: 'External Encoder',
    icon: <SvgExternalEncoIcon width="18px" height="18px" />,
    isWebinarSession: true,
  },
  // {
  //   value: '3',
  //   label: 'Hubilo BroadCast Studio 2.0',
  //   icon: (
  //     <SvgHubiloIcon width="17px" height="17px" fillColor="#fff" bgColor="#202B36" />
  //   ),
  //   expireText: 'Discontinued after 30 September, 2023'
  // }
];

export const sourceOptionsWithHbs = [
  {
    value: 'CUSTOM',
    label: 'Hubilo Streaming',
    icon: <SvgHubiloIcon width="17px" height="17px" fillColor="#fff" bgColor="#202B36" />,
    isWebinarSession: true,
  },
  { value: 'YOUTUBE', label: 'YouTube', icon: <SvgYouTube width="18px" height="18px" /> },
  { value: 'VIMEO', label: 'Vimeo', icon: <SvgVimeo width="18px" height="18px" /> },
  { value: 'ZOOM', label: 'Zoom Webinar', icon: <SvgZoom width="22px" height="22px" /> },
  { value: 'FB', label: 'Facebook', icon: <SvgFacebookIcon width="22px" height="22px" /> },
  { value: 'IBM', label: 'IBM Watson', icon: <SvgIBMWatsonIcon width="20px" height="20px" />, isWebinarSession: true },
  { value: 'OTHER', label: 'Other', icon: <SvgVideo2 width="18px" height="18px" /> },
];

export const SOURCE_OPTIONS_SIDEPANEL = [
  {
    value: 1,
    label: 'Custom',
    icon: <SvgMonitorIcon width="24px" height="24px" />,
  },
  {
    value: 2,
    label: 'Facebook Live',
    icon: <SvgFacebookIcon width="24px" height="24px" />,
  },
  { value: 3, label: 'Youtube Live', icon: <SvgYouTube width="24px" height="16px" /> },
  { value: 5, label: 'Twitch', icon: <SvgTwitchIcon width="22px" height="22px" /> },
  { value: 6, label: 'Crowdcast', icon: <SvgCrowdcastIcon width="24px" height="24px" /> },
  { value: 7, label: 'Vimeo', icon: <SvgVimeo width="22px" height="21px" /> },
];

export const SIMULCAST_MAP = {
  1: {
    value: 1,
    label: 'Custom',
    icon: <SvgMonitorIcon width="24px" height="24px" />,
  },
  2: {
    value: 2,
    label: 'Facebook Live',
    icon: <SvgFacebookIcon width="24px" height="24px" />,
  },
  3: { value: 3, label: 'Youtube Live', icon: <SvgYouTube width="24px" height="16px" /> },
  5: { value: 5, label: 'Twitch', icon: <SvgTwitchIcon width="22px" height="22px" /> },

  6: {
    value: 6,
    label: 'Crowdcast',
    icon: <SvgCrowdcastIcon width="24px" height="24px" />,
  },
  7: { value: 7, label: 'Vimeo', icon: <SvgVimeo width="22px" height="21px" /> },
};

export const SIMULCAST_DELETE_OBJECT = {
  isOpen: false,
  id: -1,
  message: '',
  card: undefined,
};

export const SIMULCAST_NEW_OBJECT = (id) => {
  return {
    isDeleted: false,
    isEdit: false,
    muxId: undefined,
    passthrough: '',
    stremKey: undefined,
    url: undefined,
    id,
    type: SOURCE_OPTIONS_SIDEPANEL[0],
    label: undefined,
    parentLiveStream: undefined,
  };
};

export const SIMULCAST_DEFAULT_SIDE_FORM = {
  isOpen: false,
  isEdit: false,
  card: undefined,
};

export const SIDEPANEL_ERROR_DEFAULT = {
  url: '',
  streamKey: '',
  label: '',
  duplicateCheck: '',
};

export const MAX_SIMULCAST_TARGETS = 6;

export const SIMULCASTING_LIVE_STREAM_ERROR_MESSAGE =
  'live streams can only be changed when inactive';

export const PLAYER_TYPE_OPTIONS = [
  { label: 'Player Link', value: '0' },
  { label: 'Player Code', value: '1' },
];

export const externalPlayerDefaultObject = {
  playerValue: PLAYER_TYPE_OPTIONS[0].value,
  playerCode: '',
  playerLink: '',
  recordingLink: '',
};

export const hbsv3FunList = [
  'Realtime Live Streaming',
  'Invite attendees to join the stream',
  'Manage Raise Hand Requests',
  'Backstage controls',
  'Add/Manage co-host',
  'Manage video uploads',
  'Control Embedded Apps ',
  'Enable Group Portraits',
  'Host / Co-host branding controls',
  'Engagement Panel controls',
  'Monitor streams in real-time',
  'Create custom themes with accessibility check'
]