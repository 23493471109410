import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  copyEvent: {
    '& .MuiBreadcrumbs-root': {
      '& a': {
        fontSize: 14,
        color: theme.palette.primary.main,
      },
      '& p': {
        fontSize: 14,
        color: theme.palette.secondary.light,
      },
    },
  },
  ticketStepper: {
    background: 'none',
    width: '43.5%',
    padding: '0 0 2rem 0',
    '& .MuiStep-root': {
      margin: 0,
      padding: 0,
      '& .MuiStepLabel-iconContainer': {
        padding: 0,
      },
    },
    '& .MuiStep-root.active': {
      // borderRadius: '2rem',
      // background: theme.palette.primary.lighter,
      padding: '5px 10px 5px 5px',
      // border: `1px solid ${theme.palette.primary.main}`,
      '& .MuiStepLabel-iconContainer': {
        paddingRight: '.5rem',
      },
    },
    '& .MuiStep-root.MuiStep-completed': {
      '& .MuiStepLabel-label': {
        display: 'none',
      },
    },
    '& .MuiStepIcon-root': {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      '&.MuiStepIcon-active': {
        color: '#fff',
        '& .MuiStepIcon-text': {
          fill: theme.palette.primary.main,
        },
      },
    },
    '& .MuiStepLabel-label': {
      color: theme.palette.primary.main,
    },
    '& .Mui-disabled': {
      '& .MuiStepIcon-root': {
        color: theme.palette.text.grayColor1,
      },
      '& .MuiStepLabel-label': {
        display: 'none',
      },
    },
  },
  ticketPrevDesc: {
    whiteSpace: 'pre-wrap',
  },
  oneButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  twoButton: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  finishBtn: {
    display: 'flex',
    justifyContent: 'center',
  },
  primaryButton: {
    width: `auto`,

    height: 48,
    padding: '1rem 1.5rem',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
  },
  secondaryButton: {
    width: `auto`,
    height: 48,
    padding: '1rem 1.5rem',
    marginRight: '1rem',
    backgroundColor: theme.palette.bg.white,
    color: theme.palette.primary.main,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.Mui-disabled': {
      display: 'none',
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },

  copyEventOptionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20,
    '& .copyEventOptionsLeft': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '& .copyEventOptions': {
        border: `1px solid ${theme.palette.border.light}`,
        borderRadius: 5,
        width: '48%',
        padding: 10,
        marginBottom: 20,
        '&.disabled': {
          filter: 'grayscale(1)',
          background: theme.palette.bg.whiteOff1,
        },
      },
    },
    '& .copyEventOptionsRight': {
      display: 'block',
      '& .copyEventInfoDesc': {
        '& p': {
          fontWeight: 400,
        },
      },
    },
  },

  navCountLimitBox: {
    background: theme.palette.primary.lighter,
    borderRadius: '4px',
    padding: `${theme.spacing(1)}px  ${theme.spacing(1.5)}px `,
    width: '100%',
  },
  copyEventPopup: {
    display: 'block',
  },
  warnBlock: {
    padding: theme.spacing(1),
    '&>p': {
      marginLeft: theme.spacing(1),
    },
    background: theme.palette.primary.primaryLight,
    borderRadius: theme.shape.borderRadius,
  },
  copyEventMsg: {
    maxWidth: '530px !important',
    width: 'auto !important',
  },
  exhasSvg: {
    width: '48px',
    height: '48px',
    color: '#FFBF3F',
  },
}));
