/* eslint-disable no-param-reassign */
import {
  TEMPLATE_PREVIEW_MODAL_TOGGLE,
  SET_PREVIEW_TYPE,
  SET_TEMPLATE_HTML,
  SET_TEMPLATE_CAMPAIGN_ID,
} from 'redux/actions/template-preview-action';

const initialState = {
  isModalOpen: false,
  previewType: 'Desktop',
  templateHtml: '',
  campaignId: '',
  listPreview: false,
};
const templatePreviewInfo = (state = { ...initialState }, action) => {
  switch (action.type) {
    case TEMPLATE_PREVIEW_MODAL_TOGGLE:
      state = { ...state, ...action.data };
      break;
    case SET_PREVIEW_TYPE:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_HTML:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_CAMPAIGN_ID:
      state = { ...state, ...action.data };
      break;
    default:
      break;
  }
  return state;
};

export default templatePreviewInfo;
