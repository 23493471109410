import ProjectSetting from 'settings';

export const BASE_URL = ProjectSetting.customBaseURL2;

export const SEARCH_API_LIMIT = 10;

export const filterOptionsObj = {
    tracks: [],
    speakers: [],
    customTags: '',
};

export const SEARCH_TYPE = {
    TRIM: 'trim',
    DETAIL: 'detail',
}

export const SCROLL_TYPE = {
    SCROLL_UP: 'SCROLL_UP',
    SCROLL_DOWN: 'SCROLL_DOWN',
}

export const SESSION_ACTIONS = {
    SESSION_DAY: 'SESSION_DAY',
    LIST_PAGE_SESSION_DAY: 'LIST_PAGE_SESSION_DAY',
    GO_TO_DAY: 'GO_TO_DAY',
    APPLY_FILTERS: 'APPLY_FILTERS',
    RESET_FILTERS: 'RESET_FILTERS',
    SIDEBAR_DEFAULT_DATA: 'SIDEBAR_DEFAULT_DATA',
    DATE_LIST: 'DATE_LIST',
    LOADING: 'LOADING',
    AGENDA_DATA: 'AGENDA_DATA',
    TEMP_AGENDA_DATA: 'TEMP_AGENDA_DATA',
    SHOW_TRIM: 'SHOW_TRIM',
    STREAM_LINK_SIDEBAR: 'STREAM_LINK_SIDEBAR',
    SHOW_EMPTY_SLOT: 'SHOW_EMPTY_SLOT',
    DEFAULT_VIEW_DETAILS: 'DEFAULT_VIEW_DETAILS',
    HIDE_OVERFLOW: 'HIDE_OVERFLOW',
    HEAVY_SESSION: 'HEAVY_SESSION',
    ADMIN_HEAVY_SESSION: 'ADMIN_HEAVY_SESSION',
    CLIENT_HEAVY_SESSION: 'CLIENT_HEAVY_SESSION',
    SET_AMPLITUDE_DATA: 'SET_AMPLITUDE_DATA',
}

export const amplitudeActions = {
    SessionNavBarOptionClicked: 'SessionNavBarOptionClicked',
    AddDayClicked: 'AddDayClicked',
    DeleteDayClicked: 'DeleteDayClicked',
    EditDayClicked: 'EditDayClicked',
    ViewAllSessionsClicked: 'ViewAllSessionsClicked',
    SessionsSearchIconClicked: 'SessionsSearchIconClicked',
    SessionsSearchResultClicked: 'SessionsSearchResultClicked',
    DayDeleted: 'DayDeleted',
}

export const HEAVY_SESSION_COMMUNITY = true;