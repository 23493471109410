/* eslint-disable */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Grid,
  makeStyles,
  Box,
  Avatar,
  Button,
  Tooltip,
  Fade,
  Paper,
  IconButton,
  ListItem,
  List,
  ClickAwayListener,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Snackbar,
  Badge,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SpeakerDeviceLargeIcon from 'icons/SpeakerDeviceLargeIcon';
import {
  userInfoSelector,
  eventDataSelector,
  eventLanguagesSelector,
  currentEventLanguagesSelector,
  baseEventLanguagesSelector,
  alertSelector,
} from 'redux/custom-selector';
import { removeUserInfo, setUserInfo } from 'redux/actions/user-action';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { removeEventData as removeEventDataRedux } from 'redux/actions/event-action';
import { API, GETAPI, POSTAPIWITHCUSTOMDOMAIN, POSTAPI } from 'api-setup/api-helper';
import {
  LOGOUT_API,
  EVENT_LIVE_STATUS_UPDATE,
  MANAGE_SUBSCRIPTION,
  UPGRADE_MODAL_ACTIONS,
  GET_NOTIFICATION_LIST,
  EVENT_OVERVIEW_API,
} from 'api-setup/api-endpoints';
import { getUserImage, getEventLogo } from 'lib/common-function';
import SkeletonLoader from 'custom-components/skeletonLoader';
import { setEventData } from 'redux/actions/event-action';
import { removeUserCredential } from 'lib/cookies';
import NotificationBar from '../custom-components/notificationToaster';
import SidebarNav from './sidebarNav';
import mixPanel from 'lib/mix-panel';
import amplitude from 'lib/amplitude';
import {
  User,
  Users,
  HelpCircle,
  X,
  Compass,
  FileText,
  Check,
  ExternalLink,
  AlertTriangle,
} from 'react-feather';
import { setNewMessage } from 'redux/actions/setting-actions';
import ProjectSetting from 'settings';
import { useSource } from 'lib/custom-common-hooks';
import PopUp, { IFramePopup } from 'custom-components/popUp';
import SvgGoLiveEventIcon from 'icons/svgGoLiveEventIcon';
import SvgPay from 'icons/svgPay';
import ErrorIcon from '@material-ui/icons/Error';
import PlanDetailsMenu from './billing-and-pricing/planDetailsMenu';

import ProPlanIconWhite from 'icons/svgProPlanIconFilledWhite';
import BasicPlanIconWhite from 'icons/svgBasicPlanIconFilledWhite';
import EnterPriseIconWhite from 'icons/svgEnterprisePlanIconFilledWhite';
import AdvanceIconWhite from 'icons/svgAdvancePlanIconFilledWhite';

import SvgProPlanIconFill from 'icons/svgProPlanIconFilled';
import SvgBasicPlanIconFill from 'icons/svgBasicPlanIconFilled';
import SvgEnterprisePlanIconFill from 'icons/svgEnterprisePlanIconFilled';
import SvgAdvancePlanIconFill from 'icons/svgAdvancePlanIconFilled';
import SvgHubiloNewIcon from 'icons/svgHubiloNewIcon';

import { setCurrentEventLanguage } from 'redux/actions/current-event-language-action';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MultiLangOption from 'utility/MultiLangOptions';
import GlobIcon from 'icons/svgGlobal';
import { PricingPlan } from 'constants/pricingPlan';
import CreateCopyEvent from './events/copyEvent/createCopyEvent';
import CustomModal from 'custom-components/customModal';
import DownTimeModal from './DownTime/DownTimeModal';
import Survey from 'pages/survey';
import { DOWN_TIME_CONSTANTS, DOWN_TIME_MODAL } from 'constants/common';
import { downTimeModalAction } from './DownTime/downTimeReducer';
import { surveyConfigSelector } from 'redux/selectors/survey-selector';
import { setSurvey } from 'redux/actions/survey-actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import projectSetting from 'settings';
import { setAlertData } from 'redux/actions/alert-action';
import { commonAmplitudeTrack } from 'lib/amplitude/amplitude-tracking';
import useAmplitudeData from 'utility/customHooks/useAmplitudeData';
import { useAmplitudeInstance } from 'lib/amplitude/use-amplitude-instance';
import usePosthogClient from '../lib/posthog/usePosthogClient';
import { planExpireTypeDisplay } from '../lib/common-function';

const CUSTOM_URL = ProjectSetting.customBaseURL;

const useStyles = makeStyles((theme) => ({
  dashHeader: {
    top: 0,
    left: 64,
    right: 0,
    backgroundColor: 'white',
    height: 60,
    borderBottom: `1px solid ` + theme.palette.border.light,
    zIndex: 15,
    '&.sidebar-not-available': {
      left: 0,
    },
  },
  tagEventNameForPopup: {
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '0.3rem 0.2rem',
    marginRight: '0.3rem',
    borderRadius: theme.shape.borderRadius,

    '& h6': {
      fontSize: '14px',
      lineHeight: 1,
    },
  },
  tagEventNameForHeader: {
    color: '#fff',
  },
  borderForProfile: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  previewBtn: {
    marginLeft: '5px',
    fontSize: '13px',
  },
  leftAside: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  w100: {
    width: '100%',
  },
  feedBackForm: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '5px',
  },
  logoBlock: {
    color: theme.palette.text.third,
    '& svg': {
      width: 100,
      height: 50,
      marginRight: theme.spacing(1),
    },
    '& :hover': {
      '& .logo-icon': {
        color: theme.palette.primary.main,
      },
      '& .logo-text': {
        color: theme.palette.primary.main,
      },
    },
    '& .logo-text': {
      fontSize: 19,
      lineHeight: 'normal',
      overflow: 'hidden',
      maxWidth: '300px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  rightMenuAction: {
    minWidth: 60,
    color: theme.palette.text.third,
    position: 'relative',
    borderLeft: '1px solid ' + theme.palette.border.light,
    '& .badgeForNewItem': {
      '& .MuiBadge-badge ': {
        height: 17,
        width: 17,
        minWidth: 17,
        border: '1px solid #fff',
      },
    },
    '& .dash-header-right-action': {
      "&.lang-btn": {
        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
      },
      '& .langGlobeIcon': {
        '& svg': {
          width: `16px`,
          height: `16px`,
        },
      },
      '& .warnIcon': {
        '& svg': {
          width: `25px`,
          height: `25px`,
          color: '#ED7767',
        },
      },

      padding: `0`,
      fontSize: 14,
      // minWidth: 60,
      borderRadius: 0,
      textTransform: 'capitalize',
      '& .ml10': {
        paddingLeft: '10px',
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        whiteSpace: `nowrap`,
      },
    },
    '& .expired-btn': {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      backgroundColor: theme.palette.alert.alertWarnBg,
      color: theme.palette.primary.light,
      fontSize: theme.fontSize.size9,
      fontWeight: 500,
    },
  },
  closeBtn: {
    padding: theme.spacing(0.3),
    width: 30,
  },
  avatarBadge: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid #fff`,
    borderRadius: '50%',
    height: 20,
    width: 20,
    right: 13,
    bottom: -6,
    '& h6': {
      fontSize: '8px',
    },
  },
  userAvatar: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(1.5),
    borderRadius: 8,
    //	border: '1px solid ' + theme.palette.border.lighter,
    '& .MuiAvatar-img': {
      height: 'auto',
    },
  },

  userAvatar2: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(1.5),
    borderRadius: 30,
    //	border: '1px solid ' + theme.palette.border.lighter,
    '& .MuiAvatar-img': {
      height: 'auto',
    },
  },
  rightAsideOtherAction: {
    '& .event-btn': {
      paddingLeft: theme.spacing(1.2),
      paddingRight: theme.spacing(1.2),
      marginLeft: 8,
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.primary.light,
      fontSize: theme.fontSize.size9,
      fontWeight: 500,
      '&:last-child': {
        marginLeft: 8,
      },
    },
    '& .plan-btn': {
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(1),
      marginLeft: 10,
      backgroundColor: theme.palette.alert.alertWarnBg,
      color: theme.palette.primary.light,
      paddingLeft: 10,
      paddingRight: 10,
      '&:last-child': {
        marginRight: 10,
      },
      '& svg': {
        fontSize: '20px',
      },
    },
    '& .header-addition-action': {
      // marginRight: 20,
      // '& button': {
      // 	'&:hover': {
      // 		backgroundColor: theme.palette.bg.whiteOff1,
      // 		color: theme.palette.primary.light,
      // 	}
      // }
      '& .goLiveBtn': {
        marginLeft: theme.spacing(1),
      },
      '& .event-btn': {
        color: theme.palette.text.grayColor,
        '&:hover': {
          backgroundColor: theme.palette.bg.whiteOff1,
          color: theme.palette.primary.light,
        },
      },
      '& .btn-event': {
        backgroundColor: theme.palette.bg.whiteOff1,
        color: theme.palette.primary.light,
      },
    },
  },
  dashContentWrapper: {
    position: 'relative',
    marginTop: 60,
    height: `calc(100vh - 60px)`,
    backgroundColor: theme.palette.bg.white,
    [theme.breakpoints.down('sm')]: {
      padding: '25px 0',
    },
    '&.planExpirePadTop': {
      paddingTop: 70,
    },
    '& .expiredStrape': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1,

      '& .snackbarBlock': {
        position: 'static',
        transform: 'none',
        background: theme.palette.bg.white,
        '& + .MuiSnackbar-root': {
          marginTop: 10,
        },
      },
      '& .community': {
        width: `calc(100% - 30px)`,
      },
      '& .alert': {
        width: '100%',
        padding: `${theme.spacing(1.5)}px  ${theme.spacing(2)}px`,
        '&.danger': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText,
        },
        '&.danger2': {
          background: '#ED7767',
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  dashboardMainWrap: {
    paddingLeft: 64,
  },
  userProfileBlock: {
    '& .MuiListItem-root': {
      paddingTop: 6,
      paddingBottom: 6,
    },

    '& .MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: `${theme.palette.bg.whiteOff1}!important`,
      },
      '&:hover *': {
        color: theme.palette.primary.light,
      },
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.bg.whiteOff1,
      '& .MuiListItemIcon-root': {
        '&>svg': {
          color: theme.palette.primary.light,
        },
      },
      '& .MuiListItemText-root': {
        '&>span': {
          color: `${theme.palette.primary.light} !important`,
        },
      },
    },
  },
  boldText: {
    fontWeight: 700,
  },
  actionIcon: {
    minWidth: 35,
    '& svg': {
      color: theme.palette.text.grayColor,
    },
  },
  btnForPopup: {
    width: '100%',
  },

  userName: {
    fontSize: theme.fontSize.size9,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    overflow: 'hidden',
  },
  email: {
    fontSize: theme.fontSize.size9,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    overflow: 'hidden',
  },
  progressBarLine: {
    minHeight: 8,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.bg.whiteOff2,
  },
  primaryColor: {
    color: theme.palette.primary.light,
  },
  successIconColor: {
    color: theme.palette.alert.success,
    strokeWidth: 3,
  },
  notificationCount: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    fontWeight: 500,
    borderRadius: '50%',
    boxShadow: theme.shadows[3],
  },
  badgeStyle: {
    '& .MuiBadge-badge': {
      height: 16,
      minWidth: 15,
      width: 15,
      backgroundColor: theme.palette.alert.warn2,
      border: `2px solid ${theme.palette.primary.contrastText}`,
      color: '#fff',
      fontSize: 10,
      right: '7%',
    },
  },
  notificationBlock: {
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: theme.shape.borderRadius,
    '& ul': {
      maxHeight: 412,
      overflowY: 'auto',
      overflowX: 'hidden',
      // '&::-webkit-scrollbar': {
      // 	display: 'none'
      // },
      '& li': {
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0px 3px 6px #0000001A',
        backgroundColor: theme.palette.primary.contrastText,
        '&:nth-child(n+1)': {
          marginTop: theme.spacing(2),
        },
        '&:nth-child(1)': {
          marginTop: theme.spacing(0),
        },

        '& .MuiAvatar-circle': {
          height: 30,
          backgroundColor: theme.palette.primary.contrastText,
          boxShadow: '0px 2px 4px #00000029',
          width: 30,
        },
      },
    },
  },
  planDropdown: {
    '& .dropDownBody': {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 6rem)',
    },
    '& .choosePlan': {
      '& .square': {
        width: 44,
        height: 44,
        backgroundColor: theme.palette.alert.alertWarnBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
      },
      '& .circle': {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.alert.alertWarnBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 44,
      },
      '& .currentPlan': {
        borderBottom: `1px solid ${theme.palette.bg.whiteOff1}`,
        '& .MuiTypography-h6': {
          color: theme.palette.primary.main,
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 500,
          marginBottom: 0,
        },
      },
      '& .expiredPlan': {
        textAlign: 'center',
        '& .MuiTypography-h6': {
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 500,
          margin: 'auto',
          width: '90%',
        },
      },

      '& .leftDataPlan': {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        '& .MuiTypography-h6': {
          color: theme.palette.secondary.main,
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 500,
          //	marginBottom: 10,
        },
      },
      '& .upgradeActionPlan': {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.bg.whiteOff1}`,
      },
      '& .expired-btn': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.alert.alertWarnBg,
        color: theme.palette.primary.light,
        fontSize: theme.fontSize.size9,
        fontWeight: 500,
      },
    },
  },
  whatsNewBlock: {
    backgroundColor: theme.palette.bg.white,
    borderRadius: theme.shape.borderRadius,
    '& ul': {
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      '& li': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.secondary.main,
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px `,
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.bg.whiteOff1,
        },
        '& .beamer_icon.active': {
          right: '85px',
          top: '15px',
        },
      },
    },
  },
  multiLangSupportPopup: {
    // width: 200,
    // border: '1px solid #DBDBDB',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: ' 10px',
    '&>.MuiPaper-elevation2': {
      border: `1px solid ${theme.palette.bg.whiteOff2}`,
      borderRadius: ' 4px',
    },
  },
  multiLangList: {
    maxHeight: 300,
    overflow: 'auto',
    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },
    '& .MuiListItem-root': {
      // paddingTop: theme.spacing(0.7),
      // paddingBottom: theme.spacing(0.7),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.bg.whiteOff1,
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'flex',
    },

    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
    },
    '& .checkSvgIcon': {
      display: 'inline-block',
      marginLeft: '8px',
      //  marginTop:'3px',
    },
  },
  langTitle: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    position: 'relative',
    fontSize: 12,
    /* '&::after': {
      content: `" "`,
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
      bottom: '93%',
      width: 0,
      height: 0,
      borderLeft: '.5rem solid transparent',
      borderRight: '.5rem solid transparent',
      borderBottom: `.7rem solid #fff`,
      clear: 'both',
    },
    '&::before': {
      content: `" "`,
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
      bottom: '98%',
      width: 0,
      height: 0,
      borderLeft: '.5rem solid transparent',
      borderRight: '.5rem solid transparent',
      borderBottom: `.7rem solid #dbdbdb`,
      clear: 'both',
    }, */

    '& .baseText': {
      fontSize: '10px',
      textTransform: 'uppercase',
      marginLeft: '4px',
      backgroundColor: '#FFF0EC',
      color: '#ED7767',
      padding: '0px 5px',
      fontWeight: 'normal',
      display: 'inline-block',
      borderRadius: '2px',
    },
  },
  langSuppoert: {
    margin: '0 16px',
    paddingLeft: 0,
  },
  svgDownTimeIcon: {
    width: 100,
    height: 100,
    color: theme.palette.text.grayColor1,
  },
  surveyStripeWrapper: {
    paddingTop: '2rem',
  },
  h100: {
    height: '100%',
  },
}));

const getSurveyConfig = (payload = {}) => {
  const {
    isShowSurvey = false,
    isEligibleSurvey = false,
    isCompleteSurvey = false,
    isExpireSurvey = false,
  } = payload || {};

  return Boolean(isShowSurvey && isEligibleSurvey && !isCompleteSurvey && !isExpireSurvey);
};

const Layout = ({ children, hasSidebarNav = true, isLoading }) => {
  const {
    dashContentWrapper,
    dashboardMainWrap,
    surveyStripeWrapper,
    boldText,
    closeBtn,
    w100,
    feedBackForm,
    h100,
  } = useStyles();
  let history = useHistory();
  const { eventId, organiserId } = useParams();
  const pagePathname = history.location.pathname;
  const source = useSource();
  const match = useRouteMatch();
  const reduxData = useSelector(userInfoSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const surveyConfig = useSelector(surveyConfigSelector, shallowEqual);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const [isMenuOpen, setMenuOption] = useState(false);
  const [isWhatsNew, setWhatsNew] = useState(false);
  const [isPlanMenuOpen, setPlanMenuOption] = useState(false);
  const [chargeBeeData, setChargeBeeData] = React.useState({ access_url: '', isLoading: false });
  const dispatch = useDispatch();
  const [openSubscriptionPopup, toggleSubscriptionPopup] = React.useState(false);
  const [trackAmplitudeEvent] = useAmplitudeInstance();

  const APICALLForCUSTOMDOMAIN = React.useCallback(
    (ACTION, reduxData, methodType, URL, headerData, bodyData, APICB) => {
      const [header, body] = API.generateHeader(reduxData, headerData, bodyData);
      let APIPromise;
      if (methodType === 'POST') {
        APIPromise = POSTAPIWITHCUSTOMDOMAIN(
          false,
          URL,
          source.AS,
          header,
          body,
          ProjectSetting.customBaseURL
        );
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(setNewMessage({ message: resp.data.message, type: 'success', show: true }));
          APICB && APICB(ACTION, 'SUCCESS', resp.data);
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            APICB && APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  // as discussed with @avadhi. we do not use free plan  condition
  // useEffect(() => {
  //   //console.log("useeffect", currentDate_milli)
  //   //console.log("useeffect1", userInfo.UserInfo.subscription_end_date_milli);
  //   if (
  //     userInfo.UserInfo.planName === PricingPlan.FREE &&
  //     userInfo.UserInfo.subscriptionStatus === 'CANCELLED'
  //   ) {
  //     setIsPlanExpired(true);
  //     userInfo.UserInfo.isPlanExpired = isPlanExpired;
  //     dispatch(setUserInfo);
  //   }
  //   // else if(userInfo.UserInfo.subscription_meta_id != 1 && userInfo.UserInfo.subscriptionStatus{

  //   // }
  //   else {
  //     setIsPlanExpired(false);
  //     userInfo.UserInfo.isPlanExpired = isPlanExpired;
  //     dispatch(setUserInfo);
  //   }
  // }, [userInfo]);

  const handleScroll = () => {
    if (isMenuOpen) {
      setMenuOption(false);
    }
    if (isWhatsNew) {
      setWhatsNew(false);
    }
    if (isPlanMenuOpen) {
      setPlanMenuOption(false);
    }
  };
  const FETCH_CHARGEBEE_DATA = React.useCallback(() => {
    const data = {
      organiserId: reduxData.userId,
      userId: reduxData.userId,
      userPermission: reduxData.UserInfo.userPermission,
    };
    setChargeBeeData((plan) => ({ ...plan, isLoading: true }));
    APICALLForCUSTOMDOMAIN(
      'FETCH_CHRGEBEE_URL',
      data,
      'POST',
      MANAGE_SUBSCRIPTION,
      null,
      null,
      (ACTION, type, res) => {
        if (type === 'SUCCESS') {
          setChargeBeeData({ access_url: res.access_url, isLoading: false });
          toggleSubscriptionPopup(true);
        }
      }
    );
  }, [APICALLForCUSTOMDOMAIN, reduxData]);

  const handleUpgradeAlertCloseClick = React.useCallback(() => {
    const data = {
      organiserId: reduxData.userId,
      userId: reduxData.userId,
      userPermission: reduxData.UserInfo.userPermission,
    };

    const body = {
      contact_for_version_change: 0,
    };

    APICALLForCUSTOMDOMAIN(
      'CLOSE_UPGRADE_CONTACT_BUTTON',
      data,
      'POST',
      UPGRADE_MODAL_ACTIONS,
      null,
      body,
      (ACTION, type, res) => {
        if (type === 'SUCCESS') {
          dispatch(setUserInfo(body));
        }
      }
    );
  }, [APICALLForCUSTOMDOMAIN, reduxData]);

  const handleSurvey = React.useCallback(() => {
    dispatch(setSurvey({ isOpen: true }));
  }, []);

  const isProOrEnterpriseUser =
    !userInfo?.UserInfo?.planName ||
    userInfo?.UserInfo?.planName === PricingPlan.PRO ||
    userInfo?.UserInfo?.planName === PricingPlan.ENTERISE;

  const addTopMarginToHeader =
    isProOrEnterpriseUser &&
    userInfo?.UserInfo?.organiser_community_version === 1 &&
    userInfo.UserInfo.contact_for_version_change === 1;

  const isEnableSnake = getSurveyConfig(surveyConfig);
  const isWebinar =
    userInfo.UserInfo.planName === PricingPlan.WEBINAR ||
    userInfo.UserInfo.planName === PricingPlan.WEBINAR_LITE;
  const isDigitalEvent = userInfo.UserInfo.planName === PricingPlan.DIGITAL_EVENTS;
  const isAllIn = userInfo.UserInfo.planName === PricingPlan.ALL_IN;

  return (
    <>
      <Box component="section" className={hasSidebarNav ? dashboardMainWrap : ''}>
        <Header
          hasSidebarNav={hasSidebarNav}
          isLoading={isLoading}
          isPlanExpired={isPlanExpired}
          isMenuOpen={isMenuOpen}
          setMenuOption={setMenuOption}
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
          isWhatsNew={isWhatsNew}
          setWhatsNew={setWhatsNew}
        />

        <Box
          id="dashContentWrapper"
          component="section"
          pt={4}
          pb={4}
          pl={3.25}
          pr={3.25}
          className={
            isPlanExpired ||
              (userInfo.UserInfo.is_payment_due_or_auto_collection_off != undefined &&
                userInfo.UserInfo.is_payment_due_or_auto_collection_off.auto_collection === 'off') ||
              // userInfo.UserInfo.subscriptionStatus === 'CANCEL_SCHEDULED' ||
            userInfo.UserInfo.subscriptionStatus === 'CANCELLED' ||
            (userInfo.UserInfo.subscriptionStatus === 'ACTIVE' &&
              userInfo.UserInfo.isPaymentFailed === 1)
              ? `scroll-y planExpirePadTop ${dashContentWrapper} ${
                  window.location.href.includes('plans') ? 'planWrapper' : ''
                }`
              : `scroll-y ${dashContentWrapper} ${
                  window.location.href.includes('plans') ? 'planWrapper' : ''
                } ${addTopMarginToHeader ? 'planExpirePadTop' : ''}`
          }
          onScroll={handleScroll}>
          {window.location.href.includes('billing') ? (
            <Box className={'expiredStrape'}>
              {userInfo.UserInfo.is_payment_due_or_auto_collection_off != undefined &&
                userInfo.UserInfo.is_payment_due_or_auto_collection_off.auto_collection === 'off' &&
                userInfo.UserInfo.is_payment_due_or_auto_collection_off.amount_due > 0 &&
                !(isAllIn || isDigitalEvent || isWebinar) ? (
                <Snackbar className="snackbarBlock" open={true}>
                  <Box
                    className={`alert danger`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Box display="inline-flex" alignItems="center">
                      <Box display="inline-flex">
                        {' '}
                        <ErrorIcon />
                      </Box>
                      <Box ml={1}>
                        <Typography variant="body2" component="span">
                          Your payment of $
                          {userInfo.UserInfo.is_payment_due_or_auto_collection_off.amount_due} is
                          due on{' '}
                          {moment(
                            new Date(
                              userInfo.UserInfo.is_payment_due_or_auto_collection_off.due_date
                            ).getTime()
                          ).format('D MMM, YYYY')}
                          .
                          <Box
                            className={`cursor-pointer ${boldText}`}
                            component="span"
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                              FETCH_CHARGEBEE_DATA();
                            }}>
                            Manage Payment
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Snackbar>
              ) : (
                false
              )}
            </Box>
          ) : (
            false
          )}

          {isEnableSnake ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={isEnableSnake}>
                <Box className={`alert danger2`} display="flex" alignItems="center">
                  <Typography variant="body2" component="p" className={`text-center ${w100}`}>
                    {surveyConfig?.label}
                    <Typography
                      variant="body2"
                      component="span"
                      className={feedBackForm}
                      onClick={handleSurvey}>
                      Give Feedback!
                    </Typography>
                  </Typography>
                </Box>
              </Snackbar>
            </Box>
          ) : (
            false
          )}

          {isProOrEnterpriseUser &&
            userInfo?.UserInfo?.organiser_community_version === 1 &&
            userInfo?.UserInfo?.contact_for_version_change === 1 && (
              <Box className={'expiredStrape'}>
                <Snackbar className="snackbarBlock" open={true}>
                  <Box className={`alert danger`} display="flex" alignItems="center">
                    <Box
                      className="community"
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center">
                      <Box display="inline-flex">
                        {' '}
                        <ErrorIcon />
                      </Box>
                      <Box ml={1}>
                        <Typography variant="body2" component="span">
                          You need to upgarde to new community. You can contact our customer support
                          support@hubilo.com for any help
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton className={closeBtn} onClick={handleUpgradeAlertCloseClick}>
                      <X color="#fff" size={17} />
                    </IconButton>
                  </Box>
                </Snackbar>
              </Box>
            )}

          {userInfo.UserInfo.subscriptionStatus != undefined &&
            userInfo.UserInfo.subscriptionStatus === 'CANCELLED' &&
            userInfo.UserInfo.planName === PricingPlan.FREE ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={true}>
                <Box
                  className={`alert danger`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Box display="inline-flex" alignItems="center">
                    <Box display="inline-flex">
                      {' '}
                      <ErrorIcon />
                    </Box>
                    <Box ml={1}>
                      <Typography variant="body2" component="span">
                        {userInfo.UserInfo.planName === PricingPlan.FREE &&
                          planExpireTypeDisplay(userInfo.UserInfo) === 'FREE TRIAL EXPIRED'
                          ? `Your FREE TRIAL Is Expired`
                          : `Your ${planExpireTypeDisplay(userInfo.UserInfo)}`}
                        {match.path !== '/plans' ? (
                          <Box
                            className={`cursor-pointer ${boldText}`}
                            component="span"
                            ml={0.5}
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                              history.push('/plans');
                            }}>
                            {'Upgrade Now'}
                          </Box>
                        ) : (
                          false
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          ) : userInfo.UserInfo.subscriptionStatus === 'CANCEL_SCHEDULED' &&
            // Check if Current Day lies between subscribtion End Date -15 days and  subscribtion End Date
            moment(new Date()).isBetween(
              new Date(
                parseInt(userInfo.UserInfo.subscription_end_date_milli) - 15 * 24 * 60 * 60 * 1000
              ).getTime(), // Subscribtion End Date -15 days
              parseInt(userInfo.UserInfo.subscription_end_date_milli) // Subscribtion End Date
            ) ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={true}>
                <Box
                  className={`alert danger`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Box display="inline-flex" alignItems="center">
                    <Box display="inline-flex">
                      {' '}
                      <ErrorIcon />
                    </Box>
                    <Box ml={1}>
                      <Typography variant="body2" component="span">
                        Your subscription will be cancelled on{' '}
                        {moment(parseInt(userInfo.UserInfo.subscription_end_date_milli)).format(
                          'D MMM, YYYY'
                        )}
                        .You can use your plan till then with limited functionalities. For the FULL
                        EXPERIENCE abort the cancellation.
                        {/* <Box className={`cursor-pointer ${boldText}`} component="span" style={{ textDecoration: 'underline' }} onClick={()=>{history.push('/billing')}}>Abort Cancellation</Box> */}
                        <Box
                          className={`cursor-pointer ${boldText}`}
                          ml={0.5}
                          component="span"
                          style={{ textDecoration: 'underline' }}
                          onClick={() => {
                            history.push('/billing');
                          }}>
                          {
                            // userInfo.UserInfo.is_team_member_loggedin === true ? false :
                            'GO TO BILLING'
                          }
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          ) : userInfo.UserInfo.subscriptionStatus === 'CANCELLED' &&
            userInfo.UserInfo.isPaymentFailed === 0 ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={true}>
                <Box
                  className={`alert danger`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Box display="inline-flex" alignItems="center">
                    <Box display="inline-flex">
                      {' '}
                      <ErrorIcon />
                    </Box>
                    <Box ml={1}>
                      <Typography variant="body2" component="span">
                        {!(isAllIn || isDigitalEvent || isWebinar)
                          ? `Your Subscription has been cancelled. Kindly reactivate to continue using
                        the dashboard.`
                          : `Your Subscription has been cancelled. Talk to our sales team to reactivate your subscription`}
                        {!(isAllIn || isDigitalEvent || isWebinar) ? (
                          <Box
                            className={`cursor-pointer ${boldText}`}
                            component="span"
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                              history.push('/billing');
                            }}>
                            {
                              // userInfo.UserInfo.is_team_member_loggedin === true ? false :
                              'GO TO BILLING'
                            }
                          </Box>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          ) : userInfo.UserInfo.subscriptionStatus === 'ACTIVE' &&
            userInfo.UserInfo.isPaymentFailed === 1 ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={true}>
                <Box
                  className={`alert danger`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Box display="inline-flex" alignItems="center">
                    <Box display="inline-flex">
                      {' '}
                      <ErrorIcon />
                    </Box>
                    <Box ml={1}>
                      <Typography variant="body2" component="span">
                        Payment declined. We could not process your payment because of insufficient
                        funds. Please update your payment method.{' '}
                        <Box
                          className={`cursor-pointer ${boldText}`}
                          component="span"
                          style={{ textDecoration: 'underline' }}
                          onClick={() => {
                            history.push('/billing');
                          }}>
                          {
                            // userInfo.UserInfo.is_team_member_loggedin === true ? false :
                            'GO TO BILLING'
                          }
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          ) : userInfo.UserInfo.subscriptionStatus === 'CANCELLED' &&
            userInfo.UserInfo.isPaymentFailed === 1 ? (
            <Box className={'expiredStrape'}>
              <Snackbar className="snackbarBlock" open={true}>
                <Box
                  className={`alert danger`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <Box display="inline-flex" alignItems="center">
                    <Box display="inline-flex">
                      {' '}
                      <ErrorIcon />
                    </Box>
                    <Box ml={1}>
                      <Typography variant="body2" component="span">
                        {!(isAllIn || isDigitalEvent || isWebinar)
                          ? `Your subscription has been cancelled due to non payment.Kindly reactivate to
                        continue using the dashboard.`
                          : `Your subscription has been cancelled due to non payment. Talk to our sales team to reactivate your subscription`}
                        {!(isAllIn || isDigitalEvent || isWebinar) ? (
                          <Box
                            className={`cursor-pointer ${boldText}`}
                            component="span"
                            style={{ textDecoration: 'underline' }}
                            onClick={() => {
                              history.push('/billing');
                            }}>
                            {
                              // userInfo.UserInfo.is_team_member_loggedin === true ? false :
                              'GO TO BILLING'
                            }
                          </Box>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          ) : (
            ''
          )}
          {/* </>:''} */}

          {!hasSidebarNav ? false : <SidebarNav isLoading={isLoading} />}

          {[
            '/events',
            '/events/upcoming',
            '/events/ended',
            '/events/ongoing',
            `/event/${eventId}/${organiserId}/`,
            `/event/${eventId}/${organiserId}`,
          ].includes(pagePathname) ? (
            <Box className={isEnableSnake ? surveyStripeWrapper : h100}>{children}</Box>
          ) : (
            children
          )}

          <NotificationBar />
        </Box>
      </Box>
      <IFramePopup
        url={chargeBeeData.access_url}
        isOpen={openSubscriptionPopup}
        onClose={() => toggleSubscriptionPopup(false)}
      />
    </>
  );
};

const Header = (props) => {
  // const { dashHeader, logoBlock, leftAside, rightMenuAction, rightAsideOtherAction, userAvatar, userAvatar2, notificationCount, boldText, actionIcon, btnForPopup, userProfileBlock, userName, progressBarLine, badgeStyle, notificationBlock, primaryColor, successIconColor } = useStyles();
  const {
    dashHeader,
    logoBlock,
    leftAside,
    rightMenuAction,
    rightAsideOtherAction,
    userAvatar,
    userAvatar2,
    actionIcon,
    btnForPopup,
    userProfileBlock,
    userName,
    email,
    avatarBadge,
    planDropdown,
    whatsNewBlock,
    multiLangSupport,
    primaryColor,
    multiLangSupportPopup,
    multiLangList,
    boldText,
    langTitle,
    langSuppoert,
    previewBtn,
    svgDownTimeIcon,
    tagEventNameForHeader,
    tagEventNameForPopup,
    borderForProfile,
  } = useStyles();
  const { hasSidebarNav, isLoading, isPlanExpired } = props;

  // const [isMenuOpen, setMenuOption] = useState(false);
  // const [isPlanMenuOpen, setPlanMenuOption] = useState(false);
  // const [isUpgrade, setUpgradeOpen] = useState(false);
  const reduxData = useSelector(userInfoSelector, shallowEqual);
  const [canManagePayout, setCanManagePayout] = useState(false);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const dispatch = useDispatch();
  const source = useSource();
  const history = useHistory();
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);
  // const [isWhatsNew, setWhatsNew] = useState(false);
  // const [isNotification, setNotification] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const match = useRouteMatch();
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const { EventData } = eventData;
  const dataEvent = match.params.eventId;
  const [isMultiLang, setMultiLang] = useState(false);
  const [multiLangOptions, setMultiLangOptions] = useState(MultiLangOption);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const baseEventLanguage = useSelector(baseEventLanguagesSelector, shallowEqual);
  const alert = useSelector(alertSelector, shallowEqual);
  const { eventId } = useParams();
  const CUSTOM_URL_V2 = projectSetting.customBaseURL2;
  const { eventPropertySet, sessionPropertySet } = useAmplitudeData();

  const navLinks = [
    {
      id: 0,
      name: 'Account',
      icon: <User />,
      url: '/account/profile',
    },
    {
      id: 1,
      name: `${userInfo.UserInfo?.is_att_log_pricing ? 'Plans & Purchases' : 'Billing & Subscriptions'
        }`,
      icon: <FileText />,
      url: '/billing',
    },
    {
      id: 2,
      name: 'Payout',
      icon: <SvgPay />,
      url: '/payout',
    },
    {
      id: 3,
      name: 'Team',
      icon: <Users />,
      url: '/team',
    },
  ];

  const FETCH_NOTIFICATION_LIST = async () => {
    const [headers] = API.generateHeader(reduxData, null, null);
    const body = {
      payload: {
        data: {
          currentPage: 0,
          limit: 10,
          orgNotCount: 0,
        },
      },
    };
    await POSTAPIWITHCUSTOMDOMAIN(
      false,
      GET_NOTIFICATION_LIST,
      source.AS,
      headers,
      body,
      CUSTOM_URL_V2
    )
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          dispatch(setAlertData({ unread: resp.data.data?.unread }));
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);

    return () => API.removeAllApi(source.AS);
  };

  useEffect(() => {
    const [headers] = API.generateHeader(reduxData, null, null);
    if (eventId && window.location.href.includes('edit')) {
      GETAPI(false, EVENT_OVERVIEW_API, null, { eventId, ...headers })
        .then((resp) => {
          if (resp.data.status === API.apiSuccessStatus) {
            dispatch(setEventData(resp.data.data.eventinfo));
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    }
  }, [eventId]);

  useEffect(() => {
    if (isMultiLang) setMultiLang(false);
  }, [match.path]);

  useEffect(() => {
    if (eventLanguages && eventLanguages.length > 0) {
      let languages = [];
      const MultiLangOptionCopy = MultiLangOption;
      eventLanguages.forEach((item, index) => {
        const langIndex = MultiLangOptionCopy.findIndex((i) => i.langName === item.name);
        if (langIndex >= 0) {
          languages.push(MultiLangOptionCopy[langIndex]);
          if (languages[index]) {
            if (item.name === currentEventLanguage.name) {
              languages[index].isChecked = true;
              setSelectedLanguage(languages[index]);
            } else {
              languages[index].isChecked = false;
            }
          }
        }
      });
      setMultiLangOptions([...languages]);
    } else {
      setSelectedLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLanguages, currentEventLanguage]);

  const onLogout = () => {
    const [headers, body] = API.generateHeader(reduxData, null, null);
    GETAPI(false, LOGOUT_API, null, headers, body)
      .then((resp) => {
        if (!(resp.data.status === API.apiSuccessStatus)) {
          API.errStatusHandler(resp, props.history, dispatch);
        }
      })
      .catch(API.catchHandler);
    dispatch(removeUserInfo());
    dispatch(removeEventDataRedux());
    removeUserCredential();
    mixPanel.Login.logoutSuccessMP();
    amplitude.Track.resetUserID();
    sessionStorage.clear();
    history.replace('/login');
    window.Intercom('shutdown');
  };
  const [isEventPublish, setEventPublish] = useState(0);
  const [live, setLive] = React.useState(false);
  const [loadingState, setLoadState] = React.useState({
    initLoad: false,
    updatePublishEvent: false,
  });
  const [eventUrl, setEventUrl] = useState('');
  const { identifyOrgData } = usePosthogClient();

  const APICALLForCUSTOMDOMAIN = React.useCallback(
    (ACTION, reduxData, methodType, URL, headerData, bodyData, APICB) => {
      const [header, body] = API.generateHeader(reduxData, headerData, bodyData);
      let APIPromise;
      if (methodType === 'POST') {
        APIPromise = POSTAPIWITHCUSTOMDOMAIN(
          false,
          URL,
          source.AS,
          header,
          body,
          ProjectSetting.customBaseURL
        );
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(setNewMessage({ message: resp.data.message, type: 'success', show: true }));
          APICB && APICB(ACTION, 'SUCCESS', resp.data.data);
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            APICB && APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    if (eventData.EventData) {
      setEventPublish(eventData.EventData.is_publish);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);
  useEffect(() => {
    if (
      reduxData &&
      reduxData.UserInfo &&
      reduxData.UserInfo.userPermission &&
      (reduxData.UserInfo.userPermission.canManagePayout.toString() === '1' ||
        reduxData.UserInfo.userPermission.isSuperAdmin.toString() === '1')
    ) {
      setCanManagePayout(true);
    } else {
      setCanManagePayout(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxData]);

  const handelClickOnPublishEvent = () => {
    setLoadState({ ...loadingState, updatePublishEvent: true });
    const reqData = {
      data: {
        is_publish: isEventPublish === 0 ? 1 : 0,
      },
    };
    const headerData = {
      eventId: eventData.eventId,
      organiserId: eventData.organiserId,
      userId: eventData.userId,
    };
    APICALLForCUSTOMDOMAIN(
      'GET-EVENTLIVE-STATUS-UPDATE',
      headerData,
      'POST',
      EVENT_LIVE_STATUS_UPDATE,
      null,
      reqData,
      (ACTION, type, data) => {
        if (type === 'SUCCESS') {
          const eventDetails = eventData.EventData;
          eventDetails.is_publish = isEventPublish === 0 ? 1 : 0;
          setLive(false);
          setEventPublish(isEventPublish === 0 ? 1 : 0);
          dispatch(setEventData(eventDetails));
          setLoadState({ ...loadingState, updatePublishEvent: false });
          // mixPanel.Login.PublishEventMP(eventDetails.eventId, eventDetails.eventName);
        } else {
          setLoadState({ ...loadingState, updatePublishEvent: false });
        }
      }
    );
  };

  const handelClickOnOpenEventUrl = (url) => {
    amplitude.Track.goToEvent('navbar');
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (reduxData.UserInfo?.email) {
      identifyOrgData(reduxData.UserInfo.email, {
        organiser_id: reduxData.UserInfo.organiser_id,
        ...(reduxData.UserInfo.organisation && { organisation: reduxData.UserInfo.organisation })
      });
    }
  }, [reduxData.UserInfo?.email]);

  useEffect(() => {
    const script = document.createElement('script');
    const headscript = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.async = true;
    script.defer = 'defer';
    script.type = 'text/javascript';
    const fName =
      userInfo && userInfo.UserInfo && userInfo.UserInfo.first_name
        ? userInfo.UserInfo.first_name
        : '';
    const lName =
      userInfo && userInfo.UserInfo && userInfo.UserInfo.last_name
        ? userInfo.UserInfo.last_name
        : '';
    const uEmail =
      userInfo && userInfo.UserInfo && userInfo.UserInfo.email ? userInfo.UserInfo.email : '';
    const uId = userInfo && userInfo.UserInfo && userInfo.UserInfo.id ? userInfo.UserInfo.id : '';
    headscript.innerHTML =
      `${'var beamer_config = {\n' +
      "		product_id: 'lWCUgZSV28251',\n" +
      "		selector: 'beamerTrigger',\n" +
      "		display: 'right',\n" +
      '		bottom: 70,\n' +
      " user_firstname :'"
      }${fName}',\n` +
      ` user_lastname :'${lName}',\n` +
      ` user_email :'${uEmail}',\n` +
      ` user_id :'${uId}',\n` +
      `	}\n`;
    headscript.async = true;
    document.body.appendChild(headscript);
    document.body.appendChild(script);

    if (userInfo?.UserInfo?.is_att_log_pricing && !('unread' in alert)) FETCH_NOTIFICATION_LIST();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (EventData && EventData.is_publish) {
      setEventPublish(EventData.is_publish);
    }
    if (EventData && EventData.url) {
      setEventUrl(EventData.url);
    }
  }, [EventData]);

  // Event Preview region
  const [previewEvent, sePreviewEvent] = React.useState({
    showPreview: false,
    previewToken: '',
  });
  useEffect(() => {
    if (EventData && EventData?.is_publish === 0 && EventData?.community_version === 2) {
      const [header, body] = API.generateHeader(
        reduxData,
        {
          eventId: eventData.eventId,
          organiserId: eventData.organiserId,
        },
        {
          data: { email: userInfo?.UserInfo?.email },
        }
      );
      const response = POSTAPIWITHCUSTOMDOMAIN(
        false,
        '/preview-event',
        source.AS,
        header,
        body,
        ProjectSetting.customBaseURL2
      );

      response
        .then((resp) => {
          sePreviewEvent({ showPreview: resp?.data?.showPreview, previewToken: resp?.data?.token });
        })
        .catch((error) => {
          sePreviewEvent({
            showPreview: false,
            previewToken: '',
          });
        });
    } else {
      sePreviewEvent({
        showPreview: false,
        previewToken: '',
      });
    }
  }, [EventData]);

  const handelClickOnOpenPreviewEventUrl = (url) => {
    const newUrl = `${url}/?previewtoken=${previewEvent?.previewToken}`;
    const eventPreData = {
      'event format': eventData?.EventData?.type,
      'clicked by':
        userInfo?.UserInfo?.id === userInfo?.UserInfo?.organiser_id ? 'organiser' : 'team member',
    };
    amplitude.Track.previewEventData(eventPreData);
    amplitude.Track.goToEvent('navbar');
    window.open(newUrl, '_blank');
  };

  // End Event Preview region
  const showPlanIcon = (val) => {
    switch (userInfo.UserInfo.planName) {
      case 'free':
        if (val === 'white') {
          return '';
        } else {
          return '';
        }

      case 'advanced':
        if (val === 'white') {
          return <AdvanceIconWhite height="13px" width="13px" />;
        } else {
          return <SvgAdvancePlanIconFill height={20} width={20} />;
        }

      case 'pro':
        if (val === 'white') {
          return <ProPlanIconWhite height="13px" width="13px" />;
        } else {
          return <SvgProPlanIconFill height={20} width={20} />;
        }
      case 'enterprise':
        if (val === 'white') {
          return <EnterPriseIconWhite height="13px" width="13px" />;
        } else {
          return <SvgEnterprisePlanIconFill height={20} width={20} />;
        }
      case 'basic':
        if (val === 'white') {
          return <BasicPlanIconWhite height="13px" width="13px" />;
        } else {
          return <SvgBasicPlanIconFill height={20} width={20} />;
        }
      case 'webinar':
        return (
          <Box className={`${val === 'white' ? tagEventNameForHeader : tagEventNameForPopup}`}>
            <Typography variant="h6" component="h6">
              WM
            </Typography>
          </Box>
        );
      case 'webinar_lite':
        return (
          <Box className={`${val === 'white' ? tagEventNameForHeader : tagEventNameForPopup}`}>
            <Typography variant="h6" component="h6">
              W+
            </Typography>
          </Box>
        );
      case 'digital-events':
        return (
          <Box className={`${val === 'white' ? tagEventNameForHeader : tagEventNameForPopup}`}>
            <Typography variant="h6" component="h6">
              DE
            </Typography>
          </Box>
        );
      case 'all-in':
        return (
          <Box className={`${val === 'white' ? tagEventNameForHeader : tagEventNameForPopup}`}>
            <Typography variant="h6" component="h6">
              All
            </Typography>
          </Box>
        );
      default:
        return '';
    }
  };
  const findDispName = (name) => {
    if (multiLangOptions && multiLangOptions.length > 1) {
      let languages = '';
      const langIndex = multiLangOptions.findIndex((i) => i.langName === name);
      if (langIndex !== -1) {
        languages = multiLangOptions[langIndex].langDisplayName;
      } else {
        languages = name;
      }
      return languages;
    } else {
      return name;
    }
  };

  return (
    <>
      <Box
        component="header"
        className={`${!hasSidebarNav ? 'sidebar-not-available' : ''} ${dashHeader} p-fixed`}>
        <Box className="h-100" pr={1} display="flex" justifyContent="space-between">
          <Box>
            <Box className={`h-100 ${leftAside}`} display="flex" alignItems="center">
              <Box className={`${logoBlock}`} display="inline-flex" alignItems="center">
                {!isLoading ? (
                  <>
                    {eventId ? (
                      <>
                        {EventData.logo && EventData.logo.length > 0 ? (
                          <Avatar
                            src={getEventLogo(EventData.organiser_id, EventData.logo[0])}
                            alt="logo"
                            variant="square"
                            className={userAvatar}
                          />
                        ) : (
                          ''
                        )}
                        <span className="logo-text">{EventData.name}</span>
                      </>
                    ) : (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            history.push('/events');
                          }}>
                          {/* <SvgHubilo className="logo-icon cursor-pointer" fill="currentColor" /> */}
                          <SvgHubiloNewIcon
                            className="logo-icon cursor-pointer"
                            height="40px"
                            width="160px"
                          />
                          {/* <span className="logo-text cursor-pointer">HUBILO</span> */}
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Box display="flex" alignItems="center" justifyContent="center" mr={2}>
                      <SkeletonLoader variant={'square'} height="36px" width="36px" />
                      <Box display="inline-flex" alignItems="center" ml={1}>
                        <SkeletonLoader variant={'text'} height="30px" width="80px" />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {!dataEvent && match.path !== '/onboarding' ? (
            <Box>
              <Grid container className={`h-100`} direction="row" justify="center">
                <Grid item>
                  <Box
                    className={`h-100 ${rightAsideOtherAction}`}
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center">
                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <SkeletonLoader variant={'square'} height="30px" width="80px" />
                      ) : (
                        <Button
                          className={`uppercase ${history.location.pathname === '/events' ? 'btn-event' : false
                            } event-btn`}
                          variant="text"
                          size="small"
                          onClick={() => {
                            history.push({
                              pathname: `/events`,
                            });
                            amplitude.Track.selectNavBarItem('event');
                            props.setMenuOption(false);
                          }}>
                          EVENTS
                        </Button>
                      )}
                    </Box>

                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              location.href = '/account-intelligence'

                            }}
                            className={`uppercase ${window.location.pathname === '/account-intelligence' ? 'btn-event'
                              : false
                              } event-btn`}
                            variant="text"
                            size="small">
                            Account Intelligence
                          </Button>
                        </>
                      )}
                    </Box>

                    {/* <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          {userInfo?.UserInfo?.userPermission?.canManageContacts ||
                          userInfo?.UserInfo?.userPermission?.isSuperAdmin ? (
                            <Button
                              onClick={() => {
                                history.push({
                                  pathname: `/contacts`,
                                  state: {
                                    eventId: EventData.id,
                                    organiserId: EventData.organiser_id,
                                  },
                                });
                                amplitude.Track.selectNavBarItem('contacts');
                                props.setMenuOption(false);
                              }}
                              className={`uppercase ${
                                history.location.pathname === '/contacts' ||
                                history.location.pathname.split('/').pop() === 'profile-details'
                                  ? 'btn-event'
                                  : false
                              } event-btn`}
                              variant="text"
                              size="small">
                              CONTACTS
                            </Button>
                          ) : null}
                        </>
                      )}
                    </Box> */}

                    {/* <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              history.push({
                                pathname: `/roi`,
                                state: {
                                  eventId: EventData.id,
                                  organiserId: EventData.organiser_id,
                                },
                              });
                              // amplitude.Track.selectNavBarItem('contacts');
                              props.setMenuOption(false);
                            }}
                            className={`uppercase ${
                              history.location.pathname === '/roi' ? 'btn-event' : false
                            } event-btn`}
                            variant="text"
                            size="small">
                            REVENUE IMPACT
                          </Button>
                        </>
                      )}
                    </Box> */}

                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          {userInfo?.UserInfo?.userPermission?.canManageAnalytics ||
                            userInfo?.UserInfo?.userPermission?.isSuperAdmin ? (
                            <Button
                              onClick={() => {
                                history.push({
                                  pathname: `/analytics/overview-analytics`,
                                  state: {
                                    eventId: EventData.id,
                                    organiserId: EventData.organiser_id,
                                    analyticsClicked: true,
                                  },
                                });
                                amplitude.Track.selectNavBarItem('analytics');
                                props.setMenuOption(false);
                              }}
                              className={`uppercase ${history.location.pathname === '/analytics/overview-analytics' ||
                                  history.location.pathname === '/analytics/engagement-analytics' ||
                                  history.location.pathname === '/analytics/revenue-analytics'
                                  ? 'btn-event'
                                  : false
                                } event-btn`}
                              variant="text"
                              size="small">
                              ANALYTICS
                            </Button>
                          ) : null}
                        </>
                      )}
                    </Box>

                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          {userInfo?.UserInfo?.userPermission?.canManageIntegrations ||
                            userInfo?.UserInfo?.userPermission?.isSuperAdmin ? (
                            <Button
                              onClick={() => {
                                history.push({
                                  pathname: `/integrations`,
                                });
                                amplitude.Track.selectNavBarItem('integration');
                              }}
                              className={`uppercase ${history.location.pathname === '/integrations' ? 'btn-event' : false
                                } event-btn`}
                              variant="text"
                              size="small">
                              INTEGRATIONS
                            </Button>
                          ) : null}
                        </>
                      )}
                    </Box>

                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <Box ml={1}>
                          <SkeletonLoader variant={'square'} height="30px" width="80px" />
                        </Box>
                      ) : (
                        <>
                          {(userInfo?.UserInfo?.userPermission?.isSuperAdmin ||
                            userInfo?.UserInfo?.userPermission?.canManagePtrDataMappings) &&
                            userInfo?.UserInfo?.is_freeman_customer ? (
                            <Button
                              onClick={() => {
                                amplitude.Track.selectNavBarItem('data-mapping');
                                history.push({ pathname: `/data-mapping` });
                              }}
                              className={`uppercase ${history.location.pathname.includes('/data-mapping')
                                  ? 'btn-event'
                                  : false
                                } event-btn`}
                              variant="text"
                              size="small">
                              DATA MAPPING
                            </Button>
                          ) : null}
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            false
          )}

          <Box>
            <Grid container className={`h-100`} justifyContent="flex-end">
              <Grid item>
                {userInfo.UserInfo.subscription_end_date_milli === undefined ? (
                  false
                ) : (
                  <Box
                    mr={1}
                    className={`h-100 ${rightAsideOtherAction}`}
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center">
                    {isLoading ? (
                      <SkeletonLoader variant={'square'} height="30px" width="80px" />
                    ) : userInfo.UserInfo.subscription_end_date_milli !== undefined ? (
                      <ClickAwayListener
                        onClickAway={() => {
                          props.setPlanMenuOption(false);
                        }}>
                        <Box position="relative">
                          {/* <Button size="small" className="plan-btn" onClick={() => setPlanMenuOption(!isPlanMenuOpen)}>FREE</Button> */}
                          <Button
                            size="small"
                            className="plan-btn"
                            startIcon={alert?.unread ? <AlertTriangle size={20} /> : false}
                            endIcon={<KeyboardArrowDownIcon size={20} />}
                            onClick={() => {
                              if (userInfo.UserInfo?.is_att_log_pricing) {
                                commonAmplitudeTrack('UsageChecked', {
                                  group: userInfo?.UserInfo?.is_team_member_loggedin
                                    ? 'TEAM_MEMBER'
                                    : 'ORGANISER',
                                  organiser_id: userInfo?.UserInfo?.organiser_id,
                                  user_id: userInfo?.UserInfo?.id,
                                  plan_type: userInfo?.UserInfo?.planName,
                                  is_unread_alert: alert?.unread ? true : false,
                                  organisation: userInfo?.UserInfo?.organisation,
                                });
                              }
                              props.setPlanMenuOption(!props.isPlanMenuOpen);
                            }}>
                            {planExpireTypeDisplay(userInfo.UserInfo)}
                          </Button>
                          <PlanDetailsMenu
                            planName={userInfo.UserInfo.planName}
                            isPlanMenuOpen={props.isPlanMenuOpen}
                            isPlanExpired={isPlanExpired}
                            setPlanMenuOption={props.setPlanMenuOption}
                          />
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      false
                    )}
                  </Box>
                )}

                {dataEvent ? (
                  <Box
                    className={`h-100 ${rightAsideOtherAction}`}
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center">
                    <Box className={`header-addition-action`}>
                      {isLoading ? (
                        <SkeletonLoader
                          variant={'square'}
                          skeletonProps={{ marginRight: '15px' }}
                          height="30px"
                          width="100px"
                        />
                      ) : (
                        <>
                          {isEventPublish === 1 ? (
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => handelClickOnOpenEventUrl(eventUrl)}>
                              GO TO EVENT
                            </Button>
                          ) : (
                            false
                          )}
                          {isEventPublish === 0 ? (
                            <>
                              {previewEvent?.showPreview && (
                                <Button
                                  disabled={userInfo.UserInfo.subscriptionStatus === 'CANCELLED'}
                                  // className=""
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={() => handelClickOnOpenPreviewEventUrl(eventUrl)}>
                                  <ExternalLink size={16} />
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    className={previewBtn}>
                                    Preview Event
                                  </Typography>
                                </Button>
                              )}

                              <Button
                                disabled={userInfo.UserInfo.subscriptionStatus === 'CANCELLED'}
                                className="uppercase goLiveBtn"
                                variant="text"
                                size="small"
                                onClick={() => setLive(!live)}>
                                Go Live
                              </Button>
                            </>
                          ) : (
                            false
                          )}
                        </>
                      )}
                    </Box>
                    <Box mr={1.5}>
                      {isLoading ? (
                        <SkeletonLoader variant={'square'} height="30px" width="100px" />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                ) : (
                  false
                )}
              </Grid>
              {match.path !== '/events' &&
                match.path !== '/events/ongoing' &&
                match.path !== '/events/upcoming' &&
                match.path !== '/events/ended' &&
                match.path !== '/events/copying' &&
                !match.path.includes('/contacts') &&
                !match.path.includes('/analytics') &&
                !match.path.includes('/integrations') &&
                !match.path.includes('/data-mapping') &&
                !match.path.includes('/account') &&
                !match.path.includes('/team') &&
                !match.path.includes('/payout') &&
                !match.path.includes('/billing') &&
                selectedLanguage && (
                  <Grid item className={`${rightMenuAction} right-menu-action`}>
                    <ClickAwayListener
                      onClickAway={() => {
                        setMultiLang(false);
                      }}>
                      <Box
                        maxWidth="140px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        height="100%">
                        {!isLoading ? (
                          <Button
                            className={`w-100 lang-btn h-100 dash-header-right-action`}
                            onClick={() => setMultiLang(!isMultiLang)}
                            title={selectedLanguage && selectedLanguage.langDisplayName}>
                            <Box className={`langGlobeIcon`} display="flex">
                              <GlobIcon />
                            </Box>

                            <span className="ml10">
                              {' '}
                              {selectedLanguage && selectedLanguage.langDisplayName}
                            </span>
                          </Button>
                        ) : (
                          <SkeletonLoader height="24px" width="24px" variant="round" />
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Grid>
                )}
              {DOWN_TIME_MODAL.IS_AVAILABLE && (
                <Grid item className={`${rightMenuAction} right-menu-action`}>
                  <Box
                    maxWidth="140px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    height="100%">
                    {!isLoading ? (
                      <Button
                        className={`w-100 h-100 dash-header-right-action`}
                        onClick={() => {
                          dispatch(downTimeModalAction(true));
                        }}>
                        <Box className={`warnIcon`} display="flex">
                          <AlertTriangle />
                        </Box>
                      </Button>
                    ) : (
                      <SkeletonLoader height="24px" width="24px" variant="square" />
                    )}
                  </Box>
                </Grid>
              )}
              <Grid item className={`${rightMenuAction} right-menu-action`}>
                <ClickAwayListener
                  onClickAway={() => {
                    props.setWhatsNew(false);
                  }}>
                  <Box position="relative" height="100%">
                    {!isLoading ? (
                      <Button
                        className={`w-100 h-100 dash-header-right-action`}
                        onClick={() => {
                          window.open('https://support.hubilo.com/hc/en-us', '_blank');
                        }}>
                        <HelpCircle size={22} strokeWidth={2} />
                      </Button>
                    ) : (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <SkeletonLoader variant={'square'} height="25px" width="25px" />
                      </Box>
                    )}
                  </Box>
                </ClickAwayListener>
              </Grid>
              <Grid item className={`${rightMenuAction} right-menu-action`}>
                {!isLoading ? (
                  <Tooltip title="Check out what's new on Hubilo!" placement="bottom" arrow>
                    <Button id="beamerTrigger" className={`w-100 h-100 dash-header-right-action`}>
                      <SpeakerDeviceLargeIcon />
                    </Button>
                  </Tooltip>
                ) : (
                  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <SkeletonLoader variant={'square'} height="25px" width="25px" />
                  </Box>
                )}
              </Grid>

              <Grid item className={`${rightMenuAction} right-menu-action`}>
                <ClickAwayListener
                  onClickAway={() => {
                    props.setMenuOption(false);
                  }}>
                  <Box position="relative" height="100%" ml={1}>
                    {!isLoading ? (
                      <Button
                        className={`w-100 h-100 dash-header-right-action`}
                        onClick={() => props.setMenuOption(!props.isMenuOpen)}>
                        <Box position="relative">
                          <Avatar
                            src={
                              reduxData.UserInfo.img_profile &&
                              getUserImage(reduxData.UserInfo.img_profile)
                            }
                            alt={
                              (reduxData.UserInfo.first_name || '') +
                              ' ' +
                              (reduxData.UserInfo.last_name || '')
                            }
                            className={userAvatar2}
                          />
                          {showPlanIcon('white') !== '' ? (
                            <Box
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              className={avatarBadge}>
                              {showPlanIcon('white')}
                            </Box>
                          ) : (
                            false
                          )}
                        </Box>
                        <Box
                          mr={0.5}
                          maxWidth={70}
                          display="block"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="noWrap"
                          component="span">
                          {reduxData.UserInfo.first_name || ''}
                        </Box>
                        <Box
                          color="text.grayColor1"
                          component="span"
                          mr={-0.8}
                          display="inline-flex">
                          <ArrowDropDownIcon className="d-block" />
                        </Box>
                      </Button>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        width={205}
                        justifyContent="center"
                        height="100%"
                        pl={1}
                        pr={1}>
                        <Avatar src="" className={userAvatar2} />
                        <Box mr={0.5} component="span">
                          <SkeletonLoader variant={'text'} height="20px" width="85px" />
                        </Box>
                        <Box
                          color="text.grayColor1"
                          component="span"
                          mr={-0.8}
                          display="inline-flex">
                          <ArrowDropDownIcon className="d-block" />
                        </Box>
                      </Box>
                    )}
                    <Fade in={props.isMenuOpen}>
                      <Box
                        position="absolute"
                        width={240}
                        right="10px"
                        zIndex={5}
                        mt={1.2}
                        mb={1.2}>
                        <Paper elevation={2}>
                          <Box
                            display="flex"
                            alignItems="center"
                            p={2}
                            className={borderForProfile}>
                            <Avatar
                              src={
                                reduxData.UserInfo.img_profile &&
                                getUserImage(reduxData.UserInfo.img_profile)
                              }
                              alt={
                                (reduxData.UserInfo.first_name || '') +
                                ' ' +
                                (reduxData.UserInfo.last_name || '')
                              }
                              className={userAvatar2}
                            />
                            <Box>
                              <Box className={userName}>
                                {(reduxData.UserInfo.first_name || '') +
                                  ' ' +
                                  (reduxData.UserInfo.last_name || '')}
                              </Box>
                              <Box className={email}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  title={reduxData.UserInfo.email}
                                  component="span">
                                  {reduxData.UserInfo.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          {userInfo.UserInfo.subscriptionStatus === undefined ? (
                            false
                          ) : (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              py={1.5}
                              px={2}>
                              <Button
                                onClick={() => { }}
                                className={`${btnForPopup} expired-btn`}
                                disabled
                                size="small">
                                {showPlanIcon('fill')}
                                {planExpireTypeDisplay(userInfo.UserInfo)}
                              </Button>
                            </Box>
                          )}

                          <List className={userProfileBlock} disablePadding>
                            {navLinks.map((navLink, index) => {
                              return (
                                <React.Fragment key={433 + index + ''}>
                                  {navLink.id !== 2 ? (
                                    <li key={navLink.name}>
                                      {userInfo.UserInfo.subscriptionStatus === undefined &&
                                        navLink.id === 1 ? (
                                        false
                                      ) : (
                                        <ListItem
                                          button
                                          onClick={() => {
                                            if (
                                              userInfo.UserInfo?.is_att_log_pricing &&
                                              navLink?.id === 1 &&
                                              navLink?.url === '/billing'
                                            ) {
                                              commonAmplitudeTrack(
                                                'PlansAndPurchasesOptionClicked',
                                                {
                                                  group: userInfo?.UserInfo?.is_team_member_loggedin
                                                    ? 'TEAM_MEMBER'
                                                    : 'ORGANISER',
                                                  organiser_id: userInfo?.UserInfo?.organiser_id,
                                                  user_id: userInfo?.UserInfo?.id,
                                                  plan_type: userInfo?.UserInfo?.planName,
                                                  organisation: userInfo?.UserInfo?.organisation,
                                                }
                                              );
                                            }
                                            history.push({
                                              // pathname: userInfo.UserInfo.is_team_member_loggedin === undefined ? navLink.url : (userInfo.UserInfo.is_team_member_loggedin === true && navLink.id === 1 ?
                                              // '/error' : navLink.url ) ,
                                              pathname: navLink.url,
                                              state: {
                                                eventId: EventData.id,
                                                organiserId: EventData.organiser_id,
                                              },
                                            });
                                            setActiveLink(index);
                                            props.setMenuOption(false);
                                            dispatch(removeEventDataRedux());
                                          }}
                                          selected={activeLink === index}>
                                          <ListItemIcon className={actionIcon}>
                                            {navLink.icon}
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={
                                              <Typography
                                                color="textSecondary"
                                                component="span"
                                                variant="body2">
                                                {navLink.name}
                                              </Typography>
                                            }
                                          />
                                        </ListItem>
                                      )}
                                    </li>
                                  ) : (
                                    <>
                                      {reduxData && canManagePayout ? (
                                        <li key={navLink.name}>
                                          <ListItem
                                            button
                                            onClick={() => {
                                              history.push({
                                                pathname: navLink.url,
                                                state: {
                                                  eventId: EventData.id,
                                                  organiserId: EventData.organiser_id,
                                                },
                                              });
                                              setActiveLink(index);
                                              props.setMenuOption(false);
                                              dispatch(removeEventDataRedux());
                                            }}
                                            selected={activeLink === index}>
                                            <ListItemIcon className={actionIcon}>
                                              {navLink.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={
                                                <Typography
                                                  color="textSecondary"
                                                  component="span"
                                                  variant="body2">
                                                  {navLink.name}
                                                </Typography>
                                              }
                                            />
                                          </ListItem>
                                        </li>
                                      ) : (
                                        ''
                                      )}{' '}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </List>
                          <Box p={2}>
                            <Button
                              onClick={onLogout}
                              className={`${btnForPopup} uppercase`}
                              variant="outlined"
                              color="primary"
                              size="small">
                              LOGOUT
                            </Button>
                          </Box>
                        </Paper>
                      </Box>
                    </Fade>
                  </Box>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* <Fade in={props.isWhatsNew}>
          <Box position="absolute" width={240} right="190px" zIndex={5} mt={1.2} mb={1.2}>
            <Paper elevation={2}>
              <Box className={whatsNewBlock}>
                <List>
                  <Box
                    component="li"
                    display="flex"
                    onClick={() => {
                      window.open('https://support.hubilo.com/hc/en-us', '_blank');
                    }}>
                    <Box>
                      <FileText size={20} strokeWidth={1} />
                    </Box>
                    <Box ml={1.5}>
                      <Typography variant="body2" component="span">
                        Knowledge Base
                      </Typography>
                    </Box>
                  </Box>
                </List>
              </Box>
            </Paper>
          </Box>
        </Fade> */}
        {multiLangOptions && multiLangOptions.length > 1 && (
          <Fade in={isMultiLang}>
            <Box
              position="absolute"
              // width={240}
              zIndex={5}
              mt={1.2}
              mb={1.2}
              right={141}
              className={multiLangSupportPopup}>
              <Paper elevation={2}>
                <Box p={1} pl={2} className={langTitle}>
                  <Typography variant="body2" color="textPrimary" component="label">
                    {baseEventLanguage &&
                      baseEventLanguage.name &&
                      findDispName(baseEventLanguage.name)}
                    <span className="baseText">Base</span>
                  </Typography>
                </Box>
                <Box p={2} className={[langTitle, langSuppoert].join(' ')}>
                  <Typography variant="body2" color="textPrimary" component="label">
                    Languages Supported
                  </Typography>
                </Box>
                <Box>
                  <List className={multiLangList}>
                    {multiLangOptions.map((e, i) => (
                      <ListItem
                        key={i}
                        onClick={() => {
                          setSelectedLanguage(e);
                          const langIndex = eventLanguages.findIndex((i) => i.name === e.langName);
                          if (langIndex !== -1) {
                            dispatch(setCurrentEventLanguage(eventLanguages[langIndex]));
                          }
                        }}>
                        {/* <ListItemIcon>{e.langIcon}</ListItemIcon> */}
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                color="textSecondary"
                                component="span"
                                variant="caption"
                                className={`${e.isChecked ? primaryColor : false}`}>
                                {e.langDisplayName}
                              </Typography>
                              {e.isChecked && (
                                <span className="checkSvgIcon">
                                  <Check size="15px" color="#00C957" strokeWidth={3} />
                                </span>
                              )}
                            </>
                          }
                        />
                        {/*   {e.isChecked ? (
                          <ListItemSecondaryAction>
                            <Check size="15px" color="#00C957" strokeWidth={3} />
                          </ListItemSecondaryAction>
                        ) : (
                          false
                        )} */}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            </Box>
          </Fade>
        )}
      </Box>
      <DownTimeModal />
      <Survey />
      <PopUp
        isOpen={live}
        onClose={() => setLive(false)}
        btnMarginTop={3.8}
        disabled={userInfo.UserInfo.subscriptionStatus === 'CANCELLED'}
        overWriteClass={'svgLarge'}
        data={{
          title: 'Go live with Event',
          Icon: SvgGoLiveEventIcon,
          secondaryDesc:
            'The event would be published and accessible to the People. Are you sure you want to continue?',
          actionBtn: [
            {
              name: 'CANCEL',
              onClick: () => {
                setLive(false);
              },
            },
            {
              name: 'GO LIVE',
              type: 'primary',
              isLoading: loadingState.updatePublishEvent,

              loadingLabel: 'Updating',
              onClick: () => {
                handelClickOnPublishEvent();
              },
            },
          ],
        }}
      />
    </>
  );
};

export default Layout;
