/* eslint-disable no-unused-vars */

import { EventAddOnsMeta } from 'pages/events/constants';
import {
  getEventTypeFormat,
  getMillisInHrsAndMins,
  getStatus,
  getTimeInHrsAndMins,
} from './common-function';
import moment from 'moment';

/* eslint-disable camelcase */
export const createAttendeeData = (data) => {
  if (!data && !data.attendees && !Array.isArray(data.attendees)) return [];
  return data.attendees.map(
    ({
      _id,
      designation = '',
      email = '',
      name = '',
      session_watch_time = 0,
      certificate = 0,
      organisation = '',
      registration_time = '',
      status = '',
      userId = '',
      profilePictures,
    }) => {
      return {
        id: _id,
        name: { name, profilePictures, userId },
        email,
        designation: { designation, organisation },
        session_watch_time: { value: session_watch_time },
        certificate: { value: certificate },
        registration_time: { registration_time },
        status: { value: status },
        action: { userId, status },
        userId,
      };
    }
  );
};

export const createTeamData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    (
      {
        user_id,
        first_name = '',
        last_name = '',
        email = '',
        status = '',
        img_profile = '',
        roles,
        designation,
      },
      key
    ) => {
      return {
        id: user_id,
        first_name,
        last_name,
        email,
        designation,
        roles: { roles, key },
        status: { status, key },
        name: { name: first_name, last_name, img_profile, email, key },
      };
    }
  );
};

export const createTeamMemberData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      _id,
      designation = '',
      email = '',
      name = '',
      organisation = '',
      registration_time = '',
      status = '',
      userId = '',
      profilePictures,
    }) => {
      return {
        id: _id,
        name: { name, profilePictures, userId },
        email,
        designation: { designation, organisation },
        registration_time,
        status: { value: status },
        action: { userId, status },
        userId,
      };
    }
  );
};

export const createPeopleData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      userId,
      designation = '',
      email = '',
      name = '',
      organisation = '',
      registration_time = '',
      status = '',
      profilePictures,
    }) => {
      return {
        id: userId,
        name: { name, profilePictures, userId },
        email,
        designation: { designation, organisation },
        registration_time,
        status: { value: status },
        action: { userId, status },
      };
    }
  );
};

export const createDiscountData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name = '',
      type = '',
      valueType = '',
      value = 0,
      usagePerTicket = '',
      used = '',
      ticketsAssociated = '',
      startDateTime = '',
      endDateTime = '',
      isArchived,
      codeName = '',
    }) => {
      return {
        id,
        name: { name },
        type: { type, codeName },
        discount: { type: valueType, value },
        usage: usagePerTicket,
        used,
        ticketsAssociated: { ticketsAssociated },
        startDate: { startDate: startDateTime },
        endDate: { endDate: endDateTime },
        isArchived,
        // endDate: {endDate: endDate}
      };
    }
  );
};

export const createTicketData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name = '',
      currency_id = '',
      price = '',
      type = '',
      status = '',
      visibility = '',
      available,
      sold,
    }) => {
      return {
        id,
        name,
        type: { type },
        price: { price, currency_id },
        available,
        sold,
        status: { status },
      };
    }
  );
};

export const createConversionPromptData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({ id = '', title = '', buttonText = '', linkUrl = '', imageUrl = '', shared = false }) => {
      return {
        id: { id },
        title: {
          title,
        },
        buttonText: {
          buttonText,
        },
        linkUrl: { linkUrl },
        imageUrl: { imageUrl },
        shared: { id, shared },
        preview: { id, preview: true },
      };
    }
  );
};

export const createOrderData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      order_no = '',
      ticket_name = '',
      purchase_time_milli = '',
      attendee_first_name = '',
      attendee_last_name = '',
      attendee_total = '',
      attendee_email = '',
      status = '',
      currency_id = '',
      organiser_total,
    }) => {
      return {
        id,
        buyer: { attendee_first_name, attendee_last_name, attendee_email },
        order_no,
        ticket_name,
        timeStamp: { purchase_time_milli },
        status: { status },
        paid: { attendee_total, currency_id },
        payable: { organiser_total, currency_id },
      };
    }
  );
};

export const createV2OrderData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map((eachItem) => {
    const {
      status,
      updateTimeMilli,
      dropOffStep = '',
      orderId: value,
      invoiceSerialNum = '',
      paymentMethod = '',
      paymentProof = '',
    } = eachItem;

    return {
      ...eachItem,
      orderId: { value },
      status: { status },
      timeStamp: { updateTimeMilli },
      dropOffStep: { dropOffStep },
      invoiceSerialNum: {
        invoiceSerialNum: eachItem.invoiceSerialNum,
        orderId: value,
        timeStamp: updateTimeMilli,
      },
      paymentMethod: { value: paymentMethod },
      paymentProof: { value: paymentProof },
    };
  });
};

export const createAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name = '',
      profile_img = '',
      profileViews = '',
      chats = '',
      avgRatings = '',
      bookmarks = '',
      connectionsMade = '',
      CTAClicks = '',
      videoViews = '',
      productViews = '',
      brochureDownloads = '',
      Polls = '',
      QandA = '',
    }) => {
      return {
        id,
        virtualBooth: { name, profile_img },
        profileViews: profileViews === null ? 0 : profileViews,
        chats: chats === null ? 0 : chats,
        bookmarks: bookmarks === null ? 0 : bookmarks,
        connectionsMade: connectionsMade === null ? 0 : connectionsMade,
        avgRatings: avgRatings === null ? 0 : avgRatings,
        CTAClicks: CTAClicks === null ? 0 : CTAClicks,
        videoViews: videoViews === null ? 0 : videoViews,
        productViews: productViews === null ? 0 : productViews,
        brochureDownloads: brochureDownloads === null ? 0 : brochureDownloads,
        download: { id },
        polls: Polls || 0,
        QandA: QandA || 0,
      };
    }
  );
};

export const createV2RegAnalyticsData = (data) => {
  let resp;
  if (!data && !Array.isArray(data)) {
    resp = [];
  } else {
    resp = data.map((eachItem) => ({
      ...eachItem,
      name: { name: eachItem.name },
      revenue: { revenue: eachItem.revenue },
      refund: { refund: eachItem.refund },
      discount: { discount: eachItem.discount },
      payout: { payout: eachItem.payout },
    }));
  }
  return resp;
};

export const createSpeakerAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name = '',
      profile_img = '',
      designation = '',
      profileViews = '',
      // chats = "",
      avgRatings = '',
      bookmarks = '',
      // meetings = "",
      fileDownloads = '',
    }) => {
      return {
        id,
        speakers: { name, profile_img, designation },
        profileViews: profileViews === null ? 0 : profileViews,
        // chats: chats === null ? 0 : chats,
        // meetings: meetings === null ? 0 : meetings,
        bookmarks: bookmarks === null ? 0 : bookmarks,
        fileDownloads: fileDownloads === null ? 0 : fileDownloads,
        avgRatings: avgRatings === null ? 0 : avgRatings,
        download: { id },
      };
    }
  );
};

export const analyticsVirtualBoothHeadRows = [
  {
    id: 'virtualBooth',
    numeric: false,
    disablePadding: false,
    label: 'VIRTUAL BOOTHS',
    hasSort: false,
    valueKey: 'virtualBooth',
  },
  {
    id: 'profileViews',
    numeric: true,
    disablePadding: false,
    label: 'PROFILE VIEWS',
    hasSort: false,
    valueKey: 'profileViews',
  },
  {
    id: 'chats',
    numeric: true,
    disablePadding: false,
    label: 'CHATS',
    hasSort: false,
    valueKey: 'chats',
  },
  {
    id: 'bookmarks',
    numeric: true,
    disablePadding: false,
    label: 'BOOKMARKS',
    hasSort: false,
    valueKey: 'bookmarks',
  },
  {
    id: 'connectionsMade',
    numeric: true,
    disablePadding: false,
    label: 'CONNECTIONS MADE',
    hasSort: false,
    valueKey: 'connectionsMade',
  },
  {
    id: 'avgRatings',
    numeric: true,
    disablePadding: false,
    label: 'AVG. RATING',
    hasSort: false,
    valueKey: 'avgRatings',
  },
  {
    id: 'CTAClicks',
    numeric: true,
    disablePadding: false,
    label: 'CTA CLICKS',
    hasSort: false,
    valueKey: 'CTAClicks',
  },
  {
    id: 'videoViews',
    numeric: true,
    disablePadding: false,
    label: 'VIDEO VIEWS',
    hasSort: false,
    valueKey: 'videoViews',
  },
  {
    id: 'productViews',
    numeric: true,
    disablePadding: false,
    label: 'PRODUCT VIEWS',
    hasSort: false,
    valueKey: 'productViews',
  },
  {
    id: 'polls',
    numeric: true,
    disablePadding: false,
    label: 'POLLS',
    hasSort: false,
    valueKey: 'polls',
  },
  {
    id: 'QandA',
    numeric: true,
    disablePadding: false,
    label: 'Q&A',
    hasSort: false,
    valueKey: 'QandA',
  },
  {
    id: 'brochureDownloads',
    numeric: true,
    disablePadding: false,
    label: 'FILES DOWNLOADED',
    hasSort: false,
    valueKey: 'brochureDownloads',
  },
  {
    id: 'download',
    numeric: true,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'download',
    fixedCell: true,
    fixedPosition: 'right',
  },
];

export const analyticsSpeakerHeadRows = [
  {
    id: 'speakers',
    numeric: false,
    disablePadding: false,
    label: 'SPEAKERS',
    hasSort: false,
    valueKey: 'speakers',
  },
  {
    id: 'profileViews',
    numeric: true,
    disablePadding: false,
    label: 'PROFILE VIEWS',
    hasSort: false,
    valueKey: 'profileViews',
  },
  // { id: 'chats', numeric: true, disablePadding: false, label: 'CHATS', hasSort: false, valueKey: "chats" },
  // { id: 'meetings', numeric: true, disablePadding: false, label: 'MEETINGS', hasSort: false, valueKey: "meetings" },
  {
    id: 'bookmarks',
    numeric: true,
    disablePadding: false,
    label: 'BOOKMARKS',
    hasSort: false,
    valueKey: 'bookmarks',
  },
  {
    id: 'fileDownloads',
    numeric: true,
    disablePadding: false,
    label: 'FILES DOWNLOADED',
    hasSort: false,
    valueKey: 'fileDownloads',
  },
  {
    id: 'avgRatings',
    numeric: true,
    disablePadding: false,
    label: 'AVG. RATING',
    hasSort: false,
    valueKey: 'avgRatings',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: 'DOWNLOAD',
    hasSort: false,
    valueKey: 'download',
  },
];

export const analyticsRegistrationHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TICKET NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'sold',
    numeric: true,
    disablePadding: false,
    label: 'TICKETS SOLD',
    hasSort: false,
    valueKey: 'sold',
  },
  {
    id: 'revenue',
    numeric: false,
    disablePadding: false,
    label: 'REVENUE',
    hasSort: false,
    valueKey: 'revenue',
  },
  {
    id: 'payout',
    numeric: false,
    disablePadding: false,
    label: 'PAYOUT',
    hasSort: false,
    valueKey: 'payout',
  },
];

export const OrdersHeadRows = [
  {
    id: 'order_id',
    numeric: false,
    disablePadding: false,
    label: 'ORDER ID',
    hasSort: true,
    valueKey: 'order_no',
  },
  {
    id: 'buyer',
    numeric: false,
    disablePadding: false,
    label: 'BUYER',
    hasSort: false,
    valueKey: 'buyer',
  },
  {
    id: 'ticket_name',
    numeric: false,
    disablePadding: false,
    label: 'TICKETS',
    hasSort: true,
    valueKey: 'ticket_name',
  },
  {
    id: 'purchase_time_milli',
    numeric: false,
    disablePadding: false,
    label: 'TIMESTAMP',
    hasSort: true,
    valueKey: 'timeStamp',
  },
  // { id: 'status', numeric: false, disablePadding: false, label: 'STATUS', hasSort: true, valueKey: "status" },
  {
    id: 'paid',
    numeric: false,
    disablePadding: false,
    label: 'PAID',
    hasSort: true,
    valueKey: 'paid',
  },
  {
    id: 'payable',
    numeric: false,
    disablePadding: false,
    label: 'PAYOUT',
    hasSort: true,
    valueKey: 'payable',
  },
];

export const TicketHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TICKETS',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'TYPE',
    hasSort: false,
    valueKey: 'type',
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'PRICE',
    hasSort: false,
    valueKey: 'price',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'available',
    numeric: true,
    disablePadding: false,
    label: 'AVAILABLE TICKETS',
    hasSort: false,
    valueKey: 'available',
  },
  {
    id: 'sold',
    numeric: true,
    disablePadding: false,
    label: 'TICKETS SOLD',
    hasSort: false,
    valueKey: 'sold',
  },
];

export const DiscountHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'DISCOUNT NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'TYPE',
    hasSort: true,
    valueKey: 'type',
  },
  {
    id: 'discount',
    numeric: false,
    disablePadding: false,
    label: 'DISCOUNT',
    hasSort: true,
    valueKey: 'discount',
  },
  {
    id: 'usage',
    numeric: false,
    disablePadding: false,
    label: 'USAGE',
    hasSort: true,
    align: 'center',
    valueKey: 'usage',
  },
  {
    id: 'used',
    numeric: false,
    disablePadding: false,
    label: 'USED',
    hasSort: true,
    align: 'center',
    valueKey: 'used',
  },
  {
    id: 'ticketsAssociated',
    numeric: false,
    label: 'TICKETS ASSOCIATED',
    hasSort: true,
    align: 'center',
    valueKey: 'ticketsAssociated',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'START DATE',
    hasSort: true,
    valueKey: 'startDate',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'END DATE',
    hasSort: true,
    valueKey: 'endDate',
  },
];

export const RegisterAttendeeHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'DESIGNATION',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'registration_time',
    numeric: false,
    disablePadding: false,
    label: 'DATE & TIME',
    hasSort: true,
    valueKey: 'registration_time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'ACTIONS',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'action',
  },
];

export const EnhancedRegisterAttendeeHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'registration_time',
    numeric: false,
    disablePadding: false,
    label: 'DATE & TIME',
    hasSort: true,
    valueKey: 'registration_time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'ACTIONS',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'action',
  },
];

export const AttendeeHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'DESIGNATION',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'registration_time',
    numeric: false,
    disablePadding: false,
    label: 'JOIN DATE & TIME',
    hasSort: true,
    valueKey: 'registration_time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'session_watch_time',
    numeric: false,
    disablePadding: false,
    label: 'WATCH TIME (MINS)', // being changed from Session Watch Time to Watch Time as part of Webinar Story
    hasSort: false,
    valueKey: 'session_watch_time',
  },
  {
    id: 'certificate',
    numeric: false,
    disablePadding: false,
    label: 'CERTIFICATE',
    hasSort: false,
    valueKey: 'certificate',
  },
  {
    id: 'ACTIONS',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'action',
  },
];

export const EnhancedAttendeeHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'registration_time',
    numeric: false,
    disablePadding: false,
    label: 'JOIN DATE & TIME',
    hasSort: true,
    valueKey: 'registration_time',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'session_watch_time',
    numeric: false,
    disablePadding: false,
    label: 'WATCH TIME (MINS)', // being changed from Session Watch Time to Watch Time as part of Webinar Story
    hasSort: false,
    valueKey: 'session_watch_time',
  },
  {
    id: 'certificate',
    numeric: false,
    disablePadding: false,
    label: 'CERTIFICATE',
    hasSort: false,
    valueKey: 'certificate',
  },
  {
    id: 'ACTIONS',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'action',
  },
];

export const TeamMembersHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'DESIGNATION',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
];

export const EnhancedTeamMembersHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
    hasSort: true,
    valueKey: 'email',
  },
];

export const TeamHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: false,
    valueKey: 'name',
  },
  // { id: 'email', numeric: false, disablePadding: false, label: 'EMAIL', hasSort: false, valueKey: "email" },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    hasSort: false,
    valueKey: 'email',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'Job Title',
    hasSort: false,
    valueKey: 'designation',
  },
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Access',
    hasSort: false,
    valueKey: 'roles',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    hasSort: false,
    valueKey: 'status',
  },
];

export const PeopleHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: true,
    valueKey: 'designation',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'CONTACT',
    hasSort: true,
    valueKey: 'email',
  },
];

export const createVirtualBoothData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      exhibitor_id,
      category,
      booth_size,
      position,
      name,
      profile_img,
      email,
      phone_code,
      phone,
      fb_url,
      linked_url,
      website_url,
      twitter_url,
      whatsapp_no,
      instagram_url,
      is_premium,
    }) => {
      return {
        id: exhibitor_id,
        name: { name, profile_img, exhibitor_id, is_premium },
        email: { email, phone: phone_code && phone ? `${phone_code}-${phone}` : '' },
        booth_size,
        position,
        category: { category },
        fb_url: { fb_url, website_url, linked_url, twitter_url, instagram_url },
        exhibitor_id,
      };
    }
  );
};

export const createRoomData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ _id, name, priority, roomType, joinPermission, shareAVPermission }) => {
    return {
      id: _id,
      name,
      priority,
      roomType,
      joinPermission: { joinPermission },
      shareAVPermission: { shareAVPermission },
    };
  });
};
export const createEmbedAppData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    const {
      id,
      integration_meta_id = '',
      name = '',
      icon_id = '',
      svg_image = '',
      is_active = 0,
      integration_meta_name = '',
      embed_code = '',
      file_name = '',
    } = item;
    return {
      id,
      integration_meta_id,
      name,
      icon_id,
      svg_image,
      is_active,
      integration_meta_name,
      embed_code,
      appName: { name, svg_image },
      actions: { is_active, id, name },
      file_name,
    };
  });
};

export const VirtualBoothHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'BOOTHS',
    hasSort: true,
    valueKey: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'CONTACT',
    hasSort: false,
    valueKey: 'email',
  },
  {
    id: 'booth_size',
    numeric: false,
    disablePadding: false,
    label: 'BOOTH SIZE',
    tooltip:
      'All the booths will be visible based on Booth size on Attendee view. Large Booth would come on top, followed by Medium and then small.',
    hasSort: true,
    valueKey: 'booth_size',
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    align: 'left',
    tooltip:
      'The booths of same size are displayed based on the priority on the attendee view. the lower the priority number, the higher the booth will be displayed within the same size group.',
    label: 'PRIORITY',
    hasSort: true,
    valueKey: 'position',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'CATEGORY',
    hasSort: false,
    valueKey: 'category',
  },
  {
    id: 'fb_url',
    numeric: false,
    disablePadding: false,
    label: 'SOCIAL LINKS',
    hasSort: false,
    valueKey: 'fb_url',
  },
];

export const RoomHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'roomType',
    numeric: false,
    disablePadding: false,
    label: 'ROOM PURPOSE',
    hasSort: false,
    valueKey: 'roomType',
  },
  {
    id: 'joinPermission',
    numeric: false,
    disablePadding: false,
    label: 'who can join the room?',
    hasSort: false,
    valueKey: 'joinPermission',
  },
  {
    id: 'shareAVPermission',
    numeric: false,
    disablePadding: false,
    label: 'who can share AV in the room?',
    hasSort: false,
    valueKey: 'shareAVPermission',
  },
  {
    id: 'priority',
    numeric: true,
    disablePadding: false,
    label: 'Priority',
    hasSort: false,
    valueKey: 'priority',
  },
];

export const createMeetingData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      _id,
      //  meetingLocation,
      meetingStatus,
      meeting_time,
      message,
      requestedBy,
      requestedBy_name,
      requestedBy_profilePictures,
      target,
      target_name,
      target_profilePictures,
      location,
    }) => {
      return {
        id: _id,
        //  meetingLocation: { meetingLocation },
        meetingStatus: { meetingStatus },
        meeting_time: { meeting_time },
        requested: requestedBy_profilePictures,
        message: { message },
        requestedBy: { requestedBy, requestedBy_name, requestedBy_profilePictures },
        respondedAt: { target, target_name, target_profilePictures },
        location: { location },
      };
    }
  );
};

export const MeetingHeadRows = [
  {
    id: 'requestedBy',
    numeric: false,
    disablePadding: false,
    label: 'PARTICIPANT 1',
    hasSort: false,
    valueKey: 'requestedBy',
  },
  {
    id: 'respondedAt',
    numeric: false,
    disablePadding: false,
    label: 'PARTICIPANT 2',
    hasSort: false,
    valueKey: 'respondedAt',
  },
  {
    id: 'meeting_time',
    numeric: false,
    disablePadding: false,
    label: 'MEETING TIME',
    hasSort: true,
    valueKey: 'meeting_time',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'MEETING DESCRIPTION',
    hasSort: false,
    valueKey: 'message',
  },
  {
    id: 'meetingStatus',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: true,
    valueKey: 'meetingStatus',
  },
];

export const MeetingHeadRowsWithLocation = [
  {
    id: 'requestedBy',
    numeric: false,
    disablePadding: false,
    label: 'PARTICIPANT 1',
    hasSort: false,
    valueKey: 'requestedBy',
  },
  {
    id: 'respondedAt',
    numeric: false,
    disablePadding: false,
    label: 'PARTICIPANT 2',
    hasSort: false,
    valueKey: 'respondedAt',
  },
  {
    id: 'meeting_time',
    numeric: false,
    disablePadding: false,
    label: 'MEETING TIME',
    hasSort: true,
    valueKey: 'meeting_time',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'MEETING DESCRIPTION',
    hasSort: false,
    valueKey: 'message',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'MEETING LOCATION',
    hasSort: false,
    valueKey: 'location',
  },
  {
    id: 'meetingStatus',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: true,
    valueKey: 'meetingStatus',
  },
];

export const createNotification = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      id: item.id,
      basicInfo: { title: item.title, description: item.description },
      // timeStamp: item.notification_timestamp,
      timestamp: {
        timestamp: item.notification_time_milli,
        timezone: item.notification_timezone.name,
      },
      group: { groups: item.group_names, targetting_section_type: item.targetting_section_type },
      recipient: item.outreach,
      // clicked: 0,
      status: { status: item.message_status.name },
      link: { link: item.link },
      rest: { ...item },
    };
  });
};

export const NotificationHeadRows = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'TITLE',
    hasSort: true,
    valueKey: 'basicInfo',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'TIME STAMP',
    hasSort: false,
    valueKey: 'timestamp',
  },
  {
    id: 'link',
    numeric: false,
    disablePadding: false,
    label: 'URL',
    hasSort: false,
    valueKey: 'link',
  },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'RECIPIENTS',
    hasSort: false,
    valueKey: 'group',
  },
  //	{ id: 'recipient', numeric: true, disablePadding: false, label: 'RECIPIENT', hasSort: false, valueKey: "recipient" },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: true,
    valueKey: 'status',
  },
];

export const createSessionAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id,
      name,
      start_time_milli,
      end_time_milli,
      registrations,
      totalReplays,
      uniqueReplays,
      totalViews,
      uniqueViews,
      avgRatings,
      bookmarks,
      polls,
      pollResponses,
      chats,
      brochureDownloads,
      notes,
      QandA,
      watchPartyParticipants,
      likes,
      avgWatchTime,
      creditType,
      noOfCertificates,
      portraitsShared,
      totalCheckIns,
      raisedHandCount,
      totalEmbedReplays,
      totalUniqueEmbedReplays,
    }) => {
      return {
        // id: _id,
        name: { name, start_time_milli, id },
        // start_time_milli: { start_time_milli },
        registrations: { registrations, id },
        totalViews: { totalViews, id },
        uniqueViews: { uniqueViews, id },
        avgWatchTime: { avgWatchTime, id },
        noOfCertificates: { noOfCertificates, id },
        creditType: { creditType, id },
        totalReplays: { totalReplays, id },
        uniqueReplays: { uniqueReplays },
        likes: { likes },
        polls: { polls, id },
        avgRatings: { avgRatings, pollResponses, id },
        chats: { chats },
        notes: { notes },
        QandA: { QandA },
        watchPartyParticipants: { watchPartyParticipants, id },
        brochureDownloads: { brochureDownloads },
        download: { id, name },
        bookmarks: { bookmarks },
        portraitsShared: { portraitsShared },
        totalCheckIns: { id, totalCheckIns },
        raisedHandCount: { id, raisedHandCount },
        totalEmbedReplays: { totalEmbedReplays, id },
        totalUniqueEmbedReplays: { totalUniqueEmbedReplays, id },
        // pollResponses:pollResponses,
      };
    }
  );
};

export const createRegistrationAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ id, name, payout, revenue, sold, ticket_id }) => {
    return {
      id,
      name,
      payout: { payout },
      revenue: { revenue },
      sold,
      ticket_id: { ticket_id },
    };
  });
};

export const SessionAnalyticsHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'SESSION NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'registrations',
    numeric: false,
    disablePadding: false,
    label: 'REGISTRATIONS',
    hasSort: false,
    valueKey: 'registrations',
  },
  {
    id: 'totalViews',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL VIEWS',
    hasSort: false,
    valueKey: 'totalViews',
  },
  {
    id: 'uniqueViews',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE VIEWS',
    hasSort: false,
    valueKey: 'uniqueViews',
  },
  {
    id: 'totalCheckIns',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL CHECK-INS',
    hasSort: false,
    valueKey: 'totalCheckIns',
  },
  {
    id: 'avgWatchTime',
    numeric: false,
    disablePadding: false,
    label: 'AVG. WATCH TIME (MINS)',
    hasSort: false,
    valueKey: 'avgWatchTime',
  },
  {
    id: 'noOfCertificates',
    numeric: false,
    disablePadding: false,
    label: 'NO. OF CERTIFICATES ISSUED',
    hasSort: false,
    valueKey: 'noOfCertificates',
  },
  {
    id: 'creditType',
    numeric: false,
    disablePadding: false,
    label: 'CREDIT TYPE',
    hasSort: false,
    valueKey: 'creditType',
  },
  {
    id: 'totalReplays',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL REPLAYS',
    hasSort: false,
    valueKey: 'totalReplays',
  },
  {
    id: 'uniqueReplays',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE REPLAYS',
    hasSort: false,
    valueKey: 'uniqueReplays',
  },
  {
    id: 'likes',
    numeric: false,
    disablePadding: false,
    label: 'LIKES',
    hasSort: false,
    valueKey: 'likes',
  },
  {
    id: 'polls',
    numeric: false,
    disablePadding: false,
    label: 'POLLS',
    hasSort: false,
    valueKey: 'polls',
  },
  {
    id: 'avgRatings',
    numeric: false,
    disablePadding: false,
    label: 'AVG. RATING',
    hasSort: false,
    valueKey: 'avgRatings',
  },
  {
    id: 'raisedHandCount',
    numeric: false,
    disablePadding: false,
    label: 'RAISE HAND PARTICIPANTS',
    hasSort: false,
    valueKey: 'raisedHandCount',
  },
  {
    id: 'chats',
    numeric: false,
    disablePadding: false,
    label: 'CHATS',
    hasSort: false,
    valueKey: 'chats',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'NOTES',
    hasSort: false,
    valueKey: 'notes',
  },
  {
    id: 'QandA',
    numeric: false,
    disablePadding: false,
    label: 'Q&A',
    hasSort: false,
    valueKey: 'QandA',
  },
  {
    id: 'watchPartyParticipants',
    numeric: false,
    disablePadding: false,
    label: 'WATCH PARTY PARTICIPANTS',
    hasSort: false,
    valueKey: 'watchPartyParticipants',
  },
  {
    id: 'portraitsShared',
    numeric: false,
    disablePadding: false,
    label: 'PORTRAITS SHARED',
    hasSort: false,
    valueKey: 'portraitsShared',
  },
  {
    id: 'brochureDownloads',
    numeric: false,
    disablePadding: false,
    label: 'FILES DOWNLOADED',
    hasSort: false,
    valueKey: 'brochureDownloads',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'download',
    fixedCell: true,
    fixedPosition: 'right',
  },
];

export const SessionAnalyticsHeadRowsWithEmbedAnalytics = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'SESSION NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'registrations',
    numeric: false,
    disablePadding: false,
    label: 'REGISTRATIONS',
    hasSort: false,
    valueKey: 'registrations',
  },
  {
    id: 'totalViews',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL VIEWS',
    hasSort: false,
    valueKey: 'totalViews',
  },
  {
    id: 'uniqueViews',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE VIEWS',
    hasSort: false,
    valueKey: 'uniqueViews',
  },
  {
    id: 'totalEmbedReplays',
    numeric: false,
    disablePadding: false,
    label: 'EMBED REPLAYS',
    hasSort: false,
    valueKey: 'totalEmbedReplays',
    align: 'center',
    width: '111px',
  },
  {
    id: 'totalUniqueEmbedReplays',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE EMBED REPLAYS',
    hasSort: false,
    valueKey: 'totalUniqueEmbedReplays',
    align: 'center',
    width: '164px',
  },
  {
    id: 'totalCheckIns',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL CHECK-INS',
    hasSort: false,
    valueKey: 'totalCheckIns',
  },
  {
    id: 'avgWatchTime',
    numeric: false,
    disablePadding: false,
    label: 'AVG. WATCH TIME (MINS)',
    hasSort: false,
    valueKey: 'avgWatchTime',
  },
  {
    id: 'noOfCertificates',
    numeric: false,
    disablePadding: false,
    label: 'NO. OF CERTIFICATES ISSUED',
    hasSort: false,
    valueKey: 'noOfCertificates',
  },
  {
    id: 'creditType',
    numeric: false,
    disablePadding: false,
    label: 'CREDIT TYPE',
    hasSort: false,
    valueKey: 'creditType',
  },
  {
    id: 'totalReplays',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL REPLAYS',
    hasSort: false,
    valueKey: 'totalReplays',
  },
  {
    id: 'uniqueReplays',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE REPLAYS',
    hasSort: false,
    valueKey: 'uniqueReplays',
  },
  {
    id: 'likes',
    numeric: false,
    disablePadding: false,
    label: 'LIKES',
    hasSort: false,
    valueKey: 'likes',
  },
  {
    id: 'polls',
    numeric: false,
    disablePadding: false,
    label: 'POLLS',
    hasSort: false,
    valueKey: 'polls',
  },
  {
    id: 'avgRatings',
    numeric: false,
    disablePadding: false,
    label: 'AVG. RATING',
    hasSort: false,
    valueKey: 'avgRatings',
  },
  {
    id: 'raisedHandCount',
    numeric: false,
    disablePadding: false,
    label: 'RAISE HAND PARTICIPANTS',
    hasSort: false,
    valueKey: 'raisedHandCount',
  },
  {
    id: 'chats',
    numeric: false,
    disablePadding: false,
    label: 'CHATS',
    hasSort: false,
    valueKey: 'chats',
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: 'NOTES',
    hasSort: false,
    valueKey: 'notes',
  },
  {
    id: 'QandA',
    numeric: false,
    disablePadding: false,
    label: 'Q&A',
    hasSort: false,
    valueKey: 'QandA',
  },
  {
    id: 'watchPartyParticipants',
    numeric: false,
    disablePadding: false,
    label: 'WATCH PARTY PARTICIPANTS',
    hasSort: false,
    valueKey: 'watchPartyParticipants',
  },
  {
    id: 'portraitsShared',
    numeric: false,
    disablePadding: false,
    label: 'PORTRAITS SHARED',
    hasSort: false,
    valueKey: 'portraitsShared',
  },
  {
    id: 'brochureDownloads',
    numeric: false,
    disablePadding: false,
    label: 'FILES DOWNLOADED',
    hasSort: false,
    valueKey: 'brochureDownloads',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'download',
    fixedCell: true,
    fixedPosition: 'right',
  },
];
export const createFileLogsData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      file_name = '',
      real_file_name = '',
      unprocessed_file_name = '',
      success_records = '',
      error_records = '',
      total_records = '',
      create_time_milli = '',
      first_name = '',
      last_name = '',
      name = '',
      status = '',
    }) => {
      return {
        id,
        file_name: { real_file_name, file_name },
        event_section: { name },
        timeStamp: { create_time_milli },
        status: { status },
        results: {
          status,
          success_records,
          error_records,
          total_records,
          file_name,
          unprocessed_file_name,
        },
      };
    }
  );
};

export const createRoomSummaryAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    (
      {
        avgSessionLength,
        avgSpectatorDuration,
        raiseHand,
        roomName,
        sessionCount,
        totalCahts,
        uniqueSpectators,
        roomId,
        totalPolls = '',
        totalQandA = '',
      },
      key
    ) => {
      return {
        roomName: { roomName, key },
        sessionCount: { sessionCount, key },
        avgSessionLength: { avgSessionLength, key },
        uniqueSpectators: { uniqueSpectators, key },
        raiseHand: { raiseHand, key },
        totalCahts: { totalCahts, key },
        avgSpectatorDuration: { avgSpectatorDuration, key },
        download: { roomId, key },
        polls: totalPolls || 0,
        QandA: totalQandA || 0,
      };
    }
  );
};

export const upcomingEventsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ name: eventName, start_time_milli, data_sync_state, id }, key) => {
    return {
      id,
      eventName: { eventName, key },
      startDate: { start_time_milli, key },
      isSync: { isSync: data_sync_state === 'SYNC_ON', key },
    };
  });
};

export const fileLogsHeadRows = [
  {
    id: 'file_name',
    numeric: false,
    disablePadding: false,
    label: 'FILE',
    hasSort: false,
    valueKey: 'file_name',
  },
  {
    id: 'event_section',
    numeric: false,
    disablePadding: false,
    label: 'EVENT SECTION',
    hasSort: false,
    valueKey: 'event_section',
  },
  {
    id: 'timeStamp',
    numeric: false,
    disablePadding: false,
    label: 'TIME STAMP',
    hasSort: true,
    valueKey: 'timeStamp',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'results',
    numeric: false,
    disablePadding: false,
    label: 'RESULTS',
    hasSort: false,
    valueKey: 'results',
  },
];
export const RoomSummaryAnalyticsHeadRows = [
  {
    id: 'roomName',
    numeric: false,
    disablePadding: false,
    label: 'ROOM NAME',
    hasSort: false,
    valueKey: 'roomName',
  },
  {
    id: 'sessionCount',
    numeric: false,
    disablePadding: false,
    label: 'NUMBER OF SESSION',
    hasSort: false,
    valueKey: 'sessionCount',
  },
  {
    id: 'avgSessionLength',
    numeric: false,
    disablePadding: false,
    label: 'AVERAGE SESSION LENGTH',
    hasSort: false,
    valueKey: 'avgSessionLength',
  },
  {
    id: 'uniqueSpectators',
    numeric: false,
    disablePadding: false,
    label: 'UNIQUE SPECTATORS',
    hasSort: false,
    valueKey: 'uniqueSpectators',
  },
  {
    id: 'raiseHand',
    numeric: false,
    disablePadding: false,
    label: 'RAISE HANDS',
    hasSort: false,
    valueKey: 'raiseHand',
  },
  {
    id: 'totalCahts',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL CHATS',
    hasSort: false,
    valueKey: 'totalCahts',
  },
  {
    id: 'avgSpectatorDuration',
    numeric: false,
    disablePadding: false,
    label: 'AVERAGE SPECTATORS DURATION',
    hasSort: false,
    valueKey: 'avgSpectatorDuration',
  },
  {
    id: 'polls',
    numeric: true,
    disablePadding: false,
    label: 'POLLS',
    hasSort: false,
    valueKey: 'polls',
  },
  {
    id: 'QandA',
    numeric: true,
    disablePadding: false,
    label: 'Q&A',
    hasSort: false,
    valueKey: 'QandA',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: 'DOWNLOAD',
    hasSort: false,
    valueKey: 'download',
  },
];

export const createRoomMeetingAnalyticsData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    (
      {
        avRequestApproved,
        avRequestCount,
        chatCount,
        endMilli,
        raiseHandCount,
        roomName,
        spectatorCount,
        startMilli,
      },
      key
    ) => {
      return {
        roomName: { roomName, key },
        avRequestApproved: { avRequestApproved, key },
        chatCount: { chatCount, key },
        endMilli: { endMilli, key },
        raiseHandCount: { raiseHandCount, key },
        spectatorCount: { spectatorCount, key },
        startMilli: { startMilli, key },
        download: { key },
      };
    }
  );
};

export const RoomMeetingAnalyticsHeadRows = [
  {
    id: 'roomName',
    numeric: false,
    disablePadding: false,
    label: 'ROOM NAME',
    hasSort: false,
    valueKey: 'roomName',
  },
  {
    id: 'startMilli',
    numeric: false,
    disablePadding: false,
    label: 'SESSION START TIME',
    hasSort: false,
    valueKey: 'startMilli',
  },
  {
    id: 'endMilli',
    numeric: false,
    disablePadding: false,
    label: 'SESSION END TIME',
    hasSort: false,
    valueKey: 'endMilli',
  },
  {
    id: 'spectatorCount',
    numeric: false,
    disablePadding: false,
    label: 'TOTAL SPECTATOR',
    hasSort: false,
    valueKey: 'spectatorCount',
  },
  {
    id: 'raiseHandCount',
    numeric: false,
    disablePadding: false,
    label: 'RAISE HAND REQUEST',
    hasSort: false,
    valueKey: 'raiseHandCount',
  },
  {
    id: 'chatCount',
    numeric: false,
    disablePadding: false,
    label: 'CHATS MESSAGE',
    hasSort: false,
    valueKey: 'chatCount',
  },
  {
    id: 'avRequestApproved',
    numeric: false,
    disablePadding: false,
    label: 'AVERAGE REQUEST APPROVED',
    hasSort: false,
    valueKey: 'avRequestApproved',
  },
  {
    id: 'download',
    numeric: false,
    disablePadding: false,
    label: 'DOWNLOAD',
    hasSort: false,
    valueKey: 'download',
  },
];

export const createRecording = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      id: item.id,
      agenda_id: item.agenda_id,
      created_at_milli: { created_at_milli: item.created_at_milli },
      mux_high_url: { mux_high_url: item.mux_high_url || item?.mux_medium_url || item?.mux_low_url },
      mux_m3u8_url: { mux_m3u8_url: item.mux_m3u8_url },
      name: {
        recording_type: item.recording_type,
        mux_high_url: item.mux_high_url || item?.mux_medium_url || item?.mux_low_url,
        mux_m3u8_url: item.mux_m3u8_url,
        created_at_milli: item.created_at_milli,
        duration: item.duration,
        start_time_milli: item.start_time_milli,
        end_time_milli: item.end_time_milli,
        mux_playback_id: item.mux_playback_id,
        name: item.name,
        agenda_id: item.agenda_id,
        asset_id: item?.asset_id,
        contentHubRedirection: item?.contentHubRedirection,
      },
      recording_type: {
        recording_type: item.recording_type,
        mux_high_url: item.mux_high_url,
        mux_m3u8_url: item.mux_m3u8_url,
        created_at_milli: item.created_at_milli,
        duration: item.duration,
        start_time_milli: item.start_time_milli,
        end_time_milli: item.end_time_milli,
        mux_playback_id: item.mux_playback_id,
        name: item.name,
        agenda_id: item.agenda_id,
      },
      duration: { duration: item.duration },
    };
  });
};

export const RecordingHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: true,
    valueKey: 'name',
  },
  // { id: 'recording_type', numeric: false, disablePadding: false, label: 'Type', hasSort: false, valueKey: "recording_type" },
  {
    id: 'duration',
    numeric: false,
    disablePadding: false,
    label: 'Duration',
    hasSort: true,
    valueKey: 'duration',
  },
  {
    id: 'created_at_milli',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
    hasSort: true,
    valueKey: 'created_at_milli',
  },
];

export const createRoomRecording = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      id: item.id,
      created_at_milli: { created_at_milli: item.createdAtMilli },
      covertedFileName: { covertedFileName: item.covertedFileName },
      originalFileName: { originalFileName: item.originalFileName },
      name: {
        // recording_type: item.recording_type, mux_high_url: item.mux_high_url,
        recording_type: 'Room',
        room_id: item._id,
        roomChannelId: item.roomChannelId,
        rooms_type: item.rooms_type,
        covertedFileName: item.covertedFileName,
        originalFileName: item.originalFileName,
        created_at_milli: item.createdAtMilli,
        duration: item.duration,
        start_time_milli: item.rooms_start_mill,
        end_time_milli: item.rooms_end_mill,
        name: item.name,
        additional_recording_links: item?.additional_recording_links,
      },
      recording_type: {
        recording_type: 'Room',
        room_id: item._id,
        roomChannelId: item.roomChannelId,
        rooms_type: item.rooms_type,
        covertedFileName: item.covertedFileName,
        originalFileName: item.originalFileName,
        created_at_milli: item.createdAtMilli,
        duration: item.duration,
        start_time_milli: item.rooms_start_mill,
        end_time_milli: item.rooms_end_mill,
        name: item.name,
      },
      duration: { duration: item.duration },
    };
  });
};

export const createEmailList = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      id: item?.campaignId,
      campaignName: {
        id: item?.campaignId,
        campaignName: item?.campaignName,
        campaignType: item?.campaignType,
        campaignSubject: item?.campaignSubject,
        campaignStatus: item?.campaignStatus,
        subCategory: item?.subCategory,
        reminders: item?.reminders || [],
      },
      // campaignType: { campaignType: item.campaignType },
      // campaignSubject: { campaignSubject: item.campaignSubject },
      campaignStatus: {
        campaignStatus: item?.campaignStatus,
        campaignType: item?.campaignType,
        campaignName: item?.campaignName,
      },
      campaignSendTo: {
        campaignSendTo: item?.campaignSendTo,
        hasPeople: item?.hasPeople,
        campaignType: item?.campaignType,
      },
      campaignEventState: { campaignEventState: item?.campaignEventState },
      shouldViewReport: {
        shouldViewReport: item?.shouldViewReport,
        id: item?.campaignId,
        campaignStatus: item?.campaignStatus,
        campaignType: item?.campaignType,
        campaignName: item?.campaignName,
      },
      isToggleOn: {
        isToggleOn: !!(item?.campaignStatus === 'ACTIVE' || item?.campaignStatus === 'SCHEDULED'),
        id: item?.campaignId,
        templateHtml: item?.templateHtml,
        campaignStatus: item?.campaignStatus,
        campaignType: item?.campaignType,
        campaignName: item?.campaignName,
        templateId: item?.templateId,
      },
      templateHtml: { templateHtml: item?.templateHtml },
    };
  });
};

export const emailListHeadRows = [
  {
    id: 'campaignName',
    numeric: false,
    disablePadding: false,
    label: 'Emails',
    hasSort: false,
    valueKey: 'campaignName',
  },
  {
    id: 'campaignStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    hasSort: false,
    valueKey: 'campaignStatus',
  },
  {
    id: 'campaignSendTo',
    numeric: false,
    disablePadding: false,
    label: 'Sent To',
    hasSort: false,
    valueKey: 'campaignSendTo',
  },
  {
    id: 'campaignEventState',
    numeric: false,
    disablePadding: false,
    label: 'Event State',
    hasSort: false,
    valueKey: 'campaignEventState',
  },
  {
    id: 'shouldViewReport',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'shouldViewReport',
  },
  {
    id: 'isToggleOn',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'isToggleOn',
  },
];

export const organiserContactDetails = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id,
      attendeeName,
      profilePictures,
      attendeeEmail,
      designation,
      company,
      events,
      revenue,
      socialLinks,
    }) => {
      return {
        id,
        name: { attendeeName, profilePictures, id, attendeeEmail },
        designation: { designation, company },
        events,
        revenue: { revenue },
        social: socialLinks,
      };
    }
  );
};

export const createSurveyData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ questionCount, surveyStatus, resposneCount, _id, surveyName, surveyType }) => {
    return {
      id: _id,
      surveyName,
      questionCount,
      surveyStatus: { surveyStatus },
      resposneCount: { resposneCount, _id, surveyName },
      surveyType: { surveyType },
    };
  });
};

export const OrgainserContactHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: false,
    valueKey: 'designation',
  },
  {
    id: 'events',
    numeric: true,
    disablePadding: false,
    label: 'EVENTS',
    hasSort: false,
    valueKey: 'events',
  },
  {
    id: 'revenue',
    numeric: true,
    disablePadding: false,
    label: 'REVENUE',
    hasSort: false,
    valueKey: 'revenue',
  },
  {
    id: 'social',
    numeric: false,
    disablePadding: false,
    label: 'SOCIAL LINKS',
    hasSort: false,
    valueKey: 'social',
  },
];

export const EmbedAppsHeadRows = [
  {
    id: 'appName',
    numeric: false,
    disablePadding: false,
    label: 'App Name',
    hasSort: false,
    valueKey: 'appName',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
    hasSort: false,
    valueKey: 'integration_meta_name',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'actions',
  },
];

export const SurveyHeadRows = [
  {
    id: 'surveyName',
    numeric: false,
    disablePadding: false,
    label: 'SURVEY NAME',
    hasSort: true,
    valueKey: 'surveyName',
  },
  {
    id: 'surveyType',
    numeric: false,
    disablePadding: false,
    label: 'SURVEY TYPE',
    hasSort: true,
    valueKey: 'surveyType',
  },
  {
    id: 'questionCount',
    numeric: true,
    disablePadding: false,
    label: 'QUESTIONS',
    hasSort: true,
    valueKey: 'questionCount',
  },
  {
    id: 'resposneCount',
    numeric: true,
    disablePadding: false,
    label: 'RESPONSES',
    hasSort: true,
    valueKey: 'resposneCount',
  },
  {
    id: 'surveyStatus',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'surveyStatus',
  },
];

export const overviewAnalyticsHeadRows = [
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Event Name',
    hasSort: false,
    valueKey: 'eventName',
  },
  {
    id: 'eventFormat',
    numeric: false,
    disablePadding: false,
    label: 'Event Format',
    hasSort: false,
    valueKey: 'eventFormat',
  },
  {
    id: 'attendees',
    numeric: false,
    disablePadding: false,
    label: 'Attendees',
    hasSort: false,
    valueKey: 'attendees',
  },
  {
    id: 'registration',
    numeric: false,
    disablePadding: false,
    label: 'Registration',
    hasSort: false,
    valueKey: 'registration',
  },
  {
    id: 'loggedInPeople',
    numeric: false,
    disablePadding: false,
    label: 'Logged in people',
    hasSort: false,
    valueKey: 'loggedInPeople',
  },
  {
    id: 'checkedInAttendee',
    numeric: false,
    disablePadding: false,
    label: 'Checked-in People',
    hasSort: false,
    valueKey: 'checkedInAttendee',
  },
  // { id: 'avgTimeSpentMilli', numeric: false, disablePadding: false, label: 'Avg time spent(HH:MM)', hasSort: false, valueKey: "avgTimeSpentMilli" },
];

export const createOverviewData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      eventName: { id: item.id, eventName: item.eventName },
      attendees: { id: item.id, attendees: item.totalAttendee },
      registration: { id: item.id, registration: item.registration },
      loggedInPeople: { id: item.id, loggedInPeople: item.loggedInAttendee },
      checkedInAttendee: { id: item.id, checkedInAttendee: item.checkedInAttendee },
      eventFormat: {
        id: item.id,
        eventFormat: getEventTypeFormat(item.eventFormat?.toUpperCase()),
      },
    };
  });
};

export const RoomRecordingHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: true,
    valueKey: 'name',
  },
  // { id: 'recording_type', numeric: false, disablePadding: false, label: 'Type', hasSort: false, valueKey: "recording_type" },
  // { id: 'duration', numeric: false, disablePadding: false, label: 'Duration', hasSort: true, valueKey: "duration" },
  {
    id: 'created_at_milli',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
    hasSort: true,
    valueKey: 'created_at_milli',
  },
];

export const createStandingData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ _id, avg_response_time, correct_response, user }) => {
    return {
      id: _id,
      avg_response_time: { avg_response_time },
      correct_response: { correct_response },
      user: { user },
    };
  });
};

export const StandingsHeadRows = [
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: true,
    valueKey: 'user',
  },
  {
    id: 'correct_response ',
    numeric: false,
    disablePadding: false,
    label: 'CORRECT RESPONSES',
    hasSort: true,
    valueKey: 'correct_response',
  },
  {
    id: 'avg_response_time',
    numeric: false,
    disablePadding: false,
    label: 'AVG. RESPONSE TIME',
    hasSort: true,
    valueKey: 'avg_response_time',
  },
];

export const brandedSpaceHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'DESIGNATION',
    hasSort: false,
    valueKey: 'designation',
  },
  {
    id: 'section',
    numeric: false,
    disablePadding: false,
    label: 'SECTION',
    hasSort: false,
    valueKey: 'section',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'TIMESTAMP',
    hasSort: false,
    valueKey: 'timestamp',
  },
  {
    id: 'social_links',
    numeric: false,
    disablePadding: false,
    label: 'SOCIAL LINKS',
    hasSort: false,
    valueKey: 'social',
  },
];

export const enhancedBrandedSpaceHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'JOB TITLE',
    hasSort: false,
    valueKey: 'designation',
  },
  {
    id: 'section',
    numeric: false,
    disablePadding: false,
    label: 'SECTION',
    hasSort: false,
    valueKey: 'section',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'TIMESTAMP',
    hasSort: false,
    valueKey: 'timestamp',
  },
  {
    id: 'social_links',
    numeric: false,
    disablePadding: false,
    label: 'SOCIAL LINKS',
    hasSort: false,
    valueKey: 'social',
  },
];

export const createBrandedSpaceData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      first_name = '',
      last_name = '',
      email = '',
      profilePictures = {},
      designation = '',
      organisation_name = '',
      section = '',
      createdAt = '',
      website = '',
      facebook_url = '',
      linkdin_url = '',
      twitter_url = '',
      instagramLink = '',
    }) => {
      return {
        id,
        name: { first_name, last_name, profilePictures, email },
        designation: { designation, organisation_name },
        section: { section },
        timestamp: { createdAt },
        social: { website, facebook_url, linkdin_url, twitter_url, instagramLink },
      };
    }
  );
};
export const createRevenueData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      eventName: { id: item.id, eventName: item.eventName },
      tickets: { id: item.id, tickets: item.tickets },
      ticketsSold: { id: item.id, ticketsSold: item.ticketsSold },
      revenueMadeUSD: { id: item.id, revenueMadeUSD: item.revenueMadeUSD, unicode: item.unicode },
      payoutUSD: { id: item.id, payoutUSD: item.payoutUSD, unicode: item.unicode },
    };
  });
};

export const revenueAnalyticsHeadRows = [
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Event Name',
    hasSort: false,
    valueKey: 'eventName',
  },
  {
    id: 'tickets',
    numeric: false,
    disablePadding: false,
    label: 'TICKETS',
    hasSort: false,
    valueKey: 'tickets',
  },
  {
    id: 'ticketsSold',
    numeric: false,
    disablePadding: false,
    label: 'TICKETS SOLD',
    hasSort: false,
    valueKey: 'ticketsSold',
  },
  {
    id: 'revenueMadeUSD',
    numeric: false,
    disablePadding: false,
    label: 'Revenue Made',
    hasSort: false,
    valueKey: 'revenueMadeUSD',
  },
  {
    id: 'payoutUSD',
    numeric: false,
    disablePadding: false,
    label: 'PAYOUT',
    hasSort: false,
    valueKey: 'payoutUSD',
  },
];

export const recentEventsDetails = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({ ticket_id, ticketName, eventName, start_time_milli, ticketType, paidAmount, unicode }) => {
      return {
        id: ticket_id,
        eventName: { eventName, start_time_milli },
        ticketName: { ticketName },
        ticketType: { ticketType },
        paidAmount: { paidAmount, unicode },
      };
    }
  );
};

export const RecentEventsHeadRows = [
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Event Name',
    hasSort: false,
    valueKey: 'eventName',
  },
  {
    id: 'ticketName',
    numeric: false,
    disablePadding: false,
    label: 'Ticket Name',
    hasSort: false,
    valueKey: 'ticketName',
  },
  {
    id: 'ticketType',
    numeric: false,
    disablePadding: false,
    label: 'Ticket Type',
    hasSort: false,
    valueKey: 'ticketType',
  },
  {
    id: 'paidAmount',
    numeric: false,
    disablePadding: false,
    label: 'Paid Amount',
    hasSort: false,
    valueKey: 'paidAmount',
  },
];

export const V2_TABLE_HEAD_ROWS = {
  PENDING: [
    {
      id: 'orderId',
      numeric: false,
      disablePadding: false,
      label: 'ORDER ID',
      hasSort: true,
      valueKey: 'orderId',
      align: 'left',
    },
    {
      id: 'buyer',
      numeric: false,
      disablePadding: false,
      label: 'BUYER',
      hasSort: false,
      valueKey: 'buyer',
    },
    {
      id: 'payable',
      numeric: false,
      disablePadding: false,
      label: 'PAYOUT',
      hasSort: true,
      valueKey: 'payable',
    },
    {
      id: 'paymentDetails',
      numeric: false,
      disablePadding: false,
      label: 'PAYMENT DETAILS',
      hasSort: false,
      valueKey: 'paymentDetails',
    },
    {
      id: 'paymentMethod',
      numeric: false,
      disablePadding: false,
      label: 'PAYMENT METHOD',
      hasSort: false,
      valueKey: 'paymentMethod',
    },
    {
      id: 'ticket_name',
      numeric: false,
      disablePadding: false,
      label: 'TICKETS',
      hasSort: false,
      valueKey: 'ticketInfo',
    },
    {
      id: 'purchaseTimeMilli',
      numeric: false,
      disablePadding: false,
      label: 'TIMESTAMP',
      hasSort: true,
      valueKey: 'timeStamp',
    },
    {
      id: 'discount',
      numeric: false,
      disablePadding: false,
      label: 'DISCOUNT',
      hasSort: false,
      valueKey: 'discount',
    },
    {
      id: 'paymentProof',
      numeric: false,
      disablePadding: false,
      label: 'PAYMENT SCREENSHOT',
      hasSort: false,
      valueKey: 'paymentProof',
    },
    {
      id: 'approvalStatus',
      numeric: false,
      disablePadding: false,
      label: 'APPROVAL STATUS',
      hasSort: false,
      valueKey: 'status',
    },
  ],
  INCOMPLETE: [
    {
      id: 'buyer',
      numeric: false,
      disablePadding: false,
      label: 'BUYER',
      hasSort: false,
      valueKey: 'buyer',
    },
    {
      id: 'ticket_name',
      numeric: false,
      disablePadding: false,
      label: 'TICKETS',
      hasSort: false,
      valueKey: 'ticketInfo',
    },
    {
      id: 'purchaseTimeMilli',
      numeric: false,
      disablePadding: false,
      label: 'LAST UPDATED',
      hasSort: true,
      valueKey: 'timeStamp',
    },
    {
      id: 'dropOffStep',
      numeric: false,
      disablePadding: false,
      label: 'DROP OFF STEP',
      hasSort: false,
      valueKey: 'dropOffStep',
    },
  ],
  COMPLETE: [
    {
      id: 'orderId',
      numeric: false,
      disablePadding: false,
      label: 'ORDER ID',
      hasSort: true,
      valueKey: 'orderId',
      align: 'left',
    },
    {
      id: 'buyer',
      numeric: false,
      disablePadding: false,
      label: 'BUYER',
      hasSort: false,
      valueKey: 'buyer',
    },
    {
      id: 'ticket_name',
      numeric: false,
      disablePadding: false,
      label: 'TICKETS',
      hasSort: false,
      valueKey: 'ticketInfo',
    },
    {
      id: 'invoiceNo',
      numeric: false,
      disablePadding: false,
      label: 'INVOICE NO',
      hasSort: false,
      valueKey: 'invoiceSerialNum',
    },
    {
      id: 'purchaseTimeMilli',
      numeric: false,
      disablePadding: false,
      label: 'TIMESTAMP',
      hasSort: true,
      valueKey: 'timeStamp',
    },
    {
      id: 'paid',
      numeric: false,
      disablePadding: false,
      label: 'PAID',
      hasSort: true,
      valueKey: 'paid',
    },
    {
      id: 'discount',
      numeric: false,
      disablePadding: false,
      label: 'DISCOUNT',
      hasSort: true,
      valueKey: 'discount',
    },
    {
      id: 'payable',
      numeric: false,
      disablePadding: false,
      label: 'PAYOUT',
      hasSort: true,
      valueKey: 'payable',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'STATUS',
      hasSort: false,
      valueKey: 'status',
    },
  ],
  ANALYTICS: {
    REG_TICKETS: [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'TICKET NAME',
        hasSort: false,
        valueKey: 'name',
      },
      {
        id: 'available',
        numeric: false,
        disablePadding: false,
        label: 'NO. OF TICKETS',
        hasSort: false,
        valueKey: 'available',
        align: 'left',
      },
      {
        id: 'sold',
        numeric: true,
        disablePadding: true,
        label: 'SOLD',
        hasSort: false,
        valueKey: 'sold',
        align: 'left',
      },
      {
        id: 'revenue',
        numeric: false,
        disablePadding: false,
        label: 'REVENUE',
        hasSort: false,
        valueKey: 'revenue',
        align: 'left',
      },
      {
        id: 'refund',
        numeric: false,
        disablePadding: false,
        label: 'REFUND',
        hasSort: false,
        valueKey: 'refund',
        align: 'left',
      },
      {
        id: 'discount',
        numeric: false,
        disablePadding: false,
        label: 'DISCOUNT',
        hasSort: false,
        valueKey: 'discount',
        align: 'left',
      },
      {
        id: 'discountsAvailable',
        numeric: false,
        disablePadding: false,
        label: 'NO. OF DISCOUNTS',
        hasSort: false,
        valueKey: 'discountsAvailable',
        align: 'left',
      },
      {
        id: 'discountsUsed',
        numeric: false,
        disablePadding: false,
        label: 'NO. OF DISCOUNTS\nUSED',
        hasSort: false,
        valueKey: 'discountsUsed',
        align: 'left',
      },
      {
        id: 'payout',
        numeric: false,
        disablePadding: false,
        label: 'PAYOUT',
        hasSort: false,
        valueKey: 'payout',
        align: 'left',
      },
    ],
  },
};

export const createTicketDataForV2 = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name = '',
      currencySymbol = '',
      group_name = '',
      price = '',
      revenue = '',
      status = '',
      visibility = '',
      available = '',
      sold,
      start_date,
      end_date,
    }) => {
      const ticketValue = price > 0 && sold > 0 ? price * sold : 0;
      return {
        id,
        name: { name },
        group: { group: group_name },
        price: { price, currency: currencySymbol },
        status: { status },
        sold: { sold, available },
        revenue: { revenue: ticketValue, currency: currencySymbol },
        start_date: { start_date },
        end_date: { end_date },
        visibility: { visibility, ticketId: id },
        ticketId: { value: id },
      };
    }
  );
};

export const TicketHeadRowsForV2 = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'TICKET ID',
    hasSort: false,
    valueKey: 'ticketId',
    align: 'left',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'TICKETS',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'GROUP',
    hasSort: false,
    valueKey: 'group',
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'PRICE',
    hasSort: false,
    valueKey: 'price',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'sold',
    numeric: false,
    disablePadding: false,
    label: 'SOLD',
    hasSort: false,
    valueKey: 'sold',
  },
  {
    id: 'revenue',
    numeric: false,
    disablePadding: false,
    label: 'TICKET VALUE',
    hasSort: false,
    valueKey: 'revenue',
    align: 'left',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
    label: 'START DATE',
    hasSort: false,
    valueKey: 'start_date',
  },
  {
    id: 'end_date',
    numeric: false,
    disablePadding: false,
    label: 'END DATE',
    hasSort: false,
    valueKey: 'end_date',
  },
  {
    id: 'visibility',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    valueKey: 'visibility',
  },
];

export const CampaignReportHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Recipient',
    hasSort: false,
    valueKey: 'name',
  },
  {
    id: 'sentMilli',
    numeric: false,
    disablePadding: false,
    label: 'Date & Time',
    hasSort: false,
    valueKey: 'sentMilli',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    hasSort: false,
    valueKey: 'status',
  },
  {
    id: 'remarks',
    numeric: false,
    disablePadding: false,
    label: 'Remarks',
    hasSort: false,
    valueKey: 'remarks',
  },
  {
    id: 'opened',
    numeric: false,
    disablePadding: false,
    label: 'Opens',
    hasSort: false,
    valueKey: 'opened',
  },
  {
    id: 'clicked',
    numeric: false,
    disablePadding: false,
    label: 'Clicks',
    hasSort: false,
    valueKey: 'clicked',
  },
  // {
  //   id: 'ctaClicked',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'CTA Clicks',
  //   hasSort: false,
  //   valueKey: 'ctaClicked',
  // },
];

export const createCampaignReportData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id,
      RecipientName,
      EmailTo,
      status,
      SentOnMilli,
      remarks,
      opened,
      clicked,
      ctaClicked,
      MesageID,
    }) => {
      return {
        id,
        MesageID,
        name: { name: RecipientName, email: EmailTo, id },
        sentMilli: { sentMilli: SentOnMilli, id },
        status: { statusName: getStatus(status).name, statusType: getStatus(status).type, id },
        remarks: { remarks, id },
        opened: { opened, id },
        clicked: { clicked, id },
        ctaClicked: { ctaClicked, id },
      };
    }
  );
};

export const QuizHeaderRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'QUIZ',
    hasSort: false,
    valueKey: 'name',
    align: 'left',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'QUIZ TYPE',
    hasSort: false,
    valueKey: 'type',
  },
  {
    id: 'questionCount',
    disablePadding: false,
    numeric: true,
    label: 'QUESTIONS',
    hasSort: false,
    align: 'left',
    valueKey: 'questionCount',
  },
  {
    id: 'responseCount',
    disablePadding: false,
    label: 'RESPONSES',
    hasSort: false,
    align: 'center',
    valueKey: 'responseCount',
  },
  {
    id: 'visibility',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    hasSort: false,
    valueKey: 'visibility',
  },
];

export const createQuizData = (data) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map(
    ({
      id = '',
      name,
      type,
      questionCount,
      responseCount,
      visibility,
      creditEnabledQuiz,
      visibleGroups,
      ctaLbl,
      fillTime,
      sessionIds,
    }) => {
      return {
        name,
        type: { type },
        questionCount,
        responseCount: { responseCount, name, id },
        visibility: { id, name, type, visibility, visibleGroups, ctaLbl, fillTime, sessionIds },
        id,
        left: 'left',
        creditEnabledQuiz,
      };
    }
  );
};

export const ConversionPromptHeader = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
    hasSort: false,
    align: 'left',
    valueKey: 'title',
  },
  {
    id: 'buttonText',
    disablePadding: false,
    numeric: false,
    label: 'Button Text',
    hasSort: false,
    align: 'left',
    valueKey: 'buttonText',
  },
  {
    id: 'linkUrl',
    disablePadding: false,
    numeric: false,
    label: 'Url',
    hasSort: false,
    align: 'left',
    valueKey: 'linkUrl',
  },
  {
    id: 'shared',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    align: 'left',
    valueKey: 'shared',
  },
  {
    id: 'preview',
    numeric: false,
    disablePadding: false,
    label: '',
    hasSort: false,
    align: 'left',
    valueKey: 'preview',
  },
];

export const AddOnHistoryRow = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: false,
    valueKey: 'type',
    align: 'center',
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'Unit',
    hasSort: false,
    valueKey: 'unit',
    align: 'center',
  },
  {
    id: 'isEventAddOn',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    hasSort: false,
    valueKey: 'isEventAddOn',
    align: 'center',
  },
  {
    id: 'purchasedOn',
    disablePadding: false,
    numeric: false,
    label: 'Purchased on',
    hasSort: false,
    align: 'center',
    valueKey: 'purchasedOn',
  },
];

export const createAddOnHistoryData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ addOnCreds, qty, purchasedOn, addOnType, isEventAddOn }) => {
    return {
      type: { credits: addOnCreds, unit: qty, type: addOnType },
      unit: { unit: qty, credits: addOnCreds, type: addOnType },
      purchasedOn: { purchasedOn },
      isEventAddOn: { addOnType: isEventAddOn ? 'Event' : 'Organiser' },
    };
  });
};

export const AddOnConsumedRow = [
  {
    id: 'addOnType',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: false,
    valueKey: 'addOnType',
    align: 'center',
  },
  {
    id: 'eventName',
    numeric: false,
    disablePadding: false,
    label: 'Consumed at',
    hasSort: false,
    valueKey: 'eventName',
    align: 'center',
  },
  {
    id: 'consumedOn',
    disablePadding: false,
    numeric: false,
    label: 'Consumed On',
    hasSort: false,
    align: 'center',
    valueKey: 'consumedOn',
  },
];

export const createAddOnConsumedData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data?.map(({ addOnType, eventName, consumedOn, isReversal }) => {
    return {
      addOnType: { label: EventAddOnsMeta[addOnType], isReversal },
      eventName: { eventName },
      consumedOn: { consumedOn },
    };
  });
};

export const SupportHistoryUsageRow = [
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    hasSort: false,
    valueKey: 'date',
    align: 'center',
  },
  {
    id: 'from',
    numeric: false,
    disablePadding: false,
    label: 'From',
    hasSort: false,
    valueKey: 'from',
    align: 'center',
  },
  {
    id: 'to',
    disablePadding: false,
    numeric: false,
    label: 'To',
    hasSort: false,
    align: 'center',
    valueKey: 'to',
  },
  {
    id: 'duration',
    numeric: false,
    disablePadding: false,
    label: 'Meeting duration',
    hasSort: false,
    valueKey: 'duration',
    align: 'center',
  },
];

export const createSupportUsageData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data?.map(({ id, meetingEndMilli, meetingStartMilli, timezone }) => {
    return {
      date: { date: moment(meetingStartMilli).format('D MMMM, YYYY') },
      from: { from: moment(meetingStartMilli).format(`hh:mm A [${timezone || 'UTC'}]`) },
      to: { to: moment(meetingEndMilli).format(`hh:mm A [${timezone || 'UTC'}]`) },
      duration: {
        duration: `${getMillisInHrsAndMins(meetingEndMilli - meetingStartMilli)}`,
      },
    };
  });
};


export const UTMHeader = [
  {
    id: 'source',
    numeric: false,
    disablePadding: false,
    label: 'Source',
    hasSort: false,
    align: 'left',
    valueKey: 'source',
  },
  {
    id: 'campaign',
    numeric: false,
    disablePadding: false,
    label: 'Campaign',
    hasSort: false,
    align: 'left',
    valueKey: 'campaign',
  },
  {
    id: 'medium',
    disablePadding: false,
    numeric: false,
    label: 'Medium',
    hasSort: false,
    align: 'left',
    valueKey: 'medium',
  },
  {
    id: 'code',
    disablePadding: false,
    numeric: false,
    label: 'UTM Code',
    hasSort: false,
    align: 'left',
    valueKey: 'code',
  },
  {
    id: 'registeredUserCount',
    disablePadding: false,
    numeric: false,
    label: 'Registrations',
    hasSort: false,
    align: 'left',
    valueKey: 'registeredUserCount',
  },
];

export const createUTMData = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data?.map(({ id = '', source, campaign, registeredUserCount, medium, code, isDisabled }) => {
    return {
      source: { source, id, isDisabled },
      campaign: { campaign, id, isDisabled },
      medium: { medium, id, isDisabled },
      code: { code, id, isDisabled },
      registeredUserCount,
      id,
      left: 'left',
      isDisabled,
    };
  });
};


export const upcomingEventsHeadRows = [
  {
    id: 'event',
    numeric: false,
    disablePadding: false,
    label: 'EVENT',
    hasSort: false,
    valueKey: 'eventName',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'START DATE',
    hasSort: false,
    valueKey: 'startDate',
  },
  {
    id: 'isSync',
    numeric: false,
    disablePadding: false,
    label: 'SYNC',
    hasSort: false,
    valueKey: 'isSync',
  },
];