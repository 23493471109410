import {
  ADD_INT_APP_LIST,
  ADD_UPDATE_ICON_LIST,
  EMBED_APP_UPDATE,
  EMBED_APP_UPDATE_STATUS,
  ENABLE_DISABLE_EMBED_APP,
  EVENT_ADD_CUSTOM_APP,
  EVENT_CUSTOM_APP_INT_LIST,
  GET_SESSION_ENGAGEMENT_LIST,
  REARRANGE_SESSION_ENGAGEMENT_LIST,
  UPDATE_SESSION_ENGAGEMENT_STATUS,
  UPDATE_SESSION_ENGAGEMENT,
  ADD_SESSION_ENGAGEMENT,
  UPDATE_ROOM_REACTION_ENABLE,
  ENABLE_FOR_IN_PLACE_SESSION,
} from 'api-setup/api-endpoints';
import { genericAPICall, genericAPIErrorHandler } from 'api-setup/api-helper';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';
import { loadingAction, toggleEmbedAppSidePanel } from 'redux/actions/creators';
import { setNewMessage } from 'redux/actions/setting-actions';
import { actionTypes } from 'redux/actions/types';

const {
  EVENT_INT_APP_LOADING,
  EVENT_INT_APP_SUCCESS,
  AVAILABLE_INT_APP_LIST_SUCCESS,
  AVAILABLE_INT_APP_LIST_LOADING,
  ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING,
  ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS,
  EVENT_ADD_APP_LOADING,
  INIT_ENGAGEMENT_LIST_LOADING,
  GET_SESSION_ENGAGEMENT_LIST_DATA,
  REARRANGE_SESSION_ENGAGEMENT_LIST_DATA,
  IS_ENGAGEMENT_REARRANGE_LIST_LOADING,
  IS_ENGAGEMENT_STATUS_LOADING,
  UPDATE_SESSION_ENGAGEMENT_STATUS_DATA,
  UPDATE_SESSION_ENGAGEMENT_DATA,
  IS_ENGAGEMENT_UPDATE_LOADING,
  ADD_SESSION_ENGAGEMENT_DATA,
  IS_ENGAGEMENT_SAVE_LOADING,
  VERIFY_ENABLED_EMBED_APP,
  IS_SESSION_ENGAGEMENT_REACTIONS_LOADING,
  UPDATE_SESSION_ENGAGEMENT_REACTIONS,
  IS_ROOM_REACTIONS_LOADING,
  UPDATE_ROOM_REACTIONS_ENABLE_DATA,
  IS_TYPE_LOADING,
  IS_TYPE_DATA,
  IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING,
  UPDATE_SESSION_ENGAGEMENT_RAISE_HAND,
} = actionTypes;

export const enableDisableEmbedAppAsync = (userData) => async (dispatch) => {
  try {
    // dispatch(loadingAction(true));
    const { status, data = {} } = await genericAPICall(ENABLE_DISABLE_EMBED_APP, 'POST', userData);
    if (status === 200) {
      if (data.status === 200) {
        commonTrackEvent('enable embed apps', {});
        dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    // dispatch(loadingAction(false));
  }
};

export const embedAppUpdateStatus = (embedAppData, eventId, onFail) => async (dispatch) => {
  try {
    // dispatch(loadingAction(true));
    const { status, data = {} } = await genericAPICall(
      EMBED_APP_UPDATE_STATUS,
      'POST',
      embedAppData,
      undefined,
      { eventId }
    );
    if (status === 200) {
      if (data.status === 200) {
        commonTrackEvent('toggle event app', {
          status: embedAppData && embedAppData.data.is_active ? 'on' : 'off',
          'app name': embedAppData && embedAppData.data.name,
        });
        dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
        if (typeof onFail === 'function') onFail();
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
    if (typeof onFail === 'function') onFail();
  } finally {
    // dispatch(loadingAction(false));
  }
};

export const embedAppUpdateAsync = (embedAppData, eventId, onSuccess) => async (dispatch) => {
  try {
    // dispatch(loadingAction(true));
    const { status, data = {} } = await genericAPICall(
      EMBED_APP_UPDATE,
      'POST',
      embedAppData,
      undefined,
      { eventId }
    );
    if (status === 200) {
      if (data.status === 200) {
        dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        if (onSuccess) onSuccess(data.data);
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    // dispatch(loadingAction(false));
  }
};

export const eventCustomAppIntegrationListAsync = (params, headers) => async (dispatch) => {
  try {
    dispatch(loadingAction(EVENT_INT_APP_LOADING, true));
    const { status, data = {} } = await genericAPICall(
      EVENT_CUSTOM_APP_INT_LIST,
      'POST',
      {}, // TODO : here is data in body
      params,
      headers // TODO : Extra header that you want to append in headers
    );
    if (status === 200) {
      if (data.status === 200) {
        dispatch({ type: EVENT_INT_APP_SUCCESS, payload: data.data });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch(loadingAction(EVENT_INT_APP_LOADING, false));
  }
};

export const availableAppsForIntegrationAsync =
  (isEvent = true, params) =>
  async (dispatch) => {
    try {
      dispatch(loadingAction(AVAILABLE_INT_APP_LIST_LOADING, true));
      const { status, data = {} } = await genericAPICall(
        isEvent ? ADD_INT_APP_LIST : ADD_INT_APP_LIST, // TODO : isEvent false for session
        'GET',
        {},
        params
      );
      if (status === 200) {
        if (data.status === 200) {
          dispatch({ type: AVAILABLE_INT_APP_LIST_SUCCESS, payload: data.data });
        } else {
          dispatch(genericAPIErrorHandler(false, data.message, dispatch));
        }
      }
    } catch (err) {
      dispatch(genericAPIErrorHandler(true, err, dispatch));
    } finally {
      dispatch(loadingAction(AVAILABLE_INT_APP_LIST_LOADING, false));
    }
  };
export const addUpdateEmbedAppIconListAsync = (params) => async (dispatch) => {
  try {
    dispatch(loadingAction(ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING, true));
    const { status, data = {} } = await genericAPICall(ADD_UPDATE_ICON_LIST, 'GET', {}, params);
    if (status === 200) {
      if (data.status === 200) {
        dispatch({ type: ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS, payload: data.data });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch(loadingAction(ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING, false));
  }
};

export const addEmbedAppToEventAsync = (addData, headers, cb) => async (dispatch) => {
  try {
    dispatch(loadingAction(EVENT_ADD_APP_LOADING, true));
    const { status, data = {} } = await genericAPICall(
      EVENT_ADD_CUSTOM_APP,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        dispatch(toggleEmbedAppSidePanel({ isShow: false, data: {} }));
        dispatch({ type: VERIFY_ENABLED_EMBED_APP, payload: true });
        cb();
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch(loadingAction(EVENT_ADD_APP_LOADING, false));
  }
};

export const getSessionEngagementListAsync = (headers, cb) => async (dispatch) => {
  try {
    dispatch({ type: INIT_ENGAGEMENT_LIST_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      GET_SESSION_ENGAGEMENT_LIST,
      'GET',
      {},
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: GET_SESSION_ENGAGEMENT_LIST_DATA, payload: data.data });
        if (cb) {
          cb(data);
        }
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: INIT_ENGAGEMENT_LIST_LOADING, payload: false });
  }
};

export const rearrangeEngagementListAsync = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_ENGAGEMENT_REARRANGE_LIST_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      REARRANGE_SESSION_ENGAGEMENT_LIST,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: REARRANGE_SESSION_ENGAGEMENT_LIST_DATA, payload: [] });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_ENGAGEMENT_REARRANGE_LIST_LOADING, payload: false });
  }
};

export const updateSessionEngagementStatus = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_ENGAGEMENT_STATUS_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      UPDATE_SESSION_ENGAGEMENT_STATUS,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: UPDATE_SESSION_ENGAGEMENT_STATUS_DATA, payload: addData });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_ENGAGEMENT_STATUS_LOADING, payload: false });
  }
};

export const updateSessionEngagementReactions = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_SESSION_ENGAGEMENT_REACTIONS_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      UPDATE_SESSION_ENGAGEMENT,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({
          type: UPDATE_SESSION_ENGAGEMENT_REACTIONS,
          payload: addData?.data?.is_att_rxn || 0,
        });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_SESSION_ENGAGEMENT_REACTIONS_LOADING, payload: false });
  }
};

export const updateSessionEngagementRaiseHand = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      UPDATE_SESSION_ENGAGEMENT,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({
          type: UPDATE_SESSION_ENGAGEMENT_RAISE_HAND,
          payload: addData?.data?.is_raise_hand || 0,
        });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING, payload: false });
  }
};

export const updateSessionEngagement = (addData, headers, cb) => async (dispatch) => {
  const dataValue = JSON.parse(JSON.stringify(addData));
  delete dataValue.data.svg_image;
  try {
    dispatch({ type: IS_ENGAGEMENT_UPDATE_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      UPDATE_SESSION_ENGAGEMENT,
      'POST',
      dataValue,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: UPDATE_SESSION_ENGAGEMENT_DATA, payload: addData });
        if (addData?.data?.is_background_mode === 1) {
          dispatch(getSessionEngagementListAsync({ ...headers, type: 'Session' }));
        }
        cb(addData?.data);
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_ENGAGEMENT_UPDATE_LOADING, payload: false });
  }
};

export const addEmbedAppToSessionAsync = (addData, headers, cb) => async (dispatch) => {
  try {
    dispatch({ type: IS_ENGAGEMENT_SAVE_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      ADD_SESSION_ENGAGEMENT,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: ADD_SESSION_ENGAGEMENT_DATA, payload: true });
        dispatch(toggleEmbedAppSidePanel({ isShow: false, data: {} }));
        dispatch(getSessionEngagementListAsync(headers, cb));
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_ENGAGEMENT_SAVE_LOADING, payload: false });
  }
};

export const updateRoomReactionEnable = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_ROOM_REACTIONS_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      UPDATE_ROOM_REACTION_ENABLE,
      'POST',
      addData,
      {},
      headers
    );

    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({
          type: UPDATE_ROOM_REACTIONS_ENABLE_DATA,
          payload: addData?.data?.is_att_rxn || 'NO',
        });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_ROOM_REACTIONS_LOADING, payload: false });
  }
};
export const enableEngagementOption = (addData, headers) => async (dispatch) => {
  try {
    dispatch({ type: IS_TYPE_LOADING, payload: true });
    const { status, data = {} } = await genericAPICall(
      ENABLE_FOR_IN_PLACE_SESSION,
      'POST',
      addData,
      {},
      headers
    );
    if (status === 200) {
      if (data.status === 200) {
        if (data.message) {
          dispatch(setNewMessage({ message: data.message, type: 'success', show: true }));
        }
        dispatch({ type: IS_TYPE_DATA, payload: true });
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch({ type: IS_TYPE_LOADING, payload: false });
  }
};
