import { getSurveyCookie } from 'lib/cookies';
import moment from 'moment-timezone';

export const HUB_LABELS = {
  INITIATE_REFUND: 'INITIATE REFUND',
  REFUNDING_LOAD: 'REFUNDING...',
  DELETING_LOAD: 'DELETING...',
  SEND_MAIL: 'SEND MAIL',
  RESENDING_LOAD: 'RESENDING...',
  LEARN_MORE: 'Learn More',
  DOWNLOADING: 'DOWNLOADING...',
  APPROVE_LOAD: 'APPROVING...',
  DISAPPROVE_LOAD: 'DISAPPROVING...',
  CONNECT: 'Connect',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  APPROVE: 'Approve',
  DISAPPROVE: 'Disapprove',
  RESEND_PAYMENT_REQUEST: 'Resend Payment Request',
  GO_TO_TICKETS: 'Go to Tickets',
};

export const DEF_DATE_TIME_FORMAT = 'DD MMM YYYY, hh:mm A';
export const EMPTY_STR = '';
export const INIT_SURVEY = 'INIT_SURVEY';

export const HUB_CONST = {
  DELETE: 'DELETE',
  RESEND_CONFIRMATION: 'RESEND_CONFIRMATION',
  REFUND: 'REFUND',
  RESEND_MAIL: 'RESEND_MAIL',
  RESEND_PAYMENT_REQUEST: 'RESEND_PAYMENT_REQUEST',
  RESEND: 'RESEND',
  CANCEL: 'CANCEL',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  POST_API_WITH_CUSTOM_DOMAIN: 'POSTAPIWITHCUSTOMDOMAIN',
  ORDERS: 'ORDERS',
  INCOMPLETE_ORDERS: 'INCOMPLETE_ORDERS',
  PENDING_ORDERS: 'PENDING_ORDERS',
  DOWNLOAD: 'DOWNLOAD',
  GET_REG_LIST: 'GET-REGISTRATION-LIST',
  GET_DOWNLOAD: 'GET-DOWNLOAD',
  GET_DOWNLOAD_LINK: 'GET-DOWNLOAD-LINK',
  PROCESSED: 'PROCESSED',
  PROCESSING: 'PROCESSING',
  APPROVE: 'APPROVE',
  DISAPPROVE: 'DISAPPROVE',
  CANCELLED: 'CANCELLED',
  DOCUMENT: 'DOCUMENT',
  POLLS: 'POLLS',
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  ENTRY: 'ENTRY',
  EDIT: 'EDIT',
  ACTIVE: 'ACTIVE',
  YES: 'YES',
  NO: 'NO',
  TICKET: 'TICKET',
  ADD: 'ADD',
  SAVE: 'SAVE',
  CONNECTED: 'CONNECTED',
  NOT_DELIVERED: 'NOT_DELIVERED',
  DELIVERED: 'DELIVERED',
  GROUP: 'GROUP',
  REJECT: 'REJECT',
  BLOCK: 'BLOCK',
  DUPLICATE: 'DUPLICATE',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  TICKETS_REQUIRED: 'TICKETS_REQUIRED',
  NONE: 'NONE',
  REMOVE_EMAIL_FILTER: 'REMOVE_EMAIL_FILTER',
};

export const HUB_MSGS = {
  REGISTRATION: {
    ORDERS:
      'Your ticket purchases will be displayed here as orders. You can track each purchase, incomplete orders and other relevant information here.',
    DISCOUNTS:
      'Add the discounts that you want to give on the tickets. You can modify the discount and track its relevant information here.',
    TICKETS: 'Create paid and free tickets for attendees.',
  },
};

export const COPY_EVENT_STATUS = {
  SUCCESS: 'success',
  PENDING: 'pending',
  PROCESSING: 'processing',
  FAILED: 'failed',
};

// DOWN TIME CONSTANTS

export const LOCAL_TIME_CONSTANTS = {
  LOCAL_TIME_ZONE: moment.tz.guess(), // for CST ==> "America/Chicago" , for IST ===> "Asia/Calcutta"
};

export const DOWN_TIME_CONSTANTS = {
  DOWN_TIME_MODAL_LOCAL_STORAGE_STATUS: 'DDM',
  ENABLE_DOWN_TIME_FEATURE: true,
  DOWNTIME_TIME_ZONE: 'America/Chicago', // CST
  START_TIME: 1671775200000, //  (Converted to UTC)
  END_TIME: 1672207140000, //  ( Converted to UTC)
  LOCAL_CURRENT_TIME_UTC: moment().tz(LOCAL_TIME_CONSTANTS.LOCAL_TIME_ZONE).utc().valueOf(), // UTC
};

export const DOWN_TIME_MODAL = {
  IS_AVAILABLE:
    DOWN_TIME_CONSTANTS.ENABLE_DOWN_TIME_FEATURE &&
    DOWN_TIME_CONSTANTS.LOCAL_CURRENT_TIME_UTC < DOWN_TIME_CONSTANTS.START_TIME,
};

export const DOWN_TIME_HUMAN_READ_ABLE_TIME = {
  DOWN_TIME_START_TIME_IN_CST: moment(DOWN_TIME_CONSTANTS.START_TIME)
    .tz(DOWN_TIME_CONSTANTS.DOWNTIME_TIME_ZONE)
    .format('llll z'), // Fri, Dec 23, 2022 12:00 AM CST
  DOWN_TIME_END_TIME_IN_CST: moment(DOWN_TIME_CONSTANTS.END_TIME)
    .tz(DOWN_TIME_CONSTANTS.DOWNTIME_TIME_ZONE)
    .format('llll z'), // Tue, Dec 27, 2022 11:59 PM CST,
  DOWN_TIME_START_TIME_IN_LOCAL: moment(DOWN_TIME_CONSTANTS.START_TIME)
    .tz(LOCAL_TIME_CONSTANTS.LOCAL_TIME_ZONE)
    .format('llll z'), // Fri, Dec 23, 2022 12:00 AM CST
  DOWN_TIME_END_TIME_IN_LOCAL: moment(DOWN_TIME_CONSTANTS.END_TIME)
    .tz(LOCAL_TIME_CONSTANTS.LOCAL_TIME_ZONE)
    .format('llll z'), // Tue, Dec 27, 2022 11:59 PM CST / IST
  LOCAL_CURRENT_TIME: moment(DOWN_TIME_CONSTANTS.LOCAL_CURRENT_TIME_UTC)
    .tz(LOCAL_TIME_CONSTANTS.LOCAL_TIME_ZONE)
    .format('llll z'),
  LOCAL_CURRENT_TIME_CST: moment(DOWN_TIME_CONSTANTS.LOCAL_CURRENT_TIME_UTC)
    .tz(DOWN_TIME_CONSTANTS.DOWNTIME_TIME_ZONE)
    .format('llll z'), //  Wed, Dec 14, 2022 10:29 AM CST
};

export const getParameterByName = (key, url = window.location.href) => {
  // eslint-disable-next-line no-useless-escape
  const name = key.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getDetails = () => {
  const type = getParameterByName('t');
  const orgId = getParameterByName('o');
  const eId = getParameterByName('e');

  return { type, orgId, eId };
};

export const getSurveyRedirection = () => {
  const cookieData = getSurveyCookie();
  const { type = '', orgId = '', eId = '' } = cookieData || getDetails() || {};
  if (type) {
    if (type === 'CSAT' && eId && orgId) return `/event/${eId}/${orgId}`;
  }
  return '/events';
};

export const IS_DOWN_TIME_STARTED =
  DOWN_TIME_CONSTANTS.ENABLE_DOWN_TIME_FEATURE &&
  DOWN_TIME_CONSTANTS.LOCAL_CURRENT_TIME_UTC >= DOWN_TIME_CONSTANTS.START_TIME &&
  DOWN_TIME_CONSTANTS.LOCAL_CURRENT_TIME_UTC <= DOWN_TIME_CONSTANTS.END_TIME;

// END :  DOWN TIME CONSTANTS
