import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    mandatory: {
        color: 'red',
    },
    feedbackDetails: {
        "@media(min-width:1600px)": {
            alignItems: "center"
        }
    },
    surveySubDesc: {
        marginTop: theme.spacing(2),
        maxWidth: '480px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    score: {
        marginBottom: theme.spacing(1.2),
        marginTop: theme.spacing(1),
    },
    themeColor: {
        color: theme.palette.primary.main
    },
    customBtn: {
        height: 32,
        width: 32,
        minWidth: 32,
        padding: 0,
        borderRadius: '10px',
        "&:nth-child(n+1)": {
            marginLeft: theme.spacing(1.4)
        },
        "&:first-child": {
            marginLeft: 0
        }
    },
    customBgBtn: {
        color: theme.palette.primary.main,
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #ED7767",
        "&:hover": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #ED7767",

        }
    }
}));