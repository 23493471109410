/* eslint-disable  */
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  uploadBtn: {
    '&>input': {
      opacity: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      zIndex: 1,
      '&::-webkit-file-upload-button': {
        display: 'none',
      },
      '&.cursor-not-allowed': {
        cursor: 'not-allowed',
      },
    },
  },
}));

export default function UploadBtn(props) {
  const classes = useStyles();
  return (
    <Box position="relative" className={classes.uploadBtn} display="inline-flex" component="label">
      <input
        accept={props.fileTypes || ''}
        disabled={props.isLoading || props.disabled}
        className={`${props.isLoading || props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        multiple={props.isMultiple ? true : false}
        type="file"
        id={props.id}
        onClick={(e) => {
          e.target.value = '';
        }}
        onChange={props.onChange}
        name={props.name}
      />
      {props.children}
    </Box>
  );
}
