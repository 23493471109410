import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  textDanger: {
    color: theme.palette.text.danger1,
    fontSize: 12,
    fontWeight: 500,
  },
  popupTheme: {
    position: 'absolute',
    top: 50,
    right: 15,
    background: theme.palette.bg.white,
    boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    overflow: 'hidden',
    zIndex: 3,
  },
  breadCrumbsBlock: {
    '& a': {
      color: theme.palette.text.grayColor,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
  },
  emailList: {
    '& .listCard': {
      mb: theme.spacing(1.5),
    },
    '& .icons': {
      '& button': {
        borderRadius: 4,
        height: 36,
        width: 36,
      },
      '& .primary': {
        background: theme.palette.primary.main,
        color: theme.palette.bg.white,
        marginLeft: theme.spacing(1.5),
      },
      '& .secondary': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
    },
  },
  card: {
    overflow: 'visible',
  },
  popup: {
    background: theme.palette.bg.white,
    boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    '& ul': {
      padding: 0,
      listStyleType: 'none',
      margin: 0,
      '& li': {
        cursor: 'pointer',
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        '&:hover': {
          background: '#f6f6f6',
        },
      },
    },
  },
  headerActionBtn: {
    '& button': {
      '&:nth-child(n+2)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  createTemplateTile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.lighter,
    border: `1px dashed ${theme.palette.primary.light}`,
    minHeight: '20rem',
    // cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: theme.palette.primary.light,
    // },
    '&.disableBlock': {
      backgroundColor: theme.palette.bg.whiteOff1,
      border: `1px dashed ${theme.palette.bg.whiteOff2}`,
    },
    '& .disableCheck': {
      '& .MuiButton-label': {
        whiteSpace: 'pre-wrap',
        lineHeight: '0.9',
        color: '#808080',
        fontWeight: '400',
      },
    },
  },
  duplicateTemplateTile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.primary.lighter,
    // border: `1px dashed ${theme.palette.primary.light}`,
    border: `1px dashed ${theme.palette.bg.whiteOff2}`,
    minHeight: '20rem',
    // cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: theme.palette.primary.light,
    // },
    '&.disableBlock': {
      backgroundColor: theme.palette.bg.whiteOff1,
      border: `1px dashed ${theme.palette.bg.whiteOff2}`,
    },
    '& .disableCheck': {
      '& .MuiButton-label': {
        whiteSpace: 'pre-wrap',
        lineHeight: '0.9',
        color: '#808080',
        fontWeight: '400',
      },
    },
  },
  detectBtn: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: 14,
  },
  emailEditorBlock: {
    '& #emailBuilder': {
      height: 'calc(100vh - 95px)',
    },
  },
  editableTitle: {
    // '& .editableField': {
    //   outline: 'none',
    //   fontSize: 24,
    //   fontWeight: 500,
    //   paddingLeft: 1,
    //   paddingBottom: 10,
    //   paddingTop: 10,
    //   width: '100%',
    //   '&:focus': {
    //     borderBottom: `1px solid ${theme.palette.primary.main}`,
    //   },
    // },
    '& .editableField': {
      boxShadow: 'none',
      border: 'none',
      borderRadius: 0,
      paddingLeft: 0,
      paddingBottom: '6px',
      '&.Mui-focused': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
      '&>input': {
        fontSize: 20,
        fontWeight: 500,
        '&::-webkit-input-placeholder': {
          color: '#c0c0c0',
        },
      },
    },
    '& .brandLogo': {
      '& img': {
        height: 'auto',
      },
    },
    '& .editableFieldModal': {
      outline: 'none',
      fontSize: 20,
      fontWeight: 500,
      paddingLeft: 1,
      maxWidth: 800,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& .empty': {
      color: theme.palette.text.grayColor1,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& .filled': {
      color: theme.palette.text.primary,
      borderBottom: 'none',
    },
    '& .editAction': {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 500,
      cursor: 'pointer',
      paddingRight: '.75rem',
      '& svg': {
        marginRight: 3,
      },
    },
  },
  chipLabel: {
    marginRight: 8,
    borderRadius: theme.shape.borderRadius,
    '& .MuiChip-label': {
      fontSize: theme.fontSize.size10,
    },
    '&.removed': {
      background: '#FEF3E3',
      color: '#FBB349',
    },
  },
  emailTypeTags: {
    display: 'flex',
    '& .MuiChip-root': {
      marginRight: 10,
    },
  },
  boldText: {
    fontWeight: 500,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  warningBox: {
    background: theme.palette.primary.primaryLight,
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
  },
  informationBox: {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.alert.warn}`,
    marginBottom: 10,

    '& .headInfo': {
      display: 'flex',
      alignItems: 'start',
      background: theme.palette.alert.warn,
      padding: `${theme.spacing(1.5)}px`,
    },
    '& .bodyInfo': {
      '& code': {
        background: theme.palette.bg.whiteOff1,
        padding: 5,
        color: theme.palette.text.secondary,
        borderRadius: 4,
      },
      '& ul': {
        paddingLeft: 15,
        margin: 0,
      },
      '& .mergeTagsList': {
        '& .MuiTypography-caption': {
          marginBottom: 10,
        },
      },
    },
  },
  pausedBoxWrapper: {
    '& .MuiPopover-paper': {
      marginTop: 35,
    },
  },
  pausedBox: {
    marginTop: 10,
    '& .pursedBoxAction': {
      '& .MuiButton-root': {
        marginRight: 15,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  },
  successIconColor: {
    color: theme.palette.alert.success,
  },
  emailComposeAlerts: {
    '& .msgBlock': {
      borderRadius: theme.shape.borderRadius,
      '&.success': {
        background: theme.palette.alert.successLight,
        '& .MuiTypography-body2': {
          color: theme.palette.alert.success,
        },
      },
      '&.warning': {
        background: theme.palette.alert.warn2Light,
      },
      '&>p': {
        fontWeight: 500,
      },
      '& .msg-icon': {
        background: theme.palette.primary.light,
        borderRadius: '50%',
        height: 25,
        width: 25,
      },
    },
  },
  template: {
    position: 'relative',
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: theme.shape.borderRadius,
    minHeight: '20rem',
    overflow: 'hidden',
    '& .templateMedia': {
      position: 'relative',
      minHeight: '20rem',
      '& img': {
        width: '100%',
      },
      '& .emailTemBlock': {
        '& .emailTemBanner': {
          width: '100%',
          height: '17rem',
        },
        '& .emailTemShortName': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          fontSize: '38px',
          letterSpacing: '3.8px',
          color: theme.palette.primary.contrastText,
          opacity: '0.8',
          textShadow: '0px 2px 4px rgba(0,0,0,.3)',
          textTransform: 'uppercase',
          fontWeight: 700,
        },
        '& .emailTemStatus': {
          position: 'absolute',
          left: 10,
          top: 10,
        },
      },
    },
    '& .templateActions': {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      height: '100%',
      width: '100%',
      '& .removeThemeBtn': {
        position: 'absolute',
        right: 15,
        top: 15,
        zIndex: '111',
        '& button': {
          background: '#fff',
        },
      },
      '&:hover': {
        '& .top': {
          opacity: 1,
        },
      },
      '& .top': {
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        opacity: 0,
        transition: '0.25s all',
        flexDirection: 'column',
        zIndex: '111',
        position: 'relative',
        '&::before': {
          content: '""',
          background: 'rgba(0,0,0,0.5)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        },
        '& .ctaPanel': {
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          justifyContent: ' space-around',
          width: '100%',
          paddingLeft: '4rem',
          paddingRight: '4rem',
          zIndex: '11',
        },
        '& .MuiButton-outlined': {
          width: 32,
          height: 32,
          minWidth: 32,
          borderRadius: 32,
          padding: 0,
          marginBottom: '.5rem',
        },
        '& .MuiTypography-root': {
          color: theme.palette.primary.contrastText,
        },
      },
      '& .bottom': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 1,
        padding: 16,
        width: '100%',
        background: theme.palette.bg.white,

        '& .MuiTypography-subtitle1': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiButton-root': {
          marginRight: 10,
          width: '100%',
          '&:last-child': {
            marginRight: 0,
          },
        },
      },
    },
  },
  activeTemplate: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardBtnActionBlock: {
    position: 'absolute',
    right: 0,
    top: 0,
    transition: theme.transitions.create(['opacity', 'visibility']),

    '& button': {
      margin: theme.spacing(1.5),
      padding: theme.spacing(0),
      minWidth: '30px',
      width: '30px',
      background: 'rgba(255,255,255,1)',
      height: '30px',
    },
    '&:hover': {
      '& button': {
        background: 'rgba(255,255,255,.9)',
      },
    },
  },

  eventActionMenu: {
    '& .MuiMenu-list': {
      '& svg': {
        marginRight: theme.spacing(1.2),
      },
      '& .MuiMenuItem-root': {
        '&:hover': {
          color: theme.palette.primary.light,
        },
      },
    },
  },

  emailDraftCard: {
    // overflow: 'visible',
    position: 'relative',
  },
  emailDraftingBlock: {
    '& .disableBlock': {
      opacity: 0.5,
      pointerEvents: 'none',
      filter: 'grayscale(1)',
    },
    '& .block': {
      display: 'flex',
      position: 'relative',
      minHeight: 20,
      paddingBottom: 30,

      '&:last-child:after': {
        display: 'none !important',
      },
      '&.b4': {
        '& .MuiButton-textPrimary': {
          background: `transparent !important`,
        },
      },
      '&::after': {
        position: 'absolute',
        content: '""',
        left: 20,
        top: 0,
        width: 1,
        height: '100%',
        background: theme.palette.border.light,
      },

      '&.active::after': {
        background: theme.palette.primary.main,
      },

      '& .checkIcon': {
        display: 'none',
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        zIndex: 1,
      },

      '& .blockIcon': {
        width: 42,
        height: 42,
        borderRadius: 50,
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.bg.white,
        color: theme.palette.primary.main,
        position: 'relative',
        zIndex: 1,
        '&.current': {
          background: theme.palette.primary.lighter,
          borderColor: theme.palette.primary.lighter,
        },
        '&.checked': {
          '& .checkIcon': {
            display: 'block',
          },
        },
      },
    },

    '& .blockContent': {
      width: '100%',
      position: 'relative',
      '& .blockInfo': {
        '& .MuiTypography-root': {
          marginBottom: 5,
        },
      },
    },
    '& .blockBodyContent': {
      '& .MuiCard-root ': {
        overflow: 'visible',
        '& .scheduleDate': {
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          '&.cursor-not-allowed ': {
            border: `1px solid #dbdbdb`,
            borderRight: 'none',
            '& svg': {
              '&>path': {
                fill: '#dbdbdb',
              },
            },
            '& .flatpickr-input': {
              color: '#c0c0c0',
              '&::-webkit-input-placeholder': {
                color: '#c0c0c0',
              },
            },
          },
        },
        '& .successStripeBlock': {
          background:
            'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CBB3E',
          color: '#4CBB3E',
          borderRadius: theme.shape.borderRadius,
        },

        '& .DeleteCTA': {
          padding: 0,
          fontWeight: 500,
          cursor: 'pointer',
          '& svg': {
            display: 'flex',
            marginRight: 10,
          },
          '&:hover': {
            background: 'none',
          },
        },
        '& .reminderEmailCTA': {
          padding: 0,
          textTransform: 'none',
          '&:hover': {
            background: 'none',
          },
        },
        '& .reminderJoinLink': {
          '&>label': {
            marginRight: theme.spacing(0.5),
          },
        },
        '& .actionCTASchedule': {
          marginTop: theme.spacing(1),
        },
        '& .scheduleTime': {
          '& .timeFieldWrapper': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '&.Mui-disabled ': {
              border: `1px solid #dbdbdb`,
              '& .MuiInputBase-input ': {
                opacity: 0.5,
              },
            },

            '&.Mui-focused': {
              boxShadow: 'none',
              border: `1px solid ${theme.palette.primary.main}`,
            },
          },
        },
      },
      '& .successStripe': {
        background: theme.palette.bg.whiteOff1,
        padding: '1rem',
        borderRadius: theme.spacing(1),
        '& .cursor-not-allowed': {
          border: `1px solid #DBDBDB`,
          '& .selectInput': {
            '&>span': {
              color: '#c0c0c0',
            },
            '&~span': {
              '&>svg': {
                color: '#c0c0c0',
              },
            },
          },
        },
      },
      '& .successStripe2': {
        padding: '0rem 0.5rem',
        '& .cursor-not-allowed': {
          border: `1px solid #DBDBDB`,
          '& .selectInput': {
            '&>span': {
              color: '#c0c0c0',
            },
            '&~span': {
              '&>svg': {
                color: '#c0c0c0',
              },
            },
          },
        },
      },
      '& .MuiCardContent-root': {
        padding: theme.spacing(3),
      },
    },
    '& .blockAction': {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'end',
      flexDirection: 'column',
      '& .editAction': {
        alignSelf: 'end',
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
        cursor: 'pointer',
        '& svg': {
          marginRight: 3,
        },
      },
    },
  },

  sendTestEmailBox: {
    padding: '60px 40px 40px 40px',
    textAlign: 'center',

    '& .sendTestEmailHead': {
      marginTop: '30px',
      marginBottom: '10px',
      fontSize: theme.fontSize.size5,
      fontWeight: 500,
      lineHeight: '27px',
    },
    '& .editableBox': {
      border: `1px solid ${theme.palette.border.dark}`,
      padding: '8px 12px 8px 16px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& Button': {
        marginLeft: '10px',
      },
    },
  },
  templateWithoutTitle: {
    '& .templateMedia': {
      maxHeight: 290,
      minHeight: 290,
      borderRadius: 8,
      '& .emailTemBlock': {
        '& .emailTemBanner': {
          paddingTop: '67%',
        },
      },
    },
  },
  footerActionBtn: {
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    padding: `${theme.spacing(3)}px 0`,
    margin: `0 ${theme.spacing(3)}px`,
    '&>button': {
      '&:nth-child(n+1)': {
        marginLeft: theme.spacing(2),
      },
    },
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    width: 'calc(100% - 48px)',
    transition: `opacity 0.3s`,
    '&.hide': {
      opacity: 0,
    },
    '&.show': {
      opacity: 1,
      // resolve SWAT-4310
      zIndex: 1,
    },
  },
  mainHeight: {
    height: `calc(100vh - 12rem)`,
    overflow: `auto`,
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(13.37)}px ${theme.spacing(3)}px`,
    margin: `${theme.spacing(3)}px 0 0`,
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 50px rgba(0,0,0,0.35) !important',
    },
  },
}));
