/* eslint-disable */
// export const browserSignature = () => {
//   const windowObj = window || global;

//   // Count Browser window object keys
//   const windowObjCount = () => {
//     const keys = [];
//     for (const i in windowObj) {
//       keys.push(i);
//     }
//     return keys.length.toString(36);
//   };
//   // window obj and navigator aggregate
//   const pad = (str: any, size: any) => {
//     return (new Array(size + 1).join('0') + str).slice(-size);
//   };

//   // Browser mimiTypes and User Agent count
//   const navi = (navigator.mimeTypes.length + navigator.userAgent.length).toString(36);
//   const padString = pad(navi + windowObjCount(), 4);
//   // Browser screen specific properties
//   const width = windowObj.screen.width.toString(36);
//   const height = windowObj.screen.height.toString(36);
//   const availWidth = windowObj.screen.availWidth.toString(36);
//   const availHeight = windowObj.screen.availHeight.toString(36);
//   const colorDepth = windowObj.screen.colorDepth.toString(36);
//   const pixelDepth = windowObj.screen.pixelDepth.toString(36);
//   // Base64 encode
//   return btoa(padString + width + height + availWidth + availHeight + colorDepth + pixelDepth);
// };

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { LOCAL_STORAGE_KEY } from 'constants/cookieConsent';
import { getDataFromLocalStorage, saveDataToLocalStorage } from './localStorageHandler';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

export const browserSignatureStorageHandler = async () => {
  let visitorId = '';
  new Promise(async function (resolve, reject) {
    try {
      const fp = await fpPromise;
      const result = await fp.get();
      visitorId = result.visitorId;
      resolve(visitorId);
    } catch (err) {
      throw err;
    }
  }).then((res) => {
    saveDataToLocalStorage(LOCAL_STORAGE_KEY.GEN_BROWSER_SIGNATURE, res);
    return res;
  });
};

export const browserSignature = async (res) => {
  let val =
    Object.keys(getDataFromLocalStorage(LOCAL_STORAGE_KEY.GEN_BROWSER_SIGNATURE)).length === 0
      ? browserSignatureStorageHandler()
      : getDataFromLocalStorage(LOCAL_STORAGE_KEY.GEN_BROWSER_SIGNATURE);

  return res;
};
