/* eslint-disable camelcase */
import mixPanel from 'mixpanel-browser';
import { shouldApplied } from './init';

export function SignupStartedMP(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'User Signup Started';
    mixPanel.track(EVENT_NAME, { method: type });
  }
}

export function loginSuccessMP(USER_ID, email, firstName, lastName, type) {
  if (shouldApplied) {
    const EVENT_NAME = 'User Login';
    mixPanel.identify(USER_ID);
    mixPanel.people.set({
      $email: email,
      $first_name: firstName || '',
      $last_name: lastName || '',
      USER_ID,
    });
    mixPanel.track(EVENT_NAME, { method: type });
  }
}

export function logoutSuccessMP() {
  if (shouldApplied) {
    const EVENT_NAME = 'User Logout';
    mixPanel.track(EVENT_NAME, {});
    mixPanel.reset();
  }
}

export function SignupSuccessMP(firstName, lastName, organisation, organiser_id, USER_ID, type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Signup Up';
    mixPanel.alias(USER_ID);
    mixPanel.people.set({
      first_name: firstName,
      last_name: lastName,
      organisation,
      organiser_id,
      USER_ID,
    });
    mixPanel.register({
      organisation,
      organiser_id,
    });

    mixPanel.track(EVENT_NAME, { method: type });
  }
}

export function RedirectFromEmailMP(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Redirect From Email';
    mixPanel.track(EVENT_NAME, { method: type });
  }
}

export function BrokenSignupLinkMP() {
  if (shouldApplied) {
    const EVENT_NAME = 'Broken Signup Link';
    mixPanel.track(EVENT_NAME);
  }
}

export function RedirectFromSocialMP(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Redirect From Social';
    mixPanel.track(EVENT_NAME, { signup_method: type });
  }
}

export function PageVisitMP(page_name, event_id, event_name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Page Visit';
    mixPanel.track(EVENT_NAME, {
      page_name,
      event_id,
      event_name,
    });
  }
}

export function PublishEventMP(event_id, event_name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Publish Event';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
    });
  }
}

export function AddSessionMP(
  event_id,
  event_name,
  session_name,
  session_id,
  session_date,
  start_time,
  end_time
) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Session';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      session_name,
      session_id,
      session_date,
      start_time,
      end_time,
    });
  }
}

export function StreamEnabledMP(
  eventId,
  eventName,
  sessionName,
  sessionId,
  streamType,
  chatEnabled,
  qNaEnabled,
  attendeesEnabled,
  pollsEnabled,
  upcomingEnabled
) {
  if (shouldApplied) {
    const EVENT_NAME = 'Stream Enabled';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      session_name: sessionName,
      session_id: sessionId,
      stream_type: streamType,
      chat_enabled: chatEnabled,
      'q&a_enabled': qNaEnabled,
      attendees_enabled: attendeesEnabled,
      polls_enabled: pollsEnabled,
      upcoming_enabled: upcomingEnabled,
    });
  }
}

export function SessionRegistrationMP(
  eventId,
  eventName,
  sessionName,
  sessionId,
  waitlist_enabled,
  unregister_enabled
) {
  if (shouldApplied) {
    const EVENT_NAME = 'Session Registration';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      session_name: sessionName,
      session_id: sessionId,
      waitlist_enabled,
      unregister_enabled,
    });
  }
}

export function SessionRestrictionMP(eventId, eventName, sessionName, sessionId) {
  if (shouldApplied) {
    const EVENT_NAME = 'Session Restriction';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      session_name: sessionName,
      session_id: sessionId,
    });
  }
}

export function PeopleAddedMP(event_id, event_name, user_id, group) {
  if (shouldApplied) {
    const EVENT_NAME = 'People Added';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      user_id,
      group,
    });
  }
}

export function MeetingsAddedMP(event_id, event_name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Meetings Added';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
    });
  }
}

export function VirtualBoothAddedMP(event_id, event_name, booth_name, category, booth_priority) {
  if (shouldApplied) {
    const EVENT_NAME = 'Virtual Booth Added';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      booth_name,
      category,
      booth_priority,
    });
  }
}

export function PremiumBoothMP() {
  if (shouldApplied) {
    const EVENT_NAME = 'Premium Booth';
    mixPanel.track(EVENT_NAME);
  }
}

export function LeaderboardToggleMP(event_id, event_name, state) {
  if (shouldApplied) {
    const EVENT_NAME = 'Leaderboard Toggle';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      state,
    });
  }
}

export function LoginOptionsEditedMP(
  event_id,
  event_name,
  restrict_access,
  facebook,
  google,
  linkedIn
) {
  if (shouldApplied) {
    const EVENT_NAME = 'LoginOptions Edited';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      restrict_access,
      facebook,
      google,
      linkedIn,
    });
  }
}

export function ScoreEditedMP(event_id, event_name, point) {
  if (shouldApplied) {
    const EVENT_NAME = 'Score Edited';
    mixPanel.track(EVENT_NAME, {
      event_id,
      event_name,
      point,
    });
  }
}

export function SaveAndAddMoreMP(eventId, eventName, section) {
  if (shouldApplied) {
    const EVENT_NAME = 'Save And Add More';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      section,
    });
  }
}

export function LoungeTableAddedMP(eventId, eventName, tableCapacity, logoAdded) {
  if (shouldApplied) {
    const EVENT_NAME = 'Lounge Table Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      table_capacity: tableCapacity,
      lodo_added: logoAdded,
    });
  }
}

export function RoomAddedMP(eventId, eventName, roomType, whoCanJoin, whoCanShare) {
  if (shouldApplied) {
    const EVENT_NAME = 'Room Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      room_type: roomType,
      who_can_join: whoCanJoin,
      who_can_share: whoCanShare,
    });
  }
}

export function NotificationScheduledMP(eventId, eventName, section) {
  if (shouldApplied) {
    const EVENT_NAME = 'Notification Scheduled';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      section,
    });
  }
}

export function NotificationSentMP(eventId, eventName, section) {
  if (shouldApplied) {
    const EVENT_NAME = 'Notification Sent';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      section,
    });
  }
}

export function PointsEditedMP(eventId, eventName, change) {
  if (shouldApplied) {
    const EVENT_NAME = 'Points Edited';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      change,
    });
  }
}

// gettiing undefined eventName from eventData
// export function TeamMemberAddedMP(eventId, eventName) {
// 	if (shouldApplied) {
// 		const EVENT_NAME = "Team Member Added";
// 		mixPanel.track(EVENT_NAME, {
// 			"event_id": eventId,
// 			"event_name": eventName
// 		});
// 	}
// }
export function TeamMemberAddedMP(eventId) {
  if (shouldApplied) {
    const EVENT_NAME = 'Team Member Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
    });
  }
}

export function FaviconAddedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Favicon Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function CommunityBannerAddedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Community Banner Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function LoginBannerAddedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Login Banner Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function AnalyticsVisitMP(eventId, eventName, section_name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Analytics Visit';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      section_name,
    });
  }
}

export function ColumnViewSavedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Column View Saved';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function PasswordChangedMP() {
  if (shouldApplied) {
    const EVENT_NAME = 'Password Changed';
    mixPanel.track(EVENT_NAME);
  }
}

export function InviteMailerSentMP(eventId, eventName, selectedNumber) {
  if (shouldApplied) {
    const EVENT_NAME = 'Invite Mailer Sent';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      selected_number: selectedNumber,
    });
  }
}

export function PeopleDeleteMP(eventId, eventName, selectedNumber) {
  if (shouldApplied) {
    const EVENT_NAME = 'People Delete';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      selected_number: selectedNumber,
    });
  }
}

export function GroupAssignedMP(eventId, eventName, groupName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Group Assigned';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      group_name: groupName,
    });
  }
}

export function GroupAddedMP(eventId, eventName, groupName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Group Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      group_name: groupName,
    });
  }
}

export function ConsentAddedMP(eventId, eventName, consentType, required) {
  if (shouldApplied) {
    const EVENT_NAME = 'Consent Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      consent_type: consentType,
      required,
    });
  }
}

export function ContactInfoAddedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Contact Info Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function IntegrationConnectedMP(eventId, eventName, integration_name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Integration Connected';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      integration_name,
    });
  }
}

export function SocialBannerAddedMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Social Banner Added';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}

export function PrivacyPolicyToggleMP(eventId, eventName, value) {
  if (shouldApplied) {
    const EVENT_NAME = 'Privacy Policy Toggle';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      value,
    });
  }
}

export function TermsAndConditionsToggleMP(eventId, eventName, value) {
  if (shouldApplied) {
    const EVENT_NAME = 'Terms And Conditions Toggle';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
      value,
    });
  }
}

export function CreateEventMP(eventId, eventName) {
  if (shouldApplied) {
    const EVENT_NAME = 'Create Event';
    mixPanel.track(EVENT_NAME, {
      event_id: eventId,
      event_name: eventName,
    });
  }
}
