import amplitude from 'amplitude-js';
import { eventProperties, shouldApplied } from './init';

const addDefaultPayload = (rest) => {
  return {
    'event id': eventProperties.eventId ?? '',
    'event name': eventProperties.eventName ?? '',
    'organiser id': eventProperties.organiserId ?? '',
    ...rest,
  };
};

export function clickAddCredit(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'click add credit';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        source,
      })
    );
  }
}

export function addBasicCreditDetails(creditId, creditName, source) {
  if (shouldApplied) {
    const EVENT_NAME = 'add basic credit details';
    const payload = {};
    if (creditId) payload['credit id'] = creditId;
    if (creditName) payload['credit name'] = creditName;
    if (source) payload['source'] = source;
    amplitude.getInstance().logEvent(EVENT_NAME, addDefaultPayload(payload));
  }
}

export function cancelBasicCreditDetails(creditId, creditName, source) {
  if (shouldApplied) {
    const EVENT_NAME = 'cancel basic credit details';
    const payload = {};
    if (creditId) payload['credit id'] = creditId;
    if (creditName) payload['credit name'] = creditName;
    if (source) payload['source'] = source;
    amplitude.getInstance().logEvent(EVENT_NAME, addDefaultPayload(payload));
  }
}

export function addAdvanceCreditDetails(creditId, creditName, sponsorLogo, source) {
  if (shouldApplied) {
    const EVENT_NAME = 'add advance credit details';
    const payload = {};
    if (creditId) payload['credit id'] = creditId;
    if (creditName) payload['credit name'] = creditName;
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        ...payload,
        'sponsor logo': sponsorLogo,
        source,
      })
    );
  }
}

export function cancelAdvanceCreditDetails(creditId, creditName, source) {
  if (shouldApplied) {
    const EVENT_NAME = 'cancel advance credit details';
    const payload = {};
    if (creditId) payload['credit id'] = creditId;
    if (creditName) payload['credit name'] = creditName;
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        ...payload,
        source,
      })
    );
  }
}

export function previewCredit(creditId, creditName, source) {
  if (shouldApplied) {
    const EVENT_NAME = 'preview credit';
    const payload = {};
    if (creditId) payload['credit id'] = creditId;
    if (creditName) payload['credit name'] = creditName;
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        ...payload,
        source,
      })
    );
  }
}

export function editCredit(creditId, creditName, editedFields) {
  if (shouldApplied) {
    const EVENT_NAME = 'edit credit';
    const payload = {
      'credit id': creditId,
      'credit name': creditName,
      ...(editedFields || {}),
    };
    amplitude.getInstance().logEvent(EVENT_NAME, addDefaultPayload(payload));
  }
}

export function clickDeleteCredit(creditId, creditName) {
  if (shouldApplied) {
    const EVENT_NAME = 'click delete credit';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        'credit id': creditId,
        'credit name': creditName,
      })
    );
  }
}

export function cancelCreditDelete(creditId, creditName) {
  if (shouldApplied) {
    const EVENT_NAME = 'cancel credit delete';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        'credit id': creditId,
        'credit name': creditName,
      })
    );
  }
}

export function proceedCreditDelete(creditId, creditName) {
  if (shouldApplied) {
    const EVENT_NAME = 'proceed credit delete';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        'credit id': creditId,
        'credit name': creditName,
      })
    );
  }
}

export function advanceSessionDetails(creditsEnabled) {
  if (shouldApplied) {
    const EVENT_NAME = 'advance session details';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        'credits enabled': creditsEnabled,
      })
    );
  }
}

export function sessionCreditMapping(
  creditId,
  creditName,
  sessionId,
  sessionName,
  creditPoint,
  creditEligibilityPoint,
  surveyFilled,
  onDemandEligibility,
  emailCertificate,
  emailTrigger
) {
  if (shouldApplied) {
    const EVENT_NAME = 'session credit mapping';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        'credit id': creditId,
        'credit name': creditName,
        'session id': sessionId,
        'session name': sessionName,
        'credit point': creditPoint,
        'credit eligibility percent': creditEligibilityPoint,
        'survey filled': surveyFilled,
        'on demand eligibility': onDemandEligibility,
        'email certificate': emailCertificate,
        'email trigger': emailTrigger,
      })
    );
  }
}

export function clickSendTestEmail() {
  if (shouldApplied) {
    const EVENT_NAME = 'click send test email';
    amplitude.getInstance().logEvent(EVENT_NAME, addDefaultPayload({}));
  }
}

export function sendTestEmail() {
  if (shouldApplied) {
    const EVENT_NAME = 'send test email';
    amplitude.getInstance().logEvent(EVENT_NAME, addDefaultPayload({}));
  }
}

export function onCreditsPageLoad() {
  if (shouldApplied) {
    const EVENT_NAME = 'on page';
    amplitude.getInstance().logEvent(
      EVENT_NAME,
      addDefaultPayload({
        page: 'credits',
      })
    );
  }
}

// Feature:Quiz CEP 
export function quizCreated({
  quizId,
  quizName,
  quizType,
  quizVisibility,
  groups, 
  sessionsId,
  ctaLabel,
  whenCanAttendeeStart,
  organisation,
  eventType,
  eventFormat,
  planType,
}) {
  if (shouldApplied) {
    amplitude.getInstance().logEvent('QuizCreated', {
      quiz_id: quizId || null,
      quiz_name: quizName || null,
      quiz_type: quizType || null,
      quiz_visibility: quizVisibility || null,
      groups: groups || null,
      sessions_id: sessionsId || null,
      CTA_label: ctaLabel || null,
      when_can_attendee_start: whenCanAttendeeStart || null,
      event_id: eventProperties?.eventId || null,
      event_type: eventType || null, 
      event_name: eventProperties?.eventName || null, 
      event_format: eventFormat || null,
      organiser_id: eventProperties?.organiserId || null, 
      organisation: organisation || null, 
      plan_type: planType || null
    });
  }
}

export function creditsEnabled({
  creditType,
  creditPoints,
  evaluationCriteria,
  liveSessionEligibilityCriteria,
  onDemandSessionEligibilityCriteria,
  isEmailCertificateEnabled,
  organisation,
  eventType,
  eventFormat,
  planType,
}) {
  if (shouldApplied) {
    amplitude.getInstance().logEvent('CreditsEnabled', {
      credit_type: creditType || null,
      credit_points: creditPoints || null,
      evaluation_criteria: evaluationCriteria || null,
      live_session_eligibility_criteria: liveSessionEligibilityCriteria || null,
      on_demand_session_eligibility_criteria: onDemandSessionEligibilityCriteria || null,
      is_email_certificate_enabled: isEmailCertificateEnabled,
      event_id: eventProperties?.eventId || null,
      event_type: eventType || null, 
      event_name: eventProperties?.eventName || null, 
      event_format: eventFormat || null,
      organiser_id: eventProperties?.organiserId || null, 
      organisation: organisation || null, 
      plan_type: planType || null
    });
  }
}
