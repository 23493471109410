/* eslint-disable  */
import React from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import activePattern from 'images/pattern.png';
import { EActionType, EEventFormat } from './common/constants';
import { PricingPlan } from '../../constants/pricingPlan';
import { shallowEqual, useSelector } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';
import CustomToolTip from 'custom-components/customToolTip';
import { getActionFromPath } from './common/utils';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  planBoxContainer: {
    '& .stripe': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      border: 'none !important',
      height: 'auto',
      textAlign: 'center',
      padding: '2px 5px',
      background: '#FEF0DB !important',
      color: '#FFBF3F',
    },
    '& .eachEventItem': {
      '&:nth-child(1)': {
        '& .eachPlanDiv': {
          border: '1px solid #D2DB51',
        },
        '&:hover': {
          '& .eachPlanDiv': {
            backgroundColor: '#D2DB51',
            '&.disabled': {
              backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
            },
          },
        },
      },
      '&:nth-child(2)': {
        '& .eachPlanDiv': {
          border: '1px solid #D8C69E',
        },
        '&:hover': {
          '& .eachPlanDiv': {
            backgroundColor: '#D8C69E',
            border: '1px solid #D8C69E',
            '&.disabled': {
              backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
            },
          },
        },
      },
    },
    '& .eachPlanDiv': {
      '&.disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      position: 'relative',
      overflow: 'hidden',
      borderRadius: 8,
      cursor: 'pointer',
      padding: theme.spacing(2),
      // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      background: `url(${activePattern}) center center no-repeat`,
      '&:hover': {
        '& .eachPlanMenu': {
          borderTop: `1px solid #f0f0f0`,
        },
        '& .eachItem': {
          '& .MuiListItemIcon-root': {
            background: 'rgba(255,255,255,0.3)',
          },
        },
      },
      '&.active': {
        '& .eachPlanMenu': {
          borderTop: `1px solid #f0f0f0`,
        },
        '& .eachItem': {
          '& .MuiListItemIcon-root': {
            background: 'rgba(255,255,255,0.3)',
          },
        },
        '&.VIRTUAL': {
          backgroundColor: '#D8C69E',
          border: '1px solid #D8C69E',
        },
        '&.WEBINAR': {
          backgroundColor: '#D2DB51',
        },
      },
    },
    '& .title': {
      fontWeight: 700,
    },
    '& .eachPlanMenu': {
      borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
      paddingBottom: theme.spacing(2.5),
      marginTop: theme.spacing(2),
      '& .eachItem': {
        paddingLeft: 0,
        paddingRight: 0,
        '& .MuiListItemIcon-root': {
          height: 28,
          width: 28,
          minWidth: 28,
          background: '#f8f8f8',
          display: 'flex',
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
          '& svg': {
            height: 16,
            width: 16,
            color: theme.palette.bg.black,
          },
        },
        '& .MuiListItemText-root': {
          marginLeft: theme.spacing(2),
        },
      },
    },
    '& .mainIcon': {
      height: 48,
      width: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      background: theme.palette.bg.whiteOff1,
      '& .radioBtnIcon': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
}));

export default function PlanBox(props) {
  const classes = useStyles();
  // history - contains route history
  const history = useHistory();

  const userInfo = useSelector(userInfoSelector, shallowEqual);
  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  const {
    is_ve_addon_purchased: isVEAddOnPurchased = false,
    is_virtual_addon_enabled: isVEAddOnEnabled = 0,
    ve_addon_qty: veAddOnQty = 0,
  } = userInfo.UserInfo;
  const planName = userInfo.UserInfo.subscription_meta_id ? userInfo.UserInfo.planName : '';
  let veToolTipTxt = '';
  let webinarToolTipTxt = '';

  if (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) {
    veToolTipTxt = !isVEAddOnPurchased
      ? 'To create virtual event, you need to purchase virtual event credit.'
      : veAddOnQty < 1
      ? 'You have exhausted all your credits. Purchase more to create virtual event.'
      : '';
  } else {
    veToolTipTxt = !isVEAddOnEnabled ? 'To create virtual event contact support.' : '';
  }

  if (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) {
    webinarToolTipTxt =
      actionType === EActionType.EDIT && props.value === EEventFormat.VIRTUAL
        ? 'You have to create a new event to change event format.'
        : '';
  }

  return (
    <Box className={classes.planBoxContainer}>
      <Grid container spacing={2}>
        {props.options.map((option, i) => (
          <Grid
            item
            xs={6}
            className={`eachEventItem ${option.disabled ? 'disabled' : ''}`}
            onClick={() => !option.disabled && props.handleChange(option.value)}>
            <CustomToolTip
              title={option.value === EEventFormat.VIRTUAL ? veToolTipTxt : webinarToolTipTxt}
              placement="top"
              arrow>
              <Box
                className={`eachPlanDiv ${props.value} ${
                  props.value === option.value ? 'active' : ''
                } ${option.disabled ? 'disabled' : ''}`}
                disabled={option.disabled}>
                <Box display="flex" alignItems="center">
                  <Box className="mainIcon">{option.isSvgIcon && option.isSvgIcon}</Box>
                  <Box ml={1.5}>
                    <Typography component="h4" className="title" variant="subtitle1">
                      {option.label}
                    </Typography>
                    <Typography
                      variant={props.value === option.value ? 'body2' : 'colorTextSecondary'}
                      component="p">
                      {option.secondaryText}
                    </Typography>
                  </Box>
                </Box>
                <List className="eachPlanMenu">
                  {option.features.map((eachFeature) => (
                    <ListItem className="eachItem">
                      <ListItemIcon>{eachFeature.icon}</ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ variant: 'body2' }}
                        primary={eachFeature.text}
                      />
                    </ListItem>
                  ))}
                </List>
                {actionType === EActionType.CREATE &&
                option.value === EEventFormat.VIRTUAL &&
                (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
                veAddOnQty > 0 ? (
                  <Typography variant="body2" component="label" className="stripe">
                    {`${veAddOnQty} credits available`}
                  </Typography>
                ) : null}
              </Box>
            </CustomToolTip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
