/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
export const isSingleFrameCount = (string = '') => {
  const re = /<([a-z]+)(?=[\s>])(?:[^>=]|='[^']*'|="[^"]*"|=[^'"\s]*)*\s?\/?>/gi;
  let m;
  let HashTable = {};

  let count = 0;

  do {
    // conduct the match
    m = re.exec(string);

    // verify the match was successful
    if (m) {
      // verify the HashTable has an entry for the found tag name
      if (!(m[1] in HashTable)) {
        // no entry was found so we'll add the entry for this tag name and count it as zero
        HashTable[m[1]] = 0;
      } // end if

      // increment the tag name counter
      HashTable[m[1]]++;
    } // end if
  } while (m);

  // output the number of all found tag names
  for (const key in HashTable) {
    if (key === 'iframe') {
      count = HashTable[key];
    }
  }
  return count === 1;
};
