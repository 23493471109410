/* eslint-disable */
import React, { Suspense, lazy, useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom';
import Authentication from './pages/authentication';
import Login from './pages/authentication/login';
import Layout from './pages/layout';
import SocialLogin from 'pages/authentication/socialLogin';
import SignUp from './pages/authentication/signUpOrga';
import PreLogin from './pages/authentication/preLoginOrga';
import OnBoardingOrg from './pages/authentication/onBoardingOrga';
import ForgotPassword from './pages/authentication/forgotPassword';
import ResetPassword from './pages/authentication/resetPassword';
import VerifyToken from './pages/authentication/verifyToken';
import VerifyForgotToken from 'pages/authentication/verifyForgotToken';
import SocialRegister from 'pages/authentication/socialRegister';
import VerifyTeamAccount from './pages/authentication/verifyTeamAccount';
import cookiePolicyComp from 'custom-components/cookie-policy/cookiePolicyComp';
import {
  checkIsLoggedIn,
  getUserCredential,
  getUserPermission,
  getOnboardingDataCollectionDone,
  setOnboardingDataCollectionDone,
  setSurveyCookie,
  getSurveyCookie,
} from 'lib/cookies';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  commonSelector,
  userInfoSelector,
  eventDataSelector,
  currentEventLanguagesSelector,
  templatePreviewModalDetailsSelector,
  templateModalDetailsSelector,
  CampaignStatusSelector,
  basicInfoSelector,
} from 'redux/custom-selector';
import { API, GETAPI, GETAPIWITHCUSTOMDOMAIN, POSTAPI } from 'api-setup/api-helper';
import {
  USER_INFO1,
  EVENT_OVERVIEW_API,
  GET_EVENT_LANG_SETTINGS,
  ADMIN_LOGIN,
} from 'api-setup/api-endpoints';
import { useSource, usePrevious } from 'lib/custom-common-hooks';
import { setUserInfo } from 'redux/actions/user-action';
import { setEventData } from 'redux/actions/event-action';
import PreLoader from 'custom-components/preloader';
import { setUserCredential } from 'lib/cookies.js';
import ErrorPage from 'pages/errorPage';
import ProjectSetting from 'settings';
import amplitude from 'lib/amplitude';
import { eventProperties } from 'lib/amplitude/init';
import { setEventLanguages } from 'redux/actions/event-languages-action';
import projectSetting from 'settings';
import { setCurrentEventLanguage } from 'redux/actions/current-event-language-action';
import { setBaseEventLanguage } from 'redux/actions/base-language-action';
import { browserSignatureStorageHandler } from 'utility/browserSignature';
import CookiePolicyModal from './custom-components/cookie-policy';
// import Timer from './custom-components/timer';
import { cookieConsentSelector, getIsCreditsTabVisible } from 'redux/custom-selector';

//const ErrorPage = lazy(() => import('pages/errorPage'));
import TemplatePreview from './pages/emailBuilder/previewModal';
import TemplateFullScreenModal from './pages/emailBuilder/templateFullscreenModal';
import EmailDisable from './pages/emailBuilder/emailDisable';
import { PricingPlan } from 'constants/pricingPlan';
import ChooseTemplate from 'pages/registration/landing-page/chooseTemplate';
import CreateEditEventContainer from 'pages/CreateNewEvent/CreateEditEventContainer';
import { InPersonNav } from 'pages/events/InPersonNavModule/InPersonNav';
import { EventTypes } from 'pages/events/constants';
import QuizQuestionsList from 'pages/engagement/quiz/quizQuestions/quizQuestionsList';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';
import profileFieldsNewUI from 'pages/settings/profileFieldsNewUI';
import LeadScoreAnalytics from 'pages/analytics/LeadScoreAnalytics';
import { getParameterByName, getSurveyRedirection } from 'constants/common';

const WebinarLandingPageSettings = lazy(() =>
  import('pages/registration/webinar-registration-setup-form/WebinarLandingPageSettings')
);

const BasicDetails = lazy(() => import('pages/session/basicDetails'));
const ConversionPrompts = lazy(() => import('pages/session/ConversionPrompts'));
const VirtualBooth = lazy(() => import('pages/virtual-booth/virtualBooth'));
const VirtualBoothsDetails = lazy(() => import('pages/virtual-booth/boothsDetails'));
const BoothBasicDetails = lazy(() => import('pages/virtual-booth/boothBasicDetails'));
const ProductImages = lazy(() => import('pages/virtual-booth/productImages'));
const ProductVideos = lazy(() => import('pages/virtual-booth/productVideos'));
const CustomCta = lazy(() => import('pages/virtual-booth/customCta'));
const EngagementBooth = lazy(() => import('pages/virtual-booth/engagementBooth'));
const TeamMembers = lazy(() => import('pages/virtual-booth/teamMembers'));
const Meeting = lazy(() => import('pages/meeting/meeting'));
const BrandYourEvent = lazy(() => import('pages/brand-your-event/brandYourEvent/brandYourEvent'));
const LeaderBoard = lazy(() => import('pages/engagement/leaderboard/leaderBoard'));
const EngagementSurvey = lazy(() => import('pages/engagement/survey/engagementSurvey'));
const EngagementLeadScore = lazy(() => import('pages/engagement/lead-score'));
const EngagementSurveyResponse = lazy(() => import('pages/engagement/survey/surveyResponse'));
const NotificationPage = lazy(() => import('pages/engagement/notification/notification'));
const ActivityFeed = lazy(() => import('pages/engagement/activityFeed/activityFeed'));
const Lounge = lazy(() => import('pages/lounge/lounge'));
const Account = lazy(() => import('./pages/account/account'));
const UserProfile = lazy(() => import('./pages/account/profile'));
const Team = lazy(() => import('pages/team-member/team'));
const Roles = lazy(() => import('pages/team-member/roles'));
const UserPassword = lazy(() => import('./pages/account/password'));
const Billing = lazy(() => import('./pages/Billing/billing'));
const Payout = lazy(() => import('./pages/payout/payout'));
const SessionDetails = lazy(() => import('./pages/session/sessionDetails'));
const EventList = lazy(() => import('./pages/events/eventList/events'));
const RegFlow = lazy(() => import('./pages/NewRegistrationFlow/index'));
const CreateCopyEvent = lazy(() => import('./pages/events/copyEvent/createCopyEvent'));
const Contact = lazy(() => import('./pages/contact/contacts'));
// const ROI = lazy(() => import('./pages/roi/roi'));
const ContactProfile = lazy(() => import('./pages/contact/contactProfile'));
const Ticket = lazy(() => import('pages/registration/ticket/ticket'));
const TicketDiscount = lazy(() => import('pages/registration/discount/discount'));
const Order = lazy(() => import('pages/registration/order/order'));
const TicketAddForV2 = lazy(() => import('pages/registration/ticket/newUI/create/ticketCreation'));
const UTMContainer = lazy(() => import('pages/registration/utm/UTMContainer'));

const Sessions = lazy(() => import('./pages/session/sessions/SessionsPage'));
const EventDetail = lazy(() => import('./pages/events/eventDetails/eventDetails'));
const Attendee = lazy(() => import('pages/session/sessionAttendee'));
const Survey = lazy(() => import('pages/session/survey'));
const Contests = lazy(() => import('pages/engagement/contest/contest'));
const Quiz = lazy(() => import('pages/engagement/quiz/quiz.jsx'));
const QuizContainer = lazy(() => import('pages/engagement/quiz/QuizContainer'));
const QuizResponses = lazy(() => import('pages/engagement/quiz/quizResponses'));
const Questions = lazy(() => import('pages/engagement/contest/questions/questions'));
const Standings = lazy(() => import('pages/engagement/contest/standings'));
const Moderates = lazy(() => import('pages/engagement/contest/moderates'));
const Entries = lazy(() => import('pages/engagement/contest/entries/entries'));
const Responses = lazy(() => import('pages/engagement/contest/responses'));
const Winners = lazy(() => import('pages/engagement/contest/winners'));
const Response = lazy(() => import('pages/engagement/contest/response'));
const People = lazy(() => import('pages/people/people'));
const StreamSession = lazy(() => import('pages/session/streamSessionDetails'));
const AdvanceSession = lazy(() => import('pages/session/advancedSessionDetails'));
const CepCreditsDetails = lazy(() => import('pages/session/cepCreditsDetails'));
const Settings = lazy(() => import('pages/settings/settings'));
const GeneralSetting = lazy(() => import('pages/settings/generalSetting'));
const LoginOptions = lazy(() => import('pages/settings/loginOptions'));
const Groups = lazy(() => import('pages/settings/groups'));
const Privacy = lazy(() => import('pages/settings/privacy'));
const Seo = lazy(() => import('pages/settings/seo'));
const VirtualBackground = lazy(() => import('pages/settings/VirtualBackground'));
const Integration = lazy(() => import('pages/settings/integration'));
const Legal = lazy(() => import('pages/settings/legal'));
const EventSections = lazy(() => import('pages/settings/eventSections'));
const CreditsCertificate = lazy(() =>
  import('pages/settings/creditsCertificate/creditsCertificate')
);
const WelcomeMailer = lazy(() => import('pages/settings/welcomeMailer'));
const EngagementSession = lazy(() => import('pages/session/sessionEngagementDetails'));
const EmbedApps = lazy(() => import('pages/embed-apps/embedApps'));

const ProfileFields = lazy(() => import('pages/settings/profileFields'));
const SenderDetails = lazy(() => import('pages/settings/senderDetails'));
const EmailFiltering = lazy(() => import('pages/settings/emailFiltering'));
const Notification = lazy(() => import('pages/settings/notification'));
const StudioHosts = lazy(() => import('pages/settings/studioHosts'));
const MatchMakingFields = lazy(() => import('pages/settings/matchMakingFields'));
const Rooms = lazy(() => import('pages/rooms/rooms'));
const FileLogs = lazy(() => import('pages/file-logs/fileLogs'));
const Analytics = lazy(() => import('pages/analytics/analytics'));
const AnalyticsAttendee = lazy(() => import('pages/analytics/People/people'));
const AnalyticsNetworking = lazy(() => import('pages/analytics/networking'));
const AnalyticsFeed = lazy(() => import('pages/analytics/feed'));
const AnalyticsSpeaker = lazy(() => import('pages/analytics/speaker'));
const AnalyticsVirtualBooth = lazy(() => import('pages/analytics/virtualBooth'));
// const AnalyticsContest = lazy(() => import('pages/analytics/contests'));
const AnalyticsSession = lazy(() => import('pages/analytics/Session/session'));
const AnalyticsRoom = lazy(() => import('pages/analytics/room'));
const AnalyticsRegistration = lazy(() => import('pages/analytics/registration/registration'));
// const AnalyticsSurvey = lazy(() => import('pages/analytics/surveyAnalytics'));
const Recording = lazy(() => import('pages/recording/recording'));
const UpgradePlan = lazy(() => import('pages/billing-and-pricing/payoutPlan'));
const EmailCampaign = lazy(() => import('pages/email-campaign/email-campaign'));
const Integrations = lazy(() => import('pages/salesforce/integrations'));
const GlobalIntegration = lazy(() => import('pages/integrations/GlobalIntegration/Details'));
const GIAfterConnection = lazy(() => import('pages/integrations/GlobalIntegration/AfterConnection'));
const Tickets = lazy(() => import('pages/settings/tickets'));
const ContestDetails = lazy(() => import('pages/engagement/contest/contestDetails'));
// const Campaign = lazy(() => import('pages/email-campaign/campaign'));
const MultiEventAnalytics = lazy(() => import('pages/multiEventAnalytics/multiEventAnalytics'));
const OverViewAnalytics = lazy(() => import('pages/multiEventAnalytics/overviewAnalytics'));
const RevenueAnalytics = lazy(() => import('pages/multiEventAnalytics/revenueAnalytics'));
const TopAnalytics = lazy(() => import('pages/multiEventAnalytics/topAnalytics'));
const EngagementAnalytics = lazy(() => import('pages/multiEventAnalytics/engagementAnalytics'));
const EngagementLeadScoreAnalytics = lazy(() => import('pages/analytics/LeadScoreAnalytics'));
const SponsorBrandDetails = lazy(() =>
  import('pages/brand-your-event/sponsorBrandDetails/sponsor-brand-details')
);
const SponsorBrand = lazy(() => import('pages/brand-your-event/sponsor-brand'));
const EmailBuilder = lazy(() => import('pages/emailBuilder/emailBuilder'));
const CreateEmail = lazy(() => import('pages/emailBuilder/create-email/createEmail'));
const ViewReport = lazy(() => import('pages/emailBuilder/view-report/viewReport'));
const LandingPage = lazy(() => import('pages/registration/landing-page/landingPage'));

// const SSO = lazy(()=>import('pages/account/sso'));
const LangSupport = lazy(() => import('pages/settings/languageSupport'));
const Mentions = lazy(() => import('pages/settings/Tagging/mentions'));
const DataMapping = lazy(() => import('pages/dataMapping/dataMapping'));
const DataMapEvents = lazy(() => import('pages/dataMapping/dataMapEvents'));
const DataMapStatus = lazy(() => import('pages/dataMapping/dataMapStatus'));
const DataMapSettings = lazy(() => import('pages/dataMapping/dataMapSettings'));
const ManageReport = lazy(() => import('pages/analytics/manage-reports/index'));
const WebinarEngagementAnalytics = lazy(() =>
  import('pages/analytics/webinarEngagementAnalytics/index')
);

const WebinarRegistrationForm = lazy(() =>
  import('pages/registration/webinar-registration-setup-form/WebinarRegistrationForm')
);

const ConnectedApps = lazy(() => import('pages/integrations/connectedAppsPage'));
const ConfigureEventData = lazy(() => import('pages/integrations/configureIntegratedApp'));
const ConfigureZoomApp = lazy(() => import('pages/integrations/zoomApp/configureZoomApp'));

const CreateEditZoomContainer = lazy(() =>
  import('pages/integrations/zoomApp/createEditZoomContainer')
);
const ZoomSyncPage = lazy(() => import('pages/integrations/zoomApp/zoomLastPage'));

const CUSTOM_URL = ProjectSetting.customBaseURL;
const CUSTOM_URL2 = ProjectSetting.customBaseURL2;

function PrivateRoute({
  component: Component,
  hasNotHeader,
  hasSidebarNav,
  mustUserData,
  mustUserAndEventData,
  ...rest
}) {
  const isLoggedIn = checkIsLoggedIn();
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const prevUserData = usePrevious(reduxData.userId);
  const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
    'isOnboardingDataCollectionDone'
  );

  //	const reduxData = useSelector(commonSelector, shallowEqual);
  const permission = getUserPermission();
  const moduleId = rest.moduleId ? rest.moduleId : 0;
  let selectedIdx = -1;
  if (permission && permission.selectedEventSections) {
    selectedIdx = permission.selectedEventSections.findIndex((x) => x === moduleId);
  }
  let selModuleId = 0;
  if (selectedIdx !== -1) {
    selModuleId = permission.selectedEventSections[selectedIdx];
  }

  if (window.location.search.length > 0 && prevUserData && reduxData) {
    window.location.replace('/');
  }

  useEffect(() => {
    if (reduxData.organiserId && isLoggedIn) {
      amplitude.Track.setAmplitudeUserId(reduxData.organiserId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.path]);

  useEffect(() => { }, [userInfo.UserInfo.subscriptionStatus]);

  const redirectLink = getSurveyRedirection();

  return isOnboardingDataCollectionDone.toString() === '1' ? (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn &&
          mustUserAndEventData &&
          (permission.isEventAdmin.toString() === '1' ||
            permission.isSuperAdmin.toString() === '1' ||
            moduleId === 0 ||
            moduleId === selModuleId) ? (
          <NavigateWrapper mustUserData {...props}>
            <Layout hasNotHeader={hasNotHeader} hasSidebarNav={hasSidebarNav}>
              <EventWrapper mustEventData {...props}>
                <Suspense fallback={<div />}>
                  <Component {...props} />
                </Suspense>
              </EventWrapper>
            </Layout>
          </NavigateWrapper>
        ) : isLoggedIn &&
          mustUserData &&
          (permission.isEventAdmin.toString() === '1' ||
            permission.isSuperAdmin.toString() === '1' ||
            moduleId === 0 ||
            moduleId === selModuleId) ? (
          <NavigateWrapper mustUserData {...props}>
            <Layout hasNotHeader={hasNotHeader} hasSidebarNav={hasSidebarNav}>
              <Suspense fallback={<div />}>
                <Component {...props} />
              </Suspense>
            </Layout>
          </NavigateWrapper>
        ) : isLoggedIn ? (
          permission.isEventAdmin.toString() === '1' ||
            permission.isSuperAdmin.toString() === '1' ||
            moduleId === 0 ||
            moduleId === selModuleId ? (
            <Layout hasNotHeader={hasNotHeader} hasSidebarNav={hasSidebarNav}>
              <Suspense fallback={<div />}>
                <Component {...props} />
              </Suspense>
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: redirectLink,
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  ) : isLoggedIn ? (
    <>
      <Route
        {...rest}
        render={(props) => (
          <NavigateWrapper mustUserData {...props}>
            <Layout hasNotHeader={hasNotHeader} hasSidebarNav={hasSidebarNav}>
              <Suspense fallback={<div />}>
                <OnBoardingOrg {...props} />
              </Suspense>
            </Layout>
          </NavigateWrapper>
        )}
      />
    </>
  ) : (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )}
    />
  );
}
function PublicRoute({ component: Component, hasAuthBanner, noAuth, ...rest }) {
  const isLoggedIn = checkIsLoggedIn();
  const source = useSource();
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectLink = getSurveyRedirection();

  const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
    'isOnboardingDataCollectionDone'
  );

  useEffect(() => {
    if (rest.location.pathname === '/orglogin') {
      const params = new URLSearchParams(rest.location.search);
      const authCode = params.get('token');
      if (authCode) {
        let header = {
          Authorization: `Bearer ${authCode}`,
        };
        POSTAPI(false, ADMIN_LOGIN, source.AS, header, null)
          .then((res) => {
            if (res.data.status === API.apiSuccessStatus) {
              const orgId = res.data.data.organiser_id;
              const authToken = res.data.data.token;
              setOnboardingDataCollectionDone(
                res.data.data.isOnboardingDataCollectionDone,
                'isOnboardingDataCollectionDone'
              );
              setUserCredential(
                {
                  authToken: authToken,
                  teamMemberId: res.data.data.team_member_id,
                  userPermission: res.data.data.userPermission,
                  organiser_id: orgId,
                },
                'userData'
              );
              // dispatch(setUserInfo(res?.data?.data?.userData));
              if (
                res?.data?.data?.userData?.planName === PricingPlan.ENTERPRISE ||
                res?.data?.data?.userData?.planName === PricingPlan.PRO
              )
                dispatch(setUserInfo({ ...res?.data?.data?.userData, isCustomEnabled: 1 }));
              else dispatch(setUserInfo(res?.data?.data?.userData));

              if (isOnboardingDataCollectionDone.toString() === '1') {
                history.replace(redirectLink); // event page redirection if login API success
              } else {
                history.replace('/onboarding');
              }
            } else {
              API.errStatusHandler(res, history, dispatch);
            }
          })
          .catch(API.catchHandler);
      }
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        noAuth && isLoggedIn ? (
          isOnboardingDataCollectionDone.toString() === '1' ? (
            // <Redirect
            //   to={{
            //     pathname: '/events',
            //     state: { from: props.location },
            //   }}
            // />
            (history.push({
              pathname: redirectLink,
              state: { from: props.location },
            }),
              window.location.reload())
          ) : (
            <Redirect
              to={{
                pathname: '/onboarding',
                state: { from: props.location },
              }}
            />
          )
        ) : hasAuthBanner ? (
          <Authentication>
            <Component {...props} />
          </Authentication>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

function SubRouteNotFound({ component: Component, hasAuthBanner, noAuth, ...rest }) {
  return <Redirect to="/pagenotfound" />;
}

function TeamLoginRoute({ component: Component, hasAuthBanner, noAuth, ...rest }) {
  const isLoggedIn = checkIsLoggedIn();
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  const redirectLink = getSurveyRedirection();
  const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
    'isOnboardingDataCollectionDone'
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        noAuth && isLoggedIn ? (
          isOnboardingDataCollectionDone.toString() === '1' ? (
            <Redirect
              to={{
                pathname: redirectLink,
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/onboarding',
                state: { from: props.location },
              }}
            />
          )
        ) : hasAuthBanner ? (
          <Authentication>
            <Component {...props} />
          </Authentication>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

function NavigateWrapper({ history, mustUserData, children }) {
  const source = useSource();
  const dispatch = useDispatch();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const prevUserData = usePrevious(reduxData.userId);
  const [isLoading, setLoad] = React.useState(true);

  React.useEffect(() => {
    if (prevUserData && !reduxData.userId) {
      history.replace('/login');
    } else if (mustUserData && !reduxData.userId) {
      setLoad(true);
    } else setLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustUserData, reduxData.userId]);

  React.useEffect(() => { }, [userInfo.UserInfo.subscriptionStatus]);

  React.useEffect(() => {
    const cookiesData = getUserCredential();
    const orgId =
      cookiesData && cookiesData.organiser_id
        ? cookiesData.organiser_id
        : reduxData.organiserId
          ? reduxData.organiserId
          : reduxData.userId;
    source.AS = new Map();
    const [headers, body] = API.generateHeader(
      reduxData,
      {
        teamMemberId: cookiesData && cookiesData.teamMemberId ? cookiesData.teamMemberId : 0,
        organiserId: orgId,
      },
      null
    );

    if (window.location.search.length > 0) {
      window.history.replaceState(null, '', window.location.href.split('?')[0]);
    }
    GETAPIWITHCUSTOMDOMAIN(false, USER_INFO1, source.AS, headers, body, CUSTOM_URL)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          setOnboardingDataCollectionDone(
            resp.data.data.isOnboardingDataCollectionDone,
            'isOnboardingDataCollectionDone'
          );
          setUserCredential(
            {
              authToken: cookiesData.authToken,
              teamMemberId: cookiesData.teamMemberId,
              userPermission: resp.data.data.userPermission,
              organiser_id: orgId,
            },
            'userData'
          );
          if (
            resp?.data?.data?.planName === PricingPlan.ENTERPRISE ||
            resp?.data?.data?.planName === PricingPlan.PRO
          )
            dispatch(setUserInfo({ ...resp?.data?.data, isCustomEnabled: 1 }));
          else dispatch(setUserInfo(resp?.data?.data));
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    return () => API.removeAllApi(source.AS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.UserInfo.id, userInfo.UserInfo.subscriptionStatus]);

  if (isLoading) {
    return <Layout isLoading />;
  }
  return <>{children}</>;
}

function EventWrapper({ history, match, mustEventData, children }) {
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const dispatch = useDispatch();
  const reduxData = useSelector(eventDataSelector, shallowEqual);
  const prevParams = usePrevious(match.params);
  const [isLoading, setLoad] = React.useState(true);
  const [isApiLoading, setApiLoad] = React.useState(false);
  const source = useSource();
  const [headers, body] = API.generateHeader(reduxData, null, null);
  const CUSTOM_URL = projectSetting.customBaseURL2;
  const [currentLang, setCurrentLang] = React.useState();
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);
  const redirectLink = getSurveyRedirection();

  useEffect(() => {
    if (reduxData.eventId) {
      GETAPIWITHCUSTOMDOMAIN(false, GET_EVENT_LANG_SETTINGS, source.AS, headers, body, CUSTOM_URL)
        .then((res) => {
          let baseLanguage = {
            id: 34,
            img_file_name: 'united-kingdom.svg',
            img_file_name_png: 'united-kingdom.png',
            name: 'English',
            native_name: 'English',
            short_code: 'EN',
          };
          if (res.data.status === API.apiSuccessStatus) {
            let eventLangs = [];
            res.data.eventLanaguages.forEach((item) => {
              if (item.is_base === 1) {
                baseLanguage = item;
              }
              if (item.is_base !== null) {
                eventLangs.push(item);
              }
            });
            dispatch(setEventLanguages(eventLangs));
            dispatch(setCurrentEventLanguage(baseLanguage));
            dispatch(setBaseEventLanguage(baseLanguage));
          } else {
            API.errStatusHandler(res, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    }
  }, [reduxData.eventId]);

  const getEventOverview = (eventData, currentLanguage) => {
    setApiLoad(true);
    const [headers] = API.generateHeader(reduxData, eventData);
    GETAPI(false, EVENT_OVERVIEW_API, null, { ...headers, language: currentLanguage.id })
      .then((resp) => {
        setApiLoad(false);
        const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
          'isOnboardingDataCollectionDone'
        );
        if (resp.data.status === API.apiSuccessStatus) {
          if (isOnboardingDataCollectionDone.toString() === '0') {
            history.replace('/onboarding');
          } else {
            eventProperties.eventId = resp.data.data.eventinfo.id;
            eventProperties.eventName = resp.data.data.eventinfo.name;
            eventProperties.organiserId = resp.data.data.eventinfo?.organiser_id || '';

            // dispatch(
            //   setEventData({
            //     ...resp.data.data.eventinfo,
            //     isCustomEnabled: 1,
            //   })
            // );

            dispatch(setEventData(resp.data.data.eventinfo));
          }
        } else {
          API.errStatusHandler(resp, history, dispatch, (_, status) => {
            if (status === 400) {
              if (isOnboardingDataCollectionDone.toString() === '0') {
                history.replace('/onboarding');
              } else {
                history.replace(redirectLink);
              }
            }
          });
        }
      })
      .catch(API.catchHandler);
  };

  React.useEffect(() => {
    if (ProjectSetting.PreRecordedVideoUpload && ProjectSetting.PreRecordedVideoUpload !== null) {
      ProjectSetting.PreRecordedVideoUpload.pause();
      ProjectSetting.PreRecordedVideoUpload = null;
    } else {
      ProjectSetting.PreRecordedVideoUpload = null;
    }
    const { params } = match;
    if (mustEventData) {
      if (!isApiLoading) {
        if (!prevParams && params.eventId && params.organiserId) {
          // firstTime
          setLoad(true);
          getEventOverview(params, currentEventLanguage);
        } else if (
          prevParams &&
          prevParams.eventId &&
          prevParams.organiserId &&
          params.eventId &&
          params.organiserId &&
          (prevParams.eventId !== params.eventId || prevParams.organiserId !== params.organiserId)
        ) {
          // eventid or organiserid change in url
          setLoad(true);
          getEventOverview(params, currentEventLanguage);
        } else if (
          reduxData.eventId &&
          reduxData.organiserId &&
          !reduxData.EventData.start_time_milli
        ) {
          // click on event card before basicinfo api
          setLoad(false);
          getEventOverview(params, currentEventLanguage);
        } else if (currentEventLanguage !== currentLang && reduxData.eventId) {
          getEventOverview(params, currentEventLanguage);
          setCurrentLang(currentEventLanguage);
        } else if (
          reduxData.eventId &&
          reduxData.organiserId &&
          reduxData.EventData.start_time_milli
        ) {
          // click on event card after basicinfo api
          setLoad(false);
        }
      }
    } else {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mustEventData,
    reduxData.eventId,
    match.params,
    prevParams,
    isApiLoading,
    currentEventLanguage,
  ]);
  if (isLoading) {
    return <PreLoader />;
  }
  return <>{children}</>;
}
const rootRender = () => {
  const isLoggedIn = checkIsLoggedIn();
  const isOnboardingDataCollectionDone = getOnboardingDataCollectionDone(
    'isOnboardingDataCollectionDone'
  );
  return isLoggedIn ? (
    isOnboardingDataCollectionDone.toString() === '1' ? (
      <Redirect to="/events" />
    ) : (
      <Redirect to="/onboarding" />
    )
  ) : (
    <Redirect to="/login" />
  );
};

const UserAccount = (props) => {
  if (props.match.path === '/account' && props.match.isExact) {
    props.history.push(`${props.match.url}/profile`);
  }
  return (
    <Account {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route moduleId={0} exact path="/account/profile" component={UserProfile} />
          <Route moduleId={0} exact path="/account/password" component={UserPassword} />
          {/* <Route moduleId={0} exact path='/account/sso' component={SSO} /> */}
          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
          {/* <Route path='/event/:eventId/:organiserId/session/:sessionId/virtual-booth/basic-details' component={VirtualBooth} /> */}
        </Switch>
      </Suspense>
    </Account>
  );
};

const SessionRoute = (props) => {
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const isCreditsTabVisible = useSelector(getIsCreditsTabVisible, shallowEqual);
  if (
    props.match.path === '/event/:eventId/:organiserId/session/:sessionId' &&
    props.match.isExact
  ) {
    props.history.push(`${props.match.url}/basic-details`);
  }
  return (
    <SessionDetails {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/basic-details"
            component={BasicDetails}
          />
          {eventData?.EventData?.is_conversion_prompt_enabled === 1 && (
            <Route
              moduleId={3}
              exact
              path="/event/:eventId/:organiserId/session/:sessionId/conversion-prompts"
              component={ConversionPrompts}
            />
          )}
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/stream-option"
            component={StreamSession}
          />
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/engagement"
            component={EngagementSession}
          />
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/advance-session"
            component={AdvanceSession}
          />
          {
            [null, true].includes(isCreditsTabVisible) && (
              <Route
                moduleId={3}
                exact
                path="/event/:eventId/:organiserId/session/:sessionId/credits-details"
                component={CepCreditsDetails}
              />
            )
          }
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/attendees"
            component={Attendee}
          />
          <Route
            moduleId={3}
            exact
            path="/event/:eventId/:organiserId/session/:sessionId/survey"
            component={Survey}
          />
          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
          {/* <Route path='/event/:eventId/:organiserId/session/:sessionId/virtual-booth/basic-details' component={VirtualBooth} /> */}
        </Switch>
      </Suspense>
    </SessionDetails>
  );
};
const VirtualRouteDetail = (props) => {
  if (
    props.match.path === '/event/:eventId/:organiserId/virtual-detail/:virtualId' &&
    props.match.isExact
  ) {
    props.history.push(`${props.match.url}/basic-detail`);
  }
  return (
    <VirtualBoothsDetails {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/basic-detail"
            component={BoothBasicDetails}
          />
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/team-members"
            component={TeamMembers}
          />
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/product-images"
            component={ProductImages}
          />
          {/* <Route exact path='/event/:eventId/:organiserId/virtual-detail/:virtualId/product-images' component={ProductImagesUpgrade} /> */}
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/product-videos"
            component={ProductVideos}
          />
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/custom-cta"
            component={CustomCta}
          />
          <Route
            moduleId={5}
            exact
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId/engagement-option"
            component={EngagementBooth}
          />
          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
        </Switch>
      </Suspense>
    </VirtualBoothsDetails>
  );
};

const ContestRouteDetail = (props) => {
  if (
    props.match.path ===
    '/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId' &&
    props.match.isExact
  ) {
    props.history.push(`${props.match.url}/questions`);
  }
  return (
    <ContestDetails {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/questions"
            component={Questions}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/standings"
            component={Standings}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/:moderateOn/moderates"
            component={Moderates}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/responses"
            component={Responses}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/entries"
            component={Entries}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/winners"
            component={Winners}
          />
          <Route
            moduleId={9}
            exact
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId/responses"
            component={Response}
          />
          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
        </Switch>
      </Suspense>
    </ContestDetails>
  );
};

const AnalyticsRoute = (props) => {
  if (props.match.path === '/event/:eventId/:organiserId/analytics' && props.match.isExact) {
    props.history.push(`${props.match.url}/people`);
  }
  const eventBasicInfo = useSelector(basicInfoSelector, shallowEqual);

  return (
    <Analytics {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/people"
            component={AnalyticsAttendee}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/feed"
            component={AnalyticsFeed}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/registration"
            component={AnalyticsRegistration}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/lead-score"
            component={LeadScoreAnalytics}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/networking"
            component={AnalyticsNetworking}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/speakers"
            component={AnalyticsSpeaker}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/sessions"
            component={AnalyticsSession}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/virtual-booths"
            component={AnalyticsVirtualBooth}
          />
          {/* <Route exact path='/event/:eventId/:organiserId/analytics/contests' component={AnalyticsContest} /> */}
          {/* <Route exact path='/event/:eventId/:organiserId/analytics/survey' component={AnalyticsSurvey} /> */}
          <Route
            exact
            path="/event/:eventId/:organiserId/analytics/rooms"
            component={AnalyticsRoom}
          />
          <Route
            moduleId={12}
            exact
            path="/event/:eventId/:organiserId/analytics/managereports"
            component={ManageReport}
          />
          {eventBasicInfo.eventType === EventTypes.WEBINAR && (
            <Route
              moduleId={12}
              exact
              path="/event/:eventId/:organiserId/analytics/engagement-analytics"
              component={WebinarEngagementAnalytics}
            />
          )}
          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
        </Switch>
      </Suspense>
    </Analytics>
  );
};

const MultiEventAnalyticsRoute = (props) => {
  if (props.match.path === '/analytics' && props.match.isExact) {
    props.history.push(`${props.match.url}/overview-analytics`);
  }
  const redirectLink = getSurveyRedirection();
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  return userInfo.UserInfo.userPermission.canManageAnalytics ||
    0 ||
    userInfo.UserInfo.userPermission.isSuperAdmin ||
    0 ? (
    <MultiEventAnalytics {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/analytics/overview-analytics" component={OverViewAnalytics} />
          <Route exact path="/analytics/revenue-analytics" component={RevenueAnalytics} />
          <Route exact path="/analytics/top-analytics" component={TopAnalytics} />
          <Route exact path="/analytics/engagement-analytics" component={EngagementAnalytics} />
        </Switch>
      </Suspense>
    </MultiEventAnalytics>
  ) : (
    <Redirect
      to={{
        pathname: redirectLink,
        state: { from: props.location },
      }}
    />
  );
};

const SetupFormRoute = (props) => {
  if (
    props.match.path === '/event/:eventId/:organiserId/registration/setup-form' &&
    props.match.isExact
  ) {
    props.history.push(`${props.match.url}/form-fields`);
  }
  return (
    <WebinarRegistrationForm {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <PrivateRoute
            exact
            mustUserAndEventData
            moduleId={2}
            path="/event/:eventId/:organiserId/registration/setup-form/form-fields"
            component={profileFieldsNewUI}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/setup-form/landing-page-settings"
            component={WebinarLandingPageSettings}
          />
          {/* <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} /> */}
        </Switch>
      </Suspense>
    </WebinarRegistrationForm>
  );
};

const SettingRoute = (props) => {
  const reduxData = useSelector(eventDataSelector, shallowEqual);
  const eventBasicInfo = useSelector(basicInfoSelector, shallowEqual);
  const organiserInfo = useSelector(commonSelector, shallowEqual);

  if (props.match.path === '/event/:eventId/:organiserId/settings' && props.match.isExact) {
    const defaultRoute =
      eventBasicInfo.eventType === EEventFormat.WEBINAR ? '/login-options' : '/general';
    props.history.push(`${props.match.url}${defaultRoute}`);
  }
  return (
    <Settings {...props}>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/general"
            component={GeneralSetting}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/login-options"
            component={LoginOptions}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/tickets"
            component={Tickets}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/event-sections"
            component={EventSections}
          />
          {(reduxData.EventData.is_cep_enabled === 1 ||
            organiserInfo?.subscription_name?.toLowerCase() === 'enterprise') && (
              <Route
                moduleId={13}
                exact
                path="/event/:eventId/:organiserId/settings/credits-certificate"
                component={
                  eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : CreditsCertificate
                }
              />
            )}
          {/* <Route
            moduleId={13}
            path="/event/:eventId/:organiserId/settings/payout-method"
            component={PayOutMethod}
          /> */}
          {/* 	<Route path='/event/:eventId/:organiserId/settings/tax-and-invoicing' component={TaxInvoicing} /> */}
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/groups"
            component={Groups}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/profile-fields"
            component={ProfileFields}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/studio-hosts"
            component={
              eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : StudioHosts
            }
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/matchmaking-fields"
            component={MatchMakingFields}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/sender-details"
            component={SenderDetails}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/email-filtering"
            component={EmailFiltering}
          />
          {reduxData?.EventData?.is_email_builder ? (
            false
          ) : (
            <Route
              moduleId={13}
              exact
              path="/event/:eventId/:organiserId/settings/invite-mailer"
              component={WelcomeMailer}
            />
          )}
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/legal"
            component={Legal}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/privacy"
            component={Privacy}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/seo"
            component={Seo}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/virtual-backgrounds"
            component={
              eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : VirtualBackground
            }
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/notifications"
            component={Notification}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/integrations"
            component={Integration}
          />
          <Route
            moduleId={13}
            exact
            path="/event/:eventId/:organiserId/settings/languageSupport"
            component={LangSupport}
          />
          <Route
            moduleId={14}
            exact
            path="/event/:eventId/:organiserId/settings/mentions"
            component={Mentions}
          />
          {/* <Route moduleId={13} exact path='/event/:eventId/:organiserId/settings/language-support' component={LangSupport} /> */}

          <SubRouteNotFound hasSidebarNav={false} component={ErrorPage} />
        </Switch>
      </Suspense>
    </Settings>
  );
};

const Routes = () => {
  const isLoggedIn = checkIsLoggedIn();
  const [isSurvey, setSurvey] = React.useState(false);

  const templatePreviewModalDetails = useSelector(
    templatePreviewModalDetailsSelector,
    shallowEqual
  );
  const templateFullScreenModalDetails = useSelector(templateModalDetailsSelector, shallowEqual);
  const isCampaignOpen = useSelector(CampaignStatusSelector, shallowEqual);
  const eventBasicInfo = useSelector(basicInfoSelector, shallowEqual);

  const { isListLoading = false } = useSelector(cookieConsentSelector, shallowEqual);

  useEffect(() => {
    const hasSurvey = getParameterByName('t');
    if (hasSurvey) {
      const orgId = getParameterByName('o');
      const eId = getParameterByName('e');
      window.history.replaceState(null, '', window.location.href.split('?')[0]);
      setSurveyCookie({ orgId, type: hasSurvey, eId: eId || '' });
      setTimeout(() => {
        setSurvey(true);
      }, 300);
    } else setSurvey(true);
  }, []);

  useEffect(() => {
    browserSignatureStorageHandler();
  }, []);

  if (!isSurvey) return <></>;

  return (
    <>
      {isLoggedIn && !isListLoading ? false : isLoggedIn ? false : <CookiePolicyModal />}
      {/* <Timer /> */}
      {templatePreviewModalDetails?.isModalOpen && <TemplatePreview />}
      {templateFullScreenModalDetails?.isModalOpen && <TemplateFullScreenModal />}

      {isCampaignOpen ? <EmailDisable /> : <></>}

      <Router basename="/">
        <Switch>
          <Route exact path="/" render={rootRender} />
          <PublicRoute exact noAuth hasAuthBanner path="/login" component={Login} />
          <TeamLoginRoute exact hasAuthBanner path="/member-login" component={VerifyTeamAccount} />
          <PublicRoute noAuth hasAuthBanner path="/3sqx5zr5lsvy" component={SignUp} />
          <PublicRoute noAuth hasAuthBanner path="/email-login" component={PreLogin} />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/onboarding"
            component={OnBoardingOrg}
          />
          <PublicRoute exact noAuth hasAuthBanner path="/social-login" component={SocialLogin} />
          <PublicRoute
            exact
            noAuth
            hasAuthBanner
            path="/social-register"
            component={SocialRegister}
          />
          <PublicRoute exact noAuth path="/cookie-policy" component={cookiePolicyComp} />
          <PublicRoute exact noAuth hasAuthBanner path="/set-password" component={ResetPassword} />
          <PublicRoute
            exact
            noAuth
            hasAuthBanner
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute exact noAuth hasAuthBanner path="/verify-account" component={VerifyToken} />
          <PublicRoute
            exact
            noAuth
            hasAuthBanner
            path="/change-password"
            component={VerifyForgotToken}
          />
          <PublicRoute exact noAuth hasAuthBanner path="/orglogin" component={Login} />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/discount"
            component={TicketDiscount}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/landing-page"
            component={LandingPage}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/landing-page/choose-template"
            component={ChooseTemplate}
          />
          <PrivateRoute
            moduleId={2}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/setup-form"
            component={SetupFormRoute}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/events"
            component={EventList}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration-flow"
            component={RegFlow}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/events/ongoing"
            component={EventList}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/events/upcoming"
            component={EventList}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/events/ended"
            component={EventList}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/events/copying"
            component={EventList}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/event/copy/:id"
            component={CreateCopyEvent}
          />
          <PrivateRoute
            exact
            hasSidebarNav={false}
            mustUserData
            path="/contacts"
            component={Contact}
          />
          {/* <PrivateRoute exact hasSidebarNav={false} mustUserData path="/roi" component={ROI} /> */}
          <PrivateRoute
            moduleId={0}
            exact
            mustUserData
            hasSidebarNav={false}
            path="/contacts/:id/profile-details"
            component={ContactProfile}
          />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserData
            path="/account"
            component={UserAccount}
          />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserData
            path="/events/create/:screenId"
            component={CreateEditEventContainer}
          />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserData
            path="/events/:eventId/:organiserId/edit/:screenId"
            component={CreateEditEventContainer}
          />
          {/* {
					userInfo.UserInfo.subscriptionStatus === undefined ? false : <PrivateRoute hasSidebarNav={false} mustUserData path='/billing' component={Billing} />
				} */}
          <PrivateRoute hasSidebarNav={false} mustUserData path="/billing" component={Billing} />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserData
            path="/payout"
            component={Payout}
          />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserData
            path="/analytics"
            component={MultiEventAnalyticsRoute}
          />
          {/* {
					userInfo.UserInfo.subscriptionStatus === undefined ? false : <PrivateRoute  hasSidebarNav={false} path='/plans' mustUserData component={UpgradePlan} />
				} */}
          <PrivateRoute hasSidebarNav={false} path="/plans" mustUserData component={UpgradePlan} />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/team"
            component={Team}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/integrations"
            component={Integrations}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/integration/:metaId"
            component={GlobalIntegration}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/integration/:metaId/setup"
            component={GIAfterConnection}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/integrations-g"
            component={GlobalIntegration}
          />
          <PrivateRoute
            moduleId={2}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/team/role"
            component={Roles}
          />
          <PrivateRoute
            moduleId={0}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId"
            component={EventDetail}
          />
          <PrivateRoute
            moduleId={3}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/sessions"
            component={eventBasicInfo.eventType === EventTypes.WEBINAR ? ErrorPage : Sessions}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/utm"
            component={!eventBasicInfo.are_utm_params_enabled ? ErrorPage : UTMContainer}
          />

          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/ticket"
            component={Ticket}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/addTicket"
            component={TicketAddForV2}
          />
          <PrivateRoute
            moduleId={2}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/editTicket/:ticketId"
            component={TicketAddForV2}
          />
          <PrivateRoute
            moduleId={2}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/registration/order"
            component={Order}
          />
          <PrivateRoute
            moduleId={10}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/brand-your-events"
            component={BrandYourEvent}
          />
          <PrivateRoute
            moduleId={10}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/brand-spaces"
            component={SponsorBrand}
          />
          <PrivateRoute
            moduleId={10}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/brand-spaces/brand-details/:sponsorId"
            component={SponsorBrandDetails}
          />
          <PrivateRoute
            moduleId={0}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/email-campaign"
            component={EmailCampaign}
          />
          {/* <PrivateRoute mustUserAndEventData path='/event/:eventId/:organiserId/campaign' component={Campaign} /> */}
          <PrivateRoute
            moduleId={4}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/people"
            component={People}
          />
          <PrivateRoute
            moduleId={5}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/virtual-booth"
            component={VirtualBooth}
          />
          <PrivateRoute
            moduleId={6}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/meetings"
            component={Meeting}
          />
          <PrivateRoute
            moduleId={8}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/rooms"
            component={eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : Rooms}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/leaderboard"
            component={
              eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : LeaderBoard
            }
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/notification"
            component={NotificationPage}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/activity-feed"
            component={ActivityFeed}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/lead-score"
            component={EngagementLeadScore}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/survey"
            component={EngagementSurvey}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/survey/:id/survey-response"
            component={EngagementSurveyResponse}
          />
          <PrivateRoute
            moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/contest"
            component={Contests}
          />
          {/* <PrivateRoute exact mustUserAndEventData path='/event/:eventId/:organiserId/engagement/contest/:contestId/questions' component={Questions} />
				  <PrivateRoute exact mustUserAndEventData path='/event/:eventId/:organiserId/engagement/contest/:contestId/standings' component={Standings} /> */}
          <PrivateRoute
            moduleId={9}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/contest/:contestType/:contestId"
            component={ContestRouteDetail}
          />
          <PrivateRoute
            // moduleId={9}
            // exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/quiz-details"
            component={eventBasicInfo.isQuizEnabled ? QuizContainer : ErrorPage}
          />
          <PrivateRoute
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/:quizId/quiz-details"
            component={eventBasicInfo.isQuizEnabled ? QuizContainer : ErrorPage}
          />
          <PrivateRoute
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/:quizId/quiz-questions"
            component={eventBasicInfo.isQuizEnabled ? QuizContainer : ErrorPage}
          />
          <PrivateRoute
            // moduleId={9}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/quiz"
            component={eventBasicInfo.isQuizEnabled ? Quiz : ErrorPage}
          />
          <PrivateRoute
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/engagement/:quizId/quiz-responses"
            component={QuizResponses}
          />
          <PrivateRoute
            moduleId={15}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/file-logs"
            component={FileLogs}
          />
          <PrivateRoute
            moduleId={17}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/email-builder"
            component={EmailBuilder}
          />
          <PrivateRoute
            moduleId={17}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/email-builder/campaign"
            component={CreateEmail}
          />
          <PrivateRoute
            moduleId={17}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/email-builder/campaign/:campaignId"
            component={CreateEmail}
          />
          <PrivateRoute
            moduleId={17}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/email-builder/view-report/:campaignId"
            component={ViewReport}
          />
          <PrivateRoute
            moduleId={12}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/analytics"
            component={AnalyticsRoute}
          />
          <PrivateRoute
            moduleId={13}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/settings"
            component={SettingRoute}
          />
          <PrivateRoute
            moduleId={5}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/virtual-detail/:virtualId"
            component={VirtualRouteDetail}
          />
          <PrivateRoute
            moduleId={3}
            mustUserAndEventData
            path="/event/:eventId/:organiserId/session/:sessionId"
            component={SessionRoute}
          />
          <PrivateRoute
            moduleId={7}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/lounge"
            component={eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : Lounge}
          />
          <PrivateRoute
            moduleId={14}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/recording"
            component={eventBasicInfo.eventType === EventTypes.IN_PERSON ? InPersonNav : Recording}
          />
          <PrivateRoute
            moduleId={0}
            hasSidebarNav={false}
            mustUserAndEventData={false}
            path="/verify_stripe"
            component={Payout}
          />
          {/*  <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/embed-apps"
            component={EmbedApps}
          /> */}
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/data-mapping"
            component={DataMapping}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/data-mapping/:eventType"
            component={DataMapEvents}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/data-mapping/:eventId/status"
            component={DataMapStatus}
          />
          <PrivateRoute
            moduleId={0}
            exact
            hasSidebarNav={false}
            mustUserData
            path="/data-mapping/:eventId/:scope"
            component={DataMapSettings}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/embed-apps"
            component={EmbedApps}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/connected-apps"
            component={ConnectedApps}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/connected-apps/:appName/:appId"
            component={ConfigureEventData}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/connected-apps/zoomApp"
            component={ConfigureZoomApp}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/zoomApp/:screenId"
            component={CreateEditZoomContainer}
          />
          <PrivateRoute
            moduleId={16}
            exact
            mustUserAndEventData
            path="/event/:eventId/:organiserId/integrations/connected-apps/zoom-sync"
            component={ZoomSyncPage}
          />
          {/* <PrivateRoute exact mustUserAndEventData={false} path='/verify_stripe' component={PayOutMethod} /> */}
          <Route exact path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
