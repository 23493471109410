/* eslint-disable  */
import { makePlaceData } from 'pages/events/eventSidebar/hybridAddressField';
import React from 'react';
import ProjectSetting from 'settings';

export const mapScriptLoad = (cb) => {
  const id = 'google-script';
  const GOOGLE_MAP_API_KEY = ProjectSetting.google_map_api_key;
  const googleScriptEle = document.getElementById(id);
  if (!window.google && !googleScriptEle) {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.id = id;
    s.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
    // Below is important.
    // We cannot access google.maps until it's finished loading
    s.addEventListener('load', () => {
      if (cb) cb('load');
    });
  } else if (googleScriptEle) {
    if (googleScriptEle.src) {
      if (cb) cb('src');
    } else googleScriptEle.onload = () => cb && cb('onload');
  } else {
    if (cb) cb('else');
  }
};

const MapView = React.memo(({ id, zoom = 10, center, onChange }) => {
  const mapRef = React.useRef(null);
  let marker = null;

  const placeMarkerPanTo = (latLng, map) => {
    marker = new window.google.maps.Marker({
      position: latLng,
      map,
    });
    map.panTo(latLng);
  };

  const onMapLoad = () => {
    if (!window.google) return false;
    const map = new window.google.maps.Map(mapRef.current, {
      center,
      zoom,
    });
    const geocoder = new window.google.maps.Geocoder();
    marker = new window.google.maps.Marker({
      position: center,
      map,
      draggable: false,
      visible: true,
    });
    map.addListener('click', (e) => {
      if (marker && marker.setMap) {
        marker.setMap(null);
      }
      geocoder.geocode(
        {
          latLng: e.latLng,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              onChange(makePlaceData(results[0]), map.getZoom());
            }
          }
        }
      );
      placeMarkerPanTo(e.latLng, map);
    });
    return null;
  };

  React.useEffect(() => {
    if (center) mapScriptLoad(onMapLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, zoom]);
  return <div style={{ width: '100%', height: '100%' }} id={id} ref={mapRef} />;
});

export default MapView;
