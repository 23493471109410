import React from 'react';

export default function SvgGermanFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <path
          d="M0.621948 13.4783C2.03488 17.2863 5.70031 20 9.99996 20C14.2996 20 17.965 17.2863 19.378 13.4783L9.99996 12.6087L0.621948 13.4783Z"
          fill="#FFDA44"
        />
        <path
          d="M9.99996 0C5.70031 0 2.03488 2.71375 0.621948 6.52176L9.99996 7.39129L19.378 6.52172C17.965 2.71375 14.2996 0 9.99996 0Z"
          fill="black"
        />
        <path
          d="M0.621992 6.52171C0.220039 7.60503 0 8.77675 0 9.99996C0 11.2232 0.220039 12.3949 0.621992 13.4782H19.378C19.78 12.3949 20 11.2232 20 9.99996C20 8.77675 19.78 7.60503 19.378 6.52171H0.621992Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
