import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { FileText, Copy, Check } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  stepperLink: {
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingRight: '2rem',
      color: theme.palette.border.dark,
      cursor: 'not-allowed',

      '& span': {
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        marginRight: '8px',
        border: `1px solid ${theme.palette.border.dark}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          color: theme.palette.border.dark,
        },
        '& .visitedTickIcon': {
          display: 'none',
        },
        '&.lockEventIcon': {
          borderStyle: 'solid',
        },
      },

      '&:not(:first-child)': {
        '& span': {
          marginLeft: '8px',
        },
        '&::after': {
          position: `absolute`,
          content: `""`,
          width: `24px`,
          height: '2px',
          background: theme.palette.border.dark,
          left: '-24px',
          top: '15px',
          zIndex: 1,
        },
      },
    },

    '& .active': {
      color: theme.palette.primary.main,
      cursor: 'default',
      '& span': {
        background: theme.palette.primary.main,
        border: 'none',
        '& svg': {
          color: theme.palette.secondary.contrastText,
        },
      },
      '&::after': {
        background: `${theme.palette.primary.main}!important`,
      },
    },

    '& .visited': {
      color: theme.palette.primary.main,
      cursor: 'default',
      '& span': {
        border: `1px solid ${theme.palette.border.dark}`,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },

      '& .icon': {
        display: 'none',
      },
      '& .visitedTickIcon': {
        display: 'block',
        '& svg path': {
          fill: theme.palette.primary.main,
        },
      },
      '&::after': {
        background: `${theme.palette.primary.main}!important`,
      },
    },
  },
}));

const Steppers = ({ activeStep }) => {
  const classes = useStyles();

  return (
    <ol className={classes.stepperLink}>
      <li className={`${activeStep === 0 ? 'active' : 'visited'}`}>
        <span>
          <FileText className="icon" width="16px" />
          <Check className="visitedTickIcon" width="16px" />
        </span>
        Event Details
      </li>

      <li className={`${activeStep === 1 ? 'active' : ''}`}>
        <span className={activeStep === 1 ? 'lockEventIcon' : ''}>
          <Copy className="icon svgLockIcon" width="16px" />
        </span>
        Copy Event Options
      </li>
    </ol>
  );
};

export default Steppers;
