export const detectIncognito = (browserName, callback) => {
  function __callback(isPrivate) {
    callback(isPrivate);
  }

  function macOSSafari14() {
    try {
      window.safari.pushNotification.requestPermission(
        'https://example.com',
        'private',
        {},
        function abc() { }
      );
    } catch (e) {
      return __callback(!new RegExp('gesture').test(e));
    }
    return __callback(false);
  }

  function iOSSafari14() {
    let tripped = false;
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.contentWindow.applicationCache.addEventListener('error', function abc() {
      tripped = true;
      return __callback(true);
    });

    setTimeout(function trip() {
      if (!tripped) {
        __callback(false);
      }
    }, 100);
  }

  function oldSafariTest() {
    const openDB = window.openDatabase;
    const storage = window.localStorage;
    try {
      openDB(null, null, null, null);
    } catch (e) {
      return __callback(true);
    }
    try {
      storage.setItem('test', '1');
      storage.removeItem('test');
    } catch (e) {
      return __callback(true);
    }
    return __callback(false);
  }

  function safariPrivateTest() {
    const w = window;
    if (navigator.maxTouchPoints !== undefined) {
      if (w.safari !== undefined && w.DeviceMotionEvent === undefined) {
        // browserName = "Safari for macOS";
        macOSSafari14();
      } else if (w.DeviceMotionEvent !== undefined) {
        // browserName = "Safari for iOS";
        iOSSafari14();
      } else {
        throw new Error('detectIncognito Could not identify this version of Safari');
      }
    } else {
      oldSafariTest();
    }
  }

  function getQuotaLimit() {
    const w = window;
    if (
      w.performance !== undefined &&
      performance.memory !== undefined &&
      performance.memory.jsHeapSizeLimit !== undefined
    ) {
      return performance.memory.jsHeapSizeLimit;
    }
    return 1073741824;
  }

  // >= 76
  function storageQuotaChromePrivateTest() {
    navigator.webkitTemporaryStorage.queryUsageAndQuota(
      function check(usage, quota) {
        __callback(quota < getQuotaLimit());
      },
      function error(e) {
        throw new Error(`detectIncognito somehow failed to query storage quota:${e?.message}`);
      }
    );
  }

  // 50 to 75
  function oldChromePrivateTest() {
    const fs = window.webkitRequestFileSystem;
    const success = function abc() {
      __callback(false);
    };
    const error = function abc() {
      __callback(true);
    };
    fs(0, 1, success, error);
  }

  function chromePrivateTest() {
    if (Promise !== undefined && Promise.allSettled !== undefined) {
      storageQuotaChromePrivateTest();
    } else {
      oldChromePrivateTest();
    }
  }

  function firefoxPrivateTest() {
    __callback(navigator.serviceWorker === undefined);
  }

  function main() {
    switch (browserName) {
      case 'safari':
        safariPrivateTest();
        break;
      case 'chrome':
        chromePrivateTest();
        break;
      case 'edge':
        chromePrivateTest();
        break;
      case 'firefox':
        firefoxPrivateTest();
        break;
      default:
        break;
    }
  }
  main();
};
