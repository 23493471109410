import { EDIT_CREATE_PARENT } from "redux/actions/eventCreateEditActions";

const createEditParentReducer = (state = { editCreateParent: '' }, action) => {
    switch (action.type) {
        case EDIT_CREATE_PARENT:
            return { ...state, editCreateParent: action.payload }
        default:
            return state

    }
}

export default createEditParentReducer