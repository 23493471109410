import React, { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import {
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { RefreshCcw } from 'react-feather';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userInfoSelector, commonSelector } from 'redux/custom-selector';
import CircularProgress from '../../../custom-components/circularProgress';
import { useStyles } from './style';
import { formatDate, formatUTCDate, planExpireTypeDisplay } from 'lib/common-function';
import CheckCircleRound from 'icons/CheckCircleRound';
import { API, POSTAPIWITHCUSTOMDOMAIN, GETAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import projectSetting from 'settings';
import { useSource } from 'lib/custom-common-hooks';
import {
  GET_NOTIFICATION_LIST,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  GET_CURRENT_SUBSCRIPTION,
} from '../../../api-setup/api-endpoints';
import SkeletonLoader from 'custom-components/skeletonLoader';
import { useList } from 'lib/hooks/list-hook';
import { setAlertData } from 'redux/actions/alert-action';
import { commonAmplitudeTrack } from 'lib/amplitude/amplitude-tracking';

const AlertLoader = () => {
  return (
    <Box px={2}>
      <Box py={2} style={{ borderBottom: '1px solid #dbdbdb' }}>
        <SkeletonLoader height={20} width="100%" variant="text" />
        <SkeletonLoader height={20} width="50%" variant="text" />
      </Box>
      <Box py={2} style={{ borderBottom: '1px solid #dbdbdb' }}>
        <SkeletonLoader height={20} width="100%" variant="text" />
        <SkeletonLoader height={20} width="50%" variant="text" />
      </Box>
      <Box py={2}>
        <SkeletonLoader height={20} width="100%" variant="text" />
        <SkeletonLoader height={20} width="50%" variant="text" />
      </Box>
    </Box>
  );
};

const CreditLoader = () => {
  return (
    <Box
      p={2}
      style={{ borderBottom: '1px solid #dbdbdb' }}
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <SkeletonLoader height={50} width={50} variant="round" />
        <Box style={{ marginLeft: '1rem' }}>
          {' '}
          <SkeletonLoader height={20} width="70px" variant="text" />
        </Box>
      </Box>
      <Box>
        <SkeletonLoader height={20} width={20} variant="round" />
      </Box>
    </Box>
  );
};

export const NewPlanDetailAlertList = ({ isPlanMenuOpen }) => {
  //   const match = useRouteMatch();
  const history = useHistory();
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const dispatch = useDispatch();
  const source = useSource();
  const { alertCTA, attendeeCreditsDropDown, title, themeColor, boldText, pricingPlanName } =
    useStyles();

  const CUSTOM_URL_V2 = projectSetting.customBaseURL2;
  const [userPlan, setUserPlan] = useState({
    attCredsConsumed: 0,
    attCredsAlloc: 0,
    planEndDate: '',
    isLoading: false,
  });
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [alertDataState, getAlertData, setAlertList] = useList(
    false,
    reduxData,
    // eslint-disable-next-line react/destructuring-assignment
    history,
    {},
    GET_NOTIFICATION_LIST,
    {},
    'POST_NODE2',
    5
  );

  const showPlanIcon = () => {
    switch (UserInfo.planName) {
      case 'webinar':
        return (
          <Box className="square" mr={2}>
            <Typography variant="h5" component="h5" className={themeColor}>
              WM
            </Typography>
          </Box>
        );
      case 'webinar_lite':
        return (
          <Box className="square" mr={2}>
            <Typography variant="h5" component="h5" className={themeColor}>
              W+
            </Typography>
          </Box>
        );
      case 'digital-events':
        return (
          <Box className="square" mr={2}>
            <Typography variant="h5" component="h5" className={themeColor}>
              DE
            </Typography>
          </Box>
        );
      case 'all-in':
        return (
          <Box className="square" mr={2}>
            <Typography variant="h5" component="h5" className={themeColor}>
              All
            </Typography>
          </Box>
        );
      default:
        return '';
    }
  };

  const READ_MULTI_NOTIFICATIONS = async () => {
    const [headers] = API.generateHeader(reduxData, null, null);
    const shownItemIds = alertDataState?.options.map((item) => item._id);
    const payloadObject = {
      payload: {
        data: {
          notificationIds: shownItemIds,
        },
      },
    };
    if (isPlanMenuOpen) {
      await POSTAPIWITHCUSTOMDOMAIN(
        false,
        READ_ALL_NOTIFICATION,
        source.AS,
        headers,
        payloadObject,
        CUSTOM_URL_V2
      )
        .then((resp) => {
          if (resp.data.status === API.apiSuccessStatus) {
            const notificationAfterRead = alertDataState?.options.map((item) => {
              return { ...item, isRead: true };
            });
            setAlertList({
              ...alertDataState,
              options: notificationAfterRead,
            });
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    }

    return () => API.removeAllApi(source.AS);
  };

  const READ_SINGLE_NOTIFICATION = async (id) => {
    const [headers] = API.generateHeader(reduxData, null, null);
    const body = {
      payload: {
        data: {
          notificationId: id,
        },
      },
    };
    if (isPlanMenuOpen) {
      await POSTAPIWITHCUSTOMDOMAIN(
        false,
        READ_NOTIFICATION,
        source.AS,
        headers,
        body,
        CUSTOM_URL_V2
      )
        .then((resp) => {
          if (resp.data.status === API.apiSuccessStatus) {
            const notificationAfterRead = alertDataState?.options.map((item) => {
              if (item._id === id) {
                return { ...item, isRead: true };
              }
              return item;
            });
            setAlertList({
              ...alertDataState,
              options: notificationAfterRead,
            });
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    }

    return () => API.removeAllApi(source.AS);
  };

  const FETCH_USER_SUBSCRIPTION_DETAILS = useCallback(() => {
    const [header] = API.generateHeader(reduxData, null, null);
    const body = {};
    setUserPlan((plans) => ({ ...plans, isLoading: true }));
    GETAPIWITHCUSTOMDOMAIN(false, GET_CURRENT_SUBSCRIPTION, source.AS, header, body, CUSTOM_URL_V2)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          setUserPlan((d) => ({
            ...d,
            attCredsConsumed: Number(
              resp.data.data?.creditUsage?.attCredsConsumed?.totalCredsConsumed
            ),
            attCredsAlloc:
              Number(resp.data.data?.creditUsage?.attCredsAlloc?.addOnsCreds) +
              Number(resp.data.data?.creditUsage?.attCredsAlloc?.allocCreds),
            planEndDate: resp.data.data?.plan?.planEndDate,
            isLoading: false,
          }));
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxData]);

  const refresh = () => {
    FETCH_USER_SUBSCRIPTION_DETAILS();
    getAlertData('', false, false, true);
    setLastUpdatedTime(moment().format('DD MMM | hh:mm A'));
  };

  const handleClickOnScroll = (event) => {
    const ele = event.target;
    if (
      ele.scrollHeight - ele.scrollTop <= ele.clientHeight + 5 &&
      alertDataState.page + 1 <= alertDataState.totalPage &&
      !alertDataState.loading
    ) {
      getAlertData('', true, {
        ...(alertDataState.totalPage && { totalPage: alertDataState.totalPage }),
        ...(alertDataState.unread && { unread: alertDataState.unread }),
      });
    }
  };
  const isReadAllActive = alertDataState?.options.find((item) => !item.isRead);
  React.useEffect(() => {
    FETCH_USER_SUBSCRIPTION_DETAILS();
    getAlertData('', false);
    setLastUpdatedTime(moment().format('DD MMM | hh:mm A'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanMenuOpen]);

  React.useEffect(() => {
    if (
      alertDataState?.page === alertDataState?.totalPage &&
      !alertDataState?.loading &&
      !alertDataState?.initLoad
    ) {
      dispatch(setAlertData({ unread: !isReadAllActive ? 0 : 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDataState?.page, alertDataState?.options]);

  return (
    <>
      {showPlanIcon() !== '' ? (
        <Box p={2} className="currentPlan" display="flex" alignItems="center">
          <Box className="square" mr={2}>
            {showPlanIcon}
          </Box>
          <Box>
            <Typography variant="h5" component="h6" display="block" className={pricingPlanName}>
              {planExpireTypeDisplay(UserInfo)}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                component="h6"
                display="block"
                color="textSecondary"
                className={boldText}>
                Plan ends:{' '}
                {userPlan?.planEndDate ? formatDate(userPlan?.planEndDate, 'DD MMM YYYY') : ' - '}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        false
      )}
      {userPlan?.isLoading ? (
        <CreditLoader />
      ) : (
        <>
          <Typography className={title} color="textSecondary" component="p" variant="body2">
            Attendee credits consumption
          </Typography>
          <Box
            className={`leftDataPlan attendeeCreditsProgress ${
              alertDataState?.options.length > 0 && 'attendeeCreditsProgressBorder'
            }`}
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box mr={1.5} className={themeColor}>
                <CircularProgress
                  icon={`${
                    userPlan.attCredsConsumed === 0 && userPlan.attCredsAlloc === 0
                      ? 0
                      : Math.floor((userPlan.attCredsConsumed / userPlan.attCredsAlloc) * 100) > 100
                      ? 100
                      : Math.floor((userPlan.attCredsConsumed / userPlan.attCredsAlloc) * 100)
                  }%`}
                  color="primary"
                  value={
                    (userPlan.attCredsConsumed / userPlan.attCredsAlloc) * 100 > 100
                      ? 100
                      : (userPlan.attCredsConsumed / userPlan.attCredsAlloc) * 100
                  }
                  size={50}
                />
              </Box>
              <Box>
                <Typography variant="h6" component="h6" display="block">
                  {userPlan.attCredsConsumed}/{userPlan.attCredsAlloc}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <IconButton onClick={() => refresh()} color="primary" size="small">
                <RefreshCcw size={16} />
              </IconButton>
              <Typography variant="caption" component="p" color="textSecondary">
                Last updated :{lastUpdatedTime}
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {!alertDataState?.initLoad ? (
        <>
          {alertDataState?.options.length > 0 ? (
            <>
              <Box
                className={alertCTA}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="body1" component="label">
                  Alerts
                </Typography>
                <Button
                  className="markAllButton"
                  disabled={!isReadAllActive}
                  color={!isReadAllActive ? 'secondary' : 'primary'}
                  onClick={() => {
                    commonAmplitudeTrack('UsageAlertMarkAsRead', {
                      group: UserInfo?.is_team_member_loggedin ? 'TEAM_MEMBER' : 'ORGANISER',
                      organiser_id: UserInfo?.organiser_id,
                      user_id: UserInfo?.id,
                      plan_type: UserInfo?.planName,
                      is_all_alerts: true,
                    });
                    READ_MULTI_NOTIFICATIONS();
                  }}>
                  Mark all as read
                </Button>
              </Box>
              <List
                className={attendeeCreditsDropDown}
                onScroll={(e) => {
                  handleClickOnScroll(e);
                }}>
                {alertDataState?.options.length ? (
                  <>
                    {alertDataState?.options.map((item) => (
                      <ListItem key={item._id} className={item.isRead ? '' : 'unReadBlock'}>
                        <ListItemText
                          primary={item.text}
                          secondary={formatDate(item.localCreatedAt, 'DD MMM | hh:mm A')}
                        />
                        {!item.isRead ? (
                          <ListItemIcon
                            className="tickIcon"
                            onClick={() => {
                              commonAmplitudeTrack('UsageAlertMarkAsRead', {
                                group: UserInfo?.is_team_member_loggedin
                                  ? 'TEAM_MEMBER'
                                  : 'ORGANISER',
                                organiser_id: UserInfo?.organiser_id,
                                user_id: UserInfo?.id,
                                plan_type: UserInfo?.planName,
                                is_all_alerts: false,
                              });
                              READ_SINGLE_NOTIFICATION(item._id);
                            }}>
                            <CheckCircleRound />
                          </ListItemIcon>
                        ) : null}
                      </ListItem>
                    ))}
                  </>
                ) : null}
              </List>
            </>
          ) : null}
        </>
      ) : (
        <AlertLoader />
      )}
    </>
  );
};
