import React from 'react';
import { Grid, Box } from '@material-ui/core';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const CopyEventOptionLoader = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1.5}>
              <SkeletonLoaders height="60px" width="100%" variant="rect" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box mb={1}>
              <SkeletonLoaders height="16px" width="78px" variant="text" />
              <Box mt={1}>
                <SkeletonLoaders height="50px" width="100%" variant="rect" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <SkeletonLoaders height="16px" width="78px" variant="text" />
              <Box mt={1}>
                <SkeletonLoaders height="72px" width="100%" variant="rect" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <SkeletonLoaders height="16px" width="78px" variant="text" />
              <Box mt={1}>
                <SkeletonLoaders height="50px" width="100%" variant="rect" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CopyEventOptionLoader;
