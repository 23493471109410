/* eslint-disable no-param-reassign */
import {
  SET_CAMPAIGN_DATA,
  REMOVE_CAMPAIGN_DATA,
} from 'redux/actions/invite-email-campaign-action';

const campaignData = (state = [], action) => {
  switch (action.type) {
    case SET_CAMPAIGN_DATA:
      return [...action.data];
    case REMOVE_CAMPAIGN_DATA:
      return [];
    default:
      return state;
  }
};

export default campaignData;
