/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Typography, Grid, makeStyles, Button, Link } from '@material-ui/core';
// import SvgHubilo from 'icons/svgNewHubiloIconWithText';
import SvgHubilo from 'icons/svgHubiloNewIcon';
import SvgUnauthorized from 'icons/svgUnauthorized';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    background: '#ffffff',
  },
  logoBlock: {
    color: theme.palette.text.third,
    '& .mono': {
      width: 150,
      height: 70,
    },
    '& .logo-text': {
      fontSize: theme.fontSize.size3,
    },
  },
}));

export default function UnAuthorized() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box>
        <Grid container className={classes.wrapper}>
          <Grid item lg>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              height="100%">
              <Link
                onClick={() => history.push('/events')}
                className="cursor-pointer"
                underline="none"
                color="inherit">
                <Box className={classes.logoBlock} alignItems="center">
                  <Box display="flex" alignItems="center">
                    <SvgHubilo fill="currentColor" className="mono" />
                    {/* <span className="logo-text">HUBILO</span> */}
                  </Box>
                </Box>
              </Link>

              <Box component="div" mt={5} mb={3}>
                <SvgUnauthorized width="380px" Height="280px" />
              </Box>

              <Box component="div">
                <Typography variant="h5" component="h5">
                  {' '}
                  Restricted Access
                </Typography>
              </Box>

              <Box color="text.grayColor" mt={2} mb={4} maxWidth="400px" textAlign="center">
                <Typography variant="caption" component="p">
                  You are not authorised to do this activity. Please contact your admin. For more
                  queries, reach us on
                  <Box
                    p={0}
                    border={0}
                    color="inherit"
                    bgcolor="transparent"
                    className="text-underline no-outline cursor-pointer"
                    component="a"
                    onClick={() => window.open('http://www.support@hubilo.com/', '_blank')}>
                    {' '}
                    support@hubilo.com
                  </Box>
                </Typography>
              </Box>

              <Button
                onClick={() => history.push('/events')}
                className="uppercase"
                variant="contained"
                size="small"
                color="primary">
                Back to home page
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
