/* eslint-disable no-param-reassign */
import { SET_CONTEST_DATA, REMOVE_CONTEST_DATA } from 'redux/actions/contest-action';

const contestData = (state = {}, action) => {
    switch (action.type) {
        case SET_CONTEST_DATA:
            state = { ...state, ...action.data };
            break;
        case REMOVE_CONTEST_DATA:
            state = {};
            break;
        default:
            break;
    }
    return state;
}

export default contestData;