/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import AddEditQuestion from './addEditQuestion';
import QuizQuestion from './quizQuestion';

function QuizQuestionWrapper(
  props,
  { quizLoading = false, apiLoading = false, questionsLength = 0 }
) {
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (isEdit) props.closeAddQuestion();
  }, [isEdit]);
  return (
    <>
      {isEdit ? (
        <AddEditQuestion
          isEdit={isEdit}
          quizLoading={quizLoading}
          apiLoading={apiLoading}
          questionData={props}
          handleEditQuestion={props?.handleEditQuestion}
          handleAddQuestion={props?.handleAddQuestion}
          handleEditCancle={() => setEdit(!isEdit)}
        />
      ) : (
        <QuizQuestion
          questionsLength={questionsLength}
          quizLoading={quizLoading}
          onEditClick={() => setEdit(!isEdit)}
          {...props}
        />
      )}
    </>
  );
}

export default QuizQuestionWrapper;
