export const actionTypes = {
  LOADING: 'LOADING',
  // Custom APP
  EVENT_INT_APP_LOADING: 'EVENT_INT_APP_LOADING',
  EVENT_INT_APP_SUCCESS: 'EVENT_INT_APP_SUCCESS',
  AVAILABLE_INT_APP_LIST_LOADING: 'AVAILABLE_INT_APP_LIST_LOADING',
  AVAILABLE_INT_APP_LIST_SUCCESS: 'AVAILABLE_INT_APP_LIST_SUCCESS',
  TOGGLE_ADD_UPDATE_APP_SIDE_PANEL: 'TOGGLE_ADD_UPDATE_APP_SIDE_PANEL',
  ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING: 'ADD_UPDATE_EMBED_APP_ICON_LIST_LOADING',
  ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS: 'ADD_UPDATE_EMBED_APP_ICON_LIST_SUCCESS',
  EVENT_ADD_APP_LOADING: 'EVENT_ADD_APP_LOADING',
  GET_SESSION_ENGAGEMENT_LIST_DATA: 'GET_SESSION_ENGAGEMENT_LIST_DATA',
  INIT_ENGAGEMENT_LIST_LOADING: 'INIT_ENGAGEMENT_LIST_LOADING',
  REARRANGE_SESSION_ENGAGEMENT_LIST_DATA: 'REARRANGE_SESSION_ENGAGEMENT_LIST_DATA',
  UPDATE_SESSION_ENGAGEMENT_STATUS_DATA: 'UPDATE_SESSION_ENGAGEMENT_STATUS_DATA',
  IS_ENGAGEMENT_STATUS_LOADING: 'IS_ENGAGEMENT_STATUS_LOADING',
  IS_ENGAGEMENT_REARRANGE_LIST_LOADING: 'IS_ENGAGEMENT_REARRANGE_LIST_LOADING',
  UPDATE_SESSION_ENGAGEMENT_DATA: 'UPDATE_SESSION_ENGAGEMENT',
  IS_ENGAGEMENT_UPDATE_LOADING: 'IS_ENGAGEMENT_UPDATE_LOADING',
  ADD_SESSION_ENGAGEMENT_DATA: 'ADD_SESSION_ENGAGEMENT_DATA',
  IS_ENGAGEMENT_SAVE_LOADING: 'IS_ENGAGEMENT_SAVE_LOADING',
  UPDATE_SESSION_ENGAGEMENT: 'UPDATE_SESSION_ENGAGEMENT',
  VERIFY_ENABLED_EMBED_APP: 'VERIFY_ENABLED_EMBED_APP',
  EVENT_ICON_LOADING: 'EVENT_ICON_LOADING',
  EVENT_ICON_SUCCESS: 'EVENT_ICON_SUCCESS',
  MEETING_DETAILS_LOADING: 'MEETING_DETAILS_LOADING',
  MEETING_DETAILS_SUCCESS: 'MEETING_DETAILS_SUCCESS',
  MEETING_VISIBLE_LOADING: 'MEETING_VISIBLE_LOADING',
  MEETING_VISIBLE_SUCCESS: 'MEETING_VISIBLE_SUCCESS',
  MEETING_DETAILS_UPDATE_LOADING: 'MEETING_DETAILS_UPDATE_LOADING',
  COOKIE_RECORD_LIST: 'COOKIE_RECORD_LIST',
  COOKIE_RECORD: 'COOKIE_RECORD',
  MEETING_SLOT_UPDATE_LOADING: 'MEETING_SLOT_UPDATE_LOADING',
  MEETING_SLOT_UPDATE_SUCCESS: 'MEETING_SLOT_UPDATE_SUCCESS',
  IS_SESSION_ENGAGEMENT_REACTIONS_LOADING: 'IS_SESSION_ENGAGEMENT_REACTIONS_LOADING',
  UPDATE_SESSION_ENGAGEMENT_REACTIONS: 'UPDATE_SESSION_ENGAGEMENT_REACTIONS',
  IS_ROOM_REACTIONS_LOADING: 'IS_ROOM_REACTIONS_LOADING',
  UPDATE_ROOM_REACTIONS_ENABLE_DATA: 'UPDATE_ROOM_REACTIONS_ENABLE_DATA',
  GET_LOUNGE_REACTION_DATA: 'GET_LOUNGE_REACTION_DATA',
  GET_LOUNGE_REACTION_LOADING: 'GET_LOUNGE_REACTION_LOADING',
  IS_LOUNGE_REACTIONS_LOADING: 'IS_LOUNGE_REACTIONS_LOADING',
  UPDATE_LOUNGE_ENABLE_REACTIONS_DATA: 'UPDATE_LOUNGE_ENABLE_REACTIONS_DATA',
  CHECK_EMBED_APP_BACKGROUND_MODE: 'CHECK_EMBED_APP_BACKGROUND_MODE',
  IS_TYPE_LOADING: 'IS_TYPE_LOADING',
  IS_TYPE_DATA: 'IS_TYPE_DATA',
  SET_TICKET_SETTINGS_SIDE_BAR_OPEN: 'SET_TICKET_SETTINGS_SIDE_BAR_OPEN',
  IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING: 'IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING',
  UPDATE_SESSION_ENGAGEMENT_RAISE_HAND: 'UPDATE_SESSION_ENGAGEMENT_RAISE_HAND',
};
