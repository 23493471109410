import React from 'react';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  eKveLE8GcMT37_tr: {
    animation: `$eKveLE8GcMT37_tr__tr 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT37_tr__tr': {
    '0%': {
      transform: 'translate(128.194px, 823.389001px) rotate(0deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '42%': {
      transform: 'translate(128.194px, 823.389001px) rotate(-4.556431deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '100%': {
      transform: 'translate(128.194px, 823.389001px) rotate(0deg)',
    },
  },

  eKveLE8GcMT40_tr: {
    animation: `$eKveLE8GcMT40_tr__tr 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT40_tr__tr': {
    '0%': {
      transform: ' translate(151.61235px, 824.649109px) rotate(0deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '50%': {
      transform: 'translate(151.61235px, 824.649109px) rotate(-1.337223deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '100%': {
      transform: ' translate(151.61235px, 824.649109px) rotate(0deg)',
    },
  },

  eKveLE8GcMT43_tr: {
    animation: `$eKveLE8GcMT43_tr__tr 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT43_tr__tr': {
    '0%': {
      transform: 'translate(178.68px, 824.649109px) rotate(0deg)',
    },
    '8%': {
      transform: 'translate(178.68px, 824.649109px) rotate(0deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '56%': {
      transform: 'translate(178.68px, 824.649109px) rotate(-4.556431deg)',
      animationTimingFunction: 'cubic-bezier(0.42, 0, 0.58, 1)',
    },
    '100%': {
      transform: 'translate(178.68px, 824.649109px) rotate(0deg)',
    },
  },

  eKveLE8GcMT70_to: {
    animation: `$eKveLE8GcMT70_to__to 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT70_to__to': {
    '0%': {
      transform: 'translate(630.303833px, 787.649px)',
    },
    '6%': {
      transform: 'translate(630.303833px, 787.649px)',
    },
    '30%': {
      transform: 'translate(630.303833px, 734.131226px)',
    },
    '62%': {
      transform: 'translate(630.303833px, 787.649px)',
    },
    '86%': {
      transform: 'translate(630.303833px, 734.131226px)',
    },
    '100%': {
      transform: 'translate(630.303833px, 734.131226px)',
    },
  },

  eKveLE8GcMT70: {
    animation: `$eKveLE8GcMT70_c_o 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT70_c_o': {
    '0%': {
      opacity: 0,
    },
    '6%': {
      opacity: 0,
    },
    '18%': {
      opacity: 0.6,
    },
    '28%': {
      opacity: 0,
    },
    '62%': {
      opacity: 0,
    },
    '74% ': {
      opacity: 0.6,
    },
    '84%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },

  eKveLE8GcMT71_to: {
    animation: `$eKveLE8GcMT71_to__to 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT71_to__to': {
    '0%': {
      transform: 'translate(666.303192px, 787.649px)',
    },
    '20%': {
      transform: 'translate(666.303192px, 756.016px)',
    },
    '54%': {
      transform: 'translate(666.303192px, 787.649px)',
    },
    '74% ': {
      transform: 'translate(666.303192px, 756.016px)',
    },
    '100%': {
      transform: 'translate(666.303192px, 756.016px)',
    },
  },

  eKveLE8GcMT71: {
    animation: `$eKveLE8GcMT71_c_o 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT71_c_o': {
    '0%': {
      opacity: 0,
    },
    '10%': {
      opacity: 0.6,
    },
    '20%': {
      opacity: 0,
    },
    '54%': {
      opacity: 0,
    },
    '64%': {
      opacity: 0.6,
    },
    '74% ': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },

  eKveLE8GcMT72_to: {
    animation: `$eKveLE8GcMT72_to__to 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT72_to__to': {
    '0%': {
      transform: 'translate(647.634126px, 801.149px)',
    },
    '12%': {
      transform: 'translate(647.634126px, 801.149px)',
    },
    '32%': {
      transform: 'translate(647.634126px, 737.788px)',
    },
    '68%': {
      transform: 'translate(647.634126px, 801.149px)',
    },
    '88%': {
      transform: 'translate(647.634126px, 737.788px)',
    },
    '100%': {
      transform: 'translate(647.634126px, 737.788px)',
    },
  },

  eKveLE8GcMT72: {
    animation: `$eKveLE8GcMT72_c_o 5000ms linear infinite normal forwards`,
  },

  '@keyframes eKveLE8GcMT72_c_o': {
    '0%': {
      opacity: 0,
    },
    '12%': {
      opacity: 0,
    },
    '20%': {
      opacity: 0.6,
    },
    '32%': {
      opacity: 0,
    },
    '68%': {
      opacity: 0,
    },
    '76%': {
      opacity: 0.6,
    },
    '88%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

export default function LoungeSvgIcon() {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      cache-id="6c3041623314490884d5041f514457c2"
      id="eKveLE8GcMT1"
      viewBox="0 0 1420 1040"
      height="15rem"
      widt="25rem"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision">
      <g className={classes.eKveLE8GcMT2} transform="matrix(1 0 0 1 0 0.000001)">
        <path
          id="eKveLE8GcMT3"
          d="M577.955,129.749C471.164,207.445,50.7711,575.624,173.035,891.307C196.215,951.06,331.792,951.251,408.631,952.821C600.084,956.724,814.193,949.942,971.84,954.059C1051.77,956.129,1237.18,850.854,1284.54,772.206C1307.89,733.656,1304.71,656.198,1279.46,618.505C1254.2,580.811,1206.85,526.412,1181.74,488.647C1110.63,381.801,1115.39,268.697,1047.86,160.256C985.269,59.597,778.722,-16.2423,577.955,129.749Z"
          fill="rgb(224,224,224)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="eKveLE8GcMT4"
          d="M577.955,129.749C471.164,207.445,50.7711,575.624,173.035,891.307C196.215,951.06,331.792,951.251,408.631,952.821C600.084,956.724,814.193,949.942,971.84,954.059C1051.77,956.129,1237.18,850.854,1284.54,772.206C1307.89,733.656,1304.71,656.198,1279.46,618.505C1254.2,580.811,1206.85,526.412,1181.74,488.647C1110.63,381.801,1115.39,268.697,1047.86,160.256C985.269,59.597,778.722,-16.2423,577.955,129.749Z"
          opacity="0.7"
          fill="rgb(224,224,224)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <path
        id="eKveLE8GcMT5"
        d="M375.475,865.957L1034.48,865.957L1069.91,722.229C1080.16,666.6,1045.23,619.189,991.393,619.189C900.858,580.477,803.416,560.518,704.952,560.518C606.488,560.518,509.045,580.477,418.51,619.189C364.669,619.189,329.744,666.6,339.99,722.229L375.475,865.957Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT6"
        d="M405.702,659.08L417.522,655.745C605.376,602.954,804.297,604.11,991.525,659.08C1007.24,659.08,1017.89,672.607,1015.3,688.962L989.097,855.261L409.384,855.261L382.117,688.935C379.422,672.607,389.988,659.08,405.702,659.08Z"
        fill="rgb(237,119,103)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT7"
        d="M405.703,659.078L417.523,655.743C605.377,602.952,804.298,604.107,991.526,659.078C1007.24,659.078,1017.89,672.605,1015.3,688.96L989.098,855.259L409.385,855.259L382.118,688.933C379.423,672.605,389.989,659.078,405.703,659.078Z"
        opacity="0.2"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT8"
        d="M991.34,966.914L1012.2,966.914C1014.21,966.914,1016.18,964.246,1016.98,960.351L1018.61,952.56L987.072,952.56L987.525,960.351C987.765,964.22,989.339,966.914,991.34,966.914Z"
        fill="rgb(158,158,158)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT9"
        d="M440.387,966.914L419.522,966.914C417.521,966.914,415.547,964.246,414.72,960.351L413.119,952.56L444.655,952.56L444.202,960.351C443.962,964.22,442.388,966.914,440.387,966.914Z"
        fill="rgb(158,158,158)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT10"
        d="M391.404,952.559L1018.61,952.559L1034.78,864.7L375.235,864.7L391.404,952.559Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT11"
        d="M705.004,864.706L995.021,864.706L1001.8,822.845C1002.4,819.853,1002.31,816.765,1001.53,813.814C1000.76,810.862,999.326,808.126,997.339,805.811C995.351,803.497,992.862,801.666,990.061,800.457C987.261,799.247,984.221,798.692,981.174,798.832C981.174,798.832,885.578,800.586,854.148,800.586C822.719,800.586,729.497,798.832,729.497,798.832C726.312,798.801,723.152,799.397,720.198,800.586C717.243,801.776,714.552,803.536,712.277,805.766C710.003,807.995,708.19,810.651,706.942,813.582C705.694,816.512,705.036,819.66,705.004,822.845L705.004,864.706Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT12"
        d="M414.989,864.702L705.005,864.702L705.005,828.737C704.879,820.731,701.589,813.101,695.855,807.512C690.122,801.923,682.409,798.83,674.403,798.908L434.946,798.908C418.057,798.908,406.531,812.249,409.172,828.737L414.989,864.702Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT13"
        d="M405.25,952.559L374.941,952.559C361.761,952.559,349.035,942.767,346.5,930.574L303.411,718.278C301.303,708.139,307.76,699.841,317.845,699.841C356.132,699.841,392.23,730.951,398.207,767.957L425.634,930.707C427.609,942.767,418.591,952.559,405.25,952.559Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT14"
        d="M999.852,952.557L1030.19,952.557C1036.93,952.269,1043.39,949.777,1048.58,945.464C1053.77,941.15,1057.4,935.254,1058.92,928.678L1103.21,704.936C1105.56,693.73,1099.18,684.552,1088.99,684.552C1050.3,684.552,1013.35,718.97,1006.76,759.764L979.815,928.651C979.184,931.593,979.232,934.639,979.956,937.558C980.68,940.478,982.061,943.194,983.993,945.499C985.925,947.805,988.358,949.639,991.106,950.862C993.855,952.085,996.846,952.665,999.852,952.557Z"
        fill="rgb(237,119,103)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT15"
        d="M107.03,971.517L1186.13,971.517L1229.46,971.517L1234.56,971.517L1277.86,971.517L1312.97,971.517"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT16"
        d="M1228.03,748.53L1228.03,748.618L1228,756.637L1227.53,971.517L1184.2,971.517L1193.03,754.8L1193.38,746.839L1193.65,740.103L1228.03,748.53Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT17"
        d="M1275.94,971.517L1232.64,971.517L1232.17,756.666L1232.14,748.647L1232.14,748.53L1266.52,740.103L1266.81,747.131L1267.13,755.12L1275.94,971.517Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT18"
        d="M1231.76,756.667C1278.53,756.667,1316.44,749.134,1316.44,739.842C1316.44,730.549,1278.53,723.016,1231.76,723.016C1184.99,723.016,1147.08,730.549,1147.08,739.842C1147.08,749.134,1184.99,756.667,1231.76,756.667Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT19"
        d="M1316.53,731.823C1316.53,741.125,1278.62,748.649,1231.96,748.649C1185.31,748.649,1147.25,741.125,1147.25,731.823C1147.25,722.521,1185.16,715.027,1231.96,715.027C1278.77,715.027,1316.53,722.55,1316.53,731.823Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT20"
        d="M1227.45,658.875C1227.45,658.875,1218.63,547.72,1243.32,497.905C1243.32,497.905,1245.97,598.888,1239.79,658.875L1227.45,658.875Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT21"
        d="M1227.45,658.875C1227.45,658.875,1218.63,547.72,1243.32,497.905C1243.32,497.905,1245.97,598.888,1239.79,658.875L1227.45,658.875Z"
        opacity="0.2"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT22"
        d="M1214.47,653.952C1214.47,653.952,1222.94,623.415,1188.77,552.969C1188.77,552.969,1224.17,557.07,1225.93,653.952L1214.47,653.952Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT23"
        d="M1233.6,643.738C1233.6,643.738,1241.23,561.704,1253.72,520.236C1253.72,520.236,1258.15,570.851,1239.94,641.236L1233.6,643.738Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT24"
        d="M1249.33,559.309C1239.96,645.978,1239.34,648.029,1239.34,647.701"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT25"
        d="M1248.12,659.678C1248.12,659.678,1242.2,632.607,1273.74,560.089C1273.74,560.089,1238.42,562.858,1236.66,659.678L1248.12,659.678Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT26"
        d="M1236.93,545.013C1236.27,551.514,1233.05,603.503,1231.72,647.76"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT27"
        d="M1236.27,659.675C1236.27,659.675,1237.52,511.338,1215.56,499.361C1215.56,499.361,1222.98,634.922,1222.47,658.875L1236.27,659.675Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT28"
        d="M1223.98,547.188C1224.85,552.971,1229.17,616.24,1228.56,647.679"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT29"
        d="M1206.12,578.299C1206.12,578.299,1221.01,613.04,1219.97,647.638"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT30"
        d="M1260.76,580.516C1260.76,580.516,1246.17,615.38,1243.49,647.763"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT31"
        d="M1244.27,730.145L1219.15,730.145L1206.12,647.763L1257.29,647.763L1244.27,730.145Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT32"
        d="M1207.33,675.568L1256.55,675.568C1269.2,679.937,1277.59,687.607,1277.59,696.323C1277.59,705.49,1268.34,722.533,1254.62,732.705L1209.26,732.705C1195.56,722.451,1186.31,705.49,1186.31,696.323C1186.31,687.607,1194.7,679.937,1207.33,675.568Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT33"
        d="M1257.17,699.3C1260.14,698.787,1263.15,698.131,1266.17,697.249L1257.17,699.3Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT34"
        d="M1257.17,699.3C1260.14,698.787,1263.15,698.131,1266.17,697.249"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="eKveLE8GcMT35"
        d="M1198.92,697.249C1215.33,700.992,1232.24,702.038,1248.98,700.346L1198.92,697.249Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT36"
        d="M1198.92,697.249C1215.33,700.992,1232.24,702.038,1248.98,700.346"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g className={classes.eKveLE8GcMT37_tr} transform="translate(128.194,823.389001) rotate(0)">
        <g className={classes.eKveLE8GcMT37} transform="translate(-128.194,-823.388996)">
          <path
            id="eKveLE8GcMT38"
            d="M128.194,823.389L128.194,576.238"
            fill="none"
            stroke="rgb(158,158,158)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            id="eKveLE8GcMT39"
            d="M119.533,600.615C119.533,600.615,115.073,592.506,100.184,574.552C85.2959,556.599,23.4878,508.284,23.4878,508.284C23.4878,508.284,58.4708,526.903,69.6452,536.569C80.8196,546.235,95.708,558.188,95.708,558.188C95.708,558.188,54.0107,512.777,49.5507,509.793C45.0906,506.808,91.9941,545.473,91.9941,545.473C91.9941,545.473,74.1539,509.793,65.1851,497.823C56.2164,485.854,39.1223,457.634,39.1223,457.634C39.1223,457.634,87.5178,522.508,89.7559,526.157C91.9941,529.806,101.66,546.251,101.66,546.251L84.5336,501.586L105.39,538.061C105.39,538.061,75.5973,476.269,74.1215,473.285C72.6456,470.301,94.962,502.316,94.962,502.316L75.5973,444.238L120.279,535.839L117.311,500.159L125.42,537.38L127.739,445.73L132.961,536.553C132.961,536.553,141.071,478.475,142.692,466.571C144.314,454.667,144.914,442,144.914,442C144.914,442,143.422,520.918,143.422,523.092C143.422,525.265,141.947,543.202,141.947,543.202C141.947,543.202,153.105,509.695,156.089,500.759C159.073,491.823,162.041,461.284,163.533,456.824C165.025,452.364,156.089,525.33,155.424,528.298C154.104,533.16,152.362,537.897,150.218,542.456C147.98,546.916,166.598,515.647,173.297,502.235L192.661,463.522C192.661,463.522,181.487,500.824,178.503,507.457C175.518,514.09,156.17,549.901,156.17,549.901C156.17,549.901,185.217,515.647,197.867,506.711C210.518,497.775,233.548,480.648,233.548,480.648C233.548,480.648,196.245,520.854,188.866,528.298C181.487,535.742,159.835,561.805,159.835,561.805C159.835,561.805,197.137,537.98,199.294,535.758C201.452,533.536,228.293,516.475,228.293,516.475C228.293,516.475,174.237,559.177,171.691,561.886C150.104,584.981,142.66,596.139,138.184,600.615C133.708,605.091,126.247,607.313,119.533,600.615Z"
            fill="rgb(204,204,204)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g className={classes.eKveLE8GcMT40_tr} transform="translate(151.61235,824.649109) rotate(0)">
        <g className={classes.eKveLE8GcMT40} transform="translate(-151.61234,-824.649106)">
          <path
            id="eKveLE8GcMT41"
            d="M150.459,819.773L150.459,640.82"
            fill="none"
            stroke="rgb(158,158,158)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            id="eKveLE8GcMT42"
            d="M145.238,665.896C145.238,665.896,142.076,660.09,131.534,647.455C120.992,634.821,77.2837,600.584,77.2837,600.584C77.2837,600.584,102.049,613.754,109.947,620.598C117.846,627.442,128.388,635.876,128.388,635.876C128.388,635.876,98.8865,603.747,95.7239,601.639C92.5613,599.53,125.744,626.923,125.744,626.923C125.744,626.923,113.11,601.639,106.785,593.205C100.46,584.772,88.3446,564.758,88.3446,564.758C88.3446,564.758,122.581,610.591,124.171,613.219L132.604,627.442L120.489,595.833L135.248,621.652C135.248,621.652,114.164,577.863,113.126,575.819C112.088,573.776,127.869,596.368,127.869,596.368L114.18,555.287L145.774,620.063L143.665,594.795L149.471,621.117L151.093,556.341L154.775,620.598C154.775,620.598,160.581,579.517,161.635,571.083C162.689,562.65,163.257,553.697,163.257,553.697C163.257,553.697,162.203,609.537,162.203,611.11C162.203,612.683,161.149,625.334,161.149,625.334L171.155,595.314C173.264,588.988,175.372,567.402,176.426,564.239C177.48,561.077,171.155,612.7,170.636,614.808C169.699,618.245,168.461,621.594,166.939,624.815C165.317,627.961,178.535,605.855,183.27,596.368L196.959,568.975C196.959,568.975,189.06,595.314,186.952,600.049C184.844,604.785,171.155,630.069,171.155,630.069C171.155,630.069,191.704,605.855,200.656,599.53C209.609,593.205,225.941,581.09,225.941,581.09C225.941,581.09,199.489,609.537,194.234,614.808C188.979,620.079,173.702,638.503,173.702,638.503C173.702,638.503,200.024,621.652,201.613,620.063C203.203,618.473,222.146,606.374,222.146,606.374C222.146,606.374,183.919,636.573,182.119,638.503C166.841,654.835,161.586,662.733,158.424,665.896C155.261,669.058,149.974,670.631,145.238,665.896Z"
            fill="rgb(204,204,204)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g className={classes.eKveLE8GcMT43_tr} transform="translate(178.68,824.649109) rotate(0)">
        <g className={classes.eKveLE8GcMT43} transform="translate(-178.68,-824.649105)">
          <path
            id="eKveLE8GcMT44"
            d="M178.68,824.589L178.68,737.788"
            fill="none"
            stroke="rgb(158,158,158)"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            id="eKveLE8GcMT45"
            d="M172.046,741.098C172.046,741.098,168.803,735.308,158.342,722.658C147.881,710.008,104.092,675.787,104.092,675.787C104.092,675.787,128.857,688.956,136.755,695.801C144.654,702.645,155.179,711.078,155.179,711.078C155.179,711.078,125.694,678.95,122.532,676.841C119.369,674.733,152.552,702.126,152.552,702.126C152.552,702.126,139.918,676.841,133.593,668.408C127.268,659.974,115.152,639.977,115.152,639.977C115.152,639.977,149.389,685.794,150.979,688.421L159.396,702.645L147.281,671.051L162.04,696.855C162.04,696.855,140.956,653.065,139.918,651.038C138.88,649.011,154.66,671.57,154.66,671.57L140.972,630.489L172.565,695.265L170.457,669.997L176.247,696.32L177.869,631.544L181.55,695.801C181.55,695.801,187.34,654.72,188.394,646.286C189.449,637.853,190.016,628.916,190.016,628.916C190.016,628.916,188.962,684.74,188.962,686.313C188.962,687.886,187.908,700.536,187.908,700.536L197.915,670.516C200.023,664.191,202.131,642.605,203.186,639.442C204.24,636.279,197.915,687.902,197.396,690.011C196.46,693.449,195.223,696.797,193.698,700.017C192.076,703.18,205.294,681.058,210.03,671.57L223.653,644.145C223.653,644.145,215.755,670.484,213.646,675.22C211.538,679.955,197.85,705.256,197.85,705.256C197.85,705.256,218.398,681.026,227.351,674.701C236.303,668.375,252.619,656.293,252.619,656.293C252.619,656.293,226.297,684.691,221.026,690.011C215.755,695.33,200.477,713.706,200.477,713.706C200.477,713.706,226.816,696.855,228.405,695.265C229.994,693.676,248.937,681.577,248.937,681.577C248.937,681.577,210.711,711.792,208.911,713.706C193.633,730.037,188.362,737.936,185.216,741.098C182.069,744.261,176.782,745.834,172.046,741.098Z"
            fill="rgb(204,204,204)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <path
        id="eKveLE8GcMT46"
        d="M205.375,882.556C205.375,917.717,196.131,948.759,182.005,967.508L119.337,967.508C105.211,948.759,95.9666,917.717,95.9666,882.556C95.9666,879.491,95.9666,876.442,96.1774,873.441C96.5174,865.831,97.3246,858.248,98.594,850.736C99.0643,847.979,99.5833,845.27,100.216,842.627C100.702,840.421,101.221,838.264,101.838,836.139C104.24,826.776,107.701,817.716,112.153,809.136L189.368,809.136C193.82,817.716,197.28,826.776,199.683,836.139C200.25,838.264,200.769,840.421,201.305,842.627C201.888,845.27,202.407,847.979,202.926,850.736C204.196,858.248,205.003,865.831,205.343,873.441C205.311,876.393,205.375,879.442,205.375,882.556Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT47"
        d="M199.601,836.086L101.788,836.086C101.221,838.211,100.702,840.368,100.167,842.574L201.174,842.574C200.72,840.384,200.169,838.211,199.601,836.086ZM202.747,850.683L98.5447,850.683C97.2752,858.195,96.4682,865.778,96.1282,873.389L205.115,873.389C204.791,865.779,204.001,858.196,202.747,850.683Z"
        fill="rgb(158,158,158)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT48"
        d="M842.399,274.285L575.828,274.285L575.828,432.178L842.399,432.178L842.399,274.285Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT49"
        d="M842.399,395.998L842.399,432.179L575.828,432.179L575.828,322.457C625.488,328.034,754.54,346.629,842.399,395.998Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT50"
        d="M772.348,432.176L575.828,432.176L575.828,335.267C627.817,354.773,678.215,378.28,726.571,405.575C745.183,416.181,760.219,424.957,772.348,432.176Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT51"
        d="M842.399,274.285L842.399,312.159C822.556,313.151,791.713,315.957,743.968,322.423C660.83,333.799,603.301,360.93,575.828,376.514L575.828,274.285L842.399,274.285Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT52"
        d="M575.828,274.285L728.076,274.285C718.411,279.417,708.66,285.268,698.91,291.717C636.3,333.063,597.519,348.168,575.879,353.625L575.828,274.285Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT53"
        d="M575.828,319.789C595.86,309.969,619.997,294.676,631.236,274.285L575.828,274.285L575.828,319.789Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT54"
        d="M633.238,392.197C600.428,371.669,585.049,379.898,601.454,405.523C609.204,417.635,615.328,426.29,619.348,432.175L672.754,432.175C664.988,420.269,651.97,403.864,633.238,392.197Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT55"
        d="M842.399,274.285L575.828,274.285L575.828,432.178L842.399,432.178L842.399,274.285Z"
        fill="none"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT56"
        d="M484.042,660.645C484.042,660.645,490.74,655.085,442.786,663.243C394.832,671.401,382.15,682.457,382.15,682.457C382.15,682.457,366.451,683.367,365.816,689.507C365.181,695.646,378.065,714.88,378.065,714.88L403.784,793.45C403.784,793.45,395.04,805.391,399.098,807.049C403.155,808.707,421.579,800.543,421.579,800.543C421.579,800.543,473.113,800.905,496.095,792.805C519.077,784.705,535.756,779.887,535.756,779.887C535.756,779.887,543.888,778.63,544.138,775.56C544.387,772.489,539.469,764.968,539.469,764.968C539.469,764.968,524.697,703.645,505.153,670.303C505.153,670.303,508.616,658.454,501.281,655.062C493.946,651.67,484.042,660.645,484.042,660.645Z"
        fill="rgb(72,128,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT57"
        d="M484.042,660.645C484.042,660.645,490.74,655.085,442.786,663.243C394.832,671.401,382.15,682.457,382.15,682.457C382.15,682.457,366.451,683.367,365.816,689.507C365.181,695.646,378.065,714.88,378.065,714.88L403.784,793.45C403.784,793.45,395.04,805.391,399.098,807.049C403.155,808.707,421.579,800.543,421.579,800.543C421.579,800.543,473.113,800.905,496.095,792.805C519.077,784.705,535.756,779.887,535.756,779.887C535.756,779.887,543.888,778.63,544.138,775.56C544.387,772.489,539.469,764.968,539.469,764.968C539.469,764.968,524.697,703.645,505.153,670.303C505.153,670.303,508.616,658.454,501.281,655.062C493.946,651.67,484.042,660.645,484.042,660.645Z"
        fill="rgb(242,242,242)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT58"
        d="M569.309,666.239C569.309,666.239,576.801,661.767,528.141,662.505C479.481,663.243,465.281,672.232,465.281,672.232C465.281,672.232,449.628,670.734,448.063,676.705C446.497,682.675,456.292,703.651,456.292,703.651L469.709,785.227C469.709,785.227,459.221,795.715,463.001,797.951C466.78,800.188,486.19,794.955,486.19,794.955C486.19,794.955,537.086,803.206,561.035,798.712C584.985,794.217,602.204,792.003,602.204,792.003C602.204,792.003,610.433,792.003,611.149,789.029C611.864,786.055,608.152,777.848,608.152,777.848C608.152,777.848,602.919,714.966,588.697,679.053C588.697,679.053,593.93,667.872,587.199,663.399C580.468,658.927,569.309,666.239,569.309,666.239Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT59"
        d="M883.147,768.999C883.147,768.999,874.6,770.649,920.561,786.577C966.522,802.504,982.96,798.916,982.96,798.916C982.96,798.916,997.159,805.673,1000.67,800.597C1004.18,795.521,1002.15,772.461,1002.15,772.461L1017.41,691.21C1017.41,691.21,1030.82,684.951,1028.07,681.545C1025.31,678.139,1005.23,676.429,1005.23,676.429C1005.23,676.429,960.229,651.31,936.185,647.35C912.141,643.39,895.203,639.586,895.203,639.586C895.203,639.586,887.469,636.774,885.772,639.346C884.076,641.917,884.768,650.878,884.768,650.878C884.768,650.878,868.204,711.741,869.29,750.373C869.29,750.373,860.551,759.093,865.349,765.596C870.146,772.1,883.147,768.999,883.147,768.999Z"
        fill="rgb(242,242,242)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT60"
        d="M825.269,793.407C825.269,793.407,817.596,797.559,866.243,798.872C914.891,800.185,929.457,791.802,929.457,791.802C929.457,791.802,945.033,793.958,946.849,788.059C948.664,782.159,939.762,760.79,939.762,760.79L929.794,678.72C929.794,678.72,940.714,668.684,937.033,666.29C933.351,663.897,913.738,668.307,913.738,668.307C913.738,668.307,863.235,657.919,839.117,661.4C814.999,664.882,797.702,666.369,797.702,666.369C797.702,666.369,789.48,666.022,788.64,668.963C787.8,671.905,791.163,680.261,791.163,680.261C791.163,680.261,793.742,743.307,806.438,779.788C806.438,779.788,800.739,790.738,807.276,795.49C813.812,800.242,825.269,793.407,825.269,793.407Z"
        fill="rgb(255,255,255)"
        stroke="rgb(158,158,158)"
        strokeWidth="1"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        id="eKveLE8GcMT61"
        d="M836,830.9L552,830.9L552,857L836,857L836,830.9Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT62"
        d="M836,830.9L552,830.9L552,857L836,857L836,830.9Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT63"
        d="M631.2,856.9L621.6,877.2L574.4,976L562.4,976L594.1,877.2L600.5,856.9L631.2,856.9Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT64"
        d="M631.2,856.9L621.6,877.2L574.4,976L562.4,976L594.1,877.2L600.5,856.9L631.2,856.9Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT65"
        d="M600.5,856.9L631.2,856.9L621.6,877.2L594.1,877.2L600.5,856.9Z"
        opacity="0.2"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT66"
        d="M825.7,976L813.6,976L766.4,877.2L756.8,856.9L787.5,856.9L794,877.2L825.7,976Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT67"
        d="M825.7,976L813.6,976L766.4,877.2L756.8,856.9L787.5,856.9L794,877.2L825.7,976Z"
        fill="rgb(204,204,204)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT68"
        d="M794,877.2L766.4,877.2L756.8,856.9L787.5,856.9L794,877.2Z"
        opacity="0.2"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <g className={classes.eKveLE8GcMT69}>
        <g className={classes.eKveLE8GcMT70_to} transform="translate(630.303833,787.649)">
          <path
            className={classes.eKveLE8GcMT70}
            d="M627.404,790C627.404,790,646.904,780.5,646.604,768.3C646.304,756.1,620.604,748.9,620.404,739.4C620.204,729.9,631.204,724,631.204,724C631.204,724,613.704,730.4,614.004,742.4C614.304,754.4,631.304,762.2,633.004,769.8C635.604,780.4,627.404,790,627.404,790Z"
            transform="translate(-630.303833,-757)"
            opacity="0"
            fill="rgb(255,255,255)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g className={classes.eKveLE8GcMT71_to} transform="translate(666.303192,787.649)">
          <path
            className={classes.eKveLE8GcMT71}
            d="M669.203,790C669.203,790,649.703,780.5,650.003,768.3C650.303,756.1,676.003,748.9,676.203,739.4C676.403,729.9,665.403,724,665.403,724C665.403,724,682.903,730.4,682.603,742.4C682.303,754.4,665.303,762.2,663.603,769.8C661.003,780.4,669.203,790,669.203,790Z"
            transform="translate(-666.303192,-757)"
            opacity="0"
            fill="rgb(255,255,255)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g className={classes.eKveLE8GcMT72_to} transform="translate(647.634126,801.149)">
          <path
            className={classes.eKveLE8GcMT72}
            d="M649.347,757C649.347,757,637.825,751.386,638.002,744.177C638.179,736.968,653.366,732.714,653.484,727.1C653.602,721.486,647.102,718,647.102,718C647.102,718,657.443,721.782,657.266,728.873C657.088,735.964,647.043,740.573,646.038,745.064C644.502,751.327,649.347,757,649.347,757Z"
            transform="translate(-647.634125,-737.5)"
            opacity="0"
            fill="rgb(255,255,255)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <path
        id="eKveLE8GcMT73"
        d="M657.4,827.9L638.4,827.9L638.4,830.9L657.4,830.9L657.4,827.9Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT74"
        d="M657.4,827.9L638.4,827.9L638.4,829.9L657.4,829.9L657.4,827.9Z"
        opacity="0.1"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT75"
        d="M615.8,819.9C618.2,823.4,622.3,825.2,626.5,824.5C627.2,824.4,627.8,824.2,628.4,823.9C629.3,823.5,630.2,823.1,631.1,822.5L628.7,818.9C628.3,819.2,627.8,819.5,627.3,819.7C624.4,821,621.1,820,619.3,817.4C616.7,813.7,615.8,805.4,618.4,802.2C618.6,802,619.3,801.6,622.4,802.2C623,802.3,623.6,802.5,624.3,802.7C624.9,802.9,625.4,803,626,803.1L626.1,801L626.3,798.9C626.1,798.9,625.8,798.8,625.4,798.7C624,798.3,622.5,798,621.1,797.8C618.8,797.6,616.5,797.8,615,799.6C610.8,804.7,612.6,815.4,615.8,819.9Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT76"
        d="M628.7,819C628.3,819.3,627.8,819.6,627.3,819.8L628.5,824C629.4,823.6,630.3,823.2,631.2,822.6L628.7,819Z"
        opacity="0.1"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT77"
        d="M621.2,797.7L622.5,802.3C623.1,802.4,623.7,802.6,624.4,802.8C625,803,625.5,803.1,626.1,803.2L626.2,801.1L626.4,799C626.2,799,625.9,798.9,625.5,798.8C624.1,798.2,622.6,797.9,621.2,797.7Z"
        opacity="0.1"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT78"
        d="M673.2,792.1C673.2,792.1,670.9,811.5,667,819.6C663.1,827.7,657.6,828.7,654.1,828.7L641.7,828.7C638.3,828.7,632.8,827.8,628.9,819.6C624.9,811.5,622.7,792.1,622.7,792.1L673.2,792.1Z"
        fill="rgb(242,242,242)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT79"
        d="M670.7,807C670.5,807.8,670.3,808.6,670.2,809.5L625.6,809.5L625.1,807L670.7,807Z"
        opacity="0.1"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
      <path
        id="eKveLE8GcMT80"
        d="M669.6,811.9C669.2,813.6,668.6,815.4,668,817L627.8,817C627.2,815.5,626.7,813.7,626.2,811.9L669.6,811.9Z"
        opacity="0.1"
        fill="rgb(158,158,158)"
        stroke="none"
        strokeWidth="1"
      />
    </svg>
  );
}
