/* eslint-disable */

import React, { useEffect, useState } from 'react';
import {
  Drawer,
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  useTheme,
  makeStyles,
  Tooltip,
  Fab,
  Zoom,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';
import BtnWithLoader from 'custom-components/btnWithLoader';
import TooltipIcon from '@material-ui/icons/InfoOutlined';
import { ArrowLeft } from 'react-feather';
import SvgInfoCircle from 'icons/svgInfoCircle';

import MultiLanguageStatus from 'custom-components/form-fields/multiLanguageStatus';
import MultiLangOptions from 'utility/MultiLangOptions';

// redux
import { shallowEqual, useSelector } from 'react-redux';
import {
  eventLanguagesSelector,
  currentEventLanguagesSelector,
  baseEventLanguagesSelector,
  commonSelector,
} from 'redux/custom-selector';
import MultiLanguageDropDown from './form-fields/multiLanguageDropDown';
import { useCommunityVersionNumber } from 'lib/hooks/use-community-version';
import SwitchField from './form-fields/switch';

const useStyles = makeStyles((theme) => ({
  asideWrapper: {
    zIndex: theme.zIndex.modal + ' !important',
  },
  asideHeader: {
    backgroundColor: theme.palette.bg.whiteOff1,
  },
  asideInner: {
    height: `calc(100% + ${theme.spacing(6)}px)`,
  },
  withFooterAsideContent: {
    maxHeight: 'calc(100vh - 142px)',
  },
  withoutFooterAsideContent: {
    maxHeight: 'calc(100vh - 70px)',
  },
  innerBlocks: {
    paddingTop: '20px !important',
    paddingBottom: '20px !important',

    '&.spacingZero': {
      padding: '0px !important',
    },
  },
  headerCloseBtn: {
    color: theme.palette.text.primary,
  },
  asideFooter: {
    borderTop: `1px solid ${theme.palette.border.light}`,
  },
  infoMsg: {
    background: theme.palette.primary.main,
  },
  whiteMsg: {
    color: '#fff',
  },
  tooltipFabIcon: {
    fontSize: 16,
  },
  tooltipFab: {
    width: 16,
    height: 16,
    minHeight: 16,
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: theme.palette.text.third,
    boxShadow: 'none',
    marginLeft: 5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  minWidthBtn: {
    minWidth: 100,
  },
  minWidthBtn: {
    minWidth: 100,
  },
}));

/* const MultiLanguageOptions = [
  { langIcon: <SvgUsFlag height="20px" width="20px" />, langName: 'English' },
  { langIcon: <SvgIndiaFlag height="20px" width="20px" />, langName: 'Hindi' },
  { langIcon: <SvgFranceFlag height="20px" width="20px" />, langName: 'France' },
  { langIcon: <SvgItalyFlag height="20px" width="20px" />, langName: 'Italy' },
  { langIcon: <SvgIndiaFlag height="20px" width="20px" />, langName: 'Gujarati' },
] */

const Sidebar = ({
  /* variant = "persistent",*/
  addMeetingLocation,
  onScroll,
  className = '',
  isLanguageSupport = false,
  infoMsgBlock,
  position = 'right',
  infoMsgTitle,
  showFooterCloseBtn = true,
  children,
  open,
  hasBackBtn,
  onCancel,
  onClose,
  onCustomCancelBtn,
  asideWidth,
  footerRightBtnVariant = 'contained',
  title,
  isFooterDisable,
  loading,
  footerBtnAction,
  footerLoading,
  footerBtnLoadingLabel,
  footerBtnLabel,
  footerCloseBtnLabel = 'Cancel',
  footerRightBtnAction,
  footerRightBtnLoading,
  footerRightBtnLoadingLabel,
  footerRightBtnCancelLabel,
  disabled,
  footerRightBtnLabel,
  tooltipText,
  secondaryTitle,
  secondaryAction,
  bodySpacingZero,
  footerRow,
  footerCloseBtnTooltip,
  onLanguageChange,
  isEdit,
  isNewEventSidebar,
  headerActionBtn,
  isBackButtonClick,
  bottomStaticStripe,

  ...restProps
}) => {
  const {
    asideWrapper = '',
    aside,
    asideInner,
    withFooterAsideContent,
    withoutFooterAsideContent,
    innerBlocks,
    headerCloseBtn,
    asideHeader,
    asideFooter,
    tooltipFab,
    tooltipFabIcon,
    infoMsg,
    whiteMsg,
  } = useStyles();
  const theme = useTheme();
  const eventCommunityVersion = useCommunityVersionNumber();

  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const commonSelectorData = useSelector(commonSelector, shallowEqual);
  const [multiLanguageOptions, setMultilanguageOptions] = useState([]);
  const [langNotification, setLangNotification] = useState(false);

  useEffect(() => {
    if (eventLanguages && eventLanguages.length > 0) {
      let languages = [];
      for (let language of eventLanguages) {
        const langIndex = MultiLangOptions.findIndex((i) => i.langName === language.name);
        if (langIndex !== -1) {
          languages.push({
            ...MultiLangOptions[langIndex],
            id: language.id,
          });
        }
      }
      setMultilanguageOptions(languages);
    }
  }, [eventLanguages]);

  const onGotItClick = () => {
    setLangNotification(false);
    if (commonSelectorData && commonSelectorData?.eventId && commonSelectorData?.userId)
      localStorage.setItem(
        `${commonSelectorData?.eventId}-${commonSelectorData?.userId}-langNotificationSeen`,
        'true'
      );
  };

  useEffect(() => {
    if (typeof isLanguageSupport === 'boolean') {
      setLangNotification(isLanguageSupport);
    }
  }, [isLanguageSupport]);

  return (
    <Drawer
      className={`${className} ${asideWrapper}`}
      open={open}
      onClose={onClose}
      anchor={position}
      onScroll={onScroll}>
      <Box
        p={3}
        component="aside"
        className={`h-100 ${aside}`}
        width={asideWidth ? asideWidth : theme.asideWidth}>
        <Grid
          spacing={6}
          container
          direction="column"
          className={`${asideInner} h-100`}
          wrap="nowrap">
          <Grid item className={`${innerBlocks} ${asideHeader}`} component="header">
            <Grid container justifyContent="space-between" spacing={4} alignItems="center">
              <Grid item xs>
                <Box display="flex" alignItems="center">
                  {!hasBackBtn ? (
                    false
                  ) : (
                    <Box
                      mr={1}
                      onClick={() => {
                        // Added This click so any one in future can add handling to backButtonClick as well - @sachin
                        if (isBackButtonClick) {
                          isBackButtonClick(true);
                        }
                      }}>
                      {' '}
                      <IconButton
                        className={headerCloseBtn}
                        onClick={onCancel || onClose}
                        size="small">
                        <ArrowLeft size={22} />
                      </IconButton>
                    </Box>
                  )}
                  <Typography component="h3" variant="h6">
                    {title || ''}
                  </Typography>
                  {addMeetingLocation}
                  {!tooltipText ? (
                    false
                  ) : (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={tooltipText}
                      interactive
                      placement={'right'}
                      arrow>
                      <Fab size="small" className={tooltipFab}>
                        <TooltipIcon className={tooltipFabIcon} />
                      </Fab>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center">
                  {!isNewEventSidebar && isLanguageSupport ? (
                    <Box mr={1}>
                      <MultiLanguageDropDown
                        onMenuSelect={onLanguageChange}
                        options={multiLanguageOptions}
                        isDisable={isLanguageSupport && !isEdit}
                        isEdit={isEdit}
                      />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
              </Grid>

              <Grid item>
                <Box display="flex" alignItems="center">
                  {!secondaryTitle ? (
                    false
                  ) : (
                    <Box mr={2}>
                      <Link color="primary" className="cursor-pointer" onClick={secondaryAction}>
                        New Theme
                      </Link>
                    </Box>
                  )}
                  {headerActionBtn && headerActionBtn}
                  <IconButton className={headerCloseBtn} onClick={onCancel || onClose} size="small">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {langNotification &&
            !isNewEventSidebar &&
            !localStorage.getItem(
              `${commonSelectorData?.eventId}-${commonSelectorData?.userId}-langNotificationSeen`
            ) && (
              <Box>
                <MultiLanguageStatus onGotItClick={onGotItClick} />
              </Box>
            )}
          {infoMsgBlock ? (
            <Box width="100%" px={2.5} py={1.5} className={infoMsg}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" mr={2}>
                  <SvgInfoCircle />
                </Box>
                <Typography variant="caption" className={whiteMsg}>
                  {infoMsgTitle}
                </Typography>
              </Box>
            </Box>
          ) : (
            false
          )}
          <Grid
            item
            xs
            className={`${innerBlocks} ${
              isFooterDisable ? withoutFooterAsideContent : withFooterAsideContent
            } ${bottomStaticStripe} ${bodySpacingZero ? 'spacingZero' : ''} scroll-y`}>
            {loading ? <div>Loader</div> : false}
            {children && children}
          </Grid>
          {isFooterDisable ? (
            false
          ) : (
            <Grid item className={`${innerBlocks} ${asideFooter}`} component="footer">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  {!footerBtnLabel ? (
                    false
                  ) : (
                    <Box mr={2} display={{ xs: 'inline-flex' }}>
                      <BtnWithLoader
                        onClick={footerBtnAction}
                        disabled={footerLoading || disabled || restProps.footerBtnDisabled}
                        variant="contained"
                        size="small"
                        color="primary"
                        isLoading={footerLoading}
                        loadingLabel={footerBtnLoadingLabel}
                        label={footerBtnLabel}
                      />
                    </Box>
                  )}
                  {showFooterCloseBtn && !footerRow ? (
                    footerCloseBtnTooltip ? (
                      <Tooltip title={footerCloseBtnTooltip} arrow placement="top">
                        <Box>
                          <Button
                            component={
                              footerLoading || restProps.footerCloseBtnDisabled ? 'span' : 'div'
                            }
                            disabled={footerLoading || restProps.footerCloseBtnDisabled}
                            onClick={onCustomCancelBtn ? onCustomCancelBtn : onCancel || onClose}
                            size="small"
                            variant="outlined"
                            color="primary">
                            {footerCloseBtnLabel}
                          </Button>
                        </Box>
                      </Tooltip>
                    ) : !footerRightBtnCancelLabel ? (
                      <Button
                        disabled={footerLoading || restProps.footerCloseBtnDisabled}
                        onClick={onCustomCancelBtn ? onCustomCancelBtn : onCancel || onClose}
                        size="small"
                        variant="outlined"
                        color="primary">
                        {footerCloseBtnLabel}
                      </Button>
                    ) : null
                  ) : null}
                </Box>
                <Box>
                  {footerRightBtnCancelLabel ? (
                    <Box mr={2} display={{ xs: 'inline-flex' }}>
                    <Button
                      disabled={footerLoading || restProps.footerCloseBtnDisabled}
                      onClick={onCustomCancelBtn ? onCustomCancelBtn : onCancel || onClose}
                      size="small"
                      variant="outlined"
                      color="primary">
                      {footerRightBtnCancelLabel}
                    </Button>
                    </Box>
                  ) : null}
                  {!footerRightBtnLabel ? null : (
                    <BtnWithLoader
                      // className="uppercase"
                      onClick={footerRightBtnAction}
                      isLoading={footerRightBtnLoading}
                      disabled={footerRightBtnLoading || restProps.footerRightBtnDisabled}
                      loadingLabel={footerRightBtnLoadingLabel}
                      label={footerRightBtnLabel}
                      size="small"
                      variant={footerRightBtnVariant}
                      color="primary"
                    />
                  )}
                </Box>
              </Box>
              {footerRow}
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
