import CustomModal from 'custom-components/customModal';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
import InputField from 'custom-components/form-fields/inputField';
import { ArrowRight, Monitor, Smartphone, Tablet } from 'react-feather';
import SvgTabMockUp from 'icons/svgTabMockUp';
import SvgMobMockup from 'icons/svgMobMockup';
import { TEMPLATE_PREVIEW } from './constants';
import BtnWithLoader from 'custom-components/btnWithLoader';

export default function ChooseLandingModal({
  isOpen,
  onClose,
  buildTemplateData,
  getSelectedView,
  setSelectedView,
  setTemplateName,
  templateName,
  onBuildHandler,
  errorMsg,
  rename = false,
  renameLoader,
}) {
  const classes = useStyles();
  const {
    desktopThumbnailUrl = '',
    tabletThumbnailUrl = '',
    mobileThumbnailUrl = '',
    name = '',
    id,
    thumbnailUrl,
  } = buildTemplateData;
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} paddingZero>
      <Box
        width="800px"
        display="flex"
        className={`${classes.chooseModal} ${!rename ? '' : classes.renameModal} `}>
        <Box
          width="50%"
          px={5}
          py={2}
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="center">
          <Typography variant="h4" component="h4">
            {!rename ? 'Build your landing page' : 'Rename your landing page'}
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary" className="subTitle">
            {!rename
              ? 'Name your page to find it easily'
              : ' Page name (so you can find your page easily)'}
          </Typography>
          <InputField
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            placeholder="Enter Your Page Name"
            error={errorMsg}
            maxLength={50}
          />
          {!rename ? (
            <>
              <Box mt={4} display="inline-block">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onBuildHandler({ templateId: id, name: templateName }, true)}>
                  Continue
                </Button>
              </Box>
              <Box display="flex" justifyContent="flex-end" className="skipButton">
                <IconButton onClick={() => onBuildHandler({ templateId: id, name: '' }, false)}>
                  Skip <ArrowRight size={17} />
                </IconButton>
              </Box>
            </>
          ) : (
            <Box mt={4} display="flex" className="actionButton">
              <BtnWithLoader
                variant="contained"
                color="primary"
                onClick={() => onBuildHandler({ templateId: id, name: templateName }, true)}
                label="Save"
                isLoading={renameLoader}
                loadingLabel="Updating.."
                disabled={renameLoader}
              />
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
        {rename ? (
          <Box
            width="50%"
            className="leftBlock"
            px={3}
            py={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className={`deskPreview ${rename ? 'renamePreview' : false}`} width="80%">
                {thumbnailUrl && (thumbnailUrl.includes('png') || thumbnailUrl.includes('jpg')) ? (
                  <img src={thumbnailUrl} alt="template-img" />
                ) : (
                  <iframe
                    title="Embed Iframe"
                    src={thumbnailUrl}
                    name="customTemplatePreview"
                    width="100%"
                    className="iframeSet"
                    height={330}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            width="50%"
            className="leftBlock"
            px={3}
            py={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              {getSelectedView.preview === TEMPLATE_PREVIEW.MOBILE ? (
                <Box position="relative">
                  <SvgMobMockup height="20rem" />
                  <Box className="previewMob">
                    {mobileThumbnailUrl.includes('png') || mobileThumbnailUrl.includes('jpg') ? (
                      <img src={mobileThumbnailUrl} alt="template-img" />
                    ) : (
                      <iframe
                        title="Embed Iframe"
                        src={mobileThumbnailUrl}
                        name="customTemplatePreview"
                        width="100%"
                        className="iframeSet"
                        height="100%"
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                false
              )}
              {getSelectedView.preview === TEMPLATE_PREVIEW.TABLET ? (
                <Box position="relative">
                  <SvgTabMockUp height="19rem" />
                  <Box className="previewTab">
                    {tabletThumbnailUrl.includes('png') || tabletThumbnailUrl.includes('jpg') ? (
                      <img src={tabletThumbnailUrl} alt="template-img" />
                    ) : (
                      <iframe
                        title="Embed Iframe"
                        src={tabletThumbnailUrl}
                        name="customTemplatePreview"
                        width="100%"
                        className="iframeSet"
                        height="100%"
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                false
              )}
              {getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP ? (
                <Box className="deskPreview" width="80%">
                  {desktopThumbnailUrl.includes('png') || desktopThumbnailUrl.includes('jpg') ? (
                    <img src={desktopThumbnailUrl} alt="template-img" />
                  ) : (
                    <iframe
                      title="Embed Iframe"
                      src={desktopThumbnailUrl}
                      name="customTemplatePreview"
                      width="100%"
                      className="iframeSet"
                      height={330}
                    />
                  )}
                </Box>
              ) : (
                false
              )}
            </Box>
            <Box
              mt={3}
              display="flex"
              width="100%"
              px={5}
              alignItems="center"
              justifyContent="space-between">
              <Typography variant="h5" component="h5" className="title">
                {name}
              </Typography>
              <Box display="flex" alignItems="center" className="modalAction">
                <IconButton
                  className={getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP ? 'active' : ''}
                  onClick={() =>
                    setSelectedView({ ...getSelectedView, preview: TEMPLATE_PREVIEW.DESKTOP })
                  }>
                  <Monitor size={20} />
                </IconButton>
                <IconButton
                  className={getSelectedView.preview === TEMPLATE_PREVIEW.TABLET ? 'active' : ''}
                  onClick={() =>
                    setSelectedView({ ...getSelectedView, preview: TEMPLATE_PREVIEW.TABLET })
                  }>
                  <Tablet size={20} />
                </IconButton>
                <IconButton
                  className={getSelectedView.preview === TEMPLATE_PREVIEW.MOBILE ? 'active' : ''}
                  onClick={() =>
                    setSelectedView({ ...getSelectedView, preview: TEMPLATE_PREVIEW.MOBILE })
                  }>
                  <Smartphone size={14} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </CustomModal>
  );
}
