import React from 'react';

export default function SvgBasicPlanIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3841 18.3296C15.1637 17.918 15.0352 17.4644 15.0063 17H8.99372C8.96478 17.4644 8.83626 17.918 8.61595 18.3296C8.36383 18.8008 7.99929 19.2023 7.5547 19.4987C7.38407 19.6125 7.24416 19.7666 7.14739 19.9474C7.05063 20.1282 7 20.3301 7 20.5352V21H17V20.5352C17 20.3301 16.9494 20.1282 16.8526 19.9474C16.7558 19.7666 16.6159 19.6125 16.4453 19.4987C16.0007 19.2023 15.6362 18.8008 15.3841 18.3296ZM8 16.7982C8 17.1679 7.90873 17.5318 7.73428 17.8578C7.55983 18.1837 7.30761 18.4616 7 18.6667C6.69239 18.8717 6.44017 19.1496 6.26572 19.4755C6.09127 19.8015 6 20.1655 6 20.5352V22H18V20.5352C18 20.1655 17.9087 19.8015 17.7343 19.4755C17.5598 19.1496 17.3076 18.8717 17 18.6667C16.6924 18.4616 16.4402 18.1837 16.2657 17.8578C16.0913 17.5318 16 17.1679 16 16.7982V16H8V16.7982Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8 11.8624V11H11.2V11.8624C11.2 13.2878 10.9657 14.6974 10.5123 16H13.4877C13.0343 14.6974 12.8 13.2878 12.8 11.8624ZM14.5526 16C14.685 16.3438 14.8343 16.6779 15 17H9C9.16566 16.6779 9.31496 16.3438 9.44741 16C9.94054 14.7198 10.2 13.3038 10.2 11.8624V10H13.8V11.8624C13.8 13.3038 14.0595 14.7198 14.5526 16Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11H9V9L10.8 7H13.2L15 9V11ZM12.7546 8H11.2454L10 9.38374V10H14V9.38374L12.7546 8Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.33334C12.9205 6.33334 13.6667 5.58714 13.6667 4.66667C13.6667 3.74619 12.9205 3 12 3C11.0796 3 10.3334 3.74619 10.3334 4.66667C10.3334 5.58714 11.0796 6.33334 12 6.33334ZM14.6667 4.66667C14.6667 6.13943 13.4728 7.33334 12 7.33334C10.5273 7.33334 9.33337 6.13943 9.33337 4.66667C9.33337 3.19391 10.5273 2 12 2C13.4728 2 14.6667 3.19391 14.6667 4.66667Z"
        fill="#F59C86"
      />
    </svg>
  );
}
