export const SET_USER_INFO = "SET_USER_INFO";
export const SET_USER_PLAN_INFO = "SET_USER_PLAN_INFO";
export const REMOVE_USER_INFO = "REMOVE_USER_INFO";
export const SET_TABLE_DATA = "SET_TABLE_DATA";

export const setUserInfo = (userInfo) => {   
    return {
        type: SET_USER_INFO,
        data: userInfo
    }
}

export const setTableData = (ticketDataInfo) => {
    return {
        type: SET_TABLE_DATA,
        data: ticketDataInfo
    }
}

export const setUserPlanInfo = (userPlanInfo) => {
    return {
        type: SET_USER_PLAN_INFO,
        data: userPlanInfo
    }
}
export const removeUserInfo = () => {
    return {
        type: REMOVE_USER_INFO
    }
}