import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  msgBlock: {
    background: theme.palette.alert.warn2Light,
    borderRadius: theme.shape.borderRadius,
    '&>p': {
      fontWeight: 500,
    },
    '& .msg-icon': {
      background: theme.palette.primary.light,
      borderRadius: '50%',
      height: 25,
      width: 25,
    },
  },
}));
