/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { getLeadLevelDetails } from '../../lead-level/handler';

const useStyles = makeStyles((theme) => ({
  chartsTooltipWrapper: {
    width: '190px',
    height: '45px',
    borderRadius: '4px',
    padding: theme.spacing(1.5),
    background: theme.palette.bg.white,
    filter: 'drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.12))',

    '& .tooltipContentBox': {
      paddingBottom: theme.spacing(1.25),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& span': {
        fontSize: '12px',
      },
      '& .tooltipContent': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .legendColor': {
      width: '12px',
      height: '12px',
      borderRadius: '2px',
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  },
}));

const ChartsTooltipComponent = (props) => {
  const classes = useStyles();

  return (
    <>
      {props && props?.payload?.length > 0 ? (
        <Box className={`${classes.chartsTooltipWrapper} ${props.className}`}>
          {!props.isShowAbsoluteCountOnly &&
            props?.payload.map(({ payload } = {}) => {
              const { id = '', name = '', total = 0 } = payload || {};
              const { color } = getLeadLevelDetails(id);

              return (
                <Box className="tooltipContentBox" key={id}>
                  <Box className="tooltipContent">
                    <span className="legendColor" style={{ background: color }}>
                      &nbsp;
                    </span>
                    <Typography
                      component="span"
                      veriant="caption"
                      color="textSecondary"
                      className="legendName">
                      {name}
                    </Typography>
                  </Box>
                  <Typography
                    component="span"
                    veriant="caption"
                    color="textPrimary"
                    className="legendCount">
                    {total}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      ) : null}
    </>
  );
};

export default ChartsTooltipComponent;
