import React from 'react';

export default function SvgStepperLockIcon(props) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path d="M11.6667 7.33325H2.33333C1.59695 7.33325 1 7.93021 1 8.66659V13.3333C1 14.0696 1.59695 14.6666 2.33333 14.6666H11.6667C12.403 14.6666 13 14.0696 13 13.3333V8.66659C13 7.93021 12.403 7.33325 11.6667 7.33325Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.66675 7.33325V4.66659C3.66675 3.78253 4.01794 2.93468 4.64306 2.30956C5.26818 1.68444 6.11603 1.33325 7.00008 1.33325C7.88414 1.33325 8.73198 1.68444 9.3571 2.30956C9.98222 2.93468 10.3334 3.78253 10.3334 4.66659V7.33325" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
