import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";

const Counter = ({ handleClick, anchorLink }) => {
  const [counter, setCounter] = useState(60);

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
  return (
    <>
      {counter === 0 ? (
        <Typography color="textSecondary" variant="body2" component="p">
          Didn’t receive code?
          <Typography
            color="primary"
            className={anchorLink}
            variant="body2"
            onClick={() => {
              setCounter(60);
              handleClick();
            }}
            component="a">
            Resend code
          </Typography>
        </Typography>
      ) : (
        <Typography color="textSecondary" variant="body2" component="p">
          Didn’t receive code? Resend code in
          <Typography color="primary" variant="body2" component="span">
            {' '}
            {counter}
          </Typography>
        </Typography>
      )}
    </>
  );
};
export default Counter;