/* eslint-disable  */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Tooltip,
  withStyles,
  Fab,
  FormHelperText,
  Zoom,
} from '@material-ui/core';
import TooltipIcon from '@material-ui/icons/InfoOutlined';
// import { Bold } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  textDanger: {
    color: theme.palette.text.danger1,
    fontWeight: 500,
  },
  inputLabel: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(3),
  },
  tooltipFab: {
    width: 16,
    height: 16,
    minHeight: 16,
    display: 'flex',
    backgroundColor: 'transparent',
    color: theme.palette.text.third,
    position: 'absolute',
    left: '100%',
    top: 0,
    marginLeft: 5,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  fieldWithLabelOrTooltip: {
    paddingTop: theme.spacing(3.3),
    '& label': {
      display: 'flex',
      whiteSpace: 'nowrap',
      //     overflow: ‘hidden’,
      maxWidth: '100%',
    },
    '& label span.textVal': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      // maxWidth: '280px',
      display: 'block',
      lineHeight: 'normal',
    },
  },
  tooltipFabIcon: {
    fontSize: 16,
  },
  textTrans: {
    textTransform: 'initial',
  },
}));

const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    maxWidth: 220,
    fontSize: 10,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

const FieldWrapper = (props) => {
  const {
    formControlClassName = '',
    label = '',
    id,
    error = '',
    required = false,
    descType,
    descText,
    toolTipPlacement = 'right',
  } = props;

  const { fieldWithLabelOrTooltip, tooltipFab, tooltipFabIcon, inputLabel, textDanger, textTrans } =
    useStyles();

  let helperText = '';
  let tooltip = '';
  let errorHelperText = '';

  if (descText !== undefined || descText != null) {
    if (
      typeof descText.toString().split(' ')[0] === 'number' &&
      descText.toString().split(' ')[0] >= 0
    ) {
      descType === 'TOOLTIP' ? (tooltip = descText) : (helperText = descText);
    } else if (
      typeof descText.toString().split(' ')[0] === 'number' &&
      descText.toString().split(' ')[0] <= 0
    ) {
      descType === 'TOOLTIP' ? (tooltip = descText) : (errorHelperText = descText);
    } else {
      descType === 'TOOLTIP' ? (tooltip = descText) : (helperText = descText);
    }
  }

  const makeFirstLetterCapFromSecondWrd = (val) => {
    return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  };

  const { showErrorMsg = true, subTextComponent: SubTextComponent = null } = props;
  return (
    <FormControl
      className={` ${
        label || tooltip ? `${fieldWithLabelOrTooltip} subfieldCheckbox` : ''
      } ${formControlClassName}`}
      fullWidth>
      {!label ? (
        false
      ) : (
        <>
          <InputLabel
            required={required}
            className={tooltip ? inputLabel : ''}
            shrink
            htmlFor={id}
            title={label}>
            <span className={`textVal ${label.split(' ').length >= 3 ? textTrans : ''}`}>
              {label.split(' ').length <= 3 ? label : makeFirstLetterCapFromSecondWrd(label)}
            </span>
            {!tooltip ? (
              false
            ) : (
              <CustomToolTip
                TransitionComponent={Zoom}
                title={tooltip}
                interactive
                placement={toolTipPlacement}
                arrow>
                <Fab size="small" className={tooltipFab}>
                  <TooltipIcon className={tooltipFabIcon} />
                </Fab>
              </CustomToolTip>
            )}
          </InputLabel>
          {SubTextComponent && <SubTextComponent />}
        </>
      )}
      {props.children}

      {helperText ? <FormHelperText className="">{helperText}</FormHelperText> : false}
      {errorHelperText ? (
        <FormHelperText id={`${id}-help-text`} className={`${textDanger}`}>
          {errorHelperText}
        </FormHelperText>
      ) : (
        false
      )}
      {props.charHelperText ? (
        <FormHelperText className="">{props.charHelperText}</FormHelperText>
      ) : (
        false
      )}
      {error && showErrorMsg ? (
        <FormHelperText id={`${id}-help-text`} className={`${textDanger}`}>
          {error}
        </FormHelperText>
      ) : (
        false
      )}
    </FormControl>
  );
};

export default FieldWrapper;
