import React from 'react';

export default function SvgSessionOverviewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
      <rect width="72" height="72" rx="36" fill="#F6F8F8" />
      <path
        d="M24 42V30"
        stroke="#ED7767"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 52V20"
        stroke="#ED7767"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 39.5V32.5"
        stroke="#ED7767"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
