import {
  SETUP_ZOOM_APP,
  SET_ZOOM_APP_IDS,
  ZOOM_TOKEN_EXPIRE,
  SET_ZOOM_ERROR,
  SET_ZOOM_SCOPE_ERROR,
} from '../actions/zoom-action';

const initialState = {
  isFromZoomApp: false,
  tokenExpire: 0,
  appId: null,
  isZoomError: false,
  zoomScopeError: false,
};
const zoomData = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ZOOM_APP:
      return { ...state, isFromZoomApp: action.data };
    case ZOOM_TOKEN_EXPIRE:
      return { ...state, tokenExpire: action.data };
    case SET_ZOOM_APP_IDS:
      return { ...state, ...action.data };
    case SET_ZOOM_ERROR:
      return { ...state, isZoomError: action.data };
    case SET_ZOOM_SCOPE_ERROR:
      return { ...state, zoomScopeError: action.data };
    default:
      break;
  }
  return state;
};

export default zoomData;
