/* eslint-disable react/self-closing-comp */
import React from 'react';

export default function SvgAgendaIcon(props) {
  return (
    <svg fill="currentColor" viewBox="3 2.7 27.9 24.9" {...props}>
      <g transform="translate(5 4)">
        <path d="M20.5,6.5H1a.5.5,0,0,1,0-1H20.5a.5.5,0,0,1,0,1Z"></path>
        <path d="M14.8,14.4"></path>
        <path d="M17.5,12.4"></path>
        <path d="M6.5,11.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M6.5,14.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M6.5,17.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M11.5,11.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M11.5,14.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M11.5,17.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M16.5,11.5h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z"></path>
        <path d="M14.5,14.5h-.2c-.1,0-.1-.1-.2-.1-.1-.1-.1-.1-.1-.2v-.4c0-.1.1-.1.1-.2s.1-.1.2-.1a.44.44,0,0,1,.5.1c.1,0,.1.1.1.2V14a.758.758,0,0,1-.2.4C14.8,14.4,14.6,14.5,14.5,14.5Z"></path>
        <path d="M15.3,22.5H3.5A3.543,3.543,0,0,1,0,19V5A3.543,3.543,0,0,1,3.5,1.5.472.472,0,0,1,4,2a.472.472,0,0,1-.5.5A2.476,2.476,0,0,0,1,5V19a2.476,2.476,0,0,0,2.5,2.5H15.3a.5.5,0,1,1,0,1Z"></path>
        <path d="M20.5,11.5A.472.472,0,0,1,20,11V5a2.476,2.476,0,0,0-2.5-2.5A.472.472,0,0,1,17,2a.472.472,0,0,1,.5-.5A3.543,3.543,0,0,1,21,5v6A.472.472,0,0,1,20.5,11.5Z"></path>
        <path d="M10.5,2.5h-.2c-.1,0-.1-.1-.2-.1-.1-.1-.1-.1-.1-.2V2c0-.1,0-.3.1-.3,0-.1.1-.1.2-.1a.44.44,0,0,1,.5.1c.1,0,.2.2.2.3v.2c0,.1-.1.1-.1.2a.349.349,0,0,1-.2.1Z"></path>
        <path d="M7,4A2,2,0,1,1,9,2,2.006,2.006,0,0,1,7,4ZM7,1A.945.945,0,0,0,6,2,.945.945,0,0,0,7,3,.945.945,0,0,0,8,2,.945.945,0,0,0,7,1Z"></path>
        <path d="M14,4a2,2,0,1,1,2-2A2.006,2.006,0,0,1,14,4Zm0-3a.945.945,0,0,0-1,1,1,1,0,0,0,2,0A.945.945,0,0,0,14,1Z"></path>
        <path d="M20,22.5a5,5,0,1,1,5-5A4.951,4.951,0,0,1,20,22.5Zm0-9a4,4,0,1,0,4,4A4.012,4.012,0,0,0,20,13.5Z"></path>
        <path d="M19.5,19.5c-.1,0-.3-.1-.4-.1l-1.7-1.7a.495.495,0,1,1,.7-.7l1.3,1.3L21.7,16a.495.495,0,1,1,.7.7l-2.7,2.7C19.8,19.4,19.6,19.5,19.5,19.5Z"></path>
      </g>
    </svg>
  );
}
