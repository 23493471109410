import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import CustomModal from 'custom-components/customModal';
import BtnGroup from 'custom-components/form-fields/btnGroup';
import SvgWindowDotsIcon from 'icons/svgWindowDotsIcon';
import SvgIphoneMock from 'icons/svgiPhoneMock';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  templatePreviewModalDetailsSelector,
  commonSelector,
  eventDataSelector,
} from 'redux/custom-selector';
import { useStyles } from './style';
import {
  GET_TEMPLATE_PREVIEW_BY_ID,
  GET_TEMPLATE_DETAILS_BY_ID,
  BASIC_INFO_SESSION,
  GET_BRAND_EVENT_SETTING
} from 'api-setup/api-endpoints';
import { API, GETAPIWITHCUSTOMDOMAIN, GETAPI } from 'api-setup/api-helper';
import {
  templatePreviewToggle,
  setPreviewType,
  setTemplateCampaignId,
} from 'redux/actions/template-preview-action';
import { useSource } from 'lib/custom-common-hooks';
import { CUSTOM_URL } from '../emailBuilder/create-email/constants';
import { useHistory } from 'react-router-dom';
import { setNewMessage } from 'redux/actions/setting-actions';
import moment from 'moment-timezone';
import { useCommunityVersionNumber } from 'lib/hooks/use-community-version';
import {
  defaultThemeId
} from './create-email/constants';
import {
  templateModalToggle,
  setActiveTemplate,
  setActiveTemplateName,
  setTemplateActiontype,
  setTemplateId,
  setCampaignId,
  setTemplateIsPrimary,
} from 'redux/actions/template-details-action';

const createDateTimeheading = (startTimeMilli, endTimeMilli, timeZone) => {
  let str = '';
  try {
    const timezone = timeZone?.name?.replace(/\([^()]*\)/g, '').trim();
    const st = moment(+startTimeMilli).tz(timezone);
    const et = moment(+endTimeMilli).tz(timezone);
    if (st.isSame(et, 'year') && st.isSame(et, 'month') && st.isSame(et, 'day')) {
      str = `${st.format('MMMM Do YYYY, h:mm a')} - ${et.format('h:mm a')} | ${
        timeZone?.description || timeZone?.name
      }`;
    } else if (st.isSame(et, 'year') && st.isSame(et, 'month')) {
      str = `${st.format('h:mm a')} - ${et.format('h:mm a , MMMM Do YYYY')} | ${
        timeZone?.description || timeZone?.name
      }`;
    } else if (st.isSame(et, 'year')) {
      str = `${st.format('MMMM Do, h:mm a')} - ${et.format('h:mm a,MMMM Do YYYY')} | ${
        timeZone?.description || timeZone?.name
      }`;
    } else {
      str = `${st.format('MMMM Do YYYY, h:mm a')} - ${et.format('h:mm a, MMMM Do YYYY')} | ${
        timeZone?.description || timeZone?.name
      }`;
    }
    return str;
  } catch (e) {
    return str;
  }
};

const makeLinksUnClickable = (html) => {
  if (html) {
    return html.replace(/href/g, 'mluc');
  }
  return html;
};

const templateBodyThemeUpdate = (templateStr, themeData) => {
  if (templateStr && themeData) {
   Object.keys(themeData).forEach(key => {
    switch (key) {
      case 'primaryColor':{
        if(themeData?.primaryColor)
          templateStr = templateStr?.replace(new RegExp(defaultThemeId?.primaryColor,'gi'), themeData?.primaryColor);
        break;
      }
      case 'primaryFontColor':{
        if(themeData?.primaryFontColor)
          templateStr = templateStr?.replace(new RegExp(defaultThemeId?.primaryFontColor,'gi'), themeData?.primaryFontColor);
        break;
      }
      case 'secondaryFontColor':{
        if(themeData?.secondaryFontColor)
          templateStr = templateStr?.replace(new RegExp(defaultThemeId?.secondaryFontColor,'gi'), themeData?.secondaryFontColor);
        break;
      }
      case 'accentColor':{
        if(themeData?.accentColor)
            templateStr = templateStr?.replace(new RegExp(defaultThemeId?.accentColor,'gi'), themeData?.accentColor);
          break;
      }
      case 'mainBackgroundColor':{
        if(themeData?.mainBackgroundColor)
            templateStr = templateStr?.replace(new RegExp(defaultThemeId?.mainBackgroundColor,'gi'), themeData?.mainBackgroundColor);
          break;  
      }
    }
   })
   return templateStr;
  } else {
    return templateStr;
  }
};

export default function TemplatePreview() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const source = useSource();
  const history = useHistory();
  const previewModalInfo = useSelector(templatePreviewModalDetailsSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const [isLoading, setIsLoading] = useState(true);
  const [previewHtml, setPreviewHtml] = useState('');
  const eventCommunityVersion = useCommunityVersionNumber();
  const [theme, setTheme] = useState();
  const [isThemeLoading, setThemeLoading] = useState(true);
  const [previewHtmlWithTheme, setPreviewHtmlWithTheme] = useState('');


  const APICALL = React.useCallback(
    (ACTION, _reduxData, methodType, URL, headerData, bodyData, APICB) => {
      const [header, body] = API.generateHeader(_reduxData, headerData, bodyData);
      let APIPromise;
      if (methodType === 'GET') {
        APIPromise = GETAPI(false, URL, source.AS, header);
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(setNewMessage({ message: resp.data.message, type: 'success', show: true }));
          if (APICB) {
            APICB(ACTION, 'SUCCESS', resp.data.data);
          }
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            if (APICB) {
              APICB(ACTION, 'ERROR', resp.data);
            }
          });
        }
      }).catch(API.catchHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const getThemeData = useCallback(() => {
    const [header] = API.generateHeader(
      reduxData,
      {
        community_version: eventCommunityVersion,
      },
      null
    );
    GETAPI(false, GET_BRAND_EVENT_SETTING, source.AS, header)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          // Theme assigning
          if (resp?.data?.data?.chosenTheme) setTheme({...resp?.data?.data?.chosenTheme, main_background_color: resp?.data?.data?.chosenTheme?.main_background_color?.color1 ? resp?.data?.data?.chosenTheme?.main_background_color : {color1: "#EDEDED"}});
          setThemeLoading(false)
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    getThemeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplatePreviewHtml = useCallback((campaignID, listPreview) => {
    const [headers] = API.generateHeader(reduxData, null, null);
    const uri = listPreview
      ? GET_TEMPLATE_DETAILS_BY_ID.replace('{ID}', campaignID)
      : GET_TEMPLATE_PREVIEW_BY_ID.replace('{ID}', campaignID);

    GETAPIWITHCUSTOMDOMAIN(false, uri, source.AS, { ...headers, campaignID }, null, CUSTOM_URL)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp?.data?.data) {
            const preHtml = listPreview ? resp?.data?.data?.templateHtml : resp?.data?.data;
            const position = preHtml.search(/sessionDateAndTime/i);
            const position2 = preHtml.search(/sessionUid="sct01"/i);

            if (position !== -1 && position2 !== -1) {
              try {
                const sessionIds = [];
                const sessionIdAndDate = {};
                const tempPreHtml = preHtml;
                const arr = tempPreHtml.split('-sessionDateAndTime');
                // eslint-disable-next-line array-callback-return
                arr.map((i, ind) => {
                  if (ind < arr.length - 1) {
                    let sIdLength = 4;

                    while(!isNaN(i.slice(i.length - sIdLength))){
                      sIdLength += 1;
                    }
                    const sessionId = parseInt(i.slice(i.length - (sIdLength-1)), 10);
                    sessionIds.push(sessionId);
                  }
                });

                // eslint-disable-next-line array-callback-return
                sessionIds.map((sId, ind) => {
                  APICALL(
                    'GET-BASIC-INFO',
                    reduxData,
                    'GET',
                    BASIC_INFO_SESSION,
                    { agendaId: sId },
                    null,
                    (ACTION, type, data) => {
                      if (
                        data?.details?.start_time_milli &&
                        data?.details?.end_time_milli &&
                        eventData?.EventData?.timezone
                      ) {
                        const formatedDate = createDateTimeheading(
                          data?.details?.start_time_milli,
                          data?.details?.end_time_milli,
                          eventData?.EventData?.timezone
                        );

                        sessionIdAndDate[`${data?.details?.id}-sessionDateAndTime`] = formatedDate;
                        if (Object.keys(sessionIdAndDate)?.length === sessionIds?.length || ind === (sessionIds?.length-1)) {
                          const doc = new DOMParser().parseFromString(preHtml, 'text/html');
                          // eslint-disable-next-line array-callback-return
                          sessionIds.map((snId) => {
                            const ele = doc.getElementById(`${snId}-sessionDateAndTime`);
                            ele.innerHTML = sessionIdAndDate[`${snId}-sessionDateAndTime`];
                          });
                          setPreviewHtml(makeLinksUnClickable(doc?.documentElement?.innerHTML));
                          setIsLoading(false);
                        }
                      } else {
                        setPreviewHtml(
                          makeLinksUnClickable(
                            listPreview ? resp?.data?.data?.templateHtml : resp?.data?.data
                          )
                        );
                        setIsLoading(false);
                      }
                    }
                  );
                });
              } catch (e) {
                setPreviewHtml(
                  makeLinksUnClickable(
                    listPreview ? resp?.data?.data?.templateHtml : resp?.data?.data
                  )
                );
                setIsLoading(false);
              }
            } else {
              setPreviewHtml(
                makeLinksUnClickable(
                  listPreview ? resp?.data?.data?.templateHtml : resp?.data?.data
                )
              );
              setIsLoading(false);
            }
          }
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeObj = {
    primaryColor: theme?.primary_color?.color1 ,
    primaryFontColor: theme?.font_color?.color1 ,
    secondaryFontColor: theme?.secondary_font_color?.color1,
    accentColor: theme?.accent_color?.color1 ,
    mainBackgroundColor: theme?.main_background_color?.color1 
  }
  const onClose = () => {
     dispatch(templatePreviewToggle({ isModalOpen: false, isEdit: false }));
     dispatch(setPreviewType({ previewType: 'Desktop' }));
     dispatch(setTemplateCampaignId({ campaignId: '', templateHtml: '' }));
     setPreviewHtml('');
     setIsLoading(true);
     setThemeLoading(true);
     setPreviewHtmlWithTheme('');
  };

  const onEditClick = () => {
    if (previewModalInfo?.template?.default) {
      dispatch(
        templateModalToggle({
          isModalOpen: true,
          isEnableCTA: true,
          isDefaultAction: true,
          isActive: true,
          applyTheme: previewModalInfo?.template?.applyTheme,
          emailSubject: previewModalInfo?.campaign?.campaignSubject,
          applyEventTheme: previewModalInfo?.template?.applyEventTheme,
        })
      );
      dispatch(
        setActiveTemplateName({
          activeTemplateName: previewModalInfo?.template?.templateName,
        })
      );
      dispatch(setTemplateActiontype({ actionType: 'DUPLICATE' }));
      dispatch(
        setActiveTemplate({
          activeTemplate: previewModalInfo?.campaign?.unlayerId,
          mergeTag: previewModalInfo?.template?.mergeTag,
        })
      );
      dispatch(setTemplateId({ templateId: null })); // id
      dispatch(setCampaignId({ campaignId: previewModalInfo?.campaign?._id }));
      dispatch(setTemplateIsPrimary({ isPrimary: true }));
    } else {
      dispatch(
        templateModalToggle({
          isModalOpen: true,
          applyTheme: previewModalInfo?.template?.applyTheme,
          emailSubject: previewModalInfo?.campaign?.campaignSubject,
          applyEventTheme: previewModalInfo?.template?.applyEventTheme,
        })
      );
      dispatch(
        setActiveTemplateName({
          activeTemplateName: previewModalInfo?.template?.templateName,
        })
      );
      dispatch(setTemplateActiontype({ actionType: 'EDIT' }));
      dispatch(
        setActiveTemplate({
          activeTemplate: previewModalInfo?.template?.templateJson,
          mergeTag: previewModalInfo?.template?.mergeTag,
        })
      );
      dispatch(setTemplateId({ templateId: previewModalInfo?.template?._id }));
      dispatch(setCampaignId({ campaignId: previewModalInfo?.template?.campaignId }));
    }
    onClose();
  };

  useEffect(() => {
    if (previewModalInfo?.templateHtml) {
      setPreviewHtml(makeLinksUnClickable(previewModalInfo?.templateHtml));
      setIsLoading(false);
    } else {
      if (previewModalInfo?.campaignId)
        getTemplatePreviewHtml(previewModalInfo?.campaignId, previewModalInfo?.listPreview);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    getTemplatePreviewHtml,
    previewModalInfo.campaignId,
    previewModalInfo.listPreview,
    previewModalInfo.templateHtml,
  ]);

  useEffect(() => {
    if (previewHtml && theme) {
      setPreviewHtmlWithTheme(templateBodyThemeUpdate(previewHtml, themeObj))
    }
  }, [previewHtml, theme]);

  return (
    <>
      <CustomModal
        isOpen={previewModalInfo.isModalOpen}
        size="large"
        paddingZero
        onClose={onClose}
        // title="Email Template Preview"
        title="Email preview"
        headerActions={
          <>
            {previewModalInfo?.isEdit && (
              <Button name="PreviewType" variant="contained" color="primary" onClick={onEditClick}>
                Edit Email
              </Button>
            )}
          </>
        }
        // headerActions={
        //   <BtnGroup
        //     name="PreviewType"
        //     options={[
        //       {
        //         label: 'Desktop',
        //         isChecked: previewModalInfo.previewType === 'Desktop',
        //         type: 'desk',
        //       },
        //       {
        //         label: 'Mobile',
        //         isChecked: previewModalInfo.previewType === 'Mobile',
        //         type: 'mob',
        //       },
        //     ]}
        //     onChange={(checkbox) => dispatch(setPreviewType({ previewType: checkbox.label }))}
        //   />
        // }
      >
        {isLoading || isThemeLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="420px"
            minWidth="800px">
            <CircularProgress color="secondary" component="span" size={32} />
          </Box>
        ) : previewModalInfo.previewType === 'Desktop' ? (
          <Box className={classes.emailPreviewDeskModal} width="800px">
            <Box className="modalHeader">{/* <SvgWindowDotsIcon width="60px" /> */}</Box>
            <Box className="deskBody" dangerouslySetInnerHTML={{ __html: previewHtmlWithTheme }} />
          </Box>
        ) : (
          <Box className={classes.emailPreviewMobileModal} width="800px">
            <Box className="iphoneMock">
              <Box className="phoneFrame">
                <SvgIphoneMock />
              </Box>
              <Box className="phoneBody">
                <Box
                  component="span"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  dangerouslySetInnerHTML={{ __html: previewHtmlWithTheme }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </CustomModal>
    </>
  );
}