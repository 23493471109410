/* eslint-disable no-param-reassign */
import { SET_STRIPE_DETAILS } from 'redux/actions/stripe-details-action';

const stripeDetailsInitState = {
  isStripeConnected: false,
  stripeUrl: '',
  stripeEmail: '',
  stripeUserName: '',
};

const stripeDetails = (state = stripeDetailsInitState, action) => {
  switch (action.type) {
    case SET_STRIPE_DETAILS:
      state = { ...state, ...action.data };
      break;
    default:
      break;
  }
  return state;
};

export default stripeDetails;
