export const EMAIL_ACTIVATE = 'EMAIL_ACTIVATE';
export const EMAIL_DEACTIVATE = 'EMAIL_DEACTIVATE';

export const activateEmailAction = (value) => {
    return {
        type: EMAIL_ACTIVATE,
        data: value,
    };
};

export const deactivateEmailAction = (value) => {
    return {
        type: EMAIL_DEACTIVATE,
        data: value,
    };
};
