import React from 'react';

export default function SvgIBMWatsonIcon(props) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_28_47)">
        <mask
          id="mask0_28_47"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="14"
          height="12">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.98258 4.78409C1.93917 4.78409 1.89524 4.77312 1.85495 4.7499L0.62793 4.04308C0.505579 3.97254 0.463705 3.81642 0.534284 3.69433C0.604982 3.57241 0.761321 3.53066 0.883751 3.60089L2.11073 4.30779C2.23308 4.37829 2.27499 4.53441 2.20437 4.65646C2.15698 4.7383 2.07098 4.78409 1.98258 4.78409ZM4.08122 2.69055C3.99282 2.69055 3.90682 2.64483 3.85939 2.56291L3.15091 1.33856C3.08033 1.21651 3.12224 1.06035 3.24456 0.989848C3.36738 0.919461 3.52344 0.961363 3.59406 1.08329L4.3025 2.30765C4.37308 2.42973 4.3312 2.58585 4.20885 2.65636C4.16855 2.67953 4.12463 2.69055 4.08122 2.69055ZM6.94696 1.92429C6.80569 1.92429 6.69114 1.81 6.69114 1.66903V0.255266C6.69114 0.114295 6.80569 0 6.94696 0C7.08816 0 7.20278 0.114295 7.20278 0.255266V1.66903C7.20278 1.81 7.08816 1.92429 6.94696 1.92429ZM9.81279 2.69055C9.76937 2.69055 9.72545 2.67953 9.68515 2.65636C9.56272 2.58585 9.52085 2.42981 9.59151 2.30765L10.2998 1.08329C10.3705 0.961284 10.527 0.919618 10.6493 0.989848C10.7717 1.06035 10.8136 1.21643 10.7429 1.33856L10.0346 2.56291C9.98718 2.64475 9.90119 2.69055 9.81279 2.69055ZM11.9114 4.78409C11.823 4.78409 11.737 4.73837 11.6896 4.65646C11.6189 4.53441 11.6609 4.37829 11.7832 4.30779L13.0102 3.60089C13.1328 3.53066 13.289 3.57229 13.3596 3.69433C13.4303 3.81642 13.3884 3.97254 13.266 4.04308L12.0391 4.7499C11.9988 4.77312 11.9548 4.78409 11.9114 4.78409Z"
            fill="white"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.65402 9.71577C7.63118 10.8093 9.82045 10.6554 10.5333 9.37212C11.2461 8.08894 10.2172 6.15528 8.23971 5.06155C6.26215 3.96785 4.07343 4.12191 3.36055 5.40509C2.64762 6.68819 3.67654 8.62196 5.65402 9.71577ZM8.35236 10.946C7.4272 10.9621 6.38963 10.7063 5.40599 10.1623C3.18171 8.93202 2.06344 6.68697 2.91309 5.15759C3.76262 3.62841 6.26345 3.3849 8.48773 4.615C10.7119 5.84514 11.8303 8.09027 10.9807 9.61957C10.5068 10.4726 9.51907 10.9256 8.35236 10.946Z"
            fill="url(#paint0_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.65425 9.71573C7.6314 10.8093 9.82066 10.6554 10.5335 9.37207C11.2463 8.08889 10.2174 6.15523 8.23993 5.06149C6.26238 3.96779 4.07367 4.12185 3.36079 5.40503C2.64787 6.68814 3.67678 8.62192 5.65425 9.71573ZM8.34679 10.9389C7.42257 10.955 6.38604 10.6994 5.40339 10.1559C3.18135 8.92691 2.06421 6.68411 2.913 5.15626C3.76168 3.6286 6.25999 3.38533 8.48203 4.61421C10.704 5.84312 11.8213 8.086 10.9725 9.61377C10.499 10.4659 9.51233 10.9185 8.34679 10.9389Z"
            fill="url(#paint1_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.94919 3.29482C6.47217 3.29482 5.99397 3.37894 5.53295 3.54796C4.50478 3.92481 3.68531 4.67866 3.2254 5.67058C2.76545 6.66239 2.72015 7.77368 3.09781 8.79952C3.47547 9.82556 4.23098 10.6433 5.22501 11.1022C6.21911 11.5612 7.3328 11.6065 8.3609 11.2294C9.38899 10.8525 10.2084 10.0988 10.6683 9.10696V9.10684C11.1284 8.115 11.1737 7.00374 10.796 5.97775C10.4183 4.95191 9.66279 4.13416 8.6688 3.67524C8.12053 3.42214 7.53579 3.29482 6.94919 3.29482ZM6.9443 11.9933C6.28448 11.9933 5.62687 11.8502 5.01015 11.5655C3.89212 11.0493 3.04241 10.1295 2.61763 8.97559C2.19278 7.82168 2.24376 6.57189 2.76104 5.45619C3.82892 3.1531 6.57555 2.14634 8.88354 3.21192C10.0016 3.72805 10.8513 4.64785 11.2761 5.80176C11.701 6.95574 11.6499 8.20561 11.1327 9.32135C10.6154 10.4369 9.69366 11.2847 8.53727 11.7087C8.01868 11.8988 7.4807 11.9933 6.9443 11.9933Z"
            fill="url(#paint2_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.94919 3.29482C6.47217 3.29482 5.99397 3.37894 5.53295 3.54796C4.50478 3.92481 3.68531 4.67866 3.2254 5.67058C2.76545 6.66239 2.72015 7.77368 3.09781 8.79952C3.47547 9.82556 4.23098 10.6433 5.22501 11.1022C6.21911 11.5612 7.3328 11.6065 8.3609 11.2294C9.38899 10.8525 10.2084 10.0988 10.6683 9.10696V9.10684C11.1284 8.115 11.1737 7.00374 10.796 5.97775C10.4183 4.95191 9.66279 4.13416 8.6688 3.67524C8.12053 3.42214 7.53579 3.29482 6.94919 3.29482ZM6.9443 11.9933C6.28448 11.9933 5.62687 11.8502 5.01015 11.5655C3.89212 11.0493 3.04241 10.1295 2.61763 8.97559C2.19278 7.82168 2.24376 6.57189 2.76104 5.45619C3.82892 3.1531 6.57555 2.14634 8.88354 3.21192C10.0016 3.72805 10.8513 4.64785 11.2761 5.80176C11.701 6.95574 11.6499 8.20561 11.1327 9.32135C10.6154 10.4369 9.69366 11.2847 8.53727 11.7087C8.01868 11.8988 7.4807 11.9933 6.9443 11.9933Z"
            fill="url(#paint3_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.89633 4.66425C8.0215 4.66425 6.93067 4.96964 5.89319 5.56736C3.96826 6.67628 2.82456 8.44717 3.39574 9.43444C3.66241 9.89521 4.27373 10.1375 5.10802 10.1146C6.00194 10.0908 7.0292 9.76958 8.00068 9.20987C9.9256 8.10098 11.0692 6.33013 10.4981 5.34282C10.2348 4.88772 9.64455 4.66425 8.89633 4.66425ZM5.00428 10.6264C4.02938 10.6264 3.30344 10.296 2.95267 9.68967C2.21833 8.42046 3.39756 6.41551 5.63737 5.12521C7.87695 3.83498 10.2065 3.81834 10.9412 5.08755C11.6755 6.3568 10.4962 8.36176 8.2565 9.65202C7.21255 10.2534 6.09925 10.5989 5.12159 10.6248C5.08216 10.6259 5.04296 10.6264 5.00428 10.6264Z"
            fill="url(#paint4_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.89633 4.66425C8.0215 4.66425 6.93067 4.96964 5.89319 5.56736C3.96826 6.67628 2.82456 8.44717 3.39574 9.43444C3.66241 9.89521 4.27373 10.1375 5.10802 10.1146C6.00194 10.0908 7.0292 9.76958 8.00068 9.20987C9.9256 8.10098 11.0692 6.33013 10.4981 5.34282C10.2348 4.88772 9.64455 4.66425 8.89633 4.66425ZM5.00428 10.6264C4.02938 10.6264 3.30344 10.296 2.95267 9.68967C2.21833 8.42046 3.39756 6.41551 5.63737 5.12521C7.87695 3.83498 10.2065 3.81834 10.9412 5.08755C11.6755 6.3568 10.4962 8.36176 8.2565 9.65202C7.21255 10.2534 6.09925 10.5989 5.12159 10.6248C5.08216 10.6259 5.04296 10.6264 5.00428 10.6264Z"
            fill="url(#paint5_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.54217 3.73194C7.63469 3.73194 6.29564 4.84676 5.35291 6.47605C4.24154 8.39691 4.02609 10.4366 4.88255 10.9299C5.73877 11.4237 7.40162 10.2175 8.51287 8.29655C9.07609 7.32321 9.42379 6.30809 9.49208 5.43818C9.55426 4.64618 9.36886 4.06464 8.98339 3.84262C8.85335 3.76767 8.70458 3.73194 8.54217 3.73194ZM5.30745 11.5462C5.06089 11.5462 4.83145 11.49 4.62673 11.3721C3.49237 10.7185 3.61669 8.45577 4.90984 6.22078C6.20302 3.98587 8.1043 2.74688 9.23921 3.40047C9.81048 3.72958 10.0815 4.4674 10.0022 5.478C9.92806 6.4223 9.55659 7.51399 8.95599 8.55177C7.89619 10.3835 6.42738 11.5462 5.30745 11.5462Z"
            fill="url(#paint6_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.54217 3.73194C7.63469 3.73194 6.29564 4.84676 5.35291 6.47605C4.24154 8.39691 4.02609 10.4366 4.88255 10.9299C5.73877 11.4237 7.40162 10.2175 8.51287 8.29655C9.07609 7.32321 9.42379 6.30809 9.49208 5.43818C9.55426 4.64618 9.36886 4.06464 8.98339 3.84262C8.85335 3.76767 8.70458 3.73194 8.54217 3.73194ZM5.30745 11.5462C5.06089 11.5462 4.83145 11.49 4.62673 11.3721C3.49237 10.7185 3.61669 8.45577 4.90984 6.22078C6.20302 3.98587 8.1043 2.74688 9.23921 3.40047C9.81048 3.72958 10.0815 4.4674 10.0022 5.478C9.92806 6.4223 9.55659 7.51399 8.95599 8.55177C7.89619 10.3835 6.42738 11.5462 5.30745 11.5462Z"
            fill="url(#paint7_linear_28_47)"></path>
        </mask>
        <g mask="url(#mask0_28_47)">
          <rect y="-1" width="14" height="14" fill="#C4C4C4"></rect>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.9443 11.9933C6.28449 11.9933 5.62688 11.8503 5.01015 11.5655C3.89212 11.0494 3.04241 10.1295 2.61763 8.97561C2.19278 7.8217 2.24376 6.5719 2.76104 5.4562C3.82892 3.1531 6.57556 2.14634 8.88354 3.21192C10.0017 3.72805 10.8513 4.64786 11.2761 5.80176C11.701 6.95575 11.6499 8.20563 11.1327 9.32137C10.6154 10.4369 9.69367 11.2848 8.53727 11.7087C8.01869 11.8988 7.48071 11.9933 6.9443 11.9933Z"
            fill="url(#paint8_linear_28_47)"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.98258 4.7841C1.93917 4.7841 1.89524 4.77312 1.85495 4.74991L0.62793 4.04309C0.505579 3.97254 0.463705 3.81642 0.534284 3.69434C0.604982 3.57241 0.761321 3.53067 0.883751 3.6009L2.11073 4.3078C2.23308 4.3783 2.27499 4.53442 2.20437 4.65647C2.15698 4.7383 2.07098 4.7841 1.98258 4.7841ZM4.08122 2.69055C3.99282 2.69055 3.90682 2.64483 3.85939 2.56292L3.15091 1.33856C3.08033 1.21651 3.12224 1.06035 3.24456 0.98985C3.36738 0.919462 3.52344 0.961364 3.59406 1.08329L4.3025 2.30765C4.37308 2.42974 4.3312 2.58585 4.20885 2.65636C4.16855 2.67953 4.12463 2.69055 4.08122 2.69055ZM6.94696 1.92429C6.80569 1.92429 6.69114 1.81 6.69114 1.66903V0.255266C6.69114 0.114295 6.80569 0 6.94696 0C7.08816 0 7.20278 0.114295 7.20278 0.255266V1.66903C7.20278 1.81 7.08816 1.92429 6.94696 1.92429ZM9.81279 2.69055C9.76937 2.69055 9.72545 2.67953 9.68515 2.65636C9.56272 2.58585 9.52085 2.42981 9.59151 2.30765L10.2998 1.08329C10.3705 0.961285 10.527 0.91962 10.6493 0.98985C10.7717 1.06035 10.8136 1.21643 10.7429 1.33856L10.0346 2.56292C9.98718 2.64475 9.90119 2.69055 9.81279 2.69055ZM11.9114 4.7841C11.823 4.7841 11.737 4.73838 11.6896 4.65647C11.6189 4.53442 11.6609 4.3783 11.7832 4.3078L13.0102 3.6009C13.1328 3.53067 13.289 3.57229 13.3596 3.69434C13.4303 3.81642 13.3884 3.97254 13.266 4.04309L12.0391 4.74991C11.9988 4.77312 11.9548 4.7841 11.9114 4.7841Z"
            fill="url(#paint9_linear_28_47)"></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_28_47"
          x1="9.1941"
          y1="5.04058"
          x2="7.42753"
          y2="10.5085"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"></stop>
          <stop offset="0.4515" stopColor="white" stopOpacity="0.01"></stop>
          <stop offset="0.6633" stopColor="white" stopOpacity="0.45"></stop>
          <stop offset="0.7133" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_28_47"
          x1="11.1887"
          y1="4.56646"
          x2="2.87969"
          y2="7.31966"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"></stop>
          <stop offset="0.2641" stopColor="white" stopOpacity="0.01"></stop>
          <stop offset="0.2879" stopColor="white" stopOpacity="0.03"></stop>
          <stop offset="0.7444" stopColor="white" stopOpacity="0.4"></stop>
          <stop offset="0.89" stopColor="white" stopOpacity="0.9"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_28_47"
          x1="3.20259"
          y1="5.67484"
          x2="5.29074"
          y2="10.6197"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.2" stopColor="white" stopOpacity="0.01"></stop>
          <stop offset="0.29" stopColor="white" stopOpacity="0.09"></stop>
          <stop offset="0.85" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_28_47"
          x1="4.23533"
          y1="4.27452"
          x2="9.86325"
          y2="4.05988"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stopColor="white" stopOpacity="0.1"></stop>
          <stop offset="0.77" stopColor="white" stopOpacity="0.8"></stop>
          <stop offset="0.84" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_28_47"
          x1="6.39177"
          y1="9.96093"
          x2="8.64241"
          y2="3.62811"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"></stop>
          <stop offset="0.3325" stopColor="white" stopOpacity="0.09"></stop>
          <stop offset="0.7512" stopColor="white" stopOpacity="0.65"></stop>
          <stop offset="0.8271" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_28_47"
          x1="7.07328"
          y1="10.9815"
          x2="7.51973"
          y2="4.24157"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.03"></stop>
          <stop offset="0.522" stopColor="white" stopOpacity="0.03"></stop>
          <stop offset="0.8231" stopColor="white" stopOpacity="0.24"></stop>
          <stop offset="0.8731" stopColor="white" stopOpacity="0.34"></stop>
          <stop offset="0.9801" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_28_47"
          x1="8.00992"
          y1="9.24242"
          x2="7.31194"
          y2="2.9732"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.03"></stop>
          <stop offset="0.387" stopColor="white" stopOpacity="0.12"></stop>
          <stop offset="0.58" stopColor="white" stopOpacity="0.28"></stop>
          <stop offset="0.69" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_28_47"
          x1="7.66411"
          y1="8.86886"
          x2="4.903"
          y2="7.58291"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0"></stop>
          <stop offset="0.27" stopColor="white" stopOpacity="0.1"></stop>
          <stop offset="0.36" stopColor="white" stopOpacity="0.23"></stop>
          <stop offset="0.54" stopColor="white"></stop>
          <stop offset="1" stopColor="white"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_28_47"
          x1="7.70059"
          y1="7.59778"
          x2="2.60568"
          y2="8.31823"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#44A0F9"></stop>
          <stop offset="1" stopColor="#0AB5CF"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_28_47"
          x1="8"
          y1="2.5"
          x2="1.65652"
          y2="4.91168"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#44A0F9"></stop>
          <stop offset="1" stopColor="#0AB5CF"></stop>
        </linearGradient>
        <clipPath id="clip0_28_47">
          <rect width="12.9" height="12" fill="white" transform="translate(0.5)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
