/* eslint-disable  */
import React from 'react';
import { ButtonGroup, Button, makeStyles, withStyles, Tooltip, Zoom } from '@material-ui/core';
import FieldWrapper from './fieldWrapper';

const useStyles = makeStyles((theme) => ({
  virtualDataGroupBtn: {
    width: '100%',
    '& .MuiButton-sizeLarge': {
      paddingTop: theme.spacing(1.1),
      paddingBottom: theme.spacing(1.1),
    },
    '& .MuiButton-label': {
      textTransform: 'capitalize',
      fontWeight: 400,
    },
    '&.fluid-button': {
      '& .MuiButton-root': {
        flexGrow: 1,
      },
    },
    '& .Mui-disabled': {
      opacity: 0.6,
    },
    '& .MuiButton-root': {
      color: theme.palette.text.grayColor,
      backgroundColor: theme.palette.bg.whiteOff1,
      border: 'none',
      // fontSize: theme.fontSize.size9,
      '&.active': {
        // backgroundColor: 'white',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        // boxShadow: theme.shadows[4],
      },
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
      '&:not(:first-child)': {
        borderLeftColor: 'transparent',
      },
      '&:not(:last-child)': {
        borderRightColor: 'transparent',
      },
    },
  },
  btn: {
    position: 'relative',
    whiteSpace: 'nowrap',
    '& input': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      margin: 0,
    },
  },
}));
const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    maxWidth: 220,
    fontSize: 10,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);

export default function BtnGroup(props) {
  const classes = useStyles();
  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <ButtonGroup
        variant={props.variant}
        size={props.size}
        disabled={props.disabled}
        aria-label={`${props.variant} ${props.size} button group`}
        className={`${classes.virtualDataGroupBtn} ${props.isFluidBtn ? 'fluid-button' : ''} `}>
        {props.options.map((checkbox, i) => {
          if (checkbox.tooltip && checkbox.tooltip !== '') {
            return (
              <CustomToolTip
                key={props.labelKey ? checkbox[props.labelKey] : checkbox.label}
                TransitionComponent={Zoom}
                title={checkbox.tooltip}
                interactive
                placement="top"
                arrow>
                <Button
                  key={props.labelKey ? checkbox[props.labelKey] : checkbox.label}
                  component="label"
                  className={`${classes.btn} ${checkbox.isChecked ? 'active' : ''}`}>
                  <>
                    {props.labelKey ? checkbox[props.labelKey] : checkbox.label}
                    <input
                      checked={checkbox.isChecked}
                      className={`cursor-pointer`}
                      onChange={() => props.onChange(checkbox)}
                      name={props.name}
                      type={props.isMultiSelect ? 'checkbox' : 'radio'}
                    />
                  </>
                </Button>
              </CustomToolTip>
            );
          } else {
            return (
              <Button
                key={props.labelKey ? checkbox[props.labelKey] : checkbox.label}
                component="label"
                className={`${classes.btn} ${checkbox.isChecked ? 'active' : ''}`}>
                {props.labelKey ? checkbox[props.labelKey] : checkbox.label}
                <input
                  checked={checkbox.isChecked}
                  className={`cursor-pointer`}
                  onChange={() => props.onChange(checkbox)}
                  name={props.name}
                  type={props.isMultiSelect ? 'checkbox' : 'radio'}
                />
              </Button>
            );
          }
        })}
      </ButtonGroup>
    </FieldWrapper>
  );
}
