export const isOpenSurveySelector = (state) => state?.SurveyDetail?.isOpen || false;
export const isNpsSelector = (state) => state?.SurveyDetail?.type === "NPS";
export const surveyMetaSelector = (state) => state?.SurveyDetail?.meta || {};

export const surveyConfigSelector = (state) => {
    return {
        isNps: state?.SurveyDetail?.type === "NPS",
        label: state?.SurveyDetail?.meta?.title || "",
        isShowSurvey: state?.SurveyDetail?.canShowFeedBackBanner || false,
        isEligibleSurvey: state?.SurveyDetail?.type === "NPS" ? state?.SurveyDetail?.canFillNPSSurvey : state?.SurveyDetail?.canFillSurvey || false,
        isCompleteSurvey: state?.SurveyDetail?.isCompleted || false,
        isExpireSurvey: state?.SurveyDetail?.isExpired || false,
    };
};

export const surveyStoreSelector = (state) => {
    const { surveyId = "", rating = -1, supportRating = -1, feedBack = "" } = state?.SurveyDetail || {};
    return { surveyId, rating, supportRating, feedBack, };
};
