import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Sidebar from "custom-components/sidebar";
import SvgPlus2Icon from "icons/svgPlusTwoIcon";
import { useStyles } from './styles';
import WebinarPreBuiltFields from "./WebinarPreBuiltFields";

const WebinarAddFormField = ({
    preBuiltFieldList,
    addFormFields,
    getUserFields,
    setAddField,
    setAddFormFields,
}) => {
    const classes = useStyles();

    return (
        <>
            <Sidebar
                isFooterDisable
                open={addFormFields}
                asideWidth={600}
                onClose={() => {
                    setAddFormFields(false);
                }}
                title="Add Form Field">
                <Box className={classes.addCustomField} mb={3}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        className="square active"
                        onClick={() => setAddField()}>
                        <SvgPlus2Icon size={17} /> Add Custom Field
                    </Button>
                </Box>

                <Box className={classes.orDivider} mb={3} textAlign="center">
                    <Typography variant="body2" color="textSecondary" component="span">
                        or
                    </Typography>
                </Box>

                <WebinarPreBuiltFields
                    preBuiltFieldList={preBuiltFieldList}
                    onClose={() => setAddFormFields(false)}
                    getUserFields={getUserFields}
                />
            </Sidebar>
        </>
    )
}

export default WebinarAddFormField;