export const LOGIN = '/auth/login';
export const FORGOT_PASSWORD = '/auth/forgot';
export const REGISTER = '/auth/register';
export const SET_PASSWORD = '/account/set-password';
export const SET_VERIFICATION_CODE = '/account/send-verification-code';

export const UPDATE_USER = '/user/update';
export const RESEND_EMAIL = '/resend/user';

export const USER_INFO1 = '/user/auth';
export const USER_INFO = '/auth/user';
export const VERIFY_USER = '/verify/user';
export const RESET_PASSWORD = '/auth/password';
export const GET_TIMEZONES = '/timezones/list';
export const GET_EVENT_LANG_SETTINGS = '/event-language-settings/list';
export const GET_EVENT_LIST_API = '/events';
export const GET_ALL_EVENTS_API = '/events/list';
export const SOCIAL_VERIFY = '/socials/verify';
export const SOCIAL_REGISTER = '/socials/register';
export const LOGOUT_API = '/auth/logout';
export const EVENT_OVERVIEW_API = '/events/basicinfo';
export const DELETE_EVENT = '/events/delete';
export const EVENT_TYPE_LIST = '/events/type-list';
export const CREDIT_REVERSAL_ON_DEL = '/api/v2/event/check-reversal';

export const GET_SESSION_API = '/events/agendas';
export const GET_EVENT_TRACKS = '/events/tracks';
export const SAVE_EVENT_TRACK = '/events/agendatracks';
export const DELETE_EVENT_TRACK = '/events/agendatracks/delete';
export const SAVE_EVENT_AGENDA = '/events/agendas';

export const BASIC_INFO_SESSION = '/events/agenda/basic';
export const GET_EVENT_TAGS = '/events/general/customtags/list';
export const GET_EVENT_BOOTHS = '/events/general/booth/list';
export const GET_EVENT_SPEAKERS = '/events/general/speakers/list';
export const BASIC_INFO_SESSION_UPLOAD = '/events/uploads';
export const ADD_TAGS = '/events/general/customtags/add';
export const UPDATE_TAGS = '/events/general/customtags/update';
export const DELETE_TAGS = '/events/general/customtags/delete';
export const UPDATE_CATEGORY = '/events/booth/categories/update';
export const DELETE_CATEGORY = '/events/booth/categories/delete';
export const AGENDA_ATTENDEE_PAGINATE = '/agendas/attendees/list';
export const AGENDA_REGISTERED_ATTENDEES = '/session/attendees/registered';
export const AGENDA_ATTENDED_ATTENDEES = '/session/attendees/attended';
export const EVENTS_ATTENDEE_PAGINATE = '/events/attendees';
export const ADD_AGENDA_ATTENDEE = '/agendas/attendees';
export const DOWNLOAD_AGENDA_ATTENDEE = '/download/agendas/attendees';
export const SAMPLE_ATTENDEE_AGENDA = '/sample/attendees/agendas';
export const AGENDA_ATTENDED_ATTENDEES_DATA = '/session/export/attended-attendees';
export const AGENDA_REGISTERED_ATTENDEES_DATA = '/session/export/registered-attendees';
export const UPLOAD_PER_RECORDING_VIDEO = '/session/upload/video';
export const ABORT_PRE_RECORDING_VIDEO = '/session/upload/abort';
export const DELETE_PRE_RECORDING_VIDEO = '/session/upload/delete';
export const GET_PRE_RECORDED_LIVE_LINK = '/session/upload/stream_url';
export const PLAY_PRE_RECORDED_LIVE_LINK = '/session/upload/stream';
export const GET_SESSION_SAMPLE_FILE_DATA = '/session/export/sample';
export const SESSION_BULK_UPLOAD = '/session/bulkupload';
export const SESSION_BULK_UPLOAD_FILE = '/events/exceluploads';

export const POST_STREAM_OPTIONS = '/agendas/stream';
export const DELETE_AGENDAS = '/agendas/delete';
export const DELETE_AGENDAS_DAYS = '/events/agendadays/delete';
export const DELETE_AGENDA_ATTENDEE = '/attendees/agendas/delete';
export const DELETE_AGENDA_ATTENDED_PEOPLE = '/session/attendees/delete';
export const CHANGE_AGENDA_STATUS = '/attendees/agendas/status';

export const GET_AGENDA_TRACKS = '/agendas/tracks';
export const GET_AGENDA_SPEAKERS = '/agendas/speakers';
export const GET_AGENDA_CUSTOM_TAGS = '/agendas/customtags';
export const ADD_SESSION_SCHEDULE = '/events/agendadays';

export const SESSION_ADVANCED_SETTING = '/events/agenda/advanced';
export const SESSION_ADVANCED_FILE_UPLOAD = '/events/exceluploads';
export const GET_SAMPLE_FILE_SESSION = '/sample/agendas';

export const GET_PROFILE_FIELDS = '/people/profilefields';
export const UPDATE_PROFILE_FIELDS_PREF = '/people/select-profile-fields';

export const GET_CATEGORIES_LIST = '/events/booth/categories/list';

export const ADD_SINGLE_VIRTUAL_BOOTH = '/events/booth/add';
export const UPLOAD_IMAGES_VIRTUAL_BOOTH = '/events/uploads';
export const GET_OR_UPDATE_BOOTH_DATA = '/events/booth/basic';
export const GET_OR_PUT_CUSTOM_CTA = '/events/booth/cta';
export const GET_PRODUCT_IMAGES = '/events/booth/product/images';
export const GET_PRODUCT_VIDEOS = '/events/booth/product/videos';
export const GET_VIRTUAL_BOOTH_LIST = '/events/booth/list';
export const GET_SAMPLE_FILE_DATA = '/events/booth/sample';
export const VIRTUAL_BOOTH_BULK_UPLOAD = '/events/exceluploads';
export const ADD_VIRTUAL_BOOTH_BULK_UPLOAD = '/events/booth/bulkupload';
export const GET_ENGAGEMENT_BOOTH = '/events/booth/engagement/list';
export const POST_ENGAGEMENT_BOOTH = '/events/booth/engagement/update';

export const GET_NOTIFICATIONS_CONFIG = '/notifications/config';
export const ADD_NEW_NOTIFICATION = '/events/notifications';
export const EDIT_NOTIFICATION = '/notifications/update';
export const GET_NOTIFICATIONS = '/notifications/list';
export const DELETE_NOTIFICATIONS = '/notifications/delete';
export const GET_NOTIFICATION_DETAIL = '/notifications/details';

export const UPLOAD_IMAGES_BRAND_EVENT = '/events/uploads';
export const GET_BRAND_EVENT_SETTING = '/brandyourevent/settings';
export const UPDATE_BRAND_EVENT_SETTING = '/brandyourevent/settings';

export const GET_LOUNGE_TABLES = '/lounge';
export const LOUNGE_TABLES = '/lounge/tables';
export const DELETE_LOUNGE_TABLES = '/lounge/delete/tables';
export const LOUNGE_CONFIG = '/lounge/config';
export const LOUNGE_SINGLE = '/lounge/single';

export const MEETINGS_LIST = '/meetings/list';
export const MEETINGS_PEOPLE_LIST = '/meetings/people/list';
export const MEETING_DELETE = '/meetings/delete';
export const EDIT_MEETING = '/meetings/edit'; // TODO Keep this comment in flexibility
export const MEETING_ADD = '/meetings/schedule';
export const MEETING_CONFIG = '/meetings/settings';
export const EXPORT_MEETING = '/meetings/export';
export const CHECK_LOCATION = '/meetings/check-location';
// TODO change url
export const ALLOW_BOOK_TABLE = '/meetings/allowBookTable';
export const GET_ALL_LOCATION = '/physical-meet/location/all';
export const UPDATE_SINGLE_LOCATION = '/physical-meet/location/update';
export const DELETE_SINGLE_LOCATION = '/physical-meet/location/delete';
export const ADD_SINGLE_TABLE_LOCATION = '/physical-meet/location';
export const GET_LOCATION_SAMPLE_FILE_DATA = '/meetings/location/sample';
export const LOCATION_BULK_UPLOAD = '/events/exceluploads';
export const ADD_LOCATION_BULK_UPLOAD = '/meetings/location/bulk';

export const ADD_TEAM_MEMBERS = '/events/booth/members/add';
export const DELETE_TEAM_MEMBER = '/events/booth/members/delete';
export const GET_EVENT_ATTENDEE = '/events/booth/attendees';
export const DELETE_VIRTUAL_BOOTH = '/events/booth/delete';
export const EXPORT_VIRTUAL_BOOTH = '/events/booth/export';
export const SORT_VIRTUAL_BOOTH = '/events/booth/sort';
export const GET_TEAM_MEMBERS = '/events/booth/members/list';
export const TEAM_MEMBER_ASSIGN_ROLE = '/events/booth/members/role/assign';

export const GET_PREMIUM_SETTINGS = '/events/booth/premium/settings';
export const UPDATE_PREMIUM_SETTINGS = '/events/booth/premium/settings';

export const LEADERBOARD_SETTINGS = '/leaderboard/settings';
export const GET_LEADERBOARD_PEOPLES = '/events/people/list';
export const SET_LEADERBOARD_ACTIVITY_LEVEL = '/leaderboard/people/activitylevel';
export const LEADERBOARD_AWARD = '/leaderboard/award';

export const UPLOAD_IMAGE = '/events/uploads';
export const UPDATE_PRODUCT_IMAGE = '/events/booth/product/images';

export const PEOPLE_GET_STATIC_FIELDS = '/people/staticfieldvalues';
export const PEOPLE_UPDATE_STATIC_FIELDS = '/people/staticfieldvalues/update';
export const PEOPLE_DELETE_STATIC_FIELDS = '/people/staticfieldvalues/delete';
export const PEOPLE_ADD_STATIC_FIELDS = '/people/staticfieldvalues/add';
export const GET_PAGINATE_VIRTUAL_BOOTH_LIST = '/events/general/booth/list';
export const GET_PAGINATE_SESSION_LIST = '/events/general/sessions/list';
export const ADD_SINGLE_PEOPLE = '/people/single';
export const GET_PEOPLE_LIST = '/people/list';
export const GET_GROUP_DATA = '/people/groups/list';
export const DELETE_PEOPLE = '/people/delete';
export const GET_SINGLE_PEOPLE = '/people/single';
export const UPDATE_SINGLE_PEOPLE = '/people/single/edit';
export const PEOPLE_INVITE_MAILER = '/people/invitemailer';
export const PEOPLE_GROUP_ASSIGN = '/people/groups';
export const GET_PEOPLE_COLUMN = '/people/columns';
export const SET_PEOPLE_COLUMN = '/people/columns';
export const BLOCK_PEOPLE = '/people/blockstate';
export const PEOPLE_EXPORT = '/people/export';
export const ADD_SPEAKER_CATEGORY = '/events/general/speaker/cateogry/add';
export const DELETE_SPEAKER_CATEGORY = '/events/general/speaker/cateogry/delete';
export const GET_SPEAKER_CATEGORY_LIST = '/events/general/speaker/cateogry/list';
export const UPDATE_SPEAKER_CATEGORY = '/events/general/speaker/cateogry/update';
export const GET_PEOPLE_BULK_UPLOAD_SAMPLE_FILE = '/people/sample';
export const PEOPLE_BULK_UPLOAD = '/people/bulkupload';
export const PEOPLE_FILE_BULK_UPLOAD = '/events/exceluploads';
export const PEOPLE_REMOVE_EMAIL_FILTER = '/events/people/remove-filter';

export const EVENT_GENERAL_SETTING = '/settings/general';
export const UPDATE_SENDER_ADDRESS = '/settings/senderAddress';
export const EVENT_LOGIN_SETTING = '/events/settings/login';
export const EVENT_SENDER_SETTING = '/events/settings/sender';
export const EVENT_INVITEMAIL_SETTING = '/events/settings/invitemail';
export const EVENT_LEGAL_SETTING = '/events/settings/legal';
export const EVENT_NOTIFICATION_SETTING = '/events/settings/notification';
export const EVENT_MATCHMAKING_SETTING = '/events/matchmaking/settings';
export const EVENT_MATCHMAKING_DETAILS_SETTING = '/events/matchmaking/details';
export const EVENT_MATCHMAKING_BULK_UPLOAD_SAMPLE_FILE = '/settings/matchmaking/sample';
export const EVENT_MATCHMAKING_BULK_UPLOAD = '/settings/matchmaking/bulkupload';
export const MATCH_MAKING_BULK_FILE_UPLOAD = '/events/exceluploads';
export const EVENT_SEO_SETTING = '/events/settings/seo';
export const EVENT_PRIVACY_SETTING = '/events/settings/privacy';
export const EVENT_PRIVACY_GDPR_SETTING = '/events/privacy/gdpr';
export const EVENT_CONSENT_SETTING = '/events/settings/consent';
export const EVENT_CONSENT_DELETE_SETTING = '/events/consent/delete';
export const EVENT_GROUP_SETTING = '/events/settings/groups';
export const EVENT_GROUP_DELETE_SETTING = '/events/groups/delete';
export const ADD_EVENT_GROUP_SETTING = '/events/groups';
export const GET_EVENT_GROUP_DETAILS = '/events/groups/details';
export const EVENT_CUSTOM_INTEGRATIONS_SETTING = '/integrations/custom';
export const EVENT_INTEGRATIONS_SETTING = '/integrations/settings';
export const EVENT_REVOKE_INTEGRATIONS_SETTING = '/revoke/access';
export const EVENT_EVENTBRITE_TOKEN = '/eventbrite/token';
export const EVENT_EVENTBRITE = '/eventbrite/events';
export const EVENT_ZOOM_USER_DETAILS = '/zoom/users/details';
export const ZOOM_HOST = '/zoom/hosts';
export const EVENT_EVENTBRITE_USER_DETAILS = '/eventbrite/users/details';

export const GET_PROFILE_ACCOUNT = '/account/profile';
export const UPDATE_PROFILE_ACCOUNT = '/account/profile';
export const UPDATE_ACCOUNT_PASSWORD = '/account/password';

export const GET_EVENT_SESSION_TRACKS = '/events/session/tracks';
export const ADD_EVENT_SESSION_TRACKS = '/events/session/tracks/add';
export const UPDATE_EVENT_SESSION_TRACKS = '/events/session/tracks/update';
export const DELETE_EVENT_SESSION_TRACKS = '/events/session/tracks/delete';
export const REARRANGE_EVENT_SESSION_TRACKS = '/events/session/tracks/rearrange';
export const CHECK_SESSION_CONCURRENCY = '/events/agendas/checkconcurrency';
export const GET_SIGNED_URL = '/get-signed-url';

export const GET_TEAM_MEMBER_LIST = '/team/list';
export const GET_TEAM_MEMBER_LIST1 = '/team-member/list';
export const GET_EVENT_LIST = '/events/general/list';
export const GET_SECTION_LIST = '/events/general/section/list';
export const DELETE_TEAM = '/team/delete';
export const DELETE_TEAM_NODE = '/team-member/delete';
export const ADD_TEAM = '/team/add';
export const TEAM_MEMBER_EXPORT = '/team-member/export';

export const ADD_TEAM_MEMBER = '/team-member/create';
export const ADD_TEAM_SINGLE_MEMBER = '/team-member/create-single';
export const GET_TEAM_MEMBER_DETAILS = '/team-member/getone';
export const UPDATE_TEAM_MEMBER = '/team-member/edit';
// export const RESEND_TEAM = "/team/resend"
export const RESEND_TEAM = '/team-member/resend-email';

export const VERIFY_TEAM = '/team/verify';
export const TEAM_LOGIN = '/auth/team/login';

export const ACTIVITY_FEED_LIST = '/activityfeed/feedlist';
export const ACTIVITY_FEED_STATUS = '/activityfeed/feedstatus';
export const ACTIVITY_FEED_LIKES = '/activityfeed/likelist';
export const ACTIVITY_FEED_REPORTS = '/activityfeed/reportlist';
export const ACTIVITY_FEED_COMMENT = '/activityfeed/commentlist';
export const ACTIVITY_FEED_PIN = '/activityfeed/pin';
export const ACTIVITY_FEED_UPDATE_MODERATION = '/activityfeed/moderation';
export const ACTIVITY_FEED_DELETE_COMMENT = '/activityfeed/deletecomment';
export const ACTIVITY_COMMENT_REPORT = '/activityfeed/comment/report';

export const COMMON_GET_PEOPLE_LIST = '/events/people/list';
export const GET_HBS_PEOPLE_LIST = '/events/hbs/people/list';

export const GET_ROOM_LIST = '/rooms/list';
export const ADD_ROOM = '/rooms/add';
export const DELETE_ROOM = '/rooms/delete';
export const GET_ROOM_BY_ID = '/rooms/id';

export const EVENT_LIVE_STATUS = '/event/livestatus';
export const EVENT_LIVE_STATUS_UPDATE = '/event/livestatus/update';

export const EVENT_ANALYTICS_NUMBERS = '/event/overview/analytics/numbers';
export const EVENT_ANALYTICS = '/event/overview/analytics';

export const GET_EVENT_PEOPLE_LIST = '/events/people/groups/list';
export const GET_CURRENCY_LIST = '/currency/list';
export const ADD_TICKET = '/tickets/add';
export const GET_TICKET_LIST = '/tickets/list';
export const DELETE_TICKET = '/tickets/delete';
export const GET_TICKET_BY_ID = '/tickets/id';
export const UPDATE_TICKET = '/tickets/update';
export const EXPORT_TICKET = '/tickets/exports';

export const EVENT_SECTION_ICON = '/events/webapp/icons';
// export const EVENT_SECTION_ICON='/events/icons';
export const EVENT_SECTION_STATUS = '/events/sections/status';
export const EVENT_SECTION_REARRANGE = '/events/sections/rearrange';
export const EVENT_SECTION_DELETE = '/events/sections/delete';
export const EVENT_SECTION_SETTINGS = '/sections/settings';
export const EVENT_SETTING_SECTION = '/events/settings/sections';

export const GET_USER_PROFILE_LIST = '/events/settings/userprofilelist';
export const REARRANGE_PROFILE_FIELD_LIST = '/events/userprofile/rearrange';
export const DELETE_PROFILE_FIELDS = '/events/userprofile/delete';
export const GET_USER_PROFILE_FIELDS = '/events/settings/userprofile';
export const ADD_USER_PROFILE_FIELDS = '/events/settings/userprofile';
export const UPDATE_USER_PROFILE_FIELDS = '/events/settings/userprofile';
export const ENABLE_PREBUILT_FIELDS = '/events/settings/userprofile/enable-in-ticketing ';

export const POST_TAX_INVOICING = '/settings/tax/update';
export const GET_TAX_INVOICING = '/settings/tax';

export const GET_SPEAKER_STATS = '/analytics/speaker/stats';
export const GET_SPEAKER_TOPCHARTS = '/analytics/speaker/topcharts';
export const GET_ANALYTICS_BOOTH_DATA = '/analytics/booth/stats';
export const GET_ANALYTICS_BOOTH_TOP_CHARTS = '/analytics/booth/topcharts';

export const GET_ORDER_LIST = '/tickets/orders';
export const DELETE_ORDER = '/orders/delete';
export const RESEND_ORDER = '/orders/resend';
export const REFUND_ORDER = '/orders/refund';
export const ORDER_EXPORTS = '/tickets/orders/exports';

export const LEADERBOARD_RESET_CURRENT_SETTINGS = '/leaderboard/reset';

export const GET_ANALYTICS_FEED_STATS = '/analytics/feeds/stats';
export const GET_ANALYTICS_FEED_POOLS = '/analytics/feeds/polls';
export const GET_MOST_ACTIVE_ATTENDEE = '/event/mostActiveAttendee';
export const FEED_WORD_CLOUD = '/event/wordCloud';
export const ANALYTICS_SESSION_STATS = '/analytics/session/stats';
export const ANALYTICS_TABLE_DATA = '/analytics/boothwise/stats';
export const GET_ANALYTICS_SPEAKER_STATS = '/analytics/speakerwise/stats';
export const VIRTUAL_BOOTH_INDIVIDUAL_ANALYTICS = '/analytics/boothwise/download';
export const VIRTUAL_BOOTH_OVERALL_ANALYTICS = '/analytics/download/booth/summary';
export const SPEAKER_WISE_OVERALL_ANALYTICS = '/analytics/download/speaker/summary';
export const SPEAKER_WISE_INDIVIDUAL_ANALYTICS = '/analytics/download/speaker/individual';
export const CONSOLIDATED_SESSION_REPORT = '/analytics/download/sessions/consolidatedSessionReport';

export const ANALYTICS_SESSION_TOPCHARTS = '/analytics/session/topcharts';
export const ANALYTICS_SESSION_WISE_STATS = '/analytics/sessionwise/stats';
export const ANALYTICS_SESSION_INDIVIDUAL_DATA = '/analytics/download/sessions/individual';
export const ANALYTICS_SESSION_SUMMARY_DATA = '/analytics/download/sessions/summary';

export const GET_FILE_LOGS = '/events/logs/files';
export const DELETE_FILE_LOGS = '/logs/files/delete';
export const GET_FILE_CONFIG = '/logs/files/config';
export const ANALYTICS_ROOMS_MEETINGS = '/analytics/rooms/meetings';
export const ANALYTICS_ROOMS_SUMMARY = '/analytics/rooms/summary';
export const ANALYTICS_ROOMS_STATS = '/analytics/rooms/stats';
export const ANALYTICS_ROOMS_OVERALL = '/analytics/download/rooms/summary';
export const ANALYTICS_ROOMS_SESSION = '/analytics/download/rooms/session/summary';
export const ANALYTICS_ROOMS_INDIVIDUAL = '/analytics/download/rooms/session/data';
export const ANALYTICS_LOUNGE_MEETINGS = '/analytics/download/lounge/meetings';
export const ANALYTICS_ONE_ON_ONE_MEETINGS = '/meetings/export';

export const ANALYTICS_ATTENDEE_STATS = '/analytics/attendees/stats';
export const ANALYTICS_ATTENDEE_DAY_WISE = '/analytics/attendees/daywise';
export const ANALYTICS_ATTENDEE_GROUP_WISE = '/analytics/attendees/groupStats';

export const ANALYTICS_AGENDA_STATS = '/webinar-analytics/engagement/stats';
export const ANALYTICS_LANGING_PAGE_STATS = '/webinar-analytics/registration/stats';

export const GET_STRIPE_SETTING = '/user/payout/stripe/details';
export const DISCONNECT_STRIPE_SETTING = '/user/payout/stripe/disconnect';
export const VERIFY_STRIPE_SETTING = '/user/payout/stripe/connect';

export const GET_NETWORKING_STATS = '/analytics/networking/stats';
export const GET_NETWORKING_ATTENDEE_STATS = '/analytics/networking/attendees/stats';
export const GET_NETWORKING_MEETING = '/analytics/networking/meetings';
export const GET_NETWORKING_REQUEST_MEETING = '/analytics/networking/requests/meetings';
export const GET_NETWORKING_LOUNGE_METTING = '/analytics/networking/lounge/meetings';
export const GET_NETWORKING_LOUNGE_USER = '/analytics/networking/lounge/users';

export const GET_REGISTRATION_OVERVIEW = '/analytics/ticket/overview';
export const GET_REGISTRATION_DAYWISE = '/analytics/ticket/daywise';
export const GET_REGISTRATION_LIST = '/analytics/ticket/list';
export const GET_REGISTRATION_SUMMARY = '/analytics/download/ticket/summary';

export const POLL_EXPORT = '/event/polls/export';

export const SESSION_STREAM_EVENT_HOST = '/events/hosts';

export const GET_RECORDING_LIST = '/recording/list';
export const GET_ROOM_RECORDING_LIST = '/recording/rooms/list';

export const ADD_WELCOME_VIDEO = '/brandyourevent/welcomevideo';
export const DELETE_WELCOME_VIDEO = '/brandyourevent/welcomevideo/delete';

export const ADD_PERVIOUS_EVENT_VIRTUAL_BOOTH = '/events/previousevents/booths';
export const GET_PERVIOUS_EVENT_VIRTUAL_BOOTH = '/events/previousevents/booths';

// multi skin
export const ALL_THEMES = '/event/allthemes';
export const ADD_NEW_THEME = '/event/theme';
export const APPLYE_THEME = '/event/applyTheme';
export const PREVIEW_THEME = '/event/theme/preview';
export const GET_THEME_BY_ID = '/event/theme/id';

// room bulk upload
export const GET_ROOM_SAMPLE_FILE_DATA = '/rooms/export/sample';
export const UPLOAD_ROOM_BULK_FILE_DATA = '/rooms/bulkupload';

// Onboarding
export const CHECK_USER_EXIST = '/checkuserexist';
export const SIGNUP = '/user/auth/signup';
export const SIGNUP_NEW = '/org/signup'
export const ORG_ONBOARDING = '/auth/collectonboardingdetails';
export const ONBOARDING_INDUSTRY = '/industry/list';
export const ONBOARDING_COUNTRIES_INDUSTRIES = '/countries-industries/list';

// survey
export const GET_SURVEY_LIST = '/survey/list';
export const GET_SURVEY_SETTINGS = '/survey/settings';
export const GET_SURVEY_DEFAULT_QUESTIONS = '/survey/questions/default';
export const CREATE_SURVEY = '/survey/create';
export const GET_SURVEY_RESPONSE_LIST = '/surveyresponse/list';
export const DELETE_SURVEY = '/survey/delete';
export const GET_SURVRY_SESSION_LIST = '/survey/sesssions/list';
export const DOWNLOAD_SURVEY = '/survey/downloadFile';
export const GET_SURVEY_BY_ID = '/survey/id';
export const EDIT_SURVEY = '/survey/edit';
export const EXPORT_SURVEY_RESPONSE = '/surveyresponse/exports';
export const DELETE_SURVEY_RESPONSE = '/surveyresponse/delete';
export const SESSION_SURVEY_RESPONSE_LIST = '/surveyresponse/sessions';
export const SESSION_LIST_FOR_SURVEY = '/survey/unmappedSession/list';

// sponsor ad
export const GET_BRAND_SPACE_TABLE_DATA = '/sponsor-ads-analytics/impressions-click';
export const ADD_SPONSOR_AD = '/sponsored-ads/create';
export const GET_EVENT_SECTION = '/sponsored-ads/getwebtabs';
export const SAVE_REARRANGE_SECTION = '/sponsored-ads/set-position-view';
export const EDIT_SPONSOR_AD = '/sponsored-ads/edit';
export const EXPORT_SPONSOR_AD = '/sponsor-ads-analytics/exports';
export const ANALYTICS_SPONSOR_AD = '/sponsor-ads-analytics/statistics';
export const GET_REARRANGE_MAIN_SECTION = '/sponsored-ads/main-list';
export const GET_REARRANGE_SIDE_SECTION = '/sponsored-ads/side-list';

export const GET_SPONSOR_LIST = '/sponsored-ads/list';
export const DELETE_SPONSOR = '/sponsored-ads/delete';
export const VISIBLITY_SPONSOR = '/sponsored-ads/visibility';
export const PUBLISH_SPONSOR = '/sponsored-ads/set-to-publish';
export const EXPORT_SPONSOR = '/sponsor-ads/exports';
export const CHECK_FEASIBILITY_VANITY_URL = '/brandyourevent/settings/check-event-availability';

// constest
export const ADD_CONTEST_DETAILS = '/contest/create';
export const CONTEST_LIST = '/contest/list';
export const CONTEST_EDIT = '/contest/edit';

export const GET_STANDINGS_LIST = '/contest/que/standings';
export const GET_CONTEST_BY_ID = '/contest/getbyid';

// constest question
export const CONTEST_QUESTION_LIST = '/contest/que/list';
export const CONTEST_ADD_QUESTION = '/contest/que/create';
export const CONTEST_QUESTION_GET_BY_ID = '/contest/que/getbyid';
export const CONTEST_DELETE_QUESTION = '/contest/que/delete';
export const CONTEST_EDIT_QUESTION = '/contest/que/edit';
export const EXPORT_QUESTION = '/contest/que/download';

export const CONTEST_MODERATE_LIST = '/contest/moderate';
export const CONTEST_MODERATE_APPROVE_AND_REJECT = '/contest/moderate/approve';
export const CONTEST_QUIZ_DECLARE_WINNER = '/contest/que/winner/declare';

export const CONTEST_ENTRY_LIST = '/contest/entries';
export const CONTEST_ENTRY_DELETE = '/contest/entry/delete';
export const CONTEST_ENTRY_SHORTLIST_UNSHORTLIST = '/contest/entries/shortlist_unshortlist';
export const CONTEST_FEED_LIKE = '/contest/feed/like';
export const CONTEST_COMMENT_DELETE = '/contest/comment/delete';
export const CONTEST_RESPONSE_WINNER_LIST = '/contest/response/winners';
export const CONTEST_ENTRY_WINNER_LIST = '/contest/entry/winners';
export const CONTEST_WINNER_DECLARE = '/contest/winners/declare';

// studio-hosts endpoints
export const STUDIO_HOST_LIST = '/events/studiohost/list';
export const STUDIO_CO_HOST_LIST = '/events/hbsv3cohost/list';
export const HOST_LIST = '/broadcaststudio/host/list';
export const HOST_SWAP = '/broadcaststudio/host/swap';
export const HOST_ADD = '/broadcaststudio/host/add';
export const HOST_DELETE = '/broadcaststudio/host/delete';
export const HOST_PEOPLE_LIST = '/broadcaststudio/host/peoplelist';

export const GET_SUBSCRIPTIONS_PLANS = '/subscriptions/plans';
export const ADD_SUBSCRIPTIONS_CONTACT_SALES = '/subscriptions/contact-sales';
export const UPGRADE_PLANS = '/upgrade/plans';
export const LAST_SUBSCRIPTION_DETAILS = '/subscriptions/last-payment-details';
export const GET_ESTIMATION_PLANS = '/upgrade/estimate-upgrade-plans';
export const ABORT_SUBSCRIPTION = '/subscriptions/abort-cancel-subscription';
export const REACTIVATION_SUBSCRIPTIONS = '/subscriptions/reactivate-subscription';
export const MANAGE_SUBSCRIPTION = '/subscriptions/access-url';
export const GET_CURRENT_PLANS = '/subscriptions/current-plan';

// trayio salesforce hubspot etc integrations api starts here
export const GET_INTEGRATION_LIST_API = '/integrations/list';
export const CONNECT_API = '/integrations/create_solution_instance';
export const FINISH_API = '/integrations/activate';
export const EVENT_LIST_API = '/integrations/events';
export const OLD_ENTRIES_SYNC_API = '/integrations/events/old_entries';
export const DONE_API = '/integrations/done';
export const DISCONNECT_API = '/integrations/disconnect';
export const EVENTS_WITH_SYNC_STATUS_API = '/integrations/events/sync_status';
export const LOGS_LIST_API = '/integrations/logs';
export const SYNC_EVENT_API = '/integrations/events/sync_status/update';
export const SYNC_EVENT_OLD_ENTRIES_API = '/integrations/sync/now';

// Version 2 APIs
// in usage integrations/orgs/<org_id>/apps/<app_id>/connects
// in usage /integrations/orgs/<org_id>/apps/<app_id>/activates
// in usage /odin/api/v1/integrations/orgs/<org_id>/apps/<app_id>/disconnects
export const INTEGRATION_V2 = '/integrations/orgs';
export const INTEGRATION_V2_LOGS = '/integrations/logs/orgs';

// trayio salesforce integrations api ends here

// Multi-Event-Analytics
export const GET_DEMO_GRAPHICS = '/multievent-analytics/overview/demographics';
export const GET_MULTI_EVENT_OVERVIEW = '/multievent-analytics/overview';
export const GET_MULTI_EVENT_OVERVIEW_EVENTS = '/multievent-analytics/overview/events';
export const GET_OVERVIEW_EVENT_EXPORT = '/multievent-analytics/overview/events/export';

export const GET_MULTI_EVENT_ENGAGEMENT_PEOPLE = '/multievent-analytics/engagement/people';
export const GET_MULTI_EVENT_ENGAGEMENT = '/multievent-analytics/engagement';
export const GET_MULTI_EVENT_ENGAGEMENT_SESSIONS = '/multievent-analytics/engagement/sessions';
export const GET_MULTI_EVENT_ENGAGEMENT_VIRTUAL_BOOTHS =
  '/multievent-analytics/engagement/virtual-booths';
export const GET_MULTI_EVENT_ENGAGEMENT_LOUNGE = '/multievent-analytics/engagement/lounge';
export const GET_MULTI_EVENT_ENGAGEMENT_ROOMS = '/multievent-analytics/engagement/rooms';
export const GET_MULTI_EVENT_ENGAGEMENT_ONE_ON_ONE_MEETINGS =
  '/multievent-analytics/engagement/one-on-one-meetings';

export const GET_MULTI_EVENT_REVENUE = '/multievent-analytics/revenue';
export const GET_MULTI_EVENT_REVENUE_DISTRIBUTION = '/multievent-analytics/revenue/distribution';
export const GET_MULTI_EVENT_REVENUE_EVENTS = '/multievent-analytics/revenue/events';
export const GET_MULTI_EVENT_REVENUE_EXPORT = '/multievent-analytics/revenue/events/export';
export const GET_MULTI_EVENT_REVENUE_COUNTRY = '/multievent-analytics/revenue/country';
export const GET_MULTI_EVENT_EVENT_LIST = '/multievent-analytics/events';

// speaker reorder
export const GET_SPEAKER_LIST = '/event/speakers/list';
export const REARRANGE_SPEAKER_LIST = '/event/rearrange/speakers';

// session
export const GET_SESSION_LIST = '/event/session/list';

// sso
// export const SSO_CONNECT_API = '/sso/add';
export const SSO_CONNECT_API = '/sso/manage'; // @jayesh added new end point for add/update sso details | HUB 13034
export const SSO = '/sso'; // HUB 13034 | End point use for delete / get the data with method GET|Delete
export const SSO_DISCONNECT_API = '/sso/disconnect';
export const LAST_SYNC_TIME_API = '/integrations/sync/time';

// organiser people
export const GET_ORGANISER_PEOPLE_LIST = '/organiser-contacts/people/list';
export const GET_ORGANISER_LOOKING_FOR = '/organiser-contacts/looking-for';
export const GET_ORGANISER_OFFERING = '/organiser-contacts/offering';
export const GET_ORGANISER_INDUSTRY = '/organiser-contacts/industry';
export const GET_ORGANISER_INTEREST = '/organiser-contacts/interest';
export const GET_ORGANISER_GROUPS = '/organiser-contacts/groups';
export const GET_ORGANISER_CUSTOM_TAGS = '/organiser-contacts/custom-tags';
export const GET_RECENT_EVENTS = '/organiser-contacts/recent-events';
export const GET_PROFILE_DETAILS = '/organiser-contacts/profile-details';
export const GET_ACTIVITY_DETAILS = '/organiser-contacts/activity';
export const GET_ACTIVITY_TYPE = '/organiser-contacts/activity-type';
export const GET_ALL_EVENTS = '/organiser-contacts/events';
export const EXPORT_ORGANISER_PEOPLE = '/organiser-contacts/people/export';
export const EXPORT_ORGANISER_RECENT_EVENT = '/organiser-contacts/recent-events-export';
export const EXPORT_ORGANISER_PROFILE_DETAILS = '/organiser-contacts/profile-details-export';
export const CONTACT_SALES_CUSTOM_DAYS = '/subscriptions/contact-sales-custom-days';

// Upgrade modal endpoints
export const UPGRADE_MODAL_ACTIONS = '/user/organizer/setting';
/// /event section re design
export const EVENT_SECTION_MAIN_SEC_LIST = '/event-section/event-section/list';
export const EVENT_SECTION_SUB_SEC_LIST = '/event-section/event-subsection/list';
export const EVENT_SECTION_MAIN_SEC_DETAIL = '/event-section/event-section/details';
export const EVENT_SECTION_SUB_SEC_DETAIL = '/event-section/event-subsection/details';
export const EVENT_SECTION_ICON_LIB = '/event-section/event-section/icon-library';
export const EVENT_SECTION_SUB_ADD = '/event-section/event-subsection/create';
export const EVENT_SECTION_SUB_DELETE = '/event-section/event-subsection/delete';
export const EVENT_SECTION_VISIBILTY = '/event-section/event-section/show-hide';
export const EVENT_SECTION_MAIN_ORDER = '/event-section/event-section/update/order-index';
export const EVENT_SECTION_EDIT_DETAILS = '/event-section/event-section/update/details';
export const EVENT_SECTION_SUB_SEC_DELETE = '/event-section/event-subsection/delete';
export const EVENT_SECTION_SUB_SEC_ORDER = '/event-section/event-subsection/update/order-index';

export const EVENT_SECTION_SLOTS = 'event-section/event-section/slots/confirm';
// V2 API END POINTS

export const SWAGGER_V2_BASE_URL = 'https://ticketing-backend-doc.herokuapp.com/api/v1';
export const CUSTOM_V2_BASE_URL = 'https://ticketing.v2dev.demohubilo.com/api/v1';

// Analytics Registration
export const GET_V2_REG_OVERVIEW = '/analytics/overview';
export const GET_V2_REG_DAY_WISE = '/analytics/daywise';
export const GET_V2_REG_LIST = '/analytics/list';
export const GET_V2_REG_SUMMARY = '/analytics/export';

// Orders
export const GET_V2_ORDERS_LIST = '/orders';
export const V2_ORDER_RESEND_CONFIRM_MAIL = '/orders/resend-confirmation';
// Incomplete order resend email
export const V2_ORDER_RESEND_MAIL = '/orders/resend-mail';
export const V2_REFUND_ORDER = '/orders/{ID}/refund';
export const V2_ORDER_DOWNLOAD = '/orders/download';
export const V2_EACH_ORDER_DOWNLOAD = '/orders/order/invoice';
export const V2_ORDER_DELETE = '/orders';
export const V2_ORDER_APPROVE = '/orders/{ID}/approve';
export const V2_ORDER_DISAPPROVE = '/orders/{ID}/deny';
export const V2_RESEND_PAYMENT_REQUEST = '/orders/resend-payment-request';

// Discount
export const GET_DISCOUNT_LIST = '/discounts';
export const DISCOUNT_LIST_ARCHIVE = '/discounts/archives';
export const DISCOUNT_LIST_UNARCHIVE = '/discounts/unarchives';
export const DISCOUNT_LIST_DELETE = '/discounts';
export const ADD_DISCOUNT = '/discounts/add';
export const GET_DISCOUNT_DATA = '/discounts/{ID}';
export const GET_DISCOUNT_TICKETS = '/ticket/basic-details';

// new tickting flow
export const TICKET_CURRENCY_LIST = '/ticket/currency';
export const TICKET_CREATE = '/ticket/create';
export const TICKET_EDIT = '/ticket/edit';
export const TICKET_INVOICE_PRVIEW = '/ticket/preview';
export const TICKET_GROUP_LIST = '/ticket/attendee-group';
export const TICKET_COUNTRY_LIST = '/ticket/countries/list';
export const TICKET_STATE_LIST = '/ticket/states/list';
export const TICKET_EMAIL_UPLOAD = '/ticket/restricted-emails';
export const TICKET_EMAIL_TEMPLATE = '/ticket/download_template';
export const GET_TICKET_DTLS_BY_ID = '/ticket/details/';
export const TICKET_LIST = '/ticket/search';
export const TICKET_ARCHIVE = '/ticket/archive';
export const TICKET_UNARCHIVE = '/ticket/unarchive';
export const TICKET_HIDE = '/ticket/hidden';
export const TICKET_VISIBLE = '/ticket/visible';
export const TICKET_DELETE = '/ticket/delete';
export const TICKET_CREATE_EMAIL_CONFIG = '/campaign/ticketconfig';
export const TICKET_SETTING_EMAIL_CONFIG = '/campaign/ticketsettings';

// Ticket Settings
export const GET_TICKET_SETTINGS = '/ticketsetting/details';
export const SAVE_TICKET_SETTINGS = '/ticketsetting/save';
export const FETCH_TICKET_COUNTRY_STATE = '/ticketsetting/country-state';
export const GET_BUYER_FORMS = '/ticketing-form-fields';
export const POST_TICKETING_WIDGET_URL = '/events/ticketing-widget';
export const GET_TS_OFFLINE_PAYMENTS = '/ticketsetting/payment-methods?type=offline';

// EMBED APPS (BASE URL would be V2 includeds)

export const ENABLE_DISABLE_EMBED_APP = '/integrations/embed-app';
export const EMBED_APP_UPDATE_STATUS = '/embed-app/status-app';
export const EVENT_CUSTOM_APP_INT_LIST = '/embed-app/list-app';
export const EMBED_APP_UPDATE = '/embed-app/update-app';
export const ADD_INT_APP_LIST = 'embed-app/integration-list';
export const ADD_UPDATE_ICON_LIST = 'embed-app/icon-list';
export const EVENT_ADD_CUSTOM_APP = 'embed-app/save-app';
export const GET_SESSION_ENGAGEMENT_LIST = 'engagement/list';
export const REARRANGE_SESSION_ENGAGEMENT_LIST = 'engagement/order-index';
export const UPDATE_SESSION_ENGAGEMENT_STATUS = 'engagement/status';
export const UPDATE_SESSION_ENGAGEMENT = 'engagement/update';
export const ADD_SESSION_ENGAGEMENT = 'engagement/save';
export const ENABLE_FOR_IN_PLACE_SESSION = 'engagement/enable-for-in-place-session';

// Developer Section - Refresh Token
export const REFRESH_ACCESS_TOKEN = '/helper/refreshToken';
export const APPLICATION_GET_TOKEN = '/helper/getToken';
export const APPLICATION_GET_TOKEN_ACCESS = '/helper/getOrganiserAPIToken';

export const ADMIN_LOGIN = '/auth/admin-login';

export const IFRAME_EVENT_SLUG = '/events/iframe-slug';
export const COOKIE_RECORD_LIST = '/cookie-record/list';
export const COOKIE_RECORD = '/cookie-record';

// Virtual Background
export const VIRTUAL_BACKGROUND_LIST = '/virtual-background/list';
export const VIRTUAL_BACKGROUND_SAVE = '/virtual-background/save';
// Tag in Chat
export const GET_TAG_IN_CHAT = '/settings/tagging-chat';
export const POST_TAG_IN_CHAT_ALLOWED = '/settings/tagging-chat/update';
export const UPDATE_EVENT_TRACKING = '/events/tracking';
export const GET_EVENT_TRACKING = '/events/tracking';

// Duda Landing Page
export const GET_LANDING_PAGES = '/landing-pages';
export const GET_ALL_TEMPLATES = '/templates';
export const EDIT_LANDING_PAGE = '/landing-pages/{ID}/edit';
export const PUBLISH_LANDING_PAGE = '/landing-pages/{ID}/publish';
export const DUPLICATE_LANDING_PAGE = '/landing-pages/{ID}/duplicate';
export const RENAME_LANDING_PAGE = '/landing-pages/{ID}/rename';

// Delete Recordings
export const DELETE_ROOM_RECORDING = '/recording/rooms/delete';
export const DELETE_SESSION_RECORDING = '/recording/session/delete';

// Copy Event
export const COPY_EVENT_GET_BASIC_INFO_API = '/events/basicinfo';
export const COPY_EVENT_API = '/events/copy-event';
export const CHECK_USER_CREDIT = '/events/check-credits';
export const UPDATE_BEACON_STATUS = '/events/update-beacon';
export const GET_DUPLICATION_STATUS = '/events/duplication-status';
export const GET_EVENT_COPY_STATUS = '/events/copy-status';
export const GET_FAILED_ITEMS = '/events/failed';

export const UPDATE_ROOM_REACTION_ENABLE = '/rooms/add';
export const GET_LOUNGE_REACTION = '/lounge';
export const UPDATE_LOUNGE_ENABLE_REACTION = '/lounge/tables';

// Campaign Flow
export const POST_CAMPAIGN_DETAILS = '/campaign';
export const GET_CAMPAIGN_DETAILS_ID = '/campaign/{ID}';
export const GET_CAMPAIGN_TEMPLATES = '/campaign/template';
export const TOGGLE_CAMPAIGN_STATUS = '/campaign/toggleCampaignStatus';
export const DELETE_EMAIL_TEMPLATE = '/template/{ID}';
export const GET_CAMPAIGN_REPORT_OVERVIEW_ID = '/campaign/report/overview/{ID}';
export const POST_CAMPAIGN_REPORT_BY_ID = '/campaign/report/delivery/{ID}';
export const GET_CAMPAIGN_LIST = '/campaign/list';
export const UPDATE_CAMPAIGN_STATUS = '/campaign/toggleCampaignStatus';
export const DOWNLOAD_CAMPAIGN_REPORT_BY_ID = '/campaign/report/download/{ID}';
export const RESEND_MAIL_BY_CAMPAIGN_ID = '/email/resend/{ID}';
export const GET_PEOPLE_COUNT = '/people/count';
export const POST_CAMPAIGN_SCHEDULE = '/campaign/schedule-email';
export const PATCH_CAMPAIGN_SLOT_ID = '/campaign/{ID}/comminstance';

// Template Flow
export const SEND_TEST_EMAIL = '/email/send/test';
export const CREATE_TEMPLATE = '/template';
export const UPDATE_TEMPLATE_DETAILS_BY_ID = '/template/{ID}';
export const GET_TEMPLATE_PREVIEW_BY_ID = '/campaign/preview/{ID}';
export const GET_TEMPLATE_DETAILS_BY_ID = '/template/{ID}';
export const GET_TEMPLATE_UNLAYER_OBJECT = '/unlayer/template/{ID}';

// Partner Data Mappings APIs
export const PARTNER_EVENTS = '/events';
export const PARTNER_LOGS = '/partners/logs/orgs/{organiserId}';
export const PARTNER_EVENT_LOGS = '/partners/logs/orgs/{organiserId}/events/{eventId}';
export const PARTNER_LOGS_EVENTLIST = '/events/syncs/logs';
export const META_DATA = '/partner/event/{eventId}/mappings/metadata';
export const FETCH_MAPPINGS = '/partner/event/{eventId}/mappings/data';
export const SAVE_MAPPINGS = '/partner/event/{eventId}/mappings/save';
export const ENABLE_SYNC_DATA = '/partner/event/{eventId}/datasync/update';
export const SYNC_STATUS_INFO = '/partner/event/{eventId}/datamappings/status';
export const SYNC_DATA_TIME = '/partner/event/{eventId}/datasync/autosyncoff/timestamp';

// Ticket Reorder
export const REARRANGE_TICKET_LIST = '/ticket/reorderPostions';

// Analytics Report

export const ANALYTICS_REPORTS_FIELD = '/report-analytics/profile-fields';

// new event creation
export const GET_LANGUAGES_LIST = '/api/v2/events/language-list';
export const EVENT_TIMEZONE_LIST = '/api/v2/events/timezone-list';
export const CREATE_NEW_EVENT = '/api/v2/event';
export const CREATE_EVENT_TYPE_LIST = '/api/v2/events/type-list';
export const FETCH_EVENT_DETAILS = '/api/v2/event';
// Campagin toggle status update
export const CAMPAIGN_TOGGLE_STATUS = '/campaign/toggleCampaignStatus';
// Campagin toggle status
export const CAMPAIGN_STATUS = '/campaign/campaignStatus';
// validate email
export const VALIDATE_EMAIL = '/notifications/people/validate-email';
// event addons
export const EVENT_ADDONS = '/subscription/event-addons';

// quiz
export const SESSION_LIST_FOR_QUIZ = '/engagement/quiz/unmappedsessions';
export const GROUPS_LIST_FOR_QUIZ = '/engagement/quiz/groups';
export const CREATE_QUIZ = '/engagement/quiz';
export const GET_QUIZ = '/engagement/quiz';
export const DELETE_QUIZ = '/engagement/quiz';
export const GET_SINGLE_QUIZ = '/engagement/quiz';
export const GET_QUIZ_FIELD_TYPES = '/engagement/quiz/fieldtypes';
export const GET_QUIZ_QUESTIONS = '/engagement/quiz/questions';
export const DOWNLOAD_QUIZ_LIST = '/engagement/quiz/download';
export const GET_QUIZ_RESPONSES = '/engagement/quiz/responses';
export const POST_QUIZ_RESPONSE_DOWNLOAD = '/engagement/quiz/responses/download';
export const CREATE_QUIZ_QUESTIONS = '/engagement/quiz/question';
export const EDIT_QUIZ_BASIC_DETAILS = '/engagement/quiz';
export const GET_QUIZ_SESSIONS = '/engagement/quiz/sessions';
export const DELETE_QUIZ_QUESTIONS = '/engagement/quiz/question';
export const REORDER_QUIZ_QUESTIONS = '/engagement/quiz/question/reorder';
export const EDIT_QUIZ_QUESTIONS = '/engagement/quiz/question';

// Credits Quiz
export const AGENDA_CREDITS = '/events/agenda/credits';
export const CREDITS_LIST = '/credits/list';

// session enhancement
export const FETCH_SESSION_LIST = '/agendas/list';
export const INIT_AGENDA = '/agendas/init';
export const INIT_AGENDA_LIST = '/agendas/init/list';
export const FETCH_SESSION_GLOBAL_SETTINGS = '/agenda/global-settings';

// Lead-Score
export const ACTIVITY_SCORE = 'marketing-insights/scores'; // (only method will change get,put)
export const RESET_ACTIVITY_SCORE = 'marketing-insights/scores/reset';
export const LEAD_LEVELS = 'marketing-insights/levels';
export const RESET_LEAD_LEVELS = 'marketing-insights/levels/reset';
export const LEAD_LEVEL_DIST = 'marketing-insights/leads'; // (only method will change get,put ,& lebel update)
export const LEAD_ANALYTICS_OVERVIEW = 'marketing-insights/analytics/overview';
export const LEAD_ANALYTICS_DETAILS = '/marketing-insights/analytics/detailed';
export const EXPORT_LEAD_ANALYTICS = 'marketing-insights/analytics/export';
// conversion prompt
export const FETCH_ALL_CONVERSION_PROMPTS =
  '/marketing-insights/session/<session-id>/conversion-prompt/';
export const FETCH_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/conversion-prompt/<cp-id>';
export const CREATE_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/conversion-prompt/';
export const EDIT_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/conversion-prompt/<cp-id>/edit';
export const DELETE_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/conversion-prompt/delete';
export const SHARE_REMOVE_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/conversion-prompt/<cp-id>/shareremove';
export const CURRENTLY_SHARED_CONVERSION_PROMPT =
  '/marketing-insights/session/<session-id>/engagement';
// NPS & CSAT
export const GET_NPS_DETAILS = '/events/nps/';
export const SET_NPS_DETAILS = '/events/nps/save';
export const GET_CSAT_DETAILS = '/events/csat/';
export const SET_CSAT_DETAILS = '/events/csat/save';

// Registration flow

export const GET_WORKFLOW_STATUS = '/workflow/status';
export const UPDATE_WORKFLOW_POP_UP = '/workflow/settings';
export const GET_WORKFLOW_STEP_DETAIL = '/workflow/get';
export const UPDATE_WORKFLPW_STEP_DETAIL = '/workflow/update';
export const FETCH_INTEGRATION_SETTINGS = '/workflow/integration-settings';
// Zoom Integration
export const SET_ZOOM_LICENSE = '/zoom/organiser/license';
export const GENERATE_ZOOM_TOKEN = '/zoom/generate/token';
export const GET_ZOOM_DETAIL = '/zoom/organiser/details';
export const REGENERATE_ZOOM_TOKEN = '/zoom/regenerate/token';
export const ZOOM_WEBINAR_HOSTS = '/zoom/webinar/hosts';
export const ZOOM_STATUS = '/zoom/setup/status';
export const ZOOM_ORGANISER = '/zoom/get/organiser/settings';
export const ZOOM_EDIT_ORGANISER = '/zoom/edit/organiser/settings';
// plans and purchase
export const GET_ADD_ON_HISTORY = '/pricing/xns/add-ons/list';
export const GET_CURRENT_SUBSCRIPTION = '/pricing/current-subscription';
export const GET_NOTIFICATION_LIST = '/notifications/list';
export const READ_ALL_NOTIFICATION = '/notifications/mark/read';
export const READ_NOTIFICATION = '/notifications/mark/single-read';
export const GET_ADD_ON_CONSUMED_HISTORY = '/subscription/event-addons/usage/list';
export const GET_SUPPORT_USAGE_HISTORY = '/pricing/support-usage/list';

// Video Snippets
export const VS_OVERVIEW = '/sesh/events/details';
export const VS_SESSION_LIST_BY_EVENT = '/sesh/events/details/{ID}';
export const VS_SESSION_DTLS = '/sesh/agenda/{ID}/info';

// UTM
export const GET_UTM_LIST = '/marketing-insights/utm-params/list';
export const SAVE_UTM_LIST = '/marketing-insights/utm-params';
export const DELETE_UTM_LIST = '/marketing-insights/utm-params/disable';
export const EXPORT_UTM = '/utm/export/report';

// Email Filtering
export const GET_POST_DOMAIN_SETTINGS = '/event/settings/restricted-domain'; // for get and post
export const CHECK_RESTRICTED_DOMAIN = '/events/people/check-restricted-domain';

// Global Integration
export const GET_META_DETAILS = '/integrations/orgs/{ORG_ID}/apps/{ID}/details';
export const GET_UPCOMING_EVENT_LIST =
  '/integrations/orgs/{ORG_ID}/apps/{ID}/upcoming/events?page_number={PAGE_NUM}&page_size={PAGE_SIZE}';
export const UPDATE_UPCOMING_EVENT_LIST = '/integrations/orgs/{ORG_ID}/apps/{ID}/mappings/updates';

// Email Manager
export const DUPLICATE_EMAIL_MANAGER_TEMPLATE = '/template/active-custom';
export const INVITE_EMAIL_CAMPAIGN = '/campaign/invite-email-campaign';
export const REMOVE_THEME_EMAIl_CAMPAIGN = '/template/{templateId}/removeTheme';
export const MERGE_TAG_VERIFY = '/template/merge-tag/verify';
