import React from 'react';

export default function SvgProPlanIconFilled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9998 21C16.9999 21.0078 17 21.0156 17 21.0235V22H7V21.0235C7 21.0156 7.00008 21.0078 7.00023 21C7.0049 20.7616 7.08068 20.5274 7.22143 20.317C7.36681 20.0997 7.57699 19.9145 7.83333 19.7778C8.08968 19.6411 8.29986 19.4558 8.44523 19.2385C8.59061 19.0212 8.66667 18.7786 8.66667 18.5321V18H15.3333V18.5321C15.3333 18.7786 15.4094 19.0212 15.5548 19.2385C15.7001 19.4558 15.9103 19.6411 16.1667 19.7778C16.423 19.9145 16.6332 20.0997 16.7786 20.317C16.9193 20.5274 16.9951 20.7616 16.9998 21Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4518 18C14.6078 18.346 14.7909 18.6805 15 19H9C9.20914 18.6805 9.39221 18.346 9.5482 18C9.97583 17.0514 10.2 16.016 10.2 14.9633V8H13.8V14.9633C13.8 16.016 14.0242 17.0514 14.4518 18Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8V9H8V8L9.71429 7H14.2857L16 8Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H9L10 8H14L15 2H14L13 3.2L12.5 2H11.5L11 3.2L10 2Z"
        fill="#F59C86"
      />
    </svg>
  );
}
