import projectSetting from 'settings';
import { PricingPlan } from 'constants/pricingPlan';
import moment from 'moment-timezone';

// Logger
const localLog = (key, val) => {
  // eslint-disable-next-line no-console
  console.log(`local logs ${key}`, val);
};

const campaignNameElementId = 'campaignName';
const templateNameElementId = 'templateName';
const labels = {
  auto: {
    CampaignBreadCrump: 'Automated email',
    chip: 'Automated',
  },
  custom: {
    CampaignBreadCrump: 'Custom Emails',
    chip: 'Custom',
  },
};
const schemaCampaignType = {
  PRE_EVENT: 'Pre-event',
  POST_EVENT: 'Post-event',
  DURING_EVENT: 'During-event',
};

const schemaCampaignStatus = {
  active: 'Active',
  paused: 'Paused',
  draft: 'Draft',
  ongoing: 'Ongoing',
  scheduled: 'Scheduled',
  sent: 'Sent',
};

const readOnlySchema = ['ongoing', 'sent'];

const styleCampaignStatus = {
  active: 'text-info',
  draft: 'text-danger',
  paused: 'text-secondary',
  ongoing: 'text-warn2',
  scheduled: 'text-warn2',
  sent: 'text-success2',
};

const campaignStateOption = [
  { value: 'PRE_EVENT', label: 'Pre-event' },
  { value: 'POST_EVENT', label: 'Post-event' },
  { value: 'DURING_EVENT', label: 'During event' },
];

const schemaSuggestions = {
  subject: 'SUBJECT',
  description: 'DESCRIPTION',
};

const schemaAudienceItem = {
  group: { label: 'Group', value: 'GROUP' },
  people: { label: 'People', value: 'PEOPLE' },
};

const schemaAudience = [{ ...schemaAudienceItem.group }, { ...schemaAudienceItem.people }];

const campaignSteps = {
  name: 'NAME',
  basic: 'BASIC',
  receiver: 'RECEIVER',
  sender: 'SENDER',
  template: 'TEMPLATE',
};

const allCampaignSteps = [
  campaignSteps.name,
  campaignSteps.basic,
  campaignSteps.receiver,
  campaignSteps.sender,
  campaignSteps.template,
];

const CAMPAIGN_SUB_TYPE = {
  INVITE: 'INVITE',
  EVENT_PROMOTIONAL: 'EVENT_PROMOTIONAL',
  EVENT_REGISTRATION: 'EVENT_REGISTRATION',
  SUCCESSFUL_TRANSACTION: 'SUCCESSFUL_TRANSACTION',
  INCOMPLETE_TRANSACTION: 'INCOMPLETE_TRANSACTION',
  MEETING_REMINDER: 'MEETING_REMINDER',
  FEEDBACK_SURVEY: 'FEEDBACK_SURVEY',
  CUSTOM: '',
  EVENT_REMINDER: 'EVENT_REMINDER',
  FOLLOW_UP_ABSENTEE: 'FOLLOW_UP_ABSENTEE',
  FOLLOW_UP_ATTENDEE: 'FOLLOW_UP_ATTENDEE'
};

// Labels
const LABEL_CAMPAIGN_NAME = 'Enter campaign title';
const LABEL_CAMPAIGN_TYPE = 'Select Event State';
const LABEL_BASIC_DETAIL = 'Select State and subject of the email';
const LABEL_TITLE_EDIT_CONFIRM_CREATE = 'Your email campaign will be paused automatically';
const LABEL_DESC_EDIT_CONFIRM_CREATE =
  'You must switch on the toggle on top of the page to re-activate this campaign after you make changes.';
const LABEL_TITLE_EDIT_CONFIRM_UPDATE = 'Your emailer will be paused if you want to edit';
const LABEL_DESC_EDIT_CONFIRM_UPDATE =
  'You must deactivate the automated email function to make edits to the email. You can activate the function later.';
const LABEL_EDIT_CONFIRM_BTN_CREATE = 'Paused & Edit';
const LABEL_EDIT_CONFIRM_BTN_UPDATE = 'Continue';
const LABEL_EDIT_CONFIRM_BTN_CANCEL = 'Cancel';
const LABEL_CAMPAIGN_ACTIVE = 'Your custom email is ready to send.';
const LABEL_CAMPAIGN_PAUSED = 'The email has been paused. Enable the toggle to activate it.';
const LABEL_SCHEDULE_TITLE = 'Your campaign has been scheduled successfully';
const LABEL_SCHEDULE_DATE_TIME = 'This campaign has been scheduled for {{DATE_TIME}}.';
const LABEL_SCHEDULE_TIMEZONE = 'Timezone : {{TIMEZONE}}';
const LABEL_DEACTIVATE_TITLE = 'Are you sure you want to deactivate email?';
const LABEL_DEACTIVATE_DESCRIPTION = 'The recipient will not receive this email.';
const LABEL_ACTIVATE_BTN = 'Activate';
const LABEL_DEACTIVATE_BTN = 'Deactivate';
const LABEL_SEND_TITLE = 'You are about to share the email “<email_name>” to your recipients';
const LABEL_DELETE_CAMPAIGN = 'Delete “{{CAMPAIGN}}”?';
const LABEL_DESC_DELETE_CAMPAIGN =
  '“{{CAMPAIGN}}” will be deleted and removed from the list of emails.';
const LABEL_TEST_TITLE = 'Send a test email';
const LABEL_TEST_DESC =
  'Your test email will have dummy information for a better testing experience.';
const LABEL_EMAIL_LIST_TITLE = "Let's set up your event emails";

const ERROR_TIME_ZONE = 'Please select a timezone';
const ERROR_EMPTY_DATE_TIME = 'Please select a date and time';
const ERROR_PAST_DATE_TIME = 'Past date and time cannot be selected';
const ERROR_INVALID_DATE_TIME = 'Please select a valid date and time';
const ERROR_CAMPAIGN_CREATE = 'Failed to create. Something went wrong';
const ERROR_CAMPAIGN_MODIFY = 'Failed to update. Something went wrong';
const LABEL_TEMPLATE_NAME = 'Enter Template Name';
const ERROR_CAMPAIGN_EMPTY = 'Please enter a email name';
const CAMPAIGN_CHAR_LIMIT = 60;
const ERROR_CHAR_LIMIT = `Maximum allowed characters ${CAMPAIGN_CHAR_LIMIT}, <FIELD_NAME> has <CHAR_LIMIT> characters`;
const ERROR_EVENT_STATE = 'Please select an event state';
const ERROR_CAMPAIGN_PAST_TRIGGER = "The trigger time cannot be in the past.";
const ERROR_CAMPAIGN_FUTURE_TRIGGER = "Email trigger time cannot be after event start date and time.";
const ERROR_SLOT_PAST_SELECTION = "Email trigger time cannot be in the past.";
const FAIL_CAMPAIGN_SLOT_SAVE = "Failed! Email setting couldn't be updated, try again.";
const SUCCESS_CAMPAIGN_SLOT_SAVE = "Success! Email setting has been updated.";
const ERROR_SLOT_GENERIC = "Invalid time selected, please check the trigger time.";
const ERROR_TEMPLATE_GENERIC = "Please select a template for a reminder."

// State Initialization
const INIT_CAMPAIGN_BASIC_DETAIL = {
  eventState: '', // PRE_EVENT | POST_EVENT | DURING_EVENT,
  emailSubject: '',
  emailDescription: '',
};

const INIT_CAMPAIGN_SENDER_DETAIL = {
  senderName: '',
  senderEmail: '',
  bcc: [],
  cc: [],
};

const INIT_CAMPAIGN_AUDIENCE = {
  group: [],
  hasPeople: false,
};

const INIT_SCHEDULE_CAMPAIGN = {
  campaignTimeMilli: -1,
  campaignTimeZone: '',
};

const INIT_CAMPAIGN_STATE = {
  isLoading: true,
  designLoading: true,
  isEdit: false,
  editCampaignId: '',
  isCustom: true,
  initPendingStep: '',
  subCategory: '',
  reminders: [],
  campaignStatus: 'draft',
  isEnableActiveStatus: false,
  isEnableNameEdit: false,
  campaignName: '',
  activeTemplateId: '',
  campaignLabel: LABEL_CAMPAIGN_NAME,
  basicDetail: { ...INIT_CAMPAIGN_BASIC_DETAIL },
  audienceSegment: [],
  isOpenSchedule: false,
  hasAuthDisable: false,
  timezones: [],
  schedule: { ...INIT_SCHEDULE_CAMPAIGN },
  senderDetail: { ...INIT_CAMPAIGN_SENDER_DETAIL },
};

const INIT_GROUP_STATE = {
  apiCallDone: false,
  currentPage: 1,
  totalCount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  isSelectAll: '',
  error: '',
  options: [],
  apiDone: true,
  apiPeopleDone: false,
  peopleCount: '',
};

const CAMPAIGN_PLAN_ACCESS = [
  PricingPlan.PRO,
  PricingPlan.ENTERPRISE,
  PricingPlan.WEBINAR,
  PricingPlan.DIGITAL_EVENTS,
  PricingPlan.ALL_IN,
];

const CAMPAIGN_SLOT_OPTIONS = [
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'ONE_WEEK',
    label: '1 week before the event starts',
    deduct: moment.duration(1, 'week').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'TWO_DAYS',
    label: '2 days before the event starts',
    deduct: moment.duration(2, 'days').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'ONE_DAY',
    label: '1 day before the event starts',
    deduct: moment.duration(1, 'day').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'FOUR_HOURS',
    label: '4 hrs before the event starts',
    deduct: moment.duration(4, 'hours').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'ONE_HOUR',
    label: '1 hr before the event starts',
    deduct: moment.duration(1, 'hour').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'FIFTEEN_MINUTES',
    label: '15 mins before the event starts',
    deduct: moment.duration(15, 'minutes').asMilliseconds(),
  },
  {
    id: '',
    showJoinLink: true,
    delayTimestamp: 0,
    isDisable: false,
    sid: 'CUSTOM',
    label: 'Custom Date & Time',
  },
];

const CAMPAIGN_MERGE_TAGS = [
  { id: "EVENT_NAME", isCopied: false, name: "Event Name", tag: "{{event_name}}" },
  { id: "EVENT_DATE_TIME", isCopied: false, name: "Date and Time of event", tag: "{{event_datetime}}" },
  { id: "EVENT_START_DATE", isCopied: false, name: "Start date of event", tag: "{{event_start_date}}" },
  { id: "EVENT_START_COUNTDOWN", isCopied: false, name: "Time left till event start", tag: "{{time_to_event_start}}" },
  { id: "ATTENDEE_NAME", isCopied: false, name: "Attendee’s full name", tag: "{{attendee_name}}" },
  { id: "ATTENDEE_FIRST_NAME", isCopied: false, name: "Attendee’s first name", tag: "{{attendee_firstname}}" },
  { id: "ATTENDEE_LAST_NAME", isCopied: false, name: "Attendee’s last name", tag: "{{attendee_lastname}}" },
  { id: "ATTENDEE_ORGANISATION_NAME", isCopied: false, name: "Attendee’s organisation", tag: "{{attendee_organisation}}" },
  { id: "ATTENDEE_DESIGNATION_NAME", isCopied: false, name: "Attendee’s designation", tag: "{{attendee_designation}}" },
];

const CAMPAIGN_ADD_SLOT_IDS = ['ONE_DAY', 'ONE_HOUR', 'FIFTEEN_MINUTES', 'CUSTOM'];

const CAMPAIGN_PLAN_ACCESS_P_E_A = [PricingPlan.PRO, PricingPlan.ENTERPRISE, PricingPlan.ADVANCED];

const CAMPAIGN_PLANS = [PricingPlan.PRO, PricingPlan.ADVANCED, PricingPlan.ENTERPRISE];

const CUSTOM_URL = projectSetting.customBaseURL5;
const UNLAYER_IMAGE_PATH = 'https://api.unlayer.com/v2/export/image';
const TEMPLATE_MERGE_TAG_LINK = 'https://support.hubilo.com/hc/en-us/articles/9879735828493-Email-Marketing-Engine-Create-and-manage-all-your-event-mails-at-one-place';
const TEMPLATE_MAGIC_LINK = 'https://support.hubilo.com/hc/en-us/search?utf8=%E2%9C%93&query=email+manager';

// Unlayer Tools Link
const CUSTOM_TOOL_BANNER = 'https://cdn.hubilo.com/email-builder/tools/banner-custom-tool.js';
const CUSTOM_TOOL_BOOTH = 'https://cdn.hubilo.com/email-builder/tools/booth-custom-tool.js';
const CUSTOM_TOOL_LOGO = 'https://cdn.hubilo.com/email-builder/tools/logo-custom-tool.js';
const CUSTOM_TOOL_SESSION = 'https://cdn.hubilo.com/email-builder/tools/session-custom-tool.js';
const CUSTOM_TOOL_SPEAKER = 'https://cdn.hubilo.com/email-builder/tools/speaker-custom-tool.js';

export {
  localLog,
  labels,
  schemaCampaignType,
  schemaCampaignStatus,
  schemaSuggestions,
  schemaAudienceItem,
  schemaAudience,
  readOnlySchema,
  styleCampaignStatus,
  CAMPAIGN_PLANS,
  CAMPAIGN_PLAN_ACCESS,
  campaignNameElementId,
  templateNameElementId,
  campaignStateOption,
  campaignSteps,
  allCampaignSteps,
  CAMPAIGN_SUB_TYPE,
  CUSTOM_URL,
  UNLAYER_IMAGE_PATH,
  LABEL_CAMPAIGN_NAME,
  LABEL_CAMPAIGN_TYPE,
  LABEL_BASIC_DETAIL,
  LABEL_TITLE_EDIT_CONFIRM_CREATE,
  LABEL_DESC_EDIT_CONFIRM_CREATE,
  LABEL_TITLE_EDIT_CONFIRM_UPDATE,
  LABEL_DESC_EDIT_CONFIRM_UPDATE,
  LABEL_EDIT_CONFIRM_BTN_CREATE,
  LABEL_EDIT_CONFIRM_BTN_UPDATE,
  LABEL_EDIT_CONFIRM_BTN_CANCEL,
  LABEL_CAMPAIGN_ACTIVE,
  LABEL_CAMPAIGN_PAUSED,
  LABEL_SCHEDULE_TITLE,
  LABEL_SCHEDULE_DATE_TIME,
  LABEL_SCHEDULE_TIMEZONE,
  LABEL_DEACTIVATE_TITLE,
  LABEL_DEACTIVATE_DESCRIPTION,
  LABEL_ACTIVATE_BTN,
  LABEL_DEACTIVATE_BTN,
  LABEL_SEND_TITLE,
  LABEL_DELETE_CAMPAIGN,
  LABEL_DESC_DELETE_CAMPAIGN,
  LABEL_TEST_TITLE,
  LABEL_TEST_DESC,
  LABEL_EMAIL_LIST_TITLE,
  INIT_CAMPAIGN_BASIC_DETAIL,
  INIT_CAMPAIGN_SENDER_DETAIL,
  INIT_CAMPAIGN_AUDIENCE,
  INIT_SCHEDULE_CAMPAIGN,
  INIT_CAMPAIGN_STATE,
  INIT_GROUP_STATE,
  ERROR_TIME_ZONE,
  ERROR_EMPTY_DATE_TIME,
  ERROR_PAST_DATE_TIME,
  ERROR_INVALID_DATE_TIME,
  ERROR_CAMPAIGN_CREATE,
  ERROR_CAMPAIGN_MODIFY,
  ERROR_CAMPAIGN_EMPTY,
  ERROR_CHAR_LIMIT,
  ERROR_EVENT_STATE,
  ERROR_CAMPAIGN_PAST_TRIGGER,
  ERROR_CAMPAIGN_FUTURE_TRIGGER,
  ERROR_SLOT_PAST_SELECTION,
  FAIL_CAMPAIGN_SLOT_SAVE,
  SUCCESS_CAMPAIGN_SLOT_SAVE,
  CAMPAIGN_CHAR_LIMIT,
  ERROR_SLOT_GENERIC,
  ERROR_TEMPLATE_GENERIC,
  LABEL_TEMPLATE_NAME,
  CUSTOM_TOOL_BANNER,
  CUSTOM_TOOL_BOOTH,
  CUSTOM_TOOL_LOGO,
  CUSTOM_TOOL_SESSION,
  CUSTOM_TOOL_SPEAKER,
  CAMPAIGN_PLAN_ACCESS_P_E_A,
  CAMPAIGN_SLOT_OPTIONS,
  CAMPAIGN_ADD_SLOT_IDS,
  CAMPAIGN_MERGE_TAGS,
  TEMPLATE_MERGE_TAG_LINK,
  TEMPLATE_MAGIC_LINK,
};

export const SAMPLE_CREATE_DATA = {
  isLoading: false,
  isEdit: false,
  editCampaignId: '',
  isCustom: true,
  campaignStatus: 'draft',
  campaignName: '',
  basicDetail: {
    eventState: '',
    emailSubject: '',
    emailDescription: '',
  },
};

export const IS_PHASE_2 = true;

export const defaultTheme = {
  primaryColor: '#FFFFFF',
  primaryFontColor: '#202020',
  secondaryFontColor: '#F1F1F1',
  accentColor: '#E67E23',
  mainBackgroundColor: '#F4F4F4',
};

export const defaultThemeId = {
  primaryColor: '{{PRM_CLR}}',
  primaryFontColor: '{{PRM_FNT_CLR}}',
  secondaryFontColor: '{{SEC_FNT_CLR}}',
  accentColor: '{{ASNT_CLR}}',
  mainBackgroundColor: '{{BG_CLR}}',
};
