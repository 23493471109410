/* eslint-disable no-unused-vars */
/* eslint-disable */
import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import SvgUpgrade from 'icons/svgUpgrade';
import { useHistory } from 'react-router-dom';
import { PricingPlan } from 'constants/pricingPlan';
import { userInfoSelector } from 'redux/custom-selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  upgradePlanBlock: {
    display: 'block',
    background: theme.palette.primary.lighter,
    padding: `${theme.spacing(3)}px ${theme.spacing(3.75)}px`,
    textAlign: 'center',
    borderRadius: 8,
    '& .proAndEnterprise': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& p': {
        background: '#ffffff',
        padding: `${theme.spacing(1)}px  ${theme.spacing(1.5)}px `,
        color: '#202020',
        borderRadius: 25,
        margin: `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px ${theme.spacing(
          3
        )}px ${theme.spacing(0.5)}px`,
        fontWeight: 400,
      },
    },
  },
}));
export default function copyEventRestrictionScreen() {
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const isPlanlessUser = userInfo.UserInfo.subscriptionStatus === undefined;
  const isWebinar = userInfo.UserInfo.planName === PricingPlan.WEBINAR;

  return (
    <>
      {!isWebinar ? (
        <Box className={classes.upgradePlanBlock}>
          <Typography variant="subtitle2" component="p" align="center">
            This feature is included in the following plans
          </Typography>
          <Box className="proAndEnterprise">
            {isPlanlessUser ? (
              <>
                <Typography variant="subtitle2" component="p">
                  Digital Events
                </Typography>
                <Typography variant="subtitle2" component="p">
                  All-in
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="subtitle2" component="p">
                  Pro Plan
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Enterprise Plan
                </Typography>
              </>
            )}
          </Box>
          <SvgUpgrade />
          <Box sx={{ my: 3 }}>
            <Typography variant="body2" component="p" align="center">
              Multi Lingual support will allow you to host an event in multiple language and help
              the attendees joining from all areas of the globe. Upgrade the plan to unlock the
              benefits.
              <Box
                component="a"
                variant="body2"
                ml={0.3}
                className={'text-underline  cursor-pointer'}>
                Learn More
              </Box>
            </Typography>
          </Box>
          {isPlanlessUser ? (
            <>
              <Typography variant="subtitle" component="p">
                To enable Multilingual feature talk to our sales team
              </Typography>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                history.push('/plans');
              }}>
              Upgrade
            </Button>
          )}
        </Box>
      ) : null}
    </>
  );
}
