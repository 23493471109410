import React from 'react';

export default function SvgKoreanFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g>
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill="#F0F0F0"
        />
        <path
          d="M9.99994 0C6.96904 0 4.2533 1.34859 2.41943 3.47824H17.5804C15.7466 1.34859 13.0308 0 9.99994 0Z"
          fill="#0052B4"
        />
        <path
          d="M17.5805 16.5217H2.41943C4.2533 18.6514 6.96904 20 9.99994 20C13.0308 20 15.7466 18.6514 17.5805 16.5217Z"
          fill="#0052B4"
        />
        <path
          d="M18.5323 4.78262H1.46773C0.536914 6.30156 0 8.08801 0 10C0 11.912 0.536914 13.6985 1.46773 15.2174H18.5323C19.4631 13.6985 20 11.912 20 10C20 8.08801 19.4631 6.30156 18.5323 4.78262Z"
          fill="#D80027"
        />
        <path
          d="M6.15213 13.8478C8.27722 13.8478 9.99995 12.1251 9.99995 10C9.99995 7.87494 8.27722 6.15221 6.15213 6.15221C4.02705 6.15221 2.30432 7.87494 2.30432 10C2.30432 12.1251 4.02705 13.8478 6.15213 13.8478Z"
          fill="#F0F0F0"
        />
        <path
          d="M6.1522 6.15221L7.01552 8.80928H9.81388L7.54916 10.4515L8.41923 13.1165L6.1522 11.4665L3.88861 13.1135L4.75525 10.4515L2.49255 8.80928H5.28884L6.1522 6.15221Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
