// TODO : this file is keep as hardcoded configuration value for cookie policy consent
// TODO : PRD Link : https://hubilo.atlassian.net/wiki/spaces/PROD/pages/137429047/Hubilo+Cookie+Policy+-+Dashboard+and+Community+Portal
// TODO : we commented marketing and personalization because there is no application yet

export const classification = {
  MARKETING: 'Marketing',
  PERSONALIZATION: 'Personalization',
  ANALYTICS: 'Analytics',
  ESSENTIAL: 'Essential',
};

export const mandatoryPref = {
  id: 4,
  application: [{ isHubilo: true, isBeamer: true, isIntercom: true }],
  classification: classification.ESSENTIAL,
  desc: 'We use essential cookies that are necessary for the site to work as intended. This is enabled by default to ensure basic website functionality. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.',
};

export const dashboardCookies = [
  mandatoryPref,
  // {
  //   id: 1,
  //   application: '',
  //   classification: classification.MARKETING,
  //   desc: `Marketing cookies are used to display ads that are more relevant to you and your interests. It may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Advertising networks usually place them with the website operator's permission. If you do not allow these cookies, you may experience less relevant advertising.`,
  // },
  // {
  //   id: 2,
  //   application: '',
  //   classification: classification.PERSONALIZATION,
  //   desc: `Personalisation cookies enable the site to provide improved functionality and personalisation. It might be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.`,
  // },{

  {
    id: 3,
    application: [{ isAmplitude: true, isGoogle: true }],
    classification: classification.ANALYTICS,
    desc: `Analytics cookies are used to help the website operator know how the site performs, how visitors interact with the site, and whether there may be technical issues. This storage type usually doesn't collect information that identifies a visitor.`,
  },
];

export const cookieConsentConfigOptionsForBanner = [
  { label: 'Banner Top', value: 'top' },
  { label: 'Banner Bottom', value: 'bottom' },
];

export const cookieConsentConfigOptionsForBox = [
  { label: 'Floating top-left', value: 'topLeft' },
  { label: 'Floating top-right', value: 'topRight' },
  { label: 'Floating bottom-left', value: 'bottomLeft' },
  { label: 'Floating bottom-right', value: 'bottomRight' },
  { label: 'Floating center', value: 'center' },
];

export const LOCAL_STORAGE_KEY = {
  COOKIE_CONSENT_DATA: 'cookieConsentData',
  SENDER_EMAIL_UPDATE_REQUEST_REJECTED: 'senderEmailUpdateRequestRejected', // stores array of rejected request ids
  GEN_BROWSER_SIGNATURE: 'browserSignature',
  GET_BROWSER_SIGNATURE: 'browserSignature_get',
  SAVE_BROWSER_SIGNATURE: 'browserSignature_save',
};

export const COOKIE_EXPIRE_TIME = 5000 * 60; // in min
export const IS_ENABLE_SAVE_API_COOKIE_CONSENT = false; // Boolean Api if in future save api call required so need to pass true only
