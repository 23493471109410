import React from 'react';

const style = {
  a: {
    fill: '#c1c1c1',
    stroke: '#c1c1c1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2px',
  },
};

function SvgNoRoomsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="368" height="304" viewBox="0 0 368 304">
      <path
        d="M167.957 302.356C240.086 302.356 298.558 298.252 298.558 293.189C298.558 288.125 240.086 284.021 167.957 284.021C95.8279 284.021 37.356 288.125 37.356 293.189C37.356 298.252 95.8279 302.356 167.957 302.356Z"
        fill="#F2F2F2"
      />
      <path d="M303.425 278.812H276.602V279.015H303.425V278.812Z" fill="#F2F2F2" />
      <path d="M207.308 281.016H200.271V281.219H207.308V281.016Z" fill="#F2F2F2" />
      <path d="M275.792 271.297H260.25V271.5H275.792V271.297Z" fill="#F2F2F2" />
      <path
        d="M302.896 229.606H32.0101C29.8868 229.603 27.8516 229.115 26.3502 228.248C24.8488 227.381 24.0037 226.205 24 224.978V5.01056C24.0221 3.79099 24.8754 2.6256 26.3748 1.76699C27.8742 0.908385 29.8989 0.425741 32.0101 0.423676H302.896C305.004 0.427893 307.023 0.91174 308.517 1.77034C310.011 2.62895 310.86 3.79313 310.878 5.01056V224.978C310.878 226.203 310.038 227.377 308.542 228.245C307.046 229.112 305.016 229.601 302.896 229.606ZM32.0101 0.585757C29.9799 0.587899 28.0338 1.05477 26.5982 1.88412C25.1626 2.71346 24.3544 3.83768 24.3507 5.01056V224.978C24.3544 226.151 25.1626 227.275 26.5982 228.105C28.0338 228.934 29.9799 229.401 32.0101 229.403H302.896C304.926 229.401 306.872 228.934 308.308 228.105C309.744 227.275 310.552 226.151 310.555 224.978V5.01056C310.552 3.83768 309.744 2.71346 308.308 1.88412C306.872 1.05477 304.926 0.587899 302.896 0.585757H32.0101Z"
        fill="#E0E0E0"
      />
      <path
        d="M93.0083 216.066V89.4383H154.926V216.066H93.0083Z"
        fill="#F2F2F2"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path
        d="M95.3649 92.076H152.577V213.423H95.3649V92.076Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path d="M124.434 91.2417H123.366V213.045H124.434V91.2417Z" fill="#F2F2F2" />
      <path
        d="M91.5674 93.7228V87.6061H156.368V93.7228H91.5674Z"
        fill="#F2F2F2"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path d="M156.618 94.71H91.3174V95.4956H156.618V94.71Z" fill="#F2F2F2" />
      <path d="M156.618 158.553H91.3174V159.339H156.618V158.553Z" fill="#F2F2F2" />
      <path d="M156.618 126.493H91.3174V127.278H156.618V126.493Z" fill="#F2F2F2" />
      <path d="M156.618 190.336H91.3174V191.122H156.618V190.336Z" fill="#F2F2F2" />
      <path d="M156.618 96.4838H91.3174V97.2693H156.618V96.4838Z" fill="#F2F2F2" />
      <path d="M156.618 160.327H91.3174V161.113H156.618V160.327Z" fill="#F2F2F2" />
      <path d="M156.618 128.266H91.3174V129.052H156.618V128.266Z" fill="#F2F2F2" />
      <path d="M156.618 192.11H91.3174V192.895H156.618V192.11Z" fill="#F2F2F2" />
      <path d="M156.618 98.2659H91.3174V99.0515H156.618V98.2659Z" fill="#F2F2F2" />
      <path d="M156.618 162.109H91.3174V162.895H156.618V162.109Z" fill="#F2F2F2" />
      <path d="M156.618 130.048H91.3174V130.834H156.618V130.048Z" fill="#F2F2F2" />
      <path d="M156.618 193.892H91.3174V194.677H156.618V193.892Z" fill="#F2F2F2" />
      <path d="M156.618 100.048H91.3174V100.834H156.618V100.048Z" fill="#F2F2F2" />
      <path d="M156.618 163.891H91.3174V164.677H156.618V163.891Z" fill="#F2F2F2" />
      <path d="M156.618 131.831H91.3174V132.616H156.618V131.831Z" fill="#F2F2F2" />
      <path d="M156.618 195.674H91.3174V196.46H156.618V195.674Z" fill="#F2F2F2" />
      <path d="M156.618 101.83H91.3174V102.616H156.618V101.83Z" fill="#F2F2F2" />
      <path d="M156.618 165.674H91.3174V166.459H156.618V165.674Z" fill="#F2F2F2" />
      <path d="M156.618 133.613H91.3174V134.398H156.618V133.613Z" fill="#F2F2F2" />
      <path d="M156.618 197.456H91.3174V198.242H156.618V197.456Z" fill="#F2F2F2" />
      <path d="M156.618 103.61H91.3174V104.396H156.618V103.61Z" fill="#F2F2F2" />
      <path d="M156.618 167.454H91.3174V168.239H156.618V167.454Z" fill="#F2F2F2" />
      <path d="M156.618 135.393H91.3174V136.178H156.618V135.393Z" fill="#F2F2F2" />
      <path d="M156.618 199.236H91.3174V200.022H156.618V199.236Z" fill="#F2F2F2" />
      <path d="M156.618 105.384H91.3174V106.17H156.618V105.384Z" fill="#F2F2F2" />
      <path d="M156.618 169.227H91.3174V170.013H156.618V169.227Z" fill="#F2F2F2" />
      <path d="M156.618 137.167H91.3174V137.952H156.618V137.167Z" fill="#F2F2F2" />
      <path d="M156.618 201.01H91.3174V201.796H156.618V201.01Z" fill="#F2F2F2" />
      <path d="M156.618 107.166H91.3174V107.952H156.618V107.166Z" fill="#F2F2F2" />
      <path d="M156.618 171.01H91.3174V171.795H156.618V171.01Z" fill="#F2F2F2" />
      <path d="M156.618 138.949H91.3174V139.734H156.618V138.949Z" fill="#F2F2F2" />
      <path d="M156.618 202.792H91.3174V203.578H156.618V202.792Z" fill="#F2F2F2" />
      <path d="M156.618 108.948H91.3174V109.734H156.618V108.948Z" fill="#F2F2F2" />
      <path d="M156.618 172.792H91.3174V173.577H156.618V172.792Z" fill="#F2F2F2" />
      <path d="M156.618 140.731H91.3174V141.516H156.618V140.731Z" fill="#F2F2F2" />
      <path d="M156.618 204.574H91.3174V205.36H156.618V204.574Z" fill="#F2F2F2" />
      <path d="M156.618 110.73H91.3174V111.516H156.618V110.73Z" fill="#F2F2F2" />
      <path d="M156.618 174.574H91.3174V175.359H156.618V174.574Z" fill="#F2F2F2" />
      <path d="M156.618 142.513H91.3174V143.299H156.618V142.513Z" fill="#F2F2F2" />
      <path d="M156.618 206.356H91.3174V207.142H156.618V206.356Z" fill="#F2F2F2" />
      <path d="M156.618 112.51H91.3174V113.296H156.618V112.51Z" fill="#F2F2F2" />
      <path d="M156.618 176.354H91.3174V177.139H156.618V176.354Z" fill="#F2F2F2" />
      <path d="M156.618 144.293H91.3174V145.079H156.618V144.293Z" fill="#F2F2F2" />
      <path d="M156.618 208.136H91.3174V208.922H156.618V208.136Z" fill="#F2F2F2" />
      <path d="M156.618 114.293H91.3174V115.078H156.618V114.293Z" fill="#F2F2F2" />
      <path d="M156.618 178.136H91.3174V178.922H156.618V178.136Z" fill="#F2F2F2" />
      <path d="M156.618 146.075H91.3174V146.861H156.618V146.075Z" fill="#F2F2F2" />
      <path d="M156.618 209.919H91.3174V210.704H156.618V209.919Z" fill="#F2F2F2" />
      <path d="M156.618 116.066H91.3174V116.852H156.618V116.066Z" fill="#F2F2F2" />
      <path d="M156.618 179.91H91.3174V180.695H156.618V179.91Z" fill="#F2F2F2" />
      <path d="M156.618 147.849H91.3174V148.635H156.618V147.849Z" fill="#F2F2F2" />
      <path d="M156.618 211.692H91.3174V212.478H156.618V211.692Z" fill="#F2F2F2" />
      <path d="M156.618 117.849H91.3174V118.634H156.618V117.849Z" fill="#F2F2F2" />
      <path d="M156.618 181.692H91.3174V182.478H156.618V181.692Z" fill="#F2F2F2" />
      <path d="M156.618 149.631H91.3174V150.417H156.618V149.631Z" fill="#F2F2F2" />
      <path d="M156.618 213.474H91.3174V214.26H156.618V213.474Z" fill="#F2F2F2" />
      <path d="M156.618 119.631H91.3174V120.416H156.618V119.631Z" fill="#F2F2F2" />
      <path d="M156.618 183.474H91.3174V184.26H156.618V183.474Z" fill="#F2F2F2" />
      <path d="M156.618 151.413H91.3174V152.199H156.618V151.413Z" fill="#F2F2F2" />
      <path d="M156.618 215.257H91.3174V216.042H156.618V215.257Z" fill="#F2F2F2" />
      <path d="M156.618 121.413H91.3174V122.198H156.618V121.413Z" fill="#F2F2F2" />
      <path d="M156.618 185.256H91.3174V186.042H156.618V185.256Z" fill="#F2F2F2" />
      <path d="M156.618 153.195H91.3174V153.981H156.618V153.195Z" fill="#F2F2F2" />
      <path d="M156.618 217.039H91.3174V217.824H156.618V217.039Z" fill="#F2F2F2" />
      <path d="M156.618 123.193H91.3174V123.978H156.618V123.193Z" fill="#F2F2F2" />
      <path d="M156.618 187.036H91.3174V187.822H156.618V187.036Z" fill="#F2F2F2" />
      <path d="M156.618 154.975H91.3174V155.761H156.618V154.975Z" fill="#F2F2F2" />
      <path d="M156.618 218.819H91.3174V219.604H156.618V218.819Z" fill="#F2F2F2" />
      <path d="M156.618 124.969H91.3174V125.754H156.618V124.969Z" fill="#F2F2F2" />
      <path d="M156.618 188.812H91.3174V189.598H156.618V188.812Z" fill="#F2F2F2" />
      <path d="M156.618 156.751H91.3174V157.537H156.618V156.751Z" fill="#F2F2F2" />
      <path d="M156.618 220.595H91.3174V221.38H156.618V220.595Z" fill="#F2F2F2" />
      <path
        d="M102.194 236.95H158.351C159.174 236.95 159.963 237.277 160.544 237.859C161.126 238.44 161.453 239.229 161.453 240.052V246.15H99.0925V240.052C99.0925 239.644 99.1727 239.241 99.3286 238.865C99.4844 238.489 99.7129 238.147 100.001 237.859C100.289 237.571 100.631 237.342 101.007 237.186C101.383 237.03 101.787 236.95 102.194 236.95Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
        strokeWidth="0.6"
      />
      <path
        opacity="0.6"
        d="M102.194 236.95H118.392C119.214 236.95 120.003 237.277 120.585 237.859C121.166 238.44 121.493 239.229 121.493 240.052V246.15H99.0925V240.052C99.0925 239.644 99.1727 239.241 99.3286 238.865C99.4844 238.489 99.7129 238.147 100.001 237.859C100.289 237.571 100.631 237.342 101.007 237.186C101.383 237.03 101.787 236.95 102.194 236.95Z"
        fill="#F2F2F2"
        stroke="#9E9E9E"
        strokeWidth="0.6"
      />
      <path
        d="M141.749 291.732H140.355L139.106 246.166H141.749V291.732Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path
        d="M101.564 246.166L100.322 291.732H98.9204V246.166H101.564Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path
        d="M160.911 291.732H159.509L158.268 246.166H160.911V291.732Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
        strokeWidth="0.5"
      />
      <path
        d="M98.0308 246.216H163.275C163.583 246.216 163.834 246.466 163.834 246.774V250.062C163.834 250.371 163.583 250.621 163.275 250.621H98.0308C97.7224 250.621 97.4724 250.371 97.4724 250.062V246.774C97.4724 246.466 97.7224 246.216 98.0308 246.216Z"
        fill="#F2F2F2"
        stroke="#9E9E9E"
        strokeWidth="0.6"
      />
      <path
        d="M62.0431 269.013C62.0431 269.013 63.2903 259.886 69.1296 258.882C74.9688 257.878 73.179 253.472 74.7259 251.763C76.2727 250.054 80.2169 255.092 76.8153 259.975C73.4138 264.859 76.1917 263.328 74.7259 267.872C73.26 272.415 61.5085 276.586 62.0431 269.013Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.2"
        d="M62.0431 269.013C62.0431 269.013 63.2903 259.886 69.1296 258.882C74.9688 257.878 73.179 253.472 74.7259 251.763C76.2727 250.054 80.2169 255.092 76.8153 259.975C73.4138 264.859 76.1917 263.328 74.7259 267.872C73.26 272.415 61.5085 276.586 62.0431 269.013Z"
        fill="#CCCCCC"
      />
      <path
        d="M63.9793 275.25H63.8335C63.7349 275.209 63.6563 275.131 63.614 275.033C63.5717 274.935 63.5691 274.824 63.6068 274.724C63.6068 274.627 67.235 265.005 63.7525 250.427C62.6696 246.232 61.1523 242.16 59.2253 238.279C55.8481 230.99 52.9406 224.641 57.2573 212.841C58.5855 209.188 59.9704 205.787 61.3067 202.49C66.2551 190.342 70.1587 180.737 66.0364 171.853C65.9943 171.753 65.9919 171.642 66.0296 171.541C66.0672 171.44 66.1421 171.357 66.2389 171.31C66.3366 171.266 66.4478 171.263 66.548 171.301C66.6481 171.339 66.7292 171.415 66.7734 171.512C71.0415 180.713 67.0812 190.464 62.0599 202.798C60.7155 206.086 59.3387 209.48 58.0105 213.116C53.8072 224.6 56.5203 230.488 59.9461 237.939C61.9041 241.85 63.4511 245.954 64.5624 250.185C68.1097 265.078 64.4247 274.894 64.3842 274.991C64.3522 275.071 64.296 275.139 64.2234 275.186C64.1507 275.232 64.0654 275.255 63.9793 275.25Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.655 273.12C65.655 273.12 69.429 267.451 63.9785 264.503C58.528 261.555 57.4428 263.936 54.0736 261.831C50.7045 259.725 48.1453 254.639 47.5946 257.895C47.0439 261.15 50.9151 263.013 49.5383 264.503C48.1615 265.993 58.6738 275.68 65.655 273.12Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.3849 176.209C68.3849 176.209 71.1628 164.774 62.8048 165.203C54.4469 165.632 64.4732 175.327 68.3849 176.209Z"
        fill="#F2F2F2"
      />
      <path
        d="M61.8331 185.353C61.8331 185.353 58.5369 175.424 52.7948 178.064C47.0527 180.704 55.9533 186.009 61.8331 185.353Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.9398 185.426C71.9398 185.426 70.401 177.408 76.6695 176.744C82.938 176.079 80.7108 183.409 71.9398 185.426Z"
        fill="#F2F2F2"
      />
      <path
        d="M69.0894 202.288C69.0894 202.288 76.9128 194.1 80.4277 199.453C83.9426 204.806 74.5723 206.677 69.0894 202.288Z"
        fill="#F2F2F2"
      />
      <path
        d="M54.074 202.11C54.074 202.11 51.4904 189.759 44.9952 194.189C38.4999 198.619 48.2428 203.835 54.074 202.11Z"
        fill="#F2F2F2"
      />
      <path
        d="M49.5145 220.454C49.5145 220.454 53.5639 207.447 44.3069 209.618C35.05 211.788 42.6953 217.927 49.5145 220.454Z"
        fill="#F2F2F2"
      />
      <path
        d="M64.2056 223.806C64.2056 223.806 71.3406 213.885 77.6091 216.347C83.8776 218.809 79.6824 228.439 64.2056 223.806Z"
        fill="#F2F2F2"
      />
      <path
        d="M58.6984 244.96C58.6984 244.96 50.7939 230.585 45.2138 236.092C39.6337 241.599 50.0245 248.329 58.6984 244.96Z"
        fill="#F2F2F2"
      />
      <path
        d="M68.3849 243.308C68.3849 243.308 65.688 231.872 72.4343 232.277C79.1806 232.682 77.9577 239.89 68.3849 243.308Z"
        fill="#F2F2F2"
      />
      <path
        d="M68.3849 176.209C68.3849 176.209 71.1628 164.774 62.8048 165.203C54.4469 165.632 64.4732 175.327 68.3849 176.209Z"
        fill="#CCCCCC"
      />
      <path
        d="M61.8331 185.353C61.8331 185.353 58.5369 175.424 52.7948 178.064C47.0527 180.704 55.9533 186.009 61.8331 185.353Z"
        fill="#CCCCCC"
      />
      <path
        d="M71.9398 185.426C71.9398 185.426 70.401 177.408 76.6695 176.744C82.938 176.079 80.7108 183.409 71.9398 185.426Z"
        fill="#CCCCCC"
      />
      <path
        d="M69.0894 202.288C69.0894 202.288 76.9128 194.1 80.4277 199.453C83.9426 204.806 74.5723 206.677 69.0894 202.288Z"
        fill="#CCCCCC"
      />
      <path
        d="M54.074 202.11C54.074 202.11 51.4904 189.759 44.9952 194.189C38.4999 198.619 48.2428 203.835 54.074 202.11Z"
        fill="#CCCCCC"
      />
      <path
        d="M49.5145 220.454C49.5145 220.454 53.5639 207.447 44.3069 209.618C35.05 211.788 42.6953 217.927 49.5145 220.454Z"
        fill="#CCCCCC"
      />
      <path
        d="M64.2056 223.806C64.2056 223.806 71.3406 213.885 77.6091 216.347C83.8776 218.809 79.6824 228.439 64.2056 223.806Z"
        fill="#CCCCCC"
      />
      <path
        d="M58.6984 244.96C58.6984 244.96 50.7939 230.585 45.2138 236.092C39.6337 241.599 50.0245 248.329 58.6984 244.96Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.3849 243.308C68.3849 243.308 65.688 231.872 72.4343 232.277C79.1806 232.682 77.9577 239.89 68.3849 243.308Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.9709 191.759H65.8899C65.7838 191.735 65.6909 191.671 65.6308 191.581C65.6308 191.516 61.9134 185.661 59.3947 184.405C59.3407 184.386 59.2913 184.356 59.2499 184.316C59.2085 184.277 59.1759 184.229 59.1543 184.176C59.1328 184.123 59.1227 184.066 59.1248 184.009C59.1269 183.951 59.1411 183.895 59.1665 183.844C59.1918 183.793 59.2278 183.747 59.272 183.711C59.3162 183.674 59.3676 183.648 59.4229 183.633C59.4781 183.618 59.5359 183.615 59.5925 183.623C59.6491 183.632 59.7031 183.653 59.751 183.684C62.124 184.859 65.2258 189.459 66.0924 190.795C70.4577 187.702 73.438 182.462 73.4704 182.405C73.4957 182.358 73.5303 182.316 73.572 182.283C73.6137 182.25 73.6616 182.225 73.7131 182.211C73.7645 182.196 73.8183 182.193 73.8712 182.199C73.9241 182.206 73.9751 182.224 74.0212 182.251C74.1129 182.305 74.1801 182.393 74.2088 182.495C74.2375 182.598 74.2254 182.708 74.175 182.802C74.0455 183.037 70.9355 188.544 66.1977 191.71C66.1279 191.747 66.0495 191.764 65.9709 191.759Z"
        fill="#CCCCCC"
      />
      <path
        d="M59.5728 207.86H59.5242C59.4677 207.853 59.4132 207.834 59.3643 207.805C59.3155 207.776 59.2734 207.737 59.2408 207.69C59.2003 207.625 54.6811 201.276 49.3359 198.676C49.2392 198.629 49.1653 198.545 49.1304 198.443C49.0954 198.341 49.1024 198.23 49.1496 198.133C49.1969 198.037 49.2806 197.963 49.3824 197.928C49.4841 197.893 49.5956 197.9 49.6922 197.947C54.5515 200.304 58.601 205.438 59.6376 206.856C66.4163 201.073 75.3898 200.579 75.4789 200.571C75.5326 200.567 75.5867 200.573 75.6376 200.591C75.6886 200.608 75.7354 200.636 75.775 200.673C75.8146 200.709 75.8462 200.754 75.8677 200.803C75.8892 200.852 75.9002 200.906 75.9001 200.96C75.9033 201.013 75.896 201.065 75.8786 201.115C75.8611 201.165 75.8338 201.211 75.7984 201.25C75.7629 201.29 75.72 201.321 75.6721 201.344C75.6242 201.366 75.5723 201.379 75.5194 201.381C75.4303 201.381 66.4325 201.883 59.8401 207.755C59.7668 207.821 59.6718 207.859 59.5728 207.86Z"
        fill="#CCCCCC"
      />
      <path
        d="M56.3497 228.706C56.2691 228.71 56.1895 228.687 56.1229 228.641C50.7696 225.013 46.1127 215.295 45.9184 214.873C45.8951 214.825 45.8818 214.773 45.879 214.719C45.8762 214.666 45.8841 214.612 45.9021 214.561C45.9202 214.511 45.9481 214.465 45.9843 214.425C46.0204 214.385 46.0641 214.353 46.1127 214.331C46.2107 214.286 46.3222 214.281 46.4236 214.318C46.5249 214.354 46.6081 214.428 46.6554 214.525C46.6554 214.622 51.2717 224.171 56.374 227.807C57.9451 226.746 66.7486 221.004 73.8027 220.3C73.8558 220.294 73.9095 220.299 73.9607 220.315C74.0119 220.33 74.0595 220.356 74.1008 220.39C74.1422 220.423 74.1765 220.465 74.2018 220.512C74.227 220.559 74.2428 220.611 74.2481 220.664C74.2534 220.717 74.2482 220.771 74.2328 220.822C74.2173 220.873 74.192 220.921 74.1581 220.962C74.1243 221.004 74.0826 221.038 74.0355 221.063C73.9884 221.088 73.9368 221.104 73.8836 221.109C66.4813 221.855 56.6979 228.569 56.6007 228.641C56.5255 228.688 56.438 228.71 56.3497 228.706Z"
        fill="#CCCCCC"
      />
      <path
        d="M63.9787 249.382C63.9207 249.382 63.8634 249.369 63.8104 249.346C63.7575 249.322 63.71 249.287 63.6709 249.245C60.5199 246.001 56.454 243.798 52.0167 242.927C51.9636 242.916 51.9131 242.895 51.8682 242.864C51.8234 242.833 51.785 242.794 51.7553 242.749C51.7256 242.703 51.7051 242.652 51.6951 242.599C51.6851 242.546 51.6857 242.491 51.6968 242.437C51.708 242.384 51.7295 242.334 51.7602 242.289C51.7909 242.244 51.8301 242.206 51.8756 242.176C51.9211 242.146 51.972 242.126 52.0254 242.116C52.0788 242.106 52.1336 242.106 52.1868 242.118C56.676 243.01 60.7995 245.214 64.0354 248.451C67.4855 245.73 71.5268 236.052 71.5673 235.946C71.6092 235.846 71.689 235.767 71.7893 235.726C71.8895 235.685 72.0019 235.686 72.1018 235.728C72.2017 235.77 72.2809 235.849 72.3219 235.95C72.3629 236.05 72.3624 236.162 72.3205 236.262C72.1423 236.691 67.9066 246.791 64.2217 249.334C64.1476 249.374 64.0628 249.391 63.9787 249.382Z"
        fill="#CCCCCC"
      />
      <path
        d="M65.0723 271.759C65.0723 271.759 68.3118 266.608 71.9644 265.879C75.6169 265.15 77.1962 260.931 78.5487 263.45C79.9012 265.968 76.1758 274.367 65.0723 271.759Z"
        fill="#CCCCCC"
      />
      <path d="M81.869 291.982H46.5338L44.1689 270.18H84.2339L81.869 291.982Z" fill="#CCCCCC" />
      <path d="M85.0597 274.311H43.3426L42.8081 269.694H85.5942L85.0597 274.311Z" fill="#9E9E9E" />
      <path
        opacity="0.3"
        d="M85.0597 274.311H43.3426L42.8081 269.694H85.5942L85.0597 274.311Z"
        fill="white"
      />
      <path d="M174.73 289.739V80.0645H271.515V289.739H174.73Z" fill="#ED7767" stroke="#9E9E9E" />
      <path d="M187.032 170.824V91.8968H206.506V170.824H187.032Z" fill="#ED7767" stroke="#9E9E9E" />
      <path
        opacity="0.7"
        d="M187.032 170.824V91.8968H204.61V170.824H187.032Z"
        fill="white"
        stroke="#9E9E9E"
      />
      <mask
        id="mask0_135_33492"
        // style="maskType:alpha"
        maskUnits="userSpaceOnUse"
        x="168"
        y="116"
        width="59"
        height="190">
        <path d="M187.065 300.924H182.476V290.236H187.065V300.924Z" fill="#EAEAEA" />
        <path
          d="M224.096 292.122L219.633 293.442L214.792 283.76L219.193 282.44L224.096 292.122Z"
          fill="#EAEAEA"
        />
        <path
          d="M217.935 294.259C217.62 294.385 217.306 294.385 216.991 294.259C216.803 294.197 216.677 294.008 216.614 293.756C216.614 293.631 216.614 293.505 216.74 293.379C217.18 293.002 218.815 293.442 219.003 293.505C219.066 293.505 219.066 293.568 219.066 293.568C219.066 293.631 219.066 293.631 219.003 293.694C218.752 293.945 218.375 294.134 217.935 294.259ZM217.054 293.505C216.991 293.505 216.929 293.568 216.929 293.568C216.866 293.631 216.866 293.631 216.929 293.694C216.929 293.819 217.054 293.945 217.18 294.008C217.746 294.071 218.312 293.945 218.752 293.631C218.186 293.505 217.62 293.442 217.054 293.505Z"
          fill="#C6C6C6"
        />
        <path
          d="M219.067 293.757C218.501 293.631 217.369 292.939 217.306 292.436C217.306 292.248 217.432 292.122 217.558 292.059C217.683 291.996 217.872 291.996 218.061 292.059C218.689 292.31 219.129 293.568 219.129 293.631C219.129 293.694 219.129 293.694 219.129 293.757H219.067ZM217.683 292.185C217.495 292.248 217.495 292.31 217.495 292.31C217.495 292.625 218.249 293.191 218.815 293.379C218.689 293.002 218.312 292.31 217.935 292.122C217.872 292.185 217.809 292.185 217.683 292.185Z"
          fill="#C6C6C6"
        />
        <path
          d="M219.192 293.002L224.159 291.493C224.348 291.43 224.536 291.493 224.599 291.682L226.485 295.391C226.674 295.768 226.485 296.146 226.171 296.334C226.108 296.334 226.108 296.334 226.045 296.397C224.285 296.9 223.468 297.026 221.267 297.717C219.947 298.095 215.609 300.295 213.785 300.861C211.962 301.427 211.208 299.666 211.962 299.226C215.169 297.466 217.746 294.825 218.564 293.505C218.752 293.254 218.941 293.128 219.192 293.002Z"
          fill="#878787"
        />
        <path
          d="M180.589 301.175C180.212 301.238 179.835 301.112 179.458 300.861C179.332 300.735 179.269 300.546 179.269 300.358C179.269 300.232 179.332 300.106 179.458 300.044C180.024 299.729 181.847 300.672 182.035 300.798C182.098 300.798 182.098 300.861 182.098 300.924C182.098 300.987 182.035 300.987 182.035 300.987C181.532 301.112 181.029 301.175 180.589 301.175ZM179.772 300.232C179.709 300.232 179.646 300.232 179.583 300.295C179.521 300.295 179.521 300.358 179.521 300.421C179.521 300.546 179.521 300.672 179.646 300.798C179.898 301.049 180.652 301.112 181.721 300.924C181.092 300.546 180.401 300.358 179.772 300.232Z"
          fill="#C6C6C6"
        />
        <path
          d="M181.972 301.05C181.407 300.798 180.338 299.792 180.401 299.289C180.401 299.163 180.526 299.038 180.841 298.975C181.092 298.912 181.281 298.975 181.469 299.163C182.035 299.666 182.098 300.861 182.098 300.924C182.098 300.987 182.098 300.987 182.035 300.987C182.035 301.05 182.035 301.05 181.972 301.05ZM180.966 299.163H180.904C180.715 299.163 180.715 299.289 180.652 299.289C180.589 299.604 181.281 300.358 181.847 300.735C181.847 300.232 181.658 299.729 181.281 299.289C181.218 299.226 181.092 299.163 180.966 299.163Z"
          fill="#C6C6C6"
        />
        <path
          d="M182.539 300.358H187.568C187.757 300.358 187.945 300.484 187.945 300.672L188.7 304.759C188.763 305.136 188.511 305.513 188.134 305.576C188.071 305.576 188.071 305.576 188.008 305.576C186.185 305.576 183.607 305.451 181.281 305.451C178.64 305.451 176.314 305.576 173.171 305.576C171.285 305.576 170.844 303.69 171.662 303.502C175.308 302.684 178.263 302.621 181.47 300.672C181.721 300.547 182.098 300.421 182.539 300.358Z"
          fill="#878787"
        />
        <path
          d="M198.885 132.868C198.068 136.263 197.565 140.413 199.828 142.676C199.828 142.676 198.319 146.071 192.032 146.071C186.311 146.071 189.329 142.676 189.329 142.676C193.101 141.796 193.101 137.144 192.598 134.503L198.885 132.868Z"
          fill="#EAEAEA"
        />
        <path opacity="0.2" d="M182.476 290.236V295.768H187.065V290.236H182.476Z" fill="black" />
        <path
          opacity="0.2"
          d="M219.193 282.44L214.792 283.76L217.306 288.79L221.707 287.406L219.193 282.44Z"
          fill="black"
        />
        <path
          d="M209.07 146.889L203.16 150.284C203.16 150.284 209.384 164.744 208.756 167.07C207.938 170.214 207.498 176.627 206.241 180.776C206.744 181.342 208.441 182.348 209.133 182.348C211.648 177.318 215.734 168.768 215.483 165.813C214.917 159.211 209.07 146.889 209.07 146.889Z"
          fill="#EAEAEA"
        />
        <path
          d="M206.178 180.776L202.154 182.474L206.24 185.869C206.24 185.869 209.761 184.297 209.07 181.342L206.178 180.776Z"
          fill="#EAEAEA"
        />
        <path
          d="M199.891 186.058L203.663 188.95L206.178 185.869L202.154 182.474L199.891 186.058Z"
          fill="#EAEAEA"
        />
        <path
          d="M208.064 143.682C212.339 144.562 215.86 159.903 215.86 159.903L206.178 164.556C206.178 164.556 202.845 157.2 201.399 151.73C199.89 146.071 203.411 142.739 208.064 143.682Z"
          fill="#C6C6C6"
        />
        <path
          opacity="0.2"
          d="M204.606 148.963C202.783 147.077 203.6 154.559 204.103 159.589C204.48 160.595 204.858 161.475 205.172 162.229C207.75 158.457 207.121 151.604 204.606 148.963Z"
          fill="black"
        />
        <path
          d="M189.517 144.059C189.517 142.425 189.265 141.67 189.705 141.042C190.146 140.413 199.01 139.721 200.519 140.413C201.022 140.664 201.337 142.991 200.708 144.059C199.765 145.568 189.517 144.059 189.517 144.059Z"
          fill="#C6C6C6"
        />
        <path
          opacity="0.2"
          d="M189.517 144.059C189.517 142.425 189.265 141.67 189.705 141.042C190.146 140.413 199.01 139.721 200.519 140.413C201.022 140.664 201.337 142.991 200.708 144.059C199.765 145.568 189.517 144.059 189.517 144.059Z"
          fill="black"
        />
        <path
          d="M182.224 143.934C182.224 143.934 176.188 157.828 180.212 183.731H204.857C205.172 179.959 203.6 161.475 208.064 143.682C205.36 143.179 202.594 142.802 199.891 142.613C196.433 142.425 192.912 142.425 189.391 142.613C186.939 142.928 184.55 143.305 182.224 143.934Z"
          fill="#C6C6C6"
        />
        <path
          d="M188.7 128.027C188.637 128.404 188.448 128.656 188.197 128.656C187.945 128.656 187.819 128.342 187.819 127.964C187.819 127.587 188.071 127.336 188.322 127.336C188.574 127.336 188.7 127.65 188.7 128.027Z"
          fill="#878787"
        />
        <path
          d="M189.014 128.027C188.448 129.159 187.757 130.165 186.939 131.108C187.442 131.485 188.071 131.674 188.7 131.548L189.014 128.027Z"
          fill="#CECECE"
        />
        <path
          d="M188.259 126.707L188.196 126.644C188.071 126.581 188.071 126.455 188.134 126.33C188.511 125.701 189.202 125.324 189.894 125.324C190.02 125.324 190.083 125.449 190.083 125.512C190.083 125.638 189.957 125.701 189.831 125.764C189.265 125.827 188.762 126.141 188.448 126.581C188.448 126.707 188.322 126.77 188.259 126.707Z"
          fill="#878787"
        />
        <path
          d="M201.211 127.713C200.456 132.24 200.268 134.943 197.753 137.144C195.364 139.344 191.592 139.155 189.391 136.703C188.574 135.76 188.071 134.629 187.882 133.434C187.316 129.285 188.637 122.557 193.289 120.986C196.37 119.854 199.828 121.426 200.959 124.506C201.337 125.575 201.399 126.644 201.211 127.713Z"
          fill="#EAEAEA"
        />
        <path
          d="M191.718 129.662C190.775 126.644 189.517 122.054 192.912 120.357C196.307 118.659 198.885 121.048 202.531 123.249C206.618 125.701 204.103 133.56 199.136 135.446C195.05 136.955 190.963 133.811 191.718 129.662Z"
          fill="#878787"
        />
        <path
          d="M192.095 124.129C189.14 124.946 187.128 122.997 188.574 120.86C190.397 118.093 197.69 118.785 200.394 121.803C203.097 124.821 194.169 126.204 192.095 124.129Z"
          fill="#878787"
        />
        <path
          d="M193.226 121.929C189.643 121.237 188.134 118.345 191.151 116.522C193.981 114.824 202.028 117.779 203.349 122.494C204.669 127.21 194.295 125.261 193.226 121.929Z"
          fill="#878787"
        />
        <path
          d="M202.846 124.192C203.412 123.752 205.549 123.123 205.801 125.575C205.046 124.758 203.977 124.255 202.846 124.192Z"
          fill="#878787"
        />
        <path
          d="M204.417 121.111C204.857 121.677 205.486 123.815 203.034 124.066C203.852 123.312 204.292 122.243 204.417 121.111Z"
          fill="#878787"
        />
        <path
          d="M194.358 129.41C194.043 130.416 193.352 131.234 192.409 131.674C191.214 132.302 190.397 131.296 190.523 130.039C190.648 128.907 191.466 127.147 192.723 127.084C193.98 127.021 194.735 128.216 194.358 129.41Z"
          fill="#EAEAEA"
        />
        <path
          d="M188.951 183.731C188.951 183.731 192.661 218.939 194.924 232.142C197.439 246.603 215.043 288.35 215.043 288.35L223.153 285.898C223.153 285.898 210.579 247.546 209.384 233.211C208.127 217.682 204.921 183.731 204.921 183.731H188.951Z"
          fill="#878787"
        />
        <path
          opacity="0.2"
          d="M194.61 196.054C194.232 195.488 193.918 195.3 193.541 195.425H193.478C193.415 195.425 193.352 195.488 193.289 195.551C193.226 195.614 193.164 195.677 193.101 195.74C193.038 195.803 193.038 195.803 192.975 195.866C192.912 195.928 192.912 195.991 192.849 196.054C192.723 196.243 192.661 196.431 192.535 196.62C192.472 196.746 192.409 196.871 192.409 196.997C192.409 197.123 192.283 197.312 192.22 197.5C192.158 197.689 192.032 198.003 191.969 198.255C191.969 198.318 191.906 198.38 191.906 198.443C191.906 198.506 191.843 198.758 191.78 198.883L191.717 199.261C191.655 199.512 191.592 199.764 191.529 200.015C191.466 200.267 191.403 200.581 191.34 200.895C191.34 201.021 191.277 201.084 191.277 201.21C191.214 201.587 191.152 201.964 191.026 202.341C192.032 211.08 193.226 221.14 194.295 228.181C194.358 227.93 194.421 227.741 194.547 227.49C194.861 226.295 195.113 225.164 195.301 223.969C196.81 214.853 196.936 199.638 194.61 196.054Z"
          fill="black"
        />
        <path
          d="M180.212 183.731C180.212 183.731 178.074 218.185 177.949 232.205C177.823 246.792 180.841 294.637 180.841 294.637H188.637C188.637 294.637 191.026 247.672 191.969 233.274C193.038 217.619 196.433 183.669 196.433 183.669L180.212 183.731Z"
          fill="#878787"
        />
        <path
          d="M189.203 292.31L189.077 294.825H180.086L179.835 291.996L189.203 292.31Z"
          fill="#C6C6C6"
        />
        <path
          d="M222.461 283.068C222.65 283.446 223.53 285.96 223.53 285.96L214.665 288.664L213.219 286.275L222.461 283.068Z"
          fill="#C6C6C6"
        />
        <path
          d="M186.122 147.517L179.583 149.404C179.583 149.404 182.224 164.933 181.092 167.07C179.521 169.9 177.634 176.061 175.371 179.833C175.748 180.525 177.194 181.908 177.823 182.034C181.47 177.759 187.442 170.34 187.883 167.448C188.951 160.909 186.122 147.517 186.122 147.517Z"
          fill="#EAEAEA"
        />
        <path
          d="M175.371 179.833L171.033 180.525L174.176 184.737C174.176 184.737 177.949 184.046 178.011 180.965L175.371 179.833Z"
          fill="#EAEAEA"
        />
        <path
          d="M168.015 183.543L171.032 187.189L174.176 184.8L171.032 180.525L168.015 183.543Z"
          fill="#EAEAEA"
        />
        <path
          d="M185.871 144.248C189.831 146.071 189.643 161.789 189.643 161.789L179.143 164.053C179.143 164.053 177.634 156.131 177.509 150.472C177.383 144.625 181.595 142.236 185.871 144.248Z"
          fill="#C6C6C6"
        />
      </mask>
      <g mask="url(#mask0_135_33492)">
        <path
          opacity="0.7"
          d="M187.032 170.824V91.8968H204.61V170.824H187.032Z"
          fill="white"
          stroke="#9E9E9E"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.288 79.4468H272.073V290.122H174.288V79.4468ZM186.589 91.279H205.168V171.206H186.589V91.279Z"
        fill="#ED7767"
      />
      <path
        d="M193.143 193.716V193.717C193.143 194.321 192.963 194.912 192.628 195.415C192.292 195.917 191.815 196.309 191.256 196.54C190.698 196.771 190.084 196.832 189.491 196.714C188.898 196.596 188.354 196.305 187.927 195.878C187.499 195.45 187.208 194.906 187.09 194.313C186.973 193.721 187.033 193.106 187.264 192.548C187.496 191.99 187.887 191.512 188.39 191.177C188.892 190.841 189.483 190.662 190.087 190.662L190.088 190.662C190.49 190.661 190.887 190.739 191.258 190.892L191.449 190.43L191.258 190.892C191.629 191.046 191.966 191.271 192.25 191.554C192.534 191.838 192.759 192.175 192.912 192.546C193.065 192.917 193.143 193.315 193.143 193.716Z"
        fill="#ED7767"
        stroke="#9E9E9E"
      />
      <path
        d="M196.698 193.717C196.698 195.405 195.33 196.773 193.643 196.773C191.955 196.773 190.587 195.405 190.587 193.717C190.587 192.03 191.955 190.662 193.643 190.662C195.33 190.662 196.698 192.03 196.698 193.717Z"
        fill="#9E9E9E"
        stroke="#9E9E9E"
      />
      <path
        opacity="0.8"
        d="M196.698 193.717C196.698 195.405 195.33 196.773 193.643 196.773C191.955 196.773 190.587 195.405 190.587 193.717C190.587 192.03 191.955 190.662 193.643 190.662C195.33 190.662 196.698 192.03 196.698 193.717Z"
        fill="white"
        stroke="#9E9E9E"
      />
      <path
        opacity="0.2"
        d="M176.182 289.622V79.9468H269.849V289.622H176.182Z"
        fill="white"
        stroke="#9E9E9E"
      />
    </svg>
  );
}

export default SvgNoRoomsIcon;
