/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Typography, Grid, makeStyles, Button, Link } from '@material-ui/core';
import SvgHubilo from 'icons/svgHubiloNewIcon';
import { userInfoSelector } from 'redux/custom-selector';
import Svg404 from 'icons/svgError404';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UnAuthorized from './unauthorized';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    background: '#ffffff',
  },
  logoBlock: {
    color: theme.palette.text.third,
    '& .mono': {
      width: 150,
      height: 70,
    },
    '& .logo-text': {
      fontSize: theme.fontSize.size3,
    },
  },
}));

export default function ErrorPage() {
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  return (
    <>
      {userInfo.UserInfo.is_team_member_loggedin === true ||
      userInfo.UserInfo.subscriptionStatus === undefined ? (
        <UnAuthorized />
      ) : (
        <Box>
          <Grid container className={classes.wrapper}>
            <Grid item lg>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                height="100%">
                <Link
                  onClick={() => history.push('/events')}
                  className="cursor-pointer"
                  underline="none"
                  color="inherit">
                  <Box className={classes.logoBlock} alignItems="center">
                    <Box display="flex" alignItems="center">
                      <SvgHubilo fill="currentColor" className="mono" />
                      <span className="logo-text">HUBILO</span>
                    </Box>
                  </Box>
                </Link>

                <Box component="div" mt={5} mb={3}>
                  {' '}
                  <Svg404 width="380px" Height="280px" />{' '}
                </Box>

                <Box component="div">
                  <Typography variant="h5" component="h5">
                    {' '}
                    Page not found{' '}
                  </Typography>
                </Box>

                <Box color="text.grayColor" mt={2} mb={4} maxWidth="450px" textAlign="center">
                  <Typography variant="caption" component="p">
                    Sorry the page you tried cannot be found. :( You may have typed the address
                    incorrectly or you may have used an outdated link. If you found a broken link
                    from our site then reach out to us on
                    <Box
                      p={0}
                      border={0}
                      color="inherit"
                      bgcolor="transparent"
                      className="text-underline no-outline cursor-pointer"
                      component="a"
                      onClick={() => window.open('http://www.support@hubilo.com/', '_blank')}>
                      {' '}
                      support@hubilo.com
                    </Box>
                  </Typography>
                </Box>

                <Button
                  onClick={() => history.push('/events')}
                  className="uppercase"
                  variant="contained"
                  size="small"
                  color="primary">
                  Back to home page
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
