import React from 'react';

const style = {
  a: { fill: 'none' },
  b: { fill: 'currentColor' },
};
export default function SvgEngagementTwoIcon(props) {
  return (
    <svg
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      {...props}>
      <rect style={style.a} width="26" height="26" />
      <path
        style={style.b}
        d="M4.273,17.684A.5.5,0,0,1,4,17.238V15H3.5A3.5,3.5,0,0,1,0,11.5v-8A3.5,3.5,0,0,1,3.5,0h13A3.5,3.5,0,0,1,20,3.5v8A3.5,3.5,0,0,1,16.5,15H8.432L4.794,17.644a.5.5,0,0,1-.294.1A.512.512,0,0,1,4.273,17.684ZM1,3.5v8A2.5,2.5,0,0,0,3.5,14h1a.5.5,0,0,1,.5.5v1.758L7.976,14.1A.5.5,0,0,1,8.27,14H16.5A2.5,2.5,0,0,0,19,11.5v-8A2.5,2.5,0,0,0,16.5,1H3.5A2.5,2.5,0,0,0,1,3.5Zm8.757,8.337-3-2.723A4.394,4.394,0,0,1,5.413,6.869C5.4,6.8,5.384,6.74,5.374,6.677a2.716,2.716,0,0,1-.041-.91A2.917,2.917,0,0,1,8.159,2.96a2.7,2.7,0,0,1,1.943.829,2.812,2.812,0,0,1,1.977-.829A2.93,2.93,0,0,1,14.908,5.8a2.939,2.939,0,0,1-.084,1.068,4.382,4.382,0,0,1-1.344,2.245l-3.052,2.725a.5.5,0,0,1-.669,0ZM6.325,5.893a1.822,1.822,0,0,0,.031.6h0l.03.141A3.386,3.386,0,0,0,7.429,8.373L10.1,10.794l2.715-2.422a3.408,3.408,0,0,0,1.04-1.733,2.089,2.089,0,0,0,.066-.714A1.966,1.966,0,0,0,12.079,3.96a1.808,1.808,0,0,0-1.555.9A.5.5,0,0,1,10.1,5.1h0a.5.5,0,0,1-.429-.249A1.748,1.748,0,0,0,8.159,3.96,1.953,1.953,0,0,0,6.325,5.893Z"
        transform="translate(3 6)"
      />
    </svg>
  );
}
