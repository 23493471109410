/* eslint-disable react/self-closing-comp */
import React from 'react';

export default function SvgGoogleIcon(props) {
  return (
    <svg fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.1 14.1" {...props}>
      <path d="M7.7,8.5h3.4c-0.7,1.9-2.6,3.2-4.8,2.9C4.6,11.1,3.1,9.7,2.8,8C2.2,5.2,4.3,2.7,7.1,2.7c1.1,0,2.2,0.4,2.9,1.1c0.2,0.2,0.5,0.2,0.6,0l1.3-1.2c0.2-0.2,0.2-0.5,0-0.7c-1.5-1.5-3.8-2.3-6.2-1.8C3,0.6,0.7,2.8,0.1,5.6c-0.9,4.5,2.6,8.5,6.9,8.5c3.8,0,6.8-2.9,7-6.7c0,0,0-1.6,0-1.6H7.7C7.5,5.8,7.3,6,7.3,6.3V8C7.3,8.3,7.5,8.5,7.7,8.5z"></path>
    </svg>
  );
}
