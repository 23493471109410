/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useRef, useState, useEffect } from 'react';
import { Box, makeStyles, Typography, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Recaptcha from 'react-invisible-recaptcha';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';
import { setUserInfo } from 'redux/actions/user-action';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
// import SvgFacebookIcon from '../../icons/svgFb';
// import SvgGoogleIcon from '../../icons/svgGooglePlus';
import { Mail, Lock } from 'react-feather';
// import SvgLinkInIcon from '../../icons/svgLinkedin';
import InputField from '../../custom-components/form-fields/inputField';
import { LOGIN } from '../../api-setup/api-endpoints.js';
import validateFunctions from '../../models/validation';
import { LoginSchema } from '../../models/schema';
import userSetting from '../../settings';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { setUserCredential, setOnboardingDataCollectionDone } from 'lib/cookies.js';
import { API, POSTAPI } from 'api-setup/api-helper';
import mixPanel from 'lib/mix-panel';
import amplitude from 'lib/amplitude';
import usePosthogClient from 'lib/posthog/usePosthogClient';
import { downTimeModalAction } from 'pages/DownTime/downTimeReducer';

const useStyles = makeStyles((theme) => ({
  anchorLink: {
    textDecoration: 'none',
  },
  termsLink: {
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      marginTop: '-5px',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  socialLoginBtn: {
    background: 'white',
    boxShadow: '0px 3px 6px #0000001A',
    minHeight: '34px',
    flexGrow: 1,
    borderRadius: 4,
    '&:nth-child(n+2)': {
      marginLeft: 10,
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  fieldIcon: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

// Check for validation of form when login button clicked
function handleValidations(formFields) {
  const { email, password, isAcceptTerms } = formFields;
  const data = {
    email: email.trim(),
    password: password.trim(),
    isAcceptTerms: Boolean(isAcceptTerms),
  };
  const errorObj = validateFunctions.validate(LoginSchema, data);
  if (errorObj || !isAcceptTerms) {
    if (!isAcceptTerms) {
      return {
        ...errorObj,
        isAcceptTerms: 'Agreeing to the terms of service and privacy policy is required',
      };
    }
    return errorObj;
  } else {
    return false;
  }
}

// login API call
const makeAPIcall = (data, recaptchaRef, history, cb, errorCb, dispatch) => {
  POSTAPI(false, LOGIN, null, null, data)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        const { token, userData, team_member_id, isOnboardingDataCollectionDone, organiser_id, isFirstLogin } =
          res.data.data;
        if (isFirstLogin) {
          history.push({
            pathname: '/set-password',
            state: {
              organiser_id,
              token,
              email: userData?.email,
            },
          });
        } else {
          setOnboardingDataCollectionDone(
            isOnboardingDataCollectionDone,
            'isOnboardingDataCollectionDone'
          );
          dispatch(downTimeModalAction(true)); // DOWNTIME MODAL ACTION TRIGGER
          setUserCredential(
            {
              authToken: token,
              teamMemberId: team_member_id,
              organiser_id,
              userPermission: userData.userPermission,
            },
            'userData'
          );
          const resp = {
            userData,
            isFirstLogin,
            isOnboardingDataCollectionDone,
          };
          if (cb) cb(resp);
        }
      } else {
        errorCb(res.data.message || 'Some error occurred');
        API.errStatusHandler(res, history, dispatch, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'stg' ||
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            if (recaptchaRef && recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
          }
        });
      }
    })
    .catch((err) => {
      const errorMsg =
        err.response && err.response.data ? err.response.data.message : 'Some error occurred';
      errorCb(errorMsg);
      API.catchHandler(err, () => {
        if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
          if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
    });
};

let isOnboardingDataCollectionDone = '0';

export default function LoginForm(props) {
  const { anchorLink, subTitleWithSeparator, fieldIcon, termsLink } = useStyles();
  const recaptchaRef = useRef(null);
  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
    isAcceptTerms: false,
  });
  const [errorObj, setErrorObj] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isAcceptTerms, setAcceptTerms] = useState(false);

  const [welName, setWelName] = useState('');
  const [readOnlyEmail, setReadOnlyEmail] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const { identifyOrgData } = usePosthogClient();

  useEffect(() => {
    const { location } = props;
    if (location.state && location.state.name && location.state.email) {
      setFormFields({ ...formFields, email: location.state.email });
      setWelName(`Welcome Back! ${location.state.name}`);
      setReadOnlyEmail(true);
    } else {
      setWelName('');
      setReadOnlyEmail(false);
    }
    amplitude.Track.onPage('login');
  }, []);

  useEffect(() => {
    if (captchaToken) {
      setIsLoading(true);
      const data = {
        email: formFields.email.trim(),
        password: formFields.password.trim(),
        captchaToken,
      };
      makeAPIcall(
        { data },
        recaptchaRef,
        history,
        (resp) => {
          const userInfo = resp.userData;
          const isOnboardingColl = resp.isOnboardingDataCollectionDone;
          if (isOnboardingColl) {
            isOnboardingDataCollectionDone = isOnboardingColl;
          }
          mixPanel.Login.loginSuccessMP(
            userInfo.id,
            userInfo.email,
            userInfo.firstName,
            userInfo.lastName,
            'FORM'
          );
          const deviceIdAmplitude = decodeURIComponent(
            window.location.search.replace(
              new RegExp(
                `^(?:.*[&\\?]${encodeURIComponent('deviceId').replace(
                  // eslint-disable-next-line no-useless-escape
                  /[\.\+\*]/g,
                  '\\$&'
                )}(?:\\=([^&]*))?)?.*$`,
                'i'
              ),
              '$1'
            )
          );
          if (deviceIdAmplitude) {
            amplitude.Track.loginSuccessful(
              'email',
              userInfo.first_name,
              userInfo.last_name,
              userInfo.id,
              userInfo.organisation,
              deviceIdAmplitude
            );
          } else {
            amplitude.Track.loginSuccessful(
              'email',
              userInfo.first_name,
              userInfo.last_name,
              userInfo.id,
              userInfo.organisation
            );
          }
          console.log(':::reduxData.UserInfo login', userInfo)
          identifyOrgData(userInfo.email, {
            organiser_id: userInfo.organiser_id,
            ...(userInfo.organisation && { organisation: userInfo.organisation })
          });
          dispatch(setUserInfo(userInfo));
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        dispatch
      );
    }
  }, [captchaToken]);
  useEffect(() => {
    if (reduxData.userId) {
      if (isOnboardingDataCollectionDone.toString() === '1') {
        history.replace('/events'); // event page redirection if login API success
      } else {
        history.replace('/onboarding');
      }
    }
  }, [reduxData.userId]);

  // handle change of inputs in formFields
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (errorObj[name]) setErrorObj({ ...errorObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };
  // handle login function on button click
  const handleLogin = (event) => {
    // reset userid in amplitude
    amplitude.Track.resetUserID();
    event.preventDefault();
    const errorObject = handleValidations(formFields);
    if (!errorObject) {
      amplitude.Track.enterEmail(); // TODO : @ramesh removed email
      setErrorObj({});
      if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.execute(); // ref
        }
      } else {
        setIsLoading(true);
        const data = {
          email: formFields.email.trim(),
          password: formFields.password.trim(),
          captchaToken,
        };
        makeAPIcall(
          { data },
          recaptchaRef,
          history,
          (resp) => {
            const userInfo = resp.userData;
            const isOnboardingColl = resp.isOnboardingDataCollectionDone;
            if (isOnboardingColl) {
              isOnboardingDataCollectionDone = isOnboardingColl;
            }
            mixPanel.Login.loginSuccessMP(
              userInfo.id,
              userInfo.email,
              userInfo.firstName,
              userInfo.lastName,
              'FORM'
            );
            amplitude.Track.loginSuccessful(
              'email',
              userInfo.first_name,
              userInfo.last_name,
              userInfo.id,
              userInfo.organisation
            );
            identifyOrgData(userInfo.email, {
              organiser_id: userInfo.organiser_id,
              ...(userInfo.organisation && { organisation: userInfo.organisation })
            });
            dispatch(setUserInfo(userInfo));
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
          },
          dispatch
        );
      }
    } else {
      setErrorObj(errorObject);
    }
  };

  return (
    <>
      <Box mb={8} display="flex" justifyContent="center">
        <HubiloLogo height="70px" width="140px" />
      </Box>
      <Box className="text-center">
        {/* <h3>{welName}</h3>
				<Typography variant="caption" className={subTitle}>CONNECT WITH</Typography> */}
        <Box mb={1}>
          <Typography variant="h6">{welName || 'Welcome to Hubilo'}</Typography>
        </Box>
        {/* <Typography variant="caption" className={subTitle}>
          CONTINUE WITH
        </Typography>
        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
          <Button variant="contained" className={socialLoginBtn} onClick={() => onFacebookLogin()}>
            <SvgFacebookIcon height="14px" width="14px" color="#355087" />
          </Button>
          <Button variant="contained" className={socialLoginBtn} onClick={() => onLinkedinLogin()}>
            <SvgLinkInIcon height="14px" width="14px" color="#0077B3" />
          </Button>
          <Button variant="contained" className={socialLoginBtn} onClick={() => onGoogleLogin()}>
            <SvgGoogleIcon height="14px" width="14px" color="#D94437" />
          </Button>
        </Box> */}
      </Box>
      <Box mt={3.2}>
        {process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod' ? (
          <Recaptcha
            ref={recaptchaRef}
            sitekey={userSetting.recaptchaSiteKey}
            onResolved={(propCaptchaToken) => {
              setCaptchaToken(propCaptchaToken);
            }}
            onError={() => {
              // TODO : error console removed
            }}
          />
        ) : (
          ''
        )}
        <form onSubmit={(event) => handleLogin(event)}>
          <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
            <Typography variant="caption" component="span">
              {readOnlyEmail ? `PLEASE ENTER YOUR PASSWORD TO SIGN-IN` : `LOGIN WITH YOUR EMAIL`}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                disabled={isLoading}
                readOnly={readOnlyEmail}
                required
                placeholder="Email Address"
                type="email"
                id="email"
                name="email"
                startAdornment
                startIconAdornment
                startAdornmentIcon={<Mail size={18} className={fieldIcon} />}
                error={errorObj.email}
                onChange={handleChange}
                value={formFields.email}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                disabled={isLoading}
                required
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                startAdornment
                startIconAdornment
                startAdornmentIcon={<Lock size={18} className={fieldIcon} />}
                showFieldVisibilityBtn
                error={errorObj.password}
                onChange={handleChange}
                value={formFields.password}
              />
            </Grid>
          </Grid>
          <Box textAlign="right" mt={1.25}>
            <Link to="/forgot-password" className={anchorLink}>
              <Typography color="textPrimary" component="span" variant="caption">
                Forgot Password?
              </Typography>
            </Link>
          </Box>

          <Box textAlign="left" mt={1.25} className={termsLink}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={formFields.isAcceptTerms}
                  name="isAcceptTerms"
                  id="isAcceptTerms"
                  onChange={(event) => {
                    setErrorObj({ ...errorObj, isAcceptTerms: event.target.checked });
                    setFormFields({ ...formFields, isAcceptTerms: event.target.checked });
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to Hubilo’s{' '}
                  <a target="_blank" href="https://www.hubilo.com/terms-of-use">
                    {' '}
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    href="https://www.hubilo.com/privacy-policy?__hstc=251652889.62c9a29ed31bba51a74716e0085fcb1d.1653523919077.1659367392688.1662486070043.7&__hssc=251652889.1.1662486070043&__hsfp=2738856684">
                    Privacy Policy.
                  </a>
                </Typography>
              }
            />
          </Box>
          {errorObj.isAcceptTerms && !formFields.isAcceptTerms ? (
            <Box textAlign="left" mt={1}>
              <Typography color="error" component="span" variant="caption">
                Agreeing to the terms of service and privacy policy is required
              </Typography>
            </Box>
          ) : null}
          <Box mt={5}>
            <BtnWithLoader
              className="uppercase"
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
              isLoading={isLoading}
              loadingLabel="Logging In"
              label="Login"
              fullWidth
            />
          </Box>
        </form>
        {/* <Box mt={5} textAlign="center" component="p">
          {' '}
          <Link to="/signup" className={anchorLink}>
            <Typography
              variant="caption"
              color="primary"
              className="text-uppercase"
              component="span">
              <Box component="span" fontWeight={600}>
                Create an account
              </Box>
            </Typography>
          </Link>
        </Box> */}
      </Box>
    </>
  );
}
