const primaryColor = {
  extraLight: '#F59C861A',
  lighter: '#FFF0EC',
  light: '#F59C86',
  main: '#ED7767', // #ED7767
  dark: '#DE604F',
  primaryLight: '#FFF7ED',
  contrastText: '#fff',
  light20: '#f19285',
  light40: '#f4ada4',
  light60: '#f8c9c2',
  light80: '#fbe4e1',
  mainRgb: {
    r: 251,
    g: 122,
    b: 117,
  },
};
const asideWidth = 480;
const textColor = {
  primary: '#202020', // #353535
  secondary: '#808080',
  disabled: '#a09abb',
  grayColor: '#808080', // #a5a5a5
  grayColor1: '#C1C1C1',
  danger1: 'red',
  third: '#767676',
};
const secondaryColor = {
  light: textColor.grayColor,
  main: '#262544',
  dark: textColor.primary,
  contrastText: '#fff',
};
const bg = {
  separator: '#dbdbdb',
  transparent: 'transparent',
  whiteOff1: '#f6f6f6',
  whiteOff2: '#dbdbdb',
  whiteOff3: '#f9f3f5',
  whiteOff4: '#fbfbfb',
  whiteAlpha50: 'rgba(255,255,255,0.50)',
  whiteAlpha70: 'rgba(255,255,255,0.70)',
  white: '#ffffff',
  black: '#000000',
};
const border = {
  light: '#dbdbdb',
  lighter: '#f1f1f1',
  dark: '#c0c0c0',
};
const socialMediaColor = {
  facebook: '#355087',
  linkedin: '#0077B3',
  google: '#D94437',
};
const otherColor = {
  success: '#00C957',
  successLight: 'rgba(0, 201, 87,0.1)',
  successMid: 'rgba(0, 201, 87,0.5)',
  successDark: '#c62306',
  warn: '#FFB018',
  warnLight: '#FFF7E7',
  warning2: '#FFBF3F',
  warnMid: 'rgba(255, 176, 24, 0.5)',
  info: '#2196F3',
  infoLight: 'rgba(211, 234, 253, 1)',
  infoMid: 'rgba(104, 107, 255, 0.5)',
  danger: '#F83E1D',
  dangerLight: 'rgba(248, 62, 29, 0.1)',
  dangerMid: 'rgba(248, 62, 29, 0.5)',
  dangerDark: '#008037',
  yellowLight: 'rgba(255, 176, 24, 0.1)',
  yellow: 'rgba(255, 176, 24, 1)',
  greenLight: 'rgba(39, 194, 76, 0.1)',
  green: 'rgba(39, 194, 76, 1)',
  inputDisableBg: '#F5F5F5',
  checkBoxDisable: 'rgba(0, 0, 0, 0.26)',
  warn2Bg: 'rgba(251, 179, 73, 0.2)',
  warn2: '#FBB349',
  success2Bg: 'rgba(40, 196, 113, 0.2)',
  success2: '#28C471',
  danger2: '#F27170',
  tagbg_magenta: '#dfd4f7',
  tag_magenta: '#604c8c',
  tagbg_green: '#d9f7e7',
  tag_green: '#3D8A61',
  tagbg_orange: '#fde1b6',
  tag_orange: '#EE8C46',
  tagbg_gray: '#e0e0e0',
  tag_gray: '#727272',
  tagbg_red: '#ffedea',
  tag_red: '#DD8D8C',
  tagbg_yellow: '#ffedb3',
  tag_yellow: '#d09c34',
  tagbg_pink: '#ffeeed',
  tag_pink: '#e39982',
  alertWarnBg: '#FEF5F3',
  alertWarnText: '#353535',
  black: '#000000',
  warn2Light: '#FEF3E3',
};
const breakpoints = {
  xl: 1440,
  lg: 1104,
  md: 960,
  sm: 600,
  xs: 0,
};
const zIndex = {
  dashHeader: 99,
  modal: 1200,
  // popperTooltip: 2002,
};
const fontFamily = 'Helvetica Neue New';

// const fontSize = {
// 	size1: '',
// 	size2: '1rem',      // = 16px
// 	size3: '1.25rem',   // = 20px
// 	size4: '1.1875em',  // = 19px
// 	size5: '1.125rem',  // = 18px
// 	size6: '0.955rem',  // = 15.28px
// 	size7: '0.935rem',  // = 14.96px
// 	size8: '0.875rem',  // = 14px
// 	size9: '0.75rem',   // = 12px
// 	size10: '0.625rem', // = 10px
// 	size11: '1.5rem',   // = 24px
// 	size12: '0.8125rem', // = 13px
// 	size13: '0.7125rem', // = 11px
// 	size14: '1.875rem', // = 30px
// }

const fontSize = {
  size1: '',
  size2: '1rem', // = 16px
  size3: '1.25rem', // = 20px
  size4: '1.1875em', // = 19px
  size5: '1.125rem', // = 18px
  size6: '0.955rem', // = 15.28px
  size7: '0.935rem', // = 14.96px
  size8: '0.875rem', // = 14px
  size9: '0.75rem', // = 12px
  size10: '0.625rem', // = 10px
  size11: '1.5rem', // = 24px
  size12: '0.8125rem', // = 13px
  size13: '0.7125rem', // = 11px
  size14: '1.875rem', // = 30px
};

const fontWeight = {
  regular: 400,
  medium: 500,
  // semiBold: 600,
  bold: 700,
};
const borderRadius = 4;

const inputBoxShadow = `0 0 0 1px ${primaryColor.main}, 0 0 0 5px rgba(${primaryColor.mainRgb.r}, ${primaryColor.mainRgb.g}, ${primaryColor.mainRgb.b}, 0.2)`;

export default {
  props: {
    MuiButtonBase: {
      disableRipple: true,
      // disabledTouchRipple: true
    },
  },
  breakpoints: {
    values: breakpoints,
  },
  typography: {
    fontFamily: [fontFamily, '-apple-system', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),

    h3: {
      fontSize: fontSize.size14,
      fontWeight: fontWeight.medium,
    },
    h4: {
      fontSize: fontSize.size11,
      fontWeight: fontWeight.medium,
    },
    h5: {
      fontSize: fontSize.size3,
      fontWeight: fontWeight.medium,
    },
    h6: {
      fontSize: fontSize.size5,
      fontWeight: fontWeight.medium,
    },
    button: {
      fontWeight: fontWeight.regular,
      textTransform: 'none',
    },
    subtitle2: {
      fontWeight: fontWeight.medium,
    },
    body2: {
      lineHeight: '1.375rem',
    },
    body1: {
      fontWeight: fontWeight.regular,
    },
  },
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    text: textColor,
    bg,
    socialMediaColor,
    border,
    alert: otherColor,
  },
  inputBoxShadow,
  fontSize,
  shape: {
    borderRadius,
  },
  asideWidth,
  zIndex,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        '@media screen and (max-width: 960px)': {
          html: {
            fontSize: '90%',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        '&.square': {
          borderRadius: 4,
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: fontWeight.bold,
        paddingLeft: 22,
        paddingRight: 22,
        boxShadow: 'none',
        borderRadius: 4,
        textTransform: 'inherit',
        whiteSpace: 'nowrap',
        '&.Mui-disabled': {
          cursor: 'not-allowed !important',
          background: `#f0f0f0 !important`,
          backgroundColor: `#f0f0f0 !important`,
          transition: 'none',
        },
        '&.uppercase': {
          textTransform: 'uppercase',
        },
        '&.rounded': {
          borderRadius: 48,
        },
      },
      sizeSmall: {
        borderRadius: 4,
      },
      sizeLarge: {
        fontSize: fontSize.size8,
        borderRadius: 4,
        paddingTop: 9.5,
        paddingBottom: 9.5,
      },
      sizeMedium: {
        fontSize: fontSize.size8,
        borderRadius: 4,
        paddingTop: 12,
        paddingBottom: 9,
      },
      textSizeSmall: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
      },
      contained: {
        paddingLeft: 22,
        paddingRight: 22,
        '&.rounded': {
          paddingLeft: 6,
          paddingRight: 6,
          minWidth: 36,
          minHeight: 36,
          borderRadius: 36,
        },
        '&.squarebtn': {
          borderRadius,
        },
        '&:hover': {
          boxShadow: '0px 2px 4px rgba(0,0,0,.12)',
        },
      },
      containedSizeSmall: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
        '@media screen and (max-width: 960px)': {
          paddingLeft: 12,
          paddingRight: 12,
        },
        '&.rounded': {
          paddingLeft: 4,
          paddingRight: 4,
          minWidth: 30,
          minHeight: 30,
          borderRadius: 30,
        },
        '&.square': {
          paddingLeft: 8,
          paddingRight: 8,
          minWidth: 30,
          minHeight: 30,
          borderRadius,
        },
      },
      containedSizeLarge: {
        paddingLeft: 32,
        paddingRight: 32,
        '&.rounded': {
          paddingLeft: 9.5,
          paddingRight: 9.5,
          minWidth: 44,
          minHeight: 44,
          borderRadius: 44,
        },
      },
      outlined: {
        background: bg.white,
        paddingLeft: 22,
        paddingRight: 22,
        '&.square': {
          paddingLeft: 6,
          paddingRight: 6,
          minWidth: 36,
          minHeight: 36,
          borderRadius,
        },
        '&:hover': {
          backgroundColor: bg.white,
          boxShadow: '0px 2px 4px rgba(0,0,0,.12)',
        },
      },
      outlinedSizeSmall: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
        '&.square': {
          paddingLeft: 4,
          paddingRight: 4,
          minWidth: 32,
          minHeight: 32,
          borderRadius,
        },
      },
      outlinedSizeLarge: {
        paddingLeft: 32,
        paddingRight: 32,
        '&.square': {
          paddingLeft: 9.5,
          paddingRight: 9.5,
          minWidth: 44,
          minHeight: 44,
          borderRadius,
        },
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: bg.white,
        },
      },

      containedPrimary: {
        background: `#ED7767`,
        boxShadow: 'none',
      },
      containedSecondary: {
        backgroundColor: `#FFF0EC`,
        boxShadow: 'none',
        color: primaryColor.main,
        '&.square': {
          paddingLeft: 4,
          paddingRight: 4,
          minWidth: 30,
          minHeight: 30,
          borderRadius,
        },
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: `#FFF0EC`,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: bg.separator,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: fontSize.size8,
        // padding: '14px 12px 14px 12px',
        // borderBottomColor: bg.separator,
        // '&:first-child': {
        // 	borderTopLeftRadius: borderRadius,
        // },
        // '&:last-child': {
        // 	borderTopRightRadius: borderRadius,
        // },
        // fontSize: fontSize.size9,
        // "@media screen and (min-width: 599px)": {
        // 	fontSize: fontSize.size8
        // }
        '& .MuiChip-root': {
          textTransform: 'uppercase',
        },
        '&.fixedC': {
          position: 'sticky',
          top: 'auto',
          background: 'white',
          zIndex: 10,
          '&.left': {
            left: 0,
            boxShadow: `inset -12px 0 4px -11px rgb(0 0 0 / 20%)`,
          },
          '&.right': {
            right: 0,
            boxShadow: `inset 4px 0 6px -7px rgb(0 0 0 / 70%)`,
          },
        },
      },
      head: {
        // textTransform: 'capitalize',
        fontSize: fontSize.size12,
        backgroundColor: bg.whiteOff4,
        lineHeight: '1.95rem',
        whiteSpace: 'nowrap',
        fontWeight: 500,
        padding: '14px 16px',
        // color: textColor.disabled,
        // textTransform: 'uppercase',
        // fontSize: fontSize.size9,
        // padding: '10px 8px',
        // "@media screen and (min-width: 599px)": {
        // 	fontSize: fontSize.size8,
        // 	padding: '14px 12px'
        // }
        '&.fixedC': {
          backgroundColor: bg.whiteOff4,
          '&.fixedHead':{
            top:0,
            zIndex:11,
          },
        },
        
        '@media screen and (max-width: 960px)': {
          padding: '10px 14px',
        },
      },
      paddingCheckbox: {
        // paddingLeft: "11px !important"
      },
      body: {
        padding: '14px 16px',
        position: 'relative',
        '@media screen and (max-width: 960px)': {
          padding: '10px 14px',
        },
        '& .MuiTypography-caption': {
          fontSize: `${fontSize.size8}`,
        },
      },
    },
    MuiRadio: {
      // root: {
      // 	color: border.light,
      // 	'&.Mui-disabled': {
      // 		cursor: 'not-allowed'
      // 	}
      // },
      // colorPrimary: {
      // 	'&:hover': {
      // 		color: primaryColor.light
      // 	},
      // 	'&.Mui-checked': {
      // 		color: primaryColor.main
      // 	},
      // 	'&.Mui-disabled': {
      // 		color: otherColor.checkBoxDisable
      // 	}
      // }
    },
    MuiCheckbox: {
      root: {
        color: textColor.grayColor1,
      },
      indeterminate: {
        color: primaryColor.light,
      },
      colorPrimary: {
        color: textColor.grayColor1,
        '&:hover': {
          color: primaryColor.light,
        },
        '&.Mui-checked': {
          color: primaryColor.main,
        },
        '&.Mui-disabled': {
          color: otherColor.checkBoxDisable,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: primaryColor.extraLight,
          },
        },
        '&.Mui-selected': {
          backgroundColor: primaryColor.extraLight,
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    MuiTablePagination: {
      // toolbar: {
      // 	height: 70,
      // 	minHeight: 70,
      // 	paddingRight: 24
      // },
      // select: {
      // 	borderRadius: 4,
      // 	'&:focus': {
      // 		borderRadius: 4,
      // 	}
      // },
      // selectIcon: {
      // 	top: 'calc(50% - 12px)'
      // }
    },
    MuiListItem: {
      // root: {
      // 	'&.Mui-selected': {
      // 		backgroundColor: primaryColor.main,
      // 		color: primaryColor.contrastText,
      // 		'& svg': {
      // 			color: primaryColor.contrastText,
      // 		},
      // 		'&:hover': {
      // 			backgroundColor: primaryColor.main,
      // 			color: primaryColor.contrastText,
      // 		}
      // 	}
      // },
      // button: {
      // 	'&:hover': {
      // 		backgroundColor: primaryColor.light,
      // 		color: primaryColor.main
      // 	}
      // }
    },
    MuiFormLabel: {
      root: {
        fontSize: fontSize.size8,
        color: textColor.secondary,
        textTransform: 'capitalize',
        fontWeight: 400,
        '&.Mui-focused': {
          color: textColor.secondary,
        },
        // "@media screen and (min-width: 599px)": {
        // 	fontSize: fontSize.size7,
        // }
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 2,
        // '&.yellow': {
        // 	backgroundColor: otherColor.yellowLight,
        // 	'& .MuiLinearProgress-bar': {
        // 		backgroundColor: otherColor.yellow,
        // 	}
        // },
        '&.green': {
          backgroundColor: bg.whiteOff2,
          '& .MuiLinearProgress-bar': {
            borderRadius: 2,
            backgroundColor: otherColor.success2,
          },
        },
      },
    },
    MuiInputLabel: {
      // root: {
      // 	textTransform: 'uppercase',
      // 	fontSize: fontSize.size12
      // },
      shrink: {
        transform: `translate(0, 0) scale(1)`,
      },
      asterisk: {
        color: textColor.danger1,
      },
    },
    MuiInputAdornment: {
      root: {
        height: 'auto',
        '&.btn-adornment, &.icon-adornment': {
          width: 24,
        },
      },
      positionStart: {
        marginRight: `calc(8px)`,
      },
      positionEnd: {
        marginLeft: `calc(8px)`,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: bg.white,
        border: `1px solid #c0c0c0`,
        borderRadius: 4,
        transition: `color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        '& .MuiInputAdornment-root': {
          // color: textColor.grayColor,
          color: textColor.grayColor1,
          transition: `color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
          '& svg': {
            // fill: 'currentColor',
            // color: textColor.primary,
            maxWidth: 18,
            maxHeight: 18,
          },
        },
        '&.read-only': {
          '& input': {
            color: textColor.grayColor1,
          },
        },
        '&[data-size=small] .MuiInputAdornment-root svg': {
          width: 18,
          height: 18,
        },
        '&:focus': {
          outline: 0,
        },
        '&.Mui-focused': {
          borderColor: 'transparent',
          boxShadow: inputBoxShadow,
        },
      },
      input: {
        boxSizing: 'content-box !important',
        fontSize: fontSize.size8,
        fontWeight: fontWeight.regular,
        color: textColor.primary,
        '&::-webkit-input-placeholder': {
          color: textColor.grayColor,
          opacity: 1,
        },
        '&::-moz-placeholder': {
          color: textColor.grayColor,
          opacity: 1,
        },
        '&:-moz-placeholder': {
          color: textColor.grayColor,
          opacity: 1,
        },
        '&&:-ms-input-placeholder': {
          color: textColor.grayColor,
          opacity: 1,
        },
        '&:placeholder': {
          color: textColor.grayColor,
          opacity: 1,
        },
        // borderRadius: borderRadius,
        // fontSize: fontSize.size8,
        // border: '1px solid ' + border.light,
        // backgroundColor: 'white',
        // padding: '7px 10px',
        // transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        // lineHeight: 1.6,
        // '-webkit-apperance': 'none',
        // '-moz-apperance': 'none',
        // '-ms-apperance': 'none',
        // '-o-apperance': 'none',
        // 'apperance': 'none',
        // '&:hover': {
        // 	borderColor: primaryColor.main,
        // },
        // '&:focus': {
        // 	borderColor: primaryColor.main,
        // },
        // '&.Mui-disabled': {
        // 	backgroundColor: otherColor.inputDisableBg,
        // 	cursor: 'not-allowed',
        // 	'&:hover': {
        // 		borderColor: border.light
        // 	}
        // },
        // "@media screen and (min-width: 599px)": {
        // 	fontSize: fontSize.size7,
        // 	padding: '9px 12px',
        // },
      },
    },
    MuiSelect: {
      select: {
        borderRadius,
        backgroundColor: bg.whiteOff1,
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
        '&:focus': {
          backgroundColor: bg.whiteOff1,
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: 40,
        color: textColor.grayColor,
        fontSize: fontSize.size8,
        paddingTop: 9.5,
        paddingBottom: 9.5,
        '&:hover': {
          backgroundColor: bg.whiteOff1,
          color: textColor.primary,
        },
        '&.Mui-selected': {
          backgroundColor: primaryColor.main,
          color: primaryColor.contrastText,
          '&:hover': {
            backgroundColor: primaryColor.main,
            color: primaryColor.contrastText,
          },
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        '&.Mui-disabled': {
          color: textColor.disabled,
          cursor: 'not-allowed',
        },
      },
      label: {
        '&.Mui-disabled': {
          color: textColor.disabled,
          cursor: 'not-allowed',
        },
        '&.MuiTypography-root.Mui-disabled': {
          color: textColor.disabled,
        },
      },
    },
    MuiButtonBase: {
      disableRipple: true,
      root: {
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiAppBar: {
      root: {
        '&.custom-app-bar': {
          backgroundColor: 'transparent',
          boxShadow: 'none !important',
        },
        '& .MuiTabs-scrollButtons:first-child': {
          marginLeft: -40,
        },
        '& .MuiTabs-scrollButtons:last-child': {
          marginRight: -40,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
        // borderBottom: `1px solid ${border.light} `,
        '&.tabs-style-2': {
          '& .MuiTabScrollButton-root': {
            // opacity: 1,
            cursor: 'pointer',
            '&.Mui-disabled': {
              width: 0,
            },
          },
          borderBottom: `1px solid transparent`,
          '& .MuiTabs-scroller': {
            border: `1px solid ${bg.whiteOff2}`,
            borderRadius: 5,
            backgroundColor: bg.whiteOff4,
            // display: 'flex',
            '& .MuiTab-root': {
              fontSize: 14,
              minHeight: 36,
              padding: '7px 10.5px',
              color: textColor.primary,
              textTransform: 'capitalize',
              // borderRight: '1px solid ' + bg.whiteOff2,
              '&:last-child': {
                borderRight: 'none',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'transparent',
            },
            '& .Mui-selected': {
              backgroundColor: primaryColor.main,
              // boxShadow: '4px 4px 12px #0000001A',
              borderColor: 'transparent',
              color: primaryColor.contrastText,
              '& .MuiBox-root': {
                color: primaryColor.contrastText,
              },
            },
          },
        },
      },
      vertical: {
        borderBottom: 0,
        '& .MuiTabs-scroller': {
          zIndex: 0,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: bg.whiteOff1,
          width: 'auto',
          left: 0,
          borderRadius: 6,
          zIndex: -1,
        },
        '& .MuiTab-root': {
          padding: `calc(8px * 1.5) calc(8px * 1.5)`,
          borderRadius: 6,
          transition: 'background-color 0.3s ease',
          backgroundColor: 'transparent',
          '& + .MuiTab-root': {
            marginTop: `calc(8px * 1)`,
          },
          '&:hover': {
            // backgroundColor: bg.whiteOff1,
            color: textColor.primary,
            '& .MuiTab-wrapper': {
              // color: textColor.primary
            },
          },
          '&.Mui-selected': {
            backgroundColor: primaryColor.lighter,
            '& .MuiTab-wrapper': {
              color: primaryColor.main,
            },
          },
          '&.Mui-selected:hover': {
            color: textColor.primary,
          },
        },
        '& .MuiTab-wrapper': {
          textAlign: 'left',
          alignItems: 'flex-start',
          transition: 'color 0.3s ease',
          color: textColor.primary,
        },
      },
      indicator: {
        // backgroundColor: primaryColor.main,
        height: 4,
      },
    },
    MuiTab: {
      root: {
        color: textColor.secondary,
        minWidth: 100,
        minHeight: 40,
        // textTransform: 'capitalize',
        fontWeight: fontWeight.medium,
        padding: '12px 0',
        '@media (min-width: 600px)': {
          minWidth: 40,
        },
        '&.Mui-selected': {
          color: textColor.primary,
          // color: primaryColor.main,
        },
        '& .MuiTab-wrapper': {
          display: 'block',
          '&::first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
      textColorInherit: {
        color: textColor.secondary,
      },
    },
    MuiTooltip: {
      // popper: {
      // 	zIndex: zIndex.popperTooltip
      // }
      tooltip: {
        backgroundColor: otherColor.black,
        fontSize: fontSize.size13,
        '& .MuiTooltip-arrow': {
          color: otherColor.black,
        },
      },

      // MuiTooltip: {
      // 	backgroundColor: bg.black,
      // 	'& :MuiTooltip-arrow': {
      // 		color: bg.black
      // 	}
      // },
    },
    MuiBreadcrumbs: {
      // separator: {
      // 	color: textColor.disabled,
      // }
    },
    MuiDialog: {
      // root: {
      // 	zIndex: zIndex.popperTooltip + ' !important',
      // },
      // paper: {
      // 	margin: '24px',
      // },
      // paperScrollPaper: {
      // 	maxHeight: 'calc(100% - 48px)'
      // }
    },

    MuiChip: {
      root: {
        minWidth: 50,
        height: 28,
        '&.text-success': {
          backgroundColor: otherColor.tagbg_green,
          color: otherColor.success2,
        },
        '&.text-warning': {
          backgroundColor: otherColor.tagbg_yellow,
          color: otherColor.tag_yellow,
        },
        '&.text-danger': {
          backgroundColor: otherColor.tagbg_red,
          color: otherColor.tag_red,
        },
        '&.text-success2': {
          backgroundColor: otherColor.success2Bg,
          color: otherColor.success2,
        },
        '&.text-warn2': {
          backgroundColor: otherColor.warn2Bg,
          color: otherColor.warn2,
        },
        '&.text-primary': {
          backgroundColor: otherColor.tagbg_pink,
          color: otherColor.danger,
        },
        '&.text-secondary': {
          backgroundColor: otherColor.tagbg_gray,
          color: otherColor.tag_gray,
        },
        '&.text-info': {
          backgroundColor: otherColor.infoLight,
          color: otherColor.info,
        },
        '&.label-tag-1': {
          backgroundColor: otherColor.tagbg_magenta,
          color: otherColor.tag_magenta,
        },
        '&.label-tag-2': {
          backgroundColor: otherColor.tagbg_orange,
          color: otherColor.tag_orange,
        },
        '&.text-primaryWarning': {
          backgroundColor: primaryColor.primaryLight,
          color: textColor.main,
        },
        // '&.label-tag-4': {
        //   backgroundColor: otherColor.tagbg_magenta,
        //   color: otherColor.tag_magenta,
        // },
      },
    },
    MuiPaper: {
      root: {
        '&.MuiPaper-elevation1': {
          borderRadius: 10,
          boxShadow: 'none',
          border: `1px solid ${border.light}`,
        },
      },
    },
    MuiAvatar: {
      root: {
        //	border: '1px solid ' + border.lighter
      },
      colorDefault: {
        backgroundColor: bg.whiteOff2,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: fontSize.size12,
        marginTop: 7,
      },
    },
  },
};
