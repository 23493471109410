/* eslint-disable no-param-reassign */
import {
  COOKIE_CONSENT_TOGGLE,
  STORAGE_PREF_TOGGLE,
  COOKIE_CONSENT_LOADING,
  COOKIE_CONSENT_UPDATE,
} from 'redux/actions/cookie-consent-action';
import { actionTypes } from 'redux/actions/types';

const { COOKIE_RECORD_LIST, COOKIE_RECORD } = actionTypes;

const initialState = {
  isListLoading: false,
  isShow: true,
  isShowStoragePref: false,
  clientStorageId: '',
  details: { consentData: {} },
  config: {
    bannerMessage: '',
    dialogType: 'box',
    dialogPosition: 'Floating bottom-left',
  },
};

const cookieConsentInfo = (state = { ...initialState }, action) => {
  switch (action.type) {
    case COOKIE_CONSENT_TOGGLE:
      state = { ...state, ...action.data };
      break;
    case STORAGE_PREF_TOGGLE:
      state = { ...state, ...action.data };
      break;
    case COOKIE_RECORD_LIST:
      state = { ...state, ...action.payload };
      break;
    case COOKIE_RECORD:
      state = { ...state, ...action.payload };
      break;
    case COOKIE_CONSENT_LOADING:
      state = { ...state, ...action.data };
      break;
    case COOKIE_CONSENT_UPDATE:
      state = { ...state, details: { consentData: action.payload } };
      break;
    default:
      break;
  }
  return state;
};

export default cookieConsentInfo;
