import { SET_EVENT_LANGUAGES } from "redux/actions/event-languages-action";



const eventLanguagesReducer = (state = [], action) => {
    switch (action.type) {
        case SET_EVENT_LANGUAGES:
            return [...action.payload]
        default:
            return state;
    }
}

export default eventLanguagesReducer;