/* eslint-disable react/self-closing-comp */
import React from 'react';

export default function SvgFaceBookIcon(props) {
  return (
    <svg fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.3 15.7" {...props}>
      <path d="M7.3,5.1H4.8V3.4c0-0.6,0.4-0.8,0.7-0.8c0.3,0,1.7,0,1.7,0V0L4.8,0C2.2,0,1.5,2,1.5,3.3v1.8H0v2.8h1.5c0,3.5,0,7.8,0,7.8h3.3c0,0,0-4.3,0-7.8H7L7.3,5.1z"></path>
    </svg>
  );
}
