import React from 'react';

export default function SvgAdvancedPlanIconFilled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71429 17.5413C8.71429 17.8582 8.63605 18.1702 8.48652 18.4496C8.337 18.729 8.12081 18.9671 7.85714 19.1429C7.59348 19.3187 7.37729 19.5568 7.22776 19.8362C7.07824 20.1156 7 20.4276 7 20.7445V22H17.2857V20.7445C17.2857 20.4276 17.2075 20.1156 17.058 19.8362C16.9084 19.5568 16.6922 19.3187 16.4286 19.1429C16.1649 18.9671 15.9487 18.729 15.7992 18.4496C15.6497 18.1702 15.5714 17.8582 15.5714 17.5413V16.8572H8.71429V17.5413Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8125 4.46154C10.0666 4.46154 9.35121 4.78571 8.82376 5.36275C8.29632 5.93978 8 6.72241 8 7.53846V14.9231L9.6875 18H15.3125V13.0769L11.9375 8.76923V8.15385L15.3125 9.38462H17V7.53846L13.625 4.46154V2L11.375 2.61538L10.8125 4.46154Z"
        fill="#F59C86"
      />
    </svg>
  );
}
