import React from 'react';

export default function SvgItalianFlag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M24.0001 11.9999C24.0001 6.84031 20.7436 2.44179 16.174 0.746277V23.2535C20.7436 21.558 24.0001 17.1595 24.0001 11.9999Z"
          fill="#D80027"
        />
        <path
          d="M0 11.9999C0 17.1595 3.2565 21.558 7.82611 23.2535V0.746277C3.2565 2.44179 0 6.84031 0 11.9999Z"
          fill="#6DA544"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
