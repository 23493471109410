import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useCustomCreateEventContext } from './context';
import { CREATE_EVENT_TYPE_LIST } from 'api-setup/api-endpoints';
import useFetch from './common/fetch';

import { useStyles } from 'pages/CreateNewEvent/style';
import EventTypeLoader from 'pages/CreateNewEvent/Loaders/EventTypeScreenLoader';
import { EActionType, EEventFormat } from 'pages/CreateNewEvent/common/constants';
import CreateEventFooter from './CreateNewEventLayout/CreateEventFooter';
import CreateNewEventRadioBtn from 'custom-components/form-fields/CreateNewEventRadioBtn';
import { useHistory, useParams } from 'react-router';
import { getActionFromPath } from './common/utils';

import SvgConferenceIcon from 'icons/svgConferenceIcon';
import SvgWebinarIcon from 'icons/svgWebinarIcon';
import SvgJobFairIcon from 'icons/svgJobFairIcon';
import SvgWorkshopIcon from 'icons/svgWorkshopIcon';
import SvgCorporateIcon from 'icons/svgCorporateIcon';
import SvgExhibitionIcon from 'icons/svgExhibitionIcon';
import { createEventTrackAmplitute } from 'lib/amplitude/amplitude-tracking';
import ProjectSetting, { restrictingCharacerInput } from 'settings';
import { useSelector, shallowEqual } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';
import SvgProductEducationIcon from 'icons/svgProductEducationIcon';
import SvgProductLaunchIcon from 'icons/svgProductLaunchIcon';
import SvgBrandBuildingIcon from 'icons/svgBrandBuildingIcon';
import SvgPartnerMarketingIcon from 'icons/svgPartnerMarketingIcon';
import SvgUserGroupMeetingIcon from 'icons/svgUserGroupMeetingIcon';
import SvgPanelDiscussionIcon from 'icons/svgPanelDiscussionIcon';
import SvgSalesKickOff from 'icons/svgSalesKickoff';
import SvgMarketing from 'icons/svgMarketing';
import SvgDemandGenerationWebinarIcon from 'icons/svgDemandGenerationWebinarIcon';
import SvgUserConferenceIcon from 'icons/svgUserConferenceIcon';
import SvgThoughtLeadershipIcon from 'icons/svgThoughtLeadershipIcon';
import SvgProductDemonstrationIcon from 'icons/svgProductDemonstrationIcon';
import SvgCustomerTrainingIcon from 'icons/svgCustomerTrainingIcon';
import InputField from 'custom-components/form-fields/inputField';
import { alphabetsSpaceRegEx } from 'models/regex';

// svgObject - stores the static svg icons for all event types exept webinar event
const svgObject = {
  'svg-category-corporate': <SvgCorporateIcon />,
  'svg-category-workshop': <SvgWorkshopIcon />,
  'svg-category-conference': <SvgConferenceIcon />,
  'svg-category-exhibition': <SvgExhibitionIcon />,
  'svg-category-external-job-fair': <SvgJobFairIcon />,
  'svg-category-webinar': <SvgWebinarIcon />,
  'svg-category-product-education': <SvgProductEducationIcon />,
  'svg-category-product': <SvgProductLaunchIcon />,
  'svg-category-brand-building': <SvgBrandBuildingIcon />,
  'svg-category-partner-marketing': <SvgPartnerMarketingIcon />,
  'svg-category-user-group-meeting': <SvgUserGroupMeetingIcon />,
  'svg-category-panel-discussion': <SvgPanelDiscussionIcon />,
  'svg-category-saleskickoff': <SvgSalesKickOff />,
  'svg-category-community': <SvgConferenceIcon />,
  'svg-category-marketing': <SvgMarketing />,
  'svg-category-demand-generation-webinar': <SvgDemandGenerationWebinarIcon />,
  'svg-category-user-conference': <SvgUserConferenceIcon />,
  'svg-category-thought-leadership': <SvgThoughtLeadershipIcon />,
  'svg-category-product-demonstration': <SvgProductDemonstrationIcon />,
  'svg-category-customer-training': <SvgCustomerTrainingIcon />,
  'svg-category-lead-gen-activity': <SvgSalesKickOff />,
};

const EventType = ({ isLoading: screenLoading }) => {
  const classes = useStyles();

  // showOtherEventTypes  - is a boolean used to handle the show/hide the more event types
  const [showOtherEventTypes, setShowOtherEventType] = useState(false);
  // accessing the context data
  const { state, dispatch } = useCustomCreateEventContext();

  // extracting eventMetaTypeId from state
  const { eventMetaTypeId, customEventType = '' } = state;

  // selectedType - contains the event type selected by user.
  const [selectedType, setSelectedType] = useState(eventMetaTypeId);

  // typeOptions - stores all the event types
  const [typeOptions, setTypeOptions] = useState([]);

  // showTextField - shows the input field when other option is selected
  const [showTextField, setShowTextField] = useState(false);

  const [otherText, setOtherText] = useState(customEventType || '');
  const [otherTextErr, setOtherTextErr] = useState(null);

  const params = useParams();
  const history = useHistory();
  // screen - represents the current screen (ex: format, type, details, language)
  const screen = Number(params.screenId);

  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  // userInfo - the data of logged in user
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  // planname - the organizer planname (ex: planless, pro etc)
  const planName = (userInfo && userInfo?.UserInfo?.planName) || 'Planless';

  useEffect(() => {
    setSelectedType(eventMetaTypeId);
  }, [eventMetaTypeId]);

  // handleChange - triggeres when user interact with the event types
  const handleChange = (event) => {
    setSelectedType(event.target.value);
    if (showTextField) {
      setShowTextField(false);
      setOtherText('');
      setOtherTextErr(null);
    }
  };

  // apiData is the copy of event api data for edit event
  const apiData = state?.editDetails;

  const format = state?.format;
  // using the custom hook to fetch the data
  const [data, isLoading] = useFetch(
    `${CREATE_EVENT_TYPE_LIST}?format=${format?.toLowerCase()}`,
    format !== ''
  );

  useEffect(() => {
    setOtherText(customEventType);
    setOtherTextErr(null);
    if (data?.more_values && eventMetaTypeId) {
      const selectedData = data.more_values.find((item) => item.id === eventMetaTypeId);
      if (selectedData && Object.keys(selectedData)) {
        setShowOtherEventType(true);
        if (selectedData.category === 'Other') setShowTextField(true);
        else setShowTextField(false);
      } else {
        setShowOtherEventType(false);
        setShowTextField(false);
      }
    }
  }, [eventMetaTypeId, customEventType, data]);

  // findName - this returns the event type name that is selected by the organizer
  const findName = () => {
    let index = -1;
    if (data.top?.length > 0 && data.more_values?.length > 0 && Number(selectedType)) {
      index = data.top.findIndex((item) => Number(item.id) === Number(selectedType));
    }
    if (index !== -1) return data.top[index]?.category;
    else {
      index = data.more_values.findIndex((item) => Number(item.id) === Number(selectedType));
      if (index !== -1) return data.more_values[index].category;
      else return '';
    }
  };

  // this triggers on data
  useEffect(() => {
    if (!data || !data?.top) return;

    // result - contains the data with added svg icons
    const result = data.top.map((item) => {
      return {
        ...item,
        value: item.id,
        isSvgIcon: <span className="radioBtnIcon">{svgObject[item.image_name]}</span>,
      };
    });
    // checking the selected type is exists in more values or not.
    // If we found then we are expanding the view more for the first time when user comeback to this page.
    const index = data.more_values.findIndex((item) => item.id === selectedType);
    if (index !== -1) {
      setShowOtherEventType(true);
    }
    setTypeOptions(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isOther = useMemo(() => {
    if (data?.more_values && selectedType) {
      const index = data?.more_values.findIndex((item) => Number(item.id) === Number(selectedType));
      if (index !== -1 && data?.more_values[index].category === 'Other') return true;
      else return false;
    }
    return false;
  }, [data, selectedType]);

  // store the type in redux on click of next and doing the amplitude tracking
  const onNext = () => {
    const isValidOther = alphabetsSpaceRegEx.test(otherText?.trim());
    if (isOther && isValidOther) {
      setOtherTextErr('Incorrect event type.');
    } else {
      if (actionType === EActionType.EDIT) {
        createEventTrackAmplitute('EditEventTypeNextButtonClicked', {
          event_type_edited: findName(),
          parent_screen: 'EDIT_EVENT_TYPE',
          funnel_entry_section: 'EDIT_EVENT_FUNNEL',
          app_version: ProjectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          plan_type: planName,
        });
        createEventTrackAmplitute('EditEventDetailsScreenViewed', {
          referrer_screen: 'EDIT_EVENT_TYPE',
          funnel_entry_section: 'EDIT_EVENT_FUNNEL',
          app_version: ProjectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          plan_type: planName,
        });
      } else {
        createEventTrackAmplitute('EventTypeNextButtonClicked', {
          event_type_selected: findName(),
          parent_screen: 'EVENT_TYPE',
          funnel_entry_section: 'CREATE_EVENT_FUNNEL',
          event_type: findName(),
          customEventType: otherText || undefined,
          app_version: ProjectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          plan_type: planName,
        });
        createEventTrackAmplitute('EventDetailsScreenViewed', {
          referrer_screen: 'EVENT_TYPE',
          funnel_entry_section: 'CREATE_EVENT_FUNNEL',
          app_version: ProjectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          plan_type: planName,
        });
      }

      let payload = {
        id: selectedType,
        name: findName(),
      };
      if (findName() === 'Other') payload = { ...payload, customEventType: otherText };
      dispatch({ type: 'type', payload });

      if (actionType === EActionType.CREATE) history.push(`/events/create/${screen + 1}`);
      else history.push(`/events/${params?.eventId}/${params?.organiserId}/edit/${screen + 1}`);
    }
  };

  // onPrevious - triggers when organizer clicks on previous button and here we are doing the amplitude tracking
  const onPrevious = () => {
    if (actionType === EActionType.EDIT) {
      createEventTrackAmplitute('EditEventTypePreviousButtonClicked', {
        parent_screen: 'EDIT_EVENT_TYPE',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        event_type_edited: findName(),
        customEventType: otherText || undefined,
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    } else {
      createEventTrackAmplitute('EventTypePreviousButtonClicked', {
        parent_screen: 'EVENT_TYPE',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        event_type_selected: findName(),
        event_type: findName(),
        customEventType: state.customEventType || undefined,
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
      createEventTrackAmplitute('EventFormatScreenViewed', {
        referrer_screen: 'EVENT_TYPE',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: ProjectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    }
  };

  // if loading return loading screen
  if (isLoading || screenLoading) {
    return <EventTypeLoader />;
  }

  if (!data) return null;

  return (
    <>
      <Box className={classes.eventTypeScreen}>
        <Box className={` ${classes.eventType}`} mt={3}>
          <CreateNewEventRadioBtn
            formControlClassName="createEventOption"
            name="eventType"
            handleChange={handleChange}
            defaultValue={Number(selectedType)}
            value={selectedType}
            options={typeOptions}
          />

          {showOtherEventTypes && (
            <Box className="otherEventType" mt={1}>
              <Typography component="h6" variant="h6" color="textPrimary">
                Other event types
              </Typography>
              <Box className="otherEventTypeList" mt={2}>
                {data.more_values &&
                  data.more_values.map((item) => {
                    return (
                      <Box
                        className={`otherEventTypeItem ${
                          Number(selectedType) === Number(item.id) ? 'active' : ''
                        }`}
                        key={item.id}
                        onClick={() => {
                          if (item.category === 'Other') {
                            setShowTextField(true);
                          } else {
                            setShowTextField(false);
                          }
                          setSelectedType(item.id);
                        }}>
                        {item.category}
                      </Box>
                    );
                  })}
                {/* <Box className='otherEventTypeItem active'>Hackathon</Box>
                 */}
              </Box>
            </Box>
          )}
          {showTextField && (
            <Box mt={1} className="otherTextInput">
              <InputField
                value={otherText}
                label="Your event type"
                onChange={(e) => {
                  setOtherText(e.target.value);
                  setOtherTextErr(null);
                }}
                error={otherTextErr}
                placeholder="Enter your event type"
                type="text"
                required
                maxLength={restrictingCharacerInput?.event?.customEventType || 32}
              />
            </Box>
          )}
          <Box className={classes.viewMoreEventType} fontWeight="500">
            <Typography
              variant="body2"
              component="p"
              className={`viewMoreEventType ${showOtherEventTypes ? 'viewLessEventType' : ''}`}
              onClick={() => {
                setShowOtherEventType(!showOtherEventTypes);
                if (showOtherEventTypes && showTextField) setShowTextField(false);
                if (!showOtherEventTypes && findName() === 'Other') setShowTextField(true);
                if (!showOtherEventTypes)
                  createEventTrackAmplitute('ViewMoreEventTypesButtonClicked', {
                    parent_screen: `${
                      actionType === EActionType.EDIT ? 'EDIT_EVENT_TYPE' : 'EVENT_TYPE'
                    }`,
                    funnel_entry_section: `${
                      actionType === EActionType.EDIT ? 'EDIT_EVENT_FUNNEL' : 'CREATE_EVENT_FUNNEL'
                    }`,
                    app_version: ProjectSetting.buildversion,
                    user_id: userInfo.UserInfo.id,
                    organisation: userInfo.UserInfo.organisation,
                    group:
                      userInfo.UserInfo.is_team_member_loggedin === true
                        ? 'TEAM MEMBER'
                        : 'ORGANISER',
                    plan_type: planName,
                  });
              }}>
              {!showOtherEventTypes ? 'View more' : 'View less'}
            </Typography>
          </Box>
        </Box>
        <CreateEventFooter
          onNext={onNext}
          onPrevious={onPrevious}
          hidePrevious={
            actionType === EActionType.EDIT && apiData && apiData.type === EEventFormat.WEBINAR
          }
          disabledNext={!selectedType || (showTextField && otherText?.trim() === '')}
        />
      </Box>
    </>
  );
};

export default EventType;
