/* eslint-disable no-param-reassign */
import { SET_MULTI_EVENT_DATA, REMOVE_MULTI_EVENT_DATA } from 'redux/actions/multi-analytics-event-action';

const multiEventData = (state = {}, action) => {
    switch (action.type) {
        case SET_MULTI_EVENT_DATA:
            state = { ...state, ...action.data };
            break;
        case REMOVE_MULTI_EVENT_DATA:
            state = {};
            break;
        default:
            break;
    }
    return state;
}

export default multiEventData;