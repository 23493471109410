/* eslint-disable  */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { InputBase, InputAdornment, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FieldWrapper from './fieldWrapper';
import { getTotalLength } from 'pages/settings/eventSectionsNewUI';

const useStyles = makeStyles((theme) => ({
  borderDanger: {
    border: `1px solid ${theme.palette.text.danger1} !important`,
  },
  textAdornment: {
    '&>.MuiTypography-root': {
      color: '#686868',
      fontSize: '0.75rem',
    },
  },
  textAdornmentStyle: {
    borderRadius: theme.shape.borderRadius,
    fontSize: `${theme.fontSize.size8}`,
    border: 'none',
    color: `${theme.palette.border.dark} !important`,
    // padding: `${theme.spacing(2.1)}px ${theme.spacing(2)}px`,
    // width: '100%',
    backgroundColor: `${theme.palette.bg.white}`,
    maxHeight: 'none',
    // boxSizing: 'border-box',
    // '&:focus': {
    // 	outline: 'none',
    // },
    '& p': {
      fontSize: `${theme.fontSize.size8}`,
    },
    'label + &': {
      marginTop: theme.spacing(3.3),
    },
    '&.medium': {
      margin: `${theme.spacing(-1.45)}px 0 ${theme.spacing(-1.425)}px 0`,
      padding: `${theme.spacing(1.45)}px ${theme.spacing(1.5)}px`,
    },

    '&.small': {
      margin: `${theme.spacing(-1)}px 0 ${theme.spacing(-1)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    },
    '&.MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(-1.5),
      marginLeft: theme.spacing(1.5),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&.MuiInputAdornment-positionStart': {
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(1.5),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  charAdornmentStyle: {
    fontSize: `${theme.fontSize.size8}`,
    border: 'none',
    // color: `${theme.palette.text.primary} !important`,
    maxHeight: 'none',
    '& p': {
      fontSize: `${theme.fontSize.size8}`,
    },
    'label + &': {
      marginTop: theme.spacing(3.3),
    },
    '&.medium': {
      margin: `${theme.spacing(-1.45)}px 0 ${theme.spacing(-1.425)}px 0`,
      padding: `${theme.spacing(1.45)}px ${theme.spacing(1.5)}px`,
    },

    '&.small': {
      margin: `${theme.spacing(-1)}px 0 ${theme.spacing(-1)}px 0`,
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    },
    '&.MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(-1.5),
      marginLeft: theme.spacing(1.5),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&.MuiInputAdornment-positionStart': {
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(1.5),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  borderHighlightML: {
    borderColor: theme.palette.alert.warn2,
    boxShadow: `0px 0px 4px ${theme.palette.alert.warn2}`,
  },
  partitionLine: {
    paddingLeft: theme.spacing(1),
    '&::before': {
      background: theme.palette.text.grayColor1,
      position: 'absolute',
      height: '100%',
      content: `""`,
      top: 0,
      right: '3rem',
      width: '1px',
    },
  },
}));
const Input = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    '&[data-size=small]': {
      padding: `${theme.spacing(0.1)}px ${theme.spacing(1.5)}px`,
    },
    '&.Mui-disabled': {
      background: theme.palette.bg.whiteOff1,
      cursor: 'not-allowed',
      '& input': {
        color: theme.palette.text.grayColor,
        cursor: 'not-allowed',
        background: theme.palette.bg.whiteOff1,
      },
    },
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  input: {
    transition: theme.transitions.create(['border-color', 'color']),
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const InputField = (props) => {
  const classes = useStyles();
  const { isShowLess, autoComplete = 'off' } = props;
  const [showFieldValue, toggleFieldValue] = useState(0);
  const [inputLength, setInputLength] = useState(0);
  const onEnterPress = (event) => {
    if (!event.shiftKey && (event.keyCode === 13 || event.which === 13 || event.key === 'Enter')) {
      props.onEnter(event.target.value);
    }
  };

  useEffect(() => {
    if (props.maxLength) {
      if (props.value && props.value.toString().length > 0) {
        setInputLength(props.maxLength - props.value.toString().length);
      } else {
        setInputLength(props.maxLength);
      }
    }
  }, [props.maxLength, props.value]);

  useEffect(() => {
    if (props.charLimit) {
      if (props.value && props.value.toString().length > 0) {
        setInputLength(props.charLimit - props.value.toString().length);
      } else {
        setInputLength(props.charLimit);
      }
    }
  }, [props.charLimit, props.value]);

  React.useEffect(() => {
    if (props.inputRef && !props?.noFocus && props.autofocus) {
      props.inputRef.current.focus();
    }
  }, []);

  const handelChangeInput = (e) => {
    if (!props.maxLength) {
      props.onChange(e);
      return;
    }

    if (props.charLimit) {
      let currentInput = e.target.value;
      let trimmedInput = currentInput.slice(0, props.charLimit);
      e.target.value = trimmedInput;
      if (inputLength > 0 || trimmedInput.length < props.charLimit) {
        const changeRemainingChar = props.onChange(e);

        if (changeRemainingChar === undefined || changeRemainingChar) {
          setInputLength(props.charLimit - trimmedInput.length);
        }
      }
    }
    let currentInput = e.target.value;
    let trimmedInput = currentInput.slice(0, props.maxLength);
    e.target.value = trimmedInput;
    if (inputLength > 0 || trimmedInput.length < props.maxLength) {
      const changeRemainingChar = props.onChange(e);

      if (changeRemainingChar === undefined || changeRemainingChar) {
        setInputLength(props.maxLength - trimmedInput.length);
      }
    }
  };

  const { showMaxLength = true } = props;

  return (
    <>
      <FieldWrapper
        charHelperText={
          props.maxLength && props.isShowCustomDescText && props.ToolTipCharCount
            ? `${inputLength}${inputLength <= 1 ? ' Character' : ' Characters'}`
            : ''
        }
        formControlClassName={props.formControlClassName}
        label={props.label}
        id={props.id}
        error={props.error}
        showErrorMsg={props.showErrorMsg}
        required={props.required}
        descType={props.descType}
        descText={
          props.maxLength && !props.isShowCustomDescText && showMaxLength
            ? `${inputLength}${
                inputLength <= 1 ? ' Character' : ` Characters ${isShowLess ? 'or less' : ''}`
              }`
            : props.descText
        }>
        <Input
          disabled={props.disabled}
          className={`w-100 ${props.readOnly ? 'read-only' : ''} ${props.className || ''} ${
            props.inputClassName || ''
          } ${props.error ? classes.borderDanger : ''} ${
            props.isLanguageSupport ? classes.borderHighlightML : ''
          }`}
          type={showFieldValue ? 'text' : props.type || 'text'}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          id={props.id}
          onClick={props.onClick}
          data-size={props.size || 'medium'}
          onChange={handelChangeInput}
          onFocus={props.onFocus}
          // onEnter={props.onEnter}
          autofocus={props.autofocus}
          onKeyDown={props.onKeyDown}
          onKeyPress={
            props.onKeyPress ? props.onKeyPress : props.onEnter ? onEnterPress : undefined
          }
          aria-describedby={`${props.id ? `${props.id}-` : ''}help-text`}
          error={!!props.error}
          inputRef={props.inputRef}
          minLength={0}
          inputProps={{
            maxLength: props.maxLength || props.charLimit,
            min: props.minValue,
            max: props.maxValue,
            step: props.step,
            style: {
              // paddingRight: classes.textAdornment ? 12 + classes.textAdornment.length * 7 : 12
            },
            autoComplete,
          }}
          startAdornment={
            props.bothActionIconBtn ? (
              !props.startAdornment ? (
                false
              ) : props.startTextAdornment ? (
                <InputAdornment
                  className={`${classes.textAdornmentStyle} ${props.size || 'medium'}`}
                  position="start">
                  {props.textAdornment}
                </InputAdornment>
              ) : props.startIconAdornment ? (
                <InputAdornment className="icon-adornment" position="start">
                  {props.startAdornmentIcon}
                </InputAdornment>
              ) : props.startIconBtnAdornment ? (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    disabled={props.leftBtnDisabled}
                    onClick={props.onLeftBtnClick}
                    onMouseDown={props.onBtnMouseDown}>
                    {props.startBtnIcon}
                  </IconButton>
                </InputAdornment>
              ) : (
                false
              )
            ) : !props.startAdornment ? (
              false
            ) : props.startTextAdornment ? (
              <InputAdornment
                className={`${classes.textAdornmentStyle} ${props.size || 'medium'}`}
                position="start">
                {props.textAdornment}
              </InputAdornment>
            ) : props.startIconAdornment ? (
              <InputAdornment className="icon-adornment" position="start">
                {props.startAdornmentIcon}
              </InputAdornment>
            ) : props.startIconBtnAdornment ? (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={props.onBtnClick}
                  onMouseDown={props.onBtnMouseDown}>
                  {props.startBtnIcon}
                </IconButton>
              </InputAdornment>
            ) : (
              false
            )
          }
          endAdornment={
            props.showFieldVisibilityBtn ? (
              <InputAdornment className="btn-adornment" position="end">
                <IconButton
                  size="small"
                  tabIndex="-1"
                  onClick={() => toggleFieldValue(!showFieldValue)}
                  onMouseDown={props.onBtnMouseDown}>
                  {showFieldValue ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ) : !props.endAdornment ? (
              false
            ) : props.endTextAdornment ? (
              <>
                {props.charLimit ? (
                  <InputAdornment
                    variant="filled"
                    className={`${classes.charAdornmentStyle} ${props.size || 'medium'}`}
                    position="end">
                    {props.charLimit ? inputLength : ''}
                  </InputAdornment>
                ) : (
                  <InputAdornment
                    variant="filled"
                    className={`${classes.textAdornmentStyle} ${props.size || 'medium'}`}
                    position="end">
                    {props.textAdornment}
                  </InputAdornment>
                )}
              </>
            ) : props.endIconAdornment ? (
              <InputAdornment className="icon-adornment" position="end">
                {props.endAdornmentIcon}
              </InputAdornment>
            ) : props.endIconBtnAdornment ? (
              <InputAdornment
                position="end"
                className={props.isPartitionLine ? classes.partitionLine : false}>
                <IconButton
                  size="small"
                  disabled={props.rightBtnDisabled}
                  onClick={props.onBtnClick}
                  onMouseDown={props.onBtnMouseDown}>
                  {props.endBtnIcon}
                </IconButton>
              </InputAdornment>
            ) : props.endAdornmentCustom ? (
              props.endAdornmentCustom
            ) : (
              false
            )
          }
          onBlur={props.onBlur}
          onPaste={props.onPaste}
        />
        {/* {props.descType === "TOOLTIP" && props.showToolTipCharCount ? <p>{inputLength} characters</p> : ""} */}
      </FieldWrapper>
    </>
  );
};

export default InputField;
