/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

import { NEW_MESSAGE, REMOVE_MESSAGE, HARD_REMOVE_MESSAGE } from 'redux/actions/setting-actions';
import produce from 'immer';


const settings = (
  state = {
    isInitiate: false,
    messageList: [],
  },
  action
) => {
  switch (action.type) {
    case NEW_MESSAGE:
      state = {
        ...state,
        messageList: populateMessage(state.messageList, action.message),
      };
      break;
    case REMOVE_MESSAGE:
      state = {
        ...state,
        messageList: removeMessage(state.messageList, action.index),
      };
      break;
    // not use this case
    case HARD_REMOVE_MESSAGE:
      state = {
        ...state,
        messageList: removeForcefully(state.messageList, action.index),
      };
      break;

    default:
      break;
  }
  return state;
};

export default settings;

const populateMessage = (list, msg) => {
  const id = new Date().getTime();
  const newList = [...list, { ...msg, id }];
  if (newList.length > 3) {
    // keep only last 3 messages
    return newList.slice(-3);
  }
  return newList;
};

const removeMessage = (list = [], id) => {
  const arr = [];
  const index = list.findIndex((x) => x.id === id);
  if (list.length) {
    return list.splice(index, 1);
  } else {
    return arr;
  }
};
const removeForcefully = (list, id) => {
	const index = list.findIndex(x => x.id === id);
	if (index === -1) {
		return list;
	}
	return produce(list, draftState => { draftState.splice(index, 1) });
}
