import React from 'react';

const style = {
  a: {
    opacity: 0.4,
  },
  b: {
    fill: '#e2e2e2',
  },
  c: {
    fill: '#d1d1d1',
  },
  d: {
    fill: '#f4f4f4',
  },
  e: {
    fill: '#686868',
  },
  f: {
    fill: '#686868',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '1.5px',
  },
};
export default function SvgSessionDefaultIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149.658"
      height="130.547"
      viewBox="0 0 149.658 130.547"
      {...props}>
      <g style={style.a} transform="translate(-2688.864 -1800.418)">
        <g transform="translate(2688.864 1916.709)">
          <ellipse style={style.b} cx="74.829" cy="7.128" rx="74.829" ry="7.128" />
        </g>
        <g transform="translate(2708.217 1800.418)">
          <g transform="translate(0)">
            <g transform="translate(14.495 0.489)">
              <path
                style={style.c}
                d="M2816.318,1870.9v-64.971a4.765,4.765,0,0,0-4.765-4.765H2740.7v122.151h70.854a4.764,4.764,0,0,0,4.765-4.764V1870.9Z"
                transform="translate(-2740.699 -1801.167)"
              />
            </g>
            <g transform="translate(12.22 0.489)">
              <path
                style={style.b}
                d="M2812.833,1870.9v-64.971a4.765,4.765,0,0,0-4.765-4.765h-70.854v122.151h70.854a4.764,4.764,0,0,0,4.765-4.764V1870.9Z"
                transform="translate(-2737.215 -1801.167)"
              />
            </g>
            <g transform="translate(0.49 0.489)">
              <path
                style={style.d}
                d="M2722.139,1918.554V1805.932a4.765,4.765,0,0,1,4.765-4.765h-2.887a4.765,4.765,0,0,0-4.765,4.765v109.734a7.652,7.652,0,0,0,7.652,7.652h0A4.764,4.764,0,0,1,2722.139,1918.554Z"
                transform="translate(-2719.251 -1801.167)"
              />
              <g transform="translate(2.063)">
                <path
                  style={style.c}
                  d="M2731.473,1801.167a9.062,9.062,0,0,0-9.062,9.062v108.324a4.764,4.764,0,0,0,4.765,4.764h7.178V1801.167Z"
                  transform="translate(-2722.411 -1801.167)"
                />
              </g>
            </g>
            <g transform="translate(48.716 0.489)">
              <g transform="translate(0.49)">
                <path
                  style={style.c}
                  d="M2793.855,1801.167v57.409l12.562-10.338,13.009,10.338v-57.409"
                  transform="translate(-2793.855 -1801.167)"
                />
              </g>
              <g transform="translate(0 0)">
                <path
                  style={style.e}
                  d="M2793.595,1859.066a.492.492,0,0,1-.49-.49v-57.408h.979v56.371l11.761-9.679a.489.489,0,0,1,.616,0l12.214,9.707v-56.394h.979v57.408a.489.489,0,0,1-.794.383l-12.7-10.091-12.256,10.087A.492.492,0,0,1,2793.595,1859.066Z"
                  transform="translate(-2793.105 -1801.168)"
                />
              </g>
            </g>
            <path
              style={style.e}
              d="M2803.85,1923.549h-75.8A9.563,9.563,0,0,1,2718.5,1914V1809.969a9.562,9.562,0,0,1,9.551-9.551h75.8a5.26,5.26,0,0,1,5.255,5.254v112.622A5.26,5.26,0,0,1,2803.85,1923.549Zm-75.8-122.151a8.581,8.581,0,0,0-8.572,8.571V1914a8.583,8.583,0,0,0,8.572,8.573h75.8a4.28,4.28,0,0,0,4.275-4.275V1805.672a4.279,4.279,0,0,0-4.275-4.274Z"
              transform="translate(-2718.501 -1800.418)"
            />
            <g transform="translate(14.006 0.489)">
              <rect style={style.e} width="0.979" height="122.151" />
            </g>
          </g>
          <g transform="translate(63.845 65.195)">
            <g transform="translate(2.721 13.436)">
              <path
                style={style.d}
                d="M2822.553,1944.2a23.361,23.361,0,0,1,22.306-23.335c-.351-.015-.7-.027-1.057-.027a23.362,23.362,0,1,0,0,46.724c.354,0,.706-.011,1.057-.027A23.36,23.36,0,0,1,2822.553,1944.2Z"
                transform="translate(-2820.44 -1920.834)"
              />
              <g transform="translate(2.113 0.027)">
                <ellipse style={style.c} cx="22.305" cy="23.335" rx="22.305" ry="23.335" />
              </g>
            </g>
            <g transform="translate(2.231 12.946)">
              <path
                style={style.e}
                d="M2843.542,1967.787a23.852,23.852,0,1,1,23.851-23.851A23.879,23.879,0,0,1,2843.542,1967.787Zm0-46.724a22.872,22.872,0,1,0,22.872,22.873A22.9,22.9,0,0,0,2843.542,1921.063Z"
                transform="translate(-2819.69 -1920.084)"
              />
            </g>
            <g transform="translate(9.437 20.152)">
              <g transform="translate(0.489 0.49)">
                <circle style={style.b} cx="16.156" cy="16.156" r="16.156" />
              </g>
              <path
                style={style.e}
                d="M2847.371,1964.411a16.646,16.646,0,1,1,16.646-16.646A16.665,16.665,0,0,1,2847.371,1964.411Zm0-32.313a15.667,15.667,0,1,0,15.667,15.667A15.684,15.684,0,0,0,2847.371,1932.1Z"
                transform="translate(-2830.725 -1931.119)"
              />
            </g>
            <g transform="translate(26.083 38.697)">
              <g transform="translate(0 13.034)">
                <path
                  style={style.f}
                  d="M2856.217,1979.48"
                  transform="translate(-2856.217 -1979.48)"
                />
              </g>
              <path
                style={style.f}
                d="M2856.217,1959.519"
                transform="translate(-2856.217 -1959.519)"
              />
            </g>
            <g transform="translate(22.44 33.156)">
              <g transform="translate(0.489 0.489)">
                <circle style={style.c} cx="3.153" cy="3.153" r="3.153" />
              </g>
              <path
                style={style.e}
                d="M2854.281,1958.318a3.642,3.642,0,1,1,3.643-3.642A3.646,3.646,0,0,1,2854.281,1958.318Zm0-6.305a2.663,2.663,0,1,0,2.663,2.663A2.666,2.666,0,0,0,2854.281,1952.012Z"
                transform="translate(-2850.639 -1951.033)"
              />
            </g>
            <g transform="translate(8.808 53.963)">
              <g transform="translate(0.49 0.488)">
                <line style={style.c} x1="1.485" y2="7.543" />
              </g>
              <path
                style={style.e}
                d="M2830.253,1991.419a.532.532,0,0,1-.1-.009.492.492,0,0,1-.387-.575l1.485-7.543a.49.49,0,0,1,.961.189l-1.484,7.543A.491.491,0,0,1,2830.253,1991.419Z"
                transform="translate(-2829.762 -1982.897)"
              />
            </g>
            <g transform="translate(40.58 53.963)">
              <g transform="translate(0.49 0.488)">
                <line style={style.c} x2="1.475" y2="7.494" />
              </g>
              <path
                style={style.e}
                d="M2880.382,1991.37a.492.492,0,0,1-.481-.4l-1.475-7.494a.49.49,0,0,1,.962-.189l1.475,7.495a.491.491,0,0,1-.386.575A.525.525,0,0,1,2880.382,1991.37Z"
                transform="translate(-2878.418 -1982.898)"
              />
            </g>
            <g transform="translate(25.593 26.429)">
              <g transform="translate(0.49 0.49)">
                <path
                  style={style.c}
                  d="M2856.217,1948.208v0Z"
                  transform="translate(-2856.217 -1941.482)"
                />
              </g>
              <path
                style={style.e}
                d="M2855.957,1948.438a.49.49,0,0,1-.49-.49v-6.727a.49.49,0,0,1,.979,0v6.727A.49.49,0,0,1,2855.957,1948.438Z"
                transform="translate(-2855.467 -1940.732)"
              />
            </g>
            <g transform="translate(18.907 36.309)">
              <g transform="translate(0.49 0.49)">
                <line style={style.c} x1="3.533" />
              </g>
              <path
                style={style.e}
                d="M2849.251,1956.84h-3.533a.49.49,0,0,1,0-.979h3.533a.49.49,0,0,1,0,.979Z"
                transform="translate(-2845.228 -1955.861)"
              />
            </g>
            <g transform="translate(38.847 14.059)">
              <g transform="translate(0.49 0.491)">
                <line style={style.c} y1="3.01" x2="1.966" />
              </g>
              <path
                style={style.e}
                d="M2876.253,1925.778a.484.484,0,0,1-.267-.08.49.49,0,0,1-.142-.678l1.966-3.01a.49.49,0,1,1,.82.536l-1.965,3.01A.489.489,0,0,1,2876.253,1925.778Z"
                transform="translate(-2875.764 -1921.787)"
              />
            </g>
            <g transform="translate(10.265 14.059)">
              <g transform="translate(0.49 0.491)">
                <line style={style.c} x1="1.966" y1="3.01" />
              </g>
              <path
                style={style.e}
                d="M2834.449,1925.778a.488.488,0,0,1-.411-.222l-1.966-3.01a.49.49,0,0,1,.82-.536l1.966,3.01a.489.489,0,0,1-.409.757Z"
                transform="translate(-2831.993 -1921.787)"
              />
            </g>
            <g transform="translate(0 2.294)">
              <g transform="translate(0.486 0.486)">
                <path
                  style={style.b}
                  d="M2834.642,1909l-16.173,10.11a9.536,9.536,0,0,1,16.173-10.11Z"
                  transform="translate(-2817.018 -1904.515)"
                />
              </g>
              <path
                style={style.e}
                d="M2818.21,1919.34a.458.458,0,0,1-.11-.013.488.488,0,0,1-.3-.218,10.026,10.026,0,0,1,17-10.63.489.489,0,0,1-.156.675l-16.172,10.111A.491.491,0,0,1,2818.21,1919.34Zm8.073-14.589a9.043,9.043,0,0,0-7.905,13.418l15.319-9.578A9.054,9.054,0,0,0,2826.283,1904.75Z"
                transform="translate(-2816.274 -1903.771)"
              />
            </g>
            <g transform="translate(33.839 2.508)">
              <g transform="translate(0.49 0.492)">
                <path
                  style={style.b}
                  d="M2885.214,1919.285l-16.369-9.788a9.536,9.536,0,0,1,16.369,9.788Z"
                  transform="translate(-2868.845 -1904.852)"
                />
              </g>
              <path
                style={style.e}
                d="M2884.954,1919.514a.491.491,0,0,1-.251-.07l-16.369-9.789a.489.489,0,0,1-.169-.671,10.026,10.026,0,1,1,17.211,10.291.491.491,0,0,1-.42.239Zm-15.687-10.442,15.507,9.274a9.047,9.047,0,0,0-15.507-9.274Z"
                transform="translate(-2868.095 -1904.099)"
              />
            </g>
            <g transform="translate(1.156)">
              <g transform="translate(0.49 0.49)">
                <line style={style.c} x2="2.498" y2="3.722" />
              </g>
              <path
                style={style.e}
                d="M2821.033,1904.96a.492.492,0,0,1-.408-.218l-2.5-3.722a.49.49,0,0,1,.814-.546l2.5,3.723a.49.49,0,0,1-.406.763Z"
                transform="translate(-2818.044 -1900.258)"
              />
            </g>
            <g transform="translate(47.611 0.151)">
              <g transform="translate(0.489 0.49)">
                <line style={style.c} x1="2.45" y2="3.11" />
              </g>
              <path
                style={style.e}
                d="M2889.674,1904.579a.483.483,0,0,1-.3-.1.489.489,0,0,1-.082-.687l2.45-3.11a.489.489,0,1,1,.769.606l-2.45,3.11A.485.485,0,0,1,2889.674,1904.579Z"
                transform="translate(-2889.185 -1900.49)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
