export const COOKIE_CONSENT_TOGGLE = 'COOKIE_CONSENT_TOGGLE';
export const STORAGE_PREF_TOGGLE = 'STORAGE_PREF_TOGGLE';
export const COOKIE_CONSENT_LOADING = 'COOKIE_CONSENT_LOADING';
export const COOKIE_CONSENT_UPDATE = 'COOKIE_CONSENT_UPDATE';

export const cookieConsentToggle = (value) => {
  return {
    type: COOKIE_CONSENT_TOGGLE,
    data: value,
  };
};

export const storagePrefToggle = (value) => {
  return {
    type: STORAGE_PREF_TOGGLE,
    data: value,
  };
};

export const cookieConsentLoading = (value) => {
  return {
    type: COOKIE_CONSENT_LOADING,
    data: value,
  };
};
