export const TRANSL_CONST = [
  {
    label: '名',
    code: 'zh-hk',
    subtype: 'firstName',
  },
  {
    label: '姓',
    code: 'zh-hk',
    subtype: 'lastName',
  },
  {
    label: '電子郵件',
    code: 'zh-hk',
    subtype: 'email',
  },
  {
    label: '電話',
    code: 'zh-hk',
    subtype: 'phone',
  },
  {
    label: '名',
    code: 'zh',
    subtype: 'firstName',
  },
  {
    label: '姓',
    code: 'zh',
    subtype: 'lastName',
  },
  {
    label: '电子邮件',
    code: 'zh',
    subtype: 'email',
  },
  {
    label: '电话',
    code: 'zh',
    subtype: 'phone',
  },
  {
    label: 'Prénom',
    code: 'fr',
    subtype: 'firstName',
  },
  {
    label: 'Nom de famille',
    code: 'fr',
    subtype: 'lastName',
  },
  {
    label: 'E-mail',
    code: 'fr',
    subtype: 'email',
  },
  {
    label: 'Téléphone',
    code: 'fr',
    subtype: 'phone',
  },
  {
    label: 'Vorname',
    code: 'de',
    subtype: 'firstName',
  },
  {
    label: 'Nachname',
    code: 'de',
    subtype: 'lastName',
  },
  {
    label: 'E-Mail',
    code: 'de',
    subtype: 'email',
  },
  {
    label: 'Telefon',
    code: 'de',
    subtype: 'phone',
  },
  {
    label: 'नाम',
    code: 'hi',
    subtype: 'firstName',
  },
  {
    label: 'उपनाम',
    code: 'hi',
    subtype: 'lastName',
  },
  {
    label: 'ईमेल',
    code: 'hi',
    subtype: 'email',
  },
  {
    label: 'फ़ोन',
    code: 'hi',
    subtype: 'phone',
  },
  {
    label: 'Nome',
    code: 'it',
    subtype: 'firstName',
  },
  {
    label: 'Cognome',
    code: 'it',
    subtype: 'lastName',
  },
  {
    label: 'Email',
    code: 'it',
    subtype: 'email',
  },
  {
    label: 'Telefono',
    code: 'it',
    subtype: 'phone',
  },
  {
    label: 'Nome',
    code: 'pt',
    subtype: 'firstName',
  },
  {
    label: 'Sobrenome',
    code: 'pt',
    subtype: 'lastName',
  },
  {
    label: 'Email',
    code: 'pt',
    subtype: 'email',
  },
  {
    label: 'Telefone',
    code: 'pt',
    subtype: 'phone',
  },
  {
    label: 'Имя',
    code: 'ru',
    subtype: 'firstName',
  },
  {
    label: 'Фамилия',
    code: 'ru',
    subtype: 'lastName',
  },
  {
    label: 'Электронная почта',
    code: 'ru',
    subtype: 'email',
  },
  {
    label: 'Телефон',
    code: 'ru',
    subtype: 'phone',
  },
  {
    label: 'Nombre',
    code: 'es',
    subtype: 'firstName',
  },
  {
    label: 'Apellido',
    code: 'es',
    subtype: 'lastName',
  },
  {
    label: 'Email',
    code: 'es',
    subtype: 'email',
  },
  {
    label: 'Teléfono',
    code: 'es',
    subtype: 'phone',
  },
  {
    label: 'Họ',
    code: 'vi',
    subtype: 'firstName',
  },
  {
    label: 'Họ',
    code: 'vi',
    subtype: 'lastName',
  },
  {
    label: 'E-mail',
    code: 'vi',
    subtype: 'email',
  },
  {
    label: 'Điện thoại',
    code: 'vi',
    subtype: 'phone',
  },
  {
    label: 'Nama Depan',
    code: 'id',
    subtype: 'firstName',
  },
  {
    label: 'Nama Belakang',
    code: 'id',
    subtype: 'lastName',
  },
  {
    label: 'Surel',
    code: 'id',
    subtype: 'email',
  },
  {
    label: 'Telepon',
    code: 'id',
    subtype: 'phone',
  },
  {
    label: '名前（ファーストネーム',
    code: 'ja',
    subtype: 'firstName',
  },
  {
    label: '姓（ラストネーム',
    code: 'ja',
    subtype: 'lastName',
  },
  {
    label: 'Eメール',
    code: 'ja',
    subtype: 'email',
  },
  {
    label: '電話',
    code: 'ja',
    subtype: 'phone',
  },
  {
    label: '이름',
    code: 'ko',
    subtype: 'firstName',
  },
  {
    label: '성',
    code: 'ko',
    subtype: 'lastName',
  },
  {
    label: '이메일',
    code: 'ko',
    subtype: 'email',
  },
  {
    label: '핸드폰',
    code: 'ko',
    subtype: 'phone',
  },

  {
    label: 'First Name',
    code: 'en',
    subtype: 'firstName',
  },
  {
    label: 'Last Name',
    code: 'en',
    subtype: 'lastName',
  },
  {
    label: 'Email',
    code: 'en',
    subtype: 'email',
  },
  {
    label: 'Phone',
    code: 'en',
    subtype: 'phone',
  },
];
