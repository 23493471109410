/* eslint-disable  */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button,
  Box,
  makeStyles,
  IconButton,
  Grid,
  Paper,
  Fade,
  ClickAwayListener,
  Typography,
} from '@material-ui/core';
// import SvgCaretDownIcon from 'icons/svgCaretDown';
import CustomToolTip from 'custom-components/customToolTip';
import { X as Close, Search, Edit2 } from 'react-feather';
import InputField from './inputField';

const useStyles = makeStyles((theme) => ({
  optionWrapper: {
    padding: '10px 0px',
  },
  disabledIcon: {
    pointerEvents: 'all !important',
  },
  popUpWidth: {
    minWidth: '21.25rem',
  },
  header: {
    background: theme.palette.bg.whiteOff1,
  },
  doneBtn: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  eventsIcon: {
    color: theme.palette.text.grayColor,
    '& img': {
      height: '100%',
      width: '100%',
    },
    '& svg': {
      height: '30px',
      width: '30px',
    },
  },
  iconButtonSidebar: {
    background: theme.palette.bg.whiteOff1,
    borderRadius: 0,
    minWidth: 'auto',
    width: 65,
    height: 65,
    color: theme.palette.text.grayColor,
    marginBottom: theme.spacing(1.5),
    '&.active': {
      background: theme.palette.primary.extraLight,
      '& svg': {
        color: theme.palette.primary.light,
      },
    },
  },
  iconButtonSidebarBtn: {
    border: `1px solid  ${theme.palette.border.dark}`,
    background: theme.palette.bg.whiteOff1,
    borderRadius: theme.shape.borderRadius,
    minWidth: 'auto',
    width: 110,
    height: 110,
    color: theme.palette.text.grayColor,
    '& .eventsIcon': {
      color: theme.palette.text.grayColor,
      '& img': {
        height: '100%',
        width: '100%',
      },
      '& svg': {
        width: '50px',
        height: '50px',
        color: theme.palette.primary.main,
      },
    },
    '& .editIcon': {
      width: 28,
      height: 28,
      backgroundColor: theme.palette.bg.whiteOff1,
      border: `1px solid  ${theme.palette.border.dark}`,
      borderRadius: '50%',
      position: 'absolute',
      right: -14,
      top: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: 14,
        height: 14,
        color: theme.palette.text.primary,
      },
    },
  },
}));

export default function IconSearchPopUp(props) {
  const { iconHandler, value, onChangeHandler, activeIcon = {} } = props;
  const classes = useStyles();
  const [isMenuOpen, toggleMenu] = React.useState(false);
  const ButtonComponent = () => {
    return (
      <>
        <Button
          disabled={props.disabled}
          className={`${props.disabled ? classes.disabledIcon : ''} ${
            classes.iconButtonSidebarBtn
          }`}
          onClick={() => toggleMenu(!isMenuOpen)}>
          <Box
            component="span"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="eventsIcon">
            {props.btnSvg}
          </Box>
          <Box className="editIcon">
            <Edit2 />
          </Box>
        </Button>
        <Fade in={isMenuOpen}>
          <Box
            className={classes.popUpWidth}
            position="absolute"
            top="100%"
            left="0"
            right="auto"
            zIndex={5}
            mt={1.2}>
            <Paper elevation={2}>
              {props.title && (
                <Box
                  className={classes.header}
                  pt={2}
                  pb={2}
                  pl={2}
                  pr={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between">
                  <Typography component="label" variant="h6" weight="bold">
                    {props.title}
                  </Typography>
                  <IconButton size="small" onClick={() => toggleMenu(false)}>
                    <Close size={17} />
                  </IconButton>
                </Box>
              )}

              <Box px={3} py={2.5}>
                {props.options.length > 8 && (
                  <InputField
                    placeholder="Search"
                    icon
                    endAdornment
                    endIconAdornment
                    endAdornmentIcon={<Search size={18} />}
                    onChange={onChangeHandler}
                    value={value}
                  />
                )}

                <Box mt={1}>
                  <Grid container spacing={1}>
                    {props.options && props.options.length > 0 ? (
                      <>
                        {props.options.map((data, i) => (
                          <Grid item xs={3} key={i}>
                            <Button
                              className={`${classes.iconButtonSidebar} ${
                                data.id === activeIcon.id ? 'active' : ''
                              }`}
                              onClick={() => {
                                iconHandler(data);
                                toggleMenu(false);
                              }}>
                              <Box
                                component="span"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.eventsIcon}
                                dangerouslySetInnerHTML={{ __html: data.svg_image }}
                              />
                            </Button>
                          </Grid>
                        ))}{' '}
                      </>
                    ) : (
                      <Box width="100%" mb={2}>
                        <Typography
                          variant="body2"
                          component="p"
                          align="center"
                          color="textSecondary">
                          No Data Available
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
                {/* {props.btnActionLabel && (
                  <Button
                    size={props.btnSize || 'large'}
                    className={classes.doneBtn}
                    onClick={props.btnAction}
                    variant={props.btnVariant || 'contained'}
                    color={props.color || 'primary'}>
                    {props.btnActionLabel}
                  </Button>
                )} */}
              </Box>
            </Paper>
          </Box>
        </Fade>
      </>
    );
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          toggleMenu(false);
        }}>
        {props.isShowTooltip && props.disabled ? (
          <CustomToolTip title={props.tooltipTitle} placement="top">
            <Box position="relative" display="inline-flex">
              <ButtonComponent />
            </Box>
          </CustomToolTip>
        ) : (
          <Box position="relative" display="inline-flex">
            <ButtonComponent />
          </Box>
        )}
      </ClickAwayListener>
    </>
  );
}
