import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useStyles } from 'pages/CreateNewEvent/style';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const EventTypeLoader = () => {
    const classes = useStyles();
    const [showOtherEventTypes, setShowOtherEventType] = useState(false);

    return (
        <Box className={classes.eventTypeScreen}>

            <Box className={` ${classes.eventType}`}>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="246px" />
                </Box>
                {showOtherEventTypes && <Box className="otherEventType" mt={1}>
                    <Typography component='h6' variant='h6' color="textPrimary">
                        <SkeletonLoaders variant="text" height="40px" width="180px" />
                    </Typography>
                    <Box className='otherEventTypeList' mt={2}>
                        <Box className='otherEventTypeItem'>
                            <SkeletonLoaders variant="text" height="40px" width="104px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="96px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="164px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="178px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="152px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="136px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="136px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="192px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="162px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="110px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="118px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="200px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="103px" /></Box>
                        <Box className='otherEventTypeItem'><SkeletonLoaders variant="text" height="40px" width="70px" /></Box>
                    </Box>
                </Box>}
                <Box className={classes.viewMoreEventType} fontWeight="500">
                    <Typography variant="body2" component="p" className='viewMoreEventType'
                        onClick={() => setShowOtherEventType(!showOtherEventTypes)}>
                        <SkeletonLoaders variant="text" height="30px" width="50px" />
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default EventTypeLoader;