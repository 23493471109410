/* eslint-disable  */
import React from 'react';
import clsx from 'clsx';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FieldWrapper from './fieldWrapper';
import CustomToolTip from 'custom-components/customToolTip';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main} 35%,transparent 40%)`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundImage: `radial-gradient(${theme.palette.primary.light}, ${theme.palette.primary.light} 35%,transparent 40%)`,
    },
  },
  radio: {
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-label': {
        fontSize: theme.fontSize.size8,
        minWidth: 100,
      },
    },
  },
  text: {
    marginTop: '-0.4rem',
    marginLeft: theme.spacing(3.3),
  },
  fluidFullWidth: {
    width: '100%',
    '&>span:nth-child(2)': {
      fontWeight: '400',
    },
  },
  fontNormal: {
    '& .MuiTypography-root': {
      fontWeight: 400,
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function RadioBtn(props) {
  const classes = useStyles();
  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <Box className={classes.radio}>
        <RadioGroup
          defaultValue={props.defaultValue}
          aria-label="gender"
          onChange={props.handleChange}
          value={props.value}
          name={props.name || 'customized-radios'}>
          {props.options.map((option, i) => (
            <React.Fragment key={option.value}>
              <FormControlLabel
                key={i}
                value={option.value}
                control={<StyledRadio />}
                label={
                  <>
                    {option.isSvgIcon && option.isSvgIcon}
                    {option.label}
                    {option.isDisabledIcon && option.isDisabledIcon}
                    {option.tooltipText && (
                      <Box
                        display="inline-flex"
                        position="relative"
                        mr={3}
                        style={{ verticalAlign: 'middle' }}>
                        <CustomToolTip isFabIcon title={option.tooltipText} placement="top" />
                      </Box>
                    )}
                  </>
                }
                className={`${props.isFluid ? classes.fluidFullWidth : ''} ${props.isNormal ? classes.fontNormal : ''
                  }`}
                disabled={props.disabled}
              />
              {option.secondaryText ? (
                <Box key={i} ml={3.2} mt={-0.5}>
                  <Typography component="span" variant="caption" color="textSecondary">
                    {option.secondaryText}
                  </Typography>
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </RadioGroup>
      </Box>
    </FieldWrapper>
  );
}
