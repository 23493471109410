import { restrictingCharacerInput } from 'settings';

export const LoginSchema = {
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter valid email id' },
  ],
  password: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter a correct password' },
    { method: 'isHTML' },
  ],
  isAcceptTerms: [
    {
      method: 'isRequired',
      errMsg: 'Agreeing to the terms of service and privacy policy is required',
    },
    { method: 'isHTML' },
  ],
};

export const ForgotPasswordSchema = {
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter valid email id' },
  ],
};

export const ResetPasswordSchema = {
  password: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    { method: 'checkCharLen', properties: { min: 7 } },
    {
      method: 'isPassword',
      errMsg:
        'Password should contain at least an uppercase letter, a number & a special character from #?!@$%^&*-',
    },
    { method: 'isHTML' },
  ],
  confirmPassword: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter valid password' },
    { method: 'checkCharLen', properties: { min: 7 } },
    {
      method: 'isEqual',
      properties: { matchKey: 'password' },
      errMsg:
        'The passwords do not match. Kindly make sure the Password and Confirm password are the same.',
    },
    { method: 'isHTML' },
  ],
};

export const AccountDetailSchema = {
  newPassword: [
    { method: 'isRequired', errMsg: 'Please enter your new password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    {
      method: 'checkCharLen',
      properties: { min: 7 },
      errMsg: 'New password should be at least 7 characters',
    },
    {
      method: 'isPassword',
      errMsg:
        'Password should contain at least an uppercase letter, a number & a special character from #?!@$%^&*-',
    },
    { method: 'isHTML' },
  ],
  confirmNewPassword: [
    { method: 'isRequired', errMsg: 'Please enter your confirm password' },
    { method: 'isString', errMsg: 'Please enter valid password' },
    {
      method: 'checkCharLen',
      properties: { min: 7 },
      errMsg: 'Confirm new password should be at least 7 characters',
    },
    {
      method: 'isEqual',
      properties: { matchKey: 'newPassword' },
      errMsg: 'Passwords do not match',
    },
    { method: 'isHTML' },
  ],
  currentPassword: [
    { method: 'isRequired', errMsg: 'Please enter your current password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    { method: 'isHTML' },
  ],
};

export const AddSurveySchema = {
  surveyName: [{ method: 'isRequired', errMsg: 'Please enter Survey name' }],
  surveyType: [{ method: 'isRequired', errMsg: 'Please select Survey type' }],
  sessionId: [{ method: 'isRequired', errMsg: 'Please select session' }],
  sessionCtaLabel: [{ method: 'isRequired', errMsg: 'Please enter Session CTA label' }],
};

export const AddTeamSchema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter last name' },
    { method: 'isString', errMsg: 'Please enter a valid last name' },
    { method: 'isHTML' },
  ],
  email: [
    { method: 'isRequired', errMsg: 'Please enter email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
  designation: [
    { method: 'isRequired', errMsg: 'Please enter job title' },
    { method: 'isString', errMsg: 'Please enter a valid job title' },
    { method: 'isHTML' },
  ],
};

export const AccountProfileSchema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid last name' },
    { method: 'isHTML' },
  ],
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
  organization: [{ method: 'isRequired', errMsg: 'Please enter your organization' }],
  designation: [{ method: 'isRequired', errMsg: 'Please enter your designation' }],
  phone: [{ method: 'isRequired', errMsg: 'Please enter you mobile number' }],
};

export const EnhancedAccountProfileSchema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid last name' },
    { method: 'isHTML' },
  ],
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
  organization: [{ method: 'isRequired', errMsg: 'Please enter your organization' }],
  designation: [{ method: 'isRequired', errMsg: 'Please enter your job title' }],
  phone: [{ method: 'isRequired', errMsg: 'Please enter you mobile number' }],
};

export const RoomSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Please enter name' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Please enter name less than 100 characters',
    },
    { method: 'isHTML' },
  ],
  description: [
    { method: 'isRequired', errMsg: 'Please enter description' },
    {
      method: 'checkCharLen',
      properties: { max: 500 },
      errMsg: 'Please enter name less than 500  characters',
    },
    { method: 'isHTML' },
  ],
  priority: [{ method: 'isRequired', errMsg: 'Please enter priority' }],
  roomCode: [
    {
      method: 'isRequired',
      errMsg: 'Room code is missing. Please enter the room code to proceed further',
    },
  ],
  avAcceptPermissionUsers: [
    {
      method: 'isRequired',
      errMsg: 'Moderator is missing. Please select at least one moderator to proceed further',
    },
  ],
  joinPermissionGroups: [
    {
      method: 'isRequired',
      errMsg: 'Group is missing. Please select atleast one group to proceed further',
    },
  ],
};

export const AddTicketSchema = {
  name: [{ method: 'isRequired', errMsg: 'Please enter ticket name' }, { method: 'isHTML' }],
  quantity: [
    { method: 'isRequired', errMsg: 'Please enter number of tickets' },
    { method: 'isHTML' },
  ],
  price: [{ method: 'isRequired', errMsg: 'Please enter price' }, { method: 'isHTML' }],
};

export const ProfileFieldSchema = {
  fieldName: [{ method: 'isRequired', errMsg: 'Please enter field name' }, { method: 'isHTML' }],
  fieldType: [{ method: 'isRequired', errMsg: 'Please select field type' }, { method: 'isHTML' }],
};

export const AddQuestionsSchema = {
  fieldName: [{ method: 'isRequired', errMsg: 'Please enter question' }, { method: 'isHTML' }],
  fieldType: [{ method: 'isRequired', errMsg: 'Please select field type' }, { method: 'isHTML' }],
};

export const SignUp1Schema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
  termsConditions: [
    { method: 'isRequired', errMsg: 'Please agree to terms and conditions to proceed' },
    {
      method: 'isSame',
      properties: { matchValue: true },
      errMsg: 'Please agree to terms and conditions to proceed',
    },
  ],
};
export const SignUp2Schema = {
  organizationName: [
    { method: 'isRequired', errMsg: 'Please enter your organisation name' },
    { method: 'isString', errMsg: 'Please enter a valid organisation' },
    { method: 'isHTML' },
  ],
  password: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    { method: 'checkCharLen', properties: { min: 7 } },
    {
      method: 'isPassword',
      errMsg:
        'Password should contain at least an uppercase letter, a number & a special character from #?!@$%^&*-',
    },
    { method: 'isHTML' },
  ],
  confirmPassword: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter valid password' },
    { method: 'checkCharLen', properties: { min: 7 } },
    {
      method: 'isEqual',
      properties: { matchKey: 'password' },
      errMsg: 'Please re-enter your password',
    },
    { method: 'isHTML' },
  ],
};

export const VerifyAccountSchema = {
  password: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    {
      method: 'checkCharLen',
      properties: { min: 7 },
      errMsg: 'Password should be at least 7 characters',
    },
    {
      method: 'isPassword',
      errMsg:
        'Password should contain at least an uppercase letter, a number & a special character from #?!@$%^&*-',
    },
    { method: 'isHTML' },
  ],
  confirmPassword: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter valid password' },
    {
      method: 'checkCharLen',
      properties: { min: 7 },
      errMsg: 'Confirm password should be at least 7 characters',
    },
    {
      method: 'isEqual',
      properties: { matchKey: 'password' },
      errMsg: 'Please re-enter your password',
    },
    { method: 'isHTML' },
  ],
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid first name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid last name' },
    { method: 'isHTML' },
  ],
};

export const SocialRegisterSchema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    { method: 'isHTML' },
  ],
  organizationName: [
    { method: 'isRequired', errMsg: 'Please enter your organisation name' },
    { method: 'isString', errMsg: 'Please enter a valid organisation' },
    { method: 'isHTML' },
  ],
  termsConditions: [
    { method: 'isRequired', errMsg: 'Please agree to terms and conditions to proceed' },
    {
      method: 'isSame',
      properties: { matchValue: true },
      errMsg: 'Please agree to terms and conditions to proceed',
    },
  ],
};

export const EventSideBarSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Please enter the Event Name' },
    { method: 'isString', errMsg: 'Please enter a valid Event Name' },
    {
      method: 'checkCharLen',
      properties: { min: 3 },
      errMsg: 'Event Name should have more than 3 characters',
    },
    { method: 'isHTML', errMsg: 'Event Name is HTML' },
  ],
  timezone: [{ method: 'isRequired', errMsg: 'Timezone is required' }],
  // language: [
  // 	{ method: "isRequired", errMsg: "Language is required" },
  // ],
  linked_url: [{ method: 'isUrl', errMsg: 'Please enter a valid LinkedIn link' }],
  twitter_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Twitter link' }],
  instagram_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Instagram link' }],
  fb_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Facebook link' }],
  website_url: [{ method: 'isUrl', errMsg: 'Please enter a valid link' }],
  eventMetaTypeId: [{ method: 'isRequired', errMsg: 'Event Type is required' }],
};

export const HybridAddressSchema = {
  lat: [{ method: 'isRequired', errMsg: 'Please select Address' }],
  lon: [{ method: 'isRequired', errMsg: 'Please select Address' }],
  address: [{ method: 'isRequired', errMsg: 'Please enter Address' }],
  country: [{ method: 'isRequired', errMsg: 'Please select Country' }],
  province: [{ method: 'isRequired', errMsg: 'Please select State/Province' }],
  city: [{ method: 'isRequired', errMsg: 'Please enter City' }],
  zip: [{ method: 'isRequired', errMsg: 'Please enter ZIP/Postal Code' }],
};
export const EventDaysSchema = {
  eventDays: [{ method: 'isRequired', errMsg: 'Please enter a event days' }],
};

export const NewEventDaysSchema = {
  eventDays: [{ method: 'isRequired', errMsg: 'Please enter number of event days' }],
};

export const ContestSideBarSchema = {
  contestName: [
    { method: 'isRequired', errMsg: 'Please enter the Contest Name' },
    { method: 'isString', errMsg: 'Please enter a valid Contest Name' },
    {
      method: 'checkCharLen',
      properties: { min: 3 },
      errMsg: 'Contest Name should have more than 3 characters',
    },
    { method: 'isHTML', errMsg: 'Contest Name is HTML' },
  ],
  groups: [{ method: 'isRequired', errMsg: 'Please select Participate.' }],
};

export const AddSessionSchema = {
  title: [{ method: 'isRequired', errMsg: 'Please enter value for title' }],
  agendaDate: [
    { method: 'isRequired', errMsg: 'Please enter value for session date' },
    { method: 'isDate', errMsg: 'Please enter a valid date' },
  ],
  startTime: [{ method: 'isRequired', errMsg: 'Please enter value for start time' }],
  endTime: [{ method: 'isRequired', errMsg: 'Please enter value for end time' }],
};
export const EditSessionSchema = {
  name: [{ method: 'isRequired', errMsg: 'Please enter value for title' }],
  agenda_date: [
    { method: 'isRequired', errMsg: 'Please enter value for session date' },
    { method: 'isDate', errMsg: 'Please enter valid end time' },
  ],
  start_time: [{ method: 'isRequired', errMsg: 'Please enter value for start time' }],
  end_time: [{ method: 'isRequired', errMsg: 'Please enter value for end time' }],
};

export const EditProductSchema = {
  url: [{ method: 'isUrl', errMsg: 'Please enter valid url' }],
};

export const StreamSessionSchema = {
  sourceOption: [{ method: 'isRequired', errMsg: 'Please enter value for Stream Source' }],
  streamLink: [
    { method: 'isRequired', errMsg: 'Please enter value for Stream Link' },
    { method: 'isHTML' },
    { method: 'isUrl', errMsg: 'Please enter valid url' },
  ],
  recordingLink: [
    { method: 'isHTML', errMsg: 'Recording Link is HTML' },
    { method: 'isUrl', errMsg: 'Please enter valid url' },
  ],
};

export const InPersonStreamSessionSchema = {
  recordingLink: [
    { method: 'isRequired', errMsg: 'Please enter value for Recording Link' },
    { method: 'isHTML', errMsg: 'Recording Link is HTML' },
    { method: 'isUrl', errMsg: 'Please enter valid url' },
  ],
};

export const StreamZoomSessionSchema = {
  sourceOption: [{ method: 'isRequired', errMsg: 'Please enter value for Stream Source' }],
  recordingLink: [
    // { method: "isHTML", errMsg: 'Recording Link is HTML' },
    { method: 'isUrl', errMsg: 'Please enter valid url' },
  ],
};

export const AddSessionSchedule = {
  startDate: [
    { method: 'isRequired', errMsg: 'Oops! Start date is a required field' },
    { method: 'isDate', errMsg: 'Please enter a valid date' },
  ],
  endDate: [
    { method: 'isRequired', errMsg: 'Oops! End date is a required field' },
    { method: 'isDate', errMsg: 'Please enter a valid date' },
  ],
};

export const AddSingleVirtualBoothSchema = {
  name: [{ method: 'isRequired', errMsg: 'Booth Name is a required field' }],
  email: [{ method: 'isEmail', errMsg: 'Please enter a valid email id' }],
  linked_url: [{ method: 'isUrl', errMsg: 'Please enter a valid LinkedIn link' }],
  twitter_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Twitter link' }],
  instagram_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Instagram link' }],
  fb_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Facebook link' }],
  website_url: [{ method: 'isUrl', errMsg: 'Please enter a valid link' }],
  sourceLink: [{ method: 'isUrl', errMsg: 'Please enter a valid Source link' }],
  productLabel: [{ method: 'isRequired', errMsg: 'Product/Service is a required field' }],
};
export const AddSingleVirtualBoothSchemaV1 = {
  name: [{ method: 'isRequired', errMsg: 'Booth Name is a required field' }],
  email: [{ method: 'isEmail', errMsg: 'Please enter a valid email id' }],
  linked_url: [{ method: 'isUrl', errMsg: 'Please enter a valid LinkedIn link' }],
  twitter_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Twitter link' }],
  instagram_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Instagram link' }],
  fb_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Facebook link' }],
  website_url: [{ method: 'isUrl', errMsg: 'Please enter a valid link' }],
  sourceLink: [{ method: 'isUrl', errMsg: 'Please enter a valid Source link' }],
};

export const AddSinglePeopleSchema = {
  firstName: [{ method: 'isRequired', errMsg: 'Please enter value for first name' }],
  lastName: [{ method: 'isRequired', errMsg: 'Please enter value for last name' }],
  email: [{ method: 'isEmail', errMsg: 'Please enter value for email' }],
  linked_url: [{ method: 'isUrl', errMsg: 'Please enter a valid LinkedIn link' }],
  twitter_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Twitter link' }],
  instagram_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Instagram link' }],
  fb_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Facebook link' }],
  website_url: [{ method: 'isUrl', errMsg: 'Please enter a valid link' }],
};

export const AddLoungeSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Table Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 35 },
      errMsg: 'Table Name can be maximum 35 characters',
    },
  ],
  topic: [
    { method: 'isRequired', errMsg: 'Topic is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 50 },
      errMsg: 'Topic can be maximum 50 characters',
    },
  ],
  capacity: [{ method: 'isRequired', errMsg: 'Please select capacity.' }],
  // priority:[
  // 	{ method: "isRequired", errMsg: "Priority is a required field" },
  // ]
  // lounge_image:[
  // 	{ method: "isRequired", errMsg: "Logo is a required field" },
  // ]
};

export const AddLoungeExhibitorSchema = {
  meetingLimit: [{ method: 'isRequired', errMsg: 'Meeting Limit is required.' }],
  exhibitors: [{ method: 'isRequired', errMsg: 'Please select virtual booth.' }],
  capacity: [{ method: 'isRequired', errMsg: 'Please select capacity.' }],
};

export const AddBrandEventSchema = {
  url: [
    { method: 'isRequired', errMsg: 'Please enter a Url' },
    { method: 'isDomain', errMsg: 'Please enter valid Domain' },
  ],
};

export const AddMeeTingSchema = {
  participant1: [{ method: 'isRequired', errMsg: 'Please select Participant' }],
  participant2: [{ method: 'isRequired', errMsg: 'Please select Participant' }],
  meeting_description: [{ method: 'isRequired', errMsg: 'Description is a required field' }],
  meeting_time: [{ method: 'isRequired', errMsg: 'Please select Date' }],
  meeting_date: [{ method: 'isRequired', errMsg: 'Please select Time' }],
};

export const EventLoginSettingSchema = {
  support_email: [{ method: 'isEmail', errMsg: 'Please enter valid email id' }],
  default_custom_otp: [
    {
      method: 'checkCharLen',
      properties: { min: 4 },
      errMsg: 'Default Login Code should be at least 4 characters',
    },
    {
      method: 'checkCharLen',
      properties: { max: 10 },
      errMsg: 'Default Login Code can be maximum 10 characters',
    },
  ],
};

export const EventSenderSettingSchema = {
  // email_from: [
  // 	{ method: "isRequired", errMsg: "Email from is a required field" },
  // 	{ method: "isEmail", errMsg: "Please enter valid email id" },
  // 	{ method: "isEmailDomin", errMsg: "Please enter a valid business Email ID" }
  // ],

  // email_cc: [
  // 	{ method: "isEmail", errMsg: "Please enter valid email id" },
  // 	{ method: "isEmailDomin", errMsg: "Please enter a valid business Email ID" }
  // ], email_bcc: [
  // 	{ method: "isEmail", errMsg: "Please enter valid email id" },
  // 	{ method: "isEmailDomin", errMsg: "Please enter a valid business Email ID" }
  // ],

  email_sender_name: [
    { method: 'isRequired', errMsg: 'Sender name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 50 },
      errMsg: 'Sender name can be maximum 50 characters',
    },
  ],
};

export const EventInvitEmailSettingSchema = {
  email_subject: [{ method: 'isRequired', errMsg: 'Subject is a required field' }],
  email_title: [{ method: 'isRequired', errMsg: 'Title is a required field' }],
  email_body: [{ method: 'isRequired', errMsg: 'Body is a required field' }],
  join_button_name: [{ method: 'isRequired', errMsg: 'Login CTA label is a required field' }],
};

export const LegalSettingSchema = {
  terms_description: [{ method: 'isRequired', errMsg: 'Description is a required field' }],
  terms_label: [{ method: 'isRequired', errMsg: 'Label is a required field' }],
  terms_link: [
    { method: 'isRequired', errMsg: 'Link is a required field' },
    { method: 'isUrl', errMsg: 'Please enter a valid link' },
  ],
  policy_description: [{ method: 'isRequired', errMsg: 'Description is a required field' }],
  policy_label: [{ method: 'isRequired', errMsg: 'Label is a required field' }],
  policy_link: [
    { method: 'isRequired', errMsg: 'Link is a required field' },
    { method: 'isUrl', errMsg: 'Please enter a valid link' },
  ],
  cookie_description: [{ method: 'isRequired', errMsg: 'Description is a required field' }],
  cookie_label: [{ method: 'isRequired', errMsg: 'Label is a required field' }],
  cookie_link: [
    { method: 'isRequired', errMsg: 'Link is a required field' },
    { method: 'isUrl', errMsg: 'Please enter a valid link' },
  ],
};

export const SeoSettingSchema = {
  title: [
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Title can be maximum 100 characters',
    },
  ],
  description: [
    {
      method: 'checkCharLen',
      properties: { max: 1000 },
      errMsg: 'Description can be maximum 1000 characters',
    },
  ],
};

export const PrivacySettingSchema = {
  contact_info_email: [
    { method: 'isRequired', errMsg: 'Email ID is a required field' },
    { method: 'isEmail', errMsg: 'Please enter valid email id' },
  ],
};

export const PrivacyConsentSchema = {
  label: [
    { method: 'isRequired', errMsg: 'Consent Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Consent Name can be maximum 100 characters',
    },
  ],
  content: [{ method: 'isRequired', errMsg: 'Consent Text is a required field' }],
};

export const GroupSettingSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Group Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: restrictingCharacerInput.settings.groups.groupName },
      errMsg: `Group Name can be maximum ${restrictingCharacerInput.settings.groups.groupName} characters`,
    },
  ],
};

export const EventSectionV1Schema = {
  label: [
    { method: 'isRequired', errMsg: 'Section Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 20 },
      errMsg: 'Section Name can be maximum 20 characters',
    },
  ],
};
export const EventSectionV1SchemaForBooth = {
  label: [
    { method: 'isRequired', errMsg: 'Section Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: restrictingCharacerInput.settings.eventSectionV2.boothLabel },
      errMsg: `Section Name can be maximum ${restrictingCharacerInput.settings.eventSectionV2.boothLabel} characters`,
    },
  ],
};

export const EventSectionSchema = {
  label: [
    { method: 'isRequired', errMsg: 'Section Name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 15 },
      errMsg: 'Section Name can be maximum 15 characters',
    },
  ],
};

export const SignUpForOrgSchema = {
  firstName: [
    { method: 'isRequired', errMsg: 'Please enter your first name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'First Name can be maximum 100 characters',
    },
    { method: 'isHTML' },
  ],
  lastName: [
    { method: 'isRequired', errMsg: 'Please enter your last name' },
    { method: 'isString', errMsg: 'Please enter a valid name' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Last Name can be maximum 100 characters',
    },
    { method: 'isHTML' },
  ],
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
  orgName: [{ method: 'isRequired', errMsg: 'Please enter your organization/ Business name' }],
  termsConditions: [
    { method: 'isRequired', errMsg: 'Please agree to terms and conditions to proceed' },
    {
      method: 'isSame',
      properties: { matchValue: true },
      errMsg: 'Please agree to terms and conditions to proceed',
    },
  ],
  password: [
    { method: 'isRequired', errMsg: 'Please enter your password' },
    { method: 'isString', errMsg: 'Please enter a password' },
    {
      method: 'checkCharLen',
      properties: { min: 8 },
      errMsg: 'Password should be at least 8 characters',
    },
    {
      method: 'isPassword',
      errMsg:
        'Password should contain at least an uppercase letter, a number & a special character from #?!@$%^&*-',
    },
    { method: 'isHTML' },
  ],
  confirmPassword: [
    { method: 'isRequired', errMsg: 'Please enter your confirmation password' },
    { method: 'isString', errMsg: 'Please enter a confirmation password' },
  ],
};

export const PreLoginSchema = {
  email: [
    { method: 'isRequired', errMsg: 'Please enter email id' },
    { method: 'isEmail', errMsg: 'Please enter a valid email id' },
  ],
};

export const OrgQusAnsSchema = {
  eventInYear: [{ method: 'isRequired', errMsg: 'Please select events in a year' }],
  eventAttende: [{ method: 'isRequired', errMsg: 'Please select attendee size' }],
  industry: [{ method: 'isRequired', errMsg: 'Please select industry' }],
  country: [{ method: 'isRequired', errMsg: 'Please select country' }],
};

export const ConetstQuestionSchema = {
  pollQuestion: [{ method: 'isRequired', errMsg: 'Question is a required field' }],
  pollStartMilli: [{ method: 'isRequired', errMsg: 'Please select question active from date' }],
  pollEndMilli: [{ method: 'isRequired', errMsg: 'Please select question active till date' }],
};

export const SponsorAdSchema = {
  title: [
    { method: 'isRequired', errMsg: 'Title is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 75 },
      errMsg: 'Title can be maximum 75 characters',
    },
  ],
  image: [{ method: 'isRequired', errMsg: 'Please select image' }],
  display_location: [
    { method: 'isRequired', errMsg: 'Please select at least one location to display' },
  ],
  boothList: [{ method: 'isRequired', errMsg: 'Please select at least one virtual booth' }],
  call_to_action: [{ method: 'isRequired', errMsg: 'Please select action' }],
  url: [
    { method: 'isRequired', errMsg: 'URL is a required field' },
    { method: 'isUrl', errMsg: 'Please enter a valid link' },
  ],
};

export const AddCreditsCertificateSchema = {
  name: [
    {
      method: 'isRequired',
      errMsg: 'Credit Name is a required field',
    },
    // {
    //   method: 'isSame',
    //   properties: { matchValue: true },
    //   errMsg: 'Credit Name already exists',
    // },
  ],
  title: [{ method: 'isRequired', errMsg: 'Title is a required field' }],
  organisationLogo: [{ method: 'isRequired', errMsg: 'Organisation Logo is a required field' }],
  authPersonSignature: [
    { method: 'isRequired', errMsg: 'Authorised Person Signature is a required field' },
  ],
  authPersonName: [{ method: 'isRequired', errMsg: 'Authorised Person Name is a required field' }],
  sponsorName: [{ method: 'isRequired', errMsg: 'Sponsor Name is a required field' }],
};

export const InCompleteTransactionMailSchema = {
  emailSubject: [
    { method: 'isRequired', errMsg: 'Email Subject is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 150 },
      errMsg: 'Email Subject can be maximum 150 characters',
    },
  ],
  emailTitle: [
    { method: 'isRequired', errMsg: 'Email Title is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Email Title can be maximum 100 characters',
    },
  ],
  emailBody: [
    { method: 'isRequired', errMsg: 'Email Body is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 1000 },
      errMsg: 'Email Body can be maximum 1000 characters',
    },
  ],
  ctaLabel: [
    { method: 'isRequired', errMsg: 'CTA Label is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'CTA Label can be maximum 100 characters',
    },
  ],
};

export const TransactionMailSchema = {
  emailSubject: [
    { method: 'isRequired', errMsg: 'Email Subject is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 150 },
      errMsg: 'Email Subject can be maximum 150 characters',
    },
  ],
  emailTitle: [
    { method: 'isRequired', errMsg: 'Email Title is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Email Title can be maximum 100 characters',
    },
  ],
  emailBody: [
    { method: 'isRequired', errMsg: 'Email Body is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 1000 },
      errMsg: 'Email Body can be maximum 1000 characters',
    },
  ],
};

export const TicketBasicDetailsSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Name is a required field' },
    { method: 'checkCharLen', properties: { max: 50 }, errMsg: 'Name is a maximum 50 character' },
  ],
  description: [
    {
      method: 'checkCharLen',
      properties: { max: 1500 },
      errMsg: 'Description is a maximum 1500 character',
    },
  ],
  // ,ticket_fee_type_id:[
  //  { method: "isRequired", errMsg: "Fee setting is a required field" },
  // ]
  price: [{ method: 'isRequired', errMsg: 'Price is a required field' }],
  quantity: [{ method: 'isRequired', errMsg: 'Number of tickets available is a required field' }],
  start_date_time: [{ method: 'isRequired', errMsg: 'Please select start date and time' }],
  end_date_time: [{ method: 'isRequired', errMsg: 'Please select end date and time' }],
  group: [{ method: 'isRequired', errMsg: 'Please select group' }],
  // ,is_status_open:[
  //  { method: "isRequired", errMsg: "Status is a required field" },
  // ]
  // ,is_visible:[
  //  { method: "isRequired", errMsg: "Visibility is a required field" },
  // ]
};

export const TicketCrInviteEmailSchema = {
  email_subject: [
    { method: 'isRequired', errMsg: 'Email subject is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Email subject is a maximum 100 character',
    },
  ],
  email_body: [
    { method: 'isRequired', errMsg: 'Email body is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 1500 },
      errMsg: 'Email body is a maximum 1500 character',
    },
  ],
  cta_label: [
    { method: 'isRequired', errMsg: 'CTA label is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'CTA label is a maximum 100 character',
    },
  ],
};

export const TicketCrRegEmailSchema = {
  registrationEmailSubject: [
    { method: 'isRequired', errMsg: 'Email subject is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Email subject is a maximum 100 character',
    },
  ],
  registrationEmailBody: [
    { method: 'isRequired', errMsg: 'Email body is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 1500 },
      errMsg: 'Email body is a maximum 1500 character',
    },
  ],
};

export const TicketTaxDetailsSchema = {
  country_id: [{ method: 'isRequired', errMsg: 'Please select a country' }],
  tax_name: [
    { method: 'isRequired', errMsg: 'Tax name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 50 },
      errMsg: 'Tax name is a maximum 50 character',
    },
  ],
  tax_rate: [{ method: 'isRequired', errMsg: 'Tax rate is a required field' }],
};

export const TicketInvoiceDetailsSchema = {
  invoice_name: [
    { method: 'isRequired', errMsg: 'Invoice name is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 50 },
      errMsg: 'Invoice name is a maximum 50 character',
    },
  ],
  address: [
    { method: 'isRequired', errMsg: 'Address is a required field' },
    {
      method: 'checkCharLen',
      properties: { max: 96 },
      errMsg: 'Address is a maximum 96 character',
    },
  ],
  city: [{ method: 'isRequired', errMsg: 'City is a required field' }],
  state: [{ method: 'isRequired', errMsg: 'Please select a State' }],
  country: [{ method: 'isRequired', errMsg: 'Please select a Country' }],
  pincode: [{ method: 'isRequired', errMsg: 'Pin code is a required field' }],
  tax_id: [{ method: 'isRequired', errMsg: 'Tax ID is a required field' }],
  invoice_no: [{ method: 'isRequired', errMsg: 'Invoice number is a required field' }],
};

export const AddDiscountSchema = {
  offerName: [{ method: 'isRequired', errMsg: 'Please enter Offer Name' }],
  discountType: [{ method: 'isRequired', errMsg: 'Please select Discount Type' }],
  discountGroupLogic: [{ method: 'isRequired', errMsg: 'Please select Discount Group Logic' }],
  discountNoOfTickets: [{ method: 'isRequired', errMsg: 'Please enter No. of Tickets' }],
  discountRangeFrom: [{ method: 'isRequired', errMsg: 'Please enter Range From' }],
  discountRangeTo: [{ method: 'isRequired', errMsg: 'Please enter Range To' }],
  discountCodeName: [{ method: 'isRequired', errMsg: 'Please enter Code Name' }],
  discountAmount: [{ method: 'isRequired', errMsg: 'Please select Discount Amount' }],
  discountPercentage: [{ method: 'isRequired', errMsg: 'Please enter discount' }],
  discountUsage: [{ method: 'isRequired', errMsg: 'Please select Discount Usage' }],
  discountUsagePerTickets: [
    { method: 'isRequired', errMsg: 'Please enter Discount Usage per tickets' },
  ],
  discountApplicableTo: [{ method: 'isRequired', errMsg: 'Please select Discount Applicable To' }],
  startDate: [{ method: 'isRequired', errMsg: 'Please enter Discount Start Date and Time' }],
  endDate: [{ method: 'isRequired', errMsg: 'Please enter Discount End Date and Time' }],
};

const {
  ticketSettings: {
    transactionalEmailProps,
    incTransactionalEmailProps,
    landingPageSettings,
    gdprCompliance,
  },
} = restrictingCharacerInput;

export const TicketSettingsSchema = {
  taxSettingCountryId: [{ method: 'isRequired', errMsg: 'Please select a country' }],
  taxSettingTaxName: [{ method: 'isRequired', errMsg: 'Tax name is a required field' }],
  taxSettingTaxRate: [{ method: 'isRequired', errMsg: 'Tax rate is a required field' }],
  sTaxInvoiceRegisteredName: [
    { method: 'isRequired', errMsg: 'Registered name is a required field' },
  ],
  sTaxInvoiceTaxId: [{ method: 'isRequired', errMsg: 'Tax Id is a required field' }],
  sTaxInvoiceCountryId: [{ method: 'isRequired', errMsg: 'Please select a country' }],
  sTaxInvoiceStateId: [{ method: 'isRequired', errMsg: 'Please select a state' }],
  sTaxInvoiceCity: [{ method: 'isRequired', errMsg: 'City is a required field' }],
  sTaxInvoiceZipCode: [{ method: 'isRequired', errMsg: 'ZipCode is a required field' }],
  sTaxInvoiceInvoiceNumber: [{ method: 'isRequired', errMsg: 'Invoice No. is a required field' }],
  sTaxInvoiceInvoiceBibNo: [{ method: 'isRequired', errMsg: 'Invoice No. is a required field' }],
  tEmailESubject: [
    {
      method: 'isRequired',
      errMsg: 'Email Subject is a required field',
      properties: { max: transactionalEmailProps.emailSubject },
    },
  ],
  tEmailEBody: [
    {
      method: 'isRequired',
      errMsg: 'Email Body is a required field',
      properties: { max: transactionalEmailProps.emailBody },
    },
  ],
  incTEmailESubject: [
    {
      method: 'isRequired',
      errMsg: 'Email Subject is a required field',
      properties: { max: incTransactionalEmailProps.emailSubject },
    },
  ],
  incTEmailEBody: [
    {
      method: 'isRequired',
      errMsg: 'Email Body is a required field',
      properties: { max: incTransactionalEmailProps.emailBody },
    },
  ],
  incTCtaLabel: [
    {
      method: 'isRequired',
      errMsg: 'CTA Label is a required field',
      properties: { max: incTransactionalEmailProps.ctaLabel },
    },
  ],
  lPSLabel: [
    {
      method: 'isRequired',
      errMsg: 'Label is a required field',
      properties: { max: landingPageSettings.label },
    },
  ],
  lPSApplicableFrom: [
    {
      method: 'isRequired',
      errMsg: 'Applicable From is a required field',
    },
  ],
  addToCalendarCTAVisibleFromMilli: [
    {
      method: 'isRequired',
      errMsg: 'Applicable From is a required field',
    },
  ],
  gCConsentText: [
    {
      method: 'isRequired',
      errMsg: 'Consent Text is a required field',
      properties: { max: gdprCompliance.consentText },
    },
  ],
};

export const LocationSchema = {
  Location: [{ method: 'isRequired', errMsg: 'Table Name is a required field' }],
};

// WebinarEngagementSchema - to check the app name for default engagement options
export const WebinarEngagementSchema = {
  name: [{ method: 'isRequired', errMsg: 'App name is required' }],
};

export const SessionEngagementSchema = {
  name: [{ method: 'isRequired', errMsg: 'App name is required' }],
  embed_code: [{ method: 'isRequired', errMsg: 'Embed code is required' }],
};

export const EmbedAppFileUploadSchema = {
  name: [{ method: 'isRequired', errMsg: 'App name is required' }],
};

export const CopyEventBasicDetailSchema = {
  name: [
    { method: 'isRequired', errMsg: 'Event name is required' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Event name is maximum 100 characters',
    },
  ],
  description: [
    {
      method: 'checkCharLen',
      properties: { max: 4000 },
      errMsg: 'Event description is maximum 4000 characters',
    },
  ],
  start_date_time: [{ method: 'isRequired', errMsg: 'Please select start date and time' }],
  timezone: [{ method: 'isRequired', errMsg: 'Timezone is required' }],
};

export const quizQuestionsSchema = {
  name: [{ method: 'isRequired', errMsg: 'Please enter question name' }, { method: 'isHTML' }],
  fieldType: [
    { method: 'isRequired', errMsg: 'Please select question type' },
    { method: 'isHTML' },
  ],
  points: [{ method: 'isRequired', errMsg: 'Please enter question points' }],
};

export const CreditsPointIdSchema = {
  creditPoint: [{ method: 'isNumber', errMsg: 'Please enter credit point' }],
  creditId: [{ method: 'isRequired', errMsg: 'Please select credit id' }],
};

export const quizBasicDetailsSchema = {
  quizName: [{ method: 'isRequired', errMsg: 'Please enter quiz name' }],
  ctaLabel: [{ method: 'isRequired', errMsg: 'Please enter CTA label' }],
  fillTime: [{ method: 'isRequired', errMsg: 'Please select When Can Attendee Start The Quiz' }],
  visibility: [{ method: 'isRequired', errMsg: 'Please select Quiz Visibility' }],
  type: [{ method: 'isRequired', errMsg: 'Please select question type' }],
  visibleGroups: [{ method: 'isRequired', errMsg: 'Please select Show Quiz To Following Groups' }],
};
export const CEPCreditsQuizSchema = {
  creditPoint: [{ method: 'isRequired', errMsg: 'Please enter credit point' }],
  creditId: [{ method: 'isRequired', errMsg: 'Please select credit id' }],
};

export const CreditsOnDemandSchema = {
  onDemandEligibilityPercentage: [
    { method: 'isNumber', errMsg: 'Please enter percentage of session watched' },
  ],
};

export const CreditsLiveSessionSchema = {
  eligibilityPercentage: [
    { method: 'isNumber', errMsg: 'Please enter percentage of session watched' },
  ],
};

export const CopyEventBasicDetailSchemaForWebinar = {
  name: [
    { method: 'isRequired', errMsg: 'Event name is a required' },
    {
      method: 'checkCharLen',
      properties: { max: 100 },
      errMsg: 'Event name is a maximum 100 character',
    },
  ],
  start_date_time: [{ method: 'isRequired', errMsg: 'Please select start date and time' }],
  timezone: [{ method: 'isRequired', errMsg: 'Timezone is required' }],
};

export const WebinarRegistrationSetUpSchema = {
  addToCalendarCTAVisibleFromMilli: [
    {
      method: 'isRequired',
      errMsg: 'Applicable From is a required field',
    },
  ],
  gCConsentText: [
    {
      method: 'isRequired',
      errMsg: 'Consent Text is a required field',
      properties: { max: gdprCompliance.consentText },
    },
  ],
  incTCtaLabel: [
    {
      method: 'isRequired',
      errMsg: 'CTA Label is a required field',
      properties: { max: incTransactionalEmailProps.ctaLabel },
    },
  ],
};

export const CreditsEmailIdSchema = {
  emailTriggerId: [{ method: 'isRequired', errMsg: 'Select any email trigger' }],
};

export const zoomAppSchema = {
  appId: [{ method: 'isRequired', errMsg: 'Please enter value for App ID' }],
  clientId: [{ method: 'isRequired', errMsg: 'Please enter value for Client ID' }],
  clientSecrete: [{ method: 'isRequired', errMsg: 'Please enter value for Client Secret' }],
};

export const zoomHostSchema = {
  firstName: [{ method: 'isRequired', errMsg: 'Please enter first name' }],
  lastName: [{ method: 'isRequired', errMsg: 'Please enter last name' }],
  email: [
    { method: 'isRequired', errMsg: 'Please enter your email id' },
    { method: 'isEmail', errMsg: 'Please enter valid email id' },
  ],
};
