import { convertDateToTimezoneMilli, convertUTCToTimezone } from 'lib/common-function';
import moment from 'moment';

export const isEventEnd = (eventData = {}) => {
  const timeZoneDtls = eventData.EventData.timezone;
  const eventEndDate = convertDateToTimezoneMilli(
    convertUTCToTimezone(+eventData?.EventData?.end_time_milli, timeZoneDtls.name).timeStamp,
    timeZoneDtls.name
  );
  const currentDate = convertDateToTimezoneMilli(
    convertUTCToTimezone(moment.utc(), timeZoneDtls.name).timeStamp,
    timeZoneDtls.name
  );
  return currentDate > eventEndDate;
};

export const isSessionEnd = (sessionEndDate = '') => {
  const currentDate = moment.utc().valueOf(); // UTC
  const sessionEndTime = Number(sessionEndDate); // UTC
  return currentDate > sessionEndTime;
};

export const isFeatureAvailabilityBasedOnEventTime = ({
  featureEnabledFrom = '',
  eventCreatedAt = '',
}) => {
  let res = false;
  if (featureEnabledFrom && eventCreatedAt) {
    res = eventCreatedAt >= featureEnabledFrom;
  }
  return res;
};
