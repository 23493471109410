import { genericAPICall, genericAPIErrorHandler } from 'api-setup/api-helper';
import leadScoreApiUrls from '../../constants/apiUrls';

import { getLeadLevelOverviewLoading, getLeadLevelOverview } from '../action-creator';

export const getLeadLevelOverviewThunk = () => async (dispatch, state) => {
  const { EventData: { id = '' } = {} } = state();
  try {
    dispatch(getLeadLevelOverviewLoading(true));
    const { status, data = {} } = await genericAPICall(
      leadScoreApiUrls.leadLevelAnalyticsOverview,
      'GET',
      {},
      {},
      { eventid: id }
    );
    if (status === 200) {
      if (data.status === 200) {
        dispatch(getLeadLevelOverview(data.data));
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch(getLeadLevelOverviewLoading(false));
  }
};
