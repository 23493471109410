import React, { useEffect, useState } from 'react';
import CustomModal from 'custom-components/customModal';
import Box from '@material-ui/core/Box';
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Button,
  Tooltip,
} from '@material-ui/core';
import InfoMsgComponent from '../../integrations/infoMsgComponent';
import InputField from 'custom-components/form-fields/inputField';
import { PlusCircle, CheckCircle } from 'react-feather';
import { API_CALL_METHODS, customBaseURL2, useApiHook } from 'api-setup/use-api-hook';
import { EVENT_ADDONS } from 'api-setup/api-endpoints';
import { useParams } from 'react-router-dom';
import { useCustomCreateEventContext } from '../context';
import { EActionType, EEventFormat } from '../common/constants';
import { userInfoSelector } from 'redux/custom-selector';
import { useSelector, shallowEqual } from 'react-redux';
import { PricingPlan } from 'constants/pricingPlan';

const useStyles = makeStyles((theme) => ({
  addonsWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  selectAddons: {
    background: theme.palette.bg.whiteOff1,
    height: 95,
    fontSize: 20,
    borderRadius: 8,
    '&>svg': {
      marginRight: theme.spacing(1),
    },
  },
  selectOption: {
    // background: theme.palette.bg.whiteOff1,
    // borderRadius: 8,
    '& .formLabels': {
      background: '#F6F8F8',
      margin: 0,
      border: `1px solid ${theme.palette.bg.whiteOff2}`,
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(1),
      minHeight: 48,
      '& .MuiCheckbox-root ': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
      '&.Mui-disabled': {
        opacity: 0.8,
        '& .MuiSvgIcon-root': {
          color: theme.palette.bg.whiteOff2,
        },
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        width: '100%',
        '& .MuiTypography-body2': {
          fontWeight: 500,
        },
        '&.Mui-disabled': {
          color: '#c0c0c0',
        },
      },
      '&:last-child': {
        marginBottom: theme.spacing(0),
      },
    },
    '& .listEdit': {
      border: `1px solid ${theme.palette.bg.whiteOff2}`,
      borderRadius: theme.shape.borderRadius,
      '&>svg': {
        marginRight: theme.spacing(1),
        color: '#4CBB3E',
      },
      '& label': {
        fontWeight: 500,
      },
    },
  },
  tooltipWidth: {
    '& .MuiTooltip-tooltip ': {
      maxWidth: '100%',
    },
  },
  listUI: {
    background: '#FFF7ED',
    borderRadius: 8,
    '&>li': {
      marginTop: theme.spacing(0.2),
      marginBottom: theme.spacing(0.2),
    },
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    '&>span': {
      fontWeight: 500,
    },
  },
  modalOverlay: {
    width: 515,
  },
  virtualEventSection: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    textAlign: 'center',
    overflow: 'hidden',
    '& label': {
      background: 'linear-gradient(90deg, #ED7767 0.9%, #F19285 47.02%, #F4ADA4 99.93%)',
      padding: '8px 4px',
      display: 'flex',
      justifyContent: 'center',
      color: '#fff',
      fontWeight: 500,
    },
    '& p': {
      padding: '8px 4px',
    },
  },
  requiredRedStar: {
    fontSize: '14px',
    color: '#E45747',
    fontWeight: 400,
  },
}));

const EventAddonsModal = ({
  isOpen = false,
  onClose = () => {},
  eventType = '',
  actionType,
  submitCallback = null,
}) => {
  const classes = useStyles();
  const [apiPromise] = useApiHook();
  const [addOnsData, setAddOnsData] = useState({});
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [confirmInput, setConfirmInput] = useState('');

  const { eventId = '' } = useParams();
  const { state, dispatch } = useCustomCreateEventContext();
  // userInfo - the data of logged in user
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const planName = userInfo && userInfo?.UserInfo?.planName;

  useEffect(() => {
    if (state?.addOns?.length > 0) {
      setSelectedAddons(state?.addOns || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getEventAddons = () => {
    apiPromise(
      `${EVENT_ADDONS}?type=${eventType}&id=${eventId}`,
      API_CALL_METHODS.GET,
      {},
      {},
      customBaseURL2
    ).then((response) => {
      setAddOnsData(response?.data?.data);
    });
  };

  useEffect(() => {
    getEventAddons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddonSelect = (e) => {
    if (e.target.checked) {
      setSelectedAddons([...selectedAddons, e.target.value]);
    } else {
      selectedAddons.splice(selectedAddons.indexOf(e.target.value), 1);
      setSelectedAddons([...selectedAddons]);
    }
  };

  const areAllAvailableAddonsPurchased = () => {
    const availableAddOns = addOnsData?.addOns?.filter((i) => !i.xnsId && i.availableQty > 0) || [];

    return availableAddOns?.length === 0;
  };

  return (
    <CustomModal
      paddingZero
      isOpen={isOpen}
      onClose={onClose}
      contentCardClass={classes.modalOverlay}>
      <>
        <Box p={3} maxHeight="calc(100vh - 160px)" className={classes.addonsWrapper}>
          {actionType === EActionType.CREATE &&
          state.format === EEventFormat.VIRTUAL &&
          (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) ? (
            <>
              <Typography variant="h4" component="h4" style={{ textAlign: 'center' }}>
                You are creating a virtual event
              </Typography>

              <Box mb={2} mt={2} className={classes.virtualEventSection}>
                <Typography component="label" variant="body2">
                  1 Virtual event credit will be utilised
                </Typography>
                <Typography component="p" variant="caption" color="textSecondary">
                  Virtual event credit will be reversed if the event is deleted before the start
                  time.
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={classes.selectAddons}
                mb={2.5}>
                <PlusCircle size={34} color="#FFBF3F" />
                <Typography variant="h5" component="h3">
                  {!addOnsData?.isAnyAddOnPurchased
                    ? 'You have not purchased add-ons yet'
                    : 'Select add-ons for your event'}
                </Typography>
              </Box>
              {addOnsData?.isAnyAddOnPurchased && !areAllAvailableAddonsPurchased() && (
                <Box component="ul" className={classes.listUI} mt={3} pl={4} mb={1} py={1} pr={1}>
                  <Typography component="li" variant="body2" color="textSecondary">
                    {' '}
                    Selected add-ons get applied on event creation
                  </Typography>
                  <Typography component="li" variant="body2" color="textSecondary">
                    Track add-ons usage history under Profile &gt; Plans & Purchases
                  </Typography>
                  <Typography component="li" variant="body2" color="textSecondary">
                    Prefer to add later? Come back anytime by editing your event
                  </Typography>
                </Box>
              )}

              {(!addOnsData?.isAnyAddOnPurchased || areAllAvailableAddonsPurchased()) && (
                <Box mt={3}>
                  <InfoMsgComponent
                    marginBottom={3}
                    title="Talk to sales to know more about add-ons."
                  />
                </Box>
              )}
            </>
          )}

          <Typography variant="body2" style={{ fontWeight: 500 }} component="subtitle1">
            Apply other add-ons to your event?
          </Typography>
          <Box mb={3} mt={1} className={classes.selectOption}>
            <FormGroup>
              {addOnsData?.addOns?.map((addon) => {
                if (!addOnsData?.applicableAddOns?.includes(addon.addOnType)) return null;
                if (eventId && addon.xnsId) {
                  return (
                    <Box mb={1} className={classes.selectOption}>
                      <Box p={1.5} className="listEdit" display="flex" alignItems="center">
                        <CheckCircle strokeWidth={3} size={16} />
                        <Typography component="label" variant="body2">
                          {addon.label}
                        </Typography>
                      </Box>
                    </Box>
                  );
                } else {
                  return (
                    <Tooltip
                      title={
                        addon.availableQty === 0 && addon.totalQty > 0
                          ? 'You exceeded this add-on limit, talk to sales to know more about this.'
                          : addon.availableQty === 0 && addon.totalQty === 0
                          ? 'You have not purchased this add-on, or you’ve already used it.'
                          : !addOnsData?.applicableAddOns?.includes(addon.addOnType)
                          ? 'Not applicable for webinars and virtual events.'
                          : ''
                      }
                      placement="top"
                      arrow
                      classes={{ popper: classes.tooltipWidth }}
                      key={addon.addOnType}>
                      <FormControlLabel
                        disabled={
                          addon.availableQty === 0 ||
                          !addOnsData?.applicableAddOns?.includes(addon.addOnType)
                        }
                        className="formLabels"
                        control={
                          <Checkbox
                            value={addon.addOnType}
                            onChange={handleAddonSelect}
                            checked={selectedAddons.includes(addon.addOnType)}
                            color="primary"
                          />
                        }
                        label={
                          <Box
                            width="100%"
                            pr={1.5}
                            component="span"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <Typography component="span" variant="body2">
                              {addon.label}
                            </Typography>
                            {addon.availableQty !== 0 && (
                              <Typography variant="caption" color="textSecondary" component="span">
                                Remaining: {addon.availableQty}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </Tooltip>
                  );
                }
              })}
            </FormGroup>
          </Box>

          {addOnsData?.isAnyAddOnPurchased &&
          !areAllAvailableAddonsPurchased() &&
          ((planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
          actionType === EActionType.CREATE
            ? selectedAddons.length
            : true) ? (
            <Box>
              <InfoMsgComponent
                marginBottom={3}
                title="Add-ons once applied cannot be changed or removed."
              />

              <Typography
                className={classes.inputLabel}
                variant="body2"
                component="p"
                color="textSecondary">
                Type{' '}
                <Typography variant="body2" color="textSecondary" component="span">
                  ‘CONFIRM’
                </Typography>{' '}
                {planName === '' ||
                planName === PricingPlan.WEBINAR ||
                planName === PricingPlan.WEBINAR_LITE
                  ? 'to proceed'
                  : 'to apply selected add-ons to this event'}
                <span className={classes.requiredRedStar}>*</span>
              </Typography>
              <InputField
                value={confirmInput}
                onChange={(e) => {
                  setConfirmInput(e.target.value);
                }}
                type="text"
              />
            </Box>
          ) : null}
        </Box>

        {!(
          (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
          actionType === EActionType.CREATE
        ) ? (
          <Box p={3} display="flex" alignItems="center" justifyContent="center">
            <Button
              variant={
                addOnsData?.isAnyAddOnPurchased && !areAllAvailableAddonsPurchased()
                  ? 'outlined'
                  : 'contained'
              }
              color="primary"
              style={{ marginRight: 20 }}
              onClick={onClose}>
              {areAllAvailableAddonsPurchased()
                ? 'Close'
                : addOnsData?.isAnyAddOnPurchased
                ? 'Cancel'
                : 'Got it'}
            </Button>
            {addOnsData?.isAnyAddOnPurchased && !areAllAvailableAddonsPurchased() && (
              <Button
                disabled={confirmInput !== 'CONFIRM'}
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({ type: 'setAddOns', payload: { selectedAddons } });
                  onClose();
                }}>
                Apply add-ons
              </Button>
            )}
          </Box>
        ) : (
          <Box p={3} display="flex" alignItems="center" justifyContent="center">
            {selectedAddons.length ? (
              <Button
                variant={
                  addOnsData?.isAnyAddOnPurchased && !areAllAvailableAddonsPurchased()
                    ? 'outlined'
                    : 'contained'
                }
                color="primary"
                style={{ marginRight: 20 }}
                onClick={() => {
                  onClose();
                }}>
                Cancel
              </Button>
            ) : null}
            {addOnsData?.isAnyAddOnPurchased || areAllAvailableAddonsPurchased() ? (
              <Button
                disabled={
                  submitCallback && selectedAddons.length ? confirmInput !== 'CONFIRM' : false
                }
                variant="contained"
                color="primary"
                onClick={(e) => {
                  dispatch({ type: 'setAddOns', payload: { selectedAddons } });
                  onClose();
                  if (submitCallback) submitCallback(e, selectedAddons);
                }}>
                {submitCallback && selectedAddons.length ? 'Confirm and create' : 'Finish'}
              </Button>
            ) : null}
          </Box>
        )}
      </>
    </CustomModal>
  );
};

export default EventAddonsModal;
