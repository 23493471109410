const isTrueArray = (array = []) => {
  return array && Array.isArray(array);
};

const isTrueValueArray = (array = []) => {
  const isArray = isTrueArray(array);
  if (isArray) return isArray && array.length;
  return false;
};

const getCheckCondition = (condition) => {
  if (condition) return true;
  return false;
};

const getConditionalEffects = (condition, getTrue, getFalse, getDefault = false) => {
  if (condition) return getTrue;
  else {
    if (getFalse) return getFalse;
  }
  return getDefault;
};

const setEndOfContentEditable = (contentEditableElement) => {
  if (document.createRange) {
    // Firefox, Chrome, Opera, Safari, IE 9+
    const range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  } else if (document.selection) {
    // IE 8 and lower
    const range = document.body.createTextRange(); // Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    range.select(); // Select the range (make it the visible selection
  }
};

export {
  isTrueArray,
  isTrueValueArray,
  getCheckCondition,
  getConditionalEffects,
  setEndOfContentEditable,
};
