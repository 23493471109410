import React from 'react';
import LoungeSvgIcon from 'icons/animateIcon/loungeSvg';
import SvgNoRoomsIcon from 'icons/svgNoRoomsIcon';

import SvgNoCreditCertificateIcon from 'icons/svgNoCreditCertificateIcon';
import SvgNoLeaderboardIcon from 'icons/svgNoLeaderboardIcon';
import SvgNoVirtualBackgroundsIcon from 'icons/svgNoVirtualBackgroundsIcon';
import SvgNoStudioHostsIcon from 'icons/svgNoStudioHostsIcon';
import SvgNoRecordingsIcon from 'icons/svgNoRecordingsIcon';
import SvgEmailManagerIcon from 'icons/svgEmailManager';

export const EventTypes = {
  IN_PERSON: 'IN_PERSON',
  HYBRID: 'HYBRID',
  VIRTUAL: 'VIRTUAL',
  WEBINAR: 'WEBINAR',
};

export const InPersonDisabledModules = [
  {
    title: 'Lounge',
    parentSection: 'lounge',
    childSection: '',
    infoText:
      'Networking lounges are created for attendees to interact, making the event experience engaging for them. This feature is available for virtual and hybrid event formats.',
    svg: <LoungeSvgIcon />,
  },
  {
    title: 'Rooms',
    parentSection: 'rooms',
    childSection: '',
    infoText:
      'Rooms are created to conduct demo sessions, product presentations, breakout sessions, panel discussions, etc. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoRoomsIcon />,
  },
  {
    title: 'Leaderboard',
    parentSection: 'engagement',
    childSection: 'leaderboard',
    infoText:
      'Point-based leaderboards are created to motivate attendees to participate and engage at events. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoLeaderboardIcon />,
  },
  {
    title: 'Recordings',
    parentSection: 'recording',
    childSection: '',
    infoText:
      'The sessions and rooms can be recorded and shared with attendees. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoRecordingsIcon />,
  },
  {
    title: 'Credit and Certificate',
    parentSection: 'settings',
    childSection: 'credits-certificate',
    infoText:
      'Certificates are given to attendees based on the credits created to ensure their participation in events. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoCreditCertificateIcon />,
  },
  {
    title: 'Studio Hosts',
    parentSection: 'settings',
    childSection: 'studio-hosts',
    infoText:
      'Studio hosts are moderators of sessions streaming on Hubilo Broadcast Studio. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoStudioHostsIcon />,
  },
  {
    title: 'Virtual Backgrounds',
    parentSection: 'settings',
    childSection: 'virtual-backgrounds',
    infoText:
      'Virtual backgrounds can be added to the video in rooms, lounges, meetings, and HBS. This feature is available for virtual and hybrid event formats.',
    svg: <SvgNoVirtualBackgroundsIcon />,
  },
  {
    title: 'Emails',
    parentSection: 'email-builder',
    childSection: '',
    infoText:
      'Although this feature is not available for in-person events, all automated (default) emails will be sent to attendees. You will not be able to edit automated emails, create new emails and view data of emails.',
    svg: <SvgEmailManagerIcon />,
  },
];

export const EventAddOnsMeta = {
  WHITE_LABELING: 'White-labeling',
  PROF_SERVICES: 'Professional services',
  IN_PERSON_APP: 'In-person App',
  VE_ADDON: 'Virtual event credits',
};

export const EventAddOnsMetaForToast = {
  WHITE_LABELING: 'White-labeling',
  PROF_SERVICES: 'Professional services',
  IN_PERSON_APP: 'In-person App',
  VE_ADDON: 'Virtual event',
};
