import React from 'react';
import { Box, Typography, Grid, Container, Divider } from '@material-ui/core';
import { colors } from 'constants/colors';
import { useStyles } from './style';
import { InPersonDisabledModules } from '../constants';


export const InPersonNav = () => {
  const classes = useStyles();

  const currentModuleInfo =
    InPersonDisabledModules.find((m) =>
      m.childSection !== ''
        ? window.location.href.indexOf(m.childSection) > -1
        : window.location.href.indexOf(m.parentSection) > -1
    ) || {};

  return (
    <>
      {currentModuleInfo.parentSection === 'settings' ? (
        <Box className={classes.noContentArea}>
          <Box component="header" mb={3.75}>
            <Typography color="textPrimary" variant="h5" component="h5">
              {currentModuleInfo.title}
            </Typography>
          </Box>
          <Box className={classes.divider}>
            <Divider />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="Space-between">
            <Box
              className={classes.noContentAreaLeft}
              display="flex"
              alignItems="flex-start"
              justifyContent="center">
              {currentModuleInfo.svg}
            </Box>
            <Box
              ml={4.75}
              className={classes.noContentAreaRight}
              display="flex"
              alignItems="flex-start"
              flexDirection="column">
              <Typography component="h4" variant="h4" color={colors.textPrimary}>
                This feature is not supported for <br /> in-person event format
              </Typography>
              <Typography
                component="p"
                variant="caption"
                color={colors.textSecondary}
                display="block"
                className={classes.grayHelpTextInPerson}>
                {currentModuleInfo.infoText}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={0.5}>
            <Box component="header" mb={3.75}>
              <Typography component="h1" variant="h4" color="textPrimary">
                {currentModuleInfo.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="Space-between"
              className={classes.noContentArea}>
              <Box
                className={classes.noContentAreaLeft}
                display="flex"
                alignItems="flex-start"
                justifyContent="center">
                {currentModuleInfo.svg}
              </Box>
              <Box
                ml={4.75}
                className={classes.noContentAreaRight}
                display="flex"
                alignItems="flex-start"
                flexDirection="column">
                <Typography component="h4" variant="h4" color={colors.textPrimary}>
                  This feature is not supported for <br /> in-person event format
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  color={colors.textSecondary}
                  display="block"
                  className={classes.grayHelpTextInPerson}>
                  {currentModuleInfo.infoText}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Container>
      )}
    </>
  );
};
