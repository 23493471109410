import { SET_CURRENT_EVENT_LANGUAGE } from "redux/actions/current-event-language-action";


const currentEventLanguageReducer = (state = [], action) => {
    switch (action.type) {
        case SET_CURRENT_EVENT_LANGUAGE:
            return action.payload
        default:
            return state;
    }
}

export default currentEventLanguageReducer;