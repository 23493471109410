/* eslint-disable  */

import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MultiLangOptions from 'utility/MultiLangOptions';
import MultiLanguageDropDown from './form-fields/multiLanguageDropDown';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const LanguageBox = (props) => {
  const classes = useStyles();
  const [multiLanguageOptions, setMultilanguageOptions] = useState([]);

  useEffect(() => {
    if (props.languages) {
      let languages = [];
      for (let language of props.languages) {
        const langIndex = MultiLangOptions.findIndex((i) => i.langName === language.name);
        if (langIndex !== -1) {
          languages.push({
            ...MultiLangOptions[langIndex],
            id: language.id,
          });
        }
      }
      setMultilanguageOptions(languages);
    }
  }, [props.languages]);

  return (
    <Box {...props} className={classes.box}>
      {props.children}
      <Box>
        <MultiLanguageDropDown
          selectedLanguage={props.selectedLanguage}
          onMenuSelect={props.onLanguageChange ? props.onLanguageChange : null}
          options={multiLanguageOptions}
        />
      </Box>
    </Box>
  );
};
