/* eslint-disable radix */
import React, { useEffect } from 'react';
import {
	Grid, Box, Button
} from '@material-ui/core';
import Sidebar from 'custom-components/sidebar';
import { IconSidebarLoader } from './skeleton'

const iconInit = {
	id: 16
}

export const IconSideBar = (props) => {
	const { classes, loadingState, action, selectedIcon, dataList, isOpen, onClose, handelClickOnIcon } = props
	const [iconEvt, setIconEvt] = React.useState('');
	const [selIconId, setSelIconId] = React.useState(-1);
	const [tempSelIconId, setTempSelIconId] = React.useState(-1);

	useEffect(() => {
		if (selectedIcon) {
			setSelIconId(selectedIcon);
			setTempSelIconId(selectedIcon)
		} else {
			setSelIconId(iconInit.id);
			setTempSelIconId(iconInit.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, selectedIcon]);
	return (<Sidebar
		open={isOpen}
		title="Choose Icon"
		onClose={() => { onClose(); setSelIconId(tempSelIconId) }}
		asideWidth={650}
		footerBtnLabel="Choose"
		footerBtnAction={() => { handelClickOnIcon(iconEvt, action); setTempSelIconId(iconEvt.id) }}
	>
		<Grid container spacing={2} >
			{dataList && dataList.length > 0
				? <>
					{dataList.map((item, key) => {
						return <Grid item xs={2} key={key}>
							{action === "add" && parseInt(selIconId) === parseInt(item.id) ?
								<Button className={`${classes.iconButtonSidebar} ${classes.activeBorderColor}`} data-item={JSON.stringify(item)} onClick={() => { setIconEvt(item); setSelIconId(item.id) }} >
									<Box component="span" display="flex" alignItems="center" justifyContent="center" className={classes.eventsIcon} height={60} width={60} >
										<Box component="span" display="flex" alignItems="center" justifyContent="center" dangerouslySetInnerHTML={{ __html: item.svg || item.icon }} />
									</Box>
								</Button> :
								action === "edit" && parseInt(selIconId) === parseInt(item.id) ?
									<Button className={`${classes.iconButtonSidebar} ${classes.activeBorderColor}`} data-item={JSON.stringify(item)} onClick={() => { setIconEvt(item); setSelIconId(item.id) }} >
										<Box component="span" display="flex" alignItems="center" justifyContent="center" className={classes.eventsIcon} height={60} width={60} >
											<Box component="span" display="flex" alignItems="center" justifyContent="center" dangerouslySetInnerHTML={{ __html: item.svg || item.icon }} />
										</Box>
									</Button>
									: <Button className={classes.iconButtonSidebar} data-item={JSON.stringify(item)} onClick={() => { setIconEvt(item); setSelIconId(item.id) }} >
										<Box component="span" display="flex" alignItems="center" justifyContent="center" className={classes.eventsIcon} height={60} width={60} >
											<Box component="span" display="flex" alignItems="center" justifyContent="center" dangerouslySetInnerHTML={{ __html: item.svg || item.icon }} />
										</Box>
									</Button>
							}

						</Grid>
					})}</> : false
			}
		</Grid>
		{loadingState.iconLoad ? <IconSidebarLoader /> : false}
	</Sidebar>)
}
