/* eslint-disable prefer-promise-reject-errors */
import ProjectSetting from 'settings';
import { removeUserCredential, getUserCredential } from 'lib/cookies';
import axios from 'axios';
import { setNewMessage } from 'redux/actions/setting-actions';
import { removeUserInfo } from 'redux/actions/user-action';
import configureStore from 'redux/store';
import { removeEventData as removeEventDataRedux } from 'redux/actions/event-action';

export const API_CALL_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const API_CALL_RESP_CODES = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INT_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  UNPROCESSABLE: 422,
};

export const httpErrorStatusHandler = (error, history, dispatch, cb, hasShowMessage = true) => {
  if (error.response) {
    const resp = error.response;
    const { status } = resp;
    const message = resp?.data?.message ? resp.data?.message : resp?.data?.data?.message || '';
    if (
      status === API_CALL_RESP_CODES.BAD_REQUEST ||
      status === API_CALL_RESP_CODES.UNAUTHORIZED ||
      status === API_CALL_RESP_CODES.NOT_FOUND ||
      status === API_CALL_RESP_CODES.INT_SERVER_ERROR ||
      status === API_CALL_RESP_CODES.FORBIDDEN
    ) {
      if (typeof message === 'string' && hasShowMessage && dispatch) {
        dispatch(setNewMessage({ message, type: 'error', show: true }));
      }
    }
    if (status === API_CALL_RESP_CODES.UNAUTHORIZED) {
      if (dispatch) {
        dispatch(removeUserInfo());
        dispatch(removeEventDataRedux());
      }
      removeUserCredential();
      if (history) {
        if (history.dispatch) {
          history.dispatch(removeUserInfo());
        }
        if (history.history) {
          history.history.push('/login');
        }
      }
      if (history) history.push('/login');
    }
    if (cb) {
      cb(resp.data, status);
    }
  } else if (error.message) {
    dispatch(setNewMessage({ message: error.message, type: 'error', show: true }));
  }
};

export const errStatusHandler = (resp, history, dispatch, cb, hasShowMessage = true) => {
  const { status, message } = resp.data;
  if (
    status === API_CALL_RESP_CODES.BAD_REQUEST ||
    status === API_CALL_RESP_CODES.UNAUTHORIZED ||
    status === API_CALL_RESP_CODES.NOT_FOUND ||
    status === API_CALL_RESP_CODES.INT_SERVER_ERROR
  ) {
    if (typeof message === 'string' && hasShowMessage && dispatch) {
      dispatch(setNewMessage({ message, type: 'error', show: true }));
    }
  }
  if (status === API_CALL_RESP_CODES.UNAUTHORIZED) {
    if (dispatch) {
      dispatch(removeUserInfo());
      dispatch(removeEventDataRedux());
    }
    removeUserCredential();
    if (history) {
      if (history.dispatch) {
        history.dispatch(removeUserInfo());
      }
      if (history.history) {
        history.history.push('/login');
      }
    }
    if (history) history.push('/login');
  }
  if (cb) {
    cb(resp.data, status);
  }
};

export const generateHeader = (reduxData, reqHeader, reqBody) => {
  let headers = {};
  let body = {};

  if (reduxData) {
    const { eventId, organiserId } = reduxData;
    let cookiesData = {};
    try {
      cookiesData = getUserCredential();
    } catch (err) {
      // TODO : Error Console
    }
    if (cookiesData && cookiesData.authToken) {
      headers.Authorization = `Bearer ${cookiesData.authToken}`;
    }
    if (cookiesData && cookiesData.organiser_id) {
      headers.organiserId = cookiesData.organiser_id ? cookiesData.organiser_id : organiserId;
    } else {
      headers.organiserId = organiserId || cookiesData?.organiser_id;
    }

    if (eventId) {
      headers.eventId = eventId;
    }
    if (cookiesData && cookiesData.teamMemberId) {
      headers.teamMemberId = cookiesData.teamMemberId ? cookiesData.teamMemberId : 0;
    }
    if (ProjectSetting.buildversion) {
      headers.buildversion = ProjectSetting.buildversion;
    }
  }
  if (reqHeader) {
    headers = { ...headers, ...reqHeader };
  }
  if (reqBody) {
    body = { ...body, ...reqBody };
  }

  return [headers, body];
};

const makeFakeApi = ({ data, isErr, message }) => {
  return new Promise((resolve, reject) => {
    if (isErr) {
      reject({ status: API_CALL_RESP_CODES.BAD_REQUEST, message });
    } else
      setTimeout(
        () => resolve({ status: API_CALL_RESP_CODES.OK, data, message }),
        API_CALL_RESP_CODES.BAD_REQUEST
      );
  });
};

export const makeApi = (URL, method, apiStore, headers, body) => {
  const config = {
    method,
    url: URL,
    headers,
  };
  if (apiStore) {
    if (apiStore.has(URL)) {
      const cancel = apiStore.get(URL);
      cancel();
    }
    config.cancelToken = new axios.CancelToken(function executor(cancelCb) {
      apiStore.set(URL, cancelCb);
    });
  }
  if (body) {
    config.data = body;
  }
  return axios(config);
};
const upload = (URL, apiStore, header, formData) => {
  let headers;
  if (header) headers = { ...header };
  else headers = {};
  let cookiesData = {};
  try {
    cookiesData = getUserCredential();
  } catch (err) {
    // TODO : ERROR CONSOLE
  }
  if (cookiesData && cookiesData.authToken) {
    headers.Authorization = `Bearer ${cookiesData.authToken}`;
  }
  headers['Content-Type'] = 'multipart/;form-data';
  const config = {
    headers,
    method: API_CALL_METHODS.POST,
    url: ProjectSetting.baseURL + URL,
    data: formData,
  };
  if (apiStore?.progressCB) {
    config.onUploadProgress = (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      apiStore.progressCB(percentCompleted);
    }
  }
  if (apiStore) {
    if (apiStore.has(URL)) {
      const cancel = apiStore.get(URL);
      cancel();
    }
    config.cancelToken = new axios.CancelToken(function executor(cancelCb) {
      apiStore.set(URL, cancelCb);
    });
  }
  return axios(config);
};
const uploadWithCustomDomin = (URL, apiStore, header, formData, customDomain = '') => {
  let headers;
  if (header) headers = { ...header };
  else headers = {};
  let cookiesData = {};
  try {
    cookiesData = getUserCredential();
  } catch (err) {
    // TODO : ERROR CONSOLE
  }
  if (cookiesData && cookiesData.authToken) {
    headers.Authorization = `Bearer ${cookiesData.authToken}`;
    // body.access_token = cookiesData.authToken;
  }
  headers['Content-Type'] = 'multipart/;form-data';
  const config = {
    headers,
    method: API_CALL_METHODS.POST,
    url: customDomain === '' ? ProjectSetting.baseURL + URL : customDomain + URL,
    data: formData,
  };
  if (apiStore) {
    if (apiStore.has(URL)) {
      const cancel = apiStore.get(URL);
      cancel();
    }
    config.cancelToken = new axios.CancelToken(function executor(cancelCb) {
      apiStore.set(URL, cancelCb);
    });
  }
  return axios(config);
};
// common
export const POSTAPI = (isFake, URL, apiStore, headers, body) => {
  if (isFake) return makeFakeApi(headers);
  else return makeApi(ProjectSetting.baseURL + URL, API_CALL_METHODS.POST, apiStore, headers, body);
};

export const GETAPI = (isFake, URL, apiStore, headers) => {
  if (isFake) return makeFakeApi(headers);
  else return makeApi(ProjectSetting.baseURL + URL, API_CALL_METHODS.GET, apiStore, headers);
};
export const PUTAPI = (isFake, URL, apiStore, headers, body) => {
  if (isFake) return makeFakeApi(headers);
  else return makeApi(ProjectSetting.baseURL + URL, API_CALL_METHODS.PUT, apiStore, headers, body);
};

export const POSTAPIWITHCUSTOMDOMAIN = (
  isFake,
  URL,
  apiStore,
  headers,
  body,
  customDomain = ''
) => {
  let apiUrl = '';
  if (customDomain !== '') {
    apiUrl = customDomain + URL;
  } else {
    apiUrl = ProjectSetting.baseURL + URL;
  }
  if (isFake) return makeFakeApi(headers);
  else return makeApi(apiUrl, API_CALL_METHODS.POST, apiStore, headers, body);
};

export const GETAPIWITHCUSTOMDOMAIN = (isFake, URL, apiStore, headers, body, customDomain = '') => {
  let apiUrl = '';
  if (customDomain !== '') {
    apiUrl = customDomain + URL;
  } else {
    apiUrl = ProjectSetting.baseURL + URL;
  }
  if (isFake) return makeFakeApi(headers);
  else return makeApi(apiUrl, API_CALL_METHODS.GET, apiStore, headers, body);
};

export const PUTAPIWITHCUSTOMDOMAIN = (isFake, URL, apiStore, headers, body, customDomain = '') => {
  let apiUrl = '';
  if (customDomain !== '') {
    apiUrl = customDomain + URL;
  } else {
    apiUrl = ProjectSetting.baseURL + URL;
  }
  if (isFake) return makeFakeApi(headers);
  else return makeApi(apiUrl, API_CALL_METHODS.PUT, apiStore, headers, body);
};

export const PATCH_API_WITH_CUSTOM_DOMAIN = (
  isFake,
  URL,
  apiStore,
  headers,
  customDomain = '',
  body = {}
) => {
  let apiUrl = '';
  if (customDomain !== '') {
    apiUrl = customDomain + URL;
  } else {
    apiUrl = ProjectSetting.baseURL + URL;
  }
  if (isFake) return makeFakeApi(headers);
  else return makeApi(apiUrl, API_CALL_METHODS.PATCH, apiStore, headers, body);
};

export const DELETE_API_WITH_CUSTOM_DOMAIN = (
  isFake,
  URL,
  apiStore,
  headers,
  customDomain = '',
  body = {}
) => {
  let apiUrl = '';
  if (customDomain !== '') {
    apiUrl = customDomain + URL;
  } else {
    apiUrl = ProjectSetting.baseURL + URL;
  }
  if (isFake) return makeFakeApi(headers);
  else return makeApi(apiUrl, API_CALL_METHODS.DELETE, apiStore, headers, body);
};
// not used right now
export const UPLOADAPI = (URL, apiStore, header, formData) => {
  return upload(URL, apiStore, header, formData);
};
export const UPLOADAPICUSTOMDOMAIN = (URL, apiStore, header, formData, customDomain = '') => {
  return uploadWithCustomDomin(URL, apiStore, header, formData, customDomain);
};
export const API = {
  removeAllApi: (apiStore) => {
    apiStore.forEach((cancel) => cancel());
  },
  catchHandler: (thrown, cb) => {
    if (axios.isCancel(thrown)) {
      // TODO : ERROR CONSOLE
    } else if (cb) {
      cb(thrown);
    } else {
      // TODO : ERROR CONSOLE
    }
  },
  errStatusHandler,
  generateHeader,
  httpErrorStatusHandler,
  apiSuccessStatus: API_CALL_RESP_CODES.OK,
};

export const genericAPICall = (
  url,
  method = 'GET',
  data = {},
  params = {},
  extraHeaders = {},
  isBackendURL = false, // for supporting backend end point urls
  baseURL = '' // for overriding baseURL
) => {
  const [headers] = API.generateHeader(configureStore().getState(), {}, null);
  let overrideBaseURL;
  if (baseURL) {
    overrideBaseURL = baseURL;
  } else {
    if (isBackendURL) {
      overrideBaseURL = ProjectSetting.baseURL;
    } else {
      overrideBaseURL = ProjectSetting.customBaseURL2;
    }
  }

  const requestData = {
    url,
    baseURL: overrideBaseURL,
    method,
    headers: { ...headers, ...extraHeaders },
  };
  if (Object.keys(data).length) {
    requestData.data = data;
  }
  if (Object.keys(params).length) {
    requestData.params = params;
  }
  return axios.request(requestData);
};

export const genericAPIErrorHandler =
  (isGlobal = true, error) =>
    (dispatch) => {
      let errorMsg = error;
      if (isGlobal) {
        errorMsg = 'Something went wrong!!, Try after sometime';
      }
      dispatch(setNewMessage({ message: errorMsg, type: 'error', show: true }));
    };
