export const SETUP_ZOOM_APP = 'SETUP_ZOOM_APP';
export const ZOOM_TOKEN_EXPIRE = 'ZOOM_TOKEN_EXPIRE';
export const SET_ZOOM_APP_IDS = 'SET_ZOOM_APP_IDS';
export const SET_ZOOM_ERROR = 'SET_ZOOM_ERROR';
export const SET_ZOOM_SCOPE_ERROR = 'SET_ZOOM_SCOPE_ERROR';

export const setupZoomApp = (isSetup = false) => {
  return {
    type: SETUP_ZOOM_APP,
    data: isSetup,
  };
};

export const setupZoomExpire = (tokenExpire) => {
  return {
    type: ZOOM_TOKEN_EXPIRE,
    data: tokenExpire,
  };
};

export const setZoomAppIds = (appId) => {
  return {
    type: SET_ZOOM_APP_IDS,
    data: {
      appId,
    },
  };
};
export const setZoomIntegrationError = (isZoomError = false) => {
  return {
    type: SET_ZOOM_ERROR,
    data: isZoomError,
  };
};

export const setZoomScopeError = (zoomScopeError = false) => {
  return {
    type: SET_ZOOM_SCOPE_ERROR,
    data: zoomScopeError,
  };
};
