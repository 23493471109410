import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSource } from 'lib/custom-common-hooks';

import { commonSelector } from 'redux/custom-selector';

import projectSetting from 'settings';

import { API, POSTAPIWITHCUSTOMDOMAIN, API_CALL_RESP_CODES } from 'api-setup/api-helper';

import { isTrueValueArray } from 'utility/helpers';

import { GET_VIRTUAL_BOOTH_LIST } from 'api-setup/api-endpoints';

import { getBoothImage } from 'lib/common-function';

const CUSTOM_URL = projectSetting.baseURL;

const useBooth = () => {
  const source = useSource();
  const dispatch = useDispatch();
  const history = useHistory();

  const reduxData = useSelector(commonSelector, shallowEqual);

  const [item, setItem] = useState();

  const getBooths = useCallback(
    (currPage = 0, prevItems = [], prevTotalPage = 0) => {
      const payload = {
        page: currPage,
        search: '',
        limit: 200,
      };

      const [header] = API.generateHeader(reduxData, payload);

      POSTAPIWITHCUSTOMDOMAIN(false, GET_VIRTUAL_BOOTH_LIST, source.AS, header, null, CUSTOM_URL)
        .then((resp) => {
          const { current_page: currentPage, total_page: totalPage } = resp.data;
          let { data } = resp.data;
          data = data.map((sp) => {
            return {
              name: sp?.name,
              id: sp?.exhibitor_id,
              profile_img: sp?.profile_img
                ? getBoothImage('profileImg', sp?.profile_img, reduxData.organiserId)
                : 'https://cdn.hubilo.com/comm_v2/images/profile/exhibitor_default.png',
            };
          });
          if (resp.status === API_CALL_RESP_CODES.OK) {
            const fetchTotalPages = totalPage || prevTotalPage;
            if (data) {
              if (
                fetchTotalPages &&
                fetchTotalPages > 1 &&
                currentPage !== fetchTotalPages &&
                currentPage < fetchTotalPages
              ) {
                getBooths(currentPage + 1, [...prevItems, ...data], fetchTotalPages);
                return;
              }
              setItem([...prevItems, ...data]);
            }
          } else {
            const hasItems = isTrueValueArray([...prevItems]);
            if (hasItems) setItem([...prevItems]);
            else setItem(false);
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [item, getBooths];
};

export { useBooth };
