import learnMoreLinks from 'learnMoreLinks';

const leadScoreLearnMoreUrls = {
  activityScoreBoardUrl: learnMoreLinks.activityScoreBoardUrl,
  leadScoreBoardUrl: learnMoreLinks.leadScoreBoardUrl,
  leadLevelBoardUrl: learnMoreLinks.leadLevelBoardUrl,
  leadLevelAnalyticsUrl: learnMoreLinks.leadScoreAnalytics,
};

export default leadScoreLearnMoreUrls;
