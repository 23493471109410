/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgFreetrialRestrictions(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="100"
      viewBox="0 0 120 100"
      fill="none"
      {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M61.3355 1.21611L57.7563 2.13912L13.6598 13.5107L10.0806 14.4337C8.34326 14.8838 6.85544 16.0047 5.94351 17.5504C5.03159 19.0961 4.77003 20.9404 5.21622 22.6788L20.7365 82.8627C21.1866 84.6 22.3074 86.0878 23.8531 86.9998C25.3988 87.9117 27.2431 88.1732 28.9815 87.7271L28.9907 87.7247L80.2272 74.5118L80.2364 74.5094C81.9737 74.0593 83.4615 72.9385 84.3735 71.3928C85.2854 69.8471 85.547 68.0027 85.1008 66.2644L69.5805 6.08049C69.1304 4.34318 68.0096 2.85535 66.4639 1.94342C64.9182 1.03149 63.0738 0.769923 61.3355 1.21611Z"
          fill="#DBDBDB"
        />
        <path
          d="M61.8213 3.10217L57.582 4.1954L14.8055 15.2266L10.5663 16.3198C9.32908 16.6403 8.26956 17.4385 7.62015 18.5393C6.97075 19.64 6.78448 20.9534 7.10223 22.1913L22.6225 82.3752C22.943 83.6124 23.7412 84.6719 24.8419 85.3213C25.9426 85.9707 27.256 86.157 28.4939 85.8392L28.5032 85.8368L79.7396 72.624L79.7489 72.6216C80.9861 72.301 82.0456 71.5028 82.695 70.4021C83.3444 69.3014 83.5306 67.988 83.2129 66.7501L67.6927 6.5662C67.3721 5.32904 66.574 4.26953 65.4733 3.62012C64.3725 2.97071 63.0592 2.78444 61.8213 3.10217Z"
          fill="white"
        />
        <path
          d="M58.62 22.5913L32.7007 29.2754C32.4112 29.35 32.1039 29.3066 31.8464 29.1547C31.589 29.0028 31.4024 28.7548 31.3277 28.4653C31.2531 28.1758 31.2965 27.8685 31.4484 27.611C31.6003 27.3536 31.8483 27.167 32.1377 27.0923L58.057 20.4082C58.3464 20.3339 58.6535 20.3775 58.9108 20.5294C59.168 20.6813 59.3545 20.9292 59.4291 21.2185C59.5037 21.5079 59.4604 21.815 59.3087 22.0724C59.157 22.3298 58.9093 22.5164 58.62 22.5913Z"
          fill="#C1C1C1"
        />
        <path
          d="M64.0728 25.1141L33.6509 32.9593C33.3614 33.0339 33.0541 32.9905 32.7966 32.8386C32.5391 32.6867 32.3526 32.4387 32.2779 32.1492C32.2032 31.8597 32.2467 31.5525 32.3986 31.295C32.5505 31.0375 32.7985 30.8509 33.0879 30.7762L63.5098 22.931C63.7993 22.8564 64.1066 22.8998 64.3641 23.0517C64.6216 23.2036 64.8082 23.4516 64.8828 23.7411C64.9575 24.0306 64.9141 24.3379 64.7621 24.5954C64.6102 24.8528 64.3622 25.0394 64.0728 25.1141Z"
          fill="#C1C1C1"
        />
        <path
          d="M62.8778 39.1007L36.9585 45.7848C36.8152 45.8218 36.666 45.8301 36.5194 45.8094C36.3728 45.7887 36.2318 45.7394 36.1043 45.6641C35.9768 45.5889 35.8653 45.4893 35.7763 45.371C35.6873 45.2527 35.6225 45.1181 35.5855 44.9747C35.5485 44.8314 35.5402 44.6822 35.5609 44.5356C35.5816 44.389 35.631 44.248 35.7062 44.1205C35.7814 43.993 35.881 43.8815 35.9993 43.7925C36.1176 43.7035 36.2522 43.6387 36.3956 43.6017L62.3149 36.9177C62.4582 36.8807 62.6074 36.8723 62.754 36.893C62.9006 36.9137 63.0416 36.9631 63.1691 37.0383C63.2966 37.1135 63.4081 37.2131 63.4971 37.3314C63.5861 37.4497 63.6509 37.5844 63.6879 37.7277C63.7248 37.871 63.7332 38.0203 63.7125 38.1668C63.6918 38.3134 63.6424 38.4545 63.5672 38.582C63.492 38.7095 63.3924 38.8209 63.2741 38.9099C63.1558 38.9989 63.0212 39.0638 62.8778 39.1007Z"
          fill="#C1C1C1"
        />
        <path
          d="M68.3301 41.6235L37.9082 49.4687C37.7649 49.5056 37.6157 49.514 37.4691 49.4933C37.3225 49.4726 37.1815 49.4232 37.054 49.348C36.9265 49.2728 36.815 49.1732 36.726 49.0549C36.637 48.9366 36.5722 48.802 36.5352 48.6586C36.4983 48.5153 36.4899 48.3661 36.5106 48.2195C36.5313 48.0729 36.5807 47.9318 36.6559 47.8044C36.7311 47.6769 36.8307 47.5654 36.949 47.4764C37.0673 47.3874 37.2019 47.3226 37.3453 47.2856L67.7671 39.4404C68.0566 39.3658 68.3639 39.4092 68.6214 39.5611C68.8789 39.713 69.0655 39.961 69.1401 40.2505C69.2148 40.54 69.1714 40.8473 69.0195 41.1047C68.8676 41.3622 68.6196 41.5488 68.3301 41.6235Z"
          fill="#C1C1C1"
        />
        <path
          d="M67.1349 55.6101L41.2156 62.2942C40.9262 62.3686 40.6191 62.3251 40.3617 62.1731C40.1044 62.0212 39.918 61.7733 39.8433 61.4839C39.7687 61.1946 39.812 60.8874 39.9638 60.63C40.1156 60.3726 40.3633 60.1859 40.6526 60.1111L66.5719 53.427C66.8614 53.3524 67.1687 53.3958 67.4262 53.5477C67.6837 53.6996 67.8703 53.9476 67.9449 54.2371C68.0196 54.5266 67.9762 54.8339 67.8243 55.0914C67.6723 55.3488 67.4244 55.5354 67.1349 55.6101Z"
          fill="#C1C1C1"
        />
        <path
          d="M72.5874 58.1329L42.1656 65.9781C42.0222 66.0152 41.8729 66.0237 41.7262 66.0031C41.5795 65.9824 41.4383 65.9331 41.3107 65.8579C41.1831 65.7827 41.0716 65.6831 40.9825 65.5648C40.8934 65.4464 40.8286 65.3117 40.7916 65.1683C40.7546 65.0249 40.7462 64.8755 40.767 64.7289C40.7877 64.5822 40.8372 64.4411 40.9125 64.3136C40.9878 64.186 41.0875 64.0746 41.2059 63.9856C41.3243 63.8966 41.4591 63.8319 41.6026 63.795L72.0244 55.9498C72.3139 55.8752 72.6212 55.9186 72.8787 56.0705C73.1362 56.2224 73.3228 56.4704 73.3975 56.7599C73.4721 57.0494 73.4287 57.3567 73.2768 57.6141C73.1249 57.8716 72.8769 58.0582 72.5874 58.1329Z"
          fill="#C1C1C1"
        />
        <path
          d="M28.2763 36.1289L19.1037 38.4943C18.9649 38.53 18.8176 38.5091 18.6942 38.4363C18.5707 38.3635 18.4812 38.2447 18.4453 38.1059L16.3397 29.941C16.3041 29.8022 16.325 29.655 16.3978 29.5315C16.4706 29.4081 16.5894 29.3186 16.7282 29.2827L25.9008 26.9172C26.0396 26.8816 26.1868 26.9025 26.3102 26.9753C26.4337 27.0481 26.5232 27.1669 26.5591 27.3057L28.6647 35.4706C28.7003 35.6094 28.6794 35.7566 28.6066 35.8801C28.5338 36.0035 28.415 36.093 28.2763 36.1289Z"
          fill="#DBDBDB"
        />
        <path
          d="M32.5336 52.6383L23.361 55.0038C23.2222 55.0394 23.0749 55.0185 22.9515 54.9457C22.8281 54.8729 22.7386 54.7541 22.7026 54.6153L20.5971 46.4505C20.5614 46.3117 20.5823 46.1644 20.6551 46.041C20.728 45.9175 20.8468 45.828 20.9855 45.7921L30.1581 43.4267C30.2969 43.391 30.4441 43.4119 30.5676 43.4847C30.691 43.5576 30.7805 43.6764 30.8164 43.8151L32.922 51.98C32.9576 52.1188 32.9367 52.266 32.8639 52.3895C32.7911 52.5129 32.6723 52.6024 32.5336 52.6383Z"
          fill="#DBDBDB"
        />
        <path
          d="M36.7909 69.1477L27.6183 71.5132C27.4795 71.5488 27.3322 71.5279 27.2088 71.4551C27.0854 71.3823 26.9959 71.2635 26.96 71.1247L24.8544 62.9599C24.8188 62.821 24.8396 62.6738 24.9125 62.5504C24.9853 62.4269 25.1041 62.3374 25.2428 62.3015L34.4154 59.9361C34.5542 59.9004 34.7015 59.9213 34.8249 59.9941C34.9483 60.067 35.0378 60.1858 35.0738 60.3245L37.1793 68.4894C37.215 68.6282 37.1941 68.7754 37.1213 68.8989C37.0484 69.0223 36.9296 69.1118 36.7909 69.1477Z"
          fill="#DBDBDB"
        />
        <path
          d="M97.0824 18.4198H44.1505C42.8725 18.4212 41.6472 18.9295 40.7435 19.8332C39.8398 20.7369 39.3315 21.9622 39.3301 23.2402V85.3931C39.3315 86.6712 39.8398 87.8964 40.7435 88.8001C41.6472 89.7038 42.8725 90.2121 44.1505 90.2136H97.0824C98.3604 90.2121 99.5857 89.7038 100.489 88.8001C101.393 87.8964 101.901 86.6711 101.903 85.3931V23.2402C101.901 21.9622 101.393 20.737 100.489 19.8333C99.5857 18.9296 98.3604 18.4212 97.0824 18.4198Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.1494 17.4198L44.1505 17.4198L97.0824 17.4198V18.4198H44.1505C42.8725 18.4212 41.6472 18.9295 40.7435 19.8332C39.8398 20.7369 39.3315 21.9622 39.3301 23.2402V85.3931C39.3315 86.6712 39.8398 87.8964 40.7435 88.8001C41.6472 89.7038 42.8725 90.2121 44.1505 90.2136H97.0824C98.3604 90.2121 99.5857 89.7038 100.489 88.8001C101.393 87.8964 101.901 86.6712 101.903 85.3931V23.2402C101.901 21.9622 101.393 20.737 100.489 19.8333C99.5857 18.9296 98.3604 18.4212 97.0824 18.4198L97.0835 17.4198C98.6264 17.4215 100.106 18.0352 101.196 19.1262C102.287 20.2171 102.901 21.6963 102.903 23.2391V85.3943C102.901 86.9371 102.287 88.4162 101.196 89.5072C100.106 90.5982 98.6264 91.2118 97.0835 91.2136H44.1494C42.6066 91.2118 41.1274 90.5982 40.0364 89.5072C38.9455 88.4163 38.3318 86.9371 38.3301 85.3943L38.3301 85.3931L38.3301 23.2391C38.3318 21.6963 38.9455 20.2171 40.0364 19.1261C41.1274 18.0352 42.6066 17.4215 44.1494 17.4198Z"
          fill="#353535"
        />
        <path
          d="M89.116 52.5726H62.3487C62.2006 52.5728 62.0539 52.5438 61.917 52.4872C61.78 52.4306 61.6556 52.3476 61.5508 52.2429C61.446 52.1382 61.3629 52.0139 61.3061 51.8771C61.2494 51.7402 61.2202 51.5935 61.2202 51.4454C61.2202 51.2973 61.2494 51.1506 61.3061 51.0137C61.3629 50.8769 61.446 50.7526 61.5508 50.6479C61.6556 50.5432 61.78 50.4602 61.917 50.4036C62.0539 50.347 62.2006 50.318 62.3487 50.3181H89.116C89.4147 50.3185 89.7011 50.4374 89.9122 50.6488C90.1234 50.8601 90.242 51.1466 90.242 51.4454C90.242 51.7441 90.1234 52.0307 89.9122 52.242C89.7011 52.4534 89.4147 52.5723 89.116 52.5726Z"
          fill="#DBDBDB"
        />
        <path
          d="M93.7658 56.3771H62.3487C62.2006 56.3773 62.0539 56.3482 61.917 56.2916C61.78 56.2351 61.6556 56.1521 61.5508 56.0474C61.446 55.9427 61.3629 55.8184 61.3061 55.6815C61.2494 55.5447 61.2202 55.398 61.2202 55.2498C61.2202 55.1017 61.2494 54.955 61.3061 54.8182C61.3629 54.6813 61.446 54.557 61.5508 54.4523C61.6556 54.3476 61.78 54.2646 61.917 54.208C62.0539 54.1515 62.2006 54.1224 62.3487 54.1226H93.7658C94.0648 54.1226 94.3515 54.2414 94.5629 54.4528C94.7743 54.6642 94.8931 54.9509 94.8931 55.2498C94.8931 55.5488 94.7743 55.8355 94.5629 56.0469C94.3515 56.2583 94.0648 56.3771 93.7658 56.3771Z"
          fill="#DBDBDB"
        />
        <path
          d="M89.116 69.6222H62.3487C62.2006 69.6223 62.0539 69.5933 61.917 69.5367C61.78 69.4801 61.6556 69.3971 61.5508 69.2925C61.446 69.1878 61.3629 69.0634 61.3061 68.9266C61.2494 68.7898 61.2202 68.6431 61.2202 68.4949C61.2202 68.3468 61.2494 68.2001 61.3061 68.0633C61.3629 67.9264 61.446 67.8021 61.5508 67.6974C61.6556 67.5927 61.78 67.5097 61.917 67.4531C62.0539 67.3966 62.2006 67.3675 62.3487 67.3677H89.116C89.4149 67.3677 89.7016 67.4865 89.913 67.6979C90.1244 67.9093 90.2432 68.196 90.2432 68.4949C90.2432 68.7939 90.1244 69.0806 89.913 69.292C89.7016 69.5034 89.4149 69.6222 89.116 69.6222Z"
          fill="#DBDBDB"
        />
        <path
          d="M93.7658 73.4266H62.3487C62.2006 73.4268 62.0539 73.3977 61.917 73.3412C61.78 73.2846 61.6556 73.2016 61.5508 73.0969C61.446 72.9922 61.3629 72.8679 61.3061 72.731C61.2494 72.5942 61.2202 72.4475 61.2202 72.2994C61.2202 72.1512 61.2494 72.0045 61.3061 71.8677C61.3629 71.7309 61.446 71.6065 61.5508 71.5018C61.6556 71.3971 61.78 71.3141 61.917 71.2576C62.0539 71.201 62.2006 71.172 62.3487 71.1721H93.7658C93.914 71.172 94.0607 71.201 94.1976 71.2576C94.3345 71.3141 94.4589 71.3971 94.5637 71.5018C94.6685 71.6065 94.7517 71.7309 94.8084 71.8677C94.8651 72.0045 94.8943 72.1512 94.8943 72.2994C94.8943 72.4475 94.8651 72.5942 94.8084 72.731C94.7517 72.8679 94.6685 72.9922 94.5637 73.0969C94.4589 73.2016 94.3345 73.2846 94.1976 73.3412C94.0607 73.3977 93.914 73.4268 93.7658 73.4266Z"
          fill="#DBDBDB"
        />
        <path
          d="M56.353 58.1042H46.8804C46.7371 58.104 46.5997 58.047 46.4983 57.9457C46.397 57.8443 46.34 57.7069 46.3398 57.5636V49.1316C46.34 48.9883 46.397 48.8509 46.4983 48.7496C46.5997 48.6483 46.7371 48.5913 46.8804 48.5911H56.353C56.4963 48.5913 56.6337 48.6483 56.7351 48.7496C56.8364 48.8509 56.8934 48.9883 56.8936 49.1316V57.5636C56.8934 57.7069 56.8364 57.8443 56.7351 57.9457C56.6337 58.047 56.4963 58.104 56.353 58.1042Z"
          fill="#DBDBDB"
        />
        <path
          d="M56.353 75.1537H46.8804C46.7371 75.1535 46.5997 75.0965 46.4983 74.9952C46.397 74.8939 46.34 74.7565 46.3398 74.6132V66.1811C46.34 66.0378 46.397 65.9004 46.4983 65.7991C46.5997 65.6978 46.7371 65.6408 46.8804 65.6406H56.353C56.4963 65.6408 56.6337 65.6978 56.7351 65.7991C56.8364 65.9004 56.8934 66.0378 56.8936 66.1811V74.6132C56.8934 74.7565 56.8364 74.8939 56.7351 74.9952C56.6337 75.0965 56.4963 75.1535 56.353 75.1537Z"
          fill="#DBDBDB"
        />
        <path
          d="M89.1458 33.5932H69.4114C69.1125 33.5932 68.8257 33.4744 68.6143 33.263C68.4029 33.0516 68.2842 32.7649 68.2842 32.4659C68.2842 32.167 68.4029 31.8803 68.6143 31.6689C68.8257 31.4575 69.1125 31.3387 69.4114 31.3387H89.1458C89.4448 31.3387 89.7315 31.4575 89.9429 31.6689C90.1543 31.8803 90.2731 32.167 90.2731 32.4659C90.2731 32.7649 90.1543 33.0516 89.9429 33.263C89.7315 33.4744 89.4448 33.5932 89.1458 33.5932Z"
          fill="#353535"
        />
        <path
          d="M93.7957 37.3976H69.4114C69.2634 37.3976 69.1168 37.3684 68.98 37.3118C68.8433 37.2552 68.719 37.1721 68.6143 37.0674C68.5097 36.9628 68.4266 36.8385 68.37 36.7017C68.3133 36.565 68.2842 36.4184 68.2842 36.2704C68.2842 36.1223 68.3133 35.9758 68.37 35.839C68.4266 35.7022 68.5097 35.578 68.6143 35.4733C68.719 35.3686 68.8433 35.2856 68.98 35.2289C69.1168 35.1723 69.2634 35.1431 69.4114 35.1431H93.7957C94.0947 35.1431 94.3814 35.2619 94.5928 35.4733C94.8042 35.6847 94.9229 35.9714 94.9229 36.2704C94.9229 36.5693 94.8042 36.856 94.5928 37.0674C94.3814 37.2788 94.0947 37.3976 93.7957 37.3976Z"
          fill="#353535"
        />
        <path
          d="M65.044 42.0082H46.8506C46.7073 42.008 46.5699 41.951 46.4685 41.8497C46.3672 41.7483 46.3102 41.6109 46.3101 41.4676V27.2687C46.3102 27.1254 46.3672 26.988 46.4685 26.8867C46.5699 26.7853 46.7073 26.7284 46.8506 26.7282H65.044C65.1873 26.7284 65.3247 26.7853 65.426 26.8867C65.5274 26.988 65.5843 27.1254 65.5845 27.2687V41.4676C65.5843 41.6109 65.5274 41.7483 65.426 41.8497C65.3247 41.951 65.1873 42.008 65.044 42.0082Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M97.0336 99.2667C106.956 99.2667 115 91.3869 115 81.6667C115 71.9464 106.956 64.0667 97.0336 64.0667C87.1109 64.0667 79.0669 71.9464 79.0669 81.6667C79.0669 91.3869 87.1109 99.2667 97.0336 99.2667Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M98.801 72.9359V70.6667H95.736V72.9131C94.4043 73.1525 93.3368 73.7113 92.5562 74.5893C91.7756 75.4559 91.3853 76.4594 91.3853 77.5769C91.3853 78.6488 91.6608 79.5268 92.2233 80.2338C92.7743 80.9294 93.4516 81.4311 94.2436 81.739C95.0357 82.0355 95.8278 82.2978 96.6314 82.5258C97.4349 82.7539 98.1122 83.0276 98.6632 83.3696C99.2142 83.7003 99.5012 84.1451 99.5012 84.6924C99.5012 85.2968 99.2601 85.7985 98.778 86.1976C98.2959 86.5853 97.6415 86.7906 96.7921 86.7906C96.0574 86.7906 95.3457 86.6195 94.6799 86.3002C94.0026 85.9695 93.5663 85.7073 93.3712 85.5134C93.176 85.3196 93.0268 85.1599 92.9235 85.0459L90.7998 87.144L90.995 87.3835C91.1097 87.5203 91.3164 87.7028 91.6034 87.9309C91.8789 88.1589 92.2118 88.387 92.5791 88.615C92.9465 88.8431 93.4056 89.0712 93.9681 89.2878C94.5306 89.4931 95.1161 89.6527 95.736 89.7439V91.9333H98.801V89.6641C100.201 89.3904 101.303 88.7975 102.084 87.9081C102.865 87.0072 103.266 85.9353 103.266 84.6924C103.266 83.6205 102.979 82.7311 102.428 82.0355C101.866 81.3285 101.189 80.8268 100.385 80.5303C99.5816 80.2224 98.7895 79.9601 97.9974 79.7321C97.2053 79.504 96.528 79.2189 95.977 78.8882C95.426 78.5576 95.1505 78.1242 95.1505 77.5769C95.1505 77.0866 95.3457 76.6761 95.7589 76.334C96.1722 75.9919 96.7117 75.8208 97.389 75.8208C97.94 75.8208 98.491 75.9463 99.0191 76.2085C99.5471 76.4594 99.8915 76.6647 100.052 76.8243C100.213 76.9839 100.351 77.1094 100.443 77.2234L102.555 74.9998L102.176 74.6007C101.923 74.3384 101.487 74.0306 100.867 73.6885C100.236 73.3464 99.5471 73.0955 98.801 72.9359Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="42.1179"
          y1="34.3682"
          x2="65.3633"
          y2="22.0899"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.520833" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="71.2514"
          y1="81.6667"
          x2="118.115"
          y2="61.6342"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.520833" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="110" height="98.7243" fill="white" transform="translate(5 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}
