import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  navCard: {
    borderRadius: 10,
  },
  navCardContent: {
    padding: `${theme.spacing(1.2)}px !important`,
  },
  breadCrumbsBlock: {
    '& a': {
      color: theme.palette.text.grayColor,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
  },
  pageWrapper: {
    maxHeight: `calc(100% + ${theme.spacing(8)}px)`,
    padding: `${theme.spacing(4)}px ${theme.spacing(3.25)}px`,
    margin: `-${theme.spacing(4)}px -${theme.spacing(3.25)}px`,
    overflowX: 'hidden',
    overflowY: 'auto',
    // scrollBehavior: 'smooth',
  },

  rightSideWrapper: {
    '& > section': {
      paddingLeft: `${theme.spacing(0)}px`,
      '& header ~ section': {
        marginTop: `${theme.spacing(0)}px`,
        padding: `${theme.spacing(0)}px`,
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: 'auto'
      },
    },
  },

  eventList: {
    paddingTop: '0',
    '& .disabledItem': {
      pointerEvents: 'none',
      '& li': {
        backgroundColor: theme.palette.bg.whiteOff1,
        opacity: 0.5,
      },
    },

    borderRadius: theme.shape.borderRadius,
    '& li': {
      transition: '0.3s',
      marginBottom: `${theme.spacing(2)}px`,
      padding: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`,
      '&:hover .MuiListItemSecondaryAction-root': {
        '& button': {
          opacity: 1,
        },
      },
    },
    '& .childWrapper': {
      maxHeight: '300px',
      overflowX: 'hidden',
      overflowY: 'auto',
      '&>div': {
        '&>li': {
          width: '85%',
          marginLeft: 'auto',
        },
      },
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
    '& .Mui-disabled~ .MuiListItemSecondaryAction-root': {
      '& svg': {
        color: theme.palette.text.grayColor,
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        padding: theme.spacing(0.8),
      },
      '& .circleTrashIconColor': {
        opacity: 0,
        color: theme.palette.primary.main,
      },
      '& svg': {
        color: theme.palette.primary.main,
      },
    },

    '& .MuiListItemText-secondary': {
      fontSize: theme.fontSize.size12,
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    '& .MuiListItem-secondaryAction': {
      border: `1px solid ${theme.palette.border.light}`,
      borderRadius: 10,
    },

    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: 10,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}`,
      },
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      '& ~ .MuiListItemSecondaryAction-root': {
        '&  .circleTrashIconColor': {
          opacity: 1,
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
    },

    '& .dragPlaceHolder': {
      background: theme.palette.bg.whiteOff1,
      border: `1px dashed ${theme.palette.border.light}`,
      borderRadius: theme.shape.borderRadius,
      height: 76,
      position: 'relative',
      '&:after': {
        content: "'Drop here to add an event section'",
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.grayColor,
        zIndex: 1,
      },
      '&:before': {
        content: "''",
        backgroundColor: theme.palette.alert.danger,
        position: 'absolute',
        height: '2px',
        width: '100%',
        bottom: -10,
        zIndex: 2,
      },
    },

    '& .card-ghost': {
      transform: `rotate(-15deg)`,
      transformOrigin: '10% 50%',
      transition: '0.1s all',
    },

    '& .card-ghost-drop': {
      transform: `rotate(0deg)`,
      '& .draggable': {
        background: theme.palette.alert.successMid,
      },
    },

    // '& .smooth-dnd-ghost':{

    // }
  },
  peopleBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },
  dividerBlock: {
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.bg.whiteOff2,
      height: 1,
    },
  },
  switchBtnStyle: {
    '& label': {
      marginRight: '0px !important',
      '&>span': {
        margin: 0,
      },
    },
  },
  headingTitle: {
    fontWeight: 600,
  },
  changeIconBtn: {
    width: 65,
    height: 65,
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: 7,
  },
  appSectionHeading: {
    // backgroundColor: theme.palette.border.lighter,
    // borderRadius: `8px 8px 0 0`,
    borderBottom: `1px solid  ${theme.palette.bg.whiteOff2}`,
    wordBreak: `break-word`,
  },
  iconButtonSidebar: {
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: theme.shape.borderRadius,
  },
  activeBorderColor: {
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  peopleHeading: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  peopleSubTitle: {
    paddingTop: theme.spacing(1.5),
  },
  btnActive: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
  },
  divider: {
    padding: `0`,
    '@media screen and (max-width: 960px)': {
      padding: `0 ${theme.spacing(2.5)}px`,
    },

    '& .MuiDivider-root': {
      backgroundColor: theme.palette.bg.whiteOff2,
    },
  },
  checkBoxRow: {
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    padding: `0 ${theme.spacing(2)}px`,
    borderRadius: 4,
  },
  footerActionBtn: {
    borderTop: `1px solid  ${theme.palette.bg.whiteOff2}`,
  },

  addCustomField: {
    '& button': {
      width: '100%',
    },
  },

  orDivider: {
    position: 'relative',
    '&::before': {
      backgroundColor: '#DBDBDB',
      width: '47%',
      height: '1px',
      left: '0',
      top: '50%',
      content: "''",
      position: `absolute`,
      zIndex: 1,
    },
    '&::after': {
      backgroundColor: '#DBDBDB',
      width: '47%',
      height: '1px',
      right: '0',
      top: '50%',
      content: "''",
      position: `absolute`,
      zIndex: 1,
    },
  },

  preBuiltFieldsArea: {
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: `${theme.spacing(1.5)}px`,
    position: 'relative',
    '& .preBuiltFieldsTitle': {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      '& h6': {
        fontSize: `${theme.fontSize.size2} !important`,
      },
    },
    '& .preBuiltFieldsContainer': {
      padding: `${theme.spacing(0)}px ${theme.spacing(2.6)}px`,
      minHeight: 'calc(100vh - 375px)',
      maxHeight: 'calc(100vh - 375px)',
      overflowY: 'auto',
      marginBottom: '90px',
      '& .preFieldOptionListArea': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',

        '& .noPreBuiltFields': {
          textAlign: 'center',
          position: 'absolute',
          left: '50%',
          top: '44%',
          transform: 'translate(-50%, -44%)',
        },
        '& .preFieldOptionList': {
          border: '1px solid #DBDBDB',
          backgroundColor: theme.palette.bg.white,
          padding: `${theme.spacing(1)}px`,
          position: 'relative',
          left: '.9rem',
          marginBottom: theme.spacing(2),
          borderRadius: '4px',
          width: '248px',
          height: '67px',
          '&.selected': {
            border: `1px solid  ${theme.palette.primary.main}`,
          },
          '& .preFieldOption': {
            '& label': {
              '& span:first-child': {
                display: 'block',
                fontWeight: 500,
                fontSize: '14px',
              },
              '& span:nth-child(2)': {
                display: 'block',
                alignItems: 'center',
                fontSize: '14px',
              },
            },
          },
          '&:nth-child(odd)': {
            marginLeft: '-0.8rem',
          },
          '&:nth-child(even)': {
            marginLeft: theme.spacing(1.1),
          },
        },
      },
    },
    '& .preBuiltFooterSection': {
      padding: `${theme.spacing(2.6)}px ${theme.spacing(2.6)}px`,
      maxHeight: '84px',
      display: 'flex',
      backgroundColor: theme.palette.bg.white,
      borderTop: `1px solid  ${theme.palette.bg.whiteOff2}`,
      borderRadius: `${theme.spacing(0)}px ${theme.spacing(0)}px 
      ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: '100%',
      '& .disableClearButton': {
        backgroundColor: 'none',
        background: 'none',
      },
    },
  },

  boldText: {
    fontWeight: 500,
  },
  regDateTimeRow: {
    maxWidth: '100%',
    '& label': {
      border: '0',
      padding: `0`,
      left: '0',
      marginBottom: '0.5rem',
      borderRadius: '0',
      textTransform: 'none',
    },
    '& .eventDateTime': {
      width: '100%',
      '& .eventDatePicker': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        padding: ' 8px 10px',
      },
      '& .eventTimePicker': {
        '& div': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
        },
        '& input': {
          textAlign: 'center !important',
        },
        '& label': {
          width: '100%',
          '& .MuiInputLabel-asterisk': {
            visibility: 'hidden',
          },
        },
      },
      '& ul': {
        maxHeight: '135px',
      },
    },
    '& .create-event-year-select': {
      border: '0 !important',
      background: '#fff !important',
      fontSize: '14px',
      fontWeight: '500',
      paddingRight: '10px',
      paddingLeft: '20px',
      '&:hover': {
        background: theme.palette.primary.light,
        boxShadow: '0 !important',
        border: '0 !important',
        cursor: 'pointer',
      },
      '& .create-event-year-select-option': {
        backgroundColor: '#fff',
      },
    },
    '& .mapBlock': {
      overflow: 'hidden',
      '& .mapLocation': {
        overflow: 'hidden',
      },
    },
    '& .eventDetailsCountrySelect': {
      '& ul': {
        maxHeight: '200px',
        paddingBottom: '90px',
      },
    },
    '& .eventDetailsTimezoneSelect': {
      '& ul': {
        maxHeight: '140px',
      },
    },
  },
  eventBlockSetting: {
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 5,
  },

  eventNameBlockSkeleton: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(2.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2),
    },
  },
  appRightBlockContent: {
    maxHeight: 320,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '20px',
    margin: '0 -16px',
  },
}));
