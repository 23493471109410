import { SET_TABLE_DATA } from 'redux/actions/user-action';


const ticketDataInfo = (state = {}, action) => {
     switch (action.type) {
        case SET_TABLE_DATA:
            // eslint-disable-next-line no-param-reassign
            state = { ...state, ...action.data };
            break;
        default:
            break;
    }
    return state;
}


export default ticketDataInfo