/* eslint-disable no-param-reassign */
import { SET_ALERT_DATA } from 'redux/actions/alert-action';

const alertData = (state = {}, action) => {
  switch (action.type) {
    case SET_ALERT_DATA:
      state = { ...state, ...action.data };
      break;
    default:
      break;
  }
  return state;
};

export default alertData;
