/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputField from '../../custom-components/form-fields/inputField';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import { VERIFY_TEAM, TEAM_LOGIN } from '../../api-setup/api-endpoints';
import Validation from '../../models/validation';
import { setUserInfo, removeUserInfo } from 'redux/actions/user-action';
import { VerifyAccountSchema } from '../../models/schema';
import { setUserCredential, removeUserCredential } from 'lib/cookies';
import { useDispatch } from 'react-redux';
import { removeAllFromQuery } from 'lib/url-utilities';
import { POSTAPI, GETAPI, API } from 'api-setup/api-helper';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { setNewMessage } from 'redux/actions/setting-actions';
import { Lock, Briefcase, User } from 'react-feather';
import PreLoader from 'custom-components/preloader';
import RequestNewLink from './requestNewLink';
import mixPanel from 'lib/mix-panel';
import amplitude from 'lib/amplitude';

const useStyles = makeStyles((theme) => ({
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  socialLoginBtn: {
    background: 'white',
    boxShadow: '0px 3px 6px #0000001A',
    minHeight: '34px',
    flexGrow: 1,
    borderRadius: 4,
    '&:nth-child(n+2)': {
      marginLeft: 10,
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

// handles validations for form fields
const handleValidations = (formFields) => {
  let newErrObj = '';
  newErrObj = Validation.validate(VerifyAccountSchema, formFields);
  if (newErrObj) return newErrObj;
  return false;
};

export default function VerifyTeamAccount(props) {
  const { subTitleWithSeparator, iconColor } = useStyles();
  const [isTokenVerified, setTokenVerified] = useState(false);
  const [formFields, setFormFields] = useState({
    organizationName: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
  });
  const [errObj, setErrObj] = useState('');
  const [token, setToken] = useState('');
  const history = useHistory();
  const [isInitLoading, setInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isProceed, setIsProceed] = useState(false);
  const dispatch = useDispatch();

  const formSubmit = (event) => {
    event.preventDefault();
    const err = handleValidations(formFields);
    if (!err) {
      setIsProceed(true);
      setErrObj({});
      const data = {
        organisation: formFields.organizationName,
        password: formFields.password,
        confirm_password: formFields.confirmPassword,
        firstname: formFields.firstName,
        lastname: formFields.lastName,
        token,
      };

      makeAPIcall(
        { data },
        null,
        history,
        (userInfo) => {
          mixPanel.Login.loginSuccessMP(
            userInfo.id,
            userInfo.email,
            userInfo.firstName,
            userInfo.lastName,
            'FORM'
          );
          const deviceIdAmplitude = decodeURIComponent(
            window.location.search.replace(
              new RegExp(
                `^(?:.*[&\\?]${
                  // eslint-disable-next-line no-useless-escape
                  encodeURIComponent('deviceId').replace(/[\.\+\*]/g, '\\$&')
                }(?:\\=([^&]*))?)?.*$`,
                'i'
              ),
              '$1'
            )
          );
          if (deviceIdAmplitude) {
            amplitude.Track.loginSuccessful(
              'email',
              userInfo.email,
              userInfo.firstName,
              userInfo.lastName,
              userInfo.id,
              userInfo.organisation,
              deviceIdAmplitude
            );
          } else {
            amplitude.Track.loginSuccessful(
              'email',
              userInfo.email,
              userInfo.firstName,
              userInfo.lastName,
              userInfo.id,
              userInfo.organisation
            );
          }
          dispatch(setUserInfo(userInfo));
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
        dispatch
      );
    } else {
      setErrObj(err);
    }
  };

  const makeAPIcall = (data, recaptchaRef, propHistory, cb, errorCb, propDispatch) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    POSTAPI(false, TEAM_LOGIN, null, headers, data)
      .then((res) => {
        if (res.data.status === API.apiSuccessStatus) {
          setIsProceed(false);
          const { token: tempToken, userData, team_member_id, organiser_id } = res.data.data;
          setUserCredential(
            {
              authToken: tempToken,
              teamMemberId: team_member_id,
              organiser_id,
              userPermission: userData.userPermission,
            },
            'userData'
          );
          if (cb) cb(userData);
          propHistory.push('/events');
          propDispatch(
            setNewMessage({
              message: 'Success! Your Account is active',
              type: 'success',
              show: true,
            })
          );
        } else {
          errorCb(res.data.message || 'Some error occurred');
          setIsProceed(false);
          API.errStatusHandler(res, propHistory, propDispatch, () => {});
        }
      })
      .catch((err) => {
        const errorMsg =
          err.response && err.response.data ? err.response.data.message : 'Some error occurred';
        errorCb(errorMsg);
        setIsProceed(false);
        API.catchHandler(err, () => {});
      });
  };

  useEffect(() => {
    const _token = props.location.search.split('=').pop();
    if (!_token) {
      props.history.replace('/login');
    } else {
      dispatch(removeUserInfo());
      removeUserCredential();
      mixPanel.Login.logoutSuccessMP();
      const tempToken = props.location.search.split('=').pop();
      setToken(tempToken);
      removeAllFromQuery(props.history, props.location);
      const headers = {
        Authorization: `Bearer ${tempToken}`,
      };
      GETAPI(false, VERIFY_TEAM, null, headers, null)
        .then((res) => {
          setInitLoading(false);
          if (res.data.status === API.apiSuccessStatus) {
            const data = {
              firstName: res.data.data.first_name,
              lastName: res.data.data.last_name,
            };
            setFormFields({ ...formFields, ...data });
            setTokenVerified(true);
          } else {
            API.errStatusHandler(res, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };
  if (isInitLoading)
    return (
      <Box display="flex" alignItems="center">
        <Box position="relative" width={350} height={350} m="auto">
          <PreLoader />
        </Box>
      </Box>
    );
  return (
    <>
      {!isTokenVerified ? (
        <RequestNewLink />
      ) : (
        <>
          <Box mb={10} display="flex" justifyContent="center">
            <HubiloLogo height="70px" width="140px" />
          </Box>
          <Box mt={3.2}>
            <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
              <Typography variant="caption" component="span">
                SETUP YOUR Account
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputField
                  disabled={isLoading}
                  error={errObj.organizationName}
                  name="organizationName"
                  id="organizationName"
                  value={formFields.organizationName}
                  onChange={handleChange}
                  required
                  placeholder="Organization"
                  type="text"
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<Briefcase className={iconColor} />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  error={errObj.firstName}
                  name="firstName"
                  id="firstName"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  type="text"
                  value={formFields.firstName}
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<User className={iconColor} />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  error={errObj.lastName}
                  name="lastName"
                  id="lastName"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  type="text"
                  value={formFields.lastName}
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<User className={iconColor} />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  disabled={isLoading}
                  error={errObj.password}
                  name="password"
                  id="password"
                  onChange={handleChange}
                  value={formFields.password}
                  required
                  placeholder="Password"
                  type="password"
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<Lock className={iconColor} />}
                  showFieldVisibilityBtn
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  disabled={isLoading}
                  error={errObj.confirmPassword}
                  name="confirmPassword"
                  id="confirmPassword"
                  value={formFields.confirmPassword}
                  onChange={handleChange}
                  required
                  placeholder="Confirm Password"
                  type="password"
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<Lock className={iconColor} />}
                  showFieldVisibilityBtn
                />
              </Grid>
            </Grid>
            <Box mt={5}>
              <BtnWithLoader
                onClick={(e) => {
                  formSubmit(e);
                }}
                className="uppercase"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isProceed}
                isLoading={isProceed}
                loadingLabel="Proceeding..."
                label="Proceed"
                fullWidth
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
