import { SET_SURVEY_DATA, UNSET_SURVEY } from 'redux/actions/survey-actions';

const SurveyDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SURVEY_DATA:
            return { ...state, ...action.data };
        case UNSET_SURVEY:
            return {};
        default:
            break;
    }
    return state;
}

export default SurveyDetailsReducer;