/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  GETAPI,
  API,
  POSTAPI,
  POSTAPIWITHCUSTOMDOMAIN,
  GETAPIWITHCUSTOMDOMAIN,
  genericAPICall,
} from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import ProjectSetting from 'settings';

const BASE_URL = ProjectSetting.customBaseURL;
const BASE_URL2 = ProjectSetting.customBaseURL2;
export function useList(
  isGenericAPI = false,
  reduxData,
  history,
  Helper,
  defaultURL,
  extraBody,
  methodType = 'GET',
  limit = 15,
  shouldExecute = true,
  unshiftNone,
  defaultHeaders = null
) {
  const source = useSource();
  const [paginateList, setPaginateList] = React.useState({
    search: '',
    page: 1,
    totalPage: 1,
    options: [],
    loading: false,
    initLoad: true,
    paginateLoader: false,
    searchLoader: false,
  });
  const [isExecuted, setExecute] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!source.AS) source.AS = new Map();
    if (!isExecuted && shouldExecute) {
      setExecute(true);
      getOptions('', false);
    }
    return () => API.removeAllApi(source.AS);
  }, []);

  const getOptions = (input, isPaginate, body, isReset = false, URL) => {
    if (!URL) URL = defaultURL;
    let curPaginateList = paginateList;
    if (isReset) {
      curPaginateList = {
        search: '',
        page: 1,
        totalPage: 1,
        options: [],
        loading: false,
        initLoad: true,
        paginateLoader: false,
        searchLoader: false,
      };
      setPaginateList(curPaginateList);
    } else {
      if (isPaginate && curPaginateList.page + 1 > curPaginateList.totalPage) return;
      if (isPaginate && curPaginateList.loading) return;
      if (!curPaginateList.loading) {
        const searchLoader = input.length ? true : false;
        setPaginateList({
          ...curPaginateList,
          loading: true,
          searchLoader,
          paginateLoader: isPaginate,
        });
      }
    }
    let bodyObj = {
      search: input ? input : isPaginate && curPaginateList.search ? curPaginateList.search : '',
      page: !isPaginate ? 1 : curPaginateList.page + 1,
      limit,
    };
    if (!body) bodyObj = { ...bodyObj, ...extraBody, ...defaultHeaders };
    else bodyObj = { ...bodyObj, ...body, ...defaultHeaders };
    const [headers] = API.generateHeader(reduxData, bodyObj, null);
    if (isGenericAPI) {
      return genericAPICall(defaultURL, methodType, extraBody, {}, headers).then((resp) => {
        const { status, data } = resp.data;
        if (status === 200) {
          const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
          if (bodyObj.page === 1) {
            obj.totalPage = resp.data.total_page;
            obj.initLoad = false;
          }
          if (unshiftNone && bodyObj.page === 1 && !obj.search && obj.options.length) {
            obj.options.unshift({
              id: '',
              name: 'None',
            });
          }
          setPaginateList(obj);
        } else API.errStatusHandler(resp, { dispatch, history });
      });
    } else {
      if (methodType === 'GET') {
        return GETAPI(false, URL, source.AS, headers)
          .then((resp) => {
            const { status, data } = resp.data;
            if (status === 200) {
              const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
              if (bodyObj.page === 1) {
                obj.totalPage = resp.data.total_page;
                obj.initLoad = false;
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      } else if (methodType === 'POST') {
        return POSTAPI(false, URL, source.AS, headers, bodyObj.data)
          .then((resp) => {
            const { status, data } = resp.data;
            if (status === 200) {
              const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
              if (bodyObj.page === 1) {
                obj.totalPage = resp.data.total_page;
                obj.initLoad = false;
              }
              if (unshiftNone && bodyObj.page === 1 && !obj.search && obj.options.length) {
                obj.options.unshift({
                  id: '',
                  name: 'None',
                });
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      } else if (methodType === 'GET_NODE') {
        return GETAPIWITHCUSTOMDOMAIN(false, URL, source.AS, headers, bodyObj.data, BASE_URL)
          .then((resp) => {
            const { status, data } = resp.data;
            if (status === 200) {
              const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
              if (bodyObj.page === 1) {
                obj.totalPage = resp.data.total_page;
                obj.initLoad = false;
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      } else if (methodType === 'GET_NODE2') {
        return GETAPIWITHCUSTOMDOMAIN(false, URL, source.AS, headers, bodyObj.data, BASE_URL2)
          .then((resp) => {
            const { status, data } = resp.data;
            if (status === 200) {
              const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
              if (bodyObj.page === 1) {
                obj.totalPage = resp.data.total_page;
                obj.initLoad = false;
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      } else if (methodType === 'POST_NODE') {
        return POSTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, headers, bodyObj.data, BASE_URL)
          .then((resp) => {
            let { status, data } = resp.data;
            if (status === 200) {
              data = data.map((item) => ({
                ...item,
                name: `${item.firstName} ${item.lastName}`,
                avatar: item.profilePictures.thumb,
              }));
              const obj = Helper.createData(data, curPaginateList, bodyObj, isPaginate);
              if (bodyObj.page === 1) {
                obj.totalPage = resp.data.total_page;
                obj.initLoad = false;
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      } else if (methodType === 'POST_NODE2') {
        const [header] = API.generateHeader(reduxData, null, null);
        const payloadObject = {
          payload: {
            data: {
              currentPage: bodyObj?.page - 1,
              limit: 5,
              orgNotCount: (bodyObj?.page - 1) * 5,
            },
          },
        };
        return POSTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, payloadObject, BASE_URL2)
          .then((resp) => {
            let { status, data } = resp.data;
            if (status === 200) {
              const obj = {
                ...bodyObj,
                options: isPaginate ? [...curPaginateList.options, ...data.list] : data.list,
                loading: false,
              };
              if (bodyObj.page === 1) {
                obj.totalPage = data?.totalPages;
                obj.unread = data?.unread;
                obj.initLoad = false;
              }
              setPaginateList(obj);
            } else API.errStatusHandler(resp, { dispatch, history });
          })
          .catch(API.catchHandler);
      }
    }
  };
  return [paginateList, getOptions, setPaginateList];
}
