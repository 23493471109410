import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  /** EventSectionNewUI */
  selectColumnField: {
    fontWeight: 400,
    maxWidth: 235,
  },
  columnText: {
    fontWeight: 500,
  },
  selectColumns: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(0.625)}px ${theme.spacing(
      0.75
    )}px ${theme.spacing(1.5)}px`,
    width: '100%',
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
  },
  switchBtnDrgStyle: {
    marginLeft: 'auto',
    '& label': {
      marginRight: '0px !important',
      '&>span': {
        margin: 0,
      },
    },
  },
  dragFieldLine: {
    width: 16,
    borderRadius: 4,
    height: 4,
    backgroundColor: theme.palette.bg.whiteOff2,
    '& + span': {
      marginTop: 4,
    },
  },
  eventBlockSetting: {
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  eventList: {
    '& .disabledItem': {
      pointerEvents: 'none',
      '& li': {
        backgroundColor: theme.palette.bg.whiteOff1,
        opacity: 0.5,
      },
    },

    borderRadius: theme.shape.borderRadius,
    '& li': {
      transition: '0.3s',
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
      '&:hover .MuiListItemSecondaryAction-root': {
        '& button': {
          opacity: 1,
        },
      },
    },
    '& .childWrapper': {
      maxHeight: '300px',
      overflowX: 'hidden',
      overflowY: 'auto',
      '&>div': {
        '&>li': {
          width: '85%',
          marginLeft: 'auto',
        },
      },
    },
    '& .Mui-disabled': {
      backgroundColor: theme.palette.bg.whiteOff1,
      //  pointerEvents: 'auto !important',
    },
    '& .Mui-disabled~ .MuiListItemSecondaryAction-root': {
      '& svg': {
        color: theme.palette.text.grayColor,
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'flex',
      alignItems: 'center',
      '& button': {
        padding: theme.spacing(0.8),
      },
      '& .circleTrashIconColor': {
        opacity: 0,
        color: theme.palette.primary.main,
      },
      '& svg': {
        color: theme.palette.primary.main,
      },
    },

    '& .MuiListItemText-secondary': {
      fontSize: theme.fontSize.size12,
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}`,
      },
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      '& ~ .MuiListItemSecondaryAction-root': {
        '&  .circleTrashIconColor': {
          opacity: 1,
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
    },

    '& .dragPlaceHolder': {
      background: theme.palette.bg.whiteOff1,
      border: `1px dashed ${theme.palette.border.light}`,
      borderRadius: theme.shape.borderRadius,
      height: 76,
      position: 'relative',
      '&:after': {
        content: "'Drop here to add an event section'",
        position: 'absolute',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.grayColor,
        zIndex: 1,
      },
      '&:before': {
        content: "''",
        backgroundColor: theme.palette.alert.danger,
        position: 'absolute',
        height: '2px',
        width: '100%',
        bottom: -10,
        zIndex: 2,
      },
    },

    '& .card-ghost': {
      transform: `rotate(-15deg)`,
      transformOrigin: '10% 50%',
      transition: '0.1s all',
    },

    '& .card-ghost-drop': {
      transform: `rotate(0deg)`,
      '& .draggable': {
        background: theme.palette.alert.successMid,
      },
    },

    // '& .smooth-dnd-ghost':{

    // }
  },

  eventCustomBlock: {
    '&:hover li': {
      width: '85%',
      marginLeft: 'auto',
    },
  },
  warnMsg: {
    background: theme.palette.alert.warn2Light,
    color: '#FF8630',
    border: '1px solid #FFE0CB',
  },
  eventNameBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
    '&.toastBackground': {
      background: `${theme.palette.alert.success2Bg} !important`,
    },
    '&.badgeText': {
      '& .MuiListItemText-secondary': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.bg.white,
        borderRadius: 4,
        display: 'inline-flex',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontWeight: 400,
      },
    },

    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(6.2),
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiListItemText-root': {
      '&>.eventSectiontitleTitle': {
        '&:hover': {
          overflow: 'visible',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          width: 'auto',
        },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 120,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[3],
      '& .MuiListItemText-root': {
        '& label': {
          color: theme.palette.primary.main,
        },
      },
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
  },

  eventsIcon: {
    color: theme.palette.text.grayColor,
    '&>div': {
      height: '100%',
    },
    '& img': {
      height: '100%',
      width: '100%',
    },
    '& span': {
      height: '30px',
      width: '30px',
    },
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
  eventNameBlockCheck: {
    right: 10,
    display: 'flex',
  },
  eventNameBlockTrash: {
    right: 55,
    display: 'flex',
    cursor: 'pointer',
  },
  peopleWrapper: {
    // minHeight: 395,
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },
  peopleBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    position: 'sticky',
    top: 0,
    '& .setDefaultBtn': {
      background: 'none',
      padding: 0,
      '& span p': {
        color: theme.palette.primary.main,
      },
      '&:disabled': {
        '& span p': {
          color: '#c0c0c0',
        },
        '&:hover': {
          color: '#c0c0c0',
        },
      },
    },
  },
  disabledBlock: {
    cursor: 'not-allowed',
    background: 'rgba(256,256,256,0.8)',
    borderRadius: 10,
  },
  fieldsDividerBlock: {
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.text.primary,
      height: 1,
    },
  },
  switchBtnStyle: {
    '& label': {
      marginRight: '0px !important',
      '&>span': {
        margin: 0,
      },
    },
  },
  headingTitle: {
    fontWeight: 500,
  },
  changeIconBtn: {
    width: 65,
    height: 65,
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: 7,
  },
  appSectionHeading: {
    // backgroundColor: theme.palette.border.lighter,
    // borderRadius: `8px 8px 0 0`,
    borderBottom: `1px solid  ${theme.palette.bg.whiteOff2}`,
    wordBreak: `break-word`,
  },
  appRightBlockContent: {
    maxHeight: 355,
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .editIcon': {
      width: 28,
      height: 28,
      backgroundColor: theme.palette.bg.whiteOff1,
      border: `1px solid  ${theme.palette.border.dark}`,
      borderRadius: '50%',
      position: 'absolute',
      right: -14,
      top: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: 14,
        height: 14,
        color: theme.palette.text.primary,
      },
    },
  },
  appchangeIconBtn: {
    width: 110,
    height: 110,
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid  ${theme.palette.border.dark}`,
    borderRadius: 7,
    '& svg': {
      width: '4.68rem',
      height: '4.68rem',
      color: theme.palette.primary.main,
    },
  },
  iconButtonSidebar: {
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: theme.shape.borderRadius,
  },
  activeBorderColor: {
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  peopleHeading: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  peopleSubTitle: {
    paddingTop: theme.spacing(1.5),
  },
  groupDragable: {
    flexDirection: 'column',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  btnActive: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
  },
  footerActionBtn: {
    borderTop: `1px solid  ${theme.palette.bg.whiteOff2}`,
  },
  eventSettingBlock: {
    '& li': {
      '& .MuiListItemSecondaryAction-root': {
        right: 0,
      },
    },
    '& .MuiListItem-divider': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  /** welcomeMailer.jsx */
  footerActionButton: {
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&>button': {
      '&:nth-child(n+1)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  /** taxAndInvoicing.jsx */
  taxCardContent: {
    padding: 0,
  },
  labelStyle: {
    fontWeight: 500,
    fontSize: theme.fontSize.size2,
  },
  boldText: {
    fontWeight: 500,
  },
  /** studioHosts.jsx */
  hostBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: theme.shadows[3],
      '& button': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  hostBlockLoader: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
  },
  swapIcon: {
    color: theme.palette.primary.light,
  },
  deleteIcon: {
    color: theme.palette.text.danger1,
  },
  sessionHost: {
    color: theme.palette.primary.light,
  },
  hostActionBtn: {
    '& button': {
      padding: theme.spacing(1),
      visibility: 'hidden',
      opacity: 0,
    },
  },
  addHostBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    '& button': {
      padding: theme.spacing(1),
      color: theme.palette.primary.light,
    },
  },
  sessionBlock: {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2),
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: theme.shadows[3],
    },
  },
  sessionTrack: {
    height: 12,
    width: 5,
    borderRadius: 10,
    background: '#FBB349',
  },
  progressBarLine: {
    minHeight: 8,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.bg.whiteOff2,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  countSession: {
    padding: theme.spacing(0),
    fontWeight: 400,
    color: theme.palette.primary.light,
    fontSize: theme.fontSize.size9,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      background: 'none',
    },
  },
  addedAttendeeList: {
    '& li + li': {
      marginTop: theme.spacing(2.5),
    },
  },
  addedAttendedItem: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(7.25)}px ${theme.spacing(
      2.5
    )}px ${theme.spacing(2.5)}px`,
    paddingTop: 19,
    paddingBottom: 19,
    '& .MuiListItemIcon-root': {
      minWidth: 80,
    },
  },
  addAttendeesAvatar: {
    width: 60,
    height: 60,
    margin: 0,
  },
  optionList: {
    maxHeight: 220,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  optionItem: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    color: theme.palette.text.grayColor,
    transition: theme.transitions.create(['color', 'background-color']),
    '& .MuiListItemText-multiline': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 52,
    },
    '&:not(.not-hover):hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.bg.whiteOff1,
        color: theme.palette.primary.main,
      },
    },
  },
  attendeeAvatar: {
    width: 36,
    height: 36,
    margin: 0,
  },
  sessionCountBtn: {
    '& p': {
      display: 'flex',
    },
  },
  hostDesc: {
    width: 190,
    wordBreak: 'break-all',
  },
  msgBlock: {
    background: theme.palette.alert.warn2Light,
    borderRadius: theme.shape.borderRadius,
    '&>p': {
      fontWeight: 500,
    },
  },
  /** setting.jsx */
  navCard: {
    borderRadius: 10,
  },
  navCardContent: {
    padding: `${theme.spacing(1.2)}px !important`,
    height: `calc(100vh - 185px)`,
    overflow: `auto`,
    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 50px rgba(0,0,0,0.35) !important',
    },
  },
  navLink: {
    borderRadius: 6,
    textTransform: 'uppercase',
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.standard,
    }),
    '& .MuiTypography-root': {
      fontSize: theme.fontSize.size8,
    },
    '& + .MuiListItem-root': {
      marginTop: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
  },
  rightCard: {
    borderRadius: 10,
    overflow: 'visible',
    padding: theme.spacing(3.75),
  },
  breadCrumbsBlock: {
    '& a': {
      color: theme.palette.text.grayColor,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
  },
  /** seo.jsx */
  bannerActionBtn: {
    minWidth: '30px',
    padding: '0',
    height: '30px',
    '&:nth-child(n+2)': {
      marginLeft: 10,
    },
  },
  cropperHeaderTitle: {
    backgroundColor: theme.palette.bg.whiteOff1,
  },
  cropperModal: {
    width: 720,
  },
  /** profileFields.jsx */
  profileEventBlockSetting: {
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 5,
  },
  profileEventList: {
    padding: 0,
    '& li': {
      '&:hover .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      opacity: 0,
      '& button': {
        color: theme.palette.primary.main,
      },
    },
    '& .profile-list': {
      '&:nth-child(n+1)': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}`,
      },
      '& .MuiListItemIcon-root': {
        '&>svg': {
          color: theme.palette.primary.main,
        },
        '&>div': {
          '&>div': {
            '& svg': {
              '&>path': {
                fill: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
      '& ~ .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
  },
  eventNameBlockSkeleton: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(2.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2),
    },
  },
  profileFieldsEventNameBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 45,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[3],
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.main,
        },
        '&>div': {
          '&>div': {
            '& svg': {
              '&>path': {
                fill: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '& .MuiListItemText-root': {
        '& label': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  warningBlock: {
    backgroundColor: theme.palette.alert.warnLight,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  profileFieldsEventsIcon: {
    color: theme.palette.text.grayColor,
    '& img': {
      height: '100%',
      width: '100%',
    },
    '& svg': {
      '& path': {
        fill: theme.palette.text.grayColor,
      },
    },
  },
  profileFieldsEventNameBlockCheck: {
    right: 22,
    display: 'flex',
  },
  profileFieldsDragFieldLine: {
    width: 16,
    borderRadius: 4,
    height: 4,
    backgroundColor: theme.palette.bg.whiteOff2,
    '& + span': {
      marginTop: 3,
    },
  },
  circleCheckIconColor: {
    color: theme.palette.primary.light,
  },
  advanceFiledOption: {
    display: 'block',
    zIndex: 1,
  },
  /** privacy.jsx */
  userConsent: {
    '&>div': {
      margin: 0,
    },
  },
  consentSubTitle: {
    color: theme.palette.text.grayColor,
  },
  consentList: {
    marginTop: theme.spacing(3),
    '& li:nth-child(n+2)': {
      marginTop: 20,
    },
  },
  privacyList: {
    padding: `${theme.spacing(1.8)}px ${theme.spacing(3)}px`,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    boxShadow: theme.shadows[1],
  },
  legalConsentTag: {
    textTransform: 'uppercase',
    margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    backgroundColor: theme.palette.primary.extraLight,
  },
  consentTitle: {
    color: theme.palette.primary.light,
    fontWeight: 500,
  },
  privacyDividerBlock: {
    height: 1,
    backgroundColor: theme.palette.bg.whiteOff2,
  },
  consentActionIcon: {
    color: theme.palette.primary.light,
  },
  consentActionBtn: {
    padding: theme.spacing(1),
  },
  link: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  /** group.jsx */
  groupSettings: {
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 5,
    // '&::-webkit-scrollbar': {
    // 	display: 'none'
    // },
  },
  groupEventList: {
    paddingTop: 0,
    '& li': {
      '&:nth-child(n+2)': {
        marginTop: theme.spacing(2.5),
      },
      '&:hover .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
    '& .MuiListItemText-secondary': {
      fontSize: theme.fontSize.size12,
      textAlign: 'left',
      textTransform: 'capitalize',
    },

    '& .MuiListItemSecondaryAction-root': {
      opacity: 0,
      '& button': {
        color: theme.palette.primary.light,
      },
    },
    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
      },
      '& .MuiListItemIcon-root': {
        '&>svg': {
          color: `${theme.palette.primary.main} !important`,
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
      '& ~ .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
  },
  groupEventNameBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.primary.contrastText,
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  groupFieldBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    padding: `${theme.spacing(1.8)}px ${theme.spacing(2.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
  },
  groupField: {
    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      '& .MuiListItemIcon-root': {
        '&>svg': {
          color: `${theme.palette.primary.main} !important`,
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      right: 25,
      display: 'flex',
    },
    '& li:nth-child(n+2)': {
      marginTop: theme.spacing(2.5),
    },
  },
  attendeeWrapper: {
    maxHeight: 395,
    overflowY: 'auto',
    overflowX: 'hidden',
    // '&::-webkit-scrollbar': {
    // 	display: 'none'
    // },
  },
  attendeeBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    position: 'sticky',
    top: 0,
  },
  groupDividerBlock: {
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.bg.whiteOff2,
      height: 1,
    },
  },
  attendeeList: {
    '& li': {
      '& .MuiListItem-secondaryAction': {
        paddingRight: 0,
      },
      '& .MuiListItemSecondaryAction-root': {
        right: 0,
      },
    },
  },
  groupIconButtonSidebar: {
    minWidth: 80,
    minHeight: 80,
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: 7,
  },
  attendeeHeading: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  groupIcons: {
    color: theme.palette.text.grayColor,
  },
  editIconColor: {
    color: theme.palette.primary.light,
  },
  listSubTitle: {
    paddingTop: theme.spacing(2),
  },
  labelWithSwitchHeading: {
    marginTop: theme.spacing(1.5),
  },
  upgradeBlock: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.alert.dangerLight,
    '& .MuiTypography-h6': {
      color: theme.palette.primary.main,
      lineHeight: theme.spacing[6],
      fontSize: theme.fontSize.size2,
      fontWeight: 700,
      marginBottom: 10,
    },
    '& .contentBlock': {
      textAlign: 'center',
    },
  },
  groupCopy: {
    backgroundColor: theme.palette.alert.alertWarnBg,
    borderRadius: theme.shape.borderRadius,
  },
  /** Integration.jsx */
  integrationCardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  integrationSubTitle: {
    color: theme.palette.text.grayColor,
    maxWidth: 500,
  },
  integrationList: {
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiListItemAvatar-root': {
      '& figure': {
        margin: 0,
        marginRight: theme.spacing(2.5),
      },
    },
  },
  largeIcon: {
    borderRadius: '50%',
  },
  zoomCard: {
    padding: theme.spacing(2.4),
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: 6,
    boxShadow: theme.shadows[2],
    '& .MuiListItemAvatar-root': {
      '& figure': {
        margin: 0,
      },
    },
  },
  primaryColor: {
    color: theme.palette.primary.light,
  },
  eventListWrapper: {
    '& li:nth-child(n+1)': {
      marginTop: theme.spacing(2.5),
    },
  },
  integrationEventList: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`,
    borderRadius: 6,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    '& .MuiListItemText-root': {
      '& p': {
        display: 'flex',
        alignItems: 'center',
        marginTop: 2,
        '& span:nth-child(n+2)': {
          marginLeft: theme.spacing(1),
        },
        '& span:first-child': {
          display: 'flex',
        },
      },
    },
  },
  listActionIcon: {
    right: 0,
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  hubiloIcon: {
    backgroundColor: '#ccc',
  },
  zoomIcon: {
    backgroundColor: '#2D8CFF',
  },
  stripeIcon: {
    backgroundColor: '#6772E5',
  },
  eventBriteIcon: {
    backgroundColor: '#F05537',
  },
  plusIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '50%',
    padding: 3,
    '&>svg': {
      color: '#A5A4BF',
    },
  },
  integrationDividerBlock: {
    backgroundColor: theme.palette.bg.whiteOff2,
    height: 1,
    marginTop: theme.spacing(1.2),
    marginBottom: theme.spacing(1.2),
  },
  disconnectBtn: {
    color: theme.palette.alert.danger2,
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  defaultIconColor: {
    color: theme.palette.text.grayColor,
  },
  successIconColor: {
    color: theme.palette.alert.success2,
  },
  successBtnColor: {
    color: theme.palette.alert.success2,
    borderColor: theme.palette.alert.success2,
    '&:hover': {
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      '& span': { display: 'none' },
      '&::before': { content: `"DISCONNECT"` },
    },
  },
  /** legal.jsx */
  legalFooterActionBtn: {
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&>button': {
      '&:nth-child(n+1)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  legalDividerBlock: {
    backgroundColor: theme.palette.bg.whiteOff2,
    height: 1,
  },
  /** LoginOption.jsx */
  borderBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    padding: theme.spacing(2.5),
    width: '100%',
    '&>li': {
      '& .MuiListItem-secondaryAction': {
        paddingRight: 0,
        '& .MuiListItemIcon-root': {
          minWidth: 35,
        },
        '& .MuiListItemText-multiline': {
          marginTop: 0,
          marginBottom: 0,
          '&>p': {
            maxWidth: 510,
          },
        },
      },
      '& .MuiListItemSecondaryAction-root': {
        right: 5,
      },
    },
  },
  socialLoginTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  loginDividerBlock: {
    backgroundColor: theme.palette.bg.whiteOff2,
  },
  highlightedText: {
    color: theme.palette.primary.light,
  },
  facebookIcon: {
    color: '#4267B2',
  },
  linkedinIcon: {
    color: '#2867B2',
  },
  googleplusIcon: {
    color: '#DB4A39',
  },
  usePlusIcon: {
    color: theme.palette.primary.light,
  },
  /** matchMakingFields.jsx */
  subTitle: {
    marginTop: 5,
    color: theme.palette.text.grayColor,
  },
  fieldsEventNameBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 8,
    padding: `${theme.spacing(2.3)}px ${theme.spacing(2.5)}px`,
    '&:nth-child(n+2)': {
      marginTop: 30,
    },
  },
  advanceFilter: {
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 5,
  },
  dividerBlock: {
    backgroundColor: theme.palette.bg.whiteOff2,
    height: 1,
    marginTop: 5,
  },
  tagBlock: {
    height: 200,
    overflowY: 'auto',
    overflowX: 'hidden',
    // '&::-webkit-scrollbar': {
    // 	display: 'none'
    // },
    '& .MuiChip-root': {
      margin: 5,
      maxWidth: '98%',
      '& .MuiChip-deleteIcon': {
        minWidth: 16,
      },
    },
  },
  listWrap: {
    paddingLeft: theme.spacing(0),
    margin: 0,
  },
  notificationTitle: {
    fontWeight: 500,
  },
  notificationFooterActionBtn: {
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&>button': {
      '&:nth-child(n+1)': {
        marginLeft: theme.spacing(2),
      },
    },
  },
  navCountLimitBox: {
    background: theme.palette.primary.lighter,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '4px',
    padding: `${theme.spacing(1)}px  ${theme.spacing(1.5)}px `,
    width: '100%',
    '& h6': {
      color: theme.palette.primary.main,
    },
  },

  cookieModalPreview: {
    backgroundColor: theme.palette.bg.whiteOff1,
    minHeight: 220,
    position: 'relative',
    '& .cookieBox': {
      backgroundColor: theme.palette.bg.whiteOff4,
      padding: `8px 10px`,
      borderRadius: 6,
      border: `1px solid ${theme.palette.bg.whiteOff2}`,
      // '& .cookieBoxBtns span':{
      //   background:theme.palette.primary.main,
      // }
    },
    '& .modalPosition': {
      position: 'absolute',
      '&.topLeft': {
        top: '.5rem',
        left: '.5rem',
      },
      '&.topRight': {
        top: '.5rem',
        right: '.5rem',
      },
      '&.bottomRight': {
        bottom: '.5rem',
        right: '.5rem',
      },
      '&.bottomLeft': {
        bottom: '.5rem',
        left: '.5rem',
      },
      '&.center': {
        top: '50%',
        left: '50%',
        transform: 'Translate(-50%,-50%)',
      },
      '&.top': {
        top: '.5rem',
        left: '.5rem',
      },
      '&.bottom': {
        bottom: '.5rem',
        left: '.5rem',
      },
    },

    '& .boxType': {
      maxWidth: 120,
    },

    '& .bannerType': {
      width: '100%',
      display: 'flex',
      alignItems: 'start',
    },
  },
  errorBlock: {
    background: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  trackingPixelSettingWrapper: {
    borderRadius: 10,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    padding: 30,
    marginTop: 24,
  },
  trackingPixelSetting: {
    marginTop: 24,
    '& .trackingPixelCommon': {
      border: `1px solid ${theme.palette.bg.whiteOff2}`,
      borderRadius: theme.shape.borderRadius,
      padding: 12,
      marginBottom: 12,
      fontWeight: 500,
      justifyContent: 'space-between',
      '& p': {
        fontWeight: 500,
      },
      '& .enabled': {
        color: theme.palette.alert.success,
        fontSize: 12,
      },
      '& .disabled': {
        color: theme.palette.alert.danger,
        fontSize: 12,
      },
    },
  },
  uploadImage: {
    border: `2px dotted ${theme.palette.bg.whiteOff2}`,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    padding: '3px 8px',
    color: `${theme.palette.bg.whiteOff2}`,
    '&>label': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& p': {
      lineHeight: 0.9,
    },
    '& span': {
      color: `${theme.palette.bg.whiteOff2}`,
    },
    '&.error-border': {
      border: `1px solid ${theme.palette.alert.danger}`,
    },
    '& input': {
      zIndex: '-1 !important',
    },
  },
  activeUploadImage: {
    border: `2px dotted ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main}`,
    '& span': {
      color: `${theme.palette.primary.main}`,
    },
    '& input': {
      zIndex: '1 !important',
    },
  },
  vBTile: {
    minWidth: '116px',
    maxHeight: '72.36px',
    minHeight: '72.36px',
    '& div': {
      height: '100%',
    },
    '& img': {
      height: '100%',
      width: '100%',
      objetFit: 'cover',
    },
  },
  disableVBTile: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  VBUploadLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.bg.whiteOff1,
    width: '100%',
    height: '100%',
    maxHeight: '72.36px',
  },

  tagMentions: {
    '& .upgradeText': {
      marginBottom: theme.spacing(1),
    },
    '& .planText': {
      backgroundColor: theme.palette.bg.whiteOff1,
      display: 'inline-block',
      padding: '3px 15px',
      borderRadius: '40px',
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1.25),
    },
  },
  linkDisabled: {
    '& span': {
      color: '#C0C0C0 !important',
    },
  },
  holisticAgendaTab: {
    '& label': {
      '& button': {
        paddingTop: '7px',
      },
    },
  },
  mobileApp: {},
  mobileAppSidePanelList: {
    '& li': {
      '& .makeStyles-eventNameBlock-170': {
        '&:hover': {
          '& svg': {
            color: '#ED7767',
          },
        },
      },
    },
  },
}));
