/* eslint-disable  */
import React, { useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import GlobIcon from 'icons/svgGlobal';

const useStyles = makeStyles((theme) => ({
  multiLangStatusWrapper: {
    marginTop: '1rem',
    backgroundColor: theme.palette.alert.warn2,
    padding: theme.spacing(1, 2, 1, 2),
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: theme.spacing(1),
      // marginRight: '8px'
    },
    '& p': {
      whiteSpace: 'normal',
      width: '100%',
    },

    '& .textBold': {
      fontWeight: 500,
      cursor: 'pointer',
    },
  },
}));
const MultiLanguageStatus = ({ onGotItClick }) => {
  const classes = useStyles();

  return (
    <Box
      component="span"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={`${classes.multiLangStatusWrapper} `}>
      <Box display="flex" alignItems="center">
        <GlobIcon width="20px" height="20px" />
        <Typography variant="body2">
          Language change is supported only for highlighted fields.
        </Typography>
      </Box>
      <Typography variant="caption" display="block" className={`textBold `} onClick={onGotItClick}>
        Got it
      </Typography>
    </Box>
  );
};

export default MultiLanguageStatus;
