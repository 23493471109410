import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  badgeColor: {
    '& .active': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.alert.warn2,
      color: '#fff',
      height: 14,
      minWidth: 14,
      borderRadius: '50%',
      padding: 0,
      fontSize: 10,
      transition: 'all 0.2s',
      animation: `$pulsing 1.5s infinite`,
    },
  },
  '@keyframes pulsing': {
    from: {
      boxShadow: '0 0 0 0 rgba(251, 122, 117,1)',
    },
    '70%': {
      boxShadow: '0 0 0 8px rgba(251, 122, 117,0)',
    },
    to: {
      boxShadow: '0 0 0 0 rgba(251, 122, 117,0)',
    },
  },
  chartBlock: {
    paddingRight: '8px !important',

    '& .MuiCardHeader-root': {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
      '& .MuiCardHeader-title': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    '& .MuiCardContent-root': {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },

    '& img': {
      width: '100%',
      marginTop: '10px',
    },
    '& .cardBox': {
      height: '100%',
      '& .recharts-wrapper, & .recharts-wrapper svg, & .recharts-wrapper .recharts-legend-wrapper ':
        {
          width: '100% !important',
          '& text': {
            fill: '#474747',
          },
          '& .recharts-tooltip-cursor': {
            // background: `url(${tooltipCursorBg}) center center repeat`,
            // fill: 'none',
            fill: '#f7f7f7',
            // fill: 'linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);',
          },
        },
      '& .MuiCardHeader-title, & .MuiCardHeader-subheader': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& .legendBlock': {
        '& .legendTextBlock': {
          border: `1px solid ${theme.palette.border.dark}`,
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          marginLeft: `${theme.spacing(1.5)}px`,
          borderRadius: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .legendColor': {
            width: '9px',
            height: '9px',
            borderRadius: '100%',
            display: 'inline-block',
            marginRight: `${theme.spacing(0.8)}px`,
          },
          '& .legendText': {
            lineHeight: '20px',
          },
          '&:first-child': {
            marginLeft: 0,
          },
        },
      },
    },
  },
  chipLabel: {
    borderRadius: theme.shape.borderRadius,
    '&.MuiChip-root': {
      fontSize: theme.fontSize.size10,
      '&.1': { background: '#7EBFFC', color: '#fff' },
      '&.2': { background: '#FF9500', color: '#fff' },
      '&.3': { background: '#FF5E3A', color: '#fff' },
    },
  },
}));
