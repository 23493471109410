import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useStyles } from 'pages/CreateNewEvent/style';
import { CREATE_EVENT_CONSTANTS } from 'pages/CreateNewEvent/common/constants';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const EventFormatLoader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.eventFormatScreen}>
            {CREATE_EVENT_CONSTANTS.ADVANCE_PLAN ?
                <SkeletonLoaders variant="text" height="70px" width="550px" /> : CREATE_EVENT_CONSTANTS.PLANELESS_EVENT ?
                    <SkeletonLoaders variant="text" height="70px" width="550px" /> :
                    <SkeletonLoaders variant="text" height="70px" width="550px" />
            }
            <Box className={` ${classes.eventFormat}`}>
                {/*  <CreateNewEventRadioBtn /> */}
                <SkeletonLoaders variant="text" height="150px" width="334px" />
                <Box mt={-4}>
                    <SkeletonLoaders variant="text" height="150px" width="334px" />
                </Box>
                <Box mt={-4}>
                    <SkeletonLoaders variant="text" height="150px" width="334px" mt={-4} />
                </Box>
                {CREATE_EVENT_CONSTANTS.ADVANCE_PLAN &&
                    <Box className='upgradePlanCardHover'>
                        <SkeletonLoaders variant="text" height="50px" width="50px" />
                        <Box className='upgradePlanCardHoverInner'>
                            <Typography variant="body2" component="span">
                                <SkeletonLoaders variant="text" height="42px" width="250px" />
                            </Typography>
                            <Box className='upgradePlanBtn' mt={1} onClick={() => console.log('Upgrade Plan clicked')}>
                                <Typography variant="body2" component="span">
                                    <SkeletonLoaders variant="text" height="80px" width="40px" />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
            {(CREATE_EVENT_CONSTANTS.HYBRID_EVENT || CREATE_EVENT_CONSTANTS.PERSON_EVENT) &&
                <Box className='checkInFacilityLocation' mt={3} display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Typography variant="body2" component="span">
                        <SkeletonLoaders variant="text" height="20px" width="200px" />
                        <SkeletonLoaders variant="text" height="20px" width="50px" />
                    </Typography>
                    <Box ml={2} display="inline-flex">
                        <SkeletonLoaders variant="text" height="40px" width="48px" />
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default EventFormatLoader;