/* eslint-disable no-sequences */
/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import { actionTypes } from 'redux/actions/types';

const {
  GET_SESSION_ENGAGEMENT_LIST_DATA,
  INIT_ENGAGEMENT_LIST_LOADING,
  REARRANGE_SESSION_ENGAGEMENT_LIST_DATA,
  IS_ENGAGEMENT_REARRANGE_LIST_LOADING,
  UPDATE_SESSION_ENGAGEMENT_STATUS_DATA,
  IS_ENGAGEMENT_STATUS_LOADING,
  IS_ENGAGEMENT_UPDATE_LOADING,
  UPDATE_SESSION_ENGAGEMENT_DATA,
  IS_ENGAGEMENT_SAVE_LOADING,
  ADD_SESSION_ENGAGEMENT_DATA,
  IS_SESSION_ENGAGEMENT_REACTIONS_LOADING,
  UPDATE_SESSION_ENGAGEMENT_REACTIONS,
  IS_TYPE_LOADING,
  IS_TYPE_DATA,
  IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING,
  UPDATE_SESSION_ENGAGEMENT_RAISE_HAND,
} = actionTypes;

const iState = {
  isInitLoading: true,
  list: [],
  rearrangedList: [],
  isRearrangeListLoading: false,
  isEngagementStatus: false,
  isEngagementStatusLoading: false,
  isEngagementUpdateLoading: false,
  isEngagementUpdate: false,
  isEmbedAppEnabled: false,
  isEngagementSaveLoading: false,
  saveEngagementData: true,
  is_moderate_qna: 0,
  is_anonymous_qna: 0,
  is_chat_tagging: 0,
  is_stream: 0,
  stream_subtype_id: '',
  stream_type_id: '',
  isEmojiReactionsLoading: false,
  isReactionsEnabled: 0,
  isRaiseHandEnabled: 0,
  typeLoading: false,
  type: false,
};

const sessionEngagementReducer = (state = iState, action) => {
  switch (action.type) {
    case GET_SESSION_ENGAGEMENT_LIST_DATA:
      return {
        ...state,
        list: action.payload.list.sort(function (a, b) {
          return a.order_index - b.order_index;
        }),
        isEmbedAppEnabled: action.payload.isEmbedAppEnabled,
        is_moderate_qna: action.payload.is_moderate_qna,
        is_anonymous_qna: action.payload.is_anonymous_qna,
        is_chat_tagging: action.payload.is_chat_tagging,
        is_stream: action.payload.is_stream,
        stream_subtype_id: action.payload.stream_subtype_id,
        stream_type_id: action.payload.stream_type_id,
        isReactionsEnabled: action.payload.is_att_rxn,
        isRaiseHandEnabled: action.payload.is_raise_hand,
        type: action?.payload?.type,
        session_name: action?.payload?.session_name,
        isHbsInteractiveMode: action.payload?.hbs_interactive_mode,
      };
    case INIT_ENGAGEMENT_LIST_LOADING:
      return { ...state, isInitLoading: action.payload };
    case REARRANGE_SESSION_ENGAGEMENT_LIST_DATA:
      return { ...state, rearrangedList: action.payload };
    case IS_ENGAGEMENT_REARRANGE_LIST_LOADING:
      return { ...state, isRearrangeListLoading: action.payload };
    case UPDATE_SESSION_ENGAGEMENT_STATUS_DATA:
      const listDataArray = [...state.list];
      const listDataIndex = state.list.findIndex((x) => x.id === action.payload.data.id);
      listDataArray[listDataIndex].is_visible = action.payload.data.is_visible;
      return { ...state, isEngagementStatus: action.payload, list: listDataArray };
    case IS_ENGAGEMENT_STATUS_LOADING:
      return { ...state, isEngagementStatusLoading: action.payload };
    case IS_ENGAGEMENT_UPDATE_LOADING:
      return { ...state, isEngagementUpdateLoading: action.payload };
    case UPDATE_SESSION_ENGAGEMENT_DATA:
      const listDataArrayValue = [...state.list];
      const listDataIdx = state.list.findIndex((x) => x.id === action.payload.data.id);
      (listDataArrayValue[listDataIdx].id = action.payload.data.id),
        (listDataArrayValue[listDataIdx].name = action.payload.data.name),
        (listDataArrayValue[listDataIdx].icon_id = action.payload.data.icon_id),
        (listDataArrayValue[listDataIdx].embed_code = action.payload.data.embed_code),
        (listDataArrayValue[listDataIdx].svg_image = action.payload.data.svg_image);
      listDataArrayValue[listDataIdx].show_on_stage = action.payload.data.show_on_stage;
      if (action.payload.data.hasOwnProperty('is_moderate_qna')) {
        listDataArrayValue[listDataIdx].is_moderate_qna = action.payload.data.is_moderate_qna;
      }
      if (action.payload.data.hasOwnProperty('is_anonymous_qna')) {
        listDataArrayValue[listDataIdx].is_anonymous_qna = action.payload.data.is_anonymous_qna;
      }
      if (action.payload.data.hasOwnProperty('is_background_mode')) {
        listDataArrayValue[listDataIdx].is_background_mode = action.payload.data.is_background_mode;
      }
      return {
        ...state,
        isEngagementUpdate: action.payload,
        list: listDataArrayValue,
        is_moderate_qna: action.payload.data.is_moderate_qna
          ? action.payload.data.is_moderate_qna
          : iState.is_moderate_qna,
        is_anonymous_qna: action.payload.data.is_anonymous_qna
          ? action.payload.data.is_anonymous_qna
          : iState.is_anonymous_qna,
        is_chat_tagging: action.payload.data.is_chat_tagging
          ? action.payload.data.is_chat_tagging
          : iState.is_chat_tagging,
      };
    case IS_ENGAGEMENT_SAVE_LOADING:
      return { ...state, isEngagementSaveLoading: action.payload };
    case ADD_SESSION_ENGAGEMENT_DATA:
      return { ...state, saveEngagementData: action.payload };
    case IS_SESSION_ENGAGEMENT_REACTIONS_LOADING:
      return { ...state, isEmojiReactionsLoading: action.payload };
    case UPDATE_SESSION_ENGAGEMENT_REACTIONS:
      return { ...state, isReactionsEnabled: action.payload };
    case IS_SESSION_ENGAGEMENT_RAISE_HAND_LOADING:
      return { ...state, isRaiseHandLoading: action.payload };
    case UPDATE_SESSION_ENGAGEMENT_RAISE_HAND:
      return { ...state, isRaiseHandEnabled: action.payload };
    case IS_TYPE_LOADING:
      return { ...state, typeLoading: action.payload };
    case IS_TYPE_DATA:
      return { ...state, type: action.payload };
    default:
      return { ...state };
  }
};

export default sessionEngagementReducer;
