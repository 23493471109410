/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Paper,
  Collapse,
  Chip,
} from '@material-ui/core';
import SvgExhibitorIcon from '../icons/svgExhibitor';
import SvgReceptionIcon from '../icons/svgReception';
import SvgAgendaIcon from '../icons/svgAgenda';
import SvgEmbedAppsIcon from '../icons/svgEmbedAppIcon';
import SvgLoungeIcon from '../icons/svgLounge';
import { Settings, Airplay, Database, FileText, Layout } from 'react-feather';
import SvgAnalytics from '../icons/svgAnalytics';
import SvgSmallNewHubiloIcon from 'icons/svgSmallNewHubiloIcon';
import SvgMeetingsIcon from 'icons/svgMeetings';
import { removeEventData as removeEventDataRedux } from 'redux/actions/event-action';
import {
  eventDataSelector,
  commonSelector,
  userInfoSelector,
  copyStatusSelector,
  basicInfoSelector,
} from 'redux/custom-selector';
import SvgEngagementIcon from 'icons/svgEngagement';
import SvgEngagementTwoIcon from 'icons/svgEngagementTwo';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import SvgRoomsIcon from 'icons/svgRooms';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SkeletonLoader from 'custom-components/skeletonLoader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import producer from 'immer';
import isEmpty from 'lodash/isEmpty';
import { navLinks, subMenu } from './navLink';
import { PricingPlan } from 'constants/pricingPlan';
import { API, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import { GET_EVENT_COPY_STATUS, UPDATE_BEACON_STATUS } from 'api-setup/api-endpoints';
import projectSetting from 'settings';
import { setBeaconStatus } from 'redux/actions/copy-status-action';
import { getCopyModuleKey, getCopyModuleName } from 'lib/common-function';
import { COPY_EVENT_STATUS } from 'constants/common';
import { EventTypes } from './events/constants';
import { webinarNavLinks } from './webinarNavLinks';
import { EEventFormat } from './CreateNewEvent/common/constants';
import { filterOutLeadScoreFeature } from './engagement/lead-score/handlers';

const useStyles = makeStyles((theme) => ({
  sidebarBlock: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0px 3px 6px #00000040',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 64,
    borderRadius: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    "&:hover": {
      "& .infoChipUI": {
        display: "none"
      },
    },
    '&:not(.loading):hover': {
      width: 250,
      '& .activeCircle': {
        '&::before': {
          content: `""`,
          right: '10px',
        },
      },
    },
    // '&::-webkit-scrollbar': {
    // 	display: 'none',
    // },
    '&.MuiPaper-elevation1': {
      borderRadius: 0,
    },
  },
  linkDisabled: {
    '& div svg, & div span': {
      color: '#C0C0C0',
    },
  },
  actionTextStyle: {
    marginLeft: 20,
    textTransform: 'uppercase',
    '& .MuiTypography-root': {
      transition: theme.transitions.create(['color']),
      fontWeight: 500,
    },
  },
  actionIconStyle: {
    minWidth: 30,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['color']),
  },
  actionBtnStyle: {
    padding: `${theme.spacing(1.7)}px ${theme.spacing(2)}px`,
    transition: theme.transitions.create(['background-color']),
    whiteSpace: 'nowrap',
    '&.activeCircle': {
      position: 'relative',
      '&::before': {
        content: `""`,
        backgroundColor: '#FFBF3F',
        width: '8px',
        height: '8px',
        borderRadius: '10px',
        position: 'absolute',
        right: '0px',
      },
    },
    '&.loading': {
      cursor: 'not-allowed',
    },
    '&.loading:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
    '&:not(.loading):hover, &.Mui-selected': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.primary.main,
      transition: theme.transitions.create(['color']),
      borderRight: `5px solid ${theme.palette.primary.light}`,
      '& div': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiChip-root': {
      "&.infoChipUI": {
        marginTop: 8,
        marginLeft: 0,
      },
      borderRadius: '2px',
      padding: '0px',
      backgroundColor: '#FFBF3F',
      marginLeft: 10,
      textTransform: 'capitalize',
      height: 22,
      color: `${theme.palette.secondary.main} !important`,
      width: 'auto',
      minWidth: 'auto',
      fontSize: theme.fontSize.size9,
    },
  },
  subMenuActionBtnStyle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

    '& .MuiChip-root': {
      "&.infoChipUI": {
        marginTop: 8,
        marginLeft: 0,
      },
      borderRadius: '2px',
      padding: '0px',
      backgroundColor: '#FFBF3F',
      marginLeft: 10,
      textTransform: 'capitalize',
      height: 22,
      color: `${theme.palette.secondary.main} !important`,
      width: 'auto',
      minWidth: 'auto',
      fontSize: theme.fontSize.size9,
    },
  },
  subMenuActionTextStyle: {
    marginLeft: 50,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  logoHover: {
    '&:hover': {
      '& svg': {
        color: theme.palette.primary.light,
      },
      '& .logo-text': {
        color: theme.palette.primary.light,
      },
    },
  },
}));



// const navLinks = [
//   {
//     id: 0,
//     name: 'Overview',
//     icon: <SvgReceptionIcon height="25px" width="25px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/',
//   },
//   {
//     id: 2,
//     name: 'REGISTRATION',
//     icon: <FileText size={21} strokeWidth={1} />,
//     hasEventIdAndOrganiserId: true,
//     url: '/registration',
//     isSubMenuOpen: false,
//     subMenu: [
//       {
//         name: 'TICKETS',
//         hasEventIdAndOrganiserId: true,
//         url: '/registration/ticket',
//       },
//       {
//         name: 'ORDERS',
//         hasEventIdAndOrganiserId: true,
//         url: '/registration/order',
//       },
//     ],
//     isShow: true,
//   },

//   {
//     id: 16,
//     name: 'Embedded Apps',
//     icon: <SvgEmbedAppsIcon height="22px" width="22px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/embed-apps',
//     subUrl: '/embed-apps',
//   },
//   {
//     id: 3,
//     name: 'SESSIONS',
//     icon: <SvgAgendaIcon height="25px" width="25px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/sessions',
//     subUrl: '/session',
//   },
//   {
//     id: 4,
//     name: 'PEOPLE',
//     icon: <SvgMeetingsIcon height="25px" width="25px" viewBox="0 0 30 30" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/people',
//   },
//   {
//     id: 5,
//     name: 'VIRTUAL BOOTHS',
//     icon: <SvgExhibitorIcon height="25px" width="25px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/virtual-booth',
//     subUrl: '/virtual-detail',
//   },
//   {
//     id: 6,
//     name: 'MEETINGS',
//     icon: <SvgEngagementIcon height="26px" width="26px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/meetings',
//   },
//   {
//     id: 7,
//     name: 'LOUNGE',
//     icon: <SvgLoungeIcon height="23px" width="23px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/lounge',
//   },
//   {
//     id: 8,
//     name: 'ROOMS',
//     icon: <SvgRoomsIcon height="30px" width="30px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/rooms',
//   },
//   {
//     id: 9,
//     name: 'ENGAGEMENT',
//     icon: <SvgEngagementTwoIcon height="28px" width="28px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/engagement',
//     isSubMenuOpen: false,
//     subMenu: [
//       {
//         name: 'NOTIFICATIONS',
//         hasEventIdAndOrganiserId: true,
//         url: '/engagement/notification',
//       },
//       {
//         name: 'ACTIVITY FEED',
//         hasEventIdAndOrganiserId: true,
//         url: '/engagement/activity-feed',
//       },
//       {
//         name: 'LEADERBOARD',
//         hasEventIdAndOrganiserId: true,
//         url: '/engagement/leaderboard',
//       },
//       {
//         name: 'SURVEY',
//         hasEventIdAndOrganiserId: true,
//         url: '/engagement/survey',
//       },
//       {
//         name: 'CONTEST',
//         hasEventIdAndOrganiserId: true,
//         url: '/engagement/contest',
//       },
//     ],
//   },
//   {
//     id: 10,
//     name: 'BRANDING',
//     icon: <Layout size={22} strokeWidth={1} />,
//     hasEventIdAndOrganiserId: true,
//     url: '/brand-event',
//     isShow: true,
//     isSubMenuOpen: false,
//     subMenu: [
//       {
//         name: 'BRAND YOUR EVENT',
//         hasEventIdAndOrganiserId: true,
//         url: '/brand-your-events',
//       },
//       {
//         name: 'Sponsored Ads',
//         // "name": 'BRANDED SPACES',
//         hasEventIdAndOrganiserId: true,
//         url: '/brand-spaces',
//       },
//     ],
//   },
//   // {
//   // 	"id": 11,
//   // 	"name": 'EMAIL CAMPAIGN',
//   // 	"icon": <Mail size={20} strokeWidth={1} />,
//   // 	"hasEventIdAndOrganiserId": true,
//   // 	"url": '/email-campaign',
//   // },
//   {
//     id: 12,
//     name: 'ANALYTICS',
//     icon: <SvgAnalytics height="25px" width="25px" />,
//     hasEventIdAndOrganiserId: true,
//     url: '/analytics/people',
//     subUrl: '/analytics',
//   },
//   {
//     id: 13,
//     name: 'SETTINGS',
//     icon: <Settings size={20} strokeWidth={1} />,
//     hasEventIdAndOrganiserId: true,
//     // "url": '/settings/login-options',
//     url: '/settings/general',
//     keyDivider: true,
//     subUrl: '/settings',
//   },
//   {
//     id: 14,
//     name: 'RECORDING',
//     icon: <Airplay size={20} strokeWidth={1} />,
//     hasEventIdAndOrganiserId: true,
//     url: '/recording',
//   },
//   {
//     id: 15,
//     name: 'FILE LOGS',
//     icon: <Database size={20} strokeWidth={1} />,
//     hasEventIdAndOrganiserId: true,
//     url: '/file-logs',
//   },
// ];

const getUpdNavLinks = (currentNavLinks, communityVersion = 1) => {
  const updLinks = currentNavLinks.map((eachLink) => {
    const updEachLink = { ...eachLink };
    if (!isEmpty(updEachLink?.subMenu)) {
      const updSubMenu = updEachLink?.subMenu.filter((eachSubLink) => {
        return eachSubLink?.communityVersion
          ? eachSubLink?.communityVersion === communityVersion
          : eachSubLink;
      });
      updEachLink.subMenu = updSubMenu;
    }
    if (updEachLink?.communityVersion && updEachLink?.communityVersion === communityVersion) {
      return updEachLink;
    }
    return updEachLink;
  });
  return updLinks;
};
/**
 * 
 * @param {Object} currentNavLinks (navMenu)
 * @param {[
 *  {
 *    menuId: <number>, // (required)
 *    subMenuName: <string>, // (optional) don't pass in case you want to hide the entire menu
 *    isHidden: boolean // (required) based this boolean value the menu will be shown/hidden
 *  }
 * ]} menuObj 
 * @returns updated navMenu
 */
const filterOutMenuItems = (currentNavLinks, menuObj) => {
  let updatedNavMenu = currentNavLinks;
  menuObj.forEach((menu)=>{
    const { menuId, subMenuName, isHidden } = menu;
    if(!isHidden) return;
    updatedNavMenu = subMenuName ? updatedNavMenu.map((eachLink) => {
      if(menuId && eachLink.id === menuId){
        return {
          ...eachLink,
          subMenu: eachLink.subMenu ? eachLink.subMenu.filter((subMenuItem) => {
            if(subMenuName && subMenuItem.name === subMenuName){
              return false;
            }
            return true;
          }) : eachLink.subMenu
        }
      }
      return eachLink;
    }) : updatedNavMenu.filter((eachLink) => {
      if(menuId && eachLink.id === menuId){
        return false
      }
      return true;
    });
  })
  return updatedNavMenu;
};
const CUSTOM_URL = projectSetting.customBaseURL2;

export default function SidebarNav() {
  const classes = useStyles();

  const history = useHistory();

  const match = useRouteMatch();

  const dispatch = useDispatch();

  const source = useSource();

  const eventData = useSelector(eventDataSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const copyStatus = useSelector(copyStatusSelector, shallowEqual);
  const isWebinar = eventData.EventData && eventData.EventData.type === EEventFormat.WEBINAR;
  const [callBeaconStatusAPI, setCallBeaconStatusAPI] = useState(false);
  const [menu, toggleMenu] = React.useState([]);
  const [activeLink, setActiveLink] = React.useState({
    mainLink: -1,
    subLink: -1,
  });
  const [isLoadSidebar, setLoadSidebar] = React.useState(true);
  const menuActiveLink = history.location.pathname;
  const setMenuActive = menuActiveLink.split('/', 5);
  const basicEventData = useSelector(basicInfoSelector, shallowEqual);
  const isLeadScoreEnabled = Boolean(eventData.EventData.isLeadScoreCalculationEnabled);

  const toggleSubMenu = (navLink) => {
    const newMenu = producer(menu, (draftMenu) => {
      draftMenu.filter((nav) => {
        if (nav.name === navLink.name) {
          nav.isSubMenuOpen = !nav.isSubMenuOpen;
        }
        return false;
      });
      return draftMenu;
    });
    toggleMenu(newMenu);
  };

  React.useEffect(() => {
    // let oldMenu = JSON.parse(JSON.stringify(menu));
    let oldMenu = isWebinar ? webinarNavLinks : navLinks;

    if (Object.keys(eventData.EventData).length > 0) {
      if (isWebinar && !isLeadScoreEnabled) {
        oldMenu = filterOutLeadScoreFeature(webinarNavLinks);
      }
      if (eventData.EventData.hasOwnProperty('isLoungeON')) {
        if (eventData.EventData.isLoungeON === 0) {
          setLoadSidebar(false);
          oldMenu = oldMenu.filter((x) => x.name !== 'LOUNGE');
          //	toggleMenu(oldMenu)
        } else {
          setLoadSidebar(false);
        }
      }
      toggleMenu(oldMenu);
      if (typeof eventData.EventData.is_ticketing_from_new_dashboard !== 'undefined') {
        if (eventData.EventData.is_ticketing_from_new_dashboard === 0) {
          oldMenu = oldMenu.filter((x) => x.name !== 'REGISTRATION');
        }
      }
      if (typeof eventData.EventData.is_ticketing_from_new_dashboard !== 'undefined') {
        oldMenu = getUpdNavLinks(oldMenu, eventData?.EventData?.is_ticketing_from_new_dashboard);
      }
      // Hide menu and submenu based on the isHidden condition
      oldMenu = filterOutMenuItems(oldMenu, [
        {
          menuId: 9, // (required)
          subMenuName: 'QUIZ', // (optional) remove this key to hide the entire menu
          isHidden: !eventData.EventData.quiz_enabled // based on this condition the menu or sub menu will be hidden
        }
      ]);
      toggleMenu(oldMenu);
    } else {
      toggleMenu(oldMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  React.useEffect(() => {
    const hasBuilder = get(eventData?.EventData || {}, 'is_email_builder', 1);
    if (
      // userInfo.UserInfo.id &&
      // (userInfo.UserInfo.subscriptionStatus === undefined ||
      //   userInfo.UserInfo.isPlanExpired ||
      //   userInfo.UserInfo.subscriptionStatus === 'CANCELLED' ||
      //   (userInfo.UserInfo.planName !== PricingPlan.PRO &&
      //     userInfo.UserInfo.planName !== PricingPlan.ENTERPRISE))
      eventData &&
      Object.keys(eventData?.EventData).length !== 0 &&
      !hasBuilder
    ) {
      const newMenu = menu.map((item) => {
        if (item.name === 'EMAIL MANAGER') return false;
        return item;
      });
      toggleMenu(newMenu.filter(Boolean));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    if (
      !callBeaconStatusAPI &&
      eventData?.EventData?.duplication_status === COPY_EVENT_STATUS.SUCCESS
    ) {
      const [headers] = API.generateHeader(reduxData);
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        GET_EVENT_COPY_STATUS,
        source.AS,
        headers,
        { data: { eventId: eventData?.eventId } },
        projectSetting.customBaseURL2
      )
        .then((resp) => {
          const { status, success } = resp?.data;
          if (status) {
            setCallBeaconStatusAPI(true);
            const { data } = success;
            if (data)
              dispatch(setBeaconStatus({ beacon: data?.beacon, beaconModules: data?.modules }));
            else dispatch(setBeaconStatus({ beacon: true, beaconModules: [] }));
          }
        })
        .catch(API.catchHandler);
    }
    if (!eventData?.EventData?.duplication_status) {
      dispatch(setBeaconStatus({ beacon: false, beaconModules: [] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  const closeAllSubMenu = () => {
    const newMenu = producer(menu, (draftMenu) => {
      draftMenu.filter((nav) => {
        if (nav.isSubMenuOpen !== undefined) {
          nav.isSubMenuOpen = false;
        }
        return false;
      });
      return draftMenu;
    });
    toggleMenu(newMenu);
  };

  const handleRedirect = (navLink, mainLinkId, subLinkId = 0) => {
    if (isLoadSidebar) return false;
    if (navLink.url) {
      if (navLink.hasEventIdAndOrganiserId) {
        const eventId = match.params.eventId
          ? match.params.eventId
          : history.location.state.eventId
          ? history.location.state.eventId
          : '';
        const organiserId = match.params.organiserId
          ? match.params.organiserId
          : history.location.state.organiserId
          ? history.location.state.organiserId
          : '';
        if (isWebinar && navLink.name === 'SESSIONS') {
          if (eventData && eventData?.EventData && eventData?.EventData?.sessionId) {
            history.push(
              `/event/${eventId}/${organiserId}/session/${eventData?.EventData?.sessionId}/basic-details`
            );
          } else {
            history.push(`/event/${eventId}/${organiserId}`);
          }
        } else history.push(`/event/${eventId}/${organiserId}${navLink.url}`);
        setActiveLink({ mainLink: mainLinkId, subLink: subLinkId });
      } else {
        history.push(navLink.url);
      }
    }
  };
  const isBeaconShow = (moduleName) => {
    let isVisible;
    const { beaconModules, beacon } = copyStatus;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < beaconModules?.length; index++) {
      const element = beaconModules[index];
      if (getCopyModuleName(element.type) === moduleName) {
        isVisible = beacon && element.beacon;
      }
    }
    return isVisible;
  };
  const hideBeaconIcon = (moduleName) => {
    let name = getCopyModuleKey(moduleName);
    let bodyObj = {
      data: {
        eventId: eventData.eventId,
        moduleType: name,
      },
    };
    const { beaconModules } = copyStatus;
    const updatedModules = beaconModules?.filter((e) => e.type !== name);

    const [headers] = API.generateHeader(reduxData, {}, null);
    POSTAPIWITHCUSTOMDOMAIN(false, UPDATE_BEACON_STATUS, source.AS, headers, bodyObj, CUSTOM_URL)
      .then((resp) => {
        const { status, success } = resp?.data;
        if (status) {
          const { data } = success;
          if (data)
            dispatch(setBeaconStatus({ beacon: data?.beacon, beaconModules: data?.modules }));
          else dispatch(setBeaconStatus({ beacon: true, beaconModules: updatedModules }));
        }
      })
      .catch(API.catchHandler);
  };

  const inPersonClassNameResolver = (navLink, index2) => {
    if (index2 === undefined) {
      return navLink.inPersonDisabledView &&
        basicEventData?.eventType === EventTypes.IN_PERSON &&
        !(`${setMenuActive[4]}` === 'undefined'
          ? navLink.id === 0
          : navLink.url === `/${setMenuActive[4]}`
          ? navLink.url === `/${setMenuActive[4]}`
          : navLink.subUrl === `/${setMenuActive[4]}`)
        ? classes.linkDisabled
        : '';
    } else {
      return navLink.inPersonDisabledView &&
        basicEventData?.eventType === EventTypes.IN_PERSON &&
        !(activeLink.subLink === index2)
        ? classes.linkDisabled
        : '';
    }
  };

  const monoRepoRouteHandler = ({ path }) => {
    if (path) {
      const originalURL = new URL(window.location.href);
      const [, , , , , eventId, organiserId] = originalURL.href.split('/');
      const newURL = `/${organiserId}/event/${eventId}/${path}`;
      const newAbsoluteURL = new URL(newURL, originalURL.origin);
      newAbsoluteURL.hash = ''; // Remove the hash fragment
      window.open(newAbsoluteURL, '_self');
    }
  };

  return (
    <Paper
      component="aside"
      onMouseLeave={closeAllSubMenu}
      className={`${classes.sidebarBlock} ${isLoadSidebar ? 'loading' : ''}`}>
      <Box>
        <Box
          className={`${classes.logoHover} cursor-pointer`}
          width={64}
          height={60}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            if (isLoadSidebar) return;
            dispatch(removeEventDataRedux());
            history.push('/events');
          }}>
          <SvgSmallNewHubiloIcon width={50} height={50} fillColor="black" bgColor="white" />
        </Box>
      </Box>
      <List disablePadding>
        {menu.map((navLink, index) => {
          // Content hub Admin Toggle check
          // Admin Toggle Check for Content hub
          // eslint-disable-next-line no-lone-blocks
          {
            /* if (navLink.isInMonoRepo && !reduxData.isContentHubEnabled) {
            return null;
          } */
          }
          return (
            <>
              {reduxData && reduxData?.userPermission ? (
                reduxData?.userPermission?.isSuperAdmin?.toString() === '1' ||
                reduxData?.userPermission?.isEventAdmin?.toString() === '1' ? (
                  <li key={navLink.name} className={inPersonClassNameResolver(navLink)}>
                    <ListItem
                      onClick={() => {
                        if (navLink.isInMonoRepo) {
                          monoRepoRouteHandler({ path: navLink.url });
                        } else {
                          if (navLink.subMenu) {
                            if (isBeaconShow(navLink.name)) hideBeaconIcon(navLink.name);
                            toggleSubMenu(navLink);
                          } else {
                            if (isBeaconShow(navLink.name)) hideBeaconIcon(navLink.name);
                            handleRedirect(navLink, navLink.id);
                          }
                        }
                      }}
                      // selected={activeLink.mainLink === index}
                      selected={
                        `${setMenuActive[4]}` === 'undefined'
                          ? navLink.id === 0
                          : navLink.url === `/${setMenuActive[4]}`
                          ? navLink.url === `/${setMenuActive[4]}`
                          : navLink.subUrl === `/${setMenuActive[4]}`
                          ? navLink.subUrl === `/${setMenuActive[4]}`
                          : menuActiveLink.includes(navLink.url)
                      }
                      button
                      className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} 
                      ${classes.actionBtnStyle} ${isBeaconShow(navLink.name) ? 'activeCircle' : ''}
                      `}>
                      <ListItemIcon
                        disabled
                        className={classes.actionIconStyle}
                        id={isWebinar && navLink.name === 'SESSIONS' ? 'sessionsNavLink' : ''}>
                        {isLoadSidebar ? (
                          <SkeletonLoader variant="square" height="25px" width="25px" />
                        ) : (
                              <Box component="span" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {navLink.icon}
                                {navLink.isNew && <Chip component="span" className="infoChipUI" label="New" color="primary" size="small" />}
                              </Box>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isLoadSidebar ? (
                            <SkeletonLoader variant="text" width="120px" height="20px" />
                          ) : (
                            <>
                              {navLink.name}
                              {navLink.isNew && <Chip label="New" color="primary" size="small" />}
                            </>
                          )
                        }
                        primaryTypographyProps={{
                          component: 'span',
                          variant: 'caption',
                          color: 'inherit',
                        }}
                        className={classes.actionTextStyle}
                      />
                      {!navLink.subMenu || !navLink.subMenu.length ? (
                        false
                      ) : navLink.isSubMenuOpen ? (
                        <ExpandLess size="small" />
                      ) : (
                        <ExpandMore size="small" />
                      )}
                    </ListItem>
                    {navLink.keyDivider ? <Box className={classes.divider} /> : false}
                    {!navLink.subMenu || !navLink.subMenu.length ? (
                      false
                    ) : (
                      <Collapse in={navLink.isSubMenuOpen} timeout="auto" unmountOnExit>
                        <List component="ul" disablePadding>
                          {navLink.subMenu.map((nav, index2) => {
                            if (
                              nav.name === 'UTM code' &&
                              !eventData.EventData?.are_utm_params_enabled
                            )
                              return null;
                            return (
                              <>
                                {nav.name.toLowerCase() === 'sponsored ads' ? (
                                  <>
                                    {userInfo.UserInfo.planName === PricingPlan.BASIC ||
                                    userInfo.UserInfo.planName === PricingPlan.ADVANCED ||
                                    userInfo.UserInfo.planName === PricingPlan.PRO ||
                                    userInfo.UserInfo.planName === PricingPlan.ENTERPRISE ||
                                    reduxData.is_sponsored_ads_support === 1 ? (
                                      <li
                                        key={nav.name}
                                        className={inPersonClassNameResolver(nav, index2)}>
                                        <ListItem
                                          onClick={() => handleRedirect(nav, index, index2)}
                                          button
                                          className={`${
                                            isLoadSidebar ? 'loading' : 'cursor-pointer'
                                          } ${classes.actionBtnStyle} ${
                                            classes.subMenuActionBtnStyle
                                          }`}
                                          selected={activeLink.subLink === index2}>
                                          <ListItemText
                                            primary={nav.name}
                                            primaryTypographyProps={{
                                              variant: 'caption',
                                              color: 'inherit',
                                            }}
                                            className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                          />
                                        </ListItem>
                                      </li>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  <li
                                    key={nav.name}
                                    className={inPersonClassNameResolver(nav, index2)}>
                                    <ListItem
                                      onClick={() => handleRedirect(nav, index, index2)}
                                      button
                                      className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} ${
                                        classes.actionBtnStyle
                                      } ${classes.subMenuActionBtnStyle}`}
                                      selected={activeLink.subLink === index2}>
                                      <ListItemText
                                        primary={
                                          <>
                                            {nav.name}
                                            {(nav.name === 'LEAD SCORE' ||
                                              nav.name === 'UTM code') && (
                                              <Chip label="New" color="primary" size="small" />
                                            )}
                                          </>
                                        }
                                        primaryTypographyProps={{
                                          variant: 'caption',
                                          color: 'inherit',
                                        }}
                                        className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                      />
                                    </ListItem>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </List>
                      </Collapse>
                    )}
                  </li>
                ) : navLink.id.toString() === '0' ||
                  reduxData.userPermission.selectedEventSections.findIndex(
                    (x) => x === navLink.id
                  ) !== -1 ? (
                  <li key={navLink.name} className={inPersonClassNameResolver(navLink)}>
                    <ListItem

                    onClick={() => {
                        if (navLink.isInMonoRepo) {
                          monoRepoRouteHandler({ path: navLink.url });
                        } else {
                           if (navLink.subMenu) {
                          toggleSubMenu(navLink);
                        } else {
                          handleRedirect(navLink, navLink.id);
                        }
                        }
                      }}
                      // selected={activeLink.mainLink === index}
                      selected={
                        `${setMenuActive[4]}` === 'undefined'
                          ? navLink.id === 0
                          : navLink.url === `/${setMenuActive[4]}`
                          ? navLink.url === `/${setMenuActive[4]}`
                          : navLink.subUrl === `/${setMenuActive[4]}`
                      }
                      button
                      className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} ${
                        classes.actionBtnStyle
                      }`}>
                      <ListItemIcon className={classes.actionIconStyle}>
                        {isLoadSidebar ? (
                          <SkeletonLoader variant="square" height="25px" width="25px" />
                        ) : (
                              <Box component="span" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                {navLink.icon}
                                {navLink.isNew && <Chip component="span" className="infoChipUI" label="New" color="primary" size="small" />}
                              </Box>
                        )       
                        }
                     

                      </ListItemIcon>
                      <ListItemText
                        primary={
                          isLoadSidebar ? (
                            <SkeletonLoader variant="text" width="120px" height="20px" />
                          ) :  <>
                              {navLink.name}
                              {navLink.isNew && <Chip label="New" color="primary" size="small" />}
                            </>
                        }
                        primaryTypographyProps={{
                          component: 'span',
                          variant: 'caption',
                          color: 'inherit',
                        }}
                        className={classes.actionTextStyle}
                      />
                      {!navLink.subMenu || !navLink.subMenu.length ? (
                        false
                      ) : navLink.isSubMenuOpen ? (
                        <ExpandLess size="small" />
                      ) : (
                        <ExpandMore size="small" />
                      )}
                    </ListItem>
                    {navLink.keyDivider ? <Box className={classes.divider} /> : false}
                    {!navLink.subMenu || !navLink.subMenu.length ? (
                      false
                    ) : (
                      <Collapse in={navLink.isSubMenuOpen} timeout="auto" unmountOnExit>
                        <List component="ul" disablePadding>
                          {navLink.subMenu.map((nav, index2) => {
                            return (
                              <>
                                {nav.name.toLowerCase() === 'sponsored ads' ? (
                                  <>
                                    {userInfo.UserInfo.planName === PricingPlan.BASIC ||
                                    userInfo.UserInfo.planName === PricingPlan.ADVANCED ||
                                    userInfo.UserInfo.planName === PricingPlan.PRO ||
                                    userInfo.UserInfo.planName === PricingPlan.ENTERPRISE ||
                                    reduxData.is_sponsored_ads_support === 1 ? (
                                      <li
                                        key={nav.name}
                                        className={inPersonClassNameResolver(nav, index2)}>
                                        <ListItem
                                          onClick={() => handleRedirect(nav, index, index2)}
                                          button
                                          className={`${
                                            isLoadSidebar ? 'loading' : 'cursor-pointer'
                                          } ${classes.actionBtnStyle} ${
                                            classes.subMenuActionBtnStyle
                                          }`}
                                          selected={activeLink.subLink === index2}>
                                          <ListItemText
                                            primary={nav.name}
                                            primaryTypographyProps={{
                                              variant: 'caption',
                                              color: 'inherit',
                                            }}
                                            className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                          />
                                        </ListItem>
                                      </li>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  <li
                                    key={nav.name}
                                    className={inPersonClassNameResolver(nav, index2)}>
                                    <ListItem
                                      onClick={() => handleRedirect(nav, index, index2)}
                                      button
                                      className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} ${
                                        classes.actionBtnStyle
                                      } ${classes.subMenuActionBtnStyle}`}
                                      selected={activeLink.subLink === index2}>
                                      <ListItemText
                                        primary={nav.name}
                                        primaryTypographyProps={{
                                          variant: 'caption',
                                          color: 'inherit',
                                        }}
                                        className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                      />
                                    </ListItem>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </List>
                      </Collapse>
                    )}
                  </li>
                ) : null
              ) : (
                <li key={navLink.name} className={inPersonClassNameResolver(navLink)}>
                  <ListItem
                    onClick={() => {
                      if (navLink.subMenu) {
                        toggleSubMenu(navLink);
                      } else {
                        handleRedirect(navLink, navLink.id);
                      }
                    }}
                    // selected={activeLink.mainLink === index}
                    selected={
                      `${setMenuActive[4]}` === 'undefined'
                        ? navLink.id === 0
                        : navLink.url === `/${setMenuActive[4]}`
                        ? navLink.url === `/${setMenuActive[4]}`
                        : navLink.subUrl === `/${setMenuActive[4]}`
                    }
                    button
                    className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} ${
                      classes.actionBtnStyle
                    }`}>
                    <ListItemIcon className={classes.actionIconStyle}>
                      {isLoadSidebar ? (
                        <SkeletonLoader variant="square" height="25px" width="25px" />
                      ) : (
                        navLink.icon
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        isLoadSidebar ? (
                          <SkeletonLoader variant="text" width="120px" height="20px" />
                        ) : (
                          navLink.name
                        )
                      }
                      primaryTypographyProps={{
                        component: 'span',
                        variant: 'caption',
                        color: 'inherit',
                      }}
                      className={classes.actionTextStyle}
                    />
                    {!navLink.subMenu || !navLink.subMenu.length ? (
                      false
                    ) : navLink.isSubMenuOpen ? (
                      <ExpandLess size="small" />
                    ) : (
                      <ExpandMore size="small" />
                    )}
                  </ListItem>
                  {navLink.keyDivider ? <Box className={classes.divider} /> : false}
                  {!navLink.subMenu || !navLink.subMenu.length ? (
                    false
                  ) : (
                    <Collapse in={navLink.isSubMenuOpen} timeout="auto" unmountOnExit>
                      <List component="ul" disablePadding>
                        {navLink.subMenu.map((nav, index2) => {
                          return (
                            <>
                              {nav.name.toLowerCase() === 'sponsored ads' ? (
                                <>
                                  {userInfo.UserInfo.planName === PricingPlan.BASIC ||
                                  userInfo.UserInfo.planName === PricingPlan.ADVANCED ||
                                  userInfo.UserInfo.planName === PricingPlan.PRO ||
                                  userInfo.UserInfo.planName === PricingPlan.ENTERPRISE ||
                                  reduxData.is_sponsored_ads_support === 1 ? (
                                    <li
                                      key={nav.name}
                                      className={inPersonClassNameResolver(nav, index2)}>
                                      <ListItem
                                        onClick={() => handleRedirect(nav, index, index2)}
                                        button
                                        className={`${
                                          isLoadSidebar ? 'loading' : 'cursor-pointer'
                                        } ${classes.actionBtnStyle} ${
                                          classes.subMenuActionBtnStyle
                                        }`}
                                        selected={activeLink.subLink === index2}>
                                        <ListItemText
                                          primary={nav.name}
                                          primaryTypographyProps={{
                                            variant: 'caption',
                                            color: 'inherit',
                                          }}
                                          className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                        />
                                      </ListItem>
                                    </li>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                <li
                                  key={nav.name}
                                  className={inPersonClassNameResolver(nav, index2)}>
                                  <ListItem
                                    onClick={() => handleRedirect(nav, index, index2)}
                                    button
                                    className={`${isLoadSidebar ? 'loading' : 'cursor-pointer'} ${
                                      classes.actionBtnStyle
                                    } ${classes.subMenuActionBtnStyle}`}
                                    selected={activeLink.subLink === index2}>
                                    <ListItemText
                                      primary={nav.name}
                                      primaryTypographyProps={{
                                        variant: 'caption',
                                        color: 'inherit',
                                      }}
                                      className={`${classes.actionTextStyle} ${classes.subMenuActionTextStyle}`}
                                    />
                                  </ListItem>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </li>
              )}
            </>
          );
        })}
      </List>
    </Paper>
  );
}
