export const REPORT_OVERVIEW_TOOLTIP_TEXT = {
  SENT: 'It is the number of emails sent out to the audience.',
  DELIVERED:
    'It is the number of emails that are delivered to the recipients. The recipient email server might land the email in Inbox or Spam.',
  OPENED: 'It is the number of emails opened by recipients.',
  CLICKED: 'It is the number of emails that recipients clicked tracking links.',
  BOUNCED:
    'It is the number of emails that got bounced by recipients email server(recipient might unsubscribed earlier or intended recipient might not be available.',
  SPAM_REPORTED: 'It is the number of recipients who reported the email as SPAM.',
  UNIQUE_OPENED: 'It is the number of emails(unique) opened by recipients.',
  UNIQUE_CLICKS: 'It is the number of emails(unique) that recipients clicked tracking links.',
  LAST_OPENED: 'It is the recent recipient who opened this email.',
  LAST_CLICKED: 'It is the recent recipient who clicked on the tracking link in this email.',
  OPEN_RATE:
    'It is the percentage of the number of unique recipients who opened the email divided by the number of emails sent.',
  CLICK_RATE:
    'It is the percentage of the number of unique recipients who clicked the email divided by the number of emails delivered. ',
  CLICK_TO_OPEN_RATE:
    "It is the percentage of the number of recipients' unique clicks divided by the number of unique opens.",
};

export const REPORT_FILTER_STATUS_OPTIONS = {
  OPEN: 'open',
  CLICK: 'click',
  BOUNCE: 'bounce',
  PENDING: 'pending',
  DEFERRED: 'deferred',
  DELIVERED: 'delivered',
  PROCESSED: 'processed',
  DROPPED: 'dropped',
};
