import React from 'react';

const style = {
  a: { fill: 'none' },
  b: { fill: 'currentColor' },
};

function SvgMeetingsIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <rect style={style.a} width="30" height="30" />
      <path
        style={style.b}
        d="M1229.6,20403.5a3.565,3.565,0,0,1-3.6-3.5v-10a3.561,3.561,0,0,1,3.6-3.5h3.7a.5.5,0,1,1,0,1h-3.7a2.562,2.562,0,0,0-2.6,2.5v10a2.563,2.563,0,0,0,2.6,2.5h16.7a2.5,2.5,0,0,0,2.6-2.5v-10a2.562,2.562,0,0,0-2.6-2.5h-3.7a.5.5,0,1,1,0-1h3.7a3.561,3.561,0,0,1,3.6,3.5v10a3.416,3.416,0,0,1-3.5,3.5Zm6.4-4a3,3,0,1,0-6,0,.5.5,0,0,1-1,0,4,4,0,1,1,8,0,.5.5,0,0,1-1,0Zm2.5-2a.5.5,0,1,1,0-1h8a.5.5,0,1,1,0,1Zm0-2a.5.5,0,0,1,0-1h8a.5.5,0,0,1,0,1Zm-7.5-2a2,2,0,1,1,2,2A2.008,2.008,0,0,1,1231,20393.5Zm1,0a1,1,0,1,0,1-1A.943.943,0,0,0,1232,20393.5Zm6.5,0a.5.5,0,1,1,0-1h8a.5.5,0,1,1,0,1Zm-3.5-6.5a3,3,0,1,1,3,3A2.947,2.947,0,0,1,1235,20387Zm1,0a2,2,0,1,0,2-2A2,2,0,0,0,1236,20387Z"
        transform="translate(-1223 -20379)"
      />
    </svg>
  );
}

export default SvgMeetingsIcon;
