import React from 'react';

export default function SvgLinkSurvey(props) {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="187" height="134" viewBox="0 0 187 134" fill="none">
<path d="M175.146 25.1788C175.146 25.1788 172.597 23.6239 169.78 29.6849C166.963 35.7459 165.936 39.2246 165.936 39.2246C165.936 39.2246 165.176 40.499 164.292 39.7154C163.408 38.9319 162.158 36.6666 160.707 37.1301C159.213 37.6148 158.777 39.3191 159.155 41.7856C159.533 44.252 160.158 49.685 160.216 50.1758C160.274 50.6667 160.018 51.3953 159.329 50.9441C158.64 50.4929 154.615 46.3374 152.75 48.3648C150.884 50.3923 153.054 57.0905 153.292 57.8161C153.53 58.5417 153.381 58.874 153.033 59.0722C152.686 59.2704 151.466 57.7399 150.289 56.5996C149.112 55.4594 147.512 54.3496 146.536 55.6088C145.561 56.8679 145.414 61.9594 150.545 70.0326L153.167 73.618L157.609 73.2094C167.061 71.7582 170.798 68.3009 171.103 66.7369C171.408 65.1728 169.53 64.7094 167.89 64.5631C166.25 64.4167 164.292 64.499 164.213 64.1088C164.134 63.7186 164.286 63.4045 164.99 63.0905C165.695 62.7765 172.167 60.0082 172.469 57.2673C172.771 54.5264 166.981 54.246 166.198 54.0143C165.414 53.7825 165.792 53.1179 166.198 52.8405C166.603 52.563 171.109 49.4563 173.21 48.1179C175.311 46.7795 176.28 45.3374 175.67 43.8923C175.061 42.499 172.539 43.0478 171.365 42.8953C170.192 42.7429 170.655 41.3343 170.655 41.3343C170.655 41.3343 172.597 38.2855 175.304 32.1666C178.012 26.0477 175.088 25.1544 175.088 25.1544" fill="#CCCCCC"/>
<path d="M172.622 30.6028C172.622 30.6028 172.606 30.6485 172.57 30.7339C172.533 30.8192 172.478 30.9534 172.411 31.115L171.783 32.5632C171.234 33.8223 170.429 35.6394 169.426 37.8802C167.42 42.362 164.597 48.5266 161.457 55.3254C158.317 62.1242 155.469 68.2889 153.439 72.7615L151.039 78.0572L150.387 79.4962C150.311 79.6548 150.253 79.7798 150.213 79.8682C150.194 79.9109 150.172 79.9527 150.149 79.9932C150.162 79.9485 150.179 79.9047 150.198 79.8621C150.237 79.7706 150.289 79.6456 150.356 79.484C150.503 79.1456 150.716 78.6578 150.984 78.0328C151.533 76.7737 152.338 74.9596 153.341 72.7188C155.347 68.2371 158.17 62.0724 161.311 55.2706C164.451 48.4687 167.289 42.3101 169.32 37.8406C170.332 35.6119 171.149 33.804 171.719 32.5479L172.375 31.1089C172.448 30.9473 172.506 30.8253 172.548 30.7339C172.591 30.6424 172.622 30.6028 172.622 30.6028Z" fill="#E0E0E0"/>
<path d="M162.338 40.374C162.393 40.4698 162.435 40.5723 162.463 40.6789C162.536 40.871 162.631 41.1545 162.746 41.5051C162.972 42.2094 163.262 43.185 163.56 44.2704L164.314 47.0509C164.405 47.3832 164.481 47.6606 164.545 47.8923C164.582 47.9904 164.606 48.0929 164.615 48.1972C164.561 48.1015 164.519 47.999 164.49 47.8923C164.417 47.7003 164.323 47.4167 164.21 47.0631C163.99 46.3588 163.713 45.3771 163.414 44.2917L162.643 41.5173L162.408 40.6789C162.373 40.5803 162.35 40.478 162.338 40.374Z" fill="#E0E0E0"/>
<path d="M165.027 48.2611C165.027 48.2215 167.003 47.5355 169.469 46.7367C171.936 45.938 173.948 45.3069 173.96 45.3465C173.972 45.3861 171.985 46.0721 169.518 46.8709C167.052 47.6697 165.04 48.3008 165.027 48.2611Z" fill="#E0E0E0"/>
<path d="M159.731 58.4867C159.84 58.4664 159.95 58.4572 160.061 58.4593L160.951 58.4288C161.701 58.4014 162.741 58.3617 163.884 58.2733C165.027 58.1849 166.061 58.0813 166.811 57.9959L167.695 57.8953C167.803 57.875 167.914 57.8689 168.024 57.877C167.925 57.9128 167.823 57.9384 167.719 57.9532C167.512 57.9928 167.21 58.0447 166.838 58.0995C166.094 58.2124 165.058 58.3404 163.911 58.4258C162.765 58.5111 161.722 58.5355 160.966 58.5325C160.591 58.5325 160.286 58.5325 160.076 58.5172C159.96 58.5229 159.844 58.5126 159.731 58.4867Z" fill="#E0E0E0"/>
<path d="M155.731 51.8892C155.802 51.9617 155.863 52.0426 155.914 52.13C156.024 52.2886 156.183 52.5233 156.372 52.816C156.753 53.3983 157.268 54.2093 157.829 55.1087C158.39 56.0081 158.893 56.8282 159.25 57.4258C159.43 57.7306 159.57 57.9684 159.664 58.1392C159.722 58.2231 159.768 58.3143 159.802 58.4105C159.728 58.3397 159.666 58.2586 159.616 58.1697C159.506 58.0081 159.35 57.7764 159.158 57.4837C158.78 56.9014 158.265 56.0904 157.701 55.188C157.137 54.2855 156.637 53.4684 156.28 52.8709C156.103 52.566 155.975 52.3282 155.866 52.1605C155.81 52.0758 155.765 51.9847 155.731 51.8892Z" fill="#E0E0E0"/>
<path d="M149.908 60.322C149.997 60.415 150.076 60.5172 150.143 60.6269L150.731 61.505C151.219 62.2489 151.89 63.2824 152.622 64.4287C153.353 65.5751 154.009 66.6208 154.475 67.38L155.024 68.2946C155.095 68.4028 155.156 68.5171 155.207 68.6361C155.118 68.5429 155.04 68.4407 154.972 68.3312L154.387 67.4531C153.896 66.7062 153.225 65.6727 152.494 64.5263C151.762 63.38 151.109 62.3342 150.64 61.5751C150.42 61.2153 150.237 60.9104 150.094 60.6604C150.02 60.5545 149.958 60.4411 149.908 60.322Z" fill="#E0E0E0"/>
<path d="M155.137 68.3984C155.272 68.3676 155.41 68.3513 155.548 68.3496L156.676 68.2703C157.628 68.2033 158.945 68.1057 160.393 67.9655C161.841 67.8252 163.137 67.6606 164.097 67.5295L165.216 67.3801C165.353 67.3555 165.492 67.3432 165.631 67.3435C165.499 67.3886 165.363 67.4203 165.225 67.438C164.963 67.4899 164.582 67.5569 164.112 67.6331C163.167 67.7886 161.859 67.9716 160.408 68.1179C158.957 68.2642 157.637 68.3466 156.682 68.3862C156.204 68.4045 155.82 68.4167 155.551 68.4167C155.413 68.4224 155.274 68.4163 155.137 68.3984Z" fill="#E0E0E0"/>
<path d="M122.046 107.527C111.905 101.471 99.6412 99.6286 87.9408 100.326C32.9157 107.791 7.97185 31.5497 74.2066 20.9427C107.401 14.6523 119.625 39.4276 142.342 55.1077C189.522 84.8578 167.924 116.466 155.932 121.286C143.944 125.859 131.961 113.133 122.046 107.527Z" fill="#F2F2F2"/>
<path opacity="0.9" d="M122.046 107.527C111.905 101.471 99.6412 99.6286 87.9408 100.326C32.9157 107.791 7.97185 31.5497 74.2066 20.9427C107.401 14.6523 119.625 39.4276 142.342 55.1077C189.522 84.8578 167.924 116.466 155.932 121.286C143.944 125.859 131.961 113.133 122.046 107.527Z" fill="#FAFAFA"/>
<path d="M129.319 127.46L129.318 127.46C129.115 127.463 128.914 127.424 128.728 127.344C128.542 127.264 128.375 127.146 128.238 126.996C128.101 126.847 127.998 126.671 127.934 126.478C127.871 126.286 127.849 126.082 127.87 125.881L127.87 125.879L129.688 104.758C129.737 104.325 129.941 103.925 130.262 103.63C130.582 103.335 130.998 103.165 131.434 103.151C131.434 103.151 131.435 103.151 131.435 103.151L164.498 103.107L164.499 103.106C164.702 103.103 164.902 103.142 165.088 103.222C165.274 103.302 165.441 103.42 165.577 103.57C165.713 103.719 165.816 103.896 165.879 104.088C165.942 104.28 165.963 104.484 165.941 104.685L165.941 104.687L164.123 125.807C164.123 125.807 164.123 125.808 164.123 125.808C164.074 126.237 163.874 126.634 163.558 126.929C163.243 127.223 162.833 127.396 162.402 127.415L129.319 127.46Z" fill="#ED7767" stroke="#9E9E9E" strokeWidth="0.212505"/>
<path opacity="0.9" d="M164.574 104.688L162.768 125.745L129.422 125.795L131.235 104.732L164.574 104.688Z" fill="white" stroke="#9E9E9E" strokeWidth="0.212505"/>
<path d="M160.663 110.758L154.506 110.764L154.8 107.302L160.964 107.295L160.663 110.758Z" fill="#CCCCCC"/>
<path d="M160.081 117.594L153.917 117.6L154.218 114.138L160.375 114.131L160.081 117.594Z" fill="#CCCCCC"/>
<path d="M159.492 124.43L153.328 124.443L153.629 120.973L159.786 120.967L159.492 124.43Z" fill="#CCCCCC"/>
<mask id="path-16-inside-1_116_93135" fill="white">
<path d="M133.025 121.818L133.013 122.01L150.794 121.985L150.806 121.792L133.025 121.818Z"/>
</mask>
<path d="M133.025 121.818L133.013 122.01L150.794 121.985L150.806 121.792L133.025 121.818Z" fill="#ED7767"/>
<path d="M133.025 121.818L133.025 121.606L132.827 121.606L132.813 121.804L133.025 121.818ZM133.013 122.01L132.801 121.996L132.786 122.223L133.013 122.223L133.013 122.01ZM150.794 121.985L150.794 122.197L150.992 122.197L151.006 121.999L150.794 121.985ZM150.806 121.792L151.019 121.807L151.034 121.58L150.806 121.58L150.806 121.792ZM132.813 121.804L132.801 121.996L133.225 122.024L133.238 121.832L132.813 121.804ZM133.013 122.223L150.794 122.197L150.793 121.772L133.012 121.798L133.013 122.223ZM151.006 121.999L151.019 121.807L150.594 121.778L150.582 121.97L151.006 121.999ZM150.806 121.58L133.025 121.606L133.026 122.031L150.807 122.005L150.806 121.58Z" fill="#9E9E9E" mask="url(#path-16-inside-1_116_93135)"/>
<path d="M151.005 119.527L133.243 119.552L134.299 107.282L152.061 107.257L151.005 119.527Z" fill="#CCCCCC"/>
<path d="M141.947 120.369L141.895 120.944L133.231 120.957L133.282 120.382L141.947 120.369Z" fill="#ED7767" stroke="#9E9E9E" strokeWidth="0.212505"/>
<path d="M120.114 127.106L163.894 127.106L163.894 127.263C163.894 127.683 163.669 128.096 163.252 128.406C162.834 128.716 162.262 128.894 161.661 128.894L122.347 128.894C121.746 128.894 121.174 128.716 120.756 128.406C120.339 128.096 120.114 127.683 120.114 127.263L120.114 127.106Z" fill="#ED7767" stroke="#9E9E9E" strokeWidth="0.212505"/>
<path d="M164.606 104.681L164.496 105.941L131.125 105.941L131.235 104.681L164.606 104.681Z" fill="#ED7767" stroke="#9E9E9E" strokeWidth="0.212505"/>
<path d="M182.233 129H184.179" stroke="#DADADA" strokeWidth="4" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20 129H180.818" stroke="#DADADA" strokeWidth="4" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M40.6716 116.247C46.2455 109.761 45.4971 100.655 43.1786 92.8851C42.7881 91.5887 42.5733 90.1736 41.6427 89.199C40.7121 88.2243 39.041 87.8173 37.9943 88.6826C37.1336 89.3977 36.9997 90.6412 36.6964 91.7198C36.284 93.163 35.4739 94.461 34.3585 95.4654C33.6293 96.1185 32.6557 96.6563 31.7039 96.4219C30.3992 96.1026 29.8538 94.5804 29.5216 93.2772L27.6595 85.9975C27.3243 84.6952 26.9817 83.3664 26.246 82.2392C25.5102 81.1121 24.3078 80.207 22.964 80.1897C21.6202 80.1725 20.2587 81.2934 20.3265 82.6356C20.3558 83.1941 20.609 83.7153 20.715 84.265C20.8211 84.8146 20.7363 85.4746 20.2412 85.7648C19.7461 86.055 19.134 85.8239 18.6398 85.5504C16.9352 84.5925 15.5693 83.1475 14.2709 81.7058C12.9725 80.2641 11.6757 78.7491 10.0691 77.6435C8.46255 76.538 6.45394 75.8517 4.53983 76.2406C2.62573 76.6296 0.920836 78.3117 0.915862 80.2662C0.910888 82.2206 2.49119 83.7989 4.05968 84.9721C6.39202 86.7075 8.96253 88.0974 11.6918 89.0988C12.1849 89.2776 12.7147 89.4747 13.0019 89.9136C13.5181 90.7191 12.9103 91.7846 12.1174 92.3005C11.2157 92.8688 10.1152 93.0654 9.13378 93.4502C8.15235 93.8351 7.14155 94.5795 7.00085 95.6286C6.81324 97.0991 8.30345 98.196 9.65118 98.8168C12.3511 100.06 15.2434 100.833 18.2035 101.104C19.2654 101.2 20.3649 101.238 21.3215 101.705C22.2782 102.172 23.0597 103.214 22.8278 104.254C22.5959 105.293 21.4585 105.902 20.3972 105.99C19.3359 106.077 18.2837 105.766 17.2222 105.661C15.2329 105.463 12.9407 106.198 12.1762 108.051C11.6798 109.266 11.9845 110.697 12.6865 111.805C13.3884 112.913 14.4447 113.749 15.5266 114.494C19.3095 117.091 23.7358 118.817 28.3121 119.154C32.8883 119.49 36.8786 118.851 40.6553 116.245" fill="#CCCCCC"/>
<path d="M40.1878 116.543C37.9837 112.901 32.3784 104.448 29.3506 100.577L27.1066 97.7215C26.3672 96.7853 25.6369 95.8592 24.861 94.9965C23.3722 93.346 21.7933 91.7791 20.1316 90.3029C18.5495 88.8771 16.9977 87.5821 15.5525 86.3745C14.1074 85.1668 12.6983 84.0884 11.3965 83.1446C9.34098 81.6471 7.16966 80.3154 4.90305 79.1621C4.10183 78.7528 3.47082 78.4542 3.03938 78.2581L2.52236 78.0163C2.41083 77.9683 2.35107 77.947 2.35107 77.947C2.35107 77.947 2.40449 77.9796 2.51178 78.0351L2.99616 78.2733C3.41585 78.4727 4.04591 78.7905 4.84077 79.2111C7.08575 80.3916 9.23938 81.7381 11.2837 83.2395C12.5805 84.1878 13.9568 85.2849 15.4158 86.4855C16.8748 87.6861 18.4165 88.9902 19.9948 90.4139C21.6494 91.8928 23.2214 93.4616 24.7037 95.1132C25.4729 95.9746 26.2056 96.9096 26.9418 97.834C27.678 98.7585 28.4205 99.7287 29.1858 100.689C32.2002 104.558 37.8118 113 40.0215 116.628" fill="#E0E0E0"/>
<path d="M24.7937 94.9931C24.8044 94.7967 24.796 94.5998 24.7687 94.405C24.7392 94.0271 24.6813 93.4798 24.6024 92.8117C24.4458 91.469 24.1675 89.6221 23.8551 87.5854C23.5427 85.5486 23.2961 83.7024 23.1676 82.3581C23.1005 81.6868 23.053 81.1429 23.039 80.7638C23.0337 80.5679 23.0121 80.3728 22.9742 80.1805C22.951 80.375 22.9465 80.5713 22.9606 80.7667C22.9621 81.1462 22.9923 81.7075 23.0377 82.3691C23.1088 83.723 23.3423 85.5792 23.6572 87.6248C23.972 89.6704 24.2527 91.4691 24.4648 92.8407C24.5578 93.4795 24.6337 94.0122 24.6916 94.4234C24.7095 94.6158 24.7436 94.8064 24.7937 94.9931Z" fill="#E0E0E0"/>
<path d="M7.04087 96.3264C7.10194 96.3287 7.16303 96.3223 7.22232 96.3074L7.73545 96.2179C8.18235 96.1406 8.82421 96.0247 9.62061 95.9067C11.2131 95.6583 13.4288 95.3972 15.8848 95.2146C18.3409 95.032 20.5667 94.9771 22.1815 94.9788C22.9877 94.9752 23.6405 94.9892 24.0861 94.9977L24.607 95.0041C24.6675 95.01 24.7285 95.0069 24.788 94.9947C24.7301 94.9762 24.6697 94.9663 24.6089 94.9656C24.4658 94.9518 24.2849 94.939 24.0899 94.9206C23.6407 94.8878 22.9843 94.8495 22.164 94.8254C20.5481 94.786 18.3115 94.825 15.8517 95.0054C13.3919 95.1859 11.1978 95.4789 9.59675 95.7646C8.80233 95.901 8.16034 96.0391 7.72082 96.1429L7.21869 96.2831C7.15784 96.2901 7.0981 96.3047 7.04087 96.3264Z" fill="#E0E0E0"/>
<path d="M37.0477 111.624C37.0747 111.548 37.0941 111.47 37.1056 111.39L37.2352 110.72C37.3418 110.127 37.4633 109.27 37.6331 108.2C37.944 106.072 38.2803 103.116 38.6127 99.8517C38.945 96.5872 39.2529 93.6206 39.5216 91.488L39.8549 88.9639C39.8901 88.6818 39.9216 88.4546 39.9449 88.2772C39.9584 88.1971 39.9643 88.116 39.9626 88.0348C39.9325 88.1099 39.913 88.1889 39.9047 88.2694L39.7751 88.9387C39.6685 89.5319 39.5323 90.393 39.3794 91.455C39.0693 93.5864 38.73 96.5427 38.4006 99.8065C38.0712 103.07 37.7541 106.027 37.4796 108.161L37.1462 110.685L37.0592 111.371C37.045 111.455 37.0411 111.54 37.0477 111.624Z" fill="#E0E0E0"/>
<path d="M12.6675 110.389C12.748 110.407 12.83 110.415 12.9123 110.416L13.6228 110.455L16.2369 110.589C18.4428 110.701 21.4876 110.892 24.8504 111.112C28.2132 111.331 31.264 111.498 33.4726 111.575C34.5751 111.612 35.4708 111.634 36.0864 111.64L36.7987 111.64C36.8822 111.645 36.9658 111.64 37.0484 111.627C36.9666 111.61 36.8834 111.6 36.7998 111.599L36.0893 111.56L33.4768 111.431C31.2709 111.319 28.2262 111.128 24.8633 110.909C21.5005 110.689 18.4519 110.518 16.244 110.445C15.1387 110.408 14.2459 110.385 13.6274 110.381L12.9172 110.377C12.8337 110.373 12.7501 110.377 12.6675 110.389Z" fill="#E0E0E0"/>
<path d="M98.8489 58.4766H44.3701C41.1858 58.4766 38.132 59.7415 35.8804 61.9931C33.6289 64.2447 32.3639 67.2985 32.3639 70.4827V89.8974H20.6108L32.3639 101.65V114.849C32.3639 118.033 33.6289 121.087 35.8804 123.338C38.132 125.59 41.1858 126.855 44.3701 126.855H98.8489C102.033 126.855 105.087 125.59 107.339 123.338C109.59 121.087 110.855 118.033 110.855 114.849V70.4827C110.855 67.2985 109.59 64.2447 107.339 61.9931C105.087 59.7415 102.033 58.4766 98.8489 58.4766Z" fill="#ED7767"/>
<path d="M76.3349 59.4754C75.8182 60.8038 76.7053 62.25 77.7068 63.2746C78.7083 64.2992 79.9065 65.2694 80.222 66.6567C80.4827 67.8219 80.0482 69.0324 80.1534 70.2202C80.2586 71.408 80.9217 72.5279 81.2967 73.6885C81.978 75.7921 81.7037 78.0907 81.0954 80.217C80.6568 81.9839 79.8186 83.6288 78.6443 85.0272C78.0528 85.7198 77.3197 86.2801 76.4936 86.6708C75.6674 87.0616 74.7669 87.274 73.8518 87.294C73.593 87.3102 73.3336 87.2734 73.0898 87.1861C72.8459 87.0987 72.6228 86.9626 72.4341 86.7863C72.1776 86.4195 72.036 85.9858 72.0271 85.5395C70.9799 76.4109 70.9799 67.194 72.0271 58.0654" fill="#9E9E9E"/>
<path d="M75.9495 80.3119C78.6019 80.1961 81.0532 82.7357 80.8428 85.3821C83.5288 86.0376 85.416 88.9918 84.9007 91.7053C84.727 92.6016 84.3276 93.4553 84.2727 94.3638C84.2117 95.3577 84.5776 96.3242 84.7575 97.2998C85.1026 99.0779 84.9182 100.918 84.227 102.593C83.8093 103.602 83.0288 104.644 81.9373 104.675C81.5675 104.659 81.2072 104.553 80.8882 104.365C80.5692 104.178 80.3013 103.914 80.108 103.599C79.7281 102.96 79.4774 102.252 79.3702 101.516C78.8977 99.1321 78.7605 96.6839 78.1019 94.3455C77.4434 92.0071 76.1629 89.7022 74.0501 88.5162C72.8824 87.8577 71.48 87.5467 70.5501 86.5802C70.0823 86.0528 69.7705 85.4056 69.6493 84.7111C69.5282 84.0165 69.6025 83.302 69.8641 82.6473C70.1382 81.9985 70.5459 81.4147 71.0607 80.9341C71.5755 80.4534 72.1859 80.0866 72.8519 79.8577" fill="#9E9E9E"/>
<path d="M57.6277 80.7826C54.9752 80.6668 52.524 83.2064 52.7343 85.8528C50.0484 86.5083 48.1612 89.4625 48.6764 92.176C48.8502 93.0723 49.2496 93.926 49.3044 94.8345C49.3654 95.8284 48.9996 96.7949 48.8197 97.7705C48.4745 99.5486 48.659 101.389 49.3502 103.063C49.7679 104.072 50.5484 105.115 51.6398 105.146C52.0096 105.13 52.3699 105.024 52.6889 104.836C53.0079 104.648 53.2759 104.385 53.4691 104.069C53.8491 103.43 54.0998 102.723 54.2069 101.987C54.6795 99.6028 54.8167 97.1546 55.4752 94.8162C56.1337 92.4778 57.4142 90.1729 59.527 88.9869C60.6947 88.3284 62.0972 88.0174 63.0271 87.0509C63.4948 86.5235 63.8067 85.8763 63.9278 85.1818C64.049 84.4872 63.9746 83.7727 63.713 83.118C63.439 82.4692 63.0312 81.8854 62.5164 81.4048C62.0016 80.9241 61.3913 80.5573 60.7252 80.3284" fill="#9E9E9E"/>
<path d="M57.1292 62.7116C57.0961 61.4564 58.231 60.488 59.3759 60.0122C60.5207 59.5365 61.7946 59.3779 62.8137 58.6625C63.7204 58.0417 64.3259 57.0733 65.0902 56.3006C66.0341 55.3245 67.2156 54.6222 68.5136 54.2657C69.8115 53.9093 71.1792 53.9115 72.476 54.2722C73.7729 54.6328 74.9522 55.339 75.893 56.3181C76.8338 57.2972 77.5021 58.514 77.8293 59.8435C78.2694 61.6285 77.9683 63.7408 76.5885 64.9285C74.987 66.3052 72.6179 65.9981 70.5564 65.5696C68.495 65.1411 66.1788 64.7193 64.4649 65.9509C63.4325 66.6899 62.8369 67.8979 62.2049 69.0147C61.5729 70.1316 60.7821 71.2687 59.5876 71.6905C58.3931 72.1123 56.7685 71.3834 56.7222 70.0979" fill="#9E9E9E"/>
<path d="M62.0592 62.946C61.5872 62.997 61.1514 63.2233 60.8381 63.58C60.5248 63.9368 60.3568 64.3981 60.3672 64.8728L61.4983 90.4827C61.547 93.5071 65.9678 95.2052 69.2056 95.129C72.4769 95.0528 72.5806 93.4491 72.6233 90.5711C72.6904 85.7662 72.6843 83.9613 72.6873 83.9887C72.6904 84.0162 76.8184 83.2936 77.6629 77.8911C78.0806 75.2113 77.9373 70.8149 77.7026 67.2204C77.4922 63.9856 75.3398 60.0222 72.1172 60.3697L62.0592 62.946Z" fill="#E0E0E0"/>
<path d="M72.6871 83.9917C70.148 84.0355 67.6466 83.3737 65.4614 82.0801C65.4614 82.0801 67.2206 85.7813 72.6169 85.263L72.6871 83.9917Z" fill="#CCCCCC"/>
<path d="M58.2939 80.6838C59.0373 80.8341 59.8058 80.8055 60.5359 80.6003C61.2661 80.3952 61.937 80.0193 62.4933 79.5038C63.0496 78.9883 63.4753 78.348 63.7354 77.6355C63.9955 76.9231 64.0824 76.159 63.9891 75.4064C63.8214 74.0801 63.108 72.7356 63.5683 71.4795C64.1781 69.824 66.4982 69.3149 67.1141 67.6594C67.4708 66.699 67.1507 65.574 67.5562 64.6319C67.9373 63.7478 68.8702 63.2386 69.7787 62.9094C70.6873 62.5801 71.6568 62.3545 72.419 61.763C73.1812 61.1716 73.6537 60.0557 73.1629 59.2234C69.1363 59.7065 65.1816 60.6675 61.3824 62.0862C60.5013 62.4155 59.5775 62.8118 59.0561 63.5923C58.5073 64.4216 58.5348 65.4887 58.5805 66.4826C58.8061 71.324 58.0683 75.8576 58.2939 80.6991" fill="#9E9E9E"/>
<path d="M61.1719 83.1109C58.428 82.3792 56.0804 79.7054 55.1231 77.0438C54.1658 74.3822 54.1658 71.4767 54.4128 68.6597C54.5896 66.62 54.9493 64.4615 56.303 62.9249C58.3457 60.6078 60.5713 59.4249 63.6445 59.5224" fill="#9E9E9E"/>
<path d="M77.1172 89.0556L74.5715 88.4154C74.849 88.2246 75.0513 87.943 75.1437 87.6192C75.236 87.2953 75.2127 86.9494 75.0776 86.641C74.9164 86.2456 74.6498 85.902 74.3068 85.6477C73.9638 85.3934 73.5576 85.2382 73.1324 85.1989L61.5287 83.8879C61.3259 83.8609 61.1198 83.9026 60.9435 84.0065C60.7671 84.1105 60.6308 84.2705 60.5562 84.4611C60.0419 85.77 60.0247 86.8473 60.5043 87.6928H60.4495L58.2116 88.1379L54.4189 121.595L79.2605 120.434L77.1172 89.0556Z" fill="#F2F2F2"/>
<path d="M73.4647 88.5435C73.3123 88.588 73.1561 88.6186 72.9982 88.635C72.6933 88.6837 72.2573 88.7356 71.7147 88.7904C68.8746 89.0543 66.0114 88.9116 63.2116 88.3667C62.675 88.2599 62.2451 88.1624 61.9494 88.0831C61.794 88.053 61.6419 88.0081 61.4951 87.949C61.6526 87.9605 61.8087 87.986 61.9616 88.0252C62.2665 88.0831 62.6963 88.1654 63.2329 88.2569C66.0298 88.7382 68.8747 88.8794 71.7055 88.6776C72.2482 88.641 72.6872 88.6014 72.9921 88.5709C73.1487 88.5494 73.3067 88.5402 73.4647 88.5435Z" fill="#9E9E9E"/>
<path d="M90.4193 109.404L101.974 89.1077L103.367 85.5864C103.367 85.5864 106.971 77.8424 107.221 77.0498C107.511 76.1351 108.02 76.0619 108.349 76.19C108.861 76.3943 109.026 77.1565 108.8 77.9461C108.721 78.2174 107.322 82.4735 107.322 82.4735C107.698 82.1492 108.097 81.8527 108.517 81.5864C109.404 80.9766 111.029 81.0071 112.535 81.5437C114.041 82.0803 114.413 82.3638 114.233 82.9553C114.194 83.0663 114.129 83.1669 114.045 83.2498C113.962 83.3326 113.86 83.3956 113.749 83.4339C113.394 83.582 112.997 83.5907 112.636 83.4583C110.215 82.6351 109.142 83.7266 109.142 83.7266L108.962 84.187C108.962 84.187 110.791 83.6047 111.816 84.4278C111.969 84.5487 112.097 84.6977 112.194 84.8668C112.525 84.9751 112.823 85.1681 113.057 85.4267C113.291 85.6852 113.453 86.0004 113.528 86.341C113.603 86.6817 113.588 87.0359 113.483 87.3688C113.379 87.7016 113.19 88.0015 112.935 88.2388C112.823 88.3655 112.677 88.4554 112.513 88.4968C112.35 88.5382 112.178 88.529 112.02 88.4705C111.872 88.3699 111.753 88.2321 111.675 88.0711C111.626 87.9905 111.57 87.9141 111.508 87.8425L110.819 89.062C110.819 89.062 109.121 91.7144 108.511 92.9553L100.553 114.154C100.292 114.851 99.8938 115.49 99.3826 116.032C98.8714 116.575 98.2572 117.01 97.576 117.312C96.8948 117.614 96.1602 117.778 95.4151 117.793C94.67 117.808 93.9293 117.675 93.2363 117.4C92.4886 117.105 91.813 116.653 91.2552 116.074C90.6974 115.495 90.2705 114.803 90.0034 114.045C89.7363 113.286 89.6352 112.48 89.707 111.679C89.7787 110.878 90.0216 110.102 90.4193 109.404Z" fill="#CCCCCC"/>
<path d="M102.77 89.0375C103.178 89.3058 103.742 89.1229 104.108 88.8058C104.474 88.4888 104.718 88.0528 105.071 87.7083C106.099 86.6808 107.815 86.5833 108.8 85.507C108.937 85.3576 109.059 85.129 108.94 84.9644C108.821 84.7997 108.602 84.8302 108.419 84.8668L103.69 85.8028" fill="#CCCCCC"/>
<path d="M74.0288 126.245L86.1173 126.364L84.0898 117.593L84.7697 119.248C85.3964 120.777 86.4635 122.084 87.8355 123.004C89.2074 123.925 90.822 124.416 92.474 124.416C96.2972 124.416 98.9405 121.571 100.556 118.108C104.337 110.01 107.98 96.9707 107.98 96.9707L100.611 90.495C95.2758 100.587 90.2636 109.349 90.2636 109.349C90.2636 109.349 88.8154 104.949 87.6965 101.023C86.5776 97.0957 86.5868 95.0347 84.8703 93.0774C82.227 90.0652 78.4678 89.3182 74.9678 88.4158L74.8367 104.709L74.0288 126.245Z" fill="white"/>
<path d="M60.1961 114.126L82.2084 118.394L83.2785 126.852H49.5284C48.6045 126.863 47.6884 126.682 46.8386 126.319C45.9888 125.957 45.2239 125.421 44.5927 124.746C43.9616 124.071 43.4781 123.273 43.173 122.4C42.868 121.528 42.7481 120.602 42.8211 119.681C43.3058 113.516 43.8333 105.925 44.2357 102.715C44.5406 100.212 45.5467 97.6231 46.7266 95.3975C48.4308 92.1901 54.1107 88.0804 59.0894 87.8669L60.0315 108.833" fill="white"/>
<g opacity="0.4">
<path opacity="0.4" d="M74.4355 116.627L80.8241 118.108L81.6952 119.143L84.9863 121.273L84.7693 119.248C85.396 120.777 86.4632 122.084 87.8351 123.004C89.207 123.925 90.8216 124.416 92.4736 124.416C96.2968 124.416 98.9401 121.571 100.556 118.108C104.336 110.01 107.98 96.9707 107.98 96.9707L100.611 90.495C95.2754 100.587 90.2632 109.349 90.2632 109.349C90.2632 109.349 88.815 104.949 87.6961 101.023C86.5772 97.0957 86.5864 95.0347 84.8699 93.0774C82.2266 90.0652 78.4675 89.3182 74.9674 88.4158L74.8363 104.709L74.4355 116.627Z" fill="#9E9E9E"/>
<path opacity="0.4" d="M60.1961 114.126L80.0498 117.766C80.4544 117.841 80.8276 118.034 81.1223 118.321C81.417 118.609 81.6199 118.977 81.7053 119.379L83.2785 126.87H49.5284C48.6045 126.881 47.6884 126.7 46.8386 126.337C45.9888 125.975 45.2239 125.439 44.5927 124.764C43.9616 124.09 43.4781 123.291 43.173 122.418C42.868 121.546 42.7481 120.62 42.8211 119.699C43.3058 113.534 43.8333 105.943 44.2357 102.733C44.5406 100.23 45.5467 97.6411 46.7266 95.4155C48.4308 92.2082 54.1107 88.0984 59.0894 87.885L60.0315 108.852" fill="#9E9E9E"/>
</g>
<path d="M56.0045 113.498C55.9966 113.431 55.9966 113.364 56.0045 113.297C56.0045 113.148 56.0045 112.955 56.0045 112.721C56.0045 112.199 56.0045 111.48 56.0045 110.587C56.0045 108.797 55.971 106.318 55.8338 103.596C55.7636 102.23 55.6752 100.931 55.5807 99.7542C55.5365 98.7096 55.3884 97.672 55.1386 96.6567C54.9643 95.979 54.6321 95.3521 54.1691 94.8274C53.9899 94.6442 53.8005 94.4712 53.6021 94.3091C53.8348 94.4288 54.046 94.5865 54.2271 94.7755C54.7206 95.2965 55.0759 95.9327 55.2606 96.6262C55.5269 97.6496 55.6862 98.6979 55.7362 99.7542C55.8399 100.934 55.9313 102.233 56.0014 103.599C56.1417 106.343 56.1569 108.809 56.1264 110.611C56.1082 111.507 56.0838 112.233 56.0624 112.745L56.0289 113.321C56.0277 113.381 56.0195 113.44 56.0045 113.498Z" fill="#9E9E9E"/>
<path d="M81.7819 119.004C81.5497 118.747 81.2793 118.528 80.9801 118.355C80.6243 118.161 80.2467 118.01 79.8551 117.906C79.4008 117.775 78.8673 117.675 78.2789 117.556C75.9252 117.074 72.6782 116.406 69.0807 115.726C65.4831 115.047 62.2087 114.507 59.8398 114.129L57.0318 113.681L56.2696 113.553C56.1799 113.542 56.0912 113.523 56.0044 113.498C56.0944 113.498 56.1841 113.506 56.2727 113.522L57.041 113.62C57.7056 113.708 58.669 113.842 59.855 114.019C62.23 114.373 65.5075 114.897 69.1081 115.571C72.7087 116.245 75.9557 116.928 78.3002 117.434C78.8886 117.562 79.4222 117.672 79.8795 117.812C80.2754 117.924 80.6555 118.086 81.0106 118.294C81.2387 118.422 81.4446 118.587 81.6203 118.781C81.6672 118.83 81.7101 118.882 81.7484 118.937C81.7697 118.973 81.785 119.004 81.7819 119.004Z" fill="#9E9E9E"/>
<path d="M56.0714 113.498C56.0714 113.525 55.6659 113.62 55.0378 113.876C54.6712 114.027 54.3147 114.203 53.9708 114.4C53.5548 114.648 53.1584 114.927 52.7848 115.236C52.4215 115.55 52.0831 115.893 51.7726 116.26C51.5171 116.563 51.2828 116.882 51.0714 117.217C50.7116 117.794 50.547 118.184 50.5195 118.172C50.5385 118.073 50.5703 117.978 50.614 117.888C50.7179 117.638 50.8381 117.394 50.9738 117.159C51.1774 116.811 51.4067 116.479 51.6598 116.165C52.2856 115.398 53.0511 114.755 53.9159 114.272C54.2662 114.074 54.6323 113.906 55.0104 113.769C55.2647 113.674 55.5256 113.598 55.7909 113.54C55.8819 113.513 55.9763 113.499 56.0714 113.498Z" fill="#9E9E9E"/>
<path d="M84.4955 118.766C84.4745 118.698 84.4592 118.627 84.4497 118.556C84.4253 118.404 84.3918 118.202 84.3522 117.946C84.2668 117.419 84.154 116.657 84.026 115.712C83.7699 113.824 83.4589 111.209 83.1967 108.312C82.9345 105.416 82.779 102.788 82.6936 100.882C82.651 99.931 82.6266 99.1597 82.6144 98.6262C82.6144 98.3721 82.6144 98.1688 82.6144 98.0164C82.6077 97.9454 82.6077 97.874 82.6144 97.803C82.6308 97.8729 82.639 97.9445 82.6388 98.0164L82.6784 98.6262C82.7119 99.1749 82.7546 99.9402 82.8095 100.876C82.9223 102.779 83.0991 105.404 83.3583 108.297C83.6174 111.19 83.9132 113.806 84.1418 115.696C84.2546 116.629 84.3491 117.388 84.4162 117.937C84.4436 118.19 84.468 118.391 84.4863 118.547C84.4986 118.619 84.5017 118.693 84.4955 118.766Z" fill="#9E9E9E"/>
<path d="M91.7941 115.489C91.7941 115.489 91.724 115.422 91.6173 115.288C91.4695 115.093 91.3341 114.889 91.2118 114.678C90.3937 113.281 90.0164 111.67 90.1295 110.056C90.1448 109.813 90.1774 109.572 90.227 109.333C90.2379 109.244 90.2604 109.157 90.2941 109.074C90.3216 109.074 90.2636 109.452 90.2423 110.062C90.1914 111.654 90.5593 113.231 91.3094 114.635C91.596 115.163 91.8185 115.471 91.7941 115.489Z" fill="#9E9E9E"/>
<path d="M89.9649 132.538C90.0045 132.674 90.0094 132.817 89.9792 132.956C89.9489 133.094 89.8844 133.223 89.7916 133.33C89.6988 133.437 89.5807 133.519 89.448 133.569C89.3153 133.618 89.1722 133.634 89.032 133.614C88.8694 133.591 88.717 133.521 88.5935 133.413C88.4701 133.304 88.3809 133.162 88.3368 133.004L88.0777 132.047H88.1173C88.1438 132.185 88.1369 132.328 88.0974 132.463C88.0579 132.598 87.9867 132.721 87.8899 132.824C87.7931 132.926 87.6734 133.003 87.5407 133.05C87.4079 133.097 87.2659 133.112 87.1265 133.093C86.7271 133.093 86.3826 132.51 86.2667 132.202C85.9652 132.232 85.6615 132.178 85.3885 132.047C85.1155 131.915 84.8836 131.712 84.7179 131.458L82.9283 128.666L79.6143 126.242L81.6204 119.08L88.7515 123.696C89.032 123.916 89.0747 124.001 89.5198 124.419L94.1235 128.654C93.6021 128.958 93.16 128.943 92.4344 128.654C91.7088 128.364 88.532 126.214 88.532 126.214L89.9649 132.51" fill="#CCCCCC"/>
<path d="M133.386 86.063L134.396 87.4038" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M134.223 85.5138L135.889 85.3103" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M129.132 81.9137L127.467 82.1172" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M132.703 86.6581L132.046 88.2024" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M130.653 80.7694L131.31 79.2251" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M132.465 83.5514L132.685 83.8443C132.943 84.1861 133.428 84.2546 133.77 83.9974L137.38 81.2807C137.721 81.0235 137.79 80.5379 137.533 80.1961L136.601 78.9585C136.344 78.6167 135.859 78.5481 135.517 78.8054L131.907 81.5221C131.565 81.7793 131.497 82.2649 131.754 82.6066L131.974 82.8996" stroke="#ED7767" strokeWidth="0.774497"/>
<path d="M130.89 83.8193L130.619 83.4602C130.362 83.1184 129.877 83.0498 129.535 83.307L125.925 86.0238C125.583 86.281 125.515 86.7666 125.772 87.1083L126.703 88.346C126.961 88.6878 127.446 88.7563 127.788 88.4991L131.398 85.7824C131.739 85.5251 131.808 85.0396 131.551 84.6978L131.281 84.339" stroke="#ED7767" strokeWidth="0.774497"/>
<path d="M129.97 81.3645L128.96 80.0237" stroke="#ED7767" strokeWidth="0.630405" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M123.5 58.5C126.333 64.5 134 76.2 142 75C150 73.8 149.667 69.5 148.5 67.5C147.5 65.8333 144.3 64.2 141.5 67C138.7 69.8 141 78 143.5 81.5C145.333 84.3333 149 91 149 95C149 99 148.667 101.333 148.5 102" stroke="#ED7767" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" stroke-dasharray="1 1"/>
<path fillRule="evenodd" clipRule="evenodd" d="M127.846 49.1684L127.574 49.3702C124.333 45.9669 120.716 44.6615 116.917 44.3809C118.629 41.9289 117.433 37.3944 115.27 32.7125L115.486 32.5518C116.024 32.152 116.136 31.3896 115.736 30.8521L115.011 29.8775C114.611 29.3399 113.849 29.228 113.311 29.6278L99.926 39.5841C99.3934 39.9802 99.2765 40.7462 99.6763 41.2837L100.401 42.2584C100.797 42.791 101.555 42.9067 102.086 42.5193C105.947 45.9375 109.947 48.3871 112.788 47.4527C111.964 51.1718 112.174 55.0112 114.506 59.0907L114.461 59.1243C113.928 59.5204 113.815 60.2914 114.211 60.824L114.94 61.8038C115.336 62.3364 116.107 62.4497 116.64 62.0535L130.025 52.0972C130.563 51.6974 130.671 50.9301 130.275 50.3976L129.546 49.4177C129.15 48.8851 128.384 48.7682 127.846 49.168L127.846 49.1684ZM115.438 57.6172C114.933 50.8517 118.811 47.1787 125.687 49.6344C122.56 46.5836 119.077 45.1857 115.142 45.8343C117.234 42.9909 116.822 38.8173 114.22 33.4942L103.136 41.7388C107.491 45.7575 111.365 47.3558 114.69 46.1705C112.903 49.8331 113.29 53.6585 115.439 57.6172L115.438 57.6172Z" fill="#CCCCCC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M115.314 39.6758L108.621 44.6226C113.075 46.9059 116.272 45.1601 115.314 39.6758Z" fill="#ED7767"/>
<path fillRule="evenodd" clipRule="evenodd" d="M115.465 58.2098L126.621 49.9565C119.828 47.0006 114.569 50.0078 115.465 58.2098Z" fill="#ED7767"/>
</svg>
  )}
