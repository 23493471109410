import React, { useEffect, useReducer, useState } from 'react';
import { Container } from '@material-ui/core';
import { EActionType, EEventFormat, EEventScreen } from './common/constants';
import CreateNewEventLayout from './CreateNewEventLayout';
import EventFormat from './EventFormatScreen';
import EventType from './EventTypeScreen';
import EventDetails from './EventDetailsScreen';
import EventLanguages from './EventLanguagesScreen';
import {
  CreateNewEventReducer as reducer,
  createNewEventInitialState,
  editEventDateDetails,
} from './CreateNewEventReducer';
import {
  baseEventLanguagesSelector,
  commonSelector,
  createEditParentSelector,
  currentEventLanguagesSelector,
} from 'redux/custom-selector';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CustomContext from './context/index';
import { getActionFromPath } from './common/utils';
import { FETCH_EVENT_DETAILS } from 'api-setup/api-endpoints';
import { useSource } from 'lib/custom-common-hooks';
import { API, GETAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import projectSetting from 'settings';

const CreateEditEventContainer = (props) => {
  const history = useHistory();

  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);
  const { match } = props;
  // screenId, eventId, organiserId - getting details from params
  const { screenId, eventId, organiserId } = match?.params;

  // isLoading - loader for edit event details fetching
  const [isLoading, setLoading] = useState(false);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const source = useSource();
  // baseLanguageEvent contains the events base language
  const baseLanguageEvent = useSelector(baseEventLanguagesSelector, shallowEqual);

  // currentEventLanguage contains the events current language selected by organiser
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);

  const headerLang = currentEventLanguage?.id || baseLanguageEvent?.id || 34;
  const [headers] = API.generateHeader(reduxData, { language: headerLang }, null);
  const apiDispatch = useDispatch();

  // for useReducer passing the initial data based on the event type
  const [state, dispatch] = useReducer(
    reducer,
    actionType === EActionType.EDIT ? editEventDateDetails : createNewEventInitialState
  );

  // fetchEditDetails - for fetching the event details and storing them on redux
  const fetchEditDetails = () => {
    setLoading(true);
    GETAPIWITHCUSTOMDOMAIN(
      false,
      `${FETCH_EVENT_DETAILS}/${eventId}`,
      source.AS,
      headers,
      null,
      projectSetting.customBaseURL6
    )
      .then((res) => {
        setLoading(false);
        if (res.status === API.apiSuccessStatus) {
          const { eventinfo } = res.data.data;
          editEventDateDetails.format = eventinfo.type;
          editEventDateDetails.eventMetaTypeId = eventinfo.eventMetaTypeId;
          editEventDateDetails.isCheckIn = eventinfo.is_check_in;
          editEventDateDetails.base_language = eventinfo.base_language_id;
          editEventDateDetails.timezone_id = eventinfo.timezone_id;
          editEventDateDetails.name = eventinfo.name;
          editEventDateDetails.event_languages = eventinfo.event_languages;
          editEventDateDetails.country =
            eventinfo.common_phone_code_country || eventinfo.address?.country;
          editEventDateDetails.description = eventinfo.description;
          editEventDateDetails.linked_url = eventinfo.linked_url;
          editEventDateDetails.twitter_url = eventinfo.twitter_url;
          editEventDateDetails.instagram_url = eventinfo.instagram_url;
          editEventDateDetails.fb_url = eventinfo.fb_url;
          editEventDateDetails.website_url = eventinfo.website_url;
          editEventDateDetails.address = eventinfo.address;
          editEventDateDetails.customEventType = eventinfo.customEventType;
          editEventDateDetails.isEnableSlotAlert = eventinfo?.show_reminder_warning || false;

          // re-initializing the state data with api data
          dispatch({
            type: 'initialEditDetails',
            payload: editEventDateDetails,
          });

          // storing the a copy of the edit data in store
          dispatch({
            type: 'editDetails',
            payload: eventinfo,
          });
        } else {
          API.errStatusHandler(res, history, apiDispatch);
        }
      })
      .catch((error) => {
        API.httpErrorStatusHandler(error, history, apiDispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // useEffect - triggers only when action type is changed and it calls the edit details api only on edit case
  useEffect(() => {
    if (actionType === EActionType.EDIT) {
      fetchEditDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, headerLang]);

  // useEffect - triggers only when screen changes to scroll to the top
  useEffect(() => {
    // if screen is format screen and format is not virtual, scroll to the top
    // else do nothing
    if (
      !(
        screenId?.toString() === EEventScreen.EVENT_FORMAT.toString() &&
        [EEventFormat.HYBRID, EEventFormat.IN_PERSON].includes(state.format)
      )
    ) {
      const el = document.getElementsByClassName('scroll-y');
      if (el && el.length) document.getElementsByClassName('scroll-y')[0].scrollTop = 0;
    }
  }, [screenId, state.format]);

  // creating new object for context to use it in child components
  const providerState = {
    state,
    dispatch,
  };

  const { parentData } = useSelector(createEditParentSelector, shallowEqual);
  // on refresh we will be redirecting the user to listing page for that we are checking with the store data
  useEffect(() => {
    if (parentData === undefined || parentData === '') {
      if (actionType === EActionType.EDIT && organiserId && eventId)
        history.push(`/event/${eventId}/${organiserId}`);
      else history.push('/events');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentData, actionType]);

  /* renderComponent - based on screenId rendering the compoenents
   * ex : if screenId is 2 then calling the  EventType component
   */
  const renderComponent = () => {
    switch (Number(screenId)) {
      case EEventScreen.EVENT_TYPE:
        return <EventType isLoading={isLoading} />;
      case EEventScreen.EVENT_DETAILS:
        return <EventDetails isLoading={isLoading} />;
      case EEventScreen.EVENT_LANGUAGES:
        return <EventLanguages isLoading={isLoading} />;
      default:
        return <EventFormat isLoading={isLoading} />;
    }
  };
  return (
    // wrapping the custom context using provider to avoid prop drilling.
    // whatever we pass the data through the value prop can be directly accessible in child components
    <CustomContext.Provider value={providerState}>
      <Container maxWidth="lg">
        <CreateNewEventLayout actionType={actionType}>{renderComponent()}</CreateNewEventLayout>
      </Container>
    </CustomContext.Provider>
  );
};

export default CreateEditEventContainer;
