import React, { useEffect, useState } from 'react';
import Sidebar from 'custom-components/sidebar';
import SelectDropdown from 'custom-components/form-fields/selectCheckBoxDropDown';
import { Grid } from '@material-ui/core';
import { cancelFilterClicked, saveFilterClicked } from '../../utility/lead-score-amplitude';
import { filterApplyDisabledHandler } from './handlers';

export const idExtractor = (data = []) => {
  let res = [];
  if (data.length) {
    res = data.map((item) => item.id);
  }
  return res;
};

const AnalyticsFilter = (props) => {
  const {
    isOpen = false,
    onClose,
    levels = [],
    filterPayload,
    onApply,
    isFilterApplied,
    eventInfo,
  } = props;
  const [selectedLeadLevel, setSelectedLeadLevel] = useState([]);
  const [selectedOnDemandLeadLevel, setSelectedOnDemandLeadLevel] = useState([]);

  useEffect(() => {
    setSelectedLeadLevel(filterPayload.liveLevelIds);
    setSelectedOnDemandLeadLevel(filterPayload.onDemandLevelIds);
  }, [filterPayload]);

  const selectHandler = (option, data = []) => {
    let res = [];
    if (option.id) {
      if (data.length) {
        const isAlready = data.some((item) => item.id === option.id);
        if (isAlready) {
          res = data.filter((item) => item.id !== option.id);
        } else {
          res = [...data, option];
        }
      } else {
        res = [option];
      }
    }
    return res;
  };

  const deleteHandler = (idx, data = []) => {
    let res = [];
    if (data.length) {
      res = data.filter((_item, i) => i !== idx);
    }
    return res;
  };

  const applyFilterHandler = () => {
    const payload = {
      onDemandLevelIds: selectedOnDemandLeadLevel,
      liveLevelIds: selectedLeadLevel,
    };
    saveFilterClicked(payload, eventInfo);
    onClose();
    onApply(payload);
  };

  const clearFilterHandler = () => {
    const payload = {
      onDemandLevelIds: [],
      liveLevelIds: [],
    };
    setSelectedLeadLevel([]);
    setSelectedOnDemandLeadLevel([]);
    onClose();
    onApply(payload);
  };

  const isDisabled = filterApplyDisabledHandler({
    oldData: filterPayload,
    newData: { liveLevelIds: selectedLeadLevel, onDemandLevelIds: selectedOnDemandLeadLevel },
  });

  const cancelFilterHandler = () => {
    cancelFilterClicked(eventInfo);
    onClose();
  };

  return (
    <Sidebar
      open={isOpen}
      title="Filter By"
      onClose={() => cancelFilterHandler()}
      onCancel={() => cancelFilterHandler()}
      asideWidth={600}
      footerBtnLabel="Apply"
      footerRightBtnVariant="outlined"
      footerRightBtnLabel={isFilterApplied && 'Clear Filter'}
      disabled={isDisabled} // true | false
      footerBtnAction={applyFilterHandler}
      footerRightBtnAction={clearFilterHandler}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SelectDropdown
            value={selectedLeadLevel}
            isMultiSelect
            label="Live Lead Level"
            placeholder="Select Lead Level"
            labelKey="label"
            valueKey="id"
            options={levels}
            name="liveLevelIds"
            isArrayOfObject
            isUseValueKey
            startAdornment
            startAdornmentType="ICON"
            isShowAllCheckbox={false}
            onChange={(_e, _name, _val, item) => {
              setSelectedLeadLevel(selectHandler(item, selectedLeadLevel));
            }}
            onDeleteValue={(_item, index) => {
              setSelectedLeadLevel(deleteHandler(index, selectedLeadLevel));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectDropdown
            isMultiSelect
            value={selectedOnDemandLeadLevel}
            label="On-Demand Lead Level"
            placeholder="Select Lead Level"
            labelKey="label"
            valueKey="id"
            options={levels}
            name="onDemandLevelIds"
            isArrayOfObject
            isUseValueKey
            startAdornment
            startAdornmentType="ICON"
            isShowAllCheckbox={false}
            onChange={(_e, _name, _val, item) => {
              setSelectedOnDemandLeadLevel(selectHandler(item, selectedOnDemandLeadLevel));
            }}
            onDeleteValue={(_item, index) => {
              setSelectedOnDemandLeadLevel(deleteHandler(index, selectedOnDemandLeadLevel));
            }}
          />
        </Grid>
      </Grid>
    </Sidebar>
  );
};

export default AnalyticsFilter;
