import React from 'react';

export default function SvgBasicPlanIconFilled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.7982C8 17.1679 7.90873 17.5318 7.73428 17.8578C7.55983 18.1837 7.30761 18.4616 7 18.6667C6.69239 18.8717 6.44017 19.1496 6.26572 19.4755C6.09127 19.8015 6 20.1655 6 20.5352V22H18V20.5352C18 20.1655 17.9087 19.8015 17.7343 19.4755C17.5598 19.1496 17.3076 18.8717 17 18.6667C16.6924 18.4616 16.4402 18.1837 16.2657 17.8578C16.0913 17.5318 16 17.1679 16 16.7982V16H8V16.7982Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5526 16C14.685 16.3438 14.8343 16.6779 15 17H9C9.16566 16.6779 9.31496 16.3438 9.44741 16C9.94054 14.7198 10.2 13.3038 10.2 11.8624V10H13.8V11.8624C13.8 13.3038 14.0595 14.7198 14.5526 16Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11H9V9L10.8 7H13.2L15 9V11Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 4.66667C14.6667 6.13943 13.4728 7.33334 12 7.33334C10.5273 7.33334 9.33337 6.13943 9.33337 4.66667C9.33337 3.19391 10.5273 2 12 2C13.4728 2 14.6667 3.19391 14.6667 4.66667Z"
        fill="#F59C86"
      />
    </svg>
  );
}
