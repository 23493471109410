import React from 'react';
// eslint-disable-next-line no-unused-vars
const style = {
  a: {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
};
export default function SvgTwitchIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <rect width="22" height="22" fill="#6441A5" />
      <path
        d="M13.9448 16.3767H11.2256L9.41443 18.2714H7.60325V16.3767H4.28168V6.29341L5.18517 3.77069H17.5672V12.5966L13.9448 16.3767ZM16.3629 11.9669V5.03245H6.39364V14.1726H9.11288V16.0625L10.9244 14.1726H14.246L16.3629 11.9669Z"
        fill="white" />
      <path
        d="M13.0368 7.55557V11.3389H14.246V7.55557H13.0368ZM9.71521 11.3353H10.9244V7.55557H9.71521V11.3353Z"
        fill="white" />
    </svg>
  );
}
