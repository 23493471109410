export const SET_KNOWN_ERROR = "SET_KNOWN_ERROR";
export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";
export const SET_ACTIVE_WATCH_STATE = "SET_ACTIVE_WATCH_STATE";
export const SET_SESSION_STARTED = "SET_SESSION_STARTED";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const SET_SESSION_ADVANCE_DATA = "SET_SESSION_ADVANCE_DATA";
export const SET_SESSION_NEW_DATA = "SET_SESSION_NEW_DATA";


export const setConversionPromptKnownError = (knownError) => {
    return {
        type: SET_KNOWN_ERROR,
        payload: knownError
    }
}

export const setConversionPromptActiveModal = (modalData) => {
    return {
        type: SET_ACTIVE_MODAL,
        payload: modalData
    }
}

export const setConversionPromptActiveWatchState = (watchData) => {
    return {
        type: SET_ACTIVE_WATCH_STATE,
        payload: watchData
    }
}

export const setConversionPromptSessionStarted = (isStarted) => {
    return {
        type: SET_SESSION_STARTED,
        payload: isStarted
    }
}

export const setConversionPromptLoadingState = (loadingState) => {
    return {
        type: SET_LOADING_STATE,
        payload: loadingState
    }
}

export const setSessionNewData = (sessionData) => {
    return {
        type: SET_SESSION_NEW_DATA,
        data: sessionData
    }
}

export const setSessionAdvanceData = (sessionAdvanceData) => {
    return {
        type: SET_SESSION_ADVANCE_DATA,
        data: sessionAdvanceData
    }
}


