/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { SOCIAL_VERIFY } from 'api-setup/api-endpoints';
import { API, POSTAPI } from 'api-setup/api-helper';
import { removeAllFromQuery } from 'lib/url-utilities';
import { setUserCredential } from 'lib/cookies';
import PreLoader from 'custom-components/preloader';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useDispatch } from 'react-redux';
import amplitude from 'lib/amplitude';

const makeAPIcall = (data, history, setIsLoading, dispatch, callBack) => {
  POSTAPI(false, SOCIAL_VERIFY, null, null, data)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        const callBackRespo = {
          is_new_user: res.data.data.is_new_user,
          userData: res.data.data.userData,
        };

        if (!res.data.data.is_new_user || res.data.data.is_new_user === 0) {
          const userObj = { authToken: res.data.data.token };
          callBackRespo.userData = res.data.data.userData;
          setUserCredential(userObj);
        }
        setIsLoading(false);
        callBack(callBackRespo);
      } else {
        const callBackRespo = {
          is_new_user: res.data.data.is_new_user,
          userData: res.data.data.userData,
        };
        if (res.data.message)
          dispatch(setNewMessage({ message: res.data.message, type: 'error', show: true }));
        API.errStatusHandler(res, history, dispatch);
        callBack(callBackRespo);
      }
    })
    .catch(API.catchHandler);
};

const handleData = (props) => {
  let userData = props.location.search.split('=').pop();
  let body = null;
  if (userData) {
    removeAllFromQuery(props.history, props.location);
    userData = decodeURIComponent(userData);
    userData = JSON.parse(userData);

    if (userData && userData.socialMode && userData.hash) {
      body = {
        data: {
          socialMode: userData.socialMode,
          hash: userData.hash,
        },
      };
    }
  }

  return body;
};

function SocialLogin(props) {
  const [loading, setIsLoading] = useState(true); // loader

  const dispatch = useDispatch();
  React.useEffect(() => {
    const body = handleData(props);
    if (body) {
      makeAPIcall(body, props.history, setIsLoading, dispatch, (rep) => {
        if (rep.is_new_user) {
          const { userData } = rep;

          if (userData) {
            props.history.push({
              pathname: `/signup`,
              state: {
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.last_name,
              },
            });
          } else {
            props.history.push('/signup');
          }
        } else {
          // amplitude
          if (body.data && body.data.socialMode) {
            const deviceIdAmplitude = decodeURIComponent(
              window.location.search.replace(
                new RegExp(
                  `^(?:.*[&\\?]${encodeURIComponent('deviceId').replace(
                    // eslint-disable-next-line no-useless-escape
                    /[\.\+\*]/g,
                    '\\$&'
                  )}(?:\\=([^&]*))?)?.*$`,
                  'i'
                ),
                '$1'
              )
            );
            if (deviceIdAmplitude) {
              amplitude.Track.loginSuccessful(
                body.data.socialMode.toLowerCase(),
                rep.userData.email,
                rep.userData.firstName,
                rep.userData.lastName,
                rep.userData.id,
                rep.userData.organisation,
                deviceIdAmplitude
              );
            } else {
              amplitude.Track.loginSuccessful(
                body.data.socialMode.toLowerCase(),
                rep.userData.email,
                rep.userData.firstName,
                rep.userData.lastName,
                rep.userData.id,
                rep.userData.organisation
              );
            }
          }
          props.history.push('/events');
        }
      });
    } else {
      props.history.push('/login');
    }
  }, []);

  return (
    <>
      {loading && (
        <div>
          <PreLoader />
        </div>
      )}
    </>
  );
}

export default SocialLogin;
