export const leadScoreAnalyticsHeadRows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hasSort: false,
    valueKey: 'name',
    align: 'left',
  },
  {
    id: 'liveScore',
    numeric: true,
    disablePadding: false,
    label: 'Live Score',
    hasSort: true,
    align: 'center',
    valueKey: 'liveScore',
  },
  {
    id: 'liveLeadLevelId',
    disablePadding: false,
    label: 'Live Level',
    numeric: true,
    hasSort: false,
    align: 'center',
    valueKey: 'liveLeadLevelId',
  },
  {
    id: 'onDemandScore',
    disablePadding: false,
    label: 'On-Demand Score',
    hasSort: true,
    align: 'center',
    numeric: true,
    valueKey: 'onDemandScore',
  },
  {
    id: 'onDemandLevelId',
    disablePadding: false,
    numeric: true,
    label: 'On-Demand Level',
    hasSort: false,
    align: 'center',
    valueKey: 'onDemandLevelId',
  },
];
