/* eslint-disable no-sequences */
/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import { actionTypes } from 'redux/actions/types';

const {
    GET_LOUNGE_REACTION_DATA,
    IS_LOUNGE_REACTIONS_LOADING,
    UPDATE_LOUNGE_ENABLE_REACTIONS_DATA,
} = actionTypes;

const iState = {
    isEmojiLoungeReactionsLoading: false,
    isLoungeReactionsEnabled: 'NO',
};

const loungeReactionEnableReducer = (state = iState, action) => {
    switch (action.type) {
        case GET_LOUNGE_REACTION_DATA:
            return { ...state }
        case IS_LOUNGE_REACTIONS_LOADING:
            return { ...state, isInitLoading: action.data };
        case UPDATE_LOUNGE_ENABLE_REACTIONS_DATA:
            return { ...state, isLoungeReactionsEnabled: action.data };
        default:
            return state;
    }
};

export default loungeReactionEnableReducer;