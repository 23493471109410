import { genericAPICall, genericAPIErrorHandler } from 'api-setup/api-helper';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';

const useApi = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const apiHandler = async (
    {
      url = '',
      method = 'GET',
      data = {},
      params = {},
      extraHeaders = {},
      isBackendURL = false,
      baseURL = '',
    },
    cb
  ) => {
    if (method === 'GET') {
      setIsLoading(true);
    } else {
      setIsSaveLoading(true);
    }

    try {
      const res = await genericAPICall(
        url,
        method,
        { data },
        params,
        extraHeaders,
        isBackendURL, // for its true then it will take baseURL which has backend
        baseURL // there is multiple base URL | customURL , customURL2
      );
      setResponse(res.data); // in Some case this response could be utilize in component using useEffect
      if (cb) {
        cb(res.data); // If in some case they need to do handling on basis of success they can do
      }
      if (res.data.status === 200) {
        if (res.data.message) {
          dispatch(setNewMessage({ message: res.data.message, type: 'success', show: true }));
        }
      } else {
        dispatch(genericAPIErrorHandler(false, res.data.message, dispatch));
      }
    } catch (err) {
      dispatch(genericAPIErrorHandler(true, err, dispatch));
    } finally {
      if (method === 'GET') {
        setIsLoading(false);
      } else {
        setIsSaveLoading(false);
      }
    }
  };

  return { isLoading, isSaveLoading, apiHandler, response };
};

export default useApi;
