import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  grayHelpText: {
    color: theme.palette.text.third,
    fontSize: theme.fontSize.size8,
    marginTop: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
  lpBuilderControl: {
    marginTop: theme.spacing(1.5),
  },
  pricingCard: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: '8px',
    '&.build-now-step': {
      '& .planImageBg': {
        padding: '0px',
      },
    },
    '&.active': {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.lighter,
    },
    '& .iconWithBtn': {
      marginRight: theme.spacing(1),
      display: 'flex',
    },
    '& .planImageBg': {
      background: 'linear-gradient(76.99deg, #FFE9E7 6.65%, #D4E1F9 77.16%)',
      height: '200px',
      width: '100%',
      borderRadius: '7px',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      '&>img': {
        width: '100%',
        maxHeight: '100%',
        borderRadius: '5px',
      },
    },
  },
  tourBlock: {
    background: '#fff',
    width: '400px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&~div': {
      '&>span': {
        top: '120px !important',
      },
    },
    '& .stepTitle': {
      background: theme.palette.primary.main,
      padding: '2px 4px 2px 16px',
      '&>label': {
        fontWeight: 500,
        color: '#fff',
        display: 'block',
      },
      borderTopRightRadius: '7px',
      borderTopLeftRadius: '7px',
      '&>button': {
        color: '#fff',
      },
    },
    '& .stepContent': {
      padding: '12px 16px',
    },
    '& .tourBtn': {
      margin: '12px 16px 20px',
      '&>button': {
        boxShadow: 'none',
        marginRight: theme.spacing(1),
      },
    },
  },
  listingDetails: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .checkIcon': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& li': {
      padding: '0px',
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        '&>svg': {
          color: 'red',
        },
      },
      '& span': {
        color: theme.palette.text.secondary,
        fontSize: theme.fontSize.size8,
      },
    },
  },
  chooseTemplatePreview: {
    marginTop: `-${theme.spacing(4)}px`,
    paddingTop: theme.spacing(4),
    '& label': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    '& .previewDesk': {
      '&.imagePreview': {
        maxHeight: '100%',
      },
      '@media screen and (min-width: 1600px)': {
        maxHeight: '40rem',
      },
      '& iframe': {
        border: 'none',
      },
      '&>img': {
        width: '100%',
        maxHeight: '100%',
        display: 'block',
      },
    },
    '& .previewTab': {
      position: 'absolute',
      top: '5rem',
      overflowX: 'hidden',
      overflowY: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
      height: 'calc(100% - 10.5rem)',
      width: 'calc(100% - 2rem)',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: 30,
      '&>img': {
        borderRadius: '30px',
        height: 'auto',
        width: '100%',
        display: 'block',
      },
      '& iframe': {
        border: 'none',
        borderRadius: 30,
      },
    },
    '& .previewMob': {
      position: 'absolute',
      top: 16,
      overflowX: 'hidden',
      overflowY: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
      height: 'calc(100% - 41px)',
      width: 'calc(100% - 34px)',
      borderRadius: 24,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& iframe': {
        border: 'none',
        borderRadius: 25,
      },
      '&>img': {
        borderRadius: '24px',
        height: 'auto',
        width: '100%',
        display: 'block',
      },
    },
    '& .iconAction': {
      '&>button': {
        padding: theme.spacing(0.7),
        '&:hover': {
          background: 'none',
        },
        '&.active': {
          color: theme.palette.primary.light,
        },
      },
    },
    '& .primaryAction': {
      '&>button': {
        marginLeft: theme.spacing(1.5),
      },
    },
  },
  loaderBorder: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: 8,
  },
  chooseModal: {
    minHeight: 500,
    overflow: 'hidden',
    '& .actionButton': {
      '&>button': {
        '&:last-child': {
          marginLeft: theme.spacing(1.5),
        },
      },
    },
    '& .modalAction': {
      '&>button': {
        padding: '5px',
      },
      '& .active': {
        color: theme.palette.primary.main,
      },
    },
    '& .title': {
      width: 'calc(100% - 100px)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& .leftBlock': {
      background: '#F0F2F9',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      '& .deskPreview': {
        maxHeight: '23rem',
        overflowY: 'auto',
        borderRadius: '8px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&.renamePreview': {
          maxHeight: '20rem',
          overflow: 'hidden',
        },
        '&>img': {
          maxWidth: '100%',
          borderRadius: '8px',
          display: 'block',
        },
        '& .iframeSet': {
          pointerEvents: 'none',
          border: 'none',
          borderRadius: '8px',
        },
      },
      '& .previewTab': {
        position: 'absolute',
        top: 11,
        overflowX: 'hidden',
        overflowY: 'auto',
        left: '50%',
        transform: 'translateX(-50%)',
        height: 'calc(100% - 31px)',
        width: 'calc(100% - 168px)',
        borderRadius: '8px',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&>img': {
          borderRadius: '8px',
          height: 'auto',
          width: '100%',
          display: 'block',
        },
        '& .iframeSet': {
          pointerEvents: 'none',
          borderRadius: '11px',
          border: 'none',
        },
      },
      '& .previewMob': {
        position: 'absolute',
        top: 11,
        overflowX: 'hidden',
        overflowY: 'auto',
        left: '50%',
        borderRadius: '13px',
        transform: 'translateX(-50%)',
        height: 'calc(100% - 30px)',
        width: 'calc(100% - 125px)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&>img': {
          borderRadius: '13px',
          height: 'auto',
          width: '100%',
          display: 'block',
        },
        '& .iframeSet': {
          pointerEvents: 'none',
          border: 'none',
          borderRadius: '13px',
        },
      },
    },
    '& .subTitle': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .skipButton': {
      position: 'absolute',
      bottom: '20px',
      right: '30px',
      '&>button': {
        fontSize: '14px',
        color: theme.palette.primary.light,
        '&>span': {
          fontWeight: 600,
        },
        '&:hover': {
          background: 'none',
        },
      },
    },
  },
  renameModal: {
    height: '400px',
    minHeight: 'auto',
  },
  contentSubTitle: {
    marginTop: theme.spacing(3),
    fontWeight: 400,
  },
  contentSubTitle2: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
  },
  howWork: {
    '& .MuiBackdrop-root': {
      background: '#000',
    },
    '& .MuiPaper-root': {
      border: '1px solid #000',
    },
    '& .MuiCardContent-root ': {
      background: '#000',
      color: '#fff',
    },
  },
  gif: {
    background: '#fff',
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    '&>img': {
      width: '100%',
      display: 'block',
      maxHeight: '100%',
    },
  },
  chooseCard: {
    background: 'rgba(102, 122, 193, 0.1)',
    borderRadius: '8px',
    minHeight: '300px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .deskPreview': {
      maxHeight: '270px',
      overflow: 'hidden',
      borderRadius: '8px',
      '&>img': {
        width: '100%',
        maxHeight: '100%',
        display: 'block',
      },
      '& .iframeSet': {
        pointerEvents: 'none',
        borderRadius: '8px',
        border: 'none',
      },
    },
    '& .previewTab': {
      position: 'absolute',
      top: 11,
      overflow: 'hidden',
      left: '50%',
      height: 'calc(100% - 28px)',
      width: 'calc(100% - 214px)',
      transform: 'translateX(-50%)',
      borderRadius: '4px',

      '&>img': {
        borderRadius: '4px',
        height: 'auto',
        width: '100%',
        display: 'block',
      },
      '& .iframeSet': {
        pointerEvents: 'none',
        borderRadius: '8px',
        border: 'none',
      },
    },
    '& .previewMob': {
      position: 'absolute',
      top: 10,
      overflow: 'hidden',
      left: '50%',
      transform: 'translateX(-50%)',
      height: 'calc(100% - 26px)',
      width: 'calc(100% - 165px)',
      borderRadius: '8px',
      '&>img': {
        borderRadius: '8px',
        height: 'auto',
        width: '100%',
        display: 'block',
      },
      '& .iframeSet': {
        pointerEvents: 'none',
        borderRadius: '12px',
        border: 'none',
      },
    },
    '& .cardActionBlock': {
      height: '100%',
      '&:hover': {
        '& .cardAction': {
          display: 'flex',
        },
      },
    },
    '& .cardAction': {
      position: 'absolute',
      display: 'none',
      height: 'calc(100% - 24px)',
      width: 'calc(100% - 24px)',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      borderRadius: '4px',
      '&>button': {
        '&:last-child': {
          marginTop: theme.spacing(1),
          color: '#fff',
        },
      },
    },
  },
  descriptionDetails: {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: '8px',
    '&.active': {
      background: theme.palette.primary.lighter,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&.customBlock': {
      '& .descriptionImage': {
        padding: 0,
      },
    },
    '& .descriptionImage': {
      width: '100%',
      height: '180px',
      background: 'linear-gradient(76.99deg, #FFE9E7 6.65%, #D4E1F9 77.16%)',
      padding: '30px 0px 30px 30px',
      borderRadius: 4,
      overflow: 'hidden',
      '&>img': {
        height: '100%',
        display: 'block',
      },
    },
    '& .tag': {
      position: 'absolute',
      left: '10px',
      top: '10px',
      fontSize: '12px',
      background: theme.palette.alert.success,
      padding: '2px 8px',
      borderRadius: '4px',
      color: '#fff',
    },
    '& .subHeading': {
      marginTop: theme.spacing(1),
    },
    '& .btnAction': {
      marginTop: theme.spacing(1),
      '&.MuiButton-outlined': {
        background: theme.palette.primary.lighter,
      },
    },
  },
  publishModal: {
    '& .subTitle': {
      marginTop: theme.spacing(1),
    },
    '& .publishImage': {
      height: '280px',
      overflow: 'hidden',

      borderRadius: '8px',
      '&>img': {
        width: '100%',
        height: 'auto',
        display: 'block',
        borderRadius: '8px',
      },
      '&>iframe': {
        border: 'none',
        borderRadius: '8px',
      },
      '& .iframeSet': {
        pointerEvents: 'none',
        borderRadius: '8px',
      },
    },
    '& .actionButton': {
      '&>button': {
        marginRight: theme.spacing(2),
      },
    },
  },
  desktopStripe: {
    background: theme.palette.border.lighter,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',

    '& .dot': {
      height: '9px',
      width: '9px',
      borderRadius: '50%',
      background: '#FFBC00',
      '&:nth-child(n+2)': {
        marginLeft: theme.spacing(1),
      },
      '&:first-child': {
        background: ' #F45952',
      },
      '&:last-child': {
        background: '#0CD651',
      },
    },
  },
  mainPublishCard: {
    background: 'rgba(102, 122, 193, 0.1)',

    borderRadius: '10px',
    position: 'relative',
    '& .unpublishEvent': {
      marginTop: theme.spacing(1),
    },
    '& .headingTitle': {
      marginTop: theme.spacing(1),
    },
    '& .copyBlock': {
      border: `1px solid ${theme.palette.border.dark}`,
      borderRadius: '8px',
      padding: '2px 8px',
    },
    '& .copyIcon': {
      color: theme.palette.primary.light,
      padding: theme.spacing(0.7),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    '& .mainPublishImage': {
      height: 186,
      width: '100%',
      overflow: 'hidden',

      borderRadius: '8px',
      '&>iframe': {
        border: 'none',
      },
      '& .iframeSet': {
        pointerEvents: 'none',
        borderRadius: '8px',
      },
      '&>img': {
        borderRadius: '8px',
        width: '100%',
        height: 'auto',
        display: 'block',
      },
    },
  },
  recentDesign: {
    background: 'rgba(102, 122, 193, 0.1)',
    borderRadius: '8px',
    position: 'relative',
    '& .iframeSet': {
      pointerEvents: 'none',
      borderRadius: '8px',
    },
    '& .imgBlock': {
      height: 250,
      overflow: 'hidden',
      borderRadius: '8px',
      '&>iframe': {
        border: 'none',
      },
      '&:hover': {
        '& .hoverAction': {
          display: 'flex',
        },
      },
    },
    '& .hoverAction': {
      position: 'absolute',
      top: '50%',
      display: 'none',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '100%',
      width: '100%',
      borderRadius: '8px',
      background: ' linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))',
      '&>button': {
        '&:last-child': {
          color: '#fff',
          marginTop: theme.spacing(1),
        },
      },
    },
    '& img': {
      height: 'auto',
      borderRadius: '8px',
      width: '100%',
      display: 'block',
    },

    '& .tag': {
      position: 'absolute',
      left: '10px',
      top: '10px',
      fontSize: '12px',
      padding: '3px 8px',
      borderRadius: '8px',
      '&.draft': {
        border: `1px solid ${theme.palette.primary.light}`,
        background: '#FFF0EC',
        color: theme.palette.primary.light,
      },
    },
  },
  menuBtn: {
    background: '#fff',
    height: 30,
    width: 30,
    borderRadius: '50%',

    '& .roundedBtn': {
      padding: '2.5px 3px',
      minWidth: 'auto',
      '& svg': {
        color: theme.palette.text.secondary,
      },
    },
  },
  eventActionMenu: {
    '& .MuiPaper-root': {
      width: '150px',
      marginTop: theme.spacing(1),
      '&>ul': {
        '&>li': {
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  chooseCardContent: {
    '& .actionButtonIcon': {
      '& .MuiIconButton-root': {
        padding: '5px',
      },
    },
    '& .active': {
      color: theme.palette.primary.light,
    },
  },
  fontNormal: {
    fontWeight: 500,
  },
  addIcon: {
    color: theme.palette.primary.light,
    '& .MuiIconButton-label': {
      '&>span': {
        marginLeft: theme.spacing(0.8),
      },
    },
    '&:hover': {
      background: 'none',
    },
  },
  tag: {
    position: 'absolute',
    left: '10px',
    top: '10px',
    fontSize: '12px',
    background: theme.palette.alert.success,
    padding: '2px 8px',
    borderRadius: '4px',
    color: '#fff',
  },
  breadCrumbsBlock: {
    '& li': {
      display: 'flex',
    },
    '& a': {
      color: theme.palette.primary.light,
      fontWeight: 500,
      fontSize: '12px',
    },
  },
  warnBlock: {
    padding: theme.spacing(1),
    '&>p': {
      marginLeft: theme.spacing(0.7),
    },
    background: '#FFF7ED',
    borderRadius: '5px',
    '& .medium': {
      fontWeight: 500,
    },
  },
}));
