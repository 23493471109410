import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    divider: {
        /*   padding: `0 ${theme.spacing(3.75)}px 0`,
          '@media screen and (max-width: 960px)': {
              padding: `0 ${theme.spacing(2.5)}px`,
          }, */

        '& .MuiDivider-root': {
            backgroundColor: theme.palette.bg.whiteOff2,
        },
    },
    reactionEnabled: {
        padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
        '@media screen and (max-width: 960px)': {
            padding: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
        },
    },
    switchBtnStyle: {
        marginRight: '0 !important',
        '& label': {
            marginRight: '12px !important',
            marginLeft: '1px',
            '&>span': {
                margin: 0,
            },
        },
    },
    bold: {
        fontWeight: 'bold'
    }
}));