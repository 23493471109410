import React, { useState } from 'react';

import { Box, makeStyles, Grid, Container, Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { EActionType, EEventFormat, EEventScreen } from '../common/constants';
import { useHistory, useParams } from 'react-router';
import { getActionFromPath } from '../common/utils';
import BtnWithLoader from 'custom-components/btnWithLoader';
import PropTypes from 'prop-types';
import EventAddonsModal from './EventAddonsModal';
import { shallowEqual, useSelector } from 'react-redux';
import { commonSelector, userInfoSelector } from 'redux/custom-selector';
import { PricingPlan } from 'constants/pricingPlan';

const useStyles = makeStyles((theme) => ({
  createEventFooter: {
    position: 'fixed',
    bottom: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
    width: '100%',
    left: 0,
    background: theme.palette.bg.white,
    zIndex: 8,
  },
  prevBtn: {
    '& svg': {
      fontSize: '12px',
    },
  },
  nextBtn: {
    right: 0,
    '& svg': {
      transform: 'rotate(180deg)',
      fontSize: '12px',
    },
  },
  footerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '516px',
    // justifyContent: 'center'
  },
  addoneBtn: {
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:hover': {
      background: theme.palette.border.lighter,
    },
  },
}));

const CreateEventFooter = ({
  onNext,
  disabledNext = false,
  isLoading = false,
  onPrevious,
  onNextFinish,
  format = '',
  hidePrevious = false,
}) => {
  const classes = useStyles();

  // params - contains the route param such as screen ID, eventID, organiser id
  const params = useParams();

  // history - contains the route history
  const history = useHistory();

  // screen - represents the current screen (ex: format, type, details, language)
  const screen = Number(params.screenId);

  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  const [isEventAddOnsOpen, setEventAddOnsOpen] = useState(false);

  const organiserInfo = useSelector(commonSelector, shallowEqual);
  // userInfo - the data of logged in user
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const planName = userInfo && userInfo?.UserInfo?.planName;

  // onPreviousClick - triggers when organiser clicks on previous button
  const onPreviousClick = (event) => {
    event.preventDefault();
    if (onPrevious) onPrevious();
    if (actionType === EActionType.CREATE) history.push(`/events/create/${screen - 1}`);
    else history.push(`/events/${params?.eventId}/${params?.organiserId}/edit/${screen - 1}`);
  };

  // onNextFinishClick - triggers when organiser clicks on next button
  const onNextFinishClick = (event, selectedAddons = []) => {
    event.preventDefault();
    if (onNextFinish) onNextFinish();
    if (screen !== EEventScreen.EVENT_LANGUAGES) {
      if (onNext) onNext(selectedAddons);
    } else if (onNext) onNext(selectedAddons);
  };

  const onNextFinishWithCallback = (event) => {
    event.preventDefault();
    setEventAddOnsOpen(true);
  };

  return (
    <Box className={classes.createEventFooter}>
      <Container maxWidth="lg">
        <Grid item xs={8} className={classes.footerContainer}>
          {screen !== EEventScreen.EVENT_FORMAT && !hidePrevious && (
            <Button
              className={classes.prevBtn}
              variant="outlined"
              color="primary"
              size="medium"
              onClick={onPreviousClick}>
              <ArrowBackIos /> Previous
            </Button>
          )}
          <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
            {((screen === EEventScreen.EVENT_LANGUAGES && organiserInfo?.subscription_name)) &&
              !(
                (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
                actionType === EActionType.CREATE
              ) && (
                <Button
                  variant="text"
                  color="primary"
                  className={classes.addoneBtn}
                  size="medium"
                  onClick={() => {
                    setEventAddOnsOpen(true);
                  }}>
                  Event add-ons
                </Button>
              )}

            {screen !== EEventScreen.EVENT_LANGUAGES ? (
              <Button
                className={classes.nextBtn}
                variant="contained"
                color="primary"
                size="medium"
                disabled={disabledNext}
                onClick={onNextFinishClick}>
                <>
                  Next <ArrowBackIos />
                </>
              </Button>
            ) : (
              <BtnWithLoader
                className={classes.nextBtn}
                variant="contained"
                color="primary"
                size="medium"
                disabled={disabledNext || isLoading}
                onClick={
                  (planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE) &&
                  actionType === EActionType.CREATE
                    ? onNextFinishWithCallback
                    : onNextFinishClick
                }
                isLoading={isLoading}
                label="Finish"
                loadingLabel="Saving"
              />
            )}
          </Box>
        </Grid>
      </Container>
      {((format === EEventFormat.WEBINAR && screen === EEventScreen.EVENT_DETAILS) ||
        screen === EEventScreen.EVENT_LANGUAGES) &&
      isEventAddOnsOpen ? (
        <EventAddonsModal
          actionType={actionType}
          isOpen={isEventAddOnsOpen}
          onClose={() => setEventAddOnsOpen(false)}
          eventType={format}
          submitCallback={actionType === EActionType.CREATE ? onNextFinishClick : null}
        />
      ) : null}
    </Box>
  );
};

CreateEventFooter.propTypes = {
  disabledNext: PropTypes.bool,
  isLoading: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onNextFinish: PropTypes.func,
};

export default CreateEventFooter;
