import React from 'react';

const styles = {
	st0: { fill: '#E5E5E4' },
	st1: { fill: '#FFFFFF' },
	st2: { fill: '#4A8CFF' },
	st3: { clipPath: 'url(#svgPath1)', fill: '#FFFFFF' }
}

export default function SvgZoom(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="-512 -221 500 500" xmlSpace="preserve" {...props}>
			<path style={styles.st0} d="M-263.3-212c133.7,0,242,108.3,242,242s-108.3,242-242,242s-242-108.3-242-242S-396.9-212-263.3-212z" />
			<path style={styles.st1} d="M-263.3-207.2c131,0,237.2,106.2,237.2,237.2s-106.2,237.2-237.2,237.2S-500.5,161-500.5,30 S-394.3-207.2-263.3-207.2z" />
			<path style={styles.st2} d="M-263.3-188.2c120.5,0,218.2,97.7,218.2,218.2s-97.7,218.2-218.2,218.2S-481.5,150.5-481.5,30 S-383.8-188.2-263.3-188.2z" />
			<g>
				<defs>
					<path id="svgShape1" d="M-194.5,1.7l61.7-45.1c5.4-4.4,9.5-3.4,9.5,4.7V98.8c0,9.1-5.1,8.1-9.5,4.7l-61.7-45.1V1.7z M-403.3-38.3V64.1c0.1,23.2,19,41.9,42.2,41.8h149.5c4.2,0,7.6-3.4,7.7-7.6V-4.2c-0.1-23.2-19-41.9-42.2-41.8h-149.5 C-399.8-45.9-403.2-42.6-403.3-38.3z" />
				</defs>
				<clipPath id="svgPath1">
					<use xlinkHref="#svgShape1" style={{ overflow: 'visible' }} />
				</clipPath>
				<path style={styles.st3} d="M-415.1-57.8h303.7v175.5h-303.7V-57.8z" />
			</g>
		</svg>
	)
}
