import { makeStyles } from '@material-ui/core';
import { colors } from 'constants/colors';

export const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: 500,
  },
  pricingPlanName: {
    textTransform: 'capitalize',
    marginBottom: '0px !important',
    color: theme.palette.primary.main,
  },
  currentPlan: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff1}`,
  },
  planDropdown: {
    '& .dropDownBody': {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 6rem)',
    },
    '& .choosePlan': {
      '& .square': {
        width: 60,
        height: 60,
        backgroundColor: theme.palette.alert.alertWarnBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius,
      },
      '& .circle': {
        width: 50,
        height: 50,
        backgroundColor: theme.palette.alert.alertWarnBg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 44,
      },
      '& .currentPlan': {
        borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
        '& .MuiTypography-h6': {
          color: theme.palette.primary.main,
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 700,
          marginBottom: 0,
        },
      },
      '& .expiredPlan': {
        textAlign: 'center',

        '& .MuiTypography-h6': {
          color: theme.palette.primary.light,
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 700,
          margin: 'auto',
          width: '90%',
        },
        '& h5': {
          color: `${theme.palette.text.primary} !important`,
          textAlign: 'left',
        },
      },

      '& .leftDataPlan': {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        '& .MuiTypography-h6': {
          color: theme.palette.secondary.main,
          lineHeight: theme.spacing[6],
          fontSize: theme.fontSize.size8,
          fontWeight: 700,
          //	marginBottom: 10,
          '& span': {
            color: theme.palette.secondary.light,
          },
        },
        '& .MuiTypography-caption': {
          marginTop: 10,
          '& span': {
            color: theme.palette.secondary.light,
          },
        },
      },
      '& .attendeeCreditsProgress': {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        marginBottom: theme.spacing(1),
      },
      "& .attendeeCreditsProgressBorder": {
        borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
      },
      '& .upgradeActionPlan': {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.bg.whiteOff1}`,
      },
      '& .expired-btn': {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.alert.alertWarnBg,
        color: colors.burntSienna,
        fontSize: theme.fontSize.size9,
        fontWeight: 500,
      },
    },
  },
  successPopup: {
    minWidth: '540px',
    '& .successWarning': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.alert.successLight,
      '& .MuiTypography-h5': {
        fontSize: theme.fontSize.size2,
        fontWeight: 700,
        color: theme.palette.alert.success,
      },
    },
    '& .orderDetails': {
      marginTop: 15,
      '& .MuiTypography-h6': {
        marginBottom: 20,
        fontSize: theme.fontSize.size2,
        fontWeight: 700,
      },
      '& .MuiTypography-subtitle2': {
        marginBottom: 10,
      },
      '& .borderleft': {
        borderLeft: `1px solid ${theme.palette.bg.whiteOff2}`,
      },
    },
  },
  userBlock: {
    background: theme.palette.alert.alertWarnBg,
    height: 50,
    width: 50,
    borderRadius: '50%',
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.3),
  },
  themeColor: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  attendeeCreditsDropDown: {
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    marginTop: theme.spacing(1),
    paddingTop: 0,
    '& .unReadBlock': {
      background: theme.palette.primary.lighter,
      "& .tickIcon": {
        cursor: 'pointer',
      }
    },

    '&>.MuiListItem-root': {
      borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
      "&:last-child": {
        borderBottom: 'none'
      },
      '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontWeight: 500,
          fontSize: '14px',
        },
      },
      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
      },
    },
  },
  alertCTA: {
    '&>label': {
      fontWeight: 500,
    },
    '& .markAllButton': {
      padding: 0,
      textTransform: 'inherit',
      fontSize: '12px',
      fontWeight: 400,
      background: 'transparent',
    },
  },
}));
