/* eslint-disable camelcase */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';
import { PlanDetailMenu } from './planDetailMenu';
import { PlanDetailsExpiredMenu } from './planDetailsExpiredMenu';
import { PlanCancelScheduledMenu } from './planCancelScheduledMenu';
import { PlanPaymentDeclinedMenu } from './planPaymentDeclinedMenu';
import { PlanCancelledMenu } from './planCancelledMenu';
import { PlanPaymentDueMenu } from './planPaymentDueMenu';
import { NonPaymentPlanCancelledMenu } from './nonPaymentPlanCancelledMenu';
import { apiStatus } from 'constants/api';
import moment from 'moment';
import { PricingPlan } from 'constants/pricingPlan';

const PlanDetailsMenu = (props) => {
  const { isPlanExpired, isPlanMenuOpen, setPlanMenuOption, planName } = props;
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const {
    subscriptionStatus = '',
    isPaymentFailed = null,
    is_payment_due_or_auto_collection_off: { auto_collection = '', amount_due = 0 },
  } = UserInfo;
  return (
    <>
      {isPlanExpired ? (
        <PlanDetailsExpiredMenu
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
          isPlanExpired={isPlanExpired}
        />
      ) : subscriptionStatus === apiStatus.CANCEL_SCHEDULED &&
        // Check if Current Day lies between subscribtion End Date -15 days and  subscribtion End Date
        moment(new Date()).isBetween(
          new Date(
            parseInt(UserInfo.subscription_end_date_milli) - 15 * 24 * 60 * 60 * 1000
          ).getTime(), // Subscribtion End Date -15 days
          parseInt(UserInfo.subscription_end_date_milli) // Subscribtion End Date
        ) ? (
        <PlanCancelScheduledMenu
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
          isPlanExpired={isPlanExpired}
        />
      ) : subscriptionStatus === apiStatus.ACTIVE && isPaymentFailed === 1 ? (
        <PlanPaymentDeclinedMenu
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
          isPlanExpired={isPlanExpired}
        />
      ) : subscriptionStatus === apiStatus.CANCELLED && isPaymentFailed === 0 ? (
        <PlanCancelledMenu isPlanMenuOpen={isPlanMenuOpen} setPlanMenuOption={setPlanMenuOption} />
      ) : subscriptionStatus === apiStatus.CANCELLED && isPaymentFailed === 1 ? (
        <NonPaymentPlanCancelledMenu
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
        />
      ) : (
        <PlanDetailMenu
          isPlanMenuOpen={isPlanMenuOpen}
          setPlanMenuOption={setPlanMenuOption}
          subscriptionStatus={planName}
        />
      )}
      {!isPlanExpired &&
      UserInfo.is_payment_due_or_auto_collection_off !== undefined &&
      auto_collection === 'off' &&
      amount_due > 0 &&
      !(
        UserInfo.planName === PricingPlan.WEBINAR ||
        UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
        UserInfo.planName === PricingPlan.ALL_IN
      ) ? (
        <PlanPaymentDueMenu isPlanMenuOpen={isPlanMenuOpen} />
      ) : (
        false
      )}
    </>
  );
};

export default PlanDetailsMenu;
