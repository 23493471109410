/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Box, IconButton, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useStyles } from './style';
import SvgDelete2 from 'icons/svgDelete2';
import SvgEditIcon from 'icons/svgEdit';
import SvgDraggableIcon from 'icons/svgDraggableIcon';
import ConfirmationPopUp from 'custom-components/popUp';
import { Trash2 } from 'react-feather';
import { Container, Draggable } from 'react-smooth-dnd';
import SkeletonLoader from 'custom-components/skeletonLoader';
import { useApiHook, API_CALL_METHODS, customBaseURL2 } from 'api-setup/use-api-hook';
import { DELETE_QUIZ_QUESTIONS } from 'api-setup/api-endpoints';
import { useRouteMatch } from 'react-router';
import { useCustomQuizContext } from '../context';
import { useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';

function QuizQuestion({
  name,
  fieldType,
  points,
  options,
  onEditClick,
  isMan = false,
  getQuizQuestions,
  id,
  ans = [],
  isMandatory = false,
  questionsLength = 0,
}) {
  const classes = useStyles();
  const [isConfirmModalOpen, toggleDeleteModal] = React.useState(false);
  const [isDeleting, setDeleteLoading] = useState(false);
  const [apiPromise] = useApiHook();
  const match = useRouteMatch();
  const { state } = useCustomQuizContext();
  const dispatch = useDispatch();

  const handleDeleteQuestion = () => {
    setDeleteLoading(true);
    const body = {
      data: {
        quizId: match?.params?.quizId,
        questionId: id,
      },
    };
    apiPromise(DELETE_QUIZ_QUESTIONS, API_CALL_METHODS.DELETE, {}, body, customBaseURL2)
      .then((res) => {
        setDeleteLoading(false);
        toggleDeleteModal(false);
        dispatch(
          setNewMessage({
            message: res?.data?.message,
            type: 'success',
            show: true,
          })
        );
        getQuizQuestions();
      })
      .catch(() => {
        toggleDeleteModal(false);
        setDeleteLoading(false);
      });
  };

  return (
    <>
      <Box mb={3.75} className={classes.questionsAnswerDragRow}>
        {/* <Box ml={3.5} mt={0.5}>
          <Box display="flex" alignItems="top" justifyContent="space-between">
            <Box>
              <SkeletonLoader width="200px" variant="text" height="22px" />
              <Box display="flex" alignItems="top" justifyContent="flex-start">
                <SkeletonLoader
                  width="60px"
                  variant="text"
                  height="22px"
                  skeletonProps={{ marginRight: '10px' }}
                />
                <SkeletonLoader width="50px" variant="text" height="22px" />
              </Box>
            </Box>
            <Box mt={1} display="flex" alignItems="top" justifyContent="space-between">
              <SkeletonLoader
                width="25px"
                variant="round"
                height="25px"
                skeletonProps={{ borderRadius: '100% !important', marginRight: '18px' }}
              />
              <SkeletonLoader
                width="25px"
                variant="round"
                height="25px"
                skeletonProps={{ borderRadius: '100% !important' }}
              />
            </Box>
          </Box>
          <Box mt={0.5}>
            <SkeletonLoader width="100%" variant="text" height="25px" />
          </Box>
          <Box mt={0.5}>
            <SkeletonLoader width="100%" variant="text" height="25px" />
          </Box>
          <Box mt={0.5}>
            <SkeletonLoader width="100%" variant="text" height="25px" />
          </Box>
        </Box> */}
        <Box className={classes.dragIconBox}>
          <SvgDraggableIcon />
        </Box>
        <Box ml={3.5} display="flex" alignItems="top" justifyContent="space-between">
          <Box position="relative">
            <Typography variant="caption" component="h2" className={classes.questionText}>
              {name}
              {isMan || isMandatory ? <span className="questionLabelAsterisk">*</span> : null}
            </Typography>
            <Box display="flex">
              <Box>
                {' '}
                <Typography variant="caption" component="span" color="textSecondary">
                  Type
                </Typography>
                <Typography variant="caption" component="span" className={classes.questionType}>
                  {typeof fieldType === 'number'
                    ? fieldType === 1
                      ? 'Checkbox'
                      : 'Radio'
                    : fieldType}
                </Typography>
              </Box>
              <Box ml={1.8}>
                {' '}
                <Typography variant="caption" component="span" color="textSecondary">
                  Score
                </Typography>
                <Typography variant="caption" component="span" className={classes.questionType}>
                  {points}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" position="relative" height="35px" ml={1}>
            <IconButton
              color="primary"
              className={classes.fileActionIconButton}
              onClick={onEditClick}>
              <SvgEditIcon width="18px" />
            </IconButton>
            {questionsLength <= 1 ? null : (
              <IconButton
                color="primary"
                className={classes.fileActionIconButton}
                onClick={() => {
                  toggleDeleteModal(true);
                }}>
                <SvgDelete2 width="18px" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box
          ml={3.6}
          className={
            typeof fieldType === 'number'
              ? fieldType === 1
                ? classes.optionsRowAreaCheck
                : classes.optionsRowAreaRadio
              : fieldType === 'Checkbox'
              ? classes.optionsRowAreaCheck
              : classes.optionsRowAreaRadio
          }>
          {options.map((opt) => {
            return (
              <Box className={opt.isSelected || ans.includes(opt?.id) ? classes.rightAnswer : ''}>
                {opt.value}
              </Box>
            );
          })}
        </Box>
      </Box>

      <ConfirmationPopUp
        isOpen={isConfirmModalOpen}
        onClose={() => toggleDeleteModal(false)}
        data={{
          title: 'Delete question?',
          primaryDesc: <Box width={409}>This will delete quiz question</Box>,
          Icon: Trash2,
          actionBtn: [
            {
              name: 'CANCEL',
              disabled: isDeleting,
              onClick: () => {
                toggleDeleteModal(false);
              },
            },
            {
              name: 'Delete',
              type: 'primary',
              onClick: handleDeleteQuestion,
              isLoading: isDeleting,
              disabled: isDeleting,
              loadingLabel: 'Deleting..',
            },
          ],
        }}
      />
    </>
  );
}

export default QuizQuestion;
