import React from 'react';
import { Box, Typography } from '@material-ui/core';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import BrokenLink from 'icons/svgBrokenLink';
import { Link } from 'react-router-dom';

export default function RequestNewLink() {
  return (
    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
      <Box maxWidth={350}>
        <Box mb={1.5} display="flex" justifyContent="center">
          <HubiloLogo height="70px" width="140px" />
        </Box>
        <Box className="text-center">
          <Typography variant="body2" component="span">
            This link appears to be broken
          </Typography>
          <Box mt={10} mb={5}>
            <BrokenLink />
          </Box>
          <Typography variant="caption" component="p" color="textSecondary">
            It&apos;s also possible that you have already created your account or it&apos;s been
            more than 24 hours since we sent the link to you. You can request your admin to send you
            a new link.
          </Typography>
        </Box>
        <Box mt={5} textAlign="center" color="text.grayColor">
          <Typography variant="caption" component="p" color="textPrimary">
            Already created your account?
            <Link to="/" className="text-uppercase cursor-pointer">
              <Box component="span" color="primary.main" fontWeight={600}>
                {' '}
                SIGN IN
              </Box>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
