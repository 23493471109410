import React from 'react';
import SvgExhibitorIcon from '../icons/svgExhibitor';
import SvgReceptionIcon from '../icons/svgReception';
import SvgAgendaIcon from '../icons/svgAgenda';
import SvgLoungeIcon from '../icons/svgLounge';
import { Settings, Airplay, Database, FileText, Layout } from 'react-feather';
import SvgAnalytics from '../icons/svgAnalytics';
import SvgMeetingsIcon from 'icons/svgMeetings';
import SvgEngagementIcon from 'icons/svgEngagement';
import SvgEngagementTwoIcon from 'icons/svgEngagementTwo';
import SvgRoomsIcon from 'icons/svgRooms';
import SvgMailIcon from 'icons/svgMailIcon';
import SvgIntegrations from 'icons/svgIntegrations';

export const navLinks = [
  {
    id: 0,
    name: 'Overview',
    icon: <SvgReceptionIcon height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/',
  },
  {
    id: 2,
    name: 'REGISTRATION',
    icon: <FileText size={21} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/registration',
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'TICKETS',
        hasEventIdAndOrganiserId: true,
        url: '/registration/ticket',
      },
      {
        name: 'DISCOUNTS',
        hasEventIdAndOrganiserId: true,
        url: '/registration/discount',
        communityVersion: 2,
      },
      {
        name: 'ORDERS',
        hasEventIdAndOrganiserId: true,
        url: '/registration/order',
      },
      {
        name: 'LANDING PAGE',
        hasEventIdAndOrganiserId: true,
        url: '/registration/landing-page',
        communityVersion: 2,
      },
      {
        name: 'UTM code',
        hasEventIdAndOrganiserId: true,
        url: '/registration/utm',
        communityVersion: 2,
      },
    ],
    isShow: true,
  },

  {
    id: 3,
    name: 'SESSIONS',
    icon: <SvgAgendaIcon height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/sessions',
    subUrl: '/session',
  },
  {
    id: 4,
    name: 'PEOPLE',
    icon: <SvgMeetingsIcon height="25px" width="25px" viewBox="0 0 30 30" />,
    hasEventIdAndOrganiserId: true,
    url: '/people',
  },
  {
    id: 5,
    name: 'VIRTUAL BOOTHS',
    icon: <SvgExhibitorIcon height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/virtual-booth',
    subUrl: '/virtual-detail',
  },
  {
    id: 6,
    name: 'MEETINGS',
    icon: <SvgEngagementIcon height="26px" width="26px" />,
    hasEventIdAndOrganiserId: true,
    url: '/meetings',
  },
  {
    id: 7,
    name: 'LOUNGE',
    icon: <SvgLoungeIcon height="23px" width="23px" />,
    hasEventIdAndOrganiserId: true,
    url: '/lounge',
    inPersonDisabledView: true,
  },
  {
    id: 8,
    name: 'ROOMS',
    icon: <SvgRoomsIcon height="30px" width="30px" />,
    hasEventIdAndOrganiserId: true,
    url: '/rooms',
    inPersonDisabledView: true,
  },
  {
    id: 17,
    name: 'EMAIL MANAGER',
    icon: <SvgMailIcon height="20px" width="20px" />,
    hasEventIdAndOrganiserId: true,
    url: '/email-builder',
  },
  {
    id: 9,
    name: 'ENGAGEMENT',
    icon: <SvgEngagementTwoIcon height="28px" width="28px" />,
    hasEventIdAndOrganiserId: true,
    url: '/engagement',
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'NOTIFICATIONS',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/notification',
      },
      {
        name: 'ACTIVITY FEED',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/activity-feed',
      },
      {
        name: 'LEADERBOARD',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/leaderboard',
        inPersonDisabledView: true,
      },
      {
        name: 'SURVEY',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/survey',
      },
      {
        name: 'CONTEST',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/contest',
      },
      {
        name: 'QUIZ',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/quiz',
      },
    ],
  },
  /* {
    id: 16,
    name: 'Embedded Apps',
    icon: <SvgEmbedAppsIcon height="22px" width="22px" />,
    hasEventIdAndOrganiserId: true,
    url: '/embed-apps',
    subUrl: '/embed-apps',
  }, */
  {
    id: 10,
    name: 'BRANDING',
    icon: <Layout size={22} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/brand-event',
    isShow: true,
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'BRAND YOUR EVENT',
        hasEventIdAndOrganiserId: true,
        url: '/brand-your-events',
      },
      {
        name: 'Sponsored Ads',
        // "name": 'BRANDED SPACES',
        hasEventIdAndOrganiserId: true,
        url: '/brand-spaces',
      },
    ],
  },
  // {
  // 	"id": 11,
  // 	"name": 'EMAIL CAMPAIGN',
  // 	"icon": <Mail size={20} strokeWidth={1} />,
  // 	"hasEventIdAndOrganiserId": true,
  // 	"url": '/email-campaign',
  // },
  {
    id: 12,
    name: 'ANALYTICS',
    icon: <SvgAnalytics height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/analytics/people',
    subUrl: '/analytics',
  },
  {
    id: 13,
    name: 'Integrations',
    icon: <SvgIntegrations />,
    hasEventIdAndOrganiserId: true,
    url: '/integrations',
    isShow: true,
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'Connected Apps',
        hasEventIdAndOrganiserId: true,
        url: '/integrations/connected-apps',
      },
      {
        name: 'Embedded apps',
        // "name": 'BRANDED SPACES',
        hasEventIdAndOrganiserId: true,
        url: '/integrations/embed-apps',
      },
    ],
  },
  {
    id: 14,
    name: 'SETTINGS',
    icon: <Settings size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    // "url": '/settings/login-options',
    url: '/settings/general',
    keyDivider: true,
    subUrl: '/settings',
  },
  {
    id: 15,
    name: 'RECORDING',
    icon: <Airplay size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/recording',
    inPersonDisabledView: true,
  },
  {
    id: 16,
    name: 'FILE LOGS',
    icon: <Database size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/file-logs',
  }
];

export const subMenu = [
  {
    name: 'Connected Apps',
    hasEventIdAndOrganiserId: true,
    url: '/integrations/connected-apps',
  },
  {
    name: 'Embedded apps',
    hasEventIdAndOrganiserId: true,
    url: '/integrations/embed-apps',
  },
];
