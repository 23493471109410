import posthog from 'posthog-js';

const usePosthogClient = () => {
    const identifyOrgData = (email, orgData = {}) => {
        if (process.env.REACT_APP_ENV_TYPE === 'prod') {
            posthog.identify(
                email, // Required. Replace 'distinct_id' with your user's unique identifier
                orgData
            );
        }
    }

    return {
        identifyOrgData
    }
}

export const initPosthog = () => {
    if(process.env.REACT_APP_ENV_TYPE === 'prod'){
    posthog.init(
        process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
        {
            api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            persistence: 'localStorage'
        }
    );
    }
}

export default usePosthogClient;