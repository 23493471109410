import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Smartphone, Tablet, Monitor } from 'react-feather';
import SvgMobMockup from 'icons/svgMobMockup';
import { useStyles } from './style';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { TEMPLATE_PREVIEW, DEF_TEMPLATE_PREVIEW } from './constants';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';
import SvgTabLandScapeMode from 'icons/svgTabLandScapeMode';

const GetChosenPreview = ({ chosenPreview, previewTemplateData }) => {
  const classes = useStyles();
  const {
    desktopThumbnailUrl = '',
    mobileThumbnailUrl = '',
    tabletThumbnailUrl = '',
  } = previewTemplateData;
  return (
    <>
      {chosenPreview.preview === TEMPLATE_PREVIEW.MOBILE ? (
        <Box position="relative" mt={6}>
          <SvgMobMockup />
          <Box className="previewMob">
            {mobileThumbnailUrl &&
            (mobileThumbnailUrl.includes('png') || mobileThumbnailUrl.includes('jpg')) ? (
              <img src={mobileThumbnailUrl} alt="template-img" />
            ) : (
              <iframe
                title="Embed Iframe"
                src={mobileThumbnailUrl}
                name="customTemplatePreview"
                width="100%"
                height="100%"
              />
            )}
          </Box>
        </Box>
      ) : chosenPreview.preview === TEMPLATE_PREVIEW.TABLET ? (
        <Box position="relative">
          <SvgTabLandScapeMode width="50rem" />

          <Box className="previewTab">
            {tabletThumbnailUrl &&
            (tabletThumbnailUrl.includes('png') || tabletThumbnailUrl.includes('jpg')) ? (
              <img alt="template-img" src={tabletThumbnailUrl} />
            ) : (
              <iframe
                title="Embed Iframe"
                src={tabletThumbnailUrl}
                name="customTemplatePreview"
                width="100%"
                height="100%"
              />
            )}
          </Box>
        </Box>
      ) : chosenPreview.preview === TEMPLATE_PREVIEW.DESKTOP ? (
        <Box width="100%" mt={6}>
          <Box display="flex" p={1.5} alignItems="center" className={classes.desktopStripe}>
            <Box className="dot" />
            <Box className="dot" />
            <Box className="dot" />
          </Box>
          <Box
            className={`previewDesk ${
              desktopThumbnailUrl &&
              (desktopThumbnailUrl.includes('png') || desktopThumbnailUrl.includes('jpg'))
                ? 'imagePreview'
                : ''
            }`}>
            {desktopThumbnailUrl &&
            (desktopThumbnailUrl.includes('png') || desktopThumbnailUrl.includes('jpg')) ? (
              <img src={desktopThumbnailUrl} alt="template-img" />
            ) : (
              <iframe
                title="Embed Iframe"
                src={desktopThumbnailUrl}
                name="customTemplatePreview"
                width="100%"
                height={1000}
              />
            )}
          </Box>
        </Box>
      ) : (
        false
      )}
    </>
  );
};

const PreviewTemplate = ({
  setPreview,
  previewData,
  editLPHandler,
  handlePublish,
  isLanding,
  setBuild,
  buildData,
}) => {
  const classes = useStyles();
  const [getSelectedView, setSelectedView] = useState({
    id: previewData.id,
    preview: DEF_TEMPLATE_PREVIEW,
  });
  const publishText =
    previewData.previewTemplateData && previewData.previewTemplateData.isPublished
      ? 'Republish'
      : 'Publish';

  const previewEl = useRef(null);

  const previewHandler = (e, previewType) => {
    e.preventDefault();
    setSelectedView({ ...getSelectedView, preview: previewType });
    commonTrackEvent('preview template', {
      Template_id: previewData.previewTemplateData.id,
      'Template name ': previewData.previewTemplateData.name,
      'rank template': previewData.index,
      'Preview type': previewType,
    });
  };
  useEffect(() => {
    if (previewEl.current) previewEl.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <Box ref={previewEl} className={classes.chooseTemplatePreview}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" component="label">
          <NavigateBefore />
          <Typography
            component="span"
            variant="caption"
            onClick={() => setPreview({ ...previewData, preview: !previewData.preview })}>
            Event Landing Page
          </Typography>
        </Typography>
        <Box className="iconAction" display="flex" alignItems="center">
          <IconButton
            className={getSelectedView.preview === TEMPLATE_PREVIEW.DESKTOP ? 'active' : ''}
            onClick={(e) => previewHandler(e, TEMPLATE_PREVIEW.DESKTOP)}>
            <Monitor size={20} />
          </IconButton>
          <IconButton
            className={getSelectedView.preview === TEMPLATE_PREVIEW.TABLET ? 'active' : ''}
            onClick={(e) => previewHandler(e, TEMPLATE_PREVIEW.TABLET)}>
            <Tablet size={20} />
          </IconButton>
          <IconButton
            className={getSelectedView.preview === TEMPLATE_PREVIEW.MOBILE ? 'active' : ''}
            onClick={(e) => previewHandler(e, TEMPLATE_PREVIEW.MOBILE)}>
            <Smartphone size={15} />
          </IconButton>
        </Box>
        <Box className="primaryAction" display="flex" alignItems="center">
          {isLanding ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setBuild({
                  ...buildData,
                  build: !buildData.build,
                  buildTemplateData: previewData.previewTemplateData,
                })
              }>
              Start Building
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => editLPHandler(previewData.previewTemplateData)}>
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handlePublish(publishText.toLowerCase(), previewData.previewTemplateData)
                }>
                {publishText}
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box width="100%" display="flex" alignItems="center" justifyContent="center">
        <GetChosenPreview
          previewTemplateData={previewData.previewTemplateData}
          chosenPreview={getSelectedView}
        />
      </Box>
    </Box>
  );
};

export default PreviewTemplate;
