import React from 'react';

export default function SvgVideo2(props) {
	return (
		<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="18" height="15.751" viewBox="0 0 18 15.751" {...props}>
			<g transform="translate(0.001 0.001)">
				<path fill="#f59c86" d="M.563,15.751a.563.563,0,1,1,0-1.126H17.437a.563.563,0,1,1,0,1.126Zm0-2.25a.563.563,0,1,1,0-1.126H17.437a.563.563,0,1,1,0,1.126Zm.75-2.249A1.316,1.316,0,0,1,0,9.939V1.313A1.316,1.316,0,0,1,1.313,0H16.687A1.314,1.314,0,0,1,18,1.313V9.939a1.314,1.314,0,0,1-1.313,1.313ZM1.126,1.313V9.939a.187.187,0,0,0,.187.187H16.687a.189.189,0,0,0,.189-.187V1.313a.189.189,0,0,0-.189-.187H1.313A.187.187,0,0,0,1.126,1.313ZM6.266,8.894A.562.562,0,0,1,6,8.415V2.79a.561.561,0,0,1,.813-.5L12.441,5.1a.563.563,0,0,1,0,1.007L6.814,8.919a.538.538,0,0,1-.25.059A.564.564,0,0,1,6.266,8.894ZM7.127,7.5l3.8-1.9-3.8-1.9Z" transform="translate(-0.001 -0.001)" />
			</g>
		</svg>
	)
}