/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from 'react';
import Recaptcha from 'react-invisible-recaptcha';
import { Box, Typography, Grid, makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InputField from '../../custom-components/form-fields/inputField';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import { VERIFY_USER, UPDATE_USER } from '../../api-setup/api-endpoints';
import Validation from '../../models/validation';
import { SignUp2Schema } from '../../models/schema';
import SvgBrokenLink from '../../icons/svgBrokenLink';
import ProjectSettings from '../../settings';
import { resendEmailApiCall } from 'lib/common-api-functions';
import { setUserCredential } from 'lib/cookies';
import { removeAllFromQuery } from 'lib/url-utilities';
import { POSTAPI, GETAPI, API } from 'api-setup/api-helper';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';
import { Lock, Briefcase } from 'react-feather';
import PreLoader from 'custom-components/preloader';
import mixPanel from 'lib/mix-panel';
import CustomToolTip from 'custom-components/customToolTip';

const useStyles = makeStyles((theme) => ({
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  emailVeriFailedWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

// handles validations for form fields
const handleValidations = (formFields) => {
  let newErrObj = '';
  newErrObj = Validation.validate(SignUp2Schema, formFields);
  if (newErrObj) return newErrObj;
  return false;
};

// api call for posting organisation and password
const updateApiCall = (
  captchaToken,
  recaptchaRef,
  token,
  formFields,
  history,
  setIsLoading,
  dispatch
) => {
  const { organizationName, password, confirmPassword } = formFields;
  const body = {
    data: {
      organisation: organizationName,
      password,
      confirm_password: confirmPassword,
      captchaToken,
    },
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  POSTAPI(false, UPDATE_USER, null, headers, body)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        const { token: newToken } = res.data.data;
        setUserCredential({
          authToken: newToken,
        });
        dispatch(
          setNewMessage({ message: 'Success! Your Account is active', type: 'success', show: true })
        );
        history.replace('/events');
      } else {
        API.errStatusHandler(res, history, dispatch, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'stg' ||
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            if (recaptchaRef && recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
          }
          setIsLoading(false);
        });
      }
    })
    .catch((err) => {
      API.catchHandler(err, () => {
        if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
          if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      });
      setIsLoading(false);
    });
};

export default function VerifyToken(props) {
  const { subTitleWithSeparator, emailVeriFailedWrap, iconColor } = useStyles();
  const [isEmailLinkExpired, setEmailLinkExpired] = useState(false);
  const [isAccountActivated, setAccountActivated] = useState(false);
  const [isEmailVerificationFailed, setEmailVerificationFailed] = useState(false);
  const [formFields, setFormFields] = useState({
    organizationName: '',
    password: '',
    confirmPassword: '',
  });
  const [captchaToken, setCaptchaToken] = useState('');
  const recaptchaRef = useRef(null);
  const [errObj, setErrObj] = useState('');
  const [token, setToken] = useState('');
  const [isInitLoading, setInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formSubmit = (event) => {
    event.preventDefault();
    const err = handleValidations(formFields);
    if (!err) {
      setErrObj({});
      if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.execute();
        }
      } else {
        makeApiCall('');
      }
    } else {
      setErrObj(err);
    }
  };
  useEffect(() => {
    const _token = props.location.search.split('=').pop();
    if (!_token) {
      props.history.replace('/login');
    } else {
      setToken(_token);
      removeAllFromQuery(props.history, props.location);
      const headers = {
        type: 'signup',
        Authorization: `Bearer ${_token}`,
      };
      GETAPI(false, VERIFY_USER, null, headers, null)
        .then((res) => {
          setInitLoading(false);
          if (res.data.type && res.data.type === 'OrganiserLinkExpired Exception') {
            setEmailLinkExpired(true);
          } else if (res.data.is_verified && res.data.is_verified === 1) {
            setAccountActivated(true);
          } else {
            setEmailVerificationFailed(true);
          }
          // mixpanel
          if (isEmailLinkExpired || isEmailVerificationFailed) mixPanel.Login.BrokenSignupLinkMP();
        })
        .catch(API.catchHandler);
    }
    // mixpanel
    mixPanel.Login.RedirectFromEmailMP('FORM');
  }, []);

  // api call for resending email or updating user credentials
  const makeApiCall = (propCaptchaToken = '') => {
    setIsLoading(true);
    if (isEmailLinkExpired) resendEmailApiCall(token, props.history, setIsLoading, dispatch);
    else
      updateApiCall(
        propCaptchaToken,
        recaptchaRef,
        token,
        formFields,
        props.history,
        setIsLoading,
        dispatch
      );
  };

  useEffect(() => {
    if (captchaToken) {
      makeApiCall(captchaToken);
    }
  }, [captchaToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };
  if (isInitLoading)
    return (
      <Box display="flex" alignItems="center">
        <Box position="relative" width={350} height={350} m="auto">
          <PreLoader />
        </Box>
      </Box>
    );
  return (
    <>
      {isEmailLinkExpired || isEmailVerificationFailed ? (
        <Box className={`${emailVeriFailedWrap}`} display="flex" alignItems="start">
          <Box width={350} p={3} m="auto">
            <Box mb={2} display="flex" justifyContent="center">
              <HubiloLogo height="70px" width="140px" />
            </Box>
            <Box mb={8} className="text-center">
              <Typography variant="subtitle2" component="h3">
                That link is no longer valid
              </Typography>
            </Box>
            <Box mt={8} className="text-center" color="text.grayColor">
              <Box component="span">
                <SvgBrokenLink />
              </Box>
              <Box mt={5} ml="auto" mr="auto">
                <Typography variant="caption" component="p">
                  {isEmailLinkExpired
                    ? 'Email confirmation links expire after a while and can only be used once'
                    : 'Invalid Link'}
                </Typography>
              </Box>
              <Box mt={4}>
                <BtnWithLoader
                  className="uppercase"
                  onClick={makeApiCall}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  isLoading={isLoading}
                  loadingLabel="Requesting"
                  label="Request New link"
                  fullWidth
                />
              </Box>
              <Box mt={4}>
                <Typography color="textPrimary" variant="caption">
                  Already used the link to create your account?{' '}
                  <Link to="/" className="text-uppercase">
                    <Box component="span" color="primary.main" fontWeight={600}>
                      Sign In
                    </Box>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : isAccountActivated ? (
        <>
          <Box mb={10} display="flex" justifyContent="center">
            <HubiloLogo height="50px" width="120px" />
          </Box>
          <Box component="span" display="flex" justifyContent="center">
            <SvgBrokenLink />
          </Box>
          <form>
            <Box mt={5} textAlign="center" color="text.grayColor">
              <Typography variant="caption" component="p">
                Your account is already activated. You can just log in to your account.
              </Typography>
            </Box>
            <Box mt={4}>
              <Button
                type="submit"
                onClick={() => props.history.replace('/')}
                className="uppercase"
                fullWidth
                color="primary"
                variant="contained">
                Login
              </Button>
            </Box>
          </form>
        </>
      ) : (
        <>
          <Box mb={10} display="flex" justifyContent="center">
            <HubiloLogo height="50px" width="120px" />
          </Box>
          <form onSubmit={(event) => formSubmit(event)}>
            <Box mt={3.2}>
              <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
                <Typography variant="caption" component="span">
                  Add Account Detail
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    disabled={isLoading}
                    error={errObj.organizationName}
                    name="organizationName"
                    id="organizationName"
                    value={formFields.organizationName}
                    onChange={handleChange}
                    required
                    placeholder="Organization"
                    type="text"
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Briefcase className={iconColor} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" position="relative" width="93%" height="30px">
                    <CustomToolTip
                      isFabIcon
                      title="Password should be minimum seven characters with a combination of Upper, lowercase, digits and special characters."
                      placement="top"
                    />
                  </Box>
                  <InputField
                    disabled={isLoading}
                    error={errObj.password}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    value={formFields.password}
                    required
                    placeholder="Password"
                    type="password"
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Lock className={iconColor} />}
                    showFieldVisibilityBtn
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    disabled={isLoading}
                    error={errObj.confirmPassword}
                    name="confirmPassword"
                    id="confirmPassword"
                    value={formFields.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder="Confirm Password"
                    type="password"
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Lock className={iconColor} />}
                    showFieldVisibilityBtn
                  />
                </Grid>
              </Grid>
              <Box mt={5}>
                <BtnWithLoader
                  className="uppercase"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  isLoading={isLoading}
                  loadingLabel="Finishing Up"
                  label="Proceed"
                  fullWidth
                />
              </Box>
              <Box mt={5} textAlign="center" color="text.grayColor">
                <Typography variant="caption" component="p">
                  Already have an account?{' '}
                  <Link to="/" className="text-uppercase">
                    <Box component="span" color="primary.main" fontWeight={600}>
                      Sign In
                    </Box>
                  </Link>
                </Typography>
              </Box>
            </Box>
          </form>
        </>
      )}
      {process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod' ? (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={ProjectSettings.recaptchaSiteKey}
          onResolved={(PropToken) => setCaptchaToken(PropToken)}
        />
      ) : (
        ''
      )}
    </>
  );
}
