import React from 'react';

export default function SvgChineseFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#D80027"
      />
      <path
        d="M5.47266 6.08588L6.33594 8.74213H9.12891L6.87109 10.3867L7.73438 13.0429L5.47266 11.4023L3.21094 13.0429L4.07812 10.3867L1.81641 8.74213H4.60938L5.47266 6.08588Z"
        fill="#FFDA44"
      />
      <path
        d="M11.8555 15.4883L11.1953 14.6758L10.2187 15.0547L10.7852 14.1758L10.125 13.3593L11.1367 13.6289L11.707 12.75L11.7617 13.7968L12.7773 14.0664L11.7969 14.4414L11.8555 15.4883Z"
        fill="#FFDA44"
      />
      <path
        d="M13.168 13.1054L13.4805 12.1054L12.625 11.5L13.6719 11.4843L13.9805 10.4843L14.3203 11.4765L15.3672 11.4648L14.5273 12.0898L14.8633 13.082L14.0078 12.4765L13.168 13.1054Z"
        fill="#FFDA44"
      />
      <path
        d="M14.9375 7.33978L14.4766 8.28119L15.2266 9.01166L14.1914 8.86322L13.7305 9.80072L13.5508 8.76947L12.5117 8.62103L13.4414 8.13275L13.2617 7.0976L14.0117 7.82806L14.9375 7.33978Z"
        fill="#FFDA44"
      />
      <path
        d="M11.8828 4.48825L11.8047 5.53122L12.7773 5.92575L11.7578 6.17575L11.6836 7.22263L11.1328 6.332L10.1133 6.582L10.7891 5.78122L10.2344 4.8945L11.207 5.28903L11.8828 4.48825Z"
        fill="#FFDA44"
      />
    </svg>
  );
}
