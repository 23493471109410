import {
  ACTIVITY_SCORE,
  LEAD_LEVELS,
  LEAD_LEVEL_DIST,
  RESET_ACTIVITY_SCORE,
  RESET_LEAD_LEVELS,
  LEAD_ANALYTICS_OVERVIEW,
  LEAD_ANALYTICS_DETAILS,
  EXPORT_LEAD_ANALYTICS,
} from 'api-setup/api-endpoints';

const leadScoreApiUrls = {
  activityScore: ACTIVITY_SCORE,
  resetActivityScore: RESET_ACTIVITY_SCORE,
  leadLevels: LEAD_LEVELS,
  resetLeadLevels: RESET_LEAD_LEVELS,
  leadLevelDist: LEAD_LEVEL_DIST,
  leadLevelAnalyticsOverview: LEAD_ANALYTICS_OVERVIEW,
  leadLevelAnalyticsDetails: LEAD_ANALYTICS_DETAILS,
  exportLeadLevelAnalytics: EXPORT_LEAD_ANALYTICS,
};

export default leadScoreApiUrls;
