/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, InputLabel, Typography } from '@material-ui/core';
import TextAreaField from 'custom-components/form-fields/textAreaField';
import InputField from 'custom-components/form-fields/inputField';
import Sidebar from 'custom-components/sidebar';
import {
  addEmbedAppToEventAsync,
  addUpdateEmbedAppIconListAsync,
  addEmbedAppToSessionAsync,
} from 'redux/api-service/embed-apps';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { eventEmbedAppSelector, sessionEngagementSelector } from 'redux/selectors';
import { eventDataSelector, commonSelector, sessionDataSelector } from 'redux/custom-selector';
import IconSearchPopUp from 'custom-components/form-fields/IconSearchPopUp';
import SvgEmbedAppIcon from 'icons/svgEmbedAppIcon';
import { useRouteMatch } from 'react-router-dom';
import validateFunctions from '../../../models/validation';
import { EmbedAppFileUploadSchema, SessionEngagementSchema } from '../../../models/schema';
import { iFrameRegex } from 'models/regex';
import ProjectSetting, { restrictingCharacerInput } from 'settings';
import sanitiseIframe from '../../../lib/sanitise-iframe';
import { isSingleFrameCount } from 'utility/validator';
import SwitchField from 'custom-components/form-fields/switch';
import PopUp from 'custom-components/popUp';
import SvgAudioIcon from 'icons/svgAudioIcon';
import { useStyles } from './style';
import LearnMoreLink from 'learnMoreLinks';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';
import RadioBtn from 'custom-components/form-fields/radioBtn';
import { FileUploadUI } from 'custom-components/form-fields/fileUploadField';
import { GET_SIGNED_URL } from 'api-setup/api-endpoints';
import { setNewMessage } from 'redux/actions/setting-actions';
import { API, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import { getFileUploadDetails, uploadS3Promise } from 'lib/common-file-function';

export const EmbedTypes = {
  EMBED_CODE: 'EMBED_CODE',
  FILE_UPLOAD: 'FILE_UPLOAD',
};

const EmbedAppSideBar = ({
  isOpen = false,
  handler,
  data = {},
  isEvent = true,
  backgroundModeEnabledAppInfo,
  cb,
  comingFrom,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { iconList = [], isAddAppLoading = false } = useSelector(
    eventEmbedAppSelector,
    shallowEqual
  );
  const { eventId } = useSelector(eventDataSelector, shallowEqual);
  const { organiserId } = useSelector(commonSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const { id, name } = useSelector(sessionDataSelector, shallowEqual);
  const { session_name } = useSelector(sessionEngagementSelector, shallowEqual);
  const [defaultIcon, setDefaultIcon] = useState({});
  const [appName, setAppName] = useState('');
  const [iframeCode, setIFrameCode] = useState('');
  const [isBackgroundModeEnable, setBackGroundMode] = useState(false);
  const [errorObj, setErrorObj] = useState('');
  const [loadState] = useState({
    exportApiCall: false,
  });
  const [backGroundModeModal, setBackGroundModeModal] = useState({ isOpen: false, data: {} });
  const [iconSearchText, setIconSearchText] = useState('');
  const match = useRouteMatch();
  const [embedType, setEmbedType] = useState(EmbedTypes.EMBED_CODE);
  const [fileData, setFileData] = useState({
    realFileName: '',
    fileName: '',
    fileBlob: null,
  });
  const [fileUploadData, setFileUploadData] = useState({
    uploadStatus: 'IDEAL',
    uploadingPer: 0,
  });
  const [loadingState, setLoadState] = useState({
    isUpload: false,
  });
  const source = useSource();
  const [isCancelUpload, setCancelUpload] = useState(false);
  const [s3FileName, setS3FileName] = useState('');

  const textAreas = document.getElementsByTagName('textarea');

  Array.prototype.forEach.call(textAreas, (elem, i) => {
    textAreas[i].placeholder = elem.placeholder.replace(/\\n/g, '\n');
  });

  useEffect(() => {
    dispatch(addUpdateEmbedAppIconListAsync({ name: '' }));
  }, [dispatch]);

  const defaultIconHandler = useCallback(() => {
    if (iconList.length) {
      setDefaultIcon(
        iconList.find((item) => {
          return item.id === 1;
        })
      );
    }
  }, [iconList]);

  useEffect(() => {
    defaultIconHandler();
  }, [defaultIconHandler]);

  // TODO : Error Updates

  const handleValidation = () => {
    let valid = true;
    let errorObjV = {};
    if (embedType === EmbedTypes.EMBED_CODE) {
      const dataV = { name: appName.trim(), embed_code: iframeCode };
      errorObjV = validateFunctions.validate(SessionEngagementSchema, dataV);
    } else if (embedType === EmbedTypes.FILE_UPLOAD) {
      const dataV = { name: appName.trim() };
      errorObjV = validateFunctions.validate(EmbedAppFileUploadSchema, dataV);
    }
    if (!errorObjV) errorObjV = {};
    if (iframeCode !== '') {
      const val = iframeCode.trim();
      const isValidIframe = Boolean(val.match(iFrameRegex));
      if (!isValidIframe) {
        valid = false;
        errorObjV.embed_code = 'Please enter valid embed code';
      } else {
        // TODO : Multiple Iframe Check
        if (!isSingleFrameCount(iframeCode)) {
          valid = false;
          errorObjV.embed_code = 'Please enter a valid single embed code';
        }
      }
    } else if (s3FileName !== '') {
      valid = true;
    }

    Object.values(errorObjV).forEach((val) => {
      if (val.length > 0) valid = false;
    });

    return !valid ? errorObjV : false;
  };

  const addUpdateHandler = () => {
    const errorObjVal = handleValidation();
    if (!errorObjVal) {
      setErrorObj({});
      const dataBody = {
        name: appName,
        integration_meta_id: data.id,
        icon_id: defaultIcon.id,
        embed_code: s3FileName !== '' ? '' : iframeCode,
        file_name: s3FileName !== '' ? s3FileName : '',
        svg_image: defaultIcon.svg_image,
      };
      if (isEvent) {
        dataBody.integration_meta_name = data.name;
        dispatch(addEmbedAppToEventAsync({ data: dataBody }, { eventid: eventId }, cb));
        commonTrackEvent('add integration',{
         'app name' : appName,
         'source' : 'Event',
        })
        // cb();
      } else {
        dataBody.engagement_meta_name = data.name;
        dataBody.is_background_mode = isBackgroundModeEnable ? 1 : 0;
        commonTrackEvent('add integration',{
         'app name' : appName,
         'source' : 'Session',
         'session id' : match.params.sessionId,
         'session name' : session_name
        })
        dispatch(
          addEmbedAppToSessionAsync(
            { data: dataBody },
            { eventid: eventId, type_id: match.params.sessionId, type: 'Session' },
            // eslint-disable-next-line no-use-before-define
            addEmbedAppsCallback
          )
        );

        // TODO : isEvent false for session which comes through props
        // TODO : make api call here for session only
      }
    } else {
      setErrorObj(errorObjVal);
    }
  };

  const appIconHandler = (val) => {
    setDefaultIcon(val);
  };

  const onSwitchHandler = (e) => {
    if (e.target.checked && backgroundModeEnabledAppInfo.id) {
      setBackGroundModeModal({ ...backGroundModeModal, isOpen: true, data: {} });
    } else {
      setBackGroundMode(e.target.checked);
    }
  };

  const addEmbedAppsCallback = (listData) => {
    const listAddedEmbedApp = listData.data.list[listData.data.list.length - 1];
    commonTrackEvent('confirm add embed app', {
      organiserId,
      'session id': id || match.params.sessionId,
      'session name': name,
      'app id': listAddedEmbedApp.id,
      'app name': listAddedEmbedApp.name,
      'background mode': listAddedEmbedApp.is_background_mode === 1 ? 'on' : 'off',
    });
  };

  const uploadFile = (blob, realFileName) => {
    const valueFile = blob;
    const fileDetails = getFileUploadDetails(valueFile);

    const payload = {
      payload: {
        data: {
          ...fileDetails,
          uploadType: 'EMBED_APP',
        },
      },
    };

    const [header] = API.generateHeader(reduxData);
    const customURLV2 = ProjectSetting.customBaseURL2;
    setLoadState({ ...loadingState, isUpload: true });
    // setFileUploadData({ uploadStatus: 'IDEAL', uploadingPer: 0 });
    setFileUploadData({ uploadStatus: 'UPLOADING', uploadingPer: 0 });
    POSTAPIWITHCUSTOMDOMAIN(false, GET_SIGNED_URL, source.AS, header, payload, customURLV2)
      .then((fetchSignature) => {
        clearInterval(source.interVal);

        if (fetchSignature.data?.urlLists.length && fetchSignature.data?.urlLists[0].uploadURL) {
          source.uploadingPer = 0;

          const uploadOption = {
            fileConfig: fetchSignature.data?.urlLists[0],
            contentType: fileDetails.contentType,
            progressCB: (val) => {
              source.uploadingPer = val;
            },
          };

          // if (resp.data.message)
          //   dispatch(
          //     setNewMessage({
          //       message: resp.data.message,
          //       type: 'success',
          //       show: true,
          //     })
          //   );

          // source.uploadingPer = 0;
          source.interVal = setInterval(
            () => {
              if (source.uploadingPer >= 99) {
                clearInterval(source.interVal);
              } else {
                setFileUploadData({
                  uploadStatus: 'UPLOADING',
                  uploadingPer: source.uploadingPer + 1,
                });

                source.uploadingPer += 1;
              }
            },
            source.uploadingPer < 25
              ? 200
              : source.uploadingPer < 45
              ? 600
              : source.uploadingPer < 60
              ? 800
              : source.uploadingPer < 90
              ? 1200
              : 1500
          );
          uploadS3Promise(valueFile, uploadOption)
            .then(() => {
              if (!isCancelUpload) {
                setFileData({
                  realFileName,
                  fileName: fetchSignature.data?.urlLists[0].fileName,
                  fileBlob: valueFile,
                });
                setFileUploadData({ uploadStatus: 'UPLOADED', uploadingPer: 100 });
                setLoadState({ ...loadingState, isUpload: false });
                setS3FileName(fetchSignature.data?.urlLists[0].fileName);
              } else {
                setCancelUpload(false);
              }
            })
            .catch((e) => {
              dispatch(
                setNewMessage({
                  message: e?.message,
                  type: 'error',
                  show: true,
                })
              );
              setFileUploadData({ uploadStatus: 'FAILED', uploadingPer: 0 });
              source.uploadingPer = 0;
              setLoadState({ ...loadingState, isUpload: false });
            });
        } else {
          setLoadState({ ...loadingState, isUpload: false });
          // API.errStatusHandler(resp, history, dispatch, () => {
          //   if (resp.data.status === 400) {
          //     setFileUploadData({ uploadStatus: 'FAILED', uploadingPer: 0 });
          //     source.uploadingPer = 0;
          //   }
          // });
        }
      })
      .catch(API.catchHandler);

    const bodyFormData = new FormData();
    // bodyFormData.append('location', 'user_profile');
    bodyFormData.append('type', 'file');
    bodyFormData.append('data', blob);
  };

  const onRemoveFile = () => {
    setFileData({
      realFileName: '',
      fileName: '',
      fileBlob: null,
    });
    setFileUploadData({
      uploadStatus: 'IDEAL',
      uploadingPer: 0,
    });
    setS3FileName('');
  };

  return (
    <>
      <Sidebar
        open={isOpen}
        asideWidth={500}
        onClose={() => {
          if (!isEvent) {
            commonTrackEvent('cancel add embed app', {
              organiserId,
              'session id': id || match.params.sessionId,
              'session name': name,
            });
          }
          if (loadState.exportApiCall) return;
          handler(!isOpen);
        }}
        title="Adding A New Application"
        footerBtnLabel="Add"
        footerBtnLoadingLabel="Adding"
        footerBtnAction={() => addUpdateHandler()}
        disabled={
          embedType === EmbedTypes.FILE_UPLOAD && s3FileName === '' ? true : isAddAppLoading
        }>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>App Icon</InputLabel>
            <Box mt={1.2}>
              <IconSearchPopUp
                title="Select App Icon"
                btnActionLabel="Done"
                btnSize="medium"
                iconHandler={(val) => appIconHandler(val)}
                value={iconSearchText}
                onChangeHandler={(e) => {
                  setIconSearchText(e.target.value);
                }}
                btnSvg={
                  defaultIcon.id ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: defaultIcon.svg_image,
                      }}
                    />
                  ) : (
                    <SvgEmbedAppIcon />
                  )
                }
                activeIcon={defaultIcon}
                options={iconList || []}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputField
              label="App name"
              required
              name="appName"
              placeholder="Enter App Name"
              value={appName}
              maxLength={restrictingCharacerInput.embedApps.name}
              error={errorObj.name}
              onChange={(e) => {
                setAppName(e.target.value);
                setErrorObj({ ...errorObj, name: '' });
              }}
              onKeyPress={(e) => {
                const regex = new RegExp('^[0-9a-zA-Z_. ]+$');
                const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
                if (regex.test(str)) {
                  return true;
                }
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {comingFrom !== 'sessions' && (
              <RadioBtn
                label="Embed App"
                id="embed-code"
                handleChange={(evt) => {
                  setEmbedType(evt.target.value);
                }}
                defaultValue={embedType}
                value={embedType}
                options={[
                  { label: 'Embed Code', value: EmbedTypes.EMBED_CODE },
                  { label: 'File Upload', value: EmbedTypes.FILE_UPLOAD },
                ]}
              />
            )}

            {embedType === EmbedTypes.EMBED_CODE && (
              <Box mt={3.5}>
                <TextAreaField
                  label="Embed Code"
                  isShowCustomDescText
                  ToolTipCharCount
                  descText="Paste your ‘iframe’ code below to embed your app"
                  descType="TOOLTIP"
                  required
                  placeholder={`Place your code here... \n<iframe src="https://example.com/"></iframe>`}
                  rowsMax={6}
                  rowMin={6}
                  maxLength={restrictingCharacerInput.embedApps.embedCode}
                  name="iframeCodeheader"
                  value={iframeCode}
                  error={errorObj.embed_code}
                  onChange={(e) => {
                    setIFrameCode(sanitiseIframe(e.target.value));
                    setErrorObj({ ...errorObj, embed_code: '' });
                  }}
                />
              </Box>
            )}

            {embedType === EmbedTypes.FILE_UPLOAD && (
              <Box mt={3.5}>
                <FileUploadUI
                  label="File Upload"
                  id="embed-file-upload"
                  descText="Users will be able to zoom in, Zoom out and drag across the uploaded pdf/image file."
                  descType="TOOLTIP"
                  required
                  maxSize={10}
                  fileTypes={['PDF', 'IMAGE']}
                  fileData={fileData}
                  uploadStatus={fileUploadData.uploadStatus}
                  uploadingProgress={fileUploadData.uploadingPer}
                  btnLoader={loadingState.isUpload}
                  setFileData={setFileData}
                  extraUploadInfo="2000x2000px (Maximum)| 10MB (Maximum)"
                  onUploadFile={uploadFile}
                  onErrorMessage={(message) => {
                    dispatch(setNewMessage({ message, type: 'info', show: true }));
                  }}
                  setFileUploadData={setFileUploadData}
                  onCancelUpload={() => {
                    onRemoveFile();
                    setCancelUpload(true);
                  }}
                  onDeleteFile={() => {
                    onRemoveFile();
                  }}
                  editFieldProps={{
                    placeholder: 'Enter File Name',
                    value: fileData.fileName,
                    onChange: (event) => setFileData({ ...fileData, fileName: event.target.value }),
                    isAllowFileNameEdit: false,
                  }}
                  comingFrom="EmbedApp"
                  fileSize={
                    fileData.fileBlob && typeof fileData.fileBlob === 'object'
                      ? (fileData?.fileBlob?.size / (1024 * 1024)).toFixed(2)
                      : ''
                  }
                  maxImageHeight={2000}
                  maxImageWidth={2000}
                />
              </Box>
            )}
          </Grid>
          {!isEvent ? (
            <Grid item xs={12}>
              <Box
                className={classes.SwitchField}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="body2" component="label" className="backGroundMode">
                  Background Mode
                </Typography>
                <Box>
                  <SwitchField
                    onChange={(e) => {
                      onSwitchHandler(e);
                    }}
                    checked={isBackgroundModeEnable}
                  />
                </Box>
              </Box>
              <Typography variant="body2" color="textSecondary" component="p">
                It is recommended to enable this mode for interpretation apps so as to run the
                audio, ASL or captioning service in the background during the session.
                <Box
                  onClick={() => window.open(LearnMoreLink.backgroundModeLearnMoreLink, '_blank')}
                  component="a"
                  variant="body2"
                  ml={0.3}
                  className="text-underline  cursor-pointer">
                  Learn More
                </Box>
              </Typography>
            </Grid>
          ) : (
            false
          )}
        </Grid>
      </Sidebar>
      <PopUp
        paddingBox={1}
        btnMarginTop={3}
        maxWidth={430}
        isOpen={backGroundModeModal.isOpen}
        onClose={() => {
          setBackGroundModeModal({ ...backGroundModeModal, isOpen: false, data: {} });
        }}
        data={{
          title: 'Do you want to override background mode?',
          secondaryDesc:
            'Background mode is enabled for another app in this session. If you enable background mode in this app, it will be disabled in another app.',
          Icon: SvgAudioIcon,
          actionBtn: [
            {
              name: 'Cancel',
              onClick: () => {
                setBackGroundModeModal({ ...backGroundModeModal, isOpen: false, data: {} });
                commonTrackEvent('override background mode', {
                  organiserId,
                  action: 'cancel',
                  'session id': id || match.params.sessionId,
                  'session name': name,
                });
              },
            },
            {
              name: 'Enable',
              type: 'primary',
              onClick: () => {
                setBackGroundModeModal({ ...backGroundModeModal, isOpen: false, data: {} });
                setBackGroundMode(true);
                commonTrackEvent('override background mode', {
                  organiserId,
                  action: 'enable',
                  'session id': id || match.params.sessionId,
                  'session name': name,
                });
              },
            },
          ],
        }}
      />
    </>
  );
};

export default EmbedAppSideBar;
