export const NEW_MESSAGE = 'NEW_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const HARD_REMOVE_MESSAGE = 'HARD_REMOVE_MESSAGE';

export function setNewMessage(data) {
	return {
		type: NEW_MESSAGE,
		message: data
	}
}

export function removeMessage(index) {
	return {
		type: REMOVE_MESSAGE,
		index
	}
}

export function hardRemoveMessage(index) {
	return {
		type: HARD_REMOVE_MESSAGE,
		index
	}
}

