import { EActionType } from "./constants";

// it'll return action type based on the route path
export const getActionFromPath = (path) => {
    if (!path) return null;
    const pathArray = path.split('/');
    let actionType = null;
    if (pathArray.length > 1) {
        actionType = pathArray[2] === 'create' ? EActionType.CREATE : EActionType.EDIT;
    }
    return actionType;
}

export const returnAddress = (address) => {
    let addressString = '';
    if (Object.keys(address)?.length > 1) {
        if (address?.address) addressString = address.address;
        if (address?.city) addressString = addressString ? `${addressString}, ${address?.city}` : address?.city;
        if (address?.province) addressString = addressString ? `${addressString}, ${address?.province}` : address?.province;
        if (address?.country) addressString = addressString ? `${addressString}, ${address?.country}` : address?.country;
        if (address?.zip) addressString = addressString ? `${addressString}, ${address?.zip}` : address?.zip;
    } else if (address?.country) addressString = address?.country;

    return addressString
}