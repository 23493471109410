import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    SwitchField:{
        "& .backGroundMode":{
            fontWeight:500
        },
        "& .MuiSwitch-root":{
            marginRight:0,
        }
    }
}));
