/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
/* eslint-disable prefer-object-spread */

import { INTEGRATIONS } from '../constants';
import { PricingPlan } from 'constants/pricingPlan';

// To remove parameters from URL
export const removeAllFromQuery = (history, location) => {
  let newLocation = Object.assign({}, location);
  newLocation.search = '';
  history.replace(newLocation);
};

export const embedAppName = (string = '') => {
  let str = '';
  if (string) {
    str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return str;
};

export const getOnlyIntegrationStatusForUser = (data = {}, cardId) => {
  const {
    subscriptionStatus,
    isPlanExpired = false,
    is_integration_embed_app = 0,
    is_sso_enabled = 0,
    is_eloqua_enabled = 0,
    planName,
  } = data;

  // Subscription Package : search ==> userInfo.UserInfo.subscription_meta_id
  const isFreePlan = planName === PricingPlan.FREE; // Trail / Free , subscription_meta_id = 1
  const isBasicPlan = planName === PricingPlan.BASIC; // subscription_meta_id = 5
  const isProPlan = planName === PricingPlan.PRO; // subscription_meta_id = 3
  const isAdvancePlan = planName === PricingPlan.ADVANCED; // subscription_meta_id = 2
  const isEnterPrisePlan = planName === PricingPlan.ENTERPRISE; // subscription_meta_id = 4
  const { WEBINAR_LITE, WEBINAR, DIGITAL_EVENTS, ALL_IN } = PricingPlan;

  const isWebinar = planName === WEBINAR_LITE || planName === WEBINAR;
  const isDigitalEvent = planName === DIGITAL_EVENTS;
  const isAllIn = planName === ALL_IN;
  // App Available for Integration

  const isSalesForce = cardId === 1;
  const isHubSpot = cardId === 2;
  const isMarketo = cardId === 5;
  const isMailChip = cardId === 6;
  const isMicroSoftDynamics = cardId === 7;
  const isEloqua = cardId === 10;
  const isSlackSalesForce = cardId === 11;
  const isSSO = cardId === 4;
  const isEmbedYourApp = cardId === INTEGRATIONS.CUSTOM_APP_ID;
  const isActive = subscriptionStatus === 'ACTIVE'; //  search ==> userInfo.UserInfo.subscriptionStatus ==='ACTIVE';

  const isPlanLess = subscriptionStatus === undefined;
  const isPlanCancelled =
    subscriptionStatus === 'CANCELLED' || subscriptionStatus === 'CANCEL_SCHEDULED';
  const isAdminAllowedPermissionForEmbedAPP = !!is_integration_embed_app;
  const isAdminAllowedPermissionForSSO = !!is_sso_enabled;
  const isAdminAllowedPermissionForEloqua = !!is_eloqua_enabled;

  let isUpgrade = true;

  if (
    (isAdminAllowedPermissionForEmbedAPP && isEmbedYourApp) ||
    (isAdminAllowedPermissionForSSO && isSSO)
  ) {
    isUpgrade = false;
  } else {
    if (isPlanLess || isAllIn || isDigitalEvent || isWebinar || isPlanLess) {
      isUpgrade = false;
    } else if (isPlanCancelled) {
      if (isEmbedYourApp) {
        if (isEnterPrisePlan) {
          isUpgrade = false;
        } else {
          isUpgrade = true;
        }
      } else {
        isUpgrade = false;
      }
    } else {
      if (isActive) {
        if (isFreePlan) {
          isUpgrade = false;
        } else {
          if (isBasicPlan) {
            if (isMailChip) {
              isUpgrade = false;
            } else {
              isUpgrade = true;
            }
          }
          if (isProPlan) {
            if (isSSO || isEmbedYourApp) {
              isUpgrade = true;
            } else {
              isUpgrade = false;
            }
          }
          if (isAdvancePlan) {
            if (isMailChip) {
              isUpgrade = false;
            } else if (isEloqua && isAdminAllowedPermissionForEloqua) {
              isUpgrade = false;
            } else {
              isUpgrade = true;
            }
          }
          if (isEnterPrisePlan) {
            isUpgrade = false;
          }
        }
      } else {
        isUpgrade = true;
      }
    }
  }
  return {
    isSalesForce,
    isHubSpot,
    isMarketo,
    isMailChip,
    isMicroSoftDynamics,
    isEloqua,
    isSSO,
    isEmbedYourApp,
    isUpgrade,
    isExpired: isPlanExpired,
    isCancelled: subscriptionStatus === 'CANCELLED',

    isDisabled:
      isSlackSalesForce && planName === WEBINAR_LITE
        ? true
        : subscriptionStatus === undefined
        ? false
        : isPlanExpired || subscriptionStatus === 'CANCELLED',
    isNoUpgradeForEmbedApps: isAdminAllowedPermissionForEmbedAPP && isUpgrade && isEmbedYourApp,
    isPlanLess,
  };
};
