/* eslint-disable  */
import React from 'react';
import ReactCropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Slider, Box, Button, withStyles, makeStyles } from '@material-ui/core';
import BtnWithLoader from './btnWithLoader';

const useStyles = makeStyles((theme) => ({
  footerModal: {
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  loginBannerCrop: {
    '& .cropper-crop-box': {
      '&::before': {
        position: `absolute`,
        content: `""`,
        width: `100%`,
        height: '10.19%',
        background: 'rgba(255,241,0,.5)',
        left: 0,
        top: 0,
        zIndex: 1,
      },
      '&::after': {
        position: `absolute`,
        content: `""`,
        width: `100%`,
        height: '10.19%',
        background: 'rgba(255,241,0,.5)',
        left: 0,
        bottom: 0,
        zIndex: 1,
      },
    },
  },
}));

const CustomSlide = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    height: 5,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(0)}px`,
  },
  thumb: {
    height: 25,
    width: 25,
    backgroundColor: theme.palette.primary.light,
    marginTop: -12,
    position: 'relative',
    marginLeft: -14,
    boxShadow: '0px 3px 6px #0000001A',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 3px 6px #0000001A',
    },
  },
  valueLabel: {
    top: 6,
    left: -3,
    '& *': {
      fontWeight: 500,
      background: 'transparent',
      color: theme.palette.primary.contrastText,
    },
  },
  track: {
    height: 5,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.grayColor,
  },
  rail: {
    height: 5,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.bg.whiteOff2,
  },
}))(Slider);

function calculateFinalHeightWidth(originalHeight, originalWidth, height, width) {
  let finalWidth = originalWidth,
    finalHeight = originalHeight;

  if (width / height < 1.1 && width / height > 0.9) {
    finalWidth = 200;
    finalHeight = (200 * height) / width;
  } else {
    if (window.innerWidth < 768) {
      finalWidth = 250;
      finalHeight = (250 * height) / width;
    } else {
      if (width > 400) {
        if (height > 200 && originalWidth > 400) {
          finalWidth = 400;
          finalHeight = (400 * height) / width;
        } else if (height > 200 && originalWidth < 400) {
          finalWidth = (200 * width) / height;
          finalHeight = 200;
        } else if (height < 200 && originalWidth < 400) {
          finalWidth = width;
          finalHeight = height;
        } else {
          finalWidth = 400;
          finalHeight = (400 * height) / width;
        }
      } else if (width < 400) {
        if (height < 200) {
          finalWidth = width;
          finalHeight = height;
        } else {
          finalWidth = (200 * width) / height;
          finalHeight = 200;
        }
      } else {
        finalWidth = 200;
        finalHeight = (200 * height) / width;
      }
    }
  }
  return [finalHeight, finalWidth];
}

export default function Cropper(props) {
  const {
    src,
    cropperStyle,
    croppedImageWidth,
    croppedImageHeight,
    cropBoxResizable = true,
    cropBoxMovable = true,
    // cropBoxResizable = true,
    onCrop,
    onCancel,
    loadingLabel = '',
    isLoading = false,
    minCropBoxWidth,
    isLoginBanner = false,
    minCropBoxHeight,
    ...rest
  } = props;
  const classes = useStyles();
  const cropperRef = React.useRef(null);
  const [aspectRatio, setAspectRatio] = React.useState(1);
  const getImage = () => {
    if (cropperRef && cropperRef.current) {
      onCrop(
        cropperRef.current
          .getCroppedCanvas({ width: croppedImageWidth, height: croppedImageHeight })
          .toDataURL()
      );
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={isLoginBanner ? classes.loginBannerCrop : ''}>
        {minCropBoxWidth &&
        minCropBoxHeight &&
        typeof minCropBoxHeight !== 'undefined' &&
        typeof minCropBoxWidth !== 'undefined' ? (
          <ReactCropper
            ref={cropperRef}
            src={src}
            style={{ height: 400, width: '100%', ...cropperStyle }}
            // Cropper.js options
            aspectRatio={aspectRatio}
            // aspectRatio={3.24}
            dragMode="move"
            viewMode={2}
            guides={true}
            cropBoxMovable={cropBoxMovable}
            cropBoxResizable={cropBoxResizable}
            minCropBoxWidth={minCropBoxWidth}
            minCropBoxHeight={minCropBoxHeight}
            minCanvasHeight={200}
            minCanvasWidth={200}
            // autoCropArea={1}
            ready={() => {
              let imageData = cropperRef.current.getImageData();
              const [finalHeight, finalWidth] = calculateFinalHeightWidth(
                imageData.height,
                imageData.width,
                croppedImageHeight,
                croppedImageWidth
              );
              setAspectRatio(finalWidth / finalHeight);
            }}
            checkCrossOrigin={false}
            {...rest}
          />
        ) : (
          <ReactCropper
            ref={cropperRef}
            src={src}
            style={{ height: 400, width: '100%', ...cropperStyle }}
            // Cropper.js options
            aspectRatio={aspectRatio}
            // aspectRatio={3.24}
            dragMode="move"
            viewMode={2}
            guides={true}
            cropBoxMovable={cropBoxMovable}
            cropBoxResizable={cropBoxResizable}
            minCanvasHeight={200}
            minCanvasWidth={200}
            // autoCropArea={1}
            ready={() => {
              let imageData = cropperRef.current.getImageData();
              const [finalHeight, finalWidth] = calculateFinalHeightWidth(
                imageData.height,
                imageData.width,
                croppedImageHeight,
                croppedImageWidth
              );
              setAspectRatio(finalWidth / finalHeight);
            }}
            checkCrossOrigin={false}
            {...rest}
          />
        )}
      </Box>

      <Box mt={3} width="300px" m="auto">
        <CustomSlide
          defaultValue={1}
          getAriaValueText={(value) => {
            return `${value}`;
          }}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          min={1}
          max={100}
          onChange={(e, newValue) => cropperRef.current.scale((newValue * 2) / 100 + 1 || 1)}
        />
      </Box>

      <Box
        pt={2.5}
        mt={2.5}
        className={classes.footerModal}
        display="flex"
        justifyContent="flex-end"
        alignItems="center">
        <Button
          disabled={isLoading}
          variant="outlined"
          color="primary"
          size="small"
          onClick={onCancel}>
          CANCEL
        </Button>
        <Box ml={2.5}>
          <BtnWithLoader
            onClick={getImage}
            variant="contained"
            color="primary"
            size="small"
            loadingLabel={loadingLabel}
            isLoading={isLoading}
            disabled={isLoading}
            label="CROP & SAVE"
          />
        </Box>
      </Box>
    </>
  );
}
