/* eslint-disable react/self-closing-comp */
import React from 'react';

export default function SvgLinkedInIcon(props) {
  return (
    <svg fill="currentcolor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 15" {...props}>
      <path d="M11.8,4.6c-1.8,0-2.6,1-3,1.7v0h0c0,0,0,0,0,0V4.9H5.4c0,0.9,0,10.1,0,10.1h3.4V9.3c0-0.3,0-0.6,0.1-0.8c0.2-0.6,0.8-1.2,1.7-1.2c1.2,0,1.7,0.9,1.7,2.3V15h3.4V9.2C15.7,6.1,14,4.6,11.8,4.6z"></path>
      <path d="M1.9,0C0.8,0,0,0.8,0,1.7c0,1,0.7,1.7,1.9,1.7h0c1.2,0,1.9-0.8,1.9-1.7C3.8,0.8,3,0,1.9,0z"></path>
      <rect x="0.2" y="4.9" width="3.4" height="10.1"></rect>
    </svg>
  );
}
