/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */

import { SET_EVENT_LIST_DATA, UPDATE_EVENT_LIST_DATA } from 'redux/actions/eventList-action';

const eventListData = (state = {}, action) => {
  switch (action.type) {
    case SET_EVENT_LIST_DATA:
      state = { ...state, ...action.data };
      break;
    case UPDATE_EVENT_LIST_DATA:
      const ended_eventsIndex =
        state.ended_events && state.ended_events.findIndex((item) => item.id === action.data.id);
      const ongoing_eventsIndex =
        state.ongoing_events &&
        state.ongoing_events.findIndex((item) => item.id === action.data.id);
      const upcoming_eventsIndex =
        state.upcoming_events &&
        state.upcoming_events.findIndex((item) => item.id === action.data.id);

      if (ended_eventsIndex !== -1) {
        state.ended_events[ended_eventsIndex] = {
          ...state.ended_events[ended_eventsIndex],
          ...action.data,
        };
      } else if (ongoing_eventsIndex !== -1) {
        state.ongoing_events[ongoing_eventsIndex] = {
          ...state.ongoing_events[ongoing_eventsIndex],
          ...action.data,
        };
      } else if (upcoming_eventsIndex !== -1) {
        state.upcoming_events[upcoming_eventsIndex] = {
          ...state.upcoming_events[upcoming_eventsIndex],
          ...action.data,
        };
      }

      state = { ...state };
      break;
    default:
      break;
  }
  return state;
};

export default eventListData;
