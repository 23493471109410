import React from 'react';
import { Fade, Paper, Box, Typography, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { userInfoSelector } from 'redux/custom-selector';
import CircularProgress from '../../../custom-components/circularProgress';
import SvgPeopleProgress from '../../../icons/svgPeopleProgressIcon';
import SvgBoothProgress from '../../../icons/svgBoothProgressIcon';
import SvgCalendarProgressIcon from '../../../icons/svgCalendarProgressIcon';
import amplitude from 'lib/amplitude';
import { useStyles } from './style';
import { planTypeDisplay } from './planTypeDisplay';
import { colors } from 'constants/colors';
import { PricingPlan } from 'constants/pricingPlan';

export const PlanPaymentDeclinedMenu = ({ isPlanMenuOpen, setPlanMenuOption }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const { planDropdown } = useStyles();

  return (
    <Fade in={isPlanMenuOpen}>
      <Box
        position="absolute"
        className={`${planDropdown} hasTip`}
        width={280}
        left="-50%"
        zIndex={5}
        mt={3}
        mb={1.2}>
        <Paper elevation={2}>
          <Box className="dropDownBody">
            <Box className="choosePlan" display="flex" alignItems="center">
              <Box width="100%">
                <Box className="currentPlan" display="flex" mb={1} p={2} alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%">
                    <Box>
                      <Typography variant="h6" component="h6">
                        {planTypeDisplay(UserInfo)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button size="small" className="expired-btn" disabled>
                        {' '}
                        Payment Declined{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Box className="expiredPlan" mb={1}>
                  <Box className="leftDataPlan" display="flex">
                    <Box mr={2}>
                      <CircularProgress
                        icon={<SvgPeopleProgress />}
                        value={
                          UserInfo.peoplesCreditsUsagePercentage > '100'
                            ? '100'
                            : UserInfo.peoplesCreditsUsagePercentage
                        }
                        size={50}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6" component="h6" display="block">
                        {UserInfo.total_attendee_credits === 0
                          ? UserInfo.used_attendee_credits
                          : `${UserInfo.used_attendee_credits}/${UserInfo.total_attendee_credits}`}
                      </Typography>
                      <Typography variant="caption" component="span" color={colors.textSecondary}>
                        Registrations
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="leftDataPlan" display="flex">
                    <Box mr={2}>
                      <CircularProgress
                        icon={<SvgCalendarProgressIcon height="20px" width="20px" />}
                        value={
                          UserInfo.concurrentSessionsUsagePercentage > '100'
                            ? '100'
                            : UserInfo.concurrentSessionsUsagePercentage
                        }
                        size={50}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6" component="h5" display="block">
                        {' '}
                        {UserInfo.totalConcurrentSessionCredit === 0
                          ? UserInfo.usedConcurrentSessionCredit
                          : `${UserInfo.usedConcurrentSessionCredit}/${UserInfo.totalConcurrentSessionCredit}`}
                      </Typography>
                      <Typography variant="caption" component="span" color={colors.textSecondary}>
                        Parallel Sessions/Event
                      </Typography>
                    </Box>
                  </Box>

                  {UserInfo.planName === PricingPlan.BASIC ||
                  UserInfo.planName === PricingPlan.ADVANCED ? (
                    false
                  ) : (
                    <Box className="leftDataPlan" display="flex">
                      <Box mr={2}>
                        <CircularProgress
                          icon={<SvgBoothProgress />}
                          value={
                            UserInfo.virtualBoothCreditsUsagePercentage > '100'
                              ? '100'
                              : UserInfo.virtualBoothCreditsUsagePercentage
                          }
                          size={50}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                    <Box mb={1}>
                      <Typography variant="body2" component="label" color={colors.textSecondary}>
                        {UserInfo.planName === PricingPlan.FREE
                          ? 'Upgrade to Premium Plans'
                          : false}
                      </Typography>
                    </Box>
                    {UserInfo.planName === PricingPlan.ENTERPRISE ||
                    (UserInfo.planName === PricingPlan.ENTERPRISE && match.path === '/plans') ? (
                      false
                    ) : (
                      <Button
                        onClick={() => {
                          history.push('/plans');
                          amplitude.Track.upgradeClicked('popup');
                          setPlanMenuOption(false);
                        }}
                        className="uppercase"
                        variant="contained"
                        color={colors.primary}
                        size="small">
                        Upgrade
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                  <Button
                    onClick={() => {
                      history.push('/billing');
                      setPlanMenuOption(false);
                    }}
                    className="uppercase"
                    variant="contained"
                    color={colors.primary}
                    size="small">
                    GO TO BILLING
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};
