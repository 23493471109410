import React from 'react';

const style = {
  a: { fill: 'none' },
};
export default function SvgAnalytics(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <rect style={style.a} width="30" height="30" />
      <path
        d="M858.4,191.5h-6.9a.536.536,0,0,0-.5.5v3.5h-6.5a.536.536,0,0,0-.5.5v5.9h-6.5a.536.536,0,0,0-.5.5v9.3a.536.536,0,0,0,.5.5h20.7a.536.536,0,0,0,.5-.5V192.1C858.9,191.7,858.7,191.5,858.4,191.5ZM838,202.6h6V211h-6Zm7-.4v-5.9h6V211h-6Zm13,8.9h-6V192.4h6Z"
        transform="translate(-832.5 -187)"
      />
    </svg>
  );
}
