import React from 'react';

const XSvgIcon = ({ className, width='20', height='18' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.6831 0H18.7366L12.0655 7.62462L19.9136 18H13.7686L8.9557 11.7074L3.44862 18H0.393231L7.52862 9.84462L0 0H6.30093L10.6514 5.75169L15.6831 0ZM14.6114 16.1723H16.3034L5.38154 1.73169H3.56585L14.6114 16.1723Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default XSvgIcon;
