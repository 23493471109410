import React from 'react';

export default function SvgHybridEventIcon(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19 12H18V2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H2V12H1C0.734784 12 0.48043 12.1054 0.292893 12.2929C0.105357 12.4804 0 12.7348 0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H9V15.15L4.45 18.15C4.26333 18.2655 4.11988 18.4393 4.04177 18.6445C3.96366 18.8496 3.95523 19.0748 4.01779 19.2852C4.08035 19.4957 4.21042 19.6797 4.38793 19.8089C4.56544 19.938 4.78053 20.0052 5 20C5.1966 20.0022 5.38896 19.9428 5.55 19.83L9 17.55V19C9 19.2652 9.10536 19.5196 9.29289 19.7071C9.48043 19.8946 9.73478 20 10 20C10.2652 20 10.5196 19.8946 10.7071 19.7071C10.8946 19.5196 11 19.2652 11 19V17.55L14.45 19.83C14.611 19.9428 14.8034 20.0022 15 20C15.2136 19.9984 15.4212 19.9283 15.5921 19.8002C15.7631 19.672 15.8885 19.4925 15.95 19.2879C16.0115 19.0832 16.0058 18.8643 15.9338 18.6631C15.8619 18.462 15.7273 18.2892 15.55 18.17L11 15.17V14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13C20 12.7348 19.8946 12.4804 19.7071 12.2929C19.5196 12.1054 19.2652 12 19 12ZM16 12H4V2H16V12ZM7.61 10.26C7.87653 10.4175 8.18043 10.5005 8.49 10.5005C8.79957 10.5005 9.10347 10.4175 9.37 10.26L12.37 8.52C12.6265 8.36259 12.8383 8.14204 12.9852 7.87943C13.1322 7.61683 13.2093 7.32093 13.2093 7.02C13.2093 6.71907 13.1322 6.42317 12.9852 6.16057C12.8383 5.89796 12.6265 5.67741 12.37 5.52L9.37 3.78C9.10347 3.62253 8.79957 3.53946 8.49 3.53946C8.18043 3.53946 7.87653 3.62253 7.61 3.78C7.34143 3.9314 7.11879 4.15259 6.96564 4.42017C6.81248 4.68774 6.73452 4.99174 6.74 5.3V8.78C6.74167 9.08149 6.82302 9.37718 6.97581 9.63709C7.1286 9.89701 7.34739 10.1119 7.61 10.26ZM8.74 5.68L11 7L8.72 8.32L8.74 5.68Z" fill="#AFC3CA" />
        </svg>

    );
}
