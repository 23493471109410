import React from 'react';

const style = {
  a: {
    fill: 'none',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 1.5,
  },
};
export default function SvgEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 21.795 21.795"
      {...props}>
      <g transform="translate(-1.25 -0.955)">
        <path style={style.a} d="M11,4H4A2,2,0,0,0,2,6V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V13" />
        <path style={style.a} d="M18.5,2.5a2.121,2.121,0,0,1,3,3L12,15,8,16l1-4Z" />
      </g>
    </svg>
  );
}
