/* eslint-disable no-case-declarations */

import {
  SET_ROI_METADATA,
  SET_ROI_DATE_DATE_RANGE,
  SET_OVERALL_FUNNEL_LOADING,
  SET_OVERALL_FUNNEL_DATA,
  SET_ROI_OPPORTUNITIES_STATS_LOADING,
  SET_ROI_OPPORTUNITIES_STATS_DATA,
  SET_ROI_OPPORTUNITIES_BREAKDOWN_LOADING,
  SET_ROI_OPPORTUNITIES_BREAKDOWN_DATA,
  SET_ROI_OPPORTUNITIES_FILTER_DATA,
  SET_ROI_OPPORTUNITIES_FILTER_TYPE,
  SET_ROI_OPPORTUNITIES_PRIORITY_KEY,
  SET_ROI_LEADS_STATUS_TYPE,
  SET_ROI_LEADS_STATUS_DATA,
  SET_ROI_LEADS_STATUS_LOADING,
  SET_ROI_LEADS_STATUS_FILTER_DATA,
  SET_ROI_HIDDEN_REVENUE_LOADING,
  SET_ROI_HIDDEN_REVENUE_DATA,
  ROI_TOGGLE_STATE,
  SET_ROI_EVENT_WISE_NUMBERS_DATA,
  SET_ROI_EVENT_WISE_NUMBERS_LOADING,
  SET_ROI_LAST_LOCATION,
} from 'redux/actions/roi';
import { defaultROIDatePickerRange } from '../../pages/roi/common/calendarConfig';

const roiReducer = (
  state = {
    selectedDateRange: defaultROIDatePickerRange, // Start Date & End Date
    metaData: {
      isSFConnected: false,
      isSyncInProgress: false,
      lastSyncTimestamp: null,
      isRoiEnabled: false,
      hasEvent: false,
      baseCurrency: '',
    },
    roiOpportunitiesFilterType: 'sourced',
    roiOpportunitiesFilterData: {
      roiForAllEvents: true,
      eventType: [],
      eventIds: [],
      account: [],
      industry: [],
      geography: [],
    },
    roiOpportunitiesPriorityKey: '',
    roiLeadsStatusType: 'sourced',
    roiLeadsStatusFilterData: {
      roiForAllEvents: true,
      eventType: [],
      eventIds: [],
    },
    loading: {
      isOverallFunnelLoading: false,
      isRoiOpportunitiesStatsLoading: false,
      isRoiOpportunitiesBreakdownLoading: false,
      isRoiLeadsStatusLoading: false,
      isRoiHiddenRevenueLoading: false,
      isRoiEventWiseNumbersLoading: false,
    },
    overallFunnelData: null,
    overAllFunnelDataStatus: {
      isLeadAvailable: false,
      isOpportunitiesAvailable: false,
      isCloseWonAvailable: false,
      isPipelineAvailable: false,
    },
    roiOpportunitiesStatsData: null,
    roiOpportunitiesBreakdownData: null,
    roiLeadsStatusData: null,
    roiHiddenRevenueData: {},
    roiEventWiseNumbersData: {},
    lastLocation: '/events',
  },
  action
) => {
  switch (action.type) {
    case SET_ROI_METADATA:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          ...action.payload,
        },
      };
    case SET_ROI_DATE_DATE_RANGE:
      return { ...state, selectedDateRange: { ...action.payload } };
    case SET_OVERALL_FUNNEL_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isOverallFunnelLoading: action.payload,
        },
      };
    case SET_OVERALL_FUNNEL_DATA:
      const isLeadAvailable = Boolean(
        action.payload?.leads?.all?.value || action.payload?.leads?.all
      );
      const isOpportunitiesAvailable = Boolean(
        action.payload?.opportunities?.all?.value || action.payload?.opportunities?.all?.count
      );
      const isCloseWonAvailable = Boolean(
        action.payload?.closed?.all?.value || action.payload?.closed?.all?.count
      );
      return {
        ...state,
        overallFunnelData: action.payload,
        overAllFunnelDataStatus: {
          isLeadAvailable,
          isOpportunitiesAvailable,
          isCloseWonAvailable,
        },
      };
    case SET_ROI_OPPORTUNITIES_STATS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isRoiOpportunitiesStatsLoading: action.payload,
        },
      };
    case SET_ROI_OPPORTUNITIES_STATS_DATA:
      return {
        ...state,
        roiOpportunitiesStatsData: action.payload,
      };
    case SET_ROI_OPPORTUNITIES_BREAKDOWN_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isRoiOpportunitiesBreakdownLoading: action.payload,
        },
      };
    case SET_ROI_OPPORTUNITIES_BREAKDOWN_DATA:
      return {
        ...state,
        roiOpportunitiesBreakdownData: action.payload,
      };
    case SET_ROI_OPPORTUNITIES_FILTER_DATA:
      const filter = action.payload;

      const isAllNegative =
        filter.roiForAllEvents === false &&
        filter.eventType.length === 0 &&
        filter.eventIds.length === 0;

      let updatedFilter = {
        ...state.roiOpportunitiesFilterData,
        ...action.payload,
      };
      if (isAllNegative) {
        updatedFilter = {
          ...state.roiOpportunitiesFilterData,
          ...action.payload,
          roiForAllEvents: true,
          eventType: [],
          eventIds: [],
        };
      }

      return {
        ...state,
        roiOpportunitiesFilterData: updatedFilter,
      };
    case SET_ROI_OPPORTUNITIES_FILTER_TYPE:
      return {
        ...state,
        roiOpportunitiesFilterType: action.payload,
      };
    case SET_ROI_OPPORTUNITIES_PRIORITY_KEY:
      return {
        ...state,
        roiOpportunitiesPriorityKey: action.payload,
      };
    case SET_ROI_LEADS_STATUS_TYPE:
      return {
        ...state,
        roiLeadsStatusType: action.payload,
      };
    case SET_ROI_LEADS_STATUS_DATA:
      return {
        ...state,
        roiLeadsStatusData: action.payload,
      };
    case SET_ROI_LEADS_STATUS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isRoiLeadsStatusLoading: action.payload,
        },
      };
    case SET_ROI_LEADS_STATUS_FILTER_DATA:
      const leadFilter = action.payload;

      const isAllNegativeLeadFilter =
        leadFilter.roiForAllEvents === false &&
        leadFilter.eventType.length === 0 &&
        leadFilter.eventIds.length === 0;

      let updatedLeadFilter = {
        ...state.roiLeadsStatusFilterData,
        ...action.payload,
      };
      if (isAllNegativeLeadFilter) {
        updatedLeadFilter = {
          ...state.roiLeadsStatusFilterData,
          ...action.payload,
          roiForAllEvents: true,
          eventType: [],
          eventIds: [],
        };
      }
      return {
        ...state,
        roiLeadsStatusFilterData: updatedLeadFilter,
      };
    case SET_ROI_HIDDEN_REVENUE_DATA:
      return {
        ...state,
        roiHiddenRevenueData: action.payload,
      };
    case SET_ROI_HIDDEN_REVENUE_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isRoiHiddenRevenueLoading: action.payload,
        },
      };

    case ROI_TOGGLE_STATE:
      return {
        ...state,
        metaData: { ...state.metaData, isRoiEnabled: action.payload },
      };
    case SET_ROI_EVENT_WISE_NUMBERS_DATA:
      return {
        ...state,
        roiEventWiseNumbersData: action.payload,
      };
    case SET_ROI_EVENT_WISE_NUMBERS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          isRoiEventWiseNumbersLoading: action.payload,
        },
      };
    case SET_ROI_LAST_LOCATION:
      return {
        ...state,
        lastLocation: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default roiReducer;
