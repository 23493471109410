import * as React from 'react';
import { makeStyles, Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { EActionType } from '../common/constants';
import { getActionFromPath } from '../common/utils';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    breadcrumbsLink: {
        fontSize: '14px',
        fontWeight: 500,
    },
    active: {
        color: theme.palette.primary.light,
    },

}));


const Breadcrumb = () => {
    const classes = useStyles();
    const history = useHistory()
    const actionType = getActionFromPath(history.location.pathname);

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="themeColor" href="/" className={`${classes.breadcrumbsLink} ${classes.active}`}>
                Events
            </Link>
            <Typography color="textSecondary" className={classes.breadcrumbsLink}>
                {actionType === EActionType.CREATE ?
                    'Create New Event' : 'Edit Event'}
            </Typography>
        </Breadcrumbs>
    )
}

export default Breadcrumb; 