export const saveDataToLocalStorage = (key = '', user = {}) => {
  window.localStorage.setItem(key, JSON.stringify(user));
};

export const getDataFromLocalStorage = (key = '') => {
  let data = {};
  if (key) {
    data = JSON.parse(window.localStorage.getItem(key) || '{}');
  }
  return data;
};

export const removeDataFromLocalStorage = (key = '') => {
  if (key) {
    window.localStorage.removeItem(key);
  }
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};

export const saveDataToSessionStorage = (key = '', user = {}) => {
  window.sessionStorage.setItem(key, JSON.stringify(user));
};

export const getDataFromSessionStorage = (key = '') => {
  let data = {};
  if (key) {
    data = JSON.parse(window.sessionStorage.getItem(key) || '{}');
  }
  return data;
};

export const removeDataFromSessionStorage = (key = '') => {
  if (key) {
    window.sessionStorage.removeItem(key);
  }
};

export const clearSessionStorage = () => {
  window.sessionStorage.clear();
};
