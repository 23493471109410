/* eslint-disable react/self-closing-comp */
import React from 'react';

const style = {
  a: { fill: 'none' },
  b: { fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round' },
};
export default function SvgEngagementIcon(props) {
  return (
    <svg
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      {...props}>
      <g transform="translate(5 4)">
        <rect width="30" height="30" transform="translate(-5 -4)" style={style.a}></rect>
        <g transform="translate(-705.271 -41.22)">
          <path
            d="M721.917,64.22H709.626a2.355,2.355,0,0,1-2.355-2.355V43.575a2.355,2.355,0,0,1,2.355-2.355h12.291a2.354,2.354,0,0,1,2.354,2.355v18.29A2.354,2.354,0,0,1,721.917,64.22Z"
            style={style.b}></path>
          <path d="M724.271,60.22h-17" style={style.b}></path>
          <path
            d="M715.771,54.22a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,715.771,54.22Z"
            style={style.b}></path>
          <path
            d="M720.271,60.22h-9V57.928c0-1.569,1.893-2.708,4.5-2.708s4.5,1.139,4.5,2.708Z"
            style={style.b}></path>
          <rect
            width="6"
            height="3"
            rx="1"
            transform="translate(712.271 43.22)"
            style={style.b}></rect>
          <path d="M720.294,45.9l-1.305-1.305,1.257-1.257Z" style={style.b}></path>
        </g>
      </g>
    </svg>
  );
}
