/* eslint-disable no-var */
/* eslint-disable no-useless-escape */

// var emailRegex =/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// var emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
// var emailRegex=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const firstnameLastnameRegex = /^[\p{L}a-zA-Z0-9 `@._-]{0,}$/u;
// var urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/i;
var urlRegex = /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
const urlRegex2 = new RegExp(
  '^(https:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$',
  'i'
); // fragment locator
const urlRegex3 = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i; // must have http/s
// var passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,}$/;
var passwordRegex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
var htmlTagRegex = /<[a-z][\s\S]*>/i;
const EXTRACT_REGEX = /(?:\.([^.]+))?$/;
var alphabaticsNumericRegexWithSpaceBar = /^[$^*-=?>< ]*$/;
var domainRegex = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
var stringRegex = /^[A-Za-z ]+$/i;
var alphabaticsNumericRegex = /^[A-Za-z0-9-]*$/;
var alphabaticsSpecialCharcterRegex = /^[A-Za-z-_,$%*^#@ZA&!'"{}():;?/ ]*$/;
var numberRegex = /^[0-9\b]+$/;
var phoneNumberRegex = /^[0-9\b\s+]{0,20}$/;
var decimalRegex = /^[0-9\b.]+$/;
var webUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)?/;
var webProtocolRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,15})?(?:[/?#]\S*)?$/;
// var webUrlRegex = /^((?:(?:http|https):\/\/)(www\.)|(www\.))?\w+(\.\w{2,4})\/?$/
var linkdinUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?linkedin.com\/?/;
var instaUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/?/;
var facebookUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/;
var twitterUrlRegex = /(?:(?:http|https):\/\/)?(?:www.)?(twitter|x).com\/?/;
var emailDominRegex =
  /^([\w-.]+@(?!gmail\.com)(?!yahoo\.com)(?!yahoo\.in)(?!yopmail\.com)(?!hotmail\.com)([\w-]+.)+[\w-]{2,4})?$/;
var youtubeRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
var mobileNoRegex =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[0-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
// var iFrameRegex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))*$/s;
var iFrameRegex = /(?:<iframe[^>]*src=[\"|']([^'\"]+)[\"|'][^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;

// const iFrameSourceRegex = /(?<=src=").*?(?=[\?"])/gm; // TODO : not in use
// var iFrameRegex=/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gs
var stringWithSpaceNumberAndUndercore = /^[0-9a-zA-Z_\. ]+$/;
var percentageRegex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
var emojiReplaceRegex =
  /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
const alphabetsSpaceRegEx = /[0-9!@#$%^&*(),.?"`';:{}|\[\]\-_+/<>=\\]|\d/;
module.exports = {
  firstnameLastnameRegex,
  emailRegex,
  decimalRegex,
  urlRegex,
  webProtocolRegex,
  alphabaticsNumericRegexWithSpaceBar,
  passwordRegex,
  stringRegex,
  alphabaticsSpecialCharcterRegex,
  htmlTagRegex,
  EXTRACT_REGEX,
  domainRegex,
  alphabaticsNumericRegex,
  numberRegex,
  webUrlRegex,
  linkdinUrlRegex,
  instaUrlRegex,
  facebookUrlRegex,
  twitterUrlRegex,
  emailDominRegex,
  youtubeRegex,
  mobileNoRegex,
  iFrameRegex,
  stringWithSpaceNumberAndUndercore,
  percentageRegex,
  phoneNumberRegex,
  urlRegex2,
  emojiReplaceRegex,
  alphabetsSpaceRegEx,
  urlRegex3
};
