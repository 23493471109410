/* eslint-disable  */
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, Box, makeStyles, Typography, FormGroup } from '@material-ui/core';
import FieldWrapper from './fieldWrapper';
import CustomToolTip from 'custom-components/customToolTip';
const useStyles = makeStyles((theme) => ({
  checkBox: {
    '& .MuiFormControl-root': {
      '& label': {
        // marginRight: 0,
        '& .MuiFormControlLabel-label': {
          fontSize: theme.fontSize.size8,
        },
      },
    },
    '& .labelStyle': {
      '&::after': {
        content: `"*"`,
        position: 'absolute',
        marginLeft: 4,
        color: theme.palette.text.danger1,
      },
    },
  },
  formGroup: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      '&:nth-child(n+2)': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));
export default function Checkbox2(props) {
  const classes = useStyles();
  return (
    <Box className={classes.checkBox}>
      <FieldWrapper
        formControlClassName={props.formControlClassName}
        label={props.options ? props.label : ''}
        id={props.id}
        error={props.error}
        required={props.required}
        descType={props.descType}
        descText={props.descText}
        isSvg={props.isSvg}>
        {props.options ? (
          <FormGroup className={`${classes.formGroup} ${props.className}`}>
            {props.options.map((data, i) => {
              /*
               1 : Text 
               2 : Text Area
               3 : Date
               7 : Link

               above mention id is only supported for bulk upload rest will not show tooltip , otherwise we have to show
              */
              const { user_profile_field_type, user_profile_default_field_type = 0 } = data;
              let isShowToolTipForBulkUpload = false;

              if (user_profile_field_type) {
                if ([1, 2, 3, 7].includes(user_profile_field_type)) {
                  isShowToolTipForBulkUpload = false; // No Need to Show ToolTip for bulk upload
                } else {
                  if (user_profile_default_field_type === 0) {
                    // 0 Because , its for custom profile field only
                    isShowToolTipForBulkUpload = true; // Need to Show tooltip for bulk upload (which is not supported)
                  }
                }
              }
              return (
                <FormControlLabel
                  key={i}
                  className={`${props.isActiveBox ? data.isChecked ? 'active' : '' : ''}`}
                  control={
                    <Checkbox
                      checked={data.isChecked}
                      onChange={(e) => {
                        props.onChange(data, e.target.checked);
                      }}
                      name={data.name}
                      id={data.id}
                      color="primary"
                      disabled={data.disabled}
                      value={data.value}
                      size={data.size}
                    />
                  }
                  label={
                    <>
                      <Typography component="span" variant="body2">
                        {props.isSvg ? props.isSvg : ''}
                      </Typography>
                      {isShowToolTipForBulkUpload ? (
                        <Box display="table-cell" position="relative">
                          <Typography component="span" variant="body2">
                            {data.label}
                          </Typography>
                          <CustomToolTip
                            title={'This field is not supported in Bulk Upload'}
                            isFabIcon
                            placement="top"
                            className="subfieldOpt"
                          />
                        </Box>
                      ) : (
                        <Typography component="span" variant="body2">
                          {data.label}
                        </Typography>
                      )}
                    </>
                  }
                />
              );
            })}
          </FormGroup>
        ) : (
          <FormControlLabel
            className={`${props.disabled && props.disabledClassName ? props.disabledClassName : ''} ${props.checked ? 'active' : ''}`}
            control={
              <Checkbox
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
                color={props.color}
                name={props.name}
                id={props.id}
                value={props.value}
                size={props.size}
              />
            }
            label={
              <>
                <Typography component="span" variant="body2">
                  {props.isSvg ? props.isSvg : ''}{' '}
                </Typography>
                {props.isLangCode && <span className='langCode'>{props.isLangCode}</span>}
                <Typography
                  component="span"
                  variant="body2"
                  className={props.required ? 'labelStyle' : ''}>
                  {props.label}
                </Typography>
              </>
            }
          />
        )}
      </FieldWrapper>
    </Box>
  );
}
