import Validation from 'models/validation';
import {
  CopyEventBasicDetailSchema,
  CopyEventBasicDetailSchemaForWebinar,
  TicketBasicDetailsSchema,
  TicketCrInviteEmailSchema,
  TicketCrRegEmailSchema,
} from 'models/schema';
import { TICKET_CONST } from './constants';
import moment from 'moment-timezone';
import { getMilliSecondsByDateTime, rmHtmlTags } from 'lib/common-function';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';

export const formatDate = (date) => `${moment(date).format('YYYY-MM-DD HH:mm')}`;

export const formatDateWithTimeZone = (date) =>
  `${moment.tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm')}`;

export const getUtcTimezoneValue = (dateTimeMilli, timezone) => {
  try {
    return moment.tz(formatDateWithTimeZone(dateTimeMilli), timezone).utc().valueOf();
  } catch {
    return '';
  }
};

export const handleTicketCrValidations = (formData, activeStep, eventData) => {
  let errObjDet = {};
  if (activeStep === 0) {
    errObjDet = Validation.validate(TicketBasicDetailsSchema, {
      name: formData.name,
      description: formData.description === null ? '' : formData.description,
      price: formData.price,
      quantity: formData.quantity,
      group: formData.group,
      start_date_time: formData.start_date_time,
      end_date_time: formData.end_date_time,
    });

    if (+formData.price <= 0) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.price = 'Price should be more than zero';
    }

    if (
      (eventData?.EventData?.useSimplifiedStripeFlow
        ? +formData.ticket_fee_type_id === 1 && formData.organiser_get < 1
        : true) &&
      +formData.price <= 0
    ) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.price = 'Price that you get should be greater than $1';
    }

    if (+formData.quantity <= 0) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.quantity = 'No of Tickets should be more than zero';
    } else if (+formData.soldCount > +formData.quantity) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.quantity = 'No of Tickets cannot be less than sold tickets';
    }

    if (formData?.ticketType === TICKET_CONST.FREE) {
      delete errObjDet.price;
    }

    const timeZone = eventData.EventData.timezone.name;
    const sTime = formData.start_date_time;
    const eTime = formData.end_date_time;
    let isError = false;
    if (sTime !== '' && eTime !== '') {
      const evtEndTime = getMilliSecondsByDateTime(eventData?.EventData?.end_time);
      const startTimeMils = moment.tz(formatDate(sTime), timeZone).utc().valueOf();
      const endTimeMiles = moment.tz(formatDate(eTime), timeZone).utc().valueOf();

      if (!isError && moment(startTimeMils).isAfter(+evtEndTime)) {
        isError = true;
        if (Object.keys(errObjDet).length === 0) {
          errObjDet = {};
        }
        errObjDet.start_date_time = 'Start Date and Time cannot be after Event End Date';
      }
      if (!isError && moment(endTimeMiles).isAfter(+evtEndTime)) {
        isError = true;
        if (Object.keys(errObjDet).length === 0) {
          errObjDet = {};
        }
        errObjDet.end_date_time = 'End Date and Time cannot be after Event End Date';
      }

      if (!isError && startTimeMils === endTimeMiles) {
        isError = true;
        if (Object.keys(errObjDet).length === 0) {
          errObjDet = {};
        }
        errObjDet.start_date_time = 'Start and End Date cannot be the same';
        errObjDet.end_date_time = 'Start and End Date cannot be the same';
      }
    }
  } else if (activeStep === 1) {
    if (formData.shouldSendRegistrationEmail) {
      const regEmailErrObj = Validation.validate(TicketCrRegEmailSchema, {
        registrationEmailSubject: formData.registrationEmailSubject,
        registrationEmailBody: rmHtmlTags(formData.registrationEmailBody),
      });
      if (Object.keys(regEmailErrObj).length > 0) {
        errObjDet = { ...errObjDet, ...regEmailErrObj };
      }
    }

    if (formData.is_invite_email_on === 1) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      const inviteEmailErrObj = Validation.validate(TicketCrInviteEmailSchema, {
        email_subject: formData.email_subject,
        email_body: rmHtmlTags(formData.email_body),
        cta_label: formData.cta_label,
        sendInviteEmailAt: formData.sendInviteEmailAt,
      });

      if (Object.keys(inviteEmailErrObj).length > 0) {
        errObjDet = { ...errObjDet, ...inviteEmailErrObj };
      }

      const startTimeMilsUTC = moment
        .tz(formatDate(formData.start_date_time), eventData.EventData.timezone.name)
        .utc()
        .valueOf();

      const sendInviteTimeAtUTC = moment
        .tz(formatDate(formData.sendInviteEmailAt), eventData.EventData.timezone.name)
        .utc()
        .valueOf();

      if (moment(+startTimeMilsUTC).isAfter(+sendInviteTimeAtUTC)) {
        if (Object.keys(errObjDet).length === 0) {
          errObjDet = {};
        }
        errObjDet.sendInviteEmailAt = 'Email Date cannot be after Start Date or Empty';
      }
    }

    if (
      formData.is_limit_access_on === 1 &&
      formData.emails.length === 0 &&
      !formData?.restricted_emails
    ) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.restricted_emails = 'No email id has been added';
    }

    if (formData?.buy_limit_per_user && +formData?.buy_limit_per_user <= 0) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.buy_limit_per_user =
        'Buyer limit per user should be more than zero or empty for unlimited';
    } else if (
      formData?.buy_limit_per_user &&
      +formData.buy_limit_per_user > 0 &&
      +formData?.quantity &&
      +formData.buy_limit_per_user > +formData.quantity
    ) {
      if (Object.keys(errObjDet).length === 0) {
        errObjDet = {};
      }
      errObjDet.buy_limit_per_user =
        'Buyer limit per user cannot be more than No. of tickets available';
    }
  }

  return errObjDet;
};

// Selected Ticket Name and Id List
export const getSelectedNameNdIdList = (tableList, selectedList) => {
  const ticketNameNdIdList = [];
  tableList.forEach((eachRow) => {
    if (selectedList.includes(eachRow.id))
      ticketNameNdIdList.push({
        name: eachRow.name.name,
        id: eachRow.id,
      });
  });

  return ticketNameNdIdList;
};

export const compareEqual = (currentArray, originalArray) => {
  if (JSON.stringify(currentArray) === JSON.stringify(originalArray)) {
    return true;
  } else {
    return false;
  }
};

export const handleCopyEventValidations = (formData, activeStep, eventType) => {
  let errObjDet = {};
  if (activeStep === 0) {
    errObjDet = Validation.validate(
      eventType === EEventFormat.WEBINAR
        ? CopyEventBasicDetailSchemaForWebinar
        : CopyEventBasicDetailSchema,
      {
        name: formData.name,
        description: formData.description === null ? '' : formData.description,
        start_date_time: formData.start_date_time,
        timezone: formData.timezone === null ? '' : formData.timezone,
      }
    );
  } else if (activeStep === 1) {
    //
  }
  return errObjDet;
};
