/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import React, { useEffect, useRef } from 'react';
import Routes from './routes';
import { withRouter } from 'react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
// import themeSettings from "./theme-settings";
import themeSettings from './theme-settings2';
import 'fonts/fonts.css';
import { eventDataSelector, eventListDataSelector, commonSelector } from 'redux/custom-selector';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import Notification from 'custom-components/notificationToaster';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
// import BotButton from 'custom-components/botButton.jsx';
import projectSetting from 'settings';
import { getUserCredential } from 'lib/cookies';
import { useSource } from 'lib/custom-common-hooks';
import { useHistory } from 'react-router-dom';
import { setUserInfo } from 'redux/actions/user-action';
import { GETAPIWITHCUSTOMDOMAIN, API, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { PricingPlan } from 'constants/pricingPlan';
import { USER_INFO1, GET_CURRENT_PLANS } from './api-setup/api-endpoints';

// const theme = createMuiTheme(themeSettings2);
const theme = createMuiTheme(themeSettings);
const CUSTOM_URL = projectSetting.customBaseURL;

let globalEventListData;
let organiserId;
// custom hook for getting previous value
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Main(props) {
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const source = useSource();
  const history = useHistory();
  const reduxData = useSelector(commonSelector, shallowEqual);
  organiserId = eventData.userId;
  const eventListData = useSelector(eventListDataSelector, shallowEqual);
  globalEventListData = eventListData;
  const prevProps = usePrevious(props);
  const dispatch = useDispatch();
  // const { showMessages } = useIntercom();
  const cookiesData = getUserCredential();

  useEffect(() => {
    if (!prevProps) return;
    const {
      location: { pathname },
    } = props;
    const previousLocation = prevProps.location.pathname;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const FETCH_USER_AUTH = () => {
    source.AS = new Map();
    reduxData.organiserid = reduxData.organiser_id
      ? reduxData.organiser_id
      : cookiesData && cookiesData.organiser_id
        ? cookiesData.organiser_id
        : organiserId;
    const [headers, body] = API.generateHeader(
      reduxData,
      { teamMemberId: cookiesData && cookiesData.teamMemberId ? cookiesData.teamMemberId : 0 },
      null
    );
    GETAPIWITHCUSTOMDOMAIN(false, USER_INFO1, source.AS, headers, body, CUSTOM_URL)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          // setOnboardingDataCollectionDone(
          //   resp.data.data.isOnboardingDataCollectionDone,
          //   'isOnboardingDataCollectionDone'
          // );
          // console.log('cookiesData', cookiesData);
          // setUserCredential(
          //   {
          //     email: resp.data.data.email,
          //     authToken: cookiesData.authToken,
          //     teamMemberId: cookiesData.teamMemberId,
          //     userPermission: resp.data.data.userPermission,
          //     organiser_id: reduxData.organiser_id ? reduxData.organiser_id : reduxData.organiserid,
          //   },
          //   'userData'
          // );
          // dispatch(setUserInfo(resp.data.data));
          if (
            resp?.data?.data?.planName === PricingPlan.ENTERPRISE ||
            resp?.data?.data?.planName === PricingPlan.PRO
          )
            dispatch(setUserInfo({ ...resp.data.data, isCustomEnabled: 1 }));
          else dispatch(setUserInfo(resp.data.data));
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    return () => API.removeAllApi(source.AS);
  };

  const FETCH_CURRENT_PLAN_DETAILS = () => {
    const [headers] = API.generateHeader(
      reduxData,
      {
        teamMemberId: cookiesData.teamMemberId ? cookiesData.teamMemberId : 0,
        organiser_id: reduxData.organiser_id ? reduxData.organiser_id : organiserId,
      },
      null
    );
    POSTAPIWITHCUSTOMDOMAIN(false, GET_CURRENT_PLANS, source.AS, headers, null, CUSTOM_URL)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          //
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    return () => API.removeAllApi(source.AS);
  };

  const makeAPICall = () => {
    FETCH_USER_AUTH();
    if (history.location.hash === '#/billing') {
      FETCH_CURRENT_PLAN_DETAILS();
    }
  };


  return (
    <>
      <MuiThemeProvider theme={theme}>
        {/* <IntercomProvider appId={projectSetting.intercomId} onClick={showMessages} autoBoot>
          <BotButton />
        </IntercomProvider> */}
        <ScopedCssBaseline>
          <CssBaseline />
          <Routes />
          <Notification />
        </ScopedCssBaseline>
      </MuiThemeProvider>
    </>
  );
}

export default withRouter(Main);
