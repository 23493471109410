import { makeStyles } from '@material-ui/core';
import activePattern from 'images/pattern.png';

export const useStyles = makeStyles((theme) => ({
  textDanger: {
    color: theme.palette.text.danger1,
    fontWeight: 500,
  },
  successMsg: {
    background: theme.palette.alert.successLight,
    color: theme.palette.alert.success,
    borderRadius: theme.shape.borderRadius,
    '& svg': {
      marginRight: theme.spacing(0.8),
    },
  },
  errorMsg: {
    backgroundColor: theme.palette.primary.primaryLight,
    color: theme.palette.alert.alertWarnText,
    borderRadius: theme.shape.borderRadius,
    '& svg': {
      marginRight: theme.spacing(1.2),
    },
  },
  boldText: {
    fontWeight: 600,
  },
  createNewEventLayout: {
    '& p, & div, & label': {
      webkitTouchCallout: 'none',
      webkitUserSelect: 'none',
      khtmlUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
    },
    '& label': {
      '&.MuiFormControlLabel-root.Mui-disabled': {
        cursor: 'default',
        '& *': {
          cursor: 'default',
        },
      },
    },
  },
  fontStrong: {
    fontWeight: '700',
  },

  createEventBannerWrapper: {
    zIndex: 7,
  },
  headerFixed: {
    background: theme.palette.bg.white,
    position: 'fixed',
    //  paddingBottom: `${theme.spacing(1)}px`,
    zIndex: 6,
    top: ' 3.7rem',
    paddingTop: '2rem',
    width: '99.5%',
    left: 0,
  },

  eventContainer: {
    // paddingTop: '8rem',
    paddingBottom: '15rem',
    // paddingRight: '9rem',

    '& .planExpirePadTop': {
      paddingTop: '37px',
    },
    '& .webinarEventOption': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(2),
        '&:nth-child(even)': {
          marginLeft: theme.spacing(3),
        },
      },
    },
    '& .createEventOption': {
      '& label': {
        border: '1px solid #DBDBDB',
        padding: `${theme.spacing(1)}px`,
        position: 'relative',
        left: '.9rem',
        marginBottom: theme.spacing(3),
        borderRadius: '8px',

        '& span:nth-child(2)': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .radioBtnIcon': {
          width: '48px',
          height: '48px',
          minWidth: '48px',
          background: '#F6F8F8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: theme.spacing(0.5),
          marginRight: theme.spacing(1.5),
          borderRadius: '4px',
        },
        '& .disabledLockIcon': {
          visibility: 'hidden',
        },
      },
      '& label.disabledEvent': {
        background: '#F6F8F8 !important',
        border: 'none !important',
        cursor: 'default',
        '& *': {
          cursor: 'default',
        },
        '& span': {
          color: '#c0c0c0',
        },

        '& span:nth-of-type(1) span:nth-of-type(1) span:nth-of-type(1)': {
          background: '#F6F8F8',
          border: '2px solid #DBDBDB',
          '&::before': {
            display: 'none',
          },
        },
        '& .radioBtnIcon': {
          background: theme.palette.bg.white,
          '& svg': {
            '& path': {
              fill: '#c0c0c0',
            },
          },
        },
        '& .disabledLockIcon': {
          right: '1rem',
          position: 'absolute',
          visibility: 'visible',
          '& svg': {
            '& path': {
              fill: '#C0C0C0',
            },
          },
        },
        '&:first-child': {
          '& .radioBtnIcon': {
            '& svg': {
              '& path': {
                stroke: '#c0c0c0',
                fill: 'none !important',
              },
            },
          },
        },
      },
      '&.eventTooltipCardHover': {
        '&:hover + .upgradePlanCardHover': {
          display: 'flex',
        },
      },
    },

    '& .upgradePlanCardHoverWrapper': {
      paddingLeft: '7px',
      position: 'absolute',
      left: '338px',
      '&.hybridPosition': {
        top: '6rem',
      },
      '&.inPersonPosition': {
        bottom: '1rem',
      },
      '&.virtualPosition': {
        top: '0.5rem',
      },
      '&.webinarPosition': {
        //   transform: 'none',
        // transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        top: '-60px',
        left: '75px',
        zIndex: 7,
        paddingLeft: 0,

        '& .upgradePlanCardHover': {
          background: 'none',
          borderRadius: '4px',
          border: 'none',
          backgroundColor: '#000',
          color: '#fff',
          //  maxWidth: 220,
          fontSize: 10,
          textAlign: 'center',
          justifyContent: 'center',
          position: 'relative',
          transition: 'all .2s .1s ease-in-out',
          opacity: 1,
          transform: 'scale3d(1,1,1)',
          width: '190px',
          padding: '3px',

          '& .arrow': {
            content: '',
            position: 'absolute',
            left: 0,
            right: 0,
            margin: '0 auto',
            width: 0,
            height: 0,
            borderTop: '6px solid #000',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            top: '100%',
          },
        },
      },
    },
    '& .upgradePlanCardHover': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: theme.palette.bg.white,
      padding: theme.spacing(1.25),
      width: '336px',
      border: '1px solid #F6F8F8',
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      '& svg': {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
      },
      '& .upgradePlanBtn': {
        cursor: 'pointer',
        '& span': {
          color: theme.palette.primary.light,
          fontWeight: `500 !important`,
        },
      },
      '& .upgradePlanCardHoverInner': {
        width: '86%',
      },
    },
  },
  eventFormatScreen: {
    '& .checkInFacilityLocation': {
      background: '#F6F8F8',
      padding: theme.spacing(1.25),
      width: '334px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        width: '13px',
        height: '13px',
        marginLeft: '5px',
        marginBottom: '-2px',
        '& path': {
          fill: '#808080',
        },
      },
    },
    '& .msgNotifi': {
      maxWidth: '675px',
      width: 'auto',
    },
  },
  eventFormat: {
    maxWidth: '544px',
    position: 'relative',
    '& .createEventOption': {
      '& div': {
        flexDirection: 'column !important',
      },
    },

    '& label': {
      width: '334px',
      height: '80px',
      padding: `${theme.spacing(1)}px`,
      position: 'relative',
      left: '1rem',
      marginBottom: theme.spacing(3),
      '&:not(.disabledEvent)': {
        '&.active, &:hover': {
          background: `url(${activePattern}) center center no-repeat`,
          '& .radioBtnIcon': {
            background: theme.palette.bg.white,
          },
        },
        '&:nth-child(2)': {
          border: '1px solid #AFC3CA',
          '&.active, &:hover': {
            backgroundColor: '#AFC3CA',
            '& .radioBtnIcon': {
              background: theme.palette.bg.white,
              '& svg': {
                '& path': {
                  fill: theme.palette.primary.light,
                },
              },
            },
          },
        },
        '&:first-child': {
          border: '1px solid #D8C69E',
          /*    backgroundColor: '#D8C69E', */

          '&.active, &:hover': {
            backgroundColor: '#D8C69E',
            '& .radioBtnIcon': {
              background: theme.palette.bg.white,
              '& svg': {
                '& path': {
                  stroke: theme.palette.primary.light,
                },
              },
            },
          },
        },
        '&:last-child': {
          border: '1px solid #D0A698',
          marginBottom: 0,
          '&.active, &:hover': {
            backgroundColor: '#D0A698',
            '& .radioBtnIcon': {
              background: theme.palette.bg.white,
              '& svg': {
                '& path': {
                  fill: theme.palette.primary.light,
                },
              },
            },
          },
        },
      },
      '& span + span': {
        fontSize: `${theme.fontSize.size2} !important`,
        fontWeight: 700,
        color: '#333132',
      },
    },
    '&.eventFormatWebinar': {
      maxWidth: '675px',
      '& .createEventOption.createEventOptionWithWebinar': {
        '& div': {
          justifyContent: 'space-between',
          flexDirection: 'row !important',
        },

        '& label': {
          marginRight: 0,

          '&:nth-child(3)': {
            border: '1px solid #AFC3CA',
            '&.active, &:hover': {
              backgroundColor: '#AFC3CA',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    fill: theme.palette.primary.light,
                  },
                },
              },
            },
          },
          '&:nth-child(2)': {
            border: '1px solid #D8C69E',
            /*  backgroundColor: '#D8C69E', */

            '&.active, &:hover': {
              backgroundColor: '#D8C69E',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    stroke: theme.palette.primary.light,
                  },
                },
              },
            },
          },
          '&:first-child': {
            border: '1px solid #D2DB51',
            /*    backgroundColor: '#D8C69E', */

            '&.active, &:hover': {
              backgroundColor: '#D2DB51',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    fill: theme.palette.primary.light,
                  },
                },
              },
            },
          },
        },
      },
      '& .newCreateEventOption': {
        '& label': {
          marginRight: 0,

          '&:nth-child(3)': {
            border: '1px solid #AFC3CA',
            '&.active, &:hover': {
              backgroundColor: '#AFC3CA',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    fill: theme.palette.primary.light,
                  },
                },
              },
            },
          },
          '&:nth-child(2)': {
            border: '1px solid #D8C69E',
            /*  backgroundColor: '#D8C69E', */

            '&.active, &:hover': {
              backgroundColor: '#D8C69E',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    stroke: theme.palette.primary.light,
                  },
                },
              },
            },
          },
          '&:first-child': {
            border: '1px solid #D2DB51',
            /*    backgroundColor: '#D8C69E', */

            '&.active, &:hover': {
              backgroundColor: '#D2DB51',
              '& .radioBtnIcon': {
                background: theme.palette.bg.white,
                '& svg': {
                  '& path': {
                    fill: theme.palette.primary.light,
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  eventType: {
    maxWidth: '528px',
    '& label': {
      width: '246px',
      height: '80px',
      margin: 0,
      '&:nth-child(odd)': {
        marginLeft: '-0.7rem',
      },
      '&:nth-child(even)': {
        marginLeft: theme.spacing(2),
      },
      '& .radioBtnIcon': {
        '& svg': {
          color: '#808080',
        },
      },

      '&.active, &:hover': {
        border: `1px solid #E4875D`,
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.light,

        '& .radioBtnIcon': {
          background: theme.palette.bg.white,
          '& svg': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
    '& .otherTextInput': {
      fontWeight: 500,
      cursor: 'pointer',
      // marginLeft: '28%',
      color: theme.palette.primary.light,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& label': {
        textTransform: 'inherit',
        marginLeft: '0 !important',
        '&.active, &:hover': {
          border: 'none',
          backgroundColor: 'transparent',
          color: '#808080',
        },
        '& .MuiFormLabel-asterisk': {
          color: theme.palette.primary.light,
        },
      },
    },
    '& .viewMoreEventType': {
      fontWeight: 500,
      cursor: 'pointer',
      // marginLeft: '28%',
      color: theme.palette.primary.light,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& .otherEventTypeList': {
        display: 'flex',
        alignItems: 'center',
      },
      '&.viewLessEventType': {
        marginTop: '.8rem',
      },
    },
    '& .otherEventType': {
      transition: 'all 0.3s',
      '& .otherEventTypeList': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

        '& .otherEventTypeItem': {
          padding: '.5rem 1rem',
          border: '1px solid #DBDBDB',
          borderRadius: '4px',
          cursor: 'pointer',
          fontWeight: 500,
          color: '#36485A',
          marginBottom: theme.spacing(1.5),

          '&.active, &:hover': {
            background: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            color: '#fff',
          },

          '&:not(:last-child)': {
            marginRight: '.7rem',
          },
        },
      },
    },
    '& span + span': {
      fontSize: `${theme.fontSize.size2} !important`,
      fontWeight: 700,
      color: '#333132',
    },
  },

  eventDetails: {
    maxWidth: '516px',
    marginLeft: '4px',
    '& label': {
      border: '0',
      padding: `0`,
      left: '0',
      marginBottom: '0.5rem',
      borderRadius: '0',
      textTransform: 'none',
    },
    '& .eventDateTime': {
      '& .eventDatePicker': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        padding: ' 8px 10px',
      },
      '& .eventTimePicker': {
        '& div': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
        },
        '& input': {
          textAlign: 'center !important',
        },
        '& label': {
          width: '100%',
          '& .MuiInputLabel-asterisk': {
            visibility: 'hidden',
          },
        },
      },
      '& ul': {
        maxHeight: '135px',
      },
    },
    '& .create-event-year-select': {
      border: '0 !important',
      background: '#fff !important',
      fontSize: '14px',
      fontWeight: '500',
      paddingRight: '10px',
      paddingLeft: '20px',
      '&:hover': {
        background: theme.palette.primary.light,
        boxShadow: '0 !important',
        border: '0 !important',
        cursor: 'pointer',
      },
      '& .create-event-year-select-option': {
        backgroundColor: '#fff',
      },
    },
    '& .mapBlock': {
      overflow: 'hidden',
      '& .mapLocation': {
        overflow: 'hidden',
      },
    },
    '& .eventDetailsCountrySelect': {
      '& ul': {
        maxHeight: '200px',
        paddingBottom: '90px',
      },
    },
    '& .eventDetailsTimezoneSelect': {
      '& ul': {
        maxHeight: '140px',
      },
    },
  },
  eventCardActions: {
    '& button': {
      marginRight: '10px',
    },
  },
  mapLocation: {
    borderRadius: '0.5rem',
    marginTop: '1rem',
    overflow: 'hidden',
    '& img': {
      height: '100%',
      maxWidth: '100%',
    },
  },
}));

export const useStylesForLanguages = makeStyles((theme) => ({
  eventLanguagesScreen: {
    maxWidth: '516px',

    '& .langCode': {
      width: '32px',
      height: '32px',
      background: '#F6F8F8',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.4rem',
      borderRadius: '4px',

      '& + span': {
        '& span + span': {
          fontWeight: '500',
        },
      },
    },
    '& .upgradePlanCard': {
      border: `1px solid ${theme.palette.border.light}`,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .upgradePlanCardText': {
        paddingRight: theme.spacing(3),

        '& h6': {
          marginBottom: theme.spacing(1),
        },
      },
    },
    '& .langList': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: theme.spacing(2),
        '&:nth-child(odd)': {
          marginLeft: '-0.7rem',
        },
        '&:nth-child(even)': {
          marginLeft: theme.spacing(3),
        },
        /*  '&:nth-child(odd)': {
             marginRight: theme.spacing(2),
         }, */
      },

      '& label': {
        width: '246px',
        height: '72px',
        borderRadius: '8px',
        margin: 0,

        '&.active': {
          border: `1px solid ${theme.palette.primary.light}`,
          '&.activeDisabledEvent': {
            '& > span:first-child': {
              opacity: 0.5,
              color: theme.palette.primary.main,
            },
            '& .langCode': {
              color: theme.palette.text.primary,
            },
          },
        },
      },
      '&.webinarLangList': {
        '& label': {
          '& .langCode': {
            fontWeight: 500,
          },
          '& .langName': {
            fontWeight: 500,
          },
          '&.active': {
            border: `1px solid ${theme.palette.primary.main}`,
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.bg.whiteOff2}`,
            },
            '& > span:first-child': {
              opacity: 1,
              color: theme.palette.primary.main,
            },
            '& .langCode': {
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
    '& .baseLangWrapper': {
      position: 'fixed',
      bottom: '5.5rem',
      paddingBottom: '1rem',
      paddingTop: '1rem',
      background: theme.palette.bg.white,
      zIndex: 9,
      width: '518px',
      marginLeft: '2px',
      borderRadius: '8px',
      '& ul': {
        maxHeight: '75px',
      },
    },
    '& .baseLangInner': {
      background: '#F7F9FA',
      border: '1px solid #E5E5E5',
      borderRadius: '8px',
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .baseLang': {
        '& span': {
          fontWeight: 500,
        },
        '& svg': {
          //  marginLeft: theme.spacing(1.25),
          marginTop: '4px',
          '& path': {
            fill: '#444444',
          },
        },
        display: 'flex',
        alignItems: 'center',
      },
      '& .baseLangDropDown': {
        width: '145px',
        height: '36px',
        '& .bgWhite': {
          height: '36px',
        },
        '& ul': {
          padding: 0,
        },
        '& li': {
          padding: '12px',
          height: '36px',
          '& > div': {
            padding: 0,
            display: 'contents',
          },
          '& span': {
            width: '118px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
  },
}));
