/* eslint-disable  */
import amplitude from 'lib/amplitude';
import { convertUTCToTimezone, formatDateWithTimeZone } from 'lib/common-function';
import { TICKET_CONST } from '../pages/registration/ticket/common/constants';
import isEmpty from 'lodash/isEmpty';

const getLimitAccessType = (ticketFields) => {
  if (ticketFields.emails?.length && ticketFields.restricted_emails) {
    return 'both';
  }

  if (ticketFields.emails?.length) {
    return 'email';
  }

  if (ticketFields.restricted_emails) {
    return 'bulk upload';
  }

  return null;
};

const appendForwardSlash = (text) => {
  return `${text}${text ? '/' : ''}`;
};

const getSectionEdited = (emailChanges) => {
  let sectionEdited = emailChanges.isSubjectUpdated ? 'subject' : '';
  if (emailChanges.isBodyUpdated) {
    sectionEdited = `${appendForwardSlash(sectionEdited)}body`;
  }
  if (emailChanges.isCtaUpdated) {
    sectionEdited = `${appendForwardSlash(sectionEdited)}CTA`;
  }
  return sectionEdited;
};

// Ticket Creation
export const trackTicketCreationObj = (ticketId, ticketFields, activeStep, stripeDetails) => {
  const logEventName = activeStep === 0 ? 'add basic ticket details' : 'advance ticket details';
  let reqObj = {};
  if (activeStep === 0) {
    reqObj = {
      'ticket name': ticketFields.name,
      'ticket id': ticketId,
      'ticket type': ticketFields?.ticketType,
      quantity: ticketFields.quantity ? +ticketFields.quantity : null,
      currency: ticketFields?.currency_id?.name || null,
      'associated group': ticketFields.group.name,
      'ticket status':
        ticketFields.is_status_open === 1 ? TICKET_CONST.OPEN : TICKET_CONST.SOLD_OUT,
      'ticket visibility':
        ticketFields.is_visible === 1 ? TICKET_CONST.VISIBLE : TICKET_CONST.HIDDEN,
      'start date': formatDateWithTimeZone(ticketFields.start_date_time),
      'end date': formatDateWithTimeZone(ticketFields.end_date_time),
      'fee setting':
        ticketFields?.ticketType === TICKET_CONST.PAID
          ? ticketFields.ticket_fee_type_id === 1
            ? TICKET_CONST.ABSORB
            : TICKET_CONST.PASS_ON
          : null,
      price: parseFloat(ticketFields.price),
      'connect stripe': stripeDetails.isStripeConnected ? 'connected' : 'not connected',
    };
  } else if (activeStep === 1) {
    const applyDiscountNo = ticketFields.discount_id ? ticketFields.discount_id.length : null;
    reqObj = {
      'buy limit':
        ticketFields?.buy_limit_per_user === '' || ticketFields?.buy_limit_per_user === null
          ? null
          : +ticketFields.buy_limit_per_user,
      'limit access': ticketFields.is_limit_access_on === 1 ? 'Y' : 'N',
      'limit access type': getLimitAccessType(ticketFields),
      'apply discount': applyDiscountNo,
      'discount applicability':
        applyDiscountNo > 0
          ? ticketFields.discount_on_cumulative_or_individual === 0
            ? TICKET_CONST.CUMULATIVE
            : TICKET_CONST.INDIVIDUAL
          : null,
      'invite email': ticketFields.is_invite_email_on === 1 ? 'Y' : 'N',
      'ticket name': ticketFields.name,
      'ticket id': ticketId,
    };
  }

  amplitude.Track.v2TicketingTrack(logEventName, reqObj);
};

// Edit Ticket Details
export const editTicketCreationObj = (
  ticketId,
  ticketName,
  currencyDtls,
  groupDtls,
  diffObj,
  timeZone
) => {
  let ticketEditDtlsObj = {
    'ticket id': ticketId,
  };

  Object.entries(diffObj).forEach((eachItem) => {
    let obj = {};
    const key = eachItem[0];
    const value = eachItem[1];
    switch (key) {
      case 'name':
        obj = { 'ticket name': ticketName };
        break;
      case 'ticketType':
        obj = {
          'ticket type': value === 1 ? TICKET_CONST.FREE : TICKET_CONST.PAID,
          'fee setting':
            value === 0
              ? diffObj?.ticket_fee_type_id === 1
                ? TICKET_CONST.ABSORB
                : TICKET_CONST.PASS_ON
              : null,
        };
        break;

      case 'group':
        obj = {
          'associated group': value ? groupDtls?.peopleGroupName || groupDtls?.name : null,
        };
        break;
      case 'quantity':
        obj = { quantity: value };
        break;
      case 'price':
        obj = { price: value };
        break;
      case 'is_status_open':
        obj = { 'ticket status': value === 1 ? TICKET_CONST.OPEN : TICKET_CONST.SOLD_OUT };
        break;
      case 'is_visible':
        obj = { 'ticket visibility': value === 1 ? TICKET_CONST.VISIBLE : TICKET_CONST.HIDDEN };
        break;
      case 'start_date_time':
        obj = { 'start date': convertUTCToTimezone(value, timeZone).dateTime };
        break;
      case 'end_date_time':
        obj = { 'end date': convertUTCToTimezone(value, timeZone).dateTime };
        break;
      case 'is_limit_access_on':
        obj = { 'limit access': value === 1 ? 'Y' : 'N' };
        break;
      case 'buy_limit_per_user':
        obj = {
          'buy limit': value === '' || value === null ? null : +value,
        };
        break;
      case 'is_invite_email_on':
        obj = { 'invite email': value === 1 ? 'Y' : 'N' };
        break;
      default:
        obj = {};
    }

    ticketEditDtlsObj = { ...ticketEditDtlsObj, ...obj };
  });
  const applyDiscountNo = diffObj?.discount_id ? diffObj?.discount_id.length : null;

  ticketEditDtlsObj['limit access type'] = getLimitAccessType(diffObj);
  ticketEditDtlsObj['apply discount'] = applyDiscountNo;

  ticketEditDtlsObj['discount applicability'] = diffObj.hasOwnProperty(
    'discount_on_cumulative_or_individual'
  )
    ? diffObj?.discount_on_cumulative_or_individual === 0
      ? TICKET_CONST.CUMULATIVE
      : TICKET_CONST.INDIVIDUAL
    : null;

  ticketEditDtlsObj.currency =
    diffObj?.ticketType || diffObj?.currency_id ? currencyDtls?.name : '';

  const finalEditDtlsObj = JSON.parse(JSON.stringify(ticketEditDtlsObj));

  // Removed the property whose values were empty
  Object.keys(ticketEditDtlsObj).forEach((key) => {
    if (
      ticketEditDtlsObj[key] === undefined ||
      ticketEditDtlsObj[key] === '' ||
      ticketEditDtlsObj[key] === null
    )
      delete finalEditDtlsObj[key];
  });

  if (Object.keys(finalEditDtlsObj).length > 1) {
    amplitude.Track.v2TicketingTrack('edit details', finalEditDtlsObj);
  }

  const ticketEmailEditObj = {
    'ticket id': ticketId,
  };

  ticketEmailEditObj['ticket name'] = ticketName;
  ticketEmailEditObj['invite email'] = diffObj?.is_invite_email_on;
  if (diffObj?.email_subject || diffObj?.email_body || diffObj?.cta_label) {
    ticketEmailEditObj.type = 'invite';
    ticketEmailEditObj['section edited'] = getSectionEdited({
      isSubjectUpdated: !!diffObj.email_subject,
      isBodyUpdated: !!diffObj.email_body,
      isCtaUpdated: !!diffObj.cta_label,
    });
  }

  if (
    diffObj?.registrationEmailSubject ||
    diffObj?.registrationEmailBody ||
    diffObj?.registrationCtaLabel
  ) {
    ticketEmailEditObj['email type'] = 'registration';
    ticketEmailEditObj['email section edited'] = getSectionEdited({
      isSubjectUpdated: !!diffObj.registrationEmailSubject,
      isBodyUpdated: !!diffObj.registrationEmailBody,
      isCtaUpdated: !!diffObj.registrationCtaLabel,
    });
  }

  const finalEmailEditDtlsObj = JSON.parse(JSON.stringify(ticketEmailEditObj));
  // Removed the property whose values were empty
  Object.keys(ticketEmailEditObj).forEach((key) => {
    if (
      ticketEmailEditObj[key] === undefined ||
      ticketEmailEditObj[key] === '' ||
      ticketEmailEditObj[key] === null
    )
      delete finalEmailEditDtlsObj[key];
  });

  if (Object.keys(finalEmailEditDtlsObj).length > 2) {
    amplitude.Track.v2TicketingTrack('edit ticket email', finalEmailEditDtlsObj);
  }
};

// Ticket Delete
export const trackTicketDeleteObj = (ticketNameIdList = []) => {
  if (!isEmpty(ticketNameIdList)) {
    ticketNameIdList.forEach((eachItem) => {
      const reqObj = { 'ticket name': eachItem.name, 'ticket id': eachItem.id };
      amplitude.Track.v2TicketingTrack('delete ticket', reqObj);
    });
  }
};

// Ticket Archive
export const trackTicketArchiveObj = (ticketNameIdList = []) => {
  if (!isEmpty(ticketNameIdList)) {
    ticketNameIdList.forEach((eachItem) => {
      const reqObj = { 'ticket name': eachItem.name, 'ticket id': eachItem.id };
      amplitude.Track.v2TicketingTrack('archive ticket', reqObj);
    });
  }
};

// Ticket UnArchive
export const trackTicketUnarchiveObj = (ticketNameIdList = []) => {
  if (!isEmpty(ticketNameIdList)) {
    ticketNameIdList.forEach((eachItem) => {
      const reqObj = { 'ticket name': eachItem.name, 'ticket id': eachItem.id };
      amplitude.Track.v2TicketingTrack('unarchive ticket', reqObj);
    });
  }
};

// Order Refund
export const trackOrderRefundObj = (orderList = []) => {
  if (!isEmpty(orderList)) {
    orderList.forEach((eachItem) => {
      const reqObj = {
        '# of order': eachItem.orderId,
        'order amount': eachItem.orderAmount,
        'ticket name': eachItem.ticketName,
      };
      amplitude.Track.v2TicketingTrack('initiate refund', reqObj);
    });
  }
};

// Ticket Settings Buyer forms
export const trackTicketSettingsBuyerFormObj = (reqObj = {}, currencies = []) => {
  if (!reqObj) {
    return {};
  }

  const currencyName = currencies.find(
    (eachCurrency) => eachCurrency.id === reqObj.currencyId
  ).name;

  let countryName = null;
  if (reqObj?.taxSetting) {
    const countryName = reqObj?.countryAndState?.find(
      (eachCountry) => eachCountry?.id === reqObj.taxSettingProps.countryId
    ).name;
  }

  return {
    'buyer form': reqObj.buyerForm ? 'Y' : 'N',
    'buyer form fields': reqObj.buyerFormFields.length,
    'buyer fields': reqObj.buyerForm
      ? reqObj.buyerFormFields.map((eachItem) => eachItem.label)
      : null,
    currency: currencyName,
    'tax setting': reqObj.taxSetting ? 'Y' : 'N',
    country: countryName,
    'tax name': reqObj.taxSetting ? reqObj.taxSettingProps.taxName : '',
    'tax rate': reqObj.taxSetting ? reqObj.taxSettingProps.taxRate : '',
    'send tax invoice': reqObj.taxSetting && reqObj.taxSettingProps.sendTaxInvoice ? 'Y' : 'N',
    'buyer transaction': reqObj.buyerTransaction.toLowerCase(),
    'transactional email': reqObj.transactionalEmail ? 'Y' : 'N',
    'incomplete email': reqObj.incTransactionalEmail ? 'Y' : 'N',
  };
};

// Ticket Settings Edit
export const trackTicketSettingsEditObj = (reqObj = {}) => {
  let returnObj = {};

  Object.entries(reqObj).forEach((eachItem) => {
    let obj = {};
    const key = eachItem[0];
    const value = eachItem[1];
    switch (key) {
      case 'transactionalEmail':
      case 'incTransactionalEmail':
        {
          let keyName = key === 'incTransactionalEmail' ? 'inc ' : '';
          keyName += 'transactional email';
          obj = { [keyName]: value ? 'Y' : 'N' };
        }
        break;
      case 'incTransactionalEmailProps':
      case 'transactionalEmailProps':
        {
          const isIncompleteTransactionField = key === 'incTransactionalEmailProps';
          let type = isIncompleteTransactionField ? 'incomplete ' : '';
          type += 'transactional';
          obj = {
            type,
            'section edited': getSectionEdited({
              isSubjectUpdated: !!value?.emailSubject,
              isBodyUpdated: !!value?.emailBody,
              isCtaUpdated: isIncompleteTransactionField ? !!value?.ctaLabel : false,
            }),
          };
        }
        break;
      default:
        break;
    }

    returnObj = { ...returnObj, ...obj };
  });

  return returnObj;
};

export const addProfileFieldsObj = (
  ProfileFieldsProperties = {},
  communityGroups = [],
  ticketGroupList = [],
  ticketList = []
) => {
  if (ProfileFieldsProperties) {
    let tempReq = [];
    ProfileFieldsProperties.map((item) => {
      const reqObj = {
        name: item.fieldName,
        type: item.name,
        'mandatory in ticket': item.isRequiredInTicketing,
        'ticket groups': item.ticketingGroups
          ? item.ticketingGroups === 'All'
            ? ticketGroupList.map((eachGroup) => eachGroup.name)
            : item.ticketingGroups.map((eachGroup) => eachGroup.name)
          : [],
        'mandatory in attendee platform': item.isRequired,
        'attendee platform groups': item.communityGroups
          ? item.communityGroups === 'All'
            ? communityGroups.map((eachGroup) => eachGroup.name)
            : item.communityGroups.map((eachGroup) => eachGroup.name)
          : [],
        'display in user onboarding': item.isUseInOnoarding,
        'display in user profile': item.isShowToOthers,
        'display in registration form': item.isUseInTicketing,
        'tickets tagged': item.ticketsMappedWithGroups
          ? item.ticketsMappedWithGroups === 'All'
            ? ticketList.map((eachTicket) => eachTicket.name)
            : item.ticketsMappedWithGroups.map(
                (eachGroup) => eachGroup.name
              )
          : [],
      };
      tempReq = [...tempReq, reqObj]
    })
    amplitude.Track.v2TicketingTrack('add profile field', tempReq);
  }
};

export const trackAddDiscountObj = (resData, bodyData, timeZone) => {
  const reqObj = {
    source: bodyData.source,
    'discount id': resData,
    'discount name': bodyData.name,
    'discount type': bodyData.type?.toLowerCase(),
    'discount amount': bodyData.discount.type,
    rate: bodyData.discount.value,
    usage: bodyData.usage?.toLowerCase(),
    'start date': convertUTCToTimezone(bodyData.startDateTimeMilli, timeZone).dateTime,
    'end date': convertUTCToTimezone(bodyData.endDateTimeMilli, timeZone).dateTime,
  };

  if (bodyData?.type === 'GROUP') {
    reqObj['group logic'] =
      bodyData?.groupLogic === 'GREATER_THAN'
        ? 'greater than'
        : bodyData?.groupLogic === 'NO_OF_TICKETS'
        ? 'no of tickets'
        : bodyData?.groupLogic?.toLowerCase();
  }

  if (bodyData?.usage === 'LIMITED') {
    reqObj['usage limit'] = bodyData?.usagePerTicket;
  }

  amplitude.Track.v2TicketingTrack(bodyData.eventName, reqObj);
};

export const trackAppliedTicketsForDiscount = (bodyData, ticketList = []) => {
  const reqObj = {
    'ticket id': bodyData.discountApplicableTo
      ? bodyData.discountApplicableTo === 'ALL'
        ? ticketList.map((eachTicket) => eachTicket.id)
        : bodyData.discountApplicableTo.map((res) => res.id)
      : [],
    'ticket name': bodyData.discountApplicableTo
      ? bodyData.discountApplicableTo === 'ALL'
        ? ticketList.map((eachTicket) => eachTicket.name)
        : bodyData.discountApplicableTo.map((res) => res.name)
      : [],
    'discount id': bodyData?.discountId,
    'discount name': bodyData?.discountName,
  };
  amplitude.Track.v2TicketingTrack('applicable ticket', reqObj);
};

export const deleteDiscountTrack = (bodyData) => {
  const reqObj = {
    'discount id': bodyData?.discountId,
    'discount name': bodyData?.discountName,
  };
  amplitude.Track.v2TicketingTrack('delete discount', reqObj);
};

// Archive Discount
export const archiveDiscountTrack = (bodyData) => {
  const reqObj = {
    'discount id': bodyData?.discountId,
    'discount name': bodyData?.discountName,
  };
  amplitude.Track.v2TicketingTrack('archive discount', reqObj);
};

// Edit Discount
export const editDiscount = (resObj) => {
  amplitude.Track.v2TicketingTrack('edit discount', resObj);
};
