import React from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import SwitchField from 'custom-components/form-fields/switch';
import { useDispatch, useSelector } from 'react-redux';
import { roomReactionUpdate } from 'redux/actions/room-reaction-enable-action';

import { updateSessionEngagementReactions } from 'redux/api-service/embed-apps';

import { useRouteMatch } from 'react-router-dom';

import { useStyles } from './styles';
import { sessionEngagementSelector } from 'redux/selectors';

const ReactionEnabled = ({
    desc = '',
    className = '',
    context,
    isRoomReactionsEnabled,
    isEmojiRoomReactionsLoading,
    setAttendeeReaction,
}) => {
    const classes = useStyles();
    const { isReactionsEnabled, isEmojiReactionsLoading } = useSelector(sessionEngagementSelector);

    const dispatch = useDispatch();
    const match = useRouteMatch();

    const handleReactionEnabled = (e) => {
        const { checked } = e.target;

        if (context === 'room') {
            dispatch(roomReactionUpdate(checked));
            setAttendeeReaction(checked);
        } else if (context === 'lounge') {
            setAttendeeReaction(checked);
        } else {
            const dataValue = {
                data: {
                    is_att_rxn: checked ? 1 : 0,
                    is_global_eng: 1,
                },
            };
            dispatch(
                updateSessionEngagementReactions(dataValue, {
                    eventid: match.params.eventId,
                    type_id: match.params.sessionId,
                })
            );
        }
    };

    return (
        <Box className={`reactionEnabledWrapper ${className}`}>
            <Box className={classes.divider} mt={1.5}>
                <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box width="90%">
                    <Box className={classes.reactionEnabled}>
                        <Typography variant="body2" component="p" color="textPrimary" className={classes.bold}>
                            Emoji Reactions
                        </Typography>
                        <Typography variant="caption" component="p" color="textSecondary">
                            {desc}
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.switchBtnStyle} mt={3} mr={2}>
                    <SwitchField
                        name="isReactionsEnabled"
                        disabled={isEmojiRoomReactionsLoading || isEmojiReactionsLoading}
                        checked={isRoomReactionsEnabled || isReactionsEnabled === 1}
                        onChange={handleReactionEnabled}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default ReactionEnabled;
