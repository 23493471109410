import React from 'react';

export default function SvgIphoneMock() {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="646"
      viewBox="0 0 321 646">
      <rect
        x="4.1748"
        y="1"
        width="312.98"
        height="643.856"
        rx="49"
        stroke="#DBDBDB"
        stroke-width="2"
      />
      <rect
        width="294.745"
        height="30.5314"
        transform="matrix(1 0 0 -1 12.874 587.142)"
        fill="white"
      />
      <path
        d="M0.24707 104.484C0.24707 103.379 1.1425 102.484 2.24707 102.484H3.17517V126.745H2.24707C1.1425 126.745 0.24707 125.85 0.24707 124.745V104.484Z"
        fill="#DBDBDB"
      />
      <path
        d="M1.08398 150.915C1.08398 149.81 1.97941 148.915 3.08398 148.915H3.17549V197.438H3.08398C1.97941 197.438 1.08398 196.543 1.08398 195.438V150.915Z"
        fill="#DBDBDB"
      />
      <path
        d="M1.08398 212.405C1.08398 211.301 1.97941 210.405 3.08398 210.405H3.17549V258.928H3.08398C1.97941 258.928 1.08398 258.033 1.08398 256.928V212.405Z"
        fill="#DBDBDB"
      />
      <path
        d="M320.247 240.196C320.247 241.301 319.352 242.196 318.247 242.196L318.155 242.196L318.155 165.229L318.247 165.229C319.352 165.229 320.247 166.124 320.247 167.229L320.247 240.196Z"
        fill="#DBDBDB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.94434 54.6973C4.94434 27.083 27.3301 4.69727 54.9443 4.69727H266.535C294.149 4.69727 316.535 27.083 316.535 54.6973V590.096C316.535 617.71 294.149 640.096 266.535 640.096H54.9444C27.3302 640.096 4.94434 617.71 4.94434 590.096V54.6973ZM15.2167 54.736C15.2167 32.6446 33.1253 14.736 55.2167 14.736H266.263C288.354 14.736 306.263 32.6446 306.263 54.736V589.638C306.263 611.729 288.354 629.638 266.263 629.638H55.2167C33.1253 629.638 15.2167 611.729 15.2167 589.638V54.736ZM83.9072 15.4879V19.6603C83.9072 17.3501 82.0344 15.4773 79.7242 15.4773H83.9072H236.587C236.587 28.1834 226.287 38.4838 213.58 38.4838H106.914C94.2111 38.4838 83.9129 28.1892 83.9072 15.4879ZM236.587 19.65V15.4773H240.77C238.463 15.4773 236.593 17.3445 236.587 19.65Z"
        fill="white"
      />
      <rect x="142.888" y="20.915" width="35.5555" height="5.85621" rx="2.9281" fill="#E4E8EF" />
      <ellipse cx="192.247" cy="23.8431" rx="2.9281" ry="2.9281" fill="#E4E8EF" />
    </svg>
  );
}
