/* eslint-disable  */
import React from 'react';
import PropTypes from 'prop-types';

export default function CanShow(props) {
  if (props.canShow) {
    return <>{props.children}</>;
  }
  return null;
}

CanShow.propTypes = {
  canShow: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};
