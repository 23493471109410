import React from 'react';

export default function SvgPortugueseFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 10C0 14.2996 2.71375 17.965 6.52168 19.378L7.39129 9.99996L6.52168 0.621994C2.71375 2.03496 0 5.70035 0 10Z"
        fill="#6DA544"
      />
      <path
        d="M20 10C20 4.47719 15.5229 0 10 0C8.77685 0 7.60509 0.220039 6.52173 0.621992V19.378C7.60509 19.78 8.77685 20 10 20C15.5229 20 20 15.5228 20 10Z"
        fill="#D80027"
      />
      <path
        d="M6.5217 13.4782C8.44268 13.4782 9.99994 11.921 9.99994 9.99999C9.99994 8.07901 8.44268 6.52174 6.5217 6.52174C4.60072 6.52174 3.04346 8.07901 3.04346 9.99999C3.04346 11.921 4.60072 13.4782 6.5217 13.4782Z"
        fill="#FFDA44"
      />
      <path
        d="M4.56519 8.26083V10.4347C4.56519 11.5153 5.44112 12.3913 6.52171 12.3913C7.60229 12.3913 8.47823 11.5153 8.47823 10.4347V8.26083H4.56519Z"
        fill="#D80027"
      />
      <path
        d="M6.52182 11.087C6.16221 11.087 5.86963 10.7944 5.86963 10.4348V9.56523H7.17396V10.4348C7.17396 10.7944 6.88139 11.087 6.52182 11.087Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
