/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import CustomModal from 'custom-components/customModal';
import { Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import BtnWithLoader from 'custom-components/btnWithLoader';
import LoaderSvg from './preloader';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import InputField from './form-fields/inputField';

const useStyles = makeStyles((theme) => ({
  deleteModalActionWrap: {
    '& .MuiButtonBase-root + .MuiButtonBase-root': {
      marginLeft: theme.spacing(2.5),
    },
  },
  svgIcon: {
    width: 100,
    height: 100,
    color: theme.palette.text.grayColor1,
  },
  iframeModel: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 111111,
    backgroundColor: 'rgba(0,0,0,.70)',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '5%',

    '&.iframeOff': {
      display: 'none',
    },
    '&.iframeOn': {
      display: 'flex',
    },
    '& .crossBtn': {
      position: 'relative',
      top: '-35px',
      left: '-10px',
      '& .MuiIconButton-root': {
        color: '#ffffff',
      },
    },
    '& .iframe': {
      backgroundColor: '#ffffff',
    },
  },
  primaryText: {
    color: `${theme.palette.text.primary} !important`,
    marginTop: theme.spacing(2),
  },
  headerText: {
    color: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&>h2': {
      fontWeight: 500,
      fontSize: 20,
    },
  },
  boldText: {
    fontWeight: 500,
  },
  w100: { width: '100%', maxWidth: '500px' },
}));

const ConfirmationPopUp = ({
  isScrollModal = false,
  isOpen,
  onClose,
  titleColor,
  overWriteClass = false,
  data = {},
  minWidth,
  maxWidth,
  disabled,
  paddingBox,
  paddingZero,
  showCloseIcon,
  className,
  customIconSize,
  modalSize = 'ModalSize',
  svgClassName,
  overlayClassName,
  popUpDescriptionClass,
  ...restProps
}) => {
  const { deleteModalActionWrap, svgIcon, primaryText, headerText, boldText, w100 } = useStyles();
  const {
    actionBtn,
    title,
    primaryDesc,
    secondaryDesc,
    Icon,
    mainHeading,
    overWitePrimaryClass,
    primaryDescSubText,
    onConfirmChange,
    type,
    confLabel,
    btnMtTop,
    learnMore,
  } = data;

  const [confText, setConfText] = useState('');
  const CONFIRM = 'CONFIRM';

   useEffect(() => {
     if (restProps?.initialCallback) {
       restProps?.initialCallback();
     }
   }, []);

  const onConfTextChange = (e) => {
    e.stopPropagation();
    if (e.target.value.length > 7) {
      return false;
    }
    setConfText(e.target.value);
    onConfirmChange(e.target.value === CONFIRM);
  };

  const getMainHeading = () => {
    return mainHeading ? (
      <Box fontWeight="700" pb={3} className={headerText}>
        <Typography component="h2" variant="body1">
          {mainHeading}
        </Typography>
      </Box>
    ) : (
      ''
    );
  };

  const getIcon = () => {
    return Icon ? (
      <Box display="flex" justifyContent={type === CONFIRM ? 'right' : 'center'}>
        <Icon className={`${customIconSize} ${overWriteClass ? '' : svgIcon} ${svgClassName}`} />
      </Box>
    ) : (
      ''
    );
  };

  const getTitle = () => {
    const mt = type === CONFIRM ? 0 : 3;
    return title ? (
      <Box mt={Icon ? mt : 0} color="text.primary" className="titleOFPopUp">
        <Typography color="textPrimary" variant="h5" component="h3">
          {titleColor ? <Box color={titleColor}> {title} </Box> : title}
        </Typography>
      </Box>
    ) : (
      ''
    );
  };

  const getPrimaryDesc = () => {
    return primaryDesc ? (
      <Box mt={type === CONFIRM ? 1 : 0.7} color="text.primary">
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          className={`${overWitePrimaryClass ? primaryText : ''}`}>
          {primaryDesc}{' '}
          {primaryDescSubText ? (
            <Typography component="span" className={boldText}>
              {primaryDescSubText}
            </Typography>
          ) : (
            ''
          )}
          {learnMore}
        </Typography>
      </Box>
    ) : (
      ''
    );
  };

  const getSecondaryDesc = () => {
    return secondaryDesc ? (
      <Box mt={1.5}>
        <Typography
          variant="body2"
          component="p"
          className={`${popUpDescriptionClass} secondaryDescText`}
          color="textSecondary">
          {secondaryDesc}
        </Typography>
      </Box>
    ) : (
      ''
    );
  };

  const getActionBtn = () => {
    return actionBtn && actionBtn.length ? (
      <Box
        className={deleteModalActionWrap}
        mt={restProps.btnMarginTop || btnMtTop || 7.5}
        display="flex"
        justifyContent={type === CONFIRM ? 'left' : 'center'}>
        {actionBtn.map((eachButton) => {
          return (
            <BtnWithLoader
              onClick={(e) => {
                eachButton.onClick(e);
                setConfText('');
              }}
              disabled={eachButton.isLoading || eachButton.disabled || disabled}
              isLoading={eachButton.isLoading}
              loadingLabel={eachButton.loadingLabel}
              label={eachButton.name}
              variant={eachButton.type === 'primary' ? 'contained' : 'outlined'}
              // className={`uppercase`}
              size="small"
              color="primary"
              key={eachButton.name}
              {...eachButton.restOptions}
            />
          );
        })}
      </Box>
    ) : (
      ''
    );
  };

  return (
    <CustomModal
      isScrollModal={isScrollModal}
      isOpen={isOpen}
      overlayClassName={overlayClassName}
      showCloseIcon={showCloseIcon}
      onClose={(e) => {
        setConfText('');
        onClose(e);
      }}
      modalSize={modalSize}
      paddingZero={paddingZero}>
      {getMainHeading()}
      {type === CONFIRM ? (
        <Box className={w100} display="flex" alignItems="center" justifyContent="center">
          <Box mr={2}>
            {getTitle()}
            {getPrimaryDesc()}
            <Box mt={2.5} color="text.grayColor">
              <Typography variant="labelM" component="label" color="textSecondary">
                {confLabel}
              </Typography>
              <InputField
                value={confText}
                onChange={onConfTextChange}
                error={disabled}
                placeholder="Type 'CONFIRM'"
                type="text"
                size="small"
              />
            </Box>
            {restProps.children}
            {getActionBtn()}
          </Box>
          <Box ml={1.5} mr={1.5}>
            {getIcon()}
          </Box>
        </Box>
      ) : (
        <Box
          textAlign="center"
          p={paddingBox ? paddingBox : 4}
          maxWidth={maxWidth ? maxWidth : 500}
          minWidth={minWidth ? minWidth : 'auto'}
          ml="auto"
          mr="auto"
          className={className}>
          {getIcon()}
          {getTitle()}
          {getPrimaryDesc()}
          {getSecondaryDesc()}
          {restProps.children}
          {getActionBtn()}
        </Box>
      )}
    </CustomModal>
  );
};

export default ConfirmationPopUp;

export function IFramePopup({
  isOpen,
  onClose,
  titleColor,
  overWriteClass = false,
  data = {},
  ...restProps
}) {
  const { iframeModel } = useStyles();
  const [isLoad, setLoader] = React.useState(true);

  const onFrameLoad = () => {
    if (restProps.url !== '') {
      setLoader(false);
    }
  };

  const onCloseModal = () => {
    onClose();
    setLoader(true);
  };

  return (
    <Box className={`${iframeModel} ${!isOpen ? 'iframeOff' : 'iframeOn'}`}>
      {isLoad ? (
        <span className="video-loader">
          <LoaderSvg className="p-c" width="45px" height="45px" />
        </span>
      ) : (
        false
      )}
      <iframe
        id="charge-bee-iframe"
        className="iframe"
        onLoad={() => {
          onFrameLoad();
        }}
        src={restProps.url}
        frameBorder={0}
        allowFullScreen
        scrolling="auto"
        width={450}
        height={550}
      />
      {isLoad || restProps.disableClose ? (
        false
      ) : (
        <Box className="crossBtn">
          <IconButton
            onClick={() => {
              onCloseModal();
            }}>
            <CloseRoundedIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
