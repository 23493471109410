import { GET_STRIPE_SETTING } from 'api-setup/api-endpoints';
import { API, GETAPIWITHCUSTOMDOMAIN, API_CALL_RESP_CODES } from 'api-setup/api-helper';
import { setNewMessage } from 'redux/actions/setting-actions';
import ProjectSetting from 'settings';

const CUSTOM_URL = ProjectSetting.customBaseURL;

export const SET_STRIPE_DETAILS = 'SET_STRIPE_DETAILS';

const stripeDetailsInitState = {
  isStripeConnected: false,
  stripeUrl: '',
  stripeEmail: '',
  stripeUserName: '',
};

export const setStripeDetails = (striptDetails) => {
  return {
    type: SET_STRIPE_DETAILS,
    data: striptDetails,
  };
};

export const getStripeDetails = (source, reduxData, dispatch) => {
  const [headers] = API.generateHeader(reduxData, null);
  GETAPIWITHCUSTOMDOMAIN(false, GET_STRIPE_SETTING, source.AS, headers, null, CUSTOM_URL)
    .then((resp) => {
      const { status, message, data } = resp.data;
      if (status === API_CALL_RESP_CODES.OK) {
        if ((data?.stripeUrl && Object.keys(data).length === 1) || !data?.stripe_user_auth) {
          dispatch(
            setStripeDetails({
              ...stripeDetailsInitState,
              isStripeConnected: false,
              stripeUrl: data.stripeUrl,
            })
          );
        } else {
          dispatch(
            setStripeDetails({
              ...stripeDetailsInitState,
              isStripeConnected: true,
              stripeUrl: data.stripeUrl,
              stripeEmail: data.stripe_email,
              stripeUserName: data.stripe_user_name,
              stripe_per_ticket: data.stripe_per_ticket,
              pass_stripe: data.pass_stripe,
              absorb_stripe: data.absorb_stripe,
            })
          );
        }
        if (message) dispatch(setNewMessage({ message, type: 'success', show: true }));
      } else {
        if (message) dispatch(setNewMessage({ message, type: 'error', show: true }));
      }
    })
    .catch(API.catchHandler);
};
