import React from 'react';
import moment from 'moment-timezone';
import { Fade, Paper, Box, Typography, Button, Divider } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { userInfoSelector } from 'redux/custom-selector';
import CircularProgress from '../../../custom-components/circularProgress';
import SvgPeopleProgress from '../../../icons/svgPeopleProgressIcon';
import SvgCalendarProgressIcon from '../../../icons/svgCalendarProgressIcon';
import amplitude from 'lib/amplitude';
import { useStyles } from './style';
import { ShowPlanIcon } from './showPlanIcon';
import { colors } from 'constants/colors';
import { PricingPlan } from 'constants/pricingPlan';
import { formatDate, planExpireTypeDisplay } from 'lib/common-function';
import { NewPlanDetailAlertList } from './newPlanDetailAlertList';

export const PlanDetailMenu = ({ isPlanMenuOpen, setPlanMenuOption, subscriptionStatus }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);

  const { planDropdown } = useStyles();
  const planExpiryDate = new Date(Number(UserInfo.subscription_end_date_milli));
  const currentTime = new Date(Number(UserInfo.currentServerDateTime));
  const daysLeft = Math.floor((planExpiryDate - currentTime) / 86400000) + 1;
  const planExpiry = moment(planExpiryDate).format('D MMM, YYYY');

  return (
    <Fade in={isPlanMenuOpen}>
      <Box
        position="absolute"
        className={`${planDropdown} hasTip`}
        width={380}
        right="-20%"
        zIndex={5}
        mt={3}
        mb={1.2}>
        <Paper elevation={2}>
          <Box className="dropDownBody">
            <Box className="choosePlan" display="flex" alignItems="center">
              <Box width="100%">
                {!UserInfo?.is_att_log_pricing ? (
                  <>
                    {subscriptionStatus !== undefined ? (
                      <>
                        <Box
                          className="currentPlan"
                          display="flex"
                          mb={1}
                          p={2}
                          alignItems="center">
                          {UserInfo.planName !== PricingPlan.FREE && <ShowPlanIcon />}
                          <Box>
                            <Typography
                              variant="h6"
                              component="h6"
                              display="block"
                              className="text-left">
                              {planExpireTypeDisplay(UserInfo)}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="span"
                              color={colors.textSecondary}>
                              Plan {UserInfo.planName === PricingPlan.FREE ? 'valid' : 'renews'}{' '}
                              till {planExpiry}
                            </Typography>
                          </Box>
                        </Box>
                        {UserInfo.planName !== PricingPlan.FREE ? (
                          false
                        ) : (
                          <Box className="leftDataPlan" display="flex">
                            <Box mr={2}>
                              <CircularProgress
                                icon={<SvgCalendarProgressIcon />}
                                value={((7 - daysLeft) / 7) * 100}
                                size={50}
                              />
                            </Box>
                            <Box>
                              <Typography variant="h6" component="h6" display="block">
                                {' '}
                                {daysLeft} Days left{' '}
                              </Typography>
                            </Box>
                          </Box>
                        )}

                        <Box className="leftDataPlan" display="flex">
                          <Box mr={2}>
                            <CircularProgress
                              icon={<SvgPeopleProgress />}
                              value={
                                UserInfo.peoplesCreditsUsagePercentage > '100'
                                  ? '100'
                                  : UserInfo.peoplesCreditsUsagePercentage
                              }
                              size={50}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              component="h6"
                              display="block"
                              className="text-left">
                              {UserInfo.total_attendee_credits === 0
                                ? UserInfo.used_attendee_credits
                                : `${UserInfo.used_attendee_credits}/${UserInfo.total_attendee_credits}`}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="span"
                              color={colors.textSecondary}>
                              Registrations
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="leftDataPlan" display="flex">
                          <Box mr={2}>
                            <CircularProgress
                              icon={<SvgCalendarProgressIcon height="20px" width="20px" />}
                              value={
                                UserInfo.concurrentSessionsUsagePercentage > '100'
                                  ? '100'
                                  : UserInfo.concurrentSessionsUsagePercentage
                              }
                              size={50}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              component="h6"
                              display="block"
                              className="text-left">
                              {' '}
                              {UserInfo.totalConcurrentSessionCredit === 0
                                ? UserInfo.usedConcurrentSessionCredit
                                : `${UserInfo.usedConcurrentSessionCredit}/${UserInfo.totalConcurrentSessionCredit}`}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="span"
                              color={colors.textSecondary}>
                              Parallel Sessions/Event
                            </Typography>
                          </Box>
                        </Box>

                        <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                          <Box mb={1}>
                            <Typography
                              variant="body2"
                              component="label"
                              color={colors.textSecondary}>
                              {UserInfo.planName === PricingPlan.FREE
                                ? 'Upgrade to Premium Plans'
                                : false}
                            </Typography>
                          </Box>
                          {UserInfo.planName === PricingPlan.ENTERPRISE ||
                          (UserInfo.planName === PricingPlan.ENTERPRISE &&
                            match.path === '/plans') ? (
                            false
                          ) : (
                            <Button
                              onClick={() => {
                                history.push('/plans');
                                amplitude.Track.upgradeClicked('popup');
                                setPlanMenuOption(false);
                              }}
                              className="uppercase"
                              variant="contained"
                              color={colors.primary}
                              size="small">
                              Upgrade
                            </Button>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Box className="leftDataPlan" display="flex">
                        <Box mr={2} pt={1} alignSelf="flex-start">
                          <CircularProgress
                            icon={<SvgPeopleProgress />}
                            value={
                              UserInfo.total_attendee_credits > 0
                                ? UserInfo.peoplesCreditsUsagePercentage > '100'
                                  ? '100'
                                  : UserInfo.peoplesCreditsUsagePercentage
                                : '0'
                            }
                            size={50}
                          />
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            component="h6"
                            display="block"
                            className="text-left">
                            {UserInfo.total_attendee_credits === 0
                              ? UserInfo.used_attendee_credits
                              : `${UserInfo.used_attendee_credits}/${UserInfo.total_attendee_credits}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            component="span"
                            color={colors.textSecondary}>
                            Registrations used
                          </Typography>
                          {UserInfo.price_per_attendee_credit === 0 ||
                          UserInfo.total_attendee_credits === 0 ? (
                            false
                          ) : (
                            <Typography
                              variant="caption"
                              component="span"
                              display="block"
                              className="text-left">
                              {UserInfo.currencySymbol}
                              {UserInfo.price_per_attendee_credit}
                              <span>/Additional Registrations</span>
                            </Typography>
                          )}
                          <Box mb={1} mt={2}>
                            <Divider />
                          </Box>
                          <Box mt={1}>
                            <Typography variant="body2" component="h6">
                              Contract expires on{' '}
                              {formatDate(
                                parseInt(UserInfo.subscription_end_date_milli),
                                'MMMM DD,  YYYY'
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {isPlanMenuOpen ? (
                      <NewPlanDetailAlertList isPlanMenuOpen={isPlanMenuOpen} />
                    ) : null}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};
