/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Typography, Grid, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Recaptcha from 'react-invisible-recaptcha';
import InputField from '../../custom-components/form-fields/inputField';
import SvgMailIcon from '../../icons/svgMail';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import validateFunctions from '../../models/validation';
import { ForgotPasswordSchema } from '../../models/schema';
import projectSetting from 'settings.js';
import { FORGOT_PASSWORD } from 'api-setup/api-endpoints.js';
import { API, POSTAPI } from 'api-setup/api-helper';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { useDispatch } from 'react-redux';
import { Mail } from 'react-feather';

// handle change of inputs in formFields
const handleChange = (e, formFields, setFormFields, errorObj, setErrorObj) => {
  const { name, value } = e.target;
  if (errorObj[name]) setErrorObj({ ...errorObj, [name]: '' });
  setFormFields({ ...formFields, [name]: value });
};

// Check for validation of form when login button clicked
function handleValidations(formFields) {
  const { email } = formFields;
  const data = { email: email.trim() };
  const errorObj = validateFunctions.validate(ForgotPasswordSchema, data);
  if (errorObj) {
    return errorObj;
  } else {
    return false;
  }
}

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));
export default function ForgotPassword() {
  const { iconColor } = useStyles();
  const [isEmailSentForVerification, setIsEmailSentForVerification] = useState(false);
  const isEmailVerified = false;
  const [formFields, setFormFields] = useState({
    email: '',
  });
  const [errorObj, setErrorObj] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (captchaToken) {
      const data = {
        ...formFields,
        captchaToken,
      };
      makeAPIcall(
        { data },
        recaptchaRef,
        history,
        setIsEmailSentForVerification,
        setIsLoading,
        dispatch
      );
    }
  }, [captchaToken]);

  const handleSubmit = (e, formField, setErrorObject, reCaptchaRef) => {
    e.preventDefault();
    const errorObject = handleValidations(formField);
    if (!errorObject) {
      setErrorObject({});
      if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (reCaptchaRef && reCaptchaRef.current) {
          reCaptchaRef.current.execute();
        }
      } else {
        const data = {
          ...formField,
          captchaToken,
        };
        makeAPIcall(
          { data },
          reCaptchaRef,
          history,
          setIsEmailSentForVerification,
          setIsLoading,
          dispatch
        );
      }
    } else {
      setErrorObject(errorObject);
    }
  };

  // login API call
  const makeAPIcall = (
    data,
    reCaptchaRef,
    PropHistory,
    PropSetIsEmailSentForVerification,
    propSetIsLoading,
    PropDispatch
  ) => {
    propSetIsLoading(true);
    POSTAPI(false, FORGOT_PASSWORD, null, null, data)
      .then((res) => {
        if (res.data.status === API.apiSuccessStatus) {
          PropSetIsEmailSentForVerification(true);
        } else {
          PropSetIsEmailSentForVerification(false);
          API.errStatusHandler(res, PropHistory, PropDispatch, () => {
            if (
              process.env.REACT_APP_ENV_TYPE === 'stg' ||
              process.env.REACT_APP_ENV_TYPE === 'prod'
            ) {
              if (reCaptchaRef && reCaptchaRef.current) reCaptchaRef.current.reset();
            }
          });
        }
        propSetIsLoading(false);
      })
      .catch((err) => {
        propSetIsLoading(false);
        PropSetIsEmailSentForVerification(false);
        API.catchHandler(err, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'stg' ||
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            if (reCaptchaRef && reCaptchaRef.current) reCaptchaRef.current.reset();
          }
        });
      });
  };

  return (
    <>
      {isEmailSentForVerification && !isEmailVerified ? (
        <Box textAlign="center">
          <Box>
            <SvgMailIcon height="50px" width="50px" />
          </Box>
          <Box mt={3.75} color="text.grayColor">
            <Typography variant="caption" component="p">
              We just sent an email to{' '}
              <Box component="span" color="text.primary">
                {formFields.email}
              </Box>
              . Go check your mailbox, and click in the email to log in instantly to your account.
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box mb={10} display="flex" justifyContent="center">
            <HubiloLogo height="70px" width="140px" />
          </Box>
          <form onSubmit={(e) => handleSubmit(e, formFields, setErrorObj, recaptchaRef)}>
            <Box mt={3.2}>
              <Box mb={2.5} color="text.grayColor" textAlign="center">
                <Typography variant="caption" component="p">
                  {!isEmailVerified
                    ? "Don't worry! Resetting your password is easy. Just type your registered email."
                    : 'Please create your password'}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {!isEmailVerified ? (
                  <Grid item xs={12}>
                    <InputField
                      disabled={isLoading}
                      required
                      placeholder="Email"
                      type="email"
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<Mail className={iconColor} />}
                      error={errorObj.email}
                      name="email"
                      value={formFields.email}
                      onChange={(e) => {
                        handleChange(e, formFields, setFormFields, errorObj, setErrorObj);
                      }}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Box mt={5}>
                {!isEmailVerified ? (
                  <BtnWithLoader
                    className="uppercase"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                    isLoading={isLoading}
                    loadingLabel="Requesting"
                    label="SEND"
                    fullWidth
                  />
                ) : (
                  <Button className="uppercase" fullWidth color="primary" variant="contained">
                    Reset Password
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </>
      )}
      <Box mt={5} textAlign="center" color="text.grayColor">
        <Typography variant="caption" component="p">
          Remembered your password?{' '}
          <Link to="/" className="text-uppercase">
            <Box component="span" color="primary.main" fontWeight={600}>
              Try Logging in
            </Box>
          </Link>
        </Typography>
      </Box>
      {process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod' ? (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={projectSetting.recaptchaSiteKey}
          onResolved={(propCaptchaToken) => {
            setCaptchaToken(propCaptchaToken);
          }}
          onError={() => {
            // TODO : error console removed
          }}
          onExpired={() => {
            // TODO : error console removed
          }}
        />
      ) : (
        ''
      )}
    </>
  );
}
