/* eslint-disable no-param-reassign */
/* eslint-disable no-async-promise-executor */
import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, Divider, IconButton } from '@material-ui/core';
import SwitchField from 'custom-components/form-fields/switch';
import SvgCrossIcon from 'icons/svgCrossIcon';
import CookieIcon from 'icons/svgCookieIcon';
import {
  classification,
  dashboardCookies,
  mandatoryPref,
  LOCAL_STORAGE_KEY,
} from 'constants/cookieConsent';
import { cookieConsentSelector } from 'redux/custom-selector';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { cookieConsentToggle, storagePrefToggle } from 'redux/actions/cookie-consent-action';
import { getCookieRecordAsync, saveCookieRecordAsync } from 'redux/api-service/cookie-consent';
import { useStyles } from './styles';
import { StoragePreferences } from './storagePrefModal';
import {
  dataMapperForSaveCookieConsentPref,
  getBannerPositioning,
} from 'utility/dataMapper/cookieConsent';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { saveDataToLocalStorage, getDataFromLocalStorage } from 'utility/localStorageHandler';
import { isEmpty } from 'lodash';

const fpPromise = FingerprintJS.load();

const LABELS = {
  STORAGE_PREF: 'Cookie Settings',
  COOKIE_POLICY: 'Cookie Policy',
  COOKIE_SETTING: 'Cookie Settings',
  POP_UP_DESC: ` When you visit the website, it might store or retrieve data from your browser. This storage is necessary for the basic functionality of the website. This storage might be used for marketing, analytics, and personalisation of the website, such as storing your preferences. You can disable certain types of storage that might not be necessary for the basic functioning of the website. By disabling the categories your experience on the website may have an impact.`,
  COOKIE_POP_UP_DESC: `We use cookies to improve your experience on our site. We use essential and optional cookies. You may go through our Cookie Policy to understand how we use the cookies. You may change your settings at any time or accept the default settings.`,
};

const CookiePolicyModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isListLoading = false,
    isShow = true,
    isShowStoragePref = false,
    clientStorageId = '',
    details: { consentData = {} } = {},
    config: {
      bannerMessage = '',
      dialogType = 'box',
      dialogPosition = 'Floating bottom-left',
    } = {},
  } = useSelector(cookieConsentSelector, shallowEqual);
  // const [pref, setPref] = useState(dashboardCookies);
    const [pref, setPref] = useState([mandatoryPref]) ; // TODO : will replace any later


  const sizeView = dialogType === 'box' ? 'boxView' : 'bannerView';
  const { positionX, positionY } = getBannerPositioning(dialogPosition);
  const [browserSignatureVal, setBrowserSignatureValue] = React.useState('');

  const getData = getDataFromLocalStorage(LOCAL_STORAGE_KEY.COOKIE_CONSENT_DATA);
  // Button handling for accept/rejectall/accept all
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    setIsButtonClicked(false);
  }, []);

  React.useEffect(() => {
    if (Object.keys(getData).length > 0) {
      dispatch(storagePrefToggle({ isShowStoragePref: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBrowserSignature = () => {
    let visitorId = '';
    new Promise(async (resolve) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const fp = await fpPromise;
        const result = await fp.get();
        visitorId = result.visitorId;
        resolve(visitorId);
      } catch (err) {
        throw err;
      }
    }).then((res) => {
      setBrowserSignatureValue(res);
      saveDataToLocalStorage(LOCAL_STORAGE_KEY.BROWSER_SIGNATURE, res);
    });
  };

  React.useEffect(() => {
    getBrowserSignature();
  }, []);

  useEffect(() => {
    if (browserSignatureVal !== '') {
      dispatch(getCookieRecordAsync(browserSignatureVal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSignatureVal]);

  const mappedDataFromRedux = useCallback(() => {
    const classificationsKeyArr = consentData ? Object.keys(consentData) : [];
    const classificationArr = [];
    if (classificationsKeyArr.length) {
      classificationsKeyArr.forEach((item) => {
        classificationArr.push(
          dashboardCookies.find((val) => val.classification.toLowerCase() === item)
        );
      });
      setPref(classificationArr);
    } else {
      setPref([mandatoryPref]);
    }
  }, [consentData, setPref]);

  // TODO : Auto Update already selected Pref

  useEffect(() => {
    mappedDataFromRedux();
  }, [consentData, mappedDataFromRedux, setPref]);

  const onCloseHandler = (status = false) => {
    dispatch(cookieConsentToggle({ isShow: status }));
  };

  const onCloseStoragePrefHandler = () => {
    dispatch(storagePrefToggle({ isShowStoragePref: false }));
    dispatch(cookieConsentToggle({ isShow: false }));
  };

  const storagePreModalHandler = () => {
    dispatch(storagePrefToggle({ isShowStoragePref: !isShowStoragePref }));
    dispatch(cookieConsentToggle({ isShow: false }));
  };

  const switchHandler = useCallback(
    (item) => {
      let isAlready = false;
      if (pref.length) {
        isAlready = pref.some((val) => val.id === item.id);
        if (isAlready) {
          const updatedData = pref.filter((val) => val.id !== item.id); // Item will be removed
          setPref(updatedData);
        } else {
          setPref([...pref, item]);
        }
      } else {
        setPref([item]);
      }
    },
    [pref, setPref]
  );

  const saveHandler = (btnType, isAPICall = true) => {
    let initPref = pref;
    if (!isAPICall) {
      // Close Storage Pre Pop Up
      storagePreModalHandler();
    } else {
      if (btnType === 'save') {
        initPref = pref; // only selected
        storagePreModalHandler();
        setPref(pref);
        // storagePreModalHandler();
      }
      if (btnType === 'saveAll') {
        setPref(dashboardCookies); // all choose
        initPref = dashboardCookies;
      }
      if (btnType === 'rejectAll') {
        setPref([mandatoryPref]);
        initPref = [mandatoryPref];
      }
      dispatch(
        saveCookieRecordAsync({
          clientStorageId,
          cookieData: dataMapperForSaveCookieConsentPref(initPref),
        })
      );
      setIsButtonClicked(true); // button is clicked
      // auto close consent when api called
      if (Object.keys(getData).length > 0) {
        onCloseStoragePrefHandler();
      } else {
        onCloseHandler();
      }
    }
  };

  const CookiesListJSX = React.memo(() => {
    return dashboardCookies
      .filter(
        (item) =>
          item.classification !== classification.ESSENTIAL &&
          item.classification !== classification.ANALYTICS
      )
      .map((item) => {
        const isToggle = pref.length
          ? pref.some((val) => val.classification === item.classification)
          : false;
        return (
          <Box className={classes.switchBtnStyle} mr={1.5}>
            <SwitchField
              switchType="normal"
              key={item.id}
              onChange={() => switchHandler(item)}
              checked={isToggle}
            />
            <Typography component="p" variant="body2">
              {item.classification}
            </Typography>
          </Box>
        );
      });
  }, [classes.switchBtnStyle, pref, switchHandler]);

  const cookieButtonIconHandler = () => {
    if (isEmpty(consentData)) {
      dispatch(cookieConsentToggle({ isShow: true }));
    } else {
      dispatch(cookieConsentToggle({ isShow: false }));
      dispatch(storagePrefToggle({ isShowStoragePref: !isShowStoragePref }));
    }

    setIsButtonClicked(false);
  };

  const CookieComponent = React.useCallback(() => {
    if(window.location.href.includes('/cookie-policy')) return null
    else return (
      <>
        <Box className={classes.cookiePolicyModalWrapper}>
          <Box
            className={classes.cookieCloseBtn}
            onClick={() => {
              onCloseHandler();
              if (!isButtonClicked) {
                mappedDataFromRedux();
              }
            }}>
            <IconButton>
              <SvgCrossIcon width="14" height="14" />
            </IconButton>
          </Box>
          <Box className="innerBodyWrapper miniWrapper">
            <Typography variant="body2" component="p" color="textPrimary">
              {bannerMessage || LABELS.COOKIE_POP_UP_DESC}
            </Typography>
          </Box>
          <Box mt={1.5} textAlign="left" component="p">
            <Link
              to='/#/cookie-policy'
              target="_blank"
              className={classes.anchorLink}>
              <Typography
                variant="caption"
                color="primary"
                className="text-uppercase"
                component="span">
                <Box component="span" fontWeight={600}>
                  {LABELS.COOKIE_POLICY}
                </Box>
              </Typography>
            </Link>
          </Box>
          <Box mb={1.5} textAlign="left" component="p" variant="body2">
            <Box
              className="mb20"
              onClick={() => {
                storagePreModalHandler();
              }}>
              <Link to="/" className={classes.anchorLink}>
                <Typography
                  variant="caption"
                  color="primary"
                  // className="text-uppercase"
                  component="span">
                  <Box component="span" fontWeight={600}>
                    {LABELS.COOKIE_SETTING}
                  </Box>
                </Typography>
              </Link>
            </Box>
          </Box>

          <Box>
            <CookiesListJSX />
          </Box>

          <Box className={classes.divider} mt={1}>
            <Divider />
          </Box>

          <Box mt={2} className={classes.cookieBtnGroup}>
            <Button variant="contained" color="primary" onClick={() => saveHandler('saveAll')}>
              Accept All
            </Button>
            <Button variant="contained" color="primary" onClick={() => saveHandler('rejectAll')}>
              Accept Only Essential Cookies
            </Button>
          </Box>
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerMessage, CookiesListJSX, onCloseHandler, saveHandler, storagePreModalHandler]);

  let comp = <></>;
  if (!isListLoading || window.location.href.includes('/cookie-policy')) {
    comp = <></>;
  } else if (isShowStoragePref) {
    return (
      <StoragePreferences
        pref={pref}
        positionX={positionX}
        positionY={positionY}
        sizeView={sizeView}
        bannerMessage={bannerMessage}
        switchHandler={switchHandler}
        saveHandler={(btn = 'saveAll') => saveHandler(btn, true)}
        reduxSync={mappedDataFromRedux}
        consentData={consentData}
      />
    );
  } else if (isShow && isListLoading) {
    comp = <CookieComponent />;
  } else {
    comp = (
      <Box className={classes.cookieBtn} onClick={cookieButtonIconHandler}>
        <CookieIcon height="45px" width="55px" />
      </Box>
    );
  }
  return comp;
};

export default CookiePolicyModal;
