/* eslint-disable no-param-reassign */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { basicInfoSelector, commonSelector } from 'redux/custom-selector';
import amplitude from 'amplitude-js';
import { validateAmplitudeStatus } from './init';

export const useAmplitudeInstance = () => {
  const organiserInfo = useSelector(commonSelector, shallowEqual);
  const basicEventInfo = useSelector(basicInfoSelector, shallowEqual);

  const trackAmplitudeEvent = (eventName, eventData = {}, shouldSendEventProperty = false) => {
    if (shouldSendEventProperty) {
      eventData = {
        ...eventData,
        event_id: basicEventInfo.eventId,
        event_type: basicEventInfo.eventMetaTypeValue,
        event_name: basicEventInfo.name,
        event_format: basicEventInfo.eventType,
        organiser_id: organiserInfo.organiserId,
        organisation: organiserInfo.organisationName,
        plan_type: organiserInfo.subscription_name || 'planless',
        user_id: organiserInfo.userId,
        event_end_date: basicEventInfo.endTime || basicEventInfo.end_time_milli,
        event_start_date: basicEventInfo.startTime || basicEventInfo.start_time_milli,
      };
    } else {
      eventData = {
        ...eventData,
        organiser_id: organiserInfo.organiserId,
        organisation: organiserInfo.organisationName,
        plan_type: organiserInfo.subscription_name || 'planless',
        user_id: organiserInfo.userId,
      };
    }

    if (validateAmplitudeStatus() && eventName) {
      amplitude.getInstance().logEvent(eventName, eventData);
    }
  };

  return [trackAmplitudeEvent];
};
