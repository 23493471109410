import React from 'react';

export default function SvgTabLandScapeMode({ className, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      className={className}
      height={height}
      viewBox="0 0 315 278"
      fill="none">
      <mask id="mask0_1995_71359" maskUnits="userSpaceOnUse" x="0" y="0" width="315" height="278">
        <rect
          x="314.5"
          y="0.5"
          width="277"
          height="314"
          rx="8"
          transform="rotate(90 314.5 0.5)"
          fill="#FFFFFF"
        />
      </mask>
      <g mask="url(#mask0_1995_71359)">
        <path
          d="M297.791 25.7656C306.635 25.7656 313.804 32.9302 313.804 41.768L313.804 235.536C313.804 244.374 306.635 251.539 297.791 251.539L16.5135 251.539C7.66963 251.539 0.500235 244.374 0.500236 235.536L0.500244 41.768C0.500245 32.9301 7.66964 25.7656 16.5135 25.7656L297.791 25.7656Z"
          fill="#121515"
        />
        <path
          d="M297.79 27.1572C305.865 27.1572 312.411 33.6988 312.411 41.7681L312.411 235.537C312.411 243.606 305.865 250.147 297.79 250.147L16.5132 250.147C8.43836 250.147 1.89238 243.606 1.89239 235.537L1.89239 41.7681C1.89239 33.6987 8.43837 27.1572 16.5132 27.1572L297.79 27.1572Z"
          fill="#121515"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.087 251.539C289.087 251.923 288.969 252.235 288.823 252.235L276.124 252.235C275.978 252.235 275.859 251.923 275.859 251.539L289.087 251.539Z"
          fill="#121515"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M274.118 251.539C274.118 251.923 274 252.235 273.854 252.235L261.155 252.235C261.008 252.235 260.89 251.923 260.89 251.539L274.118 251.539Z"
          fill="#121515"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.804 220.23C314.188 220.23 314.5 220.37 314.5 220.543L314.5 235.571C314.5 235.744 314.188 235.885 313.804 235.885L313.804 220.23Z"
          fill="#121515"
        />
      </g>
    </svg>
  );
}
