/* eslint-disable  */
import React from 'react';
import { Button, Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  disableBtn: {
    '&.Mui-disabled': {
      background: `transparent linear-gradient(108deg, #EDC59C 0%, #ED7767 58%, #DC4E7B 100%) 0% 0% no-repeat padding-box`,
      color: '#fff',
      boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);`,
    },
  },
}));

export default function BtnWithLoader(props) {
  const classes = useStyles();
  const {
    id,
    onClick,
    disabled,
    className,
    variant,
    size,
    color,
    fullWidth,
    isLoading,
    loadingLabel,
    label,
    type = 'button',
    disabledColor,
    ...restProps
  } = props;
  return (
    <Button
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${className} ${disabledColor ? classes.disableBtn : ''}`}
      variant={variant}
      size={size}
      color={color}
      fullWidth={fullWidth}>
      {isLoading ? (
        <Box display="flex" color="white" alignItems="center" component="span">
          <Box display="inline-flex" component="span" marginRight={0.75}>
            <CircularProgress component="span" color="inherit" size={16} />
          </Box>{' '}
          {loadingLabel}
        </Box>
      ) : (
        <Box display="flex" alignItems="center" component="span">
          {/* {typeof render !== 'function' ? label : render()} */}
          {restProps.icon ? (
            <Box display="inline-flex" component="span" marginRight={0.75}>
              {restProps.icon}
            </Box>
          ) : (
            false
          )}
          {label}
        </Box>
      )}
    </Button>
  );
}
