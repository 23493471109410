import { SET_LANGUAGE_LIST } from 'redux/actions/language-actions';

const languageReducer = (state = [], action) => {
    switch (action.type) {
        case SET_LANGUAGE_LIST:
            return [...action.payload]
        default:
            return state;
    }
}

export default languageReducer;