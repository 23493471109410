import React from 'react';

export default function SvgCrossIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      {...props}>
      <path
        d="M7.9651 0.352821C8.43032 -0.112399 9.18459 -0.112399 9.64981 0.352821C10.0727 0.775748 10.1112 1.43756 9.76515 1.90392L9.64981 2.03753L2.03839 9.64896C1.57317 10.1142 0.818896 10.1142 0.353676 9.64896C-0.0692515 9.22603 -0.107699 8.56422 0.238332 8.09785L0.353676 7.96424L7.9651 0.352821Z"
        fill="#A5A5A5"
      />
      <path
        d="M0.349003 0.348827C0.771974 -0.074056 1.43379 -0.112435 1.90012 0.233645L2.03371 0.349003L9.65149 7.96837C10.1167 8.43364 10.1166 9.18791 9.65131 9.65308C9.22834 10.076 8.56653 10.1143 8.1002 9.76826L7.9666 9.6529L0.348827 2.03354C-0.116344 1.56827 -0.116266 0.813999 0.349003 0.348827Z"
        fill="#A5A5A5"
      />
    </svg>
  );
}
