export const quizReducerActionTypes = {
  BASIC_DETAILS: 'BASIC_DETAILS',
  SESSIONS_LIST: 'SESSIONS_LIST',
  GROUP_LIST: 'GROUP_LIST',
  FIELD_TYPES: 'FIELD_TYPES',
  QUIZ_DATA: 'QUIZ_DATA',
  ADD_QUESTION: 'ADD_QUESTIONS',
};

export const fillTimeConst = {
  DURINGSESSION: { id: 'During', name: 'Anytime during the session' },
  ENDOFSESSION: { id: 'Leaving', name: 'At the end of the session' },
};

export const quizInitState = {
  basicDetails: {
    visibility: 'Visible',
    ctaLabel: 'Start Quiz',
    quizName: '',
    type: '',
    selectedSessions: [],
    creditEnabledQuiz: false,
    sessionId: [],
    visibleGroups: [],
    visibleGroupIds: [],
    fillTime: { id: 'Leaving', name: 'At the end of the session' },
  },
  questions: [],
  groupsList: [],
  quizOptions: ['General', 'Session'],
  sessionsList: [],
  fieldTypes: [],
  quizId: '',
  fillSurvey: [
    { id: 'During', name: 'Anytime during the session' },
    { id: 'Leaving', name: 'At the end of the session' },
  ],
};

export function QuizReducer(state, action) {
  switch (action.type) {
    case quizReducerActionTypes.BASIC_DETAILS:
      return { ...state, basicDetails: { ...state.basicDetails, ...action.payload } };
    case quizReducerActionTypes.SESSIONS_LIST:
      return { ...state, sessionsList: action.payload };
    case quizReducerActionTypes.GROUP_LIST:
      return { ...state, groupsList: action.payload };

    case quizReducerActionTypes.FIELD_TYPES:
      return { ...state, fieldTypes: action.payload };
    case quizReducerActionTypes.QUIZ_DATA:
      return {
        ...state,
        basicDetails: { ...state.basicDetails, ...action.payload.basicDetails },
        questions: [...action.payload.questions],
      };
    case quizReducerActionTypes.ADD_QUESTION:
      return {
        ...state,
        questions: action.payload,
      };
    default:
      return state;
  }
}
