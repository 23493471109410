/* eslint-disable  */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';
import InputField from './inputField';
import Chip from '@material-ui/core/Chip';
import SvgCaretDownIcon from '../../icons/svgCaretDown';
import FieldWrapper from './fieldWrapper';
import { Search, XCircle } from 'react-feather';
import SvgEdit3 from 'icons/svgEdit3';
import SvgDelete2 from 'icons/svgDelete2';
import SvgSessionDefaultIcon from 'icons/svgSessionDefault';
import DefaultScreen from 'custom-components/defaultScreen';
// import { useHistory, useRouteMatch } from 'react-router-dom';
// import CheckBox2 from 'custom-components/form-fields/checkbox2';
const useStyle = makeStyles((theme) => ({
  borderDanger: {
    border: `1px solid ${theme.palette.text.danger1} !important`,
  },
  chipsWrap: {
    '& .MuiChip-root': {
      marginTop: theme.spacing(0.375),
      marginBottom: theme.spacing(0.375),
      '& span': {
        textOverflow: `ellipsis`,
        overflow: `hidden`,
        maxWidth: `7rem`,
      },
    },
    '& .MuiChip-root + .MuiChip-root': {
      marginLeft: theme.spacing(1),
    },
    '& .chipCount': {
      minWidth: 32,
      height: 24,
      borderRadius: 24,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.fontSize.size12,
      lineHeight: 0,
      padding: '0 8px',
      margin: '3px 0 3px 8px',
    },
  },
  selectOption: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create(['color', 'background-color']),
    '& .MuiListItemIcon-root': {
      width: 34,
      marginRight: theme.spacing(0.5),
      '&>img': {
        width: '100%',
        maxHeight: '100%',
      },
    },
    '&:not(.not-hover):hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.bg.whiteOff1,
        color: theme.palette.primary.main,
      },
    },
    '& .Mui-checked ~ .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: theme.fontSize.size8,
      color: theme.palette.text.grayColor,
    },
    '&.editable': {
      paddingRight: 74 + theme.spacing(2),
    },
  },
  selectField: {
    backgroundColor: theme.palette.bg.white,
    border: `1px solid ${theme.palette.border.dark}`,
    padding: `${theme.spacing(0.9)}px ${theme.spacing(1.5)}px`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['box-shadow', 'color'], {
      easing: theme.transitions.easing.easeInOut,
    }),
    '&.Mui-focused': {
      boxShadow: theme.inputBoxShadow,
    },
  },
  selectInput: {
    padding: `${theme.spacing(0.6025)}px 0`,
    '&.multi-select': {
      // padding: 0,
    },
  },
  optionList: {
    maxHeight: 230,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  selectAllCheckBoxBtn: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  dropdownActionItem: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`,
    // borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&.add-option-on': {
      paddingTop: theme.spacing(1.35),
      paddingBottom: theme.spacing(1.35),
    },
  },
  addOptionField: {
    fontFamily: 'Helvetica Neue New',
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.25)}px`,
    '& input': {
      padding: `${theme.spacing(1)}px 0`,
      backgroundColor: 'transparent',
    },
  },
  addOptionInput: {
    fontFamily: 'Helvetica Neue New',
    borderRadius: 0,
    border: 0,
    outline: 0,
    width: '100%',
    fontSize: theme.fontSize.size8,
    color: theme.palette.text.primary,
    '&::-webkit-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&::-moz-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&:-moz-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&::placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
  },
  addOptionAction: {
    padding: 0,
    width: 25,
    minWidth: 25,
    height: 25,
  },
  listItemAction: {
    padding: theme.spacing(1),
    color: theme.palette.text.grayColor,
    transition: theme.transitions.create(['color', 'background-color']),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  listItemActionIcon: {
    fontSize: theme.spacing(2.1),
    width: '1em',
    height: '1em',
  },
  checkAll: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    '& .MuiFormControlLabel-label': {
      fontSize: theme.fontSize.size8,
      color: theme.palette.text.grayColor,
    },
    '& .Mui-checked ~ .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
  iconStyle: {
    padding: theme.spacing(0.6),
  },
  cursorDisabled: {
    cursor: 'not-allowed',
  },
}));

function getLabelByValueKey(options, curValue, valueKey, labelKey) {
  const isObject = typeof curValue === 'object';
  const newCurValue = isObject ? curValue[valueKey] : curValue;
  const curIndex = options.findIndex((ele) => ele[valueKey] === newCurValue);
  if (curIndex > -1) {
    return options[curIndex][labelKey];
  } else {
    return '';
  }
}

export default function SelectDropdown(props) {
  const {
    valueKey = 'value',
    labelKey = 'label',
    options = [],
    placeholder = 'Select',
    position = 'top',
    isSelectAlls,
    selectAllLabel,
    customStaticUI,
    isShowAllCheckbox = true,
    disabled,
  } = props;
  const classes = useStyle();

  // const history = useHistory();
  // const match = useRouteMatch();
  const [searchValue, setSearchValue] = useState('');
  const [isMenuOpen, toggleMenu] = useState(false);
  const [isAddOptionOn, toggleAddOption] = React.useState(false);
  const [addOptionValue, setAddOptionValue] = React.useState({});

  const onSelectOption = (event, value, item, index) => {
    props.onChange(event, value, props.name, item, index);
    if (!props.isMultiSelect) toggleMenu(false);
  };

  // const handleAddOption = (event, type) => {
  // 	setAddOptionValue({ ...addOptionValue, [event.target.name]: event.target.value });
  // }
  // const handleAllChecked = (event, value) => {
  // 	props.onChange(value, props.name, event);
  // 	if (!props.isMultiSelect) toggleMenu(false);

  // }

  const isShowPlusCount =
    Array.isArray(props.value) &&
    props.displayCount &&
    props.value.length - props.displayCount &&
    !props.isAllSelected &&
    props.value.length > props.displayCount;
  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <ClickAwayListener
        onClickAway={() => {
          if (props.onClose && isMenuOpen) props.onClose();
          toggleMenu(false);
          if (Object.keys(addOptionValue).length) setAddOptionValue({});
          if (searchValue.length && !props.isAnalytics) setSearchValue('');
          if (isAddOptionOn) toggleAddOption(false);
        }}>
        <Box position="relative">
          <Box
            display="flex"
            onClick={() => {
              if (!disabled) {
                toggleMenu(!isMenuOpen);
                if (props.onOpenDropDown && typeof props.onOpenDropDown === 'function')
                  props.onOpenDropDown(!isMenuOpen);
              }
            }}
            className={`${props.disabled ? classes.cursorDisabled : 'cursor-pointer'} ${
              isMenuOpen ? 'Mui-focused' : ''
            } ${props.inputClassName || ''} ${props.error ? classes.borderDanger : ''} ${
              classes.selectField
            } ${props.readOnly ? 'read-only' : ''}`}
            alignItems="center">
            <Box
              width="100%"
              display="flex"
              className={`${classes.selectInput} ${
                (Array.isArray(props.value) ? props.value.length : null) ? 'multi-select' : ''
              }`}>
              {(!props.value || (Array.isArray(props.value) && !props.value.length)) &&
              (!props.preSelectedValues ||
                (Array.isArray(props.preSelectedValues) && !props.preSelectedValues.length)) &&
              !props.isAllSelected ? (
                <Box component="span" color="text.grayColor">
                  {placeholder}
                </Box>
              ) : props.isMultiSelect ? (
                <Box className={classes.chipsWrap}>
                  {props.isAllSelected ? (
                    <Chip
                      variant="outlined"
                      color="primary"
                      key={3242}
                      title={selectAllLabel ? selectAllLabel : 'All'}
                      label={selectAllLabel ? selectAllLabel : 'All'}
                      size="small"
                      onDelete={() => !disabled && props.onDeleteValue('All', 0)}
                      deleteIcon={<XCircle />}
                    />
                  ) : (
                    <>
                      {props.value
                        .filter((item, i) => {
                          if (props.displayCount) {
                            return i < props.displayCount;
                          } else return item;
                        })
                        .map((eachValue, index) => {
                          let curVal;
                          if (!props.isArrayOfObject) curVal = eachValue;
                          // else if (props.isUseValueKey) { curVal = getLabelByValueKey(options, eachValue, valueKey, labelKey); }
                          else curVal = eachValue[labelKey];
                          return (
                            <Chip
                              variant="outlined"
                              color="primary"
                              key={index}
                              label={curVal}
                              title={curVal}
                              size="small"
                              onDelete={() => {
                                if (eachValue.id && !disabled) {
                                  props.onDeleteValue(eachValue, index);
                                } else if (!disabled) {
                                  props.onDeletePreSelectedValue(eachValue, index); // Id is not there (event type)
                                }
                              }}
                              deleteIcon={<XCircle />}
                              disabled={!!eachValue.isReadOnly}
                            />
                          );
                        })}
                    </>
                  )}
                  {!props.displayCount ? (
                    false
                  ) : isShowPlusCount ? (
                    <Box component="span" className="chipCount">
                      +{props.value.length - props.displayCount}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <Box component="span">
                  {props.isArrayOfObject
                    ? props.isUseValueKey
                      ? props.isRenderValueKey
                        ? props.value
                        : getLabelByValueKey(options, props.value, valueKey, labelKey)
                      : props.isRenderValueKey
                      ? props.value[valueKey]
                      : props.value[labelKey]
                    : props.value}
                </Box>
              )}
            </Box>
            {!props.endAdornment ? (
              false
            ) : props.endAdornmentType === 'ICON' ? (
              <Box display="inline-flex" ml={1}>
                <IconButton className={classes.iconStyle} color="primary">
                  {props.endAdornmentIcon}
                </IconButton>
              </Box>
            ) : (
              <Box display="inline-flex" mr={1}>
                {props.endAdornment}
              </Box>
            )}
            <Box
              ml={1}
              color="text.grayColor"
              component="span"
              display="inline-flex"
              alignItems="center"
              justifyContent="center">
              <SvgCaretDownIcon height="15px" width="15px" />
            </Box>
          </Box>

          <Fade in={isMenuOpen}>
            <Box
              position="absolute"
              top={`${position === 'top' ? '100%' : 'auto'}`}
              bottom={`${position === 'top' ? 'auto' : '100%'}`}
              left="0"
              right="0"
              zIndex={5}
              mt={1.2}
              mb={1.2}
              hidden={props.readOnly}>
              <Paper
                elevation={2}
                style={{ minWidth: props.dropDownWidth ? props.dropDownWidth : '100%' }}>
                {/* {props.loading || !props.canAddOption ? false :
									<Box className={`${classes.dropdownActionItem} ${isAddOptionOn ? 'add-option-on' : ''}`}>
										{isAddOptionOn ?
											<Box display="flex" className={`cursor-pointer ${classes.addOptionField}`} alignItems="center" width="100%">
												{props.isArrayOfObject ?
													<>
														<Box width="100%" display="flex" className={``}>
															<input
																name={labelKey}
																onChange={handleAddOption}
																className={classes.addOptionInput}
																placeholder={`Enter ${labelKey}`}
																value={addOptionValue[labelKey] || ""}
															/>
														</Box>
														{props.canAddOptionValue ?
															<Box width="100%" display="flex" className={``}>
																<input
																	name={valueKey}
																	onChange={handleAddOption}
																	className={classes.addOptionInput}
																	placeholder={`Enter ${valueKey}`}
																	value={addOptionValue[valueKey] || ""}
																/>
															</Box> :
															false
														}
													</>
													:
													<Box width="100%" display="flex" className={``}>
														<input
															name={valueKey}
															onChange={handleAddOption}
															className={classes.addOptionInput}
															placeholder={props.addOptionPlaceholder}
															value={addOptionValue[valueKey] || ""}
														/>
													</Box>
												}
												<Box ml={1.7} color="primary.main" component="span" display="inline-flex" alignItems="center" justifyContent="center">
													<Button
														onClick={() => {
															const { source, ...restOption } = addOptionValue;
															if (props.AddWithSuccessCB) {
																setAddOptionValue({});
																toggleAddOption(false);
																//  toggleMenu(false);
																props.addNewOption(restOption,
																	source || "ADD", () => {
																		if (!props.isMultiSelect) toggleMenu(false);
																	});
															} else {
																props.addNewOption(restOption, source || "ADD");
															}
														}}
														size="small"
														color="primary"
														variant="contained"
														className={classes.addOptionAction}>
														<CheckIcon size={18} />
													</Button>
													<Box ml={1}>
														<Button
															onClick={() => {
																setAddOptionValue({});
																toggleAddOption(false);
															}}
															size="small"
															color="primary"
															variant="outlined"
															className={classes.addOptionAction}>
															<CancelIcon size={19} />
														</Button>
													</Box>
												</Box>
											</Box>
											:
											<Button
												onClick={() => { toggleAddOption(true); }}
												className="uppercase"
												size="small"
												color="primary"
												variant="outlined">
												<Box mr={1} component="span">{props.addOptionBtnLabel || "Add"}</Box>
												<Box component="span" display="inline-flex">
													<PlusCircle size={16} />
												</Box>
											</Button>
										}
									</Box>
								} */}
                {!props.loading &&
                props.showSearch &&
                (options.length > 2 || searchValue.length > 0) ? (
                  <Box className={`${classes.dropdownActionItem}`}>
                    <InputField
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        props.onSearch(e.target.value);
                      }}
                      onEnter={props.onEnter}
                      value={searchValue}
                      placeholder="Search here..."
                      type="search"
                      startAdornment={true}
                      startIconAdornment={true}
                      startAdornmentIcon={<Search />}
                      endAdornment={true}
                    />
                  </Box>
                ) : (
                  false
                )}
                {!props.loading ? (
                  <>
                    {props.options && props.options.length && isShowAllCheckbox ? (
                      <Box className={classes.checkAll}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              // onChange={handleAllChecked}
                              checked={props.selelctAll || props.isAllSelected}
                              onChange={(e) => onSelectOption(e, 'All', props.options)}
                              color="primary"
                              disabled={props.isSelectAllDisabled}
                            />
                          }
                          label={
                            isSelectAlls ? 'Select All' : selectAllLabel ? selectAllLabel : 'All'
                          }
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </>
                ) : (
                  false
                )}
                {isMenuOpen && (
                  <List
                    className={classes.optionList}
                    onScroll={(e) => props.onScroll && props.onScroll(e)}>
                    {props.customStaticUI ? props.customStaticUI : null}
                    {props.loading || !props.hasNoneOption ? (
                      false
                    ) : (
                      <ListItem
                        className={`${classes.selectOption} not-hover`}
                        component="li"
                        // onClick={(e) => { e.stopPropagation(); }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleMenu(false);
                        }}>
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary="None"></ListItemText>
                      </ListItem>
                    )}
                    {props.loading || options.length ? (
                      false
                    ) : props.isCutomNoDataScreen ? (
                      <ListItem className={`${classes.selectOption} not-hover`} component="li">
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={
                            <Box p={3}>
                              <DefaultScreen
                                title={
                                  props.primaryNoDataText
                                    ? props.primaryNoDataText
                                    : `No Data Found`
                                }
                                // subTitle={<>Add some sessions in the "<Box component="a" ml={0.3} onClick={() => {
                                // 	history.push({
                                // 		pathname: `/event/${match.params.eventId}/${match.params.organiserId}/sessions`,
                                // 		state: { fromSession: true, sessionId: match.params.sessionId }
                                // 	})
                                // }}
                                // variant="body2" className={`text-underline cursor-pointer`}>Session</Box>" section to link them to this survey.</>
                                // }
                                subTitle={props.subTitleNoDataText}
                                svgIcon={
                                  props.customDefaultSvg ? (
                                    props.svgCustom
                                  ) : (
                                    <SvgSessionDefaultIcon width="100" height="85" />
                                  )
                                }
                                widthOfBox={400}
                                marginAuto="auto"
                              />
                            </Box>
                          }></ListItemText>
                      </ListItem>
                    ) : (
                      <ListItem className={`${classes.selectOption} not-hover`} component="li">
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={
                            props.primaryNoDataText
                              ? `props.primaryNoDataFoundText`
                              : `No Data Found`
                          }></ListItemText>
                      </ListItem>
                    )}
                    {props.loading
                      ? false
                      : props.isArrayOfObject
                      ? options.map((eachOption, index) => {
                          if (
                            props.hasSearch &&
                            !eachOption[labelKey].toLowerCase().includes(searchValue.toLowerCase())
                          ) {
                            return false;
                          } else {
                            let curVal = eachOption,
                              isSelected = false;
                            if (props.isUseValueKey) {
                              curVal = eachOption[valueKey];
                            }
                            if (
                              props.isMultiSelect &&
                              props.value &&
                              Array.isArray(props.value) &&
                              props.value.length
                            ) {
                              isSelected =
                                props.value.findIndex(
                                  (ele) => ele[valueKey] === eachOption[valueKey]
                                ) !== -1
                                  ? true
                                  : false;
                            } else {
                              isSelected = curVal === props.value;
                            }
                            return (
                              <ListItem
                                className={`${classes.selectOption} ${
                                  props.isOptionEditable ? 'editable' : ''
                                }`}
                                key={index}
                                button
                                component="li"
                                // disabled={false}
                                // disabled={props.isMultiSelect && isSelected ? true : false}
                                // onClick={(e) => onSelectOption(e, curVal)}
                                selected={isSelected}>
                                {!props.iconAsLabel ? (
                                  false
                                ) : (
                                  <ListItemIcon>
                                    {!eachOption.icon ? false : eachOption.icon}
                                  </ListItemIcon>
                                )}
                                {/* <ListItemText
																	primaryTypographyProps={{ variant: "body2" }}
																	primary={eachOption[labelKey]}>
																</ListItemText> */}

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={
                                        (props.isMultiSelect && props.isAllSelected) ||
                                        !!eachOption.isReadOnly ||
                                        props.optionsDisabled
                                          ? true
                                          : false
                                      }
                                      checked={props.isAllSelected ? true : isSelected}
                                      onChange={(e) => onSelectOption(e, curVal, eachOption, index)}
                                      color="primary"
                                    />
                                  }
                                  label={eachOption[labelKey]}
                                />
                                {props.isSecondaryText ? (
                                  <ListItemSecondaryAction>
                                    <Typography
                                      variant="caption"
                                      component="span"
                                      color="textSecondary">
                                      {props.secondaryText}
                                    </Typography>
                                  </ListItemSecondaryAction>
                                ) : (
                                  false
                                )}
                                {!props.isOptionEditable ? (
                                  false
                                ) : (
                                  <ListItemSecondaryAction>
                                    <Box display="flex" alignItems="center">
                                      <IconButton
                                        onClick={() => {
                                          toggleAddOption(true);
                                          setAddOptionValue({ ...eachOption, source: 'EDIT' });
                                        }}
                                        size="small"
                                        className={classes.listItemAction}>
                                        <SvgEdit3 className={classes.listItemActionIcon} />
                                      </IconButton>
                                      <Box ml={1} display="inline-flex">
                                        <IconButton
                                          onClick={() => props.onDeleteOption(eachOption)}
                                          size="small"
                                          className={classes.listItemAction}>
                                          <SvgDelete2 className={classes.listItemActionIcon} />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            );
                          }
                        })
                      : options.map((eachOption, index) => {
                          if (
                            props.hasSearch &&
                            !eachOption.toLowerCase().includes(searchValue.toLowerCase())
                          ) {
                            return false;
                          } else {
                            return (
                              <ListItem
                                className={`${classes.selectOption} ${
                                  props.isOptionEditable ? 'editable' : ''
                                }`}
                                key={index}
                                button
                                component="li"
                                onClick={(e) => onSelectOption(e, eachOption, eachOption, index)}
                                selected={eachOption === props.value}>
                                <ListItemText
                                  primaryTypographyProps={{ variant: 'body2' }}
                                  primary={eachOption}></ListItemText>
                                {props.isOptionEditable && props.canAddOption ? (
                                  <ListItemSecondaryAction>
                                    <Box display="flex" alignItems="center">
                                      <IconButton
                                        onClick={() => {
                                          toggleAddOption(true);
                                          setAddOptionValue({
                                            [valueKey]: eachOption,
                                            source: 'EDIT',
                                          });
                                        }}
                                        size="small"
                                        className={classes.listItemAction}>
                                        <SvgEdit3 className={classes.listItemActionIcon} />
                                      </IconButton>
                                      <Box ml={1} display="inline-flex">
                                        <IconButton
                                          onClick={() => props.onDeleteOption(eachOption)}
                                          size="small"
                                          className={classes.listItemAction}>
                                          <SvgDelete2 className={classes.listItemActionIcon} />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </ListItemSecondaryAction>
                                ) : (
                                  false
                                )}
                              </ListItem>
                            );
                          }
                        })}
                    {props.loadingMore || props.loading ? (
                      <ListItem className={`${classes.selectOption} not-hover`} component="li">
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={`Loading ${props.loadingMore ? 'more' : ''}`}
                        />
                      </ListItem>
                    ) : (
                      false
                    )}
                  </List>
                )}
              </Paper>
            </Box>
          </Fade>
        </Box>
      </ClickAwayListener>
    </FieldWrapper>
  );
}
