import React from 'react';

const SvgExhibitorIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 2 30 26" {...props}>
      <g transform="translate(0 0.468)">
        <g transform="translate(5 4)">
          <path d="M19.5,22H1.5A1.538,1.538,0,0,1,0,20.5V1.5A1.538,1.538,0,0,1,1.5,0h18A1.538,1.538,0,0,1,21,1.5v19A1.538,1.538,0,0,1,19.5,22ZM1.5,1a.472.472,0,0,0-.5.5v19a.472.472,0,0,0,.5.5h18a.472.472,0,0,0,.5-.5V1.5a.472.472,0,0,0-.5-.5Z" />
          <g transform="translate(2 12)">
            <path
              d="M17.5,20H3.5A1.538,1.538,0,0,1,2,18.5v-6a.472.472,0,0,1,.5-.5.472.472,0,0,1,.5.5v6a.472.472,0,0,0,.5.5h14a.472.472,0,0,0,.5-.5v-6a.5.5,0,0,1,1,0v6A1.538,1.538,0,0,1,17.5,20Z"
              transform="translate(-2 -12)"
            />
          </g>
          <g transform="translate(0 5)">
            <path
              d="M3,10A2.946,2.946,0,0,1,0,7V5.5A.472.472,0,0,1,.5,5a.472.472,0,0,1,.5.5V7A2,2,0,0,0,5,7V5.5A.472.472,0,0,1,5.5,5a.472.472,0,0,1,.5.5V7A2.946,2.946,0,0,1,3,10Z"
              transform="translate(0 -5)"
            />
          </g>
          <g transform="translate(5 5)">
            <path
              d="M8,10A2.946,2.946,0,0,1,5,7V5.5A.472.472,0,0,1,5.5,5a.472.472,0,0,1,.5.5V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,8,10Z"
              transform="translate(-5 -5)"
            />
          </g>
          <g transform="translate(10 5)">
            <path
              d="M13,10a2.946,2.946,0,0,1-3-3V5.5a.5.5,0,0,1,1,0V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,13,10Z"
              transform="translate(-10 -5)"
            />
          </g>
          <g transform="translate(15 5)">
            <path
              d="M18,10a2.946,2.946,0,0,1-3-3V5.5a.5.5,0,0,1,1,0V7a2,2,0,0,0,4,0V5.5a.5.5,0,0,1,1,0V7A2.946,2.946,0,0,1,18,10Z"
              transform="translate(-15 -5)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgExhibitorIcon;
