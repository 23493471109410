import { SET_ANALYTICS_DATA } from "redux/actions/analytics.action";


const analyticDataReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_ANALYTICS_DATA:
            return action.payload
        default:
            return state;
    }
}

export default analyticDataReducer;