import { LEAD_SCORE_ACTION_TYPE } from './constants';

const getActivityScoreLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_ACTIVITY_SCORE_LOADING,
    payload,
  };
};
const getLeadLevelLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_LOADING,
    payload,
  };
};

const getLeadScoreAction = (payload = {}) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_SCORE,
    payload,
  };
};

const getActivityScoreAction = (payload = {}) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_ACTIVITY_SCORE,
    payload,
  };
};
const saveActivityScoreAction = (payload = []) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_ACTIVITY_SCORE,
    payload,
  };
};

const saveActivityScoreLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.SAVE_ACTIVITY_SCORE_LOADING,
    payload,
  };
};
const resetActivityScoreLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.RESET_ACTIVITY_SCORE_LOADING,
    payload,
  };
};

const defaultActivityScoreStatus = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.DEFAULT_ACTIVITY_SCORE,
    payload,
  };
};
const saveLeadLevelLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.SAVE_LEAD_SCORE_LOADING,
    payload,
  };
};

const saveLeadLevelAction = (payload = []) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.SAVE_LEAD_LEVEL,
    payload,
  };
};

const updateLeadLevelLabelLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.UPDATE_LEAD_LEVEL_LABEL_LOADING,
    payload,
  };
};

const getLeadLevelDistLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_DIST_LOADING,
    payload,
  };
};

const getLeadLevelDist = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_DIST,
    payload,
  };
};

const getLeadLevelOverviewLoading = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_OVERVIEW_LOADING,
    payload,
  };
};

const getLeadLevelOverview = (payload) => {
  return {
    type: LEAD_SCORE_ACTION_TYPE.GET_LEAD_OVERVIEW,
    payload,
  };
};
export {
  getLeadScoreAction,
  getActivityScoreAction,
  getActivityScoreLoading,
  saveActivityScoreAction,
  saveActivityScoreLoading,
  defaultActivityScoreStatus,
  resetActivityScoreLoading,
  getLeadLevelLoading,
  saveLeadLevelAction,
  saveLeadLevelLoading,
  updateLeadLevelLabelLoading,
  getLeadLevelDistLoading,
  getLeadLevelDist,
  getLeadLevelOverviewLoading,
  getLeadLevelOverview,
};
