/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgUnauthorized(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 497.621 282.765">
      <g id="undraw_not_found_60pq" transform="translate(42.613)">
        <g
          id="Group_13675"
          data-name="Group 13675"
          transform="translate(-55.364 -1.562) rotate(-2)">
          <circle
            id="Ellipse_498"
            data-name="Ellipse 498"
            cx="28.716"
            cy="28.716"
            r="28.716"
            transform="translate(6.573 177.118)"
            fill="#353535"
          />
          <rect
            id="Rectangle_3872"
            data-name="Rectangle 3872"
            width="8.724"
            height="15.63"
            transform="translate(21.939 228.214) rotate(0.675)"
            fill="#353535"
          />
          <rect
            id="Rectangle_3873"
            data-name="Rectangle 3873"
            width="8.724"
            height="15.63"
            transform="translate(39.386 228.42) rotate(0.675)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_499"
            data-name="Ellipse 499"
            cx="2.726"
            cy="7.27"
            rx="2.726"
            ry="7.27"
            transform="translate(21.72 246.751) rotate(-89.325)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_500"
            data-name="Ellipse 500"
            cx="2.726"
            cy="7.27"
            rx="2.726"
            ry="7.27"
            transform="translate(39.171 246.593) rotate(-89.325)"
            fill="#353535"
          />
          <circle
            id="Ellipse_501"
            data-name="Ellipse 501"
            cx="9.814"
            cy="9.814"
            r="9.814"
            transform="translate(26.288 188.759)"
            fill="#fff"
          />
          <circle
            id="Ellipse_502"
            data-name="Ellipse 502"
            cx="3.271"
            cy="3.271"
            r="3.271"
            transform="translate(32.83 195.302)"
            fill="#353535"
          />
          <path
            id="Path_4578"
            data-name="Path 4578"
            d="M60.36,511.361c-2.2-10.411,5.339-20.815,16.83-23.239s22.587,4.05,24.783,14.461-5.444,14.151-16.935,16.575S62.556,521.772,60.36,511.361Z"
            transform="translate(-52.609 -332.559)"
            fill="#f59c86"
          />
          <path
            id="Path_4579"
            data-name="Path 4579"
            d="M164.2,591.731c0,20.02-11.9,27.01-26.582,27.01q-.51,0-1.019-.011c-.68-.015-1.354-.047-2.02-.092-13.25-.938-23.544-8.287-23.544-26.906,0-19.268,24.622-43.581,26.472-45.38l0,0,.107-.1S164.2,571.712,164.2,591.731Z"
            transform="translate(-85.107 -369.878)"
            fill="#f59c86"
          />
          <path
            id="Path_4580"
            data-name="Path 4580"
            d="M162.793,615.678l9.722-13.585-9.747,15.077-.026,1.56c-.68-.015-1.354-.047-2.02-.092l1.048-20.029-.008-.156.018-.029.1-1.892-9.771-15.114,9.8,13.7.023.4.792-15.133-8.365-15.618,8.467,12.961.824-31.374,0-.107v.1l-.138,24.741,8.328-9.808-8.362,11.94-.22,13.549,7.775-13-7.808,15-.123,7.533,11.288-18.1-11.331,20.727Z"
            transform="translate(-111.25 -369.878)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_509"
            data-name="Ellipse 509"
            cx="4.507"
            cy="14.358"
            rx="4.507"
            ry="14.358"
            transform="translate(11.464 213.584) rotate(-68.159)"
            fill="#353535"
          />
        </g>
        <circle
          id="Ellipse_493"
          data-name="Ellipse 493"
          cx="23.263"
          cy="23.263"
          r="23.263"
          transform="translate(54.012 14.176)"
          fill="#f59c86"
        />
        <path
          id="Path_4574"
          data-name="Path 4574"
          d="M308.42,185.764c0,54.891-32.632,74.057-72.886,74.057s-72.885-19.166-72.885-74.057S235.535,61.042,235.535,61.042,308.42,130.872,308.42,185.764Z"
          transform="translate(-117.96 -61.042)"
          fill="#dbdbdb"
        />
        <path
          id="Path_4575"
          data-name="Path 4575"
          d="M264.859,190.382l.747-45.939L296.671,87.61l-30.948,49.627.336-20.656,21.41-41.118-21.322,35.652h0l.6-37.151,22.927-32.735L266.846,68.121,267.223,0l-2.37,90.181.195-3.72-23.31-35.679L264.674,93.6,262.5,135.093l-.065-1.1L235.565,96.445l26.791,41.438-.272,5.189-.049.078.022.426-5.51,105.267h7.362l.883-54.372,26.725-41.336Z"
          transform="translate(-149.939)"
          fill="#353535"
        />
        <path
          id="Path_4576"
          data-name="Path 4576"
          d="M951.357,287.243c0,44.931-26.711,60.619-59.66,60.619s-59.66-15.688-59.66-60.619S891.7,185.152,891.7,185.152,951.357,242.312,951.357,287.243Z"
          transform="translate(-544.031 -140.039)"
          fill="#dbdbdb"
        />
        <path
          id="Path_4577"
          data-name="Path 4577"
          d="M908.493,279.947l.611-37.6,25.429-46.521L909.2,236.445l.275-16.908L927,185.879l-17.454,29.183h0l.494-30.41,18.767-26.8-18.689,22.014.309-55.761-1.94,73.817.16-3.045-19.08-29.205,18.774,35.051-1.778,33.962-.053-.9-22-30.734,21.929,33.919-.222,4.247-.04.064.018.349L901.689,327.8h6.026l.723-44.506,21.874-33.836Z"
          transform="translate(-563.001 -78.997)"
          fill="#353535"
        />
        <ellipse
          id="Ellipse_494"
          data-name="Ellipse 494"
          cx="201.589"
          cy="10.19"
          rx="201.589"
          ry="10.19"
          transform="translate(0 237.158)"
          fill="#a5a5a5"
        />
        <ellipse
          id="Ellipse_495"
          data-name="Ellipse 495"
          cx="34.527"
          cy="1.745"
          rx="34.527"
          ry="1.745"
          transform="translate(289.868 262.439)"
          fill="#a5a5a5"
        />
        <ellipse
          id="Ellipse_496"
          data-name="Ellipse 496"
          cx="34.527"
          cy="1.745"
          rx="34.527"
          ry="1.745"
          transform="translate(164.927 279.275)"
          fill="#a5a5a5"
        />
        <ellipse
          id="Ellipse_497"
          data-name="Ellipse 497"
          cx="78.883"
          cy="3.987"
          rx="78.883"
          ry="3.987"
          transform="translate(25.782 262.916)"
          fill="#a5a5a5"
        />
        <g id="Group_13673" data-name="Group 13673" transform="translate(388.19 162.446)">
          <ellipse
            id="Ellipse_503"
            data-name="Ellipse 503"
            cx="28.716"
            cy="28.716"
            rx="28.716"
            ry="28.716"
            transform="translate(1.31 18.93)"
            fill="#353535"
          />
          <rect
            id="Rectangle_3874"
            data-name="Rectangle 3874"
            width="8.724"
            height="15.63"
            transform="translate(31.323 87.975) rotate(-162.917)"
            fill="#353535"
          />
          <rect
            id="Rectangle_3875"
            data-name="Rectangle 3875"
            width="8.724"
            height="15.63"
            transform="translate(14.645 82.85) rotate(-162.917)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_504"
            data-name="Ellipse 504"
            cx="7.27"
            cy="2.726"
            rx="7.27"
            ry="2.726"
            transform="translate(30.469 90.755) rotate(-162.917)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_505"
            data-name="Ellipse 505"
            cx="7.27"
            cy="2.726"
            rx="7.27"
            ry="2.726"
            transform="translate(13.898 85.281) rotate(-162.917)"
            fill="#353535"
          />
          <ellipse
            id="Ellipse_506"
            data-name="Ellipse 506"
            cx="9.814"
            cy="9.814"
            rx="9.814"
            ry="9.814"
            transform="translate(21.653 30.669)"
            fill="#fff"
          />
          <ellipse
            id="Ellipse_507"
            data-name="Ellipse 507"
            cx="3.271"
            cy="3.271"
            rx="3.271"
            ry="3.271"
            transform="translate(28.195 37.212)"
            fill="#353535"
          />
          <path
            id="Path_4581"
            data-name="Path 4581"
            d="M689.851,525.681c5.267-9.245,1.264-21.452-8.94-27.265s-22.746-3.032-28.013,6.213.869,15.137,11.073,20.951S684.584,534.926,689.851,525.681Z"
            transform="translate(-625.353 -495.378)"
            fill="#f59c86"
          />
          <ellipse
            id="Ellipse_508"
            data-name="Ellipse 508"
            cx="4.507"
            cy="14.358"
            rx="4.507"
            ry="14.358"
            transform="translate(30.133 68.908) rotate(-115.374)"
            fill="#353535"
          />
        </g>
        <text
          id="unauthorized"
          transform="translate(32.365 234.425)"
          fill="#f59c86"
          font-size="59"
          font-family="InterUI-Bold, Inter UI"
          font-weight="700">
          <tspan x="0" y="0">
            Unauthorized
          </tspan>
        </text>
      </g>
    </svg>
  );
}
