import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cookieHeader: {
    backgroundColor: `#FFFFFF`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    position: 'fixed',
    width: '100%',
    left: '0',
    zIndex: '1',
  },
  whiteBg: {
    backgroundColor: `#FFFFFF`,
  },
  blockCookieView: {
    backgroundColor: `#F6F8F8`,
    padding: '20px',
    marginBottom: '30px',
    minHeight: '365px',
  },
  blankBlock: {
    marginTop: '-60px',
    paddingTop: '60px',
    fontSize: '0',
  },
  topViewStart: {
    // backgroundClip: 'padding-box',
  },
  hasFixed: {
    position: 'fixed',
    top: '80px',
    '@media screen and (max-width: 960px)': {
      position: 'relative',
      top: '0px',
    },
  },
  jumpMenuLinks: {
    minWidth: '320px',
    maxWidth: '320px',
    borderRadius: '8px',
    border: '1px solid #DBDBDB',
    padding: '10px 10px',
    marginTop: '10px',
    '@media screen and (max-width: 960px)': {
      marginBottom: '30px',
    },
    '& a': {
      cursor: 'pointer',
      display: 'block',
      color: '#808080',
      margin: '5px 0',
      borderRadius: '4px',
      fontSize: '12px',
      padding: '10px 15px',
      '&:hover': {
        textDecoration: 'none',
      },
      '&.active': {
        backgroundColor: '#FFF0EC',
        color: '#ED7767',
      },
    },
  },
  cookieBtn: {
    backgroundColor: `white`,
    width: 48,
    height: 48,
    borderRadius: 8,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    boxShadow: ` 0px 4px 6px rgba(0, 0, 0, 0.12)`,
    position: 'absolute',
    bottom: theme.spacing(3.5),
    left: theme.spacing(3.5),
    zIndex: 9,
    cursor: 'pointer',
  },

  cookieAccordionBlock: {
    // '& .MuiPaper-root.MuiPaper-elevation1': {
    //   border: `red`,
    // },
    '& .MuiAccordionDetails-root': {
      paddingLeft: 34,
      paddingTop: 0,
    },
    '& .expand-button': {
      padding: 5,
      minWidth: 25,
      minHeight: 25,
      marginRight: '0.5rem',
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 40,

      '& .MuiFormControlLabel-root': {
        marginLeft: 'auto',
        '&.Mui-disabled': {
          opacity: '0.5',
        },
        '& .MuiSwitch-root': {
          margin: `0`,
        },
      },
      '& .MuiAccordionSummary-content': {
        justifyContent: 'start',
        alignItems: 'center',
        margin: 0,
        cursor: 'default',
      },
    },
    '& .MuiAccordion-root:before': {
      backgroundColor: `transparent`,
    },
    '& .MuiPaper-root.MuiPaper-elevation1': {
      border: `0`,
      margin: `0`,
    },
    '& .MuiIconButton-edgeEnd': {
      backgroundColor: `#FFF0EC`,
      width: `20px`,
      height: `20px`,
      borderRadius: `4px`,
      marginRight: `10px`,
      '& span': {
        backgroundColor: `red`,
        position: `relative`,
        '&::after': {
          position: `absolute`,
          content: `""`,
          width: `12px`,
          height: `2px`,
          borderRadius: `4px`,
          backgroundColor: `#ED7767`,
        },
        '&::before': {
          position: `absolute`,
          content: `""`,
          width: `2px`,
          height: `12px`,
          borderRadius: `4px`,
          backgroundColor: `#ED7767`,
        },
      },
    },
    '& .MuiIconButton-edgeEnd.Mui-expanded': {
      // margin: `0`,
      transform: `rotate(0deg)`,
      '& span': {
        backgroundColor: `transparent`,
        '&::before': {
          backgroundColor: `transparent`,
        },
      },
    },
  },
  cookiePolicyModalWrapper: {
    '& .innerBodyWrapper': {
      '&.miniWrapper': {
        maxHeight: `15rem`,
        overflowY: `auto`,
      },
      maxHeight: `22rem`,
      overflowY: `auto`,
    },
    borderRadius: 8,
    padding: theme.spacing(3.5),
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    bottom: theme.spacing(4.5),
    left: theme.spacing(4.5),
    maxWidth: '25rem',
    backgroundColor: '#fff',
    zIndex: 1200,
    '@media screen and (max-width: 960px)': {
      maxWidth: 'inherit',
      width: `100%`,
      left: `0`,
      bottom: `0`,
      right: `0`,
    },

    '& .backBtn': {
      borderRadius: 50,
      color: theme.palette.secondary.main,
      marginRight: 5,
    },
  },

  anchorLink: {
    textUnderlinePosition: `under`,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
    '& span': {
      textTransform: 'capitalize',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },

  cookieBtnGroup: {
    display: `flex`,
    '& Button': {
      marginRight: theme.spacing(1),
      width: `100%`,
      whiteSpace: `pre-wrap`,
      lineHeight: '1',
    },
  },
  cookieCloseBtn: {
    position: 'absolute',
    top: theme.spacing(1.2),
    right: theme.spacing(1.2),
  },
  switchBtnStyle: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: '0.75rem',
    },
  },
}));
