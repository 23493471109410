/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, makeStyles, Button, Typography, Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import SvgFacebookIcon from '../../icons/svgFb';
import SvgGoogleIcon from '../../icons/svgGooglePlus';
import { Mail } from 'react-feather';
import SvgLinkInIcon from '../../icons/svgLinkedin';
import InputField from '../../custom-components/form-fields/inputField';
import validateFunctions from '../../models/validation';
import { PreLoginSchema } from '../../models/schema';
import userSetting from '../../settings';
import BtnWithLoader from 'custom-components/btnWithLoader';
import {
  API,
  POSTAPIWITHCUSTOMDOMAIN,
  GETAPIWITHCUSTOMDOMAIN,
  PUTAPIWITHCUSTOMDOMAIN,
} from 'api-setup/api-helper';
import { CHECK_USER_EXIST } from '../../api-setup/api-endpoints';
import { useSource } from 'lib/custom-common-hooks';
import ProjectSetting from 'settings';
import { setNewMessage } from 'redux/actions/setting-actions';
import amplitude from 'lib/amplitude';

const useStyles = makeStyles((theme) => ({
  anchorLink: {
    textDecoration: 'none',
  },
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  socialLoginBtn: {
    background: 'white',
    boxShadow: '0px 3px 6px #0000001A',
    minHeight: '34px',
    flexGrow: 1,
    borderRadius: 4,
    '&:nth-child(n+2)': {
      marginLeft: 10,
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  Title: {
    color: `${theme.palette.text.primary}`,
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  fieldIcon: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

// facebook login api call
const onFacebookLogin = () => {
  amplitude.Track.resetUserID();
  amplitude.Track.clickSocial('facebook');
  window.open(
    `${userSetting.socialLogin}fb_login_dashboard.php?domain=${window.location.href}`,
    '_self'
  );
};

// linkedin login api call
const onLinkedinLogin = () => {
  amplitude.Track.resetUserID();
  amplitude.Track.clickSocial('linkedin');
  window.open(
    `${userSetting.socialLogin}ln_login_dashboard.php?domain=${window.location.href}`,
    '_self'
  );
};

// google login api call
const onGoogleLogin = () => {
  amplitude.Track.resetUserID();
  amplitude.Track.clickSocial('google');
  window.open(
    `${userSetting.socialLogin}go_login_dashboard.php?domain=${window.location.href}`,
    '_self'
  );
};

export default function LoginForm() {
  const { subTitle, Title, subTitleWithSeparator, socialLoginBtn, fieldIcon } = useStyles();
  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
  });
  const [errorObj, setErrorObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const source = useSource();

  // handle change of inputs in formFields
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (errorObj[name]) setErrorObj({ ...errorObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };

  const APICALL = React.useCallback(
    (ACTION, reduxData, methodType, URL, headerData, bodyData, isCutomUrl, APICB) => {
      const [header, body] = API.generateHeader(reduxData, headerData, bodyData);
      let APIPromise;
      let APIBASEURL = '';
      if (!isCutomUrl) {
        APIBASEURL = ProjectSetting.baseURL;
      } else {
        APIBASEURL = ProjectSetting.customBaseURL;
      }
      if (methodType === 'GET') {
        APIPromise = GETAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      } else if (methodType === 'POST') {
        APIPromise = POSTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      } else if (methodType === 'PUT') {
        APIPromise = PUTAPIWITHCUSTOMDOMAIN(false, URL, source.AS, header, body, APIBASEURL);
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message) if (APICB) APICB(ACTION, 'SUCCESS', resp.data);
        } else {
          API.errStatusHandler(resp, history, dispatch, () => {
            if (APICB) APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
    },
    []
  );

  function handleValidations(PropFormFields) {
    const { email } = PropFormFields;
    const data = { email };
    const errorObject = validateFunctions.validate(PreLoginSchema, data);
    if (errorObject) {
      return errorObject;
    } else {
      return false;
    }
  }

  const handleClickOnContinueBtn = () => {
    const errorObject = handleValidations(formFields);
    if (!errorObject) {
      setErrorObj({});
      if (formFields.email) {
        const reObj = {
          data: {
            email: formFields.email,
          },
        };
        setIsLoading(true);
        APICALL(
          'CHECK-EMAIL',
          null,
          'POST',
          CHECK_USER_EXIST,
          null,
          reObj,
          true,
          (ACTION, type, data) => {
            if (type === 'SUCCESS') {
              setIsLoading(false);
              if (data.data) {
                const repObj = data.data;
                if (repObj.isExist) {
                  history.push({
                    pathname: `/login`,
                    state: { email: repObj.email, isExist: repObj.isExist, name: repObj.name },
                  });
                } else {
                  history.push({
                    pathname: `/signup`,
                    state: { isExist: repObj.isExist, email: repObj.email },
                  });
                }
              } else {
                dispatch(setNewMessage({ message: data.message, type: 'error', show: true }));
              }
            } else {
              dispatch(setNewMessage({ message: data.message, type: 'error', show: true }));
            }
          }
        );
      }
    } else {
      setErrorObj(errorObject);
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    }
  };

  return (
    <>
      <Box mb={10} display="flex" justifyContent="center">
        <HubiloLogo height="70px" width="140px" />
      </Box>
      <Box className="text-center">
        <Box mb={1}>
          <Typography variant="h6" className={Title}>
            Let&apos;s Get Started
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="caption" className={subTitle}>
            CONNECT WITH
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
          <Button variant="contained" className={socialLoginBtn} onClick={() => onFacebookLogin()}>
            <SvgFacebookIcon height="14px" width="14px" color="#355087" />
          </Button>
          <Button variant="contained" className={socialLoginBtn} onClick={() => onLinkedinLogin()}>
            <SvgLinkInIcon height="14px" width="14px" color="#0077B3" />
          </Button>
          <Button variant="contained" className={socialLoginBtn} onClick={() => onGoogleLogin()}>
            <SvgGoogleIcon height="14px" width="14px" color="#D94437" />
          </Button>
        </Box>
      </Box>
      <Box mt={3.2}>
        <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
          <Typography variant="caption" component="span">
            OR ENTER YOUR EMAIL ID BELOW
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              disabled={isLoading}
              required
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              startAdornment
              startIconAdornment
              startAdornmentIcon={<Mail size={18} className={fieldIcon} />}
              error={errorObj.email}
              onChange={handleChange}
              value={formFields.email}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          <BtnWithLoader
            className="uppercase"
            onClick={handleClickOnContinueBtn}
            variant="contained"
            color="primary"
            disabled={isLoading}
            type="submit"
            isLoading={isLoading}
            loadingLabel="Continue.."
            label="Continue"
            fullWidth
          />
        </Box>
        <Box mt={5} textAlign="center" color="text.grayColor">
          <Typography variant="caption" component="p">
            Already have an account?{' '}
            <Link to="/" className="text-uppercase">
              <Box component="span" color="primary.main" fontWeight={600}>
                Sign In
              </Box>
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
