import React from 'react';

const style = {
  a: {
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: 2,
  },
};

export default function SvgUnionDot(props) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11.001 18.002"
      {...props}>
      <path
        style={style.a}
        d="M7,15a1,1,0,1,1,1,1A1,1,0,0,1,7,15ZM0,15a1,1,0,1,1,1,1A1,1,0,0,1,0,15ZM7,8A1,1,0,1,1,8,9,1,1,0,0,1,7,8ZM0,8A1,1,0,1,1,1,9,1,1,0,0,1,0,8ZM7,1A1,1,0,1,1,8,2,1,1,0,0,1,7,1ZM0,1A1,1,0,1,1,1,2,1,1,0,0,1,0,1Z"
        transform="translate(1 1)"
      />
    </svg>
  );
}
