/* eslint-disable  */
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Fade,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from '@material-ui/core';
// import TooltipIcon from '@material-ui/icons/InfoOutlined';
import SvgCaretDownIcon from 'icons/svgCaretDown';
import { Clock } from 'react-feather';
import InputField from './inputField';
import moment from 'moment-timezone';

const generateTime = () => {
  let arr = [];
  let curHour = 0;
  while (curHour <= 23) {
    arr.push(`${curHour < 10 ? '0' + curHour : curHour}:00:00`);
    arr.push(`${curHour < 10 ? '0' + curHour : curHour}:30:00`);
    curHour++;
  }
  return arr;
};

// const TIME_ARRAY = generateTime();

const useStyles = makeStyles((theme) => ({
  timeField: {
    '&.MuiInputBase-root': {
      cursor: 'pointer',
    },
    '&.MuiInputBase-root.read-only input': {
      color: theme.palette.text.primary,
    },
    '&.MuiInputBase-root.Mui-disabled input': {
      color: theme.palette.text.grayColor,
    },

    '&:hover': {},
  },
  dropdownBlock: {
    maxHeight: 188,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  listItemBlock: {
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(0.875),
    transition: theme.transitions.create(['background-color']),
    '&.Mui-selected': {
      backgroundColor: theme.palette.bg.whiteOff1,
      color: theme.palette.primary.main,
    },
    '&:hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
  },
}));

const TimePickerNew = (props) => {
  const {
    className = '',
    value,
    name,
    label,
    selectTime = () => { },
    placeholder = '',
    error,
    startAdornment = true,
    endAdornment = true,
  } = props;

  const { timeField, dropdownBlock, listItemBlock } = useStyles();

  const [isMenuOpen, toggleMenu] = React.useState(false);
  const [tempValue, setTempValue] = React.useState('');

  const selectedItemRef = useRef();

  const onSelectTime = (time) => {
    setTempValue(moment('2020-01-01 ' + value, 'YYYY-MM-DD HH:mm:ss', true).format('hh:mm A'));
    if (time !== value) {
      selectTime(time, name);
    }
  };

  React.useEffect(() => {
    if (value) {
      if (selectedItemRef && selectedItemRef.current) {
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if (isChrome) {
          // Chrome
          selectedItemRef.current.scrollIntoViewIfNeeded();
        } else {
          let inlineCenter = { behavior: 'smooth', block: 'center', inline: 'nearest' };
          selectedItemRef.current.scrollIntoView(inlineCenter);
        }
      }
      setTempValue(moment('2020-01-01 ' + value, 'YYYY-MM-DD HH:mm:ss', true).format('hh:mm A'));
    } else {
      setTempValue('');
    }
  }, [value]);
  const itemArr = props.options || generateTime();

  return (
    <ClickAwayListener onClickAway={() => toggleMenu(false)}>
      <Box className={`${className}`} position="relative">
        <Box display="flex" alignItems="center">
          <InputField
            disabled={props.disabled}
            inputClassName={`${timeField} timeFieldWrapper`}
            onClick={() => {
              if (props.disabled) return false;
              toggleMenu(!isMenuOpen);
            }}
            startAdornment={startAdornment}
            startIconAdornment
            startAdornmentIcon={<Clock size={15} />}
            endAdornment={endAdornment}
            endIconAdornment
            readOnly={props.readOnly}
            endAdornmentIcon={<SvgCaretDownIcon width={15} height={15} />}
            placeholder={placeholder}
            onBlur={(event) => {
              if (event.target.value) {
                if (
                  !moment('2020-01-01 ' + event.target.value, 'YYYY-MM-DD HH:mm A', true).isValid()
                ) {
                  onSelectTime(value || '00:00:00');
                } else {
                  onSelectTime(
                    moment('2020-01-01 ' + event.target.value, 'YYYY-MM-DD HH:mm A', true).format(
                      'HH:mm:ss'
                    )
                  );
                }
              }
            }}
            label={label}
            name={name}
            required={props.required}
            value={tempValue}
            onChange={(event) => {
              if (isMenuOpen) toggleMenu(false);
              setTempValue(event.target.value);
              if (event.target.value.length === 0) {
                onSelectTime(event.target.value);
              }
            }}
            error={error}
          />
        </Box>
        <Fade in={isMenuOpen}>
          <Box position="absolute" top="100%" left="0" right="0" zIndex={5} mt={0.5} mb={1.5}>
            <Paper elevation={2}>
              <List className={dropdownBlock}>
                {itemArr.map((time, i) => {
                  return (
                    <React.Fragment key={i}>
                      {value === time ? (
                        <ListItem
                          ref={selectedItemRef}
                          selected={true}
                          onClick={() => {
                            onSelectTime(time);
                            toggleMenu(false);
                          }}
                          key={i}
                          className={`cursor-pointer ${listItemBlock}`}>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={moment(
                              '2020-01-01 ' + time,
                              'YYYY-MM-DD HH:mm:ss',
                              true
                            ).format('hh:mm A')}
                          />
                        </ListItem>
                      ) : (
                        <ListItem
                          selected={false}
                          onClick={() => {
                            onSelectTime(time);
                            toggleMenu(false);
                          }}
                          key={i}
                          className={`cursor-pointer ${listItemBlock}`}>
                          <ListItemText
                            primaryTypographyProps={{ variant: 'body2' }}
                            primary={moment(
                              '2020-01-01 ' + time,
                              'YYYY-MM-DD HH:mm:ss',
                              true
                            ).format('hh:mm A')}
                          />
                        </ListItem>
                      )}
                    </React.Fragment>
                  );
                })}
              </List>
            </Paper>
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
};

export default TimePickerNew;
