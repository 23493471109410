const createLeadScoreAnalyticsTableData = (data = []) => {
  if (!data && !data && !Array.isArray(data)) return [];
  return data.map((item) => {
    return {
      name: item,
      liveScore: item,
      liveLeadLevelId: item,
      onDemandScore: item,
      onDemandLevelId: item,
    };
  });
};

const filterApplyDisabledHandler = ({ oldData, newData }) => {
  let res = true;
  const oldLeadLevelIds = oldData.liveLevelIds.map((item) => item.id);
  const oldOnDemandLevelIds = oldData.onDemandLevelIds.map((item) => item.id);
  const newLeadLevelIds = newData.liveLevelIds.map((item) => item.id);
  const newOnDemandLevelIds = newData.onDemandLevelIds.map((item) => item.id);
  const unqLeadLevelIds = [...oldLeadLevelIds, ...newLeadLevelIds].filter(
    (item, index, arr) => arr.indexOf(item) === index && !arr.includes(item, index + 1)
  );
  const unqOnDemandLevelIds = [...oldOnDemandLevelIds, ...newOnDemandLevelIds].filter(
    (item, index, arr) => arr.indexOf(item) === index && !arr.includes(item, index + 1)
  );
  res = unqLeadLevelIds.length || unqOnDemandLevelIds.length;
  return Boolean(!res);
};
export { createLeadScoreAnalyticsTableData, filterApplyDisabledHandler };
