/* eslint-disable  */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { Box } from '@material-ui/core';
import SvgCaretDownIcon from '../../icons/svgCaretDown';
import { Calendar, Clock } from 'react-feather';
import FieldWrapper from './fieldWrapper';
import SvgCalenderNewIcon from 'icons/svgCalenderNewIcon';
const useStyles = makeStyles((theme) => ({
  adornmentIcon: {
    height: `0.01rem`,
    display: `flex`,
    maxHeight: `2rem`,
    alignItems: `center`,
    whiteSpace: `nowrap`,
  },
  startAdornment: {
    marginRight: `calc(8px * 1)`,
  },
  endAdornment: {
    marginLeft: `calc(8px * 1)`,
  },
  datePickerField: {
    color: theme.palette.text.primary,
    // cursor: 'pointer',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontWeight: 400,
    lineHeight: `1.1876em`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.bg.white,
    border: `1px solid ${theme.palette.border.dark}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    transition: theme.transitions.create(['box-shadow', 'color'], {
      easing: theme.transitions.easing.easeInOut,
    }),

    '&.borderNone': {
      border: 'none',
    },

    '&.Mui-focused': {
      //boxShadow: theme.inputBoxShadow
      boxShadow: 'none',
    },
    '&.right': {
      '& .flatpickr-wrapper': {
        '& .flatpickr-calendar': {
          left: 'auto',
          right: 0,
          '&::before': {
            right: 22,
            left: 'auto',
            display: 'none',
          },
          '&::after': {
            right: 22,
            left: 'auto',
            display: 'none',
          },
        },
      },
    },
    '&.hide': {
      padding: 0,
      '& > .MuiBox-root': {
        display: 'none',
      },
      '& .flatpickr-input': {
        display: 'none',
      },
    },
    '&.cursor-not-allowed .flatpickr-input': {
      cursor: 'not-allowed',
      color: theme.palette.text.grayColor,
    },
    '& .flatpickr-input': {
      font: `inherit`,
      color: theme.palette.text.primary,
      width: `100%`,
      border: 0,
      height: `1.1876em`,
      margin: 0,
      display: `block`,
      padding: `6px 0 7px`,
      fontSize: `0.875rem`,
      minWidth: 0,
      background: `none`,
      boxSizing: `content-box !important`,
      animationName: `mui-auto-fill-cancel`,
      letterSpacing: `inherit`,
      animationDuration: `10ms`,
      '-webkit-tap-highlight-color': 'transparent',
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&::-moz-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&:-ms-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&:-moz-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&::placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
    },
    '& .flatpickr-calendar': {
      width: 270,
      left: 0,
      boxShadow: '0 10px 35px rgb(0 0 0 / 19%)',
      borderRadius: 10,
      '&.showTimeInput.hasTime .flatpickr-time': {
        borderLeft: 0,
        borderRight: 0,
        borderBottom: 0,
        borderTopColor: '#DBDBDB',
      },
      '&.rightMost': {
        left: 'auto',
        right: 0,
      },
      '& .flatpickr-innerContainer': {
        width: 270,
        padding: '0 10px',
      },
    },
    '& .flatpickr-wrapper': {
      position: 'static',
      flexGrow: 1,
      '& .flatpickr-calendar': {
        '&.arrowBottom': {
          bottom: '100%',
          top: 'auto',
        },
      },
      '& .customPicker': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '&> .flatpickr-wrapper .flatpickr-calendar': {
      '& .flatpickr-weekday': {
        color: theme.palette.text.primary,
        fontWeight: 500,
      },
      '& .flatpickr-day': {
        fontSize: `0.82rem`,
        fontWeight: 600,
      },
      '& .flatpickr-day:hover': {
        backgroundColor: theme.palette.bg.whiteOff,
        color: theme.palette.text.primary,
        border: 'none',
      },
      '& .flatpickr-day.today': {
        backgroundColor: '#F6F8F8',
        color: theme.palette.primary.main + ' !important',
        border: 'none',
      },
      '& .flatpickr-day.selected': {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: theme.palette.primary.contrastText + ' !important',
        border: 'none',
        fontWeight: 500,
      },
      '& .flatpickr-next-month': {
        marginRight: 12,
        marginTop: 10,
        backgroundColor: theme.palette.bg.whiteOff1,
        border: '1px solid #DBDBDB',
        borderRadius: 5,
        width: 22,
        height: 22,
        padding: 0,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          height: 10,
          fill: theme.palette.text.primary,
        },
      },
      '& .flatpickr-prev-month': {
        marginLeft: 12,
        marginTop: 10,
        backgroundColor: theme.palette.bg.whiteOff1,
        border: '1px solid #DBDBDB',
        borderRadius: 5,
        width: 22,
        height: 22,
        padding: 0,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          height: 10,
          fill: theme.palette.text.primary,
        },
      },
      '&.arrowTop:after,&.arrowTop:before': {
        borderBottomColor: theme.palette.primary.main,
        // marginTop: 20,
        display: 'none',
      },
      '&.arrowBottom:after,&.arrowBottom:before': {
        borderTopColor: theme.palette.primary.main,
        // marginTop: 20,
        display: 'none',
      },
      '& .flatpickr-weekdays': {
        // borderBottom: '1px dashed #DBDBDB',
        // textTransform: 'uppercase',
        fontSize: '100%',
        marginTop: 10,
        width: 250,
      },
      '& .flatpickr-days': {
        width: 250,
        border: 0,
        padding: `${theme.spacing(1)}px 0`,
      },
      '& .flatpickr-day ': {
        maxWidth: 32,
        height: 32,
        lineHeight: `32px`,
      },
      '& .dayContainer': {
        width: 250,
        minWidth: 250,
        maxWidth: 250,
      },
      '& .flatpickr-monthDropdown-months': {
        fontSize: '14px',
        fontWeight: 500,
        marginRight: 10,
        '& .flatpickr-monthDropdown-month': {
          boxShadow: '0 10px 15px rgb(0 0 0 / 19%)',
          border: 0,
          background: theme.palette.primary.light,
        },
      },
      '& .flatpickr-current-month': {
        paddingTop: 10,
        '& .numInputWrapper': {
          background: 'none !important',
          '&:hover': {
            background: 'none !important',
          },
        },
      },
      '& .flatpickr-current-month input.cur-year': {
        fontSize: '1rem',
        fontWeight: 500,
      },
      '&> .flatpickr-months .flatpickr-month, .flatpickr-current-month .flatpickr-monthDropdown-months, flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month, .flatpickr-weekdays, span.flatpickr-weekday, .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay':
      {
        backgroundColor: '#fff',
        color: '#000',
        '&> .flatpickr-monthDropdown-month': {
          backgroundColor: '#fff',
          fontSize: 14,
          color: '#353535',
        },
      },
      '&> .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) ':
      {
        boxShadow: '- 10px 0 0 ' + theme.palette.primary.main,
      },
      '&> .flatpickr-day.week.selected': {
        borderRadius: 0,
        boxShadow:
          ('- 5px 0 0 ' + theme.palette.primary.main, '- 5px 0 0 ' + theme.palette.primary.main),
      },
    },
  },
}));

export default function DateTimePickerNew(props) {
  const {
    defaultValue = '',
    value = '',
    flatPickerClassName = 'customPicker',
    onOpen = () => { },
    onClose = () => { },
    onMonthChange = () => { },
    onYearChange = () => { },
    onReady = () => { },
    onValueUpdate = () => { },
    onDayCreate = () => { },
    onDestroy = () => { },
    disabled = false,
    onChange,
    enableDate = true,
    className = '',
    enableTime = false,
    placeholder = '',
    dateTimeFormat,
    position = '',
    size = 'medium',
    minuteIncrement = 1,
    minDate = '',
    defaultHour = '',
    defaultMin = '',
    minTime = '',
    maxDate = '',
    maxTime = '',
    disableDates = [],
    pickerRef,
    otherOptions = {},
    hideInput = false,
    positionAside = '',
    showAdornment = true,
    customIconStart,
    customIconEnd,
    // ...restProps
  } = props;

  const classes = useStyles();

  const [activeStatus, toggleActiveStatus] = React.useState(false);

  const handleOnOpen = (selectedDates, dateStr, instance) => {
    toggleActiveStatus(true);
    onOpen(selectedDates, dateStr, instance);
  };

  const handleOnClose = (e) => {
    toggleActiveStatus(false);
    onClose(e);
  };

  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      subTextComponent={props?.subTextComponent}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <Box
        className={`${hideInput ? 'hide' : ''} ${className || ''} ${positionAside} ${activeStatus ? 'Mui-focused' : ''
          } ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${classes.datePickerField} ${props.borderNone ? 'borderNone' : ''
          }`}
        data-size={size}>
        <Flatpickr
          data-input
          ref={pickerRef}
          defaultValue={defaultValue}
          className={`${flatPickerClassName}`}
          onOpen={handleOnOpen}
          onClose={handleOnClose}
          disabled={disabled}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          onReady={onReady}
          onValueUpdate={onValueUpdate}
          onDayCreate={onDayCreate}
          onDestroy={onDestroy}
          data-enable-time={enableTime}
          options={{
            wrap: true,
            static: true,
            position: position,
            dateFormat: dateTimeFormat,
            noCalendar: !enableDate,
            enableTime: enableTime,
            minuteIncrement: minuteIncrement,
            minDate: minDate,
            minTime: minTime,
            maxDate: maxDate,
            maxTime: maxTime,
            disable: disableDates,
            defaultMinute: defaultMin,
            defaultHour: defaultHour,
            ...otherOptions,
          }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}>
          {showAdornment ? (
            customIconStart ? (
              false
            ) : (
              <>
                <Box
                  data-toggle
                  color="text.grayColor"
                  component="span"
                  className={`${classes.startAdornment} ${classes.adornmentIcon}`}>
                  {!enableDate ? <Clock size={20} /> : <SvgCalenderNewIcon size={20} />}
                </Box>
              </>
            )
          ) : (
            false
          )}

          <input type="text" placeholder={props.placeholder} data-input disabled={disabled} />

          {showAdornment ? (
            customIconEnd ? (
              <Box
                data-toggle
                color="text.grayColor"
                component="span"
                className={`${classes.endAdornment} ${classes.adornmentIcon}`}>
                <SvgCalenderNewIcon size={20} />
              </Box>
            ) : (
              <Box
                data-toggle
                color="text.grayColor"
                component="span"
                className={`${classes.endAdornment} ${classes.adornmentIcon}`}>
                <SvgCaretDownIcon height="15px" width="15px" />
              </Box>
            )
          ) : (
            false
          )}
        </Flatpickr>
      </Box>
    </FieldWrapper>
  );
}
