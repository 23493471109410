import React from 'react';
import Box from '@material-ui/core/Box';

export default function LoaderForBuilder() {
  return (
    <Box
      height="100%"
      position="fixed"
      top="0"
      left="0"
      style={{ background: '#fff', zIndex: 1201 }}
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Box height="300px" width="300px">
        <img
          src="https://cdn.hubilo.com/assets/images/Preloader_7.gif"
          alt="no-found"
          style={{ height: '100%', width: '100%' }}
        />
      </Box>
    </Box>
  );
}
