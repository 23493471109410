import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CustomToolTip from 'custom-components/customToolTip';
import Checkbox2 from 'custom-components/form-fields/checkbox2';
import { useStyles } from './styles';
import { API, PUTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';
import { ENABLE_PREBUILT_FIELDS } from 'api-setup/api-endpoints';
import ProjectSetting from 'settings';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useHistory } from 'react-router-dom';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';

const { customBaseURL2 } = ProjectSetting;

const WebinarPreBuiltFields = ({ preBuiltFieldList, onClose, getUserFields }) => {
  const classes = useStyles();
  const [fieldList, setFieldList] = useState(preBuiltFieldList);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const source = useSource();
  const [headers] = API.generateHeader(reduxData, null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFieldList(preBuiltFieldList);
  }, [preBuiltFieldList]);

  // Checkbox checked and unchecked handling
  const handleChangeCheckBox = (e, index) => {
    const list = [...fieldList];
    list[index].isSelected = e.target.checked;
    setFieldList(list);
  };

  const isDisabled = useMemo(() => {
    let returnFlag = true;
    fieldList.map((field) => {
      if (field.isSelected) {
        returnFlag = false;
      }
      return null;
    });
    return returnFlag;
  }, [fieldList]);

  // formatting data and calling API
  const onAdd = () => {
    setIsLoading(true);
    const ids = [];
    fieldList.map((field) => {
      if (field.isSelected) ids.push(field._id);
      return field;
    });

    const names = [];
    fieldList.map((field) => {
      if (field.isSelected) names.push(field.fieldName);
      return field;
    });
    commonTrackEvent("add pre-built fields", { "org id": reduxData.organiserId, "field name": `[${[...names]}]` })
    const bodyData = {
      data: {
        ids,
      },
    };
    PUTAPIWITHCUSTOMDOMAIN(
      false,
      ENABLE_PREBUILT_FIELDS,
      source.AS,
      headers,
      bodyData,
      customBaseURL2
    )
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message) {
            dispatch(
              setNewMessage({
                message: resp.data.message,
                type: 'success',
                show: true,
              })
            );
          }
          onClose();
          getUserFields();
        } else {
          API.errStatusHandler(resp, history, dispatch);
          onClose();
        }
      })
      .catch(API.catchHandler);
  };

  const clearAll = () => {
    const list = fieldList.map((field) => {
      const tempField = field;
      if (tempField.isSelected) tempField.isSelected = false;
      return tempField;
    });
    commonTrackEvent("clear all pre-built fields", { "org id": reduxData.organiserId })
    setFieldList(list);
  };

  return (
    <>
      <Box className={classes.preBuiltFieldsArea}>
        <Box className="preBuiltFieldsTitle">
          <Box color="text.primary" display="table" position="relative">
            <Typography color="textSecondary" variant="h6" component="h6">
              Add Pre-Built Fields
              <CustomToolTip
                isFabIcon
                title="Select the fields you want to add to the registration form and click on Add.You can also edit the fields after adding them."
                placement="top"
              />
            </Typography>
          </Box>
        </Box>
        <Box className="preBuiltFieldsContainer">
          <Box className="preFieldOptionListArea">
            {fieldList.length > 0 ? (
              fieldList.map((field, index) => (
                <Box
                  key={field?._id}
                  className={`preFieldOptionList ${field.isSelected ? 'selected' : ''}`}>
                  <Checkbox2
                    name={field.fieldName}
                    color="primary"
                    formControlClassName="preFieldOption"
                    checked={field?.isSelected ?? false}
                    onChange={(e) => handleChangeCheckBox(e, index)}
                    label={
                      <>
                        <Typography variant="body1" color="textPrimary" component="span">
                          {field.fieldName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="span">
                          {field.userFiledType}
                        </Typography>
                      </>
                    }
                  />
                </Box>
              ))
            ) : (
              <Box className="noPreBuiltFields">
                <Typography variant="body2" color="textSecondary" component="span">
                  No Pre-Built Fields available to be added
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="preBuiltFooterSection">
          <BtnWithLoader
            size="small"
            variant="contained"
            color="primary"
            onClick={onAdd}
            isLoading={isLoading}
            label="Add"
            loadingLabel="Adding"
            disabled={isLoading || isDisabled}
          />

          <Box marginLeft={3}>
            <Button
              size="small"
              variant="text"
              className={isLoading || isDisabled ? 'disableClearButton' : ''}
              color="primary"
              onClick={clearAll}
              disabled={isLoading || isDisabled}>
              Clear All
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebinarPreBuiltFields;
