/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import CheckBox from './checkbox2';
import SelectDropdown from './selectDropdown';
import DefaultRestrictionInnerScreen from '../defaultRestrictionInnerScreen';
import CopyEventRestrictionScreen from '../copyEventRestrictionScreen';
import SvgEventRestriction from 'icons/svgEventRestriction';
import { useHistory } from 'react-router-dom';
import LearnMoreLink from 'learnMoreLinks';
import MultiLangOptions from 'utility/MultiLangOptions';
import { PricingPlan } from 'constants/pricingPlan';
import UpgardeMultiLangIcon from 'icons/animateIcon/upgardeMultiLangIcon';
import { setEventLanguages } from 'redux/actions/event-languages-action';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: 600,
  },
  danger: {
    color: theme.palette.text.danger1,
  },
  langDescription: {
    color: theme.palette.text.grayColor,
    marginTop: 5,
  },
  checkBoxStyle: {
    // backgroundColor: '#f6f6f6',
    borderRadius: 5,
    // paddingTop: 5,
    // paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    border: `1px solid ${theme.palette.primary.light}`,
    '& .MuiFormControl-root': {
      '& label': {
        '& .MuiFormControlLabel-label': {
          display: 'flex',
          alignItems: 'center',
          '& span': {
            lineHeight: 0,
            fontSize: '0.75rem',
            '& svg': {
              marginRight: 5,
            },
          },
          '&.Mui-disabled': {
            '& span': {
              color: theme.palette.text.disabled,
            },
          },
        },
        '& .Mui-checked ~ .MuiFormControlLabel-label': {
          color: theme.palette.text.primary,
          '& span': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
  },
  checkBoxStyleUnchecked: {
    // backgroundColor: '#f6f6f6',
    borderRadius: 5,
    // paddingTop: 5,
    // paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    // border:`1px solid ${theme.palette.primary.light}`,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    '& .MuiFormControl-root': {
      '& label': {
        '& .MuiFormControlLabel-label': {
          display: 'flex',
          alignItems: 'center',
          '& span': {
            lineHeight: 0,
            fontSize: '0.75rem',
            '& svg': {
              marginRight: 5,
            },
          },
          '&.Mui-disabled': {
            '& span': {
              color: theme.palette.text.disabled,
            },
          },
        },
        '& .Mui-checked ~ .MuiFormControlLabel-label': {
          color: theme.palette.text.primary,
          '& span': {
            color: theme.palette.primary.light,
          },
        },
      },
    },
  },
  fontWeight500: {
    fontWeight: 500,
  },
}));

const LanguageSettingsCopyEvent = ({
  title,
  languageSupportPlan,
  languageSupportPlanSidebar,
  supportedLanguages,
  handleLanguageChange,
  handleBaseLanguageChange,
  fromSettingsPage = false,
  eventLanguages,
  baseLang,
  subscriptionName,
  label,
  labelDescription,
  baseLanguageDescription,
  copyEventSupportPlan,
}) => {
  const {
    boldText,
    langDescription,
    checkBoxStyle,
    danger,
    checkBoxStyleUnchecked,
    fontWeight500,
  } = useStyles();
  const [selectedLanguages, setSelectedLanguages] = useState(eventLanguages || [34]);
  const history = useHistory();
  const [baseLanguage, setBaseLanguage] = useState(baseLang || 'English');
  const [availableLanguages, setAvailableLanguages] = useState(eventLanguages || ['English']);

  const findDispName = (name) => {
    let languages = '';
    if (supportedLanguages) {
      const langIndex = MultiLangOptions.findIndex((i) => i.langName === name);
      if (langIndex !== -1) {
        languages = MultiLangOptions[langIndex].langDisplayName;
      } else {
        languages = name;
      }
    }
    return languages;
  };

  const findLangName = (name) => {
    let languages = '';
    if (supportedLanguages) {
      const langIndex = MultiLangOptions.findIndex((i) => i.langDisplayName === name);
      if (langIndex !== -1) {
        languages = MultiLangOptions[langIndex].langName;
      } else {
        languages = name;
      }
    }
    return languages;
  };

  const findDisplayNameById = (id) => {
    const displayName = supportedLanguages
      ? (MultiLangOptions.find((i) => i.id === id)?.langDisplayName ?? '')
      : '';
    return displayName;
  };

  useEffect(() => {
    if (eventLanguages?.length > 0) {
      setSelectedLanguages(eventLanguages);
      let aL = eventLanguages?.map((e) => findDisplayNameById(e));
      setAvailableLanguages([...aL]);
      const res = aL?.findIndex((i) => {
        return i === baseLanguage;
      });
      if (aL?.length > 0) {
        if (!baseLang || res === -1) {
          setBaseLanguage(aL[0]);
        }
      }
    }
  }, [eventLanguages]);
  useEffect(() => {
    if (baseLang) {
      setBaseLanguage(findDisplayNameById(baseLang));
      // handleBaseLanguageChange(baseLang);
    }
  }, [baseLang]);
  return (
    <Grid item xs={12}>
      {!fromSettingsPage && (
        <Box mt={2} mb={2}>
          <Typography className={boldText} component="label" variant="body1">
            {title}
          </Typography>
          {/* <Typography variant="h6" className={`${boldText}`} component="h6">Languages to support</Typography> */}
        </Box>
      )}
      <Box>
        <Typography variant="subtitle2" component="p" required className={fontWeight500}>
          {label ? label : 'Languages to support'}{' '}
          {!languageSupportPlan &&
            subscriptionName &&
            (subscriptionName.includes(PricingPlan.ENTERPRISE) ||
              subscriptionName.includes(PricingPlan.FREE) ||
              subscriptionName.includes(PricingPlan.PRO)) && <span className={danger}>*</span>}
        </Typography>
        <Typography variant="caption" component="p" className={langDescription} required>
          {labelDescription
            ? labelDescription
            : 'Select the various languages that event needs to be supported. This cannot be edited later.'}
        </Typography>
      </Box>
      <Box mt={3}>
        <Grid container spacing={2}>
          {!languageSupportPlan &&
            !languageSupportPlanSidebar &&
            !copyEventSupportPlan &&
            supportedLanguages &&
            supportedLanguages.map((ele) => {
              return (
                <Grid item xs={6}>
                  <Box
                    className={
                      selectedLanguages.includes(ele.id) && !fromSettingsPage
                        ? checkBoxStyle
                        : checkBoxStyleUnchecked
                    }>
                    <CheckBox
                      disabled={fromSettingsPage}
                      checked={selectedLanguages.includes(ele.id)}
                      color="primary"
                      label={findDispName(ele.name)}
                      // isSvg={languageFlags[ele.name]}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const sL = [...selectedLanguages];
                          const aL = [...availableLanguages];
                          const dName = findDispName(ele.name);
                          sL.push(ele.id);
                          handleLanguageChange(sL);
                          setSelectedLanguages([...sL]);
                          aL.push(dName);
                          setAvailableLanguages([...aL]);
                        } else {
                          const sL = [...selectedLanguages];
                          const aL = [...availableLanguages];
                          const isLanguageExists = sL.includes(ele.id);
                          if (isLanguageExists) {
                            sL.splice(sL.indexOf(ele.id), 1);
                            aL.splice(aL.indexOf(findDispName(ele.name)), 1);
                            handleLanguageChange(sL);
                            setSelectedLanguages([...sL]);
                            setAvailableLanguages([...aL]);
                          }
                        }
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
        </Grid>

        {languageSupportPlan && (
          <DefaultRestrictionInnerScreen
            title="This feature is included in the following plans"
            subTitle="Multi Lingual support will allow you to host an event in multiple language and help the attendees joining from all areas of the globe. Upgrade the plan to unlock the benefits."
            svgIcon={<UpgardeMultiLangIcon width="22rem" height="10rem" />}
            btnLabel="Upgrade Now"
            planList={['Pro plan', 'Enterprise Plan']}
            btnAction={() => {
              history.push('/plans');
            }}
            languageSupportPlan
            learnMoreLink={LearnMoreLink?.MultiLingualSupport}
          />
        )}
        {copyEventSupportPlan && <CopyEventRestrictionScreen />}
        {languageSupportPlanSidebar && (
          <DefaultRestrictionInnerScreen
            title="This feature is included in the following plans"
            subTitle="Multi Lingual support will allow you to host an event in multiple language and help the attendees joining from all areas of the globe. Upgrade the plan to unlock the benefits."
            svgIcon={<UpgardeMultiLangIcon width="22rem" height="12rem" />}
            btnLabel="Upgrade"
            planList={['Pro plan', 'Enterprise Plan']}
            btnAction={() => {
              history.push('/plans');
            }}
            learnMoreLink={LearnMoreLink?.MultiLingualSupport}
          />
        )}
      </Box>
      {selectedLanguages.length >= 1 && (
        <>
          <Box mt={5}>
            <Typography variant="body2" component="p" required className={fontWeight500}>
              Base language {!languageSupportPlan && <span className={danger}>*</span>}
            </Typography>
            <Typography variant="caption" component="p" mb={5} className={langDescription} required>
              {baseLanguageDescription
                ? baseLanguageDescription
                : 'This will be the base language. The data you enter in base language will get copied to other languages as default content till you provide translation.'}
            </Typography>
          </Box>
          <Box mt={2}>
            <SelectDropdown
              disabled={fromSettingsPage || selectedLanguages.length === 1}
              placeholder="Select Base language"
              valueKey="id"
              position="top"
              required
              value={findDispName(baseLanguage)}
              options={availableLanguages}
              onChange={(e) => {
                setBaseLanguage(findLangName(e));
                const languageIndex = supportedLanguages.findIndex(
                  (item) => item.name === findLangName(e)
                );
                if (languageIndex !== -1)
                  handleBaseLanguageChange(supportedLanguages[languageIndex].id);
              }}
            />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default LanguageSettingsCopyEvent;
