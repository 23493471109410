/* eslint-disable global-require */
import React, { useEffect } from 'react';
import { Box, Container, Breadcrumbs, Link, Typography, Grid, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useStyles } from './style';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  commonSelector,
  copyEventSelector,
  eventLanguagesSelector,
  userInfoSelector,
  copyStatusSelector,
  getCopyEventVeAddOnCreditsModalOpen,
} from 'redux/custom-selector';
import {
  copyModulesForWebinar,
  COPY_EVENT_INIT_VALUES,
  COPY_EVENT_LABELS,
} from 'pages/registration/ticket/common/constants';
import BasicDetails from './steps/basic-details';
import CustomizeDetails from './steps/customize-details';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { InsufficientPopUp } from './pop-up/insufficientCreditPopUp';
import SvgTicketSmallIcon from 'icons/svgTicketSmallIcon';
import SvgCalSmallIcon from 'icons/svgCalSmallIcon';
import { API, GETAPI, POSTAPIWITHCUSTOMDOMAIN, GETAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import {
  CHECK_USER_CREDIT,
  COPY_EVENT_GET_BASIC_INFO_API,
  COPY_EVENT_API,
  GET_EVENT_LANG_SETTINGS,
} from 'api-setup/api-endpoints';
import { useHistory, useRouteMatch } from 'react-router';
import { setCopyEventData } from 'redux/actions/create-copy-event-action';
import { handleCopyEventValidations } from 'pages/registration/ticket/common/utils';
import { EMPTY_STR } from 'constants/common';
import moment from 'moment-timezone';
// import { formatDateWithTimeZone } from 'lib/common-function';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useSource } from 'lib/custom-common-hooks';
import projectSetting from 'settings';
import { setCopyingApiCall } from 'redux/actions/copy-status-action';
import Steppers from './steppers';
import amplitude from 'lib/amplitude';
import { PricingPlan } from 'constants/pricingPlan';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';
import MsgNotificationComponent from 'pages/CreateNewEvent/MsgNotificationComponent';
import InfoIcon from 'icons/svgInfoCopyEventIcon';
import LearnMoreLink from 'learnMoreLinks';
import { setCopyEventVeCreditsModal } from 'redux/actions/common';
import { AlertTriangle } from 'react-feather';
import PopUp from 'custom-components/popUp';
import CopyEventAddonsModal from './CopyEventAddonsModal';

const CUSTOM_URL = projectSetting.customBaseURL2;
const formatDateTimeStamp = (date) => `${moment(date).format('YYYY-MM-DD HH:mm')}`;

const getSameTimeClosestMoment = (timeMoment, addDays = 0) => {
  const nextDate = moment().add(addDays, 'days').format('YYYY-MM-DD');
  const nextTime = timeMoment.format('HH:mm:ss');
  return moment(`${nextDate} ${nextTime}`, 'YYYY-MM-DD HH:mm:ss').valueOf();
};

const getMinDateTime = (startTimeMilli, copyEventTz) => {
  if (startTimeMilli && copyEventTz) {
    const sourceEventDate = moment(+startTimeMilli).tz(copyEventTz);
    const todaySameTime = getSameTimeClosestMoment(sourceEventDate);
    const tomorrowSameTime = getSameTimeClosestMoment(sourceEventDate, 1);
    return moment().valueOf() < todaySameTime ? todaySameTime : tomorrowSameTime;
  }
};

export default function CreateCopyEvent() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [shouldShowSessionPopUp, setShouldShowSessionPopUp] = React.useState(false);
  const copyEventData = useSelector(copyEventSelector, shallowEqual);
  const isVeAddOnCreditsNotAvl = useSelector(getCopyEventVeAddOnCreditsModalOpen, shallowEqual);
  const copyEventInitState = COPY_EVENT_INIT_VALUES.EVENT_FIELDS(copyEventData);
  const [eventFields, setEventFields] = React.useState({
    ...copyEventInitState,
  });
  const [modules, setModules] = React.useState({
    sessions: 1,
    speakers: 1,
    lounges: 1,
    virtualbooths: 1,
    branding: 1,
    settings: 1,
    campaigns: 1,
    custom_landing_pages: 1,
  });
  const [registrationPopUp, setRegistrationPopUp] = React.useState({
    show: false,
    id: '',
  });
  const [sessionPopUp, setSessionPopUp] = React.useState({
    show: false,
    id: '',
  });
  const [loadingState, setLoadState] = React.useState({
    initLoader: false,
    nextLoad: false,
  });
  const [errObj, setErrObj] = React.useState({});
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const dispatch = useDispatch();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const history = useHistory();
  const match = useRouteMatch();
  const id = match?.params?.id;
  // const timeZone = copyEventData.timezone.name;
  const source = useSource();
  const isLanguageSupport = eventLanguages && eventLanguages.length > 1;
  const [baseLanguage, setBaseLanguage] = React.useState(34);
  const [isEventAddOnsOpen, setEventAddOnsOpen] = React.useState(false);
  const [selectedAddons, setSelectedAddons] = React.useState([]);
  const CopyStatus = useSelector(copyStatusSelector, shallowEqual);
  const isMLSupport =
    userInfo?.UserInfo?.planName === PricingPlan.ENTERPRISE ||
    userInfo?.UserInfo?.planName === PricingPlan.PRO ||
    userInfo?.UserInfo?.planName === PricingPlan.DIGITAL_EVENTS ||
    userInfo?.UserInfo?.planName === PricingPlan.ALL_IN ||
    userInfo?.UserInfo?.is_language_support;

  const triggerCopyEvent = () => {
    const startTimeMils = moment
      .tz(formatDateTimeStamp(eventFields?.start_date_time), eventFields?.timezone?.name)
      .utc()
      .valueOf();
    // moment
    //   .tz(formatDateWithTimeZone(eventFields.start_date_time), timeZone)
    //   .utc()
    //   .valueOf();
    const [headers] = API.generateHeader(reduxData);
    const eventData = {
      name: eventFields.name,
      description: eventFields.description ? eventFields.description : EMPTY_STR,
      timezoneId: eventFields.timezone?.id,
      startTimeMilli: startTimeMils,
      baseLanguage: eventFields?.base_language || CopyStatus?.copyEventBaseLang?.id || 34,
      eventLanguages: eventFields.event_languages,
      addOns: selectedAddons,
    };
    if (
      !(
        userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
        userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE
      )
    ) {
      delete eventData.addOns;
    }

    // auto select base language
    if (!eventData?.eventLanguages?.find((element) => element === eventData?.baseLanguage)) {
      eventData.baseLanguage = eventData?.eventLanguages[0];
    }

    const arr = Object.keys(modules).map((key) => ({ moduleName: key, value: modules[key] }));
    const selectedModules = arr.filter((e) => e.value === 1);
    const bodyData = {
      data: {
        sourceEventId: id,
        eventData,
        copyModules:
          copyEventData?.eventType === EEventFormat.WEBINAR
            ? copyModulesForWebinar
            : selectedModules.map((key) => key.moduleName),
      },
    };
    POSTAPIWITHCUSTOMDOMAIN(false, COPY_EVENT_API, source.AS, headers, { ...bodyData }, CUSTOM_URL)
      .then((res) => {
        const { status, success } = res?.data;
        if (status) {
          const { data } = success;
          // dispatch(
          //   setNewMessage({
          //     message: success?.message,
          //     type: 'success',
          //     show: true,
          //   })
          // );
          // dispatch(setNewCopiedEventIds(data?.newEventId));

          dispatch(
            setCopyingApiCall({
              newCopyEventId: data?.newEventId,
              newCopyEventName: eventFields?.name || '',
            })
          );
          history.push('/');
        } else {
          history.push('/');
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          dispatch(setCopyEventVeCreditsModal(true));
        } else {
          history.push('/');
        }
      });
  };

  const handlePopupNext = () => {
    if (shouldShowSessionPopUp) {
      setSessionPopUp({ ...sessionPopUp, show: true });
    } else {
      triggerCopyEvent();
    }
  };

  useEffect(() => {
    setEventFields((state) => ({
      ...state,
      event_languages: state?.event_languages?.length ? state.event_languages : [34],
      start_date_time: !state?.start_date_time
        ? getMinDateTime(copyEventInitState.start_date_time, CopyStatus.copyEventTz)
        : state?.start_date_time,
      timezone: state?.timezone || copyEventData?.timezone,
      timezone_id: state?.timezone?.id || copyEventData?.timezone?.id,
    }));
  }, [
    eventFields?.start_date_time,
    CopyStatus.copyEventTz,
    copyEventInitState.start_date_time,
    copyEventData,
  ]);

  useEffect(() => {
    if (
      (copyEventData?.event_languages &&
        Array.isArray(copyEventData?.event_languages) &&
        copyEventData?.event_languages?.length > 1) ||
      (eventFields?.event_languages &&
        Array.isArray(eventFields?.event_languages) &&
        eventFields?.event_languages?.length > 1)
    ) {
      setModules((prevState) => {
        return {
          ...prevState,
          custom_landing_pages: 0,
        };
      });
    } else if (!modules.custom_landing_pages) {
      setModules((prevState) => {
        return {
          ...prevState,
          custom_landing_pages: 1,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyEventData.event_languages, eventFields.event_languages]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicDetails
            eventFields={isMLSupport ? eventFields : { ...eventFields, event_languages: [34] }}
            setEventFields={setEventFields}
            errObj={errObj}
            setErrObj={setErrObj}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            loadingState={loadingState}
            setLoadState={setLoadState}
            multiLangObj={{
              isLanguageSupport,
            }}
          />
        );
      case 1:
        return (
          <CustomizeDetails
            eventFields={eventFields}
            setEventFields={setEventFields}
            modules={modules}
            setModules={setModules}
            loadingState={loadingState}
            setLoadState={setLoadState}
          />
        );
      default:
        return '';
    }
  };
  const handleBack = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setErrObj({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleNext = React.useCallback(() => {
    const error = handleCopyEventValidations(eventFields, activeStep, copyEventData.eventType);
    const noError = error === EMPTY_STR || Object.keys(error).length === 0;
    if (activeStep === 1 || (copyEventData.eventType === EEventFormat.WEBINAR && noError)) {
      dispatch(
        setCopyingApiCall({
          copyBtnDisable: true,
        })
      );
      amplitude.Track.copyEventOptions(id, modules);
      const [headers] = API.generateHeader(reduxData);
      const startTimeMils = moment
        .tz(formatDateTimeStamp(eventFields?.start_date_time), eventFields?.timezone?.name)
        .utc()
        .valueOf();
      // .tz(formatDateWithTimeZone(eventFields.start_date_time), eventFields?.timezone?.name)
      // .utc()
      // .valueOf();

      const eventData = {
        name: eventFields.name,
        description: eventFields.description ? eventFields.description : null,
        // timezone: eventFields.timezone,
        timezoneId: eventFields.timezone?.id,
        startTimeMilli: startTimeMils,
        baseLanguage: eventFields?.base_language || CopyStatus?.copyEventBaseLang?.id || 34,
        eventLanguages: eventFields.event_languages,
      };
      // auto select base language
      if (!eventData?.eventLanguages?.find((element) => element === eventData?.baseLanguage)) {
        eventData.baseLanguage = eventData?.eventLanguages[0];
      }

      const arr = Object.keys(modules).map(
        (key) => key !== 'custom_landing_pages' && { moduleName: key, value: modules[key] }
      );
      const selectedModules = arr.filter((e) => e.value === 1);

      const bodyData = {
        data: {
          sourceEventId: id,
          eventData,
          copyModules: selectedModules.map((key) => key.moduleName),
        },
      };
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        CHECK_USER_CREDIT,
        source.AS,
        headers,
        { ...bodyData },
        CUSTOM_URL
      )
        .then((resp) => {
          dispatch(
            setCopyingApiCall({
              copyBtnDisable: false,
            })
          );
          const { status, success } = resp?.data;
          if (status) {
            const { data } = success;
            if (data?.registrationCredit === false) {
              if (
                userInfo.UserInfo.subscriptionStatus === undefined ||
                userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
                userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE ||
                userInfo?.UserInfo?.planName === PricingPlan.DIGITAL_EVENTS ||
                userInfo?.UserInfo?.planName === PricingPlan.ALL_IN
              ) {
                triggerCopyEvent();
                // history.push('/');
              } else {
                setRegistrationPopUp({ ...registrationPopUp, show: true });
              }
              setShouldShowSessionPopUp(data?.sessionParallel === false);
            } else if (data?.sessionParallel === false) {
              if (
                userInfo.UserInfo.subscriptionStatus === undefined ||
                userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
                userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE ||
                userInfo?.UserInfo?.planName === PricingPlan.DIGITAL_EVENTS ||
                userInfo?.UserInfo?.planName === PricingPlan.ALL_IN
              ) {
                triggerCopyEvent();
                // history.push('/');
              } else {
                setSessionPopUp({ ...sessionPopUp, show: true });
              }
            } else if (data?.status) {
              triggerCopyEvent();
              // history.push('/');
            } else API.errStatusHandler(resp, { dispatch, history });
          }
        })
        .catch(API.catchHandler);
      return;
    }
    if (noError) {
      amplitude.Track.copyEventNextClick(id);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoadState({ ...loadingState, initLoader: false });
    } else {
      setErrObj(error);
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFields, activeStep, modules, selectedAddons]);

  useEffect(() => {
    const [headers, body] = API.generateHeader(
      reduxData,
      {
        eventId: id,
      },
      null,
      null
    );
    GETAPIWITHCUSTOMDOMAIN(false, GET_EVENT_LANG_SETTINGS, source.AS, headers, body, CUSTOM_URL)
      .then((res) => {
        if (res.data.status === API.apiSuccessStatus) {
          res.data.eventLanaguages.forEach((item) => {
            if (item.is_base === 1) {
              setBaseLanguage(item.name);
              dispatch(
                setCopyingApiCall({
                  copyEventBaseLang: item,
                })
              );
            }
          });
        } else {
          API.errStatusHandler(res, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    GETAPI(false, COPY_EVENT_GET_BASIC_INFO_API, null, {
      ...headers,
      eventid: id,
      language: undefined,
    })
      .then((resp) => {
        if (resp?.data?.status === API.apiSuccessStatus) {
          const eventInfo = resp?.data?.data?.eventinfo;
          if (eventInfo?.type === EEventFormat.WEBINAR)
            dispatch(setCopyEventData({ ...eventInfo, timeZone: null }));
          else dispatch(setCopyEventData({ ...eventInfo }));
          dispatch(
            setCopyingApiCall({
              copyEventTz: eventInfo?.timezone?.name,
            })
          );
          if (eventInfo?.type === EEventFormat.WEBINAR) setEventFields({ ...eventInfo });
          else setEventFields({ ...eventInfo, timezone: null });
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextCallback = () => {
    setEventAddOnsOpen(true);
  };

  const submitCallback = () => {
    handleNext();
  };

  return (
    <Box>
      <Box className={classes.copyEvent}>
        <Container maxWidth="lg">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit" href="/" underline="none">
              Events
            </Link>
            <Typography color="textPrimary">Copy Event</Typography>
          </Breadcrumbs>
          <Box my={2} mb={4.5} component="header" display="flex" justifyContent="space-between">
            <Box display="block" alignItems="center">
              <Typography component="h1" variant="h5">
                Copy Event
              </Typography>
            </Box>
          </Box>
          <Grid item xs={12}>
            {copyEventData?.eventType === EEventFormat.WEBINAR ? (
              <Box mb={4}>
                <MsgNotificationComponent
                  msgText="Session, Speakers, Branding, Settings and Custom Landing Page will get copied."
                  classType=" error"
                  maxWidth="530px"
                  className={classes.copyEventMsg}
                  icon={<InfoIcon />}
                  clicked={() => window.open(LearnMoreLink.copyEventWebinar, '_blank')}
                  linkText="Learn More"
                  ctaStyle={{ minWidth: '75px' }}
                />
              </Box>
            ) : (
              <Box width="100%" display="flex" alignItems="center" mb={4.5}>
                <Steppers activeStep={activeStep} />
              </Box>
            )}
            <Box>{getStepContent(activeStep)}</Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4.5} mb={3} className={classes.divider} />
            <Box>
              <Box
                className={
                  copyEventData.eventType === EEventFormat.WEBINAR
                    ? classes.finishBtn
                    : activeStep === 0
                    ? classes.oneButton
                    : classes.twoButton
                }>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  variant="outlined"
                  className={classes.secondaryButton}>
                  Previous
                </Button>
                <BtnWithLoader
                  className={classes.primaryButton}
                  onClick={
                    (userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
                      userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE) &&
                    ((copyEventData.eventType === EEventFormat.WEBINAR && activeStep === 0) ||
                      (copyEventData.eventType === EEventFormat.VIRTUAL && activeStep === 1))
                      ? handleNextCallback
                      : handleNext
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                  loadingLabel="Saving"
                  isLoading={loadingState.nextLoad}
                  label={
                    copyEventData.eventType === EEventFormat.WEBINAR
                      ? COPY_EVENT_LABELS.FINISH
                      : activeStep === 0
                      ? COPY_EVENT_LABELS.NEXT
                      : COPY_EVENT_LABELS.COPY
                  }
                  disabled={
                    eventFields?.event_languages?.length === 0 || CopyStatus?.copyBtnDisable
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
      <InsufficientPopUp
        title="Adding registration credits?"
        description="You will be charged for every additional user and the amount will be added to your subscription plan."
        customIcon={{
          icon: <SvgTicketSmallIcon />,
          className: 'svgIcon',
        }}
        insufficientPopUp={registrationPopUp}
        setInsufficientCreditPopUp={setRegistrationPopUp}
        triggerCopyEvent={() => {
          amplitude.Track.proceedClick(id, 'registration credits');
          handlePopupNext();
        }}
      />
      <InsufficientPopUp
        title="Adding a new parallel session?"
        description="You will be charged for every additional parallel session and the amount will be added to the subscription."
        customIcon={{
          icon: <SvgCalSmallIcon />,
          className: 'svgIcon',
        }}
        insufficientPopUp={sessionPopUp}
        setInsufficientCreditPopUp={setSessionPopUp}
        triggerCopyEvent={() => {
          amplitude.Track.proceedClick(id, 'parallel session credits');
          triggerCopyEvent();
        }}
      />
      {isVeAddOnCreditsNotAvl ? (
        <PopUp
          isOpen
          paddingBox={0.1}
          maxWidth={380}
          showCloseIcon={false}
          svgClassName={classes.exhasSvg}
          onClose={() => {
            dispatch(setCopyEventVeCreditsModal(false));
            history.push('/');
          }}
          btnMarginTop={2.5}
          data={{
            title: 'You have exhausted virtual event credits',
            secondaryDesc:
              'You have consumed your virtual event credits. Purchase credits to copy and create another virtual event.',
            Icon: AlertTriangle,
            actionBtn: [
              {
                name: 'Got it',
                type: 'primary',
                onClick: () => {
                  dispatch(setCopyEventVeCreditsModal(false));
                  history.push('/');
                },
              },
            ],
          }}
        />
      ) : null}
      {isEventAddOnsOpen ? (
        <CopyEventAddonsModal
          isOpen
          onClose={() => setEventAddOnsOpen(false)}
          eventType={copyEventData?.eventType}
          submitCallback={submitCallback}
          setSelectedAddons={setSelectedAddons}
          selectedAddons={selectedAddons}
        />
      ) : null}
    </Box>
  );
}
