import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import { DOWN_TIME_CONSTANTS, DOWN_TIME_MODAL } from 'constants/common';
import CustomModal from 'custom-components/customModal';
import { AlertTriangle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromLocalStorage } from 'utility/localStorageHandler';
import { downTimeModalAction } from './downTimeReducer';

const useStyles = makeStyles((theme) => ({
  svgDownTimeIcon: {
    width: 100,
    height: 100,
    color: theme.palette.text.grayColor1,
  },
}));

const DownTimeModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isOpen } = useSelector((state) => state.downTimeReducer);

  const lcStorageStatus = getDataFromLocalStorage(
    DOWN_TIME_CONSTANTS.DOWN_TIME_MODAL_LOCAL_STORAGE_STATUS
  );

  const isModalOpen = lcStorageStatus ? lcStorageStatus || isOpen : lcStorageStatus;

  const closeHandler = () => {
    dispatch(downTimeModalAction(false));
  };

  let jsx = <></>;

  if (DOWN_TIME_MODAL.IS_AVAILABLE) {
    jsx = (
      <CustomModal isOpen={isModalOpen} onClose={closeHandler}>
        <Box color="text.grayColor" mt={2} mb={4} maxWidth="500px" textAlign="center">
          <AlertTriangle className={classes.svgDownTimeIcon} />
          <Box mt={3} mb={1}>
            <Typography color="textPrimary" variant="h5" component="h3">
              Downtime for Maintenance
            </Typography>
          </Box>
          <Typography variant="caption" component="p">
            Due to scheduled annual maintenance activity starting from
            <b> 23rd December 2022 (12:00 am CST) until 27th December 2022 (11:59 PM CST)</b> all
            services of Hubilo Platform will remain unavailable. Please reach out to your respective
            CXM’s in case of any questions/queries or you can write to us on
            <Box
              href="mailto:support@hubilo.com"
              component="a"
              variant="body2"
              ml={0.3}
              className="text-underline  cursor-pointer">
              support@hubilo.com
            </Box>
          </Typography>
        </Box>
      </CustomModal>
    );
  }
  return jsx;
};

export default DownTimeModal;
