/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Box, makeStyles, Typography, Tooltip, Grid } from '@material-ui/core';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import { Lock } from 'react-feather';
import InputField from '../../custom-components/form-fields/inputField';
import BtnWithLoader from 'custom-components/btnWithLoader';
import TooltipIcon from '@material-ui/icons/InfoOutlined';
import { passwordRegex } from "models/regex";
import { genericAPICall } from "api-setup/api-helper";
import { SET_PASSWORD, SET_VERIFICATION_CODE } from "api-setup/api-endpoints";
import { useDispatch } from "react-redux";
import ProjectSetting from "settings";
import { setNewMessage } from "redux/actions/setting-actions";
import { removeAllFromQuery } from "lib/url-utilities";
import Counter from "./Counter";

const useStyles = makeStyles((theme) => ({
    anchorLink: {
        textDecoration: 'none',
        cursor: "pointer",
        marginLeft: 4,
        display: "inline-flex"
    },
    subTitleWithSeparator: {
        position: 'relative',
        textTransform: 'uppercase',
        '&::after': {
            content: `" "`,
            position: 'absolute',
            top: '50%',
            left: 0,
            height: 1,
            backgroundColor: theme.palette.border.light,
            width: '100%',
        },
        '& span': {
            backgroundColor: 'white',
            position: 'relative',
            zIndex: 1,
            padding: `0 ${theme.spacing(1)}px`,
        },
    },
    subTitle: {
        color: `${theme.palette.text.grayColor}`,
        display: `inline-block`,
    },
    fieldIcon: {
        color: `${theme.palette.text.grayColor} !important`,
    },
    warningMsg: {
        background: "#FFF0EC",
        borderRadius: 4,
        padding: 8
    },
    iconTooltipWrap: {
        "& svg": {
            color: theme.palette.text.secondary,
        }
    }
}));

const  ResetPassWord =  () => {
    const { anchorLink, iconTooltipWrap, warningMsg, subTitleWithSeparator, fieldIcon } = useStyles();
    const [isPasswordReset, setPasswordReset] = useState(false);
  const [isCodeEmail, setCodeEmail] = useState('');
  const [validStatus, setInvalidStatus] = useState(false);
  const dispatch = useDispatch();
    const history = useHistory();
    const [userCredential, setUserCredential] = useState({
      token: '',
      organiserId: ''
    });
    const [data, setData] = useState({
        new_password: '',
        confirm_new_password: '',
        code: '',
    });
    const [error, setError] = useState({
        password: '',
        confirmPassword: '',
        code: '',
    })
    const validPassword = (value) => {
        let passErr = '';
        const isNotValid = value !== '' && value.length > 0 && passwordRegex.test(value);
        if (value === '') {
          passErr = 'Please enter password'
        }
        else if (!isNotValid) {
          passErr = 'Please enter at least 7 character (1 number, 1 lowercase,  1 uppercase, 1 special character)'

        }
        setError((prev) => ({
            ...prev,
            password: passErr,
        }));
        if (passErr.length) return true;
        return false;
    }
    const valideConfirmPassword = (value) => {
        let conPassErr = '';
        if (value === '') {
          conPassErr = 'Please enter confirm password'
        } else if (value !== data.new_password) {
          conPassErr = "Entered passwords do not match."
        }
        setError((prev) => ({
            ...prev,
            confirmPassword: conPassErr,
        }));
        if (conPassErr.length) return true;
        return false;
    }

    const valideCode = (value) => {
        let codeErr = ''
        // const isValidNum = numberRegex.test(value);
        if (value === '') {
          codeErr = 'Please enter code'
        }
        // else if (!isValidNum) {
        //   codeErr = 'Please enter number code only'
        // }
        setError((prev) => ({
            ...prev,
            code: codeErr,
        }));
        if (codeErr.length) return true;
        return false;
    }
    const resendCode = (token, organiserId) => {
      const header = {
       Authorization: token, 
       organiserId
      };
      genericAPICall(
        SET_VERIFICATION_CODE,
        'get',
        {},
        {},
        header,
        false,
        ProjectSetting.customBaseURL
      )
        .then((response) => {
          if (response.data.status === 401) {
            dispatch(setNewMessage({ message: response.data.message, type: 'error', show: true }));
            history.push('/login');
          } else {
            setCodeEmail(response.data.email);
            dispatch(
              setNewMessage({ message: response.data.message, type: 'success', show: true })
            );
          }
        })
        .catch((errors) => {
          dispatch(
            setNewMessage({
              message: errors.response.data.message,
              type: 'error',
              show: true,
            })
          );
        });
    };
    useEffect(() => {
      const queryParams = history.location.search.split('=');
        if (history?.location) {
          if (history.location?.state && !userCredential.token.length) {
            const { organiser_id, token } = history.location?.state;
            setUserCredential({
              token,
              organiserId: organiser_id,
            });
            resendCode(token, organiser_id);
            removeAllFromQuery(history, history.location);
          } else {
            if (!(queryParams.length > 1) && !userCredential.token.length) {
              history.replace('/login');
            } else {
              if (queryParams.length > 1 && !userCredential.token.length) {
                setUserCredential({
                  token: queryParams?.[1]?.split('&')?.[0],
                  organiserId: Number(queryParams?.[2]),
                });
                removeAllFromQuery(history, history.location);
              }
            }
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

    const handleChange = (e) => {
        if (e.target.name === 'new_password') {
            validPassword(e.target.value);
        }
        if (e.target.name === 'confirm_new_password') {
            valideConfirmPassword(e.target.value);
        }
        if (e.target.name === 'code') {
          setInvalidStatus(false);
          valideCode(e.target.value);
        }
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const onHandleSubmit = (token, organiserId) => {
      const isConPasswordError = valideConfirmPassword(data.confirm_new_password.trim() || '');
      const isPasswordError = validPassword(data.new_password.trim() || '');
      const isValidCode = valideCode(data.code.trim() || '');
      const header = { Authorization: token, organiserId };
      if (!isConPasswordError && !isPasswordError && !isValidCode) {
        genericAPICall(
          SET_PASSWORD,
          'post',
          { data },
          {},
          header,
          false,
          ProjectSetting.customBaseURL
        )
          .then((response) => {
            if (response.data.status === 401) {
              dispatch(
                setNewMessage({ message: response.data.message, type: 'error', show: true })
              );
              history.push('/login');
            } else {
              setPasswordReset(true);
               dispatch(
                 setNewMessage({ message: response.data.message, type: 'success', show: true })
               );
            }
          })
          .catch((errors) => {
            if (errors.response.data.status === 401) {
              setInvalidStatus(true);
            }
            dispatch(
              setNewMessage({
                message: errors.response.data.message,
                type: 'error',
                show: true,
              })
            );
          });
      }
    };

    return (
      <>
        <Box mb={6} display="flex" justifyContent="center">
          <HubiloLogo height="70px" width="140px" />
        </Box>
        {isPasswordReset ? (
          <>
            <Typography
              className="text-center"
              style={{ marginBottom: 36 }}
              variant="h6"
              component="h4">
              Your password is reset!
            </Typography>
            <Typography
              style={{ marginBottom: 36 }}
              className="text-center"
              color="textSecondary"
              variant="body2">
              Use your new password to log in to your account.
            </Typography>
            <BtnWithLoader
              variant="contained"
              color="primary"
              type="submit"
              loadingLabel="Log in"
              label="Log in"
              fullWidth
              onClick={() => {
                history.replace('/login');
              }}
            />
          </>
        ) : (
          <>
            <Box mt={3}>
              <Box mb={4} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
                <Typography variant="caption" component="span">
                  SET YOUR PASSWORD
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                className={iconTooltipWrap}
                position="relative"
                mb={1.5}
                height={16}>
                <Tooltip
                  placement="top"
                  title={
                    <>
                      <Typography variant="caption">Password requirements:</Typography>
                      <Box component="ul" py={0} px={2} m={0}>
                        <Typography variant="caption" component="li">
                          At least 7 characters
                        </Typography>
                        <Typography variant="caption" component="li">
                          At least 1 upper case character
                        </Typography>
                        <Typography variant="caption" component="li">
                          At least 1 lower case character
                        </Typography>
                        <Typography variant="caption" component="li">
                          At least 1 number
                        </Typography>
                        <Typography variant="caption" component="li">
                          At least 1 special character
                        </Typography>
                      </Box>
                    </>
                  }>
                  <TooltipIcon fill="#808080" fontSize="small" />
                </Tooltip>
              </Box>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <InputField
                    required
                    placeholder="Password"
                    type="password"
                    id="new_password"
                    name="new_password"
                    value={data.new_password}
                    startAdornment
                    onChange={handleChange}
                    startIconAdornment
                    startAdornmentIcon={<Lock size={18} className={fieldIcon} />}
                    showFieldVisibilityBtn
                    error={error.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    required
                    placeholder="Confirm Password"
                    type="password"
                    id="confirm_new_password"
                    value={data.confirm_new_password}
                    name="confirm_new_password"
                    startAdornment
                    onChange={handleChange}
                    startIconAdornment
                    startAdornmentIcon={<Lock size={18} className={fieldIcon} />}
                    showFieldVisibilityBtn
                    showErrorMsg
                    error={error.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    required
                    placeholder="Enter code"
                      type="text"
                      maxLength={4}
                    id="code"
                    value={data.code}
                      isShowCustomDescText
                    error={
                      error.code
                        ? error.code
                        : validStatus
                        ? 'Incorrect code. Check your code again.'
                        : ''
                    }
                    onChange={handleChange}
                    name="code"
                  />
                </Grid>

                <Grid item xs={12}>
                  {(history.location?.state?.email || isCodeEmail) && (
                    <Box mb={2} className={warningMsg}>
                      <Typography component="p" color="textSecondary" variant="caption">
                        Your code has been sent to{' '}
                        <Typography variant="caption" component="span" color="textPrimary">
                          {history.location?.state?.email || isCodeEmail}
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                  <Counter anchorLink={anchorLink} handleClick={() =>{
                    resendCode(userCredential.token, userCredential.organiserId);
                  }}/>
                </Grid>
              </Grid>

              {/* <Box textAlign="left" mt={1}>
                        <Typography color="error" component="span" variant="caption">
                            Agreeing to the terms of service and privacy policy is required
                        </Typography>
                    </Box> */}

              <Box mt={4}>
                <BtnWithLoader
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    error.password !== '' ||
                    error.confirmPassword !== '' ||
                    error.code !== '' ||
                    data.code === '' ||
                    data.confirm_new_password === '' ||
                    data.new_password === ''
                  }
                  onClick={() => onHandleSubmit(userCredential.token, userCredential.organiserId)}
                  loadingLabel="Reset password"
                  label="Reset password"
                  fullWidth
                />
              </Box>
            </Box>
          </>
        )}
      </>
    );
}
export default ResetPassWord;