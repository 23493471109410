export const LEAD_SCORE_ACTION_TYPE = {
  GET_ACTIVITY_SCORE: 'GET_ACTIVITY_SCORE',
  GET_ACTIVITY_SCORE_LOADING: 'GET_ACT_SCORE_LOADING',
  SAVE_ACTIVITY_SCORE: 'SAVE_ACTIVITY_SCORE',
  SAVE_ACTIVITY_SCORE_LOADING: 'SAVE_ACTIVITY_SCORE_LOADING',
  RESET_ACTIVITY_SCORE_LOADING: 'RESET_ACTIVITY_SCORE_LOADING',
  DEFAULT_ACTIVITY_SCORE: 'DEFAULT_ACTIVITY_SCORE',

  GET_LEAD_SCORE: 'GET_LEAD_SCORE',
  GET_LEAD_LEVEL_LOADING: 'GET_LEAD_SCORE_LOADING',
  SAVE_LEAD_LEVEL: 'SAVE_LEAD_LEVEL_SCORE',
  SAVE_LEAD_SCORE_LOADING: 'SAVE_LEAD_SCORE_LOADING',
  RESET_LEAD_SCORE_LOADING: 'RESET_LEAD_SCORE_LOADING',
  DEFAULT_LEAD_SCORE: 'DEFAULT_LEAD_SCORE',
  UPDATE_LEAD_LEVEL_LABEL_LOADING: 'UPDATE_LEAD_LEVEL_LABEL_LOADING',
  GET_LEAD_LEVEL_DIST_LOADING: 'GET_LEAD_LEVEL_DIST_LOADING',
  GET_LEAD_LEVEL_DIST: 'GET_LEAD_LEVEL_DIST',

  GET_LEAD_OVERVIEW_LOADING: 'GET_LEAD_OVERVIEW_LOADING',
  GET_LEAD_OVERVIEW: 'GET_LEAD_OVERVIEW',
};

export const LEAD_SCORE_RESPONSE_MSG = {
  ACTIVITY_SCORE_SAVED: 'Scores saved successfully.',
  ACTIVITY_SCORE_RESET: 'Scores reset successfully.',
  LEAD_LEVELS_SAVED: 'Levels saved successfully.',
  LEAD_LEVELS_RESET: 'Levels reset successfully.',
  LEAD_LABEL_UPDATE: 'Label updated successfully.',
};
