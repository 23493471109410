export const SET_CONTEST_DATA = "SET_CONTEST_DATA";
export const REMOVE_CONTEST_DATA = "REMOVE_CONTEST_DATA";

export const setContestData = (contestData) => {
    return {
        type: SET_CONTEST_DATA,
        data: contestData
    }
}

export const removeContestData = () => {
    return {
        type: REMOVE_CONTEST_DATA
    }
}