import React from 'react';
import {
  Grid,
  Box,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
  ListItemText,
  List,
  Divider,
  ListItemAvatar,
} from '@material-ui/core';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import { useStyles } from './style';

export function IconSidebarLoader() {
  return (
    <Grid container spacing={2}>
      {[1, 1, 1, 1, 1, 1].map((item, key) => (
        <Grid item xs={2} key={key}>
          <SkeletonLoaders height="60px" width="60px" variant="square" />
        </Grid>
      ))}
    </Grid>
  );
}

export const SubSectionLoad = () => {
  const classes = useStyles();
  return (
    <>
      {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((it, key) => (
        <ListItem key={key} disableGutters className={classes.eventNameBlock}>
          <ListItemIcon>
            <Box ml={1}>
              <SkeletonLoaders variant="rect" height="20px" width="16px" />
            </Box>
            <Box ml={1} mr={1}>
              <SkeletonLoaders variant="square" height="24px" width="24px" />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" component="label">
                <SkeletonLoaders variant="text" height="22px" width="80px" />
              </Typography>
            }
            secondary={
              <Typography variant="body2" component="caption">
                <SkeletonLoaders variant="text" height="18px" width="50px" />
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <SkeletonLoaders variant="round" height="20px" width="20px" />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </>
  );
};
export const EventSectionRight = () => {
  const classes = useStyles();
  return (
    <Box width="50%" className={`${classes.peopleBlock}`} p={2}>
      <Box>
        <Box pb={2} className={classes.appSectionHeading}>
          <Typography variant="body1" className={classes.headingTitle} component="h4">
            <SkeletonLoaders variant="text" height="26px" width="52px" />
          </Typography>
        </Box>
        <Box py={2.5} className={classes.appRightBlockContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="label" variant="body2">
                <SkeletonLoaders variant="text" height="19px" width="34px" />
              </Typography>
              <Box mt={1.2}>
                <SkeletonLoaders variant="square" height="110px" width="110px" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SkeletonLoaders variant="text" height="19px" width="40px" />
              <Box mt={1}>
                <SkeletonLoaders variant="rect" height="44px" width="100%" />
              </Box>
            </Grid>
          </Grid>
          <Box mt={3}>
            <List>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="225px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="212px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="238px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem dense disableGutters divider />
              <ListItem disableGutters className={classes.peopleSubTitle}>
                <ListItemText
                  primary={
                    <Typography variant="body2" className={classes.headingTitle} component="h5">
                      <SkeletonLoaders variant="text" height="26px" width="145px" />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="66px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="65px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="59px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem dense disableGutters divider />
              <ListItem disableGutters className={classes.peopleSubTitle}>
                <ListItemText
                  primary={
                    <Typography variant="body2" className={classes.headingTitle} component="h5">
                      <SkeletonLoaders variant="text" height="26px" width="211px" />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="60px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="66px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Typography variant="body2" component="label">
                      <SkeletonLoaders variant="text" height="22px" width="59px" />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Box className={classes.switchBtnStyle}>
                    <SkeletonLoaders
                      variant="rect"
                      height="20px"
                      width="40px"
                      skeletonProps={{ borderRadius: '15px !important' }}
                    />
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
      <Box display="flex" pt={2} mt={2} className={classes.footerActionBtn}>
        <SkeletonLoaders
          height="30px"
          width="90px"
          skeletonProps={{ borderRadius: '4px !important' }}
          variant="rect"
        />
        <Box ml={1.2}>
          <SkeletonLoaders
            height="30px"
            width="70px"
            skeletonProps={{ borderRadius: '4px !important' }}
            variant="rect"
          />{' '}
        </Box>
      </Box>
    </Box>
  );
};
export const EventSectionLoader = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box mt={4} mb={3}>
        <SkeletonLoaders height="20px" width="120px" variant="text" />
        <SkeletonLoaders height="14px" width="70px" variant="text" />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width="46%" className={classes.eventBlockSetting}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List className={classes.eventList}>
                {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((it, key) => (
                  <ListItem key={key} disableGutters className={classes.eventNameBlock}>
                    <ListItemIcon>
                      <Box ml={1}>
                        <SkeletonLoaders variant="rect" height="20px" width="16px" />
                      </Box>
                      <Box ml={1} mr={1}>
                        <SkeletonLoaders variant="square" height="24px" width="24px" />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="label">
                          <SkeletonLoaders variant="text" height="22px" width="80px" />
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" component="caption">
                          <SkeletonLoaders variant="text" height="18px" width="50px" />
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <SkeletonLoaders variant="round" height="20px" width="20px" />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
        <EventSectionRight />
      </Box>
    </Box>
  );
};

export const WelcomeMailerLoader = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SkeletonLoaders width="99px" height="22px" variant="text" />
        <Box mt={0.7}>
          <SkeletonLoaders width="100%" height="44px" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders width="78px" height="22px" variant="text" />
        <Box mt={0.7}>
          <SkeletonLoaders width="100%" height="44px" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders width="82px" height="22px" variant="text" />
        <Box mt={0.7}>
          <SkeletonLoaders width="100%" height="198px" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <SkeletonLoaders width="115px" height="22px" variant="text" />
        <Box mt={0.7}>
          <SkeletonLoaders width="100%" height="44px" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" component="label">
            <SkeletonLoaders width="144px" height="22px" variant="text" />
          </Typography>
          <Box className={classes.switchBtnStyle}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export const TicketLoader = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Box mb={1}>
              <SkeletonLoaders width="80px" height="22px" variant="text" />
            </Box>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TaxAndInvoicingLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SkeletonLoaders width="211px" height="16px" variant="text" />
        <SkeletonLoaders
          height="20px"
          width="40px"
          skeletonProps={{ borderRadius: '15px !important' }}
          variant="rect"
        />
      </Box>
      <Box mt={2} mb={2.5}>
        <Typography variant="body1" component="h2" className={classes.boldText}>
          <SkeletonLoaders width="211px" height="16px" variant="text" />
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SkeletonLoaders height="14px" variant="text" width="86px" />
              <Box mt={0.7}>
                <SkeletonLoaders height="44px" width="100%" variant="rect" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <SkeletonLoaders height="14px" variant="text" width="86px" />
              <Box mt={0.7}>
                <SkeletonLoaders height="44px" width="100%" variant="rect" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <SkeletonLoaders height="20px" variant="square" width="20px" />
            <Box ml={1.5}>
              <SkeletonLoaders height="19px" variant="text" width="153px" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" component="h2" className={classes.boldText}>
            <SkeletonLoaders width="211px" height="16px" variant="text" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SkeletonLoaders height="14px" variant="text" width="86px" />
              <Box mt={0.7}>
                <SkeletonLoaders height="44px" width="100%" variant="rect" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <SkeletonLoaders height="14px" variant="text" width="86px" />
              <Box mt={0.7}>
                <SkeletonLoaders height="44px" width="100%" variant="rect" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="95px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="116px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <SkeletonLoaders height="14px" variant="text" width="86px" />
          <Box mt={0.7}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <SkeletonLoaders height="20px" variant="square" width="20px" />
            <Box ml={1.5}>
              <SkeletonLoaders height="19px" variant="text" width="153px" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
/** studioHosts.jsx */
export const SessionLoader = () => {
  const { ...classes } = useStyles();
  return (
    <Box p={2.5} className={`${classes.sessionBlock}`}>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <SkeletonLoaders height="15px" width="9px" variant="rect" />
        </Box>
        <Typography component="p" color="textPrimary" variant="body2">
          <SkeletonLoaders height="19px" width="120px" variant="text" />
        </Typography>
      </Box>
      <Box mt={0.8}>
        <Typography variant="caption" color="textSecondary" component="p">
          <SkeletonLoaders height="15px" width="217px" variant="text" />
        </Typography>
      </Box>
    </Box>
  );
};
/** seo.jsx */
export const SeoLoader = () => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="34px" />
          <Box mt={1}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="115px" />
          <Box mt={1}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="129px" />
          <Box mt={1}>
            <SkeletonLoaders variant="rect" height="198px" width="100%" />
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Divider />
      </Box>
      <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography component="h4" variant="h6">
            <SkeletonLoaders variant="text" height="30px" width="213px" />
          </Typography>
          <Box color="text.grayColor">
            <Typography component="p" variant="body2">
              <SkeletonLoaders variant="text" height="22px" width="213px" />
            </Typography>
          </Box>
        </Box>
        <Box>
          <SkeletonLoaders
            variant="rect"
            height="30px"
            width="122px"
            skeletonProps={{ borderRadius: '15px !important' }}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SkeletonLoaders variant="rect" height="94px" width="224px" />
          </Grid>
          <Grid item xs={4}>
            <SkeletonLoaders variant="rect" height="94px" width="224px" />
          </Grid>
          <Grid item xs={4}>
            <SkeletonLoaders variant="rect" height="94px" width="224px" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
/** senderDetails.jsx */

export const SenderLoader = () => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="40px" />
          <Box mt={0.7}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="17px" />
          <Box mt={0.7}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="26px" />
          <Box mt={0.7}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders variant="text" height="22px" width="96px" />
          <Box mt={0.7}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export const PrivacyLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List disablePadding>
            <ListItem dense disableGutters className={classes.userConsent}>
              <ListItemText
                primary={
                  <Typography variant="h6" component="h5">
                    <SkeletonLoaders variant="text" height="30px" width="116px" />
                  </Typography>
                }
                secondary={
                  <Typography component="span" variant="body2" className={classes.consentSubTitle}>
                    <SkeletonLoaders variant="text" height="22px" width="516px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Box mt={2.5}>
            <SkeletonLoaders
              variant="rect"
              height="30px"
              width="129px"
              skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
            />
          </Box>
          <List className={classes.consentList} disablePadding>
            <SkeletonLoaders variant="rect" height="118px" width="100%" />
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Divider className={classes.privacyDividerBlock} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            <ListItem dense disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="h5" className={classes.headingTitle}>
                    <SkeletonLoaders variant="text" height="30px" width="130px" />
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="label">
                    <SkeletonLoaders variant="text" height="22px" width="270px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="label">
                    <SkeletonLoaders variant="text" height="22px" width="282px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="label">
                    <SkeletonLoaders variant="text" height="22px" width="244px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="label">
                    <SkeletonLoaders variant="text" height="22px" width="275px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body2" component="label">
                    <SkeletonLoaders variant="text" height="22px" width="352px" />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters divider />
          </List>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2.5}>
          <Typography variant="body2" component="h5" className={classes.headingTitle}>
            <SkeletonLoaders variant="text" height="22px" width="102px" />
          </Typography>
        </Box>
        <List>
          <ListItem disableGutters>
            <ListItemText
              primary={
                <Typography variant="body2" component="label">
                  <SkeletonLoaders variant="text" height="22px" width="280px" />
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Box className={classes.switchBtnStyle}>
                <SkeletonLoaders
                  variant="rect"
                  height="20px"
                  width="40px"
                  skeletonProps={{ borderRadius: '15px !important' }}
                />
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Box mt={2.7}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SkeletonLoaders variant="text" height="22px" width="58px" />
              <Box mt={1}>
                <SkeletonLoaders variant="rect" height="44px" width="100%" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <SkeletonLoaders variant="text" height="22px" width="58px" />
              <Box mt={1}>
                <SkeletonLoaders variant="rect" height="44px" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export const PrivacySidebarLoader = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <SkeletonLoaders height="13px" width="88px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="36px" width="180px" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders height="13px" width="96px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders height="13px" width="96px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="200px" width="100%" variant="rect" />
        </Box>
        <Box mt={1} color="text.grayColor">
          <Typography variant="body2" component="p">
            <SkeletonLoaders height="20px" width="500px" variant="text" />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <SkeletonLoaders height="24px" width="24px" variant="square" />
          <Box ml={1.5}>
            <SkeletonLoaders height="19px" width="71px" variant="text" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const GroupLoader = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      <Grid item lg={6} xs={12}>
        <Box className={classes.groupSettings}>
          <List className={classes.groupEventList}>
            <ListItem disableGutters className={classes.groupEventNameBlock}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="24px" width="90px" />
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="20px" width="75px" />
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters className={classes.groupEventNameBlock}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="24px" width="90px" />
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="20px" width="75px" />
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters className={classes.groupEventNameBlock}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="24px" width="90px" />
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="20px" width="75px" />
                  </Typography>
                }
              />
            </ListItem>
            <ListItem disableGutters className={classes.groupEventNameBlock}>
              <ListItemText
                primary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="24px" width="90px" />
                  </Typography>
                }
                secondary={
                  <Typography variant="body1" component="label">
                    <SkeletonLoaders variant="text" height="20px" width="75px" />
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Box className={classes.attendeeBlock} pt={3} pb={2} pl={3} pr={3}>
          <Box className={classes.attendeeWrapper} p={1}>
            <Box pb={3} mb={3} className={classes.attendeeHeading}>
              <Typography variant="body1" className={classes.headingTitle} component="h4">
                <SkeletonLoaders variant="text" height="26px" width="78px" />
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SkeletonLoaders variant="text" height="22px" width="91px" />
                <Box mt={1}>
                  <SkeletonLoaders variant="rect" height="44px" width="100%" />
                </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Box className={classes.groupDividerBlock}>
                <Divider />
              </Box>
              <List className={classes.attendeeList} disablePadding>
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="157px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="66px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="65px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="59px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="132px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="66px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="65px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="59px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="96px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem dense disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="109px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="75px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="70px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="57px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="96px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="124px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="128px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="173px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box display="flex" pt={2} mt={2} className={classes.footerActionBtn}>
            <SkeletonLoaders
              height="30px"
              width="90px"
              skeletonProps={{ borderRadius: '15px !important' }}
              variant="rect"
            />
            <Box ml={1.2}>
              <SkeletonLoaders
                height="30px"
                width="70px"
                skeletonProps={{ borderRadius: '15px !important' }}
                variant="rect"
              />{' '}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const GroupEditLoader = () => {
  const classes = useStyles();
  return (
    <Grid item lg={6} xs={12}>
      <Box className={classes.attendeeBlock}>
        <Box>
          <Box px={3} py={2.5} className={classes.appSectionHeading}>
            <Typography variant="body1" className={classes.headingTitle} component="h4">
              <SkeletonLoaders variant="text" height="26px" width="78px" />
            </Typography>
          </Box>
          <Box px={3} py={2.5} className={classes.appRightBlockContent}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SkeletonLoaders variant="text" height="22px" width="91px" />
                <Box mt={1}>
                  <SkeletonLoaders variant="rect" height="44px" width="100%" />
                </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Box className={classes.groupDividerBlock}>
                <Divider />
              </Box>
              <List className={classes.attendeeList} disablePadding>
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="157px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="66px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="65px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="59px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="132px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="66px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="65px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="59px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="96px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem dense disableGutters divider />
                <ListItem disableGutters className={classes.listSubTitle}>
                  <ListItemText
                    primary={
                      <Typography variant="body2" className={classes.headingTitle} component="h5">
                        <SkeletonLoaders variant="text" height="26px" width="109px" />
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="75px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="70px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="57px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="96px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="124px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="128px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography variant="body2" component="label">
                        <SkeletonLoaders variant="text" height="22px" width="173px" />
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box className={classes.switchBtnStyle}>
                      <SkeletonLoaders
                        variant="rect"
                        height="20px"
                        width="40px"
                        skeletonProps={{ borderRadius: '15px !important' }}
                      />
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
        <Box display="flex" px={3} py={2.5}>
          <SkeletonLoaders
            height="30px"
            width="90px"
            skeletonProps={{ borderRadius: '4px !important' }}
            variant="rect"
          />
          <Box ml={1.2}>
            <SkeletonLoaders
              height="30px"
              width="70px"
              skeletonProps={{ borderRadius: '4px !important' }}
              variant="rect"
            />{' '}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export const LegalLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" component="h4">
              <SkeletonLoaders width="170px" height="28px" variant="text" />
            </Typography>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                width="40px"
                height="20px"
                variant="rect"
                skeletonProps={{ borderRadius: '15px !important' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders width="40px" height="22px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders width="100%" height="44px" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography component="label" variant="body2">
              <SkeletonLoaders width="90px" height="22px" variant="text" />
            </Typography>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                width="40px"
                height="20px"
                variant="rect"
                skeletonProps={{ borderRadius: '15px !important' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders width="247px" height="22px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders width="100%" height="198px" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders width="176px" height="22px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders width="100%" height="44px" variant="rect" />
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} mb={4}>
        <Divider className={classes.legalDividerBlock} />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" component="h4">
              <SkeletonLoaders width="117px" height="28px" variant="text" />
            </Typography>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                width="40px"
                height="20px"
                variant="rect"
                skeletonProps={{ borderRadius: '15px !important' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders width="40px" height="22px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders width="100%" height="44px" variant="rect" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" component="label">
              <SkeletonLoaders width="90px" height="22px" variant="text" />
            </Typography>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                width="40px"
                height="20px"
                variant="rect"
                skeletonProps={{ borderRadius: '15px !important' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SkeletonLoaders width="205px" height="22px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders width="100%" height="198px" variant="rect" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export const LoginOptionLoader = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <List className={`${classes.borderBlock}`}>
        <ListItem dense disableGutters>
          <ListItemText
            primary={
              <Typography variant="body1" className={classes.headingTitle} component="h4">
                <SkeletonLoaders variant="text" height="24px" width="114px" />
              </Typography>
            }
            secondary={
              <Box color="text.grayColor" component="span">
                <Typography variant="caption" component="span">
                  <SkeletonLoaders variant="text" height="16px" width="370px" />
                </Typography>
              </Box>
            }
          />
          <ListItemSecondaryAction>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                variant="rect"
                height="20px"
                width="40px"
                skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
              />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters divider />
        {[1, 1].map((x, index) => (
          <React.Fragment key={`test-${index}`}>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    <SkeletonLoaders variant="text" height="24px" width="114px" />
                  </Typography>
                }
                secondary={
                  <Box color="text.grayColor" component="span">
                    <Typography variant="caption" component="span">
                      <SkeletonLoaders variant="text" height="16px" width="370px" />
                    </Typography>
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <Box className={classes.switchBtnStyle}>
                  <SkeletonLoaders
                    variant="rect"
                    height="20px"
                    width="40px"
                    skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
                  />
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters divider />
          </React.Fragment>
        ))}
        <ListItem disableGutters className={classes.socialLoginTitle}>
          <ListItemText
            primary={
              <Typography variant="body2" className={classes.headingTitle} component="h5">
                <SkeletonLoaders
                  variant="text"
                  height="20px"
                  width="104px"
                  skeletonProps={{ display: 'block' }}
                />
              </Typography>
            }
            secondary={
              <Box color="text.grayColor" component="span">
                <Typography variant="caption" component="span">
                  <SkeletonLoaders variant="text" height="16px" width="480px" />
                </Typography>
              </Box>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <SkeletonLoaders variant="square" height="15px" width="15px" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="label">
                <SkeletonLoaders variant="text" height="20px" width="62px" />
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                variant="rect"
                height="20px"
                width="40px"
                skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
              />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <SkeletonLoaders variant="square" height="15px" width="15px" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="label">
                <SkeletonLoaders
                  variant="text"
                  height="20px"
                  width="55px"
                  skeletonProps={{ display: 'block' }}
                />
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                variant="rect"
                height="20px"
                width="40px"
                skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
              />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <SkeletonLoaders variant="square" height="15px" width="15px" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="label">
                <SkeletonLoaders
                  variant="text"
                  height="20px"
                  width="77px"
                  skeletonProps={{ display: 'block' }}
                />
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                variant="rect"
                height="20px"
                width="40px"
                skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
              />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon>
            <SkeletonLoaders variant="square" height="15px" width="15px" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="label">
                <SkeletonLoaders
                  variant="text"
                  height="20px"
                  width="49px"
                  skeletonProps={{ display: 'block' }}
                />
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Box className={classes.switchBtnStyle}>
              <SkeletonLoaders
                variant="rect"
                height="20px"
                width="40px"
                skeletonProps={{ borderRadius: '15px !important', display: 'block' }}
              />
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Box className={`${classes.borderBlock}`} mt={3.75}>
        <Box>
          <Typography variant="body1" className={classes.headingTitle} component="h4">
            <SkeletonLoaders variant="text" height="24px" width="108px" />
          </Typography>
          <Box color="text.grayColor">
            <Typography variant="caption" component="p">
              <SkeletonLoaders variant="text" height="20px" width="517px" />
            </Typography>
          </Box>
        </Box>
        <Box mt={2.5}>
          <Divider className={classes.loginDividerBlock} />
        </Box>
        <Box mt={2.5}>
          <SkeletonLoaders variant="text" height="22px" width="40px" />
          <Box mt={1}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Box>
      </Box>
      <Box className={`${classes.borderBlock}`} mt={3.75}>
        <Box>
          <Typography variant="body1" className={classes.headingTitle} component="h4">
            <SkeletonLoaders variant="text" height="24px" width="108px" />
          </Typography>
          <Box color="text.grayColor">
            <Typography variant="caption" component="p">
              <SkeletonLoaders variant="text" height="20px" width="100%" />
            </Typography>
          </Box>
        </Box>
        <Box mt={2.5}>
          <Divider className={classes.loginDividerBlock} />
        </Box>
        <Box mt={2.5}>
          <SkeletonLoaders variant="text" height="22px" width="40px" />
          <Box mt={1}>
            <SkeletonLoaders variant="rect" height="44px" width="100%" />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export const MatchSidebarLoader = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SkeletonLoaders height="19px" width="50px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <SkeletonLoaders height="24px" width="24px" variant="square" />
          <Box ml={1.5}>
            <SkeletonLoaders height="19px" width="210px" variant="text" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders height="19px" width="50px" variant="text" />
        <Box mt={1.2}>
          <SkeletonLoaders height="209px" width="100%" variant="rect" />
        </Box>
      </Grid>
    </Grid>
  );
};
export const MatchLoader = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Box className={classes.fieldsEventNameBlock}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="label">
            <SkeletonLoaders variant="text" height="30px" width="68px" />
          </Typography>
          <Box className={classes.switchBtnStyle}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Box>
        </Box>
        <Box mt={2.5}>
          <SkeletonLoaders
            variant="rect"
            height="30px"
            width="111px"
            skeletonProps={{ borderRadius: '15px !important' }}
          />
        </Box>
      </Box>
      <Box className={classes.fieldsEventNameBlock}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="label">
            <SkeletonLoaders variant="text" height="30px" width="72px" />
          </Typography>
          <Box className={classes.switchBtnStyle}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.fieldsEventNameBlock}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" component="label">
            <SkeletonLoaders variant="text" height="30px" width="180px" />
          </Typography>
          <Box className={classes.switchBtnStyle}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Box>
        </Box>
        <Box mt={2.5}>
          <SkeletonLoaders
            variant="rect"
            height="30px"
            width="111px"
            skeletonProps={{ borderRadius: '15px !important' }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export function BulkBoothLoader() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
          <SkeletonLoaders height="19px" width="300px" variant="text" />
          <SkeletonLoaders
            height="30px"
            width="130px"
            variant="rect"
            skeletonProps={{ borderRadius: '20px !important' }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <SkeletonLoaders height="19px" width="200px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="70px" width="100%" variant="rect" />
        </Box>
      </Grid>
    </Grid>
  );
}
export const NotificationLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography component="h5" variant="body2" className={classes.notificationTitle}>
            <SkeletonLoaders variant="text" height="20px" width="58px" />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography component="h5" variant="body2" className={classes.notificationTitle}>
            <SkeletonLoaders variant="text" height="20px" width="41px" />
          </Typography>
        </Grid>
        {/* <Grid item xs={3}>
                    <Typography component="h5" variant="body2" className={classes.notificationTitle}>
                        <SkeletonLoaders variant="text" height="20px" width="45px" />
                    </Typography>
                </Grid> */}
      </Grid>
      <Box mt={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="83px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="97px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="111px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="127px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="123px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="129px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="101px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width="74px" />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <SkeletonLoaders
              variant="rect"
              height="20px"
              width="40px"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Grid>
          {/* <Grid item xs={3}>
                        <Box pt={1} pb={1}><SkeletonLoaders variant="rect" height="20px" width="40px" skeletonProps={{ borderRadius: "15px !important" }} /></Box>
                    </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export const IntegrationLoader = () => {
  const classes = useStyles();
  return (
    <List disablePadding>
      {[1, 1].map((it, key) => (
        <>
          <ListItem key={key} component="li" className={classes.integrationList}>
            <ListItemAvatar>
              <Box component="figure" className={classes.large} display="flex" alignItems="center">
                <SkeletonLoaders
                  height="48px"
                  width="48px"
                  variant="square"
                  skeletonProps={{ borderRadius: '50% !important' }}
                />
              </Box>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography component="h5" variant="h6">
                  <SkeletonLoaders height="28px" width="50px" variant="text" />
                </Typography>
              }
              secondary={
                <Typography component="p" variant="body2" className={classes.integrationSubTitle}>
                  <SkeletonLoaders height="20px" width="273px" variant="text" />
                </Typography>
              }
            />
            <ListItemSecondaryAction className={classes.listActionIcon}>
              <SkeletonLoaders
                height="30px"
                width="99px"
                variant="rect"
                skeletonProps={{ borderRadius: '15px !important' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider className={classes.integrationDividerBlock} />
        </>
      ))}
    </List>
  );
};

export const SideBarLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <SkeletonLoaders height="78px" width="100%" variant="rect" />
      </Box>
      <Box mt={3.75}>
        <Typography variant="body1" component="label" className={classes.headingTitle}>
          <SkeletonLoaders height="21px" width="100%" variant="text" />
        </Typography>
        <List disablePadding className={classes.eventListWrapper}>
          <ListItem component="li" className={classes.integrationEventList}>
            <ListItemText
              primary={
                <Typography component="span" variant="body2" className={classes.headingTitle}>
                  <SkeletonLoaders height="19px" width="300px" variant="text" />
                </Typography>
              }
              secondary={
                <Typography>
                  <SkeletonLoaders height="20px" width="300px" variant="text" />
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Box className={classes.switchBtnStyle}>
                <SkeletonLoaders
                  height="20px"
                  width="40px"
                  variant="rect"
                  skeletonProps={{ borderRadius: '15px !important' }}
                />
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Box>
    </>
  );
};


export const BrandingVirtualBgLoader = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={6} justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="20px" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 4 }} spacing={1} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 4 }} spacing={1} alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body2" component="label">
              <SkeletonLoaders variant="text" height="80px" width={'100%'} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );

}