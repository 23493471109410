export const FETCH_DUPLICATE_SUCCESS = 'FETCH_DUPLICATE_SUCCESS';
export const NEW_COPIED_EVENT_IDS = 'NEW_COPIED_EVENT_IDS';
export const SET_BEACON_STATUS = 'SET_BEACON_STATUS';
export const SET_COPYING_API_CALL = 'SET_COPYING_API_CALL';

export const setDuplicationStatus = (status) => {
  return {
    type: FETCH_DUPLICATE_SUCCESS,
    data: status,
  };
};

export const setNewCopiedEventIds = (Ids) => {
  return {
    type: NEW_COPIED_EVENT_IDS,
    data: Ids,
  };
};

export const setBeaconStatus = (data) => {
  return {
    type: SET_BEACON_STATUS,
    data,
  };
};

export const setCopyingApiCall = (data) => {
  return {
    type: SET_COPYING_API_CALL,
    data,
  };
};
