import { genericAPICall, genericAPIErrorHandler } from 'api-setup/api-helper';
import { EVENT_OVERVIEW_API } from 'api-setup/api-endpoints';
import { getBasicInfo, getBasicInfoLoading } from '../actions/common';

export const getBasicInfoThunk = () => async (dispatch, state) => {
  const { EventData: { id = '' } = {} } = state();
  try {
    dispatch(getBasicInfoLoading(true));
    const { status, data = {} } = await genericAPICall(
      EVENT_OVERVIEW_API,
      'GET',
      {},
      {},
      { eventid: id },
      true
    );
    if (status === 200) {
      if (data.status === 200) {
        dispatch(getBasicInfo(data.data?.eventinfo));
      } else {
        dispatch(genericAPIErrorHandler(false, data.message, dispatch));
      }
    }
  } catch (err) {
    dispatch(genericAPIErrorHandler(true, err, dispatch));
  } finally {
    dispatch(getBasicInfoLoading(false));
  }
};
