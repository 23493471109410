import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    alert: {
        width: 'max-content',
        maxWidth: '100%',
        padding: `${theme.spacing(1.5)}px`,
        borderRadius: 7,
        '&.warning': {
            backgroundColor: theme.palette.primary.primaryLight,
            color: theme.palette.alert.alertWarnText,
        },
    },
    linkText: {
        color: theme.palette.primary.light,

        cursor: 'pointer',
        '& span': {
            fontWeight: '500 !important',
        }
    }
}));

// MsgNotificationComponent - this used to show the helper text
const MsgNotificationComponent = ({ icon, msgText, maxWidth, classType, clicked, linkText, className, mt = 3, ctaStyle = {} }) => {
    const classes = useStyles();

    return (
        <Box mt={mt}
            className={`${className}${classType} ${classes.alert} warning`}
            maxWidth={maxWidth}
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box display="inline-flex" alignItems="center">
                <Box display="inline-flex">
                    {icon}
                </Box>
                <Box ml={1.4}>
                    <Typography variant="body2" component="span">
                        {msgText}
                    </Typography>
                </Box>
            </Box>
            {clicked && <Box onClick={clicked} className={classes.linkText} ml={1} sx={ctaStyle}>
                <Typography variant="body2" component="span">
                    {linkText}
                </Typography>
            </Box>
            }

        </Box>
    );
}

MsgNotificationComponent.prototypes = {
    msgText: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
    classType: PropTypes.string,
    linkText: PropTypes.string.isRequired,
    className: PropTypes.string,
    clicked: PropTypes.func,
    icon: PropTypes.oneOf(['img', 'svg']),

}
export default MsgNotificationComponent;