import React from 'react';
import { useTheme } from '@material-ui/core';

const style = {
  fill: `url(#svg-broken-link-grad)`,
};

const SvgBrokenLink = (props) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="77.351"
      height="40.07"
      viewBox="0 0 77.351 40.07"
      {...props}>
      <defs>
        <linearGradient
          id="svg-broken-link-grad"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor={theme.palette.primary.lighter} />
          <stop offset="0.577" stopColor={theme.palette.primary.main} />
          <stop offset="1" stopColor={theme.palette.primary.dark} />
        </linearGradient>
      </defs>
      <g transform="translate(-51.617 -190.927)">
        <path
          style={style}
          d="M78.762,261.9l-11.335,6.037a10.748,10.748,0,0,1-10.1-18.975l11.335-6.037A10.8,10.8,0,0,1,83.2,247.365,10.626,10.626,0,0,1,78.762,261.9Zm-19.1-8.625a5.925,5.925,0,1,0,5.545,10.473l11.335-6.037A5.925,5.925,0,0,0,71,247.242Z"
          transform="translate(0 -40.355)"
        />
        <path
          style={style}
          d="M165.644,252.1l-1.355-.616-11.951,6.284a2.37,2.37,0,1,0,2.218,4.189l11.951-6.407-1.109-.862Z"
          transform="translate(-79.12 -48.169)"
        />
        <path
          style={style}
          d="M285.108,270.844l-12.075-4.312a10.679,10.679,0,1,1,7.27-20.083l12.075,4.312a10.679,10.679,0,0,1-7.27,20.083Zm-6.407-19.96a5.885,5.885,0,0,0-3.943,11.089l12.075,4.312a5.885,5.885,0,1,0,3.943-11.089Z"
          transform="translate(-170.492 -43.628)"
        />
        <path
          style={style}
          d="M262.585,267.609l-12.814-4.682.493,1.478-2.095,1.6.123,1.478,12.814,4.559a2.427,2.427,0,0,0,3.08-1.478A2.317,2.317,0,0,0,262.585,267.609Z"
          transform="translate(-156.347 -57.273)"
        />
        <g transform="translate(80.5 190.927)">
          <rect
            style={style}
            width="1.848"
            height="7.393"
            transform="translate(4.749 6.727) rotate(155.488)"
          />
          <rect
            style={style}
            width="7.393"
            height="1.848"
            transform="translate(21.019 0.846) rotate(114.512)"
          />
          <rect style={style} width="1.848" height="6.037" transform="translate(9.597 0.805)" />
          <rect
            style={style}
            width="1.848"
            height="7.393"
            transform="translate(21.029 39.258) rotate(155.488)"
          />
          <rect
            style={style}
            width="7.392"
            height="1.848"
            transform="translate(4.782 33.345) rotate(114.52)"
          />
          <rect style={style} width="1.848" height="6.037" transform="translate(9.597 33.209)" />
        </g>
      </g>
    </svg>
  );
};

export default SvgBrokenLink;
