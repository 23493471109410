/* eslint-disable  */
import React from 'react';
import {
  withStyles,
  makeStyles,
  Box,
  FormControlLabel,
  Typography,
  Switch,
} from '@material-ui/core';
import CustomToolTip from 'custom-components/customToolTip';

export const useStyles = makeStyles((theme) => ({
  formControlLabelMargin: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '&.Mui-disabled': {
          '&~.MuiSwitch-track ': {
            opacity: 0.6,
          },
        },
      },
    },
  },
}));
const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: 8,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 10,
    backgroundColor: theme.palette.bg.whiteOff2,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function SwitchField(props) {
  const {
    labelPlacement,
    checked,
    onChange,
    name,
    disabled,
    toolTipWithOutIcon = false,
    label = '',
    toolTipWithOutIconText = '',
    tooltipText = '',
    formControlClassName,
  } = props;
  const classes = useStyles();
  return (
    <>
      {toolTipWithOutIcon ? (
        <CustomToolTip title={toolTipWithOutIconText} placement="top">
          <FormControlLabel
            className={`${classes.formControlLabelMargin} ${formControlClassName} `}
            labelPlacement={labelPlacement}
            control={
              <CustomSwitch
                checked={checked}
                onChange={onChange}
                name={name}
                disabled={disabled}
                defaultChecked={checked && disabled}
              />
            }
            label={
              <Typography component="span" variant="body2">
                {label}
                {tooltipText && (
                  <Box
                    display="inline-flex"
                    position="relative"
                    mr={3}
                    style={{ verticalAlign: 'middle' }}>
                    <CustomToolTip isFabIcon title={tooltipText} placement="top" />
                  </Box>
                )}
              </Typography>
            }
          />
        </CustomToolTip>
      ) : (
        <FormControlLabel
          className={`${classes.formControlLabelMargin} ${formControlClassName}`}
          labelPlacement={labelPlacement}
          control={
            <CustomSwitch
              checked={checked}
              onChange={onChange}
              name={name}
              disabled={disabled}
              defaultChecked={checked && disabled}
            />
          }
          label={
            <Typography component="span" variant="body2" style={{ alignItems: 'center ' }}>
              {label}
              {tooltipText && (
                <Box
                  display="inline-flex"
                  position="relative"
                  mr={3}
                  style={{ verticalAlign: 'middle' }}>
                  <CustomToolTip isFabIcon title={tooltipText} placement="top" />
                </Box>
              )}
            </Typography>
          }
        />
      )}
    </>
  );
}
