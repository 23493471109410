export const CREATE_EVENT_CONSTANTS = {
  EVENT_FORMAT: true,
  EVENT_TYPE: false,
  EVENT_DETAIL: false,
  EVENT_LANGUAGES: false,
  EVENT_EDIT: false,
  HYBRID_EVENT: false,
  PERSON_EVENT: false,
  ADVANCE_PLAN: false,
  PLANELESS_EVENT: false,
};

// it'll represents the screen types
export const EEventScreen = {
  EVENT_FORMAT: 1,
  EVENT_TYPE: 2,
  EVENT_DETAILS: 3,
  EVENT_LANGUAGES: 4,
};

// it'll represents the action type
export const EActionType = {
  CREATE: 1,
  EDIT: 2,
};

// it'll represents the event formats
export const EEventFormat = {
  VIRTUAL: 'VIRTUAL',
  HYBRID: 'HYBRID',
  IN_PERSON: 'IN_PERSON',
  WEBINAR: 'WEBINAR',
};

export const IS_ENHANCE_FLOW = process.env.REACT_APP_ENHANCE_CREATE_EDIT_EVENT_FLOW !== 'false';
