import React from 'react';

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
/**
 * use when something store into `this` like class component
 */
export function useSource() {
  const ref = React.useRef();
  if (!ref.current) {
    ref.current = { SOURCE: {} };
  } else if (ref.current.SOURCE) {
    //
  } else {
    ref.current.SOURCE = {};
  }
  return ref.current.SOURCE;
}
