import React from 'react';
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';
// import LoginImage from '../../images/dashLogin/loginImage.png';
import LoginImage from '../../images/dashLogin/LoginPanel.png';
// import SignUpImage from '../../images/dashLogin/signUpImage.png';

const useStyles = makeStyles((theme) => ({
  authenticationPageWrapper: {
    backgroundColor: `${theme.palette.bg.whiteOff1}`,
    height: '100vh',
  },
  graphicImg: {
    height: '55vh',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '30vh',
      marginTop: '30px',
    },
  },
  copyrightText: {
    position: 'absolute',
    bottom: 36,
    color: '#7D899C',
  },
  LoginText: {
    marginTop: '2rem',
    textAlign: 'center',
    '& .MuiTypography-caption': {
      fontWeight: 500,
      fontSize: '1rem',
      color: theme.palette.text.primary,
      marginBottom: '0.6rem',
    },
    color: theme.palette.text.third,
  },
  signupText: {
    marginTop: '2rem',
    textAlign: 'center',
    '& .MuiTypography-caption': {
      fontWeight: 500,
      fontSize: '1rem',
      color: theme.palette.text.primary,
      marginBottom: '0.6rem',
    },
    color: theme.palette.text.third,
  },
  authenticationWrapper: {
    backgroundColor: `${theme.palette.primary.contrastText}`,
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.down('sm')]: {
      height: '67vh',
    },
  },
  authenticationBlock: {
    width: `calc(320px + ${theme.spacing(4) * 2}px)`,
    padding: theme.spacing(4),
    margin: 'auto',
  },
}));

export default function Authentication(props) {
  const { children } = props;
  const {
    authenticationPageWrapper,
    graphicImg,
    copyrightText,
  //  LoginText,
  //  signupText,
    authenticationWrapper,
    authenticationBlock,
  } = useStyles();
  // const isLogin =
  //   children.props.location.pathname.substring(1).trim().toLowerCase() ===
  //   'login'.trim().toLowerCase();

  return (
    <Box
      className={`h-100 ${authenticationPageWrapper}`}
      display="flex"
      alignItems="center"
      justifyContent="space-between">
      <Grid container className="h-100">
        <Grid item xs={12} sm>
          <Box
            className="w-100 h-100"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column">
            <img
             // src={isLogin ? LoginImage : SignUpImage}
              src={LoginImage}
              alt="Event Dashboard - Banner"
              className={graphicImg}
            />
            {/* {isLogin ? (
              <Box className={LoginText}>
                <Typography variant="caption" component="p">
                  Anywhere, Any Device
                </Typography>
                <Typography variant="text" component="span">
                  Join events from any device and anywhere in the world.
                </Typography>
              </Box>
            ) : (
              <Box className={signupText}>
                <Typography variant="caption" component="p">
                  Start your 7 day free trial today!
                </Typography>
                <Typography variant="text" component="span">
                  Experience the platform with the largest engagement features.
                </Typography>
              </Box>
            )} */}
            <Box className={copyrightText}>
              <Typography variant="caption" component="span" className="text-uppercase">
                &copy; {`hubilo ${new Date().getFullYear()}. All rights reserved`}.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid className={`scroll-y ${authenticationWrapper}`} item xs={12} sm="auto">
          <Box className={authenticationBlock}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
