import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import CustomModal from 'custom-components/customModal';
import TextAreaField from 'custom-components/form-fields/textAreaField';
import SvgCommentIcon from 'icons/svgCommentIcon';
import BtnWithLoader from 'custom-components/btnWithLoader';
import InputField from 'custom-components/form-fields/inputField';
import Validation from 'models/validation';
import { NewEventDaysSchema } from 'models/schema';
import { API, GETAPIWITHCUSTOMDOMAIN, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { CONTACT_SALES_CUSTOM_DAYS, USER_INFO1 } from 'api-setup/api-endpoints';

import { setNewMessage } from 'redux/actions/setting-actions';

import projectSetting from 'settings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSource } from 'lib/custom-common-hooks';
import { commonSelector, userInfoSelector } from 'redux/custom-selector';
import PropTypes from 'prop-types';
import { getUserCredential, setOnboardingDataCollectionDone, setUserCredential } from 'lib/cookies';
import { setUserInfo } from 'redux/actions/user-action';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  contactSales: {
    '& label': {
      textTransform: 'none',
    },
  },
}));

const ContactSupport = ({ isOpen, onClose }) => {
  const classes = useStyles();
  // eventDaysErrorObj - contains the errors in the form
  const [eventDaysErrorObj, setEventDaysErrorObj] = React.useState('');

  // reduxData - contains the common data from redux
  const reduxData = useSelector(commonSelector, shallowEqual);

  //userInfo - contains the logged in user
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  // loadingState - loader for submit 
  const [loadingState, setLoadingState] = React.useState({ submitEventDays: false });

  // contactSalesModal - contains the data of the form
  const [contactSalesModal, setContactSalesModal] = React.useState({
    comments: '',
    eventDays: '',
  });
  const source = useSource();
  const [headers] = API.generateHeader(reduxData, null, null);
  const dispatch = useDispatch();
  const history = useHistory()

  //handleClose - triggers when modal got closed
  const handleClose = (isSuccess) => {
    setContactSalesModal({ eventDays: '', comments: '' });
    setEventDaysErrorObj({ eventDays: '', comments: '' });
    onClose(isSuccess);
  };


  // handleInputChange - triggers on form fields change 
  const handleInputChange = (e) => {
    const { name } = e.target;
    setContactSalesModal({ ...contactSalesModal, [name]: e.target.value });
    setEventDaysErrorObj({ ...eventDaysErrorObj, [name]: '' });
  };

  // handelKeyPressOnNumberText - triggers on key press 
  const handelKeyPressOnNumberText = (evt) => {
    if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
      evt.preventDefault();
    }
    if (evt.target.value.length >= 3) {
      evt.preventDefault();
    }
  };

  // onHandleContactSubmit - triggers on form submit 
  const onHandleContactSubmit = () => {
    let eventDaysErrorObject = {};
    const { eventDays } = contactSalesModal;

    // eventDaysErrorObject - validating with schema
    eventDaysErrorObject = Validation.validate(NewEventDaysSchema, { eventDays });

    if (eventDays !== '') {
      eventDaysErrorObject = {};
      if (Number(eventDays) > 100) {
        eventDaysErrorObject.eventDays = 'Event Days is a maximum 100';
      } else if (Number(eventDays) < 1) {
        eventDaysErrorObject.eventDays = 'Event Days is a minimum 1';
      }
    }

    if (Object.keys(eventDaysErrorObject).length === 0) {
      setLoadingState({ ...loadingState, submitEventDays: true });
      const bodyData = {
        data: {
          email: userInfo.UserInfo.email,
          subscriptionId: userInfo.UserInfo.subscription_meta_id,
          eventDays: Number(contactSalesModal.eventDays),
          comments: contactSalesModal.comments,
          url: window.location.href,
        },
      };

      // calling the support api
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        CONTACT_SALES_CUSTOM_DAYS,
        source.AS,
        headers,
        bodyData,
        projectSetting.customBaseURL
        // eslint-disable-next-line consistent-return
      ).then((resp) => {
        if (resp.data.status === 200) {
          setLoadingState({ ...loadingState, submitEventDays: false });
          if (resp.data.message !== 'ALREADY_CONTACTED') {
            handleClose(true);
            dispatch(
              setNewMessage({
                message: 'The support team has been notified that you want to create an event for more than 3 days. You will be able to do so shortly.',
                type: 'success',
                show: true,
              })
            );

            // cookiesData - gets the cookies data 
            const cookiesData = getUserCredential();

            // calling the auth api to re set the data rightafter support api success response
            GETAPIWITHCUSTOMDOMAIN(
              false,
              USER_INFO1,
              source.AS,
              headers,
              reduxData,
              projectSetting.customBaseURL
            )
              .then((resp1) => {
                if (resp1.data.status === API.apiSuccessStatus) {
                  setOnboardingDataCollectionDone(
                    resp1.data.data.isOnboardingDataCollectionDone,
                    'isOnboardingDataCollectionDone'
                  );
                  setUserCredential(
                    {
                      authToken: cookiesData.authToken,
                      teamMemberId: cookiesData.teamMemberId,
                      userPermission: resp1.data.data.userPermission,
                      organiser_id: reduxData.organiserId,
                    },
                    'userData'
                  );
                  dispatch(setUserInfo(resp1.data.data));
                } else {
                  API.errStatusHandler(resp1, history, dispatch);
                }
              })
              .catch(API.catchHandler);
            return () => API.removeAllApi(source.AS);

          } else {
            setLoadingState({ ...loadingState, submitEventDays: false });
            dispatch(setNewMessage({ message: 'Already contacted.', type: 'success', show: true }));
          }
        } else {
          setLoadingState({ ...loadingState, submitEventDays: false });
          if (resp.data.message)
            dispatch(setNewMessage({ message: resp.data.message, type: 'error', show: true }));
        }
      }).catch((error) => {
        if (error) API.httpErrorStatusHandler(error, history, dispatch);
      })
    } else {
      setEventDaysErrorObj({ ...eventDaysErrorObject });
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    }
  };


  return (
    <CustomModal isOpen={isOpen} onClose={() => handleClose(false)}>
      <>
        <Box width={420}>
          <Box textAlign="center">
            <Box mt={0} textAlign="center" mb={2.8}>
              <SvgCommentIcon />
            </Box>
            <Typography
              component="h1"
              variant="h5" // color={colors.textPrimary}
            >
              Contact Support
            </Typography>
          </Box>
          <Box mt={1} textAlign="center">
            <Typography
              component="p"
              variant="body2"
              color="textSecondary"
              display="block"
            // className={classes.grayHelpText}
            >
              Send us a message to create an event for more than 3 days.
            </Typography>
          </Box>
          <Box mt={3} className={classes.contactSales}>
            <InputField
              name="eventDays"
              type="number"
              label="Number of event days"
              error={eventDaysErrorObj.eventDays}
              placeholder="Event Days"
              maxValue={100}
              minValue={1}
              value={contactSalesModal.eventDays}
              onKeyPress={handelKeyPressOnNumberText}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box mt={3}>
            <TextAreaField
              name="comments"
              maxLength={100}
              rowMin={3}
              label="Message"
              value={contactSalesModal.comments}
              onChange={handleInputChange}
              placeholder="Can you share more about the event that you want to create?"
              descType
              descText="100 characters"
            />
          </Box>

          <Box className="eventCardActions" display="flex" justifyContent="center" mt={3}>
            <Box mr={2}>
              <BtnWithLoader
                variant="outlined"
                color="primary"
                size="small"
                label="Cancel"
                onClick={() => handleClose(false)}
              />
            </Box>
            <Box>
              <BtnWithLoader
                variant="contained"
                color="primary"
                size="small"
                label="Submit"
                onClick={onHandleContactSubmit}
                isLoading={loadingState.submitEventDays}
                disabled={loadingState.submitEventDays}
                loadingLabel="Submitting"
              />
            </Box>
          </Box>
        </Box>
      </>
    </CustomModal>
  );
};

ContactSupport.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContactSupport;
