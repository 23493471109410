import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Box, Typography, Button } from '@material-ui/core';
import MsgNotificationComponent from 'pages/CreateNewEvent/MsgNotificationComponent';
import SvgInfoIcon from 'icons/svgInfoIcon';
import CheckBox from 'custom-components/form-fields/checkbox2';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomToolTip from 'custom-components/customToolTip';
import SelectDropDown from 'custom-components/form-fields/selectDropdown';
import SVGErrorIconOutline from 'icons/svgErrorIconOutline';
import EventLanguagesLoader from 'pages/CreateNewEvent/Loaders/EventLanguagesScreenLoader';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  baseEventLanguagesSelector,
  commonSelector,
  currentEventLanguagesSelector,
  userInfoSelector,
} from 'redux/custom-selector';
import { PricingPlan } from 'constants/pricingPlan';
import CreateEventFooter from './CreateNewEventLayout/CreateEventFooter';
import { useStylesForLanguages } from './style';
import useFetch from './common/fetch';
import {
  CREATE_NEW_EVENT,
  GET_LANGUAGES_LIST,
  FETCH_EVENT_DETAILS,
  CONTEST_RESPONSE_WINNER_LIST,
} from 'api-setup/api-endpoints';
import { useCustomCreateEventContext } from './context';
import SvgEventLockIcon from 'icons/svgEventLockIcon';
import { API, POSTAPIWITHCUSTOMDOMAIN, PUTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import projectSetting from 'settings';
import { useHistory, useParams } from 'react-router';
import { setNewMessage } from 'redux/actions/setting-actions';
import { getActionFromPath } from './common/utils';
import { EActionType, EEventFormat } from './common/constants';
import { createEventTrackAmplitute } from 'lib/amplitude/amplitude-tracking';
import { setCreateEditParent } from 'redux/actions/eventCreateEditActions';
import { EventAddOnsMeta, EventAddOnsMetaForToast } from 'pages/events/constants';

const formatDateTimeStamp = (date) =>
  `${moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm')}`;

const convertToTimezone = (date, timezone) =>
  moment.tz(formatDateTimeStamp(date), timezone).utc().valueOf();

const addTimeToDate = (time) => {
  const timeSplit = [...time.split(':')];
  const hourMilli = Number(timeSplit[0]) * 60 * 60 * 1000;
  const minMilli = Number(timeSplit[1]) * 60 * 1000;
  return hourMilli + minMilli;
};

const EventLanguages = ({ isLoading: screenLoading }) => {
  const classes = useStylesForLanguages();

  // userInfo refers the all the data of the logged in user.
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  // planName indicates the organizer plan name (ex: planless, advanced, enterprise, and pro)
  const planName = userInfo.UserInfo.subscription_meta_id ? userInfo.UserInfo.planName : '';
  const [webinarLang, setWebinarLang] = useState('English');
  const [webinarLangId, setWebinarLangId] = useState(34);
  // baseLangOptions contains all the languages names selected by the user.
  const [baseLangOptions, setBaseLangOptions] = useState([]);

  // displayBaseOptions is a array of objectes which stored the language objects that selected by the user.
  const [displayBaseOptions, setDisplayBoptions] = useState([]);

  // accessing the context in the child. Which is passed by the parent component.
  const { state, dispatch } = useCustomCreateEventContext();

  // commonSelectorData contains all the common data for the user who logged in.
  const commonSelectorData = useSelector(commonSelector, shallowEqual);

  // baseLanguageEvent contains the events base language
  const baseLanguageEvent = useSelector(baseEventLanguagesSelector, shallowEqual);

  // currentEventLanguage contains the events current language selected by organiser
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);

  const headerLang = currentEventLanguage?.id || baseLanguageEvent?.id || 34;

  const source = useSource();
  const [headers] = API.generateHeader(commonSelectorData, { language: headerLang }, null, null);
  const dispatchApi = useDispatch();

  // base language that is selected by the user for their event
  const [baseLanguage, setBaseLanguage] = useState('');

  // loader for finish api call
  const [finisLoading, setFinishLoading] = useState(false);
  const history = useHistory();

  // actionType stores the whether the action is for edit/create by passign the path to getActionFromPath function.
  const actionType = getActionFromPath(history.location.pathname);

  // using the useFetch hook to call the get languages api, and the api results and loading states will be returned
  const [data, isLoading] = useFetch(GET_LANGUAGES_LIST);

  // using params getting the event id and organiser id
  const params = useParams();
  const { eventId = '', organiserId = '' } = params;

  const handleChangeWebinarLang = (event) => {
    const idAsNumber = parseInt(event.target.id, 10);
    setWebinarLangId(idAsNumber);
    setWebinarLang(event.target.value);
  }

  //  based on the languages api results, this useeffect will do the pre calculations
  useEffect(() => {
    if (!data) return;

    // Shifting the english language to the array top using its index
    const englishIndex = data.findIndex((item) => item.name === 'English');
    if (englishIndex !== -1) {
      const temp = data[englishIndex];
      data.splice(englishIndex, 1);
      data.unshift(temp);
    }

    // stateLanguages contains the languages that is stored in the redux.
    // If user is editing the event then this are the lanuages that the event already had
    const stateLanguages = state.event_languages;

    if (stateLanguages && stateLanguages.length) {
      // tempArray stores the language object that is already in the redux
      const tempArray = [];

      // tempBase stores the language names that is already in the redux
      const tempBase = [];
      data.forEach((item) => {
        if (stateLanguages.includes(item.id)) {
          tempBase.push(item.name);
          tempArray.push(item);
          setWebinarLang(item.name);
        }
        // setting the base language that is already in the redux
        if (state.base_language === item.id) setBaseLanguage(item.name);
      });
      setDisplayBoptions(tempArray);
      setBaseLangOptions(tempBase);
    } else {
      // if the event is in creation state then here adding the basic data
      const index = data.findIndex((item) => item.id === state.base_language);
      if (index !== -1) {
        setBaseLangOptions([data[index].name]);
      } else {
        // adding the english as the base language
        setBaseLangOptions(['English']);

        // adding the english object
        setDisplayBoptions([
          {
            id: 34,
            imgFileName: 'united-kingdom.svg',
            imgFileNamePng: 'united-kingdom.png',
            isCommunitySupported: true,
            name: 'English',
            nativeName: 'English',
            shortCode: 'EN',
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /*
   * The handleChange function triggers when user selects/de-selects the language options.
   */
  const handleChange = (checked, ele) => {
    // baseLangOptionsCopy contains the array copy of baseLangOptions
    const baseLangOptionsCopy = [...baseLangOptions];

    // displayBaseOptionsCopy contains the array copy of displayBaseOptions
    const displayBaseOptionsCopy = [...displayBaseOptions];

    if (checked) {
      // adding the English to the first element so its displays at the top
      if (ele.name === 'English') baseLangOptionsCopy.unshift(ele.name);
      else baseLangOptionsCopy.push(ele.name);
      displayBaseOptionsCopy.push(ele);
    } else {
      // isLanguageExists will have a boolean value says whether the changed language is already in the selected options are not.
      // If language is found then simply remove from options
      const isLanguageExists = baseLangOptionsCopy.includes(ele.name);
      if (isLanguageExists) {
        baseLangOptionsCopy.splice(baseLangOptionsCopy.indexOf(ele.name), 1);
        displayBaseOptionsCopy.splice(displayBaseOptionsCopy.indexOf(ele), 1);
        if (baseLanguage === ele.name) {
          setBaseLanguage(
            displayBaseOptionsCopy?.length > 0 ? displayBaseOptionsCopy[0]?.name || '' : ''
          );
        }
      }
    }
    setBaseLangOptions(baseLangOptionsCopy);
    setDisplayBoptions(displayBaseOptionsCopy);
  };

  // createEventApiCall is responsible for calling the finish api and handling the response
  const createEventApiCall = (body) => {
    POSTAPIWITHCUSTOMDOMAIN(
      false,
      CREATE_NEW_EVENT,
      source.AS,
      headers,
      body,
      projectSetting.customBaseURL6
    )
      .then((res) => {
        setFinishLoading(false);
        if (res.status === API.apiSuccessStatus) {
          const selectLangName = displayBaseOptions.map((item) => {
            return item.shortCode;
          });
          // amplitute tracking for the finish call
          createEventTrackAmplitute('SEEventCreated', {
            funnel_entry_section: 'CREATE_EVENT_FUNNEL',
            event_id: res.data.data.eventId,
            languages_selected: selectLangName,
            event_name: body.payload.data.eventName,
            time_zone: state?.timezone_name,
            event_start_timestamp: body.payload.data.start_time_milli,
            event_end_timestamp: body.payload.data.end_time_milli,
            country: body.payload.data.country || '',
            event_type: state.eventTypeName,
            checkin_facility_required: state?.isCheckIn === 1,
            event_format_selected: state.format,
            app_version: projectSetting.buildversion,
            user_id: userInfo.UserInfo.id,
            organisation: userInfo.UserInfo.organisation,
            group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
            plan_type: planName,
            customEventType: state.customEventType || undefined,
          });

          const setAddOnsMessage = () => {
            if (res.data?.data?.addOnStatus?.success?.length) {
              const msg = `${planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR
                  ? 'Event created.'
                  : ''
                } Add-ons applied: ${res.data?.data?.addOnStatus?.success
                  .map((addon) => EventAddOnsMetaForToast[addon])
                  .join(', ')}`;

              // displays the api message
              dispatchApi(
                setNewMessage({
                  message: msg,
                  type: 'success',
                  show: true,
                })
              );
            } else if (res.data?.data?.addOnStatus?.failed?.length) {
              const msg = `These add-ons could not be applied: ${res.data?.data?.addOnStatus?.failed
                .map((addon) => EventAddOnsMeta[addon])
                .join(', ')}. Add again by editing your event`;
              dispatchApi(setNewMessage({ message: msg, type: 'error', show: true }));
            }
          };

          if (
            !(
              (planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR) &&
              res.data?.data?.addOnStatus?.success?.length
            )
          )
            // displays the api message
            dispatchApi(
              setNewMessage({
                message: `${res.data.message}`,
                type: 'success',
                show: true,
              })
            );

          setAddOnsMessage();

          setTimeout(() => {
            dispatchApi(setCreateEditParent(''));
          }, 200);
          // on success finish api redirecting the user to the event basic info page
          history.push({
            pathname: `/event/${res.data.data.eventId}/${res.data.data.organiserId}`,
            state: {
              isShowRegistrationFlowPopUp: true,
            },
          });
        } else {
          // displays the api message
          dispatchApi(setNewMessage({ message: res.data.message, type: 'error', show: true }));
          API.errStatusHandler(res, history, dispatchApi);
        }
      })
      .catch((error) => {
        setFinishLoading(false);
        API.httpErrorStatusHandler(error, history, dispatchApi);
      });
  };

  // editEventApiCall is responsible for calling the edit api and handling the response
  const editEventApiCall = (body) => {
    if (!headers.eventId) headers.eventId = eventId;
    PUTAPIWITHCUSTOMDOMAIN(
      false,
      `${FETCH_EVENT_DETAILS}/${eventId}`,
      source.AS,
      headers,
      body,
      projectSetting.customBaseURL6
    )
      .then((res) => {
        setFinishLoading(false);
        if (res.status === API.apiSuccessStatus) {
          // selectLangName contains short code for selected languages
          const selectLangName = displayBaseOptions.map((item) => {
            return item.shortCode;
          });

          // eventLanguagesBaseName contains the short code of selected base language
          const eventLanguagesBaseName =
            displayBaseOptions?.length > 0 &&
            displayBaseOptions[
              displayBaseOptions.findIndex(
                (item) => item.name === (baseLanguage === '' ? baseLangOptions[0] : baseLanguage)
              )
            ].shortCode;

          // startTime contains the start time of the event
          const startTime = addTimeToDate(state.startTime);

          // endTime contains the end time of the event
          const endTime = addTimeToDate(state.endTime);

          // startTimeUtc contains the start time of the event in UTC formate
          const startTimeUtc = convertToTimezone(
            Number(state.startDate) - 1000 + startTime,
            state.timezone_name
          );

          // endTimeUtc contains the end time of the event in UTC formate
          const endTimeUtc = convertToTimezone(
            Number(state.endDate) + endTime,
            state.timezone_name
          );

          // amplitute tracking for edit
          createEventTrackAmplitute('SEEventEdited', {
            funnel_entry_section: 'EDIT_EVENT_FUNNEL',
            event_id: res.data.eventId,
            languages_selected_edited: selectLangName,
            base_language_selected_edited: eventLanguagesBaseName,
            event_name_edited: state.name,
            time_zone_edited: state.timezone_name,
            event_start_timestamp_edited: startTimeUtc,
            event_end_timestamp_edited: endTimeUtc,
            country_edited: state.address?.country,
            event_type_edited: state.eventTypeName,
            checkin_facility_required_edited: state?.isCheckIn === 1 ? 'YES' : 'NO',
            event_format_selected_edited: state.format,
            app_version: projectSetting.buildversion,
            user_id: userInfo.UserInfo.id,
            organisation: userInfo.UserInfo.organisation,
            group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
            plan_type: planName,
            customEventType_edited: state.customEventType || undefined,
          });

          const setAddOnsMessage = () => {
            if (res.data?.data?.addOnStatus?.success?.length) {
              const msg = `${planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR
                  ? 'Event edited.'
                  : ''
                } Add-ons applied: ${res.data?.data?.addOnStatus?.success
                  .map((addon) => EventAddOnsMetaForToast[addon])
                  .join(', ')}`;

              // displays the api message
              dispatchApi(
                setNewMessage({
                  message: msg,
                  type: 'success',
                  show: true,
                })
              );
            } else if (res.data?.data?.addOnStatus?.failed?.length) {
              const msg = `These add-ons could not be applied: ${res.data?.data?.addOnStatus?.failed
                .map((addon) => EventAddOnsMeta[addon])
                .join(', ')}. Add again by editing your event`;
              dispatchApi(setNewMessage({ message: msg, type: 'error', show: true }));
            }
          };

          if (
            !(
              (planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR) &&
              res.data?.data?.addOnStatus?.success?.length
            )
          )
            // displays the api message
            dispatchApi(
              setNewMessage({
                message: `${res.data.message}`,
                type: 'success',
                show: true,
              })
            );

          setAddOnsMessage();

          setTimeout(() => {
            dispatchApi(setCreateEditParent(''));
          }, 200);
          // on success response navigate user to the event basic info page
          history.push(`/event/${eventId}/${organiserId}`);
        } else {
          // displays the api message
          dispatchApi(setNewMessage({ message: res.data.message, type: 'error', show: true }));
          API.errStatusHandler(res, history, dispatchApi);
        }
      })
      .catch((error) => {
        setFinishLoading(false);
        API.httpErrorStatusHandler(error, history, dispatchApi);
      });
  };

  const onNext = (selectedAddons = []) => {
    setFinishLoading(true);
    // baseLanguageId contains the id of selected base language
    const baseLanguageId =
      displayBaseOptions[
        displayBaseOptions.findIndex(
          (item) => item.name === (baseLanguage === '' ? baseLangOptions[0] : baseLanguage)
        )
      ]?.id || '';
    // eventLanguages contains the id of languages that are selected
    const eventLanguages = displayBaseOptions.map((item) => {
      return item.id;
    });

    // storing the data in redux
    dispatch({
      type: 'languages',
      payload: {
        event_languages: eventLanguages,
        base_language: baseLanguageId,
      },
    });

    // startTime contains the start time of the event
    const startTime = addTimeToDate(state.startTime);

    // endTime contains the end time of the event
    const endTime = addTimeToDate(state.endTime);

    // startTimeUtc contains the start time of the event in UTC formate
    const startTimeUtc = convertToTimezone(
      Number(state.startDate) - 1000 + startTime,
      state.timezone_name
    );

    // endTimeUtc contains the end time of the event in UTC formate
    const endTimeUtc = convertToTimezone(Number(state.endDate) + endTime, state.timezone_name);

    if (actionType === EActionType.CREATE) {
      // preparing the body for create api
      const body = {
        payload: {
          data: {
            type: state.format,
            eventMetaTypeId: Number(state.eventMetaTypeId),
            eventName: state.name,
            eventDescription: state.description || '',
            timeZoneId: state.timezone_id,
            start_time_milli: startTimeUtc,
            end_time_milli: endTimeUtc,
            country: state.country, // only in case of type : HYBRID/IN_PERSON
            eventLanguages: state.format === EEventFormat.WEBINAR ? [webinarLangId] : eventLanguages,
            baseLanguageId: state.format === EEventFormat.WEBINAR ? webinarLangId : baseLanguageId,
            isCheckIn: state.isCheckIn === 1, // only in case of type : HYBRID/IN_PERSON
            customEventType: state.customEventType,
            addOns: selectedAddons || state.addOns || [],
          },
        },
      };
      createEventApiCall(body);
    } else {
      // creating the body object for edit api. it adds only the edited data by comparing the state value with the api data
      const bodyData = {};
      const apiData = state?.editDetails;
      if (state.format !== apiData?.type) bodyData.type = state.format;
      if (Number(state.eventMetaTypeId) !== Number(apiData?.eventMetaTypeId))
        bodyData.eventMetaTypeId = Number(state.eventMetaTypeId);
      if (Number(state.isCheckIn) !== Number(apiData?.is_check_in))
        bodyData.isCheckIn = state.isCheckIn === 1;
      if (state.timezone_id !== apiData?.timezone_id) bodyData.timeZoneId = state.timezone_id;
      if (state.name !== apiData?.name) bodyData.eventName = state.name;
      if (state.description !== apiData?.description) bodyData.eventDescription = state.description;
      if (state.isTimeChanged) {
        if (Number(startTimeUtc) !== Number(apiData?.start_time_milli))
          bodyData.start_time_milli = startTimeUtc;
        if (Number(endTimeUtc) !== Number(apiData?.end_time_milli))
          bodyData.end_time_milli = endTimeUtc;
      }
      if (state.linked_url !== apiData?.linked_url) bodyData.linkedinUrl = state.linked_url;
      if (state.twitter_url !== apiData?.twitter_url) bodyData.twitterUrl = state.twitter_url;
      if (state.instagram_url !== apiData?.instagram_url)
        bodyData.instagramUrl = state.instagram_url;
      if (state.fb_url !== apiData?.fb_url) bodyData.fbUrl = state.fb_url;
      if (state.website_url !== apiData?.website_url) bodyData.websiteUrl = state.website_url;
      if (state.customEventType !== apiData?.customEventType) {
        bodyData.customEventType = state.customEventType;
        bodyData.eventMetaTypeId = Number(state.eventMetaTypeId);
      }
      const stateAddressKeys = Object.keys(state.address || {});
      const apiAddressKeys = Object.keys(apiData?.address || {});
      if (!apiAddressKeys?.length && stateAddressKeys?.length) bodyData.address = state.address;
      if (apiAddressKeys?.length && stateAddressKeys?.length) {
        const address = {};
        if (state.address?.country !== apiData.address?.country)
          address.country = state.address?.country;
        if (state.address?.address !== apiData.address?.address)
          address.address = state.address?.address;
        if (state.address?.city !== apiData.address?.city) address.city = state.address?.city;
        if (state.address?.lat !== apiData.address?.lat) address.lat = state.address?.lat;
        if (state.address?.lon !== apiData.address?.lon) address.lon = state.address?.lon;
        if (state.address?.province !== apiData.address?.province)
          address.province = state.address?.province;
        if (state.address?.zip !== apiData.address?.zip) address.zip = state.address?.zip;
        if (Object.keys(address).length) bodyData.address = address;
      }
      bodyData.addOns = state.addOns || [];
      // if user has changed something then only calling the api
      if (Object.keys(bodyData).length) {
        const body = {
          payload: {
            data: bodyData,
          },
        };
        editEventApiCall(body);
      } else {
        // if user doesn't change anything, then redirecting the user to the event basic details page
        history.push(`/event/${eventId}/${organiserId}`);
      }
    }
  };

  // by clicking on previous button onPrevious functions gets executed. Here mainly we do the amplitude tracking
  const onPrevious = () => {
    const selectLangName = displayBaseOptions.map((item) => {
      return item.shortCode;
    });
    if (actionType === EActionType.EDIT) {
      const eventLanguagesBaseName =
        displayBaseOptions?.length > 0 &&
        displayBaseOptions[
          displayBaseOptions.findIndex(
            (item) => item.name === (baseLanguage === '' ? baseLangOptions[0] : baseLanguage)
          )
        ].shortCode;
      createEventTrackAmplitute('EditLanguagesPreviousButtonClicked', {
        parent_screen: 'EDIT_LANGUAGES',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        languages_selected_edited: selectLangName,
        base_language_selected_edited: eventLanguagesBaseName,
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
      createEventTrackAmplitute('EditEventDetailsScreenViewed', {
        referrer_screen: 'EDIT_LANGUAGES',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    } else {
      createEventTrackAmplitute('LanguagesPreviousButtonClicked', {
        parent_screen: 'LANGUAGES',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        languages_selected: selectLangName,
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
      createEventTrackAmplitute('EventDetailsScreenViewed', {
        referrer_screen: 'LANGUAGES',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    }
  };

  // by clicking on finish button onPrevious onNextFinish gets executed. Here mainly we do the amplitude tracking

  const onNextFinish = () => {
    const eventLanguagesBaseName =
      (displayBaseOptions?.length > 0 &&
        displayBaseOptions[
          displayBaseOptions.findIndex(
            (item) => item.name === (baseLanguage === '' ? baseLangOptions[0] : baseLanguage)
          )
        ]?.shortCode) ||
      '';
    const selectLangName = displayBaseOptions.map((item) => {
      return item.shortCode;
    });
    if (actionType === EActionType.EDIT) {
      createEventTrackAmplitute('EditLanguagesFinishButtonClicked', {
        parent_screen: 'EDIT_LANGUAGES',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        languages_selected_edited: selectLangName,
        base_language_selected_edited: eventLanguagesBaseName,
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    } else {
      createEventTrackAmplitute('LanguagesFinishButtonClicked', {
        languages_selected: selectLangName,
        parent_screen: 'LANGUAGES',
        base_language_selected: eventLanguagesBaseName,
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        plan_type: planName,
      });
    }
  };

  // path stores the event url for plans page
  const path = `${window.location.origin}/#/plans`;

  // is its loading then we render the loader directly
  if (isLoading || screenLoading) return <EventLanguagesLoader />;

  // once the loading is done but if there is no api data then we will return from here.
  if (!data) return null;
  return (
    <>
      <Box
        className={classes.eventLanguagesScreen}
        pb={baseLangOptions && baseLangOptions.length > 1 ? 6 : 0}>
        {actionType === EActionType.EDIT ? (
          <MsgNotificationComponent
            classType="error"
            maxWidth="516px"
            msgText={state.format === EEventFormat.WEBINAR ? "The language selected by you cannot be changed now." : "The language(s) selected by you cannot be changed now."}
            icon={<SVGErrorIconOutline color="primary" />}
            mt={1.5}
          />
        ) : commonSelectorData.is_language_support === 1 ||
          userInfo.UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
          userInfo.UserInfo.planName === PricingPlan.ALL_IN ||
          userInfo.UserInfo.planName === PricingPlan.WEBINAR_LITE ||
          userInfo.UserInfo.planName === PricingPlan.WEBINAR ||
          !userInfo.UserInfo.planName ? (
          <MsgNotificationComponent
            classType="error"
            maxWidth="516px"
                msgText={state.format === EEventFormat.WEBINAR ? "You can only select one language and that cannot be changed later." : "Selected languages can't be changed later. You will need to translate event content for each selected language."}
            icon={<SVGErrorIconOutline color="primary" />}
            mt={1.5}
          />
        ) : planName === PricingPlan.ADVANCED ? (
          <Box p={3} mt={3} className="upgradePlanCard">
            <Box className="upgradePlanCardText">
              <Typography component="h6" variant="h6" color="textPrimary">
                Upgrade Plan!
              </Typography>
              <Typography variant="body2" component="span">
                To select multiple languages, subscribe to the Pro or Enterprise plan.
              </Typography>
            </Box>
            <Button
              className={classes.nextBtn}
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                window.open(path, '_blank');
                createEventTrackAmplitute('UpgradePlanButtonClicked', {
                  entry_point: `${state.format === EEventFormat.HYBRID
                      ? 'HYBRID_EVENT_HOVER'
                      : state.format === EEventFormat.IN_PERSON
                      ? 'IN_PERSON_EVENT_HOVER'
                      : 'PLAIN_TEXT'
                    }`,
                  parent_screen: `${actionType === EActionType.EDIT
                    } ? 'EDIT_LANGUAGES' : 'LANGUAGES'`,
                  funnel_entry_section: `${actionType !== EActionType.EDIT
                    } ? 'CREATE_EVENT_FUNNEL' : 'EDIT_EVENT_FUNNEL'`,
                  app_version: projectSetting.buildversion,
                  user_id: userInfo.UserInfo.id,
                  organisation: userInfo.UserInfo.organisation,
                  group:
                    userInfo.UserInfo.is_team_member_loggedin === true
                      ? 'TEAM MEMBER'
                      : 'ORGANISER',
                  plan_type: planName,
                });
              }}>
              Upgrade
            </Button>
          </Box>
        ) : (
          <MsgNotificationComponent
            msgText="If you want to select multiple languages, contact support."
            classType="error"
            icon={<SvgEventLockIcon color="primary" />}
            mt={1.5}
          />
        )}
        {state.format === EEventFormat.WEBINAR ?
          <Box mt={3} className="langList webinarLangList">
            <RadioGroup
              value={webinarLang}
              className="createEventOption webinarEventOption"
              onChange={handleChangeWebinarLang}
            >
              {data.map((lang) => {
                let isChecked = lang.name === webinarLang;
                let isCheckBoxDisabled = actionType === EActionType.EDIT;
                if (
                  userInfo.UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
                  userInfo.UserInfo.planName === PricingPlan.ALL_IN ||
                  userInfo.UserInfo.planName === PricingPlan.WEBINAR_LITE ||
                  userInfo.UserInfo.planName === PricingPlan.WEBINAR
                ) {
                  isCheckBoxDisabled = commonSelectorData.is_language_support === 0 || actionType === EActionType.EDIT;
                }
                return (
                  <FormControlLabel
                    key={lang.id}
                    id={lang.id}
                    className={`${isChecked ? 'active' : ''} 
                        ${!isChecked && isCheckBoxDisabled && 'disabledEvent'}
                    `}
                    control={
                      <Radio
                        value={lang.name}
                        id={lang.id}
                        disabled={isCheckBoxDisabled}
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <>
                        <Typography component="span" variant="body2">
                        </Typography>
                        {lang.shortCode && <Typography component="span" className='langCode'>{lang.shortCode}</Typography>}
                        <Typography
                          component="span"
                          variant="body2"
                        >
                          <>
                            <Typography className="langName" variant="body1" color="textPrimary" component="span">
                              {lang.name}
                            </Typography>
                            {lang.name !== 'English' ? (
                              <Typography variant="body2" color="textSecondary" component="span">
                                {lang.nativeName}
                              </Typography>
                            ) : null}
                          </>
                        </Typography>
                      </>
                    }
                  />
                );
              })}
            </RadioGroup>
          </Box>
          :
          <Box className="langList" mt={3}>
            {data.map((lang) => {
              const isChecked = baseLangOptions.includes(lang.name);
              let isCheckBoxDisabled =
                commonSelectorData.is_language_support === 0 || actionType === EActionType.EDIT;
              if (
                userInfo.UserInfo.planName === PricingPlan.DIGITAL_EVENTS ||
                userInfo.UserInfo.planName === PricingPlan.ALL_IN
              ) {
                isCheckBoxDisabled = actionType === EActionType.EDIT;
              }
              return (
                <CheckBox
                  key={lang.id}
                  label={
                    <>
                      <Typography variant="body1" color="textPrimary" component="span">
                        {lang.name}
                      </Typography>
                      {lang.name !== 'English' ? (
                        <Typography variant="body2" color="textSecondary" component="span">
                          {lang.nativeName}
                        </Typography>
                      ) : null}
                    </>
                  }
                  color="primary"
                  disabled={isCheckBoxDisabled}
                  checked={isChecked}
                  onChange={(event) => handleChange(event.target.checked, lang)}
                  value={lang.name}
                  isLangCode={lang.shortCode}
                  formControlClassName="createEventOption"
                  disabledClassName={`${isChecked && isCheckBoxDisabled ? 'activeDisabledEvent' : 'disabledEvent'
                    }`}
                />
              );
            })}
          </Box>
        }
        {state.format !== EEventFormat.WEBINAR && baseLangOptions && baseLangOptions.length > 1 ? (
          <Box className="baseLangWrapper">
            <Box className="baseLangInner">
              <Box className="baseLang">
                <Typography variant="body2" component="span">
                  Select your base language
                </Typography>
                <CustomToolTip
                  placement="top"
                  title={
                    <Typography variant="caption" component="span">
                      The language selected here will be the default event language. The data
                      entered in base language shall be copied as default content until translation
                      is provided.
                    </Typography>
                  }>
                  <Box display="flex" alignItems="center" justifyContent="center" ml={1}>
                    <SvgInfoIcon />
                  </Box>
                </CustomToolTip>
              </Box>
              <SelectDropDown
                disabled={actionType === EActionType.EDIT}
                required
                name="baseLangOption"
                inputClassName="bgWhite"
                valueKey="langLabel"
                labelKey="langLabel"
                size="large"
                value={baseLanguage || baseLangOptions[0]}
                formControlClassName="baseLangDropDown"
                placeholder="English"
                onChange={(value) => setBaseLanguage(value)}
                options={baseLangOptions}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      <CreateEventFooter
        onNext={onNext}
        onPrevious={onPrevious}
        onNextFinish={onNextFinish}
        disabledNext={!baseLangOptions.length}
        isLoading={finisLoading}
        format={state.format}
      />
    </>
  );
};

export default EventLanguages;
