/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useCallback, useState } from 'react';
import { Box, Button, Typography, IconButton, Divider } from '@material-ui/core';
import SwitchField from 'custom-components/form-fields/switch';
import SvgCrossIcon from 'icons/svgCrossIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SvgPlusIcon from 'icons/svgPlusTwoIcon';
import SvgMinusIcon from 'icons/svgMinusIcon';
import { classification, dashboardCookies } from 'constants/cookieConsent';
import { useStyles } from './styles';
import { cookieConsentToggle, storagePrefToggle } from 'redux/actions/cookie-consent-action';
import { useDispatch } from 'react-redux';
import { ArrowLeft } from 'react-feather';

const LABELS = {
  STORAGE_PREF: 'Cookie Settings',
};

export const StoragePreferences = (props) => {
  const {
    saveHandler,
    switchHandler,
    pref = [],
    positionX,
    positionY,
    bannerMessage = '',
    sizeView,
    reduxSync,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState([classification.ESSENTIAL]);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);

  const closeHandler = () => {
    dispatch(storagePrefToggle({ isShowStoragePref: false }));
    // if (!isEmpty(consentData)) {
    //   dispatch(cookieConsentToggle({ isShow: false }));
    // }
    if (!saveButtonClicked) {
      reduxSync(); // TODO : Save button not clicked in that case we have to reset the state
    }
  };

  const saveHandlerForStoragePref = (btn = 'saveAll') => {
    // TODO : Save Data For Storage Pref
    saveHandler(btn);
    setSaveButtonClicked(true);
    dispatch(storagePrefToggle({ isShowStoragePref: false }));
    dispatch(cookieConsentToggle({ isShow: false }));
  };

  const activeAccordionTabHandler = useCallback(
    (e, val) => {
      e.stopPropagation();
      const isAlready = activeTab.some((item) => item === val);

      if (isAlready) {
        setActiveTab(activeTab.filter((item) => item !== val));
      } else {
        // setActiveTab([...activeTab, val]);
        setActiveTab([val]);
      }

      // setActiveTab(activeTab === val ? '' : val);
    },
    [activeTab]
  );

  const accordionListJSX = useMemo(() => {
    return dashboardCookies.map((item) => {
      const isActive = activeTab.some((v) => v === item.classification);
      return (
        <>
          <Box className={classes.cookieAccordionBlock} mt={1}>
            <Accordion
              expanded={isActive}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className="expand-button"
                  onClick={(e) => {
                    activeAccordionTabHandler(e, item.classification);
                  }}>
                  {' '}
                  {isActive ? <SvgMinusIcon /> : <SvgPlusIcon />}{' '}
                </Button>
                <Typography
                  component="p"
                  variant="subtitle1"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {item.classification}
                </Typography>
                <SwitchField
                  checked={pref.length ? pref.some((val) => val.id === item.id) : false}
                  disabled={item.classification === classification.ESSENTIAL}
                  // label={item.classification}
                  switchType="normal"
                  onChange={(e) => {
                    if (item.classification !== classification.ESSENTIAL) {
                      e.stopPropagation();
                      switchHandler(item);
                    }
                  }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="p" color="textSecondary">
                  {item.desc}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </>
      );
    });
  }, [activeTab, classes.cookieAccordionBlock, pref, activeAccordionTabHandler, switchHandler]);

  return (
    // <Fade in timeout={{ enter: 2000, exit: 1000 }}>
    <Box className={`${classes.cookiePolicyModalWrapper} ${positionX} ${positionY} ${sizeView}`}>
      <Box
        className={classes.cookieCloseBtn}
        onClick={() => {
          closeHandler();
        }}>
        <IconButton>
          <SvgCrossIcon width="14" height="14" />
        </IconButton>
      </Box>
      <Box mb={1.4} display="flex" alignItems="center">
        <IconButton
          onClick={() => {
            dispatch(cookieConsentToggle({ isShow: true }));
            dispatch(storagePrefToggle({ isShowStoragePref: false }));
          }}
          size="small"
          className="backBtn">
          <ArrowLeft size={22} />
        </IconButton>
        <Typography component="h5" color="textPrimary">
          {LABELS.STORAGE_PREF}
        </Typography>
      </Box>

      <Box className="innerBodyWrapper" pr={0.5}>
        <Box ml={4.25}>
          <Typography variant="body2" component="p" color="textSecondary">
            {bannerMessage || ''}
          </Typography>
        </Box>
        <Box className="accordionBlock">{accordionListJSX}</Box>
      </Box>
      <Box className={classes.divider} mt={1}>
        <Divider />
      </Box>
      <Box mt={2} className={classes.cookieBtnGroup}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            saveHandlerForStoragePref('saveAll');
          }}>
          Accept All
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            saveHandlerForStoragePref('rejectAll');
          }}>
          Accept Only Essential Cookies
        </Button>
      </Box>
    </Box>
    // </Fade>
  );
};
