export const colors = {
  white: '#FFFFFF',
  geraldine: '#fa9186',
  textDarker1: 'text.darker1',
  textSecondary: 'textSecondary',
  textPrimary: 'textPrimary',
  primary: 'primary',
  silverChalice: '#A5A5A5',
  doveGray: '#727272',
  blueChill: '#0e76a8',
  razzmatazz: '#cf005d',
  cerulean: '#00acee',
  crusta: '#FF8630',
  jungleGreen: '#28C471',
  casablanca: '#fbb349',
  primaryMain: 'primary.main',
  textGrayColor: 'text.grayColor',
  geraldine2: '#F59C86',
  burntSienna: '#EB5757',
  black: '#000000',
  alto: '#e0e0e0',
  cornFlowerBlue: '#2D8CFF',
  melRose: '#A3A1FB',
  pestleGreen: '#5EE2A0',
  bitterSweat: '#FF6565',
  koromiko: '#FEC163',
  vividTangerine: '#FFA177',
  helioTrope: '#9372FA',
  persianPink: '#F67AB2',
  grayish: '#5A33E433',
  pastelPink: '#FFD2E4',
  wildSand: '#F6F6F6',
  conifer: '#9FE060',
  malibu: '#46EAFF',
  azureRadiance: '#028BFF',
  gallery: '#ededed',
  matisse: '#1f77b4',
  flamenco: '#ff7f0e',
  forestGreen: '#2ca02c',
  punch: '#d62728',
  wisteria: '#9467bd',
  spicyMix: '#8c564b',
  manila: '#D8C69E',
  energy: '#E4875D',
};
