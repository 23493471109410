export const SET_MULTI_EVENT_DATA = "SET_MULTI_EVENT_DATA";
export const REMOVE_MULTI_EVENT_DATA = "REMOVE_MULTI_EVENT_DATA";

export const setEventData = (multiEventData) => {
    return {
        type: SET_MULTI_EVENT_DATA,
        data: multiEventData
    }
}

export const removeEventData = () => {
    return {
        type: REMOVE_MULTI_EVENT_DATA
    }
}