export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_RAW_DATES = "SET_RAW_DATES";
export const REMOVE_SESSION_DATA = "REMOVE_SESSION_DATA";

export const setSessionData = (sessionData) => {
    return {
        type: SET_SESSION_DATA,
        data: sessionData
    }
}

export const removeSessionData = () => {
    return {
        type: REMOVE_SESSION_DATA
    }
}

export const setRawDates = (rawDates) => {
    return {
        type: SET_RAW_DATES,
        data: rawDates
    }
}