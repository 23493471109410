import React from 'react';

import SvgChineseFlag from 'icons/svgChineseFlag';
import SvgGermanFlag from 'icons/svgGermanFlag';
import SvgPortugueseFlag from 'icons/svgPortugueseFlag';
import SvgRussianFlag from 'icons/svgRussianFlag';
import SvgSpanishFlag from 'icons/svgSpanishFlag';
import SvgVietnameseFlag from 'icons/svgVietnameseFlag';
import SvgKoreanFlag from 'icons/svgKoreanFlag';
import SvgIndiaFlag from 'icons/svgIndiaFlag';
import SvgFranceFlag from 'icons/svgFranceFlag';
import SvgUsFlag from 'icons/svgUsFlag';
import SvgItalyFlag from 'icons/svgItalianFlag';

const MultiLangOptions = [
  {
    langName: 'Chinese',
    langDisplayName: 'Chinese (Simplified) - 中文(简体)',
    isChecked: false,
    id: 25,
    langIcon: <SvgChineseFlag height="24px" width="24px" />,
  },
  {
    langName: 'Traditional Chinese',
    langDisplayName: 'Chinese (Traditional) - 繁體中文',
    isChecked: false,
    id: 143,
    langIcon: <SvgChineseFlag height="24px" width="24px" />,
  },
  {
    langName: 'English',
    langDisplayName: 'English',
    isChecked: false,
    id: 34,
    langIcon: <SvgUsFlag height="24px" width="24px" />,
  },
  {
    langName: 'French',
    langDisplayName: 'French - Français',
    isChecked: false,
    id: 40,
    langIcon: <SvgFranceFlag height="24px" width="24px" />,
  },
  {
    langName: 'German',
    langDisplayName: 'German - Deutsch',
    isChecked: false,
    id: 43,
    langIcon: <SvgGermanFlag height="24px" width="24px" />,
  },
  {
    langName: 'Italian',
    langDisplayName: 'Italian - Italiano',
    isChecked: false,
    id: 58,
    langIcon: <SvgItalyFlag height="24px" width="24px" />,
  },
  {
    langName: 'Portuguese',
    langDisplayName: 'Portuguese - Português (Portugal)',
    isChecked: false,
    id: 97,
    langIcon: <SvgPortugueseFlag height="24px" width="24px" />,
  },
  {
    langName: 'Russian',
    langDisplayName: 'Russian - Pусский',
    isChecked: false,
    id: 102,
    langIcon: <SvgRussianFlag height="24px" width="24px" />,
  },
  {
    langName: 'Spanish',
    langDisplayName: 'Spanish - Español',
    isChecked: false,
    id: 116,
    langIcon: <SvgSpanishFlag height="24px" width="24px" />,
  },
  {
    langName: 'Vietnamese',
    langDisplayName: 'Vietnamese - Tiếng Việt',
    isChecked: false,
    id: 137,
    langIcon: <SvgVietnameseFlag height="24px" width="24px" />,
  },
  {
    langName: 'Korean',
    langDisplayName: 'Korean - 한국인',
    isChecked: false,
    id: 67,
    langIcon: <SvgKoreanFlag height="24px" width="24px" />,
  },
  {
    langName: 'Hindi',
    langDisplayName: 'Hindi - हिंदी',
    isChecked: false,
    id: 51,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    langName: 'Japanese',
    langDisplayName: 'Japanese - 日本',
    isChecked: false,
    id: 59,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    id: 54,
    langName: 'Indonesian',
    langDisplayName: 'Bahasa Indonesian',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    langName: 'Bashkir',
    langDisplayName: 'Bashkir - Башҡорт',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    langName: 'Catalan',
    langDisplayName: ' Catalan - Català',
    isChecked: false,
    id: 23,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    langName: 'Brazilian Portuguese',
    langDisplayName: 'Portuguese - Português (Brazilian)',
    isChecked: false,
    id: 142,
    langIcon: <SvgPortugueseFlag height="24px" width="24px" />,
  },
  {
    id: 123,
    langName: 'Thai',
    langDisplayName: 'Thai - ภาษาไทย',
    short_code: 'TH',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    id: 30,
    langName: 'Czech',
    langDisplayName: 'Czech - česky',
    short_code: 'CS',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    id: 101,
    langName: 'Romanian',
    langDisplayName: 'Romanian - română',
    short_code: 'RO',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    id: 95,
    langName: 'Polish',
    langDisplayName: 'Polish - polski',
    short_code: 'PL',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
  {
    id: 112,
    langName: 'Slovak',
    langDisplayName: 'Slovak - slovenčina',
    short_code: 'SK',
    isChecked: false,
    langIcon: <SvgIndiaFlag height="24px" width="24px" />,
  },
];

export default MultiLangOptions;
