import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  cardBtnActionBlock: {
    '& button': {
      margin: theme.spacing(1.5),
      padding: theme.spacing(0),
      minWidth: '30px',
      width: '30px',
      background: 'rgba(255,255,255,1)',
      height: '30px',
    },
    '&:hover': {
      '& button': {
        background: 'rgba(255,255,255,.9)',
      },
    },
  },
  campaignNameStyle: {
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  emailWelcomeBlock: {
    backgroundColor: theme.palette.bg.whiteOff1,
    marginTop: theme.spacing(3.3),
    position: 'relative',
    padding: theme.spacing(3.5),
    borderRadius: theme.shape.borderRadius,
    minHeight: 168,
    '& .emailPicture': {
      position: 'absolute',
      top: '-40px',
      right: '0',
    },
  },

  tabBlock: {
    '&:nth-child(n+2)': {
      marginLeft: 20,
    },
  },
  emailPreviewDeskModal: {
    backgroundColor: theme.palette.bg.whiteOff4,
    position: 'relative',
    borderRadius: 10,
    minHeight: '400px',
    margin: 20,
    border: `1px solid ${theme.palette.bg.separator}`,
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',

    '& .modalHeader': {
      padding: 20,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItem: 'center',
      backgroundColor: theme.palette.bg.whiteOff1,
      borderRadius: `10px 10px 0 0 `,
      paddingLeft: 20,
      borderBottom: `1px solid ${theme.palette.bg.separator}`,
    },

    '& .deskBody': {
      maxHeight: '370px',
      minHeight: '370px',
      overflowY: 'scroll',
    },
  },
  chipLabel: {
    maxWidth: 145,
    borderRadius: theme.shape.borderRadius,
    '& .MuiChip-label': {
      fontSize: theme.fontSize.size10,
    },
  },
  emailPreviewMobileModal: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    height: 500,
    '& .iphoneMock': {
      position: 'relative',
      margin: 20,
    },
    '& .phoneFrame': {
      position: 'absolute',
      zIndex: 0,
    },
    '& .phoneBody': {
      backgroundColor: theme.palette.bg.whiteOff4,
      height: 555,
      overflowY: 'auto',
      overflowX: 'hidden',
      width: 306,
      top: 45,
      left: 10,
      position: 'relative',
    },
    '& .u-row': {
      width: '100% !important',
      '& .u-col-100, & .u-col-50': {
        width: '100% !important',
        minWidth: 'auto !important',
      },
    },
  },
  badgeColor: {
    '& .active': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.alert.warn2,
      color: '#fff',
      height: 14,
      minWidth: 14,
      borderRadius: '50%',
      padding: 0,
      fontSize: 10,
      transition: 'all 0.2s',
      animation: `$pulsing 1.5s infinite`,
    },
  },

  '@keyframes pulsing': {
    from: {
      boxShadow: '0 0 0 0 rgba(251, 122, 117,1)',
    },
    '70%': {
      boxShadow: '0 0 0 8px rgba(251, 122, 117,0)',
    },
    to: {
      boxShadow: '0 0 0 0 rgba(251, 122, 117,0)',
    },
  },

  emailDisableTextBlock: {
    padding: `${theme.spacing(13.75)}px ${theme.spacing(1)}px ${theme.spacing(
      3.125
    )}px ${theme.spacing(6)}px`,
    '& .emailDisableDesc': {
      padding: `${theme.spacing(1)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
    },
    '& .MuiButton-root': {
      marginRight: theme.spacing(2),
    },
  },
  emailDisableImgBlock: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(0)}px`,
  },
  upgradeNowModal: {
    '& .MuiTypography-body2': {
      maxWidth: 200,
      marginTop: theme.spacing(2),
    },
  },
}));
