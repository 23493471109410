/* eslint-disable  */

const {
  emailRegex,
  urlRegex,
  htmlTagRegex,
  passwordRegex,
  domainRegex,
  webProtocolRegex,
  linkdinUrlRegex,
  instaUrlRegex,
  facebookUrlRegex,
  twitterUrlRegex,
  emailDominRegex,
  stringWithSpaceNumberAndUndercore,
} = require('./regex');

const functionArray = [
  'isBoolean',
  'isString',
  'isRequired',
  'checkCharLen',
  'isNumber',
  'checkNumberRange',
  'isNegative',
  'hasPrecision',
  'isEmail',
  'isHTML',
  'isDate',
  'isUrl',
  'isArray',
  'isPassword',
  'isObject',
  'objectHasValue',
  'hasOwnProperties',
  'isEqual',
  'isSame',
  'isDomain',
  'isEmailDomin',
  'isStringWithSpaceNumberAndUndercore',
];

function setErrorMsg(defineErrorMsg, customErrorMsg) {
  if (!customErrorMsg) return defineErrorMsg;
  else return customErrorMsg;
}
function generateErrorData(isNotValid, message) {
  if (isNotValid) return { isValid: false, message };
  else return { isValid: true };
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const validateFunctions = {
  isBoolean(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && typeof value !== 'boolean';
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not valid`, customErrorMsg)
    );
  },
  isString(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && typeof value !== 'string';
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid String`, customErrorMsg)
    );
  },
  isRequired(value, propertyName, customErrorMsg) {
    let isNotValid = typeof value !== 'boolean' && !value;
    if (typeof value === 'string') {
      isNotValid = !value.trim();
    }
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is Required`, customErrorMsg)
    );
  },
  checkCharLen(value, propertyName, customErrorMsg, conditionalProperties = {}) {
    let isNotValid = false;
    let errMsg = '';
    if (arguments.length > 0 && typeof value === 'string') {
      if (conditionalProperties.hasOwnProperty('equal')) {
        if (value !== conditionalProperties.equal)
          return {
            isValid: false,
            message: setErrorMsg(
              `${capitalizeFirstLetter(propertyName)} is not equal to ${
                conditionalProperties.equal
              }`,
              customErrorMsg
            ),
          };
        else return { isValid: true };
      } else {
        if (conditionalProperties.hasOwnProperty('min')) {
          if (value.trim().length < conditionalProperties.min) {
            isNotValid = true;
            errMsg += ` minimum ${conditionalProperties.min} `;
          }
        }
        if (conditionalProperties.hasOwnProperty('max')) {
          if (value.trim().length > conditionalProperties.max) {
            if (!isNotValid) {
              errMsg += 'and';
            }
            errMsg += ` maximum ${conditionalProperties.max} `;
            isNotValid = true;
          }
        }
        if (isNotValid)
          return {
            isValid: false,
            message: setErrorMsg(
              `${capitalizeFirstLetter(propertyName)} is required ${errMsg} character`,
              customErrorMsg
            ),
          };
        else return { isValid: true };
      }
    } else if (arguments.length > 0 && typeof value !== 'number') {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not valid String`,
          customErrorMsg
        ),
      };
    } else return { isValid: true };
  },
  isNumber(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && (isNaN(parseFloat(value)) || !isFinite(value));
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid Number`, customErrorMsg)
    );
  },
  checkNumberRange(value, propertyName, customErrorMsg, conditionalProperties = {}) {
    let isNotValid = false;
    let errMsg = '';
    if (arguments.length > 0 && typeof value === 'number') {
      if (conditionalProperties.hasOwnProperty('equal')) {
        if (value !== conditionalProperties.equal)
          return {
            isValid: false,
            message: setErrorMsg(
              `${capitalizeFirstLetter(propertyName)} is not equal to ${
                conditionalProperties.equal
              }`,
              customErrorMsg
            ),
          };
        else return { isValid: true };
      } else {
        if (conditionalProperties.hasOwnProperty('min')) {
          if (value < conditionalProperties.min) {
            isNotValid = true;
            errMsg += ` minimum ${conditionalProperties.min} `;
          }
        }
        if (conditionalProperties.hasOwnProperty('max')) {
          if (value > conditionalProperties.max) {
            if (!isNotValid) {
              errMsg += 'and';
            }
            errMsg += ` maximum ${conditionalProperties.max} `;
            isNotValid = true;
          }
        }
        if (isNotValid)
          return {
            isValid: false,
            message: setErrorMsg(
              `${capitalizeFirstLetter(propertyName)} range is ${errMsg}`,
              customErrorMsg
            ),
          };
        else return { isValid: true };
      }
    } else if (arguments.length > 0 && typeof value !== 'number') {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} must be a number`,
          customErrorMsg
        ),
      };
    } else return { isValid: true };
  },
  isNegative(value, propertyName, customErrorMsg) {
    if (arguments.length > 0 && typeof value !== 'number') {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not a Number`,
          customErrorMsg
        ),
      };
    } else if (arguments.length > 0 && value > -1) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not a Negative Number`,
          customErrorMsg
        ),
      };
    } else {
      return { isValid: true };
    }
  },
  hasPrecision(value, propertyName, customErrorMsg) {
    if (arguments.length > 0 && typeof value !== 'number') {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not a Number`,
          customErrorMsg
        ),
      };
    } else if (arguments.length > 0 && !value.toString().split('.')[1]) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not a Negative Number`,
          customErrorMsg
        ),
      };
    } else {
      return { isValid: true };
    }
  },
  isEmail(value, propertyName, customErrorMsg) {
    const isNotValid = value !== '' && arguments.length > 0 && !emailRegex.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a email`, customErrorMsg)
    );
  },
  isHTML(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && htmlTagRegex.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is HTML`, customErrorMsg)
    );
  },
  isPassword(value, propertyName, customErrorMsg) {
    const isNotValid = value !== '' && arguments.length > 0 && !passwordRegex.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a password`, customErrorMsg)
    );
  },
  isDate(value, propertyName, customErrorMsg) {
    const dt = new Date(value);
    const isNotValid = arguments.length > 0 && (!(dt instanceof Date) || isNaN(dt));
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid date`, customErrorMsg)
    );
  },
  isUrl(value, propertyName, customErrorMsg) {
    const data = arguments.length > 0 ? (value ? value.toLowerCase() : value) : value;
    let isNotValid = false;
    switch (propertyName) {
      case 'linked_url':
        isNotValid = arguments.length > 0 && !linkdinUrlRegex.test(data);
        break;
      case 'twitter_url':
        isNotValid = arguments.length > 0 && !twitterUrlRegex.test(data);
        break;
      case 'instagram_url':
        isNotValid = arguments.length > 0 && !instaUrlRegex.test(data);
        break;
      case 'fb_url':
        isNotValid = arguments.length > 0 && !facebookUrlRegex.test(data);
        break;
      case 'website_url':
        isNotValid = arguments.length > 0 && !webProtocolRegex.test(data);
        break;
      case 'url':
        isNotValid = arguments.length > 0 && !webProtocolRegex.test(data);
        break;
      default:
        isNotValid = arguments.length > 0 && !urlRegex.test(data);
        break;
    }
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid URL`, customErrorMsg)
    );
  },
  isArray(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && (typeof value !== 'object' || !Array.isArray(value));
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid Array`, customErrorMsg)
    );
  },
  isObject(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && typeof value !== 'object';
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid object`, customErrorMsg)
    );
  },
  objectHasValue(value, propertyName, customErrorMsg, properties = {}) {
    if (arguments.length > 0 && (!value || typeof value !== 'object')) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} not a valid object`,
          customErrorMsg
        ),
      };
    } else if (
      properties.hasOwnProperty('equal') &&
      Object.keys(value).length !== properties.equal
    ) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} length is not equal`,
          customErrorMsg
        ),
      };
    } else if (properties.hasOwnProperty('min') && Object.keys(value).length < properties.min) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} min length is ${properties.min}`,
          customErrorMsg
        ),
      };
    } else if (properties.hasOwnProperty('max') && Object.keys(value).length > properties.max) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} max length is ${properties.max}`,
          customErrorMsg
        ),
      };
    } else return { isValid: true };
  },
  hasOwnProperties(value, propertyName, customErrorMsg, properties = []) {
    let isNotValid = false;
    let excludePropName;
    properties.forEach((propName) => {
      if (!value.hasOwnProperty(propName)) {
        isNotValid = true;
        excludePropName = propName;
        return;
      }
    });
    if (arguments.length > 0 && typeof value !== 'object') {
      return generateErrorData(
        true,
        setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a object`, customErrorMsg)
      );
    } else {
      return generateErrorData(
        isNotValid,
        setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} has not ${capitalizeFirstLetter(
            excludePropName
          )} property`,
          customErrorMsg
        )
      );
    }
  },
  isEqual(value, propertyName, customErrorMsg, matchValue) {
    if (arguments.length > 0 && !matchValue) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} required a matchValue in properties`,
          customErrorMsg
        ),
      };
    } else if (value !== matchValue)
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not matched`,
          customErrorMsg
        ),
      };
    else {
      return { isValid: true };
    }
  },
  isSame(value, propertyName, customErrorMsg, properties = {}) {
    if (arguments.length > 0 && !properties.matchValue) {
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} required a matchValue in properties`,
          customErrorMsg
        ),
      };
    } else if (value !== properties.matchValue)
      return {
        isValid: false,
        message: setErrorMsg(
          `${capitalizeFirstLetter(propertyName)} is not matched`,
          customErrorMsg
        ),
      };
    else {
      return { isValid: true };
    }
  },
  isDomain(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && !domainRegex.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid Domain`, customErrorMsg)
    );
  },
  isEmailDomin(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && !emailDominRegex.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(
        `${capitalizeFirstLetter(propertyName)} is not a valid Email Domain`,
        customErrorMsg
      )
    );
  },
  isStringWithSpaceNumberAndUndercore(value, propertyName, customErrorMsg) {
    const isNotValid = arguments.length > 0 && !stringWithSpaceNumberAndUndercore.test(value);
    return generateErrorData(
      isNotValid,
      setErrorMsg(`${capitalizeFirstLetter(propertyName)} is not a valid String`, customErrorMsg)
    );
  },
};
const checkSchema = function (schema) {
  if (!schema || typeof schema !== 'object') return 'Schema must be in Object format';
  let isPropNotArray = false;
  let isPopNotValidArray = false;
  for (const prop in schema) {
    if (!schema[prop] || typeof schema[prop] !== 'object' || !Array.isArray(schema[prop])) {
      isPropNotArray = true;
      break;
    }
    // eslint-disable-next-line no-loop-func
    schema[prop].forEach((validator) => {
      if (functionArray.indexOf(validator.method) < 0) {
        isPopNotValidArray = true;
        return;
      }
    });
    if (isPopNotValidArray) {
      break;
    }
  }
  if (isPropNotArray) return 'Schema properties are must be in array format';
  if (isPopNotValidArray) return 'Check schema properties value';
  return false;
};

const validate = (schema, data, hasNoExtraProperty) => {
  const MSG = checkSchema(schema);
  if (MSG) return MSG;
  if (!data || typeof data !== 'object') return 'Data is Required';
  if (
    hasNoExtraProperty &&
    JSON.stringify(Object.keys(schema)) !== JSON.stringify(Object.keys(data))
  ) {
    return 'Data has extra property';
  }
  let isNotValid = false,
    errObj = {};
  for (const prop in schema) {
    const value = data[prop];
    // eslint-disable-next-line no-loop-func
    for (const validator of schema[prop]) {
      let isValid, message;
      if (validator.method === 'isEqual') {
        ({ isValid, message } = validateFunctions[validator.method](
          value,
          prop,
          validator.errMsg,
          data[validator.properties.matchKey]
        ));
      } else
        ({ isValid, message } = validateFunctions[validator.method](
          value,
          prop,
          validator.errMsg,
          validator.properties
        ));
      if (!isValid) {
        if (!isNotValid) isNotValid = true;
        errObj[prop] = message;
        break;
      }
    }
  }
  if (isNotValid) return errObj;
  else return false;
};

export default { ...validateFunctions, checkSchema, validate };
