export const DISCOUNT_TYPES = {
  PUBLIC: 'Public',
  CODE: 'Coded',
  GROUP: 'Group',
};

export const GET_DISCOUNT_TYPES = {
  Public: 'PUBLIC',
  Coded: 'CODE',
  Group: 'GROUP',
};

export const DISCOUNT_AMOUNT = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
};

export const DISCOUNT_USAGE = {
  LIMITED: 'Limited',
  UNLIMITED: 'Unlimited',
};

export const GET_DISCOUNT_USAGE = {
  Limited: 'LIMITED',
  Unlimited: 'UNLIMITED',
};

export const GROUP_LOGIC = {
  GREATER_THAN: 'Greater than',
  NO_OF_TICKETS: 'No. of tickets',
  RANGE: 'Range',
};

export const GET_GROUP_LOGIC = {
  'Greater than': 'GREATER_THAN',
  'No. of tickets': 'NO_OF_TICKETS',
  Range: 'RANGE',
};

export const PAGE_LIMITS = 6;
