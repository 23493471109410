import React from 'react';

export default function SvgTabMockUp({ height, className, width = '367' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      className={className}
      width={width}
      viewBox="0 0 367 506"
      fill="none">
      <g filter="url(#filter0_dd_291_73037)">
        <path
          d="M4.00098 27.5012C4.00098 13.4748 15.3639 2.10425 29.3807 2.10425H336.696C350.712 2.10425 362.075 13.4749 362.075 27.5012V473.604C362.075 487.631 350.712 499.001 336.696 499.001H29.3807C15.3639 499.001 4.00098 487.631 4.00098 473.604V27.5012Z"
          fill="#3A4245"
        />
      </g>
      <path
        d="M6.20801 27.5013C6.20801 14.6946 16.5828 4.31274 29.3808 4.31274H336.696C349.494 4.31274 359.868 14.6946 359.868 27.5013V473.604C359.868 486.411 349.494 496.793 336.696 496.793H29.3808C16.5828 496.793 6.20801 486.411 6.20801 473.604V27.5013Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.075 41.304C362.685 41.304 363.179 41.4918 363.179 41.7236V61.8644C363.179 62.0962 362.685 62.284 362.075 62.284V41.304Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M362.075 65.0444C362.685 65.0444 363.179 65.2323 363.179 65.464V85.6049C363.179 85.8367 362.685 86.0245 362.075 86.0245V65.0444Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M312.419 2.10425C312.419 1.49441 312.641 1.00003 312.916 1.00003L336.75 1.00003C337.025 1.00003 337.247 1.49441 337.247 2.10425L312.419 2.10425Z"
        fill="#121515"
      />
      <mask id="mask0_291_73037" maskUnits="userSpaceOnUse" x="22" y="20" width="322" height="461">
        <rect x="22.7598" y="20.876" width="320.557" height="459.354" fill="white" />
      </mask>

      <defs>
        <filter
          id="filter0_dd_291_73037"
          x="0.000976562"
          y="0.104248"
          width="366.074"
          height="504.897"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0823529 0 0 0 0 0.25098 0 0 0 0.14 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_291_73037" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0823529 0 0 0 0 0.25098 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_291_73037"
            result="effect2_dropShadow_291_73037"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_291_73037"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use transform="translate(0 -0.079085) scale(0.000405186 0.000282756)" />
        </pattern>
      </defs>
    </svg>
  );
}
