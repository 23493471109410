import React from 'react';
import { Box } from '@material-ui/core';
import SvgBasicPlanIcon from 'icons/svgBasicPlanIcon';
import SvgAdvancePlanIcon from 'icons/svgAdvancePlanIcon';
import SvgProPlanIcon from 'icons/svgProPlanIcon';
import SvgEnterprisePlanIcon from 'icons/svgEnterprisePlanIcon';
import { useSelector, shallowEqual } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';

export const ShowPlanIcon = () => {
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  switch (UserInfo.planName) {
    case 'free':
      return '';
    case 'advanced':
      return (
        <Box className="square" mr={2}>
          <SvgAdvancePlanIcon />
        </Box>
      );
    case 'pro':
      return (
        <Box className="square" mr={2}>
          <SvgProPlanIcon />
        </Box>
      );
    case 'enterprise':
      return (
        <Box className="square" mr={2}>
          <SvgEnterprisePlanIcon />
        </Box>
      );
    case 'basic':
      return (
        <Box className="square" mr={2}>
          <SvgBasicPlanIcon />
        </Box>
      );
    default:
      return '';
  }
};
