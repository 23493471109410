import { useState, useEffect } from 'react';
import { API, GETAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import projectSetting from 'settings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

// This custom hook takes the api url and by using the it will fetch the data and returns the corresponding loading and data

const useFetch = (url, callAPI = true) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const reduxData = useSelector(commonSelector, shallowEqual);
  const source = useSource();
  const [headers] = API.generateHeader(reduxData, null, null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!callAPI) return;
    setLoading(true);
    GETAPIWITHCUSTOMDOMAIN(false, url, source.AS, headers, null, projectSetting.customBaseURL6)
      .then((res) => {
        setLoading(false);
        if (res.status === API.apiSuccessStatus) {
          setData(res.data);
        } else {
          API.errStatusHandler(res, history, dispatch);
        }
      })
      .catch((error) => {
        setLoading(false);
        API.httpErrorStatusHandler(error, history, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, callAPI]);
  return [data, isLoading];
};

useFetch.propTypes = {
  url: PropTypes.string.isRequired,
};
export default useFetch;
