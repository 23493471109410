export const PricingPlan = {
  FREE: 'free',
  BASIC: 'basic',
  ADVANCED: 'advanced',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
  ALL_IN: 'all-in',
  DIGITAL_EVENTS: 'digital-events',
  WEBINAR: 'webinar',
  WEBINAR_LITE: 'webinar_lite',
};
