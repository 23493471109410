/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgEventRestriction({ width, height }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.73654 3.12321C3.24041 3.12321 3.64887 2.71601 3.64887 2.21371C3.64887 1.7114 3.24041 1.3042 2.73654 1.3042C2.23268 1.3042 1.82422 1.7114 1.82422 2.21371C1.82422 2.71601 2.23268 3.12321 2.73654 3.12321Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M5.86546 3.12321C6.36932 3.12321 6.77778 2.71601 6.77778 2.21371C6.77778 1.7114 6.36932 1.3042 5.86546 1.3042C5.36159 1.3042 4.95312 1.7114 4.95312 2.21371C4.95312 2.71601 5.36159 3.12321 5.86546 3.12321Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M8.99437 3.12321C9.49824 3.12321 9.90671 2.71601 9.90671 2.21371C9.90671 1.7114 9.49824 1.3042 8.99437 1.3042C8.4905 1.3042 8.08203 1.7114 8.08203 2.21371C8.08203 2.71601 8.4905 3.12321 8.99437 3.12321Z"
        fill="url(#paint2_linear)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H110V71.4624H0V0ZM0.52166 0.52166V70.9407H109.478V0.52166H0.52166Z"
        fill="#353535"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M109.74 4.46307H0.261719V3.94141H109.74V4.46307Z"
        fill="#353535"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3711 71.3023V4.20215H34.8928V71.3023H34.3711Z"
        fill="#353535"
      />
      <path d="M29.7219 12.1279H4.86328V25.0544H29.7219V12.1279Z" fill="url(#paint3_linear)" />
      <path
        d="M48.9992 15.2002H44.7992H44.1992V44.0002H50.1992L53.7992 51.2002H54.9992L58.5992 44.0002H71.7992V32.0002H81.3992V15.2002H48.9992Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M59.1848 35.7433L60.0616 33.4412H65.8957L66.7636 35.7407C66.9351 36.1954 67.4425 36.4238 67.8962 36.2526C68.3503 36.0813 68.5795 35.5742 68.4081 35.12L64.0618 23.6031C64.0589 23.5952 64.0556 23.5871 64.0523 23.5791C63.8752 23.1486 63.4602 22.8706 62.9948 22.8706H62.9935C62.5275 22.8711 62.1124 23.1502 61.9361 23.5813C61.9336 23.588 61.9309 23.5946 61.9284 23.6012L57.5422 35.1175C57.3693 35.571 57.5972 36.0788 58.0508 36.2517C58.5037 36.4249 59.0121 36.1969 59.1848 35.7433ZM62.9921 25.747L65.2324 31.6833H60.7311L62.9921 25.747Z"
        fill="white"
      />
      <path
        d="M101.801 32.6001H72.4008H71.8008V54.8001L76.6008 56.0001L80.2008 60.8001L83.2008 55.4001H101.801V32.6001Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M90.8271 40.4932H91.9111C92.3966 40.4932 92.79 40.0998 92.79 39.6143C92.79 39.1288 92.3966 38.7354 91.9111 38.7354C90.795 38.7354 88.7222 38.7354 87.8013 38.7354V38.1177C87.8013 37.6322 87.4079 37.2388 86.9224 37.2388C86.4369 37.2388 86.0435 37.6322 86.0435 38.1177V38.7354H81.9337C81.4482 38.7354 81.0548 39.1288 81.0548 39.6143C81.0548 40.0998 81.4482 40.4932 81.9337 40.4932H89.0587C88.7371 43.1167 87.8837 45.0852 86.4533 46.4603C85.8363 45.8428 85.479 45.0131 85.3288 43.9282C85.2622 43.4472 84.8181 43.1109 84.3377 43.1782C83.8568 43.2446 83.521 43.6884 83.5877 44.1692C83.7939 45.6586 84.3253 46.7375 85.0353 47.5237C84.1106 48.0598 83.0269 48.4408 81.7741 48.6722C81.2968 48.7603 80.9812 49.2187 81.0695 49.696C81.1476 50.1194 81.517 50.4155 81.9327 50.4155C81.9857 50.4155 82.0393 50.4107 82.0933 50.4007C83.8079 50.0841 85.2826 49.5014 86.5077 48.6641C87.6728 49.2906 88.997 49.5238 90.1374 49.6352C90.1665 49.6379 90.1953 49.6394 90.2239 49.6394C90.6708 49.6394 91.0533 49.2999 91.0977 48.846C91.1448 48.3629 90.7915 47.933 90.3084 47.8857C89.3974 47.7967 88.6295 47.6393 87.9882 47.4022C88.2013 47.1757 88.4031 46.9381 88.5926 46.6887C89.8092 45.0871 90.5422 43.0531 90.8271 40.4932Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1.93735"
          y1="1.3042"
          x2="3.64324"
          y2="3.12848"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="5.06625"
          y1="1.3042"
          x2="6.77214"
          y2="3.12849"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="8.19516"
          y1="1.3042"
          x2="9.90105"
          y2="3.1285"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="6.40452"
          y1="12.1279"
          x2="15.9791"
          y2="31.7576"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="46.5056"
          y1="15.2002"
          x2="80.1849"
          y2="52.3025"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="73.6608"
          y1="32.6001"
          x2="99.958"
          y2="62.425"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EDC59C" />
          <stop offset="0.577" stop-color="#ED7767" />
          <stop offset="1" stop-color="#DC4E7B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
