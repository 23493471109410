import React from 'react';

export default function SvgPay(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" {...props}>
      <g>
        <path
          d="M12.0001 8.64998C11.1859 8.64998 10.5235 8.10816 10.5235 7.44216H9.11725C9.11725 8.66367 10.0461 9.69188 11.2969 9.97725V11.0625H12.7032V9.97725C13.954 9.69188 14.8829 8.66367 14.8829 7.44216C14.8829 6.00075 13.5896 4.82812 12.0001 4.82812C11.1859 4.82812 10.5235 4.2863 10.5235 3.62034C10.5235 2.95439 11.1859 2.41252 12.0001 2.41252C12.8142 2.41252 13.4766 2.95434 13.4766 3.62034H14.8829C14.8829 2.39883 13.954 1.37063 12.7032 1.08525V0H11.2969V1.08525C10.0461 1.37063 9.11725 2.39878 9.11725 3.62034C9.11725 5.06175 10.4105 6.23438 12.0001 6.23438C12.8142 6.23438 13.4766 6.7762 13.4766 7.44216C13.4766 8.10811 12.8143 8.64998 12.0001 8.64998Z"
          fill="#C1C1C1"
        />
        <path
          d="M23.2939 11.7141C22.8587 11.2968 22.2864 11.0711 21.6841 11.0771C21.0812 11.0835 20.5145 11.322 20.087 11.7499L16.2522 15.609H14.7472C14.8589 15.3402 14.9206 15.0455 14.9206 14.7367C14.9206 13.4804 13.899 12.4583 12.6433 12.4583H7.46813C6.12783 12.4583 4.86797 12.9804 3.92058 13.9285L0 17.951V24H3.51427L7.1242 20.9033H17.6638L23.3347 14.9844C23.7703 14.5455 24.0065 13.9635 23.9999 13.3447C23.9932 12.7233 23.7425 12.1442 23.2939 11.7141ZM22.3342 13.9961L17.0636 19.4971H6.6037L2.99377 22.5938H1.40625V18.5229L4.91836 14.9194C5.59973 14.239 6.50503 13.8644 7.46813 13.8644H12.6434C13.1236 13.8644 13.5144 14.2557 13.5144 14.7366C13.5144 15.2175 13.1237 15.6089 12.6434 15.6089H8.00822V17.0151H16.8373L21.0832 12.7424C21.4208 12.4045 21.9759 12.3985 22.3207 12.7291C22.4942 12.8954 22.5912 13.1194 22.5938 13.3598C22.5963 13.6001 22.5041 13.8261 22.3342 13.9961Z"
          fill="#C1C1C1"
        />
      </g>
    </svg>
  );
}
