import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Box } from '@material-ui/core';

import CreateEventBanner from './CreateNewEventLayout/CreateEventBanner';
import CreateNewEventHeader from './CreateNewEventLayout/CreateNewEventHeader';
import { CREATE_EVENT_CONSTANTS, EActionType } from './common/constants';

import { useStyles } from 'pages/CreateNewEvent/style';
import CreateEventBannerLoading from 'pages/CreateNewEvent/Loaders/CreateEventBannerLoader';
import { PricingPlan } from 'constants/pricingPlan';
import { shallowEqual, useSelector } from 'react-redux';
import { userInfoSelector } from 'redux/custom-selector';

const CreateNewEventLayout = ({ children, actionType }) => {
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const classes = useStyles();

  const isProOrEnterpriseUser =
    !userInfo?.UserInfo?.planName ||
    userInfo?.UserInfo?.planName === PricingPlan.PRO ||
    userInfo?.UserInfo?.planName === PricingPlan.ENTERISE;

  const addTopMarginToHeader =
    isProOrEnterpriseUser &&
    userInfo?.UserInfo?.organiser_community_version === 1 &&
    userInfo.UserInfo.contact_for_version_change === 1;

  // here we are checking for the class called "planExpirePadTop" from header
  // if that is not there then we are adding that class to our component

  const isPaddingClassExists =
    (userInfo.UserInfo.is_payment_due_or_auto_collection_off !== undefined &&
      userInfo.UserInfo.is_payment_due_or_auto_collection_off.auto_collection === 'off') ||
    // userInfo.UserInfo.subscriptionStatus === 'CANCEL_SCHEDULED' ||
    userInfo.UserInfo.subscriptionStatus === 'CANCELLED' ||
    (userInfo.UserInfo.subscriptionStatus === 'ACTIVE' && userInfo.UserInfo.isPaymentFailed === 1)
      ? true
      : !!addTopMarginToHeader;

  return (
    <Box className={classes.createNewEventLayout}>
      <Grid container maxWidth="lg">
        <Grid item xs={8}>
          <CreateNewEventHeader actionType={actionType} />
          <Box
            className={classes.eventContainer}
            pt={
              userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
              userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE ||
              !userInfo?.UserInfo?.planName
                ? 18
                : 11
            } /* pt={actionType === EActionType.EDIT ? 15 : 20} */
          >
            {/* <Box className={`${planName === PricingPlan.ENTERPRISE || planName === PricingPlan.PRO ? '' : 'planExpirePadTop'}`}> */}
            <Box className={!isPaddingClassExists ? 'planExpirePadTop' : ''}>{children}</Box>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.createEventBannerWrapper}>
          {CREATE_EVENT_CONSTANTS.IS_LOADING ? <CreateEventBannerLoading /> : <CreateEventBanner />}
        </Grid>
      </Grid>
    </Box>
  );
};

CreateNewEventLayout.prototypes = {
  children: PropTypes.element.isRequired,
  actionType: PropTypes.oneOf([EActionType.EDIT, EActionType.CREATE]).isRequired,
};

export default CreateNewEventLayout;
