import React from 'react';
import { makeStyles } from '@material-ui/core';
import SvgEventFormat from 'icons/svgEventFormat';
import SvgEventType from 'icons/svgEventType';
import SvgEventDetails from 'icons/svgEventDetails';
import SvgEventLanguages from 'icons/svgEventLanguages';
import SvgTicketTickIcon from 'icons/svgCheckTickIcon';
import { EEventScreen, EActionType, EEventFormat } from '../common/constants';
import { capitalizeFirstLetter } from '../../../lib/common-function';
import SvgStepperLockIcon from 'icons/svgStepperLockIcon';
import { useHistory, useParams } from 'react-router';
import { getActionFromPath } from '../common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { commonSelector, userInfoSelector } from 'redux/custom-selector';
import { useCustomCreateEventContext } from '../context';
import { PricingPlan } from 'constants/pricingPlan';

const useStyles = makeStyles((theme) => ({
  stepperLink: {
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    margin: 0,
    padding: 0,
    listStyleType: 'none',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingRight: '2rem',
      color: '#c0c0c0',
      // cursor: 'not-allowed',

      '& span': {
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        marginRight: '8px',
        border: '1px solid #c0c0c0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          color: '#c0c0c0',
        },
        '& .visitedTickIcon': {
          display: 'none',
        },
        '&.lockEventIcon': {
          borderStyle: 'solid',
          /* '& svg': {
                        '& path': {
                            fill: '#c0c0c0'
                        }
                    }, */
        },
      },

      '&:not(:first-child)': {
        '& span': {
          marginLeft: '8px',
        },
        '&::after': {
          position: `absolute`,
          content: `""`,
          width: `24px`,
          height: '2px',
          background: '#c0c0c0',
          left: '-24px',
          top: '15px',
          zIndex: 1,
        },
      },

      /*   '&:not(:first-child)': {
                  '&::after': {
                      position: `absolute`,
                      content: `""`,
                      width: `24px`,
                      height: '2px',
                      background: '#c0c0c0',
                      right: 0,
                      top: '15px',
                      zIndex: 1,
                  }
              } */
    },

    '& .active': {
      color: theme.palette.primary.light,
      cursor: 'default',
      '& span': {
        background: theme.palette.primary.light,
        border: 'none',
        '& svg': {
          color: theme.palette.secondary.contrastText,
        },
      },
      '&::after': {
        background: `${theme.palette.primary.light}!important`,
      },
      /*  '& .lockEventIcon': {
                 '& svg': {
                     '& path': {
                         fill: theme.palette.secondary.contrastText,
                     }
                 },
             }, */
    },

    '& .visited': {
      color: theme.palette.primary.light,
      cursor: 'default',
      '& span': {
        border: '1px solid #c0c0c0',
        '& svg': {
          color: theme.palette.primary.light,
        },
      },

      '& .icon': {
        display: 'none',
      },
      '& .visitedTickIcon': {
        display: 'block',
        '& svg path': {
          fill: theme.palette.primary.light,
        },
      },
      '&::after': {
        background: `${theme.palette.primary.light}!important`,
      },
    },
  },
}));

const Steppers = () => {
  const classes = useStyles();
  // params - contains the route param such as screen ID, eventID, organiser id
  const params = useParams();

  // accessing the context data
  const { state } = useCustomCreateEventContext();

  // history - contains the route history
  const history = useHistory();

  // screen - represents the current screen (ex: format, type, details, language)
  const screen = Number(params.screenId);

  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  // commonSelectorData - contains the common data in the redux
  const commonSelectorData = useSelector(commonSelector, shallowEqual);

  // userInfo - containes the logged in user
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  const planName = userInfo?.UserInfo?.planName || '';

  // const isLangStepperOpen =
  //   planName === PricingPlan.WEBINAR || planName === PricingPlan.WEBINAR_LITE || planName === ''
  //     ? state.format === EEventFormat.VIRTUAL
  //     : state.format !== EEventFormat.WEBINAR;

  return (
    <ol className={classes.stepperLink}>
      <li className={`${screen === EEventScreen.EVENT_FORMAT ? 'active' : 'visited'} `}>
        <span className={userInfo?.UserInfo?.can_hyb_inp_event !== 1 ? 'lockEventIcon' : ''}>
          {planName === PricingPlan.WEBINAR ||
          planName === PricingPlan.WEBINAR_LITE ||
          planName === '' ? (
            <>
              <SvgEventFormat className="icon" />
              <SvgTicketTickIcon className="visitedTickIcon" />
            </>
          ) : userInfo?.UserInfo?.can_hyb_inp_event !== 1 ? (
            <SvgStepperLockIcon
              className={`${screen === EEventScreen.EVENT_FORMAT ? 'icon' : ''} svgLockIcon`}
            />
          ) : (
            <>
              <SvgEventFormat className="icon" />
              <SvgTicketTickIcon className="visitedTickIcon" />
            </>
          )}
        </span>
        {screen === EEventScreen.EVENT_FORMAT
          ? 'Format'
          : `${capitalizeFirstLetter(state.format)} event`}
      </li>
      <li
        className={`${
          screen === EEventScreen.EVENT_TYPE
            ? 'active'
            : screen === EEventScreen.EVENT_DETAILS ||
              screen === EEventScreen.EVENT_LANGUAGES ||
              actionType === EActionType.EDIT
            ? 'visited'
            : ''
        } `}>
        <span>
          <SvgEventType className="icon" />
          <SvgTicketTickIcon className="visitedTickIcon" />
        </span>
        {screen === EEventScreen.EVENT_TYPE || !state.eventTypeName ? 'Type' : state.eventTypeName}
      </li>
      <li
        className={`${
          screen === EEventScreen.EVENT_DETAILS
            ? 'active'
            : screen === EEventScreen.EVENT_LANGUAGES || actionType === EActionType.EDIT
            ? 'visited'
            : ''
        } `}>
        <span>
          <SvgEventDetails className="icon" />
          <SvgTicketTickIcon className="visitedTickIcon" />
        </span>
        Details
      </li>
      {/* {isLangStepperOpen && ( */}
        <li
          className={`${
            screen === EEventScreen.EVENT_LANGUAGES
              ? 'active'
              : actionType === EActionType.EDIT
              ? 'visited'
              : ''
          } `}>
          <span className={commonSelectorData.is_language_support !== 1 ? 'lockEventIcon' : ''}>
            {commonSelectorData.is_language_support === 0 ? (
              <SvgStepperLockIcon
                className={`${screen === EEventScreen.EVENT_LANGUAGES ? 'icon' : ''} svgLockIcon`}
              />
            ) : (
              <>
                <SvgEventLanguages className="icon" />
                <SvgTicketTickIcon className="visitedTickIcon" />
              </>
            )}
          </span>
          Languages
        </li>
      {/* )} */}
    </ol>
  );
};

export default Steppers;
