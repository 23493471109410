import React from 'react';

export default function SvgAdvancePlanIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1758 0C19.8203 0 20.3437 0.523438 20.3437 1.16797C20.3437 1.8125 19.8203 2.33594 19.1758 2.33594C18.5312 2.33594 18.0078 1.8125 18.0078 1.16797C18.0098 0.523438 18.5312 0 19.1758 0ZM19.4883 10.1719C20.4961 11.6758 21.7715 12.1367 23.4277 11.1191C23.4824 11.6856 23.4961 12.2637 23.4668 12.8496C23.1406 19.3262 17.6269 24.3125 11.1504 23.9863C4.67578 23.6582 -0.310552 18.1445 0.0156203 11.6699C0.339839 5.19336 6.04492 0.152344 12.5195 0.478516C11.9453 2.27539 12.4316 3.87109 13.8926 4.41406C12.5684 8.66797 15.5254 11.4512 19.4883 10.1719ZM5.86523 9.33399C6.83593 9.33399 7.62109 10.1211 7.62109 11.0898C7.62109 12.0586 6.83398 12.8457 5.86523 12.8457C4.89453 12.8457 4.10937 12.0586 4.10937 11.0898C4.10937 10.1211 4.89648 9.33399 5.86523 9.33399ZM11.3965 11.5723C11.9551 11.5723 12.4082 12.0254 12.4082 12.584C12.4082 13.1426 11.9551 13.5957 11.3965 13.5957C10.8379 13.5957 10.3848 13.1426 10.3848 12.584C10.3828 12.0254 10.8359 11.5723 11.3965 11.5723ZM7.00585 15.7402C7.6875 15.7402 8.24023 16.293 8.24023 16.9746C8.24023 17.6563 7.6875 18.209 7.00585 18.209C6.32421 18.209 5.77148 17.6563 5.77148 16.9746C5.77148 16.291 6.32421 15.7402 7.00585 15.7402ZM9.66601 6.29492C10.2012 6.29492 10.6328 6.72852 10.6328 7.26172C10.6328 7.79688 10.1992 8.22852 9.66601 8.22852C9.13086 8.22852 8.69921 7.79492 8.69921 7.26172C8.69921 6.72852 9.13281 6.29492 9.66601 6.29492ZM14.9199 16.1719C15.8164 16.1719 16.541 16.8984 16.541 17.793C16.541 18.6895 15.8144 19.4141 14.9199 19.4141C14.0234 19.4141 13.2988 18.6875 13.2988 17.793C13.2988 16.8984 14.0254 16.1719 14.9199 16.1719ZM18.334 4.51172C18.9355 4.51172 19.4238 5 19.4238 5.60156C19.4238 6.20313 18.9355 6.69141 18.334 6.69141C17.7324 6.69141 17.2441 6.20313 17.2441 5.60156C17.2441 5 17.7324 4.51172 18.334 4.51172Z"
        fill="#ED7767"
      />
    </svg>
  );
}
