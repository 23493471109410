import React from 'react';

const styles = {
  a: { fill: '#231f20' },
};

export default function SvgHubiloNewIcon(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 606.1 315.17"
      {...props}>
      <path style={styles.a} d="M165.93,42.43v62.25H66.33V42.43H41.43v87.15h149.4V42.43Z" />
      <path style={styles.a} d="M165.93,185.58v0H41.43v87.15h24.9V210.5h99.6v62.22h24.9V185.58Z" />
      <path
        style={styles.a}
        d="M234.7,197.44V117.62h16.57v30.43h31V117.62h16.58v79.82H282.3V161.81h-31v35.63Z"
      />
      <path
        style={styles.a}
        d="M329,138.41c.52,5.54-1.22,38.76,1.19,43.17,3.21,7.22,16.7,5.23,19-1.9,2.91-5.45,1-34.77,1.51-41.27h15.65v59h-15V189.1c-6.73,12.84-30.15,13-35.65.22-4.56-8.16-1.83-41-2.53-50.91Z"
      />
      <path
        style={styles.a}
        d="M431.82,145.51C452.22,169.78,424,221,396.09,190v7.42H380.87V117.72h15.44V146.1C403.79,133.8,423.79,134.57,431.82,145.51Zm-12.9,36c11.52-16.11-6.43-45.84-21.41-23.24C390.52,172.29,403.54,197.59,418.92,181.47Z"
      />
      <path style={styles.a} d="M465,131.37H449.38V117.13H465Zm-15.65,7H465v59H449.38Z" />
      <path style={styles.a} d="M495.74,197.44H480.31V117.62h15.43Z" />
      <path
        style={styles.a}
        d="M560,190.22c-30.65,29-70.38-11.91-45.39-44.34C545.21,116.48,585,158.06,560,190.22Zm-12.35-8.53c11-14.3-2.81-44.67-20.83-27.34C515.81,168.6,529.75,199.1,547.68,181.69Z"
      />
    </svg>
  );
}
