import React from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import { CREATE_EVENT_CONSTANTS } from 'pages/CreateNewEvent/common/constants';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const useStyles = makeStyles(() => ({
    createEventBanner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        height: 'calc(100% - 129px)',
        flexDirection: 'column',
        '@media (max-width: 1366px)': {
            top: '5rem',
        },


        '& h5': {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            maxWidth: 'fit-content',
            '& h5': {
                marginLeft: '5px',
                marginRight: '5px'
            },
        },

        '& img': {
            maxWidth: '336px'
        },

        '& .eventDetailsBannerText': {
            maxWidth: '17rem'
        }
    },
}));


const CreateEventBannerLoading = () => {
    const classes = useStyles();

    return (
        <Grid item xs component="aside" className={classes.createEventBanner}>
            {CREATE_EVENT_CONSTANTS.EVENT_FORMAT ? (
                <SkeletonLoaders className='radiusRound' variant='square' width='300px' height='200px' />
            ) : CREATE_EVENT_CONSTANTS.EVENT_TYPE ? (
                <>
                    <SkeletonLoaders variant='square' width='300px' height='200px' />
                    <Typography component="h5" variant="h5" color="textPrimary">
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <Typography component="h5" variant="h5" color="primary">
                            <SkeletonLoaders variant='text' width='100px' height='30px' /></Typography>
                    </Typography>
                </>
            ) : CREATE_EVENT_CONSTANTS.EVENT_DETAIL ? (
                <Box className="eventDetailsBannerText">
                    <SkeletonLoaders variant='square' width='300px' height='200px' />
                    <Typography component="h5" variant="h5" color="textPrimary">
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <Typography component="h5" variant="h5" color="primary">
                            <SkeletonLoaders variant='text' width='100px' height='30px' /></Typography>
                    </Typography>
                </Box>
            ) : CREATE_EVENT_CONSTANTS.EVENT_LANGUAGES ? (
                <>
                    <SkeletonLoaders variant='square' width='300px' height='200px' />
                    <Typography component="h5" variant="h5" color="textPrimary">
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <SkeletonLoaders variant='text' width='300px' height='30px' />
                        <Typography component="h5" variant="h5" color="primary">
                            <SkeletonLoaders variant='text' width='100px' height='30px' /></Typography>
                    </Typography>
                </>
            ) : null
            }
        </Grid>
    )
}

export default CreateEventBannerLoading;