/* eslint-disable  */
import React from 'react';
import { Button, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
import CustomToolTip from 'custom-components/customToolTip';
import { ArrowDownCircle } from 'react-feather';
import { shallowEqual, useSelector } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';

const useStyles = makeStyles((theme) => ({
  downloadIconButton: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.bg.whiteOff1,
    color: theme.palette.primary.main,
  },
}));

function ExportBtn(props) {
  const classes = useStyles();
  const reduxData = useSelector(commonSelector, shallowEqual);
  // console.log("reduxData:---", reduxData);

  const { onClick, disabled, loader, title, isIcon = false, iconClass, btnSize = 'small' } = props;
  return (
    <>
      {reduxData.userPermission?.isSuperAdmin.toString() === '1' ||
      reduxData.userPermission?.isEventAdmin.toString() === '1' ||
      reduxData.userPermission?.canExport.toString() === '1' ? (
        <CustomToolTip title={title ? title : 'Export'} placement="top">
          {isIcon ? (
            <IconButton
              className={iconClass ? iconClass : classes.downloadIconButton}
              onClick={() => onClick()}
              disabled={disabled}>
              {loader ? (
                <CircularProgress color="primary" size={17} />
              ) : (
                <ArrowDownCircle size={20} />
              )}
            </IconButton>
          ) : (
            <Button
              size={btnSize ? btnSize : 'small'}
              variant="outlined"
              color="primary"
              className={iconClass ? iconClass : 'square'}
              disabled={disabled}
              onClick={() => onClick()}>
              {loader ? (
                <CircularProgress color="primary" size={17} />
              ) : (
                <ArrowDownCircle size={20} />
              )}
            </Button>
          )}
        </CustomToolTip>
      ) : (
        ''
      )}
    </>
  );
}
export default React.memo(ExportBtn);
