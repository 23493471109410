/* eslint-disable */
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useStyles } from 'pages/CreateNewEvent/style';
import InputField from 'custom-components/form-fields/inputField';

import CustomModal from 'custom-components/customModal';
import TextAreaField from 'custom-components/form-fields/textAreaField';
import SvgCommentIcon from 'icons/svgCommentIcon';
import BtnWithLoader from 'custom-components/btnWithLoader';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const EventDetailsLoader = ({ conditionsForAllFields }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.eventDetailsScreen}>

                <Box className={` ${classes.eventDetails}`} mt={3}>
                    <Box>
                        <SkeletonLoaders variant="text" width="80px" height="30px" />
                        <Box mt={-2}>
                            <SkeletonLoaders variant="text" width="100%" height="70px" />
                        </Box>
                        <Box mt={-2}>
                            <SkeletonLoaders variant="text" width="80px" height="30px" />
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <SkeletonLoaders variant="text" width="80px" height="30px" />
                        <Box mt={-2}>
                            <SkeletonLoaders variant="text" width="100%" height="70px" />
                        </Box>
                    </Box>

                    <Box className="dateTimeEventDetails" mt={3} display="flex"
                        alignItems="center"
                        justifyContent="space-between">
                        <Box>
                            <SkeletonLoaders variant="text" width="130px" height="30px" />
                            <Box display="inline-flex" alignItems="end" mr={1.5} className="eventDateTime" mt={-2}>
                                <Box mr={1}>
                                    <SkeletonLoaders variant="text" width="136px" height="70px" />
                                </Box>
                                <SkeletonLoaders variant="text" width="110px" height="70px" />
                            </Box>
                        </Box>
                        <Box>
                            <SkeletonLoaders variant="text" width="130px" height="30px" />
                            <Box display="inline-flex" alignItems="end" mr={1.5} className="eventDateTime" mt={-2}>
                                <Box mr={1}>
                                    <SkeletonLoaders variant="text" width="136px" height="70px" />
                                </Box>
                                <SkeletonLoaders variant="text" width="110px" height="70px" />
                            </Box>
                        </Box>
                    </Box>

                    <SkeletonLoaders variant="text" width="100%" height="70px" />

                    {conditionsForAllFields ? (
                        <>
                            <Box mt={3}>
                                <SkeletonLoaders variant="text" width="80px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                            </Box>

                            <Box mt={3}>
                                <SkeletonLoaders variant="text" width="150px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                                <Box mt={-11}>
                                    <SkeletonLoaders variant="text" width="100%" height="400px" />
                                </Box>
                            </Box>

                            <Box mt={-4}>
                                <SkeletonLoaders variant="text" width="100px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <SkeletonLoaders variant="text" width="100px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                            </Box>

                            <Box mt={2}>
                                <SkeletonLoaders variant="text" width="100px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                            </Box>

                            <Box mt={2}>
                                <SkeletonLoaders variant="text" width="100px" height="30px" />
                                <Box mt={-2}>
                                    <SkeletonLoaders variant="text" width="100%" height="70px" />
                                </Box>
                            </Box>

                            <Box mt={2}>
                                <SkeletonLoaders variant="text" width="100px" height="30px" />
                                <Box mt={-7}>
                                    <SkeletonLoaders variant="text" width="100%" height="250px" />
                                </Box>
                            </Box>

                            <Box className="eventSocialLinkInput" mt={-3.5}>
                                <Box mt={2}>
                                    <SkeletonLoaders variant="text" width="100px" height="30px" />
                                    <Box mt={-2}>
                                        <SkeletonLoaders variant="text" width="100%" height="70px" />
                                    </Box>
                                    <Box mt={-2}>
                                        <SkeletonLoaders variant="text" width="100%" height="70px" />
                                    </Box>
                                    <Box mt={-2}>
                                        <SkeletonLoaders variant="text" width="100%" height="70px" />
                                    </Box>
                                    <Box mt={-2}>
                                        <SkeletonLoaders variant="text" width="100%" height="70px" />
                                    </Box>
                                    <Box mt={-2}>
                                        <SkeletonLoaders variant="text" width="100%" height="70px" />
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>

        </>
    );
};

export default EventDetailsLoader;
