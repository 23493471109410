/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { Avatar, Box, Button, Typography } from '@material-ui/core';
import SvgSpinner from 'icons/svgSpinner';

// Custom Components
import BtnWithLoader from 'custom-components/btnWithLoader';
import CustomFullScreenModal from 'custom-components/customFullScreenModal';
import CustomModal from 'custom-components/customModal';
import EditableOption from 'custom-components/form-fields/editableOption';

// libs
import { useSource } from 'lib/custom-common-hooks';
import { useCommunityVersionNumber } from 'lib/hooks/use-community-version';
import {
  getEventImage,
  getEventLogo,
  getPeopleImage,
  getOtherUserImage,
} from 'lib/common-function';

// redux helpers
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';
import {
  templateModalToggle,
  setActiveTemplate,
  setActiveTemplateName,
  templateNameEditToggle,
  setTemplateActiontype,
  setCampaignId,
  setTemplateId,
  setTemplateObj,
  setTemplateIsPrimary,
  resetTemplate,
} from 'redux/actions/template-details-action';
import {
  templateModalDetailsSelector,
  commonSelector,
  eventDataSelector,
} from 'redux/custom-selector';

// API helpers
import {
  API,
  POSTAPIWITHCUSTOMDOMAIN,
  PATCH_API_WITH_CUSTOM_DOMAIN,
  genericAPIErrorHandler,
  GETAPI,
  GETAPIWITHCUSTOMDOMAIN,
} from 'api-setup/api-helper';
import { getTemplateLabel, isTrueObject } from './create-email/helpers';
import { isTrueValueArray } from 'utility/helpers';

// API endpoints
import {
  SEND_TEST_EMAIL,
  UPDATE_TEMPLATE_DETAILS_BY_ID,
  CREATE_TEMPLATE,
  GET_BRAND_EVENT_SETTING,
  GET_SPEAKER_LIST,
  GET_TEMPLATE_UNLAYER_OBJECT,
  GET_SESSION_LIST,
} from 'api-setup/api-endpoints';

// Constants
import { emailRegex } from 'models/regex';
import projectSetting from 'settings';
import {
  CUSTOM_TOOL_BANNER,
  CUSTOM_TOOL_BOOTH,
  CUSTOM_TOOL_LOGO,
  CUSTOM_TOOL_SESSION,
  CUSTOM_TOOL_SPEAKER,
  LABEL_TEMPLATE_NAME,
  templateNameElementId,
  defaultTheme,
  LABEL_TEST_DESC,
  LABEL_TEST_TITLE
} from './create-email/constants';

// Assets
import SvgEdit3 from 'icons/svgEdit2';
import SendTestEmailIcon from 'icons/svgSendTestEmailIcon';
import SvgHubiloIcon from 'icons/svgHubiloNewIcon';

// Styles
import { useStyles } from './create-email/style';
import { useBooth } from './hooks';
import amplitude from 'lib/amplitude';

// Temp Design
// import { tempDesign } from './temp-template';

// Component Constants
const CUSTOM_URL = projectSetting.customBaseURL5;

const CUSTOM_URL_API = projectSetting.customBaseURL;

const initState = {
  logos: [],
  banners: [],
  speakers: [],
  sessions: [],
  logoOrBannerCall: true,
  speakerCall: true,
  boothCall: true,
  sessionCall: true,
};

const getProfilePicByFirstChar = (FirstChar) => {
  if (FirstChar) {
    return `https://cdn.hubilo.com/comm_v2/images/profile/${FirstChar?.toLocaleUpperCase()}.png`;
  } else {
    return 'https://cdn.hubilo.com/comm_v2/images/profile/O.png';
  }
};

function checkImgURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

const getSpeakerDetailsById = (speakerId, speakerList) => {
  if (speakerId && speakerList && speakerList?.length) {
    const speaker = speakerList.find((s) => s?.id === speakerId);

    if (speaker) return speaker;
    else return '';
  } else {
    return '';
  }
};

const getBoothDetailsById = (boothId, boothList) => {
  if (boothId && boothList && boothList?.length) {
    const booth = boothList.find((b) => b?.id === boothId);
    if (booth) return booth;
    else return '';
  } else {
    return '';
  }
};

const getSessionDetailsById = (sesionId, sessionList) => {
  if (sesionId && sessionList && sessionList?.length) {
    const session = sessionList.find((s) => s?.id === sesionId);
    if (session) return session;
    else return '';
  } else {
    return '';
  }
};

const templateCustomToolDataUpdate = (template, speakerList, boothList, sessionList, theme) => {
  if (template && template?.counters && template?.body?.rows) {
    const countersString = JSON.stringify(template?.counters);
    const position = countersString.search(/custom/i);
    if (position !== -1) {
      const templateCopy = JSON.parse(JSON.stringify(template));
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line no-unused-expressions
      templateCopy?.body?.rows?.map((row) => {
        // eslint-disable-next-line no-unused-expressions
        row?.columns?.map((columns) => {
          // eslint-disable-next-line no-unused-expressions
          columns?.contents?.map((contents) => {
            if (contents?.type === 'custom') {
              if (
                contents?.slug === 'speaker_tool' &&
                contents?.values?.speakerLibrary?.selected?.id &&
                speakerList
              ) {
                const speakerUpdatedData = getSpeakerDetailsById(
                  contents?.values?.speakerLibrary?.selected?.id,
                  speakerList
                );

                if (speakerUpdatedData) {
                  const valuesCopy = { ...contents?.values };
                  valuesCopy.speakerTitle = speakerUpdatedData?.name;
                  valuesCopy.speakerDesignation = speakerUpdatedData?.designation;
                  valuesCopy.speakerCompany = speakerUpdatedData?.company;
                  valuesCopy.speakerImage = {
                    ...valuesCopy?.speakerImage,
                    url: speakerUpdatedData?.profile_img,
                  };
                  valuesCopy.speakerDesignationCompanyColor = theme?.primaryFontColor;
                  valuesCopy.speakerTitleColor = theme?.accentColor;
                  valuesCopy.speakerBGColor = theme?.primaryColor;

                  // eslint-disable-next-line no-param-reassign
                  contents.values = valuesCopy;
                }
              } else if (
                contents?.slug === 'booth_tool' &&
                contents?.values?.boothLibrary?.selected?.id &&
                boothList
              ) {
                const boothUpdatedData = getBoothDetailsById(
                  contents?.values?.boothLibrary?.selected?.id,
                  boothList
                );

                if (boothUpdatedData) {
                  const valuesCopy = { ...contents.values };

                  valuesCopy.boothName = boothUpdatedData?.name;
                  valuesCopy.boothImage = {
                    ...valuesCopy?.boothImage,
                    url: boothUpdatedData?.profile_img,
                  };
                  valuesCopy.boothNameColor = theme?.accentColor;
                  valuesCopy.boothBGColor = theme?.primaryColor;

                  // eslint-disable-next-line no-param-reassign
                  contents.values = valuesCopy;
                }
              } else if (
                contents.slug === 'session_tool' &&
                contents?.values?.sessionLibrary?.selected?.id &&
                sessionList
              ) {
                const sessionUpdatedData = getSessionDetailsById(
                  contents?.values?.sessionLibrary?.selected?.id,
                  sessionList
                );

                if (sessionUpdatedData) {
                  const valuesCopy = { ...contents?.values };
                  valuesCopy.dateAndTime = sessionUpdatedData?.dateAndTime;
                  valuesCopy.description = sessionUpdatedData?.description;
                  valuesCopy.sessionName = sessionUpdatedData?.name;
                  valuesCopy.speakers = [...sessionUpdatedData?.speakers];
                  valuesCopy.sponsors = [...sessionUpdatedData?.sponsors];
                  valuesCopy.sessionDateAndTimeColor = theme?.accentColor;
                  valuesCopy.sessionDescriptionColor = theme?.primaryFontColor;
                  valuesCopy.sessionNameColor = theme?.accentColor;
                  valuesCopy.sessionCardBGColor = theme?.primaryColor;

                  // eslint-disable-next-line no-param-reassign
                  contents.values = valuesCopy;
                }
              }
            }
          });
        });
      });
      return templateCopy;
    } else {
      return template; // no custom tool found
    }
  } else {
    return ''; // empty string will not break unlayer editor
  }
};

const templateBodyThemeUpdate = (template, theme) => {
  // return template;
  if (template && template?.counters && template?.body?.rows) {
    if (theme?.primaryColor) {
      const templateCopy = JSON.parse(JSON.stringify(template));
      if (templateCopy?.body?.values?.backgroundColor && templateCopy?.body?.values?.backgroundColor.toLowerCase() === defaultTheme.mainBackgroundColor.toLowerCase()) {
        templateCopy.body.values.backgroundColor = theme?.mainBackgroundColor?.toLowerCase();
      }
      // eslint-disable-next-line no-unused-expressions
      templateCopy?.body?.rows?.map((row) => {
        // eslint-disable-next-line no-unused-expressions
        row?.columns?.map((columns) => {
          // eslint-disable-next-line no-unused-expressions
          columns?.contents?.map((contents) => {
            if (contents?.type === 'html') {
              const htmlStr = contents?.values?.html;
              const htmlStrArr = htmlStr?.split(">");
              // eslint-disable-next-line no-unused-expressions
              htmlStrArr?.map((ele, i) => {
                let eleClone = ele;
                if (eleClone?.search(/eb_sec_fnt_clr/i) !== -1 && eleClone?.search(/eb_acnt_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.secondaryFontColor, 'i')) !== -1 && eleClone?.search(new RegExp(defaultTheme?.accentColor, 'i')) !== -1 && theme?.secondaryFontColor && theme?.accentColor) {
                  const _eleClone = eleClone?.split(";");
                  // eslint-disable-next-line no-unused-expressions
                  _eleClone?.map((elem, index) => {
                    let eleClone2 = elem;
                    if (eleClone2?.search(/background-color/i) !== -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = theme?.accentColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/color/i) !== -1 && eleClone2?.search(/background-color/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = theme?.secondaryFontColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    }
                    _eleClone[index] = eleClone2;
                  })
                  eleClone = _eleClone.join(';');
                } else if (eleClone?.search(/eb_prm_fnt_clr/i) !== -1 && eleClone?.search(/eb_acnt_clr/i) !== -1 && eleClone?.search(/eb_prm_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.primaryFontColor, 'i')) !== -1 && eleClone?.search(new RegExp(defaultTheme?.accentColor, 'i')) !== -1 && eleClone?.search(new RegExp(defaultTheme?.primaryColor, 'i')) !== -1 && theme?.primaryFontColor && theme?.accentColor && theme?.primaryColor) {
                  const _eleClone = eleClone?.split(";");
                  // eslint-disable-next-line no-unused-expressions
                  _eleClone?.map((elem, index) => {
                    let eleClone2 = elem;
                    if (eleClone2?.search(/background-color/i) !== -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = theme?.primaryColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/color/i) !== -1 && eleClone2?.search(/background-color/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      if (_eleClone2[1].length === 7) {
                        _eleClone2[1] = theme?.primaryFontColor?.toLowerCase();
                      }
                      else {
                        _eleClone2[1] = _eleClone2[1]?.replace(`${defaultTheme?.primaryFontColor}`, theme?.primaryFontColor?.toLowerCase());
                      }
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/border/i) !== -1 && eleClone2?.search(/border-radius/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = `1px solid ${theme?.accentColor?.toLowerCase()}`;
                      eleClone2 = _eleClone2.join(':');
                    }
                    _eleClone[index] = eleClone2;
                  })
                  eleClone = _eleClone.join(';');
                } else {
                  if (eleClone?.search(/eb_prm_fnt_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.primaryFontColor, 'i')) !== -1 && theme?.primaryFontColor) {
                    eleClone = eleClone?.replace(new RegExp(defaultTheme?.primaryFontColor, 'i'), theme?.primaryFontColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_sec_fnt_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.secondaryFontColor, 'i')) !== -1 && theme?.secondaryFontColor) {
                    eleClone = eleClone?.replace(new RegExp(defaultTheme?.secondaryFontColor, 'i'), theme?.secondaryFontColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_acnt_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.accentColor, 'i')) !== -1 && theme?.accentColor) {
                    eleClone = eleClone?.replace(new RegExp(defaultTheme?.accentColor, 'i'), theme?.accentColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_prm_clr/i) !== -1 && eleClone?.search(new RegExp(defaultTheme?.primaryColor, 'i')) !== -1 && theme?.primaryColor) {
                    eleClone = eleClone?.replace(new RegExp(defaultTheme?.primaryColor, 'i'), theme?.primaryColor?.toLowerCase());
                  }
                }
                htmlStrArr[i] = eleClone;
              })
              const _htmlStr = htmlStrArr.join('>');
              contents.values.html = _htmlStr;
              // isColorChanged validation added to check color of parent div is changed from them color to different one
              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && columns?.values?.backgroundColor?.toLowerCase() === defaultTheme?.primaryColor?.toLowerCase() && theme?.primaryColor && !columns?.values?.isColorChanged) {
                columns.values.backgroundColor = theme?.primaryColor?.toLowerCase();
              }

            } else {

              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_fnt_clr/i) !== -1 && contents?.values?.color?.toLowerCase() === defaultTheme?.primaryFontColor?.toLowerCase() && theme?.primaryFontColor) {
                contents.values.color = theme?.primaryFontColor?.toLowerCase();
                let htmlStr = contents?.values?.text;
                htmlStr = htmlStr?.replace(new RegExp(defaultTheme?.primaryFontColor, 'gi'), theme?.primaryFontColor?.toLowerCase());
                contents.values.text = htmlStr;
              } else if (contents?.values?._meta?.htmlClassNames?.search(/eb_sec_fnt_clr/i) !== -1 && contents?.values?.color?.toLowerCase() === defaultTheme?.secondaryFontColor?.toLowerCase() && theme?.secondaryFontColor) {
                contents.values.color = theme?.secondaryFontColor?.toLowerCase();
              } else if (contents?.values?._meta?.htmlClassNames?.search(/eb_acnt_clr/i) !== -1 && contents?.values?.color?.toLowerCase() === defaultTheme?.accentColor?.toLowerCase() && theme?.accentColor) {
                contents.values.color = theme?.accentColor?.toLowerCase();
                let htmlStr = contents?.values?.text;
                htmlStr = htmlStr?.replace(new RegExp(defaultTheme?.accentColor, 'gi'), theme?.accentColor?.toLowerCase());
                contents.values.text = htmlStr;
              }

              // isColorChanged validation added to check color of parent div is changed from them color to different one
              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && columns?.values?.backgroundColor?.toLowerCase() === defaultTheme?.primaryColor?.toLowerCase() && theme?.primaryColor && !columns?.values?.isColorChanged) {
                columns.values.backgroundColor = theme?.primaryColor?.toLowerCase();
              }
            }
          });
        });
      });
      return templateCopy;
    } else {
      return template; // no custom tool found
    }
  } else {
    return ''; // empty string will not break unlayer editor
  }
};

const RemoveTemplateBodyTheme = (template, theme, isCopyInitialization = false) => {
  if (template && template?.counters && template?.body?.rows) {
    if (theme?.primaryColor) {
      const templateCopy = JSON.parse(JSON.stringify(template));

      if (templateCopy?.body?.values?.backgroundColor && templateCopy?.body?.values?.backgroundColor.toLowerCase() === theme.mainBackgroundColor.toLowerCase()) {
        templateCopy.body.values.backgroundColor = defaultTheme?.mainBackgroundColor?.toLowerCase();
      }

      // eslint-disable-next-line no-unused-expressions
      templateCopy?.body?.rows?.map((row) => {
        // eslint-disable-next-line no-unused-expressions
        row?.columns?.map((columns, ind) => {
          let counter = 0; let isAppliedTheme = false;
          // eslint-disable-next-line no-unused-expressions
          columns?.contents?.map((contents) => {

            if (contents?.type === 'html') {
              const htmlStr = contents?.values?.html;
              const htmlStrArr = htmlStr?.split(">");
              // eslint-disable-next-line no-unused-expressions
              htmlStrArr?.map((ele, i) => {
                let eleClone = ele;
                if (eleClone?.search(/eb_sec_fnt_clr/i) !== -1 && eleClone?.search(/eb_acnt_clr/i) !== -1 && theme?.secondaryFontColor && theme?.accentColor && eleClone?.search(new RegExp(theme?.secondaryFontColor, 'i')) !== -1 && eleClone?.search(new RegExp(theme?.accentColor, 'i')) !== -1) {
                  const _eleClone = eleClone?.split(";");
                  // eslint-disable-next-line no-unused-expressions
                  _eleClone?.map((elem, index) => {
                    let eleClone2 = elem;
                    if (eleClone2?.search(/background-color/i) !== -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = defaultTheme?.accentColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/color/i) !== -1 && eleClone2?.search(/background-color/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = defaultTheme?.secondaryFontColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    }
                    _eleClone[index] = eleClone2;
                  })
                  eleClone = _eleClone.join(';');
                } else if (eleClone?.search(/eb_prm_fnt_clr/i) !== -1 && eleClone?.search(/eb_acnt_clr/i) !== -1 && eleClone?.search(/eb_prm_clr/i) !== -1 && theme?.primaryFontColor && theme?.accentColor && theme?.primaryColor && eleClone?.search(new RegExp(theme?.primaryFontColor, 'i')) !== -1 && eleClone?.search(new RegExp(theme?.accentColor, 'i')) !== -1 && eleClone?.search(new RegExp(theme?.primaryColor, 'i')) !== -1) {
                  const _eleClone = eleClone?.split(";");
                  // eslint-disable-next-line no-unused-expressions
                  _eleClone?.map((elem, index) => {
                    let eleClone2 = elem;
                    if (eleClone2?.search(/background-color/i) !== -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = defaultTheme?.primaryColor?.toLowerCase();
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/color/i) !== -1 && eleClone2?.search(/background-color/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      if (_eleClone2[1].length === 7) {
                        _eleClone2[1] = defaultTheme?.primaryFontColor?.toLowerCase();
                      }
                      else {
                        _eleClone2[1] = _eleClone2[1]?.replace(`${theme?.primaryFontColor}`, defaultTheme?.primaryFontColor?.toLowerCase());
                      }
                      eleClone2 = _eleClone2.join(':');
                    } else if (eleClone2?.search(/border/i) !== -1 && eleClone2?.search(/border-radius/i) === -1) {
                      const _eleClone2 = eleClone2?.split(":");
                      _eleClone2[1] = `1px solid ${defaultTheme?.accentColor?.toLowerCase()}`;
                      eleClone2 = _eleClone2.join(':');
                    }
                    _eleClone[index] = eleClone2;
                  })
                  eleClone = _eleClone.join(';');
                } else {
                  if (eleClone?.search(/eb_prm_fnt_clr/i) !== -1 && theme?.primaryFontColor && eleClone?.search(new RegExp(theme?.primaryFontColor, 'i')) !== -1) {
                    eleClone = eleClone?.replace(new RegExp(theme?.primaryFontColor, 'i'), defaultTheme?.primaryFontColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_sec_fnt_clr/i) !== -1 && theme?.secondaryFontColor && eleClone?.search(new RegExp(theme?.secondaryFontColor, 'i')) !== -1) {
                    eleClone = eleClone?.replace(new RegExp(theme?.secondaryFontColor, 'i'), defaultTheme?.secondaryFontColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_acnt_clr/i) !== -1 && theme?.accentColor && eleClone?.search(new RegExp(theme?.accentColor, 'i')) !== -1) {
                    eleClone = eleClone?.replace(new RegExp(theme?.accentColor, 'i'), defaultTheme?.accentColor?.toLowerCase());
                  }
                  if (eleClone?.search(/eb_prm_clr/i) !== -1 && theme?.primaryColor && eleClone?.search(new RegExp(theme?.primaryColor, 'i')) !== -1) {
                    eleClone = eleClone?.replace(new RegExp(theme?.primaryColor, 'i'), defaultTheme?.primaryColor?.toLowerCase());
                  }
                }
                htmlStrArr[i] = eleClone;
              })
              const _htmlStr = htmlStrArr.join('>');
              contents.values.html = _htmlStr;

              if (!isAppliedTheme && contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && columns?.values?.backgroundColor?.toLowerCase() === defaultTheme.primaryColor.toLowerCase() && !isCopyInitialization) {
                counter += 1;
              }

              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && theme?.primaryColor && columns?.values?.backgroundColor?.toLowerCase() === theme?.primaryColor?.toLowerCase()) {
                columns.values.backgroundColor = defaultTheme?.primaryColor?.toLowerCase();
                isAppliedTheme = true;
              }

            } else {

              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_fnt_clr/i) !== -1 && theme?.primaryFontColor && contents?.values?.color?.toLowerCase() === theme?.primaryFontColor?.toLowerCase()) {
                contents.values.color = defaultTheme?.primaryFontColor?.toLowerCase();
                let htmlStr = contents?.values?.text;
                htmlStr = htmlStr?.replace(new RegExp(theme?.primaryFontColor, 'gi'), defaultTheme?.primaryFontColor?.toLowerCase());
                contents.values.text = htmlStr;
              } else if (contents?.values?._meta?.htmlClassNames?.search(/eb_sec_fnt_clr/i) !== -1 && theme?.secondaryFontColor && contents?.values?.color?.toLowerCase() === theme?.secondaryFontColor?.toLowerCase()) {
                contents.values.color = defaultTheme?.secondaryFontColor?.toLowerCase();
              } else if (contents?.values?._meta?.htmlClassNames?.search(/eb_acnt_clr/i) !== -1 && theme?.accentColor && contents?.values?.color?.toLowerCase() === theme?.accentColor?.toLowerCase()) {
                contents.values.color = defaultTheme?.accentColor?.toLowerCase();
                let htmlStr = contents?.values?.text;
                htmlStr = htmlStr?.replace(new RegExp(theme?.accentColor, 'gi'), defaultTheme?.accentColor?.toLowerCase());
                contents.values.text = htmlStr;
              }

              if (!isAppliedTheme && contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && columns?.values?.backgroundColor?.toLowerCase() === defaultTheme.primaryColor.toLowerCase() && !isCopyInitialization) {
                counter += 1;
              }

              // added isColorChanged boolean to get parent div validated if its changed
              if (contents?.values?._meta?.htmlClassNames?.search(/eb_prm_clr/i) !== -1 && theme?.primaryColor && columns?.values?.backgroundColor?.toLowerCase() === theme?.primaryColor?.toLowerCase()) {
                columns.values.backgroundColor = defaultTheme?.primaryColor?.toLowerCase();
                isAppliedTheme = true
                // isColorChanged boolean remain false to make div in theme color
              }
            }
          });

          if (counter > 0) {
            columns.values.isColorChanged = true;
          }
        });
      });
      return templateCopy;
    } else {
      return template; // no custom tool found
    }
  } else {
    return ''; // empty string will not break unlayer editor
  }
};

// const templateStrBodyThemeRemove = (templateStr, themeData) => {
//   if (templateStr && themeData) {
//    Object.keys(themeData).forEach(key => {
//     switch (key) {
//       case 'primaryColor':{
//         if(themeData?.primaryColor)
//           templateStr = templateStr?.replace(new RegExp(themeData?.primaryColor,'gi') , defaultTheme?.primaryColor);
//         break;
//       }
//       case 'primaryFontColor':{
//         if(themeData?.primaryFontColor)
//           templateStr = templateStr?.replace(new RegExp(themeData?.primaryFontColor,'gi'), defaultTheme?.primaryFontColor);
//         break;
//       }
//       case 'secondaryFontColor':{
//         if(themeData?.secondaryFontColor)
//           templateStr = templateStr?.replace(new RegExp(themeData?.secondaryFontColor,'gi'), defaultTheme?.secondaryFontColor);
//         break;
//       }
//       case 'accentColor':{
//         if(themeData?.accentColor)
//             templateStr = templateStr?.replace(new RegExp(themeData?.accentColor,'gi'), defaultTheme?.accentColor);
//           break;
//       }
//       case 'mainBackgroundColor':{
//         if(themeData?.mainBackgroundColor)
//             templateStr = templateStr?.replace(new RegExp(themeData?.mainBackgroundColor,'gi'), defaultTheme?.mainBackgroundColor);
//           break;  
//       }
//     }
//    })
//    return templateStr;
//   } else {
//     return templateStr;
//   }
// };

export default function CreateEmail() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const source = useSource();

  // redux States
  const modalinfo = useSelector(templateModalDetailsSelector, shallowEqual);
  const reduxData = useSelector(commonSelector, shallowEqual);
  // Component State
  const [testMailToggle, setTestMailToggle] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [apiCallState, setApiCallState] = useState({
    sendTestmail: false,
    save: false,
    isApiCall: false,
  });
  const emailEditorRef = useRef(null);
  const eventCommunityVersion = useCommunityVersionNumber();
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const { EventData } = eventData;
  const [theme, setTheme] = useState({});
  // Template Actions
  const addMergeTags = (mergeTag) => {
    // eslint-disable-next-line no-unused-expressions
    emailEditorRef?.current?.editor?.setMergeTags(mergeTag);
  };
  // const onLoad = () => {alinfo.actionType !== 'CREATE') {
  //     // eslint-disable-next-line no-unused-expressions
  //     if (emailEditorRef?.current?.editor) {
  //       emailEditorRef.current.editor.loadDesign(modalinfo?.activeTemplate);
  //     }
  //   addMergeTags();
  //   }
  // };

  const themeObj = {
    primaryColor: theme?.primary_color?.color1,
    primaryFontColor: theme?.font_color?.color1,
    secondaryFontColor: theme?.secondary_font_color?.color1,
    accentColor: theme?.accent_color?.color1,
    mainBackgroundColor: theme?.main_background_color?.color1
  }

  const onReady = async () => {
    if (modalinfo.actionType !== 'CREATE') {
      // eslint-disable-next-line no-unused-expressions
      if (modalinfo?.isPrimary) {
        try {
          // const res = await fetch(
          //   `https://api.unlayer.com/v2/templates/${modalinfo?.activeTemplate}`,
          //   {
          //     method: 'GET',
          //     headers: {
          //       // eslint-disable-next-line camelcase
          //       Authorization: `Basic ${btoa(`${process.env.REACT_APP_UNLAYER_API_KEY}:`)}`,
          //     },
          //   }
          // ).then((response) => response.json());
          // if (res?.success) {
          //   if (emailEditorRef?.current?.editor)
          //     emailEditorRef.current.editor.loadDesign(res?.data?.design);
          //   if (modalinfo?.mergeTag) addMergeTags(modalinfo?.mergeTag);
          // } else {
          //   dispatch(genericAPIErrorHandler(false, 'Unable to load Template', dispatch));
          // }
          const [headers] = API.generateHeader(reduxData, null, null);
          const uri = GET_TEMPLATE_UNLAYER_OBJECT.replace('{ID}', modalinfo?.activeTemplate);

          GETAPIWITHCUSTOMDOMAIN(false, uri, source.AS, { ...headers }, null, CUSTOM_URL)
            .then((resp) => {
              if (resp.data.success) {
                if (resp?.data?.data?.design) {

                  if (emailEditorRef?.current?.editor)
                    emailEditorRef.current.editor.loadDesign(templateBodyThemeUpdate(resp?.data?.data?.design, themeObj));
                  if (modalinfo?.mergeTag) addMergeTags(modalinfo?.mergeTag);
                }
              } else {
                dispatch(genericAPIErrorHandler(false, 'Unable to load Template', dispatch));
              }
            })
            .catch(API.catchHandler);
        } catch (error) {
          dispatch(genericAPIErrorHandler(false, 'Unable to load Template', dispatch));
        }
      } else if (emailEditorRef?.current?.editor) {
        emailEditorRef.current.editor.loadDesign(modalinfo?.applyTheme ?
          templateCustomToolDataUpdate(
            modalinfo?.activeTemplate,
            // eslint-disable-next-line no-use-before-define
            state?.speakers,
            // eslint-disable-next-line no-use-before-define
            [...fetchBooths],
            // eslint-disable-next-line no-use-before-define
            state?.sessions,
            {
              primaryColor: theme?.primary_color?.color1 ? theme?.primary_color?.color1 : '#ffffff',
              primaryFontColor: theme?.font_color?.color1 ? theme?.font_color?.color1 : '#202020',
              secondaryFontColor: theme?.secondary_font_color?.color1
                ? theme?.secondary_font_color?.color1
                : '#808080',
              accentColor: theme?.accent_color?.color1 ? theme?.accent_color?.color1 : '#e67e23',
            }
          ) : templateBodyThemeUpdate(
            templateCustomToolDataUpdate(
              modalinfo?.activeTemplate,
              // eslint-disable-next-line no-use-before-define
              state?.speakers,
              // eslint-disable-next-line no-use-before-define
              [...fetchBooths],
              // eslint-disable-next-line no-use-before-define
              state?.sessions,
              {
                primaryColor: theme?.primary_color?.color1 ? theme?.primary_color?.color1 : '#ffffff',
                primaryFontColor: theme?.font_color?.color1 ? theme?.font_color?.color1 : '#202020',
                secondaryFontColor: theme?.secondary_font_color?.color1
                  ? theme?.secondary_font_color?.color1
                  : '#808080',
                accentColor: theme?.accent_color?.color1 ? theme?.accent_color?.color1 : '#e67e23',
              }
            ), themeObj)
        );
        if (modalinfo?.mergeTag) addMergeTags(modalinfo?.mergeTag);
      }
    } else {
      if (modalinfo?.mergeTag) addMergeTags(modalinfo?.mergeTag);
    }
    setIsEditorReady(true);
  };

  const sendTestEmail = useCallback((payload = {}, CB, _emailList, emailSubject) => {
    setApiCallState({ ...apiCallState, sendTestmail: true });
    const [header] = API.generateHeader(reduxData, null, null);
    // eslint-disable-next-line no-unused-expressions
    emailEditorRef?.current?.editor?.exportHtml((data) => {
      const { html: templateHtml } = data;
      const newPayload = {
        ...payload,
        templateHtml,
        emails: _emailList,
        subject: emailSubject,
        campaignId: modalinfo?.campaignId,
      };
      POSTAPIWITHCUSTOMDOMAIN(false, SEND_TEST_EMAIL, source.AS, header, newPayload, CUSTOM_URL)
        .then((resp) => {
          setApiCallState({ ...apiCallState, sendTestmail: false });
          if (resp.data.status === API.apiSuccessStatus) {
            if (resp.data.message)
              amplitude.Track.sendTestEmail(
                modalinfo.isCustomCampaign,
                modalinfo.campaignName,
                modalinfo.campaignId
              );
            dispatch(
              setNewMessage({
                message: 'Test email sent successfully.' || resp.data.message,
                type: 'success',
                show: true,
              })
            );
            if (CB) CB();
            setTestMailToggle(false);
            setEmailList([]);
          } else {
            dispatch(genericAPIErrorHandler(false, data.message, dispatch));
          }
        })
        .catch((err) => {
          dispatch(genericAPIErrorHandler(true, false, dispatch));
          API.catchHandler(err);
          setApiCallState({ ...apiCallState, sendTestmail: false });
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const creatTemplateDesign = () => {
  //   emailEditorRef.current.editor.saveDesign((design) => {
  //     // const res = await makeApiCall({
  //     //   url: 'email/save-design',
  //     //   headers: {},
  //     //   body: {
  //     //     template: design,
  //     //     campaignId: campaignId,
  //     //   },
  //     // });
  //   });
  // };

  // Component State
  const [state, setState] = useState({ ...initState });
  const updateState = (currState) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...currState,
      };
    });
  };
  const isEnableEdit = get(state, 'isEnableEdit', false);
  const templateLabel = getTemplateLabel(state);

  const creatTemplateDesign = useCallback((payload = {}, CB, themeObj) => {
    setApiCallState({ ...apiCallState, save: true });
    const [header] = API.generateHeader(reduxData, null, null);

    // eslint-disable-next-line no-unused-expressions
    emailEditorRef?.current?.editor?.exportHtml((data) => {
      const { html: _templateHtml, design: _templateJson } = data;
      const templateJson = RemoveTemplateBodyTheme(
        _templateJson,
        themeObj,
        modalinfo?.isCopyInitialization
      );
      // const templateHtml = templateStrBodyThemeRemove(_templateHtml, themeObj)
      const themeData = {
        primaryColor: { color1: themeObj?.primaryColor },
        fontColor: { color1: themeObj?.primaryFontColor },
        secondaryFontColor: { color1: themeObj?.secondaryFontColor },
        accentColor: { color1: themeObj?.accentColor },
        mainBackgroundColor: { color1: themeObj?.mainBackgroundColor },
      };

      const newPayload = { ...payload, templateHtml: _templateHtml, templateJson, themeData };

      POSTAPIWITHCUSTOMDOMAIN(false, CREATE_TEMPLATE, source.AS, header, newPayload, CUSTOM_URL)
        .then((resp) => {
          setApiCallState({ ...apiCallState, save: false });
          if (resp.data.status === API.apiSuccessStatus) {
            dispatch(
              setNewMessage({
                message: resp.data.message,
                type: 'success',
                show: true,
              })
            );

            const update = { templateObj: resp.data.data };
            if (modalinfo?.isActive) update.templateObj.isActive = true;

            dispatch(setTemplateObj(update));
            if (CB) CB();
          } else {
            dispatch(genericAPIErrorHandler(false, data.message, dispatch));
          }
        })
        .catch((err) => {
          const errMgs = err?.response?.data?.message || err?.message || '';
          if (errMgs) dispatch(genericAPIErrorHandler(false, errMgs, dispatch));
          else dispatch(genericAPIErrorHandler(true, false, dispatch));
          API.catchHandler(err);
          setApiCallState({ ...apiCallState, save: false });
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editTemplateDesign = useCallback((payload = {}, CB, themeObj) => {
    setApiCallState({ ...apiCallState, save: true });
    const [header] = API.generateHeader(reduxData, null, null);
    // eslint-disable-next-line no-unused-expressions
    emailEditorRef?.current?.editor?.exportHtml((data) => {
      const { html: _templateHtml, design: _templateJson } = data;
      const templateJson = RemoveTemplateBodyTheme(_templateJson, themeObj)
      // const templateHtml = templateStrBodyThemeRemove(_templateHtml, themeObj)
      const themeData = {
        primaryColor: { color1: themeObj?.primaryColor },
        fontColor: { color1: themeObj?.primaryFontColor },
        secondaryFontColor: { color1: themeObj?.secondaryFontColor },
        accentColor: { color1: themeObj?.accentColor },
        mainBackgroundColor: { color1: themeObj?.mainBackgroundColor },
      };

      const newPayload = { ...payload, templateHtml: _templateHtml, templateJson, themeData };

      const apiUri = UPDATE_TEMPLATE_DETAILS_BY_ID.replace('{ID}', modalinfo?.templateId);
      PATCH_API_WITH_CUSTOM_DOMAIN(false, apiUri, source.AS, header, CUSTOM_URL, newPayload)
        .then((resp) => {
          setApiCallState({ ...apiCallState, save: false });
          if (resp.data.status === API.apiSuccessStatus) {
            dispatch(
              setNewMessage({
                message: resp.data.message,
                type: 'success',
                show: true,
              })
            );
            dispatch(setTemplateObj({ templateObj: resp.data.data }));
            if (CB) CB();
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLogoOrBannerList = useCallback(() => {
    const [header] = API.generateHeader(
      reduxData,
      {
        community_version: eventCommunityVersion,
      },
      null
    );
    GETAPI(false, GET_BRAND_EVENT_SETTING, source.AS, header)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          // Theme assigning
          if (resp?.data?.data?.chosenTheme && modalinfo.applyEventTheme !== false) setTheme({ ...resp?.data?.data?.chosenTheme, main_background_color: resp?.data?.data?.chosenTheme?.main_background_color?.color1 ? resp?.data?.data?.chosenTheme?.main_background_color : { color1: "#EDEDED" } });

          let stateDetails = { logoOrBannerCall: true };
          const currLogos = resp?.data?.data?.logos || [];
          const hasLogo = isTrueValueArray(currLogos);
          if (hasLogo) {
            let items = [];
            currLogos.forEach((image) => {
              if (image.img_file_name) {
                items = [
                  ...items,
                  {
                    img: getEventLogo(EventData.organiser_id, image.img_file_name),
                    id: image.id,
                  },
                ];
              }
            });
            const hasItems = isTrueValueArray(items);
            if (hasItems) stateDetails = { ...stateDetails, logos: [...items] };
          }

          const currBanners = resp?.data?.data?.communityBanners || [];
          const hasBanner = isTrueValueArray(currBanners);
          if (hasBanner) {
            let items = [];
            currBanners.forEach((image) => {
              if (image.img_file_name) {
                items = [
                  ...items,
                  {
                    img: getEventImage(EventData.organiser_id, image.img_file_name),
                    id: image.id,
                  },
                ];
              }
            });
            const hasItems = isTrueValueArray(items);
            if (hasItems) stateDetails = { ...stateDetails, banners: [...items] };
          }

          const hasUpdate = isTrueObject(stateDetails);
          if (hasUpdate) updateState({ ...stateDetails });
          updateState({ logoOrBannerCall: false });
        } else {
          API.errStatusHandler(resp, history, dispatch);
          updateState({ logoOrBannerCall: false });
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpeakersList = useCallback(() => {
    const [header] = API.generateHeader(reduxData, null, null);
    POSTAPIWITHCUSTOMDOMAIN(false, GET_SPEAKER_LIST, source.AS, header, {}, CUSTOM_URL_API)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          const currSpeaker = resp?.data?.data || [];
          const hasSpeaker = isTrueValueArray(currSpeaker);
          if (hasSpeaker) {
            const getItems = currSpeaker.map((i) => {
              return {
                id: i?.id,
                name: i?.name,
                company: i?.peopleCompany || i?.company || '',
                designation: i?.peopleDesignation || i?.designation || '',
                profile_img: i?.profile_img
                  ? getPeopleImage('profile', reduxData.organiserId, i.profile_img)
                  : getProfilePicByFirstChar(i?.name?.charAt(0)),
              };
            });
            const hasItem = isTrueValueArray(getItems);
            if (hasItem) updateState({ speakers: [...getItems] });
          }
          updateState({ speakerCall: false });
        } else {
          API.errStatusHandler(resp, history, dispatch);
          updateState({ speakerCall: false });
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSessionList = useCallback(() => {
    const [header] = API.generateHeader(reduxData, null, null);
    POSTAPIWITHCUSTOMDOMAIN(false, GET_SESSION_LIST, source.AS, header, {}, CUSTOM_URL_API)
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          const currSession = resp?.data?.data || [];

          const hasSession = isTrueValueArray(currSession);
          if (hasSession) {
            const getItems = currSession.map((i) => {
              return {
                ...i,
                speakers: i.speakers.map((sp) => {
                  return {
                    ...sp,
                    img: sp?.img && checkImgURL(sp?.img) ? sp?.img : getOtherUserImage('speaker'),
                  };
                }),
                sponsors: i.sponsors.map((sp) => {
                  return {
                    ...sp,
                    img:
                      sp?.img && checkImgURL(sp?.img)
                        ? sp?.img
                        : getProfilePicByFirstChar(sp?.name?.charAt(0)),
                  };
                }),
              };
            });

            const hasItem = isTrueValueArray(getItems);
            if (hasItem) updateState({ sessions: [...getItems] });
          }
          updateState({ sessionCall: false });
        } else {
          API.errStatusHandler(resp, history, dispatch);
          updateState({ sessionCall: false });
        }
      })
      .catch(API.catchHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusTemplateName = () => {
    const ele = document.getElementById(templateNameElementId);
    if (ele) {
      ele.focus();
      updateState({ isEnableEdit: true });
    }
  };
  const resetTemplateLabel = () => {
    updateState({ templateLabel: '', isEnableEdit: true });
  };
  const handleTemplateChange = (e) => {
    const updateValue = e.target.innerText;
    const isLabel = updateValue === LABEL_TEMPLATE_NAME;
    if (isLabel) return;
    updateState({
      isEnableEdit: false,
      templateLabel: LABEL_TEMPLATE_NAME,
    });
    dispatch(setActiveTemplateName({ activeTemplateName: updateValue }));
    dispatch(templateNameEditToggle({ activeTemplateNameIsEdit: false }));
    // if (updateValue.length > 60) {
    //   dispatch(
    //     setNewMessage({
    //       message: `Template name exceeds 60 characters. Please provide a new template name.`,
    //       type: 'error',
    //       show: true,
    //     })
    //   );
    // }
  };

  useEffect(() => {
    getLogoOrBannerList();
    getSpeakersList();
    getSessionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [fetchBooths, getBooths] = useBooth();
  useEffect(() => {
    getBooths(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // const hasBooths = isTrueValueArray(fetchBooths);
    if (fetchBooths) updateState({ boothCall: false });
    if (typeof fetchBooths === 'boolean' && fetchBooths === false) {
      updateState({ boothCall: false });
    }
  }, [fetchBooths]);

  const getCustomTools = () => {
    let returnRoutes = [];

    const getLogos = get(state, 'logos', []);
    const hasLogo = isTrueValueArray(getLogos);
    if (hasLogo) returnRoutes = [...returnRoutes, CUSTOM_TOOL_LOGO];

    const getBanners = get(state, 'banners', []);
    const hasBanners = isTrueValueArray(getBanners);
    if (hasBanners) returnRoutes = [...returnRoutes, CUSTOM_TOOL_BANNER];

    const getSpeakers = get(state, 'speakers', []);
    const hasSpeakers = isTrueValueArray(getSpeakers);
    if (hasSpeakers) returnRoutes = [...returnRoutes, CUSTOM_TOOL_SPEAKER];

    const hasBooth = isTrueValueArray(fetchBooths);
    if (hasBooth) returnRoutes = [...returnRoutes, CUSTOM_TOOL_BOOTH];

    const getSessions = get(state, 'sessions', []);
    const hasSessions = isTrueValueArray(getSessions);
    if (hasSessions) returnRoutes = [...returnRoutes, CUSTOM_TOOL_SESSION];
    return [...returnRoutes];
  };

  const fetchCustomTools = getCustomTools();

  return (
    <>
      <CustomFullScreenModal
        isOpen={modalinfo.isModalOpen}
        onClose={() => {
          dispatch(templateModalToggle({ isModalOpen: false }));
          dispatch(setActiveTemplate({ activeTemplate: null, mergeTag: {} }));
          dispatch(setActiveTemplateName({ activeTemplateName: '' }));
          dispatch(templateNameEditToggle({ activeTemplateNameIsEdit: false }));
          dispatch(setTemplateActiontype({ actionType: '' }));
          dispatch(setCampaignId({ campaignId: '' }));
          dispatch(setTemplateId({ templateId: '' }));
          dispatch(setTemplateIsPrimary({ isPrimary: false }));
          dispatch(resetTemplate());
        }}
        headerActions={
          <>
            <BtnWithLoader
              onClick={() => {
                setTestMailToggle(true);
              }}
              disabled={!isEditorReady || apiCallState.sendTestmail}
              isLoading={apiCallState.sendTestmail}
              loadingLabel="Sending..."
              label="Send Test Email"
              variant="outlined"
              color="primary"
            />
            <BtnWithLoader
              onClick={() => {
                amplitude.Track.templateActions(
                  'save email template',
                  modalinfo.isCustomCampaign,
                  modalinfo.campaignName,
                  modalinfo.campaignId,
                  modalinfo.templateId,
                  modalinfo.activeTemplateName
                );
                if (modalinfo?.actionType === 'CREATE' || modalinfo?.actionType === 'DUPLICATE') {
                  if (modalinfo?.activeTemplateName.length > 60) {
                    dispatch(
                      setNewMessage({
                        message: `Template name exceeds 60 characters. Please provide a new template name.`,
                        type: 'error',
                        show: true,
                      })
                    );
                    return;
                  }
                  creatTemplateDesign(
                    {
                      defEdited: Boolean(modalinfo?.isDefaultAction),
                      templateName: modalinfo?.activeTemplateName,
                      campaignId: modalinfo?.campaignId,
                      applyTheme: modalinfo?.applyTheme ? true : false,
                      applyEventTheme: modalinfo?.applyEventTheme === undefined ? true : modalinfo?.applyEventTheme,
                    },
                    () => {
                      // window.location.reload(false);
                      dispatch(templateModalToggle({ isModalOpen: false, applyTheme: false }));
                      dispatch(setActiveTemplate({ activeTemplate: null, mergeTag: {} }));
                      dispatch(setActiveTemplateName({ activeTemplateName: '' }));
                      dispatch(templateNameEditToggle({ activeTemplateNameIsEdit: false }));
                      dispatch(setTemplateActiontype({ actionType: '' }));
                      dispatch(setCampaignId({ campaignId: '' }));
                      dispatch(setTemplateId({ templateId: '' }));
                      dispatch(setTemplateIsPrimary({ isPrimary: false, prevTemplateId: '', isEditPrimary: false }));
                      dispatch(resetTemplate());
                    },
                    themeObj
                  );
                } else {
                  if (modalinfo?.activeTemplateName.length > 60) {
                    dispatch(
                      setNewMessage({
                        message: `Template name exceeds 60 characters. Please provide a new template name.`,
                        type: 'error',
                        show: true,
                      })
                    );
                    return;
                  }
                  editTemplateDesign(
                    {
                      templateName: modalinfo?.activeTemplateName,
                      applyEventTheme:
                        modalinfo?.applyEventTheme === undefined
                          ? true
                          : modalinfo?.applyEventTheme,
                      // campaignId: modalinfo?.campaignId,
                      // templateId: modalinfo?.templateId,
                    },
                    () => {
                      // window.location.reload(false);
                      dispatch(templateModalToggle({ isModalOpen: false }));
                      dispatch(setActiveTemplate({ activeTemplate: null, mergeTag: {} }));
                      dispatch(setActiveTemplateName({ activeTemplateName: '' }));
                      dispatch(templateNameEditToggle({ activeTemplateNameIsEdit: false }));
                      dispatch(setTemplateActiontype({ actionType: '' }));
                      dispatch(setCampaignId({ campaignId: '' }));
                      dispatch(resetTemplate());
                    },
                    themeObj
                  );
                }
              }}
              disabled={!isEditorReady || apiCallState.save}
              isLoading={apiCallState.save}
              loadingLabel="Saving..."
              // label={
              //   modalinfo.actionType === 'CREATE' || modalinfo.actionType === 'EDIT' || (modalinfo?.actionType === 'DUPLICATE' && modalinfo?.isEnableCTA)
              //     ? 'Save'
              //     : 'Save as Copy'
              // }
              label="Save"
              variant="contained"
              color="primary"
            />
          </>
        }
        title={
          <>
            <Box display="flex" alignItems="center" className={classes.editableTitle}>
              <Box display="flex" mr={4}>
                {EventData.logo && EventData.logo.length > 0 ? (
                  <Avatar
                    src={getEventLogo(EventData.organiser_id, EventData.logo[0])}
                    alt="logo"
                    variant="square"
                    className="brandLogo"
                  />
                ) : (
                  <SvgHubiloIcon width="85px" />
                )}
              </Box>
              <Box
                id={templateNameElementId}
                color="textPrimary"
                className={`editableFieldModal ${modalinfo.activeTemplateName ? 'filled' : 'empty'
                  }`}
                component="div"
                variant="h5"
                contenteditable={isEditorReady ? 'true' : 'false'}
                onClick={resetTemplateLabel}
                onBlur={(e) => {
                  handleTemplateChange(e);
                }}>
                {modalinfo.activeTemplateName || templateLabel}
              </Box>
              {!isEnableEdit && isEditorReady && (
                <Box
                  disabled={!isEditorReady}
                  ml={2}
                  display="flex"
                  alignItems="center"
                  component="a"
                  className="editAction"
                  onClick={focusTemplateName}>
                  <SvgEdit3 height="20px" width="20px" />
                </Box>
              )}
            </Box>
          </>
        }>
        <>
          {state.logoOrBannerCall || state.speakerCall || state.boothCall || state.sessionCall ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: 'calc(100vh - 65px)',
              }}>
              <SvgSpinner />
            </Box>
          ) : (
            <Box className={classes.emailEditorBlock}>
              <EmailEditor
                ref={emailEditorRef}
                editorId="emailBuilder"
                projectId={71154}
                id="editor-container"
                // onLoad={onLoad}
                onReady={onReady}
                options={{
                  version: '1.5.75',
                  features: {
                    smartMergeTags: false,
                    textEditor: {
                      cleanPaste: true,
                    }
                  },
                  customCSS: ['https://cdn.hubilo.com/email-builder/tools/custom-tool-css.css'],
                  customJS: [
                    `const theme = { primaryColor : '${theme?.primary_color?.color1 ? theme?.primary_color?.color1 : '#ffffff'
                    }', primaryFontColor : '${theme?.font_color?.color1 ? theme?.font_color?.color1 : '#202020'
                    }', secondaryFontColor : '${theme?.secondary_font_color?.color1
                      ? theme?.secondary_font_color?.color1
                      : '#808080'
                    }',  accentColor : '${theme?.accent_color?.color1 ? theme?.accent_color?.color1 : '#e67e23'
                    }'}`,
                    ...fetchCustomTools,
                  ],
                  tabs: {
                    blocks: {
                      enabled: false,
                    },
                  },
                  tools: {
                    'custom#speaker_tool': {
                      data: {
                        speakers: state.speakers,
                      },
                      properties: {
                        speakerLibrary: {
                          editor: {
                            data: {
                              speakers: state.speakers,
                            },
                          },
                        },
                      },
                    },
                    'custom#community_banner_tool': {
                      data: {
                        banners: state.banners,
                      },
                      properties: {
                        bannerLibrary: {
                          editor: {
                            data: {
                              banners: state.banners,
                            },
                          },
                        },
                      },
                    },
                    'custom#booth_tool': {
                      data: {
                        booths: [...fetchBooths],
                      },
                      properties: {
                        boothLibrary: {
                          editor: {
                            data: {
                              booths: [...fetchBooths],
                            },
                          },
                        },
                      },
                    },
                    'custom#logo_tool': {
                      data: {
                        logos: state.logos,
                      },
                      properties: {
                        logoLibrary: {
                          editor: {
                            data: {
                              logos: state.logos,
                            },
                          },
                        },
                      },
                    },
                    'custom#session_tool': {
                      data: {
                        sessions: state.sessions,
                      },
                      properties: {
                        sessionLibrary: {
                          editor: {
                            data: {
                              sessions: state.sessions,
                            },
                          },
                        },
                      },
                    },
                    // predefined tool color initialization
                    button: {
                      properties: {
                        buttonColors: {
                          value: {
                            color: `${theme?.secondary_font_color?.color1
                              ? theme?.secondary_font_color?.color1
                              : '#808080'
                              }`,
                            backgroundColor: `${theme?.accent_color?.color1 ? theme?.accent_color?.color1 : '#e67e23'
                              }`,
                          },
                        },
                      },
                    },
                    divider: {
                      properties: {
                        border: {
                          value: {
                            borderTopColor: `${theme?.secondary_font_color?.color1
                              ? theme?.secondary_font_color?.color1
                              : '#808080'
                              }`,
                          },
                        },
                      },
                    },
                    heading: {
                      properties: {
                        color: {
                          value: `${theme?.font_color?.color1 ? theme?.font_color?.color1 : '#202020'
                            }`,
                        },
                      },
                    },
                    text: {
                      properties: {
                        color: {
                          value: `${theme?.font_color?.color1 ? theme?.font_color?.color1 : '#202020'
                            }`,
                        },
                      },
                    },
                    menu: {
                      properties: {
                        textColor: {
                          value: `${theme?.secondary_font_color?.color1 ? theme?.secondary_font_color?.color1 : '#808080'
                            }`,
                        },
                        linkColor: {
                          value: `${theme?.accent_color?.color1 ? theme?.accent_color?.color1 : '#e67e23'
                            }`,
                        },
                      },
                    },

                  },
                }}
              />
            </Box>
          )}
        </>
      </CustomFullScreenModal>
      <CustomModal
        isOpen={testMailToggle}
        onClose={() => {
          setTestMailToggle(false);
          setEmailList([]);
        }}
        paddingZero>
        <Box className={classes.sendTestEmailBox} width={560} minHeight={367}>
          <SendTestEmailIcon />
          <Box className="sendTestEmailHead">{LABEL_TEST_TITLE}</Box>
          <Typography
            variant="body2"
            component="span"
            color="textSecondary"
            className="sendTestEmailText">
            {LABEL_TEST_DESC}
          </Typography>
          <Box position="relative" textAlign="left">
            <EditableOption
              id="emails"
              name="email"
              descType="TOOLTIP"
              descText="Enter the Email ID's"
              helperText="Press enter after each ID to add more."
              value={emailList}
              onEnter={(val, name, clearCB) => {
                const hasEmail = emailList.find((e) => e === val);
                if (hasEmail) {
                  dispatch(
                    setNewMessage({
                      message: 'Email already exists.',
                      type: 'error',
                      show: true,
                    })
                  );
                  if (clearCB) clearCB();
                } else if (emailList.length > 1) {
                  dispatch(
                    setNewMessage({
                      message: 'Maximum 2 email addresses are allowed.',
                      type: 'error',
                      show: true,
                    })
                  );
                  if (clearCB) clearCB();
                } else if (!emailRegex.test(val)) {
                  dispatch(
                    setNewMessage({
                      message: 'Please enter a valid Email',
                      type: 'error',
                      show: true,
                    })
                  );
                } else {
                  setEmailList((prev) => [...prev, val]);
                  if (clearCB) clearCB();
                }
              }}
              onDeleteValue={(val) => {
                setEmailList((prev) => {
                  return prev.filter((x) => x !== val);
                });
              }}
              placeholder="e.g. xyz@zyz.com, abc@abc.com"
            />
            <Box position="absolute" right="8px" bottom="36px">
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => sendTestEmail({}, null, emailList, modalinfo?.emailSubject)}
                disabled={emailList.length === 0 || apiCallState.sendTestmail}>
                {apiCallState.sendTestmail ? 'Sending...' : 'Send'}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}