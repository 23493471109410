import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import SvgPieChartIcon from 'icons/svgPieChartIcon';

export const DefaultEmptyStateForChart = ({ title = '', className = '', svgClassName = '' }) => {
  return (
    <Box my={6} className={className}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={1}
        mb={2}
        className={svgClassName}>
        <SvgPieChartIcon />
      </Box>
      {title && (
        <Box mb={4} display="flex" alignItems="center" justifyContent="center">
          <Typography color="textPrimary" variant="p" component="p">
            {title}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export const PieChartLoader = () => {
  return (
    <Card>
      <CardContent p={5}>
        <SkeletonLoaders height="16px" variant="text" width="88px" />
        <Box pt={1.6} pb={1.6}>
          <Box height="257px" display="flex" alignItems="center" justifyContent="center">
            <SkeletonLoaders
              height="160px"
              variant="round"
              width="160px"
              skeletonProps={{
                border: '17px solid rgba(0, 0, 0, 0.11)',
                backgroundColor: 'white !important',
              }}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr={0.5}>
              <SkeletonLoaders height="10px" variant="round" width="10px" />
            </Box>
            <SkeletonLoaders height="16px" variant="text" width="50px" />
            <Box mr={0.5} ml={1.2}>
              <SkeletonLoaders height="10px" variant="round" width="10px" />
            </Box>

            <SkeletonLoaders height="16px" variant="text" width="50px" />
            <Box mr={0.5} ml={1.2}>
              <SkeletonLoaders height="10px" variant="round" width="10px" />
            </Box>

            <SkeletonLoaders height="16px" variant="text" width="50px" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
