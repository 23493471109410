import React from 'react';

export default function SvgAdvancePlanIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9175 18.9214C14.7402 18.5902 14.6289 18.2288 14.5885 17.8572H9.69718C9.65682 18.2288 9.54548 18.5902 9.3682 18.9214C9.14099 19.346 8.81249 19.7078 8.41184 19.9749C8.28516 20.0594 8.18128 20.1738 8.10944 20.3081C8.03759 20.4423 8 20.5922 8 20.7445V21H16.2857L16.2857 20.7445C16.2857 20.5922 16.2481 20.4423 16.1763 20.3081C16.1044 20.1738 16.0006 20.0594 15.8739 19.9749C15.4732 19.7078 15.1447 19.346 14.9175 18.9214ZM8.71429 17.5413C8.71429 17.8582 8.63605 18.1702 8.48652 18.4496C8.337 18.729 8.12081 18.9671 7.85714 19.1429C7.59348 19.3187 7.37729 19.5568 7.22776 19.8362C7.07824 20.1156 7 20.4276 7 20.7445V22H17.2857V20.7445C17.2857 20.4276 17.2075 20.1156 17.058 19.8362C16.9084 19.5568 16.6922 19.3187 16.4286 19.1429C16.1649 18.9671 15.9487 18.729 15.7992 18.4496C15.6497 18.1702 15.5714 17.8582 15.5714 17.5413V16.8572H8.71429V17.5413Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8125 4.46154C10.0666 4.46154 9.35121 4.78571 8.82376 5.36275C8.29632 5.93978 8 6.72241 8 7.53846V14.9231L9.6875 18H15.3125V13.0769L11.9375 8.76923V8.15385L15.3125 9.38462H17V7.53846L13.625 4.46154V2L11.375 2.61538L10.8125 4.46154ZM12.1708 3.43445L11.5532 5.46154H10.8125C10.3623 5.46154 9.91016 5.6564 9.56187 6.03743C9.21085 6.42146 9 6.96008 9 7.53846V14.6669L10.2796 17H14.3125V13.422L10.9375 9.11432V6.72476L15.4891 8.38462H16V7.97999L12.625 4.90306V3.31023L12.1708 3.43445Z"
        fill="#F59C86"
      />
    </svg>
  );
}
