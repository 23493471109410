import React from 'react';

const styles = {
  a: { fill: 'none' },
  b: { fill: '#fff' },
};
export default function SvgSmallNewHubiloIcon({ bgColor, fillColor, height, width }) {
  return (
    <svg
      id="Layer_1"
      height={height}
      width={width}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 400 400">
      <defs>
        <clipPath id="clip-path">
          <rect style={styles.a} x="116.79" y="71.75" width="166.42" height="256.5" />
        </clipPath>
      </defs>
      <rect fill={bgColor} width="400" height="400" />
      <g id="Logo_Dark">
        <g>
          <polygon
            fill={fillColor}
            points="255.44 71.75 255.44 141.08 144.51 141.08 144.51 71.75 116.79 71.75 116.79 168.82 283.17 168.82 283.17 71.75 255.44 71.75"
          />
          <polygon
            fill={fillColor}
            points="255.44 231.18 116.83 231.18 116.83 328.23 144.56 328.23 144.56 258.94 255.49 258.94 255.49 328.25 283.21 328.25 283.21 231.18 255.44 231.18"
          />
        </g>
      </g>
    </svg>
  );
}
