/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgBulbIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="19"
            viewBox="0 0 14 19"
            fill="none"
            {...props}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M11.2418 1.85002C10.4754 1.21967 9.57788 0.768374 8.61483 0.529053C7.65178 0.289732 6.64745 0.268424 5.67511 0.466683C4.38043 0.728201 3.1926 1.36841 2.26235 2.30608C1.3321 3.24376 0.701341 4.43663 0.450114 5.73335C0.266653 6.70562 0.299991 7.70639 0.547749 8.66429C0.795508 9.6222 1.25158 10.5136 1.88345 11.275C2.46985 11.9367 2.80598 12.7829 2.83345 13.6667V16.1667C2.83345 16.8297 3.09684 17.4656 3.56568 17.9345C4.03452 18.4033 4.67041 18.6667 5.33345 18.6667H8.66678C9.32982 18.6667 9.96571 18.4033 10.4345 17.9345C10.9034 17.4656 11.1668 16.8297 11.1668 16.1667V13.825C11.1947 12.8493 11.5532 11.912 12.1834 11.1667C13.2877 9.80067 13.8083 8.05403 13.6319 6.30637C13.4556 4.55871 12.5967 2.95126 11.2418 1.83335V1.85002ZM9.50011 16.1667C9.50011 16.3877 9.41232 16.5997 9.25604 16.7559C9.09976 16.9122 8.8878 17 8.66678 17H5.33345C5.11243 17 4.90047 16.9122 4.74419 16.7559C4.58791 16.5997 4.50011 16.3877 4.50011 16.1667V15.3333H9.50011V16.1667ZM10.8918 10.1333C10.0539 11.1272 9.565 12.3684 9.50011 13.6667H7.83345V11.1667C7.83345 10.9457 7.74565 10.7337 7.58937 10.5774C7.43309 10.4211 7.22113 10.3333 7.00011 10.3333C6.7791 10.3333 6.56714 10.4211 6.41086 10.5774C6.25458 10.7337 6.16678 10.9457 6.16678 11.1667V13.6667H4.50011C4.47813 12.3901 4.0059 11.1623 3.16678 10.2C2.61315 9.53668 2.24086 8.7412 2.08621 7.89114C1.93156 7.04108 1.99982 6.16545 2.28435 5.34962C2.56888 4.5338 3.05997 3.80564 3.70972 3.23612C4.35947 2.66661 5.14571 2.27518 5.99178 2.10002C6.71891 1.9503 7.47025 1.96439 8.19126 2.14124C8.91226 2.3181 9.58484 2.65329 10.1602 3.12248C10.7355 3.59167 11.1991 4.18308 11.5173 4.85378C11.8356 5.52448 12.0005 6.25764 12.0001 7.00002C12.0063 8.14157 11.6144 9.24957 10.8918 10.1333Z"
                fill="#444444"
            />
        </svg>
    );
}
