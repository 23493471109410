import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';
import { extractChangeValuesFromLeads, extractLabelsFromLeads } from '../lead-level/handler';

const LEAD_SCORE_AMP_EVENT = {
  ActivityScoreTabViewed: 'ActivityScoreTabViewed',
  ResetActivityScoreClicked: 'ResetActivityScoreClicked',
  ConfirmResetActivityScore: 'ConfirmResetActivityScore',
  CancelResetActivityScore: 'CancelResetActivityScore',
  SaveActivityScoreClicked: 'SaveActivityScoreClicked',
  CancelActivityScoreClicked: 'CancelActivityScoreClicked',
  ResetLeadLevelClicked: 'ResetLeadLevelClicked',
  ConfirmResetLeadLevel: 'ConfirmResetLeadLevel',
  CancelResetLeadLevel: 'CancelResetLeadLevel',
  FilterIconClicked: 'FilterIconClicked',
  SaveFilterClicked: 'SaveFilterClicked',
  CancelFilterClicked: 'CancelFilterClicked',
  DownloadLeadScoreReport: 'DownloadLeadScoreReport',
  LeadLevelTabViewed: 'LeadLevelTabViewed',
  SaveLeadLevelName: 'SaveLeadLevelName',
  CancelLeadLevelName: 'CancelLeadLevelName',
  SaveLeadLevelScore: 'SaveLeadLevelScore',
  CancelLeadLevelScore: 'CancelLeadLevelScore',
  FinalConfirmLeadLevelClicked: 'FinalConfirmLeadLevelClicked',
  FinalCancelLeadLevelClicked: 'FinalCancelLeadLevelClicked',
};

const activityScoreTabViewed = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.ActivityScoreTabViewed, { ...data });
};

const resetActivityScoreClick = ({ ...rest }) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.ResetActivityScoreClicked, {
    ...rest,
  });
};
const confirmActivityScoreClick = ({ isDiff = false, ...rest }) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.ConfirmResetActivityScore, {
    score_edited: isDiff ? 'Y' : 'N',
    ...rest,
  });
};

const cancelResetActivityScoreClick = ({ ...rest }) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelResetActivityScore, {
    ...rest,
  });
};

const saveActivityScoreClick = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.SaveActivityScoreClicked, { ...data });
};

const cancelActivityScoreClick = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelActivityScoreClicked, {
    ...data,
  });
};

const filterIconClicked = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.FilterIconClicked, { ...data });
};

const saveFilterClicked = (data = {}, eventInfo = {}) => {
  const values = {
    live_lead_level: extractLabelsFromLeads(data.liveLevelIds).toString() || 'NA',
    ondemand_lead_level: extractLabelsFromLeads(data.onDemandLevelIds).toString() || 'NA',
  };
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.SaveFilterClicked, {
    ...values,
    ...eventInfo,
  });
};

const cancelFilterClicked = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelFilterClicked, {
    ...data,
  });
};

const downloadLeadScoreReportClicked = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.DownloadLeadScoreReport, { ...data });
};

const leadLevelTabViewed = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.LeadLevelTabViewed, { ...data });
};

const saveLeadLevelName = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.SaveLeadLevelName, { ...data });
};

const cancelLeadLevelName = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelLeadLevelName, { ...data });
};

const saveLeadLevelScore = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.SaveLeadLevelScore, { ...data });
};

const cancelLeadLevelScore = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelLeadLevelScore, { ...data });
};

const finalConfirmLeadLevelClicked = (data = {}, eventInfo) => {
  const comparedData = extractChangeValuesFromLeads({
    oldData: data.oldData || [],
    newData: data.newData || [],
  });
  const levelUpdated = extractLabelsFromLeads(comparedData);
  const values = {
    edit_source: 'slider',
    lead_score_edited: levelUpdated.length ? 'Y' : 'N',
  };
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.FinalConfirmLeadLevelClicked, { ...values, ...eventInfo });
};

const finalCancelLeadLevelClicked = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.FinalCancelLeadLevelClicked, { ...data });
};

const resetLeadLevelClick = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.ResetLeadLevelClicked, { ...data });
};

const confirmResetLeadLevel = (eventInfo) => {
  const values = {
    lead_score_edited: 'Y',
  };
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.ConfirmResetLeadLevel, { ...values, ...eventInfo });
};

const cancelResetLeadLevel = (data = {}) => {
  commonTrackEvent(LEAD_SCORE_AMP_EVENT.CancelResetLeadLevel, { ...data });
};
export {
  activityScoreTabViewed,
  resetActivityScoreClick,
  confirmActivityScoreClick,
  cancelResetActivityScoreClick,
  cancelActivityScoreClick,
  saveActivityScoreClick,
  filterIconClicked,
  downloadLeadScoreReportClicked,
  cancelFilterClicked,
  saveFilterClicked,
  leadLevelTabViewed,
  saveLeadLevelName,
  cancelLeadLevelName,
  saveLeadLevelScore,
  cancelLeadLevelScore,
  finalConfirmLeadLevelClicked,
  finalCancelLeadLevelClicked,
  resetLeadLevelClick,
  confirmResetLeadLevel,
  cancelResetLeadLevel,
};
