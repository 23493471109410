export const SET_SURVEY_DATA = "SET_SURVEY_DATA";
export const UNSET_SURVEY = "UNSET_SURVEY";

export const setSurvey = (payload) => {
    return {
        type: SET_SURVEY_DATA,
        data: payload
    }
};

export const unsetSurvey = () => {
    return {
        type: UNSET_SURVEY
    }
}

export const GET_SURVEY_SAMPLE = {
    canFillSurvey: true,
    canShowFeedBackBanner: true,
    isCompleted: false,
    isExpired: false,
    meta: {
        title: 'Help us improve',
        ratingQuestion: 'On a scale of 0 to 10, how likely are you to recommend our product to others?',
        descriptionQuestion: 'Can you share any specific feedback about the score? (Optional)',
        descriptionPlaceholder: 'Can you share any specific feedback about the score? (Optional)',
        descriptionLimit: 200,
        maxScore: 10,
    },
    rating: 0,
    feedBack: '',
};

export const GET_CSAT_SURVEY_SAMPLE = {
    canFillSurvey: true,
    canShowFeedBackBanner: true,
    isCompleted: false,
    isExpired: false,
    meta: {
        title: 'Help us improve',
        ratingQuestion: 'On a scale of 1 to 5, how likely are you to recommend our product to others?',
        descriptionQuestion: 'Can you share any specific feedback about the score? (Optional)',
        descriptionPlaceholder: 'Can you share any specific feedback about the score? (Optional)',
        descriptionLimit: 200,
        maxScore: 5,
    },
    rating: 0,
    feedBack: '',
};