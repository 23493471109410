/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import Recaptcha from 'react-invisible-recaptcha';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InputField from '../../custom-components/form-fields/inputField';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import { resendEmailApiCall } from 'lib/common-api-functions';
import Validation from '../../models/validation';
import { SignUpForOrgSchema } from '../../models/schema';
import ProjectSettings from '../../settings';
import CheckBox from '../../custom-components/form-fields/checkbox2';
import { API, POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import { SIGNUP_NEW } from '../../api-setup/api-endpoints';
import { useDispatch } from 'react-redux';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { Mail, User, Briefcase, Lock } from 'react-feather';
import ProjectSetting from 'settings';
import amplitude from 'lib/amplitude';
import { setNewMessage } from 'redux/actions/setting-actions';


const useStyles = makeStyles((theme) => ({
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  socialLoginBtn: {
    background: 'white',
    boxShadow: '0px 3px 6px #0000001A',
    minHeight: '34px',
    flexGrow: 1,
    borderRadius: 4,
    '&:nth-child(n+2)': {
      marginLeft: 10,
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  subTitle: {
    color: `${theme.palette.text.grayColor}`,
    display: `inline-block`,
  },
  Title: {
    color: `${theme.palette.text.primary}`,
  },
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

const registerApiCall = (
  captchaToken,
  recaptchaRef,
  setIsEmailSentForVerification,
  formFields,
  history,
  dispatch,
  setIsLoading,
  // props
) => {
  const { firstName, lastName, email, password, orgName } = formFields;
  const body = {
    payload : {
      data: {
      email,
      first_name : firstName,
      last_name: lastName,
      password,
      confirm_password:password,
      organisation_name: orgName,
      captchaToken,
      // lastUrl: props.location.search === '' ? '' : props.location.search.split('?').pop(),
    },
    }
    
  };
  setIsLoading(true);
  POSTAPIWITHCUSTOMDOMAIN(false, SIGNUP_NEW, null, null, body, ProjectSetting.customBaseURL2)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        setIsEmailSentForVerification(res.data.data.token);
        if (res.data.message)
            dispatch(
              setNewMessage({
                message: res.data.message,
                type: 'success',
                show: true,
              })
            );
        history.push({
          pathname: `/login`,
        });
      } else {
        API.errStatusHandler(res, history, dispatch, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            if (recaptchaRef && recaptchaRef.current) {
              recaptchaRef.current.reset();
            }
          }
        });
      }
      setIsLoading(false);
    })
    .catch((err) => {
      API.catchHandler(err, () => {
        if ( process.env.REACT_APP_ENV_TYPE === 'prod') {
          if (recaptchaRef && recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
        setIsLoading(false);
      });
    });
};

// handles validations for form fields
const handleValidations = (formFields) => {
  let newErrObj = '';
  newErrObj = Validation.validate(SignUpForOrgSchema, formFields);
  if(!newErrObj.confirmPassword && formFields.password !== formFields.confirmPassword){
    if (!newErrObj) newErrObj = {}
    newErrObj.confirmPassword = 'Your password and confirmation password do not match.';
  }
  if (newErrObj) return newErrObj;
  return false;
};

export default function SignUp(props) {
  const { Title, iconColor } = useStyles();
  const {location, history} = props;
  const [isEmailSentForVerification, setIsEmailSentForVerification] = useState('');
  const [formFields, setFormFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    termsConditions: false,
    confirmPassword:'',
  });
  const [captchaToken, setCaptchaToken] = useState('');
  const recaptchaRef = useRef(null);

  const [errObj, setErrObj] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [readOnlyEmail, setReadOnlyEmail] = useState(false);

  // api call for registering email or resending email
  const makeApiCall = (propsCaptchaToken = '') => {
    amplitude.Track.clickedSignup(); // TODO : formFields.email  @ramesh removed this
    if (isEmailSentForVerification)
      resendEmailApiCall(isEmailSentForVerification, history, setIsLoading, dispatch);
    else
      registerApiCall(
        propsCaptchaToken,
        recaptchaRef,
        setIsEmailSentForVerification,
        formFields,
        history,
        dispatch,
        setIsLoading,
        props
      );
  };

  useEffect(() => {
    if (  process.env.REACT_APP_ENV_TYPE === 'prod') {
      recaptchaRef.current.reset();
    }
    if (location && location.state) {
      const obj = {
        email: '',
        firstName: '',
        lastName: '',
        termsConditions: false,
      };
      if (location.state.email) {
        obj.email = location.state.email;
        setReadOnlyEmail(true);
      }
      if (location.state.first_name) {
        obj.firstName = location.state.first_name;
      }
      if (location.state.last_name) {
        obj.lastName = location.state.last_name;
      }
      setFormFields(obj);
    } else {
      setReadOnlyEmail(false);
    }
  }, []);

  // api gets called when captchatoken is set asynchronously
  useEffect(() => {
    if (captchaToken) {
      makeApiCall(captchaToken);
    }
  }, [captchaToken]);

  useEffect(() => {
    amplitude.Track.onPage('signup');
  }, []);

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'termsConditions') value = !formFields.termsConditions;
    if (errObj[name]) setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };

  // form submit handler
  const formSubmit = (event) => {
    // reset userid in amplitude
    amplitude.Track.resetUserID();
    event.preventDefault();
    const err = handleValidations(formFields);
    if (!err) {
      setErrObj({});
      if ( process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (recaptchaRef && recaptchaRef.current) {
          recaptchaRef.current.execute();
        }
      } else {
        makeApiCall('');
      }
    } else {
      setErrObj(err);
      if (process.env.REACT_APP_ENV_TYPE === 'prod') {
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <>
      {
        <>
          <Box mb={5} display="flex" justifyContent="center">
            <HubiloLogo height="70px" width="140px" />
          </Box>
          <Box className="text-center">
            <Box mb={1}>
              <Typography variant="h6" className={Title}>
                Sign-up to start free trial
              </Typography>
            </Box>
          </Box>
          <form onSubmit={(event) => formSubmit(event)}>
            <Box mt={3.2}>
              <Grid container spacing={2}>
                <>
                  <Grid item xs={12}>
                    <InputField
                      disabled={isLoading}
                      readOnly={readOnlyEmail}
                      error={errObj.email}
                      name="email"
                      id="email"
                      onChange={handleChange}
                      required
                      placeholder="username@mail.com"
                      type="email"
                      value={formFields.email}
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<Mail className={iconColor} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      disabled={isLoading}
                      error={errObj.firstName}
                      name="firstName"
                      id="firstName"
                      onChange={handleChange}
                      required
                      placeholder="First Name"
                      type="text"
                      value={formFields.firstName}
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<User className={iconColor} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      disabled={isLoading}
                      error={errObj.lastName}
                      name="lastName"
                      id="lastName"
                      onChange={handleChange}
                      required
                      placeholder="Last Name"
                      type="text"
                      value={formFields.lastName}
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<User className={iconColor} />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      disabled={isLoading}
                      error={errObj.password}
                      required
                      placeholder="Create your password"
                      type="password"
                      id="password"
                      name="password"
                      startAdornment
                      startIconAdornment
                      value={formFields.password}
                      startAdornmentIcon={<Lock size={18} className={iconColor} />}
                      showFieldVisibilityBtn
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      disabled={isLoading}
                      error={errObj.confirmPassword}
                      required
                      placeholder="Confirm your password"
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      startAdornment
                      startIconAdornment
                      value={formFields.confirmPassword}
                      startAdornmentIcon={<Lock size={18} className={iconColor} />}
                      showFieldVisibilityBtn
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      disabled={isLoading}
                      error={errObj.orgName}
                      name="orgName"
                      id="orgName"
                      onChange={handleChange}
                      required
                      placeholder="Organization/ Business Name"
                      type="text"
                      value={formFields.orgName}
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<Briefcase className={iconColor} />}
                    />
                  </Grid>
                </>
              </Grid>
              <Box mt={1.25}>
                <Box display="flex" alignItems="start" color="text.grayColor">
                  <CheckBox
                    name="termsConditions"
                    color="primary"
                    disabled={isLoading}
                    value={formFields.termConditions}
                    onChange={handleChange}
                    checked={formFields.termConditions}
                    error={errObj.termsConditions}
                    label={
                      <Typography variant="caption" component="span">
                        By creating your account, you agree to our{' '}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://hubilo.com/terms-service/">
                          <Box color="text.primary" component="span">
                            Terms and Conditions
                          </Box>
                        </a>{' '}
                        &amp;{' '}
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://hubilo.com/privacy-policy/">
                          <Box color="text.primary" component="span">
                            Privacy Policy
                          </Box>
                        </a>{' '}
                        of Hubilo.
                      </Typography>
                    }
                  />
                </Box>
              </Box>
              <Box mt={5}>
                <BtnWithLoader
                  className="uppercase"
                  onClick={formSubmit}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                  isLoading={isLoading}
                  loadingLabel="Creating"
                  label="Create Account"
                  fullWidth
                />
              </Box>
              <Box mt={5} textAlign="center" color="text.grayColor">
                <Typography variant="caption" component="p">
                  Do you have an existing account ?{' '}
                </Typography>
                <Link to="/" className="text-uppercase">
                  <Box component="span" color="primary.main" fontWeight={600}>
                    SIGN IN HERE
                  </Box>
                </Link>
              </Box>
            </Box>
          </form>
        </>
      }
      { process.env.REACT_APP_ENV_TYPE === 'prod' ? (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={ProjectSettings.recaptchaSiteKey}
          onResolved={(token) => setCaptchaToken(token)}
        />
      ) : (
        ''
      )}
    </>
  );
}
