import React from 'react';

export default function SvgTicketSmallIcon() {
  return (
    <svg width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 10C13.4696 10 12.9609 10.2107 12.5858 10.5858C12.2107 10.9609 12 11.4696 12 12V16C12 16.5304 12.2107 17.0391 12.5858 17.4142C12.9609 17.7893 13.4696 18 14 18C14.5304 18 15.0391 17.7893 15.4142 17.4142C15.7893 17.0391 16 16.5304 16 16V12C16 11.4696 15.7893 10.9609 15.4142 10.5858C15.0391 10.2107 14.5304 10 14 10ZM38 12C38.5304 12 39.0391 11.7893 39.4142 11.4142C39.7893 11.0391 40 10.5304 40 10V2C40 1.46957 39.7893 0.96086 39.4142 0.585787C39.0391 0.210714 38.5304 0 38 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585787C0.210714 0.96086 0 1.46957 0 2V10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.960859 16.2107 0.585786 16.5858C0.210714 16.9609 0 17.4696 0 18V26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28H38C38.5304 28 39.0391 27.7893 39.4142 27.4142C39.7893 27.0391 40 26.5304 40 26V18C40 17.4696 39.7893 16.9609 39.4142 16.5858C39.0391 16.2107 38.5304 16 38 16C37.4696 16 36.9609 15.7893 36.5858 15.4142C36.2107 15.0391 36 14.5304 36 14C36 13.4696 36.2107 12.9609 36.5858 12.5858C36.9609 12.2107 37.4696 12 38 12ZM36 8.36C34.8417 8.78041 33.8409 9.54726 33.1337 10.5563C32.4264 11.5654 32.047 12.7678 32.047 14C32.047 15.2322 32.4264 16.4346 33.1337 17.4437C33.8409 18.4527 34.8417 19.2196 36 19.64V24H16C16 23.4696 15.7893 22.9609 15.4142 22.5858C15.0391 22.2107 14.5304 22 14 22C13.4696 22 12.9609 22.2107 12.5858 22.5858C12.2107 22.9609 12 23.4696 12 24H4V19.64C5.1583 19.2196 6.15908 18.4527 6.86632 17.4437C7.57355 16.4346 7.95295 15.2322 7.95295 14C7.95295 12.7678 7.57355 11.5654 6.86632 10.5563C6.15908 9.54726 5.1583 8.78041 4 8.36V4H12C12 4.53043 12.2107 5.03914 12.5858 5.41421C12.9609 5.78929 13.4696 6 14 6C14.5304 6 15.0391 5.78929 15.4142 5.41421C15.7893 5.03914 16 4.53043 16 4H36V8.36Z"
        fill="#ED7767"
      />
    </svg>
  );
}
