import { actionTypes } from 'redux/actions/types';

const { SET_TICKET_SETTINGS_SIDE_BAR_OPEN } = actionTypes;

const initState = {
  isTSSidebarOpen: false,
};

const ticketReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TICKET_SETTINGS_SIDE_BAR_OPEN:
      return { ...state, isTSSidebarOpen: action.payload };
    default:
      return state;
  }
};

export default ticketReducer;
