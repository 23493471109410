/* eslint-disable  */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { makeStyles, TextareaAutosize } from '@material-ui/core';
import FieldWrapper from './fieldWrapper';

const useStyles = makeStyles((theme) => ({
  // borderDanger: {
  // 	"& input": {
  // 		borderColor: theme.palette.error.main + "!important"
  // 	}
  // },
  borderDanger: {
    border: `1px solid ${theme.palette.text.danger1} !important`,
  },
  textAdornment: {
    '&>.MuiTypography-root': {
      color: '#686868',
      fontSize: '0.75rem',
    },
  },
  textArea: {
    fontSize: `${theme.fontSize.size8}`,
    borderRadius: theme.shape.borderRadius,
    border: 'none',
    resize: 'none',
    // maxHeight: 140,
    padding: `${theme.spacing(2.1)}px ${theme.spacing(2)}px`,
    width: '100%',
    color: theme.palette.text.primary,
    fontFamily: 'Helvetica Neue New',
    backgroundColor: `${theme.palette.bg.white}`,
    border: `1px solid ${theme.palette.border.dark}`,

    transition: theme.transitions.create(['box-shadow', 'color'], {
      easing: theme.transitions.easing.easeInOut,
    }),
    '&.cursor-not-allowed': {
      color: theme.palette.text.grayColor,
    },
    '&.Mui-focused': {
      borderColor: 'transparent',
      boxShadow: theme.inputBoxShadow,
    },
    '&.cursor-not-allowed': {
      color: theme.palette.text.grayColor,
    },
    // boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-input-placeholder': {
      color: theme.palette.text.grayColor,
      fontSize: `${theme.fontSize.size8}`,
    },
    '&::-moz-input-placeholder': {
      color: theme.palette.text.grayColor,
      fontSize: `${theme.fontSize.size8}`,
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.grayColor,
      fontSize: `${theme.fontSize.size8}`,
    },
    '&:-moz-placeholder': {
      color: theme.palette.text.grayColor,
      fontSize: `${theme.fontSize.size8}`,
    },
    '&::placeholder': {
      color: theme.palette.text.grayColor,
      fontSize: `${theme.fontSize.size8}`,
    },
  },
  borderHighlightML: {
    borderColor: theme.palette.alert.warn2,
    boxShadow: `0px 0px 4px ${theme.palette.alert.warn2}`,
  },
}));

export default function TextAreaField(props) {
  const classes = useStyles();
  const [remainingLength, setRemainingLength] = React.useState(0);
  const [isFocused, toggleFocus] = React.useState(false);

  const handleOnChange = (e) => {
    if (!props.maxLength) {
      props.onChange(e);
      return;
    }
    let currentInput = e.target.value;
    let trimmedInput = currentInput.slice(0, props.maxLength);
    e.target.value = trimmedInput;
    if (remainingLength > 0 || trimmedInput.length < props.maxLength) {
      props.onChange(e);
      setRemainingLength(props.maxLength - trimmedInput.length);
    }
  };

  useEffect(() => {
    if (props.maxLength) {
      if (props.value && props.value.toString().length > 0) {
        setRemainingLength(props.maxLength - props.value.toString().length);
      } else {
        setRemainingLength(props.maxLength);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.maxLength, props.value]);

  return (
    <FieldWrapper
      charHelperText={
        props.maxLength && props.isShowCustomDescText && props.ToolTipCharCount
          ? `${remainingLength}${remainingLength <= 1 ? ' Character' : ' Characters'}`
          : ''
      }
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={
        props.maxLength && !props.isShowCustomDescText
          ? `${remainingLength}${remainingLength <= 1 ? ' Character' : ' Characters'}`
          : props.descText
      }>
      <TextareaAutosize
        disabled={props.disabled}
        rowsMin={props.rowMin}
        rowsMax={props.rowMin}
        placeholder={props.placeholder}
        className={`
          ${props.disabled ? 'cursor-not-allowed ' : ''} ${classes.textArea} 
          ${isFocused ? 'Mui-focused' : ''} ${props.className || ''} ${
          props.error ? classes.borderDanger : ''
        }
          ${props.isLanguageSupport ? classes.borderHighlightML : ''}
        `}
        id={props.id}
        onFocus={(e) => {
          toggleFocus(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onBlur={(e) => {
          toggleFocus(false);
          if (props.onBlur) props.onBlur(e);
        }}
        value={props.value}
        onChange={handleOnChange}
        name={props.name}
      />
    </FieldWrapper>
  );
}
