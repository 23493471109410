/* eslint-disable no-param-reassign */
import { SET_SESSION_DATA, REMOVE_SESSION_DATA, SET_RAW_DATES } from 'redux/actions/session-action';

const eventData = (state = {}, action) => {
    switch (action.type) {
        case SET_SESSION_DATA:
            state = { ...state, ...action.data };
            break;
        case REMOVE_SESSION_DATA:
            state = {};
            break;
        case SET_RAW_DATES:
            state = { ...state, rawDates: action.data }
            break;
        default:
            break;
    }
    return state;
}

export default eventData;