import projectSetting from 'settings';

export function commonSelector(state) {
  const { UserInfo, EventData } = state;
  return {
    userId: UserInfo.id,
    eventId: EventData.id,
    organiserId: EventData.organiser_id ? EventData.organiser_id : UserInfo.organiser_id,
    userPermission: UserInfo.userPermission,
    is_sponsored_ads_support: UserInfo.is_sponsored_ads_support,
    is_language_support: UserInfo.is_language_support,
    is_cep_enabled: EventData.is_cep_enabled,
    subscription_name: UserInfo.planName,
    is_chat_tagging: UserInfo.is_chat_tagging,
    is_email_builder: EventData.is_email_builder,
    organisationName: EventData.organisation,
    is_freeman_customer: UserInfo?.is_freeman_customer,
    isContentHubEnabled: Boolean(UserInfo?.is_contenthub_enabled),
  };
}
export function zoomSelector(state) {
  const { ZoomData } = state;
  return {
    isFromZoomApp: ZoomData?.isFromZoomApp || false,
    tokenExpire: ZoomData.tokenExpire,
    appId: ZoomData.appId,
    isZoomError: ZoomData.isZoomError || false,
    zoomScopeError: ZoomData.zoomScopeError || false,
  };
}

export function ticketDataInfoSelector(state) {
  return {
    ticketTableData: state.ticketDataInfo,
  };
}

export function userInfoSelector(state) {
  const { UserInfo } = state;

  // UserInfo.used_attendee_credits = 25;
  // UserInfo.used_virtual_booth_credits = 9000;
  // UserInfo.total_virtual_booth_credits = 8000;
  // UserInfo.used_virtual_booth_credits = 9;
  let peoplesCreditsUsagePercentage;
  // 	let features = [{id: 4, name: "One on One Meetings", status: "ACTIVE"},
  //  {id: 6, name: "Analytics", status: "INACTIVE"},
  //  {id: 8, name: "Gamification", status: "INACTIVE"},
  // {id: 10, name: "Integrations", status: "INACTIVE"},
  // {id: 11, name: "Exhibitor Central↵", status: "INACTIVE"},
  //  {id: 12, name: "Session Registration↵", status: "INACTIVE"},
  //  {id: 13, name: "Tier Based Grouping↵", status: "INACTIVE"},
  //  {id: 14, name: "Multi Skins↵", status: "INACTIVE"},
  //  {id: 15, name: "Multi lingual↵", status: "INACTIVE"},
  //  {id: 16, name: "Multi Event Portal↵", status: "INACTIVE"},
  // {id: 17, name: "Mobile App↵", status: "INACTIVE"},
  //  {id: 18, name: "Contests↵", status: "INACTIVE"},
  // {id: 19, name: "Role based access↵", status: "INACTIVE"},
  // {id: 20, name: "Premium Booths Exhibitor Central", status: "INACTIVE"},
  // {id: 21, name: "Report Builder↵", status: "INACTIVE"},
  //  {id: 22, name: "Sponsored Ads", status: "INACTIVE"},
  // {id: 23, name: "White Labelld URL", status: "INACTIVE"},
  //  {id: 24, name: "Single Sign On Support", status: "INACTIVE"},
  //  {id: 25, name: "Custom CSS Support", status: "INACTIVE"},
  //  {id: 26, name: "Remove Hubilo Branding", status: "INACTIVE"},
  //  {id: 27, name: "Custom API integrations", status: "INACTIVE"},
  //  {id: 57, name: "Bring Your Own Stream", status: "INACTIVE"},
  //  {id: 58, name: "Data Export", status: "INACTIVE"},
  // {id: 60, name: "Surveys", status: "INACTIVE"},
  // {id: 61, name: "Team Member Permissions", status: "INACTIVE"},
  //  {id: 62, name: "Potential Leads for Virtual Boths", status: "INACTIVE"},
  //  {id: 7, name: "Rooms", status: "INACTIVE"},
  // {id: 2, name: "Lounges", status: "INACTIVE"}]

  // //UserInfo.pricing_organiser_feature = features;

  let virtualBoothCreditsUsagePercentage;
  let adminMemberUsagePercentage;
  let concurrentSessionsUsagePercentage;
  if (UserInfo.total_attendee_credits === 0) {
    peoplesCreditsUsagePercentage = UserInfo.used_attendee_credits;
  } else {
    peoplesCreditsUsagePercentage =
      (UserInfo.used_attendee_credits / UserInfo.total_attendee_credits) * 100;
  }

  if (UserInfo.total_virtual_booth_credits === 0) {
    virtualBoothCreditsUsagePercentage = UserInfo.used_virtual_booth_credits;
  } else {
    virtualBoothCreditsUsagePercentage =
      (UserInfo.used_virtual_booth_credits / UserInfo.total_virtual_booth_credits) * 100;
  }
  if (UserInfo.totalAdminMemberCredit === 0) {
    adminMemberUsagePercentage = UserInfo.usedAdminMemberCredit;
  } else {
    adminMemberUsagePercentage =
      (UserInfo.usedAdminMemberCredit / UserInfo.totalAdminMemberCredit) * 100;
  }

  if (UserInfo.totalConcurrentSessionCredit === 0) {
    concurrentSessionsUsagePercentage = UserInfo.usedConcurrentSessionCredit;
  } else {
    concurrentSessionsUsagePercentage =
      (UserInfo.usedConcurrentSessionCredit / UserInfo.totalConcurrentSessionCredit) * 100;
  }
  // UserInfo.subscriptionStatus = "ACTIVE"
  // UserInfo.isPaymentFailed = 1;
  // UserInfo.subscriptionStatus = "CANCELLED";
  UserInfo.peoplesCreditsUsagePercentage = peoplesCreditsUsagePercentage;
  UserInfo.virtualBoothCreditsUsagePercentage = virtualBoothCreditsUsagePercentage;
  UserInfo.adminMemberUsagePercentage = adminMemberUsagePercentage;
  UserInfo.concurrentSessionsUsagePercentage = concurrentSessionsUsagePercentage;
  UserInfo.isNewEventMobileSection = true;
  if (UserInfo.currency === 'INR') {
    UserInfo.currencySymbol = '₹';
  } else if (UserInfo.currency === 'GBP') {
    UserInfo.currencySymbol = '£';
  } else if (UserInfo.currency === 'EUR') {
    UserInfo.currencySymbol = '€';
  } else {
    UserInfo.currencySymbol = '$';
  }

  return {
    userId: UserInfo.id,
    UserInfo,
  };
}

export function messageSelector(state) {
  const { OTHER } = state;
  return {
    messageList: OTHER.messageList,
  };
}

export function eventDataSelector(state) {
  const { UserInfo, EventData } = state;
  return {
    userId: UserInfo.id,
    eventId: EventData.id,
    organiserId: EventData.organiser_id,
    EventData,
  };
}

export const createEditParentSelector = (state) => {
  const { createEditParentReducer } = state;
  return {
    parentData: createEditParentReducer.editCreateParent,
  };
};

export function eventListDataSelector(state) {
  const { EventListData } = state;
  return { ...EventListData };
}

export function timeZoneSelector(state) {
  return state.Timezones;
}

export function languageSelector(state) {
  return state.Language;
}

export function basicInfoSelector(state) {
  const { EventData } = state;
  let address = {};
  if (EventData.start_time) {
    if (EventData.type === 'HYBRID' && EventData.address) {
      const { lat: defaultLat, lng: defaultLng } = projectSetting.defaultHybridEventAddress;
      address = {
        address: EventData.address.address,
        country: EventData.address.country,
        province: EventData.address.province,
        city: EventData.address.city,
        zip: EventData.address.zip,
        lat: EventData.address.lat || defaultLat,
        lon: EventData.address.lon || defaultLng,
      };
    }
    return {
      startTime: new Date(EventData.start_time),
      endTime: new Date(EventData.end_time),
      // start_time_milli: moment(+EventData.start_time_milli).tz(EventData.timezone.name).valueOf(),
      // end_time_milli: moment(+EventData.end_time_milli).tz(EventData.timezone.name).valueOf(),
      start_time_milli: +EventData.start_time_milli,
      end_time_milli: +EventData.end_time_milli,
      description: EventData.description,
      timezoneId: EventData.timezone.id,
      timezone: { ...EventData.timezone },
      name: EventData.name,
      timezoneName: EventData.timezone.name,
      fb_url: EventData.fb_url || '',
      twitter_url: EventData.twitter_url || '',
      linkedin_url: EventData.linkedin_url || '',
      instagram_url: EventData.instagram_url || '',
      website_url: EventData.website_url || '',
      currency: EventData.currency ? EventData.currency.currency || '' : '',
      currencySymbol: EventData.currency ? EventData.currency.currency.symbol || '' : '',
      event_languages: EventData && EventData?.event_languages ? EventData.event_languages[0] : '',
      selectedLanguages: EventData?.event_languages || [],
      eventType: EventData.type,
      isPhysicalMeet: EventData.isPhysicalMeet,
      address,
      eventMetaTypeId: EventData.eventMetaTypeId,
      eventMetaTypeValue: EventData.eventMetaTypeValue,
      is_intr_eng: EventData.is_intr_eng,
      duplication_status: EventData.duplication_status,
      sessionId: EventData.sessionId,
      is_cep_enabled: EventData.is_cep_enabled,
      is_session_cep_enabled: EventData.is_session_cep_enabled,
      sessionEndTimeMilli: EventData.sessionEndTimeMilli,
      eventId: EventData.id,
      are_utm_params_enabled: EventData.are_utm_params_enabled,
      createTimeMilli: Number(EventData.create_time_milli),
      isQuizEnabled: EventData.quiz_enabled,
    };
  } else return {};
}

export function contestDataSelector(state) {
  return state.ContestData;
}

export function sessionDataSelector(state) {
  const { SessionData } = state;
  return SessionData;
}

export function getMultiEventAnalyticsEventData(state) {
  return state.MultiEventData;
}

// return Stripe Details
export const stripeSelector = (state) => state.StripeDetails;

export function eventLanguagesSelector(state) {
  const { EventLanguages } = state;
  return EventLanguages;
}

export function currentEventLanguagesSelector(state) {
  const { CurrentEventLanguage } = state;

  return CurrentEventLanguage;
}

export function baseEventLanguagesSelector(state) {
  const { BaseEventLanguage } = state;

  return BaseEventLanguage;
}

export function cookieConsentSelector(state) {
  const { cookieConsentInfo } = state;
  return cookieConsentInfo;
}

export function copyEventSelector(state) {
  const { CopyEvent, UserInfo, EventData } = state;
  return {
    userId: UserInfo.id,
    eventId: EventData.id,
    organiserId: EventData.organiser_id ? EventData.organiser_id : UserInfo.organiser_id,
    start_time_milli: +CopyEvent.start_time_milli,
    description: CopyEvent.description,
    timezone: { ...CopyEvent.timezone },
    name: CopyEvent.name,
    event_languages: CopyEvent?.event_languages?.length ? CopyEvent?.event_languages : [34],
    base_language: CopyEvent && CopyEvent?.event_languages ? CopyEvent.event_languages[0] : '',
    hybrid_type_support: CopyEvent?.type === 'HYBRID',
    eventType: CopyEvent?.type,
  };
}

export function copyStatusSelector(state) {
  const { CopyStatus } = state;
  return CopyStatus;
}

export function copiedNewIdsSelector(state) {
  const { newIds } = state;
  return newIds;
}

export function templateModalDetailsSelector(state) {
  const { templateModalInfo } = state;
  return templateModalInfo;
}

export function primaryEditSelector(state) {
  const { templateModalInfo } = state;
  return {
    prevTemplateId: templateModalInfo?.prevTemplateId || '',
    isEditPrimary: templateModalInfo?.isEditPrimary || false,
    currentTemplateId: templateModalInfo?.templateObj?.templateId || '',
  };
  // return templateModalInfo?.templateId || "";
}

export function templatePreviewModalDetailsSelector(state) {
  const { templatePreviewInfo } = state;
  return templatePreviewInfo;
}

export function CampaignStatusSelector(state) {
  const { CampaignStatus = {} } = state;
  return CampaignStatus?.isOpen || false;
}

export function CampaignActionSelector(state) {
  const { CampaignStatus = {} } = state;
  return CampaignStatus?.status || '';
}

export function CampaignIdSelector(state) {
  const { CampaignStatus = {} } = state;
  return CampaignStatus?.id || '';
}

export function CampaignCBSelector(state) {
  const { CampaignStatus = {} } = state;
  return CampaignStatus?.callback;
}

export const roomReactionSelector = (state) => state.roomReactionReducerEnable;
export const loungeReactionSelector = (state) => state.loungeReactionEnableReducer;

export const ticketSelector = (state) => state.ticketReducer;
export const analyticsDataSelector = (state) => state.Analytics;

export function InviteEmailCampaignSelector(state) {
  return state.inviteEmailCampaignData;
}

export function alertSelector(state) {
  return state.alertData;
}

export function getIsCreditsTabVisible(state) {
  return state.EventData.isCreditsTabVisible ?? null;
}

export function getCopyEventVeAddOnCreditsModalOpen(state) {
  return state.commonReducer.isVeAddOnCreditsNotAvl;
}
