/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';
import InputField from './inputField';
import Chip from '@material-ui/core/Chip';
import SvgCaretDownIcon from '../../icons/svgCaretDown';
import FieldWrapper from './fieldWrapper';
import { Check as CheckIcon, Search, PlusCircle, XCircle, X as CancelIcon, Plus } from 'react-feather';
import SvgEdit3 from 'icons/svgEdit3';
import SvgDelete2 from 'icons/svgDelete2';
import CustomToolTip from 'custom-components/customToolTip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBox2 from 'custom-components/form-fields/checkbox2';

const useStyle = makeStyles((theme) => ({
  tooltip: {
    top: theme.spacing(1),
    left: 'auto',
    display: 'inline-flex',
    position: 'relative',
  },
  bottomActionCTA: {
    '&:hover': {
      background: 'transparent',
    }
  },
  borderDanger: {
    border: `1px solid ${theme.palette.text.danger1} !important`,
  },
  chipsWrap: {
    '& .MuiChip-root': {
      marginTop: theme.spacing(0.375),
      marginBottom: theme.spacing(0.375),
    },
    '& .MuiChip-label': {
      maxWidth: 240,
    },
    '& .MuiChip-root + .MuiChip-root': {
      marginLeft: theme.spacing(1),
    },
    '& .chipCount': {
      minWidth: 32,
      height: 24,
      borderRadius: 24,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.fontSize.size12,
      lineHeight: 0,
      padding: '0 8px',
      margin: '3px 0 3px 8px',
    },
    '& .truncateText': {
      overflow: 'hidden',
      maxWidth: '120px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  selectOption: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    transition: theme.transitions.create(['color', 'background-color']),
    '& .MuiListItemIcon-root': {
      minWidth: 34,
    },
    '&:not(.not-hover):hover': {
      backgroundColor: theme.palette.bg.light,
      color: theme.palette.text.primary,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
      },
    },
    '&.editable': {
      paddingRight: 74 + theme.spacing(2),
    },
    '& .hbsv2ExpireLabel': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      padding: '2px 5px',
      borderRadius: '4px',
      display: 'inline-flex',
      fontSize: '12px',
      fontWeight: '700',
      marginLeft: '10px',
    },
  },
  selectField: {
    background: theme.palette.bg.white,
    padding: `${theme.spacing(0.9)}px ${theme.spacing(1.5)}px`,
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['box-shadow', 'color'], {
      easing: theme.transitions.easing.easeInOut,
    }),
    "&.textOverflowEllipse": {
      "& .selectInput": {
        "& span": {
          whiteSpace: "initial",
        }
      }
    },
    '&.small': {
      padding: `${theme.spacing(0)}px ${theme.spacing(1.5)}px`,
    },
    '&.Mui-focused': {
      boxShadow: theme.inputBoxShadow,
    },
    '&.bgWhite': {
      background: theme.palette.bg.white,
    },
    '&.currencyStyle': {
      border: `none`,
      borderRadius: 0,
      padding: `0px ${theme.spacing(1.5)}px`,
      '&.Mui-focused': {
        boxShadow: 'none',
      },
    },
  },
  selectInput: {
    padding: `${theme.spacing(0.6025)}px 0`,
    '& .MuiFab-root': {
      top: theme.spacing(0.5),
    },
    '&> .MuiBox-root': {
      whiteSpace: 'nowrap',
    },
    '&.multi-select': {
      // padding: 0,
    },
    '& .hbsv2ExpireLabel': {
      backgroundColor: theme.palette.primary.lighter,
      color: theme.palette.primary.main,
      padding: '2px 5px',
      borderRadius: '4px',
      display: 'inline-flex',
      fontSize: '12px',
      fontWeight: '700',
      marginLeft: '10px',
    },
  },
  optionList: {
    maxHeight: 200,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  dropdownActionItem: {
    padding: `${theme.spacing(2.25)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    '&.add-option-on': {
      paddingTop: theme.spacing(1.35),
      paddingBottom: theme.spacing(1.35),
    },
  },
  addOptionField: {
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.25)}px`,
    '& input': {
      padding: `${theme.spacing(1)}px 0`,
      backgroundColor: 'transparent',
    },
  },
  addOptionInput: {
    borderRadius: 0,
    border: 0,
    outline: 0,
    width: '100%',
    fontSize: theme.fontSize.size8,
    color: theme.palette.text.primary,
    '&::-webkit-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&::-moz-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&:-moz-placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
    '&::placeholder': {
      color: theme.palette.text.grayColor,
      opacity: 1,
      transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    },
  },
  addOptionAction: {
    padding: 0,
    width: 25,
    minWidth: 25,
    height: 25,
  },
  listItemAction: {
    padding: theme.spacing(1),
    color: theme.palette.text.grayColor,
    transition: theme.transitions.create(['color', 'background-color']),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  listItemActionIcon: {
    fontSize: theme.spacing(2.1),
    width: '1em',
    height: '1em',
  },
  themeColor: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    display: 'flex',
  },
  newHbsDrp: {
    '& .MuiListItemText-root': { flex: 'none' },
  },
}));

function getLabelByValueKey(options, curValue, valueKey, labelKey) {
  const curIndex = options.findIndex((ele) => ele[valueKey] === curValue);
  if (curIndex > -1) {
    return options[curIndex][labelKey];
  } else {
    return '';
  }
}

export default function SelectDropdown(props) {
  const {
    valueKey = 'value',
    labelKey = 'label',
    options = [],
    placeholder = 'Select',
    position = 'top',
    dropdownZindex = 5,
    getDropdownStatus = () => { }, // can be used for imporving user experience when user opens dropdown
    noDataFoundCustom, // should be a component if you don't want to show No Data Found and something else instead
    noSearchResultCustom, // if search results are not founc in dropdown, then you can show this
  } = props;
  const isShowPlusCount =
    Array.isArray(props.value) &&
    props.displayCount &&
    props.value.length - props.displayCount &&
    !props.isAllSelected &&
    props.value.length > props.displayCount;
  const classes = useStyle();
  const inputRef = React.useRef();
  const [searchValue, setSearchValue] = useState('');
  const [isMenuOpen, toggleMenu] = useState(false);
  const [isAddOptionOn, toggleAddOption] = React.useState(false);
  const [addOptionValue, setAddOptionValue] = React.useState({});

  const onSelectOption = (event, value) => {
    if (props.onChange) props.onChange(value, props.name, event);
    if (!props.isMultiSelect) toggleMenu(false);
  };
  const handleAddOption = (event, type) => {
    setAddOptionValue({ ...addOptionValue, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    getDropdownStatus(isMenuOpen);
  }, [isMenuOpen]);

  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <ClickAwayListener
        onClickAway={() => {
          toggleMenu(false);
          setSearchValue('');
          if (Object.keys(addOptionValue).length) setAddOptionValue({});
          if (searchValue.length) setSearchValue('');
          if (isAddOptionOn) toggleAddOption(false);
        }}>
        <Box position="relative" className={`${props.sessionCustomTag ? props.className : ''}`}>
          <Box
            display="flex"
            onClick={() => {
              if (props.disabled) return false;
              toggleMenu(!isMenuOpen);
              setSearchValue('');
              if (props.onOpenDropDown && typeof props.onOpenDropDown === 'function')
                props.onOpenDropDown(!isMenuOpen);
            }}
            className={`${props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${
              isMenuOpen ? 'Mui-focused' : ''
            } ${props.inputClassName || ''} ${props.error ? classes.borderDanger : ''} ${
              classes.selectField
            } ${props.readOnly ? 'read-only' : ''}`}
            alignItems="center">
            {!props.startAdornment ? (
              false
            ) : props.startAdornmentType === 'ICON' ? (
              <Box display="inline-flex" mr={1}>
                {props.startAdornmentIcon}
              </Box>
            ) : (
              <Box display="inline-flex" mr={1}>
                {props.startAdornment}
              </Box>
            )}
            <Box
              width="100%"
              display="flex"
              className={`selectInput ${classes.selectInput} ${
                (Array.isArray(props.value) ? props.value.length : null) ? 'multi-select' : ''
              }`}>
              {!props.value || (Array.isArray(props.value) && !props.value.length) ? (
                <Box component="span" color="text.grayColor">
                  {placeholder}
                </Box>
              ) : props.isMultiSelect ? (
                <Box className={classes.chipsWrap}>
                  {props.isAllSelected ? (
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={props.allSelectedLabel || 'All'}
                      size="small"
                      onDelete={() => props.onDeleteValue('All', 0)}
                      deleteIcon={<XCircle />}
                    />
                  ) : (
                    <>
                      {props.value
                        .filter((item, i) => {
                          if (props.displayCount) {
                            return i < props.displayCount;
                          } else return item;
                        })
                        .map((eachValue, index) => {
                          let curVal;
                          if (!props.isArrayOfObject) curVal = eachValue;
                          else if (props.isUseValueKey) {
                            curVal = getLabelByValueKey(options, eachValue, valueKey, labelKey);
                          } else curVal = eachValue[labelKey];
                          return (
                            <Chip
                              variant="outlined"
                              color="primary"
                              title={curVal}
                              key={index}
                              label={curVal}
                              className="truncateText"
                              size="small"
                              onDelete={() => props.onDeleteValue(eachValue, index)}
                              deleteIcon={<XCircle />}
                            />
                          );
                        })}
                    </>
                  )}
                  {!props.displayCount ? (
                    false
                  ) : isShowPlusCount ? (
                    <Box component="span" className="chipCount">
                      +{props.value.length - props.displayCount}
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <Box component="span">
                  {props.isArrayOfObject
                    ? props.isUseValueKey
                      ? props.isRenderValueKey
                        ? props.value
                        : getLabelByValueKey(options, props.value, valueKey, labelKey)
                      : props.isRenderValueKey
                      ? props.value[valueKey]
                      : props.value[labelKey]
                    : props.value}

                  {props?.expireText && <div className="hbsv2ExpireLabel">{props?.expireText}</div>}
                  {props.tooltip ? (
                    <CustomToolTip
                      isFabIcon
                      title={props.tooltip}
                      className={classes.tooltip}
                      placement="right"
                    />
                  ) : (
                    ''
                  )}
                </Box>
              )}
            </Box>

            <Box
              ml={1}
              color="text.grayColor"
              component="span"
              display="inline-flex"
              alignItems="center"
              justifyContent="center">
              {!props.connectedAppsProps ? (
                <SvgCaretDownIcon height="15px" width="15px" />
              ) : (
                <ExpandMoreIcon color="#444444" />
              )}
            </Box>
          </Box>

          <Fade in={isMenuOpen}>
            <Box
              position="absolute"
              top={`${position === 'top' ? '100%' : 'auto'}`}
              bottom={`${position === 'bottom' ? 'auto' : '100%'}`}
              left="0"
              right="0"
              zIndex={dropdownZindex}
              mt={1.2}
              mb={1.2}
              hidden={props.readOnly}>
              <Paper
                elevation={2}
                style={{ minWidth: props.dropDownWidth ? props.dropDownWidth : '100%' }}>
                {props.loading || !props.canAddOption ? (
                  false
                ) : (
                  <Box
                    className={`${classes.dropdownActionItem} ${
                      isAddOptionOn ? 'add-option-on' : ''
                    }`}>
                    {isAddOptionOn ? (
                      <Box
                        display="flex"
                        className={`cursor-pointer ${classes.addOptionField}`}
                        alignItems="center"
                        width="100%">
                        {props.isArrayOfObject ? (
                          <>
                            <Box width="100%" display="flex" className={``}>
                              <input
                                autocomplete="off"
                                name={labelKey}
                                maxLength={props?.categoryLimit || 50}
                                onChange={handleAddOption}
                                className={classes.addOptionInput}
                                placeholder={`Enter ${labelKey}`}
                                value={addOptionValue[labelKey] || ''}
                              />
                            </Box>
                            {props.canAddOptionValue ? (
                              <Box width="100%" display="flex" className={``}>
                                <input
                                  autocomplete="off"
                                  name={valueKey}
                                  maxLength={50}
                                  onChange={handleAddOption}
                                  className={classes.addOptionInput}
                                  placeholder={`Enter ${valueKey}`}
                                  value={addOptionValue[valueKey] || ''}
                                />
                              </Box>
                            ) : (
                              false
                            )}
                          </>
                        ) : (
                          <Box width="100%" display="flex" className={``}>
                            <input
                              autocomplete="off"
                              name={valueKey}
                              onChange={handleAddOption}
                              className={classes.addOptionInput}
                              placeholder={props.addOptionPlaceholder}
                              value={addOptionValue[valueKey] || ''}
                            />
                          </Box>
                        )}
                        <Box
                          ml={1.7}
                          color="primary.main"
                          component="span"
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center">
                          <Button
                            onClick={() => {
                              const { source, ...restOption } = addOptionValue;
                              if (props.AddWithSuccessCB) {
                                setAddOptionValue({});
                                toggleAddOption(false);
                                //  toggleMenu(false);
                                props.addNewOption(restOption, source || 'ADD', () => {
                                  if (!props.isMultiSelect) toggleMenu(false);
                                });
                              } else {
                                props.addNewOption(restOption, source || 'ADD');
                              }
                            }}
                            disabled={
                              addOptionValue[valueKey] && addOptionValue[valueKey].length > 0
                                ? false
                                : true
                            }
                            size="small"
                            color="primary"
                            variant="contained"
                            className={classes.addOptionAction}>
                            <CheckIcon size={18} />
                          </Button>
                          <Box ml={1}>
                            <Button
                              onClick={() => {
                                setAddOptionValue({});
                                toggleAddOption(false);
                              }}
                              size="small"
                              color="primary"
                              variant="outlined"
                              className={classes.addOptionAction}>
                              <CancelIcon size={19} />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : !props.sessionCustomTag ? (
                      <Button
                        onClick={() => {
                          toggleAddOption(true);
                        }}
                        className="uppercase"
                        size="small"
                        color="primary"
                        variant="outlined">
                        <Box mr={1} component="span">
                          {props.addOptionBtnLabel || 'Add'}
                        </Box>
                        <Box component="span" display="inline-flex">
                          <PlusCircle size={16} />
                        </Box>
                      </Button>
                    ) : (
                      <Box
                        className="sessionCustomTagSearch"
                        display="flex"
                        alignItems="center"
                        justifyContent="spaceBetween">
                        {props.hasSearch && (options.length > 4 || props.sessionCustomTag || props?.hasApiSearch) ? (
                          <Box mr={1.5} className="w-100">
                            <InputField
                              onChange={(e) => {
                                if (props.onSearch) props.onSearch(e.target.value);
                                setSearchValue(e.target.value);
                              }}
                              value={searchValue}
                              placeholder="Search here..."
                              type="search"
                              inputRef={inputRef}
                              startAdornment={true}
                              startIconAdornment={true}
                              startAdornmentIcon={<Search />}
                              endAdornment={true}
                            />
                          </Box>
                        ) : (
                          false
                        )}
                        <Button
                          onClick={() => {
                            toggleAddOption(true);
                          }}
                          className="uppercase"
                          size="small"
                          color="primary"
                          variant="outlined">
                          <Box mr={1} component="span">
                            {props.addOptionBtnLabel || 'Add'}
                          </Box>
                          <Box component="span" display="inline-flex">
                            <PlusCircle size={16} />
                          </Box>
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
                {!props.loading &&
                  props.hasSearch &&
                  !props.sessionCustomTag &&
                  (options.length > 4 || props.sessionCustomTag || props?.hasApiSearch) ? (
                  <Box className={`${classes.dropdownActionItem}`}>
                    <InputField
                      onChange={(e) => {
                        if (props.onSearch) props.onSearch(e.target.value);
                        setSearchValue(e.target.value);
                      }}
                      value={searchValue}
                      placeholder="Search here..."
                      type="search"
                      inputRef={inputRef}
                      startAdornment={true}
                      startIconAdornment={true}
                      startAdornmentIcon={<Search />}
                      endAdornment={true}
                    />
                  </Box>
                ) : (
                  false
                )}
                {isMenuOpen && (
                  <List
                    className={`${classes.optionList} ${props.optionListUi}`}
                    onScroll={(e) => (props.onScroll ? props.onScroll(e) : '')}>
                    {!props.loading ? (
                      false
                    ) : (
                      <ListItem className={`${classes.selectOption}  not-hover`} component="li">
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={'Loading'}></ListItemText>
                      </ListItem>
                    )}
                    {props.loading || props.isPaginateLoader || !props.hasNoneOption ? (
                      false
                    ) : (
                      <ListItem
                        className={`${classes.selectOption}  not-hover`}
                        component="li"
                        // onClick={(e) => { e.stopPropagation(); }}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleMenu(false);
                        }}>
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary="None"></ListItemText>
                      </ListItem>
                    )}
                    {props.loading || props.isPaginateLoader || options.length ? (
                      false
                    ) : (
                      <>
                        {!noDataFoundCustom ? (
                          <ListItem className={`${classes.selectOption} not-hover`} component="li">
                            <ListItemText
                              primaryTypographyProps={{ variant: 'body2' }}
                              primary={'No Data Found'}></ListItemText>
                          </ListItem>
                        ) : (
                          <Box>{noDataFoundCustom}</Box>
                        )}
                      </>
                    )}
                    {props.loading
                      ? false
                      : props.isArrayOfObject
                      ? options.map((eachOption, index) => {
                          if (
                            props.hasSearch &&
                            !eachOption[labelKey].toLowerCase().includes(searchValue.toLowerCase())
                          ) {
                            return false;
                          } else {
                            let curVal = eachOption,
                              isSelected = false;
                            if (props.isUseValueKey) {
                              curVal = eachOption[valueKey];
                            }
                            if (
                              props.isMultiSelect &&
                              props.value &&
                              Array.isArray(props.value) &&
                              props.value.length
                            ) {
                              isSelected =
                                props.value.findIndex(
                                  (ele) => ele[valueKey] === eachOption[valueKey]
                                ) !== -1
                                  ? true
                                  : false;
                            } else {
                              isSelected = curVal === props.value;
                            }

                            return (
                              <>
                                {props.firstValueAsComponent &&
                                  index === 0 &&
                                  props.firstValueAsComponent}
                              <ListItem
                                className={`${classes.selectOption} ${
                                  eachOption.endIcon ? classes.newHbsDrp : ''
                                } ${props.dropDownListBlock} ${
                                  props.isOptionEditable ? 'editable' : ''
                                }`}
                                key={index}
                                button
                                component="li"
                                disabled={
                                  eachOption.isDisable ||
                                  (props.sessionCustomTag
                                    ? false
                                    : props.isMultiSelect && isSelected
                                    ? true
                                    : false)
                                }
                                onClick={(e) => onSelectOption(e, curVal)}
                                selected={isSelected}>
                                {props.sessionCustomTag && (
                                  <CheckBox2
                                    //  label="Include tax in the ticket price"
                                    color="primary"
                                    //  value={isIncludeTaxInTickets}
                                    checked={isSelected}
                                    onChange={() => {}}
                                    //  error={errorObj?.includeTaxInTicketPrice}
                                  />
                                )}
                                {!props.iconAsLabel ? (
                                  false
                                ) : (
                                  <ListItemIcon>
                                    {!eachOption.icon ? false : eachOption.icon}
                                  </ListItemIcon>
                                )}
                                {!props.subTextPositionLast && (
                                  // if this is rendered then the sub text won't be added at the last instead it will be added below
                                  <ListItemText
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    primary={<span> {eachOption[labelKey]}  {eachOption?.expireText && <div className='hbsv2ExpireLabel'>{eachOption?.expireText}</div>}</span>}
                                    secondary={
                                      props.subText ? eachOption[props.subTextKey] : ''
                                    }></ListItemText>
                                )}
                                {eachOption.endIcon && (
                                  <ListItemIcon>
                                    {!eachOption.endIcon ? false : eachOption.endIcon}
                                  </ListItemIcon>
                                )}

                                {props.subTextPositionLast ? (
                                  // if this is rendered then the sub text would be added at the last
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%">
                                    <Typography variant="body2" component="p">
                                      {eachOption[labelKey]}
                                    </Typography>
                                    {props.subText ? (
                                      <Typography
                                        className={classes.themeColor}
                                        variant="body2"
                                        component="p">
                                        {eachOption[props.subTextKey]}
                                      </Typography>
                                    ) : (
                                      ''
                                    )}
                                  </Box>
                                ) : null}

                                {eachOption.tooltip ? (
                                  <CustomToolTip
                                    isFabIcon
                                    title={eachOption.tooltip}
                                    className={classes.tooltip}
                                    placement="right"
                                  />
                                ) : (
                                  ''
                                )}

                                {!props.isOptionEditable ? (
                                  false
                                ) : eachOption[valueKey].toString().toLowerCase() !== 'none' ? (
                                  <ListItemSecondaryAction>
                                    <Box display="flex" alignItems="center">
                                      <IconButton
                                        onClick={() => {
                                          toggleAddOption(true);
                                          setAddOptionValue({ ...eachOption, source: 'EDIT' });
                                        }}
                                        size="small"
                                        className={classes.listItemAction}>
                                        <SvgEdit3 className={classes.listItemActionIcon} />
                                      </IconButton>
                                      <Box ml={1} display="inline-flex">
                                        <IconButton
                                          onClick={() => props.onDeleteOption(eachOption)}
                                          size="small"
                                          className={classes.listItemAction}>
                                          <SvgDelete2 className={classes.listItemActionIcon} />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </ListItemSecondaryAction>
                                ) : (
                                  ''
                                )}
                              </ListItem>
                              </>
                            );
                          }
                        })
                      : options.map((eachOption, index) => {
                          if (
                            props.hasSearch &&
                            !eachOption?.toLowerCase()?.includes(searchValue.toLowerCase())
                          ) {
                            return false;
                          } else {
                            let isSelected = false;
                            if (
                              props.isMultiSelect &&
                              props.value &&
                              Array.isArray(props.value) &&
                              props.value.length
                            ) {
                              isSelected =
                                props.value.findIndex((ele) => ele === eachOption) !== -1
                                  ? true
                                  : false;
                            } else {
                              isSelected = eachOption === props.value;
                            }
                            return (
                              <>
                                {props.firstValueAsComponent &&
                                  index === 0 &&
                                  props.firstValueAsComponent}
                                <ListItem
                                  className={`${classes.selectOption} ${
                                    props.isOptionEditable ? 'editable' : ''
                                  }`}
                                  key={index}
                                  button
                                  component="li"
                                  onClick={(e) => onSelectOption(e, eachOption)}
                                  disabled={
                                    props.sessionCustomTag
                                      ? false
                                      : props.isMultiSelect && isSelected
                                  }
                                  selected={isSelected}>
                                  <ListItemText
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    primary={eachOption}></ListItemText>

                                  {props.isOptionEditable && props.canAddOption ? (
                                    <ListItemSecondaryAction>
                                      <Box display="flex" alignItems="center">
                                        <IconButton
                                          onClick={() => {
                                            toggleAddOption(true);
                                            setAddOptionValue({
                                              [valueKey]: eachOption,
                                              source: 'EDIT',
                                            });
                                          }}
                                          size="small"
                                          className={classes.listItemAction}>
                                          <SvgEdit3 className={classes.listItemActionIcon} />
                                        </IconButton>
                                        <Box ml={1} display="inline-flex">
                                          <IconButton
                                            onClick={() => props.onDeleteOption(eachOption)}
                                            size="small"
                                            className={classes.listItemAction}>
                                            <SvgDelete2 className={classes.listItemActionIcon} />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    </ListItemSecondaryAction>
                                  ) : (
                                    false
                                  )}
                                </ListItem>
                              </>
                            );
                          }
                        })}
                    {props.bottomActionDropDownCTA &&
                      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        <Button onClick={props.bottomActionDropDownCTAClick} className={classes.bottomActionCTA} color="primary" variant="text" size="large" startIcon={<Plus />}>{props.bottomActionCTALabel}</Button>
                      </Box>}
                    {/* code to show UI when no search results are found starts */}
                    {!props.loading &&
                      props.hasSearch &&
                      searchValue &&
                      props.isArrayOfObject &&
                      options?.length > 0 &&
                      options?.filter((i) =>
                        i[labelKey].toLowerCase().includes(searchValue.toLowerCase())
                      )?.length === 0 && (
                        <>
                          {noSearchResultCustom ? (
                            noSearchResultCustom
                          ) : (
                            <ListItem
                              className={`${classes.selectOption} not-hover`}
                              component="li">
                              <ListItemText
                                primaryTypographyProps={{ variant: 'body2' }}
                                primary={'No Results Found'}></ListItemText>
                            </ListItem>
                          )}
                        </>
                      )}
                    {!props.loading &&
                      props.hasSearch &&
                      searchValue &&
                      !props.isArrayOfObject &&
                      options?.length > 0 &&
                      options?.filter((i) => i?.toLowerCase().includes(searchValue.toLowerCase()))
                        ?.length === 0 && (
                        <>
                          {noSearchResultCustom ? (
                            noSearchResultCustom
                          ) : (
                            <ListItem
                              className={`${classes.selectOption} not-hover`}
                              component="li">
                              <ListItemText
                                primaryTypographyProps={{ variant: 'body2' }}
                                primary={'No Results Found'}></ListItemText>
                            </ListItem>
                          )}
                        </>
                      )}
                    {/* code to show UI when no search results are found ends */}

                    {props.loading || !props.isPaginateLoader ? (
                      false
                    ) : (
                      <ListItem className={`${classes.selectOption} not-hover`} component="li">
                        <ListItemText
                          primaryTypographyProps={{ variant: 'body2' }}
                          primary={'Loading'}></ListItemText>
                      </ListItem>
                    )}
                  </List>
                )}
              </Paper>
            </Box>
          </Fade>
        </Box>
      </ClickAwayListener>
    </FieldWrapper>
  );
}
