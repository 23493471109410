/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Recaptcha from 'react-invisible-recaptcha';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import InputField from 'custom-components/form-fields/inputField';
import Validation from '../../models/validation';
import { SocialRegisterSchema } from '../../models/schema';
import { Link, useHistory } from 'react-router-dom';
import HubiloLogo from '../../icons/svgHubiloNewIcon';
import BtnWithLoader from 'custom-components/btnWithLoader';
import { useDispatch } from 'react-redux';
import { API, POSTAPI } from 'api-setup/api-helper';
import { SOCIAL_REGISTER } from 'api-setup/api-endpoints';
import ProjectSettings from '../../settings';
import { setUserCredential } from 'lib/cookies.js';
import ChkBox from '../../custom-components/form-fields/checkbox2';
import { setNewMessage } from 'redux/actions/setting-actions';
import { Mail, User, Briefcase } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  subTitleWithSeparator: {
    position: 'relative',
    textTransform: 'uppercase',
    '&::after': {
      content: `" "`,
      position: 'absolute',
      top: '50%',
      left: 0,
      height: 1,
      backgroundColor: theme.palette.border.light,
      width: '100%',
    },
    '& span': {
      backgroundColor: 'white',
      position: 'relative',
      zIndex: 1,
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  emailVeriFailedWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    zIndex: 9999,
  },
  iconColor: {
    color: `${theme.palette.text.grayColor} !important`,
  },
}));

const makeApiCall = (formFields, history, dispatch, setIsLoading, captchaToken, recaptchaRef) => {
  const { email, firstName, lastName, organizationName } = formFields;
  const body = {
    data: {
      email,
      first_name: firstName,
      last_name: lastName,
      organisation: organizationName,
      captchaToken,
    },
  };

  setIsLoading(true);

  POSTAPI(false, SOCIAL_REGISTER, null, null, body)
    .then((res) => {
      if (res.data.status === API.apiSuccessStatus) {
        const userData = { authToken: res.data.data.token };
        setUserCredential(userData);
        dispatch(
          setNewMessage({ message: 'Success! Your Account is active', type: 'success', show: true })
        );
        history.replace('/events');
      } else {
        API.errStatusHandler(res, history, dispatch, () => {
          if (
            process.env.REACT_APP_ENV_TYPE === 'stg' ||
            process.env.REACT_APP_ENV_TYPE === 'prod'
          ) {
            recaptchaRef.current.reset();
          }
        });
      }
      setIsLoading(false);
    })
    .catch((err) => {
      setIsLoading(false);
      API.catchHandler(err, () => {
        if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
          recaptchaRef.current.reset();
        }
      });
    });
};

// handles validations for form fields
const handleValidations = (formFields) => {
  let newErrObj = '';
  newErrObj = Validation.validate(SocialRegisterSchema, formFields);
  if (newErrObj) return newErrObj;
  return false;
};

function SocialRegisterForm(props) {
  const { userData } = props;
  const { subTitleWithSeparator, iconColor } = useStyles();
  const [formFields, setFormFields] = useState({
    email: userData && userData.email ? userData.email : '',
    firstName: userData && userData.first_name ? userData.first_name : '',
    lastName: userData && userData.last_name ? userData.last_name : '',
    organizationName: '',
  });
  const [errObj, setErrObj] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const recaptchaRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false); // button loader
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (captchaToken) {
      makeApiCall(formFields, history, dispatch, setIsLoading, captchaToken, recaptchaRef);
    }
  }, [captchaToken]);

  const formSubmit = (event) => {
    event.preventDefault();
    const err = handleValidations(formFields);
    if (!err) {
      setErrObj({});
      if (process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod') {
        if (recaptchaRef && recaptchaRef.current) recaptchaRef.current.execute();
      } else {
        makeApiCall(formFields, history, dispatch, setIsLoading, captchaToken, recaptchaRef);
      }
    } else {
      setErrObj(err);
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'termsConditions') value = !formFields.termsConditions;
    setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <>
      <Box mb={10} display="flex" justifyContent="center">
        <HubiloLogo height="70px" width="140px" />
      </Box>
      <form onSubmit={(event) => formSubmit(event)}>
        <Box mt={3.2}>
          <Box mb={3} className={`text-center ${subTitleWithSeparator}`} color="text.grayColor">
            <Typography variant="caption" component="span">
              Create an Account
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <>
              <Grid item xs={12}>
                <InputField
                  required
                  placeholder="Email Address"
                  type="email"
                  id="email"
                  value={formFields.email}
                  disabled
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<Mail className={iconColor} />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  error={errObj.firstName}
                  name="firstName"
                  id="firstName"
                  onChange={handleChange}
                  required
                  placeholder="First Name"
                  type="text"
                  value={formFields.firstName}
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<User className={iconColor} />}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  error={errObj.lastName}
                  name="lastName"
                  id="lastName"
                  onChange={handleChange}
                  required
                  placeholder="Last Name"
                  type="text"
                  value={formFields.lastName}
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<User className={iconColor} />}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  error={errObj.organizationName}
                  name="organizationName"
                  id="organizationName"
                  value={formFields.organizationName}
                  onChange={handleChange}
                  required
                  placeholder="Organization"
                  type="text"
                  startAdornment
                  startIconAdornment
                  startAdornmentIcon={<Briefcase className={iconColor} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="start" color="text.grayColor">
                  <ChkBox
                    name="termsConditions"
                    color="primary"
                    value={formFields.termConditions}
                    onChange={handleChange}
                    checked={formFields.termConditions}
                    error={errObj.termsConditions}
                    label={
                      <Typography variant="caption" component="p">
                        I agree to the{' '}
                        <a href="https://hubilo.com/terms-service/">
                          <Box color="text.primary" component="span">
                            Terms and Conditions
                          </Box>
                        </a>{' '}
                        &amp;{' '}
                        <a href="https://hubilo.com/privacy-policy/">
                          <Box color="text.primary" component="span">
                            Privacy Policy
                          </Box>
                        </a>
                      </Typography>
                    }
                  />
                </Box>
              </Grid>
            </>
          </Grid>

          <Box mt={5}>
            <BtnWithLoader
              className="uppercase"
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
              isLoading={isLoading}
              loadingLabel="Finishing Up"
              label="CREATE"
              fullWidth
            />
          </Box>
          <Box mt={5} textAlign="center" color="text.grayColor">
            <Typography variant="caption" component="p">
              Already have an account?{' '}
              <Link to="/" className="text-uppercase">
                <Box component="span" color="primary.main" fontWeight={600}>
                  Sign In
                </Box>
              </Link>
            </Typography>
          </Box>
        </Box>
      </form>
      {process.env.REACT_APP_ENV_TYPE === 'stg' || process.env.REACT_APP_ENV_TYPE === 'prod' ? (
        <Recaptcha
          ref={recaptchaRef}
          sitekey={ProjectSettings.recaptchaSiteKey}
          onResolved={(token) => setCaptchaToken(token)}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default SocialRegisterForm;
