import { SET_TIMEZONES_LIST } from 'redux/actions/timezone-actions';

const timezonesReducer = (state = [], action) => {
    switch (action.type) {
        case SET_TIMEZONES_LIST:
            return [...action.payload]
        default:
            return state;
    }
}

export default timezonesReducer;