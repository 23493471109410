import React from 'react';

export default function SvgEnterprisePlanIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7 14.8896L15 9L10 9L10.3 14.8896C10.3 15.9619 10.1792 17.0192 9.67801 18C9.50242 18.3436 9.28013 18.6778 9 19H16C15.7199 18.6778 15.4976 18.3436 15.322 18C14.8208 17.0192 14.7 15.9619 14.7 14.8896ZM14.2226 18C13.798 16.9606 13.7 15.8904 13.7 14.8896V14.8641L13.9478 10L11.0522 10L11.3 14.8641V14.8896C11.3 15.8905 11.202 16.9606 10.7774 18H14.2226Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7208 9H14.2792L14.8559 7.27009L13.5858 6H11.4142L10.1441 7.27009L10.7208 9ZM9 7L10 10H15L16 7L14 5H11L9 7Z"
        fill="#F59C86"
      />
      <path
        d="M15 2H13.3333V0H11.6667V2H10V3.43512H11.6667V5H13.3333V3.43512H15V2Z"
        fill="#F59C86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7344 19.7912C15.561 19.5332 15.4371 19.2431 15.382 18.9322H9.61802C9.5629 19.2431 9.43898 19.5332 9.26557 19.7912C9.02941 20.1426 8.70767 20.4273 8.34968 20.6479C8.18456 20.7497 8.09134 20.8478 8.04439 20.9176C8.01926 20.955 8.00836 20.9822 8.00362 21H16.9964C16.9916 20.9822 16.9807 20.955 16.9556 20.9176C16.9087 20.8478 16.8154 20.7497 16.6503 20.6479C16.2923 20.4273 15.9706 20.1426 15.7344 19.7912ZM17.9997 21C17.9999 21.008 18 21.0161 18 21.0242V22H7V21.0242C7 21.0161 7.00009 21.008 7.00027 21C7.00524 20.7802 7.07786 20.563 7.21441 20.3598C7.35596 20.1492 7.56344 19.9578 7.825 19.7966C8.08656 19.6354 8.29404 19.444 8.43559 19.2334C8.57714 19.0228 8.65 18.797 8.65 18.569V17.9322H16.35V18.569C16.35 18.797 16.4229 19.0228 16.5644 19.2334C16.706 19.444 16.9134 19.6354 17.175 19.7966C17.4366 19.9578 17.644 20.1492 17.7856 20.3598C17.9221 20.563 17.9948 20.7802 17.9997 21Z"
        fill="#F59C86"
      />
    </svg>
  );
}
