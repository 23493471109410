/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  API,
  GETAPI,
  GETAPIWITHCUSTOMDOMAIN,
  POSTAPI,
  POSTAPIWITHCUSTOMDOMAIN,
} from 'api-setup/api-helper';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';
import produce from 'immer';
import projectSetting from 'settings';
import { useHistory, useParams } from 'react-router-dom';
import { useSource, usePrevious } from 'lib/custom-common-hooks';
import { GET_NOTIFICATIONS, SWAGGER_V2_BASE_URL } from 'api-setup/api-endpoints';
import { actionTypes } from 'redux/actions/types';
import { NotificationHeadRows } from 'lib/common-table-data';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import _ from 'lodash';
import { API_CALL_METHODS, customBaseURL, customBaseURL2, useApiHook } from 'api-setup/use-api-hook';

const { EVENT_INT_APP_SUCCESS } = actionTypes;

const CUSTOM_URL = projectSetting.customBaseURL;
const CUSTOM_URL_V2 = projectSetting.customBaseURL2;
const TICKETING_URL_V2 = projectSetting.customBaseURL3;
const CUSTOM_CAMPAIGN_DOMAIN = projectSetting.reportDomain;
const CUSTOM_DOMAIN_EMAILBUILDER = projectSetting.customBaseURL5;
const useTable = (
  PAGINATE_URL,
  Helper,
  defaultHeader = {},
  defaultValues = null,
  shouldExecute = true,
  defaultFilterData = {},
  methodType,
  defaultBody = {}
) => {
  const [rows, setRows] = React.useState([]);
  const [filterData, setFilterData] = React.useState({ ...defaultFilterData });
  const [selected, setSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [header, setHeader] = useState(defaultHeader);
  const [isShowMeetingLocation, setShowMeetingLocation] = React.useState(0);
  const [isLinkedNotification, setIsLinkedNotification] = React.useState(0);

  const [sortByObj, setSortByObj] = React.useState({});
  // const [tempSearch,setTempSearch] = React.useState('');
  const [hasNoRow, setHasNoRow] = React.useState(false);
  const prevSearchText = usePrevious(searchText);
  const [excludedIds, setExcludedIds] = useState([]);
  const [excludedCount, setExcludedCount] = useState(0);
  const params = useParams();

  const [isExecuted, setExecute] = React.useState(false);
  const [restData, setRestData] = React.useState({});
  const [totalItems, setTotalItems] = React.useState(0);

  const [options, setOptions] = React.useState({
    order: 'DESC',
    orderBy: '',
    page: 1,
    rowsPerPage: defaultValues?.rowsPerPage || 5,
  });
  const prevPage = usePrevious(options.page);

  const [apiPromise] = useApiHook();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const source = useSource();

  React.useEffect(() => {
    source.AS = new Map();

    return () => API.removeAllApi(source.AS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manageOption = React.useCallback(
    (keyName) => (data) => {
      if (!Helper?.isOrdersPage) setSelectAll(false);
      setSelected([]);
      setOptions({ ...options, [keyName]: data });
    },
    [options]
  );
  const manageOptionMultiple = React.useCallback(
    (data) => {
      setOptions({ ...options, ...data });
    },
    [options]
  );

  const [loadingState, setLoadingState] = React.useState({
    isInitialLoading: true,
    isPaginateLoading: false,
    isFilterApplied: false,
  });

  const reducer = (ACTION, data) => {
    switch (ACTION) {
      case 'FIRST-PAGE': {
        manageOption('page')(1);
        break;
      }
      case 'RELOAD-PAGE': {
        getData();
        break;
      }
      case 'PREVIOUS-PAGE': {
        if (options.page !== 1) manageOption('page')(options.page - 1);
        break;
      }
      case 'DELETE-ROW': {
        let pageNum = options.page;
        if (rows.length === 1 && options.page > 1) {
          pageNum -= 1;
          manageOption('page')(pageNum);
        } // is last page
        else if (options.page === totalPages) {
          let newRows = [];
          if (typeof data === 'object' && Array.isArray(data)) {
            newRows = rows.filter((eachRow) => !data.includes(eachRow.id));
          } else {
            const index = rows.findIndex((e) => e.id === data);
            newRows = produce(rows, (draftState) => {
              draftState.splice(index, 1);
            });
          }
          setRows(newRows);
          // when page 1 , not filter and no row in table then show default value
          if (
            (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
            searchText === '' &&
            options.page === 1 &&
            newRows.length === 0
          )
            setHasNoRow(true);
        } else {
          getData();
        }
        setTotalPages(Math.ceil((count - 1) / options.rowsPerPage));
        // console.log("count before delete",count);
        // console.log("data.length:-",data)
        setCount(count - data.length);
        setTotalItems(count - data.length); // TODO : On deleting clearing filter data
        break;
      }
      case 'UPDATE-ROWS': {
        setRows(data);
        break;
      }
      default:
        break;
    }
  };

  const getData = () => {
    if (!loadingState.isPaginateLoading && !loadingState.isInitialLoading)
      setLoadingState({ ...loadingState, isPaginateLoading: true });
    setHeader(defaultHeader);
    const headerData = {
      page: options.page,
      limit: options.rowsPerPage,
      search: searchText,
      ...defaultHeader,
    };
    if (options.orderBy) {
      setSortByObj({});
      if (Helper.clearSortByFilter) Helper.clearSortByFilter();
      headerData.sort = options.orderBy;
      headerData.order = options.order.toUpperCase();
    } else if (!isEmpty(sortByObj)) {
      headerData.sort = sortByObj.name;
      headerData.order = sortByObj.orderBy.toUpperCase();
    }

    if (options.order) {
      headerData.order = options.order.toUpperCase();
    }

    const bodyData = { data: { filter: filterData, ...defaultBody } };
    const [headers, body] = API.generateHeader(reduxData, headerData, bodyData);
    if (Helper.isCustomDomainV2) {
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, CUSTOM_URL_V2)
        .then((resp) => {
          const { status, data, total_page, total_count, columnNames = {} } = resp.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (resp.status === API.apiSuccessStatus || status === API.apiSuccessStatus) {
            let newRows = [];
            if (data.list) {
              dispatch({ type: EVENT_INT_APP_SUCCESS, payload: data }); // TODO : Event Embed APP LIST ONLY
              newRows = Helper.createData(data.list, columnNames);
            } else {
              newRows = Helper.createData(data, columnNames);
            }

            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({ ...loadingState, isInitialLoading: false, isPaginateLoading: false });
        });
    } else if (Helper.isCustomDomain) {
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, CUSTOM_URL)
        .then((resp) => {
          const { status, data, total_page, total_count, columnNames } = resp.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (status === API.apiSuccessStatus) {
            const newRows = Helper.createData(data, columnNames);
            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }
          } else API.errStatusHandler(resp, history, dispatch);

          setRestData(resp?.data); // this state helps you to get complete response and use it the way you want in the component the hook is called
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({ ...loadingState, isInitialLoading: false, isPaginateLoading: false });
        });
    } else if (Helper.isCustomDomainV5) {
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, CUSTOM_CAMPAIGN_DOMAIN)
        .then((resp) => {
          const status = resp?.data?.success?.code;
          const { data, total_page, total_count } = resp.data?.success?.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (status === API.apiSuccessStatus) {
            const newRows = Helper.createData(data);
            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({ ...loadingState, isInitialLoading: false, isPaginateLoading: false });
        });
    } else if (Helper.isSwagger) {
      if (headers.search === '') {
        delete headers.search;
      }
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, SWAGGER_V2_BASE_URL)
        .then((resp) => {
          const { data, total_page, total_count, columnNames } = resp.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (data.length >= 0) {
            const newRows = Helper.createData(data, columnNames);
            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper.ticketingV2) {
      if (headers.search === '') {
        delete headers.search;
      }
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, TICKETING_URL_V2)
        .then((resp) => {
          const { data, total_page, total_count, columnNames } = resp.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (data.length >= 0) {
            const newRows = Helper.createData(data, columnNames);
            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }

            if (selectAll && Helper?.isOrdersPage) {
              let orderIds = [];
              if (excludedIds?.length) {
                for (let index = 0; index < data.length; index++) {
                  const item = data[index];
                  if (excludedIds.findIndex((ele) => ele === item.id) === -1)
                    orderIds.push(item?.id);
                }
              } else {
                orderIds = data?.map((item) => item.id);
              }
              setSelected([...selected, ...orderIds]);
            }

            setRestData(resp?.data);
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isNewQuiz) {
      let quizURL = PAGINATE_URL;

      if (searchText !== '') {
        quizURL = `${quizURL}?search=${searchText}`;
      }
      if (
        Object.keys(filterData).length > 0 &&
        ((filterData?.quizType && filterData?.quizType !== '') ||
          (filterData?.status && filterData?.status !== ''))
      ) {
        quizURL = `${quizURL}${quizURL.includes('?') ? '&' : '?'}${
          filterData?.quizType !== ''
            ? `type=${filterData?.quizType}${filterData?.status !== '' ? '&' : ''}`
            : ''
        }${filterData?.status !== '' ? `visibility=${filterData?.status}` : ''}`;
      }
      if (options?.rowsPerPage) {
        quizURL = `${quizURL}${quizURL.includes('?') ? '&' : '?'}${`page=${
          options.page ? options.page : 1
        }&limit=${options?.rowsPerPage}`}`;
      }
      if (params?.quizId) {
        quizURL += `${quizURL.includes('?') ? '&' : '?'}quizId=${params?.quizId}`;
      }
      if (Object.keys(filterData).length > 0 && filterData?.sessionId?.length > 0) {
        quizURL += `${quizURL.includes('?') ? '&' : '?'}sessionId=[${filterData?.sessionId}]`;
      }

      GETAPIWITHCUSTOMDOMAIN(false, quizURL, source.AS, headers, {}, CUSTOM_URL_V2)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const { data, total_count, total_page } = resp?.data;
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if (
              (Array.isArray(data) && data?.length >= 0) ||
              (typeof data === 'object' && data?.answerDetails?.length >= 0)
            ) {
              const newRows = Helper.createData(data);
              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }

              setRestData(resp?.data);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isAddOnHistory) {
      let addOnURL = PAGINATE_URL;
      if (options?.rowsPerPage) {
        addOnURL = `${addOnURL}${addOnURL.includes('?') ? '&' : '?'}${`page=${
          options.page ? options.page : 1
        }&limit=${options?.rowsPerPage}&version=2`}`;
      }

      GETAPIWITHCUSTOMDOMAIN(false, addOnURL, source.AS, headers, {}, CUSTOM_URL_V2)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const data = resp?.data?.data?.packItems;
            const total_count = resp?.data?.data?.totalPacks;
            const total_page = Math.ceil(Number(resp?.data?.data?.totalPacks) / 5);
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if ((Array.isArray(data) && data?.length >= 0) || typeof data === 'object') {
              const newRows = Helper.createData(data);
              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }

              setRestData(resp?.data);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isSupportHistoryUsage) {
      let addOnURL = PAGINATE_URL;
      if (options?.rowsPerPage) {
        addOnURL = `${addOnURL}${addOnURL.includes('?') ? '&' : '?'}${`page=${
          options.page ? options.page : 1
        }&limit=${options?.rowsPerPage}`}`;
      }

      GETAPIWITHCUSTOMDOMAIN(false, addOnURL, source.AS, headers, {}, CUSTOM_URL_V2)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const data = resp?.data?.data?.supportHours;
            const total_count = resp?.data?.data?.total;
            const total_page = Math.ceil(Number(resp?.data?.data?.total) / 5);
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if ((Array.isArray(data) && data?.length >= 0) || typeof data === 'object') {
              const newRows = Helper.createData(data);
              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }

              setRestData(resp?.data);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isAddOnConsumed) {
      let addOnURL = PAGINATE_URL;
      if (options?.rowsPerPage) {
        addOnURL = `${addOnURL}${addOnURL.includes('?') ? '&' : '?'}${`page=${
          options.page ? options.page : 1
        }&limit=${options?.rowsPerPage}`}`;
      }

      GETAPIWITHCUSTOMDOMAIN(false, addOnURL, source.AS, headers, {}, CUSTOM_URL_V2)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const data = resp?.data?.data;
            const total_count = resp?.data?.data?.total;
            const total_page = Math.ceil(Number(resp?.data?.data?.total) / 5);
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if (
              (Array.isArray(data?.list) && data?.list?.length >= 0) ||
              typeof data === 'object'
            ) {
              const newRows = Helper.createData(data?.list);
              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }

              setRestData(resp?.data);
            }
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isConversionPrompt) {
      GETAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, {}, CUSTOM_URL_V2)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const { data, total_count, total_page } = resp?.data;
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if (Array.isArray(data) && data?.length >= 0) {
              const newRows = Helper.createData(data);
              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }

              setRestData(resp?.data);
            }
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper?.isRoi) {
      POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, Helper?.body, customBaseURL)
        .then((resp) => {
          if (resp?.status === API.apiSuccessStatus) {
            const { data, total_count, total_page } = resp?.data;
            if (Helper.getDataWithTable) {
              Helper.getDataWithTable(resp, options.page);
            }
            if (Array.isArray(data) && data?.length >= 0) {
              let newRows;
              if (Helper.hasIndex) {
                newRows = Helper.createData(data, options.page, options.rowsPerPage);
              } else {
                newRows = Helper.createData(data);
              }

              setRows(newRows);
              if (options.page === 1) {
                setCount(total_count);
                setTotalPages(total_page);
                if (
                  (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                  searchText === '' &&
                  options.page === 1 &&
                  newRows.length === 0
                )
                  setHasNoRow(true);
                else if (hasNoRow) setHasNoRow(false);
              }
              setRestData(resp?.data);
            }
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else if (Helper.customDomainEmailbuilder) {
      if (headers.search === '') {
        delete headers.search;
      }
      POSTAPIWITHCUSTOMDOMAIN(
        false,
        PAGINATE_URL,
        source.AS,
        headers,
        body,
        CUSTOM_DOMAIN_EMAILBUILDER
      )
        .then((resp) => {
          const { data, total_page, total_count, columnNames } = resp.data;
          if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
          if (data.length >= 0) {
            const newRows = Helper.createData(data, columnNames);
            setRows(newRows);
            if (options.page === 1) {
              setCount(total_count);
              setTotalPages(total_page);
              if (
                (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
                searchText === '' &&
                options.page === 1 &&
                newRows.length === 0
              )
                setHasNoRow(true);
              else if (hasNoRow) setHasNoRow(false);
            }

            setRestData(resp?.data);
          } else API.errStatusHandler(resp, history, dispatch);
        })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({
              ...loadingState,
              isInitialLoading: false,
              isPaginateLoading: false,
            });
        });
    } else {
      const APIPromise =
        methodType === 'CUSTOMPOST'
          ? POSTAPIWITHCUSTOMDOMAIN(false, PAGINATE_URL, source.AS, headers, body, CUSTOM_URL)
          : POSTAPI(false, PAGINATE_URL, source.AS, headers, body);
      APIPromise.then((resp) => {
        const { status, data, total_page, totalCount, total_count, columnNames } = resp.data;
        if (Helper.getDataWithTable) Helper.getDataWithTable(resp, options.page);
        if (status === API.apiSuccessStatus) {
          if (PAGINATE_URL === GET_NOTIFICATIONS) {
            setIsLinkedNotification(resp.data.is_linked_notification);
            if (resp.data.is_linked_notification === 0) {
              const linkHeadCol = {
                id: 'link',
                numeric: false,
                disablePadding: false,
                label: 'URL',
                hasSort: false,
                valueKey: 'link',
              };
              if (isEqual(NotificationHeadRows[2], linkHeadCol)) NotificationHeadRows.splice(2, 1);
            }
          }
          setShowMeetingLocation(resp.data.isShowMeetingLocation);
          setTotalItems(resp.data.total_items || 0); // TODO : New Key added for total_item without filter / with filter
          const newRows = Helper.createData(data, columnNames);
          setRows(newRows);
          if (options.page === 1) {
            setCount(totalCount || total_count);
            setTotalPages(total_page);
            if (
              (Helper.checkIsFiltered ? !Helper.checkIsFiltered(filterData) : filterData) &&
              searchText === '' &&
              options.page === 1 &&
              newRows.length === 0
            )
              setHasNoRow(true);
            else if (hasNoRow) setHasNoRow(false);
          }
        } else API.errStatusHandler(resp, history, dispatch);
      })
        .catch(API.catchHandler)
        .then((isCancel) => {
          if (!isCancel)
            setLoadingState({ ...loadingState, isInitialLoading: false, isPaginateLoading: false });
        });
    }
  };

  React.useEffect(() => {
    if (!loadingState.isInitialLoading) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  React.useEffect(() => {
    if (!source.AS) source.AS = new Map();
    if (prevSearchText && searchText === '') {
      manageOption('page')(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  React.useEffect(() => {
    manageOption('page')(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  React.useEffect(() => {
    if (!isExecuted && shouldExecute) {
      setExecute(true);
      if (!source.AS) source.AS = new Map();
      if (!defaultValues) getData();
      else {
        setOptions({ ...options, ...defaultValues });
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldExecute, isExecuted]);
  React.useEffect(() => {
    if (prevPage !== options.page) {
      if (!Helper?.isOrdersPage) {
        if (selected.length) setSelected([]);
        if (selectAll) setSelectAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.page, prevPage]);

  return [
    {
      loadingState,
      tableList: rows,
      filterData,
      hasNoRow,
      searchText,
      header,
      getData,
      restData,
      //  isShowMeetingLocation,
      isLinkedNotification,
    },
    { setFilterData, reducer, setLoadingState, setSearchText, setSortByObj },
    {
      selected,
      setSelected,
      selectAll,
      setSelectAll,
      count,
      setCount,
      ...options,
      setOrder: manageOption('order'),
      setOrderBy: manageOption('orderBy'),
      setPage: manageOption('page'),
      setRowsPerPage: manageOption('rowsPerPage'),
      manageOptionMultiple,
      setTableList: setRows,
      totalItems,
      excludedIds,
      setExcludedIds,
      excludedCount,
      setExcludedCount,
    },
  ];
};

export default useTable;
