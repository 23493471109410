import 'core-js/es/set';
import 'core-js/es/map';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { enableMapSet } from 'immer';
import { BrowserRouter } from 'react-router-dom';

import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react'

// redux
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { initPosthog } from 'lib/posthog/usePosthogClient';


initPosthog();
enableMapSet();
const store = configureStore();

ReactDOM.render(
  <PostHogProvider client={posthog}>
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  </PostHogProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
