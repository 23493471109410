export const REG_FLOW_ACTIONS = {
  IS_SHOW_WORKFLOW_MODAL_UPDATE: 'IS_SHOW_WORKFLOW_MODAL_UPDATE',
  IS_SHOW_WORKFLOW_MODAL: 'IS_SHOW_WORKFLOW_MODAL',
  IS_WORKFLOW_STATUS_LOADING: 'IS_WORKFLOW_STATUS_LOADING',
  WORKFLOW_STATUS: 'WORKFLOW_STATUS',
  GET_WORKFLOW_DETAIL_LOADING: 'GET_WORKFLOW_DETAIL_LOADING',
  GET_WORKFLOW_DETAIL_CURRENT: 'SET_WORKFLOW_DETAIL_CURRENT',
  GET_WORKFLOW_DETAIL: 'GET_WORKFLOW_DETAIL_LOCAL',
  UPDATE_CURRENT_WORKFLOW_LOADING: 'UPDATE_CURRENT_WORKFLOW_LOADING',
  GET_STEP_DETAIL_LOADING: 'GET_STEP_DETAIL_LOADING',
  GET_STEP_DETAIL: 'GET_STEP_DETAIL',
  UPDATE_STEP_DETAILS: 'UPDATE_STEP_DETAILS',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_MARK_COMPLETED_LOADING: 'SET_MARK_COMPLETED_LOADING',
  SET_WORKFLOW_DETAIL_OTHER: 'SET_WORKFLOW_DETAIL_OTHER',
  RESET: 'RESET',
  SET_LOGGED_IN_STATUS_LOADING: 'SET_LOGGED_IN_STATUS_LOADING',
  SET_AMPLITUDE_DATA: 'SET_AMPLITUDE_DATA',
  RESET_AMPLITUDE_DATA: 'RESET_AMPLITUDE_DATA',
  RESET_WORKFLOW_STATUS: 'RESET_WORKFLOW_STATUS'
};
