import React from 'react';
import { useTheme } from '@material-ui/core';

const style = {
  fill: `url(#svg-mail-grad)`,
};

export default function SvgMailIcon(props) {
  const theme = useTheme();
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      {...props}
      viewBox="0 0 50 50">
      <linearGradient id="svg-mail-grad" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor={theme.palette.primary.lighter} />
        <stop offset="0.577" stopColor={theme.palette.primary.main} />
        <stop offset="1" stopColor={theme.palette.primary.dark} />
      </linearGradient>
      <g transform="translate(-0.999 -1)">
        <path
          style={style}
          d="M3124,16721a3,3,0,0,1-3-3v-26a1,1,0,0,1,.323-.73c.147-.139,5.938-4.961,7.679-6.408V16672a1,1,0,0,1,1-1h32a1,1,0,0,1,1,1v12.861l7.637,6.369a.991.991,0,0,1,.361.77v26a3,3,0,0,1-3,3Zm-1-3a1,1,0,0,0,1,1h44a1,1,0,0,0,1-1v-24.381l-22.552,11.271a1,1,0,0,1-.9,0L3123,16693.619Zm8-22.619,15,7.5,15-7.5V16673h-30Zm32-1,5.191-2.594-5.191-4.318Zm-39.19-2.594,5.191,2.594v-6.912ZM3126,16717a1,1,0,0,1,0-2h15a1,1,0,1,1,0,2Zm0-4a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Zm12-20a1,1,0,0,1,0-2h16a1,1,0,1,1,0,2Zm0-8a1,1,0,0,1,0-2h16a1,1,0,1,1,0,2Z"
          transform="translate(-3120 -16670)"
        />
      </g>
    </svg>
  );
}
