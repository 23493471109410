import React from 'react';

export default function SvgInfoCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.99902C0 4.48002 4.48 -0.000976562 10 -0.000976562C15.53 -0.000976562 20 4.48002 20 9.99902C20 15.52 15.53 19.999 10 19.999C4.48 19.999 0 15.52 0 9.99902ZM9.12 6.20902C9.12 5.73002 9.52 5.32902 10 5.32902C10.48 5.32902 10.87 5.73002 10.87 6.20902V10.629C10.87 11.11 10.48 11.499 10 11.499C9.52 11.499 9.12 11.11 9.12 10.629V6.20902ZM10.01 14.68C9.52 14.68 9.13 14.28 9.13 13.8C9.13 13.32 9.52 12.93 10 12.93C10.49 12.93 10.88 13.32 10.88 13.8C10.88 14.28 10.49 14.68 10.01 14.68Z"
        fill="white"
      />
    </svg>
  );
}
