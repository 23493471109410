/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import { Trash2 } from 'react-feather';
import SelectDropDown from 'custom-components/form-fields/selectDropdown';
import InputField from 'custom-components/form-fields/inputField';
import Sidebar from 'custom-components/sidebar';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useSource } from 'lib/custom-common-hooks';
import { useHistory } from 'react-router-dom';
import CustomToolTip from 'custom-components/customToolTip';

import {
  commonSelector,
  baseEventLanguagesSelector,
  eventLanguagesSelector,
  eventDataSelector,
} from 'redux/custom-selector';
import { ProfileFieldSchema } from 'models/schema';
import Validator from 'models/validation';
import {
  GET_USER_PROFILE_FIELDS,
  ADD_USER_PROFILE_FIELDS,
  EVENT_GROUP_SETTING,
  GET_DISCOUNT_TICKETS,
  TICKET_GROUP_LIST,
} from 'api-setup/api-endpoints';
import {
  PUTAPI,
  API,
  POSTAPI,
  GETAPI,
  GETAPIWITHCUSTOMDOMAIN,
  POSTAPIWITHCUSTOMDOMAIN,
  API_CALL_METHODS,
  API_CALL_RESP_CODES,
} from 'api-setup/api-helper';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import CheckBox2 from 'custom-components/form-fields/checkbox2';
import { numberRegex } from 'models/regex';
import SwitchField from 'custom-components/form-fields/switch';
import OptionList from 'custom-components/form-fields/optionsList';
import { HUB_CONST } from 'constants/common';
import SelectCheckBoxDropDown from 'custom-components/form-fields/selectCheckBoxDropDown';
import projectSetting from 'settings';
import isEmpty from 'lodash/isEmpty';
import { PAGE_LIMITS } from 'pages/registration/discount/discountConstant';
import { addProfileFieldsObj } from 'lib/amplitude-util';
import { Plus } from 'react-feather';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';

const useStyles = makeStyles((theme) => ({
  fieldTipBox: {
    marginLeft: '-10px',
    marginTop: '2px',
  },
  subFieldBlock: {
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
    position: 'relative',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  trimWidth: {
    '& .MuiFormControl-fullWidth': {
      width: `auto`,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.bg.whiteOff1,
    padding: theme.spacing(1.25),
    borderRadius: '50%',
    position: 'absolute',
    right: '-10px',
    top: '-20px',

    color: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
  },
}));
const initialFields = {
  fieldName: '',
  fieldType: '',
};

// const initialChildFields = {
//   id: 1,
//   fieldName: '',
//   fieldType: '',
//   fieldOption: '',
// }

const handelKeyPressOnNumberText = (evt) => {
  if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
    evt.preventDefault();
  }
  if (evt.target.value.length >= 3) {
    evt.preventDefault();
  }
};

const handelKeyPressOnNumberTextArea = (evt) => {
  if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
    evt.preventDefault();
  }
  if (evt.target.value.length >= 4) {
    evt.preventDefault();
  }
};

const getRandomId = () => {
  return parseInt(Math.random() * 999 + 1);
};

function SidebarLoader() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SkeletonLoaders variant="text" height="19px" width="100px" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders variant="text" height="19px" width="100px" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
    </Grid>
  );
}

const groupInitDetails = {
  apicallDone: false,
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const ticketsInitDetails = {
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const attendeeInitDetails = {
  apicallDone: false,
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const ProfileFieldsSidebarNewUI = ({
  isOpen,
  title,
  onClose,
  onSaveClose,
  profileFieldList = [],
  isTicketAllow = false,
  ...props
}) => {
  const { groupList = [], eventTicketList = [] } = props;
  const CUSTOM_URL = projectSetting.baseURL;
  const CUSTOM_URL_TICKETS = projectSetting.customBaseURL3;
  const classes = useStyles();
  const dispatch = useDispatch();
  const source = useSource();
  const history = useHistory();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const [headers] = API.generateHeader(reduxData, null);
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const baseEventLanguage = useSelector(baseEventLanguagesSelector, shallowEqual);
  const [loadingState, setLoadingState] = React.useState({
    addFieldsLoader: false,
    initLoad: true,
  });
  const [errObj, setErrObj] = useState('');
  const [formFields, setFormFields] = useState({ ...initialFields });
  const [childFormFields, setChildFormFields] = useState([
    {
      id: getRandomId(),
      fieldName: '',
      fieldType: '',
      value: '',
      checkBoxChildOptions: [
        { value: '', id: 1, isSelected: false },
        { value: '', id: 2, isSelected: false },
      ],
      radioChildOptions: [
        { value: '', id: 1, isSelected: false },
        { value: '', id: 2, isSelected: false },
      ],
      dropdownChildOptions: [
        { value: '', id: 1, isSelected: false },
        { value: '', id: 2, isSelected: false },
      ],
      groupChildOptions: [],
      // textChild: '',
      // textAreaChild: '',
    },
  ]);
  const [sectionList, setSectionList] = useState({ ...groupInitDetails });
  const [ticketsList, setTicketsList] = useState({ ...ticketsInitDetails });
  const [attendeeList, setAttendeeList] = useState({ ...attendeeInitDetails });
  const [completeTicketList, setCompleteTicketList] = useState([]);
  let setGroupIds = [];
  const [ticketList, setTicketList] = useState('');
  const [ticketGroupList, setTicketGroupList] = useState('');
  const [attendeeGroupList, setAttendeeGroupList] = useState('');
  const [fieldTypeList, setFieldTypeList] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [checkBoxOptions, setCheckBoxOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [radioOptions, setRadioOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [dropdownChildOptions, setDropdownChildOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [checkBoxChildOptions, setCheckBoxChildOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [radioChildOptions, setRadioChildOptions] = useState([
    { value: '', id: 1, isSelected: false },
    { value: '', id: 2, isSelected: false },
  ]);
  const [groupChildOptions, setGroupChildOptions] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const isWebinarEvent = eventData.EventData && eventData.EventData.type === EEventFormat.WEBINAR;
  const isWebinar =
    eventData.EventData &&
    eventData.EventData.type === EEventFormat.WEBINAR &&
    history.location.pathname.includes('setup-form');
  const [subFieldCheck, setSubFieldCheck] = useState(false);
  const [selectedChild, setSelectedChild] = useState({});
  const [childValueOption, setChildValueOption] = useState({});
  const [otherOption, setOtherOption] = useState(false);
  const [subFieldTypeID, setSubFieldTypeID] = useState(-1);
  const [renderChild, setRenderChild] = useState(false);
  const APICALL = React.useCallback(
    (ACTION, reduxData, methodType, URL, headerData, bodyData, APICB) => {
      const [header, body] = API.generateHeader(reduxData, headerData, bodyData);
      let APIPromise;
      if (methodType === API_CALL_METHODS.GET) {
        APIPromise = GETAPI(false, URL, source.AS, header);
      } else if (methodType === API_CALL_METHODS.POST) {
        APIPromise = POSTAPI(false, URL, source.AS, header, body);
      } else if (methodType === API_CALL_METHODS.PUT) {
        APIPromise = PUTAPI(false, URL, source.AS, header, body);
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(
              setNewMessage({
                message: resp.data.message,
                type: 'success',
                show: true,
              })
            );
          APICB && APICB(ACTION, 'SUCCESS', resp.data, headerData);
        } else {
          setLoadingState({
            ...loadingState,
            addFieldsLoader: false,
            initLoad: false,
          });
          API.errStatusHandler(resp, history, dispatch, () => {
            APICB && APICB(ACTION, 'ERROR', resp.data);
          });
        }
      }).catch(API.catchHandler);
    },
    [dispatch, history, loadingState, source.AS]
  );

  const onClearFields = () => {
    setFormFields({ ...initialFields });
    setChildFormFields([
      {
        id: getRandomId(),
        fieldName: '',
        fieldType: '',
        value: '',
        checkBoxChildOptions: [
          { value: '', id: 1, isSelected: false },
          { value: '', id: 2, isSelected: false },
        ],
        radioChildOptions: [
          { value: '', id: 1, isSelected: false },
          { value: '', id: 2, isSelected: false },
        ],
        dropdownChildOptions: [
          { value: '', id: 1, isSelected: false },
          { value: '', id: 2, isSelected: false },
        ],
        groupChildOptions: [],
      },
    ]);
    setAttendeeGroupList('');
    setTicketGroupList('');
    setTicketList('');
    setErrObj({});
    setFileTypes([]);
    setDropdownOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setRadioOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setCheckBoxOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setDropdownChildOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setRadioChildOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setCheckBoxChildOptions([
      { value: '', id: 1, isSelected: false },
      { value: '', id: 2, isSelected: false },
    ]);
    setSubFieldCheck(false);
    setSelectedChild({});
    setGroupOptions([]);
    setFileTypes([]);
  };

  const APICB = React.useCallback((ACTION, type, data) => {
    switch (ACTION) {
      case 'GET_USER_PROFILE_FIELDS': {
        if (type === 'SUCCESS') {
          setLoadingState({ ...loadingState, initLoad: false });
          let updFieldTypes = data.data.fieldTypes;
          setCompleteTicketList(data?.data?.ticketList || []);
          setAttendeeGroupList('ALL');
          updFieldTypes = updFieldTypes.map((eachFieldType) => {
            const updDefaultValues = eachFieldType.defaultValues;
            return {
              ...eachFieldType,
              defaultValues: {
                ...updDefaultValues,
              },
            };
          });
          setFieldTypeList(updFieldTypes);
        }
        break;
      }
      case 'ADD_USER_PROFILE_FIELDS': {
        if (type === 'SUCCESS') {
          setLoadingState({ ...loadingState, addFieldsLoader: false });
          onClearFields();
          onSaveClose();
        }
        break;
      }
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (isOpen && loadingState.initLoad) {
      APICALL(
        'GET_USER_PROFILE_FIELDS',
        reduxData,
        'GET',
        GET_USER_PROFILE_FIELDS,
        null,
        null,
        APICB
      );
    }
  }, [isOpen, APICALL, APICB, reduxData, loadingState]);

  const onCloseSidebar = () => {
    if (isWebinar && (formFields.fieldName || formFields.fieldType.name)) {
      commonTrackEvent('cancel custom field', {
        'org id': reduxData.organiserId,
        'field name': formFields.fieldName,
        'field type': formFields.fieldType.name,
        mandatory: formFields?.fieldType?.defaultValues?.isRequiredInTicketing
          ? formFields?.fieldType?.defaultValues?.isRequiredInTicketing
          : HUB_CONST.NO,
      });
    }
    onClose();
    onClearFields();
    setLoadingState({ addFieldsLoader: false, initLoad: true });
  };

  const handleValidations = useCallback(() => {
    // const REGEX_VULNERABILITY_CHECK = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let errObject = '';
    const { fieldName, fieldType } = formFields;
    errObject = Validator.validate(ProfileFieldSchema, { fieldName, fieldType });

    // Field Name Duplication Validation
    let isDuplicate = false;
    if (fieldName && profileFieldList.length) {
      const name = fieldName.replace(/ /g, '').toLowerCase();
      isDuplicate = profileFieldList
        .map((item) => item.fieldName.replace(/ /g, '').toLowerCase())
        .includes(name);
      if (isDuplicate) {
        errObject = { ...errObj, fieldName: 'Parent Field name is already exists' };
      }
    }

    // Field Name Duplication Validation END

    if (!errObject) errObject = {};
    let valid = true;
    switch (formFields.fieldType.name) {
      case 'Text':
        if (Object.keys(formFields.fieldType.defaultValues.properties).length > 0) {
          if (
            formFields.fieldType.defaultValues.properties.length.min === '' ||
            formFields.fieldType.defaultValues.properties.length.max === ''
          ) {
            if (formFields.fieldType.defaultValues.properties.length.min === '') {
              errObject.min = 'Min length is required';
            }
            if (formFields.fieldType.defaultValues.properties.length.max === '') {
              errObject.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(formFields.fieldType.defaultValues.properties.length.min) >
              parseInt(formFields.fieldType.defaultValues.properties.length.max)
            ) {
              errObject.min = 'Invalid length limits.';
            }
          }
        }
        if (subFieldCheck) {
          childFormFields.map((subField) => {
            if (Object.keys(subField.fieldType.defaultValues.properties).length > 0) {
              if (
                subField.fieldType.defaultValues.properties.length.min === '' ||
                subField.fieldType.defaultValues.properties.length.max === ''
              ) {
                if (subField.fieldType.defaultValues.properties.length.min === '') {
                  errObject.min = 'Min length is required';
                }
                if (subField.fieldType.defaultValues.properties.length.max === '') {
                  errObject.max = 'Max length is required';
                }
              } else {
                if (
                  parseInt(subField.fieldType.defaultValues.properties.length.min) >
                  parseInt(subField.fieldType.defaultValues.properties.length.max)
                ) {
                  errObject.min = 'Invalid length limits.';
                }
              }
            }
          });
        }
        break;
      case 'Text Area':
        if (Object.keys(formFields.fieldType.defaultValues.properties).length > 0) {
          if (
            formFields.fieldType.defaultValues.properties.length.min === '' ||
            formFields.fieldType.defaultValues.properties.length.max === ''
          ) {
            if (formFields.fieldType.defaultValues.properties.length.min === '') {
              errObject.min = 'Min length is required';
            }
            if (formFields.fieldType.defaultValues.properties.length.max === '') {
              errObject.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(formFields.fieldType.defaultValues.properties.length.min) >
              parseInt(formFields.fieldType.defaultValues.properties.length.max)
            ) {
              errObject.min = 'Invalid length limits.';
            } else {
              if (parseInt(formFields.fieldType.defaultValues.properties.length.min) > 4000) {
                errObject.min = "Limit can't be greater than 4000";
              }
              if (parseInt(formFields.fieldType.defaultValues.properties.length.max) > 4000) {
                errObject.max = "Limit can't be greater than 4000";
              }
            }
          }
        }
        break;
      case 'Dropdown':
        const lookup = dropdownOptions.reduce((item, e) => {
          item[e?.value] = ++item[e?.value] || '';
          return item;
        }, {});
        const duplicates = dropdownOptions.filter((e) => lookup[e?.value]);
        dropdownOptions.map((item) => {
          if (item.value.trim() === '' || duplicates.length > 0 || item.value.trim() === 'Other') {
            if (item.value.trim() === 'Other') {
              item.error = "Please use the Toggle to show 'Other' as Option ";
              errObject.blank_text = "Option/s can't be 'Other'.";
            }
            duplicates.forEach((duplicateItem) => {
              if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                item.error = "Option/s can't be duplicate.";
                errObject.blank_text = "Option/s can't be duplicate.";
              } else if (item.value.trim() === '') {
                item.error = 'Please enter option';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            if (isEmpty(item.value)) {
              item.error = 'Option cant be empty';
              errObject.blank_text = "Option/s can't be blank.";
            }
          }
          return 0;
        });
        break;
      case 'Radio':
        const lookupR = radioOptions.reduce((item, e) => {
          item[e?.value] = ++item[e?.value] || '';
          return item;
        }, {});
        const duplicatesR = radioOptions.filter((e) => lookupR[e?.value]);
        radioOptions.map((item) => {
          if (item.value.trim() === '' || duplicatesR.length > 0 || item.value.trim() === 'Other') {
            if (item.value.trim() === 'Other') {
              item.error = "Please use the Toggle to show 'Other' as Option ";
              errObject.blank_text = "Option/s can't be 'Other'.";
            }
            duplicatesR.forEach((duplicateItem) => {
              if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                item.error = "Option/s can't be duplicate.";
                errObject.blank_text = "Option/s can't be duplicate.";
              } else if (item.value.trim() === '') {
                item.error = 'Please enter option';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            if (isEmpty(item.value)) {
              item.error = 'Option cant be empty';
              errObject.blank_text = "Option/s can't be blank.";
            }
          }
          return 0;
        });
        break;
      case 'Checkbox':
        const lookupC = checkBoxOptions.reduce((item, e) => {
          item[e?.value] = ++item[e?.value] || '';
          return item;
        }, {});
        const duplicatesC = checkBoxOptions.filter((e) => lookupC[e?.value]);
        checkBoxOptions.map((item) => {
          if (item.value.trim() === '' || duplicatesC.length > 0 || item.value.trim() === 'Other') {
            if (item.value.trim() === 'Other') {
              item.error = "Please use the Toggle to show 'Other' as Option ";
              errObject.blank_text = "Option/s can't be 'Other'.";
            }
            duplicatesC.forEach((duplicateItem) => {
              if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                item.error = "Option/s can't be duplicate.";
                errObject.blank_text = "Option/s can't be duplicate.";
              } else if (item.value.trim() === '') {
                item.error = 'Please enter option';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            if (isEmpty(item.value)) {
              item.error = 'Option cant be empty';
              errObject.blank_text = "Option/s can't be blank.";
            }
            return 0;
          }
        });
        break;
      case 'Group':
        if (Object.keys(formFields.fieldType.defaultValues.properties).length > 0) {
          if (
            formFields.fieldType.defaultValues.properties.length.min === '' ||
            formFields.fieldType.defaultValues.properties.length.max === ''
          ) {
            if (formFields.fieldType.defaultValues.properties.length.min === '') {
              errObject.min = 'Min length is required';
            }
            if (formFields.fieldType.defaultValues.properties.length.max === '') {
              errObject.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(formFields.fieldType.defaultValues.properties.length.min) >
              parseInt(formFields.fieldType.defaultValues.properties.length.max)
            ) {
              errObject.min = 'Invalid length limits.';
            }
          }
        }
        groupOptions.map((item) => {
          if (item.value.trim() === '') {
            item.error = 'Please enter field name';
            errObject.blank_text = "Field name can't be blank.";
          }
          return 0;
        });

        if (formFields.fieldType.defaultValues.properties.field_type_name === '') {
          errObject.field_type = "Field Type can't be blank.";
        }
        if (formFields.fieldType.defaultValues.properties.button_label === '') {
          errObject.button_label = "Button Label can't be blank.";
        }
        break;
      case 'File':
        if (
          formFields.fieldType.defaultValues.properties.max_file_size >
          formFields.fieldType.defaultValues.properties.allowed_max_file_size
        ) {
          errObject.file_size = `Value can't be greater than ${formFields.fieldType.defaultValues.properties.allowed_max_file_size}`;
        }
        if (formFields.fieldType.defaultValues.properties.max_file_size === '') {
          errObject.file_size = `Value can't be blank`;
        }
        break;
      default:
        break;
    }

    if (
      formFields.fieldType.defaultValues?.communityGroups?.length === 0 &&
      attendeeGroupList !== 'ALL'
    ) {
      errObject.communityGroups = 'Group is Required';
    }

    if (formFields.fieldType.defaultValues?.isUseInTicketing === HUB_CONST.YES) {
      if (
        formFields.fieldType.defaultValues?.ticketingGroups?.length === 0 &&
        ticketGroupList !== 'ALL'
      ) {
        errObject.ticketingGroups = 'Group is Required';
      }
      if (
        formFields.fieldType.defaultValues?.ticketsMappedWithGroups?.length === 0 &&
        ticketList !== 'ALL'
      ) {
        errObject.ticketsMappedWithGroups = 'Tickets mapped with groups  is required';
      }
    }

    Object.values(errObject).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return !valid ? errObject : false;
  });

  const handleChildValidations = useCallback(() => {
    // const REGEX_VULNERABILITY_CHECK = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let errObject = {};
    childFormFields.map((subField) => {
      const { fieldName, fieldType } = subField;
      // Field Name Duplication Validation
      let isDuplicate = false;
      const name = fieldName.replace(/ /g, '').toLowerCase();
      if (fieldName && profileFieldList.length) {
        // const name = fieldName.replace(/ /g, '').toLowerCase();
        isDuplicate = profileFieldList
          .map((item) => item.fieldName.replace(/ /g, '').toLowerCase())
          .includes(name);
        if (isDuplicate) {
          errObject = { ...errObj, fieldName: 'Child Field name is already exists' };
          subField.errorName = 'Child Field name is already exists.';
          errObject.blank_text = 'Child Field name is already exists.';
        }
      }
      // [SWAT-4479] remove duplicate child field validation
      // if (fieldName) {
      //   isDuplicate =
      //     childFormFields?.filter((item) => item.fieldName.replace(/ /g, '').toLowerCase() === name.toLocaleLowerCase())?.length > 1 ? true : false;
      //   if (isDuplicate) {
      //     errObject = { ...errObj, fieldName: 'Child Field name is already exists' };
      //     subField.errorName = 'Child Field name is already exists.';
      //     errObject.blank_text = 'Child Field name is already exists.';
      //   }
      // }
    });
    childFormFields.map((subField) => {
      if (subField.fieldType.length === 0) {
        subField.errorFileType = "Child Field type can't be empty.";
        errObject.blank_text = "Child Field type can't be blank.";
      }
      if (subField.fieldName.trim().length === 0) {
        subField.errorName = "Child Field name can't be empty or contain any special characters.";
        errObject.blank_text = "Child name Name can't be blank or contain any special characters.";
      }
      if (subField.value.trim().length === 0) {
        subField.errorOption = "Child Field option can't be empty.";
        errObject.blank_text = "Child Field Option can't be blank.";
      }
    });

    // Field Name Duplication Validation END

    if (!errObject) errObject = {};
    if (subFieldCheck) {
      let valid = true;
      childFormFields.map((subField) => {
        switch (subField.fieldType.name) {
          case 'Text':
            if (Object.keys(subField.fieldType.defaultValues.properties).length > 0) {
              if (
                subField.fieldType.defaultValues.properties.length.min === '' ||
                subField.fieldType.defaultValues.properties.length.max === ''
              ) {
                if (subField.fieldType.defaultValues.properties.length.min === '') {
                  subField.errorTextMin = 'Min length is required';
                  errObject.min = 'Min length is required';
                }
                if (subField.fieldType.defaultValues.properties.length.max === '') {
                  subField.errorTextMax = 'Max length is required';
                  errObject.max = 'Max length is required';
                }
              } else {
                if (
                  parseInt(subField.fieldType.defaultValues.properties.length.min) >
                  parseInt(subField.fieldType.defaultValues.properties.length.max)
                ) {
                  subField.errorTextMin = 'Invalid length limits.';
                  errObject.min = 'Invalid length limits.';
                }
              }
            }
            break;
          case 'Text Area':
            if (Object.keys(subField.fieldType.defaultValues.properties).length > 0) {
              if (
                subField.fieldType.defaultValues.properties.length.min === '' ||
                subField.fieldType.defaultValues.properties.length.max === ''
              ) {
                if (subField.fieldType.defaultValues.properties.length.min === '') {
                  subField.errorTextAreaMin = 'Min length is required';
                  errObject.min = 'Min length is required';
                }
                if (subField.fieldType.defaultValues.properties.length.max === '') {
                  subField.errorTextAreaMax = 'Max length is required.';
                  errObject.max = 'Max length is required';
                }
              } else {
                if (
                  parseInt(subField.fieldType.defaultValues.properties.length.min) >
                  parseInt(subField.fieldType.defaultValues.properties.length.max)
                ) {
                  subField.errorTextAreaMin = 'Invalid length limits.';
                  errObject.min = 'Invalid length limits.';
                } else {
                  if (parseInt(subField.fieldType.defaultValues.properties.length.min) > 4000) {
                    subField.errorTextAreaMin = "Limit can't be greater than 4000";
                    errObject.min = "Limit can't be greater than 4000";
                  }
                  if (parseInt(subField.fieldType.defaultValues.properties.length.max) > 4000) {
                    subField.errorTextAreaMax = "Limit can't be greater than 4000";
                    errObject.max = "Limit can't be greater than 4000";
                  }
                }
              }
            }
            break;
          case 'Dropdown':
            if (subField.fieldType.length === 0) {
              subField.errorFileType = "Child Field type can't be empty.";
              errObject.blank_text = "Child Field type can't be blank.";
            }
            if (subField.fieldName.trim().length === 0) {
              subField.errorName =
                "Child Field name can't be empty or contain any special characters.";
              errObject.blank_text =
                "Child name Name can't be blank or contain any special characters.";
            }
            if (subField.value.trim().length === 0) {
              subField.errorOption = "Child Field option can't be empty.";
              errObject.blank_text = "Child Field Option can't be blank.";
            }
            subField.dropdownChildOptions.map((typeField) => {
              if (typeField.value === '') {
                typeField.error = 'Option cant be empty.';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            return 0;
            break;
          case 'Radio':
            if (subField.fieldType.length === 0) {
              subField.errorFileType = "Child Field type can't be empty.";
              errObject.blank_text = "Child Field type can't be blank.";
            }
            if (subField.fieldName.trim().length === 0) {
              subField.errorName =
                "Child Field name can't be empty or contain any special characters.";
              errObject.blank_text =
                "Child name Name can't be blank or contain any special characters.";
            }
            if (subField.value.trim().length === 0) {
              subField.errorOption = "Child Field option can't be empty.";
              errObject.blank_text = "Child Field Option can't be blank.";
            }
            subField.radioChildOptions.map((typeField) => {
              if (typeField.value === '') {
                typeField.error = 'Option cant be empty.';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            return 0;
            break;
          case 'Checkbox':
            if (subField.fieldType.length === 0) {
              subField.errorFileType = "Child Field type can't be empty.";
              errObject.blank_text = "Child Field type can't be blank.";
            }
            if (subField.fieldName.trim().length === 0) {
              subField.errorName =
                "Child Field name can't be empty or contain any special characters.";
              errObject.blank_text =
                "Child name Name can't be blank or contain any special characters.";
            }
            if (subField.value.trim().length === 0) {
              subField.errorOption = "Child Field option can't be empty.";
              errObject.blank_text = "Child Field Option can't be blank.";
            }
            subField.checkBoxChildOptions.map((typeField) => {
              if (typeField.value === '') {
                typeField.error = 'Option cant be empty.';
                errObject.blank_text = "Option/s can't be blank.";
              }
            });
            return 0;
            break;
          case 'Group':
            if (Object.keys(subField.fieldType.defaultValues.properties).length > 0) {
              if (
                subField.fieldType.defaultValues.properties.length.min === '' ||
                subField.fieldType.defaultValues.properties.length.max === ''
              ) {
                if (subField.fieldType.defaultValues.properties.length.min === '') {
                  subField.errorGroupMin = 'Min length is required';
                  errObject.min = 'Min length is required';
                }
                if (subField.fieldType.defaultValues.properties.length.max === '') {
                  subField.errorGroupMax = 'Max length is required';
                  errObject.max = 'Max length is required';
                }
              } else {
                if (
                  parseInt(subField.fieldType.defaultValues.properties.length.min) >
                  parseInt(subField.fieldType.defaultValues.properties.length.max)
                ) {
                  subField.errorGroupMin = 'Invalid length limits.';
                  errObject.min = 'Invalid length limits.';
                }
              }
            }
            subField.groupChildOptions.map((item) => {
              if (item.value.trim() === '') {
                item.error = 'Please enter field name';
                errObject.blank_text = "Field name can't be blank.";
              }
              return 0;
            });

            if (subField.fieldType.defaultValues.properties.field_type_name === '') {
              subField.errorGroupFieldType = "Field Type can't be blank.";
              errObject.field_type = "Field Type can't be blank.";
            }
            if (subField.fieldType.defaultValues.properties.button_label === '') {
              subField.errorGroupButtonLabel = "Button Label can't be blank.";
              errObject.button_label = "Button Label can't be blank.";
            }
            break;
          case 'File':
            if (
              subField.fieldType.defaultValues.properties.max_file_size >
              subField.fieldType.defaultValues.properties.allowed_max_file_size
            ) {
              subField.errorFile = `Value can't be greater than ${subField.fieldType.defaultValues.properties.allowed_max_file_size}`;
              errObject.file_size = `Value can't be greater than ${subField.fieldType.defaultValues.properties.allowed_max_file_size}`;
            }
            if (subField.fieldType.defaultValues.properties.max_file_size === '') {
              subField.errorFile = `Value can't be blank`;
              errObject.file_size = `Value can't be blank`;
            }
            break;
          default:
            break;
        }
      });
      Object.values(errObject).forEach((val) => {
        val.length > 0 && (valid = false);
      });
      return !valid ? errObject : false;
    }
  });

  const childObjectFormation = () => {
    let tempChildArray = [];
    childFormFields.map((subField, index) => {
      const obj1 = JSON.parse(JSON.stringify(subField.fieldType));
      let selectedValue = [];
      obj1.defaultValues.position = index + 1;
      obj1.defaultValues.optionId = subField.value;
      // obj1.defaultValues.optionsForFrontend = ;
      if (obj1.name === 'Checkbox') {
        const tempCheckBoxOptions = [];
        subField.checkBoxChildOptions.map((item, index) => {
          const object = {};
          object.id = index + 1;
          object.name = item?.value?.trim();
          tempCheckBoxOptions.push(object);
          return 0;
        });
        obj1.defaultValues.optionsForFrontend = tempCheckBoxOptions;
        selectedValue = subField.checkBoxChildOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj1.defaultValues.selectedOptions = selectedValue.map((item) => {
            return item?.value?.trim();
          });
        }
        obj1.defaultValues.selectedOptions = [...obj1.defaultValues.selectedOptions];
      }
      if (obj1.name === 'Dropdown') {
        const tempDropdownOptions = [];
        subField.dropdownChildOptions.map((item, index) => {
          const object = {};
          object.id = index + 1;
          object.name = item.value;
          tempDropdownOptions.push(object);
          return 0;
        });
        obj1.defaultValues.optionsForFrontend = tempDropdownOptions;
        selectedValue = subField.dropdownChildOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj1.defaultValues.selectedOptions = [
            ...obj1.defaultValues.selectedOptions,
            selectedValue[0].value,
          ];
        }
      }
      if (obj1.name === 'Radio') {
        const tempRadioOptions = [];
        subField.radioChildOptions.map((item, index) => {
          const object = {};
          object.id = index + 1;
          object.name = item.value;
          tempRadioOptions.push(object);
          return 0;
        });
        obj1.defaultValues.optionsForFrontend = tempRadioOptions;
        selectedValue = subField.radioChildOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj1.defaultValues.selectedOptions = [
            ...obj1.defaultValues.selectedOptions,
            selectedValue[0].value,
          ];
        }
      }
      if (obj1.name === 'File') {
        const tempArr = [];
        fileTypes.map((item, index) => {
          const object = {};
          object.id = index + 1;
          object.name = item.label;
          object.value = item.isChecked;
          tempArr.push(object);
          return 0;
        });
        obj1.defaultValues.properties.file_types = tempArr;
      }
      if (obj1.name === 'Text' || obj1.name === 'Text Area' || obj1.name === 'Group') {
        if (obj1.defaultValues.properties.length.min !== '') {
          obj1.defaultValues.properties.length.min = +obj1.defaultValues.properties.length.min;
        }
        if (obj1.defaultValues.properties.length.max !== '') {
          obj1.defaultValues.properties.length.max = +obj1.defaultValues.properties.length.max;
        }
      }
      if (obj1.name === 'Group') {
        const tempArray = [];
        let tempArr = [];
        subField.groupChildOptions.map((item, index) => {
          const object = {};
          object.id = index + 1;
          object.fieldName = item.value;
          object.fieldType = item.drpOption.id;
          object.isRequired = item.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
          object.isDisabledField = HUB_CONST.NO;
          tempArray.push(object);
          return 0;
        });
        obj1.defaultValues.properties.last_option_id = +subField.groupChildOptions.forEach(
          (item) => {
            data = item[subField.groupChildOptions.length - 1].id;
          }
        );
        subField.groupChildOptions.map((item, index) => {
          const object = { ...item };
          object.properties.last_option_id =
            +subField.groupChildOptions[subField.groupChildOptions.length - 1].id;
          object.properties.length.min = +object.properties.length.min;
          object.properties.length.max = +object.properties.length.max;
          object.properties.fields = [tempArray[index + 1]];
          tempArr = [...tempArr, object];
        });
        subField.groupChildOptions = tempArr;
        selectedValue = subField.groupChildOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj1.defaultValues.selectedOptions = [selectedValue[0].value];
        }
      }
      obj1.defaultValues.fieldName = subField.fieldName;
      obj1.defaultValues.user_profile_field_type = subField.fieldType.id;
      delete obj1._id;

      obj1.defaultValues.isOnboardingEditingRestricted = obj1.defaultValues
        .isOnboardingEditingRestricted
        ? obj1.defaultValues.isOnboardingEditingRestricted === HUB_CONST.YES
          ? HUB_CONST.NO
          : HUB_CONST.YES
        : HUB_CONST.NO;
      obj1.defaultValues.communityGroups =
        obj1.defaultValues?.communityGroups && attendeeGroupList !== 'ALL'
          ? obj1.defaultValues?.communityGroups
          : 'All';
      obj1.defaultValues.ticketingGroups =
        obj1.defaultValues?.ticketingGroups && ticketGroupList !== 'ALL'
          ? obj1.defaultValues?.ticketingGroups
          : 'All';
      obj1.defaultValues.ticketsMappedWithGroups =
        obj1.defaultValues?.ticketsMappedWithGroups && ticketList !== 'ALL'
          ? obj1.defaultValues?.ticketsMappedWithGroups
          : 'All';

      obj1.defaultValues.isUseInTicketing = obj1?.defaultValues?.isUseInTicketing
        ? obj1?.defaultValues?.isUseInTicketing
        : HUB_CONST.NO;
      obj1.defaultValues.isRequiredInCommunity = obj1?.defaultValues?.isRequired
        ? obj1?.defaultValues?.isRequired
        : HUB_CONST.NO;
      obj1.defaultValues.isRequiredInTicketing = obj1?.defaultValues?.isRequiredInTicketing
        ? obj1?.defaultValues?.isRequiredInTicketing
        : HUB_CONST.NO;

      if (obj1.defaultValues.isUseInTicketing === HUB_CONST.NO) {
        obj1.defaultValues.isRequiredInTicketing = HUB_CONST.NO;
        obj1.defaultValues.ticketingGroups = [];
        obj1.defaultValues.ticketsMappedWithGroups = [];
      }

      if (obj1.defaultValues.isUseInOnoarding === HUB_CONST.NO) {
        obj1.defaultValues.isRequiredInCommunity = HUB_CONST.NO;
        obj1.defaultValues.isRequired = HUB_CONST.NO;
      }
      if (props.community_version === 2) {
        obj1.defaultValues.user_profile_field_icons_id = props.selIcon.id;
      }
      tempChildArray = [...tempChildArray, obj1.defaultValues];
    });
    return tempChildArray;
  };

  const handleSave = () => {
    const err = handleValidations();
    const errChild = subFieldCheck ? handleChildValidations() : '';
    if (err || errChild) {
      setErrObj(err);
      dispatch(
        setNewMessage({
          message: 'There are errors in the form. Kindly resolve to proceed',
          type: 'error',
          show: true,
        })
      );
    } else {
      let childFields = [];
      const obj = JSON.parse(JSON.stringify(formFields.fieldType));
      let selectedValue = [];
      if (obj.name === 'Checkbox') {
        const tempCheckBoxOptions = [];
        checkBoxOptions.map((item) => {
          const object = {};
          object.id = item.id;
          object.name = item?.value?.trim();
          tempCheckBoxOptions.push(object);
          return 0;
        });
        if (subFieldCheck && childFormFields?.length > 0) {
          obj.defaultValues.hasSF = true;
        }
        obj.defaultValues.optionsForFrontend = tempCheckBoxOptions;
        selectedValue = checkBoxOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj.defaultValues.selectedOptions = selectedValue.map((item) => {
            return item?.value?.trim();
          });
        }
      }
      if (obj.name === 'Dropdown') {
        const tempDropdownOptions = [];
        dropdownOptions.map((item) => {
          const object = {};
          object.id = item.id;
          object.name = item.value;
          tempDropdownOptions.push(object);
          return 0;
        });
        if (subFieldCheck && childFormFields?.length > 0) {
          obj.defaultValues.hasSF = true;
        }
        obj.defaultValues.optionsForFrontend = tempDropdownOptions;
        selectedValue = dropdownOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj.defaultValues.selectedOptions = [selectedValue[0].value];
        }
      }
      if (obj.name === 'Radio') {
        const tempRadioOptions = [];
        radioOptions.map((item) => {
          const object = {};
          object.id = item.id;
          object.name = item.value;
          tempRadioOptions.push(object);
          return 0;
        });
        if (subFieldCheck && childFormFields?.length > 0) {
          obj.defaultValues.hasSF = true;
        }
        obj.defaultValues.optionsForFrontend = tempRadioOptions;
        selectedValue = radioOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj.defaultValues.selectedOptions = [selectedValue[0].value];
        }
      }
      if (obj.name === 'File') {
        const tempArr = [];
        fileTypes.map((item) => {
          const object = {};
          object.id = item.id;
          object.name = item.label;
          object.value = item.isChecked;
          tempArr.push(object);
          return 0;
        });
        obj.defaultValues.properties.file_types = tempArr;
      }
      if (obj.name === 'Text' || obj.name === 'Text Area' || obj.name === 'Group') {
        if (obj.defaultValues.properties.length.min !== '') {
          obj.defaultValues.properties.length.min = +obj.defaultValues.properties.length.min;
        }
        if (obj.defaultValues.properties.length.max !== '') {
          obj.defaultValues.properties.length.max = +obj.defaultValues.properties.length.max;
        }
      }
      if (obj.name === 'Group') {
        const tempArray = [];
        groupOptions.map((item) => {
          const object = {};
          object.id = item.id;
          object.fieldName = item.value;
          object.fieldType = item.drpOption.id;
          object.isRequired = item.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
          object.isDisabledField = HUB_CONST.NO;
          tempArray.push(object);
          return 0;
        });
        obj.defaultValues.properties.last_option_id = +groupOptions[groupOptions.length - 1].id;
        obj.defaultValues.properties.length.min = +obj.defaultValues.properties.length.min;
        obj.defaultValues.properties.length.max = +obj.defaultValues.properties.length.max;
        obj.defaultValues.properties.fields = tempArray;
        selectedValue = groupOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          obj.defaultValues.selectedOptions = [selectedValue[0].value];
        }
      }
      obj.defaultValues.fieldName = formFields.fieldName;
      obj.defaultValues.user_profile_field_type = formFields.fieldType.id;
      delete obj._id;

      if (isWebinar) {
        commonTrackEvent('save custom field', {
          'org id': reduxData.organiserId,
          'field name': formFields.fieldName,
          'field type': formFields.fieldType.name,
          mandatory: obj?.defaultValues?.isRequiredInTicketing
            ? obj?.defaultValues?.isRequiredInTicketing
            : HUB_CONST.NO,
        });
      }

      obj.defaultValues.isOnboardingEditingRestricted = obj.defaultValues
        .isOnboardingEditingRestricted
        ? obj.defaultValues.isOnboardingEditingRestricted === HUB_CONST.YES
          ? HUB_CONST.NO
          : HUB_CONST.YES
        : HUB_CONST.NO;
      obj.defaultValues.communityGroups =
        obj.defaultValues?.communityGroups && attendeeGroupList !== 'ALL'
          ? obj.defaultValues?.communityGroups
          : 'All';
      obj.defaultValues.ticketingGroups =
        obj.defaultValues?.ticketingGroups && ticketGroupList !== 'ALL'
          ? obj.defaultValues?.ticketingGroups
          : 'All';
      obj.defaultValues.ticketsMappedWithGroups =
        obj.defaultValues?.ticketsMappedWithGroups && ticketList !== 'ALL'
          ? obj.defaultValues?.ticketsMappedWithGroups
          : 'All';

      obj.defaultValues.isUseInTicketing = obj?.defaultValues?.isUseInTicketing
        ? obj?.defaultValues?.isUseInTicketing
        : HUB_CONST.NO;
      obj.defaultValues.isRequiredInCommunity = obj?.defaultValues?.isRequired
        ? obj?.defaultValues?.isRequired
        : HUB_CONST.NO;
      obj.defaultValues.isRequiredInTicketing = obj?.defaultValues?.isRequiredInTicketing
        ? obj?.defaultValues?.isRequiredInTicketing
        : HUB_CONST.NO;

      if (obj.defaultValues.isUseInTicketing === HUB_CONST.NO) {
        obj.defaultValues.isRequiredInTicketing = HUB_CONST.NO;
        obj.defaultValues.ticketingGroups = [];
        obj.defaultValues.ticketsMappedWithGroups = [];
      }

      if (obj.defaultValues.isUseInOnoarding === HUB_CONST.NO) {
        obj.defaultValues.isRequiredInCommunity = HUB_CONST.NO;
        obj.defaultValues.isRequired = HUB_CONST.NO;
      }

      if (props.community_version === 2) {
        obj.defaultValues.user_profile_field_icons_id = props.selIcon.id;
      }

      let bodyData;
      let parentData = [obj.defaultValues];
      if (subFieldCheck && childFormFields) {
        childFields = childObjectFormation();
        childFields.map((fields) => {
          parentData = [...parentData, fields];
        });
      }
      bodyData = {
        data: [...parentData],
      };

      addProfileFieldsObj(
        parentData,
        attendeeList?.options,
        sectionList?.options,
        completeTicketList
      );
      APICALL(
        'ADD_USER_PROFILE_FIELDS',
        reduxData,
        'POST',
        ADD_USER_PROFILE_FIELDS,
        baseEventLanguage ? { language: baseEventLanguage.id } : null,
        bodyData,
        APICB
      );
    }
  };

  const handleChange = (name, value) => {
    if (errObj[name]) setErrObj({ ...errObj, [name]: '' });
    setFormFields({ ...formFields, [name]: value });
    if (name === 'fieldType') {
      if (value.name === 'File') {
        const tempArray = [];
        value.defaultValues.properties.file_types.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.label = item.name ? item.name : item.label;
          obj.isChecked = item.value ? item.value : item.isChecked;
          tempArray.push(obj);
          return 0;
        });
        setFileTypes([...tempArray]);
      }
      if (value.name === 'Group') {
        const drpOption = value.fieldTypes.find(
          (x) => x.id === value.defaultValues.properties.fields[0].id
        );
        setGroupOptions([
          {
            value: value.defaultValues.properties.fields[0].fieldName,
            id: 1,
            fieldType: value.defaultValues.properties.fields[0].id,
            isSelected: value.defaultValues.properties.fields[0].isRequired === HUB_CONST.YES,
            drpOption,
          },
        ]);
      }
      if (!value?.defaultValues?.isUseInTicketing) {
        value.defaultValues.isUseInTicketing = HUB_CONST.YES;
      }
      renderedFields(value);
      setErrObj({});
    }
  };

  const handleChildChange = (name, value, childId = -1) => {
    if (errObj[name]) setErrObj({ ...errObj, [name]: '' });
    const tempArray = [];
    childFormFields.map((childField) => {
      const obj = {};
      if (childField.id === childId) {
        obj.id = childField.id;
        obj.checkBoxChildOptions = childField.checkBoxChildOptions;
        obj.dropdownChildOptions = childField.dropdownChildOptions;
        obj.radioChildOptions = childField.radioChildOptions;
        obj.groupChildOptions = childField.groupChildOptions;
        if (name === 'fieldName') {
          obj[name] = value;
          obj.fieldType = childField.fieldType;
          obj.value = childField.value;
        } else if (name === 'fieldType') {
          obj[name] = value;
          obj.fieldName = childField.fieldName;
          obj.value = childField.value;
        } else if (name === 'value') {
          obj[name] = value;
          obj.fieldType = childField.fieldType;
          obj.fieldName = childField.fieldName;
        }
        tempArray.push(obj);
        return 0;
      } else {
        obj.checkBoxChildOptions = childField.checkBoxChildOptions;
        obj.dropdownChildOptions = childField.dropdownChildOptions;
        obj.radioChildOptions = childField.radioChildOptions;
        obj.groupChildOptions = childField.groupChildOptions;
        obj.id = childField.id;
        obj.fieldType = childField.fieldType;
        obj.value = childField.value;
        obj.fieldName = childField.fieldName;
        tempArray.push(obj);
      }
    });
    setChildFormFields([...tempArray]);
    if (name === 'fieldType') {
      if (value.name === 'File') {
        const tempArray = [];
        value.defaultValues.properties.file_types.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.label = item.name ? item.name : item.label;
          obj.isChecked = item.value ? item.value : item.isChecked;
          tempArray.push(obj);
          return 0;
        });
        setFileTypes([...tempArray]);
      }
      if (value.name === 'Group') {
        const drpOption = value.fieldTypes.find(
          (x) => x.id === value.defaultValues.properties.fields[0].id
        );
        setGroupOptions([
          {
            value: value.defaultValues.properties.fields[0].fieldName,
            id: 1,
            fieldType: value.defaultValues.properties.fields[0].id,
            isSelected: value.defaultValues.properties.fields[0].isRequired === HUB_CONST.YES,
            drpOption,
          },
        ]);
      }
      if (!value?.defaultValues?.isUseInTicketing) {
        value.defaultValues.isUseInTicketing = HUB_CONST.YES;
      }
      renderedChildFields(value, childId);
      setChildValueOption({ ...value });
      setErrObj({});
    }
  };

  const handleChangeCheckBox = React.useCallback(
    (e, value, subFieldId = -1) => {
      if (subFieldId !== -1) {
        const childFormFieldsNew = [...childFormFields];
        childFormFieldsNew.map((field) => {
          if (subFieldId === field.id) {
            field.fieldType.defaultValues[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
          }
        });
        setChildFormFields([...childFormFieldsNew]);
      } else {
        formFields.fieldType.defaultValues[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
        setFormFields({ ...formFields });
      }
    },
    [formFields, childFormFields]
  );

  const onHandleSwitchChange = React.useCallback(
    (e, value) => {
      formFields.fieldType.defaultValues[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
      setFormFields({ ...formFields });
    },
    [formFields]
  );

  const handlePropertiesChange = React.useCallback(
    (subFieldId, e, value, type, fieldType) => {
      if (errObj[e.target.name]) setErrObj({ ...errObj, [e.target.name]: '' });
      if (subFieldId !== -1) {
        if (type === 'checkbox') {
          childFormFields.map((field) => {
            if (subFieldId === field.id) {
              field.fieldType.defaultValues.properties[value] = e.target.checked ? 1 : 0;
            }
          });
          setChildFormFields([...childFormFields]);
        } else {
          if (fieldType === 'file') {
            if (e.target.value === '' || numberRegex.test(e.target.value)) {
              childFormFields.map((field) => {
                if (subFieldId === field.id) {
                  field.fieldType.defaultValues.properties[value] = e.target.value;
                }
              });
            }
          } else if (fieldType === 'group') {
            childFormFields.map((field) => {
              if (subFieldId === field.id) {
                field.fieldType.defaultValues.properties[value] = e.target.value;
              }
            });
          } else if (fieldType === 'dropdown') {
            childFormFields.map((field) => {
              if (subFieldId === field.id) {
                field.fieldType.defaultValues.properties[value] = e.target.value;
              }
            });
          } else {
            childFormFields.map((field) => {
              if (subFieldId === field.id) {
                if (value === 'min' || value === 'max') {
                  if (e.target.value === '' || numberRegex.test(e.target.value)) {
                    if (field.fieldType.defaultValues.fieldName === 'Text Area') {
                      if (e.target.value.length <= 4) {
                        field.fieldType.defaultValues.properties.length[value] = e.target.value;
                      }
                    } else {
                      if (e.target.value.length <= 3) {
                        field.fieldType.defaultValues.properties.length[value] = e.target.value;
                      }
                    }
                  }
                } else {
                  field.fieldType.defaultValues.properties.length[value] = e.target.value;
                }
              }
            });
          }
          setChildFormFields([...childFormFields]);
        }
      } else {
        if (type === 'checkbox') {
          formFields.fieldType.defaultValues.properties[value] = e.target.checked ? 1 : 0;
          setFormFields({ ...formFields });
        } else {
          if (fieldType === 'file') {
            if (e.target.value === '' || numberRegex.test(e.target.value)) {
              formFields.fieldType.defaultValues.properties[value] = e.target.value;
            }
          } else if (fieldType === 'group') {
            formFields.fieldType.defaultValues.properties[value] = e.target.value;
          } else if (fieldType === 'dropdown') {
            formFields.fieldType.defaultValues.properties[value] = e.target.value;
          } else {
            if (value === 'min' || value === 'max') {
              if (e.target.value === '' || numberRegex.test(e.target.value)) {
                if (formFields.fieldType.defaultValues.fieldName === 'Text Area') {
                  if (e.target.value.length <= 4) {
                    formFields.fieldType.defaultValues.properties.length[value] = e.target.value;
                  }
                } else {
                  if (e.target.value.length <= 3) {
                    formFields.fieldType.defaultValues.properties.length[value] = e.target.value;
                  }
                }
              }
            } else {
              formFields.fieldType.defaultValues.properties.length[value] = e.target.value;
            }
          }

          setFormFields({ ...formFields });
        }
      }
    },
    [errObj, formFields, childFormFields]
  );

  const onAddOption = React.useCallback(
    (value, options) => {
      const addNewOptions = { value: '', isSelected: false };
      switch (value) {
        case 'checkbox':
          addNewOptions.id =
            formFields.fieldType.defaultValues.last_option_id + 1 ||
            checkBoxOptions[checkBoxOptions?.length - 1]?.id + 1;
          setCheckBoxOptions([...checkBoxOptions, addNewOptions]);
          break;
        case 'radio':
          addNewOptions.id =
            formFields.fieldType.defaultValues.last_option_id + 1 ||
            radioOptions[radioOptions?.length - 1]?.id + 1;
          formFields.fieldType.defaultValues.last_option_id =
            formFields.fieldType.defaultValues.last_option_id + 1 ||
            radioOptions[radioOptions?.length - 1]?.id + 1;
          setRadioOptions([...radioOptions, addNewOptions]);
          break;
        case 'dropdown':
          addNewOptions.id =
            formFields.fieldType.defaultValues.last_option_id + 1 ||
            dropdownOptions[dropdownOptions?.length - 1]?.id + 1;
          formFields.fieldType.defaultValues.last_option_id =
            formFields.fieldType.defaultValues.last_option_id + 1 ||
            dropdownOptions[dropdownOptions?.length - 1]?.id + 1;
          setDropdownOptions([...dropdownOptions, addNewOptions]);
          break;
        case 'group':
          addNewOptions.id = groupOptions.length + 1;
          addNewOptions.drpOption = options[0];
          setGroupOptions([...groupOptions, addNewOptions]);
          break;
        default:
          break;
      }
    },
    [checkBoxOptions, dropdownOptions, formFields, radioOptions, groupOptions]
  );

  const onAddChildOption = React.useCallback(
    (subFieldId, value, options) => {
      const addNewOptions = { value: '', isSelected: false };
      switch (value) {
        case 'checkbox':
          childFormFields.map((subField) => {
            if (subField.id === subFieldId) {
              addNewOptions.id = subField.checkBoxChildOptions.length + 1;
              const data = subField.checkBoxChildOptions;
              subField.checkBoxChildOptions = [...data, addNewOptions];
            }
          });
          setChildFormFields([...childFormFields]);
          break;
        case 'radio':
          childFormFields.map((subField) => {
            if (subField.id === subFieldId) {
              addNewOptions.id = subField.radioChildOptions.length + 1;
              const data = subField.radioChildOptions;
              subField.radioChildOptions = [...data, addNewOptions];
            }
          });
          setChildFormFields([...childFormFields]);
          break;
        case 'dropdown':
          childFormFields.map((subField) => {
            if (subField.id === subFieldId) {
              addNewOptions.id = subField.dropdownChildOptions.length + 1;
              const data = subField.dropdownChildOptions;
              subField.dropdownChildOptions = [...data, addNewOptions];
            }
          });
          setChildFormFields([...childFormFields]);
          break;
        case 'group':
          childFormFields.map((subField) => {
            if (subField.id === subFieldId) {
              addNewOptions.id = subField.groupChildOptions.length + 1;
              addNewOptions.drpOption = options[0];
              const data = subField.groupChildOptions;
              subField.groupChildOptions = [...data, addNewOptions];
            }
          });
          setChildFormFields([...childFormFields]);
          break;
        default:
          break;
      }
    },
    [
      checkBoxChildOptions,
      dropdownChildOptions,
      radioChildOptions,
      groupChildOptions,
      childValueOption,
    ]
  );

  const getTicketsList = React.useCallback(
    (currPage, search = '', isFirstPage = false) => {
      if (!sectionList.apicallDone) {
        const header = {
          ...headers,
          search: search === '' ? '' : search,
        };
        const apiEndPoint = `${TICKET_GROUP_LIST}?limit=${50}&page=${isFirstPage ? 1 : currPage}`;
        if (sectionList.apiDone) {
          setSectionList({
            ...sectionList,
            initLoad: isFirstPage ? true : sectionList.initLoad,
            loading: !isFirstPage ? true : sectionList.loading,
            apiDone: false,
          });

          GETAPIWITHCUSTOMDOMAIN(false, apiEndPoint, source.AS, header, null, CUSTOM_URL_TICKETS)
            .then((resp) => {
              const { data, current_page, total_page, total_count } = resp.data;
              if (resp.status === API_CALL_RESP_CODES.OK) {
                if (data) {
                  const respData = {
                    currentPage: current_page,
                    totalcount: total_count,
                    totalPage: total_page,
                    search,
                    tempSearch: search,
                    initLoad: false,
                    loading: false,
                    options: [],
                    apiDone: true,
                    apicallDone: true,
                  };
                  if (isFirstPage) {
                    respData.initLoad = false;
                    respData.options = data;
                  } else {
                    respData.totalcount = sectionList.totalcount;
                    respData.totalPage = sectionList.totalPage;
                    respData.options = [...sectionList.options, ...data];
                    respData.loading = false;
                  }
                  setSectionList(respData);
                }
              } else {
                setSectionList({
                  ...sectionList,
                  initLoad: false,
                  loading: false,
                  apiDone: true,
                  apicallDone: true,
                });
              }
            })
            .catch(API.catchHandler);
        }
      }
    },
    [sectionList]
  );

  const getAttendeeGroupList = React.useCallback(
    (currPage, search = '', isFirstPage = false) => {
      if (!attendeeList.apicallDone) {
        const header = {
          ...headers,
          search: search === '' ? '' : search,
        };
        if (attendeeList.apiDone) {
          setAttendeeList({
            ...attendeeList,
            initLoad: isFirstPage ? true : attendeeList.initLoad,
            loading: !isFirstPage ? true : attendeeList.loading,
            apiDone: false,
          });

          GETAPIWITHCUSTOMDOMAIN(false, EVENT_GROUP_SETTING, source.AS, header, null, CUSTOM_URL)
            .then((resp) => {
              const { data, currentPage, totalPages, totalCount } = resp.data;
              if (resp.status === API_CALL_RESP_CODES.OK) {
                if (data) {
                  const respData = {
                    currentPage,
                    totalcount: totalCount,
                    totalPage: totalPages,
                    search,
                    tempSearch: search,
                    initLoad: false,
                    loading: false,
                    options: [],
                    apiDone: true,
                    apicallDone: true,
                  };
                  if (isFirstPage) {
                    respData.initLoad = false;
                    respData.options = data?.groupsList;
                  } else {
                    respData.totalcount = attendeeList.totalcount;
                    respData.totalPage = attendeeList.totalPage;
                    respData.options = [...attendeeList.options, ...data?.groupsList];
                    respData.loading = false;
                  }
                  setAttendeeList(respData);
                }
              } else {
                setAttendeeList({
                  ...attendeeList,
                  initLoad: false,
                  loading: false,
                  apiDone: true,
                  apicallDone: true,
                });
              }
            })
            .catch(API.catchHandler);
        }
      }
    },
    [attendeeList]
  );

  const getTicketsLists = React.useCallback(
    (currPage, search = '', isFirstPage = false, groupIds = [], optionsList) => {
      const header = {
        ...headers,
        search: search === '' ? '' : search,
      };
      const apiEndPoint = `${GET_DISCOUNT_TICKETS}?page=${
        isFirstPage ? 1 : currPage
      }&limit=${PAGE_LIMITS}`;
      if (ticketsList.apiDone) {
        setTicketsList({
          ...ticketsList,
          initLoad: isFirstPage ? true : ticketsList.initLoad,
          loading: !isFirstPage ? true : ticketsList.loading,
          apiDone: false,
          options: optionsList.length > 0 ? optionsList : ticketsList.options,
        });

        const body = {
          data: {
            groupIds: ticketGroupList ? [] : groupIds.map((a) => a.id),
          },
        };

        POSTAPIWITHCUSTOMDOMAIN(false, apiEndPoint, source.AS, header, body, CUSTOM_URL_TICKETS)
          .then((resp) => {
            const { data, currentPage, totalPages, totalCount } = resp.data;
            if (resp.status === API_CALL_RESP_CODES.OK) {
              if (data) {
                const respData = {
                  currentPage,
                  totalcount: totalCount,
                  totalPage: totalPages,
                  search,
                  tempSearch: search,
                  initLoad: false,
                  loading: false,
                  options: [],
                  apiDone: true,
                };
                if (isFirstPage) {
                  respData.initLoad = false;
                  respData.options = data;
                } else {
                  respData.totalcount = ticketsList.totalcount;
                  respData.totalPage = ticketsList.totalPage;
                  respData.options = [...optionsList, ...data];
                  respData.loading = false;
                }
                setTicketsList(respData);
              }
            } else {
              setTicketsList({
                ...ticketsList,
                initLoad: false,
                loading: false,
                apiDone: true,
              });
            }
          })
          .catch(API.catchHandler);
      }
    },
    [ticketGroupList]
  );

  const onAttendeeGroupTo = (e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;

      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.communityGroups = selectedEventList;
      setFormFields(formFieldsClone);
      setAttendeeGroupList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(formFields.fieldType.defaultValues.communityGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x._id === item._id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);
      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.communityGroups = list;
      setFormFields(formFieldsClone);
    }
    if (e) {
      setErrObj({
        ...errObj,
        communityGroups: '',
      });
    }
  };

  const onApplicableTo = (e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;
      setGroupIds = [];
      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.ticketingGroups = selectedEventList;
      if (formFieldsClone.fieldType.defaultValues.ticketingGroups.length === 0) {
        formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = [];
        setTicketList(isChecked ? 'ALL' : '');
      }
      setFormFields(formFieldsClone);
      setTicketGroupList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(formFields.fieldType.defaultValues.ticketingGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);
      setGroupIds = list;

      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.ticketingGroups = list;

      formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = [];
      setTicketList('');

      setFormFields(formFieldsClone);
    }
    setTicketsList({
      ...ticketsList,
      options: [],
    });
    if (e) {
      setErrObj({
        ...errObj,
        ticketingGroups: '',
      });
    }
  };

  const onTicketsMapped = (e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;

      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = selectedEventList;
      setFormFields(formFieldsClone);
      setTicketList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(formFields.fieldType.defaultValues.ticketsMappedWithGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);
      const formFieldsClone = JSON.parse(JSON.stringify(formFields));
      formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = list;
      setFormFields(formFieldsClone);
    }
    if (e) {
      setErrObj({
        ...errObj,
        ticketsMappedWithGroups: '',
      });
    }
  };

  const handleFileTypesChecked = React.useCallback(
    (value) => {
      const valueData = fileTypes.filter((x) => x.id === value.id);
      valueData[0].isChecked = !value.isChecked;
      setFileTypes([...fileTypes]);
    },
    [fileTypes]
  );

  const getSelData = (childFormFields, subField) => {
    const selectedField = childFormFields?.filter((field) => field.id === subField.id);
    let selData = {};
    if (selectedField.length > 0) {
      selData = selectedField[0];
    }
    return selData;
  };

  const subFieldComponent = React.useCallback(
    (optionsSubField) => {
      if (otherOption) {
        optionsSubField = [
          ...optionsSubField,
          {
            id: optionsSubField.length + 1,
            fieldType: '',
            value: 'Others',
            fieldName: '',
          },
        ];
      }
      if (optionsSubField === '') {
        if (formFields.fieldType.name === 'Checkbox') {
          optionsSubField = [...optionsSubField, checkBoxOptions];
        } else if (formFields.fieldType.name === 'Radio') {
          optionsSubField = [...optionsSubField, radioOptions];
        } else if (formFields.fieldType.name === 'DropDown') {
          optionsSubField = [...optionsSubField, dropdownOptions];
        }
      }
      const optionsSubFieldList = optionsSubField
        .map((eachSubField) => {
          if (eachSubField.value.length > 0) {
            const updDefaultValues = eachSubField?.value || '';
            return {
              ...eachSubField,
              defaultValues: {
                updDefaultValues,
              },
            };
          }
        })
        .filter((childField) => childField?.value?.length > 0);

      return (
        <>
          <Box position="relative" display="inline-flex" alignItems="center">
            <CheckBox2
              label="Add a conditional subfield"
              color="primary"
              checked={subFieldCheck === true}
              onChange={() => setSubFieldCheck(!subFieldCheck)}
            />
            <CustomToolTip
              className={classes.fieldTipBox}
              isFabIcon
              title={
                'Adding subfield will enable you to ask conditional questions to the attendees based on the option selected by them for the current question.'
              }
              placement="top"
            />
          </Box>
          {subFieldCheck && (
            <>
              <Box mb={2} mt={2}>
                <Typography variant="body1" className={props.classes.headingTitle} component="h4">
                  subfield
                </Typography>
              </Box>
              {childFormFields?.map((subField) => {
                return (
                  <Box className={classes.subFieldBlock} key={subField.id}>
                    {childFormFields?.length > 1 && (
                      <IconButton
                        className={classes.deleteButton}
                        size="medium"
                        onClick={() => {
                          const newTotalSubFields = childFormFields.filter((x) => {
                            return x.id !== subField.id;
                          });
                          setChildFormFields([...newTotalSubFields]);
                        }}>
                        <Trash2 width="16px" height="16px" />
                      </IconButton>
                    )}
                    <Box>
                      <Box mb={3}>
                        <SelectDropDown
                          labelKey="value"
                          valueKey="value"
                          placeholder="Select Field Options"
                          name="FieldOptions"
                          isArrayOfObject
                          required
                          label="Field Options"
                          value={
                            getSelData(childFormFields, subField)?.value &&
                            getSelData(childFormFields, subField).value.length > 0
                              ? getSelData(childFormFields, subField)
                              : ''
                          }
                          options={optionsSubFieldList}
                          error={subField.errorOption}
                          onChange={(value) => {
                            setSelectedChild(value);
                            handleChildChange('value', value.value, subField.id);
                          }}
                        />
                      </Box>
                      <Box mb={3}>
                        <InputField
                          label="field name"
                          required
                          name="fieldName"
                          placeholder="For eg; Organisation"
                          value={subField.fieldName}
                          error={subField.errorName}
                          onChange={(e) =>
                            handleChildChange('fieldName', e.target.value, subField.id)
                          }
                        />
                      </Box>
                      <Box>
                        <SelectDropDown
                          labelKey="name"
                          valueKey="name"
                          placeholder="Select"
                          name="fieldType"
                          isArrayOfObject
                          required
                          label="field type"
                          value={getSelData(childFormFields, subField)?.fieldType || ''}
                          error={subField.errorFileType}
                          options={fieldTypeList}
                          onChange={(value) => {
                            setSubFieldTypeID(subField.id);
                            handleChildChange('fieldType', value, subField.id);
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        {renderedChildFields(subField?.fieldType || '', subField.id)}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                display="flex"
                className="text-underline cursor-pointer"
                alignItems="center"
                component="a"
                fontWeight={600}
                variant="caption"
                onClick={() => {
                  setChildFormFields([
                    ...childFormFields,
                    {
                      id: getRandomId(),
                      fieldName: '',
                      fieldType: '',
                      value: '',
                      checkBoxChildOptions: [
                        { value: '', id: 1, isSelected: false },
                        { value: '', id: 2, isSelected: false },
                      ],
                      radioChildOptions: [
                        { value: '', id: 1, isSelected: false },
                        { value: '', id: 2, isSelected: false },
                      ],
                      dropdownChildOptions: [
                        { value: '', id: 1, isSelected: false },
                        { value: '', id: 2, isSelected: false },
                      ],
                      groupChildOptions: [],
                    },
                  ]);
                }}>
                <Box mr={1} display="flex">
                  <Plus width={18} />
                </Box>
                Add subfield
              </Box>
            </>
          )}
        </>
      );
    },
    [
      subFieldCheck,
      fieldTypeList,
      childFormFields,
      formFields,
      selectedChild,
      subFieldTypeID,
      renderChild,
      childValueOption,
    ]
  );

  useEffect(() => {
    // Ticket Groups
    getTicketsList(1, '', true);
    setTicketGroupList('ALL');

    // Ticket List
    setTicketList('ALL');
    getTicketsLists(1, '', true, [], []);

    // Attendee Groups
    getAttendeeGroupList(1, '', true);
  }, []);

  const additionalDetails = React.useCallback(
    (defaultFields, subFieldId = -1) => {
      if (subFieldId !== -1) {
        return (
          <CheckBox2
            label="Mandatory"
            color="primary"
            value={defaultFields.isRequired === HUB_CONST.YES}
            onChange={(e) => {
              handleChangeCheckBox(e, 'isRequired', subFieldId);
              if (defaultFields.isUseInTicketing === HUB_CONST.YES) {
                handleChangeCheckBox(e, 'isRequiredInTicketing', subFieldId);
              }
            }}
            checked={defaultFields.isRequired === HUB_CONST.YES}
          />
        );
      } else {
        return (
          <Grid item xs={12}>
            <Box mb={2.5} className={classes.divider} />
            <Box mb={3}>
              <Typography variant="body1" className={props.classes.headingTitle} component="h4">
                Attendee platform
              </Typography>
            </Box>
            <Box display="flex" width="100%" mt={2} mb={2}>
              <SelectCheckBoxDropDown
                error={errObj.communityGroups}
                loading={attendeeList.initLoad}
                totalCount={attendeeList.total_count}
                loadingMore={attendeeList.loading}
                name="communityGroups"
                required
                hasSearch={false}
                label="Groups"
                labelKey="name"
                valueKey="_id"
                placeholder="Select"
                isAllSelected={!!attendeeGroupList}
                selelctAll={!!attendeeGroupList}
                isUseValueKey
                isSelectAlls
                id="events_list"
                options={attendeeList.options}
                value={defaultFields.communityGroups}
                onChange={(e, name, val, item, index) =>
                  onAttendeeGroupTo(e, name, val, item, index)
                }
                onDeleteValue={(item, index) => {
                  const err = handleValidations();
                  if (item === 'All') {
                    setAttendeeGroupList('');

                    const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                    formFieldsClone.fieldType.defaultValues.communityGroups = [];
                    setFormFields(formFieldsClone);
                    setErrObj({
                      ...errObj,
                      communityGroups: 'Group is Required',
                    });
                  } else {
                    const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                    if (
                      item.name ===
                      formFieldsClone.fieldType.defaultValues.communityGroups[index].name
                    ) {
                      formFieldsClone.fieldType.defaultValues.communityGroups.splice(index, 1);
                      setFormFields(formFieldsClone);
                    }
                    if (formFieldsClone.fieldType.defaultValues?.communityGroups.length === 0) {
                      setErrObj({
                        ...errObj,
                        communityGroups: 'Group is Required',
                      });
                    }
                  }
                }}
                onOpenDropDown={() => {
                  getAttendeeGroupList(1, '', true);
                }}
                onEnter={() => {
                  getAttendeeGroupList(1, attendeeList.search, true);
                }}
                onScroll={(event) => {
                  const ele = event.target;
                  if (
                    Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                    attendeeList.currentPage + 1 <= attendeeList.totalPage
                  ) {
                    getAttendeeGroupList(attendeeList.currentPage + 1, attendeeList.search, false);
                  }
                }}
                onSearch={(e) => {
                  setSectionList({ ...attendeeList, search: e });

                  if (e === '') {
                    getAttendeeGroupList(1, '', true);
                  }
                }}
                isMultiSelect
                isArrayOfObject
              />
            </Box>
            <Box mt={3} mb={3}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2" component="p">
                  Display this field in onboarding form
                </Typography>
                <SwitchField
                  checked={defaultFields.isUseInOnoarding === HUB_CONST.YES}
                  onChange={(e) => onHandleSwitchChange(e, 'isUseInOnoarding', defaultFields)}
                />
              </Box>

              {defaultFields.showIsShowToOthers === HUB_CONST.YES && (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    Show this in user Profile to others
                  </Typography>
                  <SwitchField
                    checked={defaultFields.isShowToOthers === HUB_CONST.YES}
                    onChange={(e) => onHandleSwitchChange(e, 'isShowToOthers', defaultFields)}
                  />
                </Box>
              )}
              {defaultFields.showIsUseInFilter === HUB_CONST.YES ? (
                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    Filter Attendees based on this field
                  </Typography>
                  <SwitchField
                    checked={defaultFields.isUseInFilter === HUB_CONST.YES}
                    onChange={(e) => onHandleSwitchChange(e, 'isUseInFilter')}
                  />
                </Box>
              ) : (
                false
              )}

              {defaultFields.showIsShow === HUB_CONST.YES ? (
                <Box mt={0} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    Show this field in Edit Profile
                  </Typography>
                  <SwitchField
                    checked={defaultFields.isShow === HUB_CONST.YES}
                    onChange={(e) => onHandleSwitchChange(e, 'isShow')}
                  />
                </Box>
              ) : (
                false
              )}

              <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2" component="p">
                  Allow attendee to edit the registration form field in the onboarding section
                </Typography>
                <SwitchField
                  checked={
                    defaultFields.isOnboardingEditingRestricted
                      ? defaultFields.isOnboardingEditingRestricted === HUB_CONST.YES
                      : HUB_CONST.YES
                  }
                  onChange={(e) => onHandleSwitchChange(e, 'isOnboardingEditingRestricted')}
                />
              </Box>
              <Box mb={2}>
                <CheckBox2
                  label="Mandatory"
                  color="primary"
                  value={defaultFields.isRequired === HUB_CONST.YES}
                  onChange={(e) => handleChangeCheckBox(e, 'isRequired')}
                  checked={defaultFields.isRequired === HUB_CONST.YES}
                />
              </Box>
            </Box>

            {!isWebinarEvent &&
              !isEmpty(eventTicketList) &&
              eventData?.EventData?.is_ticketing_from_new_dashboard === 2 && (
                <>
                  <Box mb={2.5} className={classes.divider} />
                  <Box mt={3} mb={3}>
                    <Typography
                      variant="body1"
                      className={props.classes.headingTitle}
                      component="h4">
                      Tickets
                    </Typography>
                  </Box>
                  <Box mt={3} mb={3}>
                    <Typography mt={2} variant="body1" component="p">
                      Registration form
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" component="p">
                      Display this field in registration form
                    </Typography>
                    <SwitchField
                      checked={defaultFields.isUseInTicketing === HUB_CONST.YES}
                      onChange={(e) => onHandleSwitchChange(e, 'isUseInTicketing', defaultFields)}
                    />
                  </Box>
                  {defaultFields.isUseInTicketing === HUB_CONST.YES ? (
                    <>
                      <Box display="flex" width="100%" mt={2} mb={2}>
                        <SelectCheckBoxDropDown
                          error={errObj.ticketingGroups}
                          loading={sectionList.initLoad}
                          totalCount={sectionList.total_count}
                          loadingMore={sectionList.loading}
                          name="ticketingGroups"
                          required
                          hasSearch={false}
                          label="Groups"
                          labelKey="name"
                          valueKey="id"
                          placeholder="Select"
                          isAllSelected={!!ticketGroupList}
                          selelctAll={!!ticketGroupList}
                          isUseValueKey
                          isSelectAlls
                          id="events_list"
                          options={sectionList.options || []}
                          value={defaultFields.ticketingGroups || null}
                          onChange={(e, name, val, item, index) =>
                            onApplicableTo(e, name, val, item, index)
                          }
                          onDeleteValue={(item, index) => {
                            if (item === 'All') {
                              const err = handleValidations();
                              setTicketGroupList('');

                              const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                              formFieldsClone.fieldType.defaultValues.ticketingGroups = [];
                              setFormFields(formFieldsClone);

                              formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = [];
                              setTicketList('');

                              setErrObj({
                                ...errObj,
                                ticketingGroups: 'Group is Required',
                              });
                            } else {
                              const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                              if (
                                item.name ===
                                formFieldsClone.fieldType.defaultValues.ticketingGroups[index].name
                              ) {
                                formFieldsClone.fieldType.defaultValues.ticketingGroups.splice(
                                  index,
                                  1
                                );
                                setFormFields(formFieldsClone);
                              }
                              if (
                                formFieldsClone.fieldType.defaultValues?.ticketingGroups.length ===
                                0
                              ) {
                                setErrObj({
                                  ...errObj,
                                  ticketingGroups: 'Group is Required',
                                });
                              }

                              formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups = [];
                              setTicketList('');
                            }

                            setTicketsList({
                              ...ticketsList,
                              options: [],
                            });
                          }}
                          onOpenDropDown={() => getTicketsList(1, '', true)}
                          onEnter={() => {
                            getTicketsList(1, sectionList.search, true);
                          }}
                          onScroll={(event) => {
                            const ele = event.target;
                            if (
                              Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                              sectionList.currentPage + 1 <= sectionList.totalPage
                            ) {
                              getTicketsList(
                                sectionList.currentPage + 1,
                                sectionList.search,
                                false
                              );
                            }
                          }}
                          isMultiSelect
                          isArrayOfObject
                        />
                      </Box>

                      {(ticketGroupList === 'ALL' ||
                        (defaultFields?.ticketingGroups &&
                          defaultFields?.ticketingGroups?.length !== 0)) && (
                        <Box display="flex" width="100%" mt={2} mb={2}>
                          <SelectCheckBoxDropDown
                            error={errObj.ticketsMappedWithGroups}
                            loading={ticketsList.initLoad}
                            totalCount={ticketsList.total_count}
                            loadingMore={ticketsList.loading}
                            name="ticketsMappedWithGroups"
                            required
                            hasSearch={false}
                            label="Tickets mapped with groups"
                            labelKey="name"
                            valueKey="id"
                            placeholder="Select"
                            isAllSelected={!!ticketList}
                            selelctAll={!!ticketList}
                            isUseValueKey
                            isSelectAlls
                            id="events_list"
                            options={ticketsList.options || []}
                            value={defaultFields.ticketsMappedWithGroups || null}
                            onChange={(e, name, val, item, index) => {
                              onTicketsMapped(e, name, val, item, index);
                            }}
                            onDeleteValue={(item, index) => {
                              if (item === 'All') {
                                const err = handleValidations();
                                setTicketList('');
                                setFormFields({ ...formFields, discountApplicableTo: [] });

                                const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                                formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups =
                                  [];
                                setFormFields(formFieldsClone);
                                setErrObj({
                                  ...errObj,
                                  ticketsMappedWithGroups:
                                    'Tickets mapped with groups  is required',
                                });
                              } else {
                                const formFieldsClone = JSON.parse(JSON.stringify(formFields));
                                if (
                                  item.name ===
                                  formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups[
                                    index
                                  ].name
                                ) {
                                  formFieldsClone.fieldType.defaultValues.ticketsMappedWithGroups.splice(
                                    index,
                                    1
                                  );
                                  setFormFields(formFieldsClone);
                                }
                                if (
                                  formFieldsClone.fieldType.defaultValues?.ticketsMappedWithGroups
                                    .length === 0
                                ) {
                                  setErrObj({
                                    ...errObj,
                                    ticketsMappedWithGroups:
                                      'Tickets mapped with groups  is required',
                                  });
                                }
                              }
                            }}
                            onOpenDropDown={() => {
                              getTicketsLists(
                                1,
                                '',
                                true,
                                defaultFields?.ticketingGroups,
                                ticketsList.options
                              );
                            }}
                            onEnter={() => {
                              getTicketsLists(
                                1,
                                ticketsList.search,
                                true,
                                defaultFields?.ticketingGroups,
                                ticketsList.options
                              );
                            }}
                            onScroll={(event) => {
                              const ele = event.target;
                              if (
                                Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                                ticketsList.currentPage + 1 <= ticketsList.totalPage
                              ) {
                                getTicketsLists(
                                  ticketsList.currentPage + 1,
                                  ticketsList.search,
                                  false,
                                  defaultFields?.ticketingGroups,
                                  ticketsList.options
                                );
                              }
                            }}
                            isMultiSelect
                            isArrayOfObject
                          />
                        </Box>
                      )}
                    </>
                  ) : null}

                  {defaultFields.isUseInTicketing === HUB_CONST.YES && (
                    <Box mb={2} className={classes.trimWidth}>
                      <CheckBox2
                        label="Mandatory"
                        color="primary"
                        value={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                        onChange={(e) => handleChangeCheckBox(e, 'isRequiredInTicketing')}
                        checked={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                      />
                    </Box>
                  )}
                </>
              )}
          </Grid>
        );
      }
    },
    [
      attendeeGroupList,
      attendeeList,
      classes.divider,
      errObj,
      eventTicketList,
      formFields,
      getAttendeeGroupList,
      getTicketsList,
      getTicketsLists,
      handleChangeCheckBox,
      handleValidations,
      onApplicableTo,
      onAttendeeGroupTo,
      onHandleSwitchChange,
      onTicketsMapped,
      props.classes.headingTitle,
      sectionList.currentPage,
      sectionList.initLoad,
      sectionList.loading,
      sectionList.options,
      sectionList.search,
      sectionList.totalPage,
      sectionList.total_count,
      ticketGroupList,
      ticketList,
      ticketsList.currentPage,
      ticketsList.initLoad,
      ticketsList.loading,
      ticketsList.options,
      ticketsList.search,
      ticketsList.totalPage,
      ticketsList.total_count,
    ]
  );

  const addWebinarDetails = React.useCallback(
    (defaultFields) => {
      return (
        <Grid item xs={12}>
          <Box mb={2.5} className={classes.divider} />
          <Box mb={3}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" component="p">
                Display this field in registration form
              </Typography>
              <SwitchField
                checked={defaultFields.isUseInTicketing === HUB_CONST.YES}
                onChange={(e) => onHandleSwitchChange(e, 'isUseInTicketing', defaultFields)}
              />
            </Box>
          </Box>
          <Box display="flex" width="100%" mt={2} mb={2}>
            {defaultFields.isUseInTicketing === HUB_CONST.YES && (
              <Box mb={2} className={classes.trimWidth}>
                <CheckBox2
                  label="Mandatory"
                  color="primary"
                  value={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                  onChange={(e) => handleChangeCheckBox(e, 'isRequiredInTicketing')}
                  checked={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                />
              </Box>
            )}
          </Box>
        </Grid>
      );
    },
    [classes, onHandleSwitchChange, handleChangeCheckBox]
  );

  const renderedFields = React.useCallback(
    (value) => {
      // setSubFieldType(false);
      const defaultFields = value.defaultValues;
      switch (value.name) {
        case 'Text': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <CheckBox2
                  label="Is Number"
                  color="primary"
                  value={defaultFields.properties.is_number === 1}
                  onChange={(e) => handlePropertiesChange(-1, e, 'is_number', 'checkbox')}
                  checked={defaultFields.properties.is_number === 1}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Minimum"
                      name="min"
                      error={errObj.min}
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(-1, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      name="max"
                      descText="Maximum"
                      error={errObj.max}
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(-1, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Text Area': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberTextArea}
                      maxValue={4000}
                      descType
                      descText="Minimum"
                      name="min"
                      error={errObj.min}
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(-1, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberTextArea}
                      maxValue={4000}
                      name="max"
                      descType
                      descText="Maximum"
                      error={errObj.max}
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(-1, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Date': {
          return (
            <Grid container spacing={4}>
              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Link': {
          return (
            <Grid container spacing={4}>
              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Number': {
          return (
            <Grid container spacing={4}>
              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'File': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CheckBox2
                  label="File Types"
                  color="primary"
                  options={fileTypes}
                  onChange={(val) => {
                    handleFileTypesChecked(val);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Input Maximum File Size in MB"
                  disabled
                  error={errObj.file_size}
                  name="file_size"
                  value={defaultFields.properties.max_file_size}
                  onChange={(e) => {
                    handlePropertiesChange(-1, e, 'max_file_size', 'text', 'file');
                  }}
                  placeholder=""
                  descText="Value cannot be greater than 5"
                  descType
                />
              </Grid>

              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Dropdown': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  addOptionListAction
                  onChange={(val, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    const data = dropdownOptions[i];
                    data.value = val;
                    data.error = '';
                    setDropdownOptions([...dropdownOptions]);
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={dropdownOptions}
                  placeholder="Enter option value"
                  checkBtn={(i, selected) => {
                    const copyDropdownOptions = dropdownOptions;
                    copyDropdownOptions.map((item, index) => {
                      if (index === i) {
                        item.isSelected = !item.isSelected;
                      } else {
                        item.isSelected = false;
                      }
                    });
                    setDropdownOptions([...copyDropdownOptions]);
                  }}
                  deleteBtn={(i) => {
                    const data = dropdownOptions.filter((option, index) => index !== i);
                    setDropdownOptions([...data]);
                  }}
                  addOption={() => onAddOption('dropdown')}
                  clearBtn={() => {
                    dropdownOptions.map((item) => {
                      item.isSelected = false;
                      return 0;
                    });
                    setDropdownOptions([...dropdownOptions]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>

              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) => {
                        setOtherOption(defaultFields.properties.include_other_option !== 1);
                        handlePropertiesChange(-1, e, 'include_other_option', 'checkbox');
                      }}
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                    {subFieldComponent(dropdownOptions)}
                  </>
                ) : (
                  false
                )}
                {defaultFields.isRequired === HUB_CONST.YES ? (
                  false
                ) : (
                  <CheckBox2
                    label="Include Blank Option"
                    color="primary"
                    value={defaultFields.properties.include_blank === 1}
                    onChange={(e) => {
                      setOtherOption(defaultFields.properties.include_other_option !== 1);
                      handlePropertiesChange(-1, e, 'include_blank', 'checkbox');
                    }}
                    checked={defaultFields.properties.include_blank === 1}
                  />
                )}
              </Grid>

              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Checkbox': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  defaultOptionsLength={2}
                  addOptionListAction
                  isMultiSelect
                  onChange={(val, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    const data = checkBoxOptions[i];
                    data.value = val;
                    data.error = '';
                    setCheckBoxOptions([...checkBoxOptions]);
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={checkBoxOptions}
                  placeholder="Enter option value"
                  handleCheckBox={(i, selected) => {
                    const dataValue = checkBoxOptions[i];
                    dataValue.isSelected = !selected;
                    setCheckBoxOptions([...checkBoxOptions]);
                  }}
                  deleteBtn={(i) => {
                    const data = checkBoxOptions.filter((option, index) => index !== i);
                    setCheckBoxOptions([...data]);
                  }}
                  addOption={() => onAddOption('checkbox')}
                  clearBtn={() => {
                    checkBoxOptions.map((item) => {
                      item.isSelected = false;
                      return 0;
                    });
                    setCheckBoxOptions([...checkBoxOptions]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) => {
                        setOtherOption(defaultFields.properties.include_other_option !== 1);
                        handlePropertiesChange(-1, e, 'include_other_option', 'checkbox');
                      }}
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                    {subFieldComponent(checkBoxOptions)}
                  </>
                ) : (
                  false
                )}
              </Grid>

              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Radio': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  addOptionListAction
                  isMultiple
                  onChange={(valueData, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    const data = radioOptions[i];
                    data.value = valueData;
                    data.error = '';
                    setRadioOptions([...radioOptions]);
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={radioOptions}
                  placeholder="Enter option value"
                  checkBtn={(i, selected) => {
                    const copyRadioOptions = radioOptions;
                    copyRadioOptions.map((item, index) => {
                      if (index === i) {
                        item.isSelected = !item.isSelected;
                      } else {
                        item.isSelected = false;
                      }
                    });
                    setRadioOptions([...copyRadioOptions]);
                  }}
                  deleteBtn={(i) => {
                    const data = radioOptions.filter((option, index) => index !== i);
                    setRadioOptions([...data]);
                  }}
                  addOption={() => onAddOption('radio')}
                  clearBtn={() => {
                    radioOptions.map((item) => {
                      item.isSelected = false;
                      return 0;
                    });
                    setRadioOptions([...radioOptions]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) =>
                        handlePropertiesChange(-1, e, 'include_other_option', 'checkbox')
                      }
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                    {subFieldComponent(radioOptions)}
                  </>
                ) : (
                  false
                )}
              </Grid>

              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        case 'Group': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Minimum"
                      name="min"
                      error={errObj.min}
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(-1, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Maximum"
                      error={errObj.max}
                      name="max"
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(-1, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OptionList
                  label="Fields"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  addOptionListAction
                  isSelectOption
                  dropDownProps={value.fieldTypes}
                  isMultiSelect
                  handleDropDown={(valueData, dataValue, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    const data = groupOptions[i];
                    data.drpOption = valueData;
                    data.error = '';
                    setGroupOptions([...groupOptions]);
                  }}
                  onChange={(val, i) => {
                    const data = groupOptions[i];
                    data.value = val;
                    setGroupOptions([...groupOptions]);
                  }}
                  defaultOptionsLength={1}
                  addOptionBtnLabel="ADD Field"
                  options={groupOptions}
                  placeholder="Enter New field"
                  handleCheckBox={(i, selected) => {
                    const data = groupOptions[i];
                    data.isSelected = !selected;
                    data.isRequired = data.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
                    setGroupOptions([...groupOptions]);
                  }}
                  deleteBtn={(i) => {
                    const data = groupOptions.filter((option, index) => index !== i);
                    setGroupOptions([...data]);
                  }}
                  addOption={() => onAddOption('group', value.fieldTypes)}
                  clearBtn={() => {
                    groupOptions.map((item) => {
                      item.isSelected = false;
                      return 0;
                    });
                    setGroupOptions([...groupOptions]);
                  }}
                  hideFieldsCheckbox // HUB-132 - FIELDS CHECKBOX - HIDDEN AS OF NOW - 18092021
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Field Type"
                  error={errObj.field_type}
                  placeholder="Field Type"
                  name="field_type_name"
                  value={defaultFields.properties.field_type_name}
                  onChange={(e) =>
                    handlePropertiesChange(-1, e, 'field_type_name', 'text', 'group')
                  }
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Button Label"
                  error={errObj.button_label}
                  placeholder="Button Label"
                  name="button_label"
                  value={defaultFields.properties.button_label}
                  onChange={(e) => handlePropertiesChange(-1, e, 'button_label', 'text', 'group')}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>

              {isWebinar ? addWebinarDetails(defaultFields) : additionalDetails(defaultFields)}
            </Grid>
          );
        }
        default:
          break;
      }
      return 0;
    },
    [
      additionalDetails,
      checkBoxOptions,
      dropdownOptions,
      errObj,
      fileTypes,
      groupOptions,
      handleFileTypesChecked,
      handlePropertiesChange,
      onAddOption,
      radioOptions,
      isWebinar,
      addWebinarDetails,
    ]
  );

  const renderedChildFields = React.useCallback(
    (value, subFieldId) => {
      if (isEmpty(value)) return;
      const defaultFields = value.defaultValues;
      switch (value.name) {
        case 'Text': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <CheckBox2
                  label="Is Number"
                  color="primary"
                  value={defaultFields.properties.is_number === 1}
                  onChange={(e) => handlePropertiesChange(subFieldId, e, 'is_number', 'checkbox')}
                  checked={defaultFields.properties.is_number === 1}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Minimum"
                      name="min"
                      error={
                        childFormFields?.filter(
                          (subField) => subField.id === subFieldId && subField.errorText !== ''
                        )?.[0]?.errorTextMin || ''
                      }
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      name="max"
                      descText="Maximum"
                      // error={errObj.max}
                      error={
                        childFormFields?.filter(
                          (subField) => subField.id === subFieldId && subField.errorText !== ''
                        )?.[0]?.errorTextMax || ''
                      }
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>{additionalDetails(defaultFields, subFieldId)}</Box>
              </Grid>
            </Grid>
          );
        }
        case 'Text Area': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberTextArea}
                      maxValue={4000}
                      descType
                      descText="Minimum"
                      name="min"
                      error={errObj.min}
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberTextArea}
                      maxValue={4000}
                      name="max"
                      descType
                      descText="Maximum"
                      error={errObj.max}
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>{additionalDetails(defaultFields, subFieldId)}</Box>
              </Grid>
            </Grid>
          );
        }
        case 'Date': {
          return (
            <Grid item xs={12}>
              {additionalDetails(defaultFields, subFieldId)}
            </Grid>
          );
        }
        case 'Link': {
          return (
            <Grid item xs={12}>
              {additionalDetails(defaultFields, subFieldId)}
            </Grid>
          );
        }
        case 'Number': {
          return (
            <Grid item xs={12}>
              {additionalDetails(defaultFields, subFieldId)}
            </Grid>
          );
        }
        case 'File': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CheckBox2
                  label="File Types"
                  color="primary"
                  options={fileTypes}
                  onChange={(val) => {
                    handleFileTypesChecked(val);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label="Input Maximum File Size in MB"
                  disabled
                  error={errObj.file_size}
                  name="file_size"
                  value={defaultFields.properties.max_file_size}
                  onChange={(e) => {
                    handlePropertiesChange(subFieldId, e, 'max_file_size', 'text', 'file');
                  }}
                  placeholder=""
                  descText="Value cannot be greater than 5"
                  descType
                />
              </Grid>
              <Grid item xs={12}>
                {additionalDetails(defaultFields, subFieldId)}
              </Grid>
            </Grid>
          );
        }
        case 'Dropdown': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  addOptionListAction
                  onChange={(val, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    childFormFields.map((subField) => {
                      // const obj = {}
                      if (subField.id === subFieldId) {
                        const data = subField.dropdownChildOptions[i];
                        data.value = val;
                        data.error = '';
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={
                    childFormFields.filter((subField) => subField.id === subFieldId)?.[0]
                      ?.dropdownChildOptions
                  }
                  placeholder="Enter option value"
                  checkBtn={(i, selected) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const copyDropdownOptions = subField.dropdownChildOptions;
                        copyDropdownOptions.map((item, index) => {
                          if (index === i) {
                            item.isSelected = !item.isSelected;
                          } else {
                            item.isSelected = false;
                          }
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  deleteBtn={(i) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.dropdownChildOptions.filter(
                          (item, index) => index !== i
                        );
                        subField.dropdownChildOptions = [...data];
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOption={() => onAddChildOption(subFieldId, 'dropdown')}
                  clearBtn={() => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        subField.dropdownChildOptions.map((item) => {
                          item.isSelected = false;
                          return 0;
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) => {
                        handlePropertiesChange(subFieldId, e, 'include_other_option', 'checkbox');
                      }}
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                  </>
                ) : (
                  false
                )}
                {additionalDetails(defaultFields, subFieldId)}
              </Grid>
            </Grid>
          );
        }
        case 'Checkbox': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  defaultOptionsLength={2}
                  addOptionListAction
                  isMultiSelect
                  onChange={(val, i) => {
                    childFormFields.map((subField) => {
                      // const obj = {}
                      if (subField.id === subFieldId) {
                        const data = subField.checkBoxChildOptions[i];
                        data.value = val;
                        data.error = '';
                      }
                    });
                    setChildFormFields([...childFormFields]);
                    setErrObj({ ...errObj, blank_text: '' });
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={
                    childFormFields.filter((subField) => subField.id === subFieldId)?.[0]
                      ?.checkBoxChildOptions
                  }
                  placeholder="Enter option value"
                  handleCheckBox={(i, selected) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const dataValue = subField.checkBoxChildOptions[i];
                        dataValue.isSelected = !selected;
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  deleteBtn={(i) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.checkBoxChildOptions.filter(
                          (item, index) => index !== i
                        );
                        subField.checkBoxChildOptions = [...data];
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOption={() => onAddChildOption(subFieldId, 'checkbox')}
                  clearBtn={() => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        subField.checkBoxChildOptions.map((item) => {
                          item.isSelected = false;
                          return 0;
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) => {
                        handlePropertiesChange(subFieldId, e, 'include_other_option', 'checkbox');
                      }}
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                  </>
                ) : (
                  false
                )}
                {additionalDetails(defaultFields, subFieldId)}
              </Grid>
            </Grid>
          );
        }
        case 'Radio': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <OptionList
                  label="Options"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  addOptionListAction
                  isMultiple
                  onChange={(valueData, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.radioChildOptions[i];
                        data.value = valueData;
                        data.error = '';
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOptionBtnLabel="ADD OPTION"
                  options={
                    childFormFields.filter((subField) => subField.id === subFieldId)?.[0]
                      ?.radioChildOptions
                  }
                  placeholder="Enter option value"
                  checkBtn={(i, selected) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const copyDropdownOptions = subField.radioChildOptions;
                        copyDropdownOptions.map((item, index) => {
                          if (index === i) {
                            item.isSelected = !item.isSelected;
                          } else {
                            item.isSelected = false;
                          }
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  deleteBtn={(i) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.radioChildOptions.filter(
                          (item, index) => index !== i
                        );
                        subField.radioChildOptions = [...data];
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOption={() => onAddChildOption(subFieldId, 'radio')}
                  clearBtn={() => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        subField.radioChildOptions.map((item) => {
                          item.isSelected = false;
                          return 0;
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(defaultFields.properties).length > 0 &&
                defaultFields.properties.hasOwnProperty('include_other_option') ? (
                  <>
                    <CheckBox2
                      label="Show Other Option"
                      color="primary"
                      value={defaultFields.properties.include_other_option === 1}
                      onChange={(e) => {
                        handlePropertiesChange(subFieldId, e, 'include_other_option', 'checkbox');
                      }}
                      checked={defaultFields.properties.include_other_option === 1}
                    />
                  </>
                ) : (
                  false
                )}
                {additionalDetails(defaultFields, subFieldId)}
              </Grid>
            </Grid>
          );
        }
        case 'Group': {
          return (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    Length Limit
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Minimum"
                      name="min"
                      error={errObj.min}
                      value={defaultFields.properties.length.min}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'min', 'text')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputField
                      type="number"
                      onKeyPress={handelKeyPressOnNumberText}
                      descType
                      descText="Maximum"
                      error={errObj.max}
                      name="max"
                      value={defaultFields.properties.length.max}
                      onChange={(e) => handlePropertiesChange(subFieldId, e, 'max', 'text')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OptionList
                  label="Fields"
                  descType="TOOLTIP"
                  descText="Select to set default values for this field."
                  showBothIcon
                  isMultiple
                  addOptionListAction
                  isSelectOption
                  dropDownProps={value.fieldTypes}
                  isMultiSelect
                  handleDropDown={(valueData, dataValue, i) => {
                    setErrObj({ ...errObj, blank_text: '' });
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.groupChildOptions[i];
                        data.value = valueData;
                        data.error = '';
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  onChange={(val, i) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.groupChildOptions[i];
                        data.value = val;
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  defaultOptionsLength={1}
                  addOptionBtnLabel="ADD Field"
                  options={
                    childFormFields.filter((subField) => subField.id === subFieldId)?.[0]
                      ?.groupChildOptions
                  }
                  placeholder="Enter New field"
                  handleCheckBox={(i, selected) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const dataValue = subField.groupChildOptions[i];
                        dataValue.isSelected = !selected;
                        data.isRequired = data.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  deleteBtn={(i) => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        const data = subField.groupChildOptions.filter(
                          (item, index) => index !== i
                        );
                        subField.groupChildOptions = [...data];
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  addOption={() => onAddChildOption(subFieldId, 'group', value.fieldTypes)}
                  clearBtn={() => {
                    childFormFields.map((subField) => {
                      if (subField.id === subFieldId) {
                        subField.groupChildOptions.map((item) => {
                          item.isSelected = false;
                          return 0;
                        });
                      }
                    });
                    setChildFormFields([...childFormFields]);
                  }}
                  hideFieldsCheckbox // HUB-132 - FIELDS CHECKBOX - HIDDEN AS OF NOW - 18092021
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Field Type"
                  error={errObj.field_type}
                  placeholder="Field Type"
                  name="field_type_name"
                  value={defaultFields.properties.field_type_name}
                  onChange={(e) =>
                    handlePropertiesChange(subFieldId, e, 'field_type_name', 'text', 'group')
                  }
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Button Label"
                  error={errObj.button_label}
                  placeholder="Button Label"
                  name="button_label"
                  value={defaultFields.properties.button_label}
                  onChange={(e) =>
                    handlePropertiesChange(subFieldId, e, 'button_label', 'text', 'group')
                  }
                  isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                />
              </Grid>
              <Grid item xs={12}>
                {additionalDetails(defaultFields, subFieldId)}
              </Grid>
            </Grid>
          );
        }
        default:
          break;
      }
      return 0;
    },
    [
      errObj,
      fileTypes,
      handleFileTypesChecked,
      handlePropertiesChange,
      onAddChildOption,
      childFormFields,
    ]
  );

  return (
    <Sidebar
      open={isOpen}
      onClose={onCloseSidebar}
      asideWidth={600}
      title={title}
      footerBtnLabel="ADD"
      footerRightBtnVariant="contained"
      footerBtnLoadingLabel="SAVING"
      footerBtnAction={() => {
        handleSave();
      }}
      footerLoading={loadingState.addFieldsLoader}
      footerRightBtnLoadingLabel="SAVING"
      isFooterDisable={loadingState.initLoad}
      isLanguageSupport={eventLanguages && eventLanguages.length > 1}>
      {loadingState.initLoad ? (
        <SidebarLoader community_version={props.community_version} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputField
              label="field name"
              required
              name="fieldName"
              placeholder="For eg; Organisation"
              value={formFields.fieldName}
              error={errObj.fieldName}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              isLanguageSupport={eventLanguages && eventLanguages.length > 1}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectDropDown
              labelKey="name"
              onChange={(value) => {
                setSubFieldCheck(false);
                setSubFieldTypeID(-1);
                setRenderChild(false);
                handleChange('fieldType', value);
              }}
              valueKey="name"
              placeholder="Select"
              name="fieldType"
              isArrayOfObject
              value={formFields.fieldType}
              error={errObj.fieldType}
              required
              label="field type"
              options={fieldTypeList}
            />
          </Grid>
          <Grid item xs={12}>
            {formFields.fieldType && formFields.fieldType !== ''
              ? renderedFields(formFields.fieldType)
              : ''}
          </Grid>
        </Grid>
      )}
    </Sidebar>
  );
};
export default React.memo(ProfileFieldsSidebarNewUI);
