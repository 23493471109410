import React from 'react';
import PopUp from 'custom-components/popUp';

export const InsufficientPopUp = ({
  title,
  description,
  customIcon,
  insufficientPopUp,
  setInsufficientCreditPopUp,
  triggerCopyEvent
}) => {
  return (
    <>
      <PopUp
        isOpen={insufficientPopUp.show}
        onClose={() => {
          setInsufficientCreditPopUp({ ...insufficientPopUp, show: false, id: '' });
        }}
        btnMarginTop={4}
        data={{
          title,
          secondaryDesc: description,
          customIcon,
          actionBtn: [
            {
              name: 'Cancel',
              onClick: () => {
                setInsufficientCreditPopUp({ ...insufficientPopUp, show: false, id: '' });
              },
            },
            {
              name: 'Proceed',
              type: 'primary',
              onClick: () => {
                setInsufficientCreditPopUp({ ...insufficientPopUp, show: false, id: '' })
                triggerCopyEvent()
              },
            },
          ],
        }}
      />
    </>
  );
};
