const commonActionTypes = {
  GET_BASIC_INFO_LOADING: 'GET_BASIC_INFO_LOADING',
  GET_BASIC_INFO: 'GET_BASIC_INFO',
  SET_COPY_EVENT_VE_CREDITS_MODAL_OPEN: 'SET_COPY_EVENT_VE_CREDITS_MODAL_OPEN',
};

const getBasicInfoLoading = (payload) => {
  return { type: commonActionTypes.GET_BASIC_INFO_LOADING, payload };
};

const getBasicInfo = (payload) => {
  return { type: commonActionTypes.GET_BASIC_INFO, payload };
};

const setCopyEventVeCreditsModal = (isVeAddOnCreditsNotAvl) => {
  return {
    type: commonActionTypes.SET_COPY_EVENT_VE_CREDITS_MODAL_OPEN,
    payload: { isVeAddOnCreditsNotAvl },
  };
};

export { getBasicInfoLoading, getBasicInfo, setCopyEventVeCreditsModal, commonActionTypes };
