/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable radix */
import moment from 'moment-timezone';
import { transform, isEqual, isObject } from 'lodash';
import { EMPTY_STR } from 'constants/common';
import { REPORT_FILTER_STATUS_OPTIONS } from 'pages/emailBuilder/view-report/constants';
import { EventTypes } from 'pages/events/constants';

export function getEventImage(organiserId, imageName) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/banner/community_banner/${organiserId}/1036/${imageName}`;
  } else {
    return '';
  }
}
export function getOtherUserImage(type, organiserId, imageName, quality = 150) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/${type}/${organiserId}/${quality}/${imageName}`;
  } else {
    if (type === 'speaker')
      return `${process.env.REACT_APP_CDN_PATH}/assets/images/default_icon_speakers_dark2.png`;
    else return `${process.env.REACT_APP_CDN_PATH}/assets/images/default_icon_speakers_dark2.png`;
  }
}

export function eventDurationFormat(startTime, endTime, timezone, moment) {
  let str = '';
  try {
    const st = moment(+startTime).tz(timezone);
    const et = moment(+endTime).tz(timezone);
    if (st.isSame(et, 'year') && st.isSame(et, 'month') && st.isSame(et, 'day')) {
      str = et.format('DD MMM, YYYY');
    } else if (st.isSame(et, 'year') && st.isSame(et, 'month')) {
      str = `${st.format('DD')} - ${et.format('DD MMM, YYYY')}`;
    } else if (st.isSame(et, 'year')) {
      str = `${st.format('DD MMM')} - ${et.format('DD MMM, YYYY')}`;
    } else {
      str = `${st.format('DD MMM, YYYY')} - ${et.format('DD MMM, YYYY')}`;
    }
    return str;
  } catch (e) {
    return str;
  }
}

export function getUserImage(imageName) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/profile/thumb/${imageName}`;
  } else {
    // return "https://www.fillmurray.com/40/40"
    return process.env.REACT_APP_DEFAULT_ATTENDEE_IMAGE;
  }
}
export function getAttendeeImage(profilePictures, firstName) {
  let url = '';
  if (profilePictures && profilePictures.orignal !== '') {
    url = `${process.env.REACT_APP_CDN_PATH}/profile/${profilePictures.orignal}`;
  } else if (firstName && /^[A-Za-z]{1}/.test(firstName)) {
    url = `${process.env.REACT_APP_CDN_PATH}/profile/${firstName.charAt(0).toUpperCase()}.png`;
  } else {
    url = process.env.REACT_APP_DEFAULT_ATTENDEE_IMAGE;
  }
  return url;
}

export const iteratorMapAndReturnArray = (mapArray, func) => {
  const arr = [];
  mapArray.forEach((value, key, map) => {
    if (func) {
      arr.push(func(value, key, map));
    } else {
      arr.push(value);
    }
  });
  return arr;
};

export function hasDataInArray(data) {
  return data && Array.isArray(data) && data.length;
}
export function optimizeSearchCB(searchValue, memId, cb) {
  if (memId) clearTimeout(memId);
  return setTimeout(() => cb(searchValue), 600);
}

export const findDataInArrayById = (arr, keyName, id) => {
  let data;
  const index = arr.findIndex((ele) => ele[keyName] === id);
  if (index > -1) data = arr[index];
  return [data, index];
};

export function compareTime(startTime, endTime) {
  if (Date.parse(`01/01/2011 ${startTime}`) > Date.parse(`01/01/2011 ${endTime}`)) {
    return 'BIGGER';
  } else if (Date.parse(`01/01/2011 ${startTime}`) < Date.parse(`01/01/2011 ${endTime}`)) {
    return 'SMALLER';
  } else return 'SAME';
}

export const clearObject = (obj, defaultVal) => {
  const tempObj = {};
  Object.keys(obj).forEach((i) => (tempObj[i] = defaultVal));
  return tempObj;
};

export function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.click();
}
export function downloadVideo(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error('Error'));
}

export function downloadFileURI(uri) {
  const link = document.createElement('a');
  link.href = uri;
  link.click();
}

export function getBoothImage(type, imageName, organiserId) {
  if (type === 'profileImg')
    return `${process.env.REACT_APP_CDN_PATH}/exhibitor/${organiserId}/300/${imageName}`;
  else if (type === 'spotlightBannerImg')
    return `${process.env.REACT_APP_CDN_PATH}/banner/exhibitor_spotlight_banner/${organiserId}/1036/${imageName}`;
  else if (type === 'listingBannerImg')
    return `${process.env.REACT_APP_CDN_PATH}/banner/exhibitor_list_banner_image/${organiserId}/1036/${imageName}`;
  else if (type === 'listingMediumBannerImg' || type === 'listingSmallBannerImg')
    return `${process.env.REACT_APP_CDN_PATH}/banner/exhibitor_list_banner_image/${organiserId}/1036/${imageName}`;
  else if (type === 'smallBannerImg')
    return `${process.env.REACT_APP_CDN_PATH}/banner/exhibitor_list_banner_image/${organiserId}/1036/${imageName}`;
  else return '';
}

export function getSpeakerImage(type, imageName, organiserId) {
  if (type === 'profileImg')
    return `${process.env.REACT_APP_CDN_PATH}/speaker/${organiserId}/150/${imageName}`;
  else return '';
}

export function getLoungeImage(type, imageName, organiserId) {
  if (type === 'logo')
    return `${process.env.REACT_APP_CDN_PATH}/logo/${organiserId}/300/${imageName}`;
  else if (type === 'exhibitor')
    return `${process.env.REACT_APP_CDN_PATH}/exhibitor/${organiserId}/300/${imageName}`;
  else return '';
}

export function getBrandEventImage(type, imageName, organiserId, eventId = undefined) {
  let url = '';
  switch (type) {
    case 'favicon':
      url = `${process.env.REACT_APP_CDN_PATH}/favicon/${organiserId}/32/${imageName}`;
      break;
    case 'logo':
      url = `${process.env.REACT_APP_CDN_PATH}/logo/${organiserId}/300/${imageName}`;
      break;
    case 'community_banner':
      url = `${process.env.REACT_APP_CDN_PATH}/banner/community_banner/${organiserId}/1036/${imageName}`;
      break;
    case 'community_login_banner':
      url = `${process.env.REACT_APP_CDN_PATH}/banner/community_login_banner/${organiserId}/1120/${imageName}`;
      break;
    case 'webappThemeBackground':
      url = `${process.env.REACT_APP_CDN_PATH}/webapp_theme_background/${organiserId}/1920/${imageName}`;
      break;
    case 'CUSTOM':
      url = `${process.env.REACT_APP_CDN_PATH}/cover_image/${organiserId}/${imageName}`;
      break;
    case 'metaSessionBackground':
      url = `${process.env.REACT_APP_CDN_PATH}/meta_session_bg_img/${organiserId}/${imageName}`;
      break;
    case 'defaultSessionBackground':
      url = `${process.env.REACT_APP_CDN_PATH}/meta_session_bg_img/default/${imageName}`;
      break;

    case 'DEFAULT':
      url = `${process.env.REACT_APP_CDN_PATH}/cover_image/default/${imageName}`;
      break;
    case 'default_virtual_background':
      url = `${process.env.REACT_APP_CDN_PATH}/virtual_background/default/images/${imageName}`;
      break;
    case 'virtual_background':
      url = `${process.env.REACT_APP_CDN_PATH}/virtual_background/${eventId}/images/${imageName}`;
      break;
    case 'embed_app':
      url = `${process.env.REACT_APP_CDN_PATH}/embed_app/${eventId}/${imageName}`;
      break;
    default:
      return url;
  }
  return url;
}

export function getConversionPromptImage(imageName, eventId, type = 'conversion_prompt') {
  if (imageName.includes('://')) {
    return imageName;
  }
  return `${process.env.REACT_APP_CDN_PATH}/${type}/${eventId}/${imageName}`;
}

export function getSessionBGImage(type, imageName, organiserId, eventId) {
  let url = '';
  switch (type) {
    case 'metaSessionBackground':
      url = `${process.env.REACT_APP_CDN_PATH}/assets/images/hubiverse/agenda_bg/custom/${organiserId}/${eventId}/${imageName}`;
      break;
    case 'defaultSessionBackground':
      url = `https://cdn.hubilo.com/assets/images/hubiverse/agenda_bg/default/${imageName}`;
      break;
    default:
      return url;
  }
  return url;
}

export function enumerateTimessBetweenDates(startDate, endDate, timeZone, duration = 30) {
  const time = [];
  let startTime = moment(startDate).tz(timeZone).format('hh:mm:ss');
  const endTime = moment(endDate).tz(timeZone).format('hh:mm:ss');
  while (startTime <= endTime) {
    time.push(startTime);
    // time.push({value:startTime,text:startTime});
    startDate = moment(startDate).tz(timeZone).add(duration, 'minutes');
    startTime = moment(startDate).tz(timeZone).format('hh:mm:ss');
  }
  return time;
}

export function enumerateDaysBetweenDates(startDate, endDate, timeZone, format = '') {
  const date = [];
  startDate = moment(startDate).tz(timeZone).format('YYYY-MM-DD');
  endDate = moment(endDate).tz(timeZone).format('YYYY-MM-DD');
  while (startDate <= endDate) {
    if (format !== '') {
      date.push({
        value: moment(startDate).format(format),
        text: moment(startDate).format(format),
      });
    } else {
      date.push({
        value: moment(startDate).format('YYYY-MM-DD'),
        text: `Day${date.length + 1}  - ${moment(startDate).format('DD-MM-YYYY')}`,
      });
    }
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
}

export const formatLink = (link) => {
  let newLink = '';
  const http = 'http://';
  const https = 'https://';
  if (!link) {
    return link;
  }
  if (!link.toLowerCase().includes(http) && !link.toLowerCase().includes(https)) {
    newLink = https + link;
  } else if (link.toLowerCase().includes(http) && !link.toLowerCase().includes(https)) {
    newLink = link.replace(http, https);
  } else newLink = link;
  return newLink;
};

export function getDateAndTimeByTimeStampAndTimeZone(timeStamp, timeZone, format) {
  return moment.tz(timeStamp, timeZone).format(format);
}

export function getTimeStampByAndTimeZoneAndDate(date, timeZone) {
  return moment(date).tz(timeZone).valueOf();
}

export function getUTCTimeStampByAndTimeZoneAndDate(date, timeZone) {
  return moment.tz(date, timeZone).utc().valueOf();
}

export function formatDate(date, format) {
  return moment(date).format(format);
}
export function formatUTCDate(date, format) {
  return moment(date).utc().format(format);
}
export function generateTime(duration = null) {
  const arr = [];
  let curHour = 0;
  let curDurration = 0;
  while (curHour <= 23) {
    if (curDurration === 0) {
      arr.push(`${curHour < 10 ? `0${curHour}` : curHour}:00:00`);
    } else {
      arr.push(`${curHour < 10 ? `0${curHour}` : curHour}:${curDurration}:00`);
    }
    if (duration === null) {
      arr.push(`${curHour < 10 ? `0${curHour}` : curHour}:30:00`);
      curHour++;
    } else {
      curDurration += duration;
      arr.push(`${curHour < 10 ? `0${curHour}` : curHour}:${curDurration}:00`);
      curDurration += duration;
      if (curDurration === 60) {
        curHour++;
        curDurration = 0;
      }
    }
  }
  return arr;
}

export function generateTimeSlot(duration = null) {
  const curDuration = duration === null ? 30 : duration;
  const timesArray = [];
  let startTime = 0;
  for (let i = 0; startTime < 24 * 60; i++) {
    const hh = Math.floor(startTime / 60);
    const mm = startTime % 60;
    timesArray[i] = `${`0${hh % 24}`.slice(-2)}:${`0${mm}`.slice(-2)}:00`;
    startTime += curDuration;
  }

  return timesArray;
}

export function getPeopleImage(type, organiserId, imageName) {
  return `${process.env.REACT_APP_CDN_PATH}/${type}/${imageName}`;
}
export function getAccountProfileImage(type, imageName) {
  return `${process.env.REACT_APP_CDN_PATH}/${type}/${imageName}`;
}

export function getEventLogo(organiserId, imageName) {
  return `${process.env.REACT_APP_CDN_PATH}/logo/${organiserId}/300/${imageName}`;
}

export function getRoomBannerImage(organiserId, imageName) {
  return `${process.env.REACT_APP_CDN_PATH}/banner/breakout_room_poster/${organiserId}/120/${imageName}`;
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
};

export function getFeedVideoThumb(eventId, imageName) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/feed/${eventId}/videos/thumb/${imageName}`;
  } else {
    return 'https://www.fillmurray.com/40/40';
  }
}

export function getFeedImage(eventId, imageName) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/feed/${eventId}/${imageName}`;
  } else {
    return 'https://www.fillmurray.com/40/40';
  }
}

export function getFeedVideo(eventId, video) {
  if (video) {
    return `${process.env.REACT_APP_CDN_PATH}/feed/${eventId}/videos/${video}`;
  } else {
    return 'https://www.fillmurray.com/40/40';
  }
}

export function getFeedDocument(eventId, documentName) {
  return `${process.env.REACT_APP_CDN_PATH}/feed/pdf/${eventId}/${documentName}`;
}

export function getContestVideo(eventId, video) {
  if (video) {
    return `${process.env.REACT_APP_CDN_PATH}/contest/${eventId}/videos/${video}`;
  } else {
    return 'https://www.fillmurray.com/40/40';
  }
}

export function getIcon(imagename) {
  if (imagename) {
    return `${process.env.REACT_APP_CDN_PATH}/icon_png/${imagename}`;
  } else {
    return '';
  }
}

export function getContestImage(eventId, imageName, contestBanner) {
  if (imageName) {
    return `${process.env.REACT_APP_CDN_PATH}/contest/${eventId}/${imageName}`;
  } else {
    if (contestBanner) return '';
    else return 'https://www.fillmurray.com/40/40';
  }
}

export function getColorArray(len) {
  // const colorArray = ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#028BFF", "#46EAFF", "#7652F7", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970"]
  const colorArray = [
    '#4573e7',
    '#fe816d',
    '#9ff3c3',
    '#ec6f86',
    '#ffdd75',
    '#d187ef',
    '#ada7fc',
    '#daff75',
    '#6aecf4',
    '#7e69ff',
    '#ad61ed',
    '#45b4e7',
    '#b2f068',
    '#ffba6d',
    '#fda6f8',
  ];
  if (len <= colorArray.length) {
    return colorArray.slice(0, len);
  } else {
    const newColorArray = [];
    let index = 0;
    while (newColorArray.length < len) {
      newColorArray.push(colorArray[index]);
      index++;

      if (index === colorArray.length) index = 0;
    }
    return newColorArray;
  }
}

export function getDaysCountFromTwoDates(startDate, endDate) {
  if (startDate && endDate) {
    return (
      moment
        .duration(moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD')))
        .asDays() + 1
    );
  } else {
    return -1;
  }
}

export function getSponsorAdImage(type, imageName, organiserId) {
  let url = '';
  url = `${process.env.REACT_APP_CDN_PATH}/sponsoredads/${organiserId}/920/${imageName}`;
  return url;
}

export function getSessionBanner(type, imageName, organiserId) {
  let url = '';
  url = `${process.env.REACT_APP_CDN_PATH}/sessionbanner/${organiserId}/${imageName}`;
  return url;
}

export function msToXhYmZsTime(duration) {
  // let milliseconds = parseInt((duration%1000)/100)
  const seconds = parseInt((duration / 1000) % 60);
  const minutes = parseInt((duration / (1000 * 60)) % 60);
  const hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  /* hours = (hours < 10) ? "0" + hours : hours;
     minutes = (minutes < 10) ? "0" + minutes : minutes;
     seconds = (seconds < 10) ? "0" + seconds : seconds; */

  let time = '';
  if (hours) time = `${time + hours}h `;
  if (minutes) time = `${time + minutes}m `;
  if (seconds) time = `${time + seconds}s`;

  return time;
}

export function msToXhoursYminutesZsecondsTime(duration) {
  const seconds = parseInt((duration / 1000) % 60);
  const minutes = parseInt((duration / (1000 * 60)) % 60);
  const hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  let time = '';
  if (hours) time = time + Math.round(hours) + (hours > 1 ? ' hours ' : ' hour ');
  if (minutes) time = time + Math.round(minutes) + (minutes > 1 ? ' mins ' : ' min ');
  if (seconds) time = time + Math.round(seconds) + (seconds > 1 ? ' secs ' : ' sec ');

  return time;
}

export function numberWithCommas(num) {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Number(num).toLocaleString('en', options);

  // return formatted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCalculatedPage(count, rowpeage) {
  // console.log("total count:--",count);
  // console.log("rowpeage:--",rowpeage);
  const val = count / rowpeage;
  // console.log("val:--",val);
  let finalPage = 0;
  if (val.toString().indexOf('.') !== -1) {
    finalPage = parseInt(val) + 1;
  } else {
    finalPage = parseInt(val);
  }
  // console.log("finalPage:--",finalPage);
  return finalPage;
}

export function getPlanExpirationDay(startDate, endDate) {
  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(parseInt(startDate));
  const secondDate = new Date(parseInt(endDate));
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays;
}

export function getContractExpiresOn(startDate, endDate) {
  const firstDate = new Date(parseInt(startDate));
  const secondDate = new Date(parseInt(endDate));
  const diffDate = new Date(firstDate() - secondDate);
  return diffDate;
}

export function planTypeDisplay(planType) {
  switch (planType) {
    case 'free':
      return 'free';
    case 'advanced':
      return 'advanced';
    case 'pro':
      return 'pro';
    case 'enterprise':
      return 'enterprise';
    case 'basic':
      return 'basic';
    case 'webinar':
      return 'Webinar+';
    case 'digital-events':
      return 'Digital Events';
    case 'all-in':
      return 'All-in';
    default:
      return '';
  }
}

export function creditsLogo(organiserId, imageName) {
  return `${process.env.REACT_APP_CDN_PATH}/favicon/${organiserId}/32/${imageName}`;
}

export function getDateFromMilliSeconds(milliSec) {
  return moment(milliSec).format('DD MMM YYYY');
}

export function getDateWithTimeFromMilliSeconds(milliSec) {
  return moment(milliSec).format('DD MMM YYYY, hh:mm A');
}

export const uptoTwoDecimalPlace = (number) => (number ? Math.ceil(number * 100) / 100 || 0 : 0);

export const truncateFractionAndFormat = (parts, digits) => {
  return parts
    .map(({ type, value }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value;
      }
      let retVal = '';
      retVal = value.slice(0, digits);
      return retVal;
    })
    .reduce((string, part) => string + part);
};

export const getIntlNumberFormat = (number = 0, options) => {
  const defaultOptions = {
    defaultResp: 0,
    isCurrency: false,
    ...options,
  };
  let resp = defaultOptions.defaultResp;
  if (!isNaN(number)) {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: defaultOptions.isCurrency && number < 999 ? 2 : 0,
      maximumFractionDigits: 20,
    });

    resp = truncateFractionAndFormat(formatter.formatToParts(number), 2);
  }
  return resp;
};

export const a11yProps = (index, name) => ({
  id: `${name}-tab-${index}`,
  'aria-controls': `${name}-tab-panel-${index}`,
});

export const getMilliSecondsByDateTime = (dateTime) => (dateTime ? Date.parse(dateTime) : null);

export const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : EMPTY_STR;

export const capitalizeAllWords = (sentence) => {
  if (typeof sentence !== 'string') return sentence;
  return sentence
    ? sentence
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : EMPTY_STR;
};

export const capitalizeWords = (sentence) => {
  if (typeof sentence !== 'string') return sentence;
  if (sentence) return sentence.toUpperCase();
  return EMPTY_STR;
};

export const convertStringItem = (sentence, from = '', to = '') => {
  if (typeof sentence !== 'string') return sentence;
  if (sentence && from && to) {
    return sentence.split(from).join(to);
  }
  if (sentence) return sentence;
  return EMPTY_STR;
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = +Math.floor(Math.log(bytes) / Math.log(1024));
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

export const roundOffTo2 = (amount) => Math.floor(amount * 100) / 100;

export const convertLocalToTimezoneMilli = (timeZone = null) => {
  if (timeZone) {
    moment.tz.setDefault(timeZone);
    return getMilliSecondsByDateTime(getDateWithTimeFromMilliSeconds(moment()));
  }
  return getMilliSecondsByDateTime(moment());
};

export const convertDateToTimezoneMilli = (localDt, timeZone) => {
  if (timeZone && localDt) {
    moment.tz.setDefault(timeZone);
    return getMilliSecondsByDateTime(getDateWithTimeFromMilliSeconds(moment(localDt)));
  }
  return null;
};

// convert utc time to another timezone
export const convertUTCToTimezone = (utcDt, timeZone) => {
  if (timeZone && utcDt) {
    const finalDate = moment.tz(utcDt, timeZone);
    return {
      dateTime: finalDate.format('DD MMM YYYY, hh:mm A'),
      timeStamp: finalDate.valueOf(),
    };
  }
  return null;
};

export const formatDateWithTimeZone = (date) =>
  `${moment
    .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format('YYYY-MM-DD hh:mm A')}`;

// Remove HTML Tags and &nbsp; from the string
export const rmHtmlTags = (reqStr) =>
  reqStr ? reqStr.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;+)/gi, '') : reqStr;

export const objDifference = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key]) ? objDifference(value, base[key]) : value;
    }
    return result;
  });
};

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export const removeDuplicates = (arrayData, field) => {
  return arrayData.filter(
    (thing, index, self) => index === self.findIndex((t) => t[field] === thing[field])
  );
};

export const statusOptions = [
  // { _id: 1, type: REPORT_FILTER_STATUS_OPTIONS.OPEN, name: 'Opened' },
  // { _id: 2, type: REPORT_FILTER_STATUS_OPTIONS.CLICK, name: 'Clicked' },
  { _id: 3, type: REPORT_FILTER_STATUS_OPTIONS.DELIVERED, name: 'Delivered' },
  { _id: 4, type: REPORT_FILTER_STATUS_OPTIONS.BOUNCE, name: 'Bounced' },
  // { _id: 5, type: REPORT_FILTER_STATUS_OPTIONS.PROCESSED, name: 'Processed' },
  // { _id: 6, type: REPORT_FILTER_STATUS_OPTIONS.PENDING, name: 'Pending' },
  { _id: 7, type: REPORT_FILTER_STATUS_OPTIONS.DEFERRED, name: 'Deferred' },
  { _id: 8, type: REPORT_FILTER_STATUS_OPTIONS.DROPPED, name: 'Dropped' },
];

export const statusOptionsForTable = [
  { _id: 1, type: REPORT_FILTER_STATUS_OPTIONS.OPEN, name: 'Opened' },
  { _id: 2, type: REPORT_FILTER_STATUS_OPTIONS.CLICK, name: 'Clicked' },
  { _id: 3, type: REPORT_FILTER_STATUS_OPTIONS.DELIVERED, name: 'Delivered' },
  { _id: 4, type: REPORT_FILTER_STATUS_OPTIONS.BOUNCE, name: 'Bounced' },
  { _id: 5, type: REPORT_FILTER_STATUS_OPTIONS.PROCESSED, name: 'Processed' },
  { _id: 6, type: REPORT_FILTER_STATUS_OPTIONS.PENDING, name: 'Pending' },
  { _id: 7, type: REPORT_FILTER_STATUS_OPTIONS.DEFERRED, name: 'Deferred' },
  { _id: 8, type: REPORT_FILTER_STATUS_OPTIONS.DROPPED, name: 'Dropped' },
];

export const getStatus = (status) => {
  const value = statusOptionsForTable.find((data) => data.type === status);
  return value;
};

export const getNewIdsOfEvent = (data) => {
  if (!data && !Array.isArray(data)) return [];
  return data.map(({ id }) => {
    return id;
  });
};

export const COPY_MODULES = [
  { key: 'virtualbooths', value: 'VIRTUAL BOOTHS', name: 'Virtual Booths' },
  { key: 'lounges', value: 'LOUNGE', name: 'Lounges' },
  { key: 'branding', value: 'BRANDING', name: 'Branding' },
  { key: 'speakers', value: 'PEOPLE', name: 'Speakers' },
  { key: 'sessions', value: 'SESSIONS', name: 'Sessions' },
  { key: 'settings', value: 'SETTINGS', name: 'Settings' },
  { key: 'custom_landing_pages', value: 'REGISTRATION', name: 'Custom Landing Page' },
];

export const getCopyModuleName = (name) => {
  const value = COPY_MODULES.find((data) => data.key === name);
  return value?.value;
};

export const getCopyModuleKey = (name) => {
  const value = COPY_MODULES.find((data) => data.value === name);
  return value?.key;
};

export const getFailedItemName = (name) => {
  const value = COPY_MODULES.find((data) => data.key === name);
  return value?.name;
};
export const convertSecondsToMinutes = (seconds) => {
  const inMinutes = (seconds / 60).toFixed(1);
  return isNaN(inMinutes) ? '-' : `${inMinutes}`;
};

export const getDateTimeHavingTimeZone = (milliSec, timeZone) => {
  if (!milliSec || !timeZone) {
    return null;
  }
  return moment(milliSec).tz(timeZone).format('DD MMM YYYY, hh:mm A');
};

export const getEventTypeFormat = (type) => {
  if (type === EventTypes.HYBRID) {
    return 'Hybrid';
  } else if (type === EventTypes.VIRTUAL) {
    return 'Virtual';
  } else if (type === EventTypes.WEBINAR) {
    return 'Webinar';
  } else if (type === EventTypes.IN_PERSON) {
    return 'In-Person';
  } else return type;
};

export const isFloatValue = (n) => {
  const isFloat = n !== 0 && !isNaN(n) && Math.round(n) !== n;
  return isFloat ? n.toFixed(2) : n;
};

export const checkIsEventEnded = (eventData = {}) => {
  const timeZoneDtls = eventData.EventData.timezone;
  const eventEndDate = convertDateToTimezoneMilli(
    convertUTCToTimezone(+eventData?.EventData?.end_time_milli, timeZoneDtls.name).timeStamp,
    timeZoneDtls.name
  );
  const currentDate = convertDateToTimezoneMilli(
    convertUTCToTimezone(moment.utc(), timeZoneDtls.name).timeStamp,
    timeZoneDtls.name
  );
  return currentDate > eventEndDate;
};

export const getThumbnailUrlForSCHBanner = (playbackId) =>
  playbackId ? `${process.env.REACT_APP_PUBLIC_IMG_MUX_URL_PATH}/${playbackId}/thumbnail.webp` : '';

export const sOrNot = (c) => {
  return c > 1 ? 's' : '';
};

export const getTimeInHrsAndMins = (millis) => {
  const duration = moment.duration(millis);
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (hours > 0 && minutes <= 0) {
    return `${hours} hr${sOrNot(hours)}`;
  } else if (hours > 0 && minutes > 0) {
    return `${hours} hr${sOrNot(hours)} ${minutes} min${sOrNot(minutes)}`;
  } else {
    return `${minutes} min${sOrNot(minutes)}`;
  }
};

export const getMillisInHrsAndMins = (millis) => {
  // Calculate the total minutes
  const totalMinutes = Math.floor(millis / (1000 * 60));

  // Calculate the hours and minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0 && minutes <= 0) {
    return `${hours} hr${sOrNot(hours)}`;
  } else if (hours > 0 && minutes > 0) {
    return `${hours} hr${sOrNot(hours)} ${minutes} min${sOrNot(minutes)}`;
  } else {
    return `${minutes} min${sOrNot(minutes)}`;
  }
};

export const planExpireTypeDisplay = (userPlanInfo) => {
  switch (userPlanInfo.planName) {
    case 'free':
      if (userPlanInfo.subscriptionStatus === 'CANCELLED') {
        return 'FREE TRIAL EXPIRED';
      } else {
        return 'FREE TRIAL';
      }

    case 'advanced':
      if (userPlanInfo.subscriptionStatus === 'CANCELLED') {
        return 'ADVANCED PLAN CANCELLED';
      } else {
        return 'ADVANCED PLAN';
      }
    case 'pro':
      if (userPlanInfo.subscriptionStatus === 'CANCELLED') {
        return 'PRO PLAN CANCELLED';
      } else {
        return 'PRO PLAN';
      }
    case 'enterprise':
      if (userPlanInfo.subscriptionStatus === 'CANCELLED') {
        return 'ENTERPRISE PLAN CANCELLED';
      } else {
        return 'ENTERPRISE PLAN';
      }
    case 'basic':
      if (userPlanInfo.subscriptionStatus === 'CANCELLED') {
        return 'BASIC PLAN CANCELLED';
      } else {
        return 'BASIC PLAN';
      }
    case 'webinar':
      return 'Webinar Multiply';
    case 'webinar_lite':
      return 'Webinar+';
    case 'digital-events':
      return 'Digital Events';
    case 'all-in':
      return 'All-in';

    default:
      return 'Usage';
  }
};


export const translateNumberToWord = (n) => {
  if (n < 0) return false;
  const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  const double_digit = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];
  const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  if (n === 0) return 'Zero';
  function translate(n) {
    let word = '';
    if (n < 10) {
      word = single_digit[n] + ' ';
    } else if (n < 20) {
      word = double_digit[n - 10] + ' ';
    } else if (n < 100) {
      const rem = translate(n % 10);
      word = below_hundred[(n - (n % 10)) / 10 - 2] + ' ' + rem;
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
    } else if (n < 1000000) {
      word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000);
    } else if (n < 1000000000) {
      word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000);
    } else {
      word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000);
    }
    return word;
  }
  const result = translate(n);
  return result.trim();
}

export function getProductWelcomeVideoThumbnail(imageName, organiserId) {
  return `${process.env.REACT_APP_CDN_PATH}/welcome_video/thumbForVideos/${organiserId}/${imageName}`;
}