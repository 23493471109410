/**
 *
 * @param content {string}
 * @returns {string}
 */
const sanitiseIframe = (content) =>
  content
    // replacing special char “
    .replaceAll(`“`, `"`)
    // replacing special char ”
    .replaceAll(`”`, `"`)
    // replacing new line to space
    .replaceAll('\n', ' ')
    // remove space between tags
    .replaceAll(/>\s+</g, '><');

export default sanitiseIframe;
