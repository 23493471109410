import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  chartBlock: {
    paddingRight: '8px !important',

    '& .MuiCardHeader-root': {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
      '& .MuiCardHeader-title': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    '& .MuiCardContent-root': {
      padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    },

    '& img': {
      width: '100%',
      marginTop: '10px',
    },
    '& .cardBox': {
      height: '100%',
      '& .recharts-wrapper, & .recharts-wrapper svg, & .recharts-wrapper .recharts-legend-wrapper ':
        {
          width: '100% !important',
          '& text': {
            fill: '#474747',
          },
          '& .recharts-tooltip-cursor': {
            // background: `url(${tooltipCursorBg}) center center repeat`,
            // fill: 'none',
            fill: '#f7f7f7',
            // fill: 'linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);',
          },
        },
      '& .MuiCardHeader-title, & .MuiCardHeader-subheader': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& .legendBlock': {
        '& .legendTextBlock': {
          border: `1px solid ${theme.palette.border.dark}`,
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          marginLeft: `${theme.spacing(1.5)}px`,
          borderRadius: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .legendColor': {
            width: '9px',
            height: '9px',
            borderRadius: '100%',
            display: 'inline-block',
            marginRight: `${theme.spacing(0.8)}px`,
          },
          '& .legendText': {
            lineHeight: '20px',
          },
          '&:first-child': {
            marginLeft: 0,
          },
        },
      },
    },
  },
}));
