/* eslint-disable  */
import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import InputField from 'custom-components/form-fields/inputField';
import { PlusCircle, CheckCircle, Trash2, X } from 'react-feather';
import CheckBox from 'custom-components/form-fields/checkbox2';
import FieldWrapper from './fieldWrapper';
import SelectDropDown from './selectDropdown';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
  primaryIconColor: {
    color: theme.palette.primary.light,
  },
  alertColor: {
    color: theme.palette.alert.danger,
  },
  addOptionBtn: {
    '& .plusIcon': {
      marginLeft: theme.spacing(1),
    },
    '& .startIconBtn': {
      paddingLeft: 0,
      paddingRight: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
    '& .plusIconLeft': {
      marginRight: theme.spacing(1),
    },
    '& .clear-btn': {
      fontSize: theme.fontSize.size9,
      color: theme.palette.primary.light,
      textTransform: 'capitalize',
      padding: theme.spacing(0.7),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  optionList: {
    '&:nth-child(n+1)': {
      marginTop: theme.spacing(1),
    },
  },
  textColor: {
    color: theme.palette.text.grayColor,
  },
}));
export default function OptionList(props) {
  const classes = useStyles();
  const isShowClearSelection = props.options.filter((x) => x.isSelected === true);

  const {
    hideFieldsCheckbox = false,
    endAdornment = true,
    disableDropDowns = false,
    isStartAdornment = true,
    btnStartIcon = false,
    btnVariant = 'outlined',
    containerClassName,
    endAdornmentIcon = false,
  } = props;

  return (
    <>
      <FieldWrapper
        formControlClassName={props.formControlClassName}
        label={props.label}
        id={props.id}
        error={props.error}
        required={props.required}
        descType={props.descType}
        toolTipPlacement={props.toolTipPlacement}
        descText={props.descText}>
        {!props.isMultiSelect ? (
          <>
            {props.isMultiple ? (
              <>
                {!isEmpty(props.options) &&
                  props.options.map((data, i) => (
                    <Box
                      key={i}
                      className={`${classes.optionList} ${containerClassName}`}
                      display="flex"
                      justifyContent="space-between">
                      <Box width={props.isSelectOption ? '65%' : '100%'}>
                        <InputField
                          placeholder={props.placeholder}
                          value={data.value}
                          onChange={(e) => props.onChange(e.target.value, i)}
                          bothActionIconBtn
                          maxLength={props.maxLength ? props.maxLength : 50}
                          error={data.error}
                          startAdornment={isStartAdornment}
                          startIconBtnAdornment={isStartAdornment}
                          onLeftBtnClick={() => {
                            props.checkBtn(i, data.isSelected);
                          }}
                          startBtnIcon={
                            isStartAdornment ? (
                              <CheckCircle
                                size={20}
                                checked={data.isSelected}
                                className={
                                  data.isSelected ? classes.primaryIconColor : classes.textColor
                                }
                                disabled={!!(data.value && data.value.trim() === '')}
                              />
                            ) : (
                              false
                            )
                          }
                          endAdornment={endAdornment}
                          endIconBtnAdornment
                          isPartitionLine={props.isPartitionLine}
                          endBtnIcon={
                            props.options.length > (props?.defaultOptionsLength || 2) &&
                            !props.optionDisabled ? (
                              endAdornmentIcon ? (
                                <X size={18} className={classes.alertColor} />
                              ) : (
                                <Trash2 size={20} className={classes.textColor} />
                              )
                            ) : (
                              false
                            )
                          }
                          onBtnClick={() => {
                            props.deleteBtn(i);
                          }}
                          leftBtnDisabled={props.leftBtnDisabled}
                          rightBtnDisabled={props.rightBtnDisabled}
                          disabled={props.optionDisabled}
                          isLanguageSupport={props.isLanguageSupport}
                        />
                      </Box>
                      {props.isSelectOption ? (
                        <Box width="30%">
                          <SelectDropDown
                            labelKey="name"
                            valueKey="name"
                            isArrayOfObject
                            placeholder="Select"
                            options={props.dropDownProps}
                            onChange={props.handleDropDown}
                            disabled={disableDropDowns}
                          />
                        </Box>
                      ) : (
                        false
                      )}
                    </Box>
                  ))}
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={props.isSelectOption ? '65%' : '100%'}>
                    <InputField
                      placeholder="Enter Value"
                      bothActionIconBtn
                      maxLength={props.maxLength ? props.maxLength : 50}
                      startAdornment
                      startIconBtnAdornment
                      onLeftBtnClick={() => props.checkBtn()}
                      startBtnIcon={<CheckCircle size={20} className={classes.textColor} />}
                      endAdornment={endAdornment}
                      endIconBtnAdornment
                      onBtnClick={() => {
                        props.deleteBtn();
                      }}
                      endBtnIcon={<Trash2 size={20} className={classes.textColor} />}
                      disabled={props.optionDisabled}
                      isLanguageSupport={props.isLanguageSupport}
                    />
                  </Box>
                  {props.isSelectOption ? (
                    <Box width="30%">
                      <SelectDropDown
                        placeholder="Select"
                        options={props.dropDownProps}
                        onChange={props.handleDropDown}
                        disabled={disableDropDowns}
                      />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={props.isSelectOption ? '65%' : '100%'}>
                    <InputField
                      placeholder="Enter Value"
                      bothActionIconBtn
                      maxLength={props.maxLength ? props.maxLength : 50}
                      startAdornment
                      startIconBtnAdornment
                      onLeftBtnClick={() => props.checkBtn()}
                      startBtnIcon={<CheckCircle size={20} className={classes.textColor} />}
                      endAdornment={endAdornment}
                      endIconBtnAdornment
                      onBtnClick={() => {
                        props.deleteBtn();
                      }}
                      endBtnIcon={<Trash2 size={20} className={classes.textColor} />}
                      disabled={props.optionDisabled}
                      isLanguageSupport={props.isLanguageSupport}
                    />
                  </Box>
                  {props.isSelectOption ? (
                    <Box width="30%">
                      <SelectDropDown
                        placeholder="Select"
                        options={props.dropDownProps}
                        onChange={props.handleDropDown}
                        disabled={disableDropDowns}
                      />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            {props.isMultiple ? (
              <>
                {props.options.map((data, i) => (
                  <Box
                    key={i}
                    className={classes.optionList}
                    display="flex"
                    justifyContent="space-between">
                    <Box width={props.isSelectOption ? '65%' : '100%'}>
                      <InputField
                        placeholder={props.placeholder}
                        value={data.value}
                        disabled={props.optionDisabled}
                        maxLength={props.maxLength ? props.maxLength : 50}
                        error={data.error}
                        onChange={(e) => props.onChange(e.target.value, i)}
                        onLeftBtnClick={() => {
                          props.checkBtn(i, data.isSelected);
                        }}
                        startAdornment={!hideFieldsCheckbox}
                        startIconAdornment
                        startAdornmentIcon={
                          <CheckBox
                            disabled={!!(data.value && data.value.trim() === '')}
                            checked={data.isSelected === undefined ? false : data.isSelected}
                            onChange={(e) => {
                              props.handleCheckBox(i, data.isSelected);
                            }}
                            color="primary"
                          />
                        }
                        endAdornment={endAdornment}
                        endIconBtnAdornment
                        onBtnClick={() => {
                          props.deleteBtn(i);
                        }}
                        endBtnIcon={
                          props.options.length > props.defaultOptionsLength ? (
                            <Trash2 size={20} className={classes.textColor} />
                          ) : (
                            false
                          )
                        }
                        isLanguageSupport={props.isLanguageSupport}
                      />
                    </Box>
                    {props.isSelectOption ? (
                      <Box width="30%">
                        <SelectDropDown
                          labelKey="name"
                          value={data.drpOption}
                          valueKey="name"
                          isArrayOfObject
                          placeholder="Select"
                          options={props.dropDownProps}
                          onChange={(value) => {
                            props.handleDropDown(value, data, i);
                          }}
                          disabled={disableDropDowns}
                        />
                      </Box>
                    ) : (
                      false
                    )}
                  </Box>
                ))}
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={props.isSelectOption ? '65%' : '100%'}>
                    <InputField
                      placeholder="Enter Value"
                      onChange={(e) => props.onChange(e.target.value)}
                      startAdornment
                      startIconAdornment
                      maxLength={props.maxLength ? props.maxLength : 50}
                      disabled={props.optionDisabled}
                      startAdornmentIcon={<CheckBox color="primary" />}
                      endAdornment={endAdornment}
                      endIconBtnAdornment
                      onBtnClick={props.deleteBtn}
                      endBtnIcon={<Trash2 size={20} className={classes.textColor} />}
                      isLanguageSupport={props.isLanguageSupport}
                    />
                  </Box>
                  {props.isSelectOption ? (
                    <Box width="30%">
                      <SelectDropDown
                        placeholder="Select"
                        options={props.dropDownProps}
                        onChange={props.handleDropDown}
                        disabled={disableDropDowns}
                      />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <Box width={props.isSelectOption ? '65%' : '100%'}>
                    <InputField
                      placeholder="Enter Value"
                      disabled={props.optionDisabled}
                      maxLength={props.maxLength ? props.maxLength : 50}
                      onChange={(e) => props.onChange(e.target.value)}
                      startAdornment
                      startIconAdornment
                      startAdornmentIcon={<CheckBox color="primary" />}
                      endAdornment={endAdornment}
                      endIconBtnAdornment
                      onBtnClick={props.deleteBtn}
                      endBtnIcon={<Trash2 size={20} className={classes.textColor} />}
                      isLanguageSupport={props.isLanguageSupport}
                    />
                  </Box>
                  {props.isSelectOption ? (
                    <Box width="30%">
                      <SelectDropDown
                        placeholder="Select"
                        options={props.dropDownProps}
                        onChange={props.handleDropDown}
                        disabled={disableDropDowns}
                      />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </FieldWrapper>
      {props.addOptionListAction ? (
        <Box
          className={classes.addOptionBtn}
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between">
          <Button
            disabled={props.disabledAddOptionBtn}
            variant={btnVariant}
            color="primary"
            className={btnStartIcon ? 'startIconBtn' : false}
            size="small"
            onClick={props.addOption}>
            {btnStartIcon ? <PlusCircle size={17} className="plusIconLeft" /> : false}{' '}
            {props.addOptionBtnLabel}{' '}
            {!btnStartIcon ? <PlusCircle size={17} className="plusIcon" /> : false}
          </Button>
          {isShowClearSelection.length > 0 ? (
            <Button
              size="small"
              className="clear-btn"
              onClick={() => {
                props.clearBtn();
              }}>
              Clear Selection
            </Button>
          ) : (
            false
          )}
        </Box>
      ) : (
        false
      )}
    </>
  );
}
