import React from "react";

export default function SvgSpanishFlag(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 10C0 11.2232 0.220039 12.3949 0.621992 13.4783L10 14.3478L19.378 13.4783C19.78 12.3949 20 11.2232 20 10C20 8.77681 19.78 7.60508 19.378 6.52176L10 5.65219L0.621992 6.52176C0.220039 7.60508 0 8.77681 0 10H0Z"
        fill="#FFDA44"
      />
      <path
        d="M19.378 6.52176C17.965 2.71379 14.2996 0 9.99996 0C5.70031 0 2.03488 2.71379 0.621948 6.52176H19.378Z"
        fill="#D80027"
      />
      <path
        d="M0.621948 13.4783C2.03488 17.2862 5.70031 20 9.99996 20C14.2996 20 17.965 17.2862 19.378 13.4783H0.621948Z"
        fill="#D80027"
      />
    </svg>
  );
}
