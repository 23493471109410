import React from 'react';

export default function SvgMailIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M3.28819 3.42334H16.4413C17.3456 3.42334 18.0855 4.1632 18.0855 5.06748V14.9323C18.0855 15.8366 17.3456 16.5765 16.4413 16.5765H3.28819C2.38391 16.5765 1.64404 15.8366 1.64404 14.9323V5.06748C1.64404 4.1632 2.38391 3.42334 3.28819 3.42334Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0855 5.06787L9.86476 10.8224L1.64404 5.06787"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
