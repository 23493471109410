export const env = process.env.REACT_APP_ENV_TYPE;
// eslint-disable-next-line import/no-mutable-exports
let projectSetting = {};

const amplitudeApiKeys = {
  qa: '9cf0ba92651e32b5047d5fa7cead4017',
  dev: '9be6d0d90b37ce3c25154da2a7355016',
  prod: 'd0f15abb2c6b285600ddde5349252971',
};

const mixPanelToken = {
  prod: '3c575775f6a55c19490d1a1066f323cf',
  dev: 'd0b24eaaa9e467288e5e2e83a314e7f9',
  qa: 'd0b24eaaa9e467288e5e2e83a314e7f9',
};

projectSetting = {
  recaptchaSiteKey: '6LcMLrMbAAAAAN_DMswsjzwAbP1cYqLM4DRBTIkp',
  baseURL: `https://newdashboard.${env}.demohubilo.com/backend/api/v2`,
  customBaseURL: `https://newdashboard-api.${env}.demohubilo.com/api/v1`,
  customBaseURL2: `https://newdashboard-api.${env}.demohubilo.com/api/v2`,
  customBaseURL3: `https://ticketing.${env}.demohubilo.com/api/v1`,
  customBaseURL4: `https://api.${env}.demohubilo.com/api/v2`,
  customBaseURL5: `https://campaign-manager.${env}.demohubilo.com`,
  customBaseURL6: `https://event-ms.${env}.demohubilo.com`,
  documentDomain: `https://document.${env}.demohubilo.com/api/v1`,
  hubiloUrl: `http://redirect.${env}.demohubilo.com?url=`,
  odinBaseUrl: `https://odin.${env}.demohubilo.com/odin/api/v1`,
  hydraBaseUrl: `https://hydra.${env}.demohubilo.com/hydra/api/v1`,
  reportDomain: `https://report.${env}.demohubilo.com/api/v2`,
  socialLogin: 'https://social.hubilo.com/',
  buildversion: process.env.REACT_APP_BUILD_VERSION,
  intercomId: 'fwpwzetu',
  isAmplitudeUsed: true,
  isMixpannelUsed: false,
  defaultHybridEventAddress: { lat: 37.7939738, lng: -122.4033288 },
  google_map_api_key: 'AIzaSyCh0fFh6OQ0Vnb2MjgNDmmSUdB-Xrilh58',
  ticketingLiveTimeStamp: 1644473820000,
  tinyMCEApiKey: 'kgaelo5l5teujgwtx5hmmut900axio5tettq40ic62m2fy2a',
  isDisableCopyEvent: false,
};

if (env.includes('qa')) {
  projectSetting = {
    ...projectSetting,
    amplitudeApiKey: amplitudeApiKeys.qa,
    mixPanelToken: mixPanelToken.qa,
  };
} else if (env === 'base' || env === 'release' || env === 'prod') {
  projectSetting = {
    ...projectSetting,
    amplitudeApiKey: amplitudeApiKeys.prod,
    mixPanelToken: mixPanelToken.prod,
  };
} else {
  projectSetting = {
    ...projectSetting,
    amplitudeApiKey: amplitudeApiKeys.dev,
    mixPanelToken: mixPanelToken.dev,
  };
}

if (env === 'prod') {
  projectSetting = {
    ...projectSetting,
    recaptchaSiteKey: '6LdcH-IUAAAAABSOgEnswXb_t3I-AicUyRKEU78z',
    baseURL: 'https://dash.hubilo.com/backend/api/v2',
    customBaseURL: 'https://dash.hubilo.com/api/v1',
    customBaseURL2: 'https://dash.hubilo.com/api/v2',
    customBaseURL3: 'https://ticketing-api.hubilo.com/api/v1',
    customBaseURL4: 'https://api-v2.hubilo.com/api/v2',
    customBaseURL6: 'https://event-ms.hubilo.com',
    customBaseURL5: 'https://campaign-manager.hubilo.com',
    reportDomain: 'https://report.hubilo.com/api/v2',
    hubiloUrl: 'https://redirect-v2.hubilo.com?url=',
    documentDomain: 'https://document.hubilo.com/api/v1',
    odinBaseUrl: 'https://odin.hubilo.com/odin/api/v1',
    hydraBaseUrl: 'https://hydra.hubilo.com/hydra/api/v1',
  };
}

// Backend API BaseUrl
export const baseURL = 'https://dash.hubilo.com/backend/api/v2/';

export const PreRecordedVideoUpload = null;

export const apiSuccess = 200;

export const orgIds = [
  1337769, 1204778, 1457195, 449329, 448861, 367132, 312423, 1790771, 1337769, 286497, 1578627,
  1219808, 1493885, 345144, 353897, 345136,
];

export const hubiloPostmanAPIDoc = 'https://developer.hubilo.com/';

export const restrictingFileUploadSize = {
  virtualBooth: {
    maxFileSize: 100,
  },
  session: {
    maxFileSize: 100,
  },
};

export const restrictingCharacerInput = {
  minMobileNo: 7,
  maxMobileNo: 20,
  event: {
    name: 100,
    description: 4000,
    eventMetaTypeValue: 100,
    customEventType: 32,
  },
  registration: {
    ticketName: 150,
    description: 500,
  },
  session: {
    title: 200,
    description: 8500,
  },
  people: {
    // currently not adding
    firstName: 100,
    lastName: 100,
    emailId: 15,
    mobileNumber: 14,
    About: 1000, // only this is specified
    designation: 50,
    organisation: 50,
    city: 50,
    whatsappNumberex: 50,
    customTextArearField: {
      name: 50,
      emailId: 50,
      number: 50,
    },
  },
  brandYourEvent: {
    themeName: 50,
    videoTitle: 50,
    videoDescription: 140,
  },
  virtualBooth: {
    boothName: 150,
    productLabel: 30,
    maxFileSize: 100,
    priority: 3,
    about: 5000,
    mobileNumber: 15, // 15 and should not show count
    contactEmailId: 100, // should not show count
    address: 500,
    whatsappNumber: 15, // same should not show count
    productImage: {
      title: 100,
      description: 1000,
      buttonLabel: 50,
    },
    customCta: {
      buttonLabel: 20,
      description: 1000,
      moreDescription: 1200,
    },
    customTags: {
      name: 50,
    },
  },
  meeting: {
    meetingDescription: 300,
  },
  lounge: {
    tableName: 35,
    topic: 50,
    priority: 3,
  },
  rooms: {
    roomName: 100,
    roomDescription: 200,
    minimumNumber: 5, // max limit 12, min- n/a
    priority: 3,
  },
  engagement: {
    title: 100,
    description: 300,
    notifications: {
      description: 160,
    },
  },
  survey: {
    surveyName: 50,
    question: 190,
    tagLine: 160,
    ctaLabel: 25,
    sessionCtaLabel: 12,
  },
  brandEvent: {
    customUrl: 100, // not given
  },
  leaderBoard: {
    title: 200,
    description: 1000,
  },
  settings: {
    // prefilled
    eventSection: {
      label: 20,
      addSection: {
        sectionName: 20,
      },
    },
    eventSectionV2: {
      label: 15,
      addSection: {
        sectionName: 15,
      },
      boothLabel: 50,
    },
    payoutMethod: {
      // not available
      holderName: 20,
      accountNumber: 20,
      bankName: 20,
      branchName: 20,
      ifscCode: 20,
    },
    taxAndInvoicing: {
      taxName: 200,
      taxRate: 200,
      registeredName: 200,
      taxId: 200,
      city: 200,
      address: 200,
      otherDetails: 200,
      pin: 10, // not needed
      invoicePrefix: 20,
      invoiceSuffix: 20,
    },
    groups: {
      groupName: 25,
    },

    profileFields: {
      firstName: 50,
      lastName: 50,
      about: 1000,
      designation: 150,
      organisation: 150,
      cityTown: 100,
      websiteEx: 200, // not specified
    },
    matchmakingFields: {
      customise: {
        label: 200, // not specified
        values: 50,
      },
    },
    senderDetails: {
      emailId: 200, // n/a
      cc: 100,
      bcc: 100,
      senderName: 50,
    },
    welcomeMailer: {
      emailSubject: 150,
      emailTitle: 100,
      emailBody: 4000,
      loginCtaLabel: 100,
    },
    legal: {
      label: 200,
      termsAndConditions: 50000, // n/a
      label2: 200,
      privacyPolicyDesc: 50000, // n/a
      label3: 200,
      cookiePolicyDesc: 50000, // n/a
    },
    privacy: {
      consentName: 100,
      consentText: 300,
    },
    seo: {
      title: 100,
      metaKeyword: 300,
      metaDescription: 500,
    },
  },
  sponsorAd: {
    title: 75,
  },
  ticketSettings: {
    transactionalEmailProps: {
      emailSubject: 100,
      emailBody: 500,
    },
    incTransactionalEmailProps: {
      emailSubject: 100,
      emailBody: 500,
      ctaLabel: 100,
    },
    taxSettingProps: {
      taxRate: 6,
      taxName: 255,
      sendTaxInvoiceProps: {
        registeredName: 255,
        taxId: 18,
        city: 255,
        zipCode: 10,
        invoiceNumber: 9,
        invoiceBibNumber: 9, // TODO need confirm from Dhroov
      },
    },
    landingPageSettings: {
      goToEventCTALabel: 50,
    },
    gdprCompliance: {
      consentText: 500,
    },
    buyerFormFields: {
      fieldName: 50,
      group: {
        fieldType: 50,
        buttonLabel: 50,
      },
      file: {
        maxFileSize: 3,
      },
    },
  },
  newTicketAdd: {
    ticketName: 50,
    description: 500,
    emailSubject: 100,
    emailTitle: 100,
    emailBody: 1500,
    ctaLabel: 100,
    taxName: 50,
    invoiceName: 50,
    invoiceAddress: 96,
    price: 6,
    noOfTicketsAvailable: 7,
    buyLimitPerUser: 4,
  },
  embedApps: {
    name: 15,
    embedCode: 5000, // TODO : as per mayank added 5000 max limit for Iframe
  },
  copy_event: {
    name: 100,
    description: 4000,
  },
  UTM: 32,
};

export default projectSetting;
