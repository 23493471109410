import React from 'react';

const CustomContextQuiz = React.createContext();

// custom context for create event
export function useCustomQuizContext() {
  return React.useContext(CustomContextQuiz);
}

export default CustomContextQuiz;
