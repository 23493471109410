/* eslint-disable  */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CanShow from './canShow';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CustomToolTip from 'custom-components/customToolTip';

import {
  File,
  Edit2,
  Trash2,
  CheckCircle,
  Slash,
  Send,
  Tag,
  Mail,
  DollarSign,
  Download,
  Copy,
  Archive,
  UserCheck,
} from 'react-feather';
import { HUB_CONST } from '../constants/common';
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Toolbar,
  Typography,
  // Tooltip,
  // IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  // FormControlLabel,
  Button,
  Fade,
  IconButton,
} from '@material-ui/core';
import SkeletonLoader from './skeletonLoader';
import Parser from 'html-react-parser';

function generateArray(start, end, cb) {
  const foo = [];
  for (let i = start; i <= end; i++) {
    foo.push(cb ? cb(i) : i);
  }
  return foo;
}

function generatePaginationArray(start, end) {
  const foo = [];
  for (let i = start; i <= end; i++) {
    // foo.push(cb ? cb(i) : i);
    foo.push({ page: i, offsetLeft: i, offsetRight: end - i });
  }
  return foo;
}

function EnhancedTableHead(props) {
  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.showCheckBox ? (
          <TableCell variant="head" padding="checkbox">
            <Checkbox
              className={`${props.classes.tableHeaderCheckbox} ${
                props.numSelected >= props.rowsPerPage ? 'selected-all' : ''
              } `}
              color="primary"
              size="small"
              disabled={props.isInitLoading || props.disabled}
              indeterminate={
                !props.selectAll && props.numSelected > 0 && props.numSelected < props.rowCount
              }
              checked={
                props.isInitLoading
                  ? false
                  : props.numSelected === props.rowCount || props.selectAll
              }
              onChange={props.onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        ) : (
          false
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            variant="head"
            className={`${headCell.fixedCell ? 'fixedC' : ''} ${headCell.fixedPosition} ${props.stickyHeader ? 'fixedHead' : ''}`}
            key={headCell.id}
            align={headCell.numeric ? (headCell?.align ? headCell?.align : 'right') : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={props.orderBy === headCell.id ? props.order : false}
            style={{ whiteSpace: 'pre-line', lineHeight: 1.5 }}>
            {headCell.hasSort ? (
              <TableSortLabel
                active={props.orderBy === headCell.id}
                direction={props.orderBy === headCell.id ? props.order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ width: headCell.width || 'auto' }}>
                {Parser(headCell.label)}
                {headCell.tooltip && (
                  <CustomToolTip
                    isFabIcon
                    title={headCell.tooltip}
                    placement="top"
                    className="relative"
                  />
                )}
                {props.orderBy === headCell.id ? (
                  <span className={props.classes.visuallyHidden}>
                    {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <Box display="inline-flex" position="relative" width={headCell.width || 'auto'}>
                {headCell.tooltip && (
                  <CustomToolTip isFabIcon title={headCell.tooltip} placement="top" />
                )}
                {Parser(headCell.label)}
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    minHeight: 48,
    // justifyContent: 'space-between'
  },
  toolBar2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    minHeight: 45,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.bg.whiteOff1,
        }
      : {
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    // flex: '1 1 100%',
    lineHeight: `normal`,
  },
  toolbarActions: {
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(1),
    },
  },
  toolbarBtnIcon: {
    color: theme.palette.text.grayColor,
  },
  svgIcon: {
    width: 18,
    height: 18,
    color: theme.palette.text.grayColor,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const { RefundIcon = null, refundTitle = 'Refund' } = props;
  const selectedCount = props.excludedIds?.length > 0 ? props.excludedCount : props.count;
  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: props.numSelected > 0,
        })}>
        {props.numSelected > 0 || props.selectAll ? (
          <>
            {props.selectAll ? (
              <Typography
                className={classes.title}
                color="textPrimary"
                variant="subtitle2"
                component="p">
                {selectedCount < 9 ? `0${selectedCount}` : selectedCount} Selected
              </Typography>
            ) : (
              <Typography
                className={classes.title}
                color="textPrimary"
                  variant="subtitle2"
                component="p">
                {props.numSelected < 9 ? `0${props.numSelected}` : props.numSelected} Selected
              </Typography>
            )}
            <Box className={classes.toolbarActions} ml={2.5} display="flex" alignItems="center">
              <CanShow canShow={props.hasEdit && props.numSelected === 1}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.EDIT)}
                  color="primary"
                  variant="text"
                  disabled={props?.disableEdit}
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Edit2 className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Edit
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasDelete}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.DELETE)}
                  color="primary"
                  variant="text"
                  disabled={props?.disableDelete}
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Trash2 className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Delete
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasInviteEmail}>
                <Button
                  onClick={() => props.handelAction('INVITE-EMAIL')}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Send className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    {/* Send Invite Mailer */}
                    Send Invite Email
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasGroup}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.GROUP)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Tag className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Assign Groups
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasResendInvite}>
                <Button
                  onClick={() => props.handelAction('RESEND-INVITE')}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Mail className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Resend Invite
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasResendConfirmation}>
                <Button
                  onClick={() => props.handelAction('RESEND-CONFIRMATION')}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Mail className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Resend Confirmation
                  </Box>
                </Button>
              </CanShow>

              <CanShow canShow={props.hasResendMail}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.RESEND_MAIL)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Mail className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Resend Email
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasRefund && props.numSelected === 1}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.REFUND)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      {RefundIcon ? (
                        <RefundIcon className={classes.svgIcon} />
                      ) : (
                        <DollarSign className={classes.toolbarBtnIcon} size={16} />
                      )}
                    </Box>
                    {refundTitle}
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasApprove && props.numSelected === 1}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.APPROVE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <CheckCircle className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Approve
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasDisapprove && props.numSelected === 1}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.DISAPPROVE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Slash className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Disapprove
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasResendPaymentReq}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.RESEND_PAYMENT_REQUEST)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Mail className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Resend Payment Request
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasReject && !props.selectAll}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.REJECT)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Slash className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Reject
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasRemoveEmailFilter}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.REMOVE_EMAIL_FILTER)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <UserCheck className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Remove Email Filter
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasBlock}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.BLOCK)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Slash className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Block
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasActive}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.ACTIVE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <CheckCircle className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Unblock
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasDownload}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.DOWNLOAD)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Download className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Download
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasRole}>
                <Button
                  onClick={() => props.handelAction('CHANGE-ROLE')}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Tag className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Change Role
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasDuplicate && props.numSelected === 1}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.DUPLICATE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Copy className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Duplicate
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasArchive}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.ARCHIVE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Archive className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Archive
                  </Box>
                </Button>
              </CanShow>
              <CanShow canShow={props.hasUnArchive}>
                <Button
                  onClick={() => props.handelAction(HUB_CONST.UNARCHIVE)}
                  color="primary"
                  variant="text"
                  size="small">
                  <Box
                    display="flex"
                    alignItems="center"
                    component="span"
                    className="text-capitalize">
                    <Box display="inline-flex" component="span" marginRight={1}>
                      <Archive className={classes.toolbarBtnIcon} size={16} />
                    </Box>
                    Unarchive
                  </Box>
                </Button>
              </CanShow>
            </Box>
          </>
        ) : (
          <Box />
        )}
      </Toolbar>
      <CanShow canShow={props.numSelected >= props.rowsPerPage}>
        <Toolbar
          className={clsx(classes.toolBar2, {
            [classes.highlight]: props.numSelected > 0,
          })}>
          <Box className={classes.toolbarActions} display="flex" alignItems="center">
            {props.showSelectAll &&
            props.numSelected === props.rowsPerPage &&
            props.count > props.rowsPerPage ? (
              <Box display="flex" alignItems="center">
                {!props.selectAll ? (
                  <>
                    <Box component="span">
                      <Typography
                        color="textPrimary"
                        variant="caption">{`All ${props.numSelected} rows on this page are selected`}</Typography>
                    </Box>
                    <Box ml={1}>
                      <Button
                        onClick={() => props.setSelectAll(true)}
                        color="primary"
                        variant="text"
                        size="small">
                        <Typography variant="caption" className="text-capitalize" component="span">
                          Select All {props.count} rows
                        </Typography>
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box component="span">
                      <Typography
                        color="textPrimary"
                        variant="caption">{`All ${props.count} rows on are selected`}</Typography>
                    </Box>
                    <Box ml={1}>
                      <Button
                        onClick={() => {
                          props.setSelectAll(false);
                          props.setSelected([]);
                        }}
                        color="primary"
                        variant="text"
                        size="small">
                        <Typography variant="caption" component="span" className="text-capitalize">
                          Clear Selection
                        </Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              false
            )}
          </Box>
        </Toolbar>
      </CanShow>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',

    '& .norecords': {
      color: theme.palette.text.grayColor,
      padding: theme.spacing(2),
      margin: theme.spacing(5),
      fontSize: theme.fontSize.size8,
      fontWeight: 400,
      textAlign: 'center',
      display: 'block',
    },
  },
  table: {
    minWidth: 750,
    '& tr': {
      '& td': {
        '& *': {
          overflow: `hidden`,
          textOverflow: `ellipsis`,
        },
      },
    },
  },

  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeaderCheckbox: {
    position: 'relative',
    zIndex: 1,
    '&.selected-all': {
      top: -44,
    },
  },
  tablePaginationSelectRoot: {
    marginRight: 'auto',
  },
  tablePaginationSpacer: {
    display: 'none',
  },
  tablePaginationToolbar: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  tablePaginationCaption: {
    fontSize: '0.8125rem',
  },
  tablePaginationSelect: {
    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  tableContainer: {
    paddingTop: theme.spacing(5.5),
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    '& > *': {
      marginLeft: theme.spacing(1.5),
    },
    '&:fist-child': {
      marginLeft: 0,
    },
  },
  tableNavBtn: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 4,
    backgroundColor: 'white',
    color: theme.palette.text.grayColor,
    padding: 0,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
  },
  tablePaginationNavBtn: {
    fontSize: theme.fontSize.size9,
    borderRadius: 4,
    width: 18,
    height: 18,
    backgroundColor: theme.palette.bg.whiteOff1,
    '&.active': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.extraLight,
    },
  },
  dotBtn: {
    color: `${theme.palette.text.primary} !important`,
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onChangePage, onPageChange } = props;

  const handleBackButtonClick = () => {
    onChangePage ? onChangePage(page + 1 - 1) : onPageChange(page + 1 - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage ? onChangePage(page + 1 + 1) : onPageChange(page + 1 + 1);
  };

  const handleGoToPage = (e, pageNo) => {
    onChangePage ? onChangePage(pageNo) : onPageChange(pageNo);
  };

  const paginationArr = React.useMemo(
    () => generatePaginationArray(0, Math.ceil(count / rowsPerPage) - 1),
    [count, rowsPerPage]
  );
  // const paginationArr = React.useMemo(() => generatePaginationArray(0, (getCalculatedPage(count, rowsPerPage) - 1)), [count, rowsPerPage])

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        size="small"
        className={classes.tableNavBtn}
        aria-label="previous page">
        <ArrowLeftIcon />
      </IconButton>
      {Math.ceil(count / rowsPerPage) - 1 < 8
        ? generateArray(0, Math.ceil(count / rowsPerPage) - 1, (pageNo) => (
            // {(getCalculatedPage(count,rowsPerPage) - 1) < 8 ?
            // 	generateArray(0, (getCalculatedPage(count,rowsPerPage) - 1), (pageNo) => (
            <IconButton
              onClick={(e) => handleGoToPage(e, pageNo + 1)}
              disabled={page === pageNo}
              key={pageNo}
              size="small"
              className={` ${page === pageNo ? 'active' : ''} ${classes.tablePaginationNavBtn}`}>
              {pageNo + 1}
            </IconButton>
          ))
        : paginationArr.map((el, i) => {
            if (
              el.page === 0 ||
              el.page === Math.ceil(count / rowsPerPage) - 1 ||
              page === el.page ||
              page === el.page - 1 ||
              page === el.page + 1
            ) {
              // if (el.page === 0 || el.page === (getCalculatedPage(count, rowsPerPage) - 1) || page === el.page || page === el.page - 1 || page === el.page + 1) {
              return (
                <IconButton
                  onClick={(e) => handleGoToPage(e, el.page + 1)}
                  disabled={page === el.page}
                  key={el.page}
                  size="small"
                  className={` ${page === el.page ? 'active' : ''} ${
                    classes.tablePaginationNavBtn
                  }`}>
                  {el.page + 1}
                </IconButton>
              );
            } else if (page - el.page === 2 || page + 2 === el.page) {
              return (
                <IconButton
                  // onClick={(e) => handleGoToPage(e, el.page + 1)}
                  disabled
                  key={el.page}
                  size="small"
                  className={`${classes.tablePaginationNavBtn} ${classes.dotBtn}`}>
                  ...
                </IconButton>
              );
            }
            return false;
          })}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page + 1 === Math.ceil(count / rowsPerPage)}
        // disabled={(page + 1) === getCalculatedPage(count, rowsPerPage)}
        size="small"
        className={classes.tableNavBtn}
        aria-label="next page">
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTable(props) {
  const classes = useStyles();

  const {
    isInitLoading,
    rows,
    showPagination = true,
    hasDelete = true,
    rowsUi = {},
    disabled,
    overlayClassName,
    disableEdit = false,
    disableDelete = false,
    paperOverlay,
    stickyHeader = false,
  } = props;

  function handleRequestSort(event, property) {
    if (isInitLoading) return false;
    const isDesc = props.orderBy === property && props.order === 'desc';
    if (props.manageOptionMultiple) {
      props.manageOptionMultiple({ order: isDesc ? 'asc' : 'desc', orderBy: property });
    } else {
      props.setOrder(isDesc ? 'asc' : 'desc');
      props.setOrderBy(property);
    }
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelection = props.rows.map((n) => n.id);
      props.setSelected(newSelection);
    } else {
      if (props.selectAll) {
        props.setSelectAll(false);
        props.setSelected([]);
        props.setExcludedIds([]);
      } else {
        props.setSelected([]);
        props.setExcludedIds([]);
      }
    }
  }
  function handleClick(event, id) {
    const selectedIndex = props.selected.indexOf(id);
    // reset SelectAll when unchecked and row
    if (!props?.routePage === 'orders')
      if (selectedIndex !== -1 && props.selectAll) props.setSelectAll(false);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1)
      );
    }

    props.setSelected(newSelected);
  }

  function handleChangePage(newPage) {
    props.setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    if (props.manageOptionMultiple)
      props.manageOptionMultiple({ page: 1, rowsPerPage: +event.target.value });
    else {
      props.setRowsPerPage(+event.target.value);
      props.setPage(1);
    }
    if (props.selectAll) {
      props.setSelectAll(false);
    }
    if (props.setSelected) {
      props.setSelected([]);
    }
  }

  const isSelected = (id) => props.selected.indexOf(id) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={`${classes.paper} ${paperOverlay}`} variant="outlined">
        {rows.length > 0 ? (
          <>
            <Fade in={Boolean(props.selected.length)}>
              <Box position="absolute" top="0" left="0" right="0" bgcolor="white" zIndex={1}>
                <EnhancedTableToolbar
                  numSelected={props.selected.length}
                  hasDelete={hasDelete}
                  disableEdit={disableEdit}
                  disableDelete={disableDelete}
                  hasEdit={props.hasEdit}
                  hasGroup={props.hasGroup}
                  hasResendInvite={props.hasResendInvite}
                  hasResendConfirmation={props.hasResendConfirmation}
                  hasRefund={props.hasRefund}
                  hasResendMail={props.hasResendMail}
                  hasInviteEmail={props.hasInviteEmail}
                  hasApprove={props.hasApprove || props.hasApproveReject}
                  hasReject={props.hasReject || props.hasApproveReject}
                  hasDisapprove={props.hasDisapprove}
                  hasResendPaymentReq={props.hasResendPaymentReq}
                  hasBlock={props.hasBlock}
                  hasRemoveEmailFilter={props.hasRemoveEmailFilter}
                  hasActive={props.hasActive}
                  hasDownload={props.hasDownload}
                  hasRole={props.hasRole}
                  hasDuplicate={props.hasDuplicate}
                  showSelectAll={props.showSelectAll}
                  selectAll={props.selectAll}
                  setSelectAll={props.setSelectAll}
                  setSelected={props.setSelected}
                  count={props.count}
                  rowsPerPage={props.rowsPerPage}
                  handelAction={props.handelAction}
                  rows={props.rows}
                  hasArchive={props.hasArchive}
                  hasUnArchive={props.hasUnArchive}
                  RefundIcon={props.RefundIcon}
                  refundTitle={props.refundTitle}
                  hasPending={props.hasPending}
                  excludedIds={props?.excludedIds}
                  excludedCount={props?.excludedCount}
                />
              </Box>
            </Fade>
            <TableContainer
              className={`tabelMainContainer ${
                props.selected.length >= props.rowsPerPage ? classes.tableContainer : ''
              }`}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                stickyHeader={stickyHeader}
                aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  stickyHeader={stickyHeader}
                  order={props.order}
                  orderBy={props.orderBy}
                  onRequestSort={handleRequestSort}
                  isInitLoading={isInitLoading || props.isPaginateLoading}
                  numSelected={props.selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  selectAll={props.selectAll}
                  setSelectAll={props.setSelectAll}
                  rowCount={rows.length}
                  headCells={props.headRows}
                  showCheckBox={props.showCheckBox}
                  rowsPerPage={props.rowsPerPage}
                  disabled={disabled}
                />
                {props.isPaginateLoading || isInitLoading ? (
                  <TableBody>
                    {new Array(props.rowsPerPage).fill().map((row, i) => (
                      <TableRow key={i}>
                        {!props.showCheckBox ? (
                          false
                        ) : (
                          <TableCell key={props.headRows.length + 1} padding="checkbox">
                            <Checkbox
                              color="primary"
                              size="small"
                              disabled
                              checked={false}
                              disabled={disabled}
                            />
                          </TableCell>
                        )}
                        {props.headRows.map((eachCell, j) => {
                          return (
                            <TableCell key={j}>
                              <SkeletonLoader
                                width="90px"
                                height="22px"
                                skeletonProps={{ ml: eachCell.numeric ? 'auto' : '0' }}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody className={overlayClassName}>
                    {rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          // className={'cursor-pointer'}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}>
                          {props.showCheckBox ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => {
                                  handleClick(event, row.id);
                                  if (props?.handleSelect) {
                                    props?.handleSelect(event, row);
                                  }
                                }}
                                color="primary"
                                size="small"
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                disabled={disabled || row.isDisabled}
                              />
                            </TableCell>
                          ) : (
                            false
                          )}
                          {props.headRows.map((eachCell, index) => {
                            let TempComp;
                            if (
                              typeof rowsUi[eachCell.valueKey] === 'object' &&
                              rowsUi[eachCell.valueKey].type === 'REACT-COMP'
                            ) {
                              TempComp = rowsUi[eachCell.valueKey].Comp;
                            }
                            return TempComp ? (
                              <TableCell
                                className={`${eachCell.fixedCell ? 'fixedC' : ''} ${
                                  eachCell.fixedPosition
                                }`}
                                key={index}
                                align={`${
                                  eachCell.hasOwnProperty('align')
                                    ? row[eachCell.align]
                                    : typeof row[eachCell.valueKey] === 'number'
                                    ? 'right'
                                    : 'left'
                                }`}>
                                <TempComp data={row[eachCell.valueKey]} />
                              </TableCell>
                            ) : typeof row[eachCell.valueKey] !== 'object' &&
                              eachCell.valueKey !== 'id' ? (
                              <TableCell
                                className={`${eachCell.fixedCell ? 'fixedC' : ''} ${
                                  eachCell.fixedPosition
                                }`}
                                key={index}
                                align={`${
                                  eachCell.hasOwnProperty('align')
                                    ? row[eachCell.align]
                                    : typeof row[eachCell.valueKey] === 'number'
                                    ? 'right'
                                    : 'left'
                                }`}>
                                {props.isShowZero
                                  ? row[eachCell.valueKey]
                                  : row[eachCell.valueKey] || '-'}
                              </TableCell>
                            ) : typeof row[eachCell.valueKey] === 'object' ? (
                              <TableCell
                                key={index}
                                className={`${eachCell.fixedCell ? 'fixedC' : ''} ${
                                  eachCell.fixedPosition
                                }`}>
                                {(rowsUi[eachCell.valueKey] &&
                                  rowsUi[eachCell.valueKey](row[eachCell.valueKey])) ||
                                  '-'}
                              </TableCell>
                            ) : (
                              false
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {showPagination ? (
              <TablePagination
                classes={{
                  toolbar: classes.tablePaginationToolbar,
                  spacer: classes.tablePaginationSpacer,
                  selectRoot: classes.tablePaginationSelectRoot,
                  caption: classes.tablePaginationCaption,
                  select: classes.tablePaginationSelect,
                }}
                rowsPerPageOptions={[5, 10, 50]}
                component="div"
                labelRowsPerPage="Showing records per page"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                labelDisplayedRows={() => false}
                page={props.page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            ) : (
              false
            )}
          </>
        ) : (
          <>
            {props.isPaginateLoading || isInitLoading ? (
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                  stickyHeader={stickyHeader}
                aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  order={props.order}
                    stickyHeader={stickyHeader}
                  orderBy={props.orderBy}
                  onRequestSort={handleRequestSort}
                  isInitLoading={isInitLoading || props.isPaginateLoading}
                  numSelected={props.selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  selectAll={props.selectAll}
                  setSelectAll={props.setSelectAll}
                  rowCount={rows.length}
                  headCells={props.headRows}
                  showCheckBox={props.showCheckBox}
                  rowsPerPage={props.rowsPerPage}
                />
                {/* {props.isPaginateLoading || isInitLoading ? */}
                <TableBody>
                  {new Array(props.rowsPerPage).fill().map((row, i) => (
                    <TableRow key={i}>
                      {!props.showCheckBox ? (
                        false
                      ) : (
                        <TableCell key={props.headRows.length + 1} padding="checkbox">
                          <Checkbox color="primary" size="small" disabled checked={false} />
                        </TableCell>
                      )}
                      {props.headRows.map((eachCell, j) => {
                        return (
                          <TableCell key={j}>
                            <SkeletonLoader
                              width="90px"
                              height="22px"
                              skeletonProps={{ ml: eachCell.numeric ? 'auto' : '0' }}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5" component="span" className="norecords">
                  {' '}
                  {props.defaultImage ? props.imageData : <File size={24} />}{' '}
                  <Box component="span" display="block" mt={1}>
                    {props.emptyText ? props.emptyTextData : 'No Records Found'}{' '}
                  </Box>
                </Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
      {/* <FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label="Dense padding"
			/> */}
    </div>
  );
}

export default React.memo(EnhancedTable);
