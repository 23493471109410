import React from 'react';

export default function SvgGoLiveEventIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187.699"
      height="133.249"
      viewBox="0 0 187.699 133.249"
      {...props}>
      <g id="undraw_online_calendar_kvu2" transform="translate(-18 2)">
        <g id="Group_13624" data-name="Group 13624">
          <ellipse
            id="Ellipse_466"
            data-name="Ellipse 466"
            cx="28.843"
            cy="3.449"
            rx="28.843"
            ry="3.449"
            transform="translate(18 124.351)"
            fill="#e6e6e6"
          />
          <path
            id="Path_4520"
            data-name="Path 4520"
            d="M259.063,296.452c-.015.074-.029.145-.046.218a19.543,19.543,0,0,0,.357,9.954H243.415l2.52-11.526a5.887,5.887,0,0,1,2.022-4.446c.107-.094.218-.187.334-.271a5.864,5.864,0,0,1,3.547-1.186h1.369a6.132,6.132,0,0,1,2.4.485c.128.055.254.113.376.176a6.063,6.063,0,0,1,3.918,6.457,19.552,19.552,0,0,0,1.512,9.844l-.834.1A19.53,19.53,0,0,1,259.063,296.452Z"
            transform="translate(-204.867 -260.087)"
            fill="#2f2e41"
          />
          <path
            id="Path_4521"
            data-name="Path 4521"
            d="M263.226,326.945s1.26,5.46-1.26,5.88,6.51,1.68,6.51,1.68-.63-4.41.84-5.88Z"
            transform="translate(-218.693 -288.912)"
            fill="#fbbebe"
          />
          <path
            id="Path_4522"
            data-name="Path 4522"
            d="M224.091,486.77s-.515,6.421,1.676,6.507.974-6.322.974-6.322Z"
            transform="translate(-190.071 -410.953)"
            fill="#fbbebe"
          />
          <path
            id="Path_4523"
            data-name="Path 4523"
            d="M339.643,500.15s2.1,6.09,0,6.72-2.52-5.88-2.52-5.88Z"
            transform="translate(-276.421 -421.17)"
            fill="#fbbebe"
          />
          <path
            id="Path_4524"
            data-name="Path 4524"
            d="M144.206,192.027l2.955-.451,1.244.871,1.05,3.15,2.52,12.81,1.89,8.4,2.94,5.88-4.83,2.1-4.62-15.119Z"
            transform="translate(-92.114 -143.285)"
            fill="#f59c86"
          />
          <path
            id="Path_4525"
            data-name="Path 4525"
            d="M223.6,431.636s-1.26,8.4-.42,10.08c0,0-1.563,9.751-2.73,16.169l-1.68,9.24-3.78,14.069,6.09,1.68,12.389-34.439,3.57,19.319.63,13.859,6.51-.21-.84-24.149s3.78-15.959-2.73-21.839-7.56-5.88-7.56-5.88Z"
            transform="translate(-183.163 -367.25)"
            fill="#2f2e41"
          />
          <path
            id="Path_4526"
            data-name="Path 4526"
            d="M319.769,646.265l1.68,5.67,1.05,5.04s3.78,5.67,0,5.04-4.2-2.73-4.2-2.73v-1.26l-1.05-.63v-2.1s-1.47-1.05,0-2.73l-.63-5.88Z"
            transform="translate(-260.747 -532.74)"
            fill="#2f2e41"
          />
          <path
            id="Path_4527"
            data-name="Path 4527"
            d="M207.41,646.265l-1.68,5.67-1.05,5.04s-3.78,5.67,0,5.04,4.2-2.73,4.2-2.73v-1.26l1.05-.63v-2.1s1.47-1.05,0-2.73l.63-5.88Z"
            transform="translate(-174.007 -532.74)"
            fill="#2f2e41"
          />
          <circle
            id="Ellipse_467"
            data-name="Ellipse 467"
            cx="5.25"
            cy="5.25"
            r="5.25"
            transform="translate(43.272 30.473)"
            fill="#fbbebe"
          />
          <path
            id="Path_4528"
            data-name="Path 4528"
            d="M239.661,347.374s5.46,2.31,6.93,1.89,1.89,1.47,1.89,1.47l4.2,2.94-1.26,7.14s1.05,3.78-.21,6.3c0,0,3.36,1.05,2.1,2.73a2.751,2.751,0,0,1,1.05,3.78s-6.3-1.68-6.93-4.83l-6.51.84-4.62,3.57s-2.31-.21-1.47-3.57a11.978,11.978,0,0,0-.21-6.3l-2.31-14.7Z"
            transform="translate(-196.389 -304.512)"
            fill="#f59c86"
          />
          <path
            id="Path_4529"
            data-name="Path 4529"
            d="M222.6,351.821l-.838.222a1.492,1.492,0,0,0-.751.472l-.272.317a1.927,1.927,0,0,0-.458,1.119l-.187,2.677-.3,13.033.265,8.606-1.381,6.427,5.2.829.7-15.794-1.3-17.42a.548.548,0,0,0-.686-.488Z"
            transform="translate(-185.981 -307.894)"
            fill="#f59c86"
          />
          <path
            id="Path_4530"
            data-name="Path 4530"
            d="M265.27,307.593c-.862-2.087-2.14-4.293-2.983-6.274-.582-2.314-.728-4.294.589-5.307,0-2.228.078-3.681-1.693-4.765a5.891,5.891,0,0,0-1.2.7h0c-.07.051-.138.105-.205.161l-.084.071-.045.038h0a5.886,5.886,0,0,0-2.024,4.445v11.106h6.51c-.215-.938-.5-1.91-.8-2.879.47.938.932,1.882,1.313,2.8Z"
            transform="translate(-215.719 -261.654)"
            fill="#2f2e41"
          />
          <path
            id="Path_4531"
            data-name="Path 4531"
            d="M280.4,297.836a20.567,20.567,0,0,1-9.45,0v-4.2h5.25a4.2,4.2,0,0,1,4.2,4.2Z"
            transform="translate(-225.893 -263.479)"
            fill="#2f2e41"
          />
        </g>
        <g id="Group_13625" data-name="Group 13625" transform="translate(-4 -2)">
          <rect
            id="Rectangle_3833"
            data-name="Rectangle 3833"
            width="130.264"
            height="81.117"
            transform="translate(79.435 9.543)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_3834"
            data-name="Rectangle 3834"
            width="23.858"
            height="15.508"
            transform="translate(88.17 25.17)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3835"
            data-name="Rectangle 3835"
            width="23.858"
            height="15.508"
            transform="translate(117.815 25.17)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3836"
            data-name="Rectangle 3836"
            width="23.858"
            height="15.508"
            transform="translate(147.461 25.17)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3837"
            data-name="Rectangle 3837"
            width="23.858"
            height="15.508"
            transform="translate(177.106 25.17)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3838"
            data-name="Rectangle 3838"
            width="23.858"
            height="15.508"
            transform="translate(88.17 47.119)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3839"
            data-name="Rectangle 3839"
            width="23.858"
            height="15.508"
            transform="translate(117.815 47.119)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3840"
            data-name="Rectangle 3840"
            width="23.858"
            height="15.508"
            transform="translate(147.461 47.119)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3841"
            data-name="Rectangle 3841"
            width="23.858"
            height="15.508"
            transform="translate(177.106 47.119)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3842"
            data-name="Rectangle 3842"
            width="23.858"
            height="15.508"
            transform="translate(88.17 69.068)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3843"
            data-name="Rectangle 3843"
            width="23.858"
            height="15.508"
            transform="translate(117.815 69.068)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3844"
            data-name="Rectangle 3844"
            width="23.858"
            height="15.508"
            transform="translate(147.461 69.068)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3845"
            data-name="Rectangle 3845"
            width="23.858"
            height="15.508"
            transform="translate(177.106 69.068)"
            fill="#fff"
          />
          <circle
            id="Ellipse_468"
            data-name="Ellipse 468"
            cx="3.579"
            cy="3.579"
            r="3.579"
            transform="translate(109.974 11.929)"
            fill="#fff"
          />
          <circle
            id="Ellipse_469"
            data-name="Ellipse 469"
            cx="3.579"
            cy="3.579"
            r="3.579"
            transform="translate(169.141 11.929)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3846"
            data-name="Rectangle 3846"
            width="2.863"
            height="16.462"
            transform="translate(112.121)"
            fill="#e6e6e6"
          />
          <rect
            id="Rectangle_3847"
            data-name="Rectangle 3847"
            width="2.863"
            height="16.462"
            transform="translate(171.288)"
            fill="#e6e6e6"
          />
          <path
            id="Path_4532"
            data-name="Path 4532"
            d="M944.158,300.629a5.38,5.38,0,1,1-.834-2.882,5.38,5.38,0,0,1,.834,2.882Z"
            transform="translate(-749.728 -267.705)"
            fill="#f59c86"
          />
          <path
            id="Path_4533"
            data-name="Path 4533"
            d="M951.15,303.238l-5.311,5.31c-.334-.517-2.193-2.918-2.193-2.918a7.534,7.534,0,0,1,.761-.672l1.542,2.056,4.579-4.579A5.374,5.374,0,0,1,951.15,303.238Z"
            transform="translate(-757.553 -273.197)"
            fill="#fff"
          />
          <path
            id="Path_4534"
            data-name="Path 4534"
            d="M693.367,393.471a5.38,5.38,0,1,1-.834-2.882A5.38,5.38,0,0,1,693.367,393.471Z"
            transform="translate(-558.228 -338.598)"
            fill="#f59c86"
          />
          <path
            id="Path_4535"
            data-name="Path 4535"
            d="M700.359,396.081l-5.311,5.31c-.334-.517-2.193-2.918-2.193-2.918a7.52,7.52,0,0,1,.761-.672l1.542,2.056,4.579-4.579a5.376,5.376,0,0,1,.622.8Z"
            transform="translate(-566.053 -344.09)"
            fill="#fff"
          />
          <path
            id="Path_4536"
            data-name="Path 4536"
            d="M567.972,393.471a5.38,5.38,0,1,1-.834-2.882A5.38,5.38,0,0,1,567.972,393.471Z"
            transform="translate(-462.478 -338.598)"
            fill="#f59c86"
          />
          <path
            id="Path_4537"
            data-name="Path 4537"
            d="M574.964,396.081l-5.311,5.31c-.334-.517-2.193-2.918-2.193-2.918a7.527,7.527,0,0,1,.761-.672l1.542,2.056,4.579-4.579a5.369,5.369,0,0,1,.622.8Z"
            transform="translate(-470.303 -344.09)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
