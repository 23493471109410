const learnMoreLinks = {
  eventListing:
    'https://support.hubilo.com/hc/en-us/categories/9209438811405-Explore-the-Dashboard',
  eventOverview:
    'https://support.hubilo.com/hc/en-us/articles/9713170281869-What-is-Event-Overview',

  sessionPage: 'https://support.hubilo.com/hc/en-us/sections/9630284059917-Create-Sessions',
  sessionAdvanceChatLink:
    'https://support.hubilo.com/hc/en-us/articles/9859299396493-What-is-the-Chat-option-in-a-session-How-to-enable-it-for-a-session',
  sessionAdvanceQALink:
    'https://support.hubilo.com/hc/en-us/articles/9859201713549-How-to-enable-Q-A-in-a-session',
  sessionAdvancePollLink:
    'https://support.hubilo.com/hc/en-us/articles/9859225953677-What-are-Polls-in-a-session',
  sessionAttendeeListLink:
    'https://support.hubilo.com/hc/en-us/articles/9859428313613-How-to-enable-attendees-to-see-other-attendees-during-the-session',
  sessionUpcomingsessionLink:
    'https://support.hubilo.com/hc/en-us/articles/9858213344781-How-to-enable-Attendees-to-see-the-upcoming-agenda-and-switch-to-other-Sessions',
  sessionAdvanceInterprefyTokenLink:
    'https://support.hubilo.com/hc/en-us/articles/11372592924301-How-to-set-up-an-Interprefy-interpretation-service-for-a-session',

  ticketsAddLink: '#',
  ticketsConnectStripe: '#',
  ticketsWidget:
    'https://support.hubilo.com/hc/en-us/articles/9713175485709-Embed-Registration-Ticket-Widget',

  advanceSessionWaitListLink:
    'https://support.hubilo.com/hc/en-us/articles/9857950271885-How-to-approve-attendee-s-sessions-registrations-which-are-on-the-Waitlist',
  advanceSessionVisibilityLink:
    'https://support.hubilo.com/hc/en-us/articles/9857754053517-What-is-Session-visibility',
  advanceSessionRegistrationLink:
    'https://support.hubilo.com/hc/en-us/articles/9857909373965-What-is-Session-Registration',

  sessionStreamYoutubeLink:
    'https://support.hubilo.com/hc/en-us/articles/9714591401101-How-to-add-YouTube-as-a-streaming-source',
  sessionStreamVimeoLink:
    'https://support.hubilo.com/hc/en-us/articles/9717811915917-How-to-add-Vimeo-as-a-streaming-source',
  sessionStreamZoomLink:
    'https://support.hubilo.com/hc/en-us/articles/9717523870477-How-to-add-Zoom-Webinar-as-a-streaming-source',
  sessionStreamCustomLink:
    'https://support.hubilo.com/hc/en-us/articles/9878346829965-How-to-add-Hubilo-Streaming-as-a-streaming-source',
  sessionStreamOtherLink:
    'https://support.hubilo.com/hc/en-us/articles/9878540054925-How-to-add-Other-live-streaming-as-a-stream-source',
  sessionStreamIBMLink:
    'https://support.hubilo.com/hc/en-us/articles/9717869289101-How-to-add-IBM-Watson-as-a-streaming-source',
  sessionStreamFBLink:
    'https://support.hubilo.com/hc/en-us/articles/9715324216845-How-to-add-Facebook-Player-as-a-streaming-source',
  sessionStreamPreRecordedLink:
    'https://support.hubilo.com/hc/en-us/articles/9717910826253-How-to-add-pre-recorded-video-as-a-stream-source',
  sessionStudioHostLink:
    'https://support.hubilo.com/hc/en-us/articles/9879509719437-Host-Guide-for-Hubilo-Broadcasting-Studio',
  sessionStudioHostURLLink:
    'https://support.hubilo.com/hc/en-us/articles/9878379379469-What-is-a-Studio-Link',

  peoplePage: 'https://support.hubilo.com/hc/en-us/sections/9630989118861-All-about-People',
  virtualBoothPage:
    'https://support.hubilo.com/hc/en-us/articles/9718304114829-What-is-a-Virtual-Booth',
  virtualBoothFeatureBoothLink:
    'https://support.hubilo.com/hc/en-us/articles/9879195565709-How-to-feature-the-virtual-booth',
  virtualBoothListingBanner:
    'https://support.hubilo.com/hc/en-us/articles/9879127934861-What-is-a-Listing-Page-Banner-How-to-upload-a-Listing-Page-Banner',
  virtualBootSmallBanner:
    'https://support.hubilo.com/hc/en-us/articles/9879128809997-How-to-Add-Small-Banner',
  virtualBoothSpotlightBanner:
    'https://support.hubilo.com/hc/en-us/articles/9879214330765-What-is-a-spotlight-banner-How-to-upload-a-spotlight-banner',
  virtualBoothCustomCTALink:
    'https://support.hubilo.com/hc/en-us/articles/9879196456333-How-to-create-custom-CTA-on-Virtual-Booths-so-attendees-can-engage-with-the-Virtual-Booth',
  virtualBoothPremiumBooths:
    'https://support.hubilo.com/hc/en-us/sections/9631017402381-Setup-booths',
  virtualBoothExport:
    'https://support.hubilo.com/hc/en-us/articles/9879125382285-How-to-add-Virtual-Booths-for-the-event',

  meetingsPage: 'https://support.hubilo.com/hc/en-us/sections/9631072389389-One-to-One-Meetings',
  loungePage: 'https://support.hubilo.com/hc/en-us/sections/9631033921165-Networking-Lounges',

  roomsPage: 'https://support.hubilo.com/hc/en-us/sections/9631070712333-Manage-Rooms',
  roomInfo:
    'https://support.hubilo.com/hc/en-us/articles/9879579873165-What-is-the-Room-Info-feature-in-Rooms-How-to-enable-it',
  roomsFilterWocanJoin:
    'https://support.hubilo.com/hc/en-us/articles/9879575720589-What-is-the-Who-Can-Join-The-Room-option-in-Rooms',
  roomsFilterWocanShare:
    'https://support.hubilo.com/hc/en-us/articles/11372533199501-What-is-Who-Can-Share-Audio-Video-In-The-Room-in-the-Rooms',

  engagementNotificationPage:
    'https://support.hubilo.com/hc/en-us/articles/9879739267853-What-are-notifications-How-to-create-a-new-notification',
  engagementActivityPage:
    'https://support.hubilo.com/hc/en-us/articles/9719298316045-What-is-the-purpose-of-the-activity-feed',
  engagementLeaderBoardPage:
    'https://support.hubilo.com/hc/en-us/articles/9719320216589-What-is-a-leaderboard',
  brandEventPage: 'https://support.hubilo.com/hc/en-us/sections/9631259795341-Branding',
  brandEventCommBannerLink:
    'https://support.hubilo.com/hc/en-us/articles/9932843900685-What-are-community-banners',
  brandEventEventLogoLink:
    'https://support.hubilo.com/hc/en-us/articles/9932857051917-What-are-the-event-logos-Where-are-they-displayed',
  brandEventLoginBannerLink:
    'https://support.hubilo.com/hc/en-us/articles/9932855519117-What-are-the-login-banners',
  brandEventWelcomeLink:
    'https://support.hubilo.com/hc/en-us/articles/9932858307341-What-is-a-Welcome-Video-and-How-do-you-configure-it',
  brandHideHubiloLink:
    'https://support.hubilo.com/hc/en-us/articles/9932852866061-How-to-brand-your-event',
  brandEventColorsLink:
    'https://support.hubilo.com/hc/en-us/articles/16119813114253-How-to-add-theme-colour-to-the-event',
  brandEventCustomTheme:
    'https://support.hubilo.com/hc/en-us/articles/9932867666829-How-to-add-a-custom-theme-to-your-event-in-the-New-Community',
  brandEventCoverImages:
    'https://support.hubilo.com/hc/en-us/articles/9932868555661-How-to-Add-a-Cover-Image',

  analyticsPage: 'https://support.hubilo.com/hc/en-us/sections/9631286237325-Analytics',
  recordingPage:
    'https://support.hubilo.com/hc/en-us/articles/9719685643277-What-is-the-Recordings-section',
  filelogPage:
    'https://support.hubilo.com/hc/en-us/articles/12019990529293-What-is-file-logs-on-Hubilo',

  settingPage: '',
  settingLoginoptions:
    'https://support.hubilo.com/hc/en-us/articles/9932943095437-Understand-your-event-app-s-Login-Settings',
  settingGeneral:
    'https://support.hubilo.com/hc/en-us/articles/9933202152589-General-Settings-on-the-New-Community',
  settingEventSection:
    'https://support.hubilo.com/hc/en-us/articles/9933012509069-What-is-the-event-section-and-How-to-configure-set-up-visibility-for-different-groups',
  settingEventSectionTopNav:
    'https://support.hubilo.com/hc/en-us/articles/9932935673741-How-to-move-sections-between-the-top-and-side-panels',
  settingGroups:
    'https://support.hubilo.com/hc/en-us/articles/9933013691917-How-to-add-a-group-How-to-set-chat-and-meeting-permissions-for-a-group',
  settingProfileField:
    'https://support.hubilo.com/hc/en-us/articles/9932972032781-What-are-custom-profile-fields-How-to-add-custom-profile-fields-to-the-event',
  settingMatchMaking:
    'https://support.hubilo.com/hc/en-us/articles/9933088500621-What-is-matchmaking-How-to-create-matchmaking-fields',
  settingSenderDetail:
    'https://support.hubilo.com/hc/en-us/articles/9933090780429-What-are-Sender-Details-and-how-is-it-useful',
  settingInviteMailer:
    'https://support.hubilo.com/hc/en-us/articles/9933160664589-What-is-an-invite-mailer-How-to-configure-the-invite-mailer',
  settingLegal:
    'https://support.hubilo.com/hc/en-us/articles/9933162849421-In-legal-settings-how-to-configure-the-labels-descriptions-and-links-for-your-Terms-Conditions-Privacy-Policies-and-Cookie-Policy',
  settingPrivacy:
    'https://support.hubilo.com/hc/en-us/articles/9933165755277-What-is-GDPR-Compliant-How-to-configure-privacy-details',
  settingUserConsent:
    'https://support.hubilo.com/hc/en-us/articles/9933164390413-How-to-set-the-user-consent-for-first-time-users-while-Signup-Login',

  settingSEO:
    'https://support.hubilo.com/hc/en-us/articles/9933200986637-What-is-SEO-and-How-to-configure-it',
  settingSEOConfigure:
    'https://support.hubilo.com/hc/en-us/articles/9933200986637-What-is-SEO-and-How-to-configure-it',
  settingNotification:
    'https://support.hubilo.com/hc/en-us/articles/9933197298957-How-to-configure-notifications-for-the-event-triggers-on-email-and-in-app',
  settingIntegration:
    'https://support.hubilo.com/hc/en-us/articles/9933194618893-How-to-add-custom-code',
  settingCustomintegration:
    'https://support.hubilo.com/hc/en-us/articles/9933194618893-How-to-add-custom-code',
  settingZoomintegration:
    'http://6784438.hs-sites.com/en/knowledge/how-to-integrate-a-zoom-account-with-hubilo',
  settingStudioHosts:
    'https://support.hubilo.com/hc/en-us/articles/9878378137869-What-is-the-Studio-Host-option-in-the-Event-Settings',

  ticketPage: 'https://support.hubilo.com/hc/en-us/sections/9630160899597-Setup-registration',
  stripeConnect:
    'https://support.hubilo.com/hc/en-us/articles/9855170458893-Connect-your-Stripe-account-on-Hubilo-to-sell-paid-tickets',
  orderPage:
    'https://support.hubilo.com/hc/en-us/articles/9855208931213-How-to-track-the-purchase-orders',
  surveyPage:
    'https://support.hubilo.com/hc/en-us/articles/9932396952077-How-to-Create-a-Survey-What-are-the-different-types-of-Surveys',
  sponsor:
    'https://support.hubilo.com/hc/en-us/articles/9719602683149-What-are-Sponsored-Ads-How-to-add-your-creatives-to-the-Ad-space',

  contestPage:
    'https://support.hubilo.com/hc/en-us/articles/9719398176397-What-is-a-Contest-and-how-many-types-of-contests-are-there',
  entriesContestHome: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  entriesContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  moderateContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  responseContestHome: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',

  winnerContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  responseContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  questionContestHome: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  standingContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',
  quizContest: 'https://support.hubilo.com/hc/en-us/sections/9631255704845-Contest',

  creditUsage: 'https://support.hubilo.com/hc/en-us/articles/16121384340749',
  configureCreditType:
    'https://support.hubilo.com/hc/en-us/articles/9860884015117-What-is-CPE-Continuous-Professional-Education-Credit-How-to-create-credit-type-and-certificate',
  creditTypeAndCertificate:
    'https://support.hubilo.com/hc/en-us/articles/9860884015117-What-is-CPE-Continuous-Professional-Education-Credit-How-to-create-credit-type-and-certificate',
  planUpgradation: 'https://support.hubilo.com/hc/en-us/articles/16121384340749',
  peopleCredits: 'https://support.hubilo.com/hc/en-us/articles/16121384340749',
  boothCredits: 'https://support.hubilo.com/hc/en-us/articles/16121384340749',

  contactsPage:
    'https://support.hubilo.com/hc/en-us/articles/12019886755213-What-are-Universal-Contacts',
  multiEventAnalytics:
    'https://support.hubilo.com/hc/en-us/articles/12019933313805-What-does-Multi-Event-Analytics-contain',
  advanceSessionCustomIframe:
    'https://support.hubilo.com/hc/en-us/articles/9878345656717-How-to-set-up-custom-iframe-integrations-in-sessions',

  upgradePlan: 'https://hubilo.com/platform-preview/',
  // v2 Links
  v2Links: {
    orderPage:
      'https://support.hubilo.com/hc/en-us/articles/9855536527373-What-are-orders-in-Registration',
    discountPage:
      'https://support.hubilo.com/hc/en-us/articles/9855449028749-How-to-set-up-Discounts-for-Paid-Tickets',
    ticketSettings: {
      taxSetting:
        'https://support.hubilo.com/hc/en-us/articles/9854824248461-Configure-tax-invoice-settings-for-your-ticket-registrations',
      buyerForm:
        'https://support.hubilo.com/hc/en-us/articles/9854955322893-What-is-the-Buyer-form-in-the-Ticket-setting',
      enableSimpleRegistration:
        'https://support.hubilo.com/hc/en-us/articles/9854498954125-Enable-Simple-Registration',
      otherPaymentMethod:
        'https://support.hubilo.com/hc/en-us/articles/9854742962189-Various-payment-methods-available-for-buying-tickets-for-events',
      newOtherPaymentMethod:
        'https://support.hubilo.com/hc/en-us/articles/16121555185293-Other-Payment-Method-Flow-Ticket-setup-Order-status-and-Pay-at-the-Venue',
    },
    ticket: {
      main: 'https://support.hubilo.com/hc/en-us/articles/9855273388301-How-to-create-new-tickets',
      basicDetails:
        'https://support.hubilo.com/hc/en-us/articles/9855374732813-What-are-Basic-Details-in-Add-Edit-Ticket-in-Hubilo-Event',
      customizeDetails:
        'https://support.hubilo.com/hc/en-us/articles/9855374732813-What-are-Basic-Details-in-Add-Edit-Ticket-in-Hubilo-Event',
      stripeBillingLink:
        'https://support.hubilo.com/hc/en-us/articles/9855170458893-Connect-your-Stripe-account-on-Hubilo-to-sell-paid-tickets',
    },
  },
  session_engagement_section:
    'https://support.hubilo.com/hc/en-us/articles/9859105670669-What-are-the-engagement-options-during-the-session',
  MultiLingualSupport:
    'https://support.hubilo.com/hc/en-us/articles/9931376619533-Selecting-Time-Zone-and-Language-for-Multilingual-Event',
  MultiLingualSupport1:
    'https://support.hubilo.com/hc/en-us/articles/9713157506573-How-to-host-a-Multilingual-event',
  closeCaptionInSession:
    'https://support.hubilo.com/hc/en-us/articles/9878342882573-Closed-Captioning-in-your-Live-sessions-with-Hubilo-Streaming',
  simulcast_this_sesssion:
    'https://support.hubilo.com/hc/en-us/articles/9878538607245-Simulcast-Your-Live-Session',
  advance_meeting_link:
    'https://support.hubilo.com/hc/en-us/articles/9718590168461-How-to-configure-meeting-visibility-and-settings',

  setting_flx_event_section_1:
    'https://support.hubilo.com/hc/en-us/articles/9933012509069-What-is-the-event-section-and-How-to-configure-set-up-visibility-for-different-groups',
  setting_flx_event_section_2:
    'https://support.hubilo.com/hc/en-us/articles/9932935673741-How-to-move-sections-between-the-top-and-side-panels',
  setup_restricted_event:
    'https://support.hubilo.com/hc/en-us/articles/9932873471757-How-to-set-up-log-in-process-for-a-restricted-event',
  custom_login_code:
    'https://support.hubilo.com/hc/en-us/articles/9932874251021-How-to-set-up-a-custom-login-code',
  virtual_background_link: 'https://support.hubilo.com/hc/en-us/articles/15917116789389',
  interactive_live_session_poll:
    'https://support.hubilo.com/hc/en-us/articles/9858866052621-Interactive-Live-Session-Polls',
  interactive_live_session_chat:
    'https://support.hubilo.com/hc/en-us/articles/9859086342797-Interactive-Live-Session-Chats',
  interactive_live_session_qna:
    'https://support.hubilo.com/hc/en-us/articles/9713349186829-Interactive-Live-Session-Q-A',
  ticket_tracking:
    'https://support.hubilo.com/hc/en-us/articles/9854531257229-Add-tracking-pixel-to-event-landing-page-ticketing-page',
  iframe_learn_more:
    'https://support.hubilo.com/hc/en-us/articles/9932850415501-Embeddable-event-section-widgets-on-the-dashboard',

  roomAdvanceChatLink:
    'https://support.hubilo.com/hc/en-us/articles/9879667682701-What-is-the-Live-Chat-feature-in-Rooms-How-to-enable-it',
  roomAdvanceQALink:
    'https://support.hubilo.com/hc/en-us/articles/9879676517773-What-is-a-Q-A-feature-in-Rooms-How-to-enable-it',
  roomAdvancePollLink:
    'https://support.hubilo.com/hc/en-us/articles/9879677627149-What-are-Live-Polls-Feature-in-Rooms-and-how-to-enable-it',
  roomAttendeeListLink:
    'https://support.hubilo.com/hc/en-us/articles/9879666338061-What-are-the-engagement-options-in-Rooms-',

  eventLandingPage:
    'https://support.hubilo.com/hc/en-us/articles/9713263024141-Event-Landing-Page-Overview',
  defaultLandingPage:
    'https://support.hubilo.com/hc/en-us/articles/9857130139661-Event-Landing-Page-Default-Setup',
  customLandingPage:
    'https://support.hubilo.com/hc/en-us/articles/9857232895501-Landing-Page-Builder-Create-Your-Own-Event-Page',
  mentionsLearMoreLink:
    'https://support.hubilo.com/hc/en-us/articles/9932933218573-Mention-users-or-various-sections-on-the-event-platform',
  backgroundModeLearnMoreLink:
    'https://support.hubilo.com/hc/en-us/articles/9932540534413-How-to-manage-embedded-apps-for-each-session-in-an-event',
  termsAndConditions: 'https://www.hubilo.com/terms-of-use',
  privacyPolicy: 'https://hubilo.com/privacy-policy',
  setUpSSO: 'https://support.hubilo.com/hc/en-us/articles/9932613719181-How-to-set-up-SSO',
  emailManager: 'https://support.hubilo.com/hc/en-us/sections/9631100504077-Email-Manager',
  sessionEnhanceLayout: 'https://support.hubilo.com/hc/en-us/articles/16122503680909',
  sessionBGImage: 'https://support.hubilo.com/hc/en-us/articles/15917116789389',
  copyYourExistingEvent:
    'https://support.hubilo.com/hc/en-us/articles/9713146952717-Copy-your-existing-event',
  checkInForSessionOnSiteAttendees: 'https://support.hubilo.com/hc/en-us/articles/16122314805645',
  manageReports:
    'https://support.hubilo.com/hc/en-us/articles/12209822299277-Manage-Analytics-Report',
  creditsInSessions: 'https://support.hubilo.com/hc/en-us/articles/16122121397773',
  rtmpAndStreamKey:
    'https://support.hubilo.com/hc/en-us/articles/9878549771533-What-is-RTMP-Stream-Key',
  copyEventWebinar: 'https://www.hubilo.com/', // [TO DO] : This needs to be updated with learn more link for webinar copy events.
  downloadReportInfo: 'https://www.hubilo.com/',

  webinarRegistrationForm:
    'https://support.hubilo.com/hc/en-us/articles/11370776067597-Setup-registration-form-for-the-webinar',
  enableSimpleRegistration:
    'https://support.hubilo.com/hc/en-us/articles/9854498954125-Enable-Simple-Registration',
  BuyerFormInTicket:
    'https://support.hubilo.com/hc/en-us/articles/9854955322893-What-is-the-Buyer-form-in-the-Ticket-setting',
  QuizPage: 'https://support.hubilo.com/hc/en-us/articles/16117569411597-Quiz-Feature',
  activityScoreBoardUrl:
    'https://support.hubilo.com/hc/en-us/articles/15916891142925-Lead-Score-Introduction-to-the-feature',
  leadScoreBoardUrl:
    'https://support.hubilo.com/hc/en-us/articles/15930489139725-Activity-scoring-Lead-Score',
  leadLevelBoardUrl:
    'https://support.hubilo.com/hc/en-us/articles/15930406877325-Lead-levels-Lead-Scoring',
  leadScoreAnalytics:
    'https://support.hubilo.com/hc/en-us/articles/15930547542541-Analytics-and-Reports-Lead-scoring',
  hubspotSetup: 'https://support.hubilo.com/hc/en-us/articles/15917088743309-1-Hubspot-Setup',
  importDataFromHubSpot:
    'https://support.hubilo.com/hc/en-us/articles/15917350661261-5-Import-data-from-HubSpot-to-Hubilo',
  exportDataFromHubSpot:
    'https://support.hubilo.com/hc/en-us/articles/15917302976269-3-Export-data-from-Hubilo-to-HubSpot',
  exportUserActivitiesHubSpot:
    'https://support.hubilo.com/hc/en-us/articles/15916879946253-4-Export-User-Activities-from-Hubilo-to-HubSpot',
  hubspotContactProperty:
    'https://support.hubilo.com/hc/en-us/articles/15917173699213-2-Hubspot-Contact-Property-Creation',
  hubiloPeopleAnalytics:
    'https://support.hubilo.com/hc/en-us/articles/15408440741517-Summary-of-People-Analytics-view-in-Hubilo-dashboard',
  embeddedApp: 'https://support.hubilo.com/hc/en-us/sections/9631269450637-Embedded-Apps',
  manageEmbeddedAppEventLevel:
    'https://support.hubilo.com/hc/en-us/articles/9932472507405-How-to-manage-embedded-apps-at-the-Event-Level',
  zoomIntegrationlink:
    'https://support.hubilo.com/hc/en-us/articles/16242657591949-Integrate-Your-Zoom-Account-with-Hubilo',
  salesforceSetup:
    'https://support.hubilo.com/hc/en-us/articles/16131783973773-How-to-set-up-and-enable-the-Salesforce-Integration-for-your-Hubilo-event',
  configureSalesforceCampaign:
    'https://help.salesforce.com/s/articleView?id=sf.create_a_campaign.htm&type=5',
  campaignSourceData:
    'https://thespotforpardot.com/2020/12/04/how-to-add-custom-fields-to-campaign-members-in-salesforce/#:~:text=You%20can%20add%20the%20new,icon%20to%20select%20your%20fields.',
  configureSFCampaign:
    'https://support.hubilo.com/hc/en-us/articles/16131845336717-How-to-connect-Salesforce-Campaign-with-your-Hubilo-event',
  configureSFMemberStatus:
    'https://help.salesforce.com/s/articleView?id=sf.campaigns_customize_member_status_parent.htm&type=5',
  configureNotAttendedStatus:
    'https://support.hubilo.com/hc/en-us/articles/16131845336717-How-to-connect-Salesforce-Campaign-with-your-Hubilo-event',
  UTMLink: 'https://support.hubilo.com/hc/en-us/articles/16150911072397-UTM-Tracking',
  emailFiltering: 'https://support.hubilo.com/hc/en-us/articles/15854743702797',
  createActivityTypeInSalesforce:
    'https://experienceleague.adobe.com/docs/marketo/using/product-docs/marketo-sales-connect/crm/salesforce-customization/activity-type-field-on-tasks-sfdc.html?lang=en',
  slackbotBrowsePeople:
    'https://slack.com/intl/en-in/help/articles/360003534892-Browse-people-and-user-groups-in-Slack',
  slackbotAddApp:
    'https://slack.com/intl/en-in/help/articles/202035138-Add-apps-to-your-Slack-workspace#request-app-approval',
  setupMarketo: 'https://support.hubilo.com/hc/en-us/articles/17370059110413-1-Setting-up-Marketo',
  marketoCustomActivity:
    'https://support.hubilo.com/hc/en-us/articles/17370587965581-5-Configure-Attendee-s-Activity-as-Custom-Activities',
  marketoConnectProgram:
    'https://support.hubilo.com/hc/en-us/articles/17370208057101-2-Configuring-Marketo-Program-with-Hubilo-Event',
  marketoCreateGroupId:
    'https://support.hubilo.com/hc/en-us/articles/17370375078925-3-Import-data-from-Marketo-to-Hubilo',
  salesforceSetupActivities:
    'https://support.hubilo.com/hc/en-us/articles/17569342468237-How-to-export-Attendee-activities-from-Hubilo-to-Salesforce-',
  overviewVideoSnippetBanner:
    'https://support.hubilo.com/hc/en-us/articles/9719685643277-What-is-the-Recordings-section-',
  hubspotConfigureImport: 'https://knowledge.hubspot.com/workflows/create-workflows',
  hubspotLoginPage: 'https://app.hubspot.com/myaccounts-beta',
  setupPardot: 'https://support.hubilo.com/hc/en-us/articles/21060018134669',
  pardotConnectProgram:
    'https://support.hubilo.com/hc/en-us/articles/21060088444941',
  pardotCreateMaketingApp:
    'https://support.hubilo.com/hc/en-us/articles/21060061808269',
  pardotAttendeeActivity:
    'https://support.hubilo.com/hc/en-us/articles/21060061808269',
  paradotSalesforceGuide: 'https://help.salesforce.com/s/articleView?language=en_US&id=user_security_token.htm&type=5',
};

export default learnMoreLinks;
