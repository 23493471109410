import React, { useEffect } from 'react';
import AttendeeLeadScoreTable from './attendee-lead-score-table';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { eventDataSelector, userInfoSelector } from 'redux/custom-selector';
import { isSessionEnd } from 'constants/handlers';
import { planTypeDisplay } from 'lib/common-function';
import { getBasicInfoThunk } from '../../../../redux/api-service/common';
import { commonSelector } from 'redux/selectors';
import {
  DefaultEmptyStateForChart,
  PieChartLoader,
} from './lead-level-distribution/PieChartLoader';
import LeadLevelDistribution from './lead-level-distribution';

const AnalyticsLeadScore = () => {
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector, shallowEqual);

  const { isBasicInfoLoading, basicInfo } = useSelector(commonSelector, shallowEqual) || {};

  const sessionHasEnded = isSessionEnd(basicInfo?.sessionEndTimeMilli);

  useEffect(() => {
    dispatch(getBasicInfoThunk());
  }, [dispatch]);

  const eventDataForAmp = {
    event_type: eventData.EventData.eventMetaTypeValue,
    event_format: eventData.EventData.type,
    event_start_date: eventData.EventData.start_time,
    event_end_date: eventData.EventData.end_time,
    plan_type: planTypeDisplay(userInfo.UserInfo.planName),
  };

  if (isBasicInfoLoading) {
    return <PieChartLoader />;
  }
  return (
    <>
      {sessionHasEnded ? (
        <>
          <LeadLevelDistribution />
          <AttendeeLeadScoreTable eventInfo={eventDataForAmp} />
        </>
      ) : (
        <DefaultEmptyStateForChart title="Lead score data will be available sometime after the session ends" />
      )}
    </>
  );
};

export default AnalyticsLeadScore;
