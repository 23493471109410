/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Snackbar, makeStyles, Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { messageSelector } from 'redux/custom-selector';
import { useSource } from 'lib/custom-common-hooks';
import { removeMessage } from 'redux/actions/setting-actions';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    color: theme.palette.primary.contrastText,
  },
  alertStyle: {
    maxWidth: 400,
    minWidth: 400,
    color: '#fff',
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1.5)}px  ${theme.spacing(2)}px`,
    boxShadow: theme.shadows[1],
    borderRadius: 7,
    '&.error': {
      backgroundColor: theme.palette.alert.danger,
      color: theme.palette.primary.contrastText,
    },
    '&.success': {
      backgroundColor: theme.palette.alert.success,
      color: theme.palette.primary.contrastText,
    },
    '&.warning': {
      backgroundColor: theme.palette.alert.warn,
      color: theme.palette.primary.contrastText,
    },
    '&.info': {
      backgroundColor: theme.palette.alert.info,
      color: theme.palette.primary.contrastText,
    },
  },
  snackbarWrapper: {
    position: 'fixed',
    bottom: 24,
    left: 24,
    zIndex: 1300,
  },
  snackbarBlock: {
    position: 'static',
    transform: 'none',
    '& + .MuiSnackbar-root': {
      marginTop: 10,
    },
  },
  boldtext: {
    textTransform: 'capitalize',
    fontWeight: 500,
  },
}));

export default function Notification() {
  const { snackbarWrapper } = useStyles();
  const reduxData = useSelector(messageSelector, shallowEqual);
  return (
    <Box className={snackbarWrapper}>
      {reduxData.messageList &&
        reduxData.messageList.map((notification, index) => (
          <Message key={notification.id} notification={notification} />
        ))}
    </Box>
  );
}

const Message = ({ notification }) => {
  const { closeBtn, alertStyle, snackbarBlock, boldtext } = useStyles();
  const [isOpen, setOpen] = useState(true);
  const sec = notification.sec ? notification.sec : 7000;
  const source = useSource();
  const dispatch = useDispatch();
  if (!source.timeOut) {
    source.timeOut = setTimeout(() => {
      setOpen(false);
      dispatch(removeMessage(notification.id));
    }, sec);
  }
  const onClose = () => {
    setOpen(false);
    dispatch(removeMessage(notification.id));
  };

  useEffect(() => {
    source.timeOut = setTimeout(() => {
      setOpen(false);
      dispatch(removeMessage(notification.id));
    }, sec);
    return () => {
      dispatch(removeMessage(notification.id));
      clearTimeout(source.timeOut);
    };
  }, [notification]);

  return (
    <Snackbar className={snackbarBlock} open={isOpen} onClose={onClose} autoHideDuration={sec}>
      <Box
        className={`${alertStyle} ${notification.type}`}
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <Box display="inline-flex" alignItems="center">
          <Box display="inline-flex">
            {notification.type === 'error' ? <ErrorOutlineOutlinedIcon /> : false}
            {notification.type === 'warning' ? <WarningIcon /> : false}
            {notification.type === 'info' ? <InfoOutlinedIcon /> : false}
            {notification.type === 'success' ? <SuccessIcon /> : false}
          </Box>
          <Box ml={1} display="flex" flexDirection="column">
            <Typography variant="subtitle1" component="span" className={boldtext}>
              {notification.type}
            </Typography>
            <Typography variant="caption" component="span">
              {notification.message}
            </Typography>
          </Box>
        </Box>
        <Box display="inline-flex">
          <IconButton size="small" aria-label="close" onClick={onClose} className={closeBtn}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Snackbar>
  );
};
