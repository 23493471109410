import React from 'react';
import moment from 'moment-timezone';
import { Fade, Paper, Box, Typography, Button } from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userInfoSelector } from 'redux/custom-selector';
import CircularProgress from '../../../custom-components/circularProgress';
import SvgPeopleProgress from '../../../icons/svgPeopleProgressIcon';
import SvgCalendarProgressIcon from '../../../icons/svgCalendarProgressIcon';
import SvgFreetrialRestrictions from '../../../icons/svgFreeTrialRestrictions';
import { useStyles } from './style';
import { planTypeDisplay } from './planTypeDisplay';
import { colors } from 'constants/colors';

export const PlanCancelScheduledMenu = ({ isPlanMenuOpen, setPlanMenuOption }) => {
  const history = useHistory();
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const { planDropdown } = useStyles();
  const planExpiryDate = new Date(Number(UserInfo.subscription_end_date_milli));
  const planExpiry = moment(planExpiryDate).format('D MMM, YYYY');
  return (
    <Fade in={isPlanMenuOpen}>
      <Box
        position="absolute"
        className={`${planDropdown} hasTip`}
        width={280}
        left="-50%"
        zIndex={5}
        mt={3}
        mb={1.2}>
        <Paper elevation={2}>
          <Box className="dropDownBody">
            <Box className="choosePlan" display="flex" alignItems="center">
              <Box width="100%">
                <Box className="currentPlan" display="flex" mb={1} p={2} alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%">
                    <Box>
                      <Typography variant="h6" component="h6">
                        {planTypeDisplay(UserInfo)}
                      </Typography>
                    </Box>
                    <Box>
                      <Button size="small" className="expired-btn" disabled>
                        {' '}
                        Cancelled{' '}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className="expiredPlan" mb={1}>
                  <Box mb={2}>
                    <Typography variant="h6" component="h6" display="block">
                      Your {planTypeDisplay(UserInfo)} will be Premium Until: {planExpiry}
                    </Typography>
                  </Box>
                  {UserInfo.used_attendee_credits >= UserInfo.total_attendee_credits
                    // || UserInfo.subscriptionStatus === 'CANCEL_SCHEDULED'
                    ? (
                    <Box className="expiredSVG">
                      <SvgFreetrialRestrictions />
                    </Box>
                  ) : (
                    false
                  )}
                </Box>
                {
                  <Box className="leftDataPlan" display="flex">
                    <Box mr={2}>
                      <CircularProgress
                        icon={<SvgPeopleProgress />}
                        value={
                          UserInfo.peoplesCreditsUsagePercentage > '100'
                            ? '100'
                            : UserInfo.peoplesCreditsUsagePercentage
                        }
                        size={50}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6" component="h5" display="block" className="text-left">
                        {UserInfo.total_attendee_credits === 0
                          ? UserInfo.used_attendee_credits
                          : `${UserInfo.used_attendee_credits}/${UserInfo.total_attendee_credits}`}
                      </Typography>
                      <Typography variant="caption" component="span" color={colors.textSecondary}>
                        Registrations
                      </Typography>
                    </Box>
                  </Box>
                  // : false
                }

                <Box className="leftDataPlan" display="flex">
                  <Box mr={2}>
                    <CircularProgress
                      icon={<SvgCalendarProgressIcon height="20px" width="20px" />}
                      value={
                        UserInfo.concurrentSessionsUsagePercentage > '100'
                          ? '100'
                          : UserInfo.concurrentSessionsUsagePercentage
                      }
                      size={50}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6" component="h6" display="block">
                      {' '}
                      {UserInfo.totalConcurrentSessionCredit === 0
                        ? UserInfo.usedConcurrentSessionCredit
                        : `${UserInfo.usedConcurrentSessionCredit}/${UserInfo.totalConcurrentSessionCredit}`}
                    </Typography>
                    <Typography variant="caption" component="span" color={colors.textSecondary}>
                      Parallel Sessions/Event
                    </Typography>
                  </Box>
                </Box>

                <Box textAlign="center" className="upgradeActionPlan" mt={1}>
                  <Box mb={1}>
                    <Typography variant="body2" component="label" color={colors.textSecondary}>
                      Your subscription will be cancelled on {planExpiry}.You can use your plan till
                      then with limited functionalities. For the FULL EXPERIENCE abort the
                      cancellation.
                    </Typography>
                  </Box>

                  <Button
                    onClick={() => {
                      history.push('/billing');
                      setPlanMenuOption(false);
                    }}
                    className="uppercase"
                    variant="contained"
                    color={colors.primary}
                    size="small">
                    GO TO BILLING
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};
