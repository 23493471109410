/* eslint-disable  */
import React from 'react';
// import PS from 'shared-local/settings';
import { Editor } from '@tinymce/tinymce-react';
import { Box, makeStyles, Snackbar } from '@material-ui/core';
import FieldWrapper from './fieldWrapper';
import PS from 'settings';
import { useCommunityVersionNumber } from 'lib/hooks/use-community-version';

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .tox :not(svg):not(rect)': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .tox.tox-tinymce': {
      border: `1px solid ${theme.palette.border.dark}`,
      background: theme.palette.bg.white,
      borderRadius: theme.shape.borderRadius,
    },
    '& .tox-editor-header': {
      borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    },
    '& .tox .tox-toolbar, & .tox .tox-toolbar__overflow, & .tox .tox-toolbar__primary': {
      background: theme.palette.bg.whiteOff1,
      cursor: 'pointer',
    },
    '& .tox .tox-edit-area__iframe': {
      background: theme.palette.bg.white,
    },
    '& .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)': {
      borderRightColor: theme.palette.bg.whiteOff2,
    },
  },
  disabled: {
    '& .tox-toolbar': {
      background: '#F5F5F5 !important',
      cursor: 'not-allowed',
    },
    '& .tox-edit-area__iframe': {
      background: '#F5F5F5 !important',
      cursor: 'not-allowed',
    },
    '& .mce-content-readonly': {
      color: 'rgb(160, 154, 187) !important',
    },
  },
  '@global': {
    body: {
      '& .tox-silver-sink': {
        '& .tox-dialog-wrap': {
          '& .tox-dialog-wrap__backdrop': {
            backgroundColor: 'rgba(0,0,0,0.5)',
          },
          '& .tox-dialog': {
            '& .tox-dialog__header': {
              padding: theme.spacing(2),
              borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
              '& .tox-dialog__title': {
                fontFamily: 'Helvetica Neue New',
                color: theme.palette.text.primary,
              },
            },
            '& .tox-dialog__body-content': {
              '& .tox-form__group': {
                marginBottom: theme.spacing(1.5),
                '& .tox-label': {
                  marginBottom: theme.spacing(1),
                  fontFamily: 'Helvetica Neue New',
                  fontSize: theme.fontSize.theme8,
                  color: theme.palette.text.primary,
                },
                '& .tox-textfield': {
                  '&:focus': {
                    outline: 'none',
                    borderColor: theme.palette.primary.main,
                  },
                },
              },
            },
            '& .tox-dialog__footer': {
              justifyContent: 'flex-start',
              padding: theme.spacing(2),
              '& .tox-button': {
                background: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                borderRadius: 4,
                border: 'none',
                fontFamily: 'Helvetica Neue New',
                fontWeight: 500,
                lineHeight: 1,
                padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
                marginRight: theme.spacing(1.5),
                marginLeft: 0,
              },
              '& .tox-button--secondary': {
                background: `${theme.palette.primary.contrastText} !important`,
                border: `1px solid ${theme.palette.primary.light} !important`,
                color: `${theme.palette.primary.light} !important`,
              },
            },
          },
        },
      },
    },
  },
  borderHighlightML: {
    //  borderColor: theme.palette.alert.warn2,
    //  boxShadow: `0px 0px 4px ${theme.palette.alert.warn2}`,
    '& .tox-tinymce': {
      border: `1px solid ${theme.palette.alert.warn2} !important`,
      boxShadow: `0px 0px 4px ${theme.palette.alert.warn2}`,
    },
  },
  snackbarBlock: {
    position: 'static',
    transform: 'none',
    marginTop: 10,
    '& .MuiSnackbar-root': {
      marginTop: 10,
    },
    '& .MuiSnackbarContent-root': {
      minWidth: 0,
      maxHeight: 40,
      backgroundColor: theme.palette.text.grayColor,
    },
    '& .MuiSnackbarContent-message': {
      padding: '0',
    },
  },
}));

export default function EditorField(props) {
  const { onChange, value, name, id, setup, extraToolbar = '', formViewType } = props;
  const classes = useStyles();
  let characterCount;
  let pastedContent;
  const editorRef = React.useRef(null);
  const [toast, setToast] = React.useState(false);
  const eventCommunityVersion = useCommunityVersionNumber();
  // const readTextFromClipboard = async (e) => {
  // 	if (navigator.clipboard) {
  // 		text = await navigator.clipboard.readText().then(text => {return text});
  // 	return text;
  // 	  }
  // 	  else {
  // 		text = e.clipboardData.getData('text/plain');
  // 		return text;
  // 	  }

  // }
  // text = readTextFromClipboard();
  if (props.descText !== undefined && props.descText !== null) {
    characterCount = props.descText.toString().split(' ');
  }
  // const [isContentPasted,setIsContentPasted] = React.useState(false);
  // if(isContentPasted){
  // 	if(characterCount[0]<0){

  // 		alert("You cant have more than 1000 characters")
  // 	}
  // }
  const handleEditorChange = (content, e) => {
    // const target = editorRef.current.editor;
    if (content.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;+)/gi, '').length >= props.maxLength) {
      // let allowedKeys = [8, 37, 38, 39, 40, 46]; // backspace, delete and cursor keys
      // e.on('keydown', function (e) {
      // 	if (allowedKeys.indexOf(e.keyCode) != -1) return true;
      // 	if (wordcount.body.getCharacterCount()> this.settings.max_chars) {
      // 		e.preventDefault();
      // 		e.stopPropagation();
      // 		return false;
      // 	}
      // 	return true;
      // });
      e.on(
        'keydown',
        function (e) {
          if ([13].indexOf(e.keyCode) > -1) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            // your code here
            return false;
          }
        },
        true
      );

      e.off('paste');
    }
    // if(pastedContent!=undefined){
    // 	if(content.replace( /(<([^>]+)>)/ig, '').length+pastedContent.length>props.maxLength){
    // 		let trimmedTex = pastedContent.substring(0,pastedContent.length-(content.replace( /(<([^>]+)>)/ig, '').length+pastedContent.length-props.maxLength));
    // 		console.log("trimmed",trimmedTex)
    // 		//content = content+trimmedTex
    // 	console.log("pasted",pastedContent.length)
    // 	console.log("length",content.replace( /(<([^>]+)>)/ig, '').length)
    // 	}

    // }

    // e.on('paste',function(e){

    // })

    if (content.replace(/(<([^>]+)>)/gi, '').replace(/(&nbsp;+)/gi, '').length <= props.maxLength) {
      // e.on('keydown', function(e) {
      // 	console.log(e)
      // 	if (
      // 		[38, 40, 13].indexOf(e.keyCode) > -1 //Enter and up/down arrows or whatever you want
      // 	) {
      // 		e.defaultPrevented = true;
      // 		e.stopPropagation();
      // 		e.stopImmediatePropagation();
      // 		//your code here
      // 		return true;

      // 	}
      // },false);
      onChange({ target: { value: content, name } });
      e.off('keydown');
    }

    // e.on('oncopy',function(e){
    // 	console.log("on paste",e.currentTarget.innerText)
    // 	console.log("current content length",content.replace( /(<([^>]+)>)/ig, '').length)
    // 	if(content.replace( /(<([^>]+)>)/ig, '').length+e.currentTarget.innerText.length>=props.maxLength){
    // 		e.currentTarget.innerText = "HHHHHHH";
    // 		console.log("hello",content.replace( /(<([^>]+)>)/ig, '').length+e.currentTarget.innerText.length)

    // 	}
    // })
    // console.log("handleEditorChange",characterCount[0])
    // console.log("value",value)
    // value.toString();
    // strippedValueString = value.replace( /(<([^>]+)>)/ig, '');
    // console.log("d",strippedValueString.length);
    // console.log("ref",editorRef.current.props.value.replace( /(<([^>]+)>)/ig, '').length);
  };

  // React.useEffect((content,e)=>{
  // 	handleEditorChange(content,e);
  // },[props.descText])

  // const onMouseDownHandler = (e) => {
  // 	console.log("before paste down")
  // }
  // const onMouseEnterHandler = (e) => {
  // 	console.log("before paste")
  // }
  const handleKeyPress = (e) => {
    if (characterCount !== undefined)
      if (+characterCount[0] <= 0) {
        return false;
      }
  };
  function handlePaste(e) {
    // console.log("handlepaste",e.type)
    // setIsContentPasted(true);
    // console.log(isContentPasted)
    // console.log(characterCount[0])
    // let text = await readTextFromClipboard();
    if (characterCount[0] !== undefined)
      // console.log("handlepaste",characterCount[0])

      pastedContent = e.clipboardData.getData('Text');
    // console.log("paste", characterCount[0]-pastedContent.length);
    // console.log("editorref",editorRef.current.currentContent.replace( /(<([^>]+)>)/ig, ''))
    if (characterCount !== undefined && pastedContent !== undefined) {
      // console.log("handle paste",text.length)
      // console.log("characterCount in paste",(characterCount[0] - text.length))

      if (props.maxLength < +characterCount[0] || +characterCount[0] - pastedContent.length < 0) {
        setToast(true);
        e.preventDefault();
        return false;
      }
    }
  }

  React.useEffect(() => {
    const lisFunc = (e) => {
      // const textf = document.getElementsByClassName('tox-textfield');
      // if (textf && textf.length ) {
      //   textf[0].setAttribute('autoFocus',true)
      // } 
      const ele = document.getElementsByClassName('tox-dialog');
      if (ele && ele.length && e.target.hasAttribute('tabindex')) {
        e.target.removeAttribute('tabindex');
      } else if (!e.target.hasAttribute('tabindex')) {
        e.target.setAttribute('tabindex', '-1');
      }
    };
    if (formViewType === 'sidebar') window.addEventListener('focusin', lisFunc);
    return () => {
      if (formViewType === 'sidebar') window.removeEventListener('focusin', lisFunc);
    };
  }, [formViewType]);
  return (
    <FieldWrapper
      formControlClassName={`${props.disabled ? classes.disabled : ''} ${
        props.formControlClassName
      }`}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <Box
        className={`${classes.editor} ${props.isLanguageSupport ? classes.borderHighlightML : ''}`}>
        <Editor
          ref={editorRef}
          id={id}
          apiKey={PS.tinyMCEApiKey}
          value={value}
          name={name}
          disabled={props.disabled}
          init={{
            // max_chars:1000,
            selector: `${props.selector || 'textarea'}`,
            plugins: ' image code fullscreen link lists paste',
            toolbar: `${extraToolbar} | bold italic underline | ${
              eventCommunityVersion === 1 ? 'fontsizeselect ' : ''
            }  | bullist numlist | link | superscript subscript `, // 'undo redo | formatselect | bold italic underline backcolor | fontselect | fontsizeselect |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | fullscreen | ' + extraToolbar,   Put : "removeformat"  to add format clear button
            branding: false,
            // menu: {
            // 	edit: {title: "edit", items: "pastetext"}
            //   },
            paste_as_text: true,
            // forced_root_blocks: false,// for removing extra p tag.
            menubar: false,
            relative_urls: 0,
            remove_script_host: 0,
            statusbar: false,
            setup,
            placeholder: `${props.placeholder || ''}`,
            fontFormats: 'Helvetica Neue New',
            fontsize_formats: `${
              props.fixedFont ? props.fixedFont : '8pt 10pt 12pt 14pt 18pt 24pt 36pt'
            }`,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'); body { font-family:'Helvetica Neue New',sans-serif ; color: #353535; font-size: 14px;font-weight:400;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{color:#A5A5A5 !important}",
          }}
          onEditorChange={handleEditorChange}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
          // onMouseDown = {onMouseDownHandler}
        />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={toast}
        className={classes.snackbarBlock}
        autoHideDuration={3000}
        message="Number of characters added exceeds the limit"
        onClose={() => setToast(false)}
      />
    </FieldWrapper>
  );
}

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Box } from '@material-ui/core';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import FieldWrapper from './fieldWrapper';
// const useStyles = makeStyles(theme => ({
// 	borderDanger: {
// 		"& .ck-toolbar_grouping": {
// 			borderColor: theme.palette.error.main + "!important"
// 		},
// 		"& .ck-editor__editable": {
// 			borderColor: theme.palette.error.main + "!important"
// 		}
// 	},
// 	editorWrap: {
// 		'& .ck-editor__editable_inline': {
// 			height: 180,
// 			backgroundColor: `${theme.palette.bg.whiteOff1} !important`,
// 		}
// 	}
// }));

// const EditorField = (props) => {
// 	let { className = '', disabled = false, inputClassName = '', name = '', value = '', onInit = () => { }, error = '', readOnly = false, onChange } = props;
// 	const { borderDanger, editorWrap } = useStyles();

// 	return (
// 		<FieldWrapper formControlClassName={props.formControlClassName} label={props.label} id={props.id} error={props.error} required={props.required} descType={props.descType} descText={props.descText}>
// 			<Box className={`${editorWrap} ${className} ${inputClassName} ${error ? borderDanger : ''}`}>
// 				<CKEditor
// 					disabled={disabled}
// 					editor={ClassicEditor}
// 					data={value}
// 					name={name}
// 					readOnly={readOnly}
// 					height={250}
// 					onInit={editor => {
// 						onInit(editor);
// 					}}
// 					onChange={(event, editor) => {
// 						onChange(event, editor);
// 					}}
// 					config={{
// 						toolbar: ['bold', 'italic', '|', 'numberedList', 'bulletedList', '|', 'indent', 'outdent'],

// 					}}
// 				// onBlur={(event, editor) => {
// 				// 	console.log('Blur.', editor);
// 				// }}
// 				// onFocus={(event, editor) => {
// 				// 	console.log('Focus.', editor);
// 				// }}
// 				/>
// 			</Box>
// 		</FieldWrapper>
// 	)
// }

// export default EditorField;

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Box } from '@material-ui/core';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import FieldWrapper from './fieldWrapper';
// const useStyles = makeStyles(theme => ({
// 	borderDanger: {
// 		"& .ck-toolbar_grouping": {
// 			borderColor: theme.palette.error.main + "!important"
// 		},
// 		"& .ck-editor__editable": {
// 			borderColor: theme.palette.error.main + "!important"
// 		}
// 	},
// 	editorWrap: {
// 		'& .ck-editor__editable_inline': {
// 			height: 180,
// 			backgroundColor: `${theme.palette.bg.whiteOff1} !important`,
// 		}
// 	}
// }));

// const EditorField = (props) => {
// 	let { className = '', disabled = false, inputClassName = '', name = '', value = '', onInit = () => { }, error = '', readOnly = false, onChange } = props;
// 	const { borderDanger, editorWrap } = useStyles();

// 	return (
// 		<FieldWrapper formControlClassName={props.formControlClassName} label={props.label} id={props.id} error={props.error} required={props.required} descType={props.descType} descText={props.descText}>
// 			<Box className={`${editorWrap} ${className} ${inputClassName} ${error ? borderDanger : ''}`}>
// 				<CKEditor
// 					disabled={disabled}
// 					editor={ClassicEditor}
// 					data={value}
// 					name={name}
// 					readOnly={readOnly}
// 					height={250}
// 					onInit={editor => {
// 						onInit(editor);
// 					}}
// 					onChange={(event, editor) => {
// 						onChange(event, editor);
// 					}}
// 					config={{
// 						toolbar: ['bold', 'italic', '|', 'numberedList', 'bulletedList', '|', 'indent', 'outdent'],

// 					}}
// 				// onBlur={(event, editor) => {
// 				// 	console.log('Blur.', editor);
// 				// }}
// 				// onFocus={(event, editor) => {
// 				// 	console.log('Focus.', editor);
// 				// }}
// 				/>
// 			</Box>
// 		</FieldWrapper>
// 	)
// }

// export default EditorField;

// e.on('oncopy',function(e){
// 	console.log("on paste",e.currentTarget.innerText)
// 	console.log("current content length",content.replace( /(<([^>]+)>)/ig, '').length)
// 	if(content.replace( /(<([^>]+)>)/ig, '').length+e.currentTarget.innerText.length>=props.maxLength){
// 		e.currentTarget.innerText = "HHHHHHH";
// 		console.log("hello",content.replace( /(<([^>]+)>)/ig, '').length+e.currentTarget.innerText.length)

// 	}
// })
// console.log("handleEditorChange",characterCount[0])
// console.log("value",value)
// value.toString();
// strippedValueString = value.replace( /(<([^>]+)>)/ig, '');
// console.log("d",strippedValueString.length);
// console.log("ref",editorRef.current.props.value.replace( /(<([^>]+)>)/ig, '').length);

// console.log("handle paste",text.length)
// console.log("characterCount in paste",(characterCount[0] - text.length))
// if((characterCount[0] - copied.length)<=0){
// 	let trimmedText = text.substring(0,characterCount[0]);
// 	// alert(`you can only paste more ${characterCount[0]} characters which is limited to "${trimmedText}"`)
// 	e.preventDefault();
// 	e.stopImmediatePropagation();
// 	e.stopPropagation();
// 	//console.log("trimmed text in paste", trimmedText)
// 	//onChange({ target: { value: trimmedText, name: name } });
// 	return false;
// }
// console.log("handlepaste",e.type)
// setIsContentPasted(true);
// console.log(isContentPasted)
// console.log(characterCount[0])
// let text = await readTextFromClipboard();
// console.log("handlepaste",characterCount[0] - text.length)
// if(content.replace( /(<([^>]+)>)/ig, '').length <= props.maxLength){
//

// return false
// }

// e.on('paste',function(e){

// 	copied = (e.clipboardData || window.clipboardData).getData('text');
// 	setTimeout(console.log("pasted Content",copied),5000);
// 	//console.log("Content",content.replace( /(<([^>]+)>)/ig, '').length+copied.length);
// //	console.log("value",content.replace( /(<([^>]+)>)/ig, '')+copied);
// 	copiedLength = copied.length;

// //	console.log("trimmedContent",content);
// if(content.replace( /(<([^>]+)>)/ig, '').length+copied.length>props.maxLength){
// 	content = (content.replace( /(<([^>]+)>)/ig, '') + copied).substring(0,props.maxLength)
// 	console.log("content",content.length)
// }

// })

// if(content.replace( /(<([^>]+)>)/ig, '').length <props.maxLength){
// 	contentLength = content.replace( /(<([^>]+)>)/ig, '').substring(0,props.maxLength)
// 	onChange({ target: { value: contentLength, name: name } });
// 	console.log("true",contentLength)

// }else{
// 	console.log("false")
// 	contentLength = content.replace( /(<([^>]+)>)/ig, '').substring(0,props.maxLength)
// 	onChange({ target: { value: contentLength, name: name } });

// 	return false
// }

// if(content.replace( /(<([^>]+)>)/ig, '').length+copiedLength>props.length){
// 	//content = content + copied.substring(0,props.maxLength - (content.replace( /(<([^>]+)>)/ig, '').length+copiedLength));

//
// }
// const [isContentPasted,setIsContentPasted] = React.useState(false);
// if(isContentPasted){
// 	console.log("hi",)
// 	if(characterCount[0]<0){
// 		console.log("Hi Hi")

// 		alert("You cant have more than 1000 characters")
// 	}
// }
// console.log("props.desc",characterCount[0])
// const readTextFromClipboard = async (e) => {
// 	if (navigator.clipboard) {
// 		text = await navigator.clipboard.readText().then(text => {return text});
// 	return text;
// 	  }
// 	  else {
// 		text = e.clipboardData.getData('text/plain');
// 		return text;
// 	  }

// }
// text = readTextFromClipboard();
// text = text1.then(result => {console.log("text1", result)})
// console.log("text",text.length)
// console.log("above if block",text)
