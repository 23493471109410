import React from 'react';

export default function SvgIndiaFlag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <g>
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="#F0F0F0"
        />
        <path
          d="M12 0C7.24242 0 3.13167 2.76867 1.19067 6.78263H22.8092C20.8682 2.76867 16.7574 0 12 0Z"
          fill="#FF9811"
        />
        <path
          d="M12 24C16.7574 24 20.8682 21.2314 22.8092 17.2174H1.19067C3.13167 21.2314 7.24242 24 12 24Z"
          fill="#6DA544"
        />
        <path
          d="M11.9999 16.1738C14.3051 16.1738 16.1738 14.3051 16.1738 11.9999C16.1738 9.69476 14.3051 7.82605 11.9999 7.82605C9.69476 7.82605 7.82605 9.69476 7.82605 11.9999C7.82605 14.3051 9.69476 16.1738 11.9999 16.1738Z"
          fill="#0052B4"
        />
        <path
          d="M12 14.6088C13.4408 14.6088 14.6087 13.4408 14.6087 12.0001C14.6087 10.5594 13.4408 9.39142 12 9.39142C10.5593 9.39142 9.39136 10.5594 9.39136 12.0001C9.39136 13.4408 10.5593 14.6088 12 14.6088Z"
          fill="#F0F0F0"
        />
        <path
          d="M12 8.78101L12.8048 10.6062L14.7878 10.3905L13.6095 12.0001L14.7878 13.6096L12.8048 13.394L12 15.2192L11.1952 13.394L9.21216 13.6096L10.3904 12.0001L9.21216 10.3905L11.1952 10.6062L12 8.78101Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
