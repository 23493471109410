import React from 'react';

export default function SvgReceptionIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 5 25 25" {...props}>
      <g id="Group_12607" data-name="Group 12607" transform="translate(4 7)">
        <path
          data-name="Path 3861"
          d="M837.492,49.318,827.168,42.8a.5.5,0,0,0-.533,0L816.26,49.35a.5.5,0,0,0,.266.923.491.491,0,0,0,.267-.078l1.108-.7V60.416a15.4,15.4,0,0,0-1.187,1.144.5.5,0,0,0,.734.68c1.854-2,3.76-3.078,5.665-3.2a7.786,7.786,0,0,1,3.779.89,9.632,9.632,0,0,0,4.431,1.031,9.852,9.852,0,0,0,5.941-2.579.5.5,0,0,0-.646-.763c-.238.2-.477.388-.717.565V49.495l1.058.668a.5.5,0,1,0,.533-.845Zm-14.443,8.723c-.05,0-.1.014-.148.018V56.677h2v1.5A6.651,6.651,0,0,0,823.049,58.041Zm11.878.777a7.908,7.908,0,0,1-3.647,1.143,6.755,6.755,0,0,1-2.223-.284H831.4a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.019.1c-.211-.086-.422-.172-.635-.263-.449-.191-.9-.384-1.384-.549V56.177a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v2c0,.016.008.03.009.046a8.646,8.646,0,0,0-3.009,1.4V48.864l8-5.053,8,5.053V58.69A.494.494,0,0,0,834.927,58.818Zm-6.026-.141v-2h2v2Z"
          transform="translate(-816.026 -42.72)"
          fill="currentcolor"
        />
        <path
          data-name="Path 3862"
          d="M825.4,49.72h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,825.4,49.72Zm-.5,3h-2v-2h2Z"
          transform="translate(-816.026 -42.72)"
          fill="currentcolor"
        />
        <path
          data-name="Path 3863"
          d="M831.4,49.72h-3a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,831.4,49.72Zm-.5,3h-2v-2h2Z"
          transform="translate(-816.026 -42.72)"
          fill="currentcolor"
        />
      </g>
    </svg>
  );
}
