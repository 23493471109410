import React from 'react';

export default function SvgEventDetails(props) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path d="M8.33341 1.33325H3.00008C2.64646 1.33325 2.30732 1.47373 2.05727 1.72378C1.80722 1.97382 1.66675 2.31296 1.66675 2.66659V13.3333C1.66675 13.6869 1.80722 14.026 2.05727 14.2761C2.30732 14.5261 2.64646 14.6666 3.00008 14.6666H11.0001C11.3537 14.6666 11.6928 14.5261 11.9429 14.2761C12.1929 14.026 12.3334 13.6869 12.3334 13.3333V5.33325L8.33341 1.33325Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66659 11.3333H4.33325" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.66659 8.66675H4.33325" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.66658 6H4.99992H4.33325" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.33325 1.33325V5.33325H12.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    );
}
