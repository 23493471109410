import React from 'react';
import { Grid, Box } from '@material-ui/core';
import SkeletonLoaders from 'custom-components/skeletonLoader';

const CopyEventCreationLoader = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SkeletonLoaders height="16px" width="78px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2.8}>
          <SkeletonLoaders height="16px" width="78px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders height="200px" width="100%" variant="rect" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3.5}>
          <SkeletonLoaders height="16px" width="78px" variant="text" />
          <Box mt={1}>
            <SkeletonLoaders height="44px" width="100%" variant="rect" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SkeletonLoaders height="16px" width="78px" variant="text" />
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={4}>
          <SkeletonLoaders variant="rect" width="30%" height={14} />
          <SkeletonLoaders variant="rect" width="100%" height={40} skeletonProps={{ mt: 0.75 }} />
        </Box>
      </Grid>
      {[1, 2, 3, 4].map((i) => (
        <Grid item xs={6}>
          <SkeletonLoaders
            variant="rect"
            width="100%"
            height={44}
            skeletonProps={{ mt: 0, mb: 0 }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <SkeletonLoaders variant="rect" width="30%" height={14} />
        <SkeletonLoaders variant="rect" width="100%" height={60} skeletonProps={{ mt: 0.75 }} />
      </Grid>
      <Grid item xs={12}>
        <Box mt={1}>
          <SkeletonLoaders height="44px" width="100%" variant="rect" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CopyEventCreationLoader;
