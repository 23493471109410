import { DOWN_TIME_CONSTANTS, DOWN_TIME_MODAL } from 'constants/common';
import { saveDataToLocalStorage } from 'utility/localStorageHandler';

const DOWN_TIME_ACTION_TYPE = {
  DOWN_TIME_MODAL: 'DOWN_TIME_MODAL',
};

const downTimeModalAction = (payload) => {
  return { type: DOWN_TIME_ACTION_TYPE.DOWN_TIME_MODAL, payload };
};

const iState = {
  isOpen: false,
};
const downTimeReducer = (state = iState, action) => {
  switch (action.type) {
    case DOWN_TIME_ACTION_TYPE.DOWN_TIME_MODAL:
      if (DOWN_TIME_MODAL.IS_AVAILABLE) {
        saveDataToLocalStorage(
          DOWN_TIME_CONSTANTS.DOWN_TIME_MODAL_LOCAL_STORAGE_STATUS,
          action.payload
        );
        return { ...state, isOpen: action.payload };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
};

export { DOWN_TIME_ACTION_TYPE, downTimeReducer, downTimeModalAction };
