// Libraries
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Typography, Box, Button } from '@material-ui/core';

// Components
import PopUp from 'custom-components/popUp';
import BtnWithLoader from 'custom-components/btnWithLoader';
import TextAreaField from 'custom-components/form-fields/textAreaField';
import CustomModal from 'custom-components/customModal';

// Redux
import { isOpenSurveySelector, surveyMetaSelector, surveyStoreSelector, surveyConfigSelector } from 'redux/selectors/survey-selector';
import { setSurvey, unsetSurvey } from 'redux/actions/survey-actions';
import { setNewMessage } from 'redux/actions/setting-actions';

// API HELPERS
import { SET_NPS_DETAILS, SET_CSAT_DETAILS } from 'api-setup/api-endpoints';
import { API_CALL_METHODS, useApiHook, customBaseURL2 } from 'api-setup/use-api-hook';
import { removeSurveyCookie } from 'lib/cookies';

// ASSETS
import SvgLinkSurvey from 'icons/SvgLinkSurvey';

// STYLE
import { useStyles } from './styles';

// CUSTOM CONSTANTS
const EXPIRED_TITLE = 'Sorry, the link has expired.';
const EXPIRED_DESCRIPTION = 'Looks like the survey link you are trying to reach has expired.';
const DONE_TITLE = 'Survey Completed';
const DONE_DESCRIPTION = 'You have already taken the survey.';

const Survey = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [apiPromise] = useApiHook();
    const isOpenSurvey = useSelector(isOpenSurveySelector, shallowEqual);
    const store = useSelector(surveyStoreSelector, shallowEqual);
    const refComment = React.useRef('');
    const {
        isNps = false,
        isCompleteSurvey = false,
        isExpireSurvey = false,
    } = useSelector(surveyConfigSelector, shallowEqual);
    const {
        title = '',
        ratingQuestion = '',
        supportQuestion = '',
        descriptionQuestion = '',
        descriptionPlaceholder = '',
        descriptionLimit = 1000,
        maxScore = 10,
    } = useSelector(surveyMetaSelector, shallowEqual);

    const [isLoading, setLoading] = React.useState(false);
    const [current, setCurrent] = React.useState(store || {});
    const updateCurrent = (currentState = {}) => {
        if (currentState && typeof currentState === "object") {
            setCurrent((prevState) => {
                return {
                    ...prevState,
                    ...currentState,
                }
            });
        }
    };

    const handleSubmit = (payload = {}, action = {}) => {

        const { isPartial = false, isClose = false, isNps: hasNps = false } = action || {};
        if (isClose) setLoading(true);

        const { rating, supportRating, surveyId = "", feedBack = "" } = payload || {};

        const apiPayload = { isPartial: !!isPartial };
        if (!Number.isNaN(rating) && Number(rating) >= 0 && isNps) apiPayload.rating = Number(rating);
        if (!Number.isNaN(rating) && Number(rating) > 0 && !isNps) apiPayload.rating = Number(rating);
        if (!Number.isNaN(supportRating) && Number(supportRating) > 0 && !isNps) apiPayload.supportRating = Number(supportRating);
        if (surveyId) apiPayload.surveyId = surveyId;
        if (feedBack) apiPayload.feedBack = String(feedBack).trim();

        const apiRoute = hasNps ? SET_NPS_DETAILS : SET_CSAT_DETAILS;

        apiPromise(apiRoute, API_CALL_METHODS.POST, {}, apiPayload, customBaseURL2)
            .then(() => {
                // const { message = "" } = res?.data || {};
                // if (message) 
                dispatch(setSurvey({ ...payload, ...apiPayload }));
                updateCurrent({ ...payload, ...apiPayload });
                if (isClose) dispatch(unsetSurvey());
            })
            .catch((error) => {
                if (isClose) dispatch(unsetSurvey());
                dispatch(
                    setNewMessage({
                        message: error.response?.data?.error?.message,
                        type: 'error',
                        show: true,
                    })
                );
            });

    };

    const handleClose = () => {
        removeSurveyCookie();
        handleSubmit(current, { isPartial: true, isNps });
        dispatch(setSurvey({ isOpen: false }));
    };

    const handleNotice = () => {
        removeSurveyCookie();
        dispatch(setSurvey({ isOpen: false }));
        setTimeout(() => {
            dispatch(unsetSurvey());
        }, 200);
    };

    const handleRating = (rating = '', isSupport = false) => {
        if (rating) {
            const key = isSupport ? 'supportRating' : 'rating';
            updateCurrent({ [key]: rating });
            handleSubmit({ ...current, [key]: Number(rating) }, { isPartial: true, isNps });
        }
    };

    const handleFocus = (event) => {
        const { value } = event.target;
        if (String(value).trim() !== String(refComment.current).trim()) {
            refComment.current = String(value).trim();
            updateCurrent({ feedBack: String(value).trim() });
            handleSubmit({ ...current, feedBack: String(value).trim() }, { isPartial: true, isNps });
        }
    };

    React.useEffect(() => {
        refComment.current = store?.feedBack || '';
        setCurrent(store);
    }, [store]);

    const renderRating = (limit = 0, isSupport = false) => {
        if (limit) {
            const returnData = [];
            const init = isNps ? 0 : 1;
            const mileStone = Number(isSupport ? current?.supportRating || 0 : current?.rating || 0);
            for (let i = init; i <= limit; i += 1) {
                returnData.push(
                    <Button
                        onClick={() => handleRating(String(i), isSupport)} color="primary"
                        variant={mileStone === Number(i) && 'contained'}
                        className={`${classes.customBtn} ${mileStone === Number(i) ? "" : classes.customBgBtn}`}
                    >
                        {i}
                    </Button>
                );
            }

            return (
                <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                    {returnData}
                </Box>
            );
        }
        return [];
    };

    const handleComment = (event) => {
        const { value } = event.target;
        updateCurrent({ feedBack: value });
    };

    const isSubmitEnable = React.useMemo(() => {
        if (isNps) {
            return Boolean(Number.isNaN(current?.rating) || Number(current?.rating) < 0);
        } else {
            return Boolean(Number.isNaN(current?.rating) || Number(current?.rating) <= 0 || Number.isNaN(current?.supportRating) || Number(current?.supportRating) <= 0);
        }
    }, [current.rating, current.supportRating, isNps]);

    const isEligibleSurvey = Boolean(isOpenSurvey && !isExpireSurvey && !isCompleteSurvey);
    const isNoticeSurvey = Boolean(isOpenSurvey && (isExpireSurvey || isCompleteSurvey));

    return (
        <>
            <PopUp
                isOpen={isNoticeSurvey}
                onClose={handleNotice}
                btnMarginTop={2}
                minWidth={375}
                paddingZero
                showCloseIcon={false}
                data={{
                    title: isCompleteSurvey ? DONE_TITLE : EXPIRED_TITLE,
                    secondaryDesc: isCompleteSurvey ? DONE_DESCRIPTION : EXPIRED_DESCRIPTION,
                    Icon: SvgLinkSurvey,
                    actionBtn: [
                        {
                            name: 'Got it',
                            type: 'primary',
                            // isLoading,
                            onClick: () => handleNotice(),
                        },
                    ],
                }}
            />
            <CustomModal
                overlayClassName={classes.feedbackDetails}
                isScrollModal
                isOpen={isEligibleSurvey} onClose={(e, reason) => {
                if (!reason) handleClose();
            }}>
                <Box minWidth={440}>
                    <Typography variant="h6" component="h4" className="text-center">{title}</Typography>

                    {/* Primary Question ==> START */}
                    <Typography variant="body1" component="p" className={`text-center ${classes.surveySubDesc}`}>
                        {ratingQuestion}<Typography className={classes.mandatory} component='span'>{' *'}</Typography>
                    </Typography>
                    <Box margin="0 auto" display="flex" flexDirection="column" width="fit-content">

                        {renderRating(maxScore)}

                        <Box mt={1} mb={3} display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="caption" component="span" className={classes.themeColor}>{isNps ? 'Not Likely' : 'Not Satisfied'}</Typography>
                            <Typography variant="caption" component="span" className={classes.themeColor}>{isNps ? 'Most Likely' : 'Extremely Satisfied'}</Typography>

                        </Box>
                    </Box>
                    {/* Primary Question ==> ENDED */}

                    {/* Support Question ==> START */}
                    {!isNps && supportQuestion ? (
                        <>
                            <Typography variant="body1" component="p" className={`text-center ${classes.surveySubDesc}`}>
                                {supportQuestion}<Typography className={classes.mandatory} component='span'>{' *'}</Typography>
                            </Typography>
                            <Box margin="0 auto" display="flex" flexDirection="column" width="fit-content">

                                {renderRating(maxScore, true)}

                                <Box mt={1} mb={3} display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="caption" component="span" className={classes.themeColor}>Not Satisfied</Typography>
                                    <Typography variant="caption" component="span" className={classes.themeColor}>Extremely Satisfied</Typography>

                                </Box>


                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                    {/* Support Question ==> ENDED */}

                    {/* Description Question ==> START */}
                    <Typography variant="body1" component="p" className={classes.score}>{descriptionQuestion}</Typography>
                    <TextAreaField
                        value={current?.feedBack || ''}
                        onChange={handleComment}
                        onBlur={handleFocus}
                        name="comment"
                        rowMin={4}
                        disabled={isSubmitEnable}
                        placeholder={descriptionPlaceholder}
                        maxLength={descriptionLimit}
                    />
                    {/* Description Question ==> ENDED */}

                    <Box display="flex" justifyContent="center" mt={2}>
                        <BtnWithLoader
                            onClick={() => handleSubmit(current, { isClose: true, isNps })}
                            isLoading={isLoading}
                            disabled={isLoading || isSubmitEnable}
                            loadingLabel="Submit"
                            label="Submit"
                            variant="contained"
                            size="medium"
                            color="primary"
                        />
                    </Box>
                </Box>
            </CustomModal>
        </>
    );
};

export default Survey;