export const TEMPLATE_MODAL_TOGGLE = 'TEMPLATE_MODAL_TOGGLE';
export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE';
export const SET_ACTIVE_TEMPLATE_NAME = 'SET_ACTIVE_TEMPLATE_NAME';
export const TEMPLATE_NAME_EDIT_TOGGLE = 'TEMPLATE_NAME_EDIT_TOGGLE';
export const SET_TEMPLATE_ACTION_TYPE = 'SET_TEMPLATE_ACTION_TYPE';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const SET_TEMPLATE_OBJ = 'SET_TEMPLATE_OBJ';
export const SET_TEMPLATE_IS_PRIMARY = 'SET_TEMPLATE_IS_PRIMARY';
export const SET_CAMPAIGN_DETAILS = 'SET_CAMPAIGN_DETAILS';
export const RESET_TEMPLATE_DETAILS = 'RESET_TEMPLATE_DETAILS';

export const templateModalToggle = (value) => {
  return {
    type: TEMPLATE_MODAL_TOGGLE,
    data: value,
  };
};

export const setActiveTemplate = (value) => {
  return {
    type: SET_ACTIVE_TEMPLATE,
    data: value,
  };
};

export const setActiveTemplateName = (value) => {
  return {
    type: SET_ACTIVE_TEMPLATE_NAME,
    data: value,
  };
};

export const templateNameEditToggle = (value) => {
  return {
    type: TEMPLATE_NAME_EDIT_TOGGLE,
    data: value,
  };
};

export const setTemplateActiontype = (value) => {
  return {
    type: SET_TEMPLATE_ACTION_TYPE,
    data: value,
  };
};

export const setCampaignId = (value) => {
  return {
    type: SET_CAMPAIGN_ID,
    data: value,
  };
};

export const setTemplateId = (value) => {
  return {
    type: SET_TEMPLATE_ID,
    data: value,
  };
};

export const setTemplateObj = (value) => {
  return {
    type: SET_TEMPLATE_OBJ,
    data: value,
  };
};

export const setTemplateIsPrimary = (value) => {
  return {
    type: SET_TEMPLATE_IS_PRIMARY,
    data: value,
  };
};

export const setCampaignDetail = (value) => {
  return {
    type: SET_CAMPAIGN_DETAILS,
    data: value,
  };
};

export const resetTemplate = () => {
  return {
    type: RESET_TEMPLATE_DETAILS
  }
};
