import React from 'react';

export default function SvgSendTestEmailIcon() {
  return (
    <svg
      width="142"
      height="100"
      viewBox="0 0 142 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.3539 20.397C44.3226 20.5352 44.3022 20.6756 44.2929 20.817L33.2829 27.168L30.6069 25.627L27.7539 29.362L33.4789 33.442L46.0389 23.533C46.5512 23.7226 47.1091 23.7518 47.6384 23.6167C48.1676 23.4815 48.6432 23.1884 49.0019 22.7764C49.3605 22.3644 49.5852 21.8529 49.6461 21.31C49.7069 20.7672 49.6011 20.2187 49.3426 19.7374C49.0841 19.2562 48.6852 18.8651 48.199 18.616C47.7128 18.367 47.1623 18.272 46.6208 18.3435C46.0792 18.4149 45.5722 18.6496 45.1673 19.0163C44.7624 19.3829 44.4787 19.8642 44.3539 20.396V20.397Z"
        fill="#FFB8B8"
      />
      <path
        d="M19.2803 24.2942L27.9493 30.4722L31.8263 25.6112L23.3993 18.8492C23.0368 18.5583 22.6187 18.3444 22.1706 18.2208C21.7226 18.0971 21.254 18.0662 20.7936 18.1299C20.3332 18.1937 19.8906 18.3508 19.493 18.5915C19.0954 18.8323 18.7512 19.1517 18.4813 19.5302C18.2211 19.8955 18.0354 20.3084 17.9347 20.7455C17.8341 21.1825 17.8205 21.6351 17.8948 22.0774C17.9691 22.5197 18.1298 22.943 18.3677 23.3232C18.6056 23.7034 18.9161 24.033 19.2813 24.2932L19.2803 24.2942Z"
        fill="#F59C86"
      />
      <path
        d="M1.38672 94.7913L4.42672 95.5913L8.94472 84.2463L4.45872 83.0703L1.38672 94.7913Z"
        fill="#FFB8B8"
      />
      <path
        d="M9.55273 99.9473L0.000222519 97.4442L0.967215 93.7538L6.82931 95.2899C7.80807 95.5463 8.64487 96.1811 9.15561 97.0545C9.66635 97.928 9.8092 98.9685 9.55273 99.9473Z"
        fill="#2F2E41"
      />
      <path
        d="M28.4044 96.954L31.4974 96.401L30.8384 84.207L26.2734 85.023L28.4044 96.954Z"
        fill="#FFB8B8"
      />
      <path
        d="M37.9766 98.209L28.2554 99.9455L27.5846 96.1899L33.5502 95.1243C34.5462 94.9464 35.5721 95.1714 36.4022 95.7499C37.2323 96.3284 37.7986 97.2129 37.9766 98.209Z"
        fill="#2F2E41"
      />
      <path
        d="M22.3122 7.2796C22.7257 7.43842 23.0981 7.68825 23.4019 8.01058C23.7057 8.3329 23.9331 8.71946 24.0672 9.1416C24.4106 10.0865 24.6432 11.0681 24.7602 12.0666C25.1997 13.9087 25.1888 15.8296 24.7282 17.6666C24.4873 18.5582 23.913 19.323 23.124 19.8031C22.335 20.2831 21.3917 20.4415 20.4892 20.2456C18.8502 19.7456 17.5462 17.8836 17.1002 15.7366C16.6944 13.5672 16.9556 11.3257 17.8492 9.3076C18.2245 8.28472 18.9102 7.40471 19.8102 6.79061C20.2287 6.53774 20.7273 6.45266 21.2059 6.55247C21.6846 6.65228 22.1077 6.92957 22.3902 7.32861"
        fill="#2F2E41"
      />
      <path
        d="M26.6272 40.9531L32.5122 93.7011L27.6482 95.5481L18.6782 60.1801L6.18922 92.5181L0.865234 92.8181C0.865234 92.8181 8.16522 42.5001 13.5832 41.3561L26.6272 40.9531Z"
        fill="#2F2E41"
      />
      <path
        d="M21.6817 14.0119C25.1584 14.0119 27.9767 11.1935 27.9767 7.71688C27.9767 4.24024 25.1584 1.42188 21.6817 1.42188C18.2051 1.42188 15.3867 4.24024 15.3867 7.71688C15.3867 11.1935 18.2051 14.0119 21.6817 14.0119Z"
        fill="#FFB8B8"
      />
      <path
        d="M24.3812 16.6526L17.5812 15.7656C17.5812 15.7656 13.1452 21.0896 13.4402 30.2576C13.7352 39.4256 12.7012 42.2356 12.7012 42.2356L26.8842 42.4916C24.8322 39.6726 33.4112 23.4806 24.3812 16.6526Z"
        fill="#F59C86"
      />
      <path
        d="M28.0356 43.774C27.8939 43.7749 27.7524 43.7869 27.6126 43.81L18.9126 34.546L19.7996 31.588L15.5116 29.666L12.8496 36.173L25.3696 46.132C25.3024 46.6741 25.4018 47.2238 25.6546 47.708C25.9074 48.1922 26.3017 48.588 26.7849 48.8427C27.2681 49.0974 27.8174 49.1989 28.3598 49.1338C28.9021 49.0687 29.4118 48.8401 29.821 48.4782C30.2302 48.1164 30.5196 47.6386 30.6506 47.1083C30.7817 46.578 30.7481 46.0204 30.5546 45.5096C30.361 44.9988 30.0164 44.5591 29.5668 44.2489C29.1172 43.9387 28.5838 43.7727 28.0376 43.773L28.0356 43.774Z"
        fill="#FFB8B8"
      />
      <path
        d="M18.5056 20.2571L14.4746 30.1101L20.0936 32.7721L24.7476 23.0211C24.9479 22.6015 25.0604 22.1455 25.0782 21.681C25.0961 21.2164 25.0189 20.7532 24.8514 20.3195C24.6839 19.8858 24.4296 19.4909 24.1042 19.159C23.7787 18.8271 23.3889 18.5651 22.9586 18.3891C22.5435 18.2192 22.099 18.1328 21.6505 18.1348C21.202 18.1368 20.7583 18.2271 20.3447 18.4005C19.9311 18.574 19.5557 18.8272 19.24 19.1458C18.9243 19.4643 18.6744 19.8419 18.5046 20.2571H18.5056Z"
        fill="#F59C86"
      />
      <path
        d="M20.0335 13.4394C21.0195 12.8704 21.1255 11.4974 20.9915 10.3674C20.8575 9.23735 20.6335 7.97635 21.2985 7.05135C22.0915 5.95135 23.6855 5.92635 25.0445 5.89035C26.4035 5.85435 28.0575 5.45635 28.4095 4.14435C28.4892 3.701 28.4393 3.24406 28.2656 2.82843C28.0919 2.41279 27.8019 2.05615 27.4305 1.80135C26.6795 1.30437 25.8383 0.95984 24.9545 0.787352C23.0445 0.147634 21.0264 -0.106282 19.0175 0.0403501C17.0473 0.319018 15.2661 1.36217 14.0592 2.94411C12.8523 4.52605 12.3168 6.51958 12.5685 8.49335C12.8905 11.0184 13.3575 12.1204 13.0505 13.2334C12.4795 15.3064 10.0185 16.6384 9.96946 18.7894C9.93146 20.4713 11.4165 21.7684 12.8695 22.6224C13.9974 23.3434 15.2622 23.8238 16.5845 24.0334C17.2459 24.1333 17.9209 24.092 18.5652 23.9122C19.2094 23.7324 19.8084 23.4183 20.3225 22.9903C20.9182 22.3658 21.3435 21.5987 21.5577 20.7626C21.7718 19.9265 21.7676 19.0494 21.5455 18.2154C21.0817 16.5539 20.3274 14.9878 19.3175 13.5894"
        fill="#2F2E41"
      />
      <path
        d="M139.138 15.3242H48.1547C47.5431 15.325 46.9567 15.5683 46.5242 16.0008C46.0918 16.4333 45.8485 17.0196 45.8477 17.6312V76.0662C45.8485 76.6778 46.0918 77.2642 46.5242 77.6966C46.9567 78.1291 47.5431 78.3724 48.1547 78.3732H139.139C139.75 78.3724 140.337 78.1291 140.769 77.6966C141.202 77.2642 141.445 76.6778 141.446 76.0662V17.6312C141.445 17.0194 141.201 16.433 140.769 16.0005C140.336 15.5679 139.749 15.3247 139.138 15.3242ZM140.932 76.0652C140.93 76.5405 140.741 76.9959 140.404 77.332C140.068 77.6681 139.613 77.8576 139.138 77.8592H48.1547C47.6794 77.8576 47.224 77.6681 46.8879 77.332C46.5518 76.9959 46.3622 76.5405 46.3607 76.0652V17.6312C46.3622 17.1559 46.5518 16.7005 46.8879 16.3644C47.224 16.0283 47.6794 15.8388 48.1547 15.8372H139.139C139.614 15.8388 140.069 16.0283 140.405 16.3644C140.742 16.7005 140.931 17.1559 140.933 17.6312L140.932 76.0652Z"
        fill="#3F3D56"
      />
      <path
        d="M67.5759 44.4121H53.2229C52.6455 44.4113 52.092 44.1816 51.6837 43.7733C51.2754 43.365 51.0457 42.8115 51.0449 42.2341V27.8811C51.0457 27.3037 51.2754 26.7502 51.6837 26.3419C52.092 25.9336 52.6455 25.7039 53.2229 25.7031H67.5749C68.1523 25.7039 68.7058 25.9336 69.1141 26.3419C69.5224 26.7502 69.7521 27.3037 69.7529 27.8811V42.2331C69.7524 42.8105 69.5229 43.3641 69.1148 43.7726C68.7067 44.1811 68.1533 44.4111 67.5759 44.4121Z"
        fill="#F59C86"
      />
      <path
        d="M134.398 59.664H52.8984C52.4566 59.664 52.0328 59.4885 51.7204 59.1761C51.4079 58.8636 51.2324 58.4399 51.2324 57.998C51.2324 57.5562 51.4079 57.1324 51.7204 56.82C52.0328 56.5076 52.4566 56.332 52.8984 56.332H134.398C134.84 56.332 135.264 56.5076 135.576 56.82C135.889 57.1324 136.064 57.5562 136.064 57.998C136.064 58.4399 135.889 58.8636 135.576 59.1761C135.264 59.4885 134.84 59.664 134.398 59.664Z"
        fill="#CCCCCC"
      />
      <path
        d="M134.457 51.5918H52.957C52.5152 51.5918 52.0914 51.4162 51.779 51.1038C51.4665 50.7914 51.291 50.3676 51.291 49.9258C51.291 49.4839 51.4665 49.0602 51.779 48.7477C52.0914 48.4353 52.5152 48.2598 52.957 48.2598H134.457C134.899 48.2598 135.323 48.4353 135.635 48.7477C135.947 49.0602 136.123 49.4839 136.123 49.9258C136.123 50.3676 135.947 50.7914 135.635 51.1038C135.323 51.4162 134.899 51.5918 134.457 51.5918Z"
        fill="#CCCCCC"
      />
      <path
        d="M94.2178 40.5683H82.1719C81.73 40.5683 81.3063 40.3928 80.9938 40.0804C80.6814 39.7679 80.5059 39.3442 80.5059 38.9023C80.5059 38.4605 80.6814 38.0367 80.9938 37.7243C81.3063 37.4119 81.73 37.2363 82.1719 37.2363H94.2178C94.6597 37.2363 95.0835 37.4119 95.3959 37.7243C95.7083 38.0367 95.8839 38.4605 95.8839 38.9023C95.8839 39.3442 95.7083 39.7679 95.3959 40.0804C95.0835 40.3928 94.6597 40.5683 94.2178 40.5683Z"
        fill="#CCCCCC"
      />
      <path
        d="M134.456 32.3691H82.1719C81.73 32.3691 81.3063 32.1936 80.9938 31.8811C80.6814 31.5687 80.5059 31.145 80.5059 30.7031C80.5059 30.2613 80.6814 29.8375 80.9938 29.5251C81.3063 29.2126 81.73 29.0371 82.1719 29.0371H134.455C134.897 29.0371 135.32 29.2126 135.633 29.5251C135.945 29.8375 136.121 30.2613 136.121 30.7031C136.121 31.145 135.945 31.5687 135.633 31.8811C135.32 32.1936 134.897 32.3691 134.455 32.3691H134.456Z"
        fill="#CCCCCC"
      />
      <path
        d="M92.1664 67.8652H52.8984C52.4566 67.8652 52.0328 67.6897 51.7204 67.3772C51.4079 67.0648 51.2324 66.6411 51.2324 66.1992C51.2324 65.7574 51.4079 65.3336 51.7204 65.0212C52.0328 64.7087 52.4566 64.5332 52.8984 64.5332H92.1654C92.6073 64.5332 93.031 64.7087 93.3435 65.0212C93.6559 65.3336 93.8314 65.7574 93.8314 66.1992C93.8314 66.6411 93.6559 67.0648 93.3435 67.3772C93.031 67.6897 92.6073 67.8652 92.1654 67.8652H92.1664Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
