/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from './style';
import LearnMoreLink from 'learnMoreLinks';
import InputField from '../../../../custom-components/form-fields/inputField';
import { Search } from 'react-feather';
import AddNewQuestion from './addEditQuestion';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import QuizQuestionWrapper from './quizQuestionWrapper';
import { API_CALL_METHODS, customBaseURL2, useApiHook } from 'api-setup/use-api-hook';
import { useCustomQuizContext } from '../context';
import {
  CREATE_QUIZ,
  CREATE_QUIZ_QUESTIONS,
  EDIT_QUIZ_QUESTIONS,
  GET_QUIZ_QUESTIONS,
  REORDER_QUIZ_QUESTIONS,
} from 'api-setup/api-endpoints';
import { applyDrag } from 'lib/common-function';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setNewMessage } from 'redux/actions/setting-actions';
import QuestionsSkeletonLoader from './quizQuestionsSkeletonLoader';
import { quizReducerActionTypes } from '../context/reducer';
import DefaultScreen from 'custom-components/defaultScreen';
import SvgNoQuestionIcon from 'icons/svgNoQuestion';
import { eventDataSelector, userInfoSelector } from 'redux/custom-selector';
import amplitude from 'lib/amplitude';

export const surveyFillTime = {
  DURINGSESSION: 'DURINGSESSION',
  ENDOFSESSION: 'ENDOFSESSION',
};

function QuizQuestionsList({ quizLoading = false, setQuizLoading }) {
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);
  const { EventData } = useSelector(eventDataSelector, shallowEqual);

  const classes = useStyles();
  const { state, dispatch } = useCustomQuizContext();
  const [addQuestion, setAddQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [apiPromise] = useApiHook();
  const [basicDetailsPayload, setBasicDetailsPayload] = useState({});
  const apiDispatch = useDispatch();
  const match = useRouteMatch();
  const [apiLoading, setApiLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const history = useHistory();
  const quizId = match?.params?.quizId;

  useEffect(() => {
    if (state?.questions?.length) setQuestions(state?.questions);
  }, [state?.questions]);

  const getQuizQuestions = (searchVal = '') => {
    setSearchLoading(true);
    const url = `${GET_QUIZ_QUESTIONS}?quizId=${quizId}&search=${searchVal}`;
    apiPromise(url, API_CALL_METHODS.GET, {}, {}, customBaseURL2)
      .then((res) => {
        setSearchLoading(false);
        setQuestions(res?.data?.data?.quizQuestion);
        dispatch({
          type: quizReducerActionTypes.ADD_QUESTION,
          payload: res?.data?.data?.quizQuestion,
        });
      })
      .catch((error) => {
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    if (quizId) {
      getQuizQuestions();
    }
  }, [quizId]);
  useEffect(() => {
    const { visibility, ctaLabel, quizName, type, sessionId, visibleGroupIds, fillTime } =
      state?.basicDetails;
    let bodyData = {
      name: quizName,
      type: type.toUpperCase(),
      visibleGroups: visibleGroupIds,
      visibility: visibility.toUpperCase(),
    };
    if (type.toUpperCase() === 'SESSION') {
      bodyData = {
        ...bodyData,
        sessionId,
        ctaLabel,
        fillTime:
          fillTime?.id !== 'Leaving' ? surveyFillTime.DURINGSESSION : surveyFillTime?.ENDOFSESSION,
      };
    }
    setBasicDetailsPayload(bodyData);
  }, [state?.basicDetails]);

  const amplitudeMapping = (data, quizIdClone) => {
    const { visibility, ctaLabel, quizName, type, sessionId, visibleGroupIds, fillTime } =
      state?.basicDetails;
    amplitude.CEP.quizCreated({
      quizId: quizIdClone,
      quizName,
      quizType: type?.toUpperCase(),
      quizVisibility: visibility?.toUpperCase(),
      groups: visibleGroupIds,
      sessionsId: sessionId,
      ctaLabel,
      whenCanAttendeeStart: fillTime?.name,
      organisation: UserInfo?.organisation,
      eventType: EventData?.eventMetaTypeValue,
      eventFormat: EventData?.type === 'HYBRID' ? 'hybrid' : 'virtual',
      planType: UserInfo?.planName?.toUpperCase() ?? 'PLAN_LESS',
    });
  };

  const handleAddQuestion = (data, addMore = false) => {
    setApiLoading(true);
    let payload = {};
    if (questions?.length === 0) {
      payload = {
        data: {
          ...basicDetailsPayload,
          name: basicDetailsPayload.name,
          quizQuestions: data,
        },
      };
    } else {
      payload = {
        data: {
          quizId: quizId,
          question: data[0],
        },
      };
    }

    apiPromise(
      questions?.length ? CREATE_QUIZ_QUESTIONS : CREATE_QUIZ,
      API_CALL_METHODS.POST,
      {},
      payload,
      customBaseURL2
    )
      .then((res) => {
        if (questions?.length === 0) {
          amplitudeMapping(data, res?.data?.data?.quizId);
        }
        apiDispatch(
          setNewMessage({
            message: res?.data?.message,
            type: 'success',
            show: true,
          })
        );
        let questionData = [...data];
        let id = quizId;
        if (res?.data?.data?.quizId) {
          history.push({
            pathname: `${res?.data?.data?.quizId}/quiz-questions`,
            state: {
              addMore,
            },
          });

          id = res?.data?.data?.quizId;
        }

        questionData[questionData.length - 1] = {
          ...questionData[questionData.length - 1],
          id: questions.length === 0 ? res?.data?.data?.questionId : res?.data?.data?.id,
        };

        setQuestions([...questions, ...questionData]);
        dispatch({
          type: quizReducerActionTypes.ADD_QUESTION,
          payload: [...questions, ...questionData],
        });
        if (!addMore) setAddQuestion(false);
        else setAddQuestion(true);
        setApiLoading(false);
      })
      .catch((error) => {
        setApiLoading(false);
      });
  };

  const handleReordering = (dropResult) => {
    if (questions?.length > 1) {
      const data = applyDrag(questions, dropResult);
      const reOrderList = data.map((item, index) => {
        return {
          id: item.id,
          position: index,
        };
      });
      const payload = {
        data: {
          quizId: quizId,
          questions: reOrderList,
        },
      };
      apiPromise(REORDER_QUIZ_QUESTIONS, API_CALL_METHODS.PUT, {}, payload, customBaseURL2).then(
        (res) => {
          apiDispatch(
            setNewMessage({
              message: res?.data?.message,
              type: 'success',
              show: true,
            })
          );
        }
      );
      setQuestions(data);
    }
  };
  const handleEditQuestion = (payload) => {
    apiPromise(EDIT_QUIZ_QUESTIONS, API_CALL_METHODS.PUT, {}, payload, customBaseURL2).then(
      (res) => {
        apiDispatch(
          setNewMessage({
            message: res?.data?.message,
            type: 'success',
            show: true,
          })
        );
        getQuizQuestions();
      }
    );
  };
  return (
    <>
      <Box id="questionsPage">
        <Box mb={3.75}>
          <Box
            mb={2.7}
            component="header"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <Typography component="h1" variant="h4" color="textPrimary">
                Questions
              </Typography>
              <Typography
                component="p"
                variant="p"
                color="textSecondary"
                display="block"
                className={classes.grayHelpText}>
                Engage with the people at the event through Quiz. People will participate by
                providing the answers to multiple choice questions.
                <Box
                  component="a"
                  onClick={() => window.open(LearnMoreLink.entriesContest, '_blank')}
                  variant="body2"
                  ml={0.3}
                  className="text-underline  cursor-pointer">
                  Learn More
                </Box>
              </Typography>
            </Box>
          </Box>
          {!quizLoading &&
            (questions.length > 0 || (questions?.length === 0 && searchText !== '')) && (
              <Box
                component="header"
                className={classes.roomHeader}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box width={250}>
                  <InputField
                    type="text"
                    size="small"
                    placeholder="Search by question name"
                    startAdornment
                    value={searchText}
                    startIconAdornment
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      if (e.target.value === '') getQuizQuestions(e.target.value);
                    }}
                    onEnter={(searchVal) => getQuizQuestions(searchVal)}
                    startAdornmentIcon={<Search size={18} />}
                    autofocus={false}
                  />
                </Box>
                <Box className={classes.quizActionBtn} display="flex" alignItems="center">
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    className="square active"
                    onClick={() => {
                      setAddQuestion(true);
                    }}>
                    Add Question
                  </Button>
                </Box>
              </Box>
            )}
        </Box>

        {quizLoading || searchLoading ? (
          <QuestionsSkeletonLoader />
        ) : (
          <>
            {searchText !== '' && questions.length === 0 ? (
              <Box mt={3} mb={3}>
                <Card>
                  <CardContent>
                    <Box pt={2.5} >
                      <DefaultScreen
                        title="No question found"
                        subTitle="Try searching with a different keyword"
                        svgIcon={<SvgNoQuestionIcon width={190} />}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <Box className={classes.trackList}>
                <Container
                  lockAxis="y"
                  orientation="vertical"
                  className=""
                  onDrop={(dropResult) => handleReordering(dropResult)}>
                  <>
                    {questions.map((question) => {
                      return (
                        <Draggable key={question.id}>
                          <QuizQuestionWrapper
                            questionsLength={questions?.length}
                            {...question}
                            closeAddQuestion={() => setAddQuestion(false)}
                            key={question.id}
                            handleEditQuestion={handleEditQuestion}
                            handleAddQuestion={handleAddQuestion}
                            getQuizQuestions={getQuizQuestions}
                          />
                        </Draggable>
                      );
                    })}
                  </>
                </Container>
              </Box>
            )}
            {addQuestion || (!quizLoading && questions.length === 0 && searchText === '') ? (
              <AddNewQuestion
                addQuestion={addQuestion}
                questionsLength={questions?.length}
                apiLoading={apiLoading}
                handleEditCancle={() => {
                  setAddQuestion(false);
                }}
                handleAddQuestion={handleAddQuestion}
              />
            ) : (
              false
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default QuizQuestionsList;
