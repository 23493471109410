import React from 'react';

export default function SvgMobMockup({ height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="262"
      height={height}
      viewBox="0 0 262 524"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M257.201 120.476H257.801C258.463 120.476 259 121.014 259 121.678V179.413C259 180.077 258.463 180.615 257.801 180.615H257.201V120.476Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 156.559C3 155.895 3.53684 155.357 4.19906 155.357H4.79859V193.245H4.19906C3.53684 193.245 3 192.706 3 192.042V156.559Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 109.049C3 108.385 3.53684 107.846 4.19906 107.846H4.79859V145.734H4.19906C3.53684 145.734 3 145.196 3 144.532V109.049Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 72.3637C3 71.6994 3.53684 71.1609 4.19906 71.1609H4.79859V91.007H4.19906C3.53684 91.007 3 90.4685 3 89.8042V72.3637Z"
        fill="#121515"
      />
      <g filter="url(#filter0_dd_291_72944)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.79883 39.8881C4.79883 18.9631 21.7093 2 42.5693 2H219.431C240.291 2 257.202 18.9631 257.202 39.8881V480.112C257.202 501.037 240.291 518 219.431 518H42.5693C21.7093 518 4.79883 501.037 4.79883 480.112V39.8881Z"
          fill="#3A4245"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7959 39.8881C7.7959 20.6238 23.3642 5.00696 42.5687 5.00696H219.431C238.635 5.00696 254.203 20.6238 254.203 39.8881V480.112C254.203 499.376 238.635 514.993 219.431 514.993H42.5687C23.3642 514.993 7.7959 499.376 7.7959 480.112V39.8881Z"
        fill="#121515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.279 26.056C160.928 26.056 163.075 23.9019 163.075 21.2448C163.075 18.5876 160.928 16.4336 158.279 16.4336C155.63 16.4336 153.482 18.5876 153.482 21.2448C153.482 23.9019 155.63 26.056 158.279 26.056Z"
        fill="#262C2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.279 24.2518C159.934 24.2518 161.277 22.9055 161.277 21.2448C161.277 19.5841 159.934 18.2378 158.279 18.2378C156.623 18.2378 155.281 19.5841 155.281 21.2448C155.281 22.9055 156.623 24.2518 158.279 24.2518Z"
        fill="#121515"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.278 20.6433C158.609 20.6433 158.878 20.3741 158.878 20.042C158.878 19.7098 158.609 19.4406 158.278 19.4406C157.947 19.4406 157.679 19.7098 157.679 20.042C157.679 20.3741 157.947 20.6433 158.278 20.6433Z"
        fill="#636F73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.712 21.2447C115.712 20.2483 116.52 19.4406 117.516 19.4406H144.484C145.48 19.4406 146.288 20.2483 146.288 21.2447C146.288 22.2412 145.48 23.0489 144.484 23.0489H117.516C116.52 23.0489 115.712 22.2412 115.712 21.2447Z"
        fill="#262C2D"
      />
      <mask id="mask0_291_72944" maskUnits="userSpaceOnUse" x="18" y="15" width="226" height="490">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.1382 33.8741H179.862C187.809 33.8741 194.251 27.412 194.251 18.6888C194.332 17.147 195.561 15.9137 197.095 15.836L220.63 15.8322C233.212 15.8322 243.412 26.0639 243.412 38.6853V481.315C243.412 493.936 233.212 504.168 220.63 504.168H41.3701C28.7878 504.168 18.5879 493.936 18.5879 481.315V38.6853C18.5879 26.0639 28.7878 15.8322 41.3701 15.8322H64.9017C66.4388 15.9137 67.6681 17.147 67.7456 18.6849C67.7495 27.412 74.1915 33.8741 82.1382 33.8741Z"
          fill="white"
        />
      </mask>

      <defs>
        <filter
          id="filter0_dd_291_72944"
          x="0.798828"
          y="0"
          width="260.402"
          height="524"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0823529 0 0 0 0 0.25098 0 0 0 0.14 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_291_72944" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0823529 0 0 0 0 0.25098 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_291_72944"
            result="effect2_dropShadow_291_72944"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_291_72944"
            result="shape"
          />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use transform="translate(0 -0.231795) scale(0.00127877 0.000588733)" />
        </pattern>
      </defs>
    </svg>
  );
}
