import amplitude from 'amplitude-js';
import { eventProperties, shouldApplied } from './init';
import PS from 'settings';

// reset user

export function resetUserID() {
  if (shouldApplied) {
    amplitude.getInstance().setUserId(null);
    // disabling regenerate device id
    // amplitude.getInstance().regenerateDeviceId();
  }
}

// login and onboarding

export function enterEmail() {
  if (shouldApplied) {
    const EVENT_NAME = 'Enter Email';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function onPage(page) {
  if (shouldApplied) {
    const EVENT_NAME = 'On Page';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      page,
    });
  }
}

export function clickedSignup() {
  if (shouldApplied) {
    const EVENT_NAME = 'Clicked Signup';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function clickSocial(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Click Social';
    amplitude.getInstance().logEvent(EVENT_NAME, { 'social type': type });
  }
}

export function setAmplitudeUserId(id) {
  if (shouldApplied) {
    amplitude.getInstance().setUserId(id);
  }
}

export function createAccount(firstName, lastname, id, organisation, deviceId) {
  if (shouldApplied) {
    if (deviceId) {
      amplitude.getInstance().init(PS.amplitudeApiKey, id, {
        deviceId,
        includeUtm: true,
        includeGclid: true,
        includeReferrer: true,
        saveParamsReferrerOncePerSession: false,
      });
    } else {
      amplitude.getInstance().init(PS.amplitudeApiKey, id, {
        includeUtm: true,
        includeGclid: true,
        includeReferrer: true,
        saveParamsReferrerOncePerSession: false,
      });
    }
    const EVENT_NAME = 'Create Account';
    amplitude.getInstance().setUserId(id);
    amplitude.getInstance().setUserProperties({
      firstName,
      lastName: lastname,
      organisation,
      'organiser id': id,
    });
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function loginSuccessful(type, firstName, lastname, id, organisation, deviceId) {
  if (shouldApplied) {
    if (deviceId) {
      amplitude.getInstance().init(PS.amplitudeApiKey, id, {
        deviceId,
        includeUtm: true,
        includeGclid: true,
        includeReferrer: true,
        saveParamsReferrerOncePerSession: false,
      });
    } else {
      amplitude.getInstance().init(PS.amplitudeApiKey, id, {
        includeUtm: true,
        includeGclid: true,
        includeReferrer: true,
        saveParamsReferrerOncePerSession: false,
      });
    }
    const EVENT_NAME = 'Login Successful';
    amplitude.getInstance().setUserId(id);
    amplitude.getInstance().setUserProperties({
      firstName,
      lastName: lastname,
      organisation,
      'organiser id': id,
    });
    amplitude.getInstance().logEvent(EVENT_NAME, { type });
  }
}

export function organisationDetails() {
  if (shouldApplied) {
    const EVENT_NAME = 'Organisation Details';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function moreAboutYourself(eventsAnually, averageAttendeeSize, industry) {
  if (shouldApplied) {
    const EVENT_NAME = 'Started Free Trial';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'number of events anually': eventsAnually,
      'avg attendee size': averageAttendeeSize,
      organisation: industry,
    });
  }
}

// get session id

export function getAmplitudeSessionId() {
  if (shouldApplied) {
    const id = amplitude.getInstance()._sessionId;
    return id;
  } else {
    return '';
  }
}

// general

export function search() {
  if (shouldApplied) {
    const EVENT_NAME = 'Search';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function exportFile() {
  if (shouldApplied) {
    const EVENT_NAME = 'Export File';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function landOnPage(page) {
  if (shouldApplied) {
    const EVENT_NAME = 'Land On page';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      page,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      'organiser id': eventProperties.organiserId ?? '',
    });
  }
}

export function selectNavBarItem(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'select navbar item';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      item: type,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function connectIntegration(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'integration connected';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// event list

export function createEvent(eventId, eventName, eventType, eventSubtype, valueAgainstOther) {
  if (shouldApplied) {
    const EVENT_NAME = 'Create Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventId,
      'event name': eventName,
      'event type': eventType,
      'event subtype': eventSubtype,
      'value against other': valueAgainstOther,
    });
  }
}

export function editEvent(
  eventId,
  eventName,
  page,
  eventType,
  eventSubtype,
  valueAgainstOther,
  country,
  addLocation,
  mapUsed,
  eventFormat
) {
  if (shouldApplied) {
    const EVENT_NAME = 'edit event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventId,
      'event name': eventName,
      page,
      'event type': eventType,
      'event subtype': eventSubtype,
      'value against other': valueAgainstOther,
      country,
      'add location': addLocation,
      'map used': mapUsed,
      'event format': eventFormat,
    });
  }
}

export function deleteEvent() {
  if (shouldApplied) {
    // TODO
  }
}

export function eventDeleted(eventId) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventId,
    });
  }
}

// event overview

export function publishEvent() {
  if (shouldApplied) {
    const EVENT_NAME = 'Publish Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function unpublishEvent() {
  if (shouldApplied) {
    const EVENT_NAME = 'Unpublish Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function quickAccess(pageType) {
  if (shouldApplied) {
    const EVENT_NAME = 'quick access';
    amplitude.getInstance().logEvent(EVENT_NAME, { 'page type': pageType });
  }
}

export function goToEvent(from) {
  if (shouldApplied) {
    const EVENT_NAME = 'go to Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      from,
    });
  }
}

// sessions

export function addSession(type, count, id, name) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Session';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
      count,
      'session id': id,
      'session name': name,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteSession(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Session';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'session id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addDay() {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Day';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteDay() {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Day';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addTrack(from) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Track';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source: from,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function editTrack() {
  if (shouldApplied) {
    const EVENT_NAME = 'edit track';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function editSession(
  sessionName,
  sessionId,
  sessionSpeaker,
  sessionSponser,
  rateSession,
  featuredSession,
  tagsAdded,
  filesAdded
) {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Session';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'session name': sessionName,
      'session id': sessionId,
      'add session speaker': sessionSpeaker,
      'add session sponser': sessionSponser,
      'rate session': rateSession,
      'feature session': featuredSession,
      'add tags': tagsAdded,
      'add files': filesAdded,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function advanceSessionProperty(
  sessionName,
  sessionId,
  registrationStatus,
  sessionStatus,
  interpretationStatus,
  iframeStatus,
  creditsEnabled,
  sessionLayout,
  enableBackgroundImage,
  uploadedImage,
  enableWatchParty
) {
  if (shouldApplied) {
    const EVENT_NAME = 'advance session detail';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'session name': sessionName,
      'session id': sessionId,
      'enable registration': registrationStatus,
      'hide session': sessionStatus,
      interpretation: interpretationStatus,
      'custom iframe intergration': iframeStatus,
      'credits enabled': creditsEnabled,
      'session layout': sessionLayout,
      'enable background image': enableBackgroundImage,
      'uploaded image': uploadedImage,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      'enable watch party': enableWatchParty,
    });
  }
}

export function registerAttendees(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Register Attendees';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
    });
  }
}

// people

export function addPeople(type, groups, inviteMailer, count) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add People';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
      'people type': groups,
      count,
      'send invite': inviteMailer,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function reorderSpeaker() {
  if (shouldApplied) {
    const EVENT_NAME = 'reorder speaker';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function changeColumnView() {
  if (shouldApplied) {
    const EVENT_NAME = 'Change Column View';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function editPeople(number) {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit People';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'number of record': number,
    });
  }
}

export function deletePeople(number, peopleType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete People';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      count: number,
      'people type': peopleType,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function sendInviteMailer(number) {
  if (shouldApplied) {
    const EVENT_NAME = 'Send Invite Mailer';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'number of record': number,
    });
  }
}

export function assignGroups(number) {
  if (shouldApplied) {
    const EVENT_NAME = 'Assign Groups';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'number of record': number,
    });
  }
}

export function block(number, peopleType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Block';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      count: number,
      'people type': peopleType,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function unblock(number, peopleType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Unblock';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      count: number,
      'people type': peopleType,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// virtual booth

export function addVirtualBooth(type, count, id, name, boothSize, rateBooth, featureBooth) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Virtual Booth';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source: type,
      count,
      'booth size': boothSize,
      'rate booth': rateBooth,
      'feature booth': featureBooth,
      'booth id': id,
      'booth name': name,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function editVirtualBooth() {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Virtual Booth';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteVirtualBooth(ids) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Virtual Booth';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'booth id': ids,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function bulkUpload() {
  if (shouldApplied) {
    const EVENT_NAME = 'Bulk Upload';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function addTeamMembers(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'assign team members';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addProductImages(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Product Images';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addProductVideos(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Product Videos';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addCustomCTA(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Custom Cta';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function premiumBooth(status, id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Premium Booth';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      status,
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function manageBooth(status, id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Manage Booth';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      status,
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function boothAnalytics(status, id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Booth Analytics';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      status,
      'booth id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// meeting

export function addMeetings(id, confirmation, isPhysicalMeet) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Meetings';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'meeting id': id,
      'send confirmation': confirmation ? 'yes' : 'no',
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      onsite: isPhysicalMeet && isPhysicalMeet === 1 ? 'yes' : 'no',
    });
  }
}

export function manageTimings() {
  if (shouldApplied) {
    const EVENT_NAME = 'Manage Timings';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function filterMeetings(options) {
  if (shouldApplied) {
    const EVENT_NAME = 'Filter Meetings';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      options,
    });
  }
}

export function deleteMeeting(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Meeting';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'meeting id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// lounge

export function addLoungeTable(type, id, name, capacity, logo) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Lounge Table';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'table type': type,
      'table id': id,
      'table name': name,
      capacity,
      logo,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteLoungeTable(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Lounge Table';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'table id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function manageSlots(duration) {
  if (shouldApplied) {
    const EVENT_NAME = 'Manage Slots';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'manage slot duration': duration,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// rooms

export function addRoom(id, name, type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Room';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'room id': id,
      'room name': name,
      'room type': type,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function editRoom(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'edit room';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
    });
  }
}

export function filterRoom(options) {
  if (shouldApplied) {
    const EVENT_NAME = 'filter room';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      options,
    });
  }
}

export function deleteRoom(ids) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Room';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      // "room type": type,
      'room id': ids,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// notifications

export function addNewNotifications(recipients, schedule) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add New Notifications';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      groups: recipients,
      schedule,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteNotifications() {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Notifications';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function filterNotifications(options) {
  if (shouldApplied) {
    const EVENT_NAME = 'filter notifications';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      options,
    });
  }
}

// activity feed

export function enableModerate(status) {
  if (shouldApplied) {
    const EVENT_NAME = 'Enable Moderate';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      status,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function approvePost() {
  if (shouldApplied) {
    const EVENT_NAME = 'Approve Post';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function rejectPost() {
  if (shouldApplied) {
    const EVENT_NAME = 'Reject Post';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function pinPost() {
  if (shouldApplied) {
    const EVENT_NAME = 'Pin Post';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteComment() {
  if (shouldApplied) {
    const EVENT_NAME = 'delete comment';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

// leaderboard

export function enableHideLeaderboard(status) {
  if (shouldApplied) {
    const EVENT_NAME = 'Enable Hide Leaderboard';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      enable: status,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function editPoints(addSubtract, attendeeId) {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Points';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'attendee id': attendeeId,
      'add / subtract': addSubtract,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function editScore() {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Score';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// brand your event

export function favicon(imageUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Favicon';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Image Upload': imageUpload,
    });
  }
}

export function editCustomUrl() {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Custom Url';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function communityBanner(imageUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Community Banner';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Image Upload': imageUpload,
    });
  }
}

export function eventLogo(imageUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Event Logo';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Image Upload': imageUpload,
    });
  }
}

// eslint-disable-next-line no-shadow
export function eventLogoCheck(eventLogo, planName) {
  if (shouldApplied) {
    const EVENT_NAME = 'enable event logo in navigation';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'enable event logo in navigation': eventLogo,
      'plan name': planName === undefined ? 'planless' : planName,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function hideHubiloLogo(hideHubilo, planName) {
  if (shouldApplied) {
    const EVENT_NAME = 'hide hubilo logo';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'hide hubilo logo': hideHubilo,
      'plan name': planName === undefined ? 'planless' : planName,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function logoBanner(imageUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Logo Banner';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Image Upload': imageUpload,
    });
  }
}

export function welcomeVideo(fileUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Welcome Video';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'File Upload': fileUpload,
    });
  }
}

export function editColor(color) {
  if (shouldApplied) {
    const EVENT_NAME = 'Edit Color';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'selected color code': color,
    });
  }
}

// analytics

export function analyticsPageExportAnalytics(section) {
  if (shouldApplied) {
    const EVENT_NAME = 'export analytics';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      section,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function analyticsPage(section) {
  if (shouldApplied) {
    const EVENT_NAME = 'Analytics Page';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      section,
    });
  }
}

// settings

export function saveLoginOptions(restrictAccess, loginType, defaultLoginCode, customLoginStatus) {
  if (shouldApplied) {
    const EVENT_NAME = 'login options';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'restrict access': restrictAccess,
      'login type': loginType,
      'default login code': defaultLoginCode,
      type: customLoginStatus,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function addEventSection(sectionType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Event Section';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'section type': sectionType,
    });
  }
}

export function addGroup(name, groupsType, attendanceType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Group';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      name,
      'groups type': groupsType,
      'attendance type': attendanceType,
    });
  }
}

export function addProfileField(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Profile Field';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
    });
  }
}
export function matchmakingFields(interest, industry, lookingFor, customTags) {
  if (shouldApplied) {
    const EVENT_NAME = 'Match Making Fields';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      interest,
      industry,
      'looking for/ offering': lookingFor,
      'custom tags': customTags,
    });
  }
}

export function senderDetailConfigure() {
  if (shouldApplied) {
    const EVENT_NAME = 'Sender Detail Configure';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function configuredLegal(termsAndCondition, privacyPolicy, cookiePolicy) {
  if (shouldApplied) {
    const EVENT_NAME = 'Configured Legal';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Terms And Condition': termsAndCondition,
      'Privacy Policy': privacyPolicy,
      'Cookie Policy': cookiePolicy,
    });
  }
}

export function integrations() {
  if (shouldApplied) {
    const EVENT_NAME = 'Integrations';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// recordings

export function clickType(type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Click Type';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
    });
  }
}

// account

export function addProfilePicture(imageUpload) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Profile Picture';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'image upload': imageUpload,
    });
  }
}
export function changePassword() {
  if (shouldApplied) {
    const EVENT_NAME = 'Change Password';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

// team

export function addTeamMember(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Team Member';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteTeamMember(count) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Team Member';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      count,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}
// pricing and billing

export function upgradeClicked(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'Upgrade Clicked';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'upgrade source': source,
    });
  }
}

export function mayBeLaterClicked(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'click may be later';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source,
    });
  }
}

export function checkOutStarted(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Checkout Started';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function salesContacted(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Sales Contacted';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function subscribed(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Subscribed';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function planUpgraded(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Plan Upgraded';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function clickAbortCancellation(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Click Abort Cancellation';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function abortCancelSubscription(planType) {
  if (shouldApplied) {
    const EVENT_NAME = 'Abort Cancel Subscription';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      plan: planType,
    });
  }
}

export function clickReactivateSubscription() {
  if (shouldApplied) {
    const EVENT_NAME = 'Click Reactivate Subscription';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function reactivatedSubscription() {
  if (shouldApplied) {
    const EVENT_NAME = 'Reactivated Subscription';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

// Ticket

export function addTicket({ type, quantity, associatedGroup }) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add Ticket';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
      quantity,
      'associated group': associatedGroup,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// settings

export function settingsGeneral(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'general settings';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function settingMatchMaking(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'Matchmaking Fields';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

/* export function addCustomIntegration(data){
  if (shouldApplied) {
    const EVENT_NAME = "add custom integration";
    amplitude.getInstance().logEvent(EVENT_NAME,{
    ...data,
    "event id":eventProperties.eventId ?? '',
    "event name":eventProperties.eventName ?? '',
    });
  }
} */

// Sponsored Ads

export function addCreativeIntegration(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'add creative';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Brand Your Event

export function addBrandEvent(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'brand event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Engagement Contest

export function addEngagementContest(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'add contest';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteEngagementContest(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'delete contest';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deactivateEngagementContest(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'deactivate contest';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Engagement Survey

export function addEngagementSurvey(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'add survey';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteEngagementSurvey(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'Delete Survey';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export const v2TicketingTrack = (logEventName, data) => {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(logEventName, {
      ...data,
      'event id': eventProperties.eventId || '',
      'event name': eventProperties.eventName || '',
    });
  }
};

export function clickManageLocation() {
  if (shouldApplied) {
    const EVENT_NAME = 'click manage location';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}

export function clickAddLocation() {
  if (shouldApplied) {
    const EVENT_NAME = 'add location';
    amplitude.getInstance().logEvent(EVENT_NAME);
  }
}
export function locationAdded(addType, addCount, name) {
  if (shouldApplied) {
    const EVENT_NAME = 'location added';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: addType,
      count: addCount,
      ' meeting room/table name': name,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function deleteLocation(id) {
  if (shouldApplied) {
    const EVENT_NAME = 'delete location';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'meeting room/table id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}
export function editLocation(name, id) {
  if (shouldApplied) {
    const EVENT_NAME = 'edit location';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'meeting room/table name': name,
      'meeting room/table id': id,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function previewEventData(data) {
  if (shouldApplied) {
    const EVENT_NAME = 'Preview Event';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      ...data,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Interactive Poll On Stage Amplitude
export function saveOnStageData(sessionId, SessionName, onStageData) {
  if (shouldApplied) {
    const EVENT_NAME = 'save engagement Polls';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Show on stage': onStageData ? 'Yes' : 'No',
      'session id': sessionId,
      'session name': SessionName,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Interactive Chat On Stage Amplitude
export function saveOnStageChatData(sessionId, SessionName, onStageData) {
  if (shouldApplied) {
    const EVENT_NAME = 'save engagement live chat';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Show on stage': onStageData ? 'Yes' : 'No',
      'session id': sessionId,
      'session name': SessionName,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Interactive QnA On Stage Amplitude
export function saveOnStageQnAData(sessionId, SessionName, onStageData, ModerationData) {
  if (shouldApplied) {
    const EVENT_NAME = 'save engagement QnA';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Show on stage': onStageData ? 'Yes' : 'No',
      'Moderation on': ModerationData ? 'Yes' : 'No',
      'session id': sessionId,
      'session name': SessionName,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export const trackUserActivityForTrackingPixel = (eventName = '', data = {}, isSave = 'Y') => {
  if (shouldApplied && eventName) {
    const EVENT_NAME = eventName;
    let prop = {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      ...data,
    };
    if (isSave) {
      prop = { ...prop, 'Save Success': isSave };
    }
    amplitude.getInstance().logEvent(EVENT_NAME, { ...prop });
  }
};

export const commonTrackEvent = (eventName = '', data = {}) => {
  if (shouldApplied && eventName) {
    const EVENT_NAME = eventName;
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      'organiser id': eventProperties.organiserId ?? '',
      ...data,
    });
  }
};

// Simulcasting EVENTS
export const SAVE_STREAM_OPTION = 'save stream option';
export const ADD_SIMULCAST_TO_STREAM = 'add simulcast to stream';
export const SAVE_SIMULCAST_TARGET = 'save simulcast target';
export const EDIT_SIMULCAST = 'edit simulcast';
export const DELETE_SIMULCAST = 'delete simulcast';
export const ENABLE_STREAM = 'enable stream';

export function amplitudeClick(event, arg) {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(event, {
      ...arg,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

// Analytics > session
export const downloadSession = (type) => {
  if (shouldApplied && type) {
    const EVENT_NAME = 'export analytics';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source: 'session',
      type,
    });
  }
};

export const cancelDownloadAllSession = (sortBy = 'day', from = 'close') => {
  if (shouldApplied) {
    const EVENT_NAME = 'cancel session report';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'format by': sortBy,
      from,
    });
  }
};

export const sendAllSessionReport = (sortBy = 'day') => {
  if (shouldApplied) {
    const EVENT_NAME = 'send session report';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'format by': sortBy,
    });
  }
};

export const ticketWidgetIconClick = () => {
  if (shouldApplied) {
    const EVENT_NAME = 'ticket widget icon click';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
};
export const emailBuilderActions = (EVENT_NAME, type, campaignName, campaignId) => {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: type === 'CUSTOM' ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const ticketWidgetCodeGenerated = (eventInfo, width, height, language, urlCount) => {
  if (shouldApplied) {
    const EVENT_NAME = 'ticket widget code generated';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'Display event name & event date': eventInfo,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
      width,
      height,
      language,
      urlCount,
    });
  }
};

export const filterEmailWorkFlow = (eventState, audienceSegments, status) => {
  if (shouldApplied) {
    const EVENT_NAME = 'apply email workflow filter';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      event_state: eventState,
      audience_segments: audienceSegments,
      status,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const totalWhitelistedURLS = (urlCount) => {
  if (shouldApplied) {
    const EVENT_NAME = 'total whitelisted urls';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      count: urlCount,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
};
export const saveBasicEmail = (isCustom, campaignName, campaignId, eventState, subjectEdited) => {
  if (shouldApplied) {
    const EVENT_NAME = 'save basic details email creation';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      'email event state': eventState,
      'email subject edited': isCustom ? 'NA' : subjectEdited ? 'Y' : 'N',
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const saveSenderEmail = (
  isCustom,
  campaignName,
  campaignId,
  senderNameEdited,
  ccEdited,
  bccEdited
) => {
  if (shouldApplied) {
    const EVENT_NAME = 'save from email creation';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      'sender name edited': isCustom ? 'NA' : senderNameEdited ? 'Y' : 'N',
      'cc edited': isCustom ? 'NA' : ccEdited ? 'Y' : 'N',
      'bcc edited': isCustom ? 'NA' : bccEdited ? 'Y' : 'N',
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const saveAudienceEmail = (isCustom, campaignName, campaignId, audienceSegments) => {
  if (shouldApplied) {
    const EVENT_NAME = 'save to email creation';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      audience_segments: audienceSegments,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const createNewTemplate = (isCustom, campaignName, campaignId) => {
  if (shouldApplied) {
    const EVENT_NAME = 'create new template';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const templateActions = (
  EVENT_NAME,
  isCustom,
  campaignName,
  campaignId,
  templateId,
  templateName
) => {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      'template id': templateId,
      'template Name': templateName,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};
export const campaignActions = (EVENT_NAME, campaignName, campaignId) => {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'campaign name': campaignName,
      'campaign id': campaignId,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export const sendTestEmail = (isCustom, campaignName, campaignId) => {
  if (shouldApplied) {
    const EVENT_NAME = 'send test email';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type: isCustom ? 'Custom' : 'Automated',
      'campaign name': campaignName,
      'campaign id': campaignId,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
};

export function createNowCustomEmail() {
  if (shouldApplied) {
    const EVENT_NAME = 'create now custom email click';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
}

export function emailBuilderUpgradeClicked(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'Upgrade Clicked';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      'upgrade source': source,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
}

export function emailBuilderMayBeLaterClicked(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'click may be later';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source,
      event_id: eventProperties.eventId ?? '',
      event_name: eventProperties.eventName ?? '',
    });
  }
}

export function copyEventClick(parentEventId) {
  if (shouldApplied) {
    const EVENT_NAME = 'copy event clicked';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_event_id: parentEventId,
    });
  }
}

export function copyEventNextClick(parentEventId) {
  if (shouldApplied) {
    const EVENT_NAME = 'copy event next event details';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_event_id: parentEventId,
    });
  }
}
export function copyEventOptions(parentEventId, modules) {
  if (shouldApplied) {
    const EVENT_NAME = 'copy event options';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_event_id: parentEventId,
      Session: modules?.sessions ? 'Y' : 'N',
      Speakers: modules?.speakers ? 'Y' : 'N',
      Lounges: modules?.lounges ? 'Y' : 'N',
      'Virtual Booths': modules?.virtualbooths ? 'Y' : 'N',
      Branding: modules?.branding ? 'Y' : 'N',
      Settings: modules?.settings ? 'Y' : 'N',
    });
  }
}
export function proceedClick(parentEventId, type) {
  if (shouldApplied) {
    const EVENT_NAME = 'Proceed click ';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_event_id: parentEventId,
      type,
    });
  }
}
export function CopyEventSuccessFailure(parentEventId, targetEventId, isSuccess) {
  if (shouldApplied) {
    const EVENT_NAME = 'copy successful';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_event_id: parentEventId,
      event_id: targetEventId,
      success: isSuccess ? 'Y' : 'N',
    });
  }
}

// create event amplitude
export const createEventTrackAmplitute = (eventName = '', data = {}) => {
  if (shouldApplied && eventName) {
    const EVENT_NAME = eventName;
    amplitude.getInstance().logEvent(EVENT_NAME, data);
  }
};

export function addBulkPeople(type, inviteMailer) {
  if (shouldApplied) {
    const EVENT_NAME = 'Add People';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      type,
      'send invite': inviteMailer,
      'event id': eventProperties.eventId ?? '',
      'event name': eventProperties.eventName ?? '',
    });
  }
}

export function clickPeopleFilter(source) {
  if (shouldApplied) {
    const EVENT_NAME = 'click filter';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      source,
    });
  }
}

export function applyPeopleFilter(
  group,
  designation,
  loginStatus,
  inviteEmailSent,
  status,
  organization,
  industry
) {
  if (shouldApplied) {
    const EVENT_NAME = 'apply people filter';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      group,
      designation,
      'login status': loginStatus,
      'invite email sent': inviteEmailSent,
      status,
      organization,
      industry,
    });
  }
}

export function newHBSSelected() {
  if (shouldApplied) {
    const EVENT_NAME = 'NewHBSSelected';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_screen: 'STREAM_OPTIONS'
    });
  }
}

export function cohostAdded() {
  if (shouldApplied) {
    const EVENT_NAME = 'CohostAdded';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_screen: 'STREAM_OPTIONS'
    });
  }
}

export function cohostReplaced() {
  if (shouldApplied) {
    const EVENT_NAME = 'CohostReplaced';
    amplitude.getInstance().logEvent(EVENT_NAME, {
      parent_screen: 'STREAM_OPTIONS'
    });
  }
}
export function commonAmplitudeTrack(eventName, data) {
  if (shouldApplied) {
    amplitude.getInstance().logEvent(eventName, {
      data,
    });
  }
}
