/* eslint-disable no-sequences */
/* eslint-disable func-names */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import { actionTypes } from 'redux/actions/types';

const {
    IS_ROOM_REACTIONS_LOADING,
    UPDATE_ROOM_REACTIONS_ENABLE_DATA,
} = actionTypes;

const iState = {
    isEmojiRoomReactionsLoading: false,
    isRoomReactionsEnabled: null,
};

const roomReactionReducerEnable = (state = iState, action) => {
    switch (action.type) {
        case IS_ROOM_REACTIONS_LOADING:
            return { ...state, isInitLoading: action.data };
        case UPDATE_ROOM_REACTIONS_ENABLE_DATA:
            return { ...state, isRoomReactionsEnabled: action.data };
        default:
            return state;
    }
};

export default roomReactionReducerEnable;
