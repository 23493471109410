/* eslint-disable  */
import React from 'react';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  upgradeBlock: {
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.alert.warn2Light,
    '& .contentBlock': {
      '& .MuiTypography-h6': {
        color: theme.palette.text.primary,
        lineHeight: theme.spacing[6],
        fontSize: theme.fontSize.size2,
        fontWeight: 600,
        marginBottom: 5,
      },
    },
  },
  tagBlock: {
    '&>label': {
      backgroundColor: theme.palette.primary.contrastText,
      padding: '4px 14px',
      borderRadius: 15,
      '&:nth-child(n+2)': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

export default function DefaultRestrictionInnerScreen({
  title,
  planList,
  subTitle,
  svgIcon,
  btnLabel,
  btnAction,
  children,
  marginAuto,
  widthOfBox,
  learnMoreLink,
  hasButtonLoader = '',
  isLoading = false,
  loadingLabel = '',
  spaceIconHeader = 1,
  spaceHeaderSubHeader = 1,
  spaceSubHeaderChildren = 1,
  spaceChildrenButton = 2,
  languageSupportPlan,
  languageSupportPlanSidebar,
  ...props
}) {
  const classes = useStyles();

  return (
    <>
      {languageSupportPlan ? (
        <Box
          className={classes.upgradeBlock}
          paddingTop={3}
          paddingBottom={3}
          paddingRight={3}
          paddingLeft={3}
          marginTop={3}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          {...props}>
          <Box className="iconBlock" display="flex" alignItems="center">
            {svgIcon}
          </Box>
          <Box className="contentBlock" pl={2.75}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box width="70%">
                {!title ? (
                  false
                ) : (
                  <>
                    <Typography component="h6" variant="h6">
                      {title}
                    </Typography>
                    {planList ? (
                      <Box display="flex" alignItems="center" mb={2} className={classes.tagBlock}>
                        <Typography color="textPrimary" variant="body2" component="label">
                          {planList[0]}
                        </Typography>
                        <Typography color="textPrimary" variant="body2" component="label">
                          {planList[1]}
                        </Typography>
                      </Box>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </Box>
              <Box width="30%" textAlign="right">
                {!btnLabel ? (
                  false
                ) : (
                  <Button
                    className="uppercase"
                    onClick={btnAction}
                    size="small"
                    variant="contained"
                    color="primary">
                    {btnLabel}
                  </Button>
                )}
              </Box>
            </Box>
            <Box>
              {!subTitle ? (
                false
              ) : (
                <Typography component="p" variant="body2" color="textSecondary">
                  {' '}
                  {subTitle}{' '}
                  {learnMoreLink ? (
                    <Box
                      onClick={(e) => window.open(learnMoreLink, '_blank')}
                      component="a"
                      variant="body2"
                      ml={0.3}
                      className={'text-underline  cursor-pointer'}>
                      Learn More
                    </Box>
                  ) : (
                    ''
                  )}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          className={classes.upgradeBlock}
          paddingTop={3}
          paddingBottom={3}
          paddingRight={3}
          paddingLeft={3}
          marginTop={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          {...props}>
          {!title ? (
            false
          ) : (
            <Typography component="h6" variant="h6">
              {title}
            </Typography>
          )}
          {planList ? (
            <Box display="flex" alignItems="center" mb={2} className={classes.tagBlock}>
              <Typography color="textPrimary" variant="body2" component="label">
                {planList[0]}
              </Typography>
              <Typography color="textPrimary" variant="body2" component="label">
                {planList[1]}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box
            className="iconBlock"
            display="flex"
            alignItems="center"
            marginTop={4.5}
            marginBottom={4.5}>
            {svgIcon}
          </Box>
          <Box paddingRight={2} paddingLeft={2} className="text-center">
            {!subTitle ? (
              false
            ) : (
              <Typography component="p" variant="body2" color="textSecondary">
                {' '}
                {subTitle}{' '}
                {learnMoreLink ? (
                  <Box
                    onClick={(e) => window.open(learnMoreLink, '_blank')}
                    component="a"
                    variant="body2"
                    ml={0.3}
                    className={'text-underline  cursor-pointer'}>
                    Learn More
                  </Box>
                ) : (
                  ''
                )}
              </Typography>
            )}
          </Box>
          <Box className="contentBlock" marginTop={3} pl={2.75}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box textAlign="right">
                {!btnLabel ? (
                  false
                ) : (
                  <Button
                    className="uppercase"
                    onClick={btnAction}
                    size="small"
                    variant="contained"
                    color="primary">
                    {btnLabel}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
