import React from 'react';

import { Box, Typography } from '@material-ui/core';

import { CREATE_EVENT_CONSTANTS } from 'pages/CreateNewEvent/common/constants';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import { useStylesForLanguages } from '../style';

const EventLanguagesLoader = () => {
    const classes = useStylesForLanguages();

    return (
        <Box className={classes.eventLanguagesScreen}>
            {!CREATE_EVENT_CONSTANTS.ADVANCE_PLAN ?
                <SkeletonLoaders variant="text" height="120px" width="516px" /> :
                <Box p={3} mt={3} className="upgradePlanCard">
                    <Box className='upgradePlanCardText'>
                        <Typography component='h6' variant='h6' color="textPrimary"><SkeletonLoaders variant="text" height="40px" width="120px" /></Typography>
                        <Typography variant="body2" component="span">
                            <SkeletonLoaders variant="text" height="30px" width="327px" />
                            <SkeletonLoaders variant="text" height="30px" width="100px" />
                        </Typography>
                    </Box>
                    <SkeletonLoaders variant="text" height="60px" width="120px" />
                </Box>
            }
            <Box className='' mt={2}>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <Box mr={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                    <Box ml={1}>
                        <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />
                    </Box>
                </Box>
                <Box display="flex"
                    alignItems="center"
                    justifyContent="space-between" mt={-4}>
                    <SkeletonLoaders className='createEventOption' variant="text" height="150px" width="250px" />

                </Box>

            </Box>
        </Box>
    );
}

export default EventLanguagesLoader;