export const SET_COPY_EVENT_DATA = 'SET_COPY_EVENT_DATA';
export const REMOVE_COPY_EVENT_DATA = 'REMOVE_COPY_EVENT_DATA';

export const setCopyEventData = (eventData) => {
  return {
    type: SET_COPY_EVENT_DATA,
    data: eventData,
  };
};

export const removeCopyEventData = () => {
  return {
    type: REMOVE_COPY_EVENT_DATA,
  };
};

