/* eslint-disable  */
import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  IconButton,
  LinearProgress,
  Button,
} from '@material-ui/core';
import SvgImageIcon from 'icons/SvgImageIcon';
// import SvgDeleteIcon from 'icons/svgDelete';
// import SvgRefreshIcon from 'icons/svgRefresh';
// import SvgCrossIcon from 'icons/svgCross';
import { Check as CheckIcon, Trash2, RotateCw, Eye, Image, X as CancelIcon, Download } from 'react-feather';
// import shape from '@material-ui/core/styles/shape';
import InputField from './inputField';
// import Sidebar from 'custom-components/sidebar';
// import { ArrowDownCircle } from 'react-feather';
import { EXTRACT_REGEX } from 'models/regex';
import { blobToFile, fileToDataUrl, generateAllowTypes } from 'lib/common-file-function';
import PropTypes from 'prop-types';
import CustomToolTip from 'custom-components/customToolTip';
import SvgImageReload from 'icons/SvgImageReload';
import FieldWrapper from './fieldWrapper';
import CustomModal from 'custom-components/customModal';
import Cropper from 'custom-components/cropper';

const useStyles = makeStyles((theme) => ({
  textDanger: {
    color: theme.palette.text.danger1,
    fontWeight: 500,
    fontSize: `13px`,
  },
  uploadedFileRow: {
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: 4,
    padding: theme.spacing(0.875),
  },
  fileSvgIcon: {
    // border: `1px solid ${theme.palette.bg.separator}`,
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: 4,
    marginTop:'0.3rem',
    // padding: theme.spacing(0.875),
  },
  fileActionIconButton: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    color: theme.palette.text.grayColor,
    backgroundColor: `transparent`,
    '&.MuiIconButton-colorPrimary': {
      color: theme.palette.primary.main,
    },
    "&:hover":{
    backgroundColor: `transparent`,

    }
    // padding: 8,
  },
  progressBar: {
    width: 90,
    marginLeft: 'auto',
  },
  fileInfoWrap: {
    maxWidth: `calc(100% - 65px - 8px)`,
    width: '100%',
    '&.uploading-proceed': {
      maxWidth: `calc(100% - 145px - 8px)`,
    },
    '&.uploaded-status': {
      maxWidth: `calc(100% - 190px - 8px)`,
    },
    '&.show-file-name-edit': {
      maxWidth: '100%',
    },
  },
  fileActionsWrap: {
    width: 40,
    '&.uploading-proceed': {
      width: 145,
    },
    '&.uploaded-status': {
      width: 190,
    },
  },
  fileInfoBlock: {
    maxWidth: `calc(100% - 40px - 10px)`,
    width: '100%',
  },
  fileNameHead: {
    maxWidth: '100%',
  },
  fileField: {
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px dashed  ${theme.palette.text.grayColor1}`,
    borderRadius: theme.shape.borderRadius,
    '& input': {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },
  fileUploadingProgress: {
    fontSize: theme.fontSize.size10,
    marginLeft: 'auto',
    marginBottom: 2,
  },
  editFileNameAction: {
    padding: 0,
    width: 25,
    minWidth: 25,
    height: 25,
  },
  fileNameEditField: {
    backgroundColor: theme.palette.bg.whiteOff1,
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1.25)}px ${theme.spacing(0.25)}px 0`,
    // '& input': {
    // 	borderRadius: 0,
    // 	border: 0,
    // 	outline: 0,
    // 	width: '100%',
    // 	fontSize: theme.fontSize.size8,
    // 	color: theme.palette.text.primary,
    // 	'&::-webkit-input-placeholder': {
    // 		color: theme.palette.text.grayColor,
    // 		opacity: 1,
    // 		transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    // 	},
    // 	'&::-moz-input-placeholder': {
    // 		color: theme.palette.text.grayColor,
    // 		opacity: 1,
    // 		transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    // 	},
    // 	'&:-ms-input-placeholder': {
    // 		color: theme.palette.text.grayColor,
    // 		opacity: 1,
    // 		transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    // 	},
    // 	'&:-moz-placeholder': {
    // 		color: theme.palette.text.grayColor,
    // 		opacity: 1,
    // 		transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    // 	},
    // 	'&::placeholder': {
    // 		color: theme.palette.text.grayColor,
    // 		opacity: 1,
    // 		transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    // 	}
    // }
  },
  errorText: {
    color: theme.palette.text.danger1,
  },
  paymentScreenshot: {
    width: '100%',
    backgroundColor: theme.palette.bg.whiteOff1,
    padding: '12px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: ' space-between',
    border: '1px solid #f0f0f0',
  },
}));

export function FileUploadUI(props) {
  const {
    fileTypes = [],
    uploadStatus = '',
    isAllowedFileUpload = true,
    isShowPreview = false,
    comingFrom,
    downloadUrl,
    maxImageHeight,
    maxImageWidth,
    isCropper,
    showMaxSize = false,
  } = props;
  const classes = useStyles();
  const [isEditFileName, toggleEditFileName] = useState(false);
  const [cropperData, setCropperData] = useState({
    isOpen: false,
    url: '',
    tempFile: null
  });
  const [previewURL, setPreviewURL] = useState('');

  const handleCropperClose = () => setCropperData({
    url: '',
    isOpen: false,
    tempFile: null
  });

  const handleOnCrop = (url) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = blobToFile(blob, cropperData.tempFile.name);
        const prvUrl = URL.createObjectURL(file); // local file url generate
        setPreviewURL(prvUrl);
        props.setFileData({
          fileName: file.name,
          fileBlob: file,
          realFileName: file.name,
        });
        props.onUploadFile(file, file.name);
        handleCropperClose();
      });
  }

  const onChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const ext = EXTRACT_REGEX.exec(file.name)[1];
      const allowedType = generateAllowTypes(fileTypes);
      const imageTypes = generateAllowTypes(['IMAGE']);
      if (!ext || !allowedType.includes(`.${ext.toLocaleLowerCase()}`)) {
        return props.onErrorMessage(
          `This file type is not supported. Supported attachments are ${allowedType.toString()} `
        );
      }
      if ((file.size / (1024 * 1024)).toFixed(2) > (props.maxSize || 0)) {
        return props.onErrorMessage(
          `The file size is too high. Kindly upload Image lesser than ${props.maxSize}MB`
        );
      }
      if(!isCropper){
        props.setFileData({ ...props.fileData, fileBlob: file, realFileName: file.name });
        const prvUrl = URL.createObjectURL(file); // local file url generate
        setPreviewURL(prvUrl);
        props.onUploadFile(file, file.name);
      }
      if (imageTypes.includes(`.${ext.toLocaleLowerCase()}`) && maxImageHeight && maxImageWidth) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target.result;
          image.onload = (e) => {
            const height = e.target.height;
            const width = e.target.width;
            if (height > maxImageHeight || width > maxImageWidth) {
              return props.onErrorMessage(
                `Maximum allowed dimensions are ${maxImageHeight}px x ${maxImageWidth}px`
              );
            } else {
              if(isCropper){
                fileToDataUrl(file, (url) => {
                  setCropperData({
                    ...cropperData,
                    url,
                    isOpen: true,
                    tempFile: file
                  });
                });
              } else {
                props.setFileData({
                  ...props.fileData,
                  fileBlob: file,
                  realFileName: file.name,
                });
                props.onUploadFile(file, file.name);
              }
            }
          };
        };
      } else {
        if(isCropper){
          fileToDataUrl(file, (url) => {
            setCropperData({
              ...cropperData,
              url,
              isOpen: true,
              tempFile: file
            });
          });
        } else {
          props.setFileData({ ...props.fileData, fileBlob: file, realFileName: file.name });
          props.onUploadFile(file, file.name);
        }
      }
    } else {
      return props.onErrorMessage('Choose valid file type');
    }
  };
  const onRemoveFile = () => {
    if (uploadStatus === 'UPLOADING') props.onCancelUpload();
    if (props.onDeleteFile) {
      props.onDeleteFile(props.fileData);
    } else {
      props.setFileData({
        realFileName: '',
        fileName: '',
        fileBlob: null,
      });
      props.setFileUploadData({
        uploadStatus: 'IDEAL',
        uploadingPer: 0,
      });
    }
  };

  const { hideFileSizeMB = false } = props;

  const downloadFile = (path, filename) => {
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    anchor.target = '_blank';

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        wrap="nowrap"
        display={comingFrom === 'EmbedApp' ? 'flex' : ''}
        justifyContent={comingFrom === 'EmbedApp' ? 'space-between' : ''}
        className={
          comingFrom === 'EmbedApp' && props.fileData.fileName ? classes.uploadedFileRow : ''
        }>
        {isAllowedFileUpload && !props.fileData.fileName && !props.fileData.fileBlob ? (
          <Grid item xs={12}>
            <Box
              position="relative"
              htmlFor={props.id}
              component="label"
              className={classes.fileField}
              color="text.grayColor"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={2.5}>
              <Typography color="textPrimary" component="p" variant="body2">
                Drag & drop the file here or select file
              </Typography>
              <Typography component="p" variant="caption">
                Supported Formats:{' '}
                {props.fileTypes &&
                  (props.fileTypes.includes('EXCEL') || props.fileTypes.includes('IMAGE'))
                  ? generateAllowTypes(fileTypes).join(", ")
                  : props.fileTypes && props.fileTypes.toString()}
                {
                  showMaxSize && props.maxSize && `. ${props.maxSize}mb max`
                }
              </Typography>
              <input
                id={props.id}
                disabled={props.disabled}
                accept={generateAllowTypes(fileTypes).toString()}
                onChange={onChange}
                type="file"
              />
            </Box>

            {
              props.helperText ? (
                <Box component="div" mt={0.75} color="text.grayColor">
                  <Typography component="span" variant="caption">
                    {props.helperText}
                  </Typography>
                </Box>
              ) : (
                false
              )
            }
            {
              !props.errorText ? (
                false
              ) : (
                <Box mt={1}>
                  <Typography component="p" variant="caption" className={classes.textDanger}>
                    {props.errorText}
                  </Typography>
                </Box>
              )
            }
          </Grid >
        ) : (
          <>
            <Box className={classes.paymentScreenshot}>
              <Grid
                item
                className={`${classes.fileInfoWrap} ${uploadStatus === 'UPLOADING' ? 'uploading-proceed' : ''
                  } ${uploadStatus !== 'UPLOADED' ? '' : 'uploaded-status'} ${isEditFileName ? 'show-file-name-edit' : ''
                  }`}>
                <Box display="flex">
                  <Box className={classes.fileSvgIcon} display="inline-flex" color="text.grayColor">
                    <SvgImageIcon />
                  </Box>
                  <Box ml={1.25} className={classes.fileInfoBlock} color="text.grayColor">
                    {isEditFileName ? (
                      <Box
                        className={classes.fileNameEditField}
                        display="flex"
                        justifyContent="between"
                        alignItems="center">
                        <Box width="100%">
                          <InputField size="small" {...props.editFieldProps} />
                        </Box>
                        <Box ml={1.25} display="inline-flex" alignItems="center">
                          <Button
                            onClick={() => {
                              toggleEditFileName(false);
                            }}
                            size="small"
                            color="primary"
                            variant="contained"
                            className={classes.editFileNameAction}>
                            <CheckIcon size={18} />
                          </Button>
                          <Box ml={1}>
                            <Button
                              onClick={() => {
                                toggleEditFileName(false);
                              }}
                              size="small"
                              color="primary"
                              variant="outlined"
                              className={classes.editFileNameAction}>
                              <CancelIcon size={19} />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography
                          onClick={() => {
                            if (!props.editFieldProps.isAllowFileNameEdit) return;
                            toggleEditFileName(true);
                          }}
                          className={` ${props.editFieldProps.isAllowFileNameEdit ? 'cursor-pointer' : ''
                            } ${classes.fileNameHead}`}
                          noWrap
                          color="textPrimary"
                          variant="body2"
                          component="p">
                          {props.fileData.realFileName}
                        </Typography>
                        {props.fileSize && (
                          <Typography variant="caption">{`${props.fileSize} ${!hideFileSizeMB ? 'MB' : ''
                            }`}</Typography>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>

              {
                isEditFileName ? (
                  false
                ) : (
                  <Grid
                    item
                    className={`${classes.fileActionsWrap} ${uploadStatus === 'UPLOADING' ? 'uploading-proceed' : ''
                      } ${uploadStatus === 'UPLOADED' ? '' : 'uploaded-status'}`}>
                    {!isAllowedFileUpload ? (
                      <IconButton
                        onClick={onRemoveFile}
                        color="primary"
                        className={classes.fileActionIconButton}>
                        <Trash2 size={20} />
                      </IconButton>
                    ) : (
                      <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {uploadStatus === 'UPLOADING' ? (
                          <Box
                            component="span"
                            width="100%"
                            mr={1.25}
                            color="text.grayColor"
                            display="inline-flex"
                            flexDirection="column">
                            <Typography
                              className={classes.fileUploadingProgress}
                              component="span">{`${props.uploadingProgress}%`}</Typography>
                            <LinearProgress
                              className={`${classes.progressBar} green`}
                              value={props.uploadingProgress}
                              variant="determinate"
                              color="primary"
                            />

                            {/* {props.progressType === "circle" ? <CircularProgress variant="static" value={75} /> :
                          <LinearProgress className={`${classes.progressBar} green`} value={props.uploadingProgress} variant="determinate" color="primary" />} */}
                          </Box>
                        ) : (
                          false
                        )}
                        {uploadStatus === 'UPLOADED' ? (
                          <Box mr={1.25} component="span" color="alert.success2">
                            <Typography variant="caption" component="span">
                              Uploaded Successfully
                            </Typography>
                          </Box>
                        ) : uploadStatus === 'FAILED' ? (
                          <Box mr={1.25} component="span" color="alert.danger2">
                            <Typography variant="caption" component="span">
                              Failed! Please Retry
                            </Typography>
                          </Box>
                        ) : (
                          false
                        )}
                        <IconButton
                          onClick={
                            uploadStatus === 'FAILED'
                              ? () =>
                                props.onUploadFile(
                                  props.fileData.fileBlob,
                                  props.fileData.realFileName
                                )
                              : onRemoveFile
                          }
                          size="small"
                          color={uploadStatus === 'UPLOADING' ? 'default' : 'primary'}
                          className={classes.fileActionIconButton}>
                          {uploadStatus === 'UPLOADING' ? (
                            <CancelIcon size={20} />
                          ) : uploadStatus === 'UPLOADED' || uploadStatus === 'IDEAL' ? (
                            <>
                              <Box display="flex" component="span" alignItems="center" justifyContent="space-between">
                                {isShowPreview && (previewURL || props.fileData.fileName) ? (
                                  <Box
                                    mr={2}
                                    component="span"
                                    display="flex"
                                    alignItems="center"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (previewURL) {
                                        window.open(previewURL, '_blank');
                                      } else if (props.fileData.fileName) {
                                        window.open(props.fileData.fileName, '_blank');
                                      }
                                    }}>
                                    <CustomToolTip title="Preview" placement="top">
                                      <Eye size={19} color="#ED7767" />
                                    </CustomToolTip>
                                  </Box>
                                ) : null}

                                <Box>
                                  <Trash2 size={19} color="#ED7767" />
                                </Box>
                              </Box>
                            </>
                          ) : uploadStatus === 'FAILED' ? (
                            <RotateCw size={20} />
                          ) : (
                            false
                          )}
                        </IconButton>
                        {uploadStatus === 'FAILED' ? (
                          <IconButton
                            onClick={onRemoveFile}
                            color="primary"
                            size="small"
                            className={classes.fileActionIconButton}>
                            <Trash2 size={20} />
                          </IconButton>
                        ) : (
                          false
                        )}
                      </Box>
                    )}
                  </Grid>
                )
              }
            </Box >
          </>
        )
        }
      </Grid >

      {isCropper &&
        <CustomModal
        isScrollModal
          isOpen={Boolean(cropperData.isOpen)}
          title="Crop image"
          
          onClose={handleCropperClose}
        >
          <Box width={720}>
            <Cropper
                src={cropperData.url}
                onCrop={handleOnCrop}
                onCancel={handleCropperClose}
                // isLoading={props.btnLoader}
                // loadingLabel="Uploading..."
                croppedImageWidth={props.cropperOptions?.croppedImageWidth}
                croppedImageHeight={props.cropperOptions?.croppedImageHeight}
            />
          </Box>
        </CustomModal>
      }
    </>
  );
}

FileUploadUI.propTypes = {
  id: PropTypes.string.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileData: PropTypes.exact({
    fileName: PropTypes.string,
    realFileName: PropTypes.string,
    fileBlob: PropTypes.object,
  }),
  editFieldProps: PropTypes.exact({
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isAllowFileNameEdit: PropTypes.bool,
  }).isRequired,
  fileSize: PropTypes.string,
  uploadStatus: PropTypes.string.isRequired,
  uploadingProgress: PropTypes.number.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  setFileData: PropTypes.func.isRequired,
  setFileUploadData: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  onErrorMessage: PropTypes.func.isRequired,
  maxSize: PropTypes.number.isRequired,
  onDeleteFile: PropTypes.func,
  type: PropTypes.string,
  showMaxSize: PropTypes.bool,
};

// const useStylesFileUpload = makeStyles(theme => ({
// 	listWrap: {
// 		paddingLeft: theme.spacing(1)
// 	},
// 	listItem: {
// 		'& + li': {
// 			marginTop: theme.spacing(3.75)
// 		}
// 	}
// }));

// export default function FileUploadField(props) {
// 	const { listWrap, listItem } = useStylesFileUpload();
// 	const [isTemplateSidebarOpen, toggleTemplateSidebar] = React.useState(false);
// 	const onAddClick = () => {
// 		toggleTemplateSidebar(true);
// 	}

// 	return (
// 		<>
// 			<Box display="flex" flexWrap="nowrap" alignItems="center" justifyContent="between">
// 				<Box width="100%" color="text.grayColor">
// 					<InputLabel>File</InputLabel>
// 					<Typography variant="caption" component="p">
// 						{props.fileTypes.toString()}  {props.maxSize ? `| ${props.maxSize}MB (maximum)` : ''}
// 					</Typography>
// 				</Box>
// 				{!props.hasTemplateSidebar ? false :
// 					<Box ml={1.25} whiteSpace="nowrap">
// 						<Button
// 							className="uppercase"
// 							onClick={onAddClick}
// 							size="small"
// 							color="primary"
// 							variant="contained"
// 						>Add File</Button>
// 					</Box>
// 				}
// 			</Box>
// 			{props.hasTemplateSidebar ?
// 				false
// 				:
// 				<Box mt={2.5}>
// 					<FileUploadUI
// 						id={props.id}
// 						fileTypes={props.fileTypes}
// 						fileData={props.fileData}
// 						editFieldProps={props.editFieldProps}
// 						fileSize={props.fileData.fileBlob ? (props.fileData.fileBlob.size / (1024 * 1024)).toFixed(2) : ""}
// 						uploadStatus={props.fileUploadData.uploadStatus}
// 						uploadingProgress={props.fileUploadData.uploadingPer}
// 						maxSize={props.maxSize}
// 						onUploadFile={props.onUploadFile}
// 						setFileData={props.setFileData}
// 						setFileUploadData={props.setFileUploadData}
// 						onErrorMessage={props.onErrorMessage}
// 						onCancelUpload={props.onCancelUpload}
// 					/>
// 				</Box>
// 			}

// 			<Sidebar
// 				open={isTemplateSidebarOpen}
// 				onClose={() => { toggleTemplateSidebar(false) }}
// 				title={`Import via Excel file`}>
// 				<Box className={listWrap} component="ol">
// 					<li className={listItem}>
// 						<Box display="flex" flexWrap="nowrap">
// 							<Typography component="p" variant="body2">Download the template pre-filled with your content</Typography>
// 							<Box ml={1.25}>
// 								<Button
// 									onClick={props.onDownLoadClick}
// 									className="uppercase"
// 									variant="outlined"
// 									color="primary"
// 									size="small">
// 									Download
// 									<Box ml={0.5} display="inline-flex" component="span">
// 										<ArrowDownCircle size={16} />
// 									</Box>
// 								</Button>
// 							</Box>
// 						</Box>
// 					</li>
// 					<li className={listItem}>
// 						<Typography component="p" variant="body2">Uploaded file with your changes</Typography>
// 						<Box mt={2.5}>
// 							<FileUploadUI
// 								id={props.id}
// 								fileTypes={props.fileTypes}
// 								fileData={props.fileData}
// 								editFieldProps={props.editFieldProps}
// 								fileSize={props.fileData.fileBlob ? (props.fileData.fileBlob.size / (1024 * 1024)).toFixed(2) : ""}
// 								uploadStatus={props.fileUploadData.uploadStatus}
// 								uploadingProgress={props.fileUploadData.uploadingPer}
// 								maxSize={props.maxSize}
// 								onUploadFile={props.onUploadFile}
// 								setFileData={props.setFileData}
// 								setFileUploadData={props.setFileUploadData}
// 								onErrorMessage={props.onErrorMessage}
// 								onCancelUpload={props.onCancelUpload}
// 							/>
// 						</Box>
// 					</li>
// 				</Box>
// 			</Sidebar>
// 		</>

// 	)
// }
// FileUploadField.propTypes = {
// 	hasTemplateSidebar: PropTypes.bool,
// 	id: PropTypes.string.isRequired,
// 	fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,//"DOC", "PDF", "IMAGE", "PPT", "EXCEL"
// 	onErrorMessage: PropTypes.func.isRequired,
// 	onUploadFile: PropTypes.func.isRequired,
// 	setFileData: PropTypes.func.isRequired,
// 	setFileUploadData: PropTypes.func.isRequired,
// 	onDownLoadClick: PropTypes.func,
// 	onCancelUpload: PropTypes.func.isRequired,
// 	fileUploadData: PropTypes.exact({
// 		uploadStatus: PropTypes.string.isRequired,
// 		uploadingPer: PropTypes.number.isRequired,
// 	}),
// 	maxSize: PropTypes.number.isRequired,
// 	fileData: PropTypes.exact({
// 		fileName: PropTypes.string,
// 		realFileName: PropTypes.string,
// 		fileBlob: PropTypes.object
// 	}),
// 	editFieldProps: PropTypes.exact({
// 		placeholder: PropTypes.string,
// 		value: PropTypes.string,
// 		onChange: PropTypes.func,
// 		isAllowFileNameEdit: PropTypes.bool,
// 	}).isRequired
// }
