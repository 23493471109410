/* eslint-disable  */
import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@keyframes skeleton': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    borderRadius: 4,
    animation: '$skeleton 1.5s ease-in-out 0.5s infinite',
    '&[data-variant=text]': {
      transform: 'scale(1, 0.60)',
      display: 'block',
      transformOrigin: '0 60%',
    },
    '&[data-variant=round]': {
      borderRadius: '50%',
      display: 'block',
    },
    '&[data-variant=square]': {
      display: 'block',
    },
    '&[data-variant=rect]': {
      display: 'block',
    },
  },
}));
export default function SkeletonLoader({
  className = '',
  variant = 'text',
  width,
  height,
  skeletonProps,
  mt,
  ml,
  mb,
  mr,
}) {
  const { skeleton } = useStyles();
  return (
    <Box
      mt={mt}
      ml={ml}
      mb={mb}
      mr={mr}
      className={`${skeleton} ${className}`}
      component="span"
      height={height}
      width={width}
      data-variant={variant}
      {...skeletonProps}
    />
  );
}
