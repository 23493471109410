

// createNewEventInitialState - contains the intial data for create event
export const createNewEventInitialState = { format: '', isCheckIn: 0, eventMetaTypeId: '', base_language: 34, event_languages: [34] };

// editEventDateDetails - contains the intial data for edit event
export const editEventDateDetails = { format: '', isCheckIn: 0, eventMetaTypeId: '', base_language: 34, event_languages: [34], editDetails: {}, isTimeChanged: false };


export function CreateNewEventReducer(state, action) {
    switch (action.type) {
      case 'format':
        return { ...state, format: action.payload.format, isCheckIn: action.payload.isCheckIn };
      case 'type':
        return {
          ...state,
          eventMetaTypeId: action.payload.id,
          eventTypeName: action.payload.name,
          customEventType: action.payload.customEventType,
        };
      case 'languages':
        return {
          ...state,
          event_languages: action.payload.event_languages,
          base_language: action.payload.base_language,
        };
      case 'details':
        return { ...state, ...action.payload };
      case 'editDetails':
        return { ...state, editDetails: action.payload };
      case 'initialEditDetails':
        return { ...state, ...action.payload };
      case 'setAddOns':
        return { ...state, addOns: action.payload?.selectedAddons };
      default:
        return state;
    }
}