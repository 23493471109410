import React from 'react';

// eslint-disable-next-line no-unused-vars
const style = {
  a: {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
};
export default function SvgCrowdcastIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M8.93915 15.8553V18H15.0608V15.8553H8.93915ZM8.93915 12.863V15.0077H15.0608V12.863H8.93915ZM8.93915 9.86043V12.0051H15.0608V9.86043H8.93915ZM12.0048 9.00256C12.2882 9.00255 12.5652 8.91423 12.8007 8.74882C13.0362 8.5834 13.2195 8.34833 13.3275 8.07341C13.4355 7.79849 13.4633 7.49611 13.4073 7.20462C13.3513 6.91312 13.214 6.64564 13.013 6.4361C12.8119 6.22655 12.5561 6.08438 12.2779 6.02761C11.9998 5.97084 11.7118 6.00203 11.4506 6.11723C11.1893 6.23243 10.9666 6.42644 10.8105 6.67466C10.6545 6.92289 10.5723 7.21414 10.5742 7.51149C10.5773 7.90771 10.7293 8.28664 10.9972 8.56587C11.2651 8.84509 11.6272 9.00203 12.0048 9.00256Z"
        fill="#EC5F3E" />
      <path
        d="M15.8784 15.8553V18H22V15.8553H15.8784ZM15.8784 12.863V15.0077H22V12.863H15.8784ZM18.9343 12.0051C19.2173 12.0051 19.4939 11.9171 19.7291 11.7521C19.9644 11.5871 20.1478 11.3527 20.2561 11.0784C20.3644 10.804 20.3927 10.5022 20.3375 10.211C20.2823 9.91974 20.146 9.65223 19.9459 9.44228C19.7459 9.23232 19.4909 9.08934 19.2134 9.03141C18.9359 8.97348 18.6482 9.00321 18.3868 9.11684C18.1254 9.23047 17.902 9.42289 17.7448 9.66978C17.5876 9.91666 17.5037 10.2069 17.5037 10.5038C17.5042 10.9018 17.6551 11.2834 17.9233 11.5648C18.1914 11.8462 18.555 12.0046 18.9343 12.0051Z"
        fill="#3E5667" />
      <path
        d="M2 15.8553V18H8.12165V15.8553H2ZM5.06569 15.0076C5.34865 15.0076 5.62525 14.9196 5.86052 14.7546C6.09579 14.5897 6.27916 14.3552 6.38745 14.0809C6.49573 13.8066 6.52406 13.5047 6.46886 13.2135C6.41366 12.9223 6.2774 12.6548 6.07732 12.4448C5.87724 12.2349 5.62232 12.0919 5.3448 12.0339C5.06728 11.976 4.77962 12.0057 4.5182 12.1194C4.25679 12.233 4.03335 12.4254 3.87615 12.6723C3.71894 12.9192 3.63504 13.2094 3.63504 13.5064C3.63555 13.9044 3.78645 14.2859 4.05464 14.5673C4.32282 14.8488 4.68642 15.0071 5.06569 15.0076Z"
        fill="#F9CA3F" />
    </svg>
  );
}
