import React from 'react';

export default function SvgPersonEventIcon(props) {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M16.79 6.13C16.6959 6.01082 16.5758 5.91466 16.4389 5.84882C16.302 5.78298 16.1519 5.74918 16 5.75H5.00004V5.25C5.00129 4.81468 5.14453 4.39165 5.40803 4.04514C5.67153 3.69862 6.04089 3.44754 6.46004 3.33C6.72932 3.58983 7.08599 3.73963 7.46004 3.75H8.46004C8.85787 3.75 9.2394 3.59196 9.5207 3.31066C9.80201 3.02936 9.96004 2.64782 9.96004 2.25C9.96004 1.85218 9.80201 1.47064 9.5207 1.18934C9.2394 0.908035 8.85787 0.75 8.46004 0.75H7.46004C7.23461 0.75031 7.01218 0.80177 6.80952 0.900504C6.60686 0.999237 6.42924 1.14267 6.29004 1.32C5.36831 1.48625 4.53414 1.97067 3.93292 2.68884C3.3317 3.40702 3.00156 4.31339 3.00004 5.25V5.75H2.00004C1.84815 5.74918 1.69807 5.78298 1.56118 5.84882C1.4243 5.91466 1.30422 6.01082 1.21004 6.13C1.10987 6.24874 1.03873 6.38918 1.00228 6.5402C0.965831 6.69121 0.965065 6.84864 1.00004 7L1.62004 9.49C1.76808 10.0635 2.08259 10.5804 2.52393 10.9755C2.96527 11.3705 3.51368 11.6261 4.10004 11.71L4.88004 18.71H4.00004C3.73483 18.71 3.48047 18.8154 3.29294 19.0029C3.1054 19.1904 3.00004 19.4448 3.00004 19.71C3.00004 19.9752 3.1054 20.2296 3.29294 20.4171C3.48047 20.6046 3.73483 20.71 4.00004 20.71H14C14.2653 20.71 14.5196 20.6046 14.7072 20.4171C14.8947 20.2296 15 19.9752 15 19.71C15 19.4448 14.8947 19.1904 14.7072 19.0029C14.5196 18.8154 14.2653 18.71 14 18.71H13.12L13.9 11.71C14.4823 11.6199 15.0251 11.3604 15.4608 10.9639C15.8965 10.5673 16.2058 10.0512 16.35 9.48L17 7C17.035 6.84864 17.0343 6.69121 16.9978 6.5402C16.9614 6.38918 16.8902 6.24874 16.79 6.13ZM11.1 18.75H6.90004L6.12004 11.75H11.88L11.1 18.75ZM14.41 9C14.3553 9.22188 14.2262 9.41831 14.0442 9.55659C13.8623 9.69487 13.6385 9.76666 13.41 9.76H4.56004C4.33161 9.76666 4.10779 9.69487 3.92584 9.55659C3.74389 9.41831 3.61479 9.22188 3.56004 9L3.28004 7.75H14.72L14.41 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
