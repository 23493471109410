import { LEAD_SCORE_ACTION_TYPE } from './constants';

const iLeadScoreStateActivityScore = {
  isActivityScoreLoading: false,
  isSaveActivityScoreLoading: false,
  isResetActivityScoreLoading: false,
  isDefaultActivityScore: true,
  isLeadScoreLoading: false,
  activityScore: [],
  updatedActivityScore: [],
  isSaveLevelLoading: false,
};

const iLeadScoreStateLeadLevels = {
  isGetLeadLevelsLoading: false,
  leadLevels: [],
  isDefaultLeadLevels: true,
  isLeadLabelUpdateLoading: false,
  isLeadLevelDistLoading: false,
  distribution: {
    levels: [],
    existingDistribution: {
      live: {
        totalAttendees: 0,
        leadLevelDistribution: [],
      },
      onDemand: {
        totalAttendees: 0,
        leadLevelDistribution: [],
      },
    },
    updatedDistribution: {
      live: {
        totalAttendees: 0,
        leadLevelDistribution: [],
      },
      onDemand: {
        totalAttendees: 0,
        leadLevelDistribution: [],
      },
    },
  },
};

const iLeadLevelAnalytics = {
  isGetOverviewLoading: false,
  overview: {
    live: {
      totalAttendees: 0,
      avgAttendeeScore: 0,
      leadLevelDistribution: [],
    },
    onDemand: {
      totalAttendees: 0,
      avgAttendeeScore: 0,
      leadLevelDistribution: [],
    },
    levels: [],
  },
};
const leadScoreReducer = (
  state = { ...iLeadScoreStateActivityScore, ...iLeadScoreStateLeadLevels, ...iLeadLevelAnalytics },
  action
) => {
  switch (action.type) {
    case LEAD_SCORE_ACTION_TYPE.GET_ACTIVITY_SCORE:
      return {
        ...state,
        activityScore: action.payload.list,
        isDefaultActivityScore: action.payload.isDefault,
      };

    case LEAD_SCORE_ACTION_TYPE.GET_ACTIVITY_SCORE_LOADING:
      return {
        ...state,
        isActivityScoreLoading: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.SAVE_ACTIVITY_SCORE_LOADING:
      return {
        ...state,
        isSaveActivityScoreLoading: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.RESET_ACTIVITY_SCORE_LOADING:
      return {
        ...state,
        isResetActivityScoreLoading: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.DEFAULT_ACTIVITY_SCORE:
      return {
        ...state,
        isDefaultActivityScore: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_LOADING:
      return { ...state, isLeadScoreLoading: action.payload };

    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_SCORE:
      return {
        ...state,
        leadLevels: action.payload.list,
        isDefaultLeadLevels: action.payload.isDefault,
      };

    case LEAD_SCORE_ACTION_TYPE.SAVE_LEAD_SCORE_LOADING:
      return {
        ...state,
        isSaveLevelLoading: action.payload,
      };
    case LEAD_SCORE_ACTION_TYPE.UPDATE_LEAD_LEVEL_LABEL_LOADING:
      return {
        ...state,
        isLeadLabelUpdateLoading: action.payload,
      };
    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_DIST_LOADING:
      return {
        ...state,
        isLeadLevelDistLoading: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_LEVEL_DIST:
      return {
        ...state,
        distribution: action.payload,
      };
    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_OVERVIEW_LOADING:
      return {
        ...state,
        isGetOverviewLoading: action.payload,
      };

    case LEAD_SCORE_ACTION_TYPE.GET_LEAD_OVERVIEW:
      return {
        ...state,
        overview: action.payload,
      };
    default:
      return state;
  }
};

export default leadScoreReducer;
