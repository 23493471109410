/* eslint-disable  */
import React, { useEffect } from 'react';
import { Box, makeStyles, Paper, Fade, ClickAwayListener, Typography } from '@material-ui/core';
import SvgCaretDownIcon from 'icons/svgCaretDown';
import { useSelector, shallowEqual } from 'react-redux';
import { baseEventLanguagesSelector, currentEventLanguagesSelector } from 'redux/custom-selector';
import { Check } from 'react-feather';
import MultiLangOptions from 'utility/MultiLangOptions';
// import GlobIcon from 'icons/svgGlobal';

const useStyles = makeStyles((theme) => ({
  btnDropDown: {
    backgroundColor: theme.palette.bg.white,
    borderRadius: 5,
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    minWidth: 170,
    //  padding: theme.spacing(1, 5, 1, 1),
    padding: theme.spacing(0.5, 3, 0.5, 0),
    // cursor: 'pointer',
  },
  downIcon: {
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
  },
  languages: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    // cursor: 'pointer',
  },
  optionList: {
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.palette.bg.whiteOff1,
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  opacity50: {
    opacity: 0.5,
  },
  langWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  langTitle: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: 12,
    '& .baseText': {
      fontSize: '10px',
      textTransform: 'uppercase',
      marginLeft: '4px',
      backgroundColor: '#FFF0EC',
      color: '#ED7767',
      padding: '0px 5px',
      fontWeight: 'normal',
      display: 'inline-block',
      borderRadius: '2px',
    },
  },
  langSuppoert: {
    margin: '0 16px',
    paddingLeft: 0,
  },
  boldText: {
    fontWeight: 600,
  },
  multiLangSupportPopup: {
    border: '1px solid #DBDBDB',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.12)',
  },
  multiLanguageDropDownWrapper: {
    '& .customDropDown': {
      backgroundColor: theme.palette.bg.white,
      border: `1px solid #c0c0c0`,
      borderRadius: 4,
      transition: `color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      color: theme.palette.text.grayColor1,
      padding: `${theme.spacing(1.625)}px ${theme.spacing(1.5)}px`,
      marginTop: '-3px',
    },
  },
  langOptions: {
    maxHeight: 300,
    overflow: 'auto',
  },
}));
export default function MultiLanguageDropDown(props) {
  const classes = useStyles();
  const [isMenuOpen, toggleMenu] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const baseEventLanguage = useSelector(baseEventLanguagesSelector, shallowEqual);
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);

  const onMenuSelect = (value) => {
    setSelected(value);
    if (props.onMenuSelect) props.onMenuSelect(value);
    toggleMenu(false);
  };

  useEffect(() => {
    const langIndex = props.options.findIndex((i) => i.langName === baseEventLanguage.name);
    if (langIndex !== -1 && !props.isEdit) {
      setSelected(props.options[langIndex]);
      if (props.onMenuSelect) onMenuSelect(props.options[langIndex]);
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (props.selectedLanguage && props.options) {
      const langIndex = props.options.findIndex(
        (i) =>
          i.langName === props.selectedLanguage.name ||
          i.langName === props.selectedLanguage.langName
      );
      if (langIndex !== -1) {
        setSelected(props.options[langIndex]);
      }
    }
  }, [props.selectedLanguage, props.options]);

  useEffect(() => {
    const langIndex = props.options.findIndex((i) => i.langName === baseEventLanguage.name);
    if (langIndex !== -1 && !props.isEdit && !props.selectedLanguage) {
      setSelected(props.options[langIndex]);
      if (props.onMenuSelect) onMenuSelect(props.options[langIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseEventLanguage, props.options, props.selectedLanguage]);

  useEffect(() => {
    const langIndex = props.options.findIndex((i) => i.langName === currentEventLanguage.name);
    if (langIndex !== -1 && props.isEdit) {
      setSelected(props.options[langIndex]);
      if (props.onMenuSelect) onMenuSelect(props.options[langIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEventLanguage, props.options]);

  useEffect(() => {
    const langIndex = props.options.findIndex((i) => i.langName === currentEventLanguage.name);
    if (langIndex !== -1 && props.isEdit) {
      setSelected(props.options[langIndex]);
      onMenuSelect(props.options[langIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEventLanguage]);

  const findDispName = (name) => {
    let languages = '';
    if (props.options) {
      const langIndex = MultiLangOptions.findIndex((i) => i.langName === name);
      if (langIndex !== -1) {
        languages = MultiLangOptions[langIndex].langDisplayName;
      } else {
        languages = name;
      }
    }
    return languages;
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        toggleMenu(false);
      }}>
      <Box
        style={{ pointerEvents: `${props.disableDropdown ? 'none' : 'auto'}` }}
        position="relative"
        display="flex"
        alignItems="center"
        className={props.isFluid ? classes.multiLanguageDropDownWrapper : false}>
        <Box
          onClick={() => {
            toggleMenu((p) => !p);
          }}
          pl={1}
          pr={1}
          pt={0.5}
          pb={0.5}
          position="relative"
          display="flex"
          alignItems="center"
          className={`${props.isFluid ? 'customDropDown' : false} ${classes.btnDropDown} ${classes.cursorPointer
            }`}>
          {/* <GlobIcon width="20px" height="20px" /> */}
          <Box display="flex" ml={1}>
            {selected && (
              <Typography variant="caption" color="textPrimary" component="label">
                {findDispName(selected.langName)}
              </Typography>
            )}
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              className={classes.downIcon}
              ml={1}>
              <SvgCaretDownIcon height="15px" width="15px" color="#a5a5a5" />
            </Box>
          </Box>
        </Box>
        <Fade in={isMenuOpen}>
          <Box position="absolute" top="100%" width="247px" right="0" zIndex={5} mt={1.2}>
            <Paper elevation={2} className={classes.multiLangSupportPopup}>
              <Box p={1} pl={2} className={classes.langTitle}>
                <Typography variant="" color="textPrimary" component="label">
                  {baseEventLanguage &&
                    baseEventLanguage.name &&
                    findDispName(baseEventLanguage.name)}
                  <span className="baseText">Base</span>
                </Typography>
              </Box>
              <Box p={2} className={[classes.langTitle, classes.langSuppoert].join(' ')}>
                <Typography variant="" color="textPrimary" component="label">
                  Languages Supported
                </Typography>
              </Box>
              <Box pt={1} pb={1} className={classes.langOptions}>
                {props.options.map((data, i) => (
                  <Box
                    className={
                      baseEventLanguage.name === data.langName
                        ? `${classes.optionList} ${classes.cursorPointer} ${classes.langWrapper}`
                        : props.isDisable
                          ? `${classes.optionList} ${classes.opacity50} ${classes.langWrapper}`
                          : `${classes.optionList} ${classes.cursorPointer} ${classes.langWrapper}`
                    }
                    onClick={() => {
                      if (baseEventLanguage.name === data.langName) onMenuSelect(data);
                      else if (props.isDisable) {
                        // do nothing;
                      } else {
                        onMenuSelect(data);
                      }
                    }}>
                    <Box display="flex" pl={1} pr={1} pt={0.7} pb={0.7} key={i}>
                      {/* <GlobIcon width="20px" height="20px" /> */}
                      <Typography
                        variant="caption"
                        color="textPrimary"
                        component="label"
                        className={
                          baseEventLanguage.name === data.langName
                            ? `${classes.languages} ${classes.cursorPointer}`
                            : props.isDisable
                              ? classes.languages
                              : `${classes.languages} ${classes.cursorPointer}`
                        }>
                        {findDispName(data.langName)}
                      </Typography>
                    </Box>
                    {selected && selected.langName === data.langName && (
                      <Box display="flex" pl={1} pr={1} pt={0.7} pb={0.7} alignItems="center">
                        <Check size="15px" color="#00C957" strokeWidth={3} />
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Paper>
          </Box>
        </Fade>
      </Box>
    </ClickAwayListener>
  );
}
