export const env = process.env.REACT_APP_ENV_TYPE;

const getCustomEmbedAPPId = () => {
  let val = 9;
  if (env === 'v2dev') {
    val = 10;
  }
  if (env === 'v2release') {
    val = 9;
  }
  if (env === 'prod') {
    val = 8;
  }
  return val;
};

const INTEGRATIONS = {
  CUSTOM_APPS_TAG: 'Custom Apps',
  CUSTOM_APPS_NAME_1: 'Embed Your App',
  CUSTOM_APP_ID: getCustomEmbedAPPId(),
  SSO_APP_NAME: 'Single Sign-On (SSO)',
};
export { INTEGRATIONS };
