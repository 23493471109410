import React from 'react';
import { Grid, makeStyles, Typography, Box } from '@material-ui/core';
import EventFormatImg from 'images/Event format illustration_v04.gif';
import EventTypeImg from 'images/Event_type.gif';
import EventDetailsImg from 'images/Event_details.gif';
import EventLanguagesImg from 'images/Event_language.gif';
import { EEventScreen } from '../common/constants';
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
  createEventBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    height: 'calc(100% - 129px)',
    flexDirection: 'column',
    '@media (max-width: 1366px)': {
      top: '5rem',
    },

    '& h5': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: 'fit-content',
      '& h5': {
        marginLeft: '5px',
        marginRight: '5px',
      },
    },

    '& img': {
      maxWidth: '336px',
    },

    '& .eventDetailsBannerText': {
      maxWidth: '17rem',
    },
  },
}));

const CreateEventBanner = () => {
  const classes = useStyles();
  const params = useParams();
  const { screenId } = params;

  const renderText = () => {
    switch (Number(screenId)) {
      case EEventScreen.EVENT_TYPE:
        return (
          <>
            <img src={EventTypeImg} alt="Event Type" />
            <Typography component="h5" variant="h5" color="textPrimary">
              An event platform that can do
              <Typography component="h5" variant="h5" color="primary">
                anything,
              </Typography>
              for people who do
              <Typography component="h5" variant="h5" color="primary">
                everything.
              </Typography>
            </Typography>
          </>
        );
      case EEventScreen.EVENT_DETAILS:
        return (
          <Box className="eventDetailsBannerText">
            <img src={EventDetailsImg} alt="Event Details" />
            <Typography component="h5" variant="h5" color="textPrimary">
              Built for event
              <Typography component="h5" variant="h5" color="primary">
                excellence.
              </Typography>
              Built for
              <Typography component="h5" variant="h5" color="primary">
                {' '}
                you.
              </Typography>
            </Typography>
          </Box>
        );
      case EEventScreen.EVENT_LANGUAGES:
        return (
          <>
            <img src={EventLanguagesImg} alt="Event Languages" />
            <Typography component="h5" variant="h5" color="textPrimary">
              Reach a
              <Typography component="h5" variant="h5" color="primary">
                global audience
              </Typography>
              by choosing from
              <Typography component="h5" variant="h5" color="primary">
                {' '}
                12+ languages
              </Typography>
            </Typography>
          </>
        );
      default:
        return <img src={EventFormatImg} alt="Event Format" />;
    }
  };

  return (
    <Grid item xs component="aside" className={classes.createEventBanner}>
      {renderText()}
    </Grid>
  );
};

export default CreateEventBanner;
