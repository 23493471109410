const filterOutLeadScoreFeature = (links = []) => {
  let result = [];
  if (links.length) {
    result = links.map((item) => {
      if (item.name === 'ENGAGEMENT') {
        if (item.subMenu.length) {
          const res = item.subMenu.filter((sub) => sub.name !== 'LEAD SCORE');
          return { ...item, subMenu: res };
        }
        return item;
      }
      return item;
    });
  }
  return result;
};

const leadScoreAnalyticsUrl = (match = {}) => {
  return `/event/${match.params.eventId}/${match.params.organiserId}/analytics/lead-score`;
};
const isHideManageReportsButtonHandler = ({ history, match }) => {
  return history.location.pathname !== leadScoreAnalyticsUrl(match);
};

export { filterOutLeadScoreFeature, leadScoreAnalyticsUrl, isHideManageReportsButtonHandler };
