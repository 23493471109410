/* eslint-disable no-param-reassign */
import { SET_SESSION_ADVANCE_DATA, SET_SESSION_NEW_DATA , SET_ACTIVE_MODAL, SET_ACTIVE_WATCH_STATE, SET_KNOWN_ERROR, SET_LOADING_STATE, SET_SESSION_STARTED } from 'redux/actions/conversion-prompt-action';

const initialState = {
    knownError: {
        trigger: null, // ADD/EDIT/DELETE/PREVIEW
        type: null, // CONVERSION_PROMPT_KNOWN_ERRORS
        id: null,
        isActive: false
    },
    activeModal: null,
    activeWatchState: null,
    isSessionStarted: false,
    loadingState: {
        isSharing: false
    }
}
const ConversionPromptReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION_NEW_DATA:
            state = {
                ...state,
                data: action.data
            }
            break;
        case SET_SESSION_ADVANCE_DATA:
            state = {
                ...state,
                advanceData: action.data
            }
            break;
        case SET_KNOWN_ERROR:
            state = {
                ...state,
                knownError: action.payload
            }
            break;
        case SET_ACTIVE_MODAL:
            state = {
                ...state,
                activeModal: action.payload
            }
            break;
        case SET_ACTIVE_WATCH_STATE:
            state = {
                ...state,
                activeWatchState: action.payload
            }
            break;
        case SET_SESSION_STARTED:
            state = {
                ...state,
                isSessionStarted: action.payload
            }
            break;
        case SET_LOADING_STATE:
            state = {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    ...action.payload
                }
            }
            break;
        default:
            break;
    }
    return state;
}

export default ConversionPromptReducer;