import AnalyticsLeadScore from 'pages/engagement/lead-score/analytics-lead-score';
import React from 'react';

const LeadScoreAnalytics = () => {
  return (
    <div>
      <AnalyticsLeadScore />
    </div>
  );
};

export default LeadScoreAnalytics;
