/* eslint-disable  */
import React from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  CardHeader,
  Box,
  CardActions,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import BtnWithLoader from './btnWithLoader';
import UploadBtn from 'custom-components/form-fields/uploadBtn';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    // minWidth: 480,
    // overflow: `hidden`,
    padding: theme.spacing(3.75),
    paddingBottom: `${theme.spacing(3.75)}px !important`,
    '&.paddingNone': {
      padding: '0 !important',
    },
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
    position: `relative`,
    overflow: 'visible',
    '&:focus': {
      outline: 'none',
    },
    '&.small': {
      maxWidth: '485px',
    },
    '&.medium': {
      maxWidth: '600px',
    },
    '&.large': {
      maxWidth: '900px',
    },
    '&.full': {
      maxWidth: '100%',
      border: 'none !important',
    },
  },

  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: `translate(80%, -80%)`,
    color: 'white',
    padding: theme.spacing(1),
    '& svg': {
      width: `30px`,
      height: `30px`,
    },
  },
  header: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3.75)}px`,
    backgroundColor: theme.palette.bg.whiteOff1,
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    "& .subtitle": {
      marginTop: theme.spacing(0.5)
    }
  },
  cardActions: {
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2.5),
    },
  },
  footer: {
    padding: theme.spacing(3.75),
    borderTop: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  footerWithOutStyle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3.75)}px ${theme.spacing(3.75)}px`,
    borderTop: 'none',
  },
  modalScroll: {
    alignItems: 'flex-start',
    overflow: 'auto',
    // '&>div':{
    // 	position:'absolute'
    // },
    '&>div.MuiPaper-root': {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  w100: {
    width: '100%',
    height: '100%',
  },
}));

export default function ({
  children,
  onClose,
  isOpen,
  isCancellable = true,
  customFooter,
  showCloseIcon = true,
  isScrollModal = false,
  overlayClassName,
  ...restProps
}) {
  const {
    modal,
    paper,
    content,
    closeBtn,
    modalScroll,
    modalSize = 'medium',
    w100,
    ...classes
  } = useStyles();
  return (
    <Modal
      className={`${modal} ${overlayClassName} ${isScrollModal ? modalScroll : ''} ${
        restProps.customModal
      }`}
      open={isOpen}
      onClose={isCancellable ? onClose : () => {}}
      closeAfterTransition
      disableEscapeKeyDown={restProps.disableEscape}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <Card
          className={`${paper} ${restProps.modalSize} ${restProps?.contentCardClass} ${
            restProps.modalWidth ? w100 : ''
          }`}>
          {showCloseIcon && (
            <IconButton onClick={onClose} className={closeBtn}>
              <CloseRoundedIcon />
            </IconButton>
          )}
          {restProps.title && (
            <CardHeader
              className={classes.header}
              subheader={restProps.subTitle}
              subheaderTypographyProps={{
                variant: 'caption',
                component: 'p',
                className: 'subtitle'
              }}
              title={restProps.title}
              titleTypographyProps={{
                variant: 'h6',
                component: 'h3',
              }}
              action={
                <Box className={classes.cardActions}>{restProps.headerActions || false}</Box>
              }></CardHeader>
          )}
          <CardContent
            className={`${content} ${restProps.contentWrapperClassName} ${
              restProps.paddingZero ? 'paddingNone' : ''
            }`}>
            {children}
          </CardContent>

          {(restProps.otherAction && restProps.otherAction.length) ||
          (restProps.action && restProps.action.length) ? (
            <CardActions
              className={`${classes.footer} ${customFooter ? classes.footerWithOutStyle : ''}`}>
              <Box
                display="flex"
                justifyContent={`${restProps.footerShowCenter ? 'center' : 'space-between'}`}
                width="100%">
                <Box className={classes.cardActions} display="flex" justifyContent="flex-end">
                  {restProps.otherAction &&
                    restProps.otherAction.length &&
                    restProps.otherAction.map((eachButton, i) => {
                      return eachButton.isFileUpload ? (
                        <UploadBtn
                          onChange={eachButton.onChange}
                          isMultiple={eachButton.isMultiple}
                          key={i}>
                          <BtnWithLoader
                            onClick={eachButton.onClick}
                            disabled={eachButton.isLoading}
                            isLoading={eachButton.isLoading}
                            loadingLabel={eachButton.loadingLabel}
                            label={eachButton.name}
                            variant={eachButton.type === 'primary' ? 'contained' : 'outlined'}
                            className={`uppercase`}
                            size="small"
                            color="primary"
                            key={eachButton.name}
                            icon={eachButton.icon}
                          />
                        </UploadBtn>
                      ) : (
                        <BtnWithLoader
                          onClick={eachButton.onClick}
                          disabled={eachButton.isLoading}
                          isLoading={eachButton.isLoading}
                          loadingLabel={eachButton.loadingLabel}
                          label={eachButton.name}
                          variant={eachButton.type === 'primary' ? 'contained' : 'outlined'}
                          className={`uppercase`}
                          size="small"
                          color="primary"
                          key={eachButton.name}
                          icon={eachButton.icon}
                        />
                      );
                    })}
                </Box>
                <Box className={classes.cardActions} display="flex" justifyContent="flex-end">
                  {restProps.action &&
                    restProps.action.length &&
                    restProps.action.map((eachButton) => {
                      return (
                        <BtnWithLoader
                          onClick={eachButton.onClick}
                          disabled={eachButton.isLoading || eachButton.disabled}
                          isLoading={eachButton.isLoading}
                          loadingLabel={eachButton.loadingLabel}
                          label={eachButton.name}
                          variant={eachButton.type === 'primary' ? 'contained' : 'outlined'}
                          className={`uppercase`}
                          size="small"
                          color="primary"
                          key={eachButton.name}
                          icon={eachButton.icon}
                        />
                      );
                    })}
                </Box>
              </Box>
            </CardActions>
          ) : (
            false
          )}
        </Card>
      </Fade>
    </Modal>
  );
}
