/* eslint-disable react/self-closing-comp */
import React from 'react';

const styles = {
  a: {
    fill: 'currentColor',
  },
  b: {
    fill: 'none',
  },
};
export default function SvgRoomIcon(props) {
  return (
    <svg style={styles.a} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
      <rect style={styles.b} width="25" height="25"></rect>
      <path
        style={styles.a}
        d="M24.5,14.5h-9v-9a.5.5,0,0,0-1,0v9h-9a.5.5,0,0,0,0,1h9v9a.5.5,0,0,0,1,0v-9h9a.5.5,0,0,0,0-1Z"></path>
      <path
        style={styles.a}
        d="M10,9.665A1.665,1.665,0,1,0,8.335,8,1.667,1.667,0,0,0,10,9.665Zm0-2.43A.765.765,0,1,1,9.235,8,.766.766,0,0,1,10,7.235Z"></path>
      <path
        style={styles.a}
        d="M7.278,13.811a.452.452,0,0,0,.451-.45,2.271,2.271,0,1,1,4.542,0,.451.451,0,0,0,.9,0,3.172,3.172,0,0,0-6.344,0A.451.451,0,0,0,7.278,13.811Z"></path>
      <path
        style={styles.a}
        d="M20.125,9.665A1.665,1.665,0,1,0,18.46,8,1.667,1.667,0,0,0,20.125,9.665Zm0-2.43A.765.765,0,1,1,19.36,8,.766.766,0,0,1,20.125,7.235Z"></path>
      <path
        style={styles.a}
        d="M17.4,13.811a.452.452,0,0,0,.451-.45,2.271,2.271,0,1,1,4.543,0,.451.451,0,0,0,.9,0,3.173,3.173,0,0,0-6.345,0A.451.451,0,0,0,17.4,13.811Z"></path>
      <path
        style={styles.a}
        d="M11.665,17.854A1.665,1.665,0,1,0,10,19.52,1.667,1.667,0,0,0,11.665,17.854Zm-2.43,0a.765.765,0,1,1,.765.765A.766.766,0,0,1,9.235,17.854Z"></path>
      <path
        style={styles.a}
        d="M10,20.043a3.176,3.176,0,0,0-3.172,3.172.451.451,0,0,0,.9,0,2.271,2.271,0,1,1,4.542,0,.451.451,0,0,0,.9,0A3.176,3.176,0,0,0,10,20.043Z"></path>
      <path
        style={styles.a}
        d="M21.79,17.854a1.665,1.665,0,1,0-1.665,1.666A1.667,1.667,0,0,0,21.79,17.854Zm-2.43,0a.765.765,0,1,1,.765.765A.766.766,0,0,1,19.36,17.854Z"></path>
      <path
        style={styles.a}
        d="M20.125,20.043a3.177,3.177,0,0,0-3.173,3.172.451.451,0,0,0,.9,0,2.271,2.271,0,1,1,4.543,0,.451.451,0,0,0,.9,0A3.176,3.176,0,0,0,20.125,20.043Z"></path>
    </svg>
  );
}
