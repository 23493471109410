import React, { useMemo, useState, useEffect } from 'react';
import { Box, FormHelperText, Typography, Grid } from '@material-ui/core';
import { useStyles } from './style';
import InputField from 'custom-components/form-fields/inputField';
import SelectDropdown from 'custom-components/form-fields/selectDropdown';
import SvgBulbIcon from 'icons/svgEventBulbIcon';
import DateTimePickerNew from 'custom-components/form-fields/CreateEventDateTimePicker';
import TimePickerNew from 'custom-components/form-fields/timePickerNew';
import moment from 'moment-timezone';
import MsgNotificationComponent from './MsgNotificationComponent';
import { Facebook, Globe, Instagram, Linkedin, CheckCircle, Info } from 'react-feather';
import XSvgIcon from '../../icons/XSvgIcon';
import EditorField from 'custom-components/form-fields/editorField';
import projectSetting, { restrictingCharacerInput } from 'settings';
import { mapScriptLoad } from 'custom-components/mapView';
import { API, POSTAPIWITHCUSTOMDOMAIN, PUTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';
import CustomToolTip from 'custom-components/customToolTip';

import SvgInfoIconCustom from 'icons/svgInfoIconCustom';
import { EActionType, EEventFormat } from './common/constants';
import EventDetailsLoader from './Loaders/EventDetailsScreenLoader';
import { EventDetailsAddress } from './EventDetailsAddress';
import useFetch from './common/fetch';
import countryList from 'models/country';
import CreateEventFooter from './CreateNewEventLayout/CreateEventFooter';
import { useCustomCreateEventContext } from './context';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  baseEventLanguagesSelector,
  commonSelector,
  currentEventLanguagesSelector,
  userInfoSelector,
  eventDataSelector
} from 'redux/custom-selector';
import { getActionFromPath } from './common/utils';
import { useHistory, useLocation, useParams } from 'react-router';
import Validation from 'models/validation';
import { getCalculatedCharLegth } from 'lib/common-file-function';
import SVGErrorIconOutline from 'icons/svgErrorIconOutline';
import ContactSupport from 'pages/CreateNewEvent/ContactSupport';
import { createEventTrackAmplitute } from 'lib/amplitude/amplitude-tracking';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useSource } from 'lib/custom-common-hooks';
import {
  CREATE_NEW_EVENT,
  FETCH_EVENT_DETAILS,
  EVENT_TIMEZONE_LIST,
} from 'api-setup/api-endpoints';
import { setCreateEditParent } from 'redux/actions/eventCreateEditActions';
import { EventAddOnsMeta, EventAddOnsMetaForToast } from 'pages/events/constants';
import { PricingPlan } from 'constants/pricingPlan';

// format given date to  YYYY-MM-DD HH:mm
const formatDateTimeStamp = (date) =>
  `${moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm')}`;

// socialLinksSchema - to validate social link URL with regular expression by a common validate function
const socialLinksSchema = {
  linked_url: [{ method: 'isUrl', errMsg: 'Please enter a valid LinkedIn link' }],
  twitter_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Twitter link' }],
  instagram_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Instagram link' }],
  fb_url: [{ method: 'isUrl', errMsg: 'Please enter a valid Facebook link' }],
  website_url: [{ method: 'isUrl', errMsg: 'Please enter a valid link' }],
};

// addTimeToDate will add time to the timestamp and return the final timestamp
const addTimeToDate = (time) => {
  // split time in hours, minutes, seconds by using ':' as delimiter (ex: 06:10:12)
  const timeSplit = [...time.split(':')];

  // convert hours to milli seconds
  const hourMilli = Number(timeSplit[0]) * 60 * 60 * 1000;

  // convert minutes to milli seconds
  const minMilli = Number(timeSplit[1]) * 60 * 1000;

  // add hours milli seconds and minutes milli seconds then return
  return hourMilli + minMilli;
};

// onReadyYearMaker - will create a UI for date picker years
const onReadyYearMaker = (_selectedDates, _dateStr, instance) => {
  // fetching year element from the datepicker instance
  const flatpickrYearElement = instance.currentYearElement;
  const { children } = flatpickrYearElement.parentElement;
  // hiding all the children elements of the year element
  // eslint-disable-next-line no-restricted-syntax
  for (const i in children) {
    // eslint-disable-next-line no-prototype-builtins
    if (children?.hasOwnProperty(i)) {
      children[i].style.display = 'none';
    }
  }

  // creating the year element with select input type
  const yearSelect = document.createElement('select');
  const minYear = moment.tz(moment.tz.guess()).year() - 1;
  const maxYear = moment.tz(moment.tz.guess()).year() + 10;
  // generating the options for the select input based on min and max years
  for (let i = minYear; i < maxYear; i += 1) {
    const option = document.createElement('option');
    option.value = `${i}`;
    option.text = `${i}`;
    option.className = 'create-event-year-select-option';
    // adding the option to the select input
    yearSelect.appendChild(option);
  }
  // adding onchange listener to the year element
  yearSelect.addEventListener('change', (event) => {
    flatpickrYearElement.value = event.target.value;
    if (instance.currentYear) {
      // eslint-disable-next-line no-param-reassign
      instance.currentYear = parseInt(event.target?.value, 10);
      instance.redraw();
    }
  });

  yearSelect.className = 'create-event-year-select';
  yearSelect.id = 'CREATE_EVENT_CUSTOM_YEAR_SELECT';
  yearSelect.value = instance.currentYearElement.value;

  // appending the year select input to the year element
  flatpickrYearElement.parentElement.appendChild(yearSelect);
};

// convertToTimezone will return the UTC milliseconds of given date in given timezone
const convertToTimezone = (date, timezone) =>
  moment.tz(formatDateTimeStamp(date), timezone).utc().valueOf();

const EventDetails = ({ isLoading }) => {
  const classes = useStyles();

  // the copy of current logged-in organiser data
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const location = useLocation();
  const { state, dispatch } = useCustomCreateEventContext();
  const [name, setName] = useState(state.name);
  const [country, setCountry] = useState(state.country || '');
  const [timezone, setTimezone] = useState('');

  // fetch the timezone from the api and store them in 'timezones' key
  const [timezones, isTimezoneLoading] = useFetch(EVENT_TIMEZONE_LIST);

  // initLoading - to track the initialization of data and show the loader while initializing
  const [initLoading, setInitLoading] = useState(true);

  // commonSelectorData contains all the common data for the user who logged in.
  const commonSelectorData = useSelector(commonSelector, shallowEqual);

  // baseLanguageEvent contains the events base language
  const baseLanguageEvent = useSelector(baseEventLanguagesSelector, shallowEqual);

  // currentEventLanguage contains the events current language selected by organiser
  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);

  const headerLang = currentEventLanguage?.id || baseLanguageEvent?.id || 34;

  // timeZoneList contains formatted timezones used for dropdown
  const [timeZoneList, setTimeZoneList] = useState([]);

  // contactedSupport - to show the contact support success message, if organiser already contact the support
  const [contactedSupport, setContactedSupport] = useState(
    userInfo.UserInfo.isOrganiserContactForCustomDays === 1
  );

  // startDateTime - contains start date in milliseconds and start time in the format given by time picker
  const [startDateTime, setStartDateTime] = useState({
    date: '',
    time: '',
  });

  const dispatchApi = useDispatch();

  // startDateTime - contains end date in milliseconds and end time in the format given by time picker
  const [endDateTime, setEndDateTime] = useState({
    date: '',
    time: '',
  });

  // socialLinks - stores all the social links given by organiser
  const [socialLinks, setSocialLinks] = useState({
    linked_url: state.linked_url || '',
    twitter_url: state.twitter_url || '',
    instagram_url: state.instagram_url || '',
    fb_url: state.fb_url || '',
    website_url: state.website_url || '',
  });

  const source = useSource();
  const [headers] = API.generateHeader(commonSelectorData, { language: headerLang }, null, null);

  // address - store the address in object format which contains address, city, province, zip, latitude, longitude
  const [address, setAddress] = useState(state.address || {});

  // description - stores description
  const [description, setDescription] = useState(state.description || '');

  // endDateLimit - to restrict the calender to select the maximum days given to organiser
  const [endDateLimit, setEndDateLimit] = useState('');

  // errObj - to show the errors in the filled details of the event in order to correct them
  const [errObj, setErrObj] = useState();

  // isTimeChanged - represents if the time is changed or not in edit flow
  const [isTimeChanged, setTimeChanged] = useState(false);

  // params - contains the route param such as screen ID, eventID, organiser id
  const params = useParams();
  const { eventId = '', organiserId = '' } = params;

  // history - contains the route history
  const history = useHistory();

  // loader for finish api call
  const [finisLoading, setFinishLoading] = useState(false);

  const [isEndTimeEdited, setIsEndTimeEdited] = useState(false);

  // screen - represents the current screen (ex: format, type, details, language)
  const screen = Number(params.screenId);

  // actionType - represents the current action type (ex: edit, create)
  const actionType = getActionFromPath(history.location.pathname);

  // isEdit - contains if the current screen action type is edit or not
  const isEdit = actionType === EActionType.EDIT;

  // disableStatusStartTime - will be disable if the event start time is past in edit flow
  const disableStatusStartTime = !isEdit
    ? false
    : state?.editDetails?.start_time_milli < Date.now();

  // disableStatusEndTime - will be disable if the event end time is past in edit flow
  const disableStatusEndTime = !isEdit ? false : state?.editDetails?.end_time_milli < Date.now();

  // eventDays will contains the maximum number of event days
  const eventDays =
    userInfo.UserInfo.maximumEventDays && userInfo.UserInfo.maximumAllowedEventDays
      ? Number(userInfo.UserInfo.maximumAllowedEventDays) >
        Number(userInfo.UserInfo.maximumEventDays)
        ? userInfo.UserInfo.maximumAllowedEventDays
        : userInfo.UserInfo.maximumEventDays
      : userInfo.UserInfo.maximumEventDays || userInfo.UserInfo.maximumAllowedEventDays;
  const DataOfEvent = useSelector(eventDataSelector, shallowEqual);
  const eventCreateMillie = DataOfEvent?.EventData?.create_time_milli || '';
  const isWebinarCreatedTimeMili = Number(eventCreateMillie) < Number(process.env.REACT_APP_EVENT_SESSION_TIME_SYNC_WEBINAR_FEAT_LIVE);
  const showCounty = state.format !== EEventFormat.VIRTUAL && state.format !== EEventFormat.WEBINAR;
  // showAddressFields is to show the address fields in edit flow for hybrid and in-person events
  const showAddressFields = isEdit && showCounty;

  // isModalOpen is to show the contact support modal, if organiser clicks on contact support button
  const [isModalOpen, handleModal] = useState(false);

  // planName - represents the logged-in organiser's plan name. such as Planless, enterprise, pro, advanced
  const planName = (userInfo && userInfo?.UserInfo?.planName) || 'Planless';

  // this effect will load the google script to the DOM
  useEffect(() => {
    mapScriptLoad(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
   * this effect is to initialize and format the the data from timezone api and the other details
   * this effect will trigger
   * after the timezones api call
   * if action type changes
   * if there are any changes occurs in redux state
   */
  useEffect(() => {
    // timeZonesList - copy of api timezones
    const timeZonesList = timezones;

    if (timeZonesList) {
      // tempArray - to store the formatted timezones temporarily
      const tempArray = [];

      // defaultTimezone - contains event timezone
      let defaultTimezone = {};

      // currentTimezone - contains logged-in organiser browser timezone
      const currentTimezone = moment.tz.guess(true);

      // formatting the timezones for the dropdown
      timeZonesList.map((item) => {
        const obj = {};
        obj.name = item.name;
        obj.description = `${item.description} (${item.country})`;
        obj.country = item.country;
        obj.id = item.id;
        tempArray.push(obj);

        // storing the event timezone
        if (state.timezone_id) {
          if (state.timezone_id === item.id) defaultTimezone = item;
        } else if (
          item.name === currentTimezone ||
          (currentTimezone === 'Asia/Calcutta' && item.name === 'Asia/Kolkata')
        )
          defaultTimezone = obj;
        return 0;
      });

      // storing the formatted timezones in state variable for dropdown
      setTimeZoneList(tempArray);

      // storing the default timezone to show it by default
      setTimezone(defaultTimezone);

      // storing the existing details in state to show it to the organiser in edit flow
      if (state.name) setName(state.name);
      if (state.country) setCountry(state.country);
      setDescription(state.description || '');

      // initializing data to the state when organiser clicking on prev on languages page
      if (state.startDate && state.endDate) {
        setStartDateTime({
          date: state.startDate,
          time: state.startTime,
        });

        const dateValue = new Date(state.startDate);
        dateValue.setDate(dateValue.getDate() + eventDays);
        setEndDateLimit(dateValue);

        setEndDateTime({
          date: state.endDate,
          time: state.endTime,
        });

        // initializing data for create event initial state
      } else if (actionType === EActionType.CREATE) {
        // initializing the data only when defaultTimezone is generated
        if (defaultTimezone?.name) {
          // calculating the start date
          const currentStartDate = moment()
            .set({ h: 0, m: 0, s: 1 })
            .tz(defaultTimezone?.name)
            .valueOf();

          // calculating the start time
          const currentStartTime = moment()
            .add(1, 'hours')
            .tz(defaultTimezone?.name)
            .format('HH:mm:ss');
          const currentStartDateLimit = moment()
            .add(1, 'hours')
            .tz(defaultTimezone?.name)
            .valueOf();

          if (!startDateTime.date)
            // storing the start date and time in state
            setStartDateTime({
              date: currentStartDate,
              time: currentStartTime,
            });

          if (state.format === EEventFormat.WEBINAR) {
            const wmili = !startDateTime.date ? currentStartDateLimit : startDateTime.date
            const dateValue = moment(wmili).add(3, 'hours').valueOf();
            setEndDateLimit(dateValue);
          } else {
          // initializing the end date limit for the end date calendar
            const dateValue = new Date(
              !startDateTime.date ? currentStartDateLimit : startDateTime.date
            );
            dateValue.setDate(dateValue.getDate() + eventDays);
            setEndDateLimit(dateValue);
          }

          const currentStartMilliSeconds = moment()
            .add(1, 'hours')
            .tz(defaultTimezone?.name)
            .valueOf();

          // calculating the end date
          const currentEndDate = moment()
            .set({ h: 0, m: 0, s: 0 })
            .add(1, 'days')
            .tz(defaultTimezone?.name)
            .valueOf();

          const currentWebinarEndDate = moment()
            .set({ h: 0, m: 0, s: 0 })
            .tz(defaultTimezone?.name)
            .valueOf();

          const webinarDateOfEnd = moment(currentStartMilliSeconds)
            .add(90, 'minutes')
            .tz(defaultTimezone?.name)
            .valueOf();

          const nextDayMidnight = moment()
            .add(1, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .tz(defaultTimezone?.name)
            .valueOf();

          // calculating the end time
          const currentEndTime = moment()
            .add(1, 'hours')
            .add(1, 'days')
            .tz(defaultTimezone?.name)
            .format('HH:mm:ss');


          const currentWebinarEndTime = moment(currentStartMilliSeconds)
            .add(90, 'minutes')
            .tz(defaultTimezone?.name)
            .format('HH:mm:ss');


          if (!endDateTime.date)
            // storing the end date and time in state
            setEndDateTime({
              date: state.format === EEventFormat.WEBINAR ? (webinarDateOfEnd > nextDayMidnight) ? (currentWebinarEndDate + 86400000) : currentWebinarEndDate : currentEndDate,
              time: state.format === EEventFormat.WEBINAR ? currentWebinarEndTime : currentEndTime,
            });
        }
        // initializing the data for edit event flow
      } else if (isEdit) {
        // initializing the data only when defaultTimezone is generated
        if (defaultTimezone?.name) {
          // calculating and storing the start date and time
          if (!startDateTime.date)
            setStartDateTime({
              date: moment(
                moment(+state?.editDetails?.start_time_milli)
                  .tz(defaultTimezone?.name)
                  .format('DD-MM-YYYY HH:mm'),
                'DD-MM-YYYY'
              )
                .tz(currentTimezone)
                .set({ h: 0, m: 0, s: 1 })
                .valueOf(),
              time: moment(
                moment(+state?.editDetails?.start_time_milli)
                  .tz(defaultTimezone?.name)
                  .format('DD-MM-YYYY HH:mm'),
                'DD-MM-YYYY HH:mm'
              ).format('HH:mm:ss'),
            });

          if (!endDateTime.date)
            // calculating and storing the end date and time
            setEndDateTime({
              date: moment(
                moment(+state?.editDetails?.end_time_milli)
                  .tz(defaultTimezone?.name)
                  .format('DD-MM-YYYY HH:mm'),
                'DD-MM-YYYY'
              )
                .tz(currentTimezone)
                .set({ h: 0, m: 0, s: 1 })
                .valueOf(),
              time: moment(
                moment(+state?.editDetails?.end_time_milli)
                  .tz(defaultTimezone?.name)
                  .format('DD-MM-YYYY HH:mm'),
                'DD-MM-YYYY HH:mm'
              ).format('HH:mm:ss'),
            });

          // calculating and storing the end date limit for the future editable events
          if (!disableStatusStartTime) {
            if (!isWebinarCreatedTimeMili && state.format === EEventFormat.WEBINAR) {
              const dateValueWebinar = moment(
                startDateTime.date
                  ? startDateTime.date
                  : moment(+state?.editDetails?.start_time_milli)
              )
                .add(3, 'hours')
                .valueOf();

              setEndDateLimit(dateValueWebinar);
            } else {
              let dateValue = '';
              if (startDateTime.date) {
                dateValue = new Date(startDateTime.date);
              } else {
                const currentStartDateLimit =
                  moment(
                    moment(+state?.editDetails?.start_time_milli)
                      .tz(defaultTimezone?.name)
                      .format('DD-MM-YYYY HH:mm'),
                    'DD-MM-YYYY HH:mm'
                  ).valueOf() + 1;
                dateValue = new Date(currentStartDateLimit);
              }
              dateValue.setDate(dateValue.getDate() + eventDays);
              setEndDateLimit(dateValue);
            }
          }
        }
      }
      setInitLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones, state, actionType, eventDays, isEdit, disableStatusStartTime]);

  //   function compareTime(time1, time2) {
  //   const [hours1, minutes1] = time1.split(':');
  //   const [hours2, minutes2] = time2.split(':');

  //   return hours1 === hours2 && minutes1 === minutes2;
  // }

  // handleDateChange - will trigger when organizer changes the date and time
  const handleDateChange = (type, timeMilli) => {
    setTimeChanged(true);
    switch (type) {
      case 'start_date': {
        // resetting the error object
        setErrObj((prev) => ({ ...prev, startDateTime: '', endDateTime: '' }));

        // storing start date in state
        const dateValue = new Date(timeMilli);

        // is selected new startdate is in past then the existing start date
        const isPastDate =
          dateValue < new Date(moment(startDateTime.date).set({ h: 0, m: 0, s: 0 }).valueOf());

        //  is selected new startdate is greater then existing Endtimelimit
        const isNTGTE = new Date(timeMilli) > endDateLimit;

        // is selected new start time greater then existing endtime
        const isStartTimeGreater = Number(timeMilli) > Number(endDateTime.date);

        setStartDateTime((prev) => ({ ...prev, date: Number(timeMilli) + 1000 }));
        const updateDate =
          startDateTime.date && addTimeToDate(startDateTime.time)
            ? timeMilli + addTimeToDate(startDateTime.time)
            : timeMilli;

        const dateValueWebinar = moment(updateDate).add(3, 'hours').valueOf();
        // calculating and initializing the end date limit based on the selected start date
        dateValue.setDate(dateValue.getDate() + eventDays);

        let checkEndDateLimit;

        if (state.format === EEventFormat.WEBINAR) {
          if (Number(eventCreateMillie)) {
            checkEndDateLimit = isWebinarCreatedTimeMili ? dateValue : dateValueWebinar;
          } else {
            checkEndDateLimit = dateValueWebinar;
          }
        } else {
          checkEndDateLimit = dateValue;
        }

        setEndDateLimit(checkEndDateLimit);

        // resetting end date by one day on changing of start date based on condition
        if (!isEndTimeEdited || isPastDate || isNTGTE || isStartTimeGreater) {

          const endDateCheck =
            moment(updateDate)
              .add(90, 'minutes')
              .valueOf();
          const nextDayMidnight = moment(timeMilli)
            .add(1, 'days')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .valueOf();

          let checkEndDate;
          if (state.format === EEventFormat.WEBINAR) {
            if (Number(eventCreateMillie)) {
              checkEndDate = isWebinarCreatedTimeMili ? (Number(timeMilli) + 86400000) : (endDateCheck > nextDayMidnight) ? (Number(timeMilli) + 86400000) : Number(timeMilli);
            } else {
              checkEndDate = endDateCheck > nextDayMidnight ? (Number(timeMilli) + 86400000) : Number(timeMilli);
            }
          } else {
            checkEndDate = Number(timeMilli) + 86400000;
          }

          setEndDateTime((prev) => ({
            ...prev,
            date: checkEndDate,
          }));
          setIsEndTimeEdited(false);
        }

        break;
      }
      case 'end_date':
        // resetting error object and storing the end date in state
        setErrObj((prev) => ({ ...prev, endDateTime: '' }));
        setEndDateTime((prev) => ({ ...prev, date: timeMilli }));
        break;
      case 'start_time': {
        // resetting the error object
        setErrObj((prev) => ({ ...prev, startDateTime: '', endDateTime: '' }));

        // storing start time only if the time is changed
        if (startDateTime.time !== timeMilli) {
          // resetting end date and time
          // setEndDateTime({ date: '', time: '' });

          // storing start time in state
          const startDate = Number(startDateTime.date) + addTimeToDate(timeMilli);
          setStartDateTime((prev) => ({ ...prev, time: timeMilli }));
          const dateValueWebinar = moment(startDate).add(3, 'hours').valueOf();
          // calculating and initializing the end date limit based on the selected start date and time
          const dateValue = new Date(startDate);
          dateValue.setDate(dateValue.getDate() + eventDays);

          let checkEndDateLimit;

          if (state.format === EEventFormat.WEBINAR) {
            if (Number(eventCreateMillie)) {
              checkEndDateLimit = isWebinarCreatedTimeMili ? dateValue : dateValueWebinar;
            } else {
              checkEndDateLimit = dateValueWebinar;
            }
          } else {
            checkEndDateLimit = dateValue;
          }

          setEndDateLimit(checkEndDateLimit);
        }
        break;
      }
      case 'end_time': {
        // resetting error object and storing the end time in state
        setErrObj((prev) => ({ ...prev, endDateTime: '' }));
        setEndDateTime((prev) => ({ ...prev, time: timeMilli }));
        break;
      }
      default:
        break;
    }
  };

  /*
   * isValid - will validate the filled and unfilled fields in both create and edit flow
   * it will return false, if the form has any errors to do nothing
   * it will return true, if the form has no errors to proceed further steps
   */
  const isValid = () => {
    // validating description length and showing the error
    if (
      description.replace(/(&nbsp;+)/gi, '').replace(/(<([^>]+)>)/gi, '').length >
      restrictingCharacerInput.event.description
    ) {
      dispatch(
        setNewMessage({
          message: ` You have exceeded the ${restrictingCharacerInput.event.description} character limit. You cannot save the content.`,
          type: 'error',
          show: true,
        })
      );
    }

    const sampleErrObj = {};
    const nowUtcTime = moment.utc().valueOf();
    const startTime = addTimeToDate(startDateTime.time);
    const endTime = addTimeToDate(endDateTime.time);

    // validating name with empty and min length of 3 character limit
    if (name?.trim() === '') {
      setName('');
      sampleErrObj.name = 'Please enter event name';
    } else if (name.trim().length < 3)
      sampleErrObj.name = 'Event Name should have more than 3 characters';

    // validating create flow fields
    if (!isEdit || !disableStatusStartTime) {
      const startDateUtc = moment
        .tz(formatDateTimeStamp(Number(startDateTime.date) + startTime), timezone.name)
        .utc()
        .valueOf();

      // validating start time with current time
      if (moment(startDateUtc).isBefore(nowUtcTime)) {
        sampleErrObj.startDateTime = 'The start date of the event cannot be in the past';
      }

      const endDateUtc = moment
        .tz(formatDateTimeStamp(Number(endDateTime.date) + endTime), timezone.name)
        .utc()
        .valueOf();
      // validating end time with current time
      if (moment(endDateUtc).isBefore(nowUtcTime)) {
        sampleErrObj.endDateTime = 'The end date of the event cannot be in the past';
      }
      // validating end time with start time
      if (moment(endDateUtc).isSameOrBefore(startDateUtc)) {
        sampleErrObj.endDateTime = 'The end date cannot be before start date';
      }

      if (state.format === EEventFormat.WEBINAR) {
        if (Number(eventCreateMillie)) {
          if (!isWebinarCreatedTimeMili) {
            if (moment(endDateUtc).diff(startDateUtc, 'minutes') > 180) {
              sampleErrObj.endDateTime = `Webinar duration cannot be more than 3 hours`;
            }
          }
        } else if (moment(endDateUtc).diff(startDateUtc, 'minutes') > 180) {
          sampleErrObj.endDateTime = `Webinar duration cannot be more than 3 hours`;
        }
      }

      // validating event days with maximum event days
      if (moment(endDateUtc).diff(startDateUtc, 'minutes') > eventDays * 24 * 60) {
        sampleErrObj.endDateTime = `Event days cannot be more than ${eventDays}`;
      }
    }
    // validating edit flow social links fields
    if (isEdit) {
      const socialLinksCheck = Validation.validate(socialLinksSchema, socialLinks);
      if (socialLinks?.fb_url && socialLinksCheck?.fb_url)
        sampleErrObj.fb_url = socialLinksCheck?.fb_url;
      if (socialLinks?.instagram_url && socialLinksCheck?.instagram_url)
        sampleErrObj.instagram_url = socialLinksCheck?.instagram_url;
      if (socialLinks?.linked_url && socialLinksCheck?.linked_url)
        sampleErrObj.linked_url = socialLinksCheck?.linked_url;
      if (socialLinks?.twitter_url && socialLinksCheck?.twitter_url)
        sampleErrObj.twitter_url = socialLinksCheck?.twitter_url;
      if (socialLinks?.website_url && socialLinksCheck?.website_url)
        sampleErrObj.website_url = socialLinksCheck?.website_url;
    }

    // checking if the form has any errors or not
    // if yes, it'll return false and do nothing
    if (sampleErrObj && Object.keys(sampleErrObj).length > 0) {
      setErrObj(sampleErrObj);
      return false;
    }

    // if no errors, it'll return true to proceed further
    return true;
  };

  // handleTimezoneChange - will trigger when organiser changes the timezone
  const handleTimezoneChange = (value) => {
    setTimezone(value);

    // resetting start date and end date
    setStartDateTime({
      date: '',
      time: '',
    });
    setEndDateLimit('');
    setEndDateTime({
      date: '',
      time: '',
    });

    // resetting error object
    setErrObj((prev) => ({ ...prev, startDateTime: '', endDateTime: '' }));
  };

  // handleSocialLinksChange - will trigger when organiser modifies or adds any social link
  const handleSocialLinksChange = (event) => {
    const { name: fieldName, value } = event.target;
    setSocialLinks((prev) => ({ ...prev, [fieldName]: value }));

    // resetting error object
    setErrObj((prev) => ({ ...prev, [fieldName]: '' }));
  };

  // handleDescriptionChange - will trigger when organiser modifies or adds description
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setErrObj((prev) => ({ ...prev, [event.target.name]: '' }));
  };

  // handleCountryChange - will trigger when organiser modifies or adds country
  const handleCountryChange = (e) => {
    // resetting other address fields which will effect by the country field
    if (address) {
      address.province = '';
      address.city = '';
      address.zip = '';
      address.address = '';
      address.lat = '';
      address.lon = '';
    }
    setCountry(e.countryName);
  };

  // handleCountryChange - will trigger when organiser modifies or adds any address field
  const handleAddressChange = (addressData) => {
    setAddress(addressData);
    if (addressData?.country) setCountry(addressData?.country);
    setErrObj((prev) => ({ ...prev, address: '' }));
  };

  const isSocialEngagementLinksEdited = () => {
    const { editDetails } = state;
    const socialLinksCurrent = {
      website_url: socialLinks?.website_url,
      linkedin_url: socialLinks?.linked_url,
      twitter_url: socialLinks?.twitter_url,
      fb_url: socialLinks?.fb_url,
      instagram_url: socialLinks?.instagram_url,
    };

    const prevLinks = {
      website_url: editDetails?.website_url,
      linkedin_url: editDetails?.linked_url,
      twitter_url: editDetails?.twitter_url,
      fb_url: editDetails?.fb_url,
      instagram_url: editDetails?.instagram_url,
    };
    let isEdited = false;
    if (editDetails?.linked_url !== socialLinks?.linked_url) isEdited = true;

    if (editDetails?.twitter_url !== socialLinks?.twitter_url) isEdited = true;

    if (editDetails?.instagram_url !== socialLinks?.instagram_url) isEdited = true;

    if (editDetails?.fb_url !== socialLinks?.fb_url) isEdited = true;

    if (editDetails?.website_url !== socialLinks?.website_url) isEdited = true;

    if (isEdited) {
      return `"[${JSON.stringify(prevLinks)}, ${JSON.stringify(socialLinksCurrent)}]"`;
    }
    return null;
  };

  // createEventApiCall is responsible for calling the finish api and handling the response
  const createEventApiCall = (body, eventData) => {
    POSTAPIWITHCUSTOMDOMAIN(
      false,
      CREATE_NEW_EVENT,
      source.AS,
      headers,
      body,
      projectSetting.customBaseURL6
    )
      .then((res) => {
        setFinishLoading(false);
        if (res.status === API.apiSuccessStatus) {
          // amplitute tracking for the finish call
          createEventTrackAmplitute('SEEventCreated', {
            funnel_entry_section: 'CREATE_EVENT_FUNNEL',
            event_id: res.data.data.eventId,
            languages_selected: 'EN',
            event_name: body.payload.data.eventName,
            time_zone: eventData?.timezone_name,
            event_start_timestamp: body.payload.data.start_time_milli,
            event_end_timestamp: body.payload.data.end_time_milli,
            country: body.payload.data.country || '',
            event_type: eventData.eventTypeName,
            customEventType: eventData.customEventType || undefined,
            checkin_facility_required: eventData?.isCheckIn === 1,
            event_format_selected: eventData.format,
            app_version: projectSetting.buildversion,
            user_id: userInfo.UserInfo.id,
            organisation: userInfo.UserInfo.organisation,
            group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
            plan_type: planName,
          });

          const setAddOnsMessage = () => {
            if (res.data?.data?.addOnStatus?.success?.length) {
              const msg = `${
                planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR
                  ? 'Event created.'
                  : ''
              } Add-ons applied: ${res.data?.data?.addOnStatus?.success
                .map((addon) => EventAddOnsMetaForToast[addon])
                .join(', ')}`;

              // displays the api message
              dispatchApi(
                setNewMessage({
                  message: msg,
                  type: 'success',
                  show: true,
                })
              );
            } else if (res.data?.data?.addOnStatus?.failed?.length) {
              const msg = `These add-ons could not be applied: ${res.data?.data?.addOnStatus?.failed
                .map((addon) => EventAddOnsMeta[addon])
                .join(', ')}. Add again by editing your event`;
              dispatchApi(setNewMessage({ message: msg, type: 'error', show: true }));
            }
          };

          if (
            !(
              (planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR) &&
              res.data?.data?.addOnStatus?.success?.length
            )
          )
            // displays the api message
            dispatchApi(
              setNewMessage({
                message: `${res.data.message}`,
                type: 'success',
                show: true,
              })
            );

          setAddOnsMessage();

          setTimeout(() => {
            dispatchApi(setCreateEditParent(''));
          }, 200);
          // on success finish api redirecting the user to the event basic info page
          history.push({
            pathname: `/event/${res.data.data.eventId}/${res.data.data.organiserId}`,
            state: { isShowRegistrationFlowPopUp: true },
          });
        } else {
          // displays the api message
          dispatchApi(setNewMessage({ message: res.data.message, type: 'error', show: true }));
          API.errStatusHandler(res, history, dispatchApi);
        }
      })
      .catch((error) => {
        setFinishLoading(false);
        API.httpErrorStatusHandler(error, history, dispatchApi);
      });
  };

  // editEventApiCall is responsible for calling the edit api and handling the response
  const editEventApiCall = (body, eventData) => {
    if (!headers.eventId) headers.eventId = eventId;
    PUTAPIWITHCUSTOMDOMAIN(
      false,
      `${FETCH_EVENT_DETAILS}/${eventId}`,
      source.AS,
      headers,
      body,
      projectSetting.customBaseURL6
    )
      .then((res) => {
        setFinishLoading(false);
        if (res.status === API.apiSuccessStatus) {
          // startTime contains the start time of the event
          const startTime = addTimeToDate(eventData.startTime);

          // endTime contains the end time of the event
          const endTime = addTimeToDate(eventData.endTime);

          // startTimeUtc contains the start time of the event in UTC formate
          const startTimeUtc = convertToTimezone(
            Number(eventData.startDate) - 1000 + startTime,
            eventData.timezone_name
          );

          // endTimeUtc contains the end time of the event in UTC formate
          const endTimeUtc = convertToTimezone(
            Number(eventData.endDate) + endTime,
            eventData.timezone_name
          );

          // amplitute tracking for edit
          createEventTrackAmplitute('SEEventEdited', {
            funnel_entry_section: 'EDIT_EVENT_FUNNEL',
            event_id: res.data.eventId,
            languages_selected_edited: 'EN',
            base_language_selected_edited: 'EN',
            event_name_edited: eventData.name,
            time_zone_edited: eventData.timezone_name,
            event_start_timestamp_edited: startTimeUtc,
            event_end_timestamp_edited: endTimeUtc,
            country_edited: eventData.address?.country,
            event_type_edited: eventData.eventTypeName,
            customEventType_edited: eventData.customEventType || undefined,
            checkin_facility_required_edited: eventData?.isCheckIn === 1 ? 'YES' : 'NO',
            event_format_selected_edited: eventData.format,
            app_version: projectSetting.buildversion,
            user_id: userInfo.UserInfo.id,
            organisation: userInfo.UserInfo.organisation,
            group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
            plan_type: planName,
          });

          const setAddOnsMessage = () => {
            if (res.data?.data?.addOnStatus?.success?.length) {
              const msg = `${
                planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR
                  ? 'Event edited.'
                  : ''
              } Add-ons applied: ${res.data?.data?.addOnStatus?.success
                .map((addon) => EventAddOnsMetaForToast[addon])
                .join(', ')}`;

              // displays the api message
              dispatchApi(
                setNewMessage({
                  message: msg,
                  type: 'success',
                  show: true,
                })
              );
            } else if (res.data?.data?.addOnStatus?.failed?.length) {
              const msg = `These add-ons could not be applied: ${res.data?.data?.addOnStatus?.failed
                .map((addon) => EventAddOnsMeta[addon])
                .join(', ')}. Add again by editing your event`;
              dispatchApi(setNewMessage({ message: msg, type: 'error', show: true }));
            }
          };

          if (
            !(
              (planName === PricingPlan.WEBINAR_LITE || planName === PricingPlan.WEBINAR) &&
              res.data?.data?.addOnStatus?.success?.length
            )
          )
            // displays the api message
            dispatchApi(
              setNewMessage({
                message: `${res.data.message}`,
                type: 'success',
                show: true,
              })
            );

          setAddOnsMessage();

          setTimeout(() => {
            dispatchApi(setCreateEditParent(''));
          }, 200);
          // on success response navigate user to the event basic info page
          history.push(`/event/${eventId}/${organiserId}`);
        } else {
          // displays the api message
          dispatchApi(setNewMessage({ message: res.data.message, type: 'error', show: true }));
          API.errStatusHandler(res, history, dispatchApi);
        }
      })
      .catch((error) => {
        setFinishLoading(false);
        API.httpErrorStatusHandler(error, history, dispatchApi);
      });
  };

  const callFinishAPI = (eventData, selectedAddons = []) => {
    setFinishLoading(true);

    // startTime contains the start time of the event
    const startTime = addTimeToDate(eventData.startTime);

    // endTime contains the end time of the event
    const endTime = addTimeToDate(eventData.endTime);

    // startTimeUtc contains the start time of the event in UTC formate
    const startTimeUtc = convertToTimezone(
      Number(eventData.startDate) - 1000 + startTime,
      eventData.timezone_name
    );

    // endTimeUtc contains the end time of the event in UTC formate
    const endTimeUtc = convertToTimezone(
      Number(eventData.endDate) + endTime,
      eventData.timezone_name
    );

    if (actionType === EActionType.CREATE) {
      // preparing the body for create api
      const body = {
        payload: {
          data: {
            type: eventData.format,
            eventMetaTypeId: Number(eventData.eventMetaTypeId),
            eventName: eventData.name,
            eventDescription: eventData.description || '',
            timeZoneId: eventData.timezone_id,
            start_time_milli: startTimeUtc,
            end_time_milli: endTimeUtc,
            country: eventData.country, // only in case of type : HYBRID/IN_PERSON
            eventLanguages: eventData.event_languages,
            baseLanguageId: eventData.base_language,
            isCheckIn: eventData.isCheckIn === 1, // only in case of type : HYBRID/IN_PERSON
            customEventType: eventData.customEventType,
            addOns: selectedAddons || eventData.addOns || [],
          },
        },
      };
      createEventApiCall(body, eventData);
    } else {
      // creating the body object for edit api. it adds only the edited data by comparing the eventData value with the api data
      const bodyData = {};
      const apiData = eventData?.editDetails;
      if (eventData.format !== apiData?.type) bodyData.type = eventData.format;
      if (Number(eventData.eventMetaTypeId) !== Number(apiData?.eventMetaTypeId))
        bodyData.eventMetaTypeId = Number(eventData.eventMetaTypeId);
      if (Number(eventData.isCheckIn) !== Number(apiData?.is_check_in))
        bodyData.isCheckIn = eventData.isCheckIn === 1;
      if (eventData.timezone_id !== apiData?.timezone_id)
        bodyData.timeZoneId = eventData.timezone_id;
      if (eventData.name !== apiData?.name) bodyData.eventName = eventData.name;
      if (eventData.description !== apiData?.description)
        bodyData.eventDescription = eventData.description;
      if (eventData.isTimeChanged) {
        if (Number(startTimeUtc) !== Number(apiData?.start_time_milli))
          bodyData.start_time_milli = startTimeUtc;
        if (Number(endTimeUtc) !== Number(apiData?.end_time_milli))
          bodyData.end_time_milli = endTimeUtc;
      }
      if (eventData.linked_url !== apiData?.linked_url) bodyData.linkedinUrl = eventData.linked_url;
      if (eventData.twitter_url !== apiData?.twitter_url)
        bodyData.twitterUrl = eventData.twitter_url;
      if (eventData.instagram_url !== apiData?.instagram_url)
        bodyData.instagramUrl = eventData.instagram_url;
      if (eventData.fb_url !== apiData?.fb_url) bodyData.fbUrl = eventData.fb_url;
      if (eventData.website_url !== apiData?.website_url)
        bodyData.websiteUrl = eventData.website_url;
      if (state.customEventType !== apiData?.customEventType) {
        bodyData.customEventType = eventData.customEventType;
        bodyData.eventMetaTypeId = Number(eventData.eventMetaTypeId);
      }
      const stateAddressKeys = Object.keys(eventData.address || {});
      const apiAddressKeys = Object.keys(apiData?.address || {});
      if (!apiAddressKeys?.length && stateAddressKeys?.length) bodyData.address = eventData.address;
      if (apiAddressKeys?.length && stateAddressKeys?.length) {
        const address = {};
        if (eventData.address?.country !== apiData.address?.country)
          address.country = eventData.address?.country;
        if (eventData.address?.address !== apiData.address?.address)
          address.address = eventData.address?.address;
        if (eventData.address?.city !== apiData.address?.city)
          address.city = eventData.address?.city;
        if (eventData.address?.lat !== apiData.address?.lat) address.lat = eventData.address?.lat;
        if (eventData.address?.lon !== apiData.address?.lon) address.lon = eventData.address?.lon;
        if (eventData.address?.province !== apiData.address?.province)
          address.province = eventData.address?.province;
        if (eventData.address?.zip !== apiData.address?.zip) address.zip = eventData.address?.zip;
        if (Object.keys(address).length) bodyData.address = address;
      }
      bodyData.addOns = state.addOns || [];
      // if user has changed something then only calling the api
      if (Object.keys(bodyData).length) {
        const body = {
          payload: {
            data: bodyData,
          },
        };
        editEventApiCall(body, eventData);
      } else {
        // if user doesn't change anything, then redirecting the user to the event basic details page
        history.push(`/event/${eventId}/${organiserId}`);
      }
    }
  };

  const getTimezoneName = (id) => {
    return timeZoneList[timeZoneList.findIndex((item) => item.id === id)].name || '';
  };
  // store event details screen data in redux
  const onNext = (selectedAddons = []) => {
    if (!isValid()) return false;

    let tempEventDetails = {
      name: name.trim(),
      timezone_id: timezone.id,
      startDate: startDateTime.date,
      startTime: startDateTime.time,
      endDate: endDateTime.date,
      endTime: endDateTime.time,
      timezone_name: timezone.name,
      isTimeChanged,
    };
    if (!isEdit && showCounty) {
      tempEventDetails = {
        ...tempEventDetails,
        country,
      };
    }
    if (isEdit) {
      tempEventDetails = {
        ...tempEventDetails,
        description,
        ...socialLinks,
      };
      if (showCounty) {
        tempEventDetails = {
          ...tempEventDetails,
          country,
          address:
            address && address?.country
              ? address
              : {
                  ...address,
                  country,
                },
        };
      }
    }
    dispatch({ type: 'details', payload: { ...tempEventDetails } });

    const startTime = addTimeToDate(startDateTime.time);
    const endTime = addTimeToDate(endDateTime.time);
    const startTimeUtc = convertToTimezone(
      Number(startDateTime.date) - 1000 + startTime,
      timezone.name
    );
    const endTimeUtc = convertToTimezone(Number(endDateTime.date) + endTime, timezone.name);

    if (actionType === EActionType.EDIT) {
      createEventTrackAmplitute(
        state.format !== EEventFormat.WEBINAR
          ? 'EditEventDetailsNextButtonClicked'
          : 'EditEventDetailsFinishButtonClicked',
        {
          event_id: params?.eventId,
          parent_screen: 'EDIT_EVENT_DETAILS',
          funnel_entry_section: 'EDIT_EVENT_FUNNEL',
          event_name_edited:
            state?.name !== tempEventDetails?.name && state?.name
              ? `"[${state?.name}, ${tempEventDetails?.name}]"`
              : null,
          time_zone_edited:
            state?.timezone_id !== tempEventDetails?.timezone_id
              ? `"[${getTimezoneName(state?.timezone_id)}, ${tempEventDetails?.timezone_name}]"`
              : null,
          event_start_timestamp_edited:
            +state?.editDetails?.start_time_milli !== startTimeUtc
              ? `"[${state?.editDetails?.start_time_milli} , ${startTimeUtc}]"`
              : null,
          event_end_timestamp_edited:
            +state?.editDetails?.end_time_milli !== endTimeUtc
              ? `"[${state?.editDetails?.end_time_milli} , ${endTimeUtc}]"`
              : null,
          location_edited:
            state.format !== EEventFormat.WEBINAR && state?.address !== tempEventDetails?.address
              ? `"[
            {
              'latitude': ${state?.address?.lat},
              'longitude': ${state?.address?.lon}
            },            
            {
              'latitude': ${tempEventDetails?.address?.lat},
              'longitude': ${tempEventDetails?.address?.lon}
            }
          ]"`
              : null,
          address_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.address?.address !== tempEventDetails?.address?.address
              ? `"[${state?.address?.address}, ${tempEventDetails?.address?.address}]"`
              : null,
          state_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.address?.province !== tempEventDetails?.address?.province
              ? `"[${state?.address?.province}, ${tempEventDetails?.address?.province}]"`
              : null,
          city_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.address?.city !== tempEventDetails?.address?.city
              ? `"[${state?.address?.city}, ${tempEventDetails?.address?.city}]"`
              : null,
          country_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.address?.country !== tempEventDetails?.address?.country
              ? `"[${state?.address?.country}, ${tempEventDetails?.address?.country}]"`
              : null,
          postal_code_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.address?.zip !== tempEventDetails?.address?.zip
              ? `"[${state?.address?.zip}, ${tempEventDetails?.address?.zip}]"`
              : null,
          description_edited:
            state.format !== EEventFormat.WEBINAR &&
            state?.description !== tempEventDetails?.description
              ? `"[${state?.description}, ${tempEventDetails?.description}]"`
              : null,
          social_engagement_links_edited: isSocialEngagementLinksEdited(),
          app_version: projectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          // 'organisation_type': '',
          plan_type: planName,
        }
      );
      if (state.format !== EEventFormat.WEBINAR)
        createEventTrackAmplitute('EditLanguagesScreenViewed', {
          event_id: params?.eventId,
          referrer_screen: 'EDIT_EVENT_DETAILS',
          funnel_entry_section: 'EDIT_EVENT_FUNNEL',
          app_version: projectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          // 'organisation_type': '',
          plan_type: planName,
        });
    } else {
      createEventTrackAmplitute(
        state.format !== EEventFormat.WEBINAR
          ? 'EventDetailsNextButtonClicked'
          : 'EventDetailsFinishButtonClicked',
        {
          parent_screen: 'EVENT_DETAILS',
          funnel_entry_section: 'CREATE_EVENT_FUNNEL',
          event_name: tempEventDetails.name,
          time_zone: tempEventDetails.timezone_name,
          event_start_timestamp: startTimeUtc,
          event_end_timestamp: endTimeUtc,
          country: tempEventDetails?.country || tempEventDetails?.address?.country,
          app_version: projectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          // 'organisation_type': '',
          plan_type: planName,
        }
      );
      if (state.format !== EEventFormat.WEBINAR) {
        createEventTrackAmplitute('LanguagesScreenViewed', {
          referrer_screen: 'EVENT_DETAILS',
          funnel_entry_section: 'CREATE_EVENT_FUNNEL',
          app_version: projectSetting.buildversion,
          user_id: userInfo.UserInfo.id,
          organisation: userInfo.UserInfo.organisation,
          group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
          // 'organisation_type': '',
          plan_type: planName,
        });
      }
    }

    // if (state.format !== EEventFormat.WEBINAR) {
      // navigating to the language screen
      if (actionType === EActionType.CREATE) history.push(`/events/create/${screen + 1}`);
      else {
        history.push(`/events/${params?.eventId}/${params?.organiserId}/edit/${screen + 1}`);
      }
    // } else {
    //   callFinishAPI({ ...state, ...tempEventDetails }, selectedAddons);
    // }

    return true;
  };

  // handleModalClose - triggers when organiser closes the contact support modal
  const handleModalClose = (isSuccess = false) => {
    handleModal(false);
    if (isSuccess) setContactedSupport(true);

    createEventTrackAmplitute('ContactSupportButtonClicked', {
      parent_screen: `${actionType === EActionType.EDIT ? 'EDIT_EVENT_DETAILS' : 'EVENT_DETAILS'}`,
      funnel_entry_section: `${
        actionType === EActionType.EDIT ? 'EDIT_EVENT_FUNNEL' : 'CREATE_EVENT_FUNNEL'
      }`,
      app_version: projectSetting.buildversion,
      user_id: userInfo.UserInfo.id,
      organisation: userInfo.UserInfo.organisation,
      group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
      // 'organisation_type': '',
      plan_type: planName,
    });
  };

  /*
   * isDisableNext is to disable the next button until the organiser fills the mandatory fields
   * it'll change, if any change in mandatory field
   */
  const isDisableNext = useMemo(() => {
    if (timezone.id === '') return true;
    if (name === '' || !name) return true;
    if (startDateTime.date === '' || startDateTime.time === '') return true;
    if (endDateTime.date === '' || endDateTime.time === '') return true;
    if (showCounty) {
      if (country === '' || !country) return true;
    }
    return false;
  }, [
    timezone.id,
    name,
    startDateTime.date,
    startDateTime.time,
    endDateTime.date,
    endDateTime.time,
    showCounty,
    country,
  ]);

  // onPrevious - triggers when organiser clicks on previous button
  const onPrevious = () => {
    const tempEventDetails = {
      name: name?.trim(),
      timezone_id: timezone?.id,
      timezone_name: timezone?.name,
      address,
      description,
      socialLinks,
    };
    const startTime = addTimeToDate(startDateTime?.time);
    const endTime = addTimeToDate(endDateTime?.time);
    const startTimeUtc = convertToTimezone(
      Number(startDateTime?.date) - 1000 + startTime,
      timezone?.name
    );
    const endTimeUtc = convertToTimezone(Number(endDateTime?.date) + endTime, timezone?.name);

    if (actionType === EActionType.EDIT) {
      createEventTrackAmplitute('EditEventDetailsPreviousButtonClicked', {
        event_id: params?.eventId,
        parent_screen: 'EDIT_EVENT_DETAILS',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        event_name_edited:
          state?.editDetails?.name !== tempEventDetails.name
            ? `"[${state?.editDetails?.name}, ${tempEventDetails.name}]"`
            : null,
        time_zone_edited:
          state?.timezone_id !== tempEventDetails?.timezone_id
            ? `"[${getTimezoneName(state?.timezone_id)}, ${tempEventDetails?.timezone_name}]"`
            : null,
        event_start_timestamp_edited:
          +state?.editDetails?.start_time_milli !== startTimeUtc
            ? `"[${+state?.editDetails?.start_time_milli} , ${startTimeUtc}]"`
            : null,
        event_end_timestamp_edited:
          +state?.editDetails?.end_time_milli !== endTimeUtc
            ? `"[${+state?.editDetails?.end_time_milli} , ${endTimeUtc}]"`
            : null,
        location_edited:
          state.format !== EEventFormat.WEBINAR && state?.address !== tempEventDetails?.address
            ? `"[
            {
              latitude: ${state?.address?.lat},
              longitude: ${state?.address?.lon}
            },            
            {
              latitude: ${tempEventDetails?.address?.lat},
              longitude: ${tempEventDetails?.address?.lon}
            }
          ]"`
            : null,
        address_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.address?.address !== tempEventDetails?.address?.address
            ? `"[${state?.address?.address}, ${tempEventDetails?.address?.address}]"`
            : null,
        state_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.address?.province !== tempEventDetails?.address?.province
            ? `"[${state?.address?.province}, ${tempEventDetails?.address?.province}]"`
            : null,
        city_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.address?.city !== tempEventDetails?.address?.city
            ? `"[${state?.address?.city}, ${tempEventDetails?.address?.city}]"`
            : null,
        country_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.address?.country !== tempEventDetails?.address?.country
            ? `"[${state?.address?.country}, ${tempEventDetails?.address?.country}]"`
            : null,
        postal_code_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.address?.zip !== tempEventDetails?.address?.zip
            ? `"[${state?.address?.zip}, ${tempEventDetails?.address?.zip}]"`
            : null,
        description_edited:
          state.format !== EEventFormat.WEBINAR &&
          state?.description !== tempEventDetails?.description
            ? `"[${state?.description}, ${tempEventDetails?.description}]"`
            : null,
        social_engagement_links_edited: isSocialEngagementLinksEdited(),
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
      createEventTrackAmplitute('EditEventTypeScreenViewed', {
        event_id: params?.eventId,
        referrer_screen: 'EDIT_EVENT_DETAILS',
        funnel_entry_section: 'EDIT_EVENT_FUNNEL',
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
    } else {
      createEventTrackAmplitute('EventDetailsPreviousButtonClicked', {
        parent_screen: 'EVENT_DETAILS',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        event_name: tempEventDetails.name,
        time_zone: tempEventDetails.timezone_name,
        event_start_timestamp: startTimeUtc,
        event_end_timestamp: endTimeUtc,
        country: country || tempEventDetails?.address?.country,
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
      createEventTrackAmplitute('EventTypeScreenViewed', {
        referrer_screen: 'EVENT_DETAILS',
        funnel_entry_section: 'CREATE_EVENT_FUNNEL',
        app_version: projectSetting.buildversion,
        user_id: userInfo.UserInfo.id,
        organisation: userInfo.UserInfo.organisation,
        group: userInfo.UserInfo.is_team_member_loggedin === true ? 'TEAM MEMBER' : 'ORGANISER',
        // 'organisation_type': '',
        plan_type: planName,
      });
    }
  };

  return (
    <>
      {isTimezoneLoading || initLoading || isLoading ? (
        <EventDetailsLoader conditionsForAllFields={isEdit} />
      ) : (
        <>
          <Box className={classes.eventDetailsScreen}>
            <Box className={` ${classes.eventDetails}`} mt={3}>
              <Box>
                <InputField
                  name="name"
                  value={name}
                  onChange={(event) => {
                    setErrObj((prev) => ({ ...prev, name: '' }));
                    setName(event.target.value);
                  }}
                  label={
                    planName === PricingPlan.WEBINAR_LITE ||
                    planName === PricingPlan.WEBINAR ||
                    !planName
                      ? 'Title'
                      : 'Event Name'
                  }
                  placeholder="Example: Fireside chat with 'Panelist Name' on AI"
                  type="text"
                  required
                  maxLength={restrictingCharacerInput.event.name}
                  error={errObj?.name}
                />
              </Box>
              <Box mt={3}>
                <SelectDropdown
                  formControlClassName="eventDetailsTimezoneSelect"
                  name="timezone"
                  value={timezone}
                  size="large"
                  inputClassName="bgWhite"
                  onChange={handleTimezoneChange}
                  placeholder="Select a timezone for your event"
                  label={
                    planName === PricingPlan.WEBINAR_LITE ||
                    planName === PricingPlan.WEBINAR ||
                    !planName
                      ? 'Timezone'
                      : 'Time Zone'
                  }
                  options={timeZoneList}
                  valueKey="id"
                  labelKey="description"
                  isArrayOfObject
                  hasSearch
                  required
                  disabled={disableStatusStartTime || disableStatusEndTime}
                />
              </Box>

              <Box
                className="dateTimeEventDetails"
                mt={3}
                display="inline-flex"
                alignItems="center">
                <Grid container>
                  <Grid item xs={6}>
                    <Box display="inline-flex" alignItems="end" mr={1.5} className="eventDateTime">
                      <DateTimePickerNew
                        enableTime={false}
                        customIconStart
                        customIconEnd
                        yearSelectorType="dropdown"
                        label="Start Date and Time"
                        dateTimeFormat="M j, Y"
                        name="start_time_milli"
                        className="eventDatePicker"
                        disabled={disableStatusStartTime}
                        value={startDateTime.date || undefined}
                        onChange={(date) => handleDateChange('start_date', date[0].getTime())}
                        placeholder="Select Date"
                        position="auto"
                        descType="helperText"
                        required
                        prevArrow={false}
                        onReady={onReadyYearMaker}
                        minDate={
                          disableStatusStartTime ? '' : new Date(moment().set({ h: 0, m: 0, s: 0 }).valueOf())
                        }
                      />
                      <TimePickerNew
                        selectTime={(value, fieldName) => handleDateChange(fieldName, value)}
                        startAdornment={false}
                        endAdornment={false}
                        label=" "
                        name="start_time"
                        required
                        placeholder="Select Time"
                        className="eventTimePicker"
                        disabled={disableStatusStartTime}
                        value={startDateTime.time}
                      />
                    </Box>
                    {errObj?.startDateTime ? (
                      <FormHelperText className={`${classes.textDanger}`}>
                        {errObj?.startDateTime}
                      </FormHelperText>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="inline-flex" alignItems="end" ml={1.5} className="eventDateTime">
                      <DateTimePickerNew
                        enableTime={false}
                        customIconStart
                        customIconEnd
                        label="End Date and Time"
                        dateTimeFormat="M j, Y"
                        name="end_time_milli"
                        className="eventDatePicker"
                        disabled={disableStatusStartTime || disableStatusEndTime}
                        value={endDateTime.date}
                        onChange={(date) => {
                          handleDateChange('end_date', date[0].getTime());
                          setIsEndTimeEdited(true);
                        }}
                        placeholder="Select Date"
                        position="auto"
                        descType="helperText"
                        required
                        onReady={onReadyYearMaker}
                        minDate={
                          new Date(moment(startDateTime.date).set({ h: 0, m: 0, s: 0 }).valueOf())
                        }
                        maxDate={disableStatusStartTime ? '' : endDateLimit}
                      />
                      <TimePickerNew
                        selectTime={(value, fieldName) => handleDateChange(fieldName, value)}
                        startAdornment={false}
                        endAdornment={false}
                        label=" "
                        name="end_time"
                        required
                        placeholder="Select Time"
                        className="eventTimePicker"
                        disabled={disableStatusStartTime || disableStatusEndTime}
                        value={endDateTime.time}
                      />
                    </Box>
                    {errObj?.endDateTime ? (
                      <Box ml={1.25}>
                        <FormHelperText className={`${classes.textDanger}`}>
                          {errObj?.endDateTime}
                        </FormHelperText>
                      </Box>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Box>
              {disableStatusStartTime || disableStatusEndTime ? (
                <MsgNotificationComponent
                  classType="error"
                  maxWidth="516px"
                  msgText="The start and end date or time cannot be changed for an ongoing or ended event."
                  icon={<SVGErrorIconOutline color="primary" />}
                />
              ) : (
                ''
              )}

                {state.format === EEventFormat.WEBINAR ? null : !eventDays || eventDays <= 3 ? (
                <>
                  {!disableStatusStartTime &&
                    (userInfo.UserInfo.isOrganiserContactForCustomDays === 1 || contactedSupport ? (
                      <>
                        {userInfo.UserInfo.subscriptionStatus !== undefined ? (
                          <Box
                            mt={2}
                            mb={1}
                            className={classes.successMsg}
                            p={1.2}
                            display="flex"
                            alignItems="center">
                            <CheckCircle size={18} />
                            <Typography
                              variant="caption"
                              component="p"
                              className={classes.boldText}>
                              The support team has been notified that you want to create an event
                              for more than 3 days. You will be able to do so shortly.
                            </Typography>
                          </Box>
                        ) : null}
                      </>
                    ) : (
                      <MsgNotificationComponent
                        msgText={
                          userInfo.UserInfo.subscriptionStatus === undefined
                            ? 'If you want to create an event for more than 3 days, contact support.'
                            : 'Want to create an event for more than 3 days?'
                        }
                        clicked={
                          (planName === PricingPlan.WEBINAR_LITE ||
                            planName === PricingPlan.WEBINAR) &&
                          userInfo.UserInfo.subscriptionStatus !== undefined
                            ? () => null
                            : null
                        }
                        classType="error"
                        maxWidth="500px"
                        icon={<SvgBulbIcon />}
                        linkText={
                          userInfo.UserInfo.subscriptionStatus === undefined
                            ? ''
                            : 'Contact Support'
                        }
                      />
                    ))}
                </>
              ) : null}

              {isEdit && state?.isEnableSlotAlert ? (
                <Box
                  mt={2}
                  mb={1}
                  className={classes.errorMsg}
                  py={1}
                  px={1.5}
                  display="flex"
                  alignItems="center">
                  <Info size={20} />
                  <Typography variant="body2" component="p">
                    Changing event dates will reset the triggers for your reminder emails to
                    default.
                    <Box
                      onClick={() =>
                        window.open(
                          'https://support.hubilo.com/hc/en-us/sections/9631100504077-Email-Manager'
                        )
                      }
                      component="a"
                      variant="body2"
                      ml={0.5}
                      className="text-underline  cursor-pointer">
                      Learn More
                    </Box>
                  </Typography>
                </Box>
              ) : (
                ''
              )}

              {showCounty && (
                <Box mt={3}>
                  <SelectDropdown
                    formControlClassName="eventDetailsCountrySelect"
                    value={
                      country
                        ? { countryName: country }
                        : state.country
                        ? { countryName: state.country }
                        : null
                    }
                    size="large"
                    inputClassName="bgWhite"
                    name="Country"
                    placeholder="Select the country of your event’s location"
                    label="Country"
                    valueKey="countryName"
                    position="top"
                    options={countryList}
                    labelKey="countryName"
                    isArrayOfObject
                    hasSearch
                    required
                    onChange={handleCountryChange}
                  />
                </Box>
              )}

              {isEdit ? (
                <>
                  {showAddressFields ? (
                    <EventDetailsAddress
                      address={{ ...address, country }}
                      country={country}
                      error={errObj?.address}
                      handleAddressChange={handleAddressChange}
                      handleChangeLocation={() => {}}
                      handleChangeMap={() => {}}
                    />
                  ) : null}

                  {state.format !== EEventFormat.WEBINAR && (
                    <Box mt={3}>
                      <EditorField
                        label="Description"
                        name="description"
                        maxLength={restrictingCharacerInput.event.description}
                        value={description}
                        error={errObj?.description}
                        onChange={handleDescriptionChange}
                        descText={getCalculatedCharLegth(
                          restrictingCharacerInput.event.description,
                          description || 0
                        )}
                        placeholder="Tell us more about the purpose, goals, and outcomes of the event that you are organizing."
                        isLanguageSupport={state.languages && state.languages > 0}
                      />
                    </Box>
                  )}

                  <Box className="eventSocialLinkInput" mt={3}>
                    <Box mt={2}>
                      <Box display="flex" alignItems="center" mb={0.5}>
                        <Typography variant="body2" component="span" color="textSecondary">
                          Social Engagement Links
                        </Typography>
                        <CustomToolTip
                          placement="top"
                          title={
                            <Typography variant="caption" component="span">
                              Add various social links to your event so attendees can find you on
                              other platforms
                            </Typography>
                          }>
                          <Box display="flex" alignItems="center" justifyContent="center" ml={1}>
                            <SvgInfoIconCustom />
                          </Box>
                        </CustomToolTip>
                      </Box>
                      <InputField
                        placeholder="https://"
                        name="website_url"
                        value={socialLinks?.website_url}
                        error={errObj?.website_url}
                        onChange={handleSocialLinksChange}
                        type="text"
                        endAdornment
                        endIconAdornment
                        endAdornmentIcon={<Globe className="globe" />}
                      />
                    </Box>
                    <Box mt={3}>
                      <InputField
                        placeholder="https://"
                        name="linked_url"
                        value={socialLinks?.linked_url}
                        error={errObj?.linked_url}
                        onChange={handleSocialLinksChange}
                        type="text"
                        endAdornment
                        endIconAdornment
                        endAdornmentIcon={<Linkedin className="linkedIn" />}
                      />
                    </Box>
                    <Box mt={2}>
                      <InputField
                        placeholder="https://"
                        name="twitter_url"
                        value={socialLinks?.twitter_url}
                        error={errObj?.twitter_url}
                        onChange={handleSocialLinksChange}
                        type="text"
                        endAdornment
                        endIconAdornment
                        endAdornmentIcon={<XSvgIcon className="twitter" />}
                      />
                    </Box>
                    <Box mt={2}>
                      <InputField
                        placeholder="https://"
                        name="fb_url"
                        value={socialLinks?.fb_url}
                        error={errObj?.fb_url}
                        onChange={handleSocialLinksChange}
                        type="text"
                        endAdornment
                        endIconAdornment
                        endAdornmentIcon={<Facebook className="facebook" />}
                      />
                    </Box>
                    <Box mt={2}>
                      <InputField
                        placeholder="https://"
                        name="instagram_url"
                        value={socialLinks?.instagram_url}
                        error={errObj?.instagram_url}
                        onChange={handleSocialLinksChange}
                        type="text"
                        endAdornment
                        endIconAdornment
                        endAdornmentIcon={<Instagram className="instagram" />}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}
            </Box>
          </Box>
          <CreateEventFooter
            onNext={onNext}
            onPrevious={onPrevious}
            disabledNext={isDisableNext}
            format={state.format}
            isLoading={finisLoading}
          />

          <ContactSupport isOpen={isModalOpen} onClose={handleModalClose} />
        </>
      )}
    </>
  );
};

export default EventDetails;
