export const LP_CONST = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
};

export const TEMPLATE_PREVIEW = {
  MOBILE: LP_CONST.MOBILE,
  DESKTOP: LP_CONST.DESKTOP,
  TABLET: LP_CONST.TABLET,
};

export const DEF_TEMPLATE_PREVIEW = LP_CONST.DESKTOP;
