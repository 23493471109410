// Contants
import { isTrueValueArray } from 'utility/helpers';
import {
  labels,
  schemaCampaignType,
  schemaCampaignStatus,
  styleCampaignStatus,
  ERROR_CHAR_LIMIT,
} from './constants';

const toTitleCase = (str = '') => {
  if (str) {
    return `${str.substring(0, 1).toUpperCase()}${str.substring(1, str.length).toLowerCase()}`;
  }
  return str;
};

const actionListener = (ele, listener = '', CB = () => { }, isRemove = false) => {
  if (isRemove) {
    ele.removeEventListener(listener, CB, true);
    return;
  }
  ele.addEventListener(listener, CB, true);
};

const getCampaignBreadCrump = ({ isCustom }) => {
  if (isCustom) return labels.custom.CampaignBreadCrump;
  return labels.auto.CampaignBreadCrump;
};

const getCampaignChip = ({ isCustom }) => {
  if (isCustom) return labels.custom.chip;
  return labels.auto.chip;
};

const getCampaignType = ({ basicDetail = {} }) => {
  const { eventState = '' } = basicDetail;
  if (eventState) return schemaCampaignType[eventState];
  return '';
};

const getCampaignStatus = ({ campaignStatus = '' }) => {
  if (campaignStatus)
    return [schemaCampaignStatus[campaignStatus], styleCampaignStatus[campaignStatus]];
  return ['', ''];
};

const getActiveCampaign = ({ campaignStatus = '' }) => {
  if (campaignStatus && campaignStatus === 'active') return true;
  return false;
};

const getCampaignDetails = ({ campaignName = '', basicDetail = {} }) => {
  const { emailDescription = '', emailSubject = '' } = basicDetail;
  return [campaignName, emailDescription, emailSubject];
};

const getCampaignSubject = ({ basicDetail = {} }) => {
  const { emailSubject = "" } = basicDetail;
  if (emailSubject) return emailSubject;
  return "";
};

const getEnableActiveStatus = ({ isEnableActiveStatus = false }) => {
  return isEnableActiveStatus;
};

const getCampaignCustomStatus = ({ isCustom }) => {
  return isCustom;
};

const getCampaignLabel = ({ campaignLabel = '' }) => {
  return campaignLabel;
};

const getEnableNameEdit = ({ isEnableNameEdit = false }) => {
  return isEnableNameEdit;
};

const getEditCampaign = ({ isEdit = false, editCampaignId = '' }) => {
  return isEdit && editCampaignId;
};

const getTemplateLabel = ({ templateLabel = '' }) => {
  return templateLabel;
};
const getMilliSecs = (currFullDate = new Date()) => {
  if (currFullDate) {
    const localDate = currFullDate.getDate();
    const localMonth = currFullDate.getMonth() + 1;
    const localYear = currFullDate.getFullYear();
    const localHrs = currFullDate.getHours();
    const localMin = currFullDate.getMinutes();

    const currDate = !Number.isNaN(localDate) && localDate < 10 ? `0${localDate}` : localDate;
    const currMonth = !Number.isNaN(localMonth) && localMonth < 10 ? `0${localMonth}` : localMonth;
    const currYear = !Number.isNaN(localYear) && localYear < 10 ? `0${localYear}` : localYear;
    const currHrs = !Number.isNaN(localHrs) && localHrs < 10 ? `0${localHrs}` : localHrs;
    const currMin = !Number.isNaN(localMin) && localMin < 10 ? `0${localMin}` : localMin;

    const finalDate = `${currYear}-${currMonth}-${currDate}T${currHrs}:${currMin}Z`;
    return new Date(finalDate).getTime(finalDate);
  }
  return 0;
};

const isTrueObject = (obj) => {
  const isTypeObject = typeof obj === 'object';
  if (isTypeObject && Object.keys(obj).length) return true;
  return false;
};

const isObject = (obj) => {
  const isTypeObject = typeof obj === 'object';
  if (isTypeObject) return isTrueObject(obj);
  return isTypeObject;
};

const getCharLimitError = (field = '', count = -1) => {
  if (!field || !count || count < 0) return '';
  let error = ERROR_CHAR_LIMIT.replace('<FIELD_NAME>', field);
  error = error.replace('<CHAR_LIMIT>', count);
  return error;
};

const hasBasicDetails = (current = {}) => {
  const hasCurrent = isTrueObject(current);
  if (hasCurrent) {
    const { emailSubject = '', emailDescription = '' } = current || {};
    if (emailSubject || emailDescription) {
      return true;
    }
  }
  return false;
};

const hasEmailAudience = (current = {}) => {
  const hasCurrent = isTrueObject(current);
  if (hasCurrent) {
    const { group = [], hasPeople = false } = current || {};
    const hasGroup = isTrueValueArray(group);
    if (hasGroup || hasPeople) {
      return true;
    }
  }
  return false;
};

const hasEmailSender = (current = {}, isCustom = false) => {
  const hasCurrent = isTrueObject(current);
  if (hasCurrent) {
    const { senderEmail = "", senderName = '' } = current || {};
    if (!isCustom && (senderName || senderEmail)) {
      return true;
    }
    if (isCustom && senderName) {
      return true;
    }
  }
  return false;
};

const hasEmailSchedule = (current = {}) => {
  const hasCurrent = isTrueObject(current);
  if (hasCurrent) {
    const { campaignStartTimeMilli = '', campaignTimeZone = '' } = current || {};
    const isActualMilli = Number(campaignStartTimeMilli) > 0;
    if (campaignTimeZone || isActualMilli) {
      return true;
    }
  }
  return false;
};

const hasExpiredSchedule = (current = {}) => {
  const isSchedule = hasEmailSchedule(current);
  if (isSchedule) {
    const hasCurrent = isTrueObject(current);
    if (hasCurrent) {
      const { campaignStartTimeMilli = '' } = current || {};
      const currentMilli = Date.now();
      const isActualMilli = Number(campaignStartTimeMilli) > 0;
      const isExpired = Number(currentMilli) > Number(campaignStartTimeMilli);
      if (isActualMilli && isExpired) {
        return true;
      }
    }
  }
  return false;
};

const getTemplateCount = (current = "") => {
  const arr = current.split('#');
  const returnData = arr[arr.length - 1];
  if (returnData && !Number.isNaN(returnData) && Number(returnData) > 0) {
    return Number(returnData)
  } else {
    return 0
  }
};

const getTemplateNameCount = (current = []) => {
  let count = 0;
  current.forEach(({ templateName = '' }) => {
    if (templateName) {
      const getLast = getTemplateCount(templateName);
      if (getLast > count) count = getLast;
    }
  });
  return count + 1;
};

export {
  getCampaignDetails,
  getCampaignSubject,
  getCampaignBreadCrump,
  getCampaignChip,
  getCampaignType,
  getActiveCampaign,
  getCampaignStatus,
  getEnableActiveStatus,
  getCampaignCustomStatus,
  getCampaignLabel,
  getEnableNameEdit,
  getEditCampaign,
  actionListener,
  toTitleCase,
  getMilliSecs,
  isTrueObject,
  isObject,
  getTemplateLabel,
  getCharLimitError,
  hasBasicDetails,
  hasEmailAudience,
  hasEmailSender,
  hasEmailSchedule,
  hasExpiredSchedule,
  getTemplateNameCount,
};
