/* eslint-disable  */
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  EVENT_GROUP_SETTING,
  DELETE_PROFILE_FIELDS,
  GET_USER_PROFILE_FIELDS,
  REARRANGE_PROFILE_FIELD_LIST,
  UPDATE_USER_PROFILE_FIELDS,
  ADD_USER_PROFILE_FIELDS,
  GET_DISCOUNT_TICKETS,
  TICKET_GROUP_LIST,
} from 'api-setup/api-endpoints';
import {
  API,
  GETAPI,
  POSTAPI,
  PUTAPI,
  GETAPIWITHCUSTOMDOMAIN,
  POSTAPIWITHCUSTOMDOMAIN,
  API_CALL_METHODS,
  API_CALL_RESP_CODES,
} from 'api-setup/api-helper';
import BtnWithLoader from 'custom-components/btnWithLoader';
import CardWrapper from 'custom-components/cardWrapper';
import CustomToolTip from 'custom-components/customToolTip';
import CheckBox2 from 'custom-components/form-fields/checkbox2';
import InputField from 'custom-components/form-fields/inputField';
import OptionList from 'custom-components/form-fields/optionsList';
import SelectDropDown from 'custom-components/form-fields/selectDropdown';
import SwitchField from 'custom-components/form-fields/switch';
import ConfirmationPopUp from 'custom-components/popUp';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import produce from 'immer';
import LearnMoreLink from 'learnMoreLinks';
import { applyDrag } from 'lib/common-function';
import { useSource } from 'lib/custom-common-hooks';
import { numberRegex } from 'models/regex';
import React, { useCallback, useEffect, useState } from 'react';
import { Trash2, Plus } from 'react-feather';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Draggable } from 'react-smooth-dnd';
import { setNewMessage } from 'redux/actions/setting-actions';
import {
  commonSelector,
  userInfoSelector,
  eventDataSelector,
  currentEventLanguagesSelector,
  eventLanguagesSelector,
} from 'redux/custom-selector';
import ProfileFieldsSidebarNewUI from './profileFieldsSidebarNewUI';
import { IconSideBar } from './profileFieldIcons';
import isEmpty from 'lodash/isEmpty';
import { HUB_CONST, HUB_LABELS } from 'constants/common';
import SelectCheckBoxDropDown from 'custom-components/form-fields/selectCheckBoxDropDown';
import projectSetting from 'settings';
import { PAGE_LIMITS } from 'pages/registration/discount/discountConstant';
import SvgUnionDotsIcon from 'icons/svgUnionDots';
import { LanguageBox } from 'custom-components/languageBox';
import { TRANSL_CONST } from './profileFieldsConst';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';
import WebinarAddFormField from 'pages/registration/webinar-registration-setup-form/WebinarAddFormField';
import { commonTrackEvent } from 'lib/amplitude/amplitude-tracking';

const useStyles = makeStyles((theme) => ({
  trimWidth: {
    '& .MuiFormControl-fullWidth': {
      width: `auto`,
    },
  },
  errorLabel: {
    color: "red",
    fontWeight: 500,
    marginTop: theme.spacing(1),
  },
  profileInsertData: {
    paddingRight: 50,
  },
  stateTip: {
    '& button': {
      left: 'auto',
      right: 0,
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  eventBlockSetting: {
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 5,
  },
  eventList: {
    padding: 0,
    '& li': {
      '&:hover .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      opacity: 0,
      '& button': {
        color: theme.palette.primary.light,
      },
    },
    '& .profile-list': {
      '&:nth-child(n+1)': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.light}`,
      boxShadow: '0px 3px 5px -2px rgba(0,0,0,0.2)',
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.contrastText}`,
      },
      '& .MuiListItemIcon-root': {
        '&>svg': {
          color: theme.palette.primary.light,
        },
        '&>div': {
          '&>div': {
            '& svg': {
              '&>path': {
                fill: theme.palette.primary.light,
              },
            },
          },
        },
      },
      '& .MuiListItemText-root': {
        '&>label': {
          color: theme.palette.primary.light,
        },
      },
      '& ~ .MuiListItemSecondaryAction-root': {
        opacity: 1,
      },
    },
  },
  eventNameBlockSkeleton: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    padding: `${theme.spacing(1.2)}px ${theme.spacing(2.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
    '&:nth-child(n+2)': {
      marginTop: theme.spacing(2),
    },
  },
  eventNameBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 45,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[3],
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.light,
        },
        '&>div': {
          '&>div': {
            '& svg': {
              '&>path': {
                fill: theme.palette.primary.light,
              },
            },
          },
        },
      },
      '& .MuiListItemText-root': {
        '& label': {
          color: theme.palette.primary.light,
        },
      },
    },
  },
  warningBlock: {
    backgroundColor: theme.palette.alert.warnLight,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  eventsIcon: {
    color: theme.palette.text.grayColor,
    marginTop: '5px',
    '& img': {
      height: '100%',
      width: '100%',
    },
    '& svg': {
      '& path': {
        fill: theme.palette.text.grayColor,
      },
    },
  },
  eventNameBlockCheck: {
    right: 22,
    display: 'flex',
  },
  dragFieldLine: {
    width: 16,
    borderRadius: 4,
    /* border: 1.5px solid #C1C1C1; */
    height: 4,
    backgroundColor: theme.palette.bg.whiteOff2,
    '& + span': {
      marginTop: 3,
    },
  },
  dragFieldDot: {
    width: 4,
    borderRadius: 4,
    height: 4,
    backgroundColor: theme.palette.bg.whiteOff2,
    '& + span': {
      marginTop: 3,
    },
  },
  circleCheckIconColor: {
    color: theme.palette.primary.light,
  },
  peopleBlock: {
    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },
  dividerBlock: {
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.bg.whiteOff2,
      height: 1,
    },
  },
  switchBtnStyle: {
    '& label': {
      marginRight: '0px !important',
      '&>span': {
        margin: 0,
      },
    },
  },
  headingTitle: {
    fontWeight: 600,
  },
  changeIconBtn: {
    width: 65,
    height: 65,
    backgroundColor: theme.palette.bg.whiteOff1,
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: 7,
  },
  iconButtonSidebar: {
    border: `1px solid  ${theme.palette.bg.whiteOff2}`,
    borderRadius: theme.shape.borderRadius,
  },
  activeBorderColor: {
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
  },
  peopleHeading: {
    borderBottom: `1px solid ${theme.palette.bg.whiteOff2}`,
  },
  peopleSubTitle: {
    paddingTop: theme.spacing(1.5),
  },
  btnActive: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    boxShadow: theme.shadows[2],
  },
  footerActionBtn: {
    borderTop: `1px solid  ${theme.palette.bg.whiteOff2}`,
  },
  eventSettingBlock: {
    '& li': {
      '& .MuiListItemSecondaryAction-root': {
        right: 0,
      },
    },
  },
  advanceFiledOption: {
    display: 'block',
    zIndex: 1,
  },
  subFieldMainBlk: {
    position: 'relative',
    '& .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper': {
      marginTop: '15px',
    },
    '&:before': {
      content: '""',
      height: '100%',
      width: '1px',
      backgroundColor: `${theme.palette.bg.whiteOff2}`,
      position: 'absolute',
      top: '-16px',
      left: '31px',
    },
  },
  subField: {
    marginLeft: 'auto',
    width: '95%',

    border: `1px solid ${theme.palette.bg.whiteOff2}`,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.contrastText,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
    '& .MuiListItemIcon-root': {
      minWidth: 45,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[3],
      '& .MuiListItemIcon-root': {
        '& svg': {
          color: theme.palette.primary.light,
        },
        '&>div': {
          '&>div': {
            '& svg': {
              '&>path': {
                fill: theme.palette.primary.light,
              },
            },
          },
        },
      },
      '& .MuiListItemText-root': {
        '& label': {
          color: theme.palette.primary.light,
        },
      },
    },
  },
  appRightBlockContent: {
    maxHeight: 320,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '20px',
    margin: '0 -16px',
  },
  addBtn: {
    margin: '15px 0 15px 18px',
  },
}));

function ProfileLoader(props) {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between">
      <Box width="45%" className={classes.eventBlockSetting}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List className={classes.eventList}>
              {[1, 1, 1, 1, 1, 1, 1].map(() => (
                <ListItem disableGutters className={classes.eventNameBlockSkeleton}>
                  <ListItemIcon>
                    <SkeletonLoaders height="18px" width="11px" variant="rect" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body1',
                      component: 'label',
                    }}
                    primary={<SkeletonLoaders height="19px" width="170px" variant="text" />}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      color: 'textSecondary',
                      component: 'p',
                    }}
                    secondary={<SkeletonLoaders height="16px" width="100px" variant="text" />}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box width="50%" className={classes.peopleBlock} p={2}>
        <Box>
          <Box pb={2} className={classes.appSectionHeading}>
            <Typography variant="body1" className={classes.headingTitle} component="h4">
              <SkeletonLoaders height="20px" variant="text" width="100px" />
            </Typography>
          </Box>
          <Box py={2.5} className={classes.appRightBlockContent}>
            <Grid container spacing={4}>
              {props.community_version === 2 ? (
                <Grid item xs={12}>
                  <SkeletonLoaders variant="text" height="19px" width="44px" />
                  <Box mt={1}>
                    <SkeletonLoaders height="65px" width="65px" variant="rect" />
                  </Box>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12}>
                <SkeletonLoaders height="16px" width="100px" variant="text" />
                <Box mt={1}>
                  <SkeletonLoaders height="44px" width="100%" variant="rect" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <SkeletonLoaders height="16px" width="100px" variant="text" />
                <Box mt={1}>
                  <SkeletonLoaders height="44px" width="100%" variant="rect" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <SkeletonLoaders height="25px" width="25px" variant="square" />
                  <Box ml={2}>
                    <SkeletonLoaders height="20px" width="70px" variant="text" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <SkeletonLoaders
                    height="20px"
                    width="20px"
                    variant="square"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                  <Box ml={1.5} mr={2.5}>
                    <SkeletonLoaders height="20px" width="30px" variant="text" />
                  </Box>

                  <SkeletonLoaders
                    height="20px"
                    width="20px"
                    variant="square"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                  <Box ml={1.5} mr={2.5}>
                    <SkeletonLoaders height="20px" width="30px" variant="text" />
                  </Box>

                  <SkeletonLoaders
                    height="20px"
                    width="20px"
                    variant="square"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                  <Box ml={1.5}>
                    <SkeletonLoaders height="20px" width="30px" variant="text" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <Typography component="label" variant="body2">
                    <SkeletonLoaders height="16px" width="100px" variant="text" />
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SkeletonLoaders height="44px" width="100%" variant="rect" />
                  </Grid>
                  <Grid item xs={6}>
                    <SkeletonLoaders height="44px" width="100%" variant="rect" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    <SkeletonLoaders height="20px" width="220px" variant="text" />
                  </Typography>
                  <SkeletonLoaders
                    height="20px"
                    width="40px"
                    variant="rect"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    <SkeletonLoaders height="20px" width="220px" variant="text" />
                  </Typography>
                  <SkeletonLoaders
                    height="20px"
                    width="40px"
                    variant="rect"
                    skeletonProps={{ borderRadius: '15px !important' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display="flex" pl={1} pt={2} mt={2} className={classes.footerActionBtn}>
          <SkeletonLoaders
            height="30px"
            width="70px"
            variant="rect"
            skeletonProps={{ borderRadius: '15px !important' }}
          />
          <Box ml={1.2}>
            <SkeletonLoaders
              height="30px"
              width="90px"
              variant="rect"
              skeletonProps={{ borderRadius: '15px !important' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const CUSTOM_URL = projectSetting.baseURL;
const CUSTOM_URL_TICKETS = projectSetting.customBaseURL3;

const handelKeyPressOnNumberText = (evt) => {
  if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
    evt.preventDefault();
  }
  if (evt.target.value.length >= 3) {
    evt.preventDefault();
  }
};

const handelKeyPressOnNumberTextArea = (evt) => {
  if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
    evt.preventDefault();
  }
  if (evt.target.value.length >= 4) {
    evt.preventDefault();
  }
};

const iconInitData = {
  currentPage: 1,
  totalCount: 0,
  totalPage: 0,
  data: [],
};

const iconInit = {
  icon_name: 'default',
  icon: ' <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.1645 21.9977 19.3284 21.981 19.49 21.95L19.79 21.88H19.86H19.91L20.28 21.74L20.41 21.67C20.51 21.61 20.62 21.56 20.72 21.49C20.8535 21.3918 20.9805 21.2849 21.1 21.17L21.17 21.08C21.2682 20.9805 21.3585 20.8735 21.44 20.76L21.53 20.63C21.5998 20.5187 21.6601 20.4016 21.71 20.28C21.7374 20.232 21.7609 20.1818 21.78 20.13C21.83 20.01 21.86 19.88 21.9 19.75V19.6C21.9567 19.4046 21.9903 19.2032 22 19V5C22 4.20435 21.6839 3.44129 21.1213 2.87868C20.5587 2.31607 19.7956 2 19 2ZM5 20C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V14.69L7.29 11.39C7.38296 11.2963 7.49356 11.2219 7.61542 11.1711C7.73728 11.1203 7.86799 11.0942 8 11.0942C8.13201 11.0942 8.26272 11.1203 8.38458 11.1711C8.50644 11.2219 8.61704 11.2963 8.71 11.39L17.31 20H5ZM20 19C19.9991 19.1233 19.9753 19.2453 19.93 19.36C19.9071 19.4087 19.8804 19.4556 19.85 19.5C19.8232 19.5423 19.7931 19.5825 19.76 19.62L14.41 14.27L15.29 13.39C15.383 13.2963 15.4936 13.2219 15.6154 13.1711C15.7373 13.1203 15.868 13.0942 16 13.0942C16.132 13.0942 16.2627 13.1203 16.3846 13.1711C16.5064 13.2219 16.617 13.2963 16.71 13.39V13.39L20 16.69V19ZM20 13.86L18.12 12C17.5477 11.457 16.7889 11.1543 16 11.1543C15.2111 11.1543 14.4523 11.457 13.88 12L13 12.88L10.12 10C9.54772 9.45699 8.7889 9.15428 8 9.15428C7.2111 9.15428 6.45228 9.45699 5.88 10L4 11.86V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V13.86Z" fill="black"/></svg>',
  id: '16',
};

const restrictTicketGroupTypes = ['BOOTHMEMBER', 'SPEAKER'];
const attendeeInitDetails = {
  apicallDone: false,
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const groupInitDetails = {
  apicallDone: false,
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const ticketsInitDetails = {
  currentPage: 1,
  totalcount: 0,
  totalPage: 0,
  initLoad: false,
  loading: false,
  search: '',
  tempSearch: '',
  options: [],
  apiDone: true,
};

const reorderingDisabledFields = [
  1, // First Name
  2, // Last Name
  3, // Email
  4, // Phone
];

function ProfileFieldsNewUI() {
  const [isAddField, setAddField] = useState(false);
  const [openSidebar, setOpenSidebar] = useState({ iconSidbar: false });
  const [selectedProfileID, setSelectedProfilID] = useState('');
  const [selectedProfileItemIndex, setSelectedProfileItemIndex] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const source = useSource();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector);
  const eventData = useSelector(eventDataSelector, shallowEqual);
  const history = useHistory();
  const [errObj, setErrObj] = useState('');
  const [profileFieldList, setProfileFieldList] = useState([]);
  const [eventTicketList, setEventTicketList] = useState([]);
  const [loadState, setLoadState] = useState({
    isLoadProfileFields: true,
    deleteFiledLoader: false,
  });
  const [modalData, setModal] = React.useState({
    isOpen: false,
    type: 'NONE',
    data: {},
  });
  const [addFormFields, setAddFormFields] = useState(false);
  const [preBuiltFieldList, setPreBuiltFieldList] = useState([]);

  const handelClosePopup = React.useCallback(
    () => setModal({ ...modalData, isOpen: false }),
    [modalData]
  );
  const [defaultField, setDefaultField] = useState({});
  const [showDisable, setDisableField] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [groupDropdownOptions, setGroupDropdownOptions] = useState([]);
  const [defaultSelectedData, setDefaultSelectedData] = useState({});
  const [defaultGroupOptions, setDefaultGroupOptions] = useState([]);
  const [defaultGroupDropdownOptions, setDefaultGroupDropdownOptions] = useState([]);
  const [defaultFileTypes, setDefaultFileTypes] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currenGroups, setCurrenGroups] = useState([]);

  const [iconData, setIconData] = useState({ ...iconInitData });
  const [defaultIcon, setDefaultIcon] = useState({ ...iconInit });
  const [action, setAction] = useState('edit');
  const [addIcon, setAddIcon] = useState('');

  const currentEventLanguage = useSelector(currentEventLanguagesSelector, shallowEqual);
  const [selectedLanguage, setSelectedLanguage] = useState(currentEventLanguage);
  const eventLanguages = useSelector(eventLanguagesSelector, shallowEqual);
  const isWebinarEventType =
    eventData.EventData && eventData.EventData.type === EEventFormat.WEBINAR;
  const isWebinar =
    eventData.EventData &&
    eventData.EventData.type === EEventFormat.WEBINAR &&
    history.location.pathname.includes('setup-form');

  useEffect(() => {
    if (currentEventLanguage) {
      setSelectedLanguage(currentEventLanguage);
    }
  }, [currentEventLanguage]);

  useEffect(() => {
    if (selectedLanguage) {
      APICALL(
        'GET_USER_PROFILE_FIELDS',
        reduxData,
        API_CALL_METHODS.GET,
        GET_USER_PROFILE_FIELDS,
        { language: selectedLanguage?.id },
        null,
        APICB
      );
    }
  }, [selectedLanguage]);

  const [attendeeList, setAttendeeList] = useState({ ...attendeeInitDetails });
  const [attendeeGroupList, setAttendeeGroupList] = useState('');
  const [headers] = API.generateHeader(reduxData, null);
  const [sectionList, setSectionList] = useState({ ...groupInitDetails });
  const [ticketGroupList, setTicketGroupList] = useState('');
  const [ticketsList, setTicketsList] = useState({ ...ticketsInitDetails });
  const [ticketList, setTicketList] = useState('');
  const [fieldTypeList, setFieldTypeList] = useState([]);

  const APICALL = React.useCallback(
    (ACTION, reduxData, methodType, URL, headerData, bodyData, APICB) => {
      const [header, body] = API.generateHeader(reduxData, headerData, bodyData);
      let APIPromise;
      if (methodType === API_CALL_METHODS.GET) {
        APIPromise = GETAPI(false, URL, source.AS, header);
      } else if (methodType === API_CALL_METHODS.POST) {
        APIPromise = POSTAPI(
          false,
          URL,
          source.AS,
          { ...header, language: selectedLanguage?.id },
          body
        );
      } else if (methodType === API_CALL_METHODS.PUT) {
        APIPromise = PUTAPI(
          false,
          URL,
          source.AS,
          { ...header, language: selectedLanguage?.id },
          body
        );
      }

      APIPromise.then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          if (resp.data.message)
            dispatch(
              setNewMessage({
                message: resp.data.message,
                type: 'success',
                show: true,
              })
            );
          APICB && APICB(ACTION, HUB_CONST.SUCCESS, resp.data, headerData);
        } else {
          setLoadState({
            ...loadState,
            deleteFiledLoader: false,
            isLoadProfileFields: false,
          });
          API.errStatusHandler(resp, history, dispatch, () => {
            APICB && APICB(ACTION, HUB_CONST.ERROR, resp.data);
          });
        }
      }).catch(API.catchHandler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedLanguage]
  );

  const addNewSubField = (parent) => {
    const newChild = {
      communityGroups: parent?.communityGroups || 'ALL',
      disabledOptionsMsg: '',
      fieldName: '',
      hasOptions: 'NO',
      isDefault: 'NO',
      isDisabled: 'NO',
      isDisabledField: 'NO',
      isDisabledOptions: 'NO',
      isDisplayInDashboard: parent?.isDisplayInDashboard || 'YES',
      isLinkable: 'NO',
      isOnboardingEditingRestricted: parent?.isOnboardingEditingRestricted || 'NO',
      isRequired: 'NO',
      isRequiredInCommunity: 'NO',
      isRequiredInTicketing: 'NO',
      isShow: parent?.isShow || 'YES',
      isShowToOthers: parent?.isShowToOthers || 'YES',
      isUseInFilter: parent?.isUseInFilter || 'YES',
      isUseInOnoarding: parent?.isUseInOnoarding || 'YES',
      isUseInTicketing: parent?.isUseInTicketing || 'NO',
      optionId: '',
      parent: parent._id,
      options: ['', ''],
      optionsForFrontend: [
        { id: 1, value: '', name: '' },
        { id: 2, value: '', name: '' },
      ],
      position: parent.childFields.length + 1,
      properties: {
        include_other_option: 0,
        is_number: 0,
        min: 0,
        max: 4000,
        max_file_size: 5,
        include_blank: 0,
        file_types: [
          { id: 1, name: 'PDF', value: true },
          { id: 2, name: 'IMAGE', value: true },
          { id: 3, name: 'DOC', value: true },
          { id: 6, name: 'PPT', value: true },
          { id: 7, name: 'EXCEL', value: true },
        ],
        allowed_max_file_size: 5,
        button_label: 'CLICK HERE TO ADD A NEW PERSON',
        field_type_name: 'Person',
        isShowAddButton: 'YES',
        last_option_id: 1,
        length: {
          min: 1,
          max: 3,
        },
        fields: [
          {
            fieldName: 'Name',
            fieldType: 1,
            id: 1,
            isDisabledField: 'NO',
            isRequired: 'NO',
          },
        ],
      },
      selectedOptions: [],
      showIsRequired: 'YES',
      showIsShow: 'YES',
      showIsShowToOthers: 'YES',
      showIsUseInFilter: 'YES',
      showIsUseInOnoarding: 'YES',
      ticketingGroups: parent?.ticketingGroups || 'All',
      ticketsMappedWithGroups: parent?.ticketsMappedWithGroups || 'All',
      user_profile_field_icons_id: addIcon?.id || defaultIcon?.id,
      user_profile_field_type: 0,
      userFiledType: 'new field',
      // TICKETING FIELDS
      isAllTicketingGroups: parent?.isAllTicketingGroups || 'YES',
      isAllTicketsMappedWithGroups: parent?.isAllTicketsMappedWithGroups || 'YES',
      //to be deleted on post api call
      newField: true,
      fileType: { name: '' },
    };

    const newProfileFieldList = profileFieldList.map((field) => {
      const obj = { ...field };
      if (parent._id === field._id) {
        const obj1 = { ...newChild };
        obj.childFields = [...field.childFields, obj1];
      }
      return obj;
    });
    setProfileFieldList([...newProfileFieldList]);
  };

  const onCardClick = (item, index) => {
    setTicketGroupList('');
    setTicketList('');
    if (item.communityGroups === 'All') {
      onAttendeeGroupTo('', 'All', '', attendeeList.options, '');
    }
    if (item.ticketingGroups === 'All') {
      onApplicableTo('', 'All', '', attendeeList.options, '');
    }
    if (item.ticketsMappedWithGroups === 'All') {
      onTicketsMapped('', 'All', '', attendeeList.options, '');
    }

    setErrObj({});
    setSelectedProfilID(item._id);
    setSelectedProfileItemIndex(index);
    setAction('edit');
    if (item.userFiledType === 'File') {
      const tempArray = [];
      item.properties.file_types.map((fItem) => {
        const obj = {};
        obj.id = fItem.id;
        obj.label = fItem.name ? fItem.name : fItem.label;
        obj.isChecked =
          fItem.isChecked !== '' && fItem.isChecked !== undefined ? fItem.isChecked : fItem.value;
        tempArray.push(obj);
        return 0;
      });
      setFileTypes(
        produce(tempArray, (draftFileTypes) => {
          return draftFileTypes;
        })
      );
      setDefaultFileTypes(
        produce(tempArray, (draftDefaultFileTypes) => {
          return draftDefaultFileTypes;
        })
      );
      setDefaultSelectedData(item, (draftDefaultSelectedData) => {
        return (draftDefaultSelectedData.properties.file_types = tempArray);
      });
      setDefaultField(item, (draftDefaultField) => {
        return (draftDefaultField.properties.file_types = tempArray);
      });
    } else {
      if (item.userFiledType === 'Group') {
        const tempArr = [];
        item.properties.fields.map((gItem) => {
          const obj = {};
          obj.id = gItem.id;
          obj.value = gItem.fieldName;
          obj.isSelected = gItem.isRequired === HUB_CONST.YES;
          obj.drpOption =
            groupDropdownOptions.filter((x) => x.id === parseInt(gItem.fieldType)).length > 0
              ? groupDropdownOptions.filter((x) => x.id === parseInt(gItem.fieldType))[0]
              : {};
          obj.fieldNameLang = gItem.fieldNameLang;
          tempArr.push(obj);
          return 0;
        });
        setGroupOptions([...tempArr]);
        setDefaultGroupOptions([...tempArr]);
      }
      setDefaultSelectedData(item);
      setDefaultField(item);
    }
    if (item.isDisabled === HUB_CONST.YES) {
      if (item.fieldName === 'Phone' || item?.user_profile_default_field_type === 4) {
        setDisableField(false);
      } else {
        setDisableField(true);
      }
    } else {
      setDisableField(false);
    }
  };

  useEffect(() => { }, [selectedProfileID]);

  const APICB = React.useCallback(
    (ACTION, type, data) => {
      switch (ACTION) {
        case 'GET-GROUP-SETTINGS': {
          if (type === HUB_CONST.SUCCESS) {
            if (data) {
              const apiGroups = data?.data?.groupsList || [];
              if (apiGroups && Array.isArray(apiGroups) && apiGroups.length) {
                if (isEmpty(selectedGroup)) {
                  setSelectedGroup(apiGroups[0]);
                }

                setCurrenGroups(apiGroups);

                if (profileFieldList.length === 0) {
                  APICALL(
                    'GET_USER_PROFILE_FIELDS',
                    reduxData,
                    API_CALL_METHODS.GET,
                    GET_USER_PROFILE_FIELDS,
                    { language: selectedLanguage?.id },
                    null,
                    APICB
                  );
                }
              }
            }
          }
          break;
        }
        case 'GET_USER_PROFILE_FIELDS': {
          if (type === HUB_CONST.SUCCESS) {
            if (data.data.defaultIcon) {
              setDefaultIcon(data.data.defaultIcon);
            }

            let updFieldTypes = data.data.fieldTypes;
            updFieldTypes = updFieldTypes.map((eachFieldType) => {
              const updDefaultValues = eachFieldType.defaultValues;
              return {
                ...eachFieldType,
                defaultValues: {
                  ...updDefaultValues.fieldName,
                },
              };
            });
            setFieldTypeList(updFieldTypes);

            if (data.data.fieldTypes.length > 0) {
              setSelectedProfilID(
                data.data.userProfileFields[selectedProfileItemIndex]?._id ||
                data.data.userProfileFields[data.data.userProfileFields.length - 1]._id
              );
              onCardClick(
                data.data.userProfileFields[selectedProfileItemIndex],
                selectedProfileItemIndex
              );
            }

            data.data.fieldTypes.map((item) => {
              if (item.name === 'Group') {
                setGroupDropdownOptions([...item.fieldTypes]);
                setDefaultGroupDropdownOptions([...item.fieldTypes]);
              }
              return 0;
            });
            data.data.userProfileFields.map((item) => {
              item.userFiledType =
                data.data.fieldTypes.filter((x) => x.id === item.user_profile_field_type).length > 0
                  ? data.data.fieldTypes.filter((x) => x.id === item.user_profile_field_type)[0]
                    .name
                  : '';
              return 0;
            });
            data.data.userProfileFields.map((uItem) => {
              if (
                uItem.userFiledType === 'Radio' ||
                uItem.userFiledType === 'Dropdown' ||
                uItem.userFiledType === 'Checkbox'
              ) {
                const tempArray = [];
                uItem?.optionsForFrontend?.map((item, i) => {
                  const selectedValue =
                    uItem.selectedOptions.length > 0
                      ? uItem.selectedOptions.filter((x) => x === item.name).length > 0
                      : false;
                  const obj = {};
                  obj.id = item.id;
                  obj.value = item.name;
                  obj.isSelected = selectedValue;
                  tempArray.push(obj);
                  return item;
                });
                uItem.optionsForFrontend = tempArray;
              }
              if (uItem.userFiledType === 'Group') {
                let groupDrpOptions = [];
                data.data.fieldTypes.map((gOptions) => {
                  if (gOptions.name === 'Group') {
                    groupDrpOptions = [...gOptions.fieldTypes];
                  }
                  return 0;
                });
                const tempArr = [];
                uItem.properties.fields.map((gItem) => {
                  const obj = {};
                  obj.id = gItem.id;
                  obj.value = gItem.fieldName;
                  obj.isSelected = gItem.isRequired === HUB_CONST.YES;
                  obj.drpOption =
                    groupDrpOptions.filter((x) => x.id === parseInt(gItem.fieldType)).length > 0
                      ? groupDrpOptions.filter((x) => x.id === parseInt(gItem.fieldType))[0]
                      : {};
                  obj.fieldNameLang = gItem.fieldNameLang;
                  tempArr.push(obj);
                  return 0;
                });

                setGroupOptions([...tempArr]);
                setDefaultGroupOptions([...tempArr]);
              }
              return 0;
            });
            const parentField = data.data.userProfileFields?.filter((field) => !field.activeSF)
                                ?.filter((item) => !(isWebinarEventType && item.fieldName === 'QR Code'))
                                ?.filter((item) => !(isWebinarEventType && item.fieldName === 'Attendance Status'));
            const childField = data.data.userProfileFields?.filter((field) => field.activeSF);
            const parentChildFields = parentField.map((field) => {
              const obj = { ...field };
              obj.childFields = [];
              childField?.map((item) => {
                if (obj._id === item.parent) {
                  obj.childFields = [...obj.childFields, item];
                }
              });
              return obj;
            });
            if (isWebinar) {
              // segregating default and prebuilt fields
              const defaultProfileFields = parentChildFields.filter(
                (item) => item.isUseInTicketing === 'YES'
              );
              const preBuiltList = parentChildFields.filter(
                (item) => item.isUseInTicketing !== 'YES' || !item.isUseInTicketing
              );
              setProfileFieldList([...defaultProfileFields]);
              setPreBuiltFieldList([...preBuiltList]);
            } else setProfileFieldList([...parentChildFields]);
            if (isEmpty(defaultField)) {
              setDefaultField({ ...data.data.userProfileFields[selectedProfileItemIndex] });
            } else {
              setDefaultField(defaultField);
            }
            setIconData({ ...iconData, data: data.data.icons });
            if (
              data?.data?.userProfileFields[selectedProfileItemIndex]?.isDisabled === HUB_CONST.YES
            ) {
              if (
                data.data.userProfileFields[selectedProfileItemIndex].fieldName === 'Phone' ||
                data.data.userProfileFields[selectedProfileItemIndex]
                  .user_profile_default_field_type === 4
              ) {
                setDisableField(false);
              } else {
                setDisableField(true);
              }
            } else {
              setDisableField(false);
            }
            setLoadState({ ...loadState, isLoadProfileFields: false });
            setAction('');
            setDefaultSelectedData(data.data.userProfileFields[selectedProfileItemIndex]);
            setEventTicketList(data.data.ticketList);
          }
          break;
        }
        case 'DELETE_PROFILE_FIELDS': {
          if (type === HUB_CONST.SUCCESS) {
            setLoadState({ ...loadState, deleteFiledLoader: false });
            setModal({ isOpen: false, type: 'NONE', data: {} });

            // Selection Change
            const updProIndex = selectedProfileItemIndex > 0 ? selectedProfileItemIndex - 1 : 0;
            setSelectedProfileItemIndex(updProIndex);
            setSelectedProfilID(profileFieldList[updProIndex]._id);
            onCardClick(profileFieldList[updProIndex], updProIndex);
            setProfileFieldList(
              profileFieldList.filter((eachItem) => eachItem._id !== data?.data?.id)
            );

            APICALL(
              'GET_USER_PROFILE_FIELDS',
              reduxData,
              API_CALL_METHODS.GET,
              GET_USER_PROFILE_FIELDS,
              { language: selectedLanguage?.id },
              null,
              APICB
            );
          }
          break;
        }
        case 'UPDATE_USER_PROFILE_FIELDS': {
          if (type === HUB_CONST.SUCCESS) {
            setLoadState({ ...loadState, isUpdateProfileFields: false });
            const index = profileFieldList.findIndex((x) => x._id === defaultField._id);
            if (
              defaultField.userFiledType === 'Radio' ||
              defaultField.userFiledType === 'Dropdown' ||
              defaultField.userFiledType === 'Checkbox'
            ) {
              const tempArray = [];
              defaultField?.optionsForFrontend?.map((item, i) => {
                const obj = {};
                obj.id = item.id;
                obj.value = item.name ? item?.name.trim() : item?.value?.trim();
                obj.isSelected = item.isSelected;
                tempArray.push(obj);
                return item;
              });
              setDefaultField(
                produce(defaultField, (draftState) => {
                  draftState.optionsForFrontend = tempArray;
                })
              );
            }
            if (defaultField?.activeSF) {
              let profileFieldListNew = [];
              profileFieldList.forEach((field) => {
                let obj = { ...field };
                let tempArr = [];
                field?.childFields?.forEach((child) => {
                  let obj1 = { ...child };
                  if (obj1._id === defaultField._id) {
                    obj1 = defaultField;
                  }
                  tempArr = [...tempArr, obj1];
                });
                obj.childFields = tempArr;
                profileFieldListNew = [...profileFieldListNew, obj];
              });
              setProfileFieldList([...profileFieldListNew]);
            } else {
              profileFieldList[index] = defaultField;
              setProfileFieldList([...profileFieldList]);
            }
          }
          APICALL(
            'GET_USER_PROFILE_FIELDS',
            reduxData,
            API_CALL_METHODS.GET,
            GET_USER_PROFILE_FIELDS,
            { language: selectedLanguage?.id },
            null,
            APICB
          );
          break;
        }
        case 'ADD_USER_PROFILE_FIELDS': {
          if (type === 'SUCCESS') {
            setLoadState({ ...loadState, isUpdateProfileFields: true });
            onSaveClose();
          }
          break;
        }
        default:
          break;
      }
    },
    [selectedGroup, profileFieldList, reduxData, iconData, loadState, defaultField]
  );

  const onDrop = React.useCallback(
    (dropResult) => {
      const data = applyDrag(profileFieldList, dropResult);
      setProfileFieldList([...data]);
      const bodyData = {
        data: {
          field_ids: data.map((item) => item._id),
        },
      };
      APICALL(
        'REARRANGE_PROFILE_FIELD_LIST',
        reduxData,
        API_CALL_METHODS.POST,
        REARRANGE_PROFILE_FIELD_LIST,
        null,
        bodyData
      );
    },
    [profileFieldList, APICALL, reduxData]
  );

  const onDropChildElem = React.useCallback(
    (dropResult, index) => {
      let oldData = profileFieldList;
      let dragIem = oldData[index]?.childFields;
      const data = applyDrag(dragIem, dropResult);
      oldData[index].childFields = data;
      setProfileFieldList(oldData);
      const bodyData = {
        data: {
          parent: oldData[index]._id,
          sub_fields_ids: data.map((item) => item._id),
        },
      };
      APICALL(
        'REARRANGE_PROFILE_SUB_FIELD_LIST',
        reduxData,
        API_CALL_METHODS.POST,
        REARRANGE_PROFILE_FIELD_LIST,
        null,
        bodyData
      );
    },
    [profileFieldList, APICALL, reduxData]
  );

  const deleteProfileFieldHandler = React.useCallback(() => {
    setLoadState({ ...loadState, deleteFiledLoader: true });
    const customHeader = {
      fieldId: modalData.data._id,
    };
    APICALL(
      'DELETE_PROFILE_FIELDS',
      reduxData,
      API_CALL_METHODS.POST,
      DELETE_PROFILE_FIELDS,
      { ...customHeader },
      null,
      APICB
    );
  }, [loadState, APICALL, APICB, reduxData, modalData]);

  const deleteOptionsHandler = React.useCallback(() => {
    const defaultFields = { ...modalData.data };
    const i = defaultFields.itemId;
    const optionsLang = [
      ...defaultFields.optionsLang.slice(0, i),
      ...defaultFields.optionsLang.slice(i + 1),
    ];
    let tempChildArr = [];
    optionsLang.forEach((field) => {
      const obj = defaultFields?.childFields?.filter((item) => item.optionId === field._id);
      obj?.forEach((ele) => {
        tempChildArr = [...tempChildArr, ele];
      });
    });

    delete defaultFields.itemId;
    setDefaultField({
      ...defaultFields,
      optionsForFrontend: [
        ...defaultFields.optionsForFrontend.slice(0, i),
        ...defaultFields.optionsForFrontend.slice(i + 1),
      ],
      optionsLang: [
        ...defaultFields.optionsLang.slice(0, i),
        ...defaultFields.optionsLang.slice(i + 1),
      ],
      childFields: tempChildArr,
    });
    setModal({ isOpen: false, data: {}, type: 'NONE' });
  }, [modalData]);

  const ConfirmationPopUpJSON = React.useMemo(() => {
    return {
      DELETE_PROFILE_FIELD: {
        title: 'You are about to delete a profile field.',
        primaryDesc: 'This cannot be undone. Are you sure you want to continue ?',
        secondaryDesc: '',
        Icon: Trash2,
        actionBtn: [
          {
            name: HUB_CONST.CANCEL,
            disabled: loadState.deleteFiledLoader,
            onClick: handelClosePopup,
          },
          {
            name: HUB_CONST.DELETE,
            onClick: deleteProfileFieldHandler,
            type: 'primary',
            isLoading: loadState.deleteFiledLoader,
            loadingLabel: HUB_LABELS.DELETING_LOAD,
          },
        ],
      },
      DELETE_PROFILE_FIELD_OPTION: {
        title: 'Delete Option',
        primaryDesc: 'Deleting this option would also delete the Subfield.',
        secondaryDesc: '',
        Icon: Trash2,
        actionBtn: [
          {
            name: HUB_CONST.CANCEL,
            disabled: loadState.deleteFiledLoader,
            onClick: handelClosePopup,
          },
          {
            name: HUB_CONST.DELETE,
            onClick: deleteOptionsHandler,
            type: 'primary',
            isLoading: loadState.deleteFiledLoader,
            loadingLabel: HUB_LABELS.DELETING_LOAD,
          },
        ],
      },
      DELETE_CHILD_PROFILE_FIELD: {
        title: 'Delete Subfield',
        primaryDesc:
          'Are you sure you want to delete conditional Subfield from current profile field?',
        secondaryDesc: '',
        Icon: Trash2,
        actionBtn: [
          {
            name: HUB_CONST.CANCEL,
            disabled: loadState.deleteFiledLoader,
            onClick: handelClosePopup,
          },
          {
            name: HUB_CONST.DELETE,
            onClick: deleteProfileFieldHandler,
            type: 'primary',
            isLoading: loadState.deleteFiledLoader,
            loadingLabel: HUB_LABELS.DELETING_LOAD,
          },
        ],
      },
    };
  }, [handelClosePopup, loadState, deleteProfileFieldHandler, deleteOptionsHandler]);

  const handleChangeCheckBox = (e, value, defaultFields) => {
    setDefaultField(
      produce(defaultFields, (draftCheckBox) => {
        draftCheckBox[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
      })
    );
  };

  const handleChangeChildCheckBox = (e, value, defaultField) => {
    const obj = { ...defaultField };
    obj[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
    setDefaultField(obj);
  };

  const onHandleSwitchChange = (e, value, defaultFields) => {
    setDefaultField(
      produce(defaultFields, (draftSwitch) => {
        draftSwitch[value] = e.target.checked ? HUB_CONST.YES : HUB_CONST.NO;
      })
    );
  };

  const onHandleEditDisableSwitchChange = (e, value, defaultFields) => {
    setDefaultField(
      produce(defaultFields, (draftSwitch) => {
        draftSwitch[value] = e.target.checked ? HUB_CONST.NO : HUB_CONST.YES;
      })
    );
  };

  const handlePropertiesChange = React.useCallback(
    (e, value, type, defaultFields, fieldType) => {
      const fields = JSON.parse(JSON.stringify(defaultFields));
      if (errObj[e.target.name]) setErrObj({ ...errObj, [e.target.name]: '' });
      if (type === 'checkbox') {
        fields.properties[value] = e.target.checked ? 1 : 0;
        setDefaultField({ ...fields });
      } else {
        if (fieldType === 'file') {
          if (e.target.value === '' || numberRegex.test(e.target.value)) {
            fields.properties[value] = e.target.value;
          }
        } else if (fieldType === 'group') {
          fields.properties[value] = e.target.value;
        } else {
          if (value === 'min' || value === 'max') {
            if (e.target.value === '' || numberRegex.test(e.target.value)) {
              if (fields.userFiledType === 'Text Area') {
                if (e.target.value.length <= 4) {
                  fields.properties.length[value] = e.target.value;
                }
              } else {
                if (e.target.value.length <= 3) {
                  fields.properties.length[value] = e.target.value;
                }
              }
            }
          } else {
            fields.properties.length[value] = e.target.value;
          }
        }
        const updatedData = { ...fields };
        setDefaultField(updatedData);
      }
    },
    [errObj]
  );

  const handleFieldNameChange = React.useCallback(
    (e, value, defaultFields, subChild = {}) => {
      if (errObj[value]) setErrObj({ ...errObj, [value]: '' });
      if (!isEmpty(subChild)) {
        const index = defaultFields?.childFields?.findIndex((field) => field._id === subChild._id);
        setDefaultField(
          produce(defaultFields, (draftDefaultField) => {
            draftDefaultField.childFields[index][value] = e.target.value;
          })
        );
      } else {
        setDefaultField(
          produce(defaultFields, (draftDefaultField) => {
            draftDefaultField[value] = e.target.value;
          })
        );
      }
    },
    [errObj]
  );

  const handleChangeFieldOptionAndType = React.useCallback(
    (value) => {
      const profileFieldListNew = [...profileFieldList];
      profileFieldListNew?.map((prntField) => {
        const obj = { ...prntField };
        const child = [...prntField?.childFields];
        let tmpArr = [];
        child?.forEach((chldField) => {
          let data = { ...chldField };
          if (data._id === value._id) {
            data = value;
          }
          tmpArr = [...tmpArr, data];
        });
        prntField.childFields = tmpArr;
        return obj;
      });
      setProfileFieldList(profileFieldListNew);
    },
    [errObj, profileFieldList]
  );

  const handleChangeDefaultField = React.useCallback((value, field, valueKey = '', id) => {
    if (errObj[valueKey]) setErrObj({ ...errObj, [valueKey]: '' });
    let data = { ...field };
    data.hasOptions = ['Radio', 'Dropdown', 'Checkbox'].includes(data.userFiledType) ? 'YES' : 'NO';
    if (valueKey === 'fieldOption') {
      data.optionId = value._id;
    } else if (valueKey === 'userFiledType') {
      data.userFiledType = value.name;
      data.user_profile_field_type = value.id;
      const nameFieldType = { ...data.fileType };
      nameFieldType.name = value.name;
      data.fileType = nameFieldType;
    } else {
      const optionFE = data.optionsForFrontend?.map((item, index) => {
        let obj = { ...item };
        if (index === id) {
          obj.value = value;
          obj.error = '';
        }
        return obj;
      });
      data.optionsForFrontend = optionFE;
    }
    setDefaultField(data);
    handleChangeFieldOptionAndType(data);
  });

  const onAddOption = React.useCallback(
    (value, defaultFields) => {
      const addNewOptions = { value: '', isSelected: false };
      if (value === 'checkbox') {
        addNewOptions.id = defaultFields.last_option_id + 1;
        setDefaultField(
          produce(defaultFields, (draftLastId) => {
            draftLastId.last_option_id += 1;
            draftLastId.optionsForFrontend.push(addNewOptions);
          })
        );
      } else if (value === 'radio') {
        addNewOptions.id = defaultField.last_option_id + 1;
        setDefaultField(
          produce(defaultFields, (draftLastIdForRadio) => {
            draftLastIdForRadio.last_option_id += 1;
            draftLastIdForRadio.optionsForFrontend.push(addNewOptions);
          })
        );
      } else if (value === 'group') {
        addNewOptions.id = defaultFields.properties.last_option_id + 1;

        addNewOptions.drpOption = groupDropdownOptions[0];
        setGroupOptions(
          produce(groupOptions, (draftGroupData) => {
            draftGroupData.push(addNewOptions);
          })
        );
        setDefaultField(
          produce(defaultFields, (draftForGroup) => {
            draftForGroup.properties.last_option_id = draftForGroup.properties.last_option_id + 1;
          })
        );
      } else if (value === 'dropdown') {
        addNewOptions.id = defaultField.last_option_id + 1;
        setDefaultField(
          produce(defaultFields, (draftLastIdForDropdown) => {
            draftLastIdForDropdown.last_option_id += 1;
            draftLastIdForDropdown.optionsForFrontend.push(addNewOptions);
          })
        );
      }
    },
    [defaultField, groupOptions, groupDropdownOptions]
  );

  const handleFileTypesChecked = React.useCallback(
    (value, defaultField) => {
      const index = defaultField.properties.file_types.findIndex((x) => x.id === value.id);
      const newCheckedValue = produce(defaultField, (draftDefaultFields) => {
        draftDefaultFields.properties.file_types[index].isChecked = !value.isChecked;
      });
      setDefaultField(newCheckedValue);
      const fileIndex = fileTypes.findIndex((x) => x.id === value.id);
      const newFileDataValue = produce(fileTypes, (draftState) => {
        draftState[fileIndex].isChecked = !value.isChecked;
      });
      setFileTypes(newFileDataValue);
    },
    [fileTypes]
  );

  // defaultField, setDefaultField
  const onAttendeeGroupTo = useCallback((e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;
      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.communityGroups = selectedEventList;
      formFieldsClone.isAllCommunityGroups = isChecked ? 'YES' : 'NO';
      setDefaultField(formFieldsClone);
      setAttendeeGroupList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(defaultField.communityGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x._id === item._id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);
      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.communityGroups = list;
      setDefaultField(formFieldsClone);
    }
  });

  const getAttendeeGroupList = React.useCallback(
    (currPage, search = '', isFirstPage = false) => {
      if (!attendeeList.apicallDone) {
        const header = {
          ...headers,
          search: search === '' ? '' : search,
        };
        if (attendeeList.apiDone) {
          setAttendeeList({
            ...attendeeList,
            initLoad: isFirstPage ? true : attendeeList.initLoad,
            loading: !isFirstPage ? true : attendeeList.loading,
            apiDone: false,
          });

          GETAPIWITHCUSTOMDOMAIN(false, EVENT_GROUP_SETTING, source.AS, header, null, CUSTOM_URL)
            .then((resp) => {
              const { data, currentPage, totalPages, totalCount } = resp.data;
              if (resp.status === API_CALL_RESP_CODES.OK) {
                if (data) {
                  const respData = {
                    currentPage,
                    totalcount: totalCount,
                    totalPage: totalPages,
                    search,
                    tempSearch: search,
                    initLoad: false,
                    loading: false,
                    options: [],
                    apiDone: true,
                    apicallDone: true,
                  };
                  if (isFirstPage) {
                    respData.initLoad = false;
                    respData.options = data?.groupsList;
                  } else {
                    respData.totalcount = attendeeList.totalcount;
                    respData.totalPage = attendeeList.totalPage;
                    respData.options = [...attendeeList.options, ...data?.groupsList];
                    respData.loading = false;
                  }
                  setAttendeeList(respData);
                }
              } else {
                setAttendeeList({
                  ...attendeeList,
                  initLoad: false,
                  loading: false,
                  apiDone: true,
                  apicallDone: true,
                });
              }
            })
            .catch(API.catchHandler);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attendeeList]
  );

  const onApplicableTo = useCallback((e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;
      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.ticketingGroups = selectedEventList;
      formFieldsClone.isAllTicketingGroups = isChecked ? 'YES' : 'NO';
      if (formFieldsClone.ticketingGroups.length === 0) {
        formFieldsClone.ticketsMappedWithGroups = [];
        formFieldsClone.isAllTicketsMappedWithGroups = 'NO';
        setTicketList(isChecked ? 'ALL' : '');
      }
      setDefaultField(formFieldsClone);
      setTicketGroupList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(defaultField.ticketingGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);
      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.ticketingGroups = list;
      formFieldsClone.ticketsMappedWithGroups = [];
      formFieldsClone.isAllTicketsMappedWithGroups = 'NO';
      setDefaultField(formFieldsClone);
    }
    setTicketList('');
    setTicketsList({
      ...ticketsList,
      options: [],
    });
  });

  const getTicketsList = React.useCallback(
    (currPage, search = '', isFirstPage = false) => {
      const header = {
        ...headers,
        search: search === '' ? '' : search,
      };
      const apiEndPoint = `${TICKET_GROUP_LIST}?limit=${50}&page=${isFirstPage ? 1 : currPage}`;
      if (sectionList.apiDone) {
        setSectionList({
          ...sectionList,
          initLoad: isFirstPage ? true : sectionList.initLoad,
          loading: !isFirstPage ? true : sectionList.loading,
          apiDone: false,
        });

        GETAPIWITHCUSTOMDOMAIN(false, apiEndPoint, source.AS, header, null, CUSTOM_URL_TICKETS)
          .then((resp) => {
            const { data, current_page, total_page, total_count } = resp.data;
            if (resp.status === API_CALL_RESP_CODES.OK) {
              if (data) {
                const respData = {
                  currentPage: current_page,
                  totalcount: total_count,
                  totalPage: total_page,
                  search,
                  tempSearch: search,
                  initLoad: false,
                  loading: false,
                  options: [],
                  apiDone: true,
                  apicallDone: true,
                };
                if (isFirstPage) {
                  respData.initLoad = false;
                  respData.options = data;
                } else {
                  respData.totalcount = sectionList.totalcount;
                  respData.totalPage = sectionList.totalPage;
                  respData.options = [...sectionList.options, ...data];
                  respData.loading = false;
                }
                setSectionList(respData);
              }
            } else {
              setSectionList({
                ...sectionList,
                initLoad: false,
                loading: false,
                apiDone: true,
                apicallDone: true,
              });
            }
          })
          .catch(API.catchHandler);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionList]
  );

  const onTicketsMapped = useCallback((e, name, val, item, index) => {
    if (name === 'All') {
      const isChecked = e ? e.target.checked : true;

      const selectedEventList = isChecked ? [...item] : [];
      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.ticketsMappedWithGroups = selectedEventList;
      formFieldsClone.isAllTicketsMappedWithGroups = isChecked ? 'YES' : 'NO';

      setDefaultField(formFieldsClone);
      setTicketList(isChecked ? 'ALL' : '');
    } else {
      const list = [...(defaultField.ticketsMappedWithGroups || '')];
      // eslint-disable-next-line no-param-reassign
      index = list.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-unused-expressions
      e.target.checked ? list.push(item) : list.splice(index, 1);

      const formFieldsClone = JSON.parse(JSON.stringify(defaultField));
      formFieldsClone.ticketsMappedWithGroups = list;
      setDefaultField(formFieldsClone);
    }
  });

  const getTicketsLists = React.useCallback(
    (currPage, search = '', isFirstPage = false, groupIds = [], optionsList) => {
      const header = {
        ...headers,
        search: search === '' ? '' : search,
      };
      const apiEndPoint = `${GET_DISCOUNT_TICKETS}?page=${isFirstPage ? 1 : currPage
        }&limit=${PAGE_LIMITS}`;
      if (ticketsList.apiDone) {
        setTicketsList({
          ...ticketsList,
          initLoad: isFirstPage ? true : ticketsList.initLoad,
          loading: !isFirstPage ? true : ticketsList.loading,
          apiDone: false,
          options: optionsList.length > 0 ? optionsList : ticketsList.options,
        });

        const body = {
          data: {
            groupIds: ticketGroupList ? [] : groupIds.map((a) => a.id),
          },
        };

        POSTAPIWITHCUSTOMDOMAIN(false, apiEndPoint, source.AS, header, body, CUSTOM_URL_TICKETS)
          .then((resp) => {
            const { data, currentPage, totalPages, totalCount } = resp.data;
            if (resp.status === API_CALL_RESP_CODES.OK) {
              if (data) {
                const respData = {
                  currentPage,
                  totalcount: totalCount,
                  totalPage: totalPages,
                  search,
                  tempSearch: search,
                  initLoad: false,
                  loading: false,
                  options: [],
                  apiDone: true,
                };
                if (isFirstPage) {
                  respData.initLoad = false;
                  respData.options = data;
                } else {
                  respData.totalcount = ticketsList.totalcount;
                  respData.totalPage = ticketsList.totalPage;
                  respData.options = [...optionsList, ...data];
                  respData.loading = false;
                }
                setTicketsList(respData);
              }
            } else {
              setTicketsList({
                ...ticketsList,
                initLoad: false,
                loading: false,
                apiDone: true,
              });
            }
          })
          .catch(API.catchHandler);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticketGroupList]
  );

  const additionalDetails = (defaultFields, child = -1) => {
    if (child !== -1) {
      return (
        <Grid item xs={12}>
          {defaultFields.showIsRequired === HUB_CONST.YES && (
            <CheckBox2
              label="Mandatory"
              color="primary"
              value={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
              onChange={(e) => handleChangeCheckBox(e, 'isRequiredInTicketing', defaultFields)}
              checked={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
            />
          )}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12}>
          {!isWebinar ? (
            defaultFields.isCommunityFlowNonEditable === 'NO' ||
              ([7, 8, 4].indexOf(defaultFields.user_profile_default_field_type) !== -1 &&
                defaultFields.isCommunityFlowNonEditable === 'YES') ? (
              <>
                {defaultFields?.isDisabled === 'NO' ? (
                  <>
                    <Box mb={2.5} className={classes.divider} />
                    <Box mb={3}>
                      <Typography variant="body1" className={classes.headingTitle} component="h4">
                        Attendee platform
                      </Typography>
                    </Box>
                    {[7, 8].indexOf(defaultFields.user_profile_default_field_type) === -1 && (
                      <Box display="flex" width="100%" mt={2} mb={2}>
                        <SelectCheckBoxDropDown
                          error={errObj.communityGroups}
                          loading={attendeeList.initLoad}
                          totalCount={attendeeList.total_count}
                          loadingMore={attendeeList.loading}
                          name="communityGroups"
                          required
                          hasSearch={false}
                          label="Groups"
                          labelKey="name"
                          valueKey="_id"
                          placeholder="Select"
                          isAllSelected={
                            attendeeGroupList ? true : defaultFields.isAllCommunityGroups === 'YES'
                          }
                          selelctAll={
                            attendeeGroupList ? true : defaultFields.isAllCommunityGroups === 'YES'
                          }
                          isUseValueKey
                          isSelectAlls
                          id="events_list"
                          options={attendeeList.options}
                          value={defaultFields.communityGroups}
                          onChange={(e, name, val, item, index) => {
                            if (errObj.communityGroups)
                              setErrObj({ ...errObj, communityGroups: '' });
                            onAttendeeGroupTo(e, name, val, item, index);
                          }}
                          onDeleteValue={(item, index) => {
                            if (item === 'All') {
                              setAttendeeGroupList('');
                              const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                              formFieldsClone.communityGroups = [];
                              formFieldsClone.isAllCommunityGroups = 'NO';
                              setDefaultField(formFieldsClone);
                            } else {
                              const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                              if (item.name === formFieldsClone.communityGroups[index].name) {
                                formFieldsClone.communityGroups.splice(index, 1);
                                setDefaultField(formFieldsClone);
                              }
                            }
                          }}
                          onOpenDropDown={() => {
                            getAttendeeGroupList(1, '', true);
                          }}
                          onEnter={() => {
                            getAttendeeGroupList(1, attendeeList.search, true);
                          }}
                          onScroll={(event) => {
                            const ele = event.target;
                            if (
                              Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                              attendeeList.currentPage + 1 <= attendeeList.totalPage
                            ) {
                              getAttendeeGroupList(
                                attendeeList.currentPage + 1,
                                attendeeList.search,
                                false
                              );
                            }
                          }}
                          onSearch={(e) => {
                            setSectionList({ ...attendeeList, search: e });
                            if (e === '') {
                              getAttendeeGroupList(1, '', true);
                            }
                          }}
                          isMultiSelect
                          isArrayOfObject
                        />
                      </Box>
                    )}
                  </>
                ) : null}

                <Box mt={3}>
                  {defaultFields.showIsUseInOnoarding === HUB_CONST.YES && (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" component="p">
                        Display this field in onboarding form
                      </Typography>
                      <Box className={classes.switchBtnStyle}>
                        <SwitchField
                          checked={defaultFields.isUseInOnoarding === HUB_CONST.YES}
                          onChange={(e) =>
                            onHandleSwitchChange(e, 'isUseInOnoarding', defaultFields)
                          }
                        />
                      </Box>
                    </Box>
                  )}
                  {defaultFields.showIsShowToOthers === HUB_CONST.YES && !isWebinar && (
                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" component="p">
                        Show this in user Profile to others
                      </Typography>
                      <Box className={classes.switchBtnStyle}>
                        <SwitchField
                          checked={defaultFields.isShowToOthers === HUB_CONST.YES}
                          onChange={(e) => onHandleSwitchChange(e, 'isShowToOthers', defaultFields)}
                        />
                      </Box>
                    </Box>
                  )}
                  {defaultFields.showIsUseInFilter === HUB_CONST.YES && !isWebinar && (
                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" component="p">
                        Filter Attendees based on this field
                      </Typography>
                      <Box className={classes.switchBtnStyle}>
                        <SwitchField
                          checked={defaultFields.isUseInFilter === HUB_CONST.YES}
                          onChange={(e) => onHandleSwitchChange(e, 'isUseInFilter', defaultFields)}
                        />
                      </Box>
                    </Box>
                  )}
                  {defaultFields.showIsShow === HUB_CONST.YES && (
                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" component="p">
                        Show this field in Edit Profile
                      </Typography>
                      <Box className={classes.switchBtnStyle}>
                        <SwitchField
                          checked={defaultFields.isShow === HUB_CONST.YES}
                          onChange={(e) => onHandleSwitchChange(e, 'isShow', defaultFields)}
                        />
                      </Box>
                    </Box>
                  )}
                  {defaultFields?.isDisabled === 'NO' ? (
                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="body2" component="p">
                        Allow attendee to edit the registration form field in the onboarding section
                      </Typography>
                      <Box className={classes.switchBtnStyle}>
                        <SwitchField
                          checked={defaultFields.isOnboardingEditingRestricted === HUB_CONST.NO}
                          onChange={(e) =>
                            onHandleEditDisableSwitchChange(
                              e,
                              'isOnboardingEditingRestricted',
                              defaultFields
                            )
                          }
                        />
                      </Box>
                    </Box>
                  ) : null}

                  {defaultFields.showIsRequired === HUB_CONST.YES && (
                    <Box className={classes.trimWidth}>
                      <CheckBox2
                        label="Mandatory"
                        color="primary"
                        value={defaultFields.isRequired === HUB_CONST.YES}
                        onChange={(e) => handleChangeCheckBox(e, 'isRequired', defaultFields)}
                        checked={defaultFields.isRequired === HUB_CONST.YES}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <>
                {defaultFields.showIsRequired === HUB_CONST.YES && (
                  <Box className={classes.trimWidth}>
                    <CheckBox2
                      label="Mandatory"
                      color="primary"
                      value={defaultFields.isRequired === HUB_CONST.YES}
                      onChange={(e) => handleChangeCheckBox(e, 'isRequired', defaultFields)}
                      checked={defaultFields.isRequired === HUB_CONST.YES}
                    />
                  </Box>
                )}
              </>
            )
          ) : null}
          {eventData.EventData && eventData.EventData.type === EEventFormat.WEBINAR
            ? isWebinar
              ? (defaultFields.isCommunityFlowNonEditable === 'NO' ||
                (defaultFields.isTicketFlowNonEditable === 'NO' && !isEmpty(eventTicketList)) ||
                ([7, 8].indexOf(defaultFields.user_profile_default_field_type) !== -1 &&
                  defaultFields.isCommunityFlowNonEditable === 'YES')) && (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2" component="p">
                      Display this field in registration form
                    </Typography>
                    <Box className={classes.switchBtnStyle}>
                      <SwitchField
                        checked={defaultFields.isUseInTicketing === HUB_CONST.YES}
                        onChange={(e) =>
                          onHandleSwitchChange(e, 'isUseInTicketing', defaultFields)
                        }
                      />
                    </Box>
                  </Box>
                  {defaultFields.isUseInTicketing === HUB_CONST.YES && (
                    <Box mb={2} className={classes.trimWidth}>
                      <CheckBox2
                        label="Mandatory"
                        color="primary"
                        value={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                        onChange={(e) =>
                          handleChangeCheckBox(e, 'isRequiredInTicketing', defaultFields)
                        }
                        checked={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                      />
                    </Box>
                  )}
                </>
              )
              : null
            : defaultFields.isTicketFlowNonEditable === 'NO' &&
            !isEmpty(eventTicketList) &&
            eventData?.EventData?.is_ticketing_from_new_dashboard === 2 && (
              <Box>
                <Box mb={2.5} className={classes.divider} />
                <Box mt={3} mb={3}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    Tickets
                  </Typography>
                </Box>

                <Box mt={3} mb={3}>
                  <Typography mt={2} variant="body1" component="p">
                    Registration form
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2" component="p">
                    Display this field in registration form
                  </Typography>
                  <Box className={classes.switchBtnStyle}>
                    <SwitchField
                      checked={defaultFields.isUseInTicketing === HUB_CONST.YES}
                      onChange={(e) => onHandleSwitchChange(e, 'isUseInTicketing', defaultFields)}
                    />
                  </Box>
                </Box>
                {errObj.countryFieldError ?
                  <Typography component="p" variant="body2" className={classes.errorLabel}>
                    {errObj.countryFieldError}
                  </Typography> : null}
                {defaultFields.isUseInTicketing === HUB_CONST.YES ? (
                  <>
                    <Box display="flex" width="100%" mt={2} mb={2}>
                      <SelectCheckBoxDropDown
                        error={errObj.ticketingGroups}
                        loading={sectionList.initLoad}
                        totalCount={sectionList.total_count}
                        loadingMore={sectionList.loading}
                        name="ticketingGroups"
                        required
                        hasSearch={false}
                        label="Groups"
                        labelKey="name"
                        valueKey="id"
                        placeholder="Select"
                        isAllSelected={
                          ticketGroupList ? true : defaultFields.isAllTicketingGroups === 'YES'
                        }
                        selelctAll={
                          ticketGroupList ? true : defaultFields.isAllTicketingGroups === 'YES'
                        }
                        isUseValueKey
                        isSelectAlls
                        id="events_list"
                        options={sectionList.options}
                        value={defaultFields.ticketingGroups}
                        onChange={(e, name, val, item, index) => {
                          if (errObj.ticketingGroups)
                            setErrObj({ ...errObj, ticketingGroups: '' });
                          onApplicableTo(e, name, val, item, index);
                        }}
                        onDeleteValue={(item, index) => {
                          if (item === 'All') {
                            setTicketGroupList('');
                            const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                            formFieldsClone.ticketingGroups = [];
                            formFieldsClone.isAllTicketingGroups = 'NO';
                            formFieldsClone.ticketsMappedWithGroups = [];
                            formFieldsClone.isAllTicketsMappedWithGroups = 'NO';
                            setTicketList('');
                            setDefaultField(formFieldsClone);
                          } else {
                            const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                            if (item.name === formFieldsClone.ticketingGroups[index].name) {
                              formFieldsClone.ticketingGroups.splice(index, 1);
                              formFieldsClone.ticketsMappedWithGroups = [];
                              formFieldsClone.isAllTicketsMappedWithGroups = 'NO';
                              setTicketList('');
                              setDefaultField(formFieldsClone);
                            }
                          }
                          setTicketsList({
                            ...ticketsList,
                            options: [],
                          });
                        }}
                        onOpenDropDown={() => {
                          getTicketsList(1, '', true);
                        }}
                        onEnter={() => {
                          getTicketsList(1, sectionList.search, true);
                        }}
                        onScroll={(event) => {
                          const ele = event.target;
                          if (
                            Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                            sectionList.currentPage + 1 <= sectionList.totalPage
                          ) {
                            getTicketsList(
                              sectionList.currentPage + 1,
                              sectionList.search,
                              false
                            );
                          }
                        }}
                        onSearch={(e) => {
                          setSectionList({ ...sectionList, search: e });
                          if (e === '') {
                            getTicketsList(1, '', true);
                          }
                        }}
                        isMultiSelect
                        isArrayOfObject
                      />
                    </Box>

                    {(defaultFields?.ticketingGroups?.length !== 0 ||
                      defaultFields?.isAllTicketingGroups === 'YES') && (
                        <Box display="flex" width="100%" mt={2} mb={2}>
                          <SelectCheckBoxDropDown
                            error={errObj.ticketsMappedWithGroups}
                            loading={ticketsList.initLoad}
                            totalCount={ticketsList.total_count}
                            loadingMore={ticketsList.loading}
                            name="ticketsMappedWithGroups"
                            required
                            hasSearch={false}
                            label="Tickets mapped with groups"
                            labelKey="name"
                            valueKey="id"
                            placeholder="Select"
                            isAllSelected={
                              ticketList
                                ? true
                                : defaultFields.isAllTicketsMappedWithGroups === 'YES'
                            }
                            selelctAll={
                              ticketList
                                ? true
                                : defaultFields.isAllTicketsMappedWithGroups === 'YES'
                            }
                            isUseValueKey
                            isSelectAlls
                            id="events_list"
                            options={ticketsList.options}
                            value={defaultFields.ticketsMappedWithGroups}
                            onChange={(e, name, val, item, index) => {
                              if (errObj.ticketsMappedWithGroups)
                                setErrObj({ ...errObj, ticketsMappedWithGroups: '' });
                              onTicketsMapped(e, name, val, item, index);
                            }}
                            onDeleteValue={(item, index) => {
                              if (item === 'All') {
                                setTicketList('');
                                const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                                formFieldsClone.ticketsMappedWithGroups = [];
                                formFieldsClone.isAllTicketsMappedWithGroups = 'NO';
                                setDefaultField(formFieldsClone);
                              } else {
                                const formFieldsClone = JSON.parse(JSON.stringify(defaultFields));
                                if (
                                  item.name === formFieldsClone.ticketsMappedWithGroups[index].name
                                ) {
                                  formFieldsClone.ticketsMappedWithGroups.splice(index, 1);
                                  setDefaultField(formFieldsClone);
                                }
                              }
                            }}
                            onOpenDropDown={() => {
                              getTicketsLists(
                                1,
                                '',
                                true,
                                defaultFields?.ticketingGroups,
                                ticketsList.options
                              );
                            }}
                            onEnter={() => {
                              getTicketsLists(
                                1,
                                ticketsList.search,
                                true,
                                defaultFields?.ticketingGroups,
                                ticketsList.options
                              );
                            }}
                            onScroll={(event) => {
                              const ele = event.target;
                              if (
                                Math.round(ele.scrollTop + ele.clientHeight) >= ele.scrollHeight &&
                                ticketsList.currentPage + 1 <= ticketsList.totalPage
                              ) {
                                getTicketsLists(
                                  ticketsList.currentPage + 1,
                                  ticketsList.search,
                                  false,
                                  defaultFields?.ticketingGroups,
                                  ticketsList.options
                                );
                              }
                            }}
                            isMultiSelect
                            isArrayOfObject
                          />
                        </Box>
                      )}
                  </>
                ) : null}

                {defaultFields.isUseInTicketing === HUB_CONST.YES && (
                  <Box mb={2} className={classes.trimWidth}>
                    <CheckBox2
                      label="Mandatory"
                      color="primary"
                      value={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                      onChange={(e) =>
                        handleChangeCheckBox(e, 'isRequiredInTicketing', defaultFields)
                      }
                      checked={defaultFields.isRequiredInTicketing === HUB_CONST.YES}
                    />
                  </Box>
                )}
              </Box>
            )}
        </Grid>
      );
    }
  };

  const renderedFields = React.useCallback(
    (value) => {
      const defaultFields = value;
      let optionVal = null;
      let optionsSubFieldList = [];
      const profileList = profileFieldList.filter(
        (field) => field?.childFields?.length > 0 && field._id === value.parent
      )?.[0];
      if ((defaultFields?.activeSF || defaultFields?.newField) && !isEmpty(profileList)) {
        const optionsLang = [...profileList.optionsLang];
        optionsLang.map((lang) => {
          lang.name = lang.nameLang[selectedLanguage.id] ?? lang.name;
          if (lang._id === defaultFields.optionId) {
            optionVal = { ...lang }
          }
          return lang;
        });
        optionsSubFieldList = optionsLang;
      }

      switch (value.userFiledType) {
        case 'Text': {
          return (
            <Box
              // className={classes.peopleHeading}
              key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}> */}

                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                    {defaultFields.isDisabled === HUB_CONST.YES && (
                      <Typography variant="body2" component="p">
                        This is a mandatory field and the field name can’t be changed.
                      </Typography>
                    )}
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  {Object.keys(defaultFields.properties).length > 0 &&
                    defaultFields.properties.hasOwnProperty('is_number') ? (
                    <Grid item xs={12}>
                      <CheckBox2
                        label="Is Number"
                        color="primary"
                        value={defaultFields.properties.is_number === 1}
                        onChange={(e) =>
                          handlePropertiesChange(e, 'is_number', 'checkbox', defaultFields)
                        }
                        checked={defaultFields.properties.is_number === 1}
                      />
                    </Grid>
                  ) : (
                    false
                  )}

                  {Object.keys(defaultFields.properties).length > 0 &&
                    Object.keys(defaultFields.properties.length).length > 0 ? (
                    <Grid item xs={12}>
                      <Box mb={1}>
                        <Typography component="label" variant="body2">
                          Character Limit
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputField
                            type="number"
                            onKeyPress={handelKeyPressOnNumberText}
                            minValue={0}
                            maxValue={999}
                            descType
                            descText="Minimum"
                            name="min"
                            error={errObj.min}
                            value={defaultFields.properties.length.min}
                            onChange={(e) =>
                              handlePropertiesChange(e, 'min', 'text', defaultFields)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputField
                            type="number"
                            onKeyPress={handelKeyPressOnNumberText}
                            minValue={0}
                            maxValue={999}
                            descType
                            descText="Maximum"
                            name="max"
                            error={errObj.max}
                            value={defaultFields.properties.length.max}
                            onChange={(e) =>
                              handlePropertiesChange(e, 'max', 'text', defaultFields)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    false
                  )}
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Text Area': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {Object.keys(defaultFields.properties).length > 0 &&
                      Object.keys(defaultFields.properties.length).length > 0 ? (
                      <>
                        <Box mb={1}>
                          <Typography component="label" variant="body2">
                            Length Limit
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <InputField
                              type="number"
                              onKeyPress={handelKeyPressOnNumberTextArea}
                              name="min"
                              minValue={0}
                              maxValue={4000}
                              error={errObj.min}
                              descType
                              descText="Minimum"
                              value={defaultFields.properties.length.min}
                              onChange={(e) =>
                                handlePropertiesChange(e, 'min', 'text', defaultFields)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <InputField
                              type="number"
                              onKeyPress={handelKeyPressOnNumberTextArea}
                              name="max"
                              minValue={0}
                              maxValue={4000}
                              error={errObj.max}
                              descType
                              descText="Maximum"
                              value={defaultFields.properties.length.max}
                              onChange={(e) =>
                                handlePropertiesChange(e, 'max', 'text', defaultFields)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      false
                    )}
                  </Grid>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Date': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Link': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      label="Field Name"
                      required
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Number': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'File': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <CheckBox2
                      label="File Types"
                      color="primary"
                      options={
                        fileTypes.length > 0 ? fileTypes : defaultFields.properties.file_types
                      }
                      onChange={(value) => {
                        handleFileTypesChecked(value, defaultFields);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      label="Input Maximum File Size in MB"
                      disabled
                      error={errObj.file_size}
                      name="file_size"
                      value={defaultFields.properties.max_file_size}
                      onChange={(e) => {
                        handlePropertiesChange(e, 'max_file_size', 'text', defaultFields, 'file');
                      }}
                      placeholder=""
                      descText="Value cannot be greater than 5"
                      descType
                    />
                  </Grid>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Dropdown': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  {defaultFields.user_profile_default_field_type === 9 ? (
                    <Grid item xs={12}>
                      <Box position="relative" display="flex">
                        <Typography
                          variant="body2"
                          className={`${classes.headingTitle}`}
                          component="label">
                          Options
                        </Typography>
                        <Typography component="span">
                          <Box className={classes.stateTip}>
                            <CustomToolTip
                              isFabIcon
                              title="Select to set default values for this field"
                              placement="left"
                            />
                          </Box>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="p" className={classes.warningBlock}>
                        List of all the countries will be displayed
                      </Typography>
                    </Grid>
                  ) : defaultFields.user_profile_default_field_type === 10 ? (
                    <Grid item xs={12}>
                      <Box position="relative" display="flex">
                        <Typography
                          variant="body2"
                          className={`${classes.headingTitle}`}
                          component="label">
                          Options
                          <Box className={classes.stateTip}>
                            <CustomToolTip
                              isFabIcon
                              title="Select to set default values for this field"
                              placement="left"
                            />
                          </Box>
                        </Typography>
                      </Box>
                      <Typography variant="caption" component="p" className={classes.warningBlock}>
                        List of 'State options' will be displayed based on 'Country' chosen. If the
                        'Country' field is hidden, the state field will also be hidden.
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <OptionList
                        label="Options"
                        descType="TOOLTIP"
                        descText="Select to set default values for this field."
                        showBothIcon
                        isMultiple
                        onChange={(value, i) => {
                          setErrObj({ ...errObj, blank_text: '' });
                          setDefaultField(
                            produce(defaultFields, (draftStateDropdown) => {
                              draftStateDropdown.optionsForFrontend[i].value = value;
                              draftStateDropdown.optionsForFrontend[i].error = '';
                              if (
                                draftStateDropdown?.optionsLang &&
                                draftStateDropdown.optionsLang?.[i]?.name
                              ) {
                                draftStateDropdown.optionsLang[i].name = value;
                              }
                            })
                          );
                          if (defaultField?.activeSF || defaultField?.newField) {
                            handleChangeDefaultField(value, defaultField, '', i);
                          }
                        }}
                        addOptionBtnLabel="ADD OPTION"
                        options={defaultFields.optionsForFrontend}
                        placeholder="Enter option value"
                        checkBtn={(i, selected) => {
                          setDefaultField(
                            produce(defaultFields, (draftStateDropdownCheckBtn) => {
                              draftStateDropdownCheckBtn.optionsForFrontend[i].isSelected =
                                !selected;
                              const value = draftStateDropdownCheckBtn.optionsForFrontend.filter(
                                (x) => x !== draftStateDropdownCheckBtn.optionsForFrontend[i]
                              );
                              if (value && value.length > 0) {
                                value.map((item) => {
                                  item.isSelected = false;
                                  return 0;
                                });
                              }
                            })
                          );
                        }}
                        optionDisabled={defaultFields.isDisabledOptions === HUB_CONST.YES}
                        addOptionListAction={defaultFields.isDisabledOptions !== HUB_CONST.YES}
                        deleteBtn={(i) => {
                          setModal({
                            isOpen: true,
                            type: 'DELETE_PROFILE_FIELD_OPTION',
                            data: { ...defaultFields, itemId: i },
                          });
                        }}
                        addOption={() => onAddOption('dropdown', defaultFields)}
                        clearBtn={() => {
                          setDefaultField(
                            produce(defaultFields, (draftClearOption) => {
                              draftClearOption.optionsForFrontend.map((item) => {
                                item.isSelected = false;
                                return 0;
                              });
                            })
                          );
                        }}
                        isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      />
                    </Grid>
                  )}
                  {defaultFields.user_profile_default_field_type === 9 ||
                    defaultFields.user_profile_default_field_type === 10 ? (
                    false
                  ) : (
                    <Grid item xs={8}>
                      {Object.keys(defaultFields.properties).length > 0 &&
                        defaultFields.properties.hasOwnProperty('include_other_option') ? (
                        <CheckBox2
                          label="Show Other Option"
                          color="primary"
                          value={defaultFields.properties.include_other_option === 1}
                          onChange={(e) =>
                            handlePropertiesChange(
                              e,
                              'include_other_option',
                              'checkbox',
                              defaultFields
                            )
                          }
                          checked={defaultFields.properties.include_other_option === 1}
                        />
                      ) : (
                        false
                      )}
                      {defaultFields.isRequired === HUB_CONST.YES || defaultFields?.activeSF ? (
                        false
                      ) : (
                        <CheckBox2
                          label="Include Blank Option"
                          color="primary"
                          value={defaultFields.properties.include_blank === 1}
                          onChange={(e) =>
                            handlePropertiesChange(e, 'include_blank', 'checkbox', defaultFields)
                          }
                          checked={defaultFields.properties.include_blank === 1}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
                {defaultFields?.childFields?.length > 0 && (
                  <Grid item xs={12}>
                    <Box mt={2.5} mb={2.5} className={classes.divider} />
                    <Box mb={3}>
                      <Typography variant="body1" className={classes.headingTitle} component="h4">
                        Subfield
                      </Typography>
                    </Box>
                    {defaultFields?.childFields?.map((field) => {
                      if (field.fieldName !== '') {
                        return (
                          <Box mb={2}>
                            <InputField
                              isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                              placeholder="Name"
                              value={field.fieldName}
                              onChange={(e) => {
                                handleFieldNameChange(e, 'fieldName', defaultField, field);
                              }}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Checkbox': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        value={optionVal || ''}
                        error={errObj.fieldOption}
                        options={optionsSubFieldList}
                        isArrayOfObject
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldType"
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        error={errObj.userFiledType}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <OptionList
                      label="Options"
                      descType="TOOLTIP"
                      descText="Select to set default values for this field."
                      showBothIcon
                      defaultOptionsLength={2}
                      isMultiple
                      isMultiSelect
                      optionDisabled={defaultFields.isDisabledOptions === HUB_CONST.YES}
                      onChange={(value, i) => {
                        setErrObj({ ...errObj, blank_text: '' });
                        setDefaultField(
                          produce(defaultFields, (draftStateDropdown) => {
                            draftStateDropdown.optionsForFrontend[i].value = value;
                            draftStateDropdown.optionsForFrontend[i].error = '';
                            if (
                              draftStateDropdown?.optionsLang &&
                              draftStateDropdown.optionsLang?.[i]?.name
                            ) {
                              draftStateDropdown.optionsLang[i].name = value;
                            }
                          })
                        );
                        if (defaultField?.activeSF || defaultField?.newField) {
                          handleChangeDefaultField(value, defaultField, '', i);
                        }
                      }}
                      addOptionListAction={defaultFields.isDisabledOptions !== HUB_CONST.YES}
                      addOptionBtnLabel="ADD OPTION"
                      options={defaultFields.optionsForFrontend}
                      placeholder="Enter option value"
                      handleCheckBox={(i, selected) => {
                        const newData = produce(defaultFields, (draftStateForCheckbox) => {
                          draftStateForCheckbox.optionsForFrontend[i].isSelected = !selected;
                          draftStateForCheckbox.optionsForFrontend[i].isRequired = !selected
                            ? HUB_CONST.YES
                            : HUB_CONST.NO;
                        });
                        setDefaultField({ ...newData });
                      }}
                      deleteBtn={(i) => {
                        setModal({
                          isOpen: true,
                          type: 'DELETE_PROFILE_FIELD_OPTION',
                          data: { ...defaultFields, itemId: i },
                        });
                      }}
                      addOption={() => onAddOption('checkbox', defaultFields)}
                      clearBtn={() => {
                        setDefaultField(
                          produce(defaultFields, (draftClear) => {
                            draftClear.optionsForFrontend.map((item) => {
                              item.isSelected = false;
                              return 0;
                            });
                          })
                        );
                      }}
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    {Object.keys(defaultFields.properties).length > 0 &&
                      defaultFields.properties.hasOwnProperty('include_other_option') ? (
                      <CheckBox2
                        label="Show Other Option"
                        color="primary"
                        value={defaultFields.properties.include_other_option === 1}
                        onChange={(e) =>
                          handlePropertiesChange(
                            e,
                            'include_other_option',
                            'checkbox',
                            defaultFields
                          )
                        }
                        checked={defaultFields.properties.include_other_option === 1}
                      />
                    ) : (
                      false
                    )}
                  </Grid>
                </Grid>
                {defaultFields?.childFields?.length > 0 && (
                  <Grid item xs={12}>
                    <Box mt={2.5} mb={2.5} className={classes.divider} />
                    <Box mb={3}>
                      <Typography variant="body1" className={classes.headingTitle} component="h4">
                        Subfield
                      </Typography>
                    </Box>
                    {defaultFields?.childFields?.map((field) => {
                      if (field.fieldName !== '') {
                        return (
                          <Box mb={2}>
                            <InputField
                              isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                              placeholder="Name"
                              value={field.fieldName}
                              onChange={(e) => {
                                handleFieldNameChange(e, 'fieldName', defaultField, field);
                              }}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Radio': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        options={optionsSubFieldList}
                        isArrayOfObject
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      label="Field Name"
                      required
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldType"
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        error={errObj.userFiledType}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <OptionList
                      label="Options"
                      descType="TOOLTIP"
                      descText="Select to set default values for this field."
                      showBothIcon
                      isMultiple
                      onChange={(value, i) => {
                        setErrObj({ ...errObj, blank_text: '' });
                        setDefaultField(
                          produce(defaultFields, (draftStateDropdown) => {
                            draftStateDropdown.optionsForFrontend[i].value = value;
                            draftStateDropdown.optionsForFrontend[i].error = '';
                            if (
                              draftStateDropdown?.optionsLang &&
                              draftStateDropdown.optionsLang?.[i]?.name
                            ) {
                              draftStateDropdown.optionsLang[i].name = value;
                            }
                          })
                        );
                        if (defaultField?.activeSF || defaultField?.newField) {
                          handleChangeDefaultField(value, defaultField, '', i);
                        }
                      }}
                      optionDisabled={defaultFields.isDisabledOptions === HUB_CONST.YES}
                      addOptionBtnLabel="ADD OPTION"
                      options={defaultFields.optionsForFrontend}
                      placeholder="Enter option value"
                      checkBtn={(i, selected) => {
                        setDefaultField(
                          produce(defaultFields, (draftCheckBtn) => {
                            draftCheckBtn.optionsForFrontend[i].isSelected = !selected;
                            const value = draftCheckBtn.optionsForFrontend.filter(
                              (x) => x !== draftCheckBtn.optionsForFrontend[i]
                            );
                            if (value && value.length > 0) {
                              value.map((item) => {
                                item.isSelected = false;
                                return 0;
                              });
                            }
                          })
                        );
                      }}
                      addOptionListAction={defaultFields.isDisabledOptions !== HUB_CONST.YES}
                      deleteBtn={(i) => {
                        setModal({
                          isOpen: true,
                          type: 'DELETE_PROFILE_FIELD_OPTION',
                          data: { ...defaultFields, itemId: i },
                        });
                      }}
                      addOption={() => onAddOption('radio', defaultFields)}
                      clearBtn={() => {
                        setDefaultField(
                          produce(defaultFields, (draftClearData) => {
                            draftClearData.optionsForFrontend.map((item) => {
                              item.isSelected = false;
                              return 0;
                            });
                          })
                        );
                      }}
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    {Object.keys(defaultFields.properties).length > 0 &&
                      defaultFields.properties.hasOwnProperty('include_other_option') ? (
                      <CheckBox2
                        label="Show Other Option"
                        color="primary"
                        value={defaultFields.properties.include_other_option === 1}
                        onChange={(e) =>
                          handlePropertiesChange(
                            e,
                            'include_other_option',
                            'checkbox',
                            defaultFields
                          )
                        }
                        checked={defaultFields.properties.include_other_option === 1}
                      />
                    ) : (
                      false
                    )}
                  </Grid>
                </Grid>
                {defaultFields?.childFields?.length > 0 && (
                  <Grid item xs={12}>
                    <Box mt={2.5} mb={2.5} className={classes.divider} />
                    <Box mb={3}>
                      <Typography variant="body1" className={classes.headingTitle} component="h4">
                        Subfield
                      </Typography>
                    </Box>
                    {defaultFields?.childFields?.map((field) => {
                      if (field.fieldName !== '') {
                        return (
                          <Box mb={2}>
                            <InputField
                              isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                              placeholder="Name"
                              value={field.fieldName}
                              onChange={(e) => {
                                handleFieldNameChange(e, 'fieldName', defaultField, field);
                              }}
                            />
                          </Box>
                        );
                      }
                    })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'Group': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  {(defaultFields?.activeSF || defaultFields?.newField) && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Parent Field Options"
                        name="ParentFieldOptions"
                        error={errObj.fieldOption}
                        value={optionVal || ''}
                        isArrayOfObject
                        options={optionsSubFieldList}
                        required
                        label="Parent Field Options"
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'fieldOption');
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  {defaultFields?.newField && (
                    <Grid item xs={12}>
                      <SelectDropDown
                        labelKey="name"
                        valueKey="name"
                        placeholder="Select Field Type"
                        name="FieldOptions"
                        error={errObj.userFiledType}
                        value={
                          defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                        }
                        options={fieldTypeList}
                        onChange={(value) => {
                          handleChangeDefaultField(value, defaultField, 'userFiledType');
                        }}
                        isArrayOfObject
                        required
                        label="Field Type"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Box mb={1}>
                        <Typography component="label" variant="body2">
                          Length Limit
                        </Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputField
                            type="number"
                            onKeyPress={handelKeyPressOnNumberText}
                            minValue={0}
                            maxValue={999}
                            descType
                            descText="Minimum"
                            name="min"
                            error={errObj.min}
                            value={defaultFields.properties.length.min}
                            onChange={(e) =>
                              handlePropertiesChange(e, 'min', 'text', defaultFields)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputField
                            type="number"
                            onKeyPress={handelKeyPressOnNumberText}
                            minValue={0}
                            maxValue={999}
                            descType
                            descText="Maximum"
                            name="max"
                            error={errObj.max}
                            value={defaultFields.properties.length.max}
                            onChange={(e) =>
                              handlePropertiesChange(e, 'max', 'text', defaultFields)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <OptionList
                      label="Fields"
                      descType="TOOLTIP"
                      descText="Select to set default values for this field."
                      showBothIcon
                      defaultOptionsLength={1}
                      isMultiple
                      addOptionListAction
                      isSelectOption
                      dropDownProps={groupDropdownOptions}
                      isMultiSelect
                      handleDropDown={(value, dataValue, i) => {
                        setGroupOptions(
                          produce(groupOptions, (draftGroupChange) => {
                            draftGroupChange[i].drpOption = value;
                          })
                        );
                      }}
                      onChange={(value, i) => {
                        setErrObj({ ...errObj, blank_text: '' });
                        setGroupOptions(
                          produce(groupOptions, (draftGroupChangeValue) => {
                            draftGroupChangeValue[i].value = value;
                            draftGroupChangeValue[i].error = '';
                          })
                        );
                      }}
                      addOptionBtnLabel="ADD Field"
                      options={groupOptions}
                      placeholder="Enter New field"
                      handleCheckBox={(i, selected) => {
                        setGroupOptions(
                          produce(groupOptions, (draftGroupCheckbox) => {
                            draftGroupCheckbox[i].isSelected = !selected;
                          })
                        );
                      }}
                      deleteBtn={(i) => setGroupOptions([...groupOptions.slice(0, i)])}
                      isGroup
                      addOption={() => onAddOption('group', defaultFields)}
                      clearBtn={() => {
                        setGroupOptions(
                          produce(groupOptions, (draftGroupClear) => {
                            draftGroupClear.map((item) => {
                              item.isSelected = false;
                              return 0;
                            });
                          })
                        );
                      }}
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      type="text"
                      label="Field Type"
                      error={errObj.field_type}
                      placeholder="Field Type"
                      name="field_type_name"
                      value={defaultFields.properties.field_type_name}
                      onChange={(e) =>
                        handlePropertiesChange(
                          e,
                          'field_type_name',
                          'group',
                          defaultFields,
                          'group'
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      type="text"
                      label="Button Label"
                      error={errObj.button_label}
                      placeholder="Button Label"
                      name="button_label"
                      value={defaultFields.properties.button_label}
                      onChange={(e) =>
                        handlePropertiesChange(e, 'button_label', 'group', defaultFields, 'group')
                      }
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                    />
                  </Grid>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        case 'new field': {
          return (
            <Box key={`profile-fields-${selectedProfileID}`}>
              {eventLanguages && eventLanguages.length > 1 ? (
                <>
                  <LanguageBox
                    mb={1}
                    className={classes.peopleHeading}
                    languages={eventLanguages}
                    selectedLanguage={selectedLanguage}
                    onLanguageChange={setSelectedLanguage}>
                    &nbsp;
                  </LanguageBox>
                  <Box pb={2} className={classes.appSectionHeading}>
                    <Typography variant="body1" className={classes.headingTitle} component="h4">
                      {defaultFields.fieldName}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box pb={2} className={classes.appSectionHeading}>
                  <Typography variant="body1" className={classes.headingTitle} component="h4">
                    {defaultFields.fieldName}
                  </Typography>
                </Box>
              )}
              <Box py={2.5} className={classes.appRightBlockContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectDropDown
                      labelKey="name"
                      valueKey="name"
                      placeholder="Parent Field Options"
                      name="ParentFieldOptions"
                      error={errObj.fieldOption}
                      value={optionVal || ''}
                      isArrayOfObject
                      options={optionsSubFieldList}
                      required
                      label="Parent Field Options"
                      onChange={(value) => {
                        handleChangeDefaultField(value, defaultField, 'fieldOption');
                      }}
                    />
                  </Grid>
                  {/* )} */}
                  <Grid item xs={12}>
                    <InputField
                      isLanguageSupport={eventLanguages && eventLanguages.length > 1}
                      label="Field Name"
                      required
                      error={errObj.fieldName}
                      disabled={defaultFields.isDisabled === HUB_CONST.YES}
                      placeholder="Name"
                      value={defaultFields.fieldName}
                      onChange={(e) => {
                        handleFieldNameChange(e, 'fieldName', defaultFields);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectDropDown
                      labelKey="name"
                      valueKey="name"
                      placeholder="Select Field Type"
                      name="FieldOptions"
                      error={errObj.userFiledType}
                      value={
                        defaultFields?.fileType?.name?.length > 0 ? defaultFields?.fileType : ''
                      }
                      options={fieldTypeList}
                      onChange={(value) => {
                        handleChangeDefaultField(value, defaultField, 'userFiledType');
                      }}
                      isArrayOfObject
                      required
                      label="Field Type"
                    />
                  </Grid>
                  {additionalDetails(
                    defaultFields,
                    defaultFields?.activeSF || defaultFields?.newField ? 1 : -1
                  )}
                </Grid>
              </Box>
            </Box>
          );
        }
        default:
          break;
      }
    },
    [
      classes.peopleHeading,
      classes.headingTitle,
      classes.switchBtnStyle,
      classes.warningBlock,
      errObj,
      additionalDetails,
      handleFieldNameChange,
      handlePropertiesChange,
      fileTypes,
      handleFileTypesChecked,
      onAddOption,
      groupDropdownOptions,
      groupOptions,
    ]
  );

  const onSaveClose = () => {
    setAddIcon('');
    setAddField(false);
    setLoadState({ ...loadState, isLoadProfileFields: true });
    setDefaultField({});
    setProfileFieldList([]);
    if (isWebinar) setAddFormFields(false);
    APICALL(
      'GET_USER_PROFILE_FIELDS',
      reduxData,
      API_CALL_METHODS.GET,
      GET_USER_PROFILE_FIELDS,
      { language: selectedLanguage?.id },
      null,
      APICB
    );
  };

  const handleValidations = () => {
    let errObj = '';
    if (!errObj) errObj = {};
    let valid = true;
    if (defaultField?.activeSF || defaultField?.newField) {
      if (defaultField.fieldName === '' || defaultField.fieldName.trim() === '') {
        errObj.fieldOption = 'Field option is required';
      }
      if (defaultField?.parent && !defaultField?.optionId) {
        errObj.fieldOption = "Child Field option can't be empty";
      }
      if (
        defaultField.userFiledType === '' ||
        defaultField.userFiledType.trim() === '' ||
        defaultField.userFiledType === 'new field'
      ) {
        errObj.userFiledType = 'Field type is required';
      }
    }
    if (defaultField.fieldName === '' || defaultField.fieldName.trim() === '') {
      errObj.fieldName = 'Field name is required';
    }
    if (
      defaultField?.communityGroups?.length === 0 &&
      defaultField.isUseInOnoarding === 'YES' &&
      !(defaultField.isAllCommunityGroups === 'YES')
    ) {
      errObj.communityGroups = 'Group is required';
    }

    const countryIndex = profileFieldList.findIndex((x) => x.user_profile_default_field_type === 9)
    if (countryIndex && defaultField?.user_profile_default_field_type === 10 &&
      defaultField?.isUseInTicketing === 'YES' &&
      profileFieldList[countryIndex]?.isUseInTicketing === "NO" && !isWebinar) {
      errObj.stateFieldError = 'You must enable the Country field in the registration form to display the corresponding State field';
    }

    const stateIndex = profileFieldList.findIndex((x) => x.user_profile_default_field_type === 10)
    if (!isWebinar && stateIndex && defaultField?.user_profile_default_field_type === 9 && defaultField?.isUseInTicketing === 'NO' && profileFieldList[stateIndex]?.isUseInTicketing === 'YES') {
      errObj.countryFieldError = 'To not display the Country field, disable the State field from the registration form'
    }
    if (
      defaultField?.ticketingGroups?.length === 0 &&
      defaultField.showIsUseInTicketing === 'YES' &&
      defaultField.isUseInTicketing === 'YES' &&
      eventData?.EventData?.is_ticketing_from_new_dashboard === 2 &&
      !(defaultField.isAllTicketingGroups === 'YES')
    ) {
      errObj.ticketingGroups = 'Group is required';
    }
    if (
      defaultField?.ticketsMappedWithGroups?.length === 0 &&
      defaultField.showIsUseInTicketing === 'YES' &&
      defaultField.isUseInTicketing === 'YES' &&
      eventData?.EventData?.is_ticketing_from_new_dashboard === 2 &&
      !(defaultField.isAllTicketsMappedWithGroups === 'YES')
    ) {
      errObj.ticketsMappedWithGroups = 'Tickets mapped with groups  is required';
    }

    if (defaultField.isCommunityFlowNonEditable === 'YES') {
      errObj.communityGroups = '';
    }
    if (defaultField.isTicketFlowNonEditable === 'YES') {
      errObj.ticketingGroups = '';
      errObj.ticketsMappedWithGroups = '';
    }

    switch (defaultField.userFiledType) {
      case 'Text':
        if (Object.keys(defaultField.properties).length > 0) {
          if (
            defaultField.properties.length.min === '' ||
            defaultField.properties.length.max === ''
          ) {
            if (defaultField.properties.length.min === '') {
              errObj.min = 'Min length is required';
            }
            if (defaultField.properties.length.max === '') {
              errObj.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(defaultField.properties.length.min) >
              parseInt(defaultField.properties.length.max)
            ) {
              errObj.min = 'Invalid length limits.';
            }
          }
        }
        break;
      case 'Text Area':
        if (Object.keys(defaultField.properties).length > 0) {
          if (
            defaultField.properties.length.min === '' ||
            defaultField.properties.length.max === ''
          ) {
            if (defaultField.properties.length.min === '') {
              errObj.min = 'Min length is required';
            }
            if (defaultField.properties.length.max === '') {
              errObj.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(defaultField.properties.length.min) >
              parseInt(defaultField.properties.length.max)
            ) {
              errObj.min = 'Invalid length limits.';
            } else {
              if (parseInt(defaultField.properties.length.min) > 4000) {
                errObj.min = "Limit can't be greater than 4000";
              }
              if (parseInt(defaultField.properties.length.max) > 4000) {
                errObj.max = "Limit can't be greater than 4000";
              }
            }
          }
        }
        break;
      case 'Dropdown':
        if (defaultField.optionsForFrontend) {
          setDefaultField(
            produce(defaultField, (draftStateDropdownData) => {
              const lookup = draftStateDropdownData.optionsForFrontend.reduce((item, e) => {
                item[e?.value] = ++item[e?.value] || '';
                return item;
              }, {});
              const duplicates = draftStateDropdownData.optionsForFrontend.filter(
                (e) => lookup[e?.value]
              );
              draftStateDropdownData.optionsForFrontend.map((item) => {
                if (
                  item.value.trim() === '' ||
                  duplicates.length > 0 ||
                  item.value.trim() === 'Other'
                ) {
                  if (item.value.trim() === 'Other') {
                    item.error = "Please use the Toggle to show 'Other' as Option ";
                    errObj.blank_text = "Option/s can't be 'Other'.";
                  }
                  duplicates.forEach((duplicateItem) => {
                    if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                      item.error = "Option/s can't be duplicate.";
                      errObj.blank_text = "Option/s can't be duplicate.";
                    } else if (item.value.trim() === '') {
                      item.error = 'Please enter option';
                      errObj.blank_text = "Option/s can't be blank.";
                    }
                  });
                  if (isEmpty(item.value)) {
                    item.error = 'Option cant be empty';
                    errObj.blank_text = "Option/s can't be blank.";
                  }
                }
                return 0;
              });
            })
          );
        }
        break;
      case 'Radio':
        setDefaultField(
          produce(defaultField, (draftStateRadioData) => {
            const lookup = draftStateRadioData.optionsForFrontend.reduce((item, e) => {
              item[e?.value] = ++item[e?.value] || '';
              return item;
            }, {});
            const duplicates = draftStateRadioData.optionsForFrontend.filter(
              (e) => lookup[e?.value]
            );
            draftStateRadioData.optionsForFrontend.map((item) => {
              if (
                item.value.trim() === '' ||
                duplicates.length > 0 ||
                item.value.trim() === 'Other'
              ) {
                if (
                  item.value.trim() === 'Other' &&
                  defaultField?.user_profile_default_field_type !== 5
                ) {
                  item.error = "Please use the Toggle to show 'Other' as Option ";
                  errObj.blank_text = "Option/s can't be 'Other'.";
                }
                duplicates.forEach((duplicateItem) => {
                  if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                    item.error = "Option/s can't be duplicate.";
                    errObj.blank_text = "Option/s can't be duplicate.";
                  } else if (item.value.trim() === '') {
                    item.error = 'Please enter option';
                    errObj.blank_text = "Option/s can't be blank.";
                  }
                });
                if (isEmpty(item.value)) {
                  item.error = 'Option cant be empty';
                  errObj.blank_text = "Option/s can't be blank.";
                }
              }
              return 0;
            });
          })
        );
        break;
      case 'Checkbox':
        setDefaultField(
          produce(defaultField, (draftStateCheckBoxData) => {
            const lookup = draftStateCheckBoxData.optionsForFrontend.reduce((item, e) => {
              item[e?.value] = ++item[e?.value] || '';
              return item;
            }, {});
            const duplicates = draftStateCheckBoxData.optionsForFrontend.filter(
              (e) => lookup[e?.value]
            );
            draftStateCheckBoxData.optionsForFrontend.map((item) => {
              if (
                item.value.trim() === '' ||
                duplicates.length > 0 ||
                item.value.trim() === 'Other'
              ) {
                if (item.value.trim() === 'Other') {
                  item.error = "Please use the Toggle to show 'Other' as Option ";
                  errObj.blank_text = "Option/s can't be 'Other'.";
                }
                duplicates.forEach((duplicateItem) => {
                  if (item?.value === duplicateItem?.value && item.value.trim() !== '') {
                    item.error = "Option/s can't be duplicate.";
                    errObj.blank_text = "Option/s can't be duplicate.";
                  } else if (item.value.trim() === '') {
                    item.error = 'Please enter option';
                    errObj.blank_text = "Option/s can't be blank.";
                  }
                });
                if (isEmpty(item.value)) {
                  item.error = 'Option cant be empty';
                  errObj.blank_text = "Option/s can't be blank.";
                }
              }
              return 0;
            });
          })
        );
        break;
      case 'Group':
        if (Object.keys(defaultField.properties).length > 0) {
          if (
            defaultField.properties.length.min === '' ||
            defaultField.properties.length.max === ''
          ) {
            if (defaultField.properties.length.min === '') {
              errObj.min = 'Min length is required';
            }
            if (defaultField.properties.length.max === '') {
              errObj.max = 'Max length is required';
            }
          } else {
            if (
              parseInt(defaultField.properties.length.min) >
              parseInt(defaultField.properties.length.max)
            ) {
              errObj.min = 'Invalid length limits.';
            }
          }
        }
        setGroupOptions(
          produce(groupOptions, (draftGroupData) => {
            draftGroupData.map((item) => {
              if (item.value === '') {
                item.error = 'Please enter option';
                errObj.blank_text = "Field name can't be blank.";
              }
            });
          })
        );
        if (defaultField.properties.field_type_name === '') {
          errObj.field_type = "Field Type can't be blank.";
        }
        if (defaultField.properties.button_label === '') {
          errObj.button_label = "Button Label can't be blank.";
        }
        break;
      case 'File':
        if (defaultField.properties.max_file_size > defaultField.properties.allowed_max_file_size) {
          errObj.file_size = `Value can't be greater than ${defaultField.properties.allowed_max_file_size}`;
        }
        if (defaultField.properties.max_file_size === '') {
          errObj.file_size = `Value can't be blank`;
        }
        break;
      default:
        break;
    }

    Object.values(errObj).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return !valid ? errObj : false;
  };

  const getChildFields = useCallback((subField) => {
    let getTempArray = [];
    subField?.map((child, ind) => {
      const formatData = JSON.parse(JSON.stringify(child));

      let selectedValue = [];
      formatData.position = ind + 1;
      if (
        !(
          formatData.userFiledType === 'Dropdown' ||
          formatData.userFiledType === 'Radio' ||
          formatData.userFiledType === 'Checkbox'
        )
      ) {
        delete formatData.optionsForFrontend;
      }
      if (formatData.userFiledType === 'Dropdown' || formatData.userFiledType === 'Radio') {
        if (defaultField.optionsForFrontend) {
          selectedValue = defaultField.optionsForFrontend.filter((x) => x.isSelected === true);
          if (selectedValue.length > 0) {
            formatData.selectedOptions = [selectedValue[0].value];
          } else formatData.selectedOptions = [];

          const tempOptions = [];
          formatData.optionsForFrontend.map((item) => {
            const obj = {};
            obj.id = item.id;
            obj.name = item.value;
            tempOptions.push(obj);
            return 0;
          });
          formatData.optionsForFrontend = tempOptions;
        } else {
          formatData.optionsForFrontend = [];
        }
      }
      if (formatData.userFiledType === 'Checkbox') {
        if (defaultField.optionsForFrontend) {
          selectedValue = defaultField.optionsForFrontend.filter((x) => x.isSelected === true);
          if (selectedValue.length > 0) {
            formatData.selectedOptions = selectedValue.map((item) => {
              return item?.value?.trim();
            });
          } else formatData.selectedOptions = [];
          const tempOptions = [];
          formatData.optionsForFrontend.map((item) => {
            const obj = {};
            obj.id = item.id;
            obj.name = item?.value?.trim();
            tempOptions.push(obj);
            return 0;
          });
          formatData.optionsForFrontend = tempOptions;
        } else {
          formatData.optionsForFrontend = [];
        }
      }
      if (formatData.userFiledType === 'Text' || formatData.userFiledType === 'Text Area') {
        if (formatData.properties.length > 0 && formatData.properties.length.min !== '') {
          formatData.properties.length.min = parseInt(formatData.properties.length.min);
        }
        if (formatData.properties.length > 0 && formatData.properties.length.max !== '') {
          formatData.properties.length.max = parseInt(formatData.properties.length.max);
        }
      }

      if (formatData.userFiledType === 'File') {
        const tempArr = [];
        fileTypes.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.label;
          obj.value = item.isChecked;
          tempArr.push(obj);
          return 0;
        });
        formatData.properties.file_types = tempArr;
        setFileTypes(
          produce(fileTypes, (draftFile) => {
            return draftFile;
          })
        );
      }

      if (formatData.userFiledType === 'Group') {
        const tempArray = [];
        groupOptions.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.fieldName = item.value;
          obj.fieldType = item.drpOption.id;
          obj.isRequired = item.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
          obj.isDisabledField = HUB_CONST.NO;
          obj.fieldNameLang = item.fieldNameLang;
          tempArray.push(obj);
          return 0;
        });
        formatData.properties.length.min = parseInt(formatData.properties.length.min);
        formatData.properties.length.max = parseInt(formatData.properties.length.max);
        formatData.properties.fields = tempArray;

        selectedValue = groupOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          formatData.selectedOptions = [selectedValue[0].value];
        }
      }
      formatData.isRequiredInCommunity = formatData.isRequired;
      formatData.communityGroups =
        attendeeGroupList || formatData.isAllCommunityGroups === 'YES'
          ? 'All'
          : formatData?.communityGroups?.map((a) => {
            return { _id: a._id, name: a.name };
          });
      formatData.ticketingGroups =
        ticketGroupList || formatData.isAllTicketingGroups === 'YES'
          ? 'All'
          : formatData?.ticketingGroups?.map((a) => {
            return { id: a.id, name: a.name };
          });
      formatData.ticketsMappedWithGroups =
        ticketList || formatData.isAllTicketsMappedWithGroups === 'YES'
          ? 'All'
          : formatData.ticketsMappedWithGroups;
      formatData.isAllCommunityGroups =
        attendeeGroupList || formatData.isAllCommunityGroups === 'YES' ? 'YES' : 'NO';
      formatData.isAllTicketingGroups =
        ticketGroupList || formatData.isAllTicketingGroups === 'YES' ? 'YES' : 'NO';
      formatData.isAllTicketsMappedWithGroups =
        ticketList || formatData.isAllTicketsMappedWithGroups === 'YES' ? 'YES' : 'NO';

      getTempArray = [...getTempArray, formatData];
    });
    return getTempArray;
  });
  const onSaveClick = useCallback(() => {
    const err = handleValidations();
    if (err) {
      setErrObj(err);
      if (err?.stateFieldError) {
        dispatch(
          setNewMessage({
            message: err?.stateFieldError,
            type: 'error',
            show: true,
          })
        );
      } else {
        dispatch(
          setNewMessage({
            message: 'There are errors in the form. Kindly resolve to proceed',
            type: 'error',
            show: true,
          })
        );
      }
    } else {
      setLoadState({ ...loadState, isUpdateProfileFields: true });
      const formatData = JSON.parse(JSON.stringify(defaultField));
      if (
        !(
          formatData.userFiledType === 'Dropdown' ||
          formatData.userFiledType === 'Radio' ||
          formatData.userFiledType === 'Checkbox'
        )
      ) {
        delete formatData.optionsForFrontend;
      }
      let selectedValue = [];
      if (formatData.userFiledType === 'Dropdown' || formatData.userFiledType === 'Radio') {
        if (defaultField.optionsForFrontend) {
          selectedValue = defaultField.optionsForFrontend.filter((x) => x.isSelected === true);
          if (selectedValue.length > 0) {
            formatData.selectedOptions = [selectedValue[0].value];
          } else formatData.selectedOptions = [];

          const tempOptions = [];
          formatData.optionsForFrontend.map((item) => {
            const obj = {};
            obj.id = item.id;
            obj.name = item.value;
            tempOptions.push(obj);
            return 0;
          });
          formatData.optionsForFrontend = tempOptions;
        } else {
          formatData.optionsForFrontend = [];
        }
      }
      if (formatData.userFiledType === 'Checkbox') {
        if (defaultField.optionsForFrontend) {
          selectedValue = defaultField.optionsForFrontend.filter((x) => x.isSelected === true);
          if (selectedValue.length > 0) {
            formatData.selectedOptions = selectedValue.map((item) => {
              return item?.value?.trim();
            });
          } else formatData.selectedOptions = [];
          const tempOptions = [];
          formatData.optionsForFrontend.map((item) => {
            const obj = {};
            obj.id = item.id;
            obj.name = item?.value?.trim();
            tempOptions.push(obj);
            return 0;
          });
          formatData.optionsForFrontend = tempOptions;
        } else {
          formatData.optionsForFrontend = [];
        }
      }
      if (formatData.userFiledType === 'Text' || formatData.userFiledType === 'Text Area') {
        if (formatData.properties.length > 0 && formatData.properties.length.min !== '') {
          formatData.properties.length.min = parseInt(formatData.properties.length.min);
        }
        if (formatData.properties.length > 0 && formatData.properties.length.max !== '') {
          formatData.properties.length.max = parseInt(formatData.properties.length.max);
        }
      }

      if (formatData.userFiledType === 'File') {
        const tempArr = [];
        fileTypes.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.name = item.label;
          obj.value = item.isChecked;
          tempArr.push(obj);
          return 0;
        });
        formatData.properties.file_types = tempArr;
        setFileTypes(
          produce(fileTypes, (draftFile) => {
            return draftFile;
          })
        );
      }

      if (formatData.userFiledType === 'Group') {
        const tempArray = [];
        groupOptions.map((item) => {
          const obj = {};
          obj.id = item.id;
          obj.fieldName = item.value;
          obj.fieldType = item.drpOption.id;
          obj.isRequired = item.isSelected ? HUB_CONST.YES : HUB_CONST.NO;
          obj.isDisabledField = HUB_CONST.NO;
          obj.fieldNameLang = item.fieldNameLang;
          tempArray.push(obj);
          return 0;
        });
        formatData.properties.length.min = parseInt(formatData.properties.length.min);
        formatData.properties.length.max = parseInt(formatData.properties.length.max);
        formatData.properties.fields = tempArray;

        selectedValue = groupOptions.filter((x) => x.isSelected === true);
        if (selectedValue.length > 0) {
          formatData.selectedOptions = [selectedValue[0].value];
        }
      }
      formatData.isRequiredInCommunity = formatData.isRequired;
      formatData.isRequiredInTicketing = formatData.isRequiredInTicketing;
      formatData.communityGroups =
        attendeeGroupList || formatData.isAllCommunityGroups === 'YES'
          ? 'All'
          : formatData?.communityGroups?.map((a) => {
            return { _id: a._id, name: a.name };
          });
      formatData.ticketingGroups =
        ticketGroupList || formatData.isAllTicketingGroups === 'YES'
          ? 'All'
          : formatData?.ticketingGroups?.map((a) => {
            return { id: a.id, name: a.name };
          });
      formatData.ticketsMappedWithGroups =
        ticketList || formatData.isAllTicketsMappedWithGroups === 'YES'
          ? 'All'
          : formatData.ticketsMappedWithGroups;
      formatData.isAllCommunityGroups =
        attendeeGroupList || formatData.isAllCommunityGroups === 'YES' ? 'YES' : 'NO';
      formatData.isAllTicketingGroups =
        ticketGroupList || formatData.isAllTicketingGroups === 'YES' ? 'YES' : 'NO';
      formatData.isAllTicketsMappedWithGroups =
        ticketList || formatData.isAllTicketsMappedWithGroups === 'YES' ? 'YES' : 'NO';

      const childSubFields = formatData.childFields;
      delete formatData.childFields;
      let bodyData = {
        data: { ...formatData },
      };

      if (eventLanguages && eventLanguages.length > 0) {
        const fieldNameLang = {
          ...formatData.fieldNameLang,
          [selectedLanguage.id]: formatData.fieldName,
        };

        const formatDataForMultiLang = { ...formatData, fieldNameLang };

        const optionsLang = [];
        const options = [];
        let { properties } = formatData;
        if (
          formatData.hasOptions === 'YES' &&
          formatData.optionsForFrontend &&
          formatData.optionsForFrontend.length
        ) {
          formatData.optionsForFrontend.forEach((option) => {
            const langOption = {
              id: option.id,
              name: option.name,
              nameLang: {},
            };
            const savedOption = formatData?.optionsLang?.find((item) => item.id === option.id);
            if (savedOption) {
              langOption._id = savedOption._id ?? null;
              langOption.nameLang = {
                ...savedOption.nameLang,
                [selectedLanguage.id]: option.name,
              };
            } else {
              Object.keys(fieldNameLang).map((langId) => {
                langOption.nameLang[langId] = option.name;
              });
            }
            optionsLang.push(langOption);
            options.push(option.name);
          });
        } else if (formatData.userFiledType === 'Group') {
          const fields = [];
          if (
            formatData.properties &&
            formatData.properties.button_labelLang &&
            formatData.properties.field_type_name &&
            formatData.properties.field_type_nameLang &&
            formatData.properties.fields.length
          ) {
            const button_labelLang = {
              ...formatData.properties.button_labelLang,
              [selectedLanguage.id]: formatData.properties.button_label,
            };
            const field_type_nameLang = {
              ...formatData.properties.field_type_nameLang,
              [selectedLanguage.id]: formatData.properties.field_type_name,
            };

            formatData.properties.fields.forEach((item) => {
              fields.push({
                ...item,
                fieldNameLang: {
                  ...item.fieldNameLang,
                  [selectedLanguage.id]: item.fieldName,
                },
              });
            });

            if (formatData.properties)
              properties = { ...properties, button_labelLang, field_type_nameLang, fields };
          }
        }
        bodyData = {
          data: { ...formatDataForMultiLang, optionsLang, properties, options },
        };
      }

      let tempChildArr = [];
      if (childSubFields?.length > 0) {
        tempChildArr = getChildFields(childSubFields);
        let totalArray = [bodyData.data];
        bodyData = {
          data: [],
        };
        tempChildArr?.forEach((subField) => {
          if (eventData.EventData.community_version === 1) {
            delete subField.icon;
            delete subField.user_profile_field_icons_id;
          }
          totalArray = [...totalArray, subField];
        });
        bodyData = {
          data: [...totalArray],
        };
      }

      if (eventData.EventData.community_version === 1) {
        delete bodyData.data.icon;
        delete bodyData.data.user_profile_field_icons_id;
      }
      const customHeader = {
        fieldId: formatData._id,
        language: selectedLanguage ? selectedLanguage.id : 0,
      };

      if (bodyData?.data?.newField) {
        delete bodyData.data.newField;
        delete bodyData.data.fileType;
        APICALL(
          'ADD_USER_PROFILE_FIELDS',
          reduxData,
          'POST',
          ADD_USER_PROFILE_FIELDS,
          selectedLanguage ? { language: selectedLanguage.id } : null,
          bodyData,
          APICB
        );
      } else {
        APICALL(
          'UPDATE_USER_PROFILE_FIELDS',
          reduxData,
          API_CALL_METHODS.PUT,
          UPDATE_USER_PROFILE_FIELDS,
          { ...customHeader },
          bodyData,
          APICB
        );
      }
    }
  }, [
    selectedLanguage,
    defaultField,
    eventData.EventData.community_version,
    fileTypes,
    groupOptions,
    handleValidations,
    loadState,
    reduxData,
    eventLanguages,
    dispatch,
  ]);

  const getTicketPermission = (group = {}) => {
    if (!group) return false;
    const { type = '' } = group;
    const restrictGroup = restrictTicketGroupTypes.includes(type);
    return !restrictGroup;
  };

  const getFieldNameByLang = (fieldName) =>
    !isEmpty(fieldName) && (fieldName === 'Email' || fieldName === 'Phone')
      ? TRANSL_CONST.find(
        (eachTransObj) =>
          eachTransObj.subtype === fieldName.toLowerCase() &&
          eachTransObj.code.toUpperCase() === selectedLanguage?.short_code
      )?.label || fieldName
      : fieldName;

  const deleteNewChild = React.useCallback(
    (value) => {
      const profileFieldListNew = [...profileFieldList];
      profileFieldListNew?.map((prntField) => {
        const obj = { ...prntField };
        const child = [...prntField?.childFields];
        let tmpArr = [];
        child?.forEach((chldField) => {
          let data = { ...chldField };
          if (data._id !== value._id) {
            tmpArr = [...tmpArr, data];
            if (value.parent === prntField._id) {
              setDefaultField(data);
            }
          }
        });
        prntField.childFields = tmpArr;
        return obj;
      });
      setProfileFieldList(profileFieldListNew);
    },
    [profileFieldList]
  );

  return (
    <>
      <CardWrapper
        title={isWebinar ? 'Manage Form Fields' : 'Profile Fields'}
        subheader={
          <Box>
            {isWebinar
              ? 'These fields are added to your registration form.'
              : 'Create custom profile fields for your different groups. '}
            {!isWebinar && (
              <Box
                component="a"
                className="cursor-pointer text-underline"
                onClick={(e) => window.open(LearnMoreLink.settingProfileField, '_blank')}>
                Learn More
              </Box>
            )}
          </Box>
        }
        headerActions={
          <Button
            disabled={
              userInfo.UserInfo.isPlanExpired ||
              userInfo.UserInfo.subscriptionStatus === 'CANCELLED' ||
              (isWebinar && loadState.isLoadProfileFields)
            }
            size="small"
            variant="contained"
            onClick={() => {
              if (isWebinar) {
                commonTrackEvent('click add form field', { 'org id': reduxData.organiserId });
                setAddFormFields(!addFormFields);
                setAction('add');
              } else {
                setAction('add');
                setAddField(!isAddField);
              }
            }}
            color="primary">
            {isWebinar ? 'Add Form Field' : ' ADD PROFILE FIELD'}
          </Button>
        }>
        {loadState.isLoadProfileFields && profileFieldList && !profileFieldList.length ? (
          <ProfileLoader community_version={eventData.EventData.community_version} />
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Box width="45%" className={classes.eventBlockSetting}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List className={classes.eventList}>
                    <Container
                      lockAxis="y"
                      orientation="vertical"
                      onDrop={(dropResult) => {
                        if (dropResult.addedIndex !== null) {
                          if (dropResult.addedIndex !== dropResult.removedIndex) {
                            if (!reorderingDisabledFields.includes(dropResult.addedIndex + 1)) {
                              onDrop(dropResult, 'column');
                            }
                          }
                        }
                      }}>
                      {profileFieldList.map((item, i) => {
                        return item.isDisabled === HUB_CONST.YES ||
                          reorderingDisabledFields.includes(
                            item.user_profile_default_field_type
                          ) ? (
                          <ListItem
                            key={`profile-fields-${item._id}`}
                            button
                            disableGutters
                            selected={item._id === selectedProfileID}
                            className={`${classes.eventNameBlock} profile-list`}
                            onClick={() => onCardClick(item, i)}>
                            <ListItemIcon>
                              <Box>
                                <SvgUnionDotsIcon color="#a5a5a5" height="18px" width="11px" />
                              </Box>
                            </ListItemIcon>
                            <ListItemText
                              primaryTypographyProps={{
                                variant: 'subtitle2',
                                component: 'label',
                              }}
                              primary={getFieldNameByLang(item.fieldName)}
                              secondaryTypographyProps={{
                                variant: 'caption',
                                color: 'textSecondary',
                                component: 'p',
                              }}
                              secondary={item.userFiledType}
                            />
                            <ListItemSecondaryAction className={classes.eventNameBlockCheck}>
                              {item.user_profile_default_field_type === 0 ? (
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    setModal({
                                      isOpen: true,
                                      type: 'DELETE_PROFILE_FIELD',
                                      data: item,
                                    })
                                  }>
                                  <Trash2 size={20} />
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : (
                          <Draggable className="" key={`profile-field${item._id}`}>
                            <ListItem
                              button
                              disableGutters
                              selected={item._id === selectedProfileID}
                              className={`${classes.eventNameBlock} profile-list`}
                              onClick={() => onCardClick(item, i)}>
                              <ListItemIcon>
                                <Box>
                                  <SvgUnionDotsIcon color="#a5a5a5" height="18px" width="11px" />
                                </Box>
                              </ListItemIcon>
                              <ListItemText
                                className={classes.profileInsertData}
                                primaryTypographyProps={{
                                  variant: 'subtitle2',
                                  component: 'label',
                                }}
                                primary={getFieldNameByLang(item.fieldName)}
                                secondaryTypographyProps={{
                                  variant: 'caption',
                                  color: 'textSecondary',
                                  component: 'p',
                                }}
                                secondary={item.userFiledType}
                              />
                              <ListItemSecondaryAction className={classes.eventNameBlockCheck}>
                                {item.user_profile_default_field_type === 0 ? (
                                  <IconButton
                                    color="primary"
                                    onClick={() =>
                                      setModal({
                                        isOpen: true,
                                        type: 'DELETE_PROFILE_FIELD',
                                        data: item,
                                      })
                                    }>
                                    <Trash2 size={20} />
                                  </IconButton>
                                ) : (
                                  ''
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                            {item?.childFields && item.childFields.length > 0 ? (
                              <Grid item xs={12} className={classes.subFieldMainBlk}>
                                {' '}
                                <Container
                                  lockAxis="y"
                                  orientation="vertical"
                                  onDrop={(dropResult) => {
                                    if (dropResult.addedIndex !== null) {
                                      if (dropResult.addedIndex !== dropResult.removedIndex) {
                                        onDropChildElem(dropResult, i);
                                      }
                                    }
                                  }}>
                                  {item.childFields.map((field, index) => (
                                    <Draggable key={`profile-field${index}`}>
                                      <ListItem
                                        button
                                        disableGutters
                                        selected={field._id === selectedProfileID}
                                        // className={`${classes.eventNameBlock} profile-list`}
                                        className={classes.subField}
                                        onClick={() => onCardClick(field, index)}>
                                        <ListItemIcon>
                                          <Box>
                                            <SvgUnionDotsIcon
                                              color="#a5a5a5"
                                              height="18px"
                                              width="11px"
                                            />
                                          </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                          className={classes.profileInsertData}
                                          primaryTypographyProps={{
                                            variant: 'subtitle2',
                                            component: 'label',
                                          }}
                                          primary={getFieldNameByLang(
                                            field.fieldName || 'New Subfield'
                                          )}
                                          secondaryTypographyProps={{
                                            variant: 'caption',
                                            color: 'textSecondary',
                                            component: 'p',
                                          }}
                                          secondary={field.userFiledType}
                                        />
                                        <ListItemSecondaryAction
                                          className={classes.eventNameBlockCheck}>
                                          {field.user_profile_default_field_type === 0 ? (
                                            <IconButton
                                              color="primary"
                                              onClick={() =>
                                                setModal({
                                                  isOpen: true,
                                                  type: 'DELETE_CHILD_PROFILE_FIELD',
                                                  data: field,
                                                })
                                              }>
                                              <Trash2 size={20} />
                                            </IconButton>
                                          ) : (
                                            ''
                                          )}
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </Draggable>
                                  ))}
                                </Container>
                              </Grid>
                            ) : null}
                            {item?.hasSF && item?.childFields && item.childFields.length > 0 && (
                              <Box
                                display="flex"
                                className={`text-underline cursor-pointer ${classes.addBtn}`}
                                alignItems="center"
                                component="a"
                                variant="title"
                                fontWeight={600}
                                onClick={() => addNewSubField(item)}>
                                <Box mr={1} display="flex">
                                  <Plus width={18} />
                                </Box>
                                Add Subfield
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                    </Container>
                  </List>
                </Grid>
              </Grid>
            </Box>
            <Box width="50%" className={classes.peopleBlock} p={2}>
              {defaultField && Object.keys(defaultField).length > 0
                ? renderedFields(defaultField)
                : ''}
              <Box display="flex" pt={2} className={classes.footerActionBtn}>
                <BtnWithLoader
                  onClick={() => {
                    setErrObj({});
                    setDefaultField(defaultSelectedData);
                    setGroupDropdownOptions(defaultGroupDropdownOptions);
                    setGroupOptions(defaultGroupOptions);
                    setFileTypes(defaultFileTypes);
                    if (defaultField?.newField) {
                      deleteNewChild(defaultField);
                    }
                  }}
                  disabled={
                    loadState.isUpdateProfileFields ||
                    (showDisable &&
                      (eventData.EventData.community_version === 1 ||
                        eventData.EventData.community_version === 2))
                  }
                  label="Cancel"
                  variant="outlined"
                  className="uppercase"
                  size="small"
                  color="primary"
                />
                <Box ml={1.2}>
                  <BtnWithLoader
                    variant="contained"
                    size="small"
                    color="primary"
                    isLoading={loadState.isUpdateProfileFields}
                    disabled={
                      loadState.isUpdateProfileFields ||
                      (showDisable &&
                        (eventData.EventData.community_version === 1 ||
                          eventData.EventData.community_version === 2)) ||
                      userInfo.UserInfo.isPlanExpired ||
                      userInfo.UserInfo.subscriptionStatus === 'CANCELLED'
                    }
                    label="SAVE"
                    onClick={() => onSaveClick()}
                    loadingLabel="Saving"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </CardWrapper>

      <WebinarAddFormField
        preBuiltFieldList={preBuiltFieldList}
        modalData={modalData}
        setModal={setModal}
        addFormFields={addFormFields}
        setAddFormFields={setAddFormFields}
        setDefaultField={setDefaultField}
        getUserFields={onSaveClose}
        eventTicketList={eventTicketList}
        profileFieldList={profileFieldList}
        defaultIcon={defaultIcon}
        setAddField={() => {
          if (isWebinar) {
            commonTrackEvent('click add custom field', { 'org id': reduxData.organiserId });
          }
          setAction('add');
          setAddField(!isAddField);
        }}
        isAddField={isAddField}
      />

      {isAddField && (
        <ProfileFieldsSidebarNewUI
          isOpen={isAddField}
          title={isWebinar ? 'Add Custom Field' : 'Add Field'}
          onClose={() => {
            setAddField(false);
            setAction('edit');
            setAddIcon('');
          }}
          onSaveClose={() => onSaveClose()}
          classes={classes}
          handelClickOnIconPopup={() => setOpenSidebar((v) => ({ ...v, iconSidbar: true }))}
          selIcon={addIcon || defaultIcon}
          isTicketAllow={getTicketPermission(selectedGroup)}
          community_version={eventData.EventData.community_version}
          groupList={currenGroups}
          eventTicketList={eventTicketList}
          profileFieldList={profileFieldList}
        />
      )}
      <IconSideBar
        classes={classes}
        isOpen={openSidebar.iconSidbar}
        onClose={() => setOpenSidebar((v) => ({ ...v, iconSidbar: false }))}
        dataList={iconData.data}
        loadingState={loadState}
        iconLoad
        handelClickOnIcon={(item, action) => {
          if (item && action === 'add') {
            setAddIcon({ ...item });
          } else {
            setDefaultField({
              ...defaultField,
              icon: item.icon,
              user_profile_field_icons_id: item.id,
            });
          }
          setOpenSidebar((v) => ({ ...v, iconSidbar: false }));
        }}
        action={action}
        selectedIcon={action === 'edit' ? defaultField.user_profile_field_icons_id : defaultIcon.id}
      />
      <ConfirmationPopUp
        isOpen={modalData.isOpen}
        onClose={loadState.deleteFiledLoader ? '' : handelClosePopup}
        data={ConfirmationPopUpJSON[modalData.type]}
      />
    </>
  );
}
export default React.memo(ProfileFieldsNewUI);
