/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  API,
  POSTAPIWITHCUSTOMDOMAIN,
  GETAPIWITHCUSTOMDOMAIN,
  PATCH_API_WITH_CUSTOM_DOMAIN,
  DELETE_API_WITH_CUSTOM_DOMAIN,
} from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { commonSelector } from 'redux/custom-selector';
import { useHistory } from 'react-router-dom';
import ProjectSetting from 'settings';
import {
  GET_CURRENT_PLANS,
  GET_LANDING_PAGES,
  GET_ALL_TEMPLATES,
  EDIT_LANDING_PAGE,
  DUPLICATE_LANDING_PAGE,
  RENAME_LANDING_PAGE,
  // PUBLISH_LANDING_PAGE,
} from 'api-setup/api-endpoints';
import { setNewMessage } from 'redux/actions/setting-actions';

const CUSTOM_URL = `${ProjectSetting.customBaseURL2}/landing-page`;

export function useCustomLPApi() {
  const reduxData = useSelector(commonSelector, shallowEqual);
  const source = useSource();
  const dispatch = useDispatch();
  const history = useHistory();
  const [headers] = API.generateHeader(reduxData, null, null);

  const [currentPlanData, setCurrentPlanData] = React.useState({});
  const [loadingState, setLoadingState] = React.useState({
    initLoad: true,
    saveLoader: false,
    isSaveSuccess: false,
    createLoader: false,
    createSuccess: false,
    urlLoader: false,
    publishLoader: false,
    deleteLoader: false,
    renameLoader: false,
    initError: false,
  });
  const [errorMsg, setErrorMsg] = React.useState('');
  const [landingPageData, setLandingPageData] = React.useState({});
  const [templatesData, setTemplatesData] = React.useState({});
  const [createLandingPageData, setCreateLandingPageData] = React.useState({});
  const [editLPData, setEditLPData] = React.useState({});
  const [publishLPData, setPublishLPData] = React.useState({});
  const [deleteLPData, setDeleteLPData] = React.useState({});
  const [duplicateLPData, setDuplicateLPData] = React.useState({});
  // apis using for custom landing page
  // Sample below
  const getCustomLPData = async () => {
    let res;
    await POSTAPIWITHCUSTOMDOMAIN(
      false,
      GET_CURRENT_PLANS,
      source.AS,
      headers,
      null,
      ProjectSetting.customBaseURL // TODO
    )
      .then((resp) => {
        if (resp.data.status === API.apiSuccessStatus) {
          const mockData = {};
          setCurrentPlanData({ ...resp.data, ...mockData });
          setLoadingState({ ...loadingState, initLoad: false });
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch(API.catchHandler);
    return res;
  };

  const getLandingPages = async () => {
    let res;
    setLoadingState({ ...loadingState, initLoad: true });
    await GETAPIWITHCUSTOMDOMAIN(false, GET_LANDING_PAGES, source.AS, headers, null, CUSTOM_URL)
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          setLandingPageData(res);
          setLoadingState({ ...loadingState, initLoad: false });
        } else {
          setLoadingState({ ...loadingState, initLoad: false });
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const { message = 'Something went wrong' } = err.response.data;
          dispatch(setNewMessage({ message, type: 'error', show: true }));
        } else {
          dispatch(setNewMessage({ message: 'Something went wrong', type: 'error', show: true }));
        }
        setLoadingState({ ...loadingState, initLoad: false, initError: true });
        API.catchHandler(err);
      });
    return res;
  };
  const getAllTemplates = async () => {
    let res;
    await GETAPIWITHCUSTOMDOMAIN(false, GET_ALL_TEMPLATES, source.AS, headers, null, CUSTOM_URL)
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          setTemplatesData(res);
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const { message = 'Something went wrong' } = err.response.data;
          dispatch(setNewMessage({ message, type: 'error', show: true }));
        }
        API.catchHandler(err);
      });
    return res;
  };

  const createLandingPage = async (bodyData, isContinue) => {
    let res;
    setLoadingState({
      ...loadingState,
      createLoader: true,
    });
    setErrorMsg('');
    if (isContinue && !bodyData.name) {
      setErrorMsg('Please enter your page name');
      setLoadingState({
        ...loadingState,
        createLoader: false,
      });
    } else {
      await POSTAPIWITHCUSTOMDOMAIN(
        false,
        GET_LANDING_PAGES,
        source.AS,
        headers,
        bodyData,
        CUSTOM_URL
      )
        .then((resp) => {
          if (resp.status === API.apiSuccessStatus) {
            res = resp.data && resp.data.data;
            setCreateLandingPageData(res);
          } else {
            API.errStatusHandler(resp, history, dispatch);
          }
        })
        .catch((err) => {
          setLoadingState({
            ...loadingState,
            createLoader: false,
          });
          if (err.response && err.response.data) {
            const { message } = err.response.data;
            setErrorMsg(message || 'Something went wrong.');
          }
          API.catchHandler(err);
        });
    }
    return res;
  };

  const duplicateLandingPage = async (id) => {
    let res;
    setLoadingState({
      ...loadingState,
      duplicateLoader: true,
    });
    const DUPLICATE_LANDING_PAGE_URL = DUPLICATE_LANDING_PAGE.replace('{ID}', id.toString());
    await POSTAPIWITHCUSTOMDOMAIN(
      false,
      DUPLICATE_LANDING_PAGE_URL,
      source.AS,
      headers,
      {},
      CUSTOM_URL
    )
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          setDuplicateLPData(res);
          setLandingPageData((data) => ({
            ...data,
            draftCustomLandingPages: [res, ...data.draftCustomLandingPages],
          }));
          setLoadingState({
            ...loadingState,
            duplicateLoader: false,
          });
          dispatch(
            setNewMessage({
              message: 'You have duplicated the design successfully.',
              type: 'success',
              show: true,
            })
          );
        } else {
          setLoadingState({
            ...loadingState,
            duplicateLoader: false,
          });
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        setLoadingState({
          ...loadingState,
          duplicateLoader: false,
        });
        if (err.response && err.response.data) {
          const { message, error } = err.response.data;
          dispatch(
            setNewMessage({
              message: message || error || 'Something went wrong.',
              type: 'error',
              show: true,
            })
          );
        }
        API.catchHandler(err);
      });
    return res;
  };

  const editLandingPage = async (id) => {
    let res;
    const EDIT_LANDING_PAGE_URL = EDIT_LANDING_PAGE.replace('{ID}', id);
    setLoadingState({
      ...loadingState,
      urlLoader: true,
    });
    await GETAPIWITHCUSTOMDOMAIN(false, EDIT_LANDING_PAGE_URL, source.AS, headers, null, CUSTOM_URL)
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          // if (isEdit) {
          //   dispatch(
          //     setNewMessage({
          //       message: 'You have created new design for the landing page successfully.',
          //       type: 'success',
          //       show: true,
          //     })
          //   );
          // }
          setEditLPData(res);
        } else {
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        setLoadingState({
          ...loadingState,
          createLoader: false,
          urlLoader: false,
        });
        if (err.response && err.response.data) {
          const { message, error } = err.response.data;
          dispatch(
            setNewMessage({
              message: message || error || 'Something went wrong.',
              type: 'error',
              show: true,
            })
          );
        }
        API.catchHandler(err);
      });
    return res;
  };

  const renameLandingPage = async ({ templateId, name }) => {
    let res;
    const RENAME_LANDING_PAGE_URL = RENAME_LANDING_PAGE.replace('{ID}', templateId.toString());
    setLoadingState({
      ...loadingState,
      renameLoader: true,
    });
    setErrorMsg('');
    await PATCH_API_WITH_CUSTOM_DOMAIN(
      false,
      RENAME_LANDING_PAGE_URL,
      source.AS,
      headers,
      CUSTOM_URL,
      { name }
    )
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp;
          setLoadingState({
            ...loadingState,
            renameLoader: false,
          });
          const objIndex = landingPageData.draftCustomLandingPages.findIndex(
            (obj) => obj.id === templateId
          );
          landingPageData.draftCustomLandingPages[objIndex].name = name;
          setLandingPageData({
            ...landingPageData,
          });
          dispatch(
            setNewMessage({
              message: 'You have successfully renamed the landing page.',
              type: 'success',
              show: true,
            })
          );
        } else {
          setLoadingState({
            ...loadingState,
            renameLoader: false,
          });
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        res = err.response;
        setLoadingState({
          ...loadingState,
          renameLoader: false,
        });
        setErrorMsg('Landing page with that name already exists.');
        if (err.response && err.response.data) {
          const { message, error } = err.response.data;
          dispatch(
            setNewMessage({
              message: message || error || 'Something went wrong.',
              type: 'error',
              show: true,
            })
          );
        }
        API.catchHandler(err);
      });
    return res;
  };

  const publishLandingPage = async ({ id, url, republish }) => {
    let res;
    const PUBLISH_LANDING_PAGE_URL = `${GET_LANDING_PAGES}/${id}/${url}`;
    setLoadingState({
      ...loadingState,
      publishLoader: true,
    });
    await PATCH_API_WITH_CUSTOM_DOMAIN(
      false,
      PUBLISH_LANDING_PAGE_URL,
      source.AS,
      headers,
      CUSTOM_URL,
      null
    )
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          setPublishLPData(res);
          setLoadingState({
            ...loadingState,
            publishLoader: false,
          });
          if (url === 'publish') {
            if (!republish) {
              const tempLP = landingPageData && landingPageData?.publishedCustomLandingPage;
              const objIndex = landingPageData.draftCustomLandingPages.findIndex(
                (obj) => obj.id === id
              );
              if (tempLP) {
                setLandingPageData((data) => ({
                  ...data,
                  publishedCustomLandingPage: data.draftCustomLandingPages[objIndex],
                  isUsingCustomLandingPage: true,
                  draftCustomLandingPages: [
                    tempLP,
                    ...data.draftCustomLandingPages.filter((landingPage) => landingPage.id !== id),
                  ],
                }));
              } else {
                setLandingPageData((data) => ({
                  ...data,
                  publishedCustomLandingPage: data.draftCustomLandingPages[objIndex],
                  isUsingCustomLandingPage: true,
                  draftCustomLandingPages: [
                    ...data.draftCustomLandingPages.filter((landingPage) => landingPage.id !== id),
                  ],
                }));
              }
            }
            dispatch(
              setNewMessage({
                message: !republish
                  ? 'You have successfully published your landing page.'
                  : 'You have successfully republished your landing page.',
                type: 'success',
                show: true,
              })
            );
          } else {
            const tempLP = landingPageData && landingPageData?.publishedCustomLandingPage;
            setLandingPageData((data) => ({
              ...data,
              publishedCustomLandingPage: {},
              isUsingCustomLandingPage: false,
              draftCustomLandingPages: [tempLP && tempLP, ...data.draftCustomLandingPages],
            }));
            dispatch(
              setNewMessage({
                message: 'This page has been unpublished.',
                type: 'success',
                show: true,
              })
            );
          }
        } else {
          setLoadingState({
            ...loadingState,
            publishLoader: false,
          });
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          const { message, error } = err.response.data;
          dispatch(
            setNewMessage({
              message: message || error || 'Something went wrong.',
              type: 'error',
              show: true,
            })
          );
        }
        setLoadingState({
          ...loadingState,
          publishLoader: false,
        });
        API.catchHandler(err);
      });
  };

  const deleteLandingPage = async (id) => {
    let res;
    const DELETE_LENDING_PAGE_URL = `${GET_LANDING_PAGES}/${id}`;
    setLoadingState({
      ...loadingState,
      deleteLoader: true,
    });
    await DELETE_API_WITH_CUSTOM_DOMAIN(
      false,
      DELETE_LENDING_PAGE_URL,
      source.AS,
      headers,
      CUSTOM_URL,
      { id }
    )
      .then((resp) => {
        if (resp.status === API.apiSuccessStatus) {
          res = resp.data && resp.data.data;
          dispatch(
            setNewMessage({
              message: 'You have successfully deleted draft design.',
              type: 'success',
              show: true,
            })
          );
          setDeleteLPData(res);
          setLoadingState({
            ...loadingState,
            deleteLoader: false,
          });

          setLandingPageData((data) => ({
            ...data,
            draftCustomLandingPages: data.draftCustomLandingPages.filter(
              (landingPage) => landingPage.id !== id
            ),
          }));
        } else {
          setLoadingState({
            ...loadingState,
            deleteLoader: false,
          });
          API.errStatusHandler(resp, history, dispatch);
        }
      })
      .catch((err) => {
        setLoadingState({
          ...loadingState,
          deleteLoader: false,
        });
        if (err.response && err.response.data) {
          const { message, error } = err.response.data;
          dispatch(
            setNewMessage({
              message: message || error || 'Something went wrong.',
              type: 'error',
              show: true,
            })
          );
        }
        API.catchHandler(err);
      });
  };
  return [
    {
      currentPlanData,
      loadingState,
      setCurrentPlanData,
      setLoadingState,
      landingPageData,
      templatesData,
      createLandingPageData,
      errorMsg,
      editLPData,
      publishLPData,
      deleteLPData,
      duplicateLPData,
    },
    {
      getCustomLPData,
      getLandingPages,
      getAllTemplates,
      createLandingPage,
      editLandingPage,
      publishLandingPage,
      deleteLandingPage,
      duplicateLandingPage,
      renameLandingPage,
      setErrorMsg,
    },
  ];
}
