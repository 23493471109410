import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    maxHeight: `calc(100% + ${theme.spacing(8)}px)`,
    padding: `${theme.spacing(4)}px ${theme.spacing(3.25)}px`,
    margin: `-${theme.spacing(4)}px -${theme.spacing(3.25)}px`,
    overflowX: 'hidden',
    overflowY: 'auto',
    // scrollBehavior: 'smooth',
  },
  badgeColor: {
    '& .active': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.alert.warn2,
      color: '#fff',
      height: 14,
      minWidth: 14,
      borderRadius: '50%',
      padding: 0,
      fontSize: 10,
      transition: 'all 0.2s',
      animation: `$pulsing 1.5s infinite`,
    },
  },
  questionOptions: {
    border: `1px solid #C0C0C0`,
    borderRadius: '5px',
    padding: `${theme.spacing(0.4)}px ${theme.spacing(2)}px`,
    marginTop: `${theme.spacing(1.8)}px`,
  },
  questionsAnswerDragRow: {
    border: `1px solid #DBDBDB`,
    borderRadius: '10px',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2.4)}px`,
    position: 'relative',
    cursor: 'pointer',
  },
  optionsRow: {
    padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    '&:hover': {
      backgroundColor: '#EEF9EC',
      borderRadius: '5px',
      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
    },
  },
  rightAnswer: {
    backgroundColor: '#EEF9EC',
    borderRadius: '3px',
  },
  optionsRowAreaCheck: {
    paddingTop: `${theme.spacing(1)}px`,

    '& div': {
      padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
      position: 'relative',
      paddingLeft: '30px',
      marginTop: '5px',
      '&::before': {
        width: '16px',
        height: '16px',
        background: '#fff',
        border: `1px solid #F0F0F0`,
        borderRadius: '2px',
        content: `""`,
        position: 'absolute',
        left: '7px',
        top: '5px',
      },
    },
  },

  optionsRowAreaRadio: {
    paddingTop: `${theme.spacing(1)}px`,

    '& div': {
      padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
      position: 'relative',
      paddingLeft: '30px',
      marginTop: '5px',
      '&::before': {
        width: '16px',
        height: '16px',
        background: '#fff',
        border: `1px solid #F0F0F0`,
        borderRadius: '100%',
        content: `""`,
        position: 'absolute',
        left: '7px',
        top: '5px',
      },
    },
  },

  dragIconBox: {
    position: 'absolute',
    left: '17px',
    top: '17px',
  },
  questionText: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    lineHeight: theme.spacing[6],
    fontSize: '0.875rem',
    wordBreak: 'break-word',
    '& .questionLabelAsterisk': {
      color: 'red',
    },
  },
  questionType: {
    color: theme.palette.text.primary,
    marginLeft: '0.2rem',
  },
  fileActionIconButton: {
    marginTop: '-0.5rem',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  trackList: {
    padding: 0,
    width: '100%',
    '& .smooth-dnd-draggable-wrapper': {
      marginBottom: theme.spacing(2),
      overflow: 'visible !important',
      background: '#fff',
    },
    '& .smooth-dnd-container ': {
      minHeight: '0',
    },
  },
}));
