/* eslint-disable */
import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import InputField from 'custom-components/form-fields/inputField';
import SelectDropdown from 'custom-components/form-fields/selectDropdown';
import EditorField from 'custom-components/form-fields/editorField';
import { restrictingCharacerInput } from 'settings';
import DateTimePicker from 'custom-components/form-fields/dateTimePicker';
import CheckBox from 'custom-components/form-fields/checkbox2';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  commonSelector,
  copyEventSelector,
  languageSelector,
  timeZoneSelector,
  userInfoSelector,
  copyStatusSelector,
  baseEventLanguagesSelector,
} from 'redux/custom-selector';
import { useStyles } from '../../style';
import { EMPTY_STR } from 'constants/common';
import SvgUpgrade from 'icons/svgUpgrade';
import { PricingPlan } from 'constants/pricingPlan';
import { setTimezones } from 'redux/actions/timezone-actions';
import { setLanguages } from 'redux/actions/language-actions';
import { GET_TIMEZONES } from 'api-setup/api-endpoints';
import { API, GETAPI } from 'api-setup/api-helper';
import { useSource } from 'lib/custom-common-hooks';
import { getCalculatedCharLegth } from 'lib/common-file-function';
import CopyticketCreationLoader from './copyticketCreationLoader';
import LanguageSettingsCopyEvent from 'custom-components/form-fields/LanguageSettingsCopyEvent';
import moment from 'moment-timezone';
import { getMilliSecondsByDateTime } from 'lib/common-function';
import { Info } from 'react-feather';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';
import MsgNotificationComponent from 'pages/CreateNewEvent/MsgNotificationComponent';
import SvgBaseLangIcon from 'icons/svgBaseLangIcon';
import InfoMsgComponent from 'pages/integrations/infoMsgComponent';

export default function BasicDetails(props) {
  const classes = useStyles();
  const timeZonesArr = useSelector(timeZoneSelector, shallowEqual);
  const languageArr = useSelector(languageSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);
  const dispatch = useDispatch();
  const reduxData = useSelector(commonSelector, shallowEqual);
  const copyEventTzName = useSelector(copyStatusSelector, shallowEqual);
  const source = useSource();
  const copyEventData = useSelector(copyEventSelector, shallowEqual);
  const baseEventLanguage = useSelector(baseEventLanguagesSelector, shallowEqual);
  const { multiLangObj, loadingState, eventFields, errObj, eventLanguages } = props;
  const [timeZones, setTimeZones] = useState(timeZonesArr);
  const [language, setLanguage] = useState(languageArr);
  const handelChangeOnBasicDetailsFields = (name, value) => {
    const oldObj = JSON.parse(JSON.stringify(eventFields));
    const oldErrObj = JSON.parse(JSON.stringify(errObj));

    oldObj[name] = value;
    oldErrObj[name] = EMPTY_STR;
    props.setEventFields(oldObj);
    props.setErrObj(oldErrObj);
  };

  React.useEffect(() => {
    if (!timeZones.length) {
      props.setLoadState({ ...props.loadingState, initLoader: true });
      const [headers] = API.generateHeader(reduxData);
      GETAPI(false, GET_TIMEZONES, source.AS, headers)
        .then((res) => {
          if (res.data.status === API.apiSuccessStatus) {
            const timeZonesList = res.data.data.timezoneList;
            const tempArray = [];
            timeZonesList.map((item) => {
              const obj = {};
              obj.name = item.name;
              obj.description = `${item.description} (${item.country})`;
              obj.country = item.country;
              obj.id = item.id;
              tempArray.push(obj);
              return 0;
            });
            setTimeZones(tempArray);
            setLanguage(res.data.data.languageList);
            dispatch(setTimezones(tempArray));
            dispatch(setLanguages(res.data.data.languageList));
            props.setLoadState({ ...props.loadingState, initLoader: false });
          } else {
            API.errStatusHandler(res, history, dispatch);
            // API.errStatusHandler(res);
          }
        })
        .catch(API.catchHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZones]);

  const handleBaseLanguageChange = (langCode) => {
    const oldObj = JSON.parse(JSON.stringify(eventFields));
    oldObj['base_language'] = langCode;
    props.setEventFields(oldObj);
  };
  const handleLanguageChange = (langs) => {
    const oldObj = JSON.parse(JSON.stringify(eventFields));
    oldObj['event_languages'] = [...langs];
    props.setEventFields(oldObj);
  };

  function getTotalHours(startMilli, endMilli) {
    const startTime = moment(startMilli);
    const endTime = moment(endMilli);
    const diff = endTime.diff(startTime);
    const totalHours = Math.floor(moment.duration(diff).asHours());
    return totalHours;
  }

  const totalHours = getTotalHours(Number(eventFields?.start_time_milli), Number(eventFields?.end_time_milli));

  const getMinDateForDatePicker = React.useCallback((start_time_milli, copyEventTz) => {
    if (start_time_milli && copyEventTz) {
      const sourceEventDate = moment(+start_time_milli).tz(copyEventTz);
      const h1 = sourceEventDate.hours();
      const m1 = sourceEventDate.minute();
      const s1 = sourceEventDate.seconds();

      const h2 = moment().hours();
      const m2 = moment().minute();

      if (h1 < h2 || (h1 === h2 && m1 < m2)) {
        const tempToday = moment().hours(h1).minute(m1).seconds(s1);
        const tomorrow = tempToday.add(1, 'days');
        const tomorrowMilli = getMilliSecondsByDateTime(tomorrow);
        return tomorrowMilli;
      }
    }
    return 'today';
  }, []);

  // for webinar find lang name
  function findNameById(id, array) {
    for (let obj of array) {
      if (Number(obj.id) === Number(id)) {
        return obj.name;
      }
    }
    return null;
  }

  const langName = copyEventData?.eventType === EEventFormat.WEBINAR && findNameById(eventFields.event_languages[0], language);

  return (
    <Grid container>
      {(userInfo?.UserInfo?.planName === PricingPlan.WEBINAR ||
        userInfo?.UserInfo?.planName === PricingPlan.WEBINAR_LITE) &&
      copyEventData?.eventType === EEventFormat.VIRTUAL ? (
        <InfoMsgComponent
          className="infoMsg"
          preTitle="IMPORTANT"
          title="You are duplicating a virtual event. It will consume one virtual event credit."
        />
      ) : null}
      <Grid xs={6}>
        {props.loadingState.initLoader ? (
          <CopyticketCreationLoader />
        ) : (
          <>
            {copyEventData?.hybrid_type_support ? (
              <Box mb={3} display="flex" alignItems="center" className={classes.warnBlock}>
                <Box>
                  <Info size={15} />
                </Box>
                <Typography variant="body2" component="p">
                  This event will be copied as a virtual event. Contact Support to get this changed
                  to a hybrid event.
                </Typography>
              </Box>
            ) : null}

            <Box className="" mb={3}>
              <InputField
                name="name"
                required
                label="Event Name"
                placeholder={`${copyEventData?.name} (Copy)`}
                type="text"
                value={eventFields.name}
                isLanguageSupport={multiLangObj.isLanguageSupport}
                onChange={(e) => handelChangeOnBasicDetailsFields(e.target.name, e.target.value)}
                maxLength={restrictingCharacerInput.copy_event.name}
                error={errObj.name}
              />
            </Box>
            {copyEventData?.eventType === EEventFormat.WEBINAR ? null : (
              <Box className="" mb={3}>
                <EditorField
                  label="Event Description"
                  name="description"
                  maxLength={restrictingCharacerInput.copy_event.description}
                  value={eventFields.description}
                  error={errObj.description}
                  formViewType="sidebar"
                  descText={getCalculatedCharLegth(
                    restrictingCharacerInput.copy_event.description,
                    eventFields.description
                  )}
                  placeholder="Tell us more about the purpose, goals, and outcomes of the event you are organizing."
                  onChange={(e) => handelChangeOnBasicDetailsFields(e.target.name, e.target.value)}
                />
              </Box>
            )}
            <Box className="" mb={3}>
              <SelectDropdown
                name="timezone"
                value={eventFields.timezone}
                onChange={(value) => {
                  if (errObj['timezone']) props.setErrObj({ ...errObj, ['timezone']: '' });
                  props.setEventFields({ ...eventFields, timezone: value, timezone_id: value?.id });
                }}
                placeholder="Select Time Zone"
                label="time zone"
                isArrayOfObject
                options={timeZones}
                valueKey="id"
                labelKey="description"
                error={errObj.timezone}
                hasSearch
                required
              />
            </Box>
            <Box className="" mb={3}>
              <DateTimePicker
                enableTime
                disableTimeInputs
                label="Start Date"
                dateTimeFormat="d-m-Y G:i K"
                name="start_date_time"
                placeholder="Select Date and Time"
                position="auto"
                descType="helperText"
                  descText={(totalHours > 3 && copyEventData?.eventType === EEventFormat.WEBINAR) ? "Event duration will be 3 hours" : "Event duration will be the same as the original event"}
                required
                minDate={getMinDateForDatePicker(
                  eventFields?.start_time_milli,
                  copyEventTzName?.copyEventTz
                )}
                value={eventFields.start_date_time}
                onChange={(date) => {
                  let dateValue = new Date(date[0]?.getTime());
                  let sourceEventDate = moment(+eventFields?.start_time_milli).tz(
                    copyEventTzName?.copyEventTz
                  );

                  let h = sourceEventDate.hours();
                  let m = sourceEventDate.minute();
                  let s = sourceEventDate.seconds();

                  dateValue.setHours(h);
                  dateValue.setMinutes(m);
                  dateValue.setSeconds(s);
                  handelChangeOnBasicDetailsFields('start_date_time', dateValue.getTime());
                }}
                error={errObj.start_date_time}
              />
            </Box>
            {copyEventData?.eventType === EEventFormat.WEBINAR ? (
              <MsgNotificationComponent
                  msgText={`Base Language is ${langName}.`}
                classType="error"
                mt={1.5}
                icon={<SvgBaseLangIcon />}
                maxWidth="530px"
                className={classes.copyEventMsg}
              />
            ) : (
              <LanguageSettingsCopyEvent
                handleBaseLanguageChange={handleBaseLanguageChange}
                handleLanguageChange={handleLanguageChange}
                supportedLanguages={language}
                languageSupportPlanSidebar={false}
                languageSupportPlan={false}
                label="Language to Support"
                labelDescription={
                  'Select the language(s) supported by your event. Note: This cannot be edited later. You will be required to provide the translation for each language selected.'
                }
                baseLanguageDescription={
                  'The language selected here will be the default event language. The data entered in base language shall be copied as default content until translation is provided.'
                }
                copyEventSupportPlan={
                  userInfo?.UserInfo?.planName === PricingPlan.ENTERPRISE ||
                  userInfo?.UserInfo?.planName === PricingPlan.PRO ||
                  userInfo?.UserInfo?.planName === PricingPlan.DIGITAL_EVENTS ||
                  userInfo?.UserInfo?.planName === PricingPlan.ALL_IN ||
                  userInfo?.UserInfo?.is_language_support
                    ? false
                    : true
                }
                eventLanguages={eventFields.event_languages}
                baseLang={
                  eventFields?.base_language || Number(copyEventTzName?.copyEventBaseLang?.id) || 34
                }
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
