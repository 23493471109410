import { REG_FLOW_STEPS } from '../constants';
import { REG_FLOW_ACTIONS } from './actions/constants';

const iStateWorkFlowModal = {
  isShowSetupModal: false,
  isShowSetupFlowCard: false,
  isWorkFlowStatusLoading: false,
  workflowStatus: {
    completedSteps: 0,
    isVisited: false,
  },
};

const iStateWorkFlowSteps = {
  stepDetails: REG_FLOW_STEPS,
  currentWorkFlowDetails: {},
  localCurrentWorkFlowDetails: {},
  otherWorkFlowDetails: {},
  isGetWorkFlowDetailLoading: false,
  currentStepType: 0, // Landing page,
  isUpdateWorkFlowCompleteLoading: false,
  isStepDetailsLoading: false,
  isMarkCompletedLoading: false,
  isLoggedInStatusLoading: false,
  isLoggedIn: {
    HUBSPOT: null,
    MARKETO: null,
    ELOQUA: null,
    SALESFORCE: null,
    MAILCHIMP: null,
    MSDYNAMICS: null
  },
  amplitudeData: {}
};

const iState = {
  ...iStateWorkFlowModal,
  ...iStateWorkFlowSteps,
};

const registrationFlowReducer = (state = { ...iState }, action) => {
  switch (action.type) {
    case REG_FLOW_ACTIONS.IS_SHOW_WORKFLOW_MODAL_UPDATE:
      return { ...state, isShowSetupModal: action.payload };
    case REG_FLOW_ACTIONS.IS_SHOW_WORKFLOW_MODAL:
      return { ...state, isShowSetupModal: action.payload, isShowSetupFlowCard: !action.payload };
    case REG_FLOW_ACTIONS.IS_WORKFLOW_STATUS_LOADING:
      return { ...state, isWorkFlowStatusLoading: action.payload };
    case REG_FLOW_ACTIONS.WORKFLOW_STATUS:
      return { ...state, workflowStatus: action.payload };
    case REG_FLOW_ACTIONS.GET_WORKFLOW_DETAIL_LOADING:
      return { ...state, isGetWorkFlowDetailLoading: action.payload };
    case REG_FLOW_ACTIONS.GET_WORKFLOW_DETAIL_CURRENT:
      return { ...state, currentWorkFlowDetails: action.payload };
    case REG_FLOW_ACTIONS.GET_WORKFLOW_DETAIL:
      return { ...state, localCurrentWorkFlowDetails: action.payload };
    case REG_FLOW_ACTIONS.UPDATE_CURRENT_WORKFLOW_LOADING:
      return { ...state, isUpdateWorkFlowCompleteLoading: action.payload };
    case REG_FLOW_ACTIONS.GET_STEP_DETAIL_LOADING:
      return { ...state, isStepDetailsLoading: action.payload };
    case REG_FLOW_ACTIONS.UPDATE_STEP_DETAILS:
      return { ...state, stepDetails: action.payload };
    case REG_FLOW_ACTIONS.SET_MARK_COMPLETED_LOADING:
      return { ...state, isMarkCompletedLoading: action.payload };
    case REG_FLOW_ACTIONS.SET_LOGGED_IN_STATUS_LOADING:
      return { ...state, isLoggedInStatusLoading: action.payload };
    case REG_FLOW_ACTIONS.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: {
          ...state.isLoggedIn,
          ...action.payload
        }
      }
    case REG_FLOW_ACTIONS.SET_WORKFLOW_DETAIL_OTHER:
      return { ...state, otherWorkFlowDetails: action.payload }
    case REG_FLOW_ACTIONS.RESET:
      return {
        ...iState
      }
    case REG_FLOW_ACTIONS.SET_AMPLITUDE_DATA:
      return {
        ...state,
        amplitudeData: {
          ...state.amplitudeData,
          ...action.payload
        }
      }
    case REG_FLOW_ACTIONS.RESET_AMPLITUDE_DATA:
      return {
        ...state,
        amplitudeData: {}
      }
    case REG_FLOW_ACTIONS.RESET_WORKFLOW_STATUS:
      return {
        ...state,
        workflowStatus: iStateWorkFlowModal.workflowStatus
      }
    default:
      return state;
  }
};

export default registrationFlowReducer;
