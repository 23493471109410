/* eslint-disable */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSource } from 'lib/custom-common-hooks';

import Box from '@material-ui/core/Box';

import InputField from 'custom-components/form-fields/inputField';
import MapView, { mapScriptLoad } from 'custom-components/mapView';
import SelectDropdown from 'custom-components/form-fields/selectDropdown';
import countryList from 'models/country';
import { Search } from 'react-feather';
import projectSetting from 'settings';
import { Typography } from '@material-ui/core';
import CustomToolTip from 'custom-components/customToolTip';
import SvgInfoIconCustom from 'icons/svgInfoIconCustom';

// It'll take the google location as data and format it in a way of using it
export const makePlaceData = (data) => {
    let finalObject = {
        address: '',
        province: '',
        city: '',
        country: '',
        zip: '',
        lat: '',
        lon: '',
    };
    if (data.address_components) {
        data.address_components.forEach((ele) => {
            let label = '';
            switch (ele.types[0]) {
                case 'administrative_area_level_1':
                    label = 'province';
                    break;
                case 'administrative_area_level_2':
                    label = 'city';
                    break;
                case 'country':
                    label = 'country';
                    break;
                case 'postal_code':
                    label = 'zip';
                    break;
                case 'sublocality_level_1':
                case 'route':
                case 'locality':
                case 'political':
                    label = 'address';
                    break;
                default:
                    break;
            }

            if (label) {
                if (label === 'address' && finalObject[label])
                    finalObject[label] = `${finalObject[label]}, ${ele.long_name}`;
                else finalObject[label] = ele.long_name;
            }
        });
        finalObject = {
            ...finalObject,
            lat: String(data.geometry.location.lat()),
            lon: String(data.geometry.location.lng()),
        };

        if (data.name) {
            finalObject.address = data.name;
        }
    }

    return finalObject;
};

export function EventDetailsAddress(props) {
    const {
        address: addressProps = {},
        error: addressError,
        handleChangeLocation,
        handleChangeMap,
    } = props;

    // address - local address object which contains all the address fields such as address, city, province, country, zip, latitude, longitude
    const [address, setAddress] = useState(addressProps);

    // zoom is to set the zoom for google map
    const [zoom, setZoom] = useState(10);

    // default latitude and longitude for map
    const { lat: defaultLat, lng: defaultLng } = projectSetting.defaultHybridEventAddress;

    const source = useSource();

    // autocompleteInput is for google location search dropdown
    const autocompleteInput = React.useRef(null);

    const { handleAddressChange } = props;
    const { google } = window;


    // this effect is to initialize the google script to the DOM and to initialize the location search dropdown
    React.useEffect(() => {
        source.autocomplete = null;
        source.autocompleteLsr = null;

        // mapScriptLoad will load the google map script and adding it to DOM
        mapScriptLoad((res) => {
            if (!window.google) return false;

            // handlePlaceChanged will trigger when organiser type anything on location search field
            const handlePlaceChanged = () => {
                const place = source.autocomplete.getPlace();
                if (place.address_components && place.geometry && place.geometry.location) {

                    // handleChangeLocation will set address parent component
                    if (handleChangeLocation) handleChangeLocation(place);

                    // addressObj - storing the formatted data in order to store it on local state
                    const addressObj = makePlaceData(place);
                    setZoom(16);
                    setAddress(addressObj);
                }
            };

            // initializing the google map location search with local input
            source.autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current);

            // adding listener to the location search handle change
            source.autocompleteLsr = google.maps.event.addListener(
                source.autocomplete,
                'place_changed',
                handlePlaceChanged
            );
            return null;
        });

        return () => {
            // removing google search and location listeners
            if (window.google) {
                if (source.autocompleteLsr) google.maps.event.removeListener(source.autocompleteLsr);
                if (source.autocomplete) google.maps.event.clearInstanceListeners(source.autocomplete);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [google]);

    // resetOnFIeldChange is to reset the fields when the effected fields got changed by organiser
    const resetOnFIeldChange = (fieldval) => {
        switch (fieldval) {
            case 'city':
                address.zip = "";
                break;
            case 'province':
                address.city = "";
                address.zip = "";
                break;
            case 'country':
                address.province = "";
                address.city = "";
                address.zip = "";
                break;
            default:
                break;
        }
    };

    // handleFieldChange - will trigger when organiser changes/adds data in address fields
    const handleFieldChange = (value, name) => {
        if (!value) {
            const addressObj = {
                ...address,
            };
            addressObj[name] = '';
            setAddress(addressObj);
        } else {
            resetOnFIeldChange(name);
            setAddress({ ...address, [name]: value });
        }
    };

    // fetchStateOptions is to fetch state options based on selected country
    const fetchStateOptions = useCallback((selectedCountry) => {
        let options = [];
        if (selectedCountry) {
            const filteredCountry = countryList.find(
                (countryData) => countryData.countryName === selectedCountry
            );
            if (filteredCountry && filteredCountry.regions) {
                options = filteredCountry.regions;
            }
        }
        return options;
    }, []);

    /*
     * handleLatLanChange will trigger when organiser clicks on google map to select the location
     * it'll map address and map current zoom as parameters from map component
    */
    const handleLatLanChange = useCallback((mapAddress, selectedZoom) => {
        if (handleChangeLocation) handleChangeMap(mapAddress);
        setAddress(mapAddress);
        setZoom(selectedZoom);
    }, []);

    /*
     * center - to set the center for the google map
     * it'll initialize based on default latitude and longitude
     * it'll change when organiser change the address
    */
    const center = useMemo(
        () => ({
            lat: Number(address.lat || defaultLat),
            lng: Number(address.lon || defaultLng),
        }),
        [address.lat, address.lon, defaultLat, defaultLng]
    );
    const { country } = props

    // changing the country field in address object based on organiser selection from the parent component
    useEffect(() => {
        setAddress(prev => ({ ...prev, country }))
    }, [country])

    // this effect is to update the address when organiser changes any address field
    useEffect(() => {
        if (address) {
            handleAddressChange(address);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    return (
        <>

            <Box mt={3}>
                <Box display="flex" alignItems="center" mb={0.5}>
                    <Typography variant="body2" component="span" color="textSecondary">
                        Select your location on the map
                    </Typography>
                    <CustomToolTip
                        placement="top"
                        title={
                            <Typography variant="caption" component="span">
                                This location will be displayed to the attendees on reception and emails
                            </Typography>
                        }
                    >
                        <Box display="flex" alignItems="center" justifyContent="center" ml={1}>
                            <SvgInfoIconCustom />
                        </Box>
                    </CustomToolTip>
                </Box>
                <InputField
                    placeholder="Search Location"
                    type="text"
                    id="google-map-address"
                    inputRef={autocompleteInput}
                    error={addressError?.lat || addressError?.lon}
                    onChange={() => { }}
                    startAdornment
                    startIconAdornment
                    startAdornmentIcon={<Search size={22} />}
                />
            </Box>

            <Box
                width="100%"
                height="300px"
                className="mapBlock"
                mt={1}
                style={{ overflow: `hidden` }}>
                <MapView
                    id="exhibition-map"
                    zoom={zoom}
                    center={center}
                    // className={mapLocation}
                    onChange={handleLatLanChange}
                />
            </Box>

            <Box mt={3}>
                <InputField
                    name="address"
                    label="Address"
                    placeholder="Example : 123, Main Street"
                    type="text"
                    value={address.address}
                    error={addressError?.address}
                    onChange={(e) => e.target && handleFieldChange(e.target.value, 'address')}
                />
            </Box>

            <Box mt={3}>
                <SelectDropdown
                    name="State/Province"
                    size="large"
                    inputClassName="bgWhite"
                    onChange={(e) => handleFieldChange(e.name, 'province')}
                    placeholder="Select State/Province"
                    label="State/Province"
                    valueKey="name"
                    labelKey="name"
                    isArrayOfObject
                    hasSearch
                    error={addressError?.province}
                    value={address.province ? { name: address.province } : null}
                    options={fetchStateOptions(address.country)}
                />
            </Box>

            <Box mt={3}>
                <InputField
                    name="City"
                    label="City"
                    placeholder="Example : New York City"
                    type="text"
                    value={address.city ? address.city : ''}
                    error={addressError?.city}
                    onChange={(e) => e.target && handleFieldChange(e.target.value, 'city')}
                />
            </Box>

            <Box mt={3}>
                <InputField
                    name="zipCode"
                    label="ZIP/Postal Code"
                    placeholder="Example : 10119"
                    type="text"
                    value={address.zip ? String(address.zip) : ''}
                    error={addressError?.zip}
                    onChange={(e) => e.target && handleFieldChange(e.target.value, 'zip')}
                />
            </Box>
        </>
    );
}
