import { useState } from 'react';

const useScrollCheck = () => {
  const [isScrolling, setIsScrolling] = useState(false);
  let timer = null;

  const handleScroll = () => {
    if (timer) {
      // if there is already a timer in process cancel it
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      setIsScrolling(false);
    }, 500);
    if (isScrolling === false) {
      setIsScrolling(true);
    }
  };
  return { handleScroll, isScrolling };
};

export default useScrollCheck;
