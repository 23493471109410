export const TEMPLATE_PREVIEW_MODAL_TOGGLE = 'TEMPLATE_PREVIEW_MODAL_TOGGLE';
export const SET_PREVIEW_TYPE = 'SET_PREVIEW_TYPE';
export const SET_TEMPLATE_HTML = 'SET_TEMPLATE_HTML';
export const SET_TEMPLATE_CAMPAIGN_ID = 'SET_TEMPLATE_CAMPAIGN_ID';

export const templatePreviewToggle = (value) => {
  return {
    type: TEMPLATE_PREVIEW_MODAL_TOGGLE,
    data: value,
  };
};

export const setPreviewType = (value) => {
  return {
    type: SET_PREVIEW_TYPE,
    data: value,
  };
};

export const setTemplateHtml = (value) => {
  return {
    type: SET_TEMPLATE_HTML,
    data: value,
  };
};

export const setTemplateCampaignId = (value) => {
  return {
    type: SET_TEMPLATE_CAMPAIGN_ID,
    data: value,
  };
};
