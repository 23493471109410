/* eslint-disable no-param-reassign */
import {
  TEMPLATE_MODAL_TOGGLE,
  SET_ACTIVE_TEMPLATE,
  SET_ACTIVE_TEMPLATE_NAME,
  TEMPLATE_NAME_EDIT_TOGGLE,
  SET_TEMPLATE_ACTION_TYPE,
  SET_CAMPAIGN_ID,
  SET_TEMPLATE_ID,
  SET_TEMPLATE_OBJ,
  SET_TEMPLATE_IS_PRIMARY,
  SET_CAMPAIGN_DETAILS,
  RESET_TEMPLATE_DETAILS,
} from 'redux/actions/template-details-action';

const initialState = {
  isModalOpen: false,
  activeTemplate: null,
  activeTemplateName: '',
  activeTemplateNameIsEdit: false,
  actionType: '',
  campaignId: '',
  templateId: '',
  templateObj: null,
  isPrimary: false,
  isDefaultAction: false,
  isEnableCTA: false,
  mergeTag: {},
  campaignName: '',
  isCustomCampaign: true,
  emailSubject: '',
  // other keys
  isWhiteLabel: '',
  applyTheme: false,
};

const templateModalInfo = (state = { ...initialState }, action) => {
  switch (action.type) {
    case RESET_TEMPLATE_DETAILS:
      state = { ...initialState };
      break;
    case TEMPLATE_MODAL_TOGGLE:
      state = { ...state, ...action.data };
      break;
    case SET_ACTIVE_TEMPLATE_NAME:
      state = { ...state, ...action.data };
      break;
    case SET_ACTIVE_TEMPLATE:
      state = { ...state, ...action.data };
      break;
    case TEMPLATE_NAME_EDIT_TOGGLE:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_ACTION_TYPE:
      state = { ...state, ...action.data };
      break;
    case SET_CAMPAIGN_ID:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_ID:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_OBJ:
      state = { ...state, ...action.data };
      break;
    case SET_TEMPLATE_IS_PRIMARY:
      state = { ...state, ...action.data };
      break;
    case SET_CAMPAIGN_DETAILS:
      state = { ...state, ...action.data };
      break;
    default:
      break;
  }
  return state;
};

export default templateModalInfo;
