export const SET_EVENT_DATA = 'SET_EVENT_DATA';
export const REMOVE_EVENT_DATA = 'REMOVE_EVENT_DATA';
export const SET_CREDITS_TAB_VISIBILITY = "SET_CREDITS_TAB_VISIBILITY";

export const setEventData = (eventData) => {
  return {
    type: SET_EVENT_DATA,
    data: eventData,
  };
};

export const removeEventData = () => {
  return {
    type: REMOVE_EVENT_DATA,
  };
};

export const updateCreditsTabVisibility = (isVisible) => {
    return {
        type: SET_CREDITS_TAB_VISIBILITY,
        payload: isVisible
    }
}
