import React from 'react';

import { Box, Typography } from '@material-ui/core';
import SwitchField from 'custom-components/form-fields/switch';
import { useDispatch, useSelector } from 'react-redux';

import { updateSessionEngagementRaiseHand } from 'redux/api-service/embed-apps';

import { useRouteMatch } from 'react-router-dom';

import { useStyles } from './styles';
import { sessionEngagementSelector } from 'redux/selectors';

const RaiseHandEnabled = ({ desc = '', className = '' }) => {
  const classes = useStyles();
  const { isRaiseHandEnabled, isRaiseHandLoading } = useSelector(sessionEngagementSelector);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const handleRaiseHandEnabled = (e) => {
    const { checked } = e.target;
    const dataValue = {
      data: {
        is_raise_hand: checked ? 1 : 0,
        is_global_eng: 1,
      },
    };
    dispatch(
      updateSessionEngagementRaiseHand(dataValue, {
        eventid: match.params.eventId,
        type_id: match.params.sessionId,
      })
    );
  };

  return (
    <Box className={`reactionEnabledWrapper ${className}`}>
      <Box display="flex" justifyContent="space-between">
        <Box width="90%">
          <Box className={classes.reactionEnabled}>
            <Typography variant="body2" component="p" color="textPrimary" className={classes.bold}>
              Raise Hand
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              {desc}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.switchBtnStyle} mr={2}>
          <SwitchField
            name="isRaiseHandEnabled"
            disabled={isRaiseHandLoading}
            checked={isRaiseHandEnabled === 1}
            onChange={handleRaiseHandEnabled}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RaiseHandEnabled;
