/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgMinusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <path
        d="M12.6667 8L3.33341 8"
        stroke="currentcolor"
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
