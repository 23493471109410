import React from 'react';

const style = {
  a: {
    fill: 'none',
    stroke: '#353535',
    strokeLinecap: 'round',
    strokelinejoin: 'round',
    strokewidth: '1.5px',
  },
};
export default function SvgExternalEncoIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props} viewBox="0 0 17.5 15.9">
      {' '}
      <g transform="translate(-1.25 -2.25)">
        <path
          style={style.a}
          d="M4.4,13.4H3.6A1.546,1.546,0,0,1,2,11.914V4.486A1.546,1.546,0,0,1,3.6,3H16.4A1.546,1.546,0,0,1,18,4.486v7.429A1.546,1.546,0,0,1,16.4,13.4h-.8"
        />
        <path style={style.a} d="M12,15l5,6H7Z" transform="translate(-2 -3.6)" />
      </g>
    </svg>
  );
}
