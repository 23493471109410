import React from 'react';
import SvgReceptionIcon from '../icons/svgReception';
import SvgAgendaIcon from '../icons/svgAgenda';
import { Settings, Airplay, Database, FileText, Layout } from 'react-feather';
import SvgAnalytics from '../icons/svgAnalytics';
import SvgMeetingsIcon from 'icons/svgMeetings';
import SvgMailIcon from 'icons/svgMailIcon';
import SvgIntegrations from 'icons/svgIntegrations';
import SvgEngagementTwoIcon from 'icons/svgEngagementTwo';
import ContentHubIcon from 'icons/ContentHubIcon'

export const webinarNavLinks = [
  {
    id: 0,
    name: 'Overview',
    icon: <SvgReceptionIcon height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/',
  },
  {
    id: 2,
    name: 'REGISTRATION',
    icon: <FileText size={21} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/registration',
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'SETUP FORM',
        hasEventIdAndOrganiserId: true,
        url: '/registration/setup-form',
        communityVersion: 2,
      },
      {
        name: 'LANDING PAGE',
        hasEventIdAndOrganiserId: true,
        url: '/registration/landing-page',
        communityVersion: 2,
      },
      {
        name: 'UTM code',
        hasEventIdAndOrganiserId: true,
        url: '/registration/utm',
        communityVersion: 2,
      },
    ],
    isShow: true,
  },

  {
    id: 3,
    name: 'SESSIONS',
    icon: <SvgAgendaIcon height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/sessions',
    subUrl: '/session',
  },
  {
    id: 4,
    name: 'PEOPLE',
    icon: <SvgMeetingsIcon height="25px" width="25px" viewBox="0 0 30 30" />,
    hasEventIdAndOrganiserId: true,
    url: '/people',
  },
  {
    id: 17,
    name: 'EMAIL MANAGER',
    icon: <SvgMailIcon height="20px" width="20px" />,
    hasEventIdAndOrganiserId: true,
    url: '/email-builder',
    inPersonDisabledView: true,
  },
  {
    id: 9,
    name: 'ENGAGEMENT',
    icon: <SvgEngagementTwoIcon height="28px" width="28px" />,
    hasEventIdAndOrganiserId: true,
    url: '/engagement',
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'LEAD SCORE',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/lead-score',
      },
      {
        name: 'SURVEY',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/survey',
      },
      {
        name: 'QUIZ',
        hasEventIdAndOrganiserId: true,
        url: '/engagement/quiz',
      },
    ],
  },

  {
    id: 10,
    name: 'BRANDING',
    icon: <Layout size={22} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/brand-your-events',
    // isShow: true,
    // isSubMenuOpen: false,
    // subMenu: [
    //   {
    //     name: 'BRAND YOUR EVENT',
    //     hasEventIdAndOrganiserId: true,
    //     url: '/brand-your-events',
    //   },
    // ],
  },
  {
    id: 12,
    name: 'ANALYTICS',
    icon: <SvgAnalytics height="25px" width="25px" />,
    hasEventIdAndOrganiserId: true,
    url: '/analytics/registration',
    subUrl: '/analytics',
  },
  {
    id: 13,
    name: 'Integrations',
    icon: <SvgIntegrations />,
    hasEventIdAndOrganiserId: true,
    url: '/integrations',
    isShow: true,
    isSubMenuOpen: false,
    subMenu: [
      {
        name: 'Connected Apps',
        hasEventIdAndOrganiserId: true,
        url: '/integrations/connected-apps',
      },
    ],
  },
  {
    id: 13,
    name: 'SETTINGS',
    icon: <Settings size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/settings/login-options',
    keyDivider: true,
    subUrl: '/settings',
  },
  {
    id: 14,
    name: 'RECORDING',
    icon: <Airplay size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/recording',
    inPersonDisabledView: true,
  },
  {
    id: 16,
    name: 'CONTENT HUB',
    icon: <ContentHubIcon size={20} strokeWidth={1} />,
    isNew:true,
    hasEventIdAndOrganiserId: true,
    url: 'content-hub',
    inPersonDisabledView: true,
    isInMonoRepo:true,
  },
  {
    id: 15,
    name: 'FILE LOGS',
    icon: <Database size={20} strokeWidth={1} />,
    hasEventIdAndOrganiserId: true,
    url: '/file-logs',
  },
];
