/* eslint-disable  */

import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import SkeletonLoaders from 'custom-components/skeletonLoader';
import CircularProgress from '@material-ui/core/CircularProgress';

// const useStyles = makeStyles((theme) => ({
//     circularProgress: {
//         '& .progressing':
//         {
//             display: 'flex',
//         },
//     },
// }));

const circularProgress = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export default function CircularProgressBar(props) {
  const { isLoading } = props;
  const classes = circularProgress();

  const [progress, setProgress] = React.useState(0);

  return (
    <Box className={classes.circularProgressWrapper} display="flex">
      {isLoading ? (
        <Box display="flex" color="white" alignItems="center" component="span">
          <SkeletonLoaders variant="text" height="36px" width="36px" />
        </Box>
      ) : (
        <Box position="relative" display="inline-flex" className={classes.root}>
          <CircularProgress
            variant="determinate"
              className={`${classes.bottom} bottomBar`}
            size={props.size}
            thickness={3}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="determinate"
            //disableShrink
              className={`${classes.top} topBar`}
            classes={{
              circle: classes.circle,
            }}
            value={props.value}
            size={props.size}
            thickness={3}
            {...props}
          />

          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center">
              <Box display="flex" className="barIcon"> {props.icon} </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
