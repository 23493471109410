import React from 'react';

export default function SvgLightBulb(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <g opacity="0.8">
        <path
          d="M14.2417 2.35002C13.4752 1.71967 12.5778 1.26837 11.6147 1.02905C10.6517 0.789732 9.64733 0.768424 8.67499 0.966683C7.38031 1.2282 6.19248 1.86841 5.26223 2.80608C4.33197 3.74376 3.70122 4.93663 3.44999 6.23335C3.26653 7.20562 3.29987 8.20639 3.54763 9.16429C3.79539 10.1222 4.25146 11.0136 4.88333 11.775C5.46973 12.4367 5.80585 13.2829 5.83333 14.1667V16.6667C5.83333 17.3297 6.09672 17.9656 6.56556 18.4345C7.0344 18.9033 7.67028 19.1667 8.33333 19.1667H11.6667C12.3297 19.1667 12.9656 18.9033 13.4344 18.4345C13.9033 17.9656 14.1667 17.3297 14.1667 16.6667V14.325C14.1946 13.3493 14.5531 12.412 15.1833 11.6667C16.2876 10.3007 16.8081 8.55403 16.6318 6.80637C16.4555 5.05871 15.5965 3.45126 14.2417 2.33335V2.35002ZM12.5 16.6667C12.5 16.8877 12.4122 17.0997 12.2559 17.2559C12.0996 17.4122 11.8877 17.5 11.6667 17.5H8.33333C8.11231 17.5 7.90035 17.4122 7.74407 17.2559C7.58779 17.0997 7.49999 16.8877 7.49999 16.6667V15.8334H12.5V16.6667ZM13.8917 10.6334C13.0537 11.6272 12.5649 12.8684 12.5 14.1667H10.8333V11.6667C10.8333 11.4457 10.7455 11.2337 10.5892 11.0774C10.433 10.9211 10.221 10.8334 9.99999 10.8334C9.77898 10.8334 9.56702 10.9211 9.41074 11.0774C9.25446 11.2337 9.16666 11.4457 9.16666 11.6667V14.1667H7.49999C7.478 12.8901 7.00578 11.6623 6.16666 10.7C5.61303 10.0367 5.24074 9.2412 5.08608 8.39114C4.93143 7.54108 4.9997 6.66545 5.28423 5.84963C5.56876 5.0338 6.05984 4.30564 6.70959 3.73612C7.35934 3.16661 8.14559 2.77518 8.99166 2.60002C9.71879 2.4503 10.4701 2.46439 11.1911 2.64124C11.9121 2.8181 12.5847 3.15329 13.16 3.62248C13.7353 4.09167 14.199 4.68308 14.5172 5.35378C14.8355 6.02448 15.0004 6.75764 15 7.50002C15.0062 8.64157 14.6142 9.74957 13.8917 10.6334Z"
          fill="#FFA96C"
        />
      </g>
    </svg>
  );
}
