/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable one-var */
/* eslint-disable no-var */
import axios from 'axios';
import ProjectSetting from 'settings';
import { GET_SIGNED_URL } from 'api-setup/api-endpoints';
import { POSTAPIWITHCUSTOMDOMAIN } from 'api-setup/api-helper';

export function generateAllowTypes(fileTypes) {
  const allowedType = [];
  fileTypes.forEach((ele) => {
    switch (ele) {
      case 'PDF':
        allowedType.push('.pdf');
        break;
      case 'IMAGE':
        allowedType.push('.png');
        allowedType.push('.jpg');
        allowedType.push('.jpeg');
        break;
      case 'DOC':
        allowedType.push('.doc');
        allowedType.push('.docx');

        break;
      case 'PPT':
        allowedType.push('.ppt');
        allowedType.push('.pptx');
        break;
      case 'EXCEL':
        allowedType.push('.xlsx');
        allowedType.push('.xls');
        // allowedType.push('.csv');
        break;
      default:
        break;
    }
  });
  return allowedType;
}

export function blobToFile(theBlob, fileName) {
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return blobToFile(new Blob([u8arr], { type: mime }), filename);
}

export function fileToDataUrl(file, cb) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function () {
    cb(reader.result);
  };
}
export function fileToDataUrls(files, cb) {
  var reader = new FileReader();
  for (let i = 0; i < files.length; i++) {
    reader.readAsDataURL(files[i]);
    reader.onloadend = function () {
      cb(reader.result);
    };
  }
}

export function getCalculatedCharLegth(maxLength, description) {
  let fieldDescription;
  let finalLength;
  if (description === null || description === undefined) return false;
  else {
    fieldDescription = description.toString();
  }

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  if (typeof description !== 'number') {
    const strippedHtmlString = fieldDescription.replace(/(<([^>]+)>)/gi, '');
    const removeEnterKeySpace = strippedHtmlString.replace(/(&nbsp;+)/gi, '');
    // console.log("getcalc",removeEnterKeySpace.length)
    finalLength = maxLength - removeEnterKeySpace.length;
  } else {
    finalLength = maxLength - description;
  }

  // let finalLength = maxLength - length;
  let resultString = '';
  if (finalLength > 1) {
    resultString = `${finalLength} Characters or less`;
  } else {
    resultString = `${finalLength} Character`;
  }
  if (finalLength < 0) {
    resultString = `${finalLength} Characters - Error - Character limit has crossed. Content can't be saved`;
  }
  // if (finalLength > 1) {
  //     resultString = finalLength;
  // } else {
  //     resultString = finalLength;
  // }
  return resultString;
}

export const getFileUploadDetails = (file) => {
  const { name = '', type = '' } = file;
  const returnData = {
    extension: '',
    contentType: '',
  };
  if (name) {
    returnData.extension = name.substring(name.lastIndexOf('.') + 1, name.length) || name;
  } else {
    const typePieces = type?.split("/");
    returnData.extension = typePieces?.length ? typePieces.at(-1) : null;
  }
  if (type) returnData.contentType = type;
  if(['jpg','jpeg'].includes(returnData.extension)) returnData.contentType = 'image/jpeg';
  return returnData;
};

export const updatedUploadS3 = (file, options, cb) => {
  const { fileConfig, progressCB, contentType = '' } = options;
  const { uploadURL = '', fileName = '', width = '' } = fileConfig || {};

  const newFile = new File([file], fileName, { type: contentType });

  let config = {
    method: 'PUT',
    url: uploadURL,
    data: new Blob([newFile], { type: contentType }),
  };

  if (progressCB) {
    config = {
      ...config,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        progressCB(percentCompleted);
      },
    };
  }

  if (contentType) {
    config = {
      ...config,
      headers: {
        'Content-Type': contentType,
      },
    };
  }
  axios(config)
    .then(() => {
      if (cb) {
        if (width) cb({ [width]: fileName });
        else cb(fileName);
      }
    })
    .catch(() => {
      if (cb) cb('FAIL');
    });
};

export const updatedSignInRequest = async (payload, header) => {
  let returnData = [];
  const customURLV2 = ProjectSetting.customBaseURL2;
  const bodyData = {
    payload: {
      data: payload,
    },
  };
  const resp = await POSTAPIWITHCUSTOMDOMAIN(
    false,
    GET_SIGNED_URL,
    null,
    header,
    bodyData,
    customURLV2
  );
  const { status, data } = resp;
  if (status === 200) returnData = data?.urlLists || [];
  return returnData;
};

export const uploadS3Promise = (valueFile, uploadOption) => {
  return new Promise((resolve, reject) => {
    updatedUploadS3(valueFile, uploadOption, (data) => {
      if (!data || data === 'FAIL') {
        // failed
        reject();
      } else {
        // success
        resolve();
      }
    });
  });
};

const getMimeType = (imageAsBase64) => {
  const arr = imageAsBase64.split(',');
  const mimeEle = arr && Array.isArray(arr) && arr.length ? arr[0] : '';
  const mimeArr = mimeEle ? mimeEle.match(/:(.*?);/) : [];
  const mime = mimeArr && Array.isArray(mimeArr) && mimeArr.length && mimeArr[1] ? mimeArr[1] : '';
  return mime;
};

export const dataURLtoFileV2 = (dataurl, filename, options) => {
  const arr = dataurl.split(',');
  const mime = getMimeType(dataurl);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return blobToFile(new Blob([u8arr], { type: mime }), filename, options);
};
