import { Box, colors, Grid, Typography } from '@material-ui/core';
import Checkbox2 from 'custom-components/form-fields/checkbox2';
import LearnMoreLink from 'learnMoreLinks';
import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { copyEventSelector, userInfoSelector } from 'redux/custom-selector';
import { useStyles } from '../../style';
import CopyEventOptionLoader from '../basic-details/copyEventOptionLoader';
// import { Info } from 'react-feather';
import CustomToolTip from 'custom-components/customToolTip';
import InfoMsgComponent from 'pages/integrations/infoMsgComponent';
import { PricingPlan } from 'constants/pricingPlan';
import { EEventFormat } from 'pages/CreateNewEvent/common/constants';

export default function ({ modules, setModules, ...props }) {
  const { UserInfo } = useSelector(userInfoSelector, shallowEqual);

  const classes = useStyles();
  const copyEventData = useSelector(copyEventSelector, shallowEqual);

  const isLanguageSupport = useMemo(() => {
    if (
      copyEventData?.event_languages &&
      Array.isArray(copyEventData?.event_languages) &&
      copyEventData?.event_languages?.length > 1
    ) {
      return true;
    }
    if (
      props.eventFields?.event_languages &&
      Array.isArray(props.eventFields?.event_languages) &&
      props.eventFields?.event_languages?.length > 1
    ) {
      return true;
    }
    return false;
  }, [copyEventData.event_languages, props.eventFields.event_languages]);

  useEffect(() => {
    props.setLoadState({ ...props.loadingState, initLoader: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {props.loadingState.initLoader ? (
        <CopyEventOptionLoader />
      ) : (
        <>
          <Box>
            {(UserInfo.planName === PricingPlan.WEBINAR ||
              UserInfo.planName === PricingPlan.WEBINAR_LITE) &&
            copyEventData?.eventType === EEventFormat.VIRTUAL ? (
              <InfoMsgComponent
                className="infoMsg"
                preTitle="IMPORTANT"
                title="You are duplicating a virtual event. It will consume one virtual event credit."
              />
            ) : null}
            <Typography component="p" variant="body2" color={colors.textSecondary} display="block">
              Choose the data you want to copy from the event{' '}
              <strong>{`${copyEventData?.name}`}.</strong>
              <Box
                component="a"
                ml={0.3}
                display="block"
                onClick={() => window.open(LearnMoreLink.copyYourExistingEvent, '_blank')}
                variant="body2"
                className="text-underline  cursor-pointer">
                Learn More
              </Box>
            </Typography>
          </Box>
          <Box className={classes.copyEventOptionsWrapper}>
            <Grid container spacing={5}>
              <Grid item xs={8}>
                <Box className="copyEventOptionsLeft">
                  <Box className="copyEventOptions">
                    <Checkbox2
                      color="primary"
                      label="Sessions"
                      checked={modules.sessions}
                      onChange={(event) =>
                        setModules({
                          ...modules,
                          sessions: event.target.checked ? 1 : 0,
                          speakers: event.target.checked ? 1 : modules.speakers,
                        })
                      }
                    />
                  </Box>
                  {modules.sessions ? (
                    <CustomToolTip
                      placement="top"
                      title="Some items will be auto-selected because of dependencies in the original event.">
                      <Box className="copyEventOptions disabled">
                        <Checkbox2
                          color="primary"
                          label="Speakers"
                          checked={modules.speakers}
                          onChange={(event) =>
                            setModules({
                              ...modules,
                              speakers: event.target.checked ? 1 : modules.sessions ? 1 : 0,
                            })
                          }
                        />
                      </Box>
                    </CustomToolTip>
                  ) : (
                    <Box className="copyEventOptions">
                      <Checkbox2
                        color="primary"
                        label="Speakers"
                        checked={modules.speakers}
                        onChange={(event) =>
                          setModules({
                            ...modules,
                            speakers: event.target.checked ? 1 : modules.sessions ? 1 : 0,
                          })
                        }
                      />
                    </Box>
                  )}
                  <Box className="copyEventOptions">
                    <Checkbox2
                      color="primary"
                      label="Lounges"
                      checked={modules.lounges}
                      onChange={(event) =>
                        setModules({
                          ...modules,
                          lounges: event.target.checked ? 1 : 0,
                          virtualbooths: event.target.checked ? 1 : modules.virtualbooths,
                        })
                      }
                    />
                  </Box>
                  {modules.lounges ? (
                    <CustomToolTip
                      placement="top"
                      title="Some items will be auto-selected because of dependencies in the original event.">
                      <Box className="copyEventOptions disabled">
                        <Checkbox2
                          color="primary"
                          label="Virtual Booths"
                          checked={modules.virtualbooths}
                          onChange={(event) =>
                            setModules({
                              ...modules,
                              virtualbooths: event.target.checked ? 1 : modules.lounges ? 1 : 0,
                            })
                          }
                        />
                      </Box>
                    </CustomToolTip>
                  ) : (
                    <Box className="copyEventOptions">
                      <Checkbox2
                        color="primary"
                        label="Virtual Booths"
                        checked={modules.virtualbooths}
                        onChange={(event) =>
                          setModules({
                            ...modules,
                            virtualbooths: event.target.checked ? 1 : modules.lounges ? 1 : 0,
                          })
                        }
                      />
                    </Box>
                  )}
                  <Box className="copyEventOptions">
                    <Checkbox2
                      color="primary"
                      label="Branding"
                      checked={modules.branding}
                      onChange={(event) =>
                        setModules({ ...modules, branding: event.target.checked ? 1 : 0 })
                      }
                    />
                  </Box>
                  <Box className="copyEventOptions">
                    <Checkbox2
                      color="primary"
                      label="Settings"
                      checked={modules.settings}
                      onChange={(event) =>
                        setModules({ ...modules, settings: event.target.checked ? 1 : 0 })
                      }
                    />
                  </Box>

                  <Box className="copyEventOptions">
                    <Checkbox2
                      color="primary"
                      label="Email Templates"
                      checked={Boolean(modules?.campaigns)}
                      onChange={(event) =>
                        setModules({ ...modules, campaigns: event.target.checked ? 1 : 0 })
                      }
                    />
                  </Box>

                  {isLanguageSupport ? (
                    <CustomToolTip
                      placement="top"
                      title="Custom landing page is not available for events that support multiple languages. Your original event, and/or your new event, is a multilingual event.">
                      <Box className="copyEventOptions disabled">
                        <Checkbox2
                          color="primary"
                          label="Custom Landing Page"
                          checked={modules.custom_landing_pages}
                        />
                      </Box>
                    </CustomToolTip>
                  ) : (
                    <Box className="copyEventOptions">
                      <Checkbox2
                        color="primary"
                        label="Custom Landing Page"
                        checked={modules.custom_landing_pages}
                        onChange={(event) =>
                          setModules({
                            ...modules,
                            custom_landing_pages: event.target.checked ? 1 : 0,
                          })
                        }
                      />
                    </Box>
                  )}

                  <Box className={classes.navCountLimitBox} mb={2}>
                    <Typography variant="body2" component="p">
                      Tickets, Rooms, Meetings, Engagement options and Embedded apps will not be
                      copied.
                      <Box
                        component="a"
                        onClick={() => window.open(LearnMoreLink.copyYourExistingEvent, '_blank')}
                        className="cursor-pointer text-underline learnMoreLink"
                        sx={{ ml: '3px' }}>
                        Learn More
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="copyEventOptionsRight">
                  {/* <Box mb={3} display="flex" alignItems="center" className={classes.warnBlock}>
                    <Box>
                      <Info size={15} />
                    </Box>
                    <Typography variant="body2" component="p">
                      Items will be auto-selected because of dependencies. Some items cannot be
                      deselected because of dependencies.
                    </Typography>
                  </Box> */}

                  <Typography component="p" variant="body1" color="primary" display="block">
                    Copy Event Info:
                  </Typography>

                  <Box sx={{ my: 1.87 }}>
                    <Typography
                      component="p"
                      variant="body1"
                      color={colors.textSecondary}
                      display="block">
                      Sessions
                    </Typography>
                    <Box className="copyEventInfoDesc">
                      <Typography
                        component="p"
                        variant="body1"
                        color="textSecondary"
                        display="block">
                        Stream Options, Engagement, Advanced, and Attendee data will not be copied.
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ my: 1.87 }}>
                    <Typography
                      component="p"
                      variant="body1"
                      color={colors.textSecondary}
                      display="block">
                      Virtual Booths
                    </Typography>
                    <Box className="copyEventInfoDesc">
                      <Typography
                        component="p"
                        variant="body1"
                        color="textSecondary"
                        display="block">
                        Team Member data will not be copied, and Premium booths will be copied as
                        general booths.
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ my: 1.87 }}>
                    <Typography
                      component="p"
                      variant="body1"
                      color={colors.textSecondary}
                      display="block">
                      Settings
                    </Typography>
                    <Box className="copyEventInfoDesc">
                      <Typography
                        component="p"
                        variant="body1"
                        color="textSecondary"
                        display="block">
                          Data except for General, Login Options, Groups, Profile Fields, Sender Details and Email Filtering will not be copied.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}
