/* eslint-disable react/no-unknown-property */
import React from 'react';

export default function SvgProductDemonstrationIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            {...props}>
            <path d="M21 14H20V4H21C21.2652 4 21.5196 3.89464 21.7071 3.70711C21.8946 3.51957 22 3.26522 22 3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3C2 3.26522 2.10536 3.51957 2.29289 3.70711C2.48043 3.89464 2.73478 4 3 4H4V14H3C2.73478 14 2.48043 14.1054 2.29289 14.2929C2.10536 14.4804 2 14.7348 2 15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H11V17.15L6.45 20.15C6.26333 20.2655 6.11988 20.4393 6.04177 20.6445C5.96366 20.8496 5.95523 21.0748 6.01779 21.2852C6.08035 21.4957 6.21042 21.6797 6.38793 21.8089C6.56544 21.938 6.78053 22.0052 7 22C7.1966 22.0022 7.38896 21.9428 7.55 21.83L11 19.55V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19.55L16.45 21.83C16.611 21.9428 16.8034 22.0022 17 22C17.2136 21.9984 17.4212 21.9283 17.5921 21.8002C17.7631 21.672 17.8885 21.4925 17.95 21.2879C18.0115 21.0832 18.0058 20.8643 17.9338 20.6631C17.8619 20.462 17.7273 20.2892 17.55 20.17L13 17.17V16H21C21.2652 16 21.5196 15.8946 21.7071 15.7071C21.8946 15.5196 22 15.2652 22 15C22 14.7348 21.8946 14.4804 21.7071 14.2929C21.5196 14.1054 21.2652 14 21 14ZM18 14H6V4H18V14Z" fill="currentcolor" />
        </svg>
    );
}
