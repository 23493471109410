import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import EditableOption from 'custom-components/form-fields/editableOption';
import { emailRegex } from 'models/regex';
import { setNewMessage } from 'redux/actions/setting-actions';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { eventDataSelector, userInfoSelector } from 'redux/custom-selector';
import { useStyles } from './style';
import { InfoOutlined } from '@material-ui/icons';
import useApi from 'lib/hooks/useApi';
import projectSetting from 'settings';
import Sidebar from '../../../../../../custom-components/sidebar';
import leadScoreApiUrls from '../../../constants/apiUrls';

const ExportToMailAnalytics = (props) => {
  const { isExport, toggleExport } = props;
  const { isLoading, apiHandler } = useApi();
  const { eventId = '' } = useSelector(eventDataSelector, shallowEqual);
  const userInfo = useSelector(userInfoSelector, shallowEqual);

  const dispatch = useDispatch();
  const classes = useStyles();

  const [exportFilterData, setExportFilterData] = React.useState({
    email: [],
  });

  const onCloseSidebar = () => {
    setExportFilterData({ exportFilterData, email: [] });
    toggleExport(false);
  };

  React.useEffect(() => {
    if (userInfo.UserInfo.email) {
      setExportFilterData({ email: [userInfo.UserInfo.email] });
    }
  }, [userInfo]);

  const onExportFile = () => {
    const extraHeaders = { eventid: eventId };

    const params = {
      emails: exportFilterData.email,
    };

    apiHandler(
      {
        url: `${leadScoreApiUrls.exportLeadLevelAnalytics}`,
        params,
        baseURL: projectSetting.customBaseURL2,
        method: 'GET',
        extraHeaders,
        isBackendURL: true,
      },
      () => {
        onCloseSidebar(); // once we get success cb we need to close the sidebar
      }
    );
  };

  return (
    <Sidebar
      open={isExport}
      title="Export Data"
      onClose={() => onCloseSidebar()}
      asideWidth={600}
      footerBtnLabel="Export"
      footerBtnLoadingLabel="Exporting"
      footerRightBtnLoading={isLoading}
      footerLoading={isLoading}
      footerBtnAction={onExportFile}
      footerBtnDisabled={!exportFilterData.email.length}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <EditableOption
            label="Send via Email"
            id="emails"
            helperText="Enter email id and press enter"
            name="email"
            descType="TOOLTIP"
            descText="Enter the Email ID's and a copy of the report will be mailed"
            value={exportFilterData.email}
            onEnter={(val, name, clearCB) => {
              if (!emailRegex.test(val)) {
                dispatch(
                  setNewMessage({
                    message: 'Please enter a valid Email',
                    type: 'error',
                    show: true,
                  })
                );
              } else {
                setExportFilterData({
                  ...exportFilterData,
                  email: [...exportFilterData.email, val],
                });
                if (clearCB) clearCB();
              }
            }}
            onDeleteValue={(val) => {
              const indexToRemove = exportFilterData.email.findIndex((ele) => ele === val);
              setExportFilterData({
                ...exportFilterData,
                email: [
                  ...exportFilterData.email.slice(0, indexToRemove),
                  ...exportFilterData.email.slice(indexToRemove + 1),
                ],
              });
            }}
          />
          <Box mt={1} p={1.5} display="flex" alignItems="center" className={classes.msgBlock}>
            <Box mr={1.5}>
              <InfoOutlined fontSize="small" />
            </Box>
            <Typography variant="caption" color="textPrimary" component="p">
              Data export typically takes less than an hour but can take more time depending on the
              volume of data being exported. An Email will be sent to you when your report is ready
              for download.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Sidebar>
  );
};

export default React.memo(ExportToMailAnalytics);
