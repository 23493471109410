import React from 'react';

export default function SvgBaseLangIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2593 16.4987L17.1409 16.1667H16.7883H12.7258H12.3741L12.2552 16.4977L11.4766 18.6667H10.3058L14.0432 8.83333H15.5239L19.2742 18.6667H18.0325L17.2593 16.4987ZM16.1892 15.4425H16.8924L16.6614 14.7783L15.2406 10.6924L14.7693 9.33716L14.2963 10.6918L12.8696 14.7777L12.6375 15.4425H13.3417H16.1892ZM7.99517 5.81368L7.89226 6.21062L8.26137 6.38924C10.0591 7.25919 10.7769 9.01476 10.1224 10.573L10.1223 10.5731C9.49932 12.0567 7.96471 12.8034 6.20833 12.8961V12.0279C7.89627 11.9322 8.92911 11.1741 9.32335 10.2373L9.32358 10.2367C9.8027 9.09448 9.22795 7.93532 8.12813 7.30103L7.63752 7.01809L7.41758 7.53999C6.5366 9.63041 5.0944 11.1928 3.02477 11.3812C1.44873 11.5244 0.5 10.4432 0.5 9.2175C0.5 8.18706 1.18583 6.94389 3.03651 6.26436L3.35925 6.14585L3.36412 5.80208C3.37228 5.22546 3.40087 4.65576 3.44815 4.1071L3.49494 3.56416H2.95H1.63V2.82833H3.1725H3.59238L3.66497 2.41478C3.73131 2.03686 3.79723 1.73481 3.86816 1.45875L4.69985 1.7086C4.66266 1.85612 4.62393 2.02547 4.58301 2.23045L4.46366 2.82833H5.07333H6.37417V3.56416H4.8275H4.37089L4.32955 4.0189C4.29035 4.45011 4.26229 4.89766 4.24615 5.35399L4.22588 5.92687L4.7962 5.86912C5.42543 5.80541 6.04228 5.81617 6.6165 5.90052L7.0633 5.96616L7.17393 5.52832C7.20819 5.39273 7.2374 5.26863 7.26223 5.15774L8.11331 5.32339C8.08021 5.4725 8.04209 5.63268 7.99517 5.81368ZM6.52497 7.42052L6.81658 6.77381L6.10947 6.71663C5.65116 6.67956 5.16666 6.69142 4.68823 6.75426L4.2338 6.81394L4.25381 7.27183C4.28187 7.91381 4.3339 8.51903 4.41146 9.07493L4.55319 10.0906L5.2661 9.3534C5.80734 8.79369 6.21946 8.09808 6.52497 7.42052ZM3.41143 7.78567L3.35946 6.99184L2.6667 7.38292C1.83348 7.85329 1.30865 8.62764 1.37303 9.3677C1.40695 9.75764 1.6104 10.112 1.97983 10.3227C2.33299 10.5241 2.77982 10.5625 3.26661 10.4735L3.76197 10.3829L3.66786 9.88822C3.54894 9.26311 3.46199 8.5579 3.41143 7.78567Z" fill="black" stroke="#444444" />
        </svg>

    );
}
