import { isEmpty } from 'lodash';

const getLeadLevelDetails = (id, levels = []) => {
  let color;
  let label;
  if (id === 1) {
    color = '#7EBFFC';
  }
  if (id === 2) {
    color = '#FF9500';
  }
  if (id === 3) {
    color = '#FF5E3A';
  }
  if (levels.length && id) {
    const details = levels.find((item) => item.id === id);
    if (details.label) {
      label = details.label;
    }
  }
  return { color, label };
};

const sliderIndicatorWidthCalculator = (pointer) => {
  const pointerOne = pointer[0]; // 35,65
  const pointerTwo = pointer[1];

  return {
    1: `${pointerOne}%`, // 35%
    2: `${pointerTwo - pointerOne}%`, //
    3: `${100 - pointerTwo}%`,
  };
};

const distributionDataBasedOnId = ({ id, data = {}, distStatus = '', status = '' } = {}) => {
  const res = { attendeesCount: 0, totalAttendees: 0 };
  if (!isEmpty(data) && distStatus && status) {
    const leadLevelData = data[distStatus][status].leadLevelDistribution || [];
    if (leadLevelData && Array.isArray(leadLevelData) && leadLevelData.length && id) {
      const { attendeesCount = 0 } = leadLevelData.find((item) => item.id === id) || {};
      res.attendeesCount = Number(attendeesCount);
    }
    res.totalAttendees = data[distStatus][status]?.totalAttendees || 0;
  }
  return res;
};

const arrowRedGreen = ({ currentCount, updatedCount }) => {
  let res = {};
  if (updatedCount > currentCount) {
    res = { borderBottom: '6px solid #4CBB3E' };
  }
  if (updatedCount < currentCount) {
    res = { borderTop: '6px solid #E45747' };
  }
  if (updatedCount === currentCount) {
    res = { borderTop: '6px solid transparent', borderBottom: '6px solid transparent' };
  }
  return res;
};

const tootTipTitleGenerator = ({ id, data = [] }) => {
  let label = '';
  let range = '';
  if (id && data.length) {
    const idData = data.find((item) => item.id === id) || {};
    label = `${idData.label} Engagement`;
    range = `${idData.from} - ${idData.to}`;
  }
  return { label, range };
};

const extractLabelsFromLeads = (data = []) => {
  let res = [];
  if (data.length) {
    res = data.map((item) => item.label);
  }
  return res;
};

const extractChangeValuesFromLeads = ({ oldData = [], newData = [] }) => {
  let res = [];
  if (oldData.length && newData.length) {
    oldData.forEach((item) => {
      newData.forEach((subItem) => {
        if (item.id === subItem.id && (item.to !== subItem.to || item.from !== subItem.from)) {
          res = [...res, subItem];
        }
      });
    });
  }
  return res;
};
export {
  getLeadLevelDetails,
  sliderIndicatorWidthCalculator,
  distributionDataBasedOnId,
  arrowRedGreen,
  tootTipTitleGenerator,
  extractLabelsFromLeads,
  extractChangeValuesFromLeads,
};
