import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  noContentArea: {
    padding: '20px',
    borderRadius: 10,
    border: `1px solid #c0c0c0`,
  },
  noContentAreaLeft: {
    width: '35%',
  },
  noContentAreaRight: {
    width: '65%',
  },
  grayHelpTextInPerson: {
    color: theme.palette.text.third,
    fontSize: '16px',
    marginTop: '2rem',
  },
}));
