const dataMapperForGraph = ({ levels, distribution }) => {
  let res = [...levels];
  if (res.length) {
    res = res.map((item) => {
      let getTotal = 0;
      if (
        distribution &&
        distribution.leadLevelDistribution &&
        distribution.leadLevelDistribution.length
      ) {
        const temp = distribution.leadLevelDistribution.find((dist) => dist.id === item.id);
        if (temp) {
          getTotal = temp.attendeesCount;
        }
      }
      return {
        name: item.label,
        id: item.id,
        value: distribution.totalAttendees,
        total: getTotal,
      };
    });
  }

  return {
    distribution: res,
    totalAttendees: distribution.totalAttendees,
    avgAttendeeScore: distribution.avgAttendeeScore,
  };
};

const getChartRange = (num = 0) => {
  const pow = num.toString().length;
  let ticks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  ticks = ticks.map((item) => {
    const pow10 = 10 ** (pow - 1);
    return item * pow10;
  });

  return { domain: [0, 10 ** pow], ticks };
};

export { dataMapperForGraph, getChartRange };
