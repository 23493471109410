import React from 'react';

export default function SvgEnterprisePlanIconFilledWhite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}>
      <path
        d="M15 9L14.7 14.8896C14.7 16.3376 14.9203 17.7584 16 19H9C10.0797 17.7584 10.3 16.3376 10.3 14.8896L10 9L15 9Z"
        fill="#FFFFFF"
      />
      <path d="M10 10L9 7L11 5H14L16 7L15 10H10Z" fill="#FFFFFF" />
      <path
        d="M15 2H13.3333V0H11.6667V2H10V3.43512H11.6667V5H13.3333V3.43512H15V2Z"
        fill="#FFFFFF"
      />
      <path
        d="M16.35 17.9322V18.569C16.35 18.797 16.4229 19.0228 16.5644 19.2334C16.706 19.444 16.9134 19.6354 17.175 19.7966C17.4366 19.9578 17.644 20.1492 17.7856 20.3598C17.9271 20.5704 18 20.7962 18 21.0242V22H7V21.0242C7 20.7962 7.07286 20.5704 7.21441 20.3598C7.35596 20.1492 7.56344 19.9578 7.825 19.7966C8.08656 19.6354 8.29404 19.444 8.43559 19.2334C8.57714 19.0228 8.65 18.797 8.65 18.569V17.9322H16.35Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
