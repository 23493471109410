export const SET_ROI_METADATA = 'SET_ROI_METADATA';
export const SET_ROI_DATE_DATE_RANGE = 'SET_ROI_DATE_DATE_RANGE';
export const SET_OVERALL_FUNNEL_LOADING = 'SET_OVERALL_FUNNEL_LOADING';
export const SET_OVERALL_FUNNEL_DATA = 'SET_OVERALL_FUNNEL_DATA';
export const SET_ROI_OPPORTUNITIES_FILTER_DATA = 'SET_ROI_OPPORTUNITIES_FILTER_DATA';
export const SET_ROI_OPPORTUNITIES_FILTER_TYPE = 'SET_ROI_OPPORTUNITIES_FILTER_TYPE';
export const SET_ROI_OPPORTUNITIES_STATS_LOADING = 'SET_ROI_OPPORTUNITIES_STATS_LOADING';
export const SET_ROI_OPPORTUNITIES_STATS_DATA = 'SET_ROI_OPPORTUNITIES_STATS_DATA';
export const SET_ROI_OPPORTUNITIES_BREAKDOWN_LOADING = 'SET_ROI_OPPORTUNITIES_BREAKDOWN_LOADING';
export const SET_ROI_OPPORTUNITIES_BREAKDOWN_DATA = 'SET_ROI_OPPORTUNITIES_BREAKDOWN_DATA';
export const SET_ROI_OPPORTUNITIES_PRIORITY_KEY = 'SET_ROI_OPPORTUNITIES_PRIORITY_KEY';
export const SET_ROI_LEADS_STATUS_TYPE = 'SET_ROI_LEADS_STATUS_TYPE';
export const SET_ROI_LEADS_STATUS_DATA = 'SET_ROI_LEADS_STATUS_DATA';
export const SET_ROI_LEADS_STATUS_LOADING = 'SET_ROI_LEADS_STATUS_LOADING';
export const SET_ROI_LEADS_STATUS_FILTER_DATA = 'SET_ROI_LEADS_STATUS_FILTER_DATA';
export const SET_ROI_HIDDEN_REVENUE_DATA = 'SET_ROI_HIDDEN_REVENUE_DATA';
export const SET_ROI_HIDDEN_REVENUE_LOADING = 'SET_ROI_HIDDEN_REVENUE_LOADING';
export const ROI_TOGGLE_STATE = 'ROI_TOGGLE_STATE';
export const SET_ROI_EVENT_WISE_NUMBERS_DATA = 'SET_ROI_EVENT_WISE_NUMBERS_DATA';
export const SET_ROI_EVENT_WISE_NUMBERS_LOADING = 'SET_ROI_EVENT_WISE_NUMBERS_LOADING';
export const SET_ROI_LAST_LOCATION = 'SET_ROI_LAST_LOCATION';

export const setROIMetadata = (data) => {
  return {
    type: SET_ROI_METADATA,
    payload: data,
  };
};

export const setRoiDateRange = (data) => {
  return {
    type: SET_ROI_DATE_DATE_RANGE,
    payload: data,
  };
};

export const setRoiOverallFunnelLoading = (isLoading) => {
  return {
    type: SET_OVERALL_FUNNEL_LOADING,
    payload: isLoading,
  };
};

export const setRoiOverallFunnelData = (data) => {
  return {
    type: SET_OVERALL_FUNNEL_DATA,
    payload: data,
  };
};

export const setRoiOpportunitiesStatsLoading = (isLoading) => {
  return {
    type: SET_ROI_OPPORTUNITIES_STATS_LOADING,
    payload: isLoading,
  };
};

export const setRoiOpportunitiesStatsData = (data) => {
  return {
    type: SET_ROI_OPPORTUNITIES_STATS_DATA,
    payload: data,
  };
};

export const setRoiOpportunitiesBreakdownLoading = (isLoading) => {
  return {
    type: SET_ROI_OPPORTUNITIES_BREAKDOWN_LOADING,
    payload: isLoading,
  };
};

export const setRoiOpportunitiesBreakdownData = (data) => {
  return {
    type: SET_ROI_OPPORTUNITIES_BREAKDOWN_DATA,
    payload: data,
  };
};

export const setRoiOpportunitiesFilterData = (data) => {
  return {
    type: SET_ROI_OPPORTUNITIES_FILTER_DATA,
    payload: data,
  };
};

export const setRoiOpportunitiesFilterType = (type) => {
  return {
    type: SET_ROI_OPPORTUNITIES_FILTER_TYPE,
    payload: type,
  };
};

export const setRoiOpportunitiesPriorityKey = (key) => {
  return {
    type: SET_ROI_OPPORTUNITIES_PRIORITY_KEY,
    payload: key,
  };
};

export const setRoiLeadsStatusType = (type) => {
  return {
    type: SET_ROI_LEADS_STATUS_TYPE,
    payload: type,
  };
};

export const setRoiLeadsStatusData = (data) => {
  return {
    type: SET_ROI_LEADS_STATUS_DATA,
    payload: data,
  };
};

export const setRoiLeadsStatusLoading = (isLoading) => {
  return {
    type: SET_ROI_LEADS_STATUS_LOADING,
    payload: isLoading,
  };
};

export const setRoiLeadsStatusFilterData = (data) => {
  return {
    type: SET_ROI_LEADS_STATUS_FILTER_DATA,
    payload: data,
  };
};

export const setRoiHiddenRevenueData = (data) => {
  return {
    type: SET_ROI_HIDDEN_REVENUE_DATA,
    payload: data,
  };
};

export const setRoiHiddenRevenueLoading = (isLoading) => {
  return {
    type: SET_ROI_HIDDEN_REVENUE_LOADING,
    payload: isLoading,
  };
};

export const roiToggleAction = (payload) => {
  return {
    type: ROI_TOGGLE_STATE,
    payload,
  };
};

export const setRoiEventNumbersData = (data) => {
  return {
    type: SET_ROI_EVENT_WISE_NUMBERS_DATA,
    payload: data,
  };
};

export const setRoiEventNumbersLoading = (isLoading) => {
  return {
    type: SET_ROI_EVENT_WISE_NUMBERS_LOADING,
    payload: isLoading,
  };
};

export const setRoiLastLocation = (data) => {
  return {
    type: SET_ROI_LAST_LOCATION,
    payload: data,
  };
};
