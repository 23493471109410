export const SET_CAMPAIGN_DATA = 'SET_CAMPAIGN_DATA';
export const REMOVE_CAMPAIGN_DATA = 'REMOVE_CAMPAIGN_DATA';

export const setCampaignData = (campaignData) => {
  return {
    type: SET_CAMPAIGN_DATA,
    data: campaignData,
  };
};

export const removeCampaignData = () => {
  return {
    type: REMOVE_CAMPAIGN_DATA,
  };
};
