import React from 'react';

export default function SvgWindowDotsIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 63 12">
      <circle cx="6" cy="6" r="6" fill="#F45952" />
      <circle cx="31.4827" cy="6" r="6" fill="#FFBC00" />
      <circle cx="56.9656" cy="6" r="6" fill="#0CD651" />
    </svg>
  );
}
