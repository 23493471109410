import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ProjectSetting from 'settings';
import { removeUserCredential, getUserCredential } from 'lib/cookies';
import axios from 'axios';
import { setNewMessage } from 'redux/actions/setting-actions';
import { removeUserInfo } from 'redux/actions/user-action';
import { removeEventData as removeEventDataRedux } from 'redux/actions/event-action';
import { commonSelector } from 'redux/custom-selector';
import { useSource } from 'lib/custom-common-hooks';

// API_CALL_METHODS - contains various HTTP methods
export const API_CALL_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// API_CALL_RESP_CODES - cotains all HTTP server codes
export const API_CALL_RESP_CODES = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INT_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

// callApi is a common api to all post,put and get api requests
const callApi = (URL, method, apiStore, headers, body, customBaseUrl = '') => {
  const config = {
    method,
    url: customBaseUrl ? `${customBaseUrl}${URL}` : `${ProjectSetting.baseURL}${URL}`,
    headers,
  };
  if (apiStore) {
    if (apiStore && apiStore.has(URL)) {
      const cancel = apiStore.get(URL);
      cancel();
    }
    config.cancelToken = new axios.CancelToken(function executor(cancelCb) {
      apiStore.set(URL, cancelCb);
    });
  }
  if (body) {
    config.data = body;
  }
  return axios(config);
};

// generateHeader - this genarates HTTP request headers and body
const generateHeader = (reduxData, reqHeader, reqBody) => {
  let headers = {};
  let body = {};
  if (reduxData) {
    const { eventId, organiserId } = reduxData;
    let cookiesData = {};
    try {
      cookiesData = getUserCredential();
    } catch (err) {
      console.log(err);
    }
    if (cookiesData && cookiesData.authToken) {
      headers.Authorization = `Bearer ${cookiesData.authToken}`;
    }
    if (cookiesData && cookiesData.organiser_id) {
      headers.organiserId = cookiesData.organiser_id ? cookiesData.organiser_id : organiserId;
    } else {
      headers.organiserId = organiserId || cookiesData?.organiser_id;
    }

    if (eventId) {
      headers.eventId = eventId;
    }
    if (cookiesData && cookiesData.teamMemberId) {
      headers.teamMemberId = cookiesData.teamMemberId ? cookiesData.teamMemberId : 0;
    }
    if (ProjectSetting.buildversion) {
      headers.buildversion = ProjectSetting.buildversion;
    }
  }
  if (reqHeader) {
    headers = { ...headers, ...reqHeader };
  }
  if (reqBody) {
    body = { ...body, ...reqBody };
  }

  return [headers, body];
};

// errStatusHandler - this functions handles all the errors that thown by the http request inform of response
const errStatusHandler = (data, history, dispatch, showErrorToast=true) => {
  const { status, message } = data;
  if (status !== API_CALL_RESP_CODES.OK || status !== API_CALL_RESP_CODES.CREATED) {
    if (typeof message === 'string' && dispatch) {
      if(showErrorToast){
        dispatch(setNewMessage({ message, type: 'error', show: showErrorToast }));
      }
    }
  }
  if (status === API_CALL_RESP_CODES.UNAUTHORIZED) {
    if (dispatch) {
      dispatch(removeUserInfo());
      dispatch(removeEventDataRedux());
    }
    removeUserCredential();
    if (history) {
      if (history.dispatch) {
        history.dispatch(removeUserInfo());
      }
      if (history.history) {
        history.history.push('/login');
      }
    }
    if (history) history.push('/login');
  }
};

// different base URLs can be directly imported using below exports
export const {
  customBaseURL,
  customBaseURL2,
  customBaseURL3,
  customBaseURL4,
  customBaseURL6,
  odinBaseUrl,
  hydraBaseUrl,
} = ProjectSetting;

// useApiHook - useApiHook is a hook that handles all api calls and error handlings itself in the hook
export const useApiHook = () => {
  const reduxData = useSelector(commonSelector, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const source = useSource();

  const apiPromise = (URL, method, headerData, bodyData, customBaseUrl = '', showErrorToast=true) =>
    new Promise((resolve, reject) => {
      const [header, body] = generateHeader(reduxData, headerData, bodyData);

      callApi(URL, method, source.AS, header, body, customBaseUrl)
        .then((resp) => {
          if (resp?.data?.status === API_CALL_RESP_CODES.OK) resolve(resp);
          else {
            errStatusHandler(resp.data, history, dispatch, showErrorToast);
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(resp?.data);
          }
        })
        .catch((errorInfo) => {
          if (errorInfo.response) {
            const data = {
              status: errorInfo?.response?.status,
              message: errorInfo?.response?.data?.message
                ? errorInfo?.response?.data?.message
                : errorInfo?.message,
            };
            errStatusHandler(data, history, dispatch, showErrorToast);
          }
          reject(errorInfo);
        });
    });

  return [apiPromise];
};
