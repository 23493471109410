/* eslint-disable react/no-danger */
import React from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { useStyles } from './style';
import {
  Box,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Eye, EyeOff } from 'react-feather';
import SvgUnionDotsIcon from 'icons/svgUnionDots';
import { SubSectionLoad } from './skeleton';

const visibilityKey = (tab) => {
  const key = tab === 'mobile' ? 'is_visible' : 'mobile_app_visibility';
  return key;
};
const MobileAppEventSetting = (props) => {
  const {
    activeAppHandler,
    handelClickOnSection,
    defultIcon,
    loadingState,
    activeTab,
    mainEventSectionList,
    selStatusSectionId,
    toastItems,
    selSectionId,
    setDragStart,
    dragStart,
    handelClickOnUpdateSubSecEventStatus,
    onDropSub,
    removeDuplicates,
    handelClickOnSubSection,
    subSelSectionId,
    getListOfEventSubSection,
    setSelectedSectionIndex,
    selSubStatusSectionId,
    handelClickOnUpdateEventStatus,
  } = props;
  const classes = useStyles();
  const { eventNameBlock, eventNameBlockCheck } = useStyles();
  return mainEventSectionList && mainEventSectionList.length > 0 ? (
    <>
      {mainEventSectionList.map((eventItem, key) => {
        return (
          <React.Fragment key={key}>
            <Draggable
              className={`${
                mainEventSectionList.filter((x) => x.is_primary === 1).length === 1
                  ? 'disabledItem'
                  : ''
              }`}>
              <Box
                bgcolor={`${eventItem.is_parent && activeTab !== 'mobile' ? '#f6f6f6' : false}`}
                borderRadius={`${eventItem.is_parent && activeTab !== 'mobile' ? '8px' : false}`}
                pl={`${eventItem.is_parent && activeTab !== 'mobile' ? '5px' : false}`}
                pr={`${eventItem.is_parent && activeTab !== 'mobile' ? '5px' : false}`}>
                <Box position="relative">
                  <ListItem
                    disabled={eventItem[visibilityKey(activeTab)] === 0}
                    selected={eventItem.event_section_id === selSectionId}
                    button
                    disableGutters
                    className={`${eventNameBlock} ${
                      eventItem.integration_meta_name === null ? '' : 'badgeText'
                    } ${
                      toastItems.length && toastItems.indexOf(eventItem.event_section_id) > -1
                        ? `toastBackground`
                        : ``
                    }`}
                    onClick={() => {
                      activeAppHandler(eventItem);
                      handelClickOnSection(eventItem.event_section_id, eventItem);
                      //  setSelectedLanguage(key);
                      setSelectedSectionIndex(key);
                      // if (eventItem.is_parent && eventItem.subSectionList &&
                      // 	eventItem.subSectionList.options.length === 0) {
                      // 	getListOfEventSubSection(eventItem.event_section_id, 1);
                      // }
                    }}>
                    <ListItemIcon>
                      <Box>
                        <Box
                          component="span"
                          padding="7.5px 4px"
                          display="flex"
                          flexDirection="column">
                          <SvgUnionDotsIcon color="#a5a5a5" height="18px" width="11px" />
                        </Box>
                      </Box>
                      <Box className={classes.eventsIcon} height={30} width={30} mr={1}>
                        {eventItem.svg_image || eventItem.app_svg_image ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: eventItem.svg_image || eventItem.app_svg_image,
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: defultIcon.svg,
                            }}
                          />
                        )}
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        component: 'label',
                      }}
                      primary={eventItem.label}
                      secondaryTypographyProps={{
                        variant: 'body2',
                        component: 'caption',
                      }}
                      secondary={
                        eventItem.event_section_meta_name
                          ? eventItem.event_section_meta_name.replace(/_/g, ' ')
                          : eventItem.integration_meta_name
                          ? eventItem.integration_meta_name
                          : ''
                      }
                    />
                    <ListItemSecondaryAction className={eventNameBlockCheck}>
                      {activeTab !== 'mobile' ? (
                        <IconButton>
                          {loadingState.eventStatusLoader &&
                          selStatusSectionId === eventItem.event_section_id ? (
                            <CircularProgress color="primary" size={19} />
                          ) : (
                            <>
                              {eventItem[visibilityKey(activeTab)] === 1 ? (
                                <Eye
                                  size={20}
                                  className="circleCheckIconColor"
                                  onClick={() => handelClickOnUpdateEventStatus(eventItem, 0)}
                                />
                              ) : (
                                <EyeOff
                                  size={20}
                                  className="circleCheckIconColor"
                                  onClick={() => handelClickOnUpdateEventStatus(eventItem, 1)}
                                />
                              )}
                            </>
                          )}
                        </IconButton>
                      ) : (
                        false
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </Box>

                {eventItem.is_parent === 1 && activeTab !== 'mobile' ? (
                  <Box
                    position="relative"
                    className="childWrapper"
                    onScroll={(event) => {
                      const ele = event.target;
                      if (ele.scrollHeight - ele.scrollTop <= ele.clientHeight + 5) {
                        getListOfEventSubSection(eventItem.event_section_id, false);
                      }
                    }}>
                    <Container
                      lockAxis="y"
                      orientation="vertical"
                      groupName="col"
                      dragClass="card-ghost"
                      dropClass="card-ghost-drop"
                      dropPlaceholder={{
                        className: 'dragPlaceHolder',
                      }}
                      getChildPayload={(i) => eventItem.subSectionList.options[i]}
                      animationDuration={200}
                      onDragStart={({ isSource }) => {
                        if (isSource) {
                          setDragStart('ExhibitorSubSec');
                        }
                      }}
                      onDragEnter={() => {
                        setDragStart('ExhibitorSubSec');
                      }}
                      onDrop={(dropResult) => {
                        if (dropResult.addedIndex !== null) {
                          if (dropResult.addedIndex !== dropResult.removedIndex) {
                            if (dragStart === 'ExhibitorSubSec') {
                              onDropSub(dropResult, 'column', eventItem);
                            }
                          }
                        }
                      }}>
                      {loadingState.subSectionLoad ? (
                        <SubSectionLoad />
                      ) : eventItem.subSectionList &&
                        eventItem.subSectionList.options.length > 0 ? (
                        // && eventItem.event_section_id === selSectionId
                        <>
                          {removeDuplicates(eventItem.subSectionList.options).map(
                            (subItem, subIndex) => {
                              return (
                                <Draggable position="relative" key={`${key}${subIndex}`}>
                                  <Box position="relative">
                                    <Box
                                      position="relative"
                                      style={{
                                        float: 'right',
                                        width: '85%',
                                      }}>
                                      <ListItem
                                        disabled={subItem[visibilityKey(activeTab)] === 0}
                                        selected={subItem.event_subsection_id === subSelSectionId}
                                        button
                                        disableGutters
                                        className={`${eventNameBlock} ${
                                          eventItem.integration_meta_name === null
                                            ? ''
                                            : 'badgeText'
                                        }${
                                          toastItems.length &&
                                          toastItems.indexOf(eventItem.event_section_id) > -1
                                            ? `toastBackground`
                                            : ``
                                        }`}
                                        onClick={() => {
                                          activeAppHandler(eventItem);
                                          handelClickOnSubSection(
                                            eventItem.event_section_id,
                                            subItem.event_subsection_id,
                                            subItem
                                          );
                                          const finalIndex = mainEventSectionList
                                            .filter((f) => f.is_primary === 1)
                                            .findIndex(
                                              (item) =>
                                                item.event_section_meta_name ===
                                                eventItem.event_section_meta_name
                                            );
                                          setSelectedSectionIndex(finalIndex);
                                        }}>
                                        <ListItemIcon>
                                          <Box
                                            component="span"
                                            padding="7.5px 4px"
                                            display="flex"
                                            flexDirection="column">
                                            <SvgUnionDotsIcon
                                              color="#a5a5a5"
                                              height="18px"
                                              width="11px"
                                            />
                                          </Box>
                                          <Box
                                            className={classes.eventsIcon}
                                            height={30}
                                            width={30}
                                            mr={1}>
                                            {eventItem.svg_image ? (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: eventItem.svg_image,
                                                }}
                                              />
                                            ) : (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: defultIcon.svg,
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{
                                            variant: 'subtitle2',
                                            component: 'label',
                                            className: 'eventSectiontitleTitle',
                                          }}
                                          primary={`${subItem.label}`}
                                          secondaryTypographyProps={{
                                            variant: 'body2',
                                            component: 'caption',
                                          }}
                                          secondary={
                                            eventItem.event_section_meta_name
                                              ? eventItem.event_section_meta_name.replace(/_/g, ' ')
                                              : ''
                                          }
                                        />
                                        <ListItemSecondaryAction className={eventNameBlockCheck}>
                                          <IconButton>
                                            {loadingState.eventSubStatusLoader &&
                                            selSubStatusSectionId ===
                                              subItem.event_subsection_id ? (
                                              <CircularProgress color="primary" size={19} />
                                            ) : (
                                              <>
                                                {subItem[visibilityKey(activeTab)] === 1 ? (
                                                  <Eye
                                                    size={20}
                                                    className="circleCheckIconColor"
                                                    onClick={() =>
                                                      handelClickOnUpdateSubSecEventStatus(
                                                        subItem,
                                                        0,
                                                        eventItem.event_section_id
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <EyeOff
                                                    size={20}
                                                    className="circleCheckIconColor"
                                                    onClick={() =>
                                                      handelClickOnUpdateSubSecEventStatus(
                                                        subItem,
                                                        1,
                                                        eventItem.event_section_id
                                                      )
                                                    }
                                                  />
                                                )}
                                              </>
                                            )}
                                          </IconButton>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    </Box>
                                  </Box>
                                </Draggable>
                              );
                            }
                          )}
                          {loadingState.subSectionLoadMore ? <SubSectionLoad /> : ''}
                        </>
                      ) : (
                        ''
                      )}
                    </Container>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            </Draggable>
          </React.Fragment>
        );
      })}
    </>
  ) : (
    ''
  );
};
export default React.memo(MobileAppEventSetting);
