/* eslint-disable  */
import React from 'react';
import { Box, makeStyles, Chip, Typography } from '@material-ui/core';
import FieldWrapper from './fieldWrapper';
import { X, XCircle } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: theme.palette.bg.white,
    border: `1px solid ${theme.palette.border.dark}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    borderRadius: theme.shape.borderRadius,
    '&.overFlowWrap': {
      maxHeight: `8.25rem`,
      overflowY: `auto`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.disabled': {
      cursor: 'not-allowed',
      background: theme.palette.bg.whiteOff1,
    },

    '& input': {
      font: 'inherit',
      borderRadius: 0,
      border: 0,
      outline: 0,
      width: '100%',
      height: '1.1876em',
      margin: 0,
      display: 'block',
      padding: '6px 0 7px',
      fontSize: theme.fontSize.size8,
      color: theme.palette.text.primary,
      background: 'none',
      boxSizing: 'content-box !important',
      animationName: 'mui-auto-fill-cancel',
      letterSpacing: 'inherit',
      animationDuration: '10ms',
      '&:disabled': {
        cursor: 'not-allowed',
        background: theme.palette.bg.whiteOff1,
      },
      '-webkit-tap-highlight-color': 'transparent',
      '&::-webkit-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&::-moz-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&:-ms-input-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&:-moz-placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
      '&::placeholder': {
        color: theme.palette.text.grayColor,
        opacity: 1,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
    },
  },
  chipsWrap: {
    width: '100%',
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    '& .MuiChip-root': {
      minWidth: "auto",
      marginTop: theme.spacing(0.375),
      marginBottom: theme.spacing(0.375),
      // background: theme.palette.alert.tagbg_pink,
      // color: theme.palette.secondary.main,
      // borderRadius: 4,
      marginRight: theme.spacing(1),
      // '& svg': {
      //   color: theme.palette.primary.main,
      // },
    },
    '& .MuiChip-root + .MuiChip-root': {
      marginRight: theme.spacing(1),
    },
  },
  removeBtnAll: {
    position: `absolute`,
    color: `#f44336`,
    top: `-2px`,
    cursor: `pointer`,
    lineHeight: `normal`,
    right: `0`,
  },
}));

const EditableOption = (props) => {
  const classes = useStyles();
  const onEnterPress = (event) => {
    if (!event.shiftKey && (event.keyCode === 13 || event.which === 13 || event.key === 'Enter')) {
      props.onEnter(event.target.value, props.name, () => {
        event.target.value = '';
      });
    }
  };

  return (
    <FieldWrapper
      formControlClassName={props.formControlClassName}
      label={props.label}
      id={props.id}
      error={props.error}
      required={props.required}
      descType={props.descType}
      descText={props.descText}>
      <Box>
        <Box
          className={`${classes.inputField} ${props.overFlowWrap ? 'overFlowWrap' : ''} ${
            props.isDisabled ? 'disabled' : ''
          }`}
          display="flex"
          alignItems="center">
          {/* <Box className={classes.inputField} display="flex" alignItems="center"> */}
          {props.RemoveAll ? (
            <Box
              className={classes.removeBtnAll}
              onClick={() => {
                props.removeAllOnClick && props.removeAllOnClick();
              }}
              component="a"
              variant="body2"
              ml={0.3}>
              Remove all
            </Box>
          ) : null}
          {!props.adornmentStart ? (
            false
          ) : props.adornmentStartType === 'ICON' ? (
            <Box display="inline-flex" mr={1}>
              {props.adornmentStartIcon}
            </Box>
          ) : (
            <Box display="inline-flex" mr={1}>
              {props.adornmentStart}
            </Box>
          )}
          <Box display="inline-flex" alignSelf="flex-start" pr={(props.adornmentEnd || props.adornmentEndIcon) ? 5 : 0} width="100%">
            <Box className={classes.chipsWrap}>
              {props.value.map((eachValue, index) => {
                let curLabel;
                let curVal;
                if (!props.isArrayOfObject) {
                  curLabel = eachValue;
                  curVal = eachValue;
                } else {
                  curLabel = eachValue[props.labelKey];
                  curVal = eachValue[props.valueKey];
                }

                const propsAddition = props.isDisabled
                  ? {}
                  : {
                      onDelete: () => props.onDeleteValue(eachValue, index),
                      deleteIcon: <XCircle />,
                    };

                return (
                  <Chip
                    variant="outlined"
                    color="primary"
                    key={curVal}
                    label={curLabel}
                    size="small"
                    {...propsAddition}
                  />
                );
              })}
              <Box display="inline-flex" flexGrow={1} width={0} minWidth={30}>
                <input
                  maxLength={props.maxLength}
                  placeholder={props.value.length > 0 ? '' : props.placeholder}
                  disabled={props.isDisabled}
                  autoComplete="off"
                  data-size={props.inputSize || 'medium'}
                  onChange={(e) => {
                    props.onChange && props.onChange(e.target.value);
                  }}
                  onKeyPress={
                    props.onKeyPress ? props.onKeyPress : props.onEnter ? onEnterPress : undefined
                  }
                />
              </Box>
            </Box>
          </Box>
          {!props.adornmentEnd ? (
            false
          ) : props.adornmentEndType === 'ICON' ? (
            <Box display="inline-flex" ml={1}>
              {props.adornmentEndIcon}
            </Box>
          ) : (
            <Box
              display="inline-flex"
              ml={1}
              color="text.grayColor1"
              position="absolute"
              bottom={40}
              right={10}>
              {props.adornmentEnd}
            </Box>
          )}
        </Box>
        {props.helperText ? (
          <Box component="div" mt={0.75} color="text.grayColor">
            <Typography component="span" variant="caption">
              {props.helperText}
            </Typography>
          </Box>
        ) : (
          false
        )}
      </Box>
    </FieldWrapper>
  );
};

export default EditableOption;
