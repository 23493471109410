import React from 'react';

export default function SvgCheckTickIcon(props) {
    return (
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.3333 1L4.99996 8.33333L1.66663 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
}
