/* eslint-disable */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSource } from 'lib/custom-common-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import InputField from 'custom-components/form-fields/inputField';
import MapView, { mapScriptLoad } from 'custom-components/mapView';
import SelectDropdown from 'custom-components/form-fields/selectDropdown';
import countryList from 'models/country';
import { Search } from 'react-feather';
import projectSetting from 'settings';

const useStyles = makeStyles((theme) => ({
  mapBlock: {
    padding: '1rem 1rem 0',
    marginBottom: '2rem',
  },
  mapLabel: {
    color: theme.palette.text.secondary,
    marginBottom: '0.5rem',
  },
  googleInputSearch: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    transition: theme.transitions.create(['border-color', 'color']),
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary.main,
    },
    '&[data-size=small]': {
      padding: `${theme.spacing(0.4)}px ${theme.spacing(1.5)}px`,
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      '& input': {
        color: theme.palette.text.grayColor,
        cursor: 'not-allowed',
      },
    },
    '& svg': {
      width: 30,
      height: 30,
    },
  },
}));

export const makePlaceData = (data) => {
  let finalObject = {
    address: '',
    province: '',
    city: '',
    country: '',
    zip: '',
    lat: '',
    lon: '',
  };
  if (data.address_components) {
    data.address_components.forEach((ele) => {
      let label = '';
      switch (ele.types[0]) {
        case 'administrative_area_level_1':
          label = 'province';
          break;
        case 'administrative_area_level_2':
          label = 'city';
          break;
        case 'country':
          label = 'country';
          break;
        case 'postal_code':
          label = 'zip';
          break;
        case 'sublocality_level_1':
        case 'route':
        case 'locality':
        case 'political':
          label = 'address';
          break;
        default:
          break;
      }

      if (label) {
        if (label === 'address' && finalObject[label])
          finalObject[label] = `${finalObject[label]}, ${ele.long_name}`;
        else finalObject[label] = ele.long_name;
      }
    });
    finalObject = {
      ...finalObject,
      lat: String(data.geometry.location.lat()),
      lon: String(data.geometry.location.lng()),
    };

    if (data.name) {
      finalObject.address = data.name;
    }
  }

  return finalObject;
};

export function HybridAddressField(props) {
  const {
    address: addressProps = {},
    error: addressError,
    handleChangeLocation,
    handleChangeMap,
  } = props;
  const [address, setAddress] = useState(addressProps);
  const [zoom, setZoom] = useState(10);
  const { lat: defaultLat, lng: defaultLng } = projectSetting.defaultHybridEventAddress;

  const { eventTypeField, mapLocation, mapBlock, googleInputSearch, mapLabel } = useStyles();
  const source = useSource();
  const autocompleteInput = React.useRef(null);

  const { handleAddressChange } = props;

  React.useEffect(() => {
    source.autocomplete = null;
    source.autocompleteLsr = null;
    mapScriptLoad(() => {
      if (!window.google) return false;
      const handlePlaceChanged = () => {
        const place = source.autocomplete.getPlace();
        if (place.address_components && place.geometry && place.geometry.location) {
          handleChangeLocation(place);
          const addressObj = makePlaceData(place);
          setZoom(16);
          setAddress(addressObj);
        }
      };

      source.autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current);
      source.autocompleteLsr = google.maps.event.addListener(
        source.autocomplete,
        'place_changed',
        handlePlaceChanged
      );
      return null;
    });

    return () => {
      if (window.google) {
        if (source.autocompleteLsr) google.maps.event.removeListener(source.autocompleteLsr);
        if (source.autocomplete) google.maps.event.clearInstanceListeners(source.autocomplete);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetOnFIeldChange = (fieldval) => {
    switch (fieldval) {
      case 'city':
        delete address.zip;
        break;
      case 'province':
        delete address.city;
        delete address.zip;
        break;
      case 'country':
        delete address.province;
        delete address.city;
        delete address.zip;
        break;
      default:
        break;
    }
  };

  const handleFieldChange = (value, name) => {
    if (!value) {
      const addressObj = {
        ...address,
      };
      delete addressObj[name];
      setAddress(addressObj);
    } else {
      resetOnFIeldChange(name);
      setAddress({ ...address, [name]: value });
    }
  };

  const fetchStateOptions = useCallback((selectedCountry) => {
    let options = [];
    if (selectedCountry) {
      const filteredCountry = countryList.find(
        (countryData) => countryData.countryName === selectedCountry
      );
      if (filteredCountry && filteredCountry.regions) {
        options = filteredCountry.regions;
      }
    }
    return options;
  }, []);

  const handleLatLanChange = useCallback((mapAddress, selectedZoom) => {
    handleChangeMap(mapAddress);
    setAddress(mapAddress);
    setZoom(selectedZoom);
  }, []);

  const center = useMemo(
    () => ({
      lat: Number(address.lat || defaultLat),
      lng: Number(address.lon || defaultLng),
    }),
    [address.lat, address.lon, defaultLat, defaultLng]
  );

  useEffect(() => {
    if (address) {
      handleAddressChange(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <>
      <Grid item xs={12} className={eventTypeField}>
        <Box>
          <InputField
            name="Location"
            label="Location"
            placeholder="Search location..."
            type="text"
            className={googleInputSearch}
            id="google-map-address"
            inputRef={autocompleteInput}
            error={addressError?.lat || addressError?.lon}
            required={(Object.keys(address).length && (!address.lat || !address.lon)) || false}
            onChange={() => {}}
            endAdornment
            endIconAdornment
            noFocus
            endAdornmentIcon={<Search size={18} />}
            descText="This location will be displayed to the attendees on reception and emails"
          />
        </Box>
      </Grid>
      <Box width="100%" height="300px" className={mapBlock}>
        <Box className={mapLabel}>Select your location on the map.</Box>
        <MapView
          id="exhibition-map"
          zoom={zoom}
          center={center}
          className={mapLocation}
          onChange={handleLatLanChange}
        />
      </Box>
      <Grid item xs={12} className={eventTypeField}>
        <InputField
          name="address"
          label="Address"
          placeholder="Example: 123, Main Street"
          type="text"
          value={address.address}
          required={Object.keys(address).length || false}
          error={addressError?.address}
          onChange={(e) => e.target && handleFieldChange(e.target.value, 'address')}
        />
      </Grid>
      <Grid item xs={12} className={eventTypeField}>
        <SelectDropdown
          name="Country"
          placeholder="Select country"
          label="Country"
          valueKey="countryName"
          position="top"
          options={countryList}
          labelKey="countryName"
          required={Object.keys(address).length || false}
          error={addressError?.country}
          isArrayOfObject
          // error={errObj.timezone}
          value={address.country ? { countryName: address.country } : null}
          hasSearch
          tooltip
          descType="TOOLTIP"
          onChange={(e) => handleFieldChange(e.countryName, 'country')}
        />
      </Grid>
      <Grid item xs={12} className={eventTypeField}>
        <SelectDropdown
          name="State/Province"
          placeholder="Select State/Province"
          label="State/Province"
          valueKey="name"
          labelKey="name"
          position="top"
          isArrayOfObject
          hasSearch
          tooltip
          descType="TOOLTIP"
          required={Object.keys(address).length || false}
          error={addressError?.province}
          value={address.province ? { name: address.province } : null}
          options={fetchStateOptions(address.country)}
          onChange={(e) => handleFieldChange(e.name, 'province')}
        />
      </Grid>
      <Grid item xs={12} className={eventTypeField}>
        <InputField
          name="City"
          placeholder="Example: New York City"
          label="City"
          type="text"
          value={address.city ? address.city : ''}
          required={Object.keys(address).length || false}
          error={addressError?.city}
          onChange={(e) => e.target && handleFieldChange(e.target.value, 'city')}
        />
      </Grid>
      <Grid item xs={12} className={eventTypeField}>
        <InputField
          name="ZIP/Postal Code"
          label="ZIP/Postal Code"
          placeholder="Example: 10119"
          type="text"
          value={address.zip ? String(address.zip) : ''}
          required={Object.keys(address).length || false}
          error={addressError?.zip}
          onChange={(e) => e.target && handleFieldChange(e.target.value, 'zip')}
        />
      </Grid>
    </>
  );
}
