import React from 'react';

export default function SvgEventLockIcon(props) {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.5832 7.25001V5.41668C12.5832 4.2011 12.1003 3.03531 11.2407 2.17577C10.3812 1.31623 9.21541 0.833344 7.99984 0.833344C6.78426 0.833344 5.61847 1.31623 4.75893 2.17577C3.89939 3.03531 3.4165 4.2011 3.4165 5.41668V7.25001C2.68716 7.25001 1.98769 7.53974 1.47196 8.05547C0.956235 8.57119 0.666504 9.27066 0.666504 10V16.4167C0.666504 17.146 0.956235 17.8455 1.47196 18.3612C1.98769 18.8769 2.68716 19.1667 3.4165 19.1667H12.5832C13.3125 19.1667 14.012 18.8769 14.5277 18.3612C15.0434 17.8455 15.3332 17.146 15.3332 16.4167V10C15.3332 9.27066 15.0434 8.57119 14.5277 8.05547C14.012 7.53974 13.3125 7.25001 12.5832 7.25001ZM5.24984 5.41668C5.24984 4.68733 5.53957 3.98786 6.05529 3.47213C6.57102 2.95641 7.27049 2.66668 7.99984 2.66668C8.72918 2.66668 9.42866 2.95641 9.94438 3.47213C10.4601 3.98786 10.7498 4.68733 10.7498 5.41668V7.25001H5.24984V5.41668ZM13.4998 16.4167C13.4998 16.6598 13.4033 16.893 13.2314 17.0649C13.0594 17.2368 12.8263 17.3333 12.5832 17.3333H3.4165C3.17339 17.3333 2.94023 17.2368 2.76832 17.0649C2.59641 16.893 2.49984 16.6598 2.49984 16.4167V10C2.49984 9.7569 2.59641 9.52374 2.76832 9.35183C2.94023 9.17992 3.17339 9.08334 3.4165 9.08334H12.5832C12.8263 9.08334 13.0594 9.17992 13.2314 9.35183C13.4033 9.52374 13.4998 9.7569 13.4998 10V16.4167Z" fill="#444444" />
        </svg>

    );
}
