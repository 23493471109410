export const planTypeDisplay = (planId) => {
  switch (planId.planName) {
    case 'free':
      return 'FREE TRIAL';
    case 'advanced':
      return 'ADVANCED PLAN';
    case 'pro':
      return 'PRO PLAN';
    case 'enterprise':
      return 'ENTERPRISE PLAN';
    case 'basic':
      return 'BASIC PLAN';
    case 'webinar':
      return 'Webinar Multiply';
    case 'webinar_lite':
      return 'Webinar+';
    case 'digital-events':
      return 'Digital Events';
    case 'all-in':
      return 'All-in';
    default:
      return null;
  }
};
