export const apiStatus = {
  CANCELLED: 'CANCELLED',
  SUCCESS: 'SUCCESS',
  ACTIVE: 'ACTIVE',
  CANCEL_SCHEDULED: 'CANCEL_SCHEDULED',
  ERROR: 'ERROR',
  IDEAL: 'IDEAL',
  FAILED: 'FAILED',
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
};
