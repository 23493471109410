/* eslint-disable  */
import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  preLoaderActive: {
    position: 'absolute',
    top: '50%',
    bottom: 0,
    left: 0,
    right: 0,
  },
  blurBackground: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.35)',
  },
  circle: {
    margin: 'auto',
    position: 'relative',
    width: 15,
    height: 15,
    backgroundColor: 'rgba(175, 175, 175, .5)',
    boxShadow: '-21px 0px 0px rgba(175, 175, 175, 1)',
    borderRadius: '50%',
    animation: `$circle_classic 1s ease-in-out infinite alternate`,
    marginBottom: 30,
  },
  blurLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  '@keyframes circle_classic': {
    '0%': {
      opacity: 0.1,
      transform: 'rotate(0deg) scale(0.5)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotate(360deg) scale(1.2)',
    },
  },
  loadingText: {
    marginBottom: '0 !important',
    opacity: 0.6,
    textAlign: 'center',
    '&>span': {
      animation: 'blink',
      animationDuration: '1.4s',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
      '&:nth-child(2)': {
        animationDelay: '.2s',
      },
      '&:nth-child(3)': {
        animationDelay: '.4s',
      },
    },
  },
  loadingTextBlur: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px',
    width: '100%',
    height: '100%',
    color: '#fff',
    zIndex: '1000',
  },
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },
}));
export default function Preloader(props) {
  const classes = useStyles();
  const { isBackgroundBlur } = props;
  return (
    <Box className={`${classes.preLoaderActive}`}>
      <Box className={`${classes.circle}`}></Box>
      {isBackgroundBlur ? (
        <Typography className={`${classes.loadingText}`} variant="body1">
          {props.loadingLabel || 'Loading'}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </Typography>
      ) : (
        <Typography className={`${classes.loadingText}`} variant="body1">
          {props.loadingLabel || 'Loading'}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </Typography>
      )}
    </Box>
  );
}
