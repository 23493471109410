import React from 'react';

// eslint-disable-next-line no-unused-vars
const style = {
  a: {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
};
export default function SvgFacebookIcon(props) {
  return (
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_384_5711)">
        <path
          d="M22.6756 0H1.32442C0.592963 0 0 0.592963 0 1.32442V22.6756C0 23.407 0.592963 24 1.32442 24H22.6756C23.407 24 24 23.407 24 22.6756V1.32442C24 0.592963 23.407 0 22.6756 0Z"
          fill="#3D5A98" />
        <path
          d="M16.5594 24V14.7057H19.6794L20.1474 11.0836H16.5594V8.77139C16.5594 7.72234 16.8505 7.00803 18.355 7.00803H20.2737V3.76803C19.3454 3.66957 18.4124 3.62192 17.479 3.62529C14.7158 3.62466 12.8211 5.31287 12.8211 8.41266V11.0836H9.69159V14.7057H12.8211V24H16.5594Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_384_5711">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
